import {Input, message, Modal, Tooltip} from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo, useRef} from 'react'
import {useFocused, useSelected, useSlate, useSlateStatic} from 'slate-react'
import {renderIf} from 'views/Shared'
import {isBlockActive} from 'views/SlateEditor/functions'
import {insertLink, removeLink} from 'views/SlateEditor/functions/link'
import {ElementTypes} from 'views/SlateEditor/types'
import {string} from 'yup'
import InlineChromiumBugfix from '../InlineChromiumBugfix'
import {ToolbarButton} from '../Toolbar'
import './Link.css'
import {BiUnlink} from "react-icons/bi";
import {RiLinkM} from "react-icons/ri";

export const LinkButton = () => {
  const t = useTranslate()

  const editor = useSlate()

  const valuesRef = useRef({})

  const disabled = !!!editor?.selection

  const isActive = isBlockActive(editor, ElementTypes.LINK)

  const handleSubmit = async () => {
    const url = String(valuesRef.current?.url).trim()
    const schema = string().url()
    const valid = await schema.isValid(url)
    if (valid) {
      insertLink(editor, url)
      Modal.destroyAll()
    } else {
      message.error('url is invalid!')
    }
    return
  }

  const formContent = useMemo(
    () => (
      <form
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmit()
        }}>
        <div className="flex items-start gap-4">
          <RiLinkM
            size={40}
            style={{color: '#faad14'}}
          />
          <div className="flex flex-col mt-2">
            <div className="font-medium text-color-200 mb-1">
              {t(
                'Paste a link to embed content from another site (e.g. Twitter) and click Ok'
              )}
            </div>
            <div className="flex items-baseline gap-2 text-xs italic mb-1">
              <p className="text-color-400 font-light">{t('example')}</p>
              <p className="font-semibold text-primary">
                {'https://twitter.com'}
              </p>
            </div>
            <Input
              onChange={(event) => {
                valuesRef.current['url'] = event.target.value
              }}
              placeholder={t('enter an url')}
            />
            <input
              hidden
              type="submit"
            />
          </div>
        </div>
      </form>
    ),
    [valuesRef]
  )

  const handleClick = (event) => {
    event.preventDefault()
    Modal.confirm({
      width: 600,
      icon: null,
      okText: t('save'),
      content: formContent,
      cancelText: t('cancel'),
      className: 'custom-modal',
      okButtonProps: {
        type: 'primary',
        onClick: handleSubmit,
        className: 'no-shadow no-text-shadow rounded-lg no-border',
      },
      cancelButtonProps: {
        className: 'no-shadow no-text-shadow rounded-lg no-border',
      },
      title: (
        <div className="text-center text-color-000 font-bold uppercase">
          {t('insert link website')}
        </div>
      ),
    })
  }

  return (
    <ToolbarButton
      Icon={RiLinkM}
      active={isActive}
      disabled={disabled}
      onClick={handleClick}
    />
  )
}

export const LinkElement = ({attributes, element, children}) => {
  const t = useTranslate()
  const focused = useFocused()
  const selected = useSelected()
  const editor = useSlateStatic()
  const {href} = element ?? {}
  return (
    <div className="element-link">
      <a
        {...(attributes ?? {})}
        href={href}>
        <InlineChromiumBugfix/>
        {children}
        <InlineChromiumBugfix/>
      </a>
      {renderIf(
        selected && focused,
        <div
          className="popup"
          contentEditable={false}>
          <Tooltip title={t('open link')}>
            <a
              className="flex-1 text-sm italic leading-tight pr-2"
              target="_blank"
              href={href}
              rel="noopener noreferrer">
              {href}
            </a>
          </Tooltip>
          <Tooltip title={t('remove link')}>
            <BiUnlink
              size={15}
              className="text-color-400 hover:text-color-000 cursor-pointer"
              onClick={() => {
                removeLink(editor)
              }}
            />
          </Tooltip>
        </div>
      )}
    </div>
  )
}
