import { baseItemSchema } from 'apis/schema'
import { useSelectEntities } from 'redux/entities/useSelectEntities'
export const SelectEntityItem = ({
  item,
  schema = baseItemSchema,
  equal,
  children,
  defaultItem
}) => {
  const result = useSelectEntities(
    item,
    schema,
    defaultItem,
    equal
  )

  return children(result)
}
