import React from 'react';
import {Block} from './Block';
import NullComponent from "../../../components/NullComponent";

export const EditAbleBlockHolder = ({
    icon = NullComponent,
    title,
    description,
    onClick
}) => (
    <div
        onClick={onClick}
        className="p-5 flex flex-col items-center border border-color-50 hover:background-100 hover:shadow-items rounded-lg cursor-pointer">
        {!!icon && icon()}
        <div className="font-bold text-lg text-color-200 mt-2">
            {title}
        </div>
        <div className="text-center text-sm text-color-500 italic">
            {description}
        </div>
    </div>
)
