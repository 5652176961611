import {Avatar, Button, Form, Modal, Popconfirm, Select, Table, Tooltip, Typography} from 'antd'
import {create_product_updatePrice_Api} from 'apis'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import numeral from "numeral"
import React, {useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {getAsynCacheSelector} from "../../../../../modules/asyncCache/selectors";
import {
  create_product_copyPriceSet_Api,
  create_product_deletePriceSetGroup_Api,
  create_product_editPriceSet_Api,
  create_product_editPriceSetGroup_Api,
  create_product_expirePriceSetGroup_Api,
  create_product_removeExpirePriceSetGroup_Api
} from "../../../../../apis";
import {
    BsCalendar2Week,
    BsCopy,
    BsDiamondFill,
    BsInputCursorText,
    BsPlusCircleFill, BsStopCircle,
    BsTrash3,
    BsXCircle
} from "react-icons/bs";
import {ProductPriceSetInfo} from "./ProductPriceSetInfo";
import {ProductPriceGroupComponent, ProductPriceGroupReportComponent} from "./ProductPriceGroupComponent";
import {EditReportNumberModal} from "../../../../Organization/Settings/ProductReport/ProductReportDetailSetting";
import classNames from "classnames";
import {UnitComponent} from "./UnitComponent";
import {ProductPriceSetChart} from "./ProductPriceSetChart";
import {PriceSetGroupEffectDateUpdateForm} from "./forms/PriceSetGroupEffectDateUpdateForm";
import {PriceSetCopyToNewLocationForm} from "./forms/PriceSetCopyToNewLocationForm";
import {PriceDetailSpotPriceEditForm} from "./forms/PriceDetailSpotPriceEditForm";
import {PriceDetailNewPriceForLocationForm} from "./forms/PriceDetailNewPriceForLocationForm";
import {PriceSetGroupCopyAndInsertForm} from "./forms/PriceSetGroupCopyAndInsertForm";
import {ForwardPriceInputForm} from "../ManageProductPrices/ForwardPriceInputForm";
import {ProductPriceGroupCommentComponent} from "./forms/ProductPriceGroupCommentForm";
import {emptyArray, emptyObject} from "../../../../../helpers/emptyObjects";
import {FiEdit3} from "react-icons/fi";
import {renderIf} from "../../../../Shared";
import {FaLongArrowAltRight} from "react-icons/fa";
import {MiniButton} from "../../../../AppLocals/Organization/OrganizationEntity";
import {AiOutlineAreaChart} from "react-icons/ai";

export const ProductPriceChart = ({product, productPriceSet, location}) => {

  return (
    <ProductPriceSetChart
      product={product}
      product_id={product.id}
      contract_type_cd={productPriceSet.contract_type_cd}
      delivery_type_cd={productPriceSet.delivery_type_cd}
      incoterm_type_cd={productPriceSet.incoterm_type_cd}
      transport_type_cd={productPriceSet.transport_type_cd}
      unit_id={_.get(productPriceSet, 'unit.id')}
      unit2_id={_.get(productPriceSet, 'unit2.id')}
      price_unit_id={_.get(productPriceSet, 'price_unit.id')}
      price_unit2_id={_.get(productPriceSet, 'price_unit2.id')}
      location_type={_.get(location, '_type')}
      location_id={_.get(location, 'id')}

    />
  )
}

export const ProductPriceSetGroupComponent = ({item, productPriceSet, update, setUpdate, effectMomentDate}) => {
  const t = useTranslate();

  const {
    product_units = [],
    product_price_units = [],
    contract_types = [],
    delivery_types = [],
    incoterm_types = [],
    transport_types = [],
    countries = [],
    provinces = [],
  } = useAppConfig();

  const {
    result: price_periods = []
  } = useSelector(
    state => {
      return getAsynCacheSelector(
        state,
        "/products/get-price-periods?day=1"
      )
    }
  );

  const [__, dispatch] = useDispatchAsyncActionWithNotify();

  const [form] = Form.useForm();
  const [expand, setExpand] = useState([]);
  const [chartAddedData, setChartAddedData] = useState({additionalData: [], additionalData2: []});

  const product_price_set_ids = useMemo(
    () =>
      _.reduce(productPriceSet.data || emptyArray,
        function (result, value) {
          result.push(value.product_price_set_id);
          return result;
        }
        , []), [productPriceSet]);

  const handleOnNewSubmitLocation = (values) => {
    const {
      contract_type_cd, delivery_type_cd, incoterm_type_cd, transport_type_cd,
      unit, unit2, price_unit, price_unit2, location, ...rest
    } = productPriceSet;

    const unit_id = (unit || emptyObject).id;
    const unit2_id = (unit2 || emptyObject).id;
    const price_unit_id = (price_unit || emptyObject).id;
    const price_unit2_id = (price_unit2 || emptyObject).id;

    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_updatePrice_Api(),
          query: {
            ':id':
            item.id
          },
          values: {
            contract_type_cd, delivery_type_cd, incoterm_type_cd, transport_type_cd,
            unit_id, unit2_id, price_unit_id, price_unit2_id,
            eff_at: moment(values.eff_at).utc().format(),

            country_id: values.country_id,
            province_id: values.province_id,

            data: JSON.stringify([values].map(({rangePicker, ...rest}) => {
              return {
                ...rest,
                from_date: rangePicker[0].utc().format(),
                to_date: rangePicker[1].utc().format(),
              }
            }))
          }
        }
      )
    )

  }

  const handleOnEditSubmitLocationAsSingleRow = (rec, values) => {
    const data = [
      {...values, ..._.get(rec, 'data.0')}
    ];

    const {
      contract_type_cd, delivery_type_cd, incoterm_type_cd, transport_type_cd,
      unit, unit2, price_unit, price_unit2
    } = productPriceSet;

    const unit_id = (unit || emptyObject).id;
    const unit2_id = (unit2 || emptyObject).id;
    const price_unit_id = (price_unit || emptyObject).id;
    const price_unit2_id = (price_unit2 || emptyObject).id;

    const country_id = rec.location._type === 'country' ? rec.location.id : rec.location.country_id;
    const province_id = rec.location._type === 'province' ? rec.location.id : null;


    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_updatePrice_Api(),
          query: {
            ':id':
            item.id
          },
          values: {
            contract_type_cd, delivery_type_cd, incoterm_type_cd, transport_type_cd,
            unit_id, unit2_id, price_unit_id, price_unit2_id,
            country_id, province_id,

            eff_at: effectMomentDate.format("YYYY-MM-DD"),

            data: JSON.stringify(data)
          }
        }
      )
    )
  }

  const handleOnDeleteSubmitLocation = ({product_price_group_id}) => {
    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_deletePriceSetGroup_Api(),
          query: {
            ':product_price_group_id': product_price_group_id
          },
          values: {
            eff_at: effectMomentDate.format("YYYY-MM-DD")
          }
        }
      )
    )
  }

  const handleOnCopySubmitLocation = ({product_price_set_id, product_price_group_id, diff = 0, diff2 = 0, country_id, province_id, eff_at, end_at = null}) => {

    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_copyPriceSet_Api(),
          query: {
            ':id': item.id,
            ':product_price_set_id': product_price_set_id
          },
          values: {
            product_price_group_id,
            diff,
            diff2,
            country_id,
            province_id,
            eff_at: eff_at.format("YYYY-MM-DD"),
            end_at: _.isEmpty(end_at) ? null : end_at.format("YYYY-MM-DD")
          }
        }
      )
    )
  }

  const handleOnEditSubmitPriceSetGroup = ({product_price_group_id, eff_at}) => {
    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_editPriceSetGroup_Api(),
          query: {
            ':product_price_group_id': product_price_group_id
          },
          values: {
            eff_at: eff_at.format("YYYY-MM-DD")
          }
        }
      )
    )
  }

  const handleOnExpirePriceSetGroup = ({product_price_group_id, eff_at}) => {
    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_expirePriceSetGroup_Api(),
          query: {
            ':product_price_group_id': product_price_group_id
          },
          values: {
            end_at: eff_at.format("YYYY-MM-DD")
          }
        }
      )
    )
  }

  const handleOnRemoveExpirePriceSetGroup = ({product_price_group_id, eff_at}) => {
    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_removeExpirePriceSetGroup_Api(),
          query: {
            ':product_price_group_id': product_price_group_id
          },
          values: {
            end_at: eff_at.format("YYYY-MM-DD")
          }
        }
      )
    )
  }

  const handleOnEditSubmitPriceSet = (values) => {
    const product_price_set_ids = _.reduce(productPriceSet.data || emptyArray,
      function (result, value) {
        result.push(value.product_price_set_id);
        return result;
      }
      , []);
    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_editPriceSet_Api(),
          query: {
            ':id':
            item.id
          },
          values: {
            ...values,
            product_price_set_ids
          }
        }
      )
    )
  }

  return (
    <div key={JSON.stringify(product_price_set_ids)}>
      <div className="flex flex-col gap-1 border border-color-50 shadow-items-md rounded-md p-2">
        <React.Fragment>

          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center gap-1">
              <BsDiamondFill size={7} className="text-primary-600 mr-2"/>
              <span className="text-xs text-color-400 italic">
                        {`${t('unit')} : `}
                    </span>
              <UnitComponent
                item={productPriceSet}
                onHandleSubmit={(values) => {
                  handleOnEditSubmitPriceSet(values)
                }}
                priceUnitField={"price_unit"}
                unitField={"unit"}
                placeHolder={
                  <div className="flex items-center font-bold text-xs text-color-000 italic">
                    <span>{_.get(productPriceSet, 'price_unit.name', t('price unit'))}</span>
                    <span> / {_.get(productPriceSet, 'unit.name', t('unit'))}</span>
                  </div>
                }>
                <div className="flex flex-center">
                  <Tooltip title={t('click to edit')} overlayClassName="font-medium text-xs italic" placement="right">
                    <FiEdit3
                      size={14}
                      className="ml-3 text-color-500 hover:text-color-100 cursor-pointer"
                    />
                  </Tooltip>
                </div>
              </UnitComponent>
            </div>
            {productPriceSet.contract_type_cd !== 'spot' && (
              <div className="flex items-center gap-1">
                <BsDiamondFill size={7} className="text-primary-600 mr-2"/>
                <span className="text-xs text-color-400 italic">
                            {`${t('delivery type')} : `}
                        </span>
                <span className="font-bold text-xs text-color-000">
                            {delivery_types[_.get(productPriceSet, 'delivery_type_cd')]}
                        </span>
                <Toggle>
                  {(isToggle, toggle) => (
                    isToggle ? (
                      <Modal
                        width={480}
                        className="custom-modal"
                        title={(
                          <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                            {t('delivery type')}
                          </div>
                        )}
                        maskClosable={false}
                        destroyOnClose
                        onCancel={toggle}
                        visible={isToggle}
                        footer={null}>
                        <div className="w-full">
                          <Form className="space-y-3"
                            initialValues={{delivery_type_cd: null}}
                            onFinish={(values) => {
                              handleOnEditSubmitPriceSet(values);
                              toggle();
                            }}
                            {...{
                              labelCol: {span: 8},
                              wrapperCol: {span: 16},
                            }}>
                            {(values) => (
                              <React.Fragment>
                                <Form.Item
                                  label={
                                    <span className="text-sm text-color-400 italic">
                                                                    {t('delivery type')}
                                                                </span>
                                  }
                                  className="flex-1"
                                  name="delivery_type_cd">
                                  <Select
                                    showSearch
                                    allowClear={true}
                                    virtual={false}
                                    optionFilterProp="label"
                                    placeholder={t('delivery type')}
                                    style={{width: '100%'}}>
                                    {!_.isEmpty(delivery_types) &&
                                    Object.keys(delivery_types).map(
                                      (value, key) => {
                                        return (
                                          <Select.Option
                                            key={value}
                                            value={value}
                                            label={value + ' - ' + delivery_types[value]}>
                                            {value + ' - ' + delivery_types[value]}
                                          </Select.Option>
                                        )
                                      }
                                    )}
                                  </Select>
                                </Form.Item>

                                <div className="text-center mt-4">
                                  <Button
                                    type="primary"
                                    className="button-rounded-md no-border"
                                    htmlType="submit">
                                    {t('confirm')}
                                  </Button>
                                </div>
                              </React.Fragment>
                            )}
                          </Form>
                        </div>
                      </Modal>
                    ) : (
                      <div className="flex flex-center">
                        <Tooltip title={t('click to edit')} overlayClassName="font-medium text-xs italic"
                                 placement="right">
                          <FiEdit3
                            onClick={() => {
                              toggle()
                            }}
                            size={14}
                            className="ml-3 text-color-500 hover:text-color-100 cursor-pointer"
                          />
                        </Tooltip>
                      </div>
                    )
                  )}
                </Toggle>
              </div>
            )}
          </div>

          {productPriceSet.contract_type_cd !== 'spot' && (
            <div className="grid grid-cols-2 gap-2">
              <div className="flex items-center gap-1">
                <BsDiamondFill size={7} className="text-primary-600 mr-2"/>
                <span className="text-xs text-color-400 italic">
                            {`${t('incoterm')} : `}
                        </span>
                <span className="font-bold text-xs text-color-000">
                            {!!_.get(productPriceSet, 'incoterm_type_cd') && incoterm_types[_.get(productPriceSet, 'incoterm_type_cd')]}
                        </span>
                <Toggle>
                  {(isToggle, toggle) => (
                    isToggle ? (
                      <Modal
                        width={480}
                        className="custom-modal"
                        title={(
                          <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                            {t('incoterm')}
                          </div>
                        )}
                        maskClosable={false}
                        destroyOnClose
                        onCancel={toggle}
                        visible={isToggle}
                        footer={null}>
                        <div className="w-full">
                          <Form className="space-y-3"
                            initialValues={{incoterm_type_cd: null}}
                            onFinish={(values) => {
                              handleOnEditSubmitPriceSet(values);
                              toggle();
                            }}
                            {...{
                              labelCol: {span: 8},
                              wrapperCol: {span: 16},
                            }} >
                            {(values) => (
                              <React.Fragment>
                                <Form.Item
                                  label={
                                    <span className="text-sm text-color-400 italic">
                                                                    {t('incoterm')}
                                                                </span>
                                  }
                                  className="flex-1"
                                  name="incoterm_type_cd">
                                  <Select
                                    showSearch
                                    allowClear={true}
                                    virtual={false}
                                    optionFilterProp="label"
                                    placeholder={t('incoterm')}
                                    style={{width: '100%'}}>
                                    {!_.isEmpty(incoterm_types) &&
                                    Object.keys(incoterm_types).map(
                                      (value, key) => {
                                        return (
                                          <Select.Option
                                            key={value}
                                            value={value}
                                            label={value + ' - ' + incoterm_types[value]}>
                                            {value + ' - ' + incoterm_types[value]}
                                          </Select.Option>
                                        )
                                      }
                                    )}
                                  </Select>
                                </Form.Item>

                                <div className="text-center mt-4">
                                  <Button className="button-rounded-md no-border" type="primary"
                                          htmlType="submit">
                                    {t('confirm')}
                                  </Button>
                                </div>
                              </React.Fragment>
                            )}
                          </Form>
                        </div>
                      </Modal>
                    ) : (
                      <div className="flex flex-center">
                        <Tooltip title={t('click to edit')} overlayClassName="font-medium text-xs italic"
                                 placement="right">
                          <FiEdit3
                            onClick={() => {
                              toggle()
                            }}
                            size={14}
                            className="ml-3 text-color-500 hover:text-color-100 cursor-pointer"
                          />
                        </Tooltip>
                      </div>
                    )
                  )}
                </Toggle>
              </div>
              <div className="flex items-center gap-1">
                <BsDiamondFill size={7} className="text-primary-600 mr-2"/>
                <span className="text-xs text-color-400 italic">
                            {`${t('transport type')} : `}
                        </span>
                <span className="font-bold text-xs text-color-000">
                            {!!_.get(productPriceSet, 'transport_type_cd') && transport_types[_.get(productPriceSet, 'transport_type_cd')]}
                        </span>
                <Toggle>
                  {(isToggle, toggle) => (
                    isToggle ? (
                      <Modal
                        width={480}
                        className="custom-modal"
                        title={(
                          <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                            {t('transport type')}
                          </div>
                        )}
                        maskClosable={false}
                        destroyOnClose
                        onCancel={toggle}
                        visible={isToggle}
                        footer={null}>
                        <div className="w-full">
                          <Form className="space-y-3" initialValues={{transport_type_cd: null}}
                                onFinish={(values) => {
                                  handleOnEditSubmitPriceSet(values);
                                  toggle();
                                }}
                                {...{
                                  labelCol: {span: 8},
                                  wrapperCol: {span: 16},
                                }} >
                            {(values) => (
                              <React.Fragment>
                                <Form.Item
                                  label={
                                    <span className="text-sm text-color-400 italic">
                                                                    {t('transport type')}
                                                                </span>
                                  }
                                  className="flex-1"
                                  name="transport_type_cd">
                                  <Select
                                    showSearch
                                    allowClear={true}
                                    virtual={false}
                                    optionFilterProp="label"
                                    placeholder={t('transport type')}
                                    style={{width: '100%'}}>
                                    {!_.isEmpty(transport_types) &&
                                    Object.keys(transport_types).map(
                                      (value, key) => {
                                        return (
                                          <Select.Option
                                            key={value}
                                            value={value}
                                            label={transport_types[value]}>
                                            {transport_types[value]}
                                          </Select.Option>
                                        )
                                      }
                                    )}
                                  </Select>
                                </Form.Item>

                                <div className="text-center mt-4">
                                  <Button className="button-rounded-md no-border" type="primary"
                                          htmlType="submit">
                                    {t('confirm')}
                                  </Button>
                                </div>
                              </React.Fragment>
                            )}
                          </Form>
                        </div>
                      </Modal>
                    ) : (
                      <div className="flex flex-center">
                        <Tooltip title={t('click to edit')} overlayClassName="font-medium text-xs italic"
                                 placement="right">
                          <FiEdit3
                            onClick={() => {
                              toggle()
                            }}
                            size={14}
                            className="ml-3 text-color-500 hover:text-color-100 cursor-pointer"
                          />
                        </Tooltip>
                      </div>
                    )
                  )}
                </Toggle>
              </div>
            </div>
          )}

          {productPriceSet.contract_type_cd !== 'spot' && (
            <div className="flex items-center gap-1">
              <BsDiamondFill size={7} className="text-primary-600 mr-2"/>
              <span className="text-xs text-color-400 italic">
                        {`${t('unit of the basis price')} : `}
                    </span>
              <span className="flex items-center font-bold text-xs text-color-000">
                        <span>{_.get(productPriceSet, 'price_unit2.name', t('price unit'))}</span>
                        <span> / {_.get(productPriceSet, 'unit2.name', t('unit'))}</span>
                    </span>
              <UnitComponent
                item={productPriceSet}
                onHandleSubmit={(values) => {
                  handleOnEditSubmitPriceSet(values)
                }}
                priceUnitField={"price_unit2"}
                unitField={"unit2"}
                placeHolder={<span/>}>
                <div className="flex flex-center">
                  <Tooltip title={t('click to edit')} overlayClassName="font-medium text-xs italic" placement="right">
                    <FiEdit3
                      size={14}
                      className="ml-3 text-color-500 hover:text-color-100 cursor-pointer"
                    />
                  </Tooltip>
                </div>
              </UnitComponent>
            </div>
          )}
        </React.Fragment>

        <Form
          key={update}
          className="space-y-6 flex flex-col py-1"
          // onChange={() => setUpdate(Date.now())} // nếu ko rào lại thì sẽ bị reset laij form
          form={form}
          initialValues={{...productPriceSet}}
          name={"dynamic_form_item" + update}>
          <Form.List name="data">
            {(fields, {add, remove}) => (
              <div className="space-y-3">
                <Table
                  key={update}
                  pagination={false}
                  size="small"
                  dataSource={fields}
                  columns={[
                    {
                      visible: true,
                      title: (
                        <span className="font-bold text-sm text-color-000">{t('location')}</span>
                      ),
                      dataIndex: 'location',
                      key: 'location',
                      render: (__, record) => {
                        const _rec = form.getFieldValue('data')[record.name];
                        const disable = !!_rec.eff_at && !moment(_rec.eff_at).isBefore(moment(), "day")

                        return (
                          <div className="flex flex-col space-y-1">
                                          <span className="font-bold text-sm text-color-000">
                                              {_rec.location.location_name}
                                          </span>
                            <div className="flex flex-wrap items-center gap-x-2 gap-y-1">
                              {
                                !!_rec.eff_at ? (
                                  <span className={classNames("ml-2 font-medium text-xs italic",
                                    moment(_rec.eff_at).isBefore(moment().subtract(30, 'days'), "day") ? "text-gray-500" : (
                                      moment(_rec.eff_at).isBefore(moment().subtract(7, 'days'), "day") ? "text-red-500" : (
                                        moment(_rec.eff_at).isBefore(moment().subtract(2, 'days'), "day") ? "text-orange-500" : (
                                          moment(_rec.eff_at).isBefore(moment(), "day") ? "text-green-500" : (
                                            "text-primary-700 font-bold"
                                          )
                                        )
                                      )
                                    )
                                  )}>
                                                          {`(${moment(_rec.eff_at).format("DD/MM/YYYY")})`}
                                                      </span>
                                ) : (renderIf(
                                  !!_rec.max_eff_at,
                                  <span className="ml-2 text-color-400 text-xs italic">
                                                          {`(${moment(_rec.max_eff_at).format("DD/MM/YYYY")})`}
                                                      </span>
                                ))
                              }
                              {renderIf(
                                !!_rec.product_price_group_id && !!_rec.eff_at,
                                <Toggle>
                                  {(isToggleEditPriceRecord, toggleEditPriceRecord) => (
                                    <>
                                      <MiniButton
                                        onClick={toggleEditPriceRecord}
                                        icon={<BsCalendar2Week size={12}/>}
                                        label={t('set effect date')}
                                      />
                                      {isToggleEditPriceRecord && (
                                        <Modal
                                          width={520}
                                          className="custom-modal"
                                          onCancel={toggleEditPriceRecord}
                                          visible={isToggleEditPriceRecord}
                                          maskClosable={false}
                                          destroyOnClose
                                          title={
                                            <div
                                              className="text-center font-bold text-color-000 uppercase tracking-wide">
                                              {t('set effect date')}
                                            </div>
                                          }
                                          footer={null}>
                                          <PriceSetGroupEffectDateUpdateForm
                                            productPriceGroup={_rec}
                                            toggle={toggleEditPriceRecord}
                                            isToggle={isToggleEditPriceRecord}
                                            handleOnEditSubmitPriceSetGroup={handleOnEditSubmitPriceSetGroup}
                                          />
                                        </Modal>
                                      )}
                                    </>
                                  )}
                                </Toggle>
                              )}

                              {!!_rec.end_at ? (
                                <span className="flex items-center gap-2 text-color-400">
                                                      <FaLongArrowAltRight size={11}/>
                                                      <span className="text-xs italic">
                                                          {moment(_rec.end_at).format("DD/MM/YYYY")}
                                                      </span>
                                                  </span>
                              ) : (!!!_rec.eff_at && !!_rec.max_end_at && (
                                  <span className="flex items-center gap-2 text-color-400">
                                                      <FaLongArrowAltRight size={11}/>
                                                      <span className="text-xs italic">
                                                          {moment(_rec.max_end_at).format("DD/MM/YYYY")}
                                                      </span>
                                                  </span>
                                )
                              )}

                              {!!_rec.end_at && (
                                <Popconfirm
                                  title={
                                    <div className="font-medium text-sm text-color-100 italic">
                                      {t('are you sure to remove the expired')}
                                    </div>
                                  }
                                  onConfirm={() => {
                                    handleOnRemoveExpirePriceSetGroup({
                                      product_price_group_id: _rec.product_price_group_id,
                                      eff_at: effectMomentDate
                                    })
                                  }}
                                  okText={t('sure')}
                                  cancelText={t('no')}
                                  okButtonProps={{
                                    type: 'primary',
                                    danger: true,
                                    className: 'button-rounded-md no-border text-xs font-medium px-3',
                                  }}
                                  cancelButtonProps={{
                                    className: 'button-rounded-md no-border text-xs font-medium px-3',
                                  }}>
                                  <MiniButton
                                    type="danger"
                                    icon={<BsXCircle size={12}/>}
                                    label={t('remove expired')}
                                  />
                                </Popconfirm>
                              )}

                              {!!_rec.eff_at && !!!_rec.end_at && (
                                <Popconfirm
                                  title={
                                    <div className="font-medium text-sm text-color-100 italic">
                                      {`${t('confirm to expire at')} ${effectMomentDate.format("DD/MM/YYYY")}`}
                                    </div>
                                  }
                                  onConfirm={() => {
                                    handleOnExpirePriceSetGroup({
                                      product_price_group_id: _rec.product_price_group_id,
                                      eff_at: effectMomentDate
                                    })
                                  }}
                                  okText={t('sure')}
                                  cancelText={t('no')}
                                  okButtonProps={{
                                    type: 'primary',
                                    danger: true,
                                    className: 'button-rounded-md no-border text-xs font-medium px-3',
                                  }}
                                  cancelButtonProps={{
                                    className: 'button-rounded-md no-border text-xs font-medium px-3',
                                  }}>
                                  <MiniButton
                                    type="danger"
                                    icon={<BsStopCircle size={12}/>}
                                    label={`${t('expire at')} ${effectMomentDate.format("DD/MM/YYYY")}`}
                                  />
                                </Popconfirm>
                              )}

                            </div>

                            <div className="flex flex-wrap items-center gap-x-2 gap-y-1">
                              <Toggle>
                                {(isToggleCopyAndInsert, toggleCopyAndInsert) => (
                                  <React.Fragment>
                                    <MiniButton
                                      type="primary"
                                      onClick={() => {
                                        if (!!_rec.eff_at) {
                                          toggleCopyAndInsert()
                                        }
                                      }}
                                      disabled={!!!_rec.eff_at}
                                      icon={<BsCopy size={12}/>}
                                      label={
                                        [
                                          t('copy prices at'),
                                          moment(_rec.eff_at).format("DD/MM/YYYY")
                                        ].join(" ")
                                      }
                                    />
                                    {isToggleCopyAndInsert && (
                                      <Modal
                                        width={600}
                                        className="custom-modal"
                                        onCancel={toggleCopyAndInsert}
                                        maskClosable={false}
                                        destroyOnClose
                                        visible={isToggleCopyAndInsert}
                                        title={
                                          <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                                            {t('copy prices')}
                                          </div>
                                        }
                                        footer={null}>
                                        <PriceSetCopyToNewLocationForm
                                          effectMomentDate={effectMomentDate}
                                          productPriceSet={productPriceSet}
                                          productPriceGroup={_rec}
                                          toggle={toggleCopyAndInsert}
                                          isToggle={isToggleCopyAndInsert}
                                          handleOnCopySubmitLocation={handleOnCopySubmitLocation}
                                        />
                                      </Modal>
                                    )}
                                  </React.Fragment>
                                )}
                              </Toggle>
                              <Popconfirm
                                title={
                                  <div className="font-medium text-sm text-color-100 italic">
                                    {t('sure to remove')}
                                  </div>
                                }
                                disabled={!!!_rec.eff_at}
                                onConfirm={() => {
                                  if (!!_rec.eff_at) {
                                    handleOnDeleteSubmitLocation(
                                      {
                                        product_price_group_id: _rec.product_price_group_id,
                                        eff_at: effectMomentDate
                                      }
                                    );
                                    remove(record.name);
                                  }
                                }}
                                okText={t('sure')}
                                cancelText={t('no')}
                                okButtonProps={{
                                  type: 'primary',
                                  danger: true,
                                  className: 'button-rounded-md no-border text-xs font-medium px-3',
                                }}
                                cancelButtonProps={{
                                  className: 'button-rounded-md no-border text-xs font-medium px-3',
                                }}>
                                <MiniButton
                                  type="danger"
                                  icon={<BsTrash3 size={12}/>}
                                  label={
                                    [
                                      t('delete prices at'),
                                      moment(_rec.eff_at).format("DD/MM/YYYY")
                                    ].join(" ")
                                  }
                                />
                              </Popconfirm>
                              <Toggle>
                                {(isToggleShowCharts, toggleShowCharts) => (
                                  <React.Fragment>
                                    <MiniButton
                                      type="info"
                                      icon={<AiOutlineAreaChart size={12}/>}
                                      label={t('charts')}
                                      onClick={() => {
                                        toggleShowCharts()
                                      }}
                                    />
                                    {isToggleShowCharts && (
                                      <Modal
                                        width={600}
                                        className="custom-modal"
                                        onCancel={toggleShowCharts}
                                        maskClosable={false}
                                        title={
                                          <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                                            {t('charts')}
                                          </div>
                                        }
                                        destroyOnClose
                                        visible={isToggleShowCharts}
                                        footer={null}>
                                        <div className="w-full flex flex-col gap-3 p-3">
                                          <ProductPriceSetInfo productPriceSet={productPriceSet}/>
                                          <ProductPriceChart
                                            product={item}
                                            location={_rec.location}
                                            productPriceSet={productPriceSet}
                                          />
                                        </div>
                                      </Modal>
                                    )}
                                  </React.Fragment>
                                )}
                              </Toggle>
                            </div>

                            <div className="flex flex-wrap items-center justify-end gap-x-2 gap-y-1">
                              <Toggle>
                                {(isToggleCreateAndInsert, toggleCreateAndInsert) => (
                                  <React.Fragment>
                                    <div
                                      onClick={() => {
                                        if (!(disable)) {
                                          toggleCreateAndInsert()
                                        }
                                      }}
                                      style={{padding: '0 10px', height: 26}}
                                      className={classNames(
                                        "flex flex-center gap-2 rounded-md border",
                                        disable ? 'cursor-not-allow' : 'cursor-pointer',
                                        disable ? 'background-200 text-color-300 opacity-50' :
                                          'border-primary bg-primary-50 hover:bg-primary-100 text-primary-600'
                                      )}>
                                      <BsPlusCircleFill size={12}/>
                                      <span className="font-bold text-xs italic">
                                                                        {[
                                                                          t('insert at'),
                                                                          effectMomentDate.format("DD/MM/YYYY")
                                                                        ].join(" ")}
                                                                    </span>
                                    </div>
                                    {isToggleCreateAndInsert && (
                                      <Modal
                                        width={600}
                                        className="custom-modal"
                                        onCancel={toggleCreateAndInsert}
                                        maskClosable={false}
                                        title={
                                          <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                                            {t('create')}
                                          </div>
                                        }
                                        destroyOnClose
                                        visible={isToggleCreateAndInsert}
                                        footer={null}>
                                        <PriceSetGroupCopyAndInsertForm
                                          effectMomentDate={effectMomentDate}
                                          product={item}
                                          productPriceSet={productPriceSet}
                                          productPriceGroup={_rec}
                                          toggle={toggleCreateAndInsert}
                                          isToggle={isToggleCreateAndInsert}
                                          update={update}
                                          handleOnCopySubmitLocation={handleOnCopySubmitLocation}
                                        />
                                      </Modal>
                                    )}
                                  </React.Fragment>
                                )}
                              </Toggle>
                              {productPriceSet.contract_type_cd !== 'spot' && (
                                <React.Fragment>
                                  <Toggle key={item.id}>
                                    {(isTogglePriceInput, togglePriceInput) => (
                                      <React.Fragment>
                                        <div
                                          onClick={() => {
                                            !(moment(_rec.eff_at).diff(effectMomentDate, 'days') > 0) && togglePriceInput()
                                          }}
                                          style={{padding: '0 10px', height: 26}}
                                          className={classNames(
                                            "flex flex-center gap-2 rounded-md border border-color-50",
                                            moment(_rec.eff_at).diff(effectMomentDate, 'days') > 0 ? 'cursor-not-allow' : 'cursor-pointer',
                                            moment(_rec.eff_at).diff(effectMomentDate, 'days') > 0 ? 'background-200 text-color-300 opacity-50' :
                                              'background-200 text-color-300 hover:border-primary hover:bg-primary-50 hover:text-primary-600'
                                          )}>
                                          <BsInputCursorText size={12}/>
                                          <span className="font-bold text-xs italic">{t('edit')}</span>
                                        </div>
                                        {isTogglePriceInput && (
                                          <Modal
                                            width={600}
                                            className="custom-modal"
                                            onCancel={togglePriceInput}
                                            maskClosable={false}
                                            title={
                                              <div
                                                className="text-center font-bold text-color-000 uppercase tracking-wide">
                                                {_.get(item, 'title')}
                                              </div>
                                            }
                                            destroyOnClose
                                            visible={isTogglePriceInput}
                                            footer={null}>
                                            <ForwardPriceInputForm
                                              {...{
                                                product: item,
                                                productPriceGroup: _rec,
                                                productPriceSet
                                              }}
                                            />
                                          </Modal>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </Toggle>

                                  <ProductPriceGroupCommentComponent
                                    productPriceGroup={_rec}
                                    effectMomentDate={effectMomentDate}>
                                    <div
                                      style={{padding: '0 10px', height: 26, cursor: "pointer"}}
                                      className="flex flex-center rounded-md background-200 text-color-300 hover:bg-primary-50 hover:text-primary-600">
                                                              <span className="font-bold text-xs italic">
                                                                  {t('notes')}
                                                              </span>
                                    </div>
                                  </ProductPriceGroupCommentComponent>
                                </React.Fragment>
                              )}
                            </div>

                            {!!_.get(_rec, 'notes') && (
                              <div className="flex items-center text-xs text-color-400 italic px-1">
                                * {_.get(_rec, 'notes')}
                              </div>
                            )}

                          </div>
                        )
                      }
                    },
                    {
                      visible: productPriceSet.contract_type_cd === 'spot',
                      title: (
                        <div className="flex flex-col truncate">
                          <span className="font-bold text-sm text-color-000">{t('price')}</span>
                          <span className="font-normal text-xs text-color-400 italic leading-tight">
                                                {_.get(productPriceSet, 'price_unit.name', t('price unit'))}/{_.get(productPriceSet, 'unit.name', t('unit'))}
                                            </span>
                        </div>
                      ),
                      dataIndex: 'price',
                      key: 'price',
                      render: (__, record) => {
                        const _rec = form.getFieldValue('data')[record.name];
                        const disable = !!!_rec.eff_at || moment(_rec.eff_at).diff(effectMomentDate, 'days')

                                        return (
                                            <div className="flex flex-col items-center">
                                                <span className="font-bold text-sm text-color-000 whitespace-no-wrap">
                                                    {numeral(_.get(_rec, 'data.0.price')).format("0,0[.]00")}
                                                </span>
                                                <span className="flex items-center gap-2">
                                                    {productPriceSet.contract_type_cd === 'spot' && (
                                                        <>
                                                            <Toggle>
                                                                {( isToggle, toggle ) => (
                                                                    <>
                                                                        <Typography.Text disabled={disable}>
                                                                            <Tooltip  title={disable ? '' : t('edit')} overlayClassName="font-medium text-xs italic">
                                                                                <Avatar
                                                                                    onClick={() => (!disable) && toggle()}
                                                                                    size={26}
                                                                                    className={classNames(
                                                                                        "flex flex-center",
                                                                                        disable ? ' cursor-not-allow' : 'cursor-pointer',
                                                                                        disable ? 'background-200 text-color-300' :
                                                                                            'bg-primary-50 text-primary-600 hover:bg-primary-100'
                                                                                    )}
                                                                                    icon={ <FiEdit3 /> }
                                                                                />
                                                                            </Tooltip>
                                                                        </Typography.Text>
                                                                        <Modal
                                                                            width={600}
                                                                            className="custom-modal"
                                                                            title={(
                                                                                <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                                                                                    {t('edit')}
                                                                                </div>
                                                                            )}
                                                                            maskClosable={false}
                                                                            destroyOnClose
                                                                            onCancel={toggle}
                                                                            visible={isToggle}
                                                                            footer={null}>
                                                                            <PriceDetailSpotPriceEditForm
                                                                                effectMomentDate={effectMomentDate}
                                                                                product={item}
                                                                                productPriceSet={productPriceSet}
                                                                                productPriceGroup={_rec}
                                                                                tableRecord={record}
                                                                                chartAddedData={chartAddedData}
                                                                                setChartAddedData={setChartAddedData}
                                                                                toggle={toggle}
                                                                                isToggle={isToggle}
                                                                                update={update}
                                                                                form={form}
                                                                                handleOnEditSubmitLocationAsSingleRow={handleOnEditSubmitLocationAsSingleRow}
                                                                            />
                                                                        </Modal>
                                                                    </>
                                                                )}
                                                            </Toggle>
                                                            <ProductPriceGroupCommentComponent
                                                                productPriceGroup={_rec}
                                                                effectMomentDate={effectMomentDate}
                                                            />
                                                        </>
                                                    )}
                                                </span>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    visible: false,
                                    dataIndex: 'action',
                                    align: 'right',
                                    width: 5,
                                    key: 'action',
                                    render: (__, record) => {
                                        const _rec = form.getFieldValue('data')[record.name];

                        return (
                          <React.Fragment>

                          </React.Fragment>
                        )
                      }
                    },
                  ].filter(e => e.visible)}
                  footer={() => (
                    <Toggle>
                      {(isToggleNewLocationPriceSet, toggleNewLocationPriceSet) => (
                        <>
                          <div className="text-center mt-3">
                            <Button
                              type="primary"
                              className="button-rounded-md no-border"
                              onClick={() => toggleNewLocationPriceSet()}>
                              {t('new price record by location')}
                            </Button>
                          </div>
                          {isToggleNewLocationPriceSet && (
                            <Modal
                              width={600}
                              className="custom-modal"
                              onCancel={toggleNewLocationPriceSet}
                              maskClosable={false}
                              title={
                                <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                                  {t('new price record by location')}
                                </div>
                              }
                              destroyOnClose
                              visible={isToggleNewLocationPriceSet}
                              footer={null}>
                              <PriceDetailNewPriceForLocationForm
                                effectMomentDate={effectMomentDate}
                                productPriceSet={productPriceSet}
                                toggle={toggleNewLocationPriceSet}
                                isToggle={isToggleNewLocationPriceSet}
                                add={add}
                                handleOnNewSubmitLocation={handleOnNewSubmitLocation}
                              />
                            </Modal>
                          )}
                        </>
                      )}
                    </Toggle>
                  )}
                  expandable={{
                    columnWidth: 5,
                    // onExpand: record=>{
                    //   const _rec = form.getFieldValue('data')[record.name];
                    // },
                    defaultExpandAllRows: true,
                    expandedRowRender: record => {
                      const _rec = form.getFieldValue('data')[record.name];

                      return (
                        <ProductPriceGroupComponent
                          update={update}
                          setUpdate={setUpdate}
                          item={item}
                          productPriceSet={productPriceSet}
                          productPriceGroup={_rec}
                          effectMomentDate={effectMomentDate}
                        />
                      )
                    },
                    rowExpandable: record => productPriceSet.contract_type_cd === 'forward',
                  }}
                />
              </div>
            )}
          </Form.List>
        </Form>
      </div>
    </div>
  )
}

export const ProductPriceSetGroupReportComponent = ({readOnly = true, report, item, productPriceSet}) => {
  const t = useTranslate();

  const {
    result: price_periods = []
  } = useSelector(
    state => {
      return getAsynCacheSelector(
        state,
        "/products/get-price-periods?day=1"
      )
    }
  );

  const [__, dispatch] = useDispatchAsyncActionWithNotify();

  const [form] = Form.useForm();

  const product_price_set_ids = useMemo(
    () =>
      _.reduce(productPriceSet.data || emptyArray,
        function (result, value) {
          result.push(value.product_price_set_id);
          return result;
        }
        , []), [productPriceSet]);

  return (
    <div key={JSON.stringify(product_price_set_ids)}>
      <div className="flex flex-col space-y-2 shadow-lg px-2 py-2 my-1">
        <ProductPriceSetInfo productPriceSet={productPriceSet}/>

        <Form
          className="space-y-3 flex flex-col py-1"
          form={form}
          initialValues={{...productPriceSet}}
          name={"dynamic_form_item" + Date.now()}>
          <Form.List name="data">
            {(fields, {add, remove}) => {
              return (
                <div className="space-y-3">
                  <Table pagination={false}
                         size="small"
                         dataSource={fields}
                         columns={[
                           {
                             visible: true,
                             title: t('location'),
                             dataIndex: 'location',
                             key: 'location',
                             render: (__, record) => {
                               const _rec = form.getFieldValue('data')[record.name];
                               return (
                                 <div>
                                   {_rec.location.location_name}
                                 </div>
                               )
                             }
                           },
                           {
                             visible: productPriceSet.contract_type_cd === 'spot',
                             title: (
                               <div className="flex flex-col truncate leading-none">
                                 <div>{t('price')}</div>
                                 <div className="font-light text-xs">
                                   {
                                     _.get(productPriceSet, 'price_unit.name', t('price unit'))
                                   }/
                                   {
                                     _.get(productPriceSet, 'unit.name', t('unit'))
                                   }
                                 </div>
                               </div>
                             ),
                             dataIndex: 'price',
                             key: 'price',
                             render: (__, record) => {
                               const _rec = form.getFieldValue('data')[record.name];


                               return (
                                 <div className="flex flex-row leading-snug text-sm space-2">

                                   {
                                     productPriceSet.contract_type_cd === 'spot' && (
                                       <div className="px-1">
                                         {
                                           !!!readOnly &&
                                           <EditReportNumberModal
                                             reportId={report.id}
                                             productPriceDetailReportId={_.get(_rec, 'data.0.id')}
                                             fieldName="price"
                                             fieldLabel={t('spot price')}
                                             defaultValue={_.get(_rec, 'data.0.best_price')}
                                           />
                                         }
                                       </div>
                                     )
                                   }

                                   <div className="flex flex-wrap space-x-1 items-center">
                                     <div className="flex justify-end">
                                       {
                                         numeral(_.get(_rec, 'data.0.best_price')).format("0,0[.]00")
                                       }
                                     </div>

                                     {
                                       !!(_.get(_rec, 'data.0.best_price') !== _.get(_rec, 'data.0.price')) && (
                                         <div
                                           className="flex italic text-line-through text-2xs text-color-300 justify-end">
                                           {
                                             numeral(_.get(_rec, 'data.0.price')).format("0,0[.]00")
                                           }
                                         </div>
                                       )
                                     }
                                   </div>
                                 </div>
                               )
                             }
                           },
                         ].filter(e => e.visible)}
                         expandable={{
                           columnWidth: 5,
                           defaultExpandAllRows: true,
                           expandedRowRender: record => {
                             const _rec = form.getFieldValue('data')[record.name];
                             return (
                               <ProductPriceGroupReportComponent
                                 readOnly={readOnly}
                                 report={report}
                                 item={item}
                                 productPriceSet={productPriceSet}
                                 productPriceGroup={_rec}/>
                             )
                           },
                           rowExpandable: record => productPriceSet.contract_type_cd === 'forward',
                         }}
                  />
                </div>
              )
            }}
          </Form.List>
        </Form>
      </div>
    </div>
  )
}
