import { API_ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import React, { useContext } from 'react'
import { AppEntities } from 'views/AppLocals/enums'
import SearchContext from 'views/Search/SearchContext'
import { Null } from 'views/Shared'
import DiscoveryContext from './DiscoveryContext'
import { useFilterHandler } from './FilterHandler'

const recentItemOptions = {
  [AppEntities.KEYWORD]: {
    path: '/keywords/get-recently-items',
  },
  [AppEntities.EVENT]: {
    path: '/events/get-recently-items',
  },
  [AppEntities.CHANNEL]: {
    path: '/channels/get-recently-items',
  },
  [AppEntities.ARTICLE]: {
    path: '/articles/get-recently-items',
  },
  [AppEntities.ORGANIZATION]: {
    path: '/organizations/get-recently-items',
  },
  [AppEntities.PRODUCT]: {
    path: '/products/get-recently-items',
  },
  [AppEntities.DATASET]: {
    path: '/datasets/get-recently-items',
  },
}

export const createFetchRecentByTypeApi = (type) => {
  const current = _.get(recentItemOptions, type)
  if (current?.path) {
    return {
      apiInfo: {
        method: 'GET',
        root: API_ROOT_URL,
        ...(current ?? {}),
      },
    }
  } else {
    return {}
  }
}

const DiscoveryProvider = ({
  item,
  children,
  handleSearch,
  filterComponent,
  contentComponent,
}) => {
  const { searchParams } = useContext(SearchContext)

  const { categories, document_types, music_types, music_authors } = searchParams

  const {
    filter,
    filterRow,
    filterRows,
    setFilter = Null,
  } = useFilterHandler({
    item,
    categories,
    document_types, music_types, music_authors
  })

  return (
    <DiscoveryContext.Provider
      value={{
        item,
        filter,
        setFilter,
        filterRow,
        filterRows,
        handleSearch,
        filterComponent,
        contentComponent,
      }}>
      {children}
    </DiscoveryContext.Provider>
  )
}

export default DiscoveryProvider
