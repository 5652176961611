import {Select} from 'antd'
import _ from 'lodash'
import React, {useMemo} from 'react'
import {useAppConfig} from "../../../modules/local";

export const SelectCountries = ({
  value,
  placeholder,
  onChange
}) => {

  const {countries = []} = useAppConfig();

  const options = useMemo(() => {
    return _.flatten(countries).map(
      (country, i) => (
        <Select.Option
          key={i}
          common_name={
            country.common_name
          }
          value={country.id}>
          {country.common_name}
        </Select.Option>
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries])
  return (
    <Select
      value={value}
      showSearch
      style={{ display: 'block' }}
      loading={_.isEmpty(countries)}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      // onFocus={() => {
      //   if (!success) {
      //     handleAsyncAction()
      //   }
      // }}
      filterOption={(input, option) =>
        option.props.common_name
          .toLowerCase()
          .indexOf(
            input.toLowerCase()
          ) >= 0
      }>
      {options}
    </Select>
  )
}
