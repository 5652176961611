import {Modal} from 'antd'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import React from 'react'
import {FaRegAddressCard} from 'react-icons/fa'
import {Null, renderSelf} from 'views/Shared'
import {renderIf} from "../../Shared";

export const willGoTo = ({
                           url,
                           item,
                           icon,
                           title,
                           okText,
                           content,
                           cancelText,
                           onOk = Null,
                           okButtonProps,
                           ...props
                         }) => {
  if (item) {
    Modal.confirm({
      icon,
      title,
      okText,
      content,
      cancelText,
      okButtonProps,
      okType: 'primary',
      onOk: () => {
        onOk(_.isEmpty(url) ? getLinkToDetail(item) : url)
      },
      ...props,
    })
  }
}

export const confirmOnSuccess = ({
                                   icon,
                                   title,
                                   content,
                                   description,
                                   translate = renderSelf,
                                   Icon = FaRegAddressCard,
                                   ...props
                                 }) =>
  Modal.confirm({
    icon,
    okButtonProps: {
      hidden: true,
    },
    title: (
      <div className="flex items-center gap-3">
        <Icon
          size={20}
          className="text-green-300"
        />
        <span className="font-bold text-green-700 italic">
          {_.isString(title) ? translate(title) : title}
        </span>
      </div>
    ),
    content: (
      renderIf(
        _.some([content, description]),
        <div className="flex flex-col gap-2 ml-3">
          {renderIf(
            content,
            <div className="font-medium text-color-200">
              {_.isString(content) ? translate(content) : content}
            </div>
          )}
          {renderIf(
            description,
            <div className="text-sm text-color-500 italic">
              {_.isString(description) ? translate(description) : description}
            </div>
          )}
        </div>
      )
    ),
    cancelText: translate('close'),
    cancelButtonProps: {
      className: 'rounded-lg no-shadow no-text-shadow no-border',
    },
    ...props,
  })
