import ReactPlayer from 'react-player'

const createValidate = t => ({
  title,
  photos,
  video_url
}) => {
  const error = {}
  if (!title || title.length < 3) {
    error.title = t(
      'product must have at least 3 character'
    )
  }
  if (!(photos && photos[0])) {
    error.photos = t(
      'product must have at least one image'
    )
  }
  if (
    video_url &&
    video_url.length &&
    !ReactPlayer.canPlay(video_url)
  ) {
    error.video_url = t(
      'video url is not valid'
    )
  }

  return error
}
export default createValidate
