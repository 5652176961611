import {Avatar} from 'antd'
import {organizationModel} from 'apis/model'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {IoLinkOutline,} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {gPaths} from 'views/AppLocals/configs'
import {getLocationLines} from 'views/Shared'
import {SubDomainContext} from 'views/SubDomain/SubDomainContext'
import {BiShieldQuarter} from "react-icons/bi";
import {RiHome4Fill} from "react-icons/ri";
import {MdLocationPin} from "react-icons/md";
import {TbNumber} from "react-icons/tb";
import {FaWarehouse} from "react-icons/fa6";

const ActionButtons = ({item}) => (
  <div className="hidden md:flex flex-row space-x-1">
    <SubDomainContext.Consumer>
      {({isSubDomain}) => (
        <Link
          to={
            isSubDomain
              ? gPaths.organization_domain
              : organizationModel.getLinkToDetail(item)
          }
          className="w-10 h-10 flex flex-center background-100 hover:background-200 rounded-full text-color-500 hover:text-color-100">
          <RiHome4Fill/>
        </Link>
      )}
    </SubDomainContext.Consumer>
  </div>
)

const HeaderGroupItems = ({item}) => {
  const t = useTranslate()
  return (
    <div className="flex flex-col text-xs md:text-sm italic">
      {!!_.get(item, 'locations[0]') && (
        <div className="flex items-center">
          <MdLocationPin className="text-primary mr-2"/>
          <span className="text-color-300">{getLocationLines(item)}</span>
        </div>
      )}
      {item.tax_code && (
        <div className="flex items-center">
          <TbNumber className="text-primary mr-2"/>
          <span className="text-color-300">
            {`${t('tax code')} : `}
            <b className="font-bold text-color-100">{item.tax_code}</b>
          </span>
        </div>
      )}
      {Number(item.certified) > 0 && (
        <div className="flex items-center">
          <BiShieldQuarter className="text-primary mr-2"/>
          <span className="text-color-300">
            <b className="font-bold text-color-100">{`${t('verified')} `}</b>
            {t('provider')}
          </span>
        </div>
      )}
    </div>
  )
}

const SubDomainHeader = ({item}) => {
  if (!Boolean(item.sub_domain)) return null
  return (
    <SubDomainContext.Consumer>
      {({isSubDomain}) =>
        Boolean(item.sub_domain) && (
          <a
            href={[
              window.location.protocol,
              '//',
              !!isSubDomain ? '' : item.sub_domain + '.',
              window.location.host,
            ].join('')}
            className="flex items-center gap-2 text-primary">
            <IoLinkOutline/>
            <span className="text-xs md:text-sm italic">
              {[
                !!isSubDomain ? '' : item.sub_domain + '.',
                window.location.host,
              ].join('')}
            </span>
          </a>
        )
      }
    </SubDomainContext.Consumer>
  )
}

const OrganizationSettingHeader = ({organization}) => {
  const t = useTranslate()

  const {isSm} = useContext(LayoutContext)

  const item = organization || {}

  const avatar = organizationModel.getFullAvatarUrl(item)

  if (_.isEmpty(item)) return null

  return (
    <div className="py-3 w-full container mx-auto">
      <div className="flex items-center gap-3">
        <Avatar
          size={isSm ? 65 : 80}
          icon={<FaWarehouse size={isSm ? 45 : 60} className="text-color-400"/>}
          src={avatar}
          className="flex flex-center background-100 rounded-lg object-cover"
        />
        <div className="flex flex-col flex-1">
          <Link
            className="text-base md:text-xl text-color-000 font-bold mb-2 no-underline"
            title={item.title}
            to={organizationModel.getLinkToDetail(item)}>
            {item.title}
          </Link>
          <SubDomainHeader {...{item}} />
          <HeaderGroupItems {...{item}} />
        </div>
        <ActionButtons {...{item}} />
      </div>
    </div>
  )
}

export default OrganizationSettingHeader
