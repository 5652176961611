import React from "react";

export const TechnicalAdditives = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 511.999 511.999"
		>
			<g fillRule="evenodd" clipRule="evenodd">
				<path
					fill="#ffeed6"
					d="M231.898 495.3l-51.76-66.955 102.671-102.671c17.49-17.489 46.092-17.489 63.581 0l163.87 163.871c3.288 3.288 1.588 5.754-2.381 5.754H231.898z"
				></path>
				<path
					fill="#ffe5c1"
					d="M301.885 314.364c15.279-4.45 32.514-.68 44.504 11.31L510.26 489.545c3.288 3.288 1.588 5.754-2.381 5.754H482.48c3.997 0 5.669-2.466 2.381-5.754l-163.87-163.871a44.61 44.61 0 00-19.106-11.31z"
				></path>
				<path
					fill="#f9f7f8"
					d="M1.781 489.545l99.978-99.978c10.913-10.885 28.743-10.885 39.628 0l38.749 38.778.68 2.807 30.727 43.427 21.062 20.721H4.162c-4.365 0-5.527-2.58-2.381-5.755z"
				></path>
				<path
					fill="#ebe8fa"
					d="M108.874 384.409c10.517-5.301 23.754-3.601 32.514 5.159L247.12 495.3h-25.399L116.018 389.567a28.42 28.42 0 00-7.144-5.158z"
				></path>
				<path
					fill="#80b4fb"
					d="M181.101 138.106L388.767 18.201c4.791-2.75 8.391-1.701 11.112 2.976l13.237 22.96c7.002 12.132 2.863 27.666-9.297 34.668-9.694 5.613-21.516 4.082-29.48-2.92-4.167-3.656-7.568-5.045-12.246-2.296L198.42 168.096z"
				></path>
				<path
					fill="#bed8fb"
					d="M184.814 135.98l38.891 67.351c20.495 35.518 8.22 81.354-27.297 101.849-35.519 20.494-81.326 8.221-101.821-27.298L59.75 217.561c-2.325-3.997-1.219-8.674 2.976-11.111z"
				></path>
				<path
					fill="#9dc6fb"
					d="M184.814 135.98l38.891 67.351c20.495 35.518 8.22 81.354-27.297 101.849-15.591 9.014-33.194 11.679-49.776 8.844a73.486 73.486 0 0024.378-8.844c35.518-20.495 47.792-66.331 27.297-101.849l-32.542-56.353z"
				></path>
				<path
					fill="#61a2f9"
					d="M376.777 25.146l11.99-6.945c4.791-2.75 8.391-1.701 11.112 2.976l13.237 22.96c7.002 12.132 2.863 27.666-9.297 34.668-8.136 4.705-17.773 4.365-25.37 0 12.132-7.002 16.299-22.535 9.27-34.668z"
				></path>
			</g>
			<path
				fill="#ffbc85"
				d="M421.479 473.813c-4.224 0-7.625-3.401-7.625-7.597a7.609 7.609 0 017.625-7.625h20.863c4.195 0 7.625 3.401 7.625 7.625 0 4.195-3.43 7.597-7.625 7.597zM305.288 363.12a7.61 7.61 0 01-7.626-7.625c0-4.195 3.402-7.597 7.626-7.597h20.862c4.224 0 7.626 3.402 7.626 7.597a7.61 7.61 0 01-7.626 7.625zm37.785 39.6c-4.224 0-7.625-3.401-7.625-7.597a7.609 7.609 0 017.625-7.625h20.863c4.195 0 7.625 3.401 7.625 7.625 0 4.195-3.43 7.597-7.625 7.597zm-75.571 0c-4.224 0-7.625-3.401-7.625-7.597a7.609 7.609 0 017.625-7.625h20.863a7.609 7.609 0 017.625 7.625c0 4.195-3.401 7.597-7.625 7.597zm113.329 35.547a7.597 7.597 0 01-7.597-7.597c0-4.224 3.401-7.625 7.597-7.625h20.892c4.195 0 7.625 3.402 7.625 7.625 0 4.195-3.43 7.597-7.625 7.597zm-151.116 0c-4.224 0-7.625-3.401-7.625-7.597a7.609 7.609 0 017.625-7.625h20.863a7.61 7.61 0 017.626 7.625c0 4.195-3.402 7.597-7.626 7.597zm75.573 0c-4.224 0-7.626-3.401-7.626-7.597a7.61 7.61 0 017.626-7.625h20.862a7.61 7.61 0 017.626 7.625c0 4.195-3.402 7.597-7.626 7.597zm-34.952 35.546c-4.195 0-7.626-3.401-7.626-7.597 0-4.224 3.431-7.625 7.626-7.625h20.862a7.61 7.61 0 017.626 7.625c0 4.195-3.402 7.597-7.626 7.597zm75.572 0c-4.224 0-7.625-3.401-7.625-7.597a7.609 7.609 0 017.625-7.625h20.863a7.609 7.609 0 017.625 7.625c0 4.195-3.401 7.597-7.625 7.597z"
			></path>
			<path
				fill="#d0cce3"
				d="M148.928 473.898c-4.195 0-7.625-3.401-7.625-7.625 0-4.195 3.43-7.597 7.625-7.597h20.863c4.224 0 7.626 3.401 7.626 7.597a7.61 7.61 0 01-7.626 7.625zm-40.621-35.546a7.61 7.61 0 01-7.625-7.625c0-4.195 3.401-7.597 7.625-7.597h20.863c4.224 0 7.626 3.402 7.626 7.597a7.61 7.61 0 01-7.626 7.625zm-34.951 35.546c-4.195 0-7.625-3.401-7.625-7.625 0-4.195 3.43-7.597 7.625-7.597h20.891a7.597 7.597 0 017.597 7.597c0 4.224-3.402 7.625-7.597 7.625z"
			></path>
		</svg>
	);
}

export default TechnicalAdditives;
