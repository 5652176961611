import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../LoadingPage";

const UserCtrlLazy = Loadable({
  loader: () => import('./UserCtrl'),
  loading: LoadingPage
})

const UserCtrl = (props) => (
  <UserCtrlLazy {...props} />
)

export default UserCtrl
