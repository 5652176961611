import {Tooltip, Typography} from 'antd'
import React, {useRef} from 'react'
import {
  AiOutlineDeleteColumn,
  AiOutlineDeleteRow,
  AiOutlineInsertRowAbove,
  AiOutlineInsertRowBelow,
  AiOutlineInsertRowLeft,
  AiOutlineInsertRowRight,
} from 'react-icons/ai'
import {useFocused, useSelected, useSlateStatic} from 'slate-react'
import {renderElse, renderIf} from 'views/Shared'
import {isBlockActive, updateNode} from 'views/SlateEditor/functions'
import {
  deleteColumn,
  deleteRow,
  insertColumnLeft,
  insertColumnRight,
  insertRowAbove,
  insertRowBelow,
} from 'views/SlateEditor/functions/table'
import {useResizeHeaders} from 'views/SlateEditor/useResizeHeaders'
import useTranslate from '../../../../modules/local/useTranslate'
import {ElementTypes} from '../../types'
import {ToolbarButton} from '../Toolbar'
import './Table.css'
import {BiMessageSquareEdit} from "react-icons/bi";

const ConfigTable = ({table, rows = 0, height = 32, groups = []}) => {
  const t = useTranslate()
  const editor = useSlateStatic()
  const isActive = isBlockActive(editor, ElementTypes.TABLE)
  return renderIf(
    isActive,
    <div
      contentEditable={false}
      style={{
        height,
        right: 0,
        top: -height,
      }}
      className="absolute flex flex-wrap items-center gap-1 px-1 bg-white shadow z-50">
      <Tooltip title={t('insert row above')}>
        <ToolbarButton
          disabled={!isActive}
          Icon={AiOutlineInsertRowAbove}
          onClick={(event) => {
            event.preventDefault()
            insertRowAbove(editor, {columns: groups.length})
          }}
        />
      </Tooltip>
      <Tooltip title={t('insert row below')}>
        <ToolbarButton
          disabled={!isActive}
          Icon={AiOutlineInsertRowBelow}
          onClick={(event) => {
            event.preventDefault()
            insertRowBelow(editor, {columns: groups.length})
          }}
        />
      </Tooltip>
      <Tooltip title={t('insert column left')}>
        <ToolbarButton
          disabled={!isActive}
          Icon={AiOutlineInsertRowLeft}
          onClick={(event) => {
            event.preventDefault()
            insertColumnLeft(editor, {table, groups})
          }}
        />
      </Tooltip>
      <Tooltip title={t('insert column right')}>
        <ToolbarButton
          disabled={!isActive}
          Icon={AiOutlineInsertRowRight}
          onClick={(event) => {
            event.preventDefault()
            insertColumnRight(editor, {table, groups})
          }}
        />
      </Tooltip>
      <Tooltip title={t('delete row')}>
        <ToolbarButton
          Icon={AiOutlineDeleteRow}
          onClick={(event) => {
            event.preventDefault()
            if (rows > 1) {
              deleteRow(editor)
            }
          }}
          disabled={!isActive || rows < 2}
        />
      </Tooltip>
      <Tooltip title={t('delete column')}>
        <ToolbarButton
          Icon={AiOutlineDeleteColumn}
          onClick={(event) => {
            event.preventDefault()
            if (groups.length > 1) {
              deleteColumn(editor, {table, groups})
            }
          }}
          disabled={!isActive || groups.length < 2}
        />
      </Tooltip>
    </div>
  )
}

export const TableElement = ({
                               children,
                               element,
                               attributes,
                               readOnly = false,
                             }) => {
  const t = useTranslate()
  const table_ref = useRef()
  const editor = useSlateStatic()
  const groups = Array.from(element?.groups ?? [])
  const update = (id, value) => {
    let new_headers = groups.reduce((prev, current) => {
      if (current?.id === id) {
        return [
          ...prev,
          {
            ...current,
            text: value,
          },
        ]
      }
      return [...prev, current]
    }, [])
    updateNode({groups: new_headers})(editor, element)
  }

  useResizeHeaders(
    table_ref.current,
    (id) => {
      const th = document.getElementById(id)
      updateNode({
        groups: groups.reduce((prev, current) => {
          if (current?.id == id) {
            return [
              ...prev,
              {
                ...(current ?? {}),
                width: th.offsetWidth,
              },
            ]
          }
          return [...prev, current]
        }, []),
      })(editor, element)
    },
    {dependencies: [groups]}
  )

  return (
    <div className="relative">
      {renderElse(
        readOnly,
        <ConfigTable
          table={element}
          groups={groups}
          rows={Array.from(children ?? []).length}
        />
      )}
      <table
        ref={table_ref}
        className="ResizableTable">
        {renderIf(
          groups.length,
          <thead contentEditable={false}>
          <tr>
            {groups.map(({id, text, ...rest}, index) => (
              <th
                id={id}
                key={index}
                {...rest}>
                {readOnly ? (
                  text
                ) : (
                  <Typography.Text
                    className="EditableHeader flex justify-center custom-typography-text"
                    editable={{
                      // autoSize: true,
                      icon: <BiMessageSquareEdit size={17} className="ml-2"/>,
                      tooltip: t('click here to edit'),
                      onChange: (text) => {
                        update(id, text)
                      },
                    }}>
                    {text}
                  </Typography.Text>
                )}
              </th>
            ))}
          </tr>
          </thead>
        )}
        <tbody {...attributes}>{children}</tbody>
      </table>
    </div>
  )
}

export const TableRowElement = ({children, attributes}) => (
  <tr {...attributes}>{children}</tr>
)

export const TableCellElement = ({element, children, attributes}) => {
  const focused = useFocused()
  const selected = useSelected()
  return (
    <td
      {...attributes}
      align={element?.align}
      style={{
        backgroundColor: selected && focused ? 'rgba(246, 246, 246, 0.8)' : undefined,
        padding: '3px 5px',
      }}>
      {children}
    </td>
  )
}
