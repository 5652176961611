import React from 'react'
import { compose } from 'recompose'
import { notFoundOnError } from '../composers'
import createModuleLayout from '../factory/createModuleLayout'
import EventSubscription from './components/EventSubscription'
import EventContext from './EventContext'

const EventLayout = ({ children, ...props }) =>
  createModuleLayout({
    ...props,
    bottom: <EventSubscription />,
  })(children)

export default compose(notFoundOnError(EventContext.Consumer))(EventLayout)
