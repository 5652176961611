import {Button, Modal} from 'antd'
import {getId, getType} from 'apis/model/base'
import {ROOT_URL} from 'envs/_current/config'
import {Formik} from 'formik'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useMemo} from 'react'
import {AppEntities} from 'views/AppLocals/enums'
import {Null} from 'views/Shared'
import {target_content_options} from '../../target'
import {ObjectSelect} from "../../../../components/RichSlateEditor/utils/useEditorUtils";
import {GoMention} from "react-icons/go";

function withPath(path) {
  return path ?? ROOT_URL
}

const ModalContent = ({type, onCancel, onSubmit = Null}) => {
  const t = useTranslate()

  const transform = useCallback((values) => {
    let url,
      content,
      rows = [],
      title = ''
    const {target, name_option} = values
    const mention_options = Array.from(values?.mention_options ?? [])

    const type = getType(target)

    title = target?.title
    content = ''
    const link_attached = mention_options.includes(
      target_content_options.attach_link.value
    )

    switch (type) {
      case AppEntities.MUSIC:
        if (link_attached) {
          url = withPath(getLinkToDetail(target))
        }
        break
      default:
        break
    }

    const [mention_id, mention_type] = [getId(target), getType(target)]

    return {
      url,
      rows,
      title,
      content,
      mention_type,
      data: {
        owner_id: mention_id,
        owner_type: mention_type,
      },
    }
  }, [])

  return (
    <div className="flex flex-col gap-3 mt-6">
      <div className="flex items-center gap-3 mb-1">
        <GoMention
          size={20}
          className="anticon text-primary-300"
        />
        <p className="font-medium text-color-200 italic">
          {t(
            'Search and select based on the title of the content you want to reference'
          )}
        </p>
      </div>
      <Formik
        enableReinitialize={true}
        onSubmit={(values, {setSubmitting}) => {
          setSubmitting(true)
          onSubmit(
            _.omitBy(transform(values), _.isUndefined, () => {
              setSubmitting(false)
            })
          )
        }}
        initialValues={{
          mention_type: undefined,
          target: undefined,
          name_option: target_content_options.name.value,
          mention_options: [
            target_content_options.attach_link.value,
          ],
        }}>
        {({values, handleChange, setFieldValue, handleSubmit}) => {
          const {
            name_option,
            target,
            mention_options,
          } = values ?? {}
          return (
            <React.Fragment>
              <div className="flex flex-col gap-1">
                <span className="text-xs text-color-300 italic">
                  {t('choose')}
                </span>
                <ObjectSelect
                  placeholder={t('title')}
                  type={type}
                  onChange={(item, option) => {
                    setFieldValue('target', option?.owner ?? null)
                  }}
                />
              </div>

              <div
                className="flex gap-3"
                style={{marginTop: 24}}>
                <div className="flex-1"/>
                <Button
                  type="default"
                  onClick={onCancel}
                  className="button-rounded-lg no-border">
                  {t('cancel')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    handleSubmit()
                  }}
                  disabled={_.isEmpty(values)}
                  className="button-rounded-lg no-border">
                  {t('add content')}
                </Button>
              </div>
            </React.Fragment>
          )
        }}
      </Formik>
    </div>
  )
}

export const useMentions = ({
                              type = 'codi_document_row',
                              visible = false,
                              onSubmit = Null,
                              onCancel = Null,
                              ...props
                            }) => {
  const modal = useMemo(
    () => (
      <Modal
        width={600}
        footer={null}
        open={visible}
        onCancel={onCancel}
        maskClosable={false}
        destroyOnClose={true}
        className="NoFooter custom-modal"
        {...props}>
        <ModalContent
          type={type}
          onCancel={onCancel}
          onSubmit={(values, callback = Null) => {
            onSubmit(values)
            callback()
            onCancel()
          }}
        />
      </Modal>
    ),
    [visible]
  )
  return {
    modal,
  }
}
