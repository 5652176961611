import { Button, Descriptions } from 'antd'
import { organization_Create_Api } from 'apis'
import FieldsFactory from 'components/form/FieldsFactory'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import React, { useCallback, useContext, useMemo } from 'react'
import { Null } from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import { AppEntities } from '../enums'
import { notifyOnError } from '../factory/createErrorEvent'
import createInitialValues from '../factory/createInitialValues'
import {
  NotificationActionTypes,
  successNotify,
} from '../factory/createNotification'
import createStepForm, {
  StepActionTypes,
  StepFormContext,
} from '../factory/createStepForm'
import createValidationSchema from '../factory/createValidationSchema'
import gModel from '../gModel'
import { FlexCol } from '../Templates/ItemTemplate'
import { organizationStepFormSchema } from './organizationSchemas'

const Stage = ({ Wrapper = 'div' }) => {
  const { currentStep } = useContext(StepFormContext)

  if (_.isEmpty(currentStep)) return null

  const { formSchema } = currentStep

  return (
    <Wrapper className="space-y-3 mt-2">
      <FieldsFactory formSchema={formSchema} />
    </Wrapper>
  )
}

const SummaryStage = React.memo(() => {
  const t = useTranslate()

  const { history, handleGoBack = Null } = useContext(NavigationContext)

  const { item } = useContext(StepFormContext)

  const redirect = () => {
    if (_.isEmpty(item)) {
      handleGoBack()
    } else {
      history.push(gModel.getLinkToSetting(item))
    }
  }

  return (
    <FlexCol className="space-y-3 items-center justify-center">
      <div className="font-bold text-3xl text-green-500 uppercase">
        {t('congratulation')}
      </div>

      <span className="font-medium text-lg text-primary">
        {t('You have completed the steps of creating organization')}
      </span>

      <Descriptions>
        <Descriptions.Item
          label={<span className="font-semibold">{t('note')}</span>}>
          {t('note create description')}
        </Descriptions.Item>
      </Descriptions>

      <Button
        type="primary"
        className="no-shadow no-text-shadow border-none rounded-full"
        onClick={redirect}>
        {t('go to setting')}
      </Button>
    </FlexCol>
  )
})

const stages = [
  {
    index: 0,
    name: 'information',
    title: 'information',
    component: Stage,
    formSchema: organizationStepFormSchema.infoStage,
    actions: [
      {
        label: 'create',
        value: StepActionTypes.SUBMIT,
        type: 'primary',
        requireds: ['title'],
      },
    ],
  },
  {
    index: 1,
    name: 'summary',
    title: 'summary',
    finished: true,
    component: SummaryStage,
  },
]

const CreateOrganization = () => {
  const translate = useTranslate()

  const initialValues = useMemo(
    () => createInitialValues(AppEntities.ORGANIZATION),
    []
  )

  const validationSchema = useMemo(
    () =>
      createValidationSchema(AppEntities.ORGANIZATION, CRUD.CREATE)(translate),
    [translate]
  )

  const renderHeader = useCallback(
    () => (
      <div className="flex justify-center uppercase font-bold text-xl md:text-3xl text-color-000 my-2">
        {translate('create organization')}
      </div>
    ),
    [translate]
  )

  const onPreSubmit = ({ owner, ...values }) => ({
    ...values,
  })

  const onSuccess = (__, { response }, forward = Null) => {
    successNotify(NotificationActionTypes.CREATE, translate)
    forward(_.get(response, 'response.data'))
  }

  const content = createStepForm(
    {
      onSuccess,
      onError: notifyOnError(translate),
      apiInfo: organization_Create_Api,
    },
    {
      onPreSubmit,
      steps: stages,
      initialValues,
      validationSchema,
      Header: renderHeader,
      validateOnMount: true,
    }
  )

  return (
    <FlexCol>
      <div className="h-8" />
      {content}
    </FlexCol>
  )
}

export default CreateOrganization
