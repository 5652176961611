import { event_getById_Api } from 'apis/event'
import { getId, getType } from 'apis/model/base'
import { eventSchema } from 'apis/schema/event'
import { UseHook } from 'components/UseHook'
import _ from 'lodash'
import useAsyncWithCache from 'modules/asyncCache/useAsyncWithCache'
import useTranslate from 'modules/local/useTranslate'
import React, { useCallback, useContext, useState } from 'react'
import {
  IoIosLaptop,
  IoIosPeople,
  IoMdAlarm,
  IoMdCloudOutline,
} from 'react-icons/io'
import { Switch } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import RouteWithSubRoutes from 'routes/RouteWithSubRoutes'
import ServerContainer from 'views/Server/ServerContainer'
import { getResponseItem, LoadingWrapper, Null } from 'views/Shared'
import { EventActionTypes } from '.'
import { gPaths } from '../configs'
import { AppEntities } from '../enums'
import { ItemContextMenu } from '../factory/createContextMenu'
import { useJoinAction } from '../factory/createStatusBar'
import { bindQueryParam, mapPathParam } from '../functions/routerHelper'
import { PermissionEntity } from '../Permissions/PermissionEntity'
import EventEntity from './EventEntity'
import { routes } from './eventRouteEntities'

const EventContext = React.createContext({
  item: {},
})

const event_context_menu = {
  join: {
    label: 'join',
    key: EventActionTypes.JOIN,
    value: EventActionTypes.JOIN,
  },
  leave: {
    label: 'leave',
    key: EventActionTypes.LEAVE,
    value: EventActionTypes.LEAVE,
  },
  invite: {
    label: 'invite',
    key: EventActionTypes.INVITE,
    value: EventActionTypes.INVITE,
  },
}

export const EventTypes = Object.freeze({
  MEETING: {
    value: 'meeting',
    label: 'meeting',
    icon: <IoIosPeople />,
    required_fields: ['location_name'],
  },
  WEBINAR: {
    value: 'webinar',
    label: 'online',
    icon: <IoIosLaptop />,
    required_fields: ['external_link'],
  },
  HYBRID: {
    value: 'hybrid',
    label: 'hybrid event',
    icon: <IoMdCloudOutline />,
    required_fields: ['location_name'],
  },
  REMINDER: {
    value: 'reminder',
    label: 'reminder',
    icon: <IoMdAlarm />,
    required_fields: ['external_link'],
  },
})

export const EventIntervalTypes = Object.freeze({
  NONE: { value: 'N', label: 'none' },
  DAYLY: { value: 'D', label: 'daily' },
  WEEKLY: { value: 'W', label: 'weekly' },
  MONTHLY: { value: 'M', label: 'monthly' },
})

const EventContextMenu = ({ onSelect }) => {
  const { event } = useContext(EventContext)

  const items = [
    event?.join_status ? event_context_menu.leave : event_context_menu.join,
  ].filter(Boolean)

  return (
    <ItemContextMenu
      item={event}
      items={items}
      onMenuSelect={onSelect}
    />
  )
}

const EventProviderContainer = ({
  item,
  children,
  initialCurrent,
  routeEntities = [],
  routePathField = 'path',
  ...props
}) => {
  const t = useTranslate()

  const id = getId(item) ?? item

  const [current, setCurrent] = useState(initialCurrent)

  const apiInfo = event_getById_Api

  const cacheId = mapPathParam(apiInfo.path, { id })

  const { response, isLoading } = useAsyncWithCache({
    cacheId,
    apiInfo,
    query: bindQueryParam({ id }),
  })

  const isSucceed = Number(_.get(response, 'status')) === 200

  const cache = getResponseItem(response)

  const error = getResponseItem(response, 'error')

  const errorMessage = _.get(response, 'errorMessages.error')

  const error_code = _.get(response, 'errorMessages.error_code')

  const status = {
    title: getResponseItem(response, 'status'),
    description: errorMessage,
  }

  const defaultRoute = _.first(routeEntities)

  const getDefaultAction = (current) => {
    switch (current) {
      case gPaths.event:
        return _.get(defaultRoute, routePathField)
      default:
        return null
    }
  }

  const isValidRoute = useCallback(
    (route) => {
      return routeEntities
        .map((item) => _.get(item, routePathField))
        .includes(route)
    },
    [routeEntities, routePathField]
  )

  const renderHeader = useCallback((action, isModal) => null, [])

  const { join, leave } = useJoinAction({
    id,
    type: AppEntities.EVENT,
  })

  const handleAction = (action, params) => {
    switch (action) {
      case EventActionTypes.SHOW_SUBSCRIBE_MODAL:
        setCurrent(routes.register.path)
        break
      default:
        break
    }
  }

  const createContextMenu = ({ setCurrent = Null }) => (
    <EventContextMenu
      onSelect={(key, item) => {
        switch (key) {
          case EventActionTypes.JOIN:
            join()
            break
          case EventActionTypes.LEAVE:
            leave()
            break
          case EventActionTypes.INVITE:
            setCurrent(key)
            break
          default:
            break
        }
      }}
    />
  )

  return (
    <LoadingWrapper isLoading={!item}>
      <EventEntity
        key={cacheId}
        item={id}>
        {(event) => (
          <ServerContainer
            key={id}
            id={id}
            item={event}
            schema={eventSchema}
            type={getType(event)}
            routes={[routes.share]}
            initialCurrent={initialCurrent}
            createMenuContext={createContextMenu}>
            <PermissionEntity item={event}>
              <EventContext.Provider
                value={{
                  error,
                  cache,
                  status,
                  event,
                  isSucceed,
                  isLoading,
                  error_code,
                  renderHeader,
                  errorMessage,
                  defaultRoute,
                  routeEntities,
                  routePathField,
                  current,
                  setCurrent,
                  isValidRoute,
                  handleAction,
                  getDefaultAction,
                  ...props,
                }}>
                <React.Fragment>
                  {children}
                  {current && (
                    <Switch
                      location={{
                        hash: '',
                        key: current,
                        pathname: current,
                      }}>
                      {[routes.register].map((route, i) => (
                        <RouteWithSubRoutes
                          key={i}
                          {...route}
                        />
                      ))}
                    </Switch>
                  )}
                </React.Fragment>
              </EventContext.Provider>
            </PermissionEntity>
          </ServerContainer>
        )}
      </EventEntity>
    </LoadingWrapper>
  )
}

export const EventProvider = (props) => (
  <UseHook
    hook={useSearchParam}
    deps={['initialCurrent']}>
    {(initialCurrent) => (
      <EventProviderContainer
        {...props}
        initialCurrent={initialCurrent}
      />
    )}
  </UseHook>
)

export default EventContext
