import ContentLayout from 'components/layouts/Default/ContentLayout'
import React from 'react'
import { Null } from 'views/Shared'

const DiscoveryExplorerLayout = ({
  menu,
  Header = Null,
  children,
}) => (
  <ContentLayout
    menu={menu}
    renderRight={Null}
    renderHeader={() => <Header />}>
    {children}
  </ContentLayout>
)

export default DiscoveryExplorerLayout
