import {Select} from 'antd'
import {location_getStaticCountryById_Api} from 'apis'
import _ from 'lodash'
import useAsyncWithCache from 'modules/asyncCache/useAsyncWithCache'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useLocalStorage} from 'react-use'

let provinceindexes = {
  1: 99,
  79: 99,
  74: 90,
  31: 90
}

export const useProvinces = ({
  defaultValue,
  placeholder,
  onChange,
  className = 'block',
  countryId = '237',
  ...props
} = {}) => {
  const [
    indexes,
    setindexes
  ] = useState(provinceindexes)
  const {
    isLoading,
    action,
    response,
    success,
    handleAsyncAction
  } = useAsyncWithCache({
    cacheId: `location_getStaticCountryById_Api_{id:${countryId}}`,
    apiInfo: location_getStaticCountryById_Api,
    query: {
      ':id': countryId
    }
  })
  const [
    data,
    // = countriesJSon.VN.provinces
    setData
  ] = useLocalStorage(
    location_getStaticCountryById_Api.path
  )
  useEffect(() => {
    if (!data && handleAsyncAction) {
      handleAsyncAction()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (success) {
      setData(response.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])
  const provinceList = useMemo(
    () =>
      _.get(
        data,
        'provinces',
        []
      )
        .map(p => ({
          label: p.name,
          value: '' + p.id
        }))
        .sort(
          (a, b) =>
            (indexes[b.value] || 0) -
            (indexes[a.value] || 0)
        ),
    [data, indexes]
  )

  return {
    provinceList,
    data,
    handleAsyncAction,
    onFocus: useCallback(() => {
      if (!data) {
        handleAsyncAction()
      }
    }, [data, handleAsyncAction]),
    onChange: value => {
      provinceindexes = {
        ...provinceindexes,
        [value]:
          (provinceindexes[value] ||
            0) + 1
      }
      setindexes(provinceindexes)
      onChange && onChange(value)
    },
    isLoading: action && isLoading,
    filterOption: useCallback(
      (input, option) => {
        if (
          !Boolean(input) ||
          input === ''
        ) {
          return provinceindexes[
            option.value
          ]
        }
        return (
          option.label
            .toLowerCase()
            .indexOf(
              input.toLowerCase()
            ) >= 0
        )
      },
      []
    )
  }
}
export const SelectProvinces = ({
                                  // value = {},
  countryId,
  children,
  defaultValue,
  placeholder,
  onChange,
  className = 'block',
  ...props
}) => {
  const {
    // value = {},
    onChange: handleChange,
    isLoading,
    onFocus,
    provinceList,
    filterOption
  } = useProvinces({
    countryId,
    defaultValue,
    placeholder,
    onChange,
    className
  });
  if (children)
    return children({
      loading: isLoading,
      onChange: handleChange,
      onFocus,
      filterOption,
      options: provinceList
    })
  return (
    <Select
      allowClear={true}
      defaultValue={defaultValue}
      className={className}
      loading={isLoading}
      placeholder={placeholder}
      onChange={handleChange}
      onFocus={onFocus}
      options={provinceList}
      filterOption={filterOption}
      {...props}
    />
  )
}
