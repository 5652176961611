import {DownOutlined, RightOutlined} from '@ant-design/icons'
import Toggle from 'components/Toggle'
import React from 'react'
import {BsCaretDownFill, BsCaretRightFill} from "react-icons/bs";

export const ToggleCollapseView = ({
  defaultValue = true,
  title,
  children
}) => (
  <Toggle defaultValue={defaultValue}>
    {(isToggle, toggle) => (
      <>
        <div
          onClick={toggle}
          style={{ padding: '6px 10px', cursor: 'pointer', borderRadius: '0.375rem' }}
          className="flex items-center gap-2 text-color-100 hover:text-primary-600 border border-transparent hover:border-color-50 hover:shadow-items">
          {isToggle ? (
            <BsCaretDownFill
              onClick={toggle}
              size={11}
              className="text-primary"
            />
          ) : (
            <BsCaretRightFill
              onClick={toggle}
              size={11}
              className="text-primary"
            />
          )}
          <span className="font-semibold text-sm uppercase">
            {title}
          </span>
        </div>
        {isToggle && children}
      </>
    )}
  </Toggle>
)
