import { Checkbox, Modal, Radio } from 'antd'
import classnames from 'classnames'
import { FlagIcon } from 'components/icons/FlagIcon'
import _ from 'lodash'
import { LocalContext } from 'modules/local/LocalContext'
import useTranslate from 'modules/local/useTranslate'
import React, { useContext, useState } from 'react'
import { ReactReduxContext } from 'react-redux'
import useToggle from 'react-use/lib/useToggle'
import { getLogin } from 'redux/selectors'
import { Null } from 'views/Shared'
import Avatar from "antd/lib/avatar/avatar";

export const LanguageSwitch = ({ title, onToggle = Null }) => {
  const t = useTranslate()
  const [isToggle, toggle] = useToggle()
  const { lang, changeLang, changeContentLang } = useContext(LocalContext)
  const [displayLanguage, setDisplayLanguage] = useState(lang)
  const { store } = useContext(ReactReduxContext)
  const login = getLogin(store.getState())
  const following_languages_maximum = _.get(
    login,
    'following_languages_maximum',
    1
  )
  const _languages = _.get(login, 'following_languages', ['vi'])
  const following_languages = _.isEmpty(_languages) ? ['vi'] : _languages
  const [contentLanguages, setContentLanguages] = useState(following_languages)
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const langArray = ['vi', 'en']

  const handleToggle = (value) => {
    toggle(value)
    onToggle(value)
  }

  return (
    <div>
      <div className="flex items-center justify-center">
        <div
          onClick={() => {
            handleToggle(true)
          }}>
          {title}
        </div>
      </div>
      {isToggle && (
        <Modal
          className="custom-modal"
          wrapClassName="rounded-lg"
          style={{ minHeight: 420, borderRadius: 10 }}
          title={
            <div className="text-center font-bold text-color-000 uppercase tracking-wide">
              {t('Choose language')}
            </div>
          }
          visible={isToggle}
          header={null}
          footer={null}
          onCancel={() => {
            handleToggle(false)
          }}>
          <div className="flex-col space-y-6">
            <div className="flex flex-col">
              <span className="font-medium text-sm text-color-400 tracking-wide uppercase">
                {t('Interface language')}
              </span>
              <div className="flex flex-col ml-2">
                <Radio.Group
                  className="w-full"
                  onChange={(e) => {
                    setDisplayLanguage(e.target.value)
                    changeLang(e.target.value)
                  }}
                  value={displayLanguage}>
                  <React.Fragment>
                    {langArray.map((value, key) => (
                      <Radio
                        key={key}
                        value={value}
                        className={classnames(
                          'flex items-center rounded-lg hover:background-200 p-3 custom-radio-lang',
                          lang === value
                            ? 'font-semibold text-primary'
                            : 'font-medium'
                        )}>
                        <div className="flex justify-center items-center px-4">
                          <Avatar
                              size={26}
                              icon={ <FlagIcon type={value} size={25} /> }
                              className="flex flex-center"
                          />
                          <div className="px-3">{t(value)}</div>
                        </div>
                      </Radio>
                    ))}
                  </React.Fragment>
                </Radio.Group>
              </div>
            </div>
            <div className="flex flex-col">
              <span className="font-medium text-sm text-color-400 tracking-wide uppercase">
                {t('Content language')}
              </span>
              <div className="flex flex-col ml-2">
                <div className="ml-3 my-3">
                  <Checkbox
                    disabled={langArray.length > following_languages_maximum}
                    indeterminate={indeterminate}
                    onChange={(e) => {
                      setContentLanguages(e.target.checked ? langArray : [])
                      setIndeterminate(false)
                      setCheckAll(e.target.checked)

                      // Update to Server
                      changeContentLang(
                        langArray.reduce((result, value, i) => {
                          result += i === 0 ? value : ',' + value
                          return result
                        }, '')
                      )
                    }}
                    checked={checkAll}>
                    <span className="ml-4 text-color-300 italic">{t('check all')}</span>
                  </Checkbox>
                </div>
                <Checkbox.Group
                  style={{
                    width: '100%',
                  }}
                  value={contentLanguages}
                  onChange={(checkedValues) => {
                    if (checkedValues.length > following_languages_maximum) {
                      Modal.warning({
                        title: t('Hello you'),
                        className: 'custom-modal',
                        content: t(
                          'Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you'
                        ),
                        okText: t('got it'),
                        okButtonProps: {
                          className:
                            'no-border no-shadow no-text-shadow rounded-full',
                        },
                      })
                      return
                    }
                    changeContentLang(checkedValues.join(','))

                    setContentLanguages(checkedValues)
                    setIndeterminate(
                      !!checkedValues.length &&
                        checkedValues.length < langArray.length
                    )
                    setCheckAll(checkedValues.length === langArray.length)
                  }}>
                  <React.Fragment>
                    {langArray.map((value, key) => (
                      <div
                        key={key}
                        className="clearfix">
                        <Checkbox
                          key={key}
                          value={value}
                          className={classnames(
                            'flex items-center rounded-lg hover:background-200 p-3 custom-checkbox-lang',
                            _.includes(contentLanguages, value)
                              ? 'text-primary font-bold'
                              : 'font-medium'
                          )}>
                          <div className="flex justify-center items-center px-4">
                            <Avatar
                                size={26}
                                icon={ <FlagIcon type={value} size={25} /> }
                                className="flex flex-center"
                            />
                            <div className="px-3">{t(value)}</div>
                          </div>
                        </Checkbox>
                      </div>
                    ))}
                  </React.Fragment>
                </Checkbox.Group>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}
