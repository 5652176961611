export const CustomKeys = Object.freeze(
  {
    co_hosts: {
      name: 'co-hosts',
      label: 'co-hosts',
      path: '/co-hosts',
    },
    add_host: {
      name: 'add-host',
      label: 'add-host',
      path: '/add-host',
    },
    edit_owner: {
      name: 'edit-owner',
      label: 'edit-owner',
      path: '/edit-owner',
    },
    giving_picker: {
      name: 'giving-picker',
      label: 'giving-picker',
      path: '/giving-picker',
    },
    donation_picker: {
      name: 'donation-picker',
      label: 'donation-picker',
      path: '/donation-picker',
    },
    donor_picker: {
      name: 'donor-picker',
      label: 'donor-picker',
      path: '/donor-picker',
    },
  }
)

export const fromCustomKeys = (key) => {
  switch (key) {
    case CustomKeys.co_hosts:
      return {}
    default:
      return {}
  }
}
