import {Button, Input, message, Modal, Spin} from 'antd'
import {
  article_deleteEnhancedArticle_Api,
  bookmarks_mark_Api,
  bookmarks_unMark_Api,
  pin_mark_Api,
  pin_unMark_Api,
  poll_delete_Api,
  post_deletePost_Api,
} from 'apis'
import {articleModel} from 'apis/model'
import {baseItemSchema} from 'apis/schema'
import ApiInfoForm from 'components/form/ApiInfoForm'
import {RegAsModal} from 'components/Modal/Modal'
import Null from 'components/NullComponent'
import {SelectEntityItem} from 'components/SelectEntityItem'
import UseState from 'components/UseState'
import copyToClipboard from 'copy-to-clipboard'
import {API_ROOT_URL, ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import Async from 'modules/asyncCache/components/Async'
import {useDispatchAsyncOnlyActionWithNotify} from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, {useCallback, useContext, useState} from 'react'
import Loadable from 'react-loadable'
import {collectionsSchema} from 'views/Bookmark/collectionsSchema'
import {decryptContent} from 'views/Chart/mapParams/dataHelpers'
import {useVote} from 'views/Server/ServerContainer'
import ServerContext from 'views/Server/ServerContext'
import {useRequiredLogin} from 'views/Server/useRequiredLogin'
import {createTransform} from '../../apis/utils/createTransform'
import {getId, getType} from '../../apis/model/base'
import {EnhancedEditPoll} from '../../views/CreatePoll/EditPoll'
import FeedSetChannelForm from '../../views/Feed/FeedSetChannelForm'
import {LoginContext} from '../LoginContext'
import FeedContext from './FeedContext'
import {InputBoardProvider} from './InputBoard/InputBoardContext'
import {showConfirm, transformAlias} from './TimeLineFeed'
import Count from "./Count";

const FeedSetting = Loadable({
  loader: () => import('views/Feed/FeedSetting'),
  loading: Null,
})

export const FeedProvider = ({
                               item: raw_item,
                               detail,
                               defaultActions,
                               children
                             }) => {
  const t = useTranslate()
  const [hide, setHide] = useState(false)
  const {item: container} = useContext(ServerContext)
  const [modal, setmodal] = useState()
  const login = useContext(LoginContext)
  const history = useHistory()

  const decrypted_data = useCallback(
    (value, item, transform) => {
      if (['document'].includes(getType(raw_item))) {
        return value
      }
      return decryptContent(value, item, transform) ?? []
    },
    [raw_item]
  )

  const item = {
    ...(raw_item ?? {}),
    ...(getType(raw_item) === 'post'
      ? {
        embed_data: {
          ...(raw_item?.embed_data ?? {}),
          content_data: decrypted_data(
            raw_item?.embed_data?.content_data,
            raw_item?.embed_data
          ),
        },
      }
      : {
        content_data: decrypted_data(raw_item?.content_data, raw_item),
      }),
  }

  const [
    creatorId,
    created,
    creatorType,
    creatorName,
    title,
    description,
    cover,
    id,
    type,
    photos,
    embed_data,
  ] = transformAlias(item, [
    'creatorId',
    'created',
    'creatorType',
    'creatorName',
    'title',
    'description',
    'cover',
    'id',
    'type',
    'photos',
    'embed_data',
  ])
  const content_data =
    item._type === 'post'
      ? _.get(item, 'embed_data.content_data', [])
      : _.get(item, 'content_data', [])
  const dispatch = useDispatchAsyncOnlyActionWithNotify()
  const vote_status = _.get(item, 'vote_status')
  const bookmark_status = _.get(item, 'bookmark_status')
  const total_votes = _.get(item, 'total_votes')
  const total_downvotes = _.get(item, 'total_downvotes')
  const handleVote = useVote({
    vote_status,
    total_votes,
    owner_type: type,
    owner_id: id,
  })
  const translate = useTranslate()
  const handleRequiredLogin = useRequiredLogin()
  const url = `/news/${type}/${getId(item)}`
  const shareUrl = `${ROOT_URL}${url}`

  function handleClick(key) {
    // console.log('click', key)
    switch (key) {
      case 'to':
        if (type === 'article') {
          history.push({
            pathname: articleModel.getLinkToDetail(item),
          })
        } else {
          history.push({
            pathname: url,
            // state: { isModal: true },
          })
        }
        break
      case 'edit':
        if (type === 'article') {
          const editingURL = articleModel.getEditingURL(item)
          history.push({
            pathname: editingURL,
          })
        }
        if (type === 'post') {
          setmodal(
            <FeedSetting
              id={id}
              type={type}
              onCancel={() => setmodal(null)}
            />
          )
        }
        if (type === 'poll_question') {
          setmodal(
            <InputBoardProvider>
              <EnhancedEditPoll
                pollItem={item}
                onCancel={() => setmodal(null)}
              />
            </InputBoardProvider>
          )
        }
        break
      case 'set_channel':
        setmodal(
          <FeedSetChannelForm
            id={id}
            onCancel={() => setmodal(null)}
          />
        )
        break
      case 'delete':
        switch (type) {
          case 'poll_question':
            showConfirm({
              title: translate('Are you sure delete this poll'),
              className: 'custom-modal',
              okText: t('sure'),
              cancelText: t('ignore'),
              okButtonProps: {
                type: 'primary',
                danger: true,
                className: 'rounded-lg no-shadow no-text-shadow no-border',
              },
              cancelButtonProps: {
                className: 'rounded-lg no-shadow no-text-shadow no-border',
              },
              onOk: () => {
                dispatch(
                  createAsyncAction({
                    apiInfo: poll_delete_Api,
                    query: {
                      ':poll_question_id': item.idname,
                    },
                  })
                )
              },
            })
            break
          case 'article':
            showConfirm({
              title: translate('Are you sure delete this articles'),
              className: 'custom-modal',
              okText: t('sure'),
              cancelText: t('ignore'),
              okButtonProps: {
                type: 'primary',
                danger: true,
                className: 'rounded-lg no-shadow no-text-shadow no-border',
              },
              cancelButtonProps: {
                className: 'rounded-lg no-shadow no-text-shadow no-border',
              },
              onOk: () => {
                dispatch(
                  createAsyncAction({
                    apiInfo: article_deleteEnhancedArticle_Api,
                    query: {
                      ':article_id': item.id,
                    },
                  })
                )
              },
            })

            break

          default:
            showConfirm({
              title: translate('Are you sure delete this articles'),
              className: 'custom-modal',
              okText: t('sure'),
              cancelText: t('ignore'),
              okButtonProps: {
                type: 'primary',
                danger: true,
                className: 'rounded-lg no-shadow no-text-shadow no-border',
              },
              cancelButtonProps: {
                className: 'rounded-lg no-shadow no-text-shadow no-border',
              },
              onOk: () => {
                dispatch(
                  createAsyncAction({
                    apiInfo: post_deletePost_Api,
                    query: {
                      ':post_id': item.id,
                    },
                  })
                )
              },
            })
            break
        }

        break
      case 'vote':
        handleVote()
        break
      case 'pin':
        dispatch(
          createAsyncAction({
            apiInfo: pin_mark_Api,
            query: {
              ':container': _.get(item, 'container._type'),
              ':container_id': _.get(item, 'container.id'),
              ':type': type,
              ':id': id,
            },
          })
        )

        break
      case 'unpin':
        dispatch(
          createAsyncAction({
            apiInfo: pin_unMark_Api,
            query: {
              ':container': _.get(item, 'container._type'),
              ':container_id': _.get(item, 'container.id'),
              ':type': _.get(item, '_type'),
              ':id': _.get(item, 'id'),
            },
            onSuccess: () => setHide(true),
          })
        )
        break
      case 'copy_link':
        copyToClipboard(`${ROOT_URL}${url}`)
        message.success(translate('copied'))
        break
      case 'rebookmark':
      case 'bookmark':
        handleRequiredLogin(() =>
          setmodal(
            <Modal
              className="custom-modal"
              destroyOnClose
              title={
                <div className="text-center font-bold text-color-000 uppercase">
                  {t('favorites')}
                </div>
              }
              footer={null}
              visible={true}
              onOk={null}
              onCancel={() =>
                setmodal(null)
              }>
              <RegAsModal></RegAsModal>
              <div className="col-span-12 md:col-span-3  space-y-3 ">
                <UseState>
                  {([
                      refreshKey,
                      setRefreshKey
                    ]) => (
                    <Async
                      key={refreshKey}
                      apiInfo={{
                        root: API_ROOT_URL,
                        path:
                          '/folders/folder/gets',
                        method: 'GET',
                        transform: createTransform(
                          collectionsSchema
                        )
                      }}>
                      {({
                          isLoading
                        }) => {
                        return (
                          <div
                            className={
                              'space-y-3  h-1/2 '
                            }>
                            <div className="flex items-center pt-3 flex-wrap">
                              <div className="uppercase  text-sm font-semibold text-color-300 flex-1">
                                {t(
                                  'folders'
                                )}
                              </div>

                              <UseState
                                initialValue={
                                  0
                                }>
                                {([
                                    count,
                                    setCount
                                  ]) => {
                                  const isToggle =
                                    count %
                                    2
                                  const toggle = () =>
                                    setCount(
                                      count +
                                      1
                                    )
                                  return (
                                    <>
                                      {!isToggle && (
                                        <Spin
                                          spinning={
                                            isLoading
                                          }>
                                          {' '}
                                          <Count
                                            onClick={
                                              toggle
                                            }>
                                            <span className="text-xs  uppercase text-right">
                                              +{' '}
                                              {t(
                                                'new folder'
                                              )}
                                            </span>
                                          </Count>
                                        </Spin>
                                      )}
                                      {!!isToggle && (
                                        <ApiInfoForm
                                          onSuccess={() => {
                                            toggle()
                                            setRefreshKey(
                                              Date.now()
                                            )
                                          }}
                                          apiInfo={{
                                            root: API_ROOT_URL,
                                            path:
                                              '/folders/folder/add',
                                            method:
                                              'POST'
                                          }}
                                          initialValues={{
                                            title:
                                              ''
                                          }}>
                                          {({
                                              values,
                                              handleChange,
                                              handleSubmit,
                                              isSubmitting
                                            }) => {
                                            return (
                                              <div className="w-full flex flex-wrap justify-end space-y-2 space-x-2">
                                                <Input
                                                  name="title"
                                                  defaultValue={
                                                    values.title
                                                  }
                                                  className="block w-full animated zoomIn faster"
                                                  autoFocus
                                                  onChange={
                                                    handleChange
                                                  }
                                                  onBlur={e => {
                                                    if (
                                                      !e
                                                        .target
                                                        .value
                                                        .length
                                                    ) {
                                                      toggle()
                                                    }
                                                  }}></Input>
                                                <Button
                                                  onClick={
                                                    toggle
                                                  }>
                                                  {t(
                                                    'cancel'
                                                  )}
                                                </Button>
                                                <Button
                                                  loading={
                                                    isSubmitting
                                                  }
                                                  onClick={() =>
                                                    handleSubmit()
                                                  }
                                                  type="primary">
                                                  {t(
                                                    'create'
                                                  )}
                                                </Button>
                                              </div>
                                            )
                                          }}
                                        </ApiInfoForm>
                                      )}
                                    </>
                                  )
                                }}
                              </UseState>
                            </div>
                            <nav className="w-full py-2 rounded-lg background-100  flex-1  font-semibold">
                              <div
                                onClick={() => {
                                  dispatch(
                                    createAsyncAction(
                                      {
                                        apiInfo: bookmarks_mark_Api,
                                        query: {
                                          ':container':
                                            'user',
                                          ':container_id': _.get(
                                            login,
                                            'username'
                                          ),
                                          ':type': type,
                                          ':id': id
                                        }
                                      }
                                    )
                                  )
                                  setmodal(
                                    null
                                  )
                                }}
                                className="p-3 flex-shrink-0 cursor-pointer hover:background-200">
                                {t(
                                  'all'
                                )}
                              </div>
                              <SelectEntityItem
                                item={
                                  'bookmarkByUser'
                                }
                                schema={
                                  baseItemSchema
                                }>
                                {bookmarkByUser => {
                                  return (
                                    ((bookmarkByUser &&
                                      bookmarkByUser.data) ||
                                      null) &&
                                    bookmarkByUser.data.map(
                                      (
                                        item,
                                        i
                                      ) => {
                                        return (
                                          <SelectEntityItem
                                            key={
                                              item
                                            }
                                            item={
                                              item
                                            }>
                                            {item =>
                                              (item ||
                                                null) && (
                                                <div
                                                  onClick={() => {
                                                    dispatch(
                                                      createAsyncAction(
                                                        {
                                                          apiInfo: bookmarks_mark_Api,
                                                          query: {
                                                            ':container':
                                                              'folder',
                                                            ':container_id':
                                                            item.id,
                                                            ':type': type,
                                                            ':id': id
                                                          }
                                                        }
                                                      )
                                                    )
                                                    setmodal(
                                                      null
                                                    )
                                                  }}
                                                  style={{
                                                    minWidth: 100
                                                  }}
                                                  className="p-3 rounded-none  cursor-pointer hover:background-200 border-t border-gray-300 ">
                                                  {
                                                    item.title
                                                  }
                                                </div>
                                              )
                                            }
                                          </SelectEntityItem>
                                        )
                                      }
                                    )
                                  )
                                }}
                              </SelectEntityItem>
                            </nav>
                          </div>
                        )
                      }}
                    </Async>
                  )}
                </UseState>
              </div>
            </Modal>
          )
        )

        break
      case 'unbookmark':
        handleRequiredLogin(() =>
          dispatch(
            createAsyncAction({
              apiInfo: bookmarks_unMark_Api,
              query: {
                ':container': 'user',
                ':container_id': _.get(
                  login,
                  'username'
                ),
                ':type': type,
                ':id': id
              },
              onSuccess: () =>
                setHide(true)
            })
          )
        )

        break
      case 'message':
        break
      default:
        break
    }
  }

  const allowActions = item.edit
    ? {
      ...defaultActions,
      pin:
        true &&
        container &&
        container.edit,
      unpin:
        true &&
        container &&
        container.edit,
      edit:
        item.edit &&
        item._type !==
        'poll_question',
      delete: true
    }
    : {
      ...defaultActions,
      pin:
        true &&
        container &&
        container.edit,
      unpin:
        true &&
        container &&
        container.edit,
      edit:
        item.edit &&
        item._type !==
        'poll_question',
      delete: false
    }
  const actions = Object.keys(
    defaultActions
  )
    .filter(
      key =>
        defaultActions[key] &&
        allowActions[key]
    )
    .filter(
      key =>
        !(
          key === 'vote' &&
          item._type === 'poll_question'
        )
    )
    .reduce((result, key) => {
      result[key] = true
      return result
    }, {})
  return (
    <FeedContext.Provider
      value={{
        handleClick,
        item,
        detail,
        actions,
        creatorId,
        created,
        creatorType,
        creatorName,
        title,
        photos,
        poll:
          type === 'poll_question' &&
          item,
        article:
          type === 'article' && item,
        embed_data,
        description,
        cover,
        type,
        id,
        url,
        shareUrl,
        vote_status,
        bookmark_status,
        total_votes,
        total_downvotes,
        mentions: item.mentions,
      }}>
      <PermissionProvider
        permissions={{
          ...actions,
          ...item.available_actions
        }}>
        {children}
        {!!modal && modal}
      </PermissionProvider>
    </FeedContext.Provider>
  )
}
