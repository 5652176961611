import React, { useContext } from 'react'
import ContentAlert from 'views/AppLocals/ContentAlert'
import { LoadingWrapper, renderOwnChild } from 'views/Shared'
import UserContext from '../UserContext'
import UserNav from './UserNav'

const UserContent = ({ Wrapper = renderOwnChild }) => {
  const { user } = useContext(UserContext)
  return (
    <Wrapper>
      <LoadingWrapper
        center={false}
        isLoading={!user}>
        <ContentAlert entity={user}>{/*<AdminPanel />*/}</ContentAlert>
      </LoadingWrapper>
      <div className="space-y-3">
        <UserNav />
      </div>
    </Wrapper>
  )
}

export default UserContent
