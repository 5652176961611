import Loading from 'components/Loading'
import React, {
  useContext
} from 'react'
import { useDebounce } from 'react-use'
import { ChartContext } from '../../ChartContext'
import AmVNGeoChartStateless from './components/AmVNGeoChartStateless'

export default props => {
  const {
    item,
    setChartWrap
  } = useContext(ChartContext)
  const [
    debouncedValue,
    setDebouncedValue
  ] = React.useState('')
  const [isReady] = useDebounce(
    () => {
      setDebouncedValue(
        <AmVNGeoChartStateless
          {...props}
          {...{ item, setChartWrap }}
        />
      )
    },
    100,
    [item.version]
  )
  return !Boolean(isReady()) ? (
    <div className="flex flex-1 items-center justify-center w-full">
      <Loading />
    </div>
  ) : (
    debouncedValue
  )
}
