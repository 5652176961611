import { schema } from 'normalizr'

export const orderRequestDetailsSchema = new schema.Entity(
  'order_request_details',
  {},
  {
    idAttribute: 'id',
    processStrategy: e => e
  }
)

export const orderRequestSchema = new schema.Entity(
  'order_request',
  {
    order_request_details: [orderRequestDetailsSchema]
  },
  {
    idAttribute: 'id',
    processStrategy: e => e
  }
)