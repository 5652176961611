import React from "react";

export const Gold = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 56 56"
		>
			<path fill="#fc0" d="M14.36 34.07l1.19 9.21L4 37.78l2.71-7.46z"></path>
			<path
				fill="#fff"
				d="M10 40.63l-2.4-1.14-2.3-5.29.86-2.38zM14.71 42.88l-2.41-1.15-4.8-11.02 2.42 1.18z"
				opacity="0.4"
			></path>
			<path
				fill="#ffbc00"
				d="M39.85 31.38l-24.3 11.91-1.19-9.22 22.3-10.52z"
			></path>
			<path
				fill="#fded04"
				d="M36.66 23.55l-22.3 10.52-7.65-3.75 21.27-10.01z"
			></path>
			<g fill="#fff">
				<path
					d="M32.82 22.11l-9.1 7.54 5.93-8.72zM25.63 21.4l-7.35 10.81-3.75 1.77 7.36-10.82zM15.81 26.03l-4.46 6.56 1.79-5.31z"
					opacity="0.4"
				></path>
			</g>
			<g>
				<path
					fill="#fc0"
					d="M26.51 39.83l1.19 9.22-11.55-5.51 2.71-7.46z"
				></path>
				<path
					fill="#fff"
					d="M22.15 46.39l-2.4-1.14-2.3-5.29.86-2.38zM26.86 48.64l-2.41-1.14-4.8-11.03 2.42 1.19z"
					opacity="0.4"
				></path>
				<path
					fill="#ffbc00"
					d="M52 37.15l-24.3 11.9-1.19-9.22 22.3-10.52z"
				></path>
				<path
					fill="#fded04"
					d="M48.81 29.31l-22.3 10.52-7.65-3.75 21.27-10.01z"
				></path>
				<g fill="#fff">
					<path
						d="M44.97 27.88l-9.1 7.53 5.93-8.72zM37.78 27.17l-7.35 10.8-3.75 1.77 7.36-10.81zM27.96 31.8l-4.46 6.55 1.79-5.3z"
						opacity="0.4"
					></path>
				</g>
			</g>
			<g>
				<path
					fill="#fc0"
					d="M22.94 27.58l1.19 9.22-11.55-5.51 2.71-7.46z"
				></path>
				<path
					fill="#fff"
					d="M18.58 34.14L16.19 33l-2.31-5.29.87-2.38zM23.29 36.39l-2.41-1.14-4.8-11.03 2.43 1.18z"
					opacity="0.4"
				></path>
				<path
					fill="#ffbc00"
					d="M48.43 24.9L24.14 36.8l-1.2-9.22 22.3-10.52z"
				></path>
				<path
					fill="#fded04"
					d="M45.24 17.06l-22.3 10.52-7.64-3.75 21.26-10.01z"
				></path>
				<g fill="#fff">
					<path
						d="M41.4 15.63l-9.1 7.53 5.94-8.72zM34.21 14.92l-7.35 10.8-3.75 1.77 7.36-10.81zM24.39 19.55l-4.46 6.55 1.79-5.3z"
						opacity="0.4"
					></path>
				</g>
			</g>
			<g fill="#fff">
				<path d="M15.74 29.27a2.815 2.815 0 00-1.04 3.84 2.815 2.815 0 00-3.84-1.04 2.815 2.815 0 001.04-3.84 2.797 2.797 0 003.84 1.04z"></path>
				<path d="M15.71 33.06c-.99.57-1.33 1.83-.76 2.82a2.063 2.063 0 00-2.82-.76c.99-.57 1.33-1.83.76-2.82a2.06 2.06 0 002.82.76zM17.24 29.1a2.06 2.06 0 002.13 2 2.06 2.06 0 00-2 2.13 2.06 2.06 0 00-2.13-2 2.06 2.06 0 002-2.13z"></path>
			</g>
			<g fill="#fff">
				<path d="M40.48 17.91a2.815 2.815 0 00-1.04 3.84 2.815 2.815 0 00-3.84-1.04 2.815 2.815 0 001.04-3.84 2.795 2.795 0 003.84 1.04z"></path>
				<path d="M40.44 21.7c-.99.57-1.33 1.83-.76 2.82a2.063 2.063 0 00-2.82-.76c.99-.57 1.33-1.83.76-2.82.57.99 1.83 1.33 2.82.76zM41.97 17.74a2.06 2.06 0 002.13 2 2.06 2.06 0 00-2 2.13 2.06 2.06 0 00-2.13-2 2.06 2.06 0 002-2.13z"></path>
			</g>
			<g fill="#fded04">
				<path d="M42.4 10.81s-.07 1.13-.32 1.38-1.38.32-1.38.32 1.13.07 1.38.32.32 1.38.32 1.38.07-1.13.32-1.38 1.38-.32 1.38-.32-1.13-.07-1.38-.32-.32-1.38-.32-1.38zM39.67 6.95s-1.96 1.72-2.78 1.72-2.78-1.72-2.78-1.72 1.72 1.96 1.72 2.78-1.72 2.78-1.72 2.78 1.96-1.72 2.78-1.72 2.78 1.72 2.78 1.72-1.72-1.96-1.72-2.78 1.72-2.78 1.72-2.78zM28.51 12.12s1.58.1 1.93.45.45 1.93.45 1.93.1-1.58.45-1.93 1.93-.45 1.93-.45-1.58-.1-1.93-.45c-.34-.36-.44-1.94-.44-1.94s-.1 1.58-.45 1.93c-.36.36-1.94.46-1.94.46zM8.9 26.11s1.12-.98 1.59-.98 1.59.98 1.59.98-.98-1.12-.98-1.59.98-1.59.98-1.59-1.12.98-1.59.98-1.59-.98-1.59-.98.98 1.12.98 1.59c.01.47-.98 1.59-.98 1.59zM10.49 20.93s2.27.15 2.77.65.65 2.77.65 2.77.15-2.27.65-2.77 2.77-.65 2.77-.65-2.27-.15-2.77-.65-.65-2.77-.65-2.77-.15 2.27-.65 2.77-2.77.65-2.77.65z"></path>
			</g>
		</svg>
	);
}

export default Gold;
