import {ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import {articleSchema} from '../schema'
import base, {get, getLinkToDetailGenerator, getLinkToSettingGenerator, getLinkToShareGenerator, getType,} from './base'

const getId = get('idname')

const getContainerId = get('idname')

const replaceEditState = (id) => {
  window.history.replaceState(null, 'Edit Article', `article/${id}/edit`)
}

const fromSegments = (segments = [], separator = '') => {
  return _.join(segments, separator)
}

const getDefaultURL = getLinkToDetailGenerator({getId, getType})

export const ArticleContainerTypes = Object.freeze({
  DEFAULT: 'default',
  CHANNEL: 'channel',
})

export const getLinkToDetail = (item) => {
  const URL = getDefaultURL(item)
  return fromSegments(['/news', URL])
}

export const getLinkToSetting = getLinkToSettingGenerator({getId, getType})

export const getLinkToShare = getLinkToShareGenerator({
  rootUrl: ROOT_URL,
  getType,
  getId,
})

const getLinkToCreate = (item) => {
  const URL = getDefaultURL(item)
  return fromSegments([URL, '/new-article'])
}

const getEditingURL = (item) => {
  return fromSegments(
    [
      // '/news',
      '',
      getType(item),
      getId(item),
      'edit',
    ],
    '/'
  )
}

export const CDTypes = Object.freeze({
  MEMO: 'memo',
  FORM: 'form',
  ARTICLE: 'articles',
  RESEARCH: 'research',
})

// Todo: implement translation
// memo (ghi nh?) / form (Bi?u m?u) / research (B�i nghi�n c?u) / article (b�i vi?t)
// Colors:
// 'error',
// 'warning',
// 'success',
// 'default',
// 'processing',
// 'blue',
// '#2db7f5'
// ...
export const fromCDType = (type) => {
  switch (type) {
    case CDTypes.MEMO:
      return {
        label: 'memo',
        color: 'green',
        classColor: 'text-green-600 bg-green-100 border-green-600',
      }
    case CDTypes.FORM:
      return {
        label: 'form',
        color: 'orange',
        classColor: 'text-orange-600 bg-orange-100 border-orange-600',
      }
    case CDTypes.RESEARCH:
      return {
        label: 'research',
        color: 'red',
        classColor: 'text-red-600 bg-red-100 border-red-600',
      }
    case CDTypes.ARTICLE:
    default:
      return {
        label: 'articles',
        color: 'blue',
        classColor: 'text-blue-700 bg-blue-100 border-blue-600',
      }
  }
}

export default {
  ...base,
  getLinkToDetail,
  getLinkToCreate,
  getLinkToSetting,
  getLinkToShare,
  schema: articleSchema,
  getId,
  getContainerId,
  replaceEditState,
  getEditingURL,
}
