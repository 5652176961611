import {Button, DatePicker, Form, InputNumber, Table} from 'antd'
import {create_product_updatePrice_Api} from 'apis'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useState} from 'react'
import {formatterNumber} from "../../../../../helpers/formatterNumber";
import {ProductPriceSetInfo} from "../components/ProductPriceSetInfo";
import {parseNumber} from "../../../../../helpers/parseNumber";
import {BsCalendar2Week} from "react-icons/bs";

export const ForwardPriceInputForm = ({product, productPriceSet, productPriceGroup}) => {
  const t = useTranslate();
  const [effectDate, setEffectDate] = useState(moment(productPriceGroup.eff_at));

  const [__, dispatch] = useDispatchAsyncActionWithNotify();

  const [update, setUpdate] = useState(Date.now());
  const [form] = Form.useForm();
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  return (
    <div key={effectDate.format("YYYY-MM-DD")} className="p-3 space-y-3">

      <div className="flex items-center p-3 my-6 border-2 border-primary-600 rounded-lg">
          <div className="flex-1 font-semibold text-color-100 italic">
              {t('effected date')}
          </div>
          <DatePicker
              className="rounded-md"
              suffixIcon={<BsCalendar2Week className="text-color-400"/>}
              allowClear={false}
              disabled
              onChange={(value) => {
                  setEffectDate(value);
              }}
              defaultValue={effectDate} format={dateFormatList}
          />
      </div>

      <ProductPriceSetInfo productPriceSet={productPriceSet}/>

      <Form
          key={update}
          className="space-y-6 flex flex-col"
          form={form}
          initialValues={{ ...productPriceGroup }}
          name={"dynamic_form_item"}>
        <Form.List name="data">
          {(fields, {add, remove}) => {
            return (
              <div className="space-y-3">
                <Table
                  responsive
                  pagination={{
                    defaultPageSize: 50,
                    hideOnSinglePage: true
                  }}
                  columns={[
                    {
                      title: t('month'),
                      dataIndex: 'month',
                      key: 'month',
                      visible: true,
                      render: (_, record) => {
                        const _rec = form.getFieldValue('data')[record.name];
                        return (
                          <div className="justify-center items-start w-auto flex flex-col leading-none truncate">
                            <div className="text-sm">
                              {moment(_rec.month, 'M').format('MM')}{'-'}{_rec.year}
                            </div>
                            <div className="text-xs font-light inline-block">
                              {`${moment(_rec.from_date).format('DD/MM')}
                              -${moment(_rec.to_date).format('DD/MM')}`}
                            </div>
                          </div>
                        )
                      },
                    },
                    {
                      title: (
                        <div className="flex flex-col truncate leading-none">
                          <div>{t('flat price')}</div>
                          <div className="font-light text-xs">
                            {
                              _.get(productPriceSet, 'price_unit.name', t('price unit'))
                            }/
                            {
                              _.get(productPriceSet, 'unit.name', t('unit'))
                            }
                          </div>
                        </div>
                      ),
                      key: 'price',
                      align: 'right',
                      visible: true,
                      render: (__, record) => {
                        const _rec = form.getFieldValue('data')[record.name];
                        return (
                          <InputNumber
                            step="0.01"
                            defaultValue={_rec.price}
                            disabled={!!_.get(productPriceGroup, 'unit')}
                            name="price"
                            style={{width: '100%'}}
                            formatter={formatterNumber}
                            parser={parseNumber}
                            onChange={(value) => {
                              let data = _.cloneDeep(form.getFieldValue('data'));

                              Object.assign(data[record.name], {
                                price: value
                              })

                              form.setFieldsValue({
                                data
                              })
                            }}
                          />
                        )
                      }
                    },
                    {
                      title: (
                        <div className="flex flex-col truncate leading-none">
                          <div>{t('basis price')}</div>
                          <div className="font-light text-xs">
                            {
                              _.get(productPriceSet, 'price_unit2.name', t('price unit'))
                            }/
                            {
                              _.get(productPriceSet, 'unit2.name', t('unit'))
                            }
                          </div>
                        </div>
                      ),
                      key: 'price2',
                      align: 'right',
                      visible: (!_.isEmpty(productPriceSet.unit2) && !_.isEmpty(productPriceSet.price_unit2)),
                      render: (__, record) => {
                        const _rec = form.getFieldValue('data')[record.name];
                        return (
                          <InputNumber
                            step="0.01"
                            defaultValue={_rec.price2}
                            disabled={!!_.get(productPriceGroup, 'unit2')}
                            name="price2"
                            style={{width: '100%'}}
                            formatter={formatterNumber}
                            parser={parseNumber}
                            onChange={(value) => {
                              let data = _.cloneDeep(form.getFieldValue('data'));

                              Object.assign(data[record.name], {
                                price2: value
                              })

                              form.setFieldsValue({
                                data
                              })
                            }}
                          />
                        )
                      }
                    },
                    {
                      title: t('period'),
                      dataIndex: 'period_code',
                      key: 'period_code',
                      visible: true,
                      render: (_, record) => {
                        const _rec = form.getFieldValue('data')[record.name];
                        return (
                          <div className="justify-center items-start w-auto flex flex-col leading-none truncate">
                            <div className="text-sm">
                              {_rec.period_code}
                            </div>
                          </div>
                        )
                      },
                    },
                  ].filter(e => e.visible)}
                  dataSource={fields}
                  // bordered
                  size="small"
                />
              </div>
            )
          }}
        </Form.List>

        {/*<div className="py-3"/>*/}

        <div className="flex space-x-3 w-full py-3">
          <div className="flex-1"/>

          <Button className="float-right"
                  onClick={() => {
                    const priceSetValue = {
                      ...productPriceSet,
                      unit_id: _.get(productPriceSet, 'unit.id'),
                      unit2_id: _.get(productPriceSet, 'unit2.id'),
                      price_unit_id: _.get(productPriceSet, 'price_unit.id'),
                      price_unit2_id: _.get(productPriceSet, 'price_unit2.id'),
                      country_id: _.get(productPriceGroup, 'location._type') === "country" ? _.get(productPriceGroup, 'location.id') : null,
                      province_id: _.get(productPriceGroup, 'location._type') !== "country" ? _.get(productPriceGroup, 'location.id') : null,
                    }

                    dispatch(
                      createAsyncAction(
                        {
                          apiInfo: create_product_updatePrice_Api(),
                          query: {
                            ':id':
                            product.id
                          },
                          values: {
                            ...priceSetValue,

                            eff_at: effectDate.format("YYYY-MM-DD"),

                            data: JSON.stringify(form.getFieldValue('data'))
                          }
                        }
                      )
                    )

                  }}
                  type="primary"
                  htmlType="submit">
            <div className="px-3">
              {t('save')}
            </div>
          </Button>
        </div>

      </Form>

    </div>
  )
}


