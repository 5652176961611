import _ from "lodash"
import { BsCheckCircle, BsExclamationCircle, BsXCircle } from "react-icons/bs"
import { SpotRequestTypes } from "views/Market/components/SpotBid/SpotRequestForm"

export const RequestStatusOptions = Object.freeze({
  PENDING: 'pending',
  RESPONDED: 'responded',
  CLOSED: 'closed',
})

export const fromOrderRequestType = type => {
  switch (type) {
    case SpotRequestTypes.PRICE:
      return {
        label: 'price',
        color: "processing",
        title: 'send requirement',
        subTitle: 'get the latest and most accurate quotes from suppliers for the product you are interested in',
      }
    case SpotRequestTypes.PRODUCT_INFO:
      return {
        label: 'product info',
        color: "warning",
        title: 'contact provider',
        subTitle: 'please provide a few details to get the most accurate response from the supplier',
      }
    default:
      return {}
  }
}

const requestStatusOptions = [
  {
    isDefault: true,
    name: RequestStatusOptions.PENDING,
    value: -1,
    Icon: BsExclamationCircle,
    color: 'text-blue-500',
  },
  {
    name: RequestStatusOptions.RESPONDED,
    value: 1,
    Icon: BsCheckCircle,
    color: 'text-green-500'
  },
  {
    name: RequestStatusOptions.CLOSED,
    value: 9,
    Icon: BsXCircle,
    color: 'text-red-500',
  }
]

export const requestDetailStatusOptions = [
  {
    isDefault: true,
    name: RequestStatusOptions.PENDING,
    value: -1,
    Icon: BsExclamationCircle,
    color: 'text-blue-500',
  },
  {
    name: RequestStatusOptions.RESPONDED,
    value: 1,
    Icon: BsCheckCircle,
    color: 'text-green-500',
  },
  {
    name: RequestStatusOptions.CLOSED,
    value: 9,
    Icon: BsXCircle,
    color: 'text-red-500',
  }
]

export const getRequestDetailStatus = (value, defaultValue) => {
  return requestDetailStatusOptions
    .find(opt => opt.value === value) || defaultValue
}

export const indexOfDetailStatus = (value, [name = 'name']) => {
  return requestDetailStatusOptions.findIndex(
    opt => {
      return _.get(opt, name) === value
    }
  )
}

export const getRequestStatus = (value, defaultValue) => {
  return requestStatusOptions
    .find(opt => opt.value === value) || defaultValue
}

export default requestStatusOptions