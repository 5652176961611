import {Editor, Node, Path, Transforms} from 'slate'
import {ReactEditor} from 'slate-react'
import {ElementTypes} from '../types'
import {getNode} from './editor'
import {createParagraphNode} from './paragraph'

export function insertVideo(editor, url, meta) {
  if (!url) return

  const {selection} = editor ?? {}
  const node = {
    url,
    meta,
    type: ElementTypes.VIDEO,
    children: [{text: ''}],
  }

  ReactEditor.focus(editor)

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path
    )

    if (editor.isVoid(parentNode) || Node.string(parentNode).length) {
      Transforms.insertNodes(editor, createParagraphNode([node]), {
        at: Path.next(parentPath),
        select: true,
      })
    } else {
      Transforms.insertNodes(editor, createParagraphNode())
      const [__, path] = getNode(editor, ElementTypes.PARAGRAPH)
      Transforms.insertNodes(editor, node, {at: path, select: true})
    }
  }
}
