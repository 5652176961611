import {ConfigProvider} from 'antd'
import EmptyHolder from 'components/EmptyHolder'
import Pure from 'components/Pure'
import React from 'react'

export const RenderEmpty = () => (
  <Pure>
    <EmptyHolder icon={null}/>
  </Pure>
)

export const RenderEmptyMeta = () => (
  <Pure>
    <EmptyHolder
      icon={null}
      style={{border: 'none'}}
    />
  </Pure>
)

const AntdConfigWrapper = ({
                             children,
                             ...props
                           }) => (
  <ConfigProvider
    renderEmpty={RenderEmpty}
    {...props}>
    {children}
  </ConfigProvider>
)

export default AntdConfigWrapper
