import _ from 'lodash'

export default function getItemAltId (
	item = {}
) {
	switch (item._type) {
		case 'user':
			return _.get(item, 'username');
		case 'channel':
			return _.get(item, 'idname');
		case 'organization':
			return _.get(item, 'idname');
		default:
			return _.get(item, 'id');
	}
}
