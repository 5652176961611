import { Button } from 'antd'
import { getChannelsByProps_Api } from 'apis'
import { getId, getType } from 'apis/model/base'
import { channelSchema } from 'apis/schema'
import ContentContainer from 'components/ContentContainer'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useMemo } from 'react'
import { useLocation, useSearchParam } from 'react-use'
import { branch, compose, fromRenderProps, renderNothing } from 'recompose'
import { staticPaths } from 'routes/staticPaths'
import { bindQueryParams } from 'views/AppLocals/functions/routerHelper'
import usePaginationHelper from 'views/AppLocals/hooks/usePaginationHelper'
import ListHeader from 'views/AppLocals/Templates/items/ListHeader'
import { renderChannelItem } from 'views/MainPage/functions/renderChannelItem'
import { Null } from 'views/Shared'
import WorkspaceContext from '../WorkspaceContext'

const ChannelEntity = ({ item, children, defaultItem }) => (
  <SelectEntityItem
    item={item}
    defaultItem={defaultItem}
    schema={channelSchema}>
    {(data) => children(data)}
  </SelectEntityItem>
)

const WorkspaceChannelList = ({
  id,
  owner_id,
  owner_type,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
  },
}) => {
  const t = useTranslate()

  const location = useLocation()

  const history = useHistory()

  const sortBy = useSearchParam(filter_actions.sortBy)

  const refresh_deps = useMemo(
    () => [owner_id, `refreshToken=${_.get(location.state, 'refreshToken')}`],
    [owner_id, location.state]
  )

  const [pager_widget] = usePaginationHelper({
    onInit: ({ onSortChange = Null }) => {
      onSortChange(sortBy)
    },
    pagerInfo: {
      apiInfo: getChannelsByProps_Api,
      query: bindQueryParams([{ id: owner_id }, { type: owner_type }]),
      renderItem: renderChannelItem,
      RenderEntity: ChannelEntity,
    },
    withKey: ({ sortBy, keyword, filterBy }) =>
      [...refresh_deps, sortBy, keyword, JSON.stringify(filterBy)].join('/'),
    dependencies: [owner_id, owner_type],
    renderFilter: Null,
    query_params: filter_actions,
    sortHeader: t('channel list'),
    // renderSearchBar: Null,
    // SortByWidget: ChannelsSortBy,
  })

  return (
    <Wrapper className="flex flex-1 flex-col space-y-3">
      <ListHeader
          title="channels"
          extra={
            <Button
                type="primary"
                className="button-rounded-lg no-border"
                onClick={() => {
                  history.push({
                    ...staticPaths.createChannel,
                    search:
                        '?' +
                        new URLSearchParams({
                          owner_id,
                          owner_type,
                        }).toString(),
                  })
                }}>
              {t('create')}
            </Button>
          }
      />
      {pager_widget}
      {/* <ChannelInputBar /> */}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(WorkspaceContext.Consumer, ({ organization }) => ({
    owner_id: getId(organization),
    owner_type: getType(organization),
  })),
  branch(({ owner_id }) => _.isEmpty(owner_id), renderNothing)
)(WorkspaceChannelList)
