import {BellOutlined} from '@ant-design/icons'
import {Avatar, Layout} from 'antd'
import logo from 'assets/svgs/applogo.svg'
import Null from 'components/NullComponent'
import useTranslate from 'modules/local/useTranslate'
import {ThemeContext} from 'modules/theme/ThemeContext'
import React, {useContext, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import useClickAway from 'react-use/lib/useClickAway'
import {getLogin} from 'redux/selectors'
import RightHeader from './RightHeader'
import SearchInput from './SearchInput'

const { Header } = Layout

const DefaultHeader = () => {
  const t = useTranslate()
  const {
    headerHeight,
    lefSideBarWidth,
    changeStickyTop
  } = useContext(ThemeContext)
  useLayoutEffect(() => {
    changeStickyTop('50px')
    return () => changeStickyTop('0px')
  }, [changeStickyTop])

  const ref = useRef(null)
  const login = useSelector(getLogin)

  const [
    currentIndex,
    setCurrentIndex
  ] = useState()
  useClickAway(ref, () => {
    setCurrentIndex(null)
  })
  const popupopntions = [
    () => Null,
    () => Null,
    () => Null
  ]
  const renderPopup =
    popupopntions[currentIndex]

  const rightHeader = useMemo(() => {
    return login ? (
      <RightHeader
        currentIndex={currentIndex}
        onItemClick={setCurrentIndex}
        options={[
          {
            title: t('notification'),
            key: 'notification',
            icon: <BellOutlined />
          },
          {
            title: t('user'),
            key: 'user',
            icon: (
              <Avatar
                data-effect="ripple"
                src={login.avatar}
              />
            )
          }
        ]}
      />
    ) : (
      <RightHeader>
        <div className="mr-4">
          <Link
            to={{
              pathname: '/login',
              state: {
                isModal: true
              }
            }}
            title={t('sign up')}
            className="action signUp">
            {t('signin / signup')}
          </Link>
        </div>
      </RightHeader>
    )
  }, [currentIndex, login, t])
  return (
    <Header
      className="border-b border-gray-300 flex items-center sticky top-0"
      style={{
        zIndex: 100,
        background: 'var(--background)',
        padding: 0,
        height: headerHeight,
        lineHeight: headerHeight
      }}>
      <Link
        to="/"
        className=" flex items-center flex-shrink-0"
        style={{
          width: lefSideBarWidth,
          paddingLeft: '2em'
        }}>
        <img
          style={{ height: '30px' }}
          src={logo}
          alt="FeedIn logo"
        />
        <div className="flex-1 border-r border-gray-300 h-6" />
      </Link>

      <div className="pl-3 flex-1">
        <SearchInput />
      </div>
      <div ref={ref}>
        {rightHeader}
        {!!renderPopup && (
          <div
            key={currentIndex}
            className="userMenu">
            <div className="w-full">
              {renderPopup()}
            </div>
          </div>
        )}
      </div>
    </Header>
  )
}
export default DefaultHeader
