import enUS from 'antd/es/locale/en_US'
import frFR from 'antd/es/locale/fr_FR'
import viVN from 'antd/es/locale/vi_VN'
import en from './en/en_US'
import fr from './fr/fr_FR'
import vi from './vi/vi_VN'

export const transToCorectCode = code => {
  switch (code) {
    case 'vi':
      return 'vi-VN'
    case 'fr':
      return 'fr-FR'
    default:
      return 'en-US'
  }
}
const translations = {
  vi: vi,
  en: en,
  fr: fr
}

export const getAntdLocale = (type) => {
  switch (type) {
    case 'vi':
      return viVN
    case 'fr':
      return frFR
    default:
      return enUS
  }
}

export default translations
