import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../../../LoadingPage";

const ChartWrapperLazy = Loadable({
  loader: () => import('./ChartWrapper'),
  loading: LoadingPage
})

const ChartWrapper = (props) => (
  <ChartWrapperLazy {...props} />
)

export default ChartWrapper
