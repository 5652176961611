import classNames from 'classnames'
import FieldsFactory from 'components/form/FieldsFactory'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useCallback } from 'react'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { useToggle } from 'react-use'
import { mapProps } from 'recompose'
import {
  Null,
  PaddingWrapper,
  renderOwnChild,
  renderSelf,
  Visible,
} from 'views/Shared'

export const DefaultAdvancedToggle = ({
  className = 'w-full flex justify-end items-center',
  title,
  onClick,
  expanded = false,
  renderTitle = renderSelf,
  icons = [IoChevronDown, IoChevronUp],
}) => {
  const t = useTranslate()
  const Component = expanded ? icons[1] : icons[0]
  return (
    <div
      className={classNames(
        className,
        expanded ? 'text-primary' : 'text-color-300 hover:text-primary'
      )}>
      <div
        onClick={onClick}
        style={{ padding: '4px 10px' }}
        className={classNames("flex items-center gap-2 cursor-pointer", expanded ? '' : 'mx-2')}>
        {renderTitle(
          <span className="font-medium text-sm italic leading-tight">
              {t(title)}
          </span>
        )}
        <Component size={12} />
      </div>
    </div>
  )
}

export const ExpandablePanel = ({
  icons,
  style,
  children,
  className,
  renderTitle,
  onClick = Null,
  expanded = false,
  Header = DefaultAdvancedToggle,
  Wrapper = renderOwnChild,
  title = 'advanced settings',
  renderCollapsedContent = Null,
  ContentWrapper = renderOwnChild,
}) => {
  const renderWrapper = useCallback(
    (child) => (
      <Wrapper
        {...{
          style,
          onClick,
          className,
        }}>
        {child}
      </Wrapper>
    ),
    []
  )
  return renderWrapper(
    <div>
      <Header
        {...{
          title,
          icons,
          onClick,
          expanded,
          renderTitle,
        }}
      />
      {!!expanded ? (
        <ContentWrapper>{children}</ContentWrapper>
      ) : (
        renderCollapsedContent(<ContentWrapper>{children}</ContentWrapper>)
      )}
    </div>
  )
}

const AdvancedFormGroup = ({ groupValues, Wrapper = renderOwnChild }) => {
  const [isToggle, toggle] = useToggle(false)

  const formSchema = Array.from(groupValues || []).filter(Visible)

  if (_.isEmpty(formSchema)) return null

  return (
    <ExpandablePanel
      onClick={toggle}
      Wrapper={Wrapper}
      expanded={isToggle}>
      <PaddingWrapper padding="0.5rem">
        <FieldsFactory formSchema={formSchema} />
      </PaddingWrapper>
    </ExpandablePanel>
  )
}

export const AdvancedFormFields = ({
  formSchema,
  Wrapper = renderOwnChild,
  ...props
}) => {
  const [isToggle, toggle] = useToggle(false)

  if (_.isEmpty(formSchema)) return null

  return (
    <ExpandablePanel
      onClick={toggle}
      Wrapper={Wrapper}
      expanded={isToggle}
      {...props}>
      <div className="p-2 space-y-2">
        <FieldsFactory formSchema={formSchema} />
      </div>
    </ExpandablePanel>
  )
}

export const withAdvancedOptions = (options) => (Component) =>
  mapProps(({ name, value, onChange, disabled }) => ({
    name,
    value,
    options,
    onChange,
    disabled,
  }))(Component)

export default AdvancedFormGroup
