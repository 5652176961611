export default (obj, mapFn) => {
  try {
    return Object.keys(obj).map((key, index) =>
      mapFn ? mapFn(obj[key], key, index) : obj[key]
    );
  } catch (error) {
    console.error(error);
    return [];
  }
};
