import classNames from 'classnames'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import Pure from 'components/Pure'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, { useCallback, useContext } from 'react'
import {IoApps, IoEllipsisHorizontal} from 'react-icons/io5'
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom'
import { withProps } from 'recompose'
import { staticPaths } from 'routes/staticPaths'
import { mapPathParam } from 'views/AppLocals/functions/routerHelper'
import { Queries } from 'views/Shared/enums/Queries'
import WorkspaceSubHeader from './WorkspaceSubHeader'
import {WorkSpaceIcon} from "../../../components/AppIcon";

const rootPath = staticPaths.workspace.pathname

const renderFeatureItem = (type, { url, label, disabled = false }) => {
  if (_.isEmpty(type)) return null

  const Component = !!disabled
    ? 'div'
    : withProps({
          to: url,
          activeStyle: {
              border: '2px solid var(--color-primary-600)'
          },
          activeClassName: 'font-bold bg-primary-50 no-shadow text-primary-600',
      })(NavLink)

  return (
    <Translate key={type}>
      {(t) => (
        <Component
          style={{
            minWidth: '5.5rem',
            width: '100%',
            height: '5.5rem',
          }}
          className={classNames(
            'flex flex-col flex-center gap-2 rounded-lg no-underline p-2 hover:shadow-items font-medium border border-color-50',
            !!disabled && 'disable'
          )}>
          <WorkSpaceIcon
            type={type}
            size={25}
            FallbackComponent={IoApps}
          />
          <span className="text-center text-xs">{t(label)}</span>
        </Component>
      )}
    </Translate>
  )
}

const WorkspaceFeatures = ({
  routes = [],
  defaultAction,
  Wrapper = 'div',
  actionField = Queries.ACTION,
  isDisabled = (route) => false,
}) => {
  const { path: root } = useRouteMatch()
  const { isSm, isLg } = useContext(LayoutContext)

  const getPath = useCallback(
    (key) => {
      if (root.includes(actionField)) {
        return mapPathParam(root, {
          [actionField]: key,
        })
      } else {
        return [root, key].join('/')
      }
    },
    [root, actionField]
  )

  if (_.isEmpty(routes)) return null

  return (
    <Wrapper className="space-y-3 background container mx-auto">
      <Toggle>
        {(isToggle, toggle) => {
          const limit =
            !isToggle && isSm
              ? routes.length <= 8
                ? null
                : 8
              : !isToggle && isLg
              ? routes.length <= 12
                ? null
                : 12
              : null
          return (
            <div className="p-3 my-3">
              <div className="grid grid-cols-4 md:grid-cols-10 lg:grid-cols-12 gap-1 md:gap-3">
                <Pure input={[routes, limit, isDisabled]}>
                  {(!!limit ? routes.slice(0, limit) : routes).map(
                    ({ key, label, ...route }) =>
                      renderFeatureItem(key, {
                        label,
                        disabled: isDisabled(route),
                        url: getPath(key),
                      })
                  )}
                </Pure>
              </div>
              {!!limit && (
                <div className="flex h-1 items-center mt-6">
                  <div
                    onClick={toggle}
                    className="text-lg text-color-100 background-200 rounded-full p-1 px-3 leading-none h-5 flex items-center">
                    <IoEllipsisHorizontal />
                  </div>
                </div>
              )}

              {isSm && <WorkspaceSubHeader routes={routes} />}
            </div>
          )
        }}
      </Toggle>
      <Switch>
        <Route
          exact={true}
          path={rootPath}
          render={() => <Redirect to={getPath(defaultAction)} />}
        />
        {routes.map(({ key, required_permissions, ...route }) => (
          <Route
            key={key}
            exact={true}
            path={getPath(key)}
            {...route}
          />
        ))}
        <Route
          path="*"
          render={() => <Redirect to={getPath(defaultAction)} />}
        />
      </Switch>
      <div />
    </Wrapper>
  )
}

export default WorkspaceFeatures
