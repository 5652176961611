import {PlusOutlined} from '@ant-design/icons'
import {Button, DatePicker, Form, Input, InputNumber, Modal, Popconfirm, Select, Table, Typography} from 'antd'
import {create_product_updatePrice_Api} from 'apis'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {getAsynCacheSelector} from "../../../../../modules/asyncCache/selectors";
import {CellNumberFormat} from "./CellNumberFormat";
import {EditReportNumberModal} from "../../../../Organization/Settings/ProductReport/ProductReportDetailSetting";
import {formatterNumber} from "../../../../../helpers/formatterNumber";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import DeleteFilled from "@ant-design/icons/lib/icons/DeleteFilled";
import {PriceDetailForwardPriceEditForm} from "./forms/PriceDetailForwardPriceEditForm";
import {FullSideModalLayout} from "../../../../../components/Modal/SideModalLayout";
import {parseNumber} from "../../../../../helpers/parseNumber";
import {emptyArray, emptyObject} from "../../../../../helpers/emptyObjects";
import classNames from "classnames";
import {BsCalendar2Week, BsPlusCircleFill} from "react-icons/bs";


export const ProductPriceGroupComponent = ({item, productPriceSet, productPriceGroup, update, setUpdate, effectMomentDate}) => {
  const t = useTranslate();
  const [chartAddedData, setChartAddedData] = useState({additionalData: [], additionalData2: []});

  const {
    contract_types = [],
  } = useAppConfig();

  const {
    result: price_periods = []
  } = useSelector(
    state => {
      return getAsynCacheSelector(
        state,
        "/products/get-price-periods?day=1"
      )
    }
  );

  const {
    unit, unit2, price_unit, price_unit2
  } = productPriceSet;
  const unit_id = (unit || emptyObject).id;
  const unit2_id = (unit2 || emptyObject).id;
  const price_unit_id = (price_unit || emptyObject).id;
  const price_unit2_id = (price_unit2 || emptyObject).id;
  const location_type = _.get(productPriceGroup, 'location._type', 'country');
  const location_id = _.get(productPriceGroup, 'location.id');

  const [__, dispatch] = useDispatchAsyncActionWithNotify();

  const [form] = Form.useForm();
  const [addDetailForm] = Form.useForm();

  const handleOnEditDetailSubmit = () => {
    const {
      contract_type_cd, delivery_type_cd, incoterm_type_cd, transport_type_cd,
      unit, unit2, price_unit, price_unit2
    } = productPriceSet;
    const unit_id = (unit || emptyObject).id;
    const unit2_id = (unit2 || emptyObject).id;
    const price_unit_id = (price_unit || emptyObject).id;
    const price_unit2_id = (price_unit2 || emptyObject).id;

    let country_id = 237;
    let province_id = null;
    if (_.get(productPriceGroup, 'location._type', 'country') === 'country') {
      country_id = _.get(productPriceGroup, 'location.id');
      province_id = null;
    } else {
      province_id = _.get(productPriceGroup, 'location.id');
      country_id = _.get(productPriceGroup, 'location.country_id');
    }
    const data = [...form.getFieldsValue().data || emptyArray];

    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_updatePrice_Api(),
          query: {
            ':id':
            item.id
          },
          values: {
            contract_type_cd, delivery_type_cd, incoterm_type_cd, transport_type_cd,
            unit_id, unit2_id, price_unit_id, price_unit2_id,
            country_id, province_id,

            eff_at: productPriceGroup.eff_at,

            data: JSON.stringify(data)
          }
        }
      )
    )

    setTimeout(() => {
      setUpdate(Date.now())
    }, 500)
  }

  const handleOnAddDetailSubmit = (values) => {
    const {
      contract_type_cd, delivery_type_cd, incoterm_type_cd, transport_type_cd,
      unit, unit2, price_unit, price_unit2
    } = productPriceSet;
    const unit_id = (unit || emptyObject).id;
    const unit2_id = (unit2 || emptyObject).id;
    const price_unit_id = (price_unit || emptyObject).id;
    const price_unit2_id = (price_unit2 || emptyObject).id;

    let country_id = 237;
    let province_id = null;
    if (_.get(productPriceGroup, 'location._type', 'country') === 'country') {
      country_id = _.get(productPriceGroup, 'location.id');
      province_id = null;
    } else {
      province_id = _.get(productPriceGroup, 'location.id');
      country_id = _.get(productPriceGroup, 'location.country_id');
    }


    const {rangePicker, ...rest} = values;
    const data = [...form.getFieldsValue().data || emptyArray,
      {
        ...rest,
        from_date: rangePicker[0].utc().format(),
        to_date: rangePicker[1].utc().format(),
      }
    ];

    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_updatePrice_Api(),
          query: {
            ':id':
            item.id
          },
          values: {
            contract_type_cd, delivery_type_cd, incoterm_type_cd, transport_type_cd,
            unit_id, unit2_id, price_unit_id, price_unit2_id,
            country_id, province_id,

            eff_at: productPriceGroup.eff_at,

            data: JSON.stringify(data)
          }
        }
      )
    )

    setTimeout(() => {
      setUpdate(Date.now())
    }, 1000)
  };

  return (
    <div key={productPriceGroup.id}>
      <div className="flex flex-col">

        <Form
            key={update} className="space-y-6 flex flex-col"
            // onChange={() => setUpdate(Date.now())} // nếu ko rào lại thì sẽ bị reset laij form
            form={form}
            initialValues={{ ...productPriceGroup }}
            name={"dynamic_form_item" + Date.now()}>
          <Form.List name="data">
            {(fields, {add, remove}) => {
              return (
                  <Table
                      pagination={{
                        defaultPageSize: 10,
                        hideOnSinglePage: true
                      }}
                      columns={[
                        {
                          title: t('month'),
                          dataIndex: 'month',
                          key: 'month',
                          visible: true,
                          render: (_, record) => {
                            const _rec = form.getFieldValue('data')[record.name];
                            return (
                                <div className="justify-center items-start w-auto flex flex-col leading-none truncate">
                                  <div className="text-sm">
                                    {moment(_rec.month, 'M').format('MM')}{'-'}{_rec.year}
                                  </div>
                                  <div className="text-xs font-light inline-block">
                                    {`${moment(_rec.from_date).format('DD/MM')}
                              -${moment(_rec.to_date).format('DD/MM')}`}
                                  </div>
                                </div>
                            )
                          },
                        },
                        {
                          title: (
                              <div className="flex flex-col truncate leading-none">
                                <div>{t('flat price')}</div>
                                <div className="font-light text-xs">
                                  {
                                    _.get(productPriceSet, 'price_unit.name', t('price unit'))
                                  }/
                                  {
                                    _.get(productPriceSet, 'unit.name', t('unit'))
                                  }
                                </div>
                              </div>
                          ),
                          key: 'price',
                          align: 'right',
                          visible: true,
                          render: (_, record) => {
                            const _rec = form.getFieldValue('data')[record.name];
                            return (
                                <div id={_rec.id}
                                     className="flex w-full space-x-3 justify-end items-center">
                                  <CellNumberFormat number={_rec.price}/>
                                </div>
                            )
                          }
                        },
                        {
                          title: (
                              <div className="flex flex-col truncate leading-none">
                                <div>{t('basis price')}</div>
                                <div className="font-light text-xs">
                                  {
                                    _.get(productPriceSet, 'price_unit2.name', t('price unit'))
                                  }/
                                  {
                                    _.get(productPriceSet, 'unit2.name', t('unit'))
                                  }
                                </div>
                              </div>
                          ),
                          key: 'price2',
                          align: 'right',
                          visible: (!_.isEmpty(productPriceSet.unit2) && !_.isEmpty(productPriceSet.price_unit2)),
                          render: (_, record) => {
                            const _rec = form.getFieldValue('data')[record.name];
                            return (
                                <div id={_rec.id} className="flex w-full space-x-3 justify-end items-center">

                                  {
                                    !!_rec.price2 && (
                                        <div className="flex flex-col">
                                          <CellNumberFormat number={_rec.price2}/>
                                          <div className="flex justify-end">
                                            {' '} ({_rec.period_code})
                                          </div>
                                        </div>
                                    )
                                  }
                                </div>
                            )
                          }
                        },
                        {
                          dataIndex: 'action',
                          align: 'right',
                          width: 30,
                          key: 'action',
                          visible: true,
                          render: (__, record) => {
                            const _rec = form.getFieldValue('data')[record.name];

                            return (
                                <div className="grid grid-cols-1 gap-1">
                                  <Popconfirm
                                      title={t('Sure to remove?')}
                                      okText={t('confirm')}
                                      okCancel={t('cancel')}
                                      onConfirm={() => {
                                        remove(record.name);
                                        setTimeout(() => {
                                          handleOnEditDetailSubmit();
                                        }, 1000);
                                      }}>
                                    <Typography.Link className="inline-block">
                                      <DeleteFilled className="text-red-500"/>
                                    </Typography.Link>
                                  </Popconfirm>

                                  <Toggle>
                                    {(
                                        isToggle,
                                        toggle
                                    ) => {
                                      return (
                                          <React.Fragment>
                                            <Typography.Link
                                                disabled={moment(productPriceGroup.eff_at).diff(effectMomentDate, 'days')}
                                                onClick={toggle} className="inline-block">
                                              <EditFilled
                                                  className={moment(productPriceGroup.eff_at).diff(effectMomentDate, 'days') ? "text-color-300" : "text-primary"}/>
                                            </Typography.Link>

                                            <Modal
                                                title={(
                                                    <div
                                                        className="font-bold text-xl text-primary">{contract_types[productPriceSet.contract_type_cd]}:
                                                    </div>
                                                )}
                                                maskClosable={false}
                                                destroyOnClose
                                                onCancel={toggle}
                                                visible={isToggle}
                                                footer={null}>
                                              <PriceDetailForwardPriceEditForm
                                                  effectMomentDate={effectMomentDate}
                                                  product={item}
                                                  productPriceSet={productPriceSet}
                                                  productPriceGroup={productPriceGroup}
                                                  productPriceDetail={_rec}
                                                  location_type={location_type}
                                                  location_id={location_id}
                                                  unit_id={unit_id}
                                                  price_unit_id={price_unit_id}
                                                  unit2_id={unit2_id}
                                                  price_unit2_id={price_unit2_id}
                                                  tableRecord={record}
                                                  chartAddedData={chartAddedData} setChartAddedData={setChartAddedData}
                                                  toggle={toggle}
                                                  isToggle={isToggle}
                                                  update={update}
                                                  form={form}
                                                  handleOnEditDetailSubmit={handleOnEditDetailSubmit}
                                              />
                                            </Modal>
                                          </React.Fragment>
                                      );
                                    }}
                                  </Toggle>
                                </div>
                            )
                          }
                        },
                      ].filter(e => e.visible)}
                      dataSource={fields}
                      footer={() => (
                          <Toggle>
                            {(
                                isToggleNewForwardPrice,
                                toggleNewForwardPrice
                            ) => (
                                <React.Fragment>
                                  <div className="flex justify-center">
                                    <div
                                        onClick={() => toggleNewForwardPrice()}
                                        style={{ padding: '0 10px', height: 26, cursor: 'pointer' }}
                                        className="w-fit flex flex-center gap-2 rounded-md border border-primary bg-primary-50 hover:bg-primary-100 text-primary-600">
                                      <BsPlusCircleFill size={12}/>
                                      <span className="font-bold text-xs italic">
                                          {t('add new forward price')}
                                      </span>
                                    </div>
                                  </div>
                                  {isToggleNewForwardPrice && (
                                      <Modal
                                          width={600}
                                          className="custom-modal"
                                          onCancel={toggleNewForwardPrice}
                                          maskClosable={false}
                                          title={
                                            <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                                              {t('add new forward price')}
                                            </div>
                                          }
                                          destroyOnClose
                                          visible={isToggleNewForwardPrice}
                                          footer={null}>
                                        <div className="w-full p-3">

                                          <Form className="space-y-3"
                                              form={addDetailForm}
                                              initialValues={{
                                                price: _.get(productPriceGroup, 'data.0.price'),
                                                rangePicker: [moment(), moment()],
                                              }}
                                              onFinish={(values) => {
                                                const {rangePicker, period_code, from_date, to_date, year, month_nbr: month} =
                                                _.head(price_periods.filter(e => e.period_code === values.period))
                                                || (productPriceSet.contract_type_cd === 'spot' ? {
                                                  rangePicker: [moment(), moment()],
                                                  period_code: "T0",
                                                  price2: 0,
                                                  from_date: moment().format("YYYY-MM-DD"),
                                                  to_date: moment().format("YYYY-MM-DD"),
                                                  year: moment().year(),
                                                  month: moment().month()
                                                } : {});

                                                handleOnAddDetailSubmit(
                                                    {
                                                      rangePicker, period_code, from_date, to_date, year, month,
                                                      ...values
                                                    }
                                                );
                                                toggleNewForwardPrice();
                                              }}
                                              {...{
                                                labelCol: {span: 6},
                                                wrapperCol: {span: 18},
                                              }}>
                                            {(values) => {
                                                const selectedOptions = productPriceGroup.data.map(item => item.period_code);

                                                return (
                                                    <React.Fragment>
                                                      <Form.Item
                                                          label={
                                                            <span className="text-sm text-color-400 italic">
                                                              {t('period')}
                                                            </span>
                                                          }
                                                          className="flex-1"
                                                          name={"period"}>
                                                        <Select
                                                            showSearch
                                                            virtual={false}
                                                            optionFilterProp="label"
                                                            placeholder={t('please select')}
                                                            style={{
                                                              width: '100%'
                                                            }}
                                                            onChange={(value) => {
                                                              const {period_code, from_date, to_date, year, month} =
                                                                  _.head(price_periods.filter(e => e.period_code === value));

                                                              // setState({period_code, from_date, to_date, year, month});

                                                              const _fields = addDetailForm.getFieldsValue()
                                                              Object.assign(_fields, {
                                                                year: year,
                                                                month: month,
                                                                rangePicker: [moment(from_date), moment(to_date)],
                                                                period_code: period_code
                                                              })
                                                              addDetailForm.setFieldsValue(_fields)
                                                            }}>
                                                          {
                                                            price_periods
                                                            // .filter(e => !selectedOptions.includes(e.period_code))
                                                                .map(
                                                                    e => {
                                                                      return (
                                                                          <Select.Option
                                                                              key={e.period_code}
                                                                              value={e.period_code}
                                                                              label={e.month + ' - ' + e.year}>
                                                                            <div className="flex flex-col">
                                                                              <div className="">
                                                                                {e.month + ' - ' + e.year}
                                                                              </div>
                                                                            </div>
                                                                          </Select.Option>
                                                                      )
                                                                    }
                                                                )
                                                          }
                                                        </Select>
                                                      </Form.Item>

                                                      <Form.Item
                                                          label={
                                                            <span className="text-sm text-color-400 italic">
                                                              {t("range")}
                                                            </span>
                                                          }
                                                          className="flex-1"
                                                          name={"rangePicker"}>
                                                        <DatePicker.RangePicker
                                                            className="rounded-md"
                                                            suffixIcon={<BsCalendar2Week className="text-color-400"/>}
                                                            style={{width: '100%'}}
                                                            onChange={(value) => {
                                                              const _fields = addDetailForm.getFieldsValue()
                                                              Object.assign(_fields, {
                                                                rangePicker: value
                                                              })
                                                              addDetailForm.setFieldsValue(_fields)
                                                            }}
                                                        />
                                                      </Form.Item>

                                                      <Form.Item
                                                          label={
                                                            <div className="flex flex-col italic">
                                                              <span className="font-bold text-color-100 leading-tight">{t('flat price')}</span>
                                                              <span className="font-normal text-2xs text-color-400 leading-tight">
                                                                ({_.get(productPriceSet, 'price_unit.name')}/{_.get(productPriceSet, 'unit.name')})
                                                              </span>
                                                            </div>
                                                          }
                                                          className="flex-1"
                                                          name={"price"}>
                                                        <InputNumber
                                                            name={"price"}
                                                            style={{width: '100%'}}
                                                            formatter={formatterNumber}
                                                            parser={parseNumber}
                                                        />
                                                      </Form.Item>

                                                      {(productPriceSet.unit2 && productPriceSet.price_unit2) && (
                                                          <React.Fragment>
                                                            <Form.Item
                                                                label={
                                                                  <div className="flex flex-col italic">
                                                                    <span className="font-bold text-color-100 leading-tight">{t('basis price')}</span>
                                                                    <span className="font-normal text-2xs text-color-400 leading-tight">
                                                                      ({_.get(productPriceSet, 'price_unit2.name')}/{_.get(productPriceSet, 'unit2.name')})
                                                                    </span>
                                                                  </div>
                                                                }
                                                                className="flex-1"
                                                                name={"price2"}>
                                                              <Input.Group style={{display: 'flex'}} compact>
                                                                <InputNumber
                                                                    name={"price2"}
                                                                    style={{width: '100%'}}
                                                                    formatter={formatterNumber}
                                                                    parser={parseNumber}
                                                                    onChange={(value) => {
                                                                      const _fields = addDetailForm.getFieldsValue()
                                                                      addDetailForm.setFieldsValue(
                                                                          {..._fields, price2: value})
                                                                    }}
                                                                />
                                                                <Select
                                                                    className="flex-1"
                                                                    showSearch
                                                                    style={{width: 'auto'}}
                                                                    placeholder={t(
                                                                        'period'
                                                                    )}
                                                                    options={price_periods.map(e => {
                                                                      return {
                                                                        value: e.period_code,
                                                                        label: e.period_code
                                                                      }
                                                                    })}
                                                                    onChange={(value) => {
                                                                      const _fields = addDetailForm.getFieldsValue()
                                                                      addDetailForm.setFieldsValue(
                                                                          {..._fields, period_code: value})
                                                                    }}
                                                                />
                                                              </Input.Group>
                                                            </Form.Item>
                                                            <Form.Item
                                                                hidden={true}
                                                                className="flex-1"
                                                                name={"period_code"}>
                                                              <Select style={{width: 100}}
                                                                      className="flex-1"
                                                                      placeholder={t(
                                                                          'period code'
                                                                      )}
                                                                      options={price_periods.map(e => {
                                                                        return {
                                                                          value: e.period_code,
                                                                          label: e.period_code
                                                                        }
                                                                      })}
                                                              />
                                                            </Form.Item>
                                                          </React.Fragment>
                                                      )}

                                                      <div className="text-center mt-4">
                                                        <Button
                                                            type="primary"
                                                            className="button-rounded-md no-underline"
                                                            htmlType="submit">
                                                          {t('confirm')}
                                                        </Button>
                                                      </div>
                                                    </React.Fragment>
                                                )
                                              }
                                            }
                                          </Form>

                                        </div>
                                      </Modal>
                                  )}
                                </React.Fragment>
                            )}
                          </Toggle>
                      )}
                  />
              )
            }}
          </Form.List>
        </Form>
      </div>
    </div>
  )
}


export const ProductPriceGroupReportComponent = ({readOnly = true, report, item, productPriceSet, productPriceGroup}) => {
  const t = useTranslate();

  const {
    product_units = [],
    product_price_units = [],
    contract_types = [],
    delivery_types = [],
    incoterm_types = [],
    transport_types = [],
    countries = [],
    provinces = [],
  } = useAppConfig();

  const {
    result: price_periods = []
  } = useSelector(
    state => {
      return getAsynCacheSelector(
        state,
        "/products/get-price-periods?day=1"
      )
    }
  );

  const [__, dispatch] = useDispatchAsyncActionWithNotify();

  const [form] = Form.useForm();
  const [addDetailForm] = Form.useForm();


  return (
    <div key={productPriceGroup.id}>
      <div className="flex flex-col">

        <Form className="space-y-6 flex flex-col"
              form={form}
              initialValues={{
                ...productPriceGroup
              }}
              name={"dynamic_form_item" + Date.now()}
        >
          <Form.List name="data">
            {(fields, {add, remove}) => {
              return (
                <div className="space-y-3">
                  <Table
                    responsive
                    pagination={{
                      defaultPageSize: 10,
                      hideOnSinglePage: true
                    }}
                    columns={[
                      {
                        title: t('month'),
                        dataIndex: 'month',
                        key: 'month',
                        visible: true,
                        render: (_, record) => {
                          const _rec = form.getFieldValue('data')[record.name];
                          return (
                            <div className="justify-center items-start w-auto flex flex-col leading-none truncate">
                              <div className="">
                                {moment(_rec.month, 'M').format('MMM')}{' - '}{_rec.year}
                              </div>
                              <div className="text-xs font-light inline-block">
                                {`${moment(_rec.from_date).format('DD/MM')}
                              -${moment(_rec.to_date).format('DD/MM')}`}
                              </div>
                            </div>
                          )
                        },
                      },
                      {
                        title: (
                          <div className="flex flex-col truncate leading-none">
                            <div>{t('flat price')}</div>
                            <div className="font-light text-xs">
                              {
                                _.get(productPriceSet, 'price_unit.name', t('price unit'))
                              }/
                              {
                                _.get(productPriceSet, 'unit.name', t('unit'))
                              }
                            </div>
                          </div>
                        ),
                        key: 'price',
                        align: 'right',
                        visible: true,
                        render: (__, record) => {
                          const _rec = form.getFieldValue('data')[record.name];
                          return (
                            <div id={_rec.id}
                                 className="flex w-full space-x-3 justify-end items-center">

                              <div className="px-1">
                                {
                                  !!!readOnly && (
                                    <EditReportNumberModal
                                      reportId={report.id}
                                      productPriceDetailReportId={_.get(_rec, 'id')}
                                      fieldName="price"
                                      fieldLabel={t('flat price')}
                                      defaultValue={_.get(_rec, 'best_price')}
                                    />
                                  )
                                }
                              </div>

                              <div className="flex flex-col">
                                <div className="flex justify-end">
                                  <CellNumberFormat number={_rec.best_price}/>
                                </div>

                                {
                                  !!(_.get(_rec, 'best_price') !== _.get(_rec, 'price')) && (
                                    <div className="flex italic text-line-through text-xs text-color-300 justify-end">
                                      <CellNumberFormat number={_rec.price}/>
                                    </div>
                                  )
                                }
                              </div>

                            </div>
                          )
                        }
                      },
                      {
                        title: (
                          <div className="flex flex-col truncate leading-none">
                            <div>{t('basis price')}</div>
                            <div className="font-light text-xs">
                              {
                                _.get(productPriceSet, 'price_unit2.name', t('price unit'))
                              }/
                              {
                                _.get(productPriceSet, 'unit2.name', t('unit'))
                              }
                            </div>
                          </div>
                        ),
                        key: 'price2',
                        align: 'right',
                        visible: (!_.isEmpty(productPriceSet.unit2) && !_.isEmpty(productPriceSet.price_unit2)),
                        render: (__, record) => {
                          const _rec = form.getFieldValue('data')[record.name];
                          return (
                            <div id={_rec.id} className="flex w-full space-x-3 justify-end items-center">

                              <div className="px-1">
                                {
                                  !!!readOnly && (
                                    <EditReportNumberModal
                                      reportId={report.id}
                                      productPriceDetailReportId={_.get(_rec, 'id')}
                                      fieldName="price2"
                                      fieldLabel={t('basis price')}
                                      defaultValue={_.get(_rec, 'best_price2')}
                                    />
                                  )
                                }
                              </div>

                              {
                                !!_rec.best_price2 && (
                                  <div className="flex flex-col">
                                    <div className="flex justify-end">
                                      <CellNumberFormat number={_rec.best_price2}/>
                                    </div>

                                    {
                                      !!(_.get(_rec, 'best_price2') !== _.get(_rec, 'price2')) && (
                                        <div
                                          className="flex italic text-line-through text-xs text-color-300 justify-end">
                                          <CellNumberFormat number={_rec.price2}/>
                                          <div className="flex justify-end">
                                            {' '} ({_rec.period_code})
                                          </div>
                                        </div>
                                      )
                                    }
                                  </div>
                                )
                              }

                            </div>
                          )
                        }
                      },
                    ].filter(e => e.visible)}
                    dataSource={fields}
                    bordered
                    size="small"
                  />
                </div>
              )
            }}
          </Form.List>

          <div className="py-3"/>

        </Form>
      </div>
    </div>
  )
}

