import React, {useReducer} from 'react'

export function useHook(hook, ...args) {
  return hook(...args)
}
export function UseHook({
  children,
  hook,
                          deps = []
}) {
  const hookProps = useHook(
    hook,
    ...deps
  )
  return (
    (children || null) &&
    children(hookProps)
  )
}
export default function UseReducer({
  ...props
}) {
  return (
    <UseHook
      hook={useReducer}
      {...props}
    />
  )
}
