import {Avatar, Image} from 'antd'
import {getId, getType} from 'apis/model/base'
import {default as classNames} from 'classnames'
import {FollowButton} from 'components/Feed/FollowButton'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, {useMemo} from 'react'
import {AiOutlineLink} from 'react-icons/ai'
import {BsPersonFill} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import {AppEntities} from 'views/AppLocals/enums'
import SearchContext from 'views/Search/SearchContext'
import {SearchTypes} from 'views/Search/SearchProvider'
import {Null, renderElse, renderIf} from 'views/Shared'

const EmptySpan = () => <span/>

const ResultContentLayout = ({
  extra = null,
  Wrapper = 'div',
  Header = Null,
  Content = Null,
  Footer = Null,
                               ContentWrapper = 'div',
}) => {
  return (
    <Wrapper className="w-full relative">
      <Header />
      <ContentWrapper className="flex items-center gap-5 w-full">
        <div className="flex-1">
          <Content />
        </div>
        {extra}
      </ContentWrapper>
      <Footer />
    </Wrapper>
  )
}

const renderAvatar = (item, owner) => {

  let icon
  let avatar = _.get(owner, 'avatar')

  const type = getType(item)


  const shape = [owner, type].includes(SearchTypes.ORGANIZATION)
    ? 'square'
    : 'circle'

  switch (type) {
    case AppEntities.USER:
      avatar = _.get(item, 'avatar')
      icon = <BsPersonFill className="text-color-3"/>
      break
    default:
      break
  }

  return renderIf(
    avatar,
    <Avatar
      shape={shape}
      icon={icon}
      src={avatar}
      className="flex flex-center background-200"
    />
  )
}

const getPathDocumentType = (documentType) => {
  const documentTypePath = _.get(documentType, 'title')
  if (!!_.get(documentType, 'parent')) {
    return [
      getPathDocumentType(_.get(documentType, 'parent')),
      documentTypePath,
    ].join(' - ')
  }
  return documentTypePath
}

const renderResultContent = (item, owner) => {
  const item_type = getType(item)

  const renderDetailLink = (item) => (
    <div className="flex flex-col gap-1 mb-1">
      <Link
        to={getLinkToDetail(item)}
        className="font-bold text-sm md:text-lg text-color-000 no-underline">
        <span>{getTitle(item)}</span>
        {renderIf(
          !!_.get(item, 'document_no'),
          <span className="font-medium text-sm text-color-400 italic ml-2">
            {`(${_.get(item, 'document_no')})`}
          </span>
        )}
      </Link>
    </div>
  )

  const renderTitle = (title) =>
    renderIf(
      !!title,
      <div className="italic text-xs text-color-400 leading-tight max-lines-1">
        {title}
      </div>
    )

  const category = _.get(item, 'categories', _.get(owner, 'categories', []))

  switch (item_type) {
    case AppEntities.KEYWORD:
      return (
        <ResultContentLayout
          Header={() => (
            <Link
              to={getLinkToDetail(item)}
              className="font-semibold leading-tight truncate text-color-000 no-underline">
              {_.get(item, 'keyword')}
            </Link>
          )}
          Content={() => (
            <div className="flex flex-col text-xs">
              {_.get(item, 'keyword_refs', []).map(
                (e, i) =>
                  _.get(e, 'owner') && (
                    <Link
                      className="space-x-1 text-color-400 hover:text-primary italic"
                      to={getLinkToDetail(_.get(e, 'owner'))}
                      key={i}>
                      <AiOutlineLink size={9}/>
                      <span>{getTitle(_.get(e, 'owner'))}</span>
                    </Link>
                  )
              )}
            </div>
          )}
          Footer={EmptySpan}
        />
      )

    case AppEntities.ARTICLE:
      return (
        <Translate>
          {(t) => (
            <ResultContentLayout
              Header={() => (
                <span
                  className="bg-primary-50 text-xs text-primary font-medium italic"
                  style={{
                    padding: '3px 10px',
                    borderRadius: '13px 15px 15px 0',
                  }}>
                                  {t(item_type)}
                              </span>
              )}
              Content={() => (
                <div className="flex flex-col my-1">
                  <Link
                    to={getLinkToDetail(owner)}
                    className="font-bold text-sm md:text-lg text-color-000 no-underline leading-snug">
                    {getTitle(owner)}
                  </Link>
                  <div className="text-xs md:text-sm text-color-300 max-lines-1">
                    {_.get(owner, 'description')}
                  </div>
                </div>
              )}
              Footer={() => (
                <div className="flex items-center gap-1 text-xs italic">
                                  <span className="text-color-500">
                                    {t('posted on the channel')}
                                  </span>
                  <Link
                    to={getLinkToDetail(_.get(owner, 'container'))}
                    className="font-bold text-primary flex-1">
                    {_.get(owner, 'container.title')}
                  </Link>
                </div>
              )}
            />
          )}
        </Translate>
      )

    // case AppEntities.KEYWORD:
    //   return (
    //     <Translate>
    //       {(t) => (
    //         <ResultContentLayout
    //           Header={() => (
    //             <span
    //               className="bg-primary-50 text-xs text-primary font-medium italic"
    //               style={{
    //                 padding: '3px 10px',
    //                 borderRadius: '13px 15px 15px 0',
    //               }}>
    //               {t(item_type)}
    //             </span>
    //           )}
    //           Content={() => (
    //             <div className="flex flex-col my-1">{'kakakakkakkakaa'}</div>
    //           )}
    //           Footer={() => (
    //             <div className="flex items-center gap-1 text-xs italic">
    //               {'kakakakkakkakaa 2222'}
    //             </div>
    //           )}
    //         />
    //       )}
    //     </Translate>
    //   )

    // case AppEntities.PRODUCT:
    //   return (
    //     <ResultContentLayout
    //       Header={() => <span>Product header</span>}
    //       Content={() => (
    //         <div
    //           style={{ height: 100 }}
    //           className="flex">
    //           <div
    //             className="background-200"
    //             style={{ width: 100 }}>
    //             <ImageLoader
    //               alt={item_type}
    //               src={getAvatar(item)}
    //               holder={productImageHolder}
    //               className="object-cover w-full h-full rounded-l"
    //             />
    //           </div>
    //           <div className="flex flex-col flex-1 gap-2 p-2">
    //             <h2 className="font-bold leading-tight">
    //               <Link
    //                 className="text-primary"
    //                 to={getLinkToDetail(item)}>
    //                 {getTitle(item)}
    //               </Link>
    //             </h2>
    //             <span className="text-sm">{item?.description}</span>
    //           </div>
    //         </div>
    //       )}
    //       Footer={() => <span>Product footer</span>}
    //     />
    //   )
    // case 'affiliate_item':
    //   return (
    //     <ResultContentLayout
    //       Header={EmptySpan}
    //       Content={() => (
    //         <div
    //           style={{ height: 100 }}
    //           className="flex">
    //           <div
    //             className="background-200"
    //             style={{ width: 100 }}>
    //             <ImageLoader
    //               alt={item_type}
    //               src={item?.image_url}
    //               holder={productImageHolder}
    //               className="object-cover w-full h-full rounded-l"
    //             />
    //           </div>
    //           <div className="flex flex-col flex-1 gap-2 p-2">
    //             <h2 className="font-bold leading-tight">
    //               <Link
    //                 target="_blank"
    //                 className="text-primary"
    //                 to={{ pathname: item?.affiliate_link }}>
    //                 {getTitle(item)}
    //               </Link>
    //             </h2>
    //             <div className="flex flex-col">
    //               <div className="flex-1 font-bold lg:text-base text-sm leading-tight">
    //                 {[
    //                   numeral(item?.discount).format(),
    //                   item?.price_unit_name,
    //                 ].join(' ')}
    //               </div>
    //               {item?.price !== item?.discount && (
    //                 <div
    //                   style={{ textDecorationLine: 'line-through' }}
    //                   className="flex-1 text-color-300 font-medium lg:text-xs text-2xs leading-none">
    //                   {[
    //                     numeral(item?.price).format(),
    //                     item?.price_unit_name,
    //                   ].join(' ')}
    //                 </div>
    //               )}
    //             </div>
    //           </div>
    //         </div>
    //       )}
    //       Footer={EmptySpan}
    //     />
    //   )
    default:
      if (getType(owner) === AppEntities.POST) {
        const {embed_data} = owner ?? {}
        const {title} = embed_data ?? {}
        const {photo: post_photo, title: post_title} = _.get(
          owner,
          'overview'
        )
        const url = getLinkToDetail(owner)
        return (
          <ResultContentLayout
            extra={
              post_photo && (
                <Image
                  width={40}
                  height={40}
                  className="rounded-md"
                  preview={false}
                  src={post_photo}
                />
              )
            }
            Content={() => (
              <div className="flex flex-col gap-2">
                <Link
                  className="font-medium text-color-000"
                  to={url}>
                  {title}
                </Link>
                {renderTitle(_.get(owner, 'overview.description'))}
              </div>
            )}
          />
        )
      } else {
        return (
          <ResultContentLayout
            Header={EmptySpan}
            Content={() => renderDetailLink(owner)}
            Footer={() => renderTitle(getTitle(owner))}
          />
        )
      }
  }
}

const OverlayItemBanner = ({style, title, className}) => (
  <div
    style={{
      backgroundColor: '#e8f0fd',
      color: 'var(--color-primary)',
      ...(style ?? {}),
    }}
    className={classNames(
      'w-fit text-xs italic pl-5 pr-4 pl-2 py-1 leading-tight rounded-r-full font-medium',
      className
    )}>
    {title}
  </div>
)

export const ItemTemplate = ({item, owner, showBanner = false, children}) => {
  const title = useMemo(() => {
    const type = getType(item)
    let title = type
    switch (type) {
      default:
        break
    }
  }, [item])

  return (
    <div
      className="w-full py-3 flex flex-col gap-1 border border-color-50 hover:border-color-base rounded-lg hover:shadow-items">
      {showBanner && <OverlayItemBanner title={title}/>}
      <div className="px-3">{children}</div>
    </div>
  )
}

const renderSearchResultItem = (
  {
    about,
    owner = {},
    owner_type,
    description,
    follow_status,
    total_followers,
    ...item
  },
  index
) => {
  if (!!!getId(item)) return null;

  return (
    <ItemTemplate
      key={index}
      item={item}
      owner={owner}>
      <div className="flex items-center gap-3">
        {renderAvatar(item, owner)}
        <div className="flex flex-1 flex-col items-start">
          {renderResultContent(item, owner)}
          {renderElse(
            _.isEmpty(owner),
            <SearchContext.Consumer>
              {({searchParams: {type}}) =>
                renderElse(
                  [
                    SearchTypes.ORGANIZATION,
                    SearchTypes.USER,
                  ].includes(type),
                  <div className="w-full text-right">
                    <FollowButton
                      size="small"
                      item={owner}
                    />
                  </div>
                )
              }
            </SearchContext.Consumer>
          )}
        </div>
      </div>
    </ItemTemplate>
  )
}

export default renderSearchResultItem
