import {Breadcrumb, Input} from 'antd'
import React, {useContext, useState} from 'react'
import {create_product_getProducts_Api} from '../../../apis'
import {organizationModel} from '../../../apis/model'
import useAsyncWithCache from '../../../modules/asyncCache/useAsyncWithCache'
import useTranslate from '../../../modules/local/useTranslate'
import {OrganizationContext} from '../OrganizationContext'
import {API_ROOT_URL} from "../../../envs/_current/config";
import _ from "lodash"
import {useHistory} from "../../../modules/navigation/useRouter";
import EmptyHolder from "../../../components/EmptyHolder";
import EmptyFolderStateSvg from "../../../components/Svgs/EmptyFolderStateSvg";
import ServerContext from "../../Server/ServerContext";
import {ProductPublicView} from "./components/ProductPublicView";

export function Products({view = "block"}) {
  const history = useHistory()
  const {
    groups = [],
    organization
  } = useContext(OrganizationContext)
  const {
    item,
    handleActionClick,
    contextMenu
  } = useContext(ServerContext)
  const oId = organizationModel.getId(
    organization
  )
  useAsyncWithCache({
    cacheId: `create_product_getProducts_Api[organization,${oId}]`,
    apiInfo: create_product_getProducts_Api(),
    query: {
      ':type': 'organization',
      ':id': oId
    }
  })
  useAsyncWithCache({
    cacheId: `/products/get-price-periods?day=1`,
    apiInfo: {
      root: API_ROOT_URL,
      path: `/products/get-price-periods?day=1`,
      method: 'GET'
    }
  })
  const t = useTranslate()
  const [filter, setFilter] = useState();

  return (
    <div className="p-3 space-y-6">
      <div className="flex flex-shrink-0">
        <Breadcrumb className="font-light text-sm">
          <Breadcrumb.Item>
            {organizationModel.getTitle(
              organization
            )}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t('products')}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-3">
        <section className="hidden lg:block w-full grid-cols-1 background-100">
          <div className="p-3 font-medium text-xl uppercase">
            {t('product groups')}
          </div>

          <div
            className="w-full flex-col space-y-3 sticky top-0 md:shadow-none flex flex-1 md:flex-col flex-row overflow-auto rounded background-100">
            <Input.Search
              className="block p-3"
              placeholder={t(
                'type product name'
              )}
              allowClear
              // enterButton="Search"
              size="middle"
              onSearch={value => {
                setFilter(value)
              }}
            />
            <div className="p-3">
              {
                _.isEmpty(groups) && (
                  <EmptyHolder
                    icon={
                      () => (
                        <EmptyFolderStateSvg
                          style={{opacity: 0.5}}
                          width="3rem"
                          height="3rem"
                        />
                      )
                    }
                    subTitle={null}
                  />
                )
              }
              {groups.map((o, i) => (
                <React.Fragment key={i}>
                  <a
                    id={o.title}
                    href={'#' + o.title}
                    className="py-1 font-bold mr-2 md:rounded-none border-gray-300 md:mr-0 flex-shrink-0 text-center rounded-lg border-none md:text-left cursor-pointer hover:background-200 md:border-b border-gray-300 flex items-center text-primary">
                    {o.title}
                  </a>
                  {o.products.map(
                    (p, i) => (
                      <a
                        // id={p.idname}
                        key={
                          '#' + p.idname
                        }
                        href={
                          '#' + p.idname
                        }
                        className="py-1 mr-2 md:rounded-none border-gray-300 md:mr-0 flex-shrink-0 rounded-lg md:border-none text-left cursor-pointer hover:background-200 md:border-b border-gray-300 flex items-center">
                        {' • '}
                        {p.title}
                      </a>
                    )
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </section>

        <ProductPublicView view={view}/>

      </div>
    </div>
  )
}
