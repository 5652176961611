import {createLazyComponent} from 'helpers/createLazyComponent'
import React from 'react'

const SlateEditorLazy = createLazyComponent(() => import('./SlateEditor'))

const SlateEditor = (props) => <SlateEditorLazy {...props} />

export const SlateRenderer = ({value, ...props}) => (
  <SlateEditor
    {...props}
    readOnly={true}
    placeholder={null}
    defaultValue={value}
  />
)

export default SlateEditor
