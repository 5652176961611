import { channel_getChannelsByUser_Api } from 'apis'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import React, { useMemo } from 'react'
import { renderLoading } from 'views/AppLocals/functions/renderers/renderLoading'
import { bindQueryParam } from 'views/AppLocals/functions/routerHelper'
import { renderChannelItem } from 'views/MainPage/functions/renderChannelItem'

const UserChannels = ({ user_id, ...props }) => {
  const apiInfo = channel_getChannelsByUser_Api
  const refreshKey = useMemo(
    () => [apiInfo.path.replace(':id', user_id)].join('/'),
    [user_id]
  )
  return (
    <LazyPagination
      sample={10}
      key={refreshKey}
      apiInfo={apiInfo}
      refreshKey={refreshKey}
      renderLoading={renderLoading}
      renderItem={renderChannelItem}
      query={bindQueryParam({ id: user_id })}
      {...props}
    />
  )
}

export default UserChannels
