import React from 'react'
import {APP_COPYRIGHT} from "../../envs/_current/config";

export const OrganizationLayout = ({
  admin,
  header,
  children
}) => {
  return (
    <div className="w-full">
      {admin}
      <div className="container mx-auto ">
        <div
          className="absolute"
          style={{
            top: '-50px'
          }}
          id="home" />
        {header}
        <div>{children}</div>
      </div>
        <div className="text-center py-10 sm:py-12 flex items-center justify-center">
            <div className=" background-100 rounded-full text-center">
                <div
                    style={{ minHeight: 40 }}
                    className="container px-5 py-3 mx-auto flex items-center sm:flex-row flex-col">
                    <div className="text-sm text-color-300">
                        {APP_COPYRIGHT}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
