import { Avatar, Button } from 'antd'
import { getId } from 'apis/model/base'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import { useAppConfig } from 'modules/local'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, { useContext, useMemo } from 'react'
import { IoCallSharp } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { ConversationWith } from 'views/AppLocals/factory/createChatButton'
import { FlexCol } from 'views/AppLocals/Templates/ItemTemplate'
import {
  getFullAddress,
  renderIf,
  renderMail,
  renderOwnChild,
  renderPhone,
  renderSelf,
} from 'views/Shared'
import OrganizationContainerContext from '../OrganizationContainerContext'

const OrganizationOwnerInfo = () => {
  const { organization } = useContext(OrganizationContainerContext)

  const { owner } = organization || {}

  if (_.isEmpty(owner)) return null

  const title = getTitle(owner) || ''

  return (
    <Link
      to={getLinkToDetail(owner)}
      className="flex items-center space-x-2 mb-2 text-color-org">
      <Avatar
        alt={title}
        size="large"
        shape="circle"
        src={getAvatar(owner)}
        className="border border-color-50 background-100">
        <span className="capitalize">{_.first(title)}</span>
      </Avatar>
      <span className="text-lg  font-semibold flex-1">{title}</span>
    </Link>
  )
}

const ItemInfo = ({ label, value }) =>
  renderIf(
    Boolean(value),
    <Translate>
      {(t) => (
        <div className="flex items-center gap-1 text-xs italic">
            <span className="text-color-400">
                {`${t(label)} : `}
            </span>
            <span className="flex-1 font-bold text-color-100">{value}</span>
        </div>
      )}
    </Translate>
  )

const ContactFooter = ({ item, title, Wrapper = renderOwnChild }) => {
  const t = useTranslate()

  const appConfig = useAppConfig()

  const role_titles = _.get(appConfig, 'role_titles', [])

  return (
    <Wrapper className="mt-2">
      <div className="font-medium text-xs text-color-400 italic uppercase border-b pb-1 mb-2">
          {t('contact details')}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
        {[
          {
            name: 'prefix_name',
            label: 'representative',
            transform: (value) => [t(value), title].join(' '),
          },
          {
            name: 'role_title',
            label: 'position',
            transform: (value) => _.get(role_titles, value),
          },
          {
            name: 'phone',
            label: 'phone',
            transform: renderPhone,
          },
          {
            name: 'phone2',
            label: 'alternative phone',
            transform: renderPhone,
          },
          {
            name: 'email',
            label: 'email',
            transform: renderMail,
          },
        ].map(({ name, label, transform = renderSelf }, index) => (
          <ItemInfo
            key={index}
            label={label}
            value={transform(_.get(item, name))}
          />
        ))}
      </div>
    </Wrapper>
  )
}

const ContactActions = ({ phone, contact_member, organization_id }) => {
  const { isSm } = useContext(LayoutContext)

  const { user } = contact_member || {}

  return (
    <div className="flex flex-col gap-1">
      {contact_member && (
        <ConversationWith
          params={{
            receiver_id: getId(user),
          }}
        />
      )}
      <span className="no-underline">
        {renderPhone(
          phone,
          (value) => (
            <Button
              type="primary"
              className="w-full button-rounded-lg no-shadow bg-primary-50 hover:bg-primary-100 text-primary-600 no-border flex flex-center gap-3"
              icon={<IoCallSharp />}>
              {value}
            </Button>
          ),
          ({ href, children }) => (
            <a
              href={href}
              className="no-underline">
              {children}
            </a>
          )
        )}
      </span>
    </div>
  )
}

const ContactInfo = ({ organization_id, ...contact }) => {
  const { location } = contact || {}

  /*const address = useMemo(() => {
    const fieldNames = [
      'address',
      'ward.location_name',
      'district.location_name',
      'province.location_name',
      'country.location_name',
    ]
    const fieldValues = _.pick(
      location,
      fieldNames
    )
    return fieldNames
      .map((name) =>
        _.get(fieldValues, name)
      )
      .join(',')
  }, [contact])*/

  const address = getFullAddress(location)

  if (_.isEmpty(contact)) return null

  return (
    <div className="p-3 border border-color-50 hover:shadow-items rounded-lg">
      <div className="flex items-center gap-2">
        <div className="flex flex-col flex-1">
          <span className="font-bold text-color-org uppercase mb-2">
            {_.get(location, 'location_name')}
          </span>
          <ItemInfo
            label="address"
            value={address}
          />
        </div>
        <ContactActions
          {..._.pick(contact, ['phone', 'contact_member'])}
          organization_id={organization_id}
        />
      </div>
      <ContactFooter
        Wrapper={FlexCol}
        item={_.pick(contact, [
          'email',
          'phone',
          'phone2',
          'role_title',
          'prefix_name',
        ])}
        title={getTitle(contact)}
      />
    </div>
  )
}

const OrganizationContactInfo = ({ Wrapper = FlexCol }) => {
  const { organization } = useContext(OrganizationContainerContext)

  const contacts = Array.from(organization?.contacts || [])

  const organization_id = getId(organization)

  const body = useMemo(
    () =>
      contacts.map((contact, index) => (
        <ContactInfo
          key={index}
          {...contact}
          organization_id={organization_id}
        />
      )),
    [contacts, organization_id]
  )

  if (_.isEmpty(contacts)) {
    return null
  }

  return (
    <Wrapper className="items-start space-y-6">
      <HeaderItemInfo title="contact information">
        <div className="space-y-3">{body}</div>
      </HeaderItemInfo>
    </Wrapper>
  )
}

export default OrganizationContactInfo
