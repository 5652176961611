import EllipsisOutlined from '@ant-design/icons/EllipsisOutlined'
import Toggle from 'components/Toggle'
import React, {
  cloneElement,
  useContext
} from 'react'
import { LayoutContext } from '../layouts/Default/LayoutContext'
import DrawerBottomMenu from './DrawerBottomMenu'
import DropDownMenu from './DropDownMenu'

export const DrawMenu = ({
  component = <EllipsisOutlined />,
  container = 'root',
  children,
  menuPlacement = 'bottomRight',
  ...props
}) => {
  const { isSm } = useContext(
    LayoutContext
  )

  return isSm ? (
    <Toggle>
      {(isToggle, toggle) => {
        return (
          <>
            {cloneElement(component, {
              ...component.props,
              onClick: toggle
            })}
            {!!isToggle && (
              <DrawerBottomMenu
                {...{
                  isToggle,
                  toggle
                }}
                component={component}>
                {children}
              </DrawerBottomMenu>
            )}
          </>
        )
      }}
    </Toggle>
  ) : (
    <DropDownMenu
      className="p-0"
      container={container}
      component={component}
      placement={menuPlacement}>
      {children}
    </DropDownMenu>
  )
}

export default DrawMenu
