import classNames from 'classnames'
import useTranslate from 'modules/local/useTranslate'
import React, { useMemo } from 'react'
import { FiChevronsRight } from 'react-icons/fi'
import { Link } from 'react-router-dom'

const Chervron = ({ title }) => (
  <React.Fragment>
    <span className="pr-1">{title}</span>
    <FiChevronsRight className="my-auto" />
  </React.Fragment>
)

const SeeMore = ({
  to,
  className,
  title = 'see more',
  Component = Link,
  renderItem = (render) => render(),
}) => {
  const t = useTranslate()
  const more = useMemo(() => <Chervron title={t(title)} />, [])
  const render = useMemo(
    () => (props) =>
      (
        <Component
          className="flex cursor-pointer"
          to={to ?? '#'}
          {...props}>
          {more}
        </Component>
      ),
    [to]
  )

  return (
    <div className={classNames('bottom-0 flex w-full', className)}>
      <div className="py-3 px-3 flex-wrap flex w-full justify-center text-color-300 italic text-xs">
        <div className="flex-1" />
        {renderItem(render)}
      </div>
    </div>
  )
}

export default SeeMore
