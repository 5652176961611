import {Alert, Avatar, Button, Input, Menu, Select, Transfer} from 'antd'
import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import {SelectEntityItem} from 'components/SelectEntityItem'
import Toggle from "components/Toggle"
import _ from 'lodash'
import {createAsyncAction} from "modules/asyncCache"
import useTranslate from 'modules/local/useTranslate'
import React, {useState} from 'react'
import Partner from "./Partner.lazy"
import {
  organizationPartnerSchema,
  partners_deletePartner_Api,
  partners_editPartner_Api,
  partners_getIdentifiedPartners_Api
} from "../../../../apis/organizationPartner";
import DrawMenu from "../../../../components/drawer/DrawMenu";
import MoreOutlined from "@ant-design/icons/lib/icons/MoreOutlined";
import TagOutlined from "@ant-design/icons/lib/icons/TagOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import {showConfirm} from "../../../../components/Feed/TimeLineFeed";
import TagFilled from "@ant-design/icons/lib/icons/TagFilled";
import {LazyPagination} from "../../../../modules/asyncCache/components/LazyPagination";
import {AsyncWithCache} from "../../../../modules/asyncCache/components/Async";
import organization, {getLinkToDetail} from "../../../../apis/model/organization";
import {create_product_getAllProducts_Api} from "../../../../apis";
import {useToggle} from "react-use";
import FireOutlined from "@ant-design/icons/lib/icons/FireOutlined";
import TagsFilled from "@ant-design/icons/lib/icons/TagsFilled";
import FilterOutlined from "@ant-design/icons/lib/icons/FilterOutlined";
import useDispatchAsyncActionWithNotify from "../../../../modules/asyncCache/useDispatchAsyncActionWithNotify";
import {Link} from "react-router-dom";
import ListHeader from "../../../AppLocals/Templates/items/ListHeader";
import {AiFillFilter} from "react-icons/ai";
import {BsThreeDots, BsTrash3} from "react-icons/bs";
import {OrgType} from "../../../AppLocals/Organization/components/OrgType";
import {FaWarehouse} from "react-icons/fa6";
import Count from "../../../../components/Feed/Count";
import {FiEdit} from "react-icons/fi";

const PartnerItem = ({e, setUpdate, organization}) => {
  const t = useTranslate()
  const [
    data,
    dispatch
  ] = useDispatchAsyncActionWithNotify({
    onSuccess: () => {
      setUpdate(Date.now());
    }
  })
  const [isToggleEdit, toggleEdit] = useToggle(false);
  const [isToggleAddProduct, toggleAddProduct] = useToggle(false);
  const [targetKeys, setTargetKeys] = useState(_.get(e, 'products', []).map(e => e.id));
  const org_partner = _.get(e, 'partner_organization')

  return (
      <>
          <div className="w-full flex flex-col gap-3 border border-color-50 hover:shadow-items rounded-lg p-3 relative">

              <div id={e.id} style={{ right: 5 }} className="absolute">
                  <DrawMenu
                      container={e.id}
                      component={
                          <Count>
                              <BsThreeDots size={12} className="text-color-400"/>
                          </Count>
                      }>
                      {( isToggle, toggle ) => (
                          <Menu
                              mode="inline"
                              onClick={({key}) => {
                                  switch (key) {
                                      case "add-product":
                                          toggleAddProduct();
                                          break;
                                      case "edit":
                                          toggleEdit();
                                          break;
                                      case "delete":
                                          showConfirm({
                                              title: t('are you sure delete partner this'),
                                              onOk: () => {
                                                  dispatch(
                                                      createAsyncAction(
                                                          {
                                                              apiInfo: partners_deletePartner_Api,
                                                              query: {
                                                                  ':id':
                                                                  e.id
                                                              }
                                                          }
                                                      )
                                                  );

                                                  setTimeout(() => {
                                                      setUpdate(Date.now());
                                                  }, 2000)
                                              },
                                              className: 'custom-modal',
                                              okText: t('sure'),
                                              cancelText: t('ignore'),
                                              okButtonProps: {
                                                  type: 'primary',
                                                  danger: true,
                                                  className: 'button-rounded-lg no-border',
                                              },
                                              cancelButtonProps: {
                                                  className: 'button-rounded-lg no-border',
                                              },
                                          })
                                          break;
                                      default:
                                  }
                              }}
                              style={{minWidth: 180}}>
                              <Menu.Item key="add-product" icon={<FireOutlined/>}>{t('products for partner')}</Menu.Item>
                              <Menu.Item key="edit" icon={<FiEdit/>}>{t('edit')}</Menu.Item>
                              <Menu.Item key="delete" icon={<BsTrash3 />}>{t('delete')}</Menu.Item>
                          </Menu>
                      )}
                  </DrawMenu>
              </div>

              <div className="flex items-center gap-3">
                  <Avatar
                      size={35}
                      icon={ <FaWarehouse size={17} className="text-color-500"/> }
                      src={org_partner.avatar}
                      className="flex flex-center background-200"
                  />
                  <div className="flex flex-col">
                      <div className="flex flex-1 font-bold text-color-000">
                          {!!_.get(e, 'partner_organization') ?
                              <Link to={getLinkToDetail(_.get(e, 'partner_organization'))} className="no-underline">
                                  {_.get(e, 'partner_name')}
                              </Link>
                              : (!!_.get(e, 'partner_name') && (
                                  <span>
                                      {_.get(e, 'partner_name')}
                                  </span>
                              ))
                          }
                      </div>
                      <OrgType
                          item={e}
                          className="font-medium text-xs text-primary-600 italic leading-snug"
                      />
                      {!!_.get(e, 'tax_code') && (
                          <div className="flex items-center gap-1 text-xs italic">
                              <span className="text-xs text-color-500">
                                  {`${t('tax code')} : `}
                              </span>
                              <span className="font-bold text-color-000">
                                  {_.get(e, 'tax_code')}
                              </span>
                          </div>
                      )}
                      {!!_.get(e, 'partner_tags') && (
                          <div className="flex space-x-3">
                              {(e.partner_tags || "").split(",").map((e, i) => (
                                  <div key={i}
                                       className="flex items-center gap-1 px-2 background-100 border rounded-full">
                                      <TagFilled/>
                                      <div className="font-bold text-xs text-color-300 italic">
                                          {t(e)}
                                      </div>
                                  </div>
                              ))}

                              <DrawMenu
                                  component={
                                      <Avatar
                                          size="small"
                                          className="background p-1 flex items-center justify-center border hover:background-100"
                                          shape="circle"
                                          icon={<TagsFilled/>}>
                                      </Avatar>
                                  }>
                                  {( isToggle, toggle ) => (
                                      <Menu mode="inline"
                                            onClick={({key}) => {
                                                switch (key) {
                                                    case "vendor":
                                                    case "customer":
                                                        let partner_tags = (e.partner_tags || "").split(",");
                                                        if (partner_tags.includes(key)) {
                                                            partner_tags = partner_tags.filter(e => !!e && e !== key);
                                                        } else {
                                                            partner_tags.push(key)
                                                        }
                                                        partner_tags = partner_tags.filter(e => !!e);

                                                        dispatch(
                                                            createAsyncAction(
                                                                {
                                                                    apiInfo: partners_editPartner_Api,
                                                                    query: {
                                                                        ':id':
                                                                        e.id
                                                                    },
                                                                    values: {
                                                                        partner_tags: partner_tags.join(",")
                                                                    }
                                                                }
                                                            )
                                                        );
                                                        setTimeout(() => {
                                                            setUpdate(Date.now());
                                                        }, 2000);
                                                        break;
                                                    default:
                                                }
                                            }}
                                            style={{minWidth: 180}}>
                                          {
                                              ['vendor', 'customer'].map((ee, eei) => (
                                                  <Menu.Item className="flex items-center" key={ee}>
                                                      <div
                                                          className="flex items-center space-x-3 border-l-2 rounded-md px-2 text-sm">
                                                          {
                                                              (e.partner_tags || "").includes(ee) ? (
                                                                  <TagFilled className={"text-primary"}/>
                                                              ) : (
                                                                  <TagOutlined/>
                                                              )
                                                          }
                                                          <div className="">
                                                              {t(ee)}
                                                          </div>
                                                      </div>
                                                  </Menu.Item>
                                              ))
                                          }
                                      </Menu>
                                  )}
                              </DrawMenu>
                          </div>
                      )}
                  </div>
              </div>

          </div>
          {
              !!isToggleEdit && (
                  <FullSideModalLayout
                      right
                      onCancel={toggleEdit}
                      title={
                          <div className="font-bold">
                              {t('edit partner')}
                          </div>
                      }>
                      <Partner
                          item={organization}
                          partner={e}
                          onSuccess={() => {
                              setUpdate(Date.now());
                              toggleEdit()
                          }}
                      />
                  </FullSideModalLayout>
              )
          }

          {
              !!isToggleAddProduct && (
                  <FullSideModalLayout
                      right
                      onCancel={toggleAddProduct}
                      title={
                          <div className="font-bold">
                              {t('products can view')}
                          </div>
                      }>

                      <div className="px-3 space-y-3">
                          <div className="space-x-1 text-sm italic">
                              <span className="font-semibold text-color-300">{`* ${t('note')} : `}</span>
                              <span className="text-color-400">
                                  {t('Select the products for which you want to send price information to your partner')}
                              </span>
                          </div>
                          <AsyncWithCache
                              key={
                                  ("/products/:type/:id/get-all-products")
                                      .replace(":type", "organization")
                                      .replace(":id", organization.idname)
                                  + "_status_1"
                                  + "_active_1"
                              }
                              cacheId={
                                  ("/products/:type/:id/get-all-products")
                                      .replace(":type", "organization")
                                      .replace(":id", organization.idname)
                                  + "_status_1"
                                  + "_active_1"
                              }
                              apiInfo={create_product_getAllProducts_Api({
                                  query: {
                                      ':type': "organization",
                                      ':id': organization.idname
                                  }
                              })}
                              values={
                                  {
                                      status: 1,
                                      active: 1
                                  }
                              }>
                              {({result, response}) => {
                                  const data = _.get(
                                      response,
                                      'data.data',
                                      []
                                  ).map(e => ({...e, key: e.id}));

                                  return (
                                      <div className="flex flex-col space-y-3">
                                          <Transfer
                                              titles={[
                                                  <span className="font-semibold text-color-000 italic">{t('products')}</span>,
                                                  <span className="font-semibold text-color-000 italic">{t('partner can view')}</span>
                                              ]}
                                              listStyle={{width: '45%', height: 'calc(100vh - 220px)'}}
                                              operationStyle={{width: '6%', alignItems: 'center'}}
                                              dataSource={data}
                                              targetKeys={targetKeys}
                                              onChange={(newTargetKeys, direction, moveKeys) => {
                                                  setTargetKeys(newTargetKeys);
                                              }}
                                              render={item => item.title}
                                              oneWay={true}
                                          />
                                          <div className="flex">
                                              <div className="flex-1"/>
                                              <Button
                                                  type="primary"
                                                  className="button-rounded-md no-border"
                                                  onClick={() => {
                                                      dispatch(
                                                          createAsyncAction({
                                                              apiInfo: partners_editPartner_Api,
                                                              query: {':id': e.id},
                                                              values: { product_ids: targetKeys.join(",") }
                                                          })
                                                      )
                                                  }}>
                                                  {t('save')}
                                              </Button>
                                          </div>
                                      </div>
                                  )
                              }}
                          </AsyncWithCache>
                      </div>
                  </FullSideModalLayout>
              )
          }
      </>
  )
}

const PartnerProductBroadcast = ({item}) => {
  const t = useTranslate();
  const [keyword, setKeyword] = useState("");
  const [partnerTypes, setPartnerTypes] = useState([]);
  const [update, setUpdate] = useState(Date.now());

  return (
      <div className="flex flex-col gap-3">
          <ListHeader title="partners" />
          <div className="w-full flex flex-col gap-3">
              <div className="space-x-1 text-sm italic">
                  <span className="font-semibold text-color-300">{`* ${t('note')} : `}</span>
                  <span className="text-color-400">
                      {t('the list below shows only partners linked to the organizations on FeedIn')}
                  </span>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-3 md:gap-3">
                  <Input.Search
                      allowClear
                      className="w-full"
                      placeholder={t('keyword: name, tax number, phone number, email')}
                      onSearch={(e) => setKeyword(e) }
                  />

                  <div className="flex items-center gap-3">
                      <Select
                          mode="multiple"
                          allowClear
                          className="w-full"
                          placeholder={t('filter')}
                          onChange={(value) => {
                              setPartnerTypes(value);
                          }}
                          options={['vendor', 'customer'].map(e => ({id: e, value: e, label: t(e)}))}
                          showArrow
                          suffixIcon={<AiFillFilter className="text-color-400" />}
                      />
                      <Toggle>
                          {(isToggle, toggle) => (
                              <>
                                  <Button
                                      type="primary"
                                      className="button-rounded-md no-border px-6"
                                      onClick={toggle}>
                                      {t('create new')}
                                  </Button>
                                  {isToggle && (
                                      <FullSideModalLayout
                                          right
                                          onCancel={toggle}
                                          title={
                                              <div className="font-bold">
                                                  {t('new partner')}
                                              </div>
                                          }>
                                          <Partner
                                              item={item}
                                              onSuccess={() => {
                                                  setUpdate(Date.now());
                                                  toggle()
                                              }}
                                          />
                                      </FullSideModalLayout>
                                  )}
                              </>
                          )}
                      </Toggle>
                  </div>
              </div>

              <div key={update + keyword + partnerTypes.join(",")} className="flex flex-col space-y-3">
                  <LazyPagination
                      prefixStr={`Partners{created:${update + keyword + partnerTypes.join(",")}}`}
                      apiInfo={partners_getIdentifiedPartners_Api}
                      query={{
                          ':id': item.idname
                      }}
                      values={{
                          partner_type: partnerTypes.join(","),
                          keyword
                      }}
                      renderItem={(e) => (
                          <SelectEntityItem key={e.id} item={e.id} schema={organizationPartnerSchema}>
                              {(e) => (
                                  <PartnerItem
                                      e={e}
                                      setUpdate={setUpdate}
                                      organization={item}
                                  />
                              )}
                          </SelectEntityItem>
                      )
                      }
                  />
              </div>
          </div>
      </div>
  )
}

export default PartnerProductBroadcast
