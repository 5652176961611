import {Input} from 'antd'
import {Pure} from 'components/Pure'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {IoChevronDown, IoChevronUp} from 'react-icons/io5'
import {useLocation, useSearchParam, useToggle} from 'react-use'
import {Null, renderElse, renderOwnChild, renderSelf} from 'views/Shared'
import {DefaultAdvancedToggle, ExpandablePanel,} from '../custom/AdvancedFormGroup'
import classNames from "classnames"
import EntityList, {EntityPaginationMeta} from "../EntityList";

const EmptySortBy = ({value, onSelect}) => null

const EmptyAdvancedFilter = ({params, value, onChange}) => null

const renderDefaultSort = (child, {title}) => (
  <div className="flex flex-col space-y-2">
    <div className="flex items-center justify-between">
      <span className="uppercase text-sm font-semibold text-color-200">
        {title}
      </span>
      <div className="flex items-end justify-end">{child}</div>
    </div>
  </div>
)

export const createPagerKey = (keys = []) => {
  const array = Array.from(keys || []).map((e) =>
    _.isString(e) ? e : JSON.stringify(e)
  )
  return ({sortBy, keyword, filterBy}) =>
    [sortBy, keyword, ...array, JSON.stringify(filterBy)].join('/')
}

export const handleFilter =
  ({searchParams, onFilter = Null, filter_actions = {}}) =>
    (params = {}) => {
      let search_params = new URLSearchParams(searchParams)

      Object.keys(params).forEach((name) => {
        const param_value = params[name]
        const filter_name = _.get(filter_actions, name)
        if (param_value && filter_name) {
          search_params.set(filter_name, param_value)
        } else {
          search_params.delete(filter_name)
        }
      })

      onFilter({
        search: '?' + search_params.toString(),
      })
    }

const ListFilter = ({
                      filter,
                      sortBy,
                      params,
                      keyword,
                      onSearch = Null,
                      noHeader = false,
                      sortHeader = null,
                      onSortChange = Null,
                      onFilterChange = Null,
                      isToggleDefault = false,
                      Wrapper = renderOwnChild,
                      renderSearchBar = renderSelf,
                      SortByWidget = EmptySortBy,
                      renderSortBy = renderDefaultSort,
                      AdvancedFilter = EmptyAdvancedFilter,
                      AdvancedToggle = DefaultAdvancedToggle,
                      renderFilter = (child, filter_widget) => child,
                      renderHeader = ([searchBar, filterBar]) => (
                        <React.Fragment>
                          {filterBar}
                          {searchBar}
                        </React.Fragment>
                      ),
                    }) => {
  const t = useTranslate()

  const [isToggle, toggle] = useToggle(isToggleDefault)

  useEffect(() => {
    if (_.some(Object.values(filter), (value) => !!value)) {
      toggle(true)
    }
  }, [filter])

  const filter_widget = (
    <AdvancedFilter
      value={filter}
      onChange={onFilterChange}
      {...(params ?? {})}
    />
  )

  const search_bar = renderSearchBar(
    <Input.Search
      defaultValue={keyword}
      onSearch={(value) => onSearch(value)}
      allowClear={true}
      className="input-search"
      placeholder={t('search')}
    />
  )

  const filter_bar = renderFilter(
    <div
        style={{ backgroundColor: isToggle && '#fafffd' }}
        className={isToggle ? "border border-primary-600 p-2 rounded-md" : "border-b border-color-50 pb-1 mb-2"}>
      <ExpandablePanel
        onClick={toggle}
        Header={AdvancedToggle}
        expanded={isToggle}
        icons={[IoChevronDown, IoChevronUp]}
        title="advanced filter">
        {filter_widget}
      </ExpandablePanel>
    </div>,
    filter_widget
  )

  return (
    <Wrapper>
      {renderElse(
        noHeader,
        <div className="flex flex-col space-y-2">
          {renderHeader([search_bar, filter_bar])}
        </div>
      )}
      {renderSortBy(
        <SortByWidget
          value={sortBy ?? undefined}
          onSelect={onSortChange}
        />,
        {title: sortHeader}
      )}
    </Wrapper>
  )
}

const usePaginationHelper = ({
                               noHeader,
                               pagerInfo,
                               sortHeader,
                               renderList,
                               renderFilter,
                               renderSortBy,
                               renderHeader,
                               SortByWidget,
                               filter_params,
                               onInit = Null,
                               AdvancedFilter,
                               AdvancedToggle,
                               compact = false,
                               renderSearchBar,
                               dependencies = [],
                               query_params = {
                                 sortBy: 'sort-by',
                                 keyword: 'keyword',
                               },
                               withKey = renderSelf,
                               withValues = renderSelf,
                               isToggleDefault = false,
                               handleFilter: handleCustomFilter,
                             }) => {
  const history = useHistory()

  const location = useLocation()

  const [sortBy, setSortBy] = useState()

  const [filterBy, setFilterBy] = useState({})

  const {sortBy: sort_query, keyword: keyword_query} = query_params ?? {}

  const keyword = useSearchParam(keyword_query)

  const handleSearch = useCallback(
    (keyword) => {
      let search_params = new URLSearchParams(location.search)
      if (keyword) {
        search_params.set(keyword_query, keyword)
      } else {
        search_params.delete(keyword_query)
      }
      history.push({
        search: '?' + search_params.toString(),
        hash: location.hash,
      })
    },
    [location.search, location.hash]
  )

  useEffect(() => {
    onInit({
      onSortChange: setSortBy,
      onFilterChange: true
        ? setFilterBy
        : (values) => {
          setFilterBy(_.omitBy(values, _.isEmpty))
        },
    })
  }, [location.search, location.state])

  const onSortChange = useCallback(
    (value) => {
      let search_params = new URLSearchParams(location.search)
      if (value) {
        search_params.set(sort_query, value)
      } else {
        search_params.delete(sort_query)
      }
      history.push({
        search: '?' + search_params.toString(),
      })
    },
    [location.search]
  )

  const refreshKey = withKey({
    sortBy,
    keyword,
    filterBy,
  })

  const [widget_header, widget, data_widget] = useMemo(() => {
    const data = (
      <Pure
        input={[
          sortBy,
          keyword,
          filterBy,
          renderList,
          refreshKey,
          dependencies,
        ]}>
        {_.isFunction(renderList) ? (
          renderList({
            sortBy,
            keyword,
            filterBy,
            refreshKey,
          })
        ) : (
          <EntityList
            key={refreshKey}
            Header={({meta}) => meta}
            PaginationMeta={EntityPaginationMeta}
            values={withValues({
              keyword,
              sort_by: sortBy,
              ...(filterBy ?? {}),
            })}
            {...(pagerInfo ?? {})}
          />
        )}
      </Pure>
    )
    const header = renderElse(
      compact,
      <ListFilter
        {...{
          sortBy,
          keyword,
          noHeader,
          sortHeader,
          renderHeader,
          SortByWidget,
          renderFilter,
          renderSortBy,
          onSortChange,
          AdvancedFilter,
          AdvancedToggle,
          renderSearchBar,
          isToggleDefault,
        }}
        filter={filterBy}
        params={filter_params}
        onSearch={handleSearch}
        onFilterChange={
          _.isFunction(handleCustomFilter)
            ? handleCustomFilter
            : handleFilter({
              searchParams: location.search,
              filter_actions: query_params,
              onFilter: ({search}) =>
                history.push({
                  search,
                }),
            })
        }
      />
    )
    return [
      header,
      <React.Fragment>
        {header}
        {data}
      </React.Fragment>,
      data,
    ]
  }, [
    compact,
    keyword,
    sortBy,
    filterBy,
    refreshKey,
    renderList,
    renderHeader,
    location.search,
  ])

  return [
    widget,
    {
      sortBy,
      keyword,
      filterBy,
      setSortBy,
      data_widget,
      setFilterBy,
      handleSearch,
      widget_header,
      onFilterChange: (params) =>
        _.isFunction(handleCustomFilter)
          ? handleCustomFilter
          : handleFilter({
            searchParams: location.search,
            filter_actions: query_params,
            onFilter: ({search}) =>
              history.push({
                search,
              }),
          })(params),
    },
  ]
}

export default usePaginationHelper
