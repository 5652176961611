import {Avatar, List} from 'antd'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import moment from 'moment'
import React from 'react'
import {Link} from 'react-router-dom'
import {IoNewspaperOutline} from "react-icons/io5";

export const renderHomeRepost = (item, index) => {
  const repost = _.get(item, 'content')

  return (
    <Translate key={index}>
      {(t) => (
            <div className="flex items-center gap-2 md:gap-3">
              <Avatar
                  shape="square"
                  src={_.get(repost, 'overview.photo')}
                  size={40}
                  icon={<IoNewspaperOutline className="text-color-500"/>}
                  className="flex items-center justify-center background-200 rounded-md"
              />
              <div className="flex flex-col flex-1">
                <Link
                    to={getLinkToDetail(repost)}
                    className="font-bold text-sm text-color-000 no-underline max-lines-1">
                  {_.get(repost, 'overview.title')}
                </Link>
                <div className="flex items-baseline">
                  <div className="flex items-baseline flex-1 gap-1 italic">
                    <span className="text-2xs text-color-400">
                      {t('reposts by')}
                    </span>
                    <Link
                        to={getLinkToDetail(_.get(repost, 'creator'))}
                        className="text-xs font-medium text-primary-600 flex-1 max-lines-1 leading-tight">
                      {getTitle(_.get(repost, 'creator'))}
                    </Link>
                  </div>
                  <span className="text-2xs text-color-400 italic ml-3">
                    {moment(_.get(item, 'created')).format('DD/MM/YY')}
                  </span>
                </div>
              </div>
            </div>
      )}
    </Translate>
  )
}
