import Null from 'components/NullComponent'
import React from 'react'

const ServerContext = React.createContext(
  {
    onFollow: Null,
      item: {},
    routes: [],
    current: '',
    setCurrent: Null
  }
)
export default ServerContext
