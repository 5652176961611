import { API_ROOT_URL } from 'envs/_current/config'
import { memberSchema } from './member'
import { createTransform } from './utils/createTransform'
// subscription
// Route::post('channels/channel/{channel_id}/user/{user_id}/set-subscription', 'ChannelSubscriptionController@manageChannelSubscriptionAdd'); // nothing <--- cho channel
export const subscription_manageChannelSubscriptionAdd_Api = {
  name: 'manageChannelSubscriptionAdd',
  root: API_ROOT_URL,
  path:
    '/channels/channel/:id/user/:user_id/set-subscription',
  method: 'POST',
  transform: createTransform(
    memberSchema,
    'data'
  )
}

export const subscription_manageDatasetSubscriptionAdd_Api = {
  name: 'manageDatasetSubscriptionAdd',
  root: API_ROOT_URL,
  path:
    'datasets/dataset/:id/user/:user_id/set-subscription',
  method: 'POST',
  transform: createTransform(
    memberSchema,
    'data'
  )
}
export const subscription_subscriptionAdd_Api_creator = (type) => {
  switch (type) {
    case 'channel':
      return subscription_manageChannelSubscriptionAdd_Api
    case 'dataset':
      return subscription_manageDatasetSubscriptionAdd_Api

    default:
      throw new Error('type not valid')
  }
}