import DoubleRightOutlined from '@ant-design/icons/lib/icons/DoubleRightOutlined'
import { Skeleton } from 'antd'
import {
  auth_fetchSuggestionChannelsByAuthCategories_Api,
  auth_fetchSuggestionDatasetByAuthCategories_Api,
  get_editorChoiceWidgetFeeds_Api,
  get_typicalChartsFeeds_Api,
  post_dashboard_Api
} from 'apis'
import _ from 'lodash'
import React, {
  Suspense,
  useContext,
  useEffect,
  useState
} from 'react'
import { AiOutlinePushpin } from 'react-icons/ai'
import { ImNewspaper } from 'react-icons/im'
import { VscPinned } from 'react-icons/vsc'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-use'
import {
  auth_getMyFollowingChannels_Api,
  feed_fetchEvents_Api
} from '../../../apis'
import { auth_getMyFollowingDataset_Api } from '../../../apis/auth'
import { dataset_getChartsByFollowingDataset_Api } from '../../../apis/chart'
import { get_authUserChoiceWidgetFeeds_Api } from '../../../apis/post'
import EmptyHolder from '../../../components/EmptyHolder'
import { LayoutContext } from '../../../components/layouts/Default/LayoutContext'
import { ContentLanguageSwitch } from '../../../components/layouts/Default/SiderMenu/ContentLanguageSwitch'
import LazyComponent from '../../../components/LazyComponent'
import { LoginContext } from '../../../components/LoginContext'
import StickyFrame from '../../../components/StickyFrame'
import UseState from '../../../components/UseState'
import { emptyObject } from '../../../helpers/emptyObjects'
import useQueryString from '../../../hooks/useQueryString'
import CustomLazyPagination from '../../../modules/asyncCache/components/CustomLazyPagination'
import CustomLazyPaginationWrapper from '../../../modules/asyncCache/components/CustomLazyPaginationWrapper'
import { LazyPagination } from '../../../modules/asyncCache/components/LazyPagination'
import { LocalContext } from '../../../modules/local/LocalContext'
import useTranslate from '../../../modules/local/useTranslate'
import { useHistory } from '../../../modules/navigation/useRouter'
import { DelayRender } from '../../Discovery/DelayRender'
import { WidgetList } from '../../Feed/components/WidgetList'
import { WidgetListByCategory } from '../../Feed/components/WidgetListByCategory'
import { WeatherList } from '../Category'
import { renderChartItemRow } from '../functions/renderChartItemRow'
import { renderChannelItem } from './../functions/renderChannelItem'
import { renderDatasetItem } from './../functions/renderDatasetItem'
import { renderEventItem } from './../functions/renderEventItem'
import { renderFeedItem } from './../functions/renderFeedItem'
import { renderHeadlineFeedItem } from './../functions/renderNewsItem'
import ToggleView from './../ToggleView'
import {renderIf} from "../../Shared";
import {HiMiniArchiveBox} from "react-icons/hi2";
import {BsPinAngleFill} from "react-icons/bs";
import {HighLightTitle} from "../../AppLocals/Organization/OrganizationEntity";
import classNames from 'classnames'

export const MarketOverview = ({}) => {
  const t = useTranslate()
  const { categories = [] } =
    useContext(LoginContext) ||
    emptyObject
  const location = useLocation()
  const [
    { category: categoryId, ...rest },
    go
  ] = useQueryString(location.pathname)

  const key =
    categoryId ||
    `[${categories
      .map(c => {
        return c.idname
      })
      .toString()}]`

  const displayCategories = !_.isEmpty(
    categoryId
  )
    ? _.filter(categories, {
        id: categoryId
      })
    : categories

  return (
    <div className="space-y-3">
        <HeaderBlock title="vietnam market" />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div className="space-y-3">
                <DelayRender>
                    {() =>
                        !!displayCategories.length &&
                        displayCategories.map(
                            (item, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <LazyComponent>
                                            <Suspense fallback={null}>
                                                <WidgetListByCategory
                                                    categoryId={item.id}
                                                    title={item.name}
                                                />
                                            </Suspense>
                                        </LazyComponent>
                                    </React.Fragment>
                                )
                            }
                        )
                    }
                </DelayRender>
            </div>

            <div className="space-y-3">
                <HighLightTitle title="overview"/>
                <DelayRender time={100}>
                    <LazyComponent>
                        <Suspense fallback={null}>
                            <WidgetList />
                        </Suspense>
                    </LazyComponent>
                </DelayRender>
            </div>
        </div>
    </div>
  )
}


const DataAuthUsersChoiceFeeds = ({
    categoryId
}) => {
    const t = useTranslate()
    const history = useHistory()
    const { categories = [] } =
    useContext(LoginContext) || {}

    const key =
        categoryId ||
        `[${categories
            .map(c => {
                return c.idname
            })
            .toString()}]`
    const location = useLocation()
    const [
        { tab = 'following', ...rest },
        go
    ] = useQueryString(location.pathname)
    const handleChangeTab = tab => {
        go({
            ...rest,
            tab
        })
    }
    return (
        <UseState initialValue={1}>
            {([limit, setLimit]) => (
                <CustomLazyPaginationWrapper
                    alwaysVisible={true}
                    sample={limit}
                    id={
                        key +
                        get_authUserChoiceWidgetFeeds_Api.path +
                        '_category_' +
                        categoryId +
                        '_following_1'
                    }
                    key={
                        key +
                        get_authUserChoiceWidgetFeeds_Api.path +
                        '_category_' +
                        categoryId +
                        '_following_1'
                    }
                    apiInfo={
                        get_authUserChoiceWidgetFeeds_Api
                    }
                    values={
                        categoryId
                            ? {
                                following: 1,
                                category_id: categoryId
                            }
                            : {
                                following: 1
                            }
                    }
                    renderLoading={() => (
                        <div className="w-full h-screen flex justify-center">
                            <Skeleton
                                className="p-3"
                                active
                            />
                        </div>
                    )}
                    renderItem={renderFeedItem}
                    renderEmpty={() => (
                        <div className="w-full justify-center items-center border border-color-50 rounded-lg">
                            <div className="flex flex-col justify-center items-center mb-6">
                                <EmptyHolder
                                    icon={() => <HiMiniArchiveBox size={50} style={{ color: '#ebebeb' }} />}
                                    title={t('no data available to display')}
                                    subTitle={() => {
                                        return (
                                            <div className="mb-3">
                                                {t('Click the {icon} button on the chart page you want to keep track of, the chart will be updated regularly here.',
                                                    {icon: (
                                                            <span className="px-2">
                                                                <BsPinAngleFill className="text-color-300" />
                                                            </span>
                                                        )}
                                                )}
                                            </div>
                                        )
                                    }}
                                />
                                {!String(history.location.pathname).includes('/myspace/follow/data') && (
                                    <div className="flex flex-center gap-3">
                                        <div
                                            style={{ padding: '6px 16px' }}
                                            className="button-rounded-lg shadow font-medium text-sm text-white bg-primary hover:bg-primary-700 italic cursor-pointer"
                                            onClick={() => {
                                                history.push('/myspace/follow/data')
                                            }}>
                                            {t('view more charts')}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    renderWrap={children => (
                        <div className="space-y-3">
                            <HeaderBlock title="user choices" />
                            {children}
                            {limit > 0 && (
                                <SeeMore
                                    onClick={() => {setLimit(0)}}
                                />
                            )}
                        </div>
                    )}
                />
            )}
        </UseState>
    )
}

const DataEditorSChoiceFeeds = ({
  categoryId
}) => {
  const t = useTranslate()
  const { categories = [] } =
    useContext(LoginContext) || {}

  const key =
    categoryId ||
    `[${categories
      .map(c => {
        return c.idname
      })
      .toString()}]`

  return (
    <div className="space-y-3">
        <HeaderBlock title="editor choices" />
        <UseState initialValue={1}>
            {([limit, setLimit]) => (
                <React.Fragment>
                    <CustomLazyPagination
                        sample={limit}
                        auto={false}
                        id={
                            key +
                            get_editorChoiceWidgetFeeds_Api.path +
                            '_category_' +
                            categoryId +
                            '_following_1'
                        }
                        key={
                            key +
                            get_editorChoiceWidgetFeeds_Api.path +
                            '_category_' +
                            categoryId
                        }
                        apiInfo={
                            get_editorChoiceWidgetFeeds_Api
                        }
                        values={ categoryId ? {category_id: categoryId} : {} }
                        renderLoading={() => (
                            <div className="w-full h-screen flex justify-center">
                                <Skeleton
                                    className="p-3"
                                    active
                                />
                            </div>
                        )}
                        renderItem={ renderFeedItem }
                        renderEmpty={() => (
                            <div className="w-full justify-center items-center border border-color-50 rounded-lg">
                                <EmptyHolder
                                    icon={() => <HiMiniArchiveBox size={50} style={{ color: '#ebebeb' }} />}
                                    title={t('no data available to display')}
                                />
                            </div>
                        )}
                    />
                    {limit > 0 && (
                        <SeeMore
                            onClick={() => {setLimit(0)}}
                        />
                    )}
                </React.Fragment>
            )}
        </UseState>
    </div>
  )
}

const DataTypicalChartFeeds = ({
  categoryId,
  loadMoreAction
}) => {
  const t = useTranslate()
  const { categories = [] } =
    useContext(LoginContext) || {}

  const key =
    categoryId ||
    `[${categories
      .map(c => {
        return c.idname
      })
      .toString()}]`

  return (
      <ToggleView
          height={24}
          renderHeadlineView={renderHeadlineFeedItem}
          renderListView={renderFeedItem}>
          {({
                switchComponent,
                renderItem,
                setView,
                view
            }) => (
              <UseState initialValue={3}>
                  {([limit, setLimit]) => (
                      <div className="space-y-3">
                          <HeaderBlock
                              title="featured charts"
                              extra={switchComponent && switchComponent}
                          />
                          <CustomLazyPagination
                              auto={false}
                              sample={limit}
                              key={
                                  key +
                                  get_typicalChartsFeeds_Api.path +
                                  '_category_' +
                                  categoryId +
                                  '_view_' +
                                  view +
                                  '_following_1'
                              }
                              apiInfo={
                                  get_typicalChartsFeeds_Api
                              }
                              values={
                                  categoryId
                                      ? {
                                          view: view,
                                          category_id: categoryId,
                                          following: 1
                                      }
                                      : {
                                          view: view,
                                          following: 1
                                      }
                              }
                              renderLoading={() => (
                                  <div className="w-full h-screen flex justify-center">
                                      <Skeleton
                                          className="p-3"
                                          active
                                      />
                                  </div>
                              )}
                              renderItem={renderItem}
                              renderEmpty={() => null}
                          />
                          {limit > 0 && (
                              <SeeMore
                                  onClick={() => {
                                      !!loadMoreAction
                                          ? loadMoreAction()
                                          : setLimit(0)
                                  }}
                              />
                          )}
                      </div>
                  )}
              </UseState>
          )}
      </ToggleView>
  )
}

const MyFollowingChartList = ({
  categoryId,
  loadMoreAction
}) => {
  const t = useTranslate()
  const { categories = [] } =
    useContext(LoginContext) || {}

  const key =
    categoryId ||
    `[${categories
      .map(c => {
        return c.idname
      })
      .toString()}]`

  return (
    <UseState initialValue={3}>
      {([limit, setLimit]) => (
        <CustomLazyPaginationWrapper
          auto={false}
          sample={limit}
          key={
            key +
            dataset_getChartsByFollowingDataset_Api.path +
            '_category_' +
            categoryId
          }
          apiInfo={
            dataset_getChartsByFollowingDataset_Api
          }
          values={
            categoryId
              ? {
                  category_id: categoryId
                }
              : {}
          }
          renderLoading={() => (
            <div className="w-full h-screen flex justify-center">
              <Skeleton
                className="p-3"
                active
              />
            </div>
          )}
          renderItem={renderChartItemRow}
          renderEmpty={() => (
              <EmptyHolder
                  icon={() => <HiMiniArchiveBox size={50} style={{ color: '#ebebeb' }} />}
                  title={t('no data available to display')}
              />
          )}
          renderWrap={children => (
            <div className="space-y-3">
                <HeaderBlock title="following charts" />
                {children}
                {limit > 0 && (
                    <SeeMore
                        onClick={() => {
                            !!loadMoreAction
                                ? loadMoreAction()
                                : setLimit(0)
                        }}
                    />
                )}
            </div>
          )}
        />
      )}
    </UseState>
  )
}

const SuggestedChannelListByAuthUser = ({
  categoryId
}) => {
  const t = useTranslate()
  const [limit, setLimit] = useState(3)

  const login =
    useContext(LoginContext) ||
    emptyObject
  const {
    categories = [],
    ...loginRest
  } = login
  const following_languages_maximum = _.get(
    loginRest,
    'following_languages_maximum',
    1
  )
  const following_languages = _.get(
    loginRest,
    'following_languages',
    [loginRest.language]
  )

  const [
    languages,
    setLanguages
  ] = useState(following_languages)

  useEffect(() => {
    setLanguages(
      _.get(
        loginRest,
        'following_languages',
        [loginRest.language]
      )
    )
  }, [login])

  const key =
    categoryId ||
    `[${categories
      .map(c => {
        return c.idname
      })
      .toString()}]`

  return (
    <CustomLazyPaginationWrapper
      sample={limit}
      key={
        key +
        auth_fetchSuggestionChannelsByAuthCategories_Api.path +
        '_category_' +
        categoryId +
        '_languages_' +
        JSON.stringify(languages)
      }
      id={
        key +
        auth_fetchSuggestionChannelsByAuthCategories_Api.path +
        '_category_' +
        categoryId +
        '_languages_' +
        JSON.stringify(languages)
      }
      apiInfo={
        auth_fetchSuggestionChannelsByAuthCategories_Api
      }
      values={{
        category_id: categoryId,
        languages: languages.join(',')
      }}
      renderLoading={() => (
        <div className="w-full h-screen flex justify-center">
          <Skeleton
            className="p-3"
            active
          />
        </div>
      )}
      renderItem={renderChannelItem}
      renderEmpty={() => (
        <EmptyHolder
          title={null}
          subTitle={t('No channels')}
        />
      )}
      renderWrap={children => (
        <div className="space-y-3">
            <HeaderBlock
                title="user choices"
                extra={
                    <ContentLanguageSwitch
                        following_languages_maximum={following_languages_maximum}
                        languages={languages}
                        setLanguages={setLanguages}
                    />
                }
            />
            {children}
            {limit > 0 && (
                <SeeMore
                    onClick={() => {
                        setLimit(0)
                    }}
                />
            )}
        </div>
      )}
    />
  )
}

const MyFollowingChannelList = ({
  categoryId,
  loadMoreAction
}) => {
  const t = useTranslate()
  const [limit, setLimit] = useState(3)

  const { contentLang } = useContext(
    LocalContext
  )
  const login = useContext(LoginContext)
  const {
    categories,
    following_languages_maximum = 1,
    ...loginProps
  } = login || emptyObject
  const [
    contentLanguages,
    setContentLanguages
  ] = useState(
    !!loginProps &&
      !_.isEmpty(
        loginProps.following_languages
      )
      ? loginProps.following_languages
      : [contentLang]
  )
  useEffect(() => {
    setContentLanguages(
      !!loginProps &&
        !_.isEmpty(
          loginProps.following_languages
        )
        ? loginProps.following_languages
        : [contentLang]
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login])

  const key =
    categoryId ||
    `[${categories
      .map(c => {
        return c.idname
      })
      .toString()}]`

  return (
    <CustomLazyPaginationWrapper
      sample={limit}
      key={
        key +
        auth_getMyFollowingChannels_Api.path
          .replace(':props', 'channel')
          .replace(
            ':params',
            '?filter=follow'
          ) +
        '_category_id_' +
        categoryId +
        '_language_' +
        contentLanguages.join(',')
      }
      id={
        key +
        auth_getMyFollowingChannels_Api.path
          .replace(':props', 'channel')
          .replace(
            ':params',
            '?filter=follow'
          ) +
        '_category_id_' +
        categoryId +
        '_language_' +
        contentLanguages.join(',')
      }
      apiInfo={
        auth_getMyFollowingChannels_Api
      }
      query={{
        ':props': 'channel',
        ':params':
          '?filter=follow' +
          (!_.isEmpty(categoryId)
            ? '&category_id=' +
              categoryId
            : '') +
          (!_.isEmpty(contentLanguages)
            ? '&languages=' +
              contentLanguages.join(',')
            : '')
      }}
      values={{
        filter: 'follow',
        category_id: categoryId,
        languages: contentLanguages.join(
          ','
        )
      }}
      renderLoading={() => (
        <div className="w-full h-screen flex justify-center">
          <Skeleton
            className="p-3"
            active
          />
        </div>
      )}
      renderItem={renderChannelItem}
      renderEmpty={() => (
          <EmptyHolder
              icon={() => <HiMiniArchiveBox size={50} style={{ color: '#ebebeb' }} />}
              title={null}
              subTitle={t('No channels')}
          />
      )}
      renderWrap={children => {
        return (
          <div className="space-y-3">
              <HeaderBlock
                  title="channels"
                  extra={
                      <ContentLanguageSwitch
                          following_languages_maximum={following_languages_maximum}
                          languages={contentLanguages}
                          setLanguages={setContentLanguages}
                      />
                  }
              />
              {children}
              {limit > 0 && (
                  <SeeMore
                      onClick={() => {
                          !!loadMoreAction
                              ? loadMoreAction()
                              : setLimit(0)
                      }}
                  />
              )}
          </div>
        )
      }}
    />
  )
}

export const News = ({}) => {
  const t = useTranslate()
  const history = useHistory()
  const login =
    useContext(LoginContext) ||
    emptyObject
  const {
    categories = [],
    ...loginRest
  } = login
  const following_languages_maximum = _.get(
    loginRest,
    'following_languages_maximum',
    1
  )
  const following_languages = _.get(
    loginRest,
    'following_languages',
    [loginRest.language]
  )

  const { isSm } = useContext(
    LayoutContext
  )
  const [
    languages,
    setLanguages
  ] = useState(following_languages)

  useEffect(() => {
    setLanguages(
      _.get(
        loginRest,
        'following_languages',
        [loginRest.language]
      )
    )
  }, [login])

  const location = useLocation()
  const [
    { category: categoryId, ...rest },
    go
  ] = useQueryString(location.pathname)
  const [limit, setLimit] = useState(3)

  return isSm ? (
    <div className="space-y-3">
      <CustomLazyPaginationWrapper
        sample={limit}
        auto={false}
        id={
          feed_fetchEvents_Api.path +
          '_category_' +
          categoryId
        }
        key={
          feed_fetchEvents_Api.path +
          '_category_' +
          categoryId
        }
        apiInfo={feed_fetchEvents_Api}
        values={
          categoryId
            ? {
                cate_id: categoryId
              }
            : {}
        }
        renderLoading={() => (
          <div className="w-full flex justify-center">
            <Skeleton
              className="p-3"
              active
            />
          </div>
        )}
        renderItem={renderEventItem}
        renderEmpty={() => <EmptyHolder title={null} /> }
        renderWrap={children => (
            <div className="space-y-3">
                <HeaderBlock title="events" />
                {children}
                {limit > 0 && (
                    <SeeMore
                        onClick={() => {
                            setLimit(0)
                        }}
                    />
                )}
            </div>
        )}
      />

      <ToggleView
          height={24}
          renderHeadlineView={renderHeadlineFeedItem}
          renderListView={renderFeedItem}>
        {({
          switchComponent,
          renderItem,
          setView,
          view
        }) => (
          <div className="">
            <div className="flex items-center space-x-3 py-1 border-b-2 border-primary">
              <h2 className="uppercase font-bold text-color-300 flex-1 truncate">
                {t(
                  'latest tracking news 1111111'
                )}
              </h2>

              <ContentLanguageSwitch
                following_languages_maximum={
                  following_languages_maximum
                }
                languages={languages}
                setLanguages={
                  setLanguages
                }
              />

              {switchComponent &&
                switchComponent}
            </div>
            <CustomLazyPagination
              auto={false}
              id={
                post_dashboard_Api.path +
                '_category_' +
                categoryId +
                '_languages_' +
                JSON.stringify(
                  languages
                )
              }
              key={
                post_dashboard_Api.path +
                '_category_' +
                categoryId +
                '_languages_' +
                JSON.stringify(
                  languages
                )
              }
              apiInfo={
                post_dashboard_Api
              }
              values={
                categoryId
                  ? {
                      cate_id: categoryId,
                      content_type: undefined,
                      following_only:
                        '1',
                      languages: languages.join(
                        ','
                      )
                    }
                  : {
                      languages: languages.join(
                        ','
                      )
                    }
              }
              renderLoading={() => (
                <div className="w-full h-screen flex justify-center">
                  <Skeleton
                    className="p-3"
                    active
                  />
                </div>
              )}
              renderItem={renderItem}
              renderEmpty={() => (
                <div
                  className="w-full justify-center items-center border rounded"
                  style={{
                    minHeight: 240
                  }}>
                  <EmptyHolder
                    icon={() => (
                      <div
                        style={{
                          fontSize:
                            '2rem',
                          color:
                            'rgba(226, 232, 240, 1)'
                        }}>
                        <ImNewspaper />
                      </div>
                    )}
                    title={null}
                    subTitle={t(
                      'Subscribe to the channels for information displayed on this page'
                    )}
                  />
                </div>
              )}
            />
          </div>
        )}
      </ToggleView>

      <MyFollowingChannelList
        categoryId={categoryId}
      />

      <SuggestedChannelListByAuthUser
        categoryId={categoryId}
      />
    </div>
  ) : (
    <div className="grid grid-cols-1 md:grid-cols-2 w-full h-full gap-4 md:gap-6">
      <div className="col-span-1 space-y-3">
        <ToggleView
            height={24}
            renderHeadlineView={renderHeadlineFeedItem}
            renderListView={renderFeedItem}>
            {({ switchComponent, renderItem, setView, view }) => (
                <div className="space-y-3">
                    <HeaderBlock
                        title="latest tracking news"
                        extra={
                            <div className="flex items-center gap-2">
                                <ContentLanguageSwitch
                                    following_languages_maximum={
                                        following_languages_maximum
                                    }
                                    languages={languages}
                                    setLanguages={
                                        setLanguages
                                    }
                                />

                                {switchComponent &&
                                switchComponent}
                            </div>
                        }
                    />
                    <LazyPagination
                        id={
                            post_dashboard_Api.path +
                            '_category_' +
                            categoryId +
                            '_languages_' +
                            JSON.stringify(
                                languages
                            )
                        }
                        key={
                            post_dashboard_Api.path +
                            '_category_' +
                            categoryId +
                            '_languages_' +
                            JSON.stringify(
                                languages
                            )
                        }
                        apiInfo={
                            post_dashboard_Api
                        }
                        values={
                            categoryId
                                ? {
                                    cate_id: categoryId,
                                    content_type: undefined,
                                    following_only:
                                        '1',
                                    languages: languages.join(
                                        ','
                                    )
                                }
                                : {
                                    languages: languages.join(
                                        ','
                                    )
                                }
                        }
                        renderLoading={() => (
                            <div className="w-full h-screen flex justify-center">
                                <Skeleton
                                    className="p-3"
                                    active
                                />
                            </div>
                        )}
                        renderItem={renderItem}
                        renderEmpty={() => (
                            <div
                                className="w-full justify-center items-center border rounded"
                                style={{
                                    minHeight: 240
                                }}>
                                <EmptyHolder
                                    icon={() => (
                                        <div
                                            style={{
                                                fontSize:
                                                    '2rem',
                                                color:
                                                    'rgba(226, 232, 240, 1)'
                                            }}>
                                            <ImNewspaper />
                                        </div>
                                    )}
                                    title={null}
                                    subTitle={t(
                                        'Subscribe to the channels for information displayed on this page'
                                    )}
                                />
                            </div>
                        )}
                    />
                </div>
            )}
        </ToggleView>
      </div>
      <div className="col-span-1 space-y-3 h-full">
        <StickyFrame className="space-y-3">
          <UseState initialValue={3}>
            {([limit, setLimit]) => (
              <CustomLazyPaginationWrapper
                auto={false}
                sample={limit}
                id={
                  feed_fetchEvents_Api.path +
                  '_category_' +
                  categoryId
                }
                key={
                  feed_fetchEvents_Api.path +
                  '_category_' +
                  categoryId
                }
                apiInfo={
                  feed_fetchEvents_Api
                }
                values={
                  categoryId
                    ? {
                        cate_id: categoryId
                      }
                    : {}
                }
                renderLoading={() => (
                  <div className="w-full flex justify-center">
                    <Skeleton
                      className="p-3"
                      active
                    />
                  </div>
                )}
                renderItem={renderEventItem}
                renderEmpty={() => <EmptyHolder title={null}/>}
                renderWrap={children => (
                    <div className="space-y-3">
                        <HeaderBlock title="events" />
                        {children}
                        {limit > 0 && (
                            <SeeMore
                                onClick={() => {
                                    setLimit(0)
                                }}
                            />
                        )}
                    </div>
                )}
              />
            )}
          </UseState>

          <MyFollowingChannelList
            categoryId={categoryId}
          />

          <SuggestedChannelListByAuthUser
            categoryId={categoryId}
          />
        </StickyFrame>
      </div>
    </div>
  )
}

const MyFollowingDataList = ({
  categoryId = null,
  loadMoreAction
}) => {
  const t = useTranslate()
  const { categories = [] } =
    useContext(LoginContext) || {}
  const [limit, setLimit] = useState(3)

  const key =
    categoryId ||
    `[${categories
      .map(c => {
        return c.idname
      })
      .toString()}]`

  const displayCategories = !_.isEmpty(
    categoryId
  )
    ? _.filter(categories, {
        id: categoryId
      })
    : categories

  return (
    <CustomLazyPaginationWrapper
      sample={limit}
      id={
        key +
        auth_getMyFollowingDataset_Api.path +
        '_dataset_follow_' +
        '_category_' +
        categoryId
      }
      key={
        key +
        auth_getMyFollowingDataset_Api.path +
        '_dataset_follow_' +
        '_category_' +
        categoryId
      }
      apiInfo={
        auth_getMyFollowingDataset_Api
      }
      values={
        categoryId
          ? {
              filter: 'follow',
              category_id: categoryId
            }
          : {
              filter: 'follow'
            }
      }
      renderLoading={() => (
        <div className="w-full h-screen flex justify-center">
          <Skeleton
            className="p-3"
            active
          />
        </div>
      )}
      renderItem={renderDatasetItem}
      renderEmpty={() => (
          <EmptyHolder
              icon={() => <HiMiniArchiveBox size={50} style={{ color: '#ebebeb' }} />}
              title={null}
              subTitle={t('no dataset')}
          />
      )}
      renderWrap={children => (
        <div className="space-y-3">
            <HeaderBlock title="dataset" />
            {children}
            {limit > 0 && (
                <SeeMore
                    onClick={() => {
                        !!loadMoreAction
                            ? loadMoreAction()
                            : setLimit(0)
                    }}
                />
            )}
        </div>
      )}
    />
  )
}

const SuggestedDataListByAuthUser = ({
  categoryId = null
}) => {
  const t = useTranslate()
  const { categories = [] } =
    useContext(LoginContext) || {}
  const { isSm } = useContext(
    LayoutContext
  )
  const [limit, setLimit] = useState(3)

  const key =
    categoryId ||
    `[${categories
      .map(c => {
        return c.idname
      })
      .toString()}]`

  const displayCategories = !_.isEmpty(
    categoryId
  )
    ? _.filter(categories, {
        id: categoryId
      })
    : categories

  return (
    <div className="space-y-3">
        <HeaderBlock title="Data set you might be interested in" />
        <LazyPagination
            sample={limit}
            key={
                key +
                auth_fetchSuggestionDatasetByAuthCategories_Api.path +
                '_category_' +
                categoryId
            }
            apiInfo={
                auth_fetchSuggestionDatasetByAuthCategories_Api
            }
            values={
                categoryId
                    ? {
                        category_id: categoryId
                    }
                    : {}
            }
            renderLoading={() => (
                <div className="w-full h-screen flex justify-center">
                    <Skeleton
                        className="p-3"
                        active
                    />
                </div>
            )}
            renderItem={renderDatasetItem}
            renderEmpty={() => (
                <EmptyHolder
                    title={null}
                    subTitle={t('no dataset')}
                />
            )}
        />
        {limit > 0 && (
            <SeeMore
                onClick={() => {
                    setLimit(0)
                }}
            />
        )}
    </div>
  )
}

export const Data = ({}) => {
  const { isSm } = useContext(
    LayoutContext
  )
  const location = useLocation()
  const [
    { category: categoryId, ...rest },
    go
  ] = useQueryString(location.pathname)

  return isSm ? (
    <div className="space-y-3">
      <DataEditorSChoiceFeeds
        categoryId={categoryId}
      />

      <MyFollowingChartList
        categoryId={categoryId}
      />

      <MyFollowingDataList
        categoryId={categoryId}
      />

      <DataTypicalChartFeeds
        categoryId={categoryId}
      />

      <SuggestedDataListByAuthUser
        categoryId={categoryId}
      />
    </div>
  ) : (
    <div className="block grid grid-cols-1 md:grid-cols-2 w-full h-full gap-4 md:gap-6">
      <div className="col-span-1 space-y-3 h-full">
        <StickyFrame>
          <DataAuthUsersChoiceFeeds
            categoryId={categoryId}
          />
        </StickyFrame>
      </div>
      <div className="col-span-1 space-y-3">
        <StickyFrame className="space-y-3">
          <DataEditorSChoiceFeeds
            categoryId={categoryId}
          />

          <MyFollowingChartList
            categoryId={categoryId}
          />

          <MyFollowingDataList
            categoryId={categoryId}
          />

          <DataTypicalChartFeeds
            categoryId={categoryId}
          />

          <SuggestedDataListByAuthUser
            categoryId={categoryId}
          />
        </StickyFrame>
      </div>
    </div>
  )
}

export const HeaderBlock = ({ title, extra, className }) => {
    const t = useTranslate()
    return (
        <div className={classNames("flex items-center gap-2 border-b-2 border-primary-700", className)}>
            <span className="flex-1 font-bold text-primary-700 truncate uppercase tracking-wider">
                {t(title)}
            </span>
            {renderIf(
                extra,
                <div>
                    {extra}
                </div>
            )}
        </div>
    )
}

export const SeeMore = ({ onClick }) => {
    const t = useTranslate()
    return (
        <div className="w-full flex justify-end bottom-0">
            <div
                onClick={onClick}
                className="font-medium text-xs text-color-500 hover:text-primary-700 italic cursor-pointer">
                {t('see more')}
            </div>
        </div>
    )
}

export const Overview = ({}) => {
  const t = useTranslate()
  const history = useHistory()
  const { sampleLimit } = useContext(
    LayoutContext
  )

  const login =
    useContext(LoginContext) ||
    emptyObject
  const {
    categories = [],
    ...loginRest
  } = login
  const following_languages_maximum = _.get(
    loginRest,
    'following_languages_maximum',
    1
  )
  const following_languages = _.get(
    loginRest,
    'following_languages',
    [loginRest.language]
  )

  const [
    languages,
    setLanguages
  ] = useState(following_languages)

  useEffect(() => {
    setLanguages(
      _.get(
        loginRest,
        'following_languages',
        [loginRest.language]
      )
    )
  }, [login])

  const location = useLocation()
  const [
    { category: categoryId, ...rest },
    go
  ] = useQueryString(location.pathname)

  const displayCategories = !_.isEmpty(
    categoryId
  )
    ? _.filter(categories, {
        id: categoryId
      })
    : categories

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 w-full h-full gap-4 md:gap-6">
      <div className="col-span-1 space-y-3">
        <DataAuthUsersChoiceFeeds
          categoryId={categoryId}
        />

        <div className="space-y-3">
          <DelayRender>
            {() =>
              !!displayCategories.length &&
              displayCategories.map(
                (item, i) => {
                  return (
                    <React.Fragment
                      key={i}>
                      <LazyComponent>
                        <Suspense
                          fallback={
                            null
                          }>
                          <WidgetListByCategory
                            categoryId={
                              item.id
                            }
                            title={
                              item.name
                            }
                          />
                        </Suspense>
                      </LazyComponent>
                    </React.Fragment>
                  )
                }
              )
            }
          </DelayRender>
        </div>

        <DataEditorSChoiceFeeds
          categoryId={categoryId}
        />
      </div>
      <div className="col-span-1 space-y-3 h-full">
        <StickyFrame className="space-y-3">
          <ToggleView
              height={24}
              renderHeadlineView={renderHeadlineFeedItem}
              renderListView={renderFeedItem}>
              {({ switchComponent, renderItem, setView, view }) => (
                  <div className="space-y-3">
                      <HeaderBlock
                          title="latest tracking news"
                          extra={
                              <div className="flex items-center gap-2">
                                  <ContentLanguageSwitch
                                      following_languages_maximum={
                                          following_languages_maximum
                                      }
                                      languages={
                                          languages
                                      }
                                      setLanguages={
                                          setLanguages
                                      }
                                  />
                                  {switchComponent && switchComponent}
                              </div>
                          }
                      />
                      <CustomLazyPagination
                          sample={3}
                          auto={false}
                          id={
                              post_dashboard_Api.path +
                              '_category_' +
                              categoryId +
                              '_languages_' +
                              JSON.stringify(
                                  languages
                              )
                          }
                          key={
                              post_dashboard_Api.path +
                              '_category_' +
                              categoryId +
                              '_languages_' +
                              JSON.stringify(
                                  languages
                              )
                          }
                          apiInfo={
                              post_dashboard_Api
                          }
                          values={
                              categoryId
                                  ? {
                                      cate_id: categoryId,
                                      content_type: undefined,
                                      following_only: '1',
                                      languages: languages.join(',')
                                  }
                                  : { languages: languages.join(',') }
                          }
                          renderLoading={() => (
                              <div className="w-full h-screen flex justify-center">
                                  <Skeleton
                                      className="p-3"
                                      active
                                  />
                              </div>
                          )}
                          renderItem={ renderItem }
                          renderEmpty={() => (
                              <div
                                  style={{ minHeight: 240 }}
                                  className="w-full justify-center items-center border rounded">
                                  <EmptyHolder
                                      icon={() => <HiMiniArchiveBox size={50} style={{ color: '#ebebeb' }} />}
                                      title={t('no data available to display')}
                                      subTitle={t('Subscribe to the channels for information displayed on this page')}
                                  />
                              </div>
                          )}
                      />
                      <SeeMore onClick={() => history.push('/myspace/follow/news')}/>
                  </div>
              )}
          </ToggleView>

          <WeatherList
            categoryId={null}
            refCategoryId={null}
          />

          <CustomLazyPaginationWrapper
            sample={3}
            auto={false}
            id={
              feed_fetchEvents_Api.path +
              '_category_' +
              categoryId
            }
            key={
              feed_fetchEvents_Api.path +
              '_category_' +
              categoryId
            }
            apiInfo={
              feed_fetchEvents_Api
            }
            values={
              categoryId
                ? {
                    cate_id: categoryId
                  }
                : {}
            }
            renderLoading={() => (
              <div className="w-full flex justify-center">
                <Skeleton
                  className="p-3"
                  active
                />
              </div>
            )}
            renderItem={renderEventItem}
            renderEmpty={() => <EmptyHolder title={null} /> }
            renderWrap={children => (
              <React.Fragment>
                  <HeaderBlock title="events" />
                  <div className="rounded background space-y-3">
                      {children}
                      <SeeMore onClick={() => history.push('/myspace/follow/news')}/>
                  </div>
              </React.Fragment>
            )}
          />

          <MyFollowingChannelList
            categoryId={categoryId}
            loadMoreAction={() => {
              history.push('/myspace/follow/news')
            }}
          />

          <MyFollowingChartList
            categoryId={categoryId}
            loadMoreAction={() => {
              history.push(
                '/myspace/follow/data'
              )
            }}
          />

          <MyFollowingDataList
            categoryId={categoryId}
            loadMoreAction={() => {
              history.push(
                '/myspace/follow/data'
              )
            }}
          />
        </StickyFrame>
      </div>
    </div>
  )
}
