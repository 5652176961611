import {LoginContext} from 'components/LoginContext'
import React, {useContext} from 'react'
import {Null} from 'views/Shared'
import _ from "lodash"

const RequiredAdmin = ({children, system = false, type, renderEmpty = Null}) => {
  const login = useContext(LoginContext)
  const {edit = false, edit_system = false} = login ?? {}
  const condition = system ? edit_system : edit;
  if (!condition) {
    if (login && type) {
      if (_.get(login, 'permission_roles.' + type, false)) {
        return children
      }
    }
    return renderEmpty()
  }
  return children
}

export const SystemPermission = ({children, system}) => {
  const login = useContext(LoginContext)
  const {edit = false, edit_system = false} = login ?? {}
  const isAdmin = system ? edit_system : edit
  return children(isAdmin)
};

export const RequiredSystemAdmin = (props) => (
  <RequiredAdmin
    {...props}
    system={true}
  />
)

export default RequiredAdmin
