import { event_getEventsByProps_Api } from 'apis/event'
import { getId, getType } from 'apis/model/base'
import _ from 'lodash'
import React, { useContext, useMemo } from 'react'
import EntityList from 'views/AppLocals/EntityList'
import EventItem from 'views/AppLocals/Event/components/EventItem'
import EventEntity from 'views/AppLocals/Event/EventEntity'
import { bindQueryParams } from 'views/AppLocals/functions/routerHelper'
import OrganizationContainerContext from '../OrganizationContainerContext'
import useTranslate from '../../../../modules/local/useTranslate'

const OrganizationEventList = ({ Wrapper = 'div' }) => {
  const t = useTranslate()
  const { organization: item } = useContext(OrganizationContainerContext)

  const [id, type] = [getId(item), getType(item)]

  const apiInfo = event_getEventsByProps_Api

  const lookupKey = useMemo(
    () => [apiInfo.path.replace(':id', id).replace(':type', type)].join('/'),
    [id, type, apiInfo]
  )

  if (_.isEmpty(item)) return null

  return (
    <Wrapper className="space-y-3">
      <div className="font-medium text-sm text-color-400 border-b uppercase">
        {t('list of event')}
      </div>
      <EntityList
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        query={bindQueryParams([{ id }, { type }])}
        RenderItem={EventItem}
        RenderEntity={EventEntity}
      />
      <div className="h-10" />
    </Wrapper>
  )
}

export default OrganizationEventList
