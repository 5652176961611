import {EllipsisOutlined} from '@ant-design/icons'
import {Menu, message} from 'antd'
import {organization_Edit_Api} from 'apis'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import DrawMenu from '../../../components/drawer/DrawMenu'
import ModalRichEditorContainer from '../../../components/RichSlateEditor/ModalRichEditorContainer'
import {QuillDrawingSvg} from '../../../components/Svgs/QuillDrawingSvg'
import {createAsyncAction} from '../../../modules/asyncCache'
import {getAsynCacheSelector} from '../../../modules/asyncCache/selectors'
import useTranslate from '../../../modules/local/useTranslate'
import ServerContext from '../../Server/ServerContext'

function useAsyncAction() {
  const dispatch = useDispatch()
  const [
    asyncId,
    setAsyncId
  ] = useState()
  const data = useSelector(state =>
    getAsynCacheSelector(state, asyncId)
  )
  const handleAction = useCallback(
    ({
      prefixStr,
      values,
      apiInfo,
      query
    }) => {
      const action = createAsyncAction({
        prefixStr,
        values,
        apiInfo,
        query
      })
      setAsyncId(action.asyncId)
      dispatch(action)
    },
    [dispatch]
  )

  return { data, handleAction }
}

export function Article({ className }) {
  const t = useTranslate()
  const { item } = useContext(
    ServerContext
  )
  const [toggle, setToggle] = useState(
    false
  )
  const {
    data,
    handleAction
  } = useAsyncAction()

  const handleSubmit = value => {
    handleAction({
      prefixStr: 'update_about',
      values: {
        about: value
      },
      apiInfo: organization_Edit_Api,
      query: {
        ':id': item.id
      }
    })
  }
  const contextMenu = (
    <Menu
      theme="dark"
      onClick={e => {
        switch (e.key) {
          case 'edit':
            setToggle(true)
            break
          case 'delete':
            handleSubmit(' ')
            break
          default:
            break
        }
      }}>
      {['edit', 'delete'].map(
        (value, i) => (
          <Menu.Item
            key={value}
          >
            <div className="text-left font-medium text-color">
              {t(value)}
            </div>
          </Menu.Item>
        )
      )}
    </Menu>
  )

  useEffect(() => {
    if (data.success) {
      setToggle(false)
      message.success(
        t('update about success')
      )
    }
  }, [data.success, t]);

  return (
    <div className={className}>
      {item.edit && !item.about && (
        <div className=" hover:border-blue-600 cursor-pointer ">
          <div
            onClick={() =>
              setToggle(true)
            }
            className="flex w-full flex-col px-4 py-5 items-center justify-center">
            <div className="p-5 flex items-center justify-center opacity-25">
              <QuillDrawingSvg
                width="15%"
                className="text-color-300"
              />
            </div>
            <div className="font-semibold text-xl hover:underline">
              {t(
                'about us'
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className="flex"
        style={{
          width: '100%'
        }}>
        <div />
        <ModalRichEditorContainer
          isEmpty={!item.about}
          value={item.about}
          isLoading={!!data.isLoading}
          toggle={item.edit && toggle}
          setToggle={setToggle}
          onSubmit={handleSubmit} />
      </div>
      {item.edit && (
        <DrawMenu
          component={
            <div
              // data-effect="ripple"
              className="absolute top-0 p-3 right-0 text-color-300">
              <EllipsisOutlined
                style={{
                  fontSize: '1.2em'
                }}
              />
            </div>
          }>
          {(isToggle, toggle) => (
            contextMenu
          )}
        </DrawMenu>
      )}
    </div>
  )
}
