import {Button, message, Tooltip, Upload} from 'antd'
import {image_upload_Api} from 'apis'
import _ from 'lodash'
import {callApi} from 'modules/asyncCache/callApi'
import Translate from 'modules/local/Translate'
import React, {useContext, useEffect, useMemo} from 'react'
import {CgImage} from 'react-icons/cg'
import {RiCloseFill} from 'react-icons/ri'
import {TbFloatCenter, TbFloatLeft, TbFloatRight} from 'react-icons/tb'
import {Transforms} from 'slate'
import {ReactEditor, useSelected, useSlate, useSlateStatic} from 'slate-react'
import LoadingPage from 'views/LoadingPage'
import {Null, renderIf} from 'views/Shared'
import {isBlockActive} from 'views/SlateEditor/functions'
import {insertImage} from 'views/SlateEditor/functions/image'
import {withOutline} from 'views/SlateEditor/SlateEditor'
import SlateEditorContext from 'views/SlateEditor/SlateEditorContext'
import {ElementTypes, ImageAlignTypes} from 'views/SlateEditor/types'
import useTranslate from '../../../../modules/local/useTranslate'
import Caption from '../Caption'
import {ToolbarButton} from '../Toolbar'
import './Image.css'

const scope = ElementTypes.IMAGE

export const ImageButton = () => {
  const editor = useSlate()
  const disabled = !!!editor?.selection
  const isActive = isBlockActive(editor, scope)
  const {set = Null, ...rest} = useContext(SlateEditorContext)

  const values = useMemo(() => _.get(rest.values, scope), [rest.values])

  const setValues = (params) => {
    set(scope, params)
  }

  useEffect(() => {
    const {error, success, isLoading, errorMessages} = values ?? {}
    const {ids, urls} = values?.data ?? {}
    const imageUrl = _.first(urls)
    const imageId = _.first(ids)
    if (values) {
      insertImage(editor, imageUrl, {
        error,
        imageId,
        isLoading,
      })
      if (success) {
        setValues(undefined)
      }
    }
    if (error) {
      const msg = _.get(error, 'photos[0]') ?? errorMessages?.message
      message.error(msg)
    }
  }, [values])

  return (
    <Upload
      fileList={[]}
      className="flex flex-center"
      disabled={disabled}
      openFileDialogOnClick={!disabled}
      customRequest={async ({onSuccess, ...args}) => {
        setValues({isLoading: true})
        const response = await callApi({
          apiInfo: image_upload_Api,
          values: {
            'photos[]': args.file,
          },
        })
        setValues({isLoading: false, ...response})
      }}>
      <ToolbarButton
        Icon={CgImage}
        active={isActive}
        disabled={disabled}
        onClick={(event) => {
          event.preventDefault()
        }}
      />
    </Upload>
  )
}

export const ImageElement = ({
                               style,
                               element,
                               children,
                               attributes,
                               readOnly = false,
                             }) => {
  const selected = useSelected()
  const editor = useSlateStatic()
  const {
    alt,
    src,
    caption,
    imageId,
    alignment = ImageAlignTypes.NONE,
  } = element ?? {}
  const {values} = useContext(SlateEditorContext)
  const {isLoading} = _.get(values, scope) ?? {}
  const t = useTranslate()

  const path = ReactEditor.findPath(editor, element)

  const wrapperStyles = useMemo(() => {
    let margin = {}
    switch (alignment) {
      case ImageAlignTypes.LEFT:
        margin = {marginRight: '0.5rem', marginBottom: '0.5rem'}
        break
      case ImageAlignTypes.RIGHT:
        margin = {marginLeft: '0.5rem', marginBottom: '0.5rem'}
        break
      default:
        break
    }
    return {
      ...margin,
      float: alignment,
      padding: '0.5rem',
      position: 'relative',
      borderRadius: '0.375rem',
      outline: withOutline({selected, focused: selected}),
      width: [ImageAlignTypes.LEFT, ImageAlignTypes.RIGHT].includes(alignment)
        ? '50%'
        : undefined,
    }
  }, [selected, alignment])

  if (!imageId) {
    return (
      <React.Fragment>
        {renderIf(isLoading, <LoadingPage style={{padding: '1rem'}}/>)}
        {children}
      </React.Fragment>
    )
  }

  return (
    <div {...(attributes ?? {})}>
      <div className="element-link">
        <div
          contentEditable={false}
          style={{
            ...(style ?? {}),
            ...wrapperStyles,
          }}>
          {renderIf(
            !readOnly && selected,
            <React.Fragment>
              <div
                style={{top: '-35px'}}
                className="absolute w-0">
                <div
                  style={{padding: '4px 10px'}}
                  className="inline-flex flex-center gap-2 shadow border border-color-50 rounded bg-white">
                  {[
                    {
                      icon: <TbFloatLeft/>,
                      alignment: ImageAlignTypes.LEFT,
                      label: 'align left',
                    },
                    {
                      icon: <TbFloatCenter/>,
                      alignment: ImageAlignTypes.NONE,
                      label: 'align center',
                    },
                    {
                      icon: <TbFloatRight/>,
                      alignment: ImageAlignTypes.RIGHT,
                      label: 'align right',
                    },
                  ].map(({icon, alignment, label}, index) => (
                    <Tooltip
                      key={index}
                      title={t(label)}>
                      <Button
                        size="small"
                        icon={icon}
                        className="flex flex-center button-rounded-xs"
                        onClick={(event) => {
                          event.preventDefault()
                          Transforms.setNodes(
                            editor,
                            {alignment},
                            {at: path}
                          )
                        }}
                      />
                    </Tooltip>
                  ))}
                </div>
              </div>
              <Button
                icon={<RiCloseFill size={13}/>}
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  zIndex: 3,
                  width: 25,
                  height: 25,
                }}
                className="flex flex-center button-rounded-full no-border bg-primary-50 hover:bg-primary-300 text-primary-500 hover:text-primary"
                onClick={(event) => {
                  event.preventDefault()
                  Transforms.removeNodes(editor, {at: path})
                }}
              />
            </React.Fragment>
          )}
          <div>
            <img
              src={src}
              alt={alt ?? caption}
              style={{
                margin: 0,
                objectFit: 'cover',
                borderRadius: '0.375rem',
              }}
            />
          </div>
          <Translate>
            {(t) => (
              <Caption
                value={caption}
                readOnly={readOnly}
                withInput={() => ({
                  placeholder: t('caption of image'),
                })}
                withModal={() => ({
                  title: t('write caption for image'),
                })}
                onChange={(caption) => {
                  const path = ReactEditor.findPath(editor, element)
                  const newProperties = {
                    caption,
                  }
                  Transforms.setNodes(editor, newProperties, {
                    at: path,
                  })
                }}
              />
            )}
          </Translate>
        </div>
      </div>
      {children}
    </div>
  )
}
