import {MessageOutlined, MoreOutlined} from '@ant-design/icons'
import FormOutlined from '@ant-design/icons/FormOutlined'
import {Menu} from 'antd'
import DrawMenu from 'components/drawer/DrawMenu'
import Count from 'components/Feed/Count'
import FeedContext from 'components/Feed/FeedContext'
import {FeedProvider} from 'components/Feed/FeedProvider'
import {Share} from 'components/Feed/Share'
import {allActions} from 'components/Feed/TimeLineFeed'
import {Vote} from 'components/Feed/Vote'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React from 'react'
import {FaBookmark, FaRegBookmark} from 'react-icons/fa'
import {renderFeedItem} from 'views/MainPage/functions/renderFeedItem'
import ArticleItem from './components/ArticleItem'

const ArticleWithActions = (feed) => {
  const history = useHistory()
  const t = useTranslate()
  const item = feed.content
  let e = null

  switch (_.get(item, '_type')) {
    case 'article':
      e = (
        <div
          {...{ key: item.id }}
          className="verticalList__small">
          <ArticleItem
            {...{
              title: item['title'],
              categories: _.get(item, 'categories'),
              keywords: _.get(item, 'keywords', []),
              auth: _.get(item, 'owner'),
              image:
                item.cover_photo &&
                item.cover_photo !== null &&
                `${item.cover_photo}`,
              description: item['description'],
              url: getLinkToDetail(item),
              auth_avatar: _.get(item, 'owner.thumbnail'),
              auth_name: _.get(item, 'owner.name'),
              created: _.get(item, 'created'),
              re_post_url: _.get(item, 'embed_data.url'),
              total_comments: _.get(item, 'total_comments', 0),
            }}
          />
          {!!!feed.hideActions && (
            <FeedProvider
              item={item}
              defaultActions={allActions}>
              <FeedContext.Consumer>
                {({handleClick, shareUrl}) => (
                  <div className="flex items-center">
                    <Vote />
                    <Count
                      defaultNumber={_.get(item, 'total_comments')}
                      onClick={() => handleClick('to')}
                      key="message"
                      color="color-primary"
                      component={MessageOutlined}
                    />
                    <Share url={shareUrl}/>
                    <Count
                      active={item.bookmark_status}
                      onClick={
                        !item.bookmark_status
                          ? () => handleClick('bookmark')
                          : () => handleClick('unbookmark')
                      }
                      key="bookmark"
                      color={
                        item.bookmark_status
                          ? 'text-yellow-500'
                          : 'text-color-300'
                      }
                      component={
                        item.bookmark_status ? FaBookmark : FaRegBookmark
                      }
                    />
                    {item.edit && (
                      <DrawMenu
                        component={
                          <div deta-effect="ripple">
                            <Count component={MoreOutlined}/>
                          </div>
                        }>
                        {(isToggle, toggle) => (
                          <Menu
                            onClick={(props) => {
                              handleClick(props.key)
                              toggle()
                            }}>
                            <Menu.Item
                              key="edit"
                              className="px-3">
                              <FormOutlined />
                              <span className="text-center font-semibold text-color">
                                {t('edit')}
                              </span>
                            </Menu.Item>
                          </Menu>
                        )}
                      </DrawMenu>
                    )}
                    <div className="flex-1"></div>
                  </div>
                )}
              </FeedContext.Consumer>
            </FeedProvider>
          )}
        </div>
      )
      break
    case 'post':
      e = (
        <div
          {...{ key: item.id }}
          className="verticalList__small">
          <ArticleItem
            {...{
              title: _.get(item, 'embed_data.title'),
              subTitle:
                _.get(item, 'embed_data.host_name') ||
                _.get(item, 'embed_data.host_url') ||
                _.get(item, 'embed_data.url'),
              categories: _.get(item, 'categories'),
              keywords: _.get(item, 'keywords', []),
              image: _.get(item, 'embed_data.photo'),
              description: _.get(item, 'embed_data.description'),
              url: `/news/post/${item.id}`,
              auth_avatar: _.get(item, 'owner.thumbnail'),
              auth_name: _.get(item, 'owner.name'),
              created: _.get(item, 'created'),
              re_post_url: _.get(item, 'embed_data.url'),
              total_comments: _.get(item, 'total_comments', 0),
            }}
          />
          {!!!feed.hideActions && (
            <FeedProvider
              item={item}
              defaultActions={allActions}>
              <FeedContext.Consumer>
                {({handleClick, shareUrl}) => (
                  <div className="flex items-center">
                    <Vote />
                    <Count
                      defaultNumber={_.get(item, 'total_comments')}
                      onClick={() => handleClick('to')}
                      key="message"
                      color="color-primary"
                      component={MessageOutlined}
                    />
                    <Share url={shareUrl}/>
                    <Count
                      active={item.bookmark_status}
                      onClick={
                        !item.bookmark_status
                          ? () => handleClick('bookmark')
                          : () => handleClick('unbookmark')
                      }
                      key="bookmark"
                      color={
                        item.bookmark_status
                          ? 'text-yellow-500'
                          : 'text-color-300'
                      }
                      component={
                        item.bookmark_status ? FaBookmark : FaRegBookmark
                      }
                    />
                    {item.edit && true && (
                      <DrawMenu
                        component={
                          <div>
                            <Count component={MoreOutlined}/>
                          </div>
                        }>
                        {(isToggle, toggle) => (
                          <Menu
                            onClick={(props) => {
                              switch (props.key) {
                                case 'edit':
                                  history.push({
                                    pathname: `/quickLink/${item.id}/settings`,
                                    state: {
                                      isModal: true,
                                    },
                                  })
                                  break
                                default:
                                  break
                              }
                              toggle()
                            }}>
                            <Menu.Item
                              key="edit"
                              className="px-3">
                              <FormOutlined/>
                              <span className="text-center font-semibold text-color">
                                {t('edit')}
                              </span>
                            </Menu.Item>
                          </Menu>
                        )}
                      </DrawMenu>
                    )}
                    <div className="flex-1"></div>
                  </div>
                )}
              </FeedContext.Consumer>
            </FeedProvider>
          )}
        </div>
      )
      break
    case 'repost':
      e = renderFeedItem(feed, feed.id)
      break
    default:
      break
  }
  return e
}
export default ArticleWithActions
