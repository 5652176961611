import { API_ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import { schema } from 'normalizr'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'
export const commentContainerSchema = new schema.Entity(
  'commentContainers',
  {},
  {
    idAttribute: 'id',
    // mergeStrategy: (entityA, entityB) => _.merge({}, entityA, entityB),
  }
)

export const withCustomCommentSchema = (
  params = {},
  withEntity = (entity) => ({})
) => {
  const { is_creating, ...rest } = params ?? {}
  return new schema.Entity(
    'comments',
    {
      commentContainer: commentContainerSchema,
    },
    {
      processStrategy: (entity) => ({
        ...entity,
        commentContainer: entity?.parent_id
          ? {
              id: [
                entity.container_type,
                entity.container_id,
                entity?.parent_id,
              ].join('_'),
              ...(is_creating
                ? {
                    sortByDate: {
                      [new Date(entity.created).getTime()]: entity.id,
                    },
                  }
                : {}),
              ...rest,
              ...(withEntity(entity) ?? {}),
            }
          : {
              id: [entity.container_type, entity.container_id].join('_'),
              sortByDate: {
                [new Date(entity.created).getTime()]: entity.id,
              },
              ...rest,
              ...(withEntity(entity) ?? {}),
            },
      }),
    }
  )
}
// Route::get('comments/{prop}/{id}/get-comments', 'CommentController@getComments');
export const comment_getComments_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/comments/:prop/:id/get-comments',
  method: 'GET',
  fields: [],
  fieldTypes: {},
  schema: withCustomCommentSchema(),
  transform: createListTransform(withCustomCommentSchema()),
}
export const create_comment_getComments_Api_action =
  () =>
  ({ prefixStr = 'comment_getComments_Api', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@comment_getComments_Api',
      query,
      values,
      apiInfo: comment_getComments_Api,
      maxAge,
    })
// Route::get('comments/{prop}/{id}/comment/{comment_id}/get-replies'
// Route::post('comments/comment/{prop}/{id}/add-comment', 'CommentController@addComment');
export const comment_addComment_Api = {
  name: 'comment_addComment',
  backend: ``,
  root: API_ROOT_URL,
  path: '/comments/comment/:prop/:id/add-comment',
  method: 'POST',
  transform: createTransform(
    withCustomCommentSchema({ is_creating: true }),
    'data'
  ),
}
// Route::post('comments/comment/{prop}/{id}/edit-comment/{comment_id}', 'CommentController@editComment');
export const comment_editComment_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/comments/comment/:prop/:id/edit-comment/:comment_id',
  method: 'POST',
  fields: ['message'],
  transform: createTransform(withCustomCommentSchema(), 'data'),
}
export const create_comment_editComment_Api_action =
  () =>
  ({ prefixStr = '', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@comment_editComment_Api',
      query,
      values,
      apiInfo: comment_editComment_Api,
      maxAge,
    })
// Route::post('comments/comment/{prop}/{id}/remove-comment/{comment_id}', 'CommentController@removeComment');
export const comment_removeComment_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/comments/comment/:prop/:id/remove-comment/:comment_id',
  method: 'POST',
  transform: (response, { query }) => {
    return {
      response,
      entities: {
        comments: {
          [query[':comment_id']]: {
            isDeleted: true,
            update: Date.now(),
          },
        },
      },
    }
  },
}
export const create_comment_removeComment_Api_action =
  () =>
  ({ prefixStr = 'comment_removeComment_Api', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@comment_removeComment_Api',
      query,
      values,
      apiInfo: comment_removeComment_Api,
      maxAge,
    })
// Route::post('comments/reply/{prop}/{id}/comment/{comment_id}/add-reply', 'CommentController@addReply');
export const comment_addReply_Api = {
  root: API_ROOT_URL,
  path: `/comments/reply/:type/:id/comment/:comment_id/add-reply`,
  method: 'POST',
  fiels: ['message'],
  transform: createTransform(
    withCustomCommentSchema({ is_creating: true }, (entity) => ({
      id: `${entity.container_type}_${entity.container_id}_${entity.parent_id}`,
    })),
    'data'
  ),
  // transform: createTransform(
  //   new schema.Entity(
  //     'comments',
  //     {
  //       commentContainer: commentContainerSchema,
  //     },
  //     {
  //       processStrategy: (entity) => {
  //         return {
  //           ...entity,
  //           is_creating: true,
  //           commentContainer: {
  //             id: `${entity.container_type}_${entity.container_id}_${entity.parent_id}`,
  //             sortByDate: {
  //               [new Date(entity.created).getTime()]: entity.id,
  //             },
  //           },
  //         }
  //       },
  //     }
  //   ),
  //   'data'
  // ),
}

// Route::post('comments/reply/{prop}/{id}/edit-reply/{comment_id}', 'CommentController@editReply');
// Route::post('comments/reply/{prop}/{id}/remove-reply/{comment_id}', 'CommentController@removeReply');

export const comment_getReples_Api = {
  root: API_ROOT_URL,
  path: `/comments/:type/:id/comment/:comment_id/get-replies`,
  method: 'GET',
  transform: createListTransform(
    withCustomCommentSchema({}, (entity) => ({
      id: `${entity.container_type}_${entity.container_id}_${entity.parent_id}`,
    }))
  ),
  // transform: createListTransform(
  //   new schema.Entity(
  //     'comments',
  //     {
  //       commentContainer: commentContainerSchema,
  //     },
  //     {
  //       processStrategy: (entity) => {
  //         return {
  //           ...entity,
  //           commentContainer: {
  //             id: `${entity.container_type}_${entity.container_id}_${entity.parent_id}`,
  //             sortByDate: {
  //               [new Date(entity.created).getTime()]: entity.id,
  //             },
  //           },
  //         }
  //       },
  //     }
  //   )
  // ),
}
