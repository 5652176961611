import { getId } from 'apis/model/base'
import classNames from 'classnames'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'
import createSearchResultComponent from 'views/Discovery/functions/createSearchResultComponent'
import { participant_status_options } from '..'
import getLinkToDetail from '../../../../helpers/getLinkToDetail'
import {renderFromToTime, renderIf} from '../../../Shared'
import {GenderTypes} from "../../appFieldTypes";

const ItemInformation = ({ label, value }) =>
  renderIf(
    value,
    <Translate>
      {(t) => (
        <div className="flex items-center gap-1 text-xs italic">
          <span className="text-color-500">{`${t(label)} : `}</span>
          <span className="font-bold text-color-100">{value}</span>
        </div>
      )}
    </Translate>
  )

const EventInfo = ({ item }) => (
  <div className="w-full">{createSearchResultComponent(item, getId(item))}</div>
)

export const renderEventParticipant =
  ({ renderContextMenu } = {}) =>
  (item, index) => {
    const {
      user,
      name,
      owner,
      status,
      gender,
      date_of_birth,
      contact_phone,
      contact_email,
      registration_date,
    } = item ?? {}

    const {
      title,
      time_open,
      time_close,
      event_type,
      location_name,
      external_link,
    } = owner ?? {}

    const status_options = Object.values(participant_status_options)

    const current_status = _.find(status_options, { value: status }) ?? {}

    return (
      <Translate key={index}>
        {(t) => (
          <div className="w-full flex flex-col gap-1 border border-color-50 hover:shadow-items rounded-lg p-2 relative custom-item-render">
            <div className="flex items-baseline gap-2 italic">
              <span className="font-medium text-sm text-color-100">
                {t('you have registered to attend the event')}
              </span>
              <Link
                to={getLinkToDetail(owner)}
                className="font-bold text-green-700 no-underline">
                {title}
              </Link>
            </div>
            <div className="flex items-baseline gap-2">
                <span className="text-xs text-color-500 italic">
                    {`${t('time')} : `}
                </span>
              <div className="flex flex-col md:flex-row gap-x-2 uppercase text-xs font-bold tracking-wide">
                <span className="leading-tight text-color-100">
                  <span className="font-normal text-color-400 capitalize italic mr-2">
                    {t('from')}
                  </span>
                  {moment(time_open).format('hh:mm a - Do MMMM, YYYY')}
                </span>
                {renderIf(
                  time_close,
                  <span className="leading-tight text-color-100">
                    <span className="font-normal text-color-400 lowercase italic mr-2">
                      {t('to')}
                    </span>
                    {moment(time_close).format('hh:mm a - Do MMMM, YYYY')}
                  </span>
                )}
              </div>
            </div>
            <ItemInformation
              label="type of event"
              value={t(event_type)}
            />
            <ItemInformation
              label="address"
              value={location_name}
            />
            <ItemInformation
              label="link"
              value={external_link}
            />

            <div style={{ bottom: 8, right: 8, position: 'absolute' }}>
              <span
                style={{ padding: '2px 10px 2px 8px' }}
                className={classNames(
                  'w-fit flex items-center gap-2 rounded-full leading-tight',
                  current_status?.className
                )}>
                {current_status?.icon}
                {renderIf(
                  _.isString(current_status?.label),
                  <span className="font-medium text-xs italic">
                    {t(current_status?.label)}
                  </span>
                )}
              </span>
            </div>

            {_.isFunction(renderContextMenu) && (
              <div
                style={{ top: 5, right: 5 }}
                className="absolute contextIcon">
                {renderContextMenu(item)}
              </div>
            )}
          </div>
        )}
      </Translate>
    )
  }

export const renderRegistrationParticipant = ({ renderContextMenu } = {}) => (item, index) => {
    const {
        user,
        name,
        owner,
        status,
        gender,
        date_of_birth,
        contact_phone,
        contact_email,
        registration_date,
    } = item ?? {}

    const { title } = owner ?? {}

    const status_options = Object.values(participant_status_options)
    const current_status = _.find(status_options, { value: status }) ?? {}
    const labelGender = _.find(GenderTypes, { value: gender })?.label
    const birthday = date_of_birth ? moment(date_of_birth).format('DD/MM/YYYY') : null

    return (
        <Translate key={index}>
            {(t) => (
                <div className="w-full flex flex-col gap-1 border border-color-50 hover:shadow-items rounded-lg p-2 relative custom-item-render">
                    <div className="flex items-baseline gap-2 text-sm italic">
                        <span className="font-bold text-color-000 italic">
                            {name}
                        </span>
                        <span className="font-medium text-color-100 lowercase">
                            {t('sign up for this event')}
                        </span>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-1">
                        <ItemInformation label="phone" value={contact_phone} />
                        <ItemInformation label="email" value={contact_email} />
                        <ItemInformation label="gender" value={t(labelGender)} />
                        <ItemInformation label="date of birth" value={birthday} />
                    </div>
                    <div style={{ bottom: 8, right: 8, position: 'absolute' }}>
                              <span
                                  style={{ padding: '2px 10px 2px 8px' }}
                                  className={classNames(
                                      'w-fit flex items-center gap-2 rounded-full leading-tight',
                                      current_status?.className
                                  )}>
                                {current_status?.icon}
                                  {renderIf(
                                      _.isString(current_status?.label),
                                      <span className="font-medium text-xs italic">
                                    {t(current_status?.label)}
                                  </span>
                                  )}
                              </span>
                    </div>
                    {_.isFunction(renderContextMenu) && (
                        <div
                            style={{ top: 5, right: 5 }}
                            className="absolute contextIcon">
                            {renderContextMenu(item)}
                        </div>
                    )}
                </div>
            )}
        </Translate>
    )
}
