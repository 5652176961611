import covertToSearchString from './covertToSearchString'

export function filterValue(str, str2) {
  const enchachedValue = covertToSearchString(
    str
  )
  return (
    str2 + covertToSearchString(str2)
  ).includes(enchachedValue)
}
export default function filterOption(
  value,
  option
) {
  return filterValue(
    value,
    option.label
  )
}
