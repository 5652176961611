import { message } from 'antd'
import { member_joinAsMember_Api, member_leaveAsMember_Api, post_create_Api, vote_add_Api, vote_remove_Api } from 'apis'
import { organizationModel } from 'apis/model'
import { LoginContext } from 'components/LoginContext'
import Null from 'components/NullComponent'
import { createAsyncAction } from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useCallback, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, useParams } from 'react-router-dom'
import RouteWithSubRoutes from 'routes/RouteWithSubRoutes'
import { routeEntities } from '../Billing/routes'
import ServerContext from './ServerContext'
import { useFollow } from './useFollow'
import { useRequiredLogin } from './useRequiredLogin'

export const useVote = ({
  vote_status,
  owner_type,
  owner_id
}) => {
  const dispatch = useDispatch()
  const handleVote = useCallback(() => {
    !vote_status
      ? dispatch(
        createAsyncAction({
          apiInfo: vote_add_Api,
          values: {
            owner_type,
            owner_id
          }
        })
      )
      : dispatch(
        createAsyncAction({
          apiInfo: vote_remove_Api,
          values: {
            owner_type,
            owner_id
          }
        })
      )
  }, [
    dispatch,
    owner_id,
    owner_type,
    vote_status
  ])
  return useRequiredLogin(handleVote)
}
export const UseFollow = ({
  follow_status,
  owner_type,
  owner_id,
  children
}) => {
  const handleFollow = useFollow({
    follow_status,
    owner_type,
    owner_id
  })
  return children(handleFollow)
}
export const useJoin = ({
  status,
  type,
  id
}) => {
  const [
    data,
    dispatch
  ] = useDispatchAsyncActionWithNotify()
  const handleJoin = useCallback(() => {
    !status
      ? dispatch(
        createAsyncAction({
          apiInfo: member_joinAsMember_Api,
          query: {
            ':prop': type,
            ':id': id
          }
        })
      )
      : dispatch(
        createAsyncAction({
          apiInfo: member_leaveAsMember_Api,
          query: {
            ':prop': type,
            ':id': id
          }
        })
      )
  }, [status, dispatch, type, id])
  return useRequiredLogin(handleJoin)
}
export const useNewPost = () => {
  const [
    newPosts,
    setNewPosts
  ] = useState([])
  const [
    viewedPosts,
    setViewedPosts
  ] = useState({})
  const handleSetViewedPosts = id =>
    setViewedPosts({
      ...viewedPosts,
      [id]: true
    })
  const addNewPost = useCallback(
    newPost => {
      setNewPosts([
        newPost,
        ...newPosts
      ])
    },
    [newPosts]
  )
  const newNumber = newPosts.filter(
    id => !viewedPosts[id]
  ).length
  const hadNews = newNumber > 0
  return {
    addNewPost,
    newPosts,
    hadNews,
    handleSetViewedPosts,
    newNumber,
    viewedPosts
  }
}
const ServerContainer = ({
  item,
  id,
  type,
  schema,
  initialCurrent,
  createMenuContext = Null,
  routes = [],
  children
}) => {

  const [
    current,
    setCurrent
  ] = useState(initialCurrent)
  const login = useContext(LoginContext)
  const isOwner =
    login &&
    item &&
    item.owner &&
    login.username ===
    item.owner.username
  const handleFollow = useFollow(
    item
      ? {
        follow_status:
          item.follow_status,
        owner_type: type,
        owner_id: id
      }
      : {}
  )
  const handleJoin = useJoin(
    item
      ? {
        status: item.joined_status,
        type: type,
        id: id
      }
      : {}
  )
  const {
    newPosts = [],
    hadNews,
    handleSetViewedPosts,
    newNumber,
    addNewPost
  } = useNewPost()
  const dispatch = useDispatch()
  const handleWritePost = useCallback(
    (
      values,
      onSuccess = Null,
      onError = Null
    ) => {
      dispatch(
        createAsyncAction({
          apiInfo: post_create_Api,
          query: {
            ':prop': type,
            ':id': id
          },
          onSuccess: result => {
            addNewPost(result)
            onSuccess(result)
          },
          onError: () => {
            message.error('error')
            onError()
          }
        })
      )
    },
    [addNewPost, dispatch, type, id]
  )
  const history = useHistory()
  const handleActionClick = key => {
    message.info(key)
    switch (key) {
      case 'follow':
      case 'unfollow':
        handleFollow()
        break
      case 'join':
      case 'leave':
        handleJoin()
        break
      case 'setting':
        history.push(
          organizationModel.getLinkToSetting(
            item
          ),
          { isModal: true }
        )
        break
      case 'manage-channel-subscription':
        history.push(
          routeEntities
            .manageChannelSubscriptions
            .path
        )
        break
      default:
        setCurrent(key)
        break
    }
  }
  const contextMenu = createMenuContext(
    {
      setCurrent,
      handleActionClick
    }
  )
  const downloadFile = downloadURL => {
    if (downloadURL) {
      const newWindow = window.open(
        downloadURL,
        '_blank',
        'noopener,noreferrer',
      )
      if (newWindow) {
        newWindow.opener = null
      }
    }
  }
  const modalMatchParams = useParams()
  return (
    <ServerContext.Provider
      value={{
        id,
        type,
        addNewPost,
        onFollow: handleFollow,
        onJoin: handleJoin,
        handleSetViewedPosts,
        hadNews,
        newNumber,
        newPosts,
        contextMenu,
        handleActionClick,
        item,
        setCurrent,
        downloadFile,
        onWritePost: handleWritePost,
        current,
        isOwner,
        modalMatchParams,
      }}>
      {children}
      {current && (
        <Switch
          location={{
            hash: '',
            key: current,
            pathname: current,
            search: ''
          }}>
          {routes.map((route, i) => (
            <RouteWithSubRoutes
              key={i}
              {...route}
            />
          ))}
        </Switch>
      )}
    </ServerContext.Provider>
  )
}

export default ServerContainer
