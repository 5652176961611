import React from "react";

export const Coffee = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 58 56"
		>
			<g fill="none" fillRule="evenodd">
				<g fillRule="nonzero">
					<path
						fill="#f3d55b"
						d="M18.34 39.76l-6.26-8.23L16 .87a.994.994 0 01.99-.87h35.12L51 27l-4.89 20h-14.6z"
					></path>
					<path
						fill="#e4c05c"
						d="M57.99 40.17a.991.991 0 01-.67 1.09L54 42.37l-1.89.63-.11.07L46.11 47l6-47z"
					></path>
					<g fill="#b19046">
						<path d="M54 29v13.37l-1.89.63-.11.07V29a1 1 0 012 0zM46 9h-2a1 1 0 010-2h2a1 1 0 010 2zM40 9h-2a1 1 0 010-2h2a1 1 0 010 2zM34 9h-2a1 1 0 010-2h2a1 1 0 010 2zM27.909 9h-2a1 1 0 010-2h2a1 1 0 010 2zM21.909 9h-2a1 1 0 010-2h2a1 1 0 010 2z"></path>
					</g>
					<path
						fill="#ecf0f1"
						d="M22.719 17a1 1 0 00-.99.86l-1.72 12A1 1 0 0021 31h18.24a1 1 0 00.99-.86l1.72-12a1 1 0 00-.99-1.14z"
					></path>
					<path
						fill="#a56a43"
						d="M30.96 40.15h-.01c-2.46 2.34-4.33.56-6.79 3.02-2.51 2.5-.63 4.38-3.14 6.88-2.47 2.46-4.33.68-6.8 3.02a7.284 7.284 0 01-.48-.92c-1.38-3.25-.2-7.79 3.21-11.17.436-.436.9-.844 1.39-1.22 3.99-3.06 8.99-3.43 11.78-.66.085.08.162.166.23.26.224.247.427.511.61.79z"
					></path>
					<path
						fill="#805333"
						d="M31.51 47a13.092 13.092 0 01-3.28 5.24c-4.15 4.15-10.05 4.98-13.17 1.88a1.624 1.624 0 01-.18-.2.265.265 0 01-.05-.06 5.745 5.745 0 01-.61-.79c2.47-2.34 4.33-.56 6.8-3.02 2.51-2.5.63-4.38 3.14-6.88 2.46-2.46 4.33-.68 6.79-3.02h.01c.182.291.342.596.48.91.726 1.91.75 4.014.07 5.94z"
					></path>
					<path
						fill="#cf976a"
						d="M7.82 47.67c0 4.09 3.04 4.15 3.14 8.15v.01c-.327.07-.657.12-.99.15C4.52 56.4-.01 50.65 0 43.5c-.01-5.71 2.89-10.54 6.83-12.02.32-.13.652-.23.99-.3.1 4 3.14 4.06 3.14 8.15 0 4.17-3.14 4.17-3.14 8.34z"
					></path>
					<path
						fill="#cb8252"
						d="M16.95 40.98c-3.74 3.71-4.8 8.82-2.73 12.09.194.303.414.587.66.85a10.042 10.042 0 01-2.93 1.63c-.323.116-.654.21-.99.28v-.01c-.1-4-3.14-4.06-3.14-8.15 0-4.17 3.14-4.17 3.14-8.34 0-4.09-3.04-4.15-3.14-8.15A8.15 8.15 0 019.39 31a7.244 7.244 0 012.69.53c2.99 1.18 5.34 4.28 6.26 8.23-.49.376-.954.784-1.39 1.22z"
					></path>
					<path
						fill="#bdc3c7"
						d="M36 23H26a1 1 0 010-2h10a1 1 0 010 2zM34 27h-7a1 1 0 010-2h7a1 1 0 010 2z"
					></path>
					<path
						fill="#f0c419"
						d="M33.59 47h-2.08a8.149 8.149 0 00-.55-6.85 6.449 6.449 0 00-.84-1.05c-2.79-2.77-7.79-2.4-11.78.66-.92-3.95-3.27-7.05-6.26-8.23l.26-2.04.48.18a12.352 12.352 0 016.63 7.02 12.861 12.861 0 015.78-1.46 8.832 8.832 0 016.3 2.45c.414.423.786.885 1.11 1.38 1.405 2.4 1.75 5.276.95 7.94z"
					></path>
					<path
						fill="#805333"
						d="M10.18 51.22a7.164 7.164 0 011.77 4.33c-.323.116-.654.21-.99.28-.327.07-.657.12-.99.15 0-.04-.01-.09-.01-.14a5.262 5.262 0 00-1.37-3.42 7.285 7.285 0 010-9.52 5.186 5.186 0 001.37-3.57 5.114 5.114 0 00-1.36-3.55 7.184 7.184 0 01-1.77-4.3c.32-.13.652-.23.99-.3.327-.07.657-.12.99-.15a.57.57 0 01.01.13 5.262 5.262 0 001.37 3.42 7.285 7.285 0 010 9.52 5.186 5.186 0 00-1.37 3.57 5.114 5.114 0 001.36 3.55z"
					></path>
					<path
						fill="#603e26"
						d="M31.44 41.06a6.236 6.236 0 01-3.7 1.54 4.411 4.411 0 00-4.16 4.15 6.362 6.362 0 01-5.87 5.85 4.311 4.311 0 00-2.8 1.2c-.03.02-.05.04-.08.06a5.745 5.745 0 01-.61-.79 7.284 7.284 0 01-.48-.92 6.3 6.3 0 013.69-1.53 4.41 4.41 0 004.17-4.15 6.364 6.364 0 015.86-5.85 4.327 4.327 0 002.8-1.19.55.55 0 01.09-.07c.224.247.427.511.61.79.182.291.342.596.48.91z"
					></path>
				</g>
			</g>
		</svg>
	);
}

export default Coffee;
