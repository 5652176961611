import { API_ROOT_URL } from 'envs/_current/config'
import { schema } from 'normalizr'
import {
  baseItemSchema,
  channelSchema,
  channelSubscriptionSchema
} from './schema'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

export const billingSchema = new schema.Entity(
  'billings',
  {},
  {
    idAttribute: () => 'billing'
  }
)

export const billing_getOverviewData_Api = {
  name: 'billing_getOverviewData',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/class-subscription/overview',
  method: 'GET',
  schema: billingSchema,
  transform: createTransform(
    billingSchema,
    'data'
  )
}

// Route::post('auth/auth-billing/add', 'UserBillingController@addBilling')
export const billing_addBillingAddress_Api = {
  name: 'billing_addBillingAddress',
  backend: ``,
  root: API_ROOT_URL,
  path: '/auth/auth-billing/add',
  method: 'POST',
  fields: [
    'payment_period',
    'country_id',
    'state',
    'city',
    'postal_code',
    'tax_code',
    'name',
    'address',
    'address2'
  ]
}

// /Route::post('auth/auth-billing/get'
export const billing_get_Api = {
  name: 'billing_get',
  backend: ``,
  root: API_ROOT_URL,
  path: '/auth/auth-billing/get',
  method: 'GET'
}

// 'payments/auth-history/gets
export const billing_authHistory_Api = {
  name: 'billing_authHistory',
  backend: ``,
  root: API_ROOT_URL,
  path: '/payments/auth-history/gets',
  method: 'GET',
  paramsFields: ['page']
}

// Route::get('features/get-all', 'ClassSubscriptionController@getStaticFeaturesOfClass');
export const billing_getStaticFeaturesOfClass_Api = {
  name:
    'billing_getStaticFeaturesOfClass',
  backend: `Route::get('features/get-all', 'ClassSubscriptionController@getStaticFeaturesOfClass')`,
  root: API_ROOT_URL,
  path: '/features/get-all',
  method: 'GET'
}

// Route::get('features/get-default-amount', 'ClassSubscriptionController@getStaticAmountList');
export const billing_getStaticAmountList_Api = {
  name: 'billing_getStaticAmountList',
  backend: `getStaticAmountList`,
  root: API_ROOT_URL,
  path: '/features/get-default-amount',
  method: 'GET'
}

// Route::post('auth/set-subscribe-class/{code}', 'ClassSubscriptionController@selectSubscription');
// code: authority_class: 0,1,2,3

export const billing_selectSubscription_Api = {
  name: 'billing_selectSubscription',
  backend: `selectSubscription`,
  root: API_ROOT_URL,
  path:
    '/auth/set-subscribe-class/:code',
  method: 'POST',
  transform: createTransform(
    new schema.Entity(
      'billings',
      {},
      {
        idAttribute: () => 'billing',
        processStrategy: entity => ({
          id: 'billling',
          class_subscription: entity
        })
      }
    ),
    'data'
  )
}

// Route::post('auth/validate-class-subscription/{code}', 'ClassSubscriptionController@validateSelectedSubscription');
// code: authority_class: 0,1,2,3
export const billing_validateSelectedSubscription_Api = {
  name:
    'ClassSubscriptionController@validateSelectedSubscription',
  backend: `auth/validate-class-subscription/{code}`,
  root: API_ROOT_URL,
  path:
    '/auth/validate-class-subscription/:code',
  method: 'POST'
}

// Route::get('auth/channel-subscriptions/gets', 'ChannelSubscriptionController@getChannelSubscriptionsByLogin');
export const billling_getChannelSubscriptionsByLogin_Api = {
  name:
    'billling_getChannelSubscriptionsByLogin',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/channel-subscriptions/gets',
  method: 'GET',
  schema: channelSubscriptionSchema,
  transform: createListTransform(
    channelSubscriptionSchema
  )
}
// Route::get('auth/dataset-subscriptions/gets', 'DatasetSubscriptionController@getDatasetSubscriptionsByLogin')
export const billling_getDatasetSubscriptionsByLogin_Api = {
  name:
    'getDatasetSubscriptionsByLogin',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/dataset-subscriptions/gets',
  method: 'GET',
  schema: baseItemSchema,
  transform: createListTransform(
    baseItemSchema
  )
}
// Route::post('auth/set-subscribe-trial-class/{code}', 'ClassSubscriptionController@subscribeTrialClass');
// code: authority_class: 0,1,2,3
export const billing_subscribeTrialClass_Api = {
  name: 'billing_subscribeTrialClass',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/set-subscribe-trial-class/:code',
  method: 'POST'
}

// Route::post('auth/set-subscribe-trial-channel/{channel_id}', 'ChannelSubscriptionController@subscribeTrialChannel')
export const billing_subscribeTrialChannel_Api = {
  name: 'billing_subscribeTrialChannel',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/set-subscribe-trial-channel/:channel_id',
  method: 'POST',
  transform: createTransform(
    channelSchema,
    'data'
  )
}

// Route::post('payments/deposit-with-paypal/channels/{id}/subscribe', 'PaymentController@depositChannelSubscriptionWithPaypal');
// description, amount
export const billing_depositChannelSubscriptionWithPaypal_Api = {
  name:
    'billing_depositChannelSubscriptionWithPaypal',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/payments/deposit-with-paypal/channels/:id/subscribe',
  method: 'POST',
  transform: createTransform(
    channelSubscriptionSchema,
    'data'
  ),
  fileds: ['description', 'amount']
}

// Route::post('payments/vnpay/deposit/channels/{id}/subscribe', 'VNPayController@depositChannelSubscriptionWithVNPay'); // description, amount
export const billing_channelSubscriptionViaGateway_Api = {
  name:
    'billing_channelSubscriptionViaGateway',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/payments/vnpay/deposit/channels/:id/subscribe',
  method: 'POST',
  transform: createTransform(
    channelSubscriptionSchema,
    'data'
  ),
  fileds: ['description', 'amount']
}

// Route::post('auth/validate-channel-subscription/{channel_id}', 'ChannelSubscriptionController@validateSelectedChannel'); // channel_id
export const billing_validateSelectedChannel_Api = {
  name:
    'billing_validateSelectedChannel',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/validate-channel-subscription/:channel_id',
  method: 'POST',
  transform: createTransform(
    channelSubscriptionSchema,
    'data'
  )
}
// Route::post('auth/set-subscribe-channel/{channel_id}', 'ChannelSubscriptionController@subscribeChannel'); // channel_id
export const billing_subscribeChannel_Api = {
  name: 'billing_subscribeChannel',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/set-subscribe-channel/:channel_id',
  method: 'POST',
  transform: createTransform(
    channelSchema,
    'data'
  )
}

// Route::post('auth/set-subscribe-trial-channel/{channel_id}', 'ChannelSubscriptionController@subscribeTrialChannel'); // channel_id

// Route::post('auth/set-unsubscribe-channel/{channel_id}', 'ChannelSubscriptionController@unsubscribeChannel'); // nothing
export const billding_unsubscribeChannel_Api = {
  name: 'billding_unsubscribeChannel',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/auth/set-unsubscribe-channel/:channel_id',
  method: 'POST',
  transform: createTransform(
    channelSchema,
    'data'
  )
}

// Route::get('channels/{id}/channel-subscriptions/gets', 'ChannelSubscriptionController@getMembersByChannelSubs'); // nothing
export const billing_getMembersByChannelSubs_Api = {
  name:
    'billing_getMembersByChannelSubs',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/channels/:id/channel-subscriptions/gets',
  method: 'GET',
  transform: createListTransform(
    baseItemSchema
  )
}
