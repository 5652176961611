import {Button} from 'antd'
import Null from 'components/NullComponent'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useEffect, useState} from 'react'
import {SettingsModal} from 'views/Organization/Settings/SettingsModal'
import SlateEditor, {SlateRenderer} from 'views/SlateEditor'
import {convertFromString, convertToString} from 'views/SlateEditor/functions'

const ModalRichEditorContainer = ({
                                    isEmpty = false,
                                    title = 'Edit articles',
                                    value,
                                    isLoading,
                                    toggle,
                                    onSubmit = Null,
                                    setToggle,
                                    ...props
                                  }) => {
  const translate = useTranslate()
  const [editorState, setEditorState] = useState()

  useEffect(() => {
    setEditorState(convertFromString(value))
  }, [value])

  const handleSubmit = useCallback(() => {
    const content = convertToString(editorState)
    onSubmit(content)
  }, [editorState])

  return (
    <>
      {!isEmpty && (
        <div className="w-full">
          <SlateRenderer value={editorState}/>
        </div>
      )}
      {toggle && (
        <SettingsModal
          className="rounded-lg"
          name="edit"
          onCancel={() => setToggle(false)}
          e
          {...props}>
          <div className="p-3">
            <SlateEditor
              name="about"
              editorState={editorState}
              onChange={(event) => {
                setEditorState(event?.target?.value)
              }}
            />
            <div className="flex justify-end p-3">
              <Button
                key="back"
                className="rounded-full border-none"
                onClick={() => setToggle(false)}>
                {translate('cancel')}
              </Button>
              <Button
                className="rounded-full ml-3"
                key="submit"
                type="primary"
                loading={isLoading}
                onClick={handleSubmit}>
                {translate('save')}
              </Button>
            </div>
          </div>
        </SettingsModal>
      )}
    </>
  )
}
export default ModalRichEditorContainer
