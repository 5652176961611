import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {Switch} from 'antd'
import classnames from 'classnames'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo} from 'react'
import {BsShieldLockFill} from "react-icons/bs";

const FieldDescription = ({children, className, description}) => {
  const t = useTranslate()
  return (
    <div className={className}>
      <div className="flex-1 text-sm text-color-300 italic">{t(description)}</div>
      {children}
    </div>
  )
}

export const ToggleField = ({
    name,
    title,
    value,
    disabled,
    className,
    onChange,
    description,
    defaultValue,
    Wrapper = FieldDescription,
}) => (
  <Wrapper
    description={description}
    className={classnames(
      'flex items-start justify-start w-full space-x-3',
      className
    )}>
    <Switch
      disabled={disabled}
      onChange={onChange}
      checkedChildren={<CheckOutlined/>}
      unCheckedChildren={<CloseOutlined/>}
      defaultChecked={defaultValue}
      {...(value
        ? {
          checked: value,
          }
        : {})}
    />
  </Wrapper>
)

export const CustomToggleField = ({
                                    title,
                                    className,
                                    description,
                                    Icon = BsShieldLockFill,
                                    ...props
                                  }) => {
  const t = useTranslate()
  const Wrapper = useMemo(() => {
    return ({children}) => (
      <div className="flex flex-col">
        <div className="w-full flex items-center">
          <div className="flex flex-1 items-center gap-2">
            <Icon
              style={{
                color: 'var(--color-primary)',
                fontSize: '1.2em',
              }}
            />
            <span className="font-bold text-lg text-primary">{t(title)}</span>
          </div>
          {children}
        </div>
        <div className="text-sm text-color-300 italic mt-2">
          {t(description)}
        </div>
      </div>
    )
  }, [t, title, description])
  return (
    <div className="p-3 border border-dashed border-primary bg-primary-50 rounded-lg">
      <ToggleField
        {...props}
        Wrapper={Wrapper}
      />
    </div>
  )
}
