import {Button, Dropdown, Menu} from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import React, {useCallback, useState} from 'react'
import {BsGlobeAmericas, BsPersonCircle} from 'react-icons/bs'
import {Null} from 'views/Shared'
import Translate from '../../../modules/local/Translate'
import useTranslate from '../../../modules/local/useTranslate'
import './PostPrivacy.css'

export const PostPrivacyTypes = Object.freeze({
  public: {
    value: 1,
    key: 1,
    label: 'public',
    title: 'public',
    icon: <BsGlobeAmericas size={18}/>,
    description:
      'Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this',
  },
  private: {
    value: 2,
    key: 2,
    label: 'private',
    title: 'private',
    description:
      'Only members can view data content, post articles or comments on this',
    icon: <BsPersonCircle size={18}/>,
  },
})

export const postPrivacy = Object.values(PostPrivacyTypes).map(
  ({key, ...rest}) => ({
    ...rest,
  })
)

const defaultPrivacyOptions = Object.values(PostPrivacyTypes).map(
  ({label, description, ...rest}) => ({
    ...rest,
    label: (
      <Translate>
        {(t) => (
          <div className="flex flex-col">
            <span className="font-bold text-sm">{t(label)}</span>
            <span className="text-xs italic leading-tight">
              {t(description)}
            </span>
          </div>
        )}
      </Translate>
    ),
  })
)

const PostPrivacy = ({
                       renderItem,
                       defaultValue,
                       onSelect = Null,
                       items = defaultPrivacyOptions,
                     }) => {
  const t = useTranslate()

  const [visible, setVisible] = useState()

  const [privacy, setPrivacy] = useState(Number(PostPrivacyTypes.public.value))

  const handleSelect = useCallback(
    ({key}) => {
      const current = Number(key)
      setPrivacy(current)
      onSelect(current)
    },
    [onSelect, setPrivacy]
  )

  const renderPrivacy = useCallback(
    (value) => {
      const item = _.find(items, {
        value: Number(value),
      })
      if (_.isEmpty(item)) return null
      if (renderItem) {
        return renderItem(item)
      } else {
        return (
          <Button
            icon={item.icon}
            type={visible ? 'primary' : 'default'}
            className="flex items-center gap-2 button-rounded-lg">
            {t(item.title)}
          </Button>
        )
      }
    },
    [items, visible, renderItem]
  )

  return (
    <Dropdown
      getPopupContainer={() => document.getElementById('AttachedActions')}
      onVisibleChange={setVisible}
      overlayClassName="w-full"
      overlay={
        <Menu
          items={items.map(({value, ...rest}) => ({
            value,
            ...rest,
            className: classNames(
              'background',
              value === defaultValue && 'ant-dropdown-menu-item-selected'
            ),
          }))}
          selectable={true}
          onSelect={handleSelect}
        />
      }
      trigger={['click']}>
      <div>{renderPrivacy(privacy)}</div>
    </Dropdown>
  )
}

export default PostPrivacy
