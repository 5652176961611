import { LoginContext } from 'components/LoginContext'
import { useQueryString } from 'hooks'
import _ from 'lodash'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useContext,
  useState
} from 'react'
import { staticPaths } from 'routes/staticPaths'
import { Null } from 'views/Shared'
import SearchContext from './SearchContext'

export const SearchTypes = Object.freeze(
  {
    ALL: 'all',
    USER: 'user',
    ARTICLE: 'article',
    CHANNEL: 'channel',
    EVENT: 'event',
    DATASET: 'dataset',
    ORGANIZATION: 'organization'
  }
)

export const fromSearchTab = type => {
  switch (type) {
    case SearchTypes.USER:
      return 'user'
    default:
      return type
  }
}

export const defaultSearchType =
  SearchTypes.ALL

export const SearchParams = Object.freeze(
  {
    TYPE: 'type',
    KEYWORD: 'keyword',
    CATEGORIES: 'categories'
  }
)

const getSearchParams = values => {
  const types = Object.values(
    SearchParams
  )
  return types.reduce(
    (arr, current) => {
      return {
        ...arr,
        [current]: _.get(
          values,
          current
        )
      }
    },
    {}
  )
}

const authenticatedTabOptions = [
  SearchTypes.ARTICLE,
  SearchTypes.CHANNEL,
  SearchTypes.EVENT,
  SearchTypes.DATASET,
  SearchTypes.ORGANIZATION,
  SearchTypes.USER
]

const unauthenticatedTabOptions = [
  SearchTypes.ARTICLE,
  SearchTypes.CHANNEL,
  SearchTypes.EVENT,
  SearchTypes.DATASET
]

const SearchProvider = ({
  location,
  children
}) => {
  const login = useContext(LoginContext)

  const [
    current,
    setCurrent
  ] = useState('')

  const { pathname } = location || {}

  const [searchParams] = useQueryString(
    pathname
  )

  const history = useHistory()

  const searchTabOptions = login
    ? authenticatedTabOptions
    : unauthenticatedTabOptions

  const onSearch = (
    { type, keyword = '' },
    animate = Null
  ) => {
    animate()
    const _searchParams = new URLSearchParams()
    if (!_.isEmpty(type)) {
      _searchParams.set(
        SearchParams.TYPE,
        Object.values(
          SearchTypes
        ).includes(type)
          ? type
          : defaultSearchType
      )
    }
    if (!_.isEmpty(keyword.trim())) {
      _searchParams.set(
        SearchParams.KEYWORD,
        keyword
      )
    }
    history.push({
      pathname:
        staticPaths.discovery.pathname,
      search: _searchParams.toString()
    })
  }

  const onClear = () => {
    const queryParams = new URLSearchParams(
      location.search
    )
    if (
      queryParams.has(
        SearchParams.KEYWORD
      )
    ) {
      queryParams.delete(
        SearchParams.KEYWORD
      )
    }
    history.replace({
      search: queryParams.toString()
    })
  }

  return (
    <SearchContext.Provider
      value={{
        current,
        onClear,
        onSearch,
        setCurrent,
        searchParams,
        getSearchParams,
        searchTabOptions
      }}>
      {children}
    </SearchContext.Provider>
  )
}

export default SearchProvider
