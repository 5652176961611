import {Avatar} from 'antd'
import classNames from 'classnames'
import {SearchGroupIcon} from 'components/AppIcon'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {Null, renderIf} from 'views/Shared'
import {LayoutContext} from '../layouts/Default/LayoutContext'
import {WithDetailPopup} from '../WithDetailPopup'

const spliter = '__MENTION__'

function toBase64(text) {
  return Buffer.from(text).toString('base64')
}

function fromBase64(text, encoding = 'utf8') {
  return Buffer.from(text, 'base64').toString(encoding)
}

export const encryptMentionTitle = (
  title,
  params = {
    prefix: undefined,
    connector: undefined,
  }
) => {
  const {prefix, connector = spliter} = params ?? {}
  return [prefix ? toBase64(prefix) : '', toBase64(title)].join(connector)
}

export const decryptMentionTitle = (
  name,
  params = {
    connector: undefined,
  }
) => {
  const {connector = spliter} = params ?? {}
  const array = String(name).split(connector)
  return array.map((item) => fromBase64(item))
}

export const mark_as_mention = (text, params) => {
  const {index = 0, owner_id, owner_type, ...rest} = params ?? {}
  const string = String(text ?? '')
  if (_.isEmpty(string)) return string
  return `[${string}${`(${index + 1})`}]`
}

export const MentionSuggestionItem = ({
                                        id,
                                        type,
                                        name,
                                        avatar,
                                        onClick,
                                        showAvatar = true,
                                      }) => {
  const t = useTranslate()

  return (
    <div
      key={id}
      onClick={onClick}
      style={{padding: '5px 12px'}}
      className="flex items-center gap-2 truncate text-color-000 hover:text-primary hover:background-100 cursor-pointer">
      {!!showAvatar && (
        <Avatar
          size={25}
          src={avatar}
          className="flex flex-center background-200"
          icon={
            <SearchGroupIcon
              size={13}
              type={type}
              className="text-color-400"
            />
          }
        />
      )}
      <div className="flex flex-col flex-1 truncate">
        <span className="font-medium text-sm leading-tight truncate">
          {name}
        </span>
        {renderIf(
          type,
          <span className="capitalize font-medium text-2xs text-green-600 italic">
            {t(type)}
          </span>
        )}
      </div>
    </div>
  )
}

export const renderMention =
  ({onClick = Null} = {}) =>
    (item, index) => {
      const {
        id,
        name,
        type,
        label,
        avatar,
        fixed = false,
        onItemClick = Null,
      } = item ?? {}
      return (
        <MentionSuggestionItem
          key={index}
          title={label}
          selectedkey={id}
          {...{
            id,
            name,
            type,
            avatar,
          }}
          onClick={() => {
            onClick({id, type, name})
          }}
        />
      )
    }

export const Mention = ({prefix, children, mentions}) => {
  const {isSm} = useContext(LayoutContext)
  const [type, id, name] = children.split(':')
  const title = decodeURI(name);
  const mention = _.find(
    mentions,
    (item) =>
      item?.owner_type === type &&
      (item?.owner_id === id ||
        item?.idname === id ||
        item?.owner?.username === id),
    {}
  )

  const {owner = {}} = mention ?? {}

  const OwnerLink = owner ? Link : 'div'

  if (type === "keyword") {
    if (isSm) {
      return (
        <OwnerLink
          to={`/t/${id}`}
          className={classNames('text-sm text-primary font-medium')}>
          {title}
        </OwnerLink>
      )
    }
    return (
      <WithDetailPopup item={{_type: 'keyword', id, title}}>
        <span
          className={classNames(
            'inline text-sm text-primary font-medium cursor-pointer'
          )}>
          {title}
        </span>
      </WithDetailPopup>
    )
  }

  if (!mention) {
    return children
  }

  return (
    <WithDetailPopup
      item={{
        ...(owner ?? {}),
        description: owner?.description,
      }}>
      <span className="color-primary font-medium cursor-pointer">
        {title}
      </span>
    </WithDetailPopup>
  )
}
