import {Alert, Button, message, Tooltip, Upload,} from 'antd'
import {getId, getType,} from 'apis/model/base'
import {handleconfirm} from 'components/Confirm'
import Toggle from 'components/Toggle'
import copyToClipboard from 'copy-to-clipboard'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import React, {useContext, useState,} from 'react'
import {IoCloudUploadOutline,} from 'react-icons/io5'
import LoadingPage from 'views/LoadingPage'
import {AcceptFileTypes} from 'views/Messages/DragDropFile'
import NoFoundPage from 'views/NoFoundPage'
import {Null, renderOwnChild,} from 'views/Shared'
import {bindQueryParam} from '../functions/routerHelper'
import {notifyOnError} from './createErrorEvent'
import {PiShootingStarDuotone} from "react-icons/pi";
import {RxCopy} from "react-icons/rx";
import {renderFromToTime, renderIf, renderIfElse} from "../../Shared";
import {BiHelpCircle} from "react-icons/bi";

const course_student_template_url =
  'https://docs.google.com/spreadsheets/d/17_Y7P1oTndoY0nG8sRmdYXx-wtUAT0cwpW62SkswFiY/edit?usp=sharing'
const course_attendance_template_url =
  'https://docs.google.com/spreadsheets/d/1dQmHNJpdspoQhJGxKZBzuXuKrXhV14zZx-oVQ8Co0FQ/edit?usp=sharing'
const course_grade_template_url =
  'https://docs.google.com/spreadsheets/d/1_9MQM6x2GHVkFKH5IGy2HA2QnUfEADBPh1M9t-RDgIU/edit?usp=sharing'

const renderSelectedFile = (file) => (
  <Translate>
    {(t) =>
      file ? (
        <div className="flex flex-col items-center">
          <IoCloudUploadOutline
            size={40}
            className="text-primary"
          />
          <span className="text-xs font-medium text-color-100">
            {file.name} {' - '}
            <span className="text-color-000 italic">
              {(file.size / 1024)
                .toFixed(2)
                .toLocaleString()}
              {' kB'}
            </span>
          </span>
        </div>
      ) : (
        <div className="flex flex-col items-center">
          <IoCloudUploadOutline
            size={40}
            style={{color: '#e0e0e0'}}
          />
          <p className="text-xs font-medium text-color-200">
            {t('click to upload')}
          </p>
        </div>
      )
    }
  </Translate>
)

const ItemNotes = ({
                     name,
                     label,
                     column,
                     optional,
                     noted = false,
                     HelperComponent = Null,
                   }) => (
  <Translate>
    {(t) => (
      <div className="flex items-center text-color-300 gap-1 text-xs">
        <span className="text-primary font-bold">
          {`${column} `}
        </span>
        {' - '}
        <span className="text-color-200 font-bold">
          {`${name} : `}
        </span>
        <span className="text-color-400 italic font-light">
          {t(label)}
        </span>
        {optional && (
          <span className="text-color-400 italic font-light">
            {`(${t('optional')})`}
          </span>
        )}
        <HelperComponent/>
        {renderIf(
          noted === true,
          <PiShootingStarDuotone className="text-primary-500"/>
        )}
      </div>
    )}
  </Translate>
)

const DescriptionTemplate = ({
                               inputHelpers = [],
                               notedDescription,
                             }) => {
  const t = useTranslate()

  const noted = _.find(Array.from(inputHelpers), ['noted', true])

  return (
    <Toggle>
      {(isToggle, toggle) => (
        <div className="flex flex-col px-3 py-1 border rounded-lg border-dashed">
          <div className="space-y-1">
            <span className="text-xs italic text-color-300 underline">
              {`${t('notes description')} : `}
            </span>
            <div className="space-y-1 pl-3">
              {Array.from(inputHelpers)
                .filter(
                  (e) =>
                    isToggle ||
                    !!!e.optional
                )
                .map((e, i) => (
                  <ItemNotes
                    key={i}
                    {...e}
                  />
                ))}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="border-b flex-1 ml-3"/>
            <div
              onClick={toggle}
              className="text-xs font-light italic cursor-pointer text-color-400 hover:text-color-100">
              {t(
                isToggle
                  ? 'see less'
                  : 'see more'
              )}
            </div>
          </div>

          {renderIf(
            noted,
            <span className="space-x-1 text-xs text-color-500 italic">
                <PiShootingStarDuotone className="text-primary-500"/>
                <span className="font-medium text-color-100">
                  {`- ${t('note')} : `}
                </span>
                <span>{t(notedDescription)}</span>
              </span>
          )}

        </div>
      )}
    </Toggle>
  )
}

const ImportModalContent = ({
                              title,
                              item,
                              query,
                              values,
                              apiInfo,
                              onCancel = Null,
                              maxSize = 2048000,
                              Description = Null,
                              templateUrlPath = '',
                              Wrapper = renderOwnChild,
                            }) => {
  const t = useTranslate()

  const {handleGoBack = Null} =
    useContext(NavigationContext)

  const [isInValid, setInvalid] =
    useState()

  const [file, setFile] = useState()

  const handleFile = (file) => {
    const {name, size, ...rest} =
    file || {}
    setInvalid(size > maxSize)
    if (size > maxSize) {
      message.error(
        <div className="flex gap-3">
          <span className="text-color-300 font-light text-sm italic">
            {t('current size')} {' : '}
            <span className="font-medium text-color-000">
              {Math.round(size / 1024)} {' MB'}
            </span>
          </span>
          <span className="text-color-300 font-light text-sm italic">
            {t('max size')} {' : '}
            <span className="font-medium text-color-000">
              {Math.round(maxSize / 1024)} {' MB'}
            </span>
          </span>
        </div>
      )
    } else {
      setFile(file)
    }
  }

  const {
    isLoading = false,
    handleAsyncAction,
  } = useAsyncAction({
    query,
    values,
    apiInfo,
    onError: notifyOnError(t),
    onSuccess: (__, data) => {
      const {total = 0, success = 0} =
        _.get(data, 'response.data', {})
      handleconfirm({
        title: t('upload file'),
        okText: t('OK'),
        cancelText: t('cancel'),
        okButtonProps: {
          type: 'primary',
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
        cancelButtonProps: {
          className:
            'rounded-lg no-shadow no-text-shadow',
        },
        onOk: () => {
          // handleGoBack()
          onCancel()
        },
        content: (
          <Alert
            type="success"
            className="background border-green-600"
            message={
              <div
                className="flex flex-row items-center py-1"
                role="alert">
                <div className="flex flex-col text-sm">
                  <span className="text-color-300 font-light italic">
                    {t('total row')}{' '}
                    {' : '}
                    <span className="font-medium text-color-000">
                      {total} {t('row')}
                    </span>
                  </span>
                  <span className="text-color-300 font-light italic">
                    {t('success row')}{' '}
                    {' : '}
                    <span className="font-medium text-green-700">
                      {success}{' '}
                      {t('row')}
                    </span>
                  </span>
                </div>
              </div>
            }
          />
        ),
      })
    },
  })

  const {time_open, time_close} = item ?? {}

  if (!item || _.isEmpty(item)) {
    return <NoFoundPage/>
  }

  return (
    <Wrapper>
      <div className="flex flex-col w-full space-y-4">
        <div className="flex flex-col gap-2">
          <span className="text-color-300 uppercase font-medium">
            {t('you are importing')}{' '}{renderIfElse(title, t(title), t(getType(item)))}
          </span>
          <div className="background border border-color-50 shadow-items-md px-3 py-2 rounded-md">
            <div className="flex flex-col gap-1 flex-1">
              <span className="font-bold text-color-000">
                {getTitle(item)}
              </span>
              <span className="font-bold text-primary text-xs italic">
                <span className="font-normal text-color-500 mr-1">
                  {t('organized by')}
                </span>
                {_.get(item, 'owner.title')}
              </span>
              {renderIf(
                _.some([time_open, time_close]),
                <span className="font-bold text-color-100 text-xs italic">
                    <span className="font-normal text-color-500 mr-1">
                      {`${t('time takes place')} : `}
                    </span>
                  {renderFromToTime(_.get(item, 'time_open'), _.get(item, 'time_close'))}
                  </span>
              )}
            </div>
          </div>
        </div>
        <div className="w-full custom-upload-file">
          <Upload.Dragger
            name="file"
            style={
              isInValid
                ? {
                  borderColor: 'var(--border-color-primary)',
                } : {
                  borderRadius: '0.375rem',
                  borderColor: 'var(--border-color-base)',
                }
            }
            accept={[
              AcceptFileTypes.XSL,
              AcceptFileTypes.XSL_APP,
              AcceptFileTypes.XSLX_APP,
            ].join(',')}
            onDrop={(event) => {
              handleFile(
                _.get(
                  event,
                  'dataTransfer.files[0]'
                )
              )
            }}
            showUploadList={false}
            beforeUpload={(file) => {
              handleFile(file)
              return false
            }}>
            {renderSelectedFile(file)}
            <p className="text-2xs italic font-light text-color-500">
              {t('limit description')}{' '}{' : '}
              <span className="font-semibold text-color-100 ml-1">
                {'2MB'}
              </span>
            </p>
          </Upload.Dragger>
        </div>

        <div className="flex flex-col gap-1">
          <span className="text-color-300 uppercase font-medium">
            {t('template data')}
          </span>
          <div className="flex items-center gap-2">
            <a
              style={{height: 32, padding: 6}}
              target="_blank"
              className="font-light text-xs italic text-color-300 truncate px-2 border background rounded-md"
              rel="noopener noreferrer"
              href={templateUrlPath}>
              {templateUrlPath}
            </a>

            <Button
              onClick={() => {
                copyToClipboard(
                  templateUrlPath
                )
                message.success(t('copied'))
              }}
              className="button-rounded-md flex flex-center text-color-300 hover:text-primary px-3 py-2"
              type="dashed">
              <RxCopy/>
            </Button>
            <Button
              className="no-shadow no-text-shadow rounded-md no-border"
              type="primary">
              <a
                target="_blank"
                className="no-underline"
                rel="noopener noreferrer"
                href={templateUrlPath}>
                {t('preview template')}
              </a>
            </Button>
          </div>
        </div>

        <Description/>

        <div className="flex justify-end gap-2">
          <NavigationContext.Consumer>
            {({
                handleGoBack = Null,
              }) => (
              <Button
                className="no-shadow no-text-shadow rounded-lg no-border"
                onClick={() => {
                  handleGoBack()
                  onCancel()
                }}>
                {t('close')}
              </Button>
            )}
          </NavigationContext.Consumer>
          <Button
            type="primary"
            loading={!!isLoading}
            className="no-shadow no-text-shadow rounded-lg no-border"
            disabled={
              !!isLoading || !file
            }
            onClick={() => {
              handleAsyncAction({
                file,
              })
            }}>
            {t('import')}
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}

export const ImporterLoader = ({
                                 id,
                                 type,
                                 onCancel,
                               }) => {
  if (_.isEmpty(id)) {
    return null
  }
  switch (type) {
    default:
      return null
  }
}
