export const staticPaths = {
  account: {
    pathname: '/account'
  },
  accountOrganization: {
    pathname: '/account/organization'
  },
  accountOrganizationMember: {
    pathname:
      '/account/organization/member'
  },
  accountOrganizationAdmin: {
    pathname:
      '/account/organization/admin'
  },
  accountChannel: {
    pathname: '/account/channel'
  },
  accountChannelFollow: {
    pathname:
      '/account/channel?type=follow'
  },
  accountChannelAdmin: {
    pathname: '/account/channel'
  },
  accountSettings: {
    pathname: '/account/settings',
    state: {
      isModal: true
    }
  },
  createProvider: {
    pathname: '/create-provider',
    state: {
      isModal: true
    }
  },
  createChannel: {
    pathname: '/create-channel',
    state: {
      isModal: true
    }
  },

  login: {
    pathname: '/login',
    state: {
      isModal: true
    }
  },
  select_category: {
    pathname: '/select-category'
  },
  category: {
    pathname: '/category'
  },
  logout: {
    pathname: '/logout',
    state: {
      isModal: true
    }
  },
  home: {
    pathname: '/'
  },
  discovery: {
    pathname: '/discovery'
  },
  bookmark: {
    pathname: '/bookmark'
  },
  welcome: {
    pathname: '/welcome'
  },
  news: {
    pathname: '/news'
  },
  data_board: {
    pathname: '/data-board'
  },
  about: {
    pathname: '/document/about-us'
  },
  policy: {
    pathname: '/document/privacy-policy'
  },
  tutorials: {
    pathname: '/document/tutorials'
  }
}
