import { comment_editComment_Api } from 'apis'
import { getText, get_mention_data } from 'components/Feed/MentionDataContext'
import InputBar from 'components/InputBar'
import { Formik } from 'formik'
import _ from 'lodash'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/AppLocals/factory/createNotification'
import { Null } from 'views/Shared'

const ResponsesEditAction = ({ item, onExit = Null }) => {
  const t = useTranslate()
  const mentions = Array.from(item?.mentions ?? [])
  const message = getText(item?.message, get_mention_data(mentions, true))
  const initialValues = {
    mentions,
    message: item?.message + ` `, // Thêm khoảng trắng để ko dính vào phần mention
  }

  if (_.isEmpty(item)) return null
  return (
    <AsyncAction
      onSuccess={() => {
        successNotify(NotificationActionTypes.UPDATE, t)
        onExit()
      }}
      apiInfo={comment_editComment_Api}
      query={{
        ':prop': item?.container_type,
        ':id': item?.container_id,
        ':comment_id': item?.id,
      }}>
      {({ isLoading, handleAsyncAction }) => (
        <Formik
          initialValues={initialValues}
          onSubmit={handleAsyncAction}>
          {({ values }) => (
            <div className="relative">
              <InputBar
                textOnly={true}
                defaultHeight={90}
                isLoading={!!isLoading}
                placeholder={t('reply')}
                defaultValue={values?.message}
                mention_data={get_mention_data(mentions)}
                handleSubmit={({ description }) => {
                  handleAsyncAction({
                    message: description,
                  })
                }}
              />
              <div
                onClick={onExit}
                className="text-xs cursor-pointer italic text-color-300 hover:text-red-600 text-right">
                {t('cancel')}
              </div>
            </div>
          )}
        </Formik>
      )}
    </AsyncAction>
  )
}

export default ResponsesEditAction
