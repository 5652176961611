import {Result} from 'antd'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import PermissionContext from 'modules/permissions/PermissionContext'
import React from 'react'
import {branch, compose, fromRenderProps,} from 'recompose'

export const hasPermissions = (
  permissions = [],
  required_permissions = []
) =>
  _.isEmpty(permissions) ||
  _.isEmpty(required_permissions)
    ? true
    : !!_.find(
    required_permissions,
    (key) => !!permissions[key]
    )

export const invalidPermissions =
  (permissions) =>
    ({required_permissions}) =>
      !hasPermissions(
        permissions,
        required_permissions
      )

export const createCheckPermissions = (
  checkPermissions = [],
  FallBackComponent = () => (
    <Translate>
      {(t) => (
        <Result
          status="403"
          title={t('permission denied')}
        />
      )}
    </Translate>
  )
) =>
  compose(
    fromRenderProps(
      PermissionContext.Consumer,
      (permissions) => ({
        denied: checkPermissions.find(
          (key) =>
            !permissions ||
            !permissions[key]
        ),
      })
    ),
    branch(
      ({denied}) => denied,
      () => FallBackComponent
    )
  )
