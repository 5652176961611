import {Button, Menu, Select, Tooltip} from 'antd'
import _ from "lodash"
import React, {useContext, useState} from 'react'
import {
    BsBarChartLine,
    BsCaretDownFill,
    BsCaretRightFill,
    BsChevronDown,
    BsInputCursorText,
    BsThreeDots
} from "react-icons/bs"
import {product_renameProductCatalogTitle_Api} from "../../../../apis"
import {organizationModel, productModel} from '../../../../apis/model'
import {productSchema} from '../../../../apis/schema'
import DrawMenu from "../../../../components/drawer/DrawMenu"
import EmptyHolder from "../../../../components/EmptyHolder"
import {PopupConfirm} from "../../../../components/form/PopupConfirm"
import {LoginContext} from "../../../../components/LoginContext"
import {FullSideModalLayout} from "../../../../components/Modal/SideModalLayout"
import {SelectEntityItem} from '../../../../components/SelectEntityItem'
import Toggle from "../../../../components/Toggle"
import {API_ROOT_URL} from "../../../../envs/_current/config"
import useAsyncWithCache from '../../../../modules/asyncCache/useAsyncWithCache'
import useTranslate from '../../../../modules/local/useTranslate'
import {useHistory} from "../../../../modules/navigation/useRouter"
import {OwnedProductPriceSetGroupChart} from "../../../Market/Seller/Product/components/OwnedProductPriceSetGroupChart"
import {ForwardPriceInputFormMulti} from "../../../Market/Seller/Product/ManageProductPrices/ForwardPriceInputFormMulti"
import {SpotPriceInputFormMulti} from "../../../Market/Seller/Product/ManageProductPrices/SpotPriceInputFormMulti"
import {ProductSaleSettingHeadlineView} from "../../../Market/Seller/Product/ProductSettings/ProductSaleSettingHeadlineView"
import ServerContext from "../../../Server/ServerContext"
import {OrganizationContext} from '../../OrganizationContext'
import ListHeader from "../../../AppLocals/Templates/items/ListHeader";
import {renderElse, renderIf, renderIfElse} from "../../../Shared";
import Count from "../../../../components/Feed/Count";
import {HiPencilSquare} from "react-icons/hi2";
import classNames from 'classnames'


export const ContextGroupMenu = ({ organization, products, group_name, sortBy }) => {
  const t = useTranslate()

  return (
    <DrawMenu
      container={"workspace_product_list"}
      component={
          <Count>
              <BsThreeDots size={14} className="text-color-400"/>
          </Count>
      }>
      {(isToggle, toggle) => (
        <Menu style={{ minWidth: 260 }}>
          {
            !!group_name && group_name !== 'null' && (
              <Menu.Item key="0" className="flex mt-2">
                <Toggle key={"0" + group_name}>
                  {(isToggleViewChart, toggleViewChart) => {
                      return (
                        <div className="w-full">
                          <div onClick={toggleViewChart} className="flex items-center gap-3">
                              <BsBarChartLine />
                              <span>{t('charts')}</span>
                          </div>
                          {isToggleViewChart && (
                              <FullSideModalLayout
                                right
                                onCancel={toggleViewChart}
                                maskClosable={false}
                                width={720}
                                title={
                                  <div className="font-bold text-color-000">
                                    {group_name}
                                  </div>
                                }
                                destroyOnClose
                                visible={isToggleViewChart}
                                footer={null}>
                                <div className="flex flex-col gap-3 p-3">
                                    <div className="font-bold text-xs text-color-400 uppercase">
                                        {t('charts')}
                                    </div>
                                    <div className="p-2 border border-color-50 rounded-lg">
                                        <OwnedProductPriceSetGroupChart
                                            product_owner_type={"organization"}
                                            product_owner_id={organization.id}
                                            product_line={sortBy === "product_line" ? group_name : null}
                                            product_catalog={sortBy === "product_catalog" ? group_name : null}
                                        />
                                    </div>
                                </div>
                              </FullSideModalLayout>
                          )}
                        </div>
                      )
                    }
                  }
                </Toggle>
              </Menu.Item>
            )
          }
          <Menu.Item
            key="1"
            className="flex mt-2">
            <Toggle key={"1" + group_name}>
              {(isTogglePriceInput, togglePriceInput) => (
                  <div className="w-full">
                    <div className="w-full">
                      <div onClick={togglePriceInput} className="flex items-center gap-3">
                          <BsInputCursorText />
                          <span>{t('enter spot price')}</span>
                      </div>
                    </div>
                    {
                      isTogglePriceInput && (
                        <FullSideModalLayout
                          right
                          onCancel={togglePriceInput}
                          maskClosable={false}
                          width={720}
                          title={
                              <div className="font-bold text-color-000">
                                  {group_name}
                              </div>
                          }
                          destroyOnClose
                          visible={isTogglePriceInput}
                          footer={null}>
                          <SpotPriceInputFormMulti
                            {...{
                              organization,
                              products
                            }}
                          />
                        </FullSideModalLayout>
                      )
                    }
                  </div>
                )
              }
            </Toggle>
          </Menu.Item>

          <Menu.Item
            key="2"
            className="flex mt-2">
            <Toggle key={"2" + group_name}>
              {(isTogglePriceInput, togglePriceInput) => (
                  <div className="w-full">
                      <div onClick={togglePriceInput} className="flex items-center gap-3">
                          <BsInputCursorText />
                          <span>{t("enter forward price")}</span>
                      </div>
                      {isTogglePriceInput && (
                          <FullSideModalLayout
                              right
                              onCancel={togglePriceInput}
                              maskClosable={false}
                              width={720}
                              title={
                                  <div className="font-bold text-color-000">
                                      {group_name}
                                  </div>
                              }
                              destroyOnClose
                              visible={isTogglePriceInput}
                              footer={null}>
                              <ForwardPriceInputFormMulti
                                  {...{
                                      organization,
                                      products
                                  }}
                              />
                          </FullSideModalLayout>
                      )}
                  </div>
                )
              }
            </Toggle>
          </Menu.Item>
        </Menu>
      )}
    </DrawMenu>
  )
}

export const ProductSaleSettings = ({ view = "block" }) => {
  const history = useHistory();
  const login = useContext(LoginContext);
  const {
    groups = [],
    organization
  } = useContext(OrganizationContext)
  const isAdmin = !!_.get(organization, 'edit');
  const {
    item,
    handleActionClick,
    contextMenu
  } = useContext(ServerContext)
  const oId = organizationModel.getId(
    organization
  )
  const editable = !!_.get(organization, 'edit');
  useAsyncWithCache({
    cacheId: `/products/get-price-periods?day=1`,
    apiInfo: {
      root: API_ROOT_URL,
      path: `/products/get-price-periods?day=1`,
      method: 'GET'
    }
  })
  const t = useTranslate()
  const [filter, setFilter] = useState();
  const [sortBy, setSortBy] = useState("product_catalog");

  const flattenProducts = _.flatMap(groups, (e) => e.products).filter(e => !!e && !!e.active && e.status === 1);
  const groupByProducts = _.groupBy(flattenProducts, function (item) {
    return item[sortBy];
  });

  return (
    <section id={"workspace_product_list"} className="space-y-3 right col-span-3">
      <ListHeader
          title="products"
          extra={renderIf(
              !!_.get(organization, 'edit'),
              <div className="flex items-center gap-3">
                {(_.get(organization, 'privileges.price_export', 0) > 0) && (
                    <Button
                        className="button-rounded-lg no-border"
                        onClick={() => handleActionClick('exportPriceData')}>
                      {t('export sales data')}
                    </Button>
                )}
                <Button
                    type="primary"
                    className="button-rounded-lg no-border"
                    onClick={() => handleActionClick('create-product')}>
                  {t('create product')}
                </Button>
              </div>
          )}
      />

      {renderIf(
          !!editable,
          <div className="flex gap-2 items-center">
            <div className="text-sm text-color-400 italic">
              {`${t('group by')} : `}
            </div>
            <Select
                style={{ width: 200 }}
                className="rounded-md"
                defaultValue={sortBy}
                onChange={setSortBy}
                suffixIcon={<BsChevronDown />}>
              <Select.Option value="product_line">{t('product line')}</Select.Option>
              <Select.Option value="product_catalog">{t('product catalog')}</Select.Option>
            </Select>
          </div>
      )}

      {renderIf(
          _.isEmpty(groupByProducts),
          <EmptyHolder
            title={null}
            subTitle={t('no products, please add products to your page')}>
            <div className="p-6">
              <Button
                className="button-rounded-lg no-border"
                type="primary"
                onClick={() => handleActionClick('create-product')}>
                {t('create product')}
              </Button>
            </div>
          </EmptyHolder>
      )}

      <div className="flex flex-col gap-3">
        {Object.keys(groupByProducts).sort().map((o, i) => {
          const currentGroup = _.first(groups.filter(e => e.title === o));
          return (
              <Toggle key={i}>
                {(isToggleCollapse, toggleCollapse) => (
                      <div
                          key={i}
                          className={classNames(
                              "p-2 flex flex-col gap-2 rounded-lg border relative",
                              isToggleCollapse ? "border-color-50 shadow-items" : "border-transparent hover:border-color-50 hover:shadow-items"
                          )}>
                          <div className="flex items-center gap-2">
                              <div className={classNames("flex flex-1 items-center gap-2", isToggleCollapse && 'mb-2')}>
                                  <div
                                      style={{ width: 26, height: 26 }}
                                      className="flex flex-center bg-primary-50 text-primary-600 hover:bg-primary-600 hover:text-white rounded-md cursor-pointer"
                                      onClick={toggleCollapse}>
                                      {renderIfElse(
                                          isToggleCollapse,
                                          <BsCaretDownFill size={12} />,
                                          <BsCaretRightFill size={12} />
                                      )}
                                  </div>
                                  <Tooltip title={t('click to expanded')} overlayClassName="font-medium text-xs italic">
                                      <div onClick={toggleCollapse} className="font-bold text-color-000 hover:text-primary-600 cursor-pointer">
                                          {!!o && o !== 'null' ? o : t('others')}
                                      </div>
                                  </Tooltip>
                                  {isAdmin && sortBy === "product_catalog" && (
                                      <PopupConfirm
                                          query={{ ':id': organization.id }}
                                          title={t('edit products catalog title')}
                                          initialValues={{
                                              product_catalog_id: _.get(currentGroup, 'id'),
                                              product_catalog_title: o
                                          }}
                                          cacheId={`${organization.id}/get-product-catalog-with-products`}
                                          apiInfo={product_renameProductCatalogTitle_Api}
                                          inputDefaultValue={o}
                                          inputType="text"
                                          inputFieldName="product_catalog_title"
                                          labelField="product catalog title"
                                          PlaceHolderComponent={() => (
                                              <Tooltip
                                                  placement="bottom"
                                                  title={t('click to edit')}
                                                  overlayClassName="font-medium text-xs italic">
                                                  <HiPencilSquare className="text-color-400 hover:text-color-100 cursor-pointer"/>
                                              </Tooltip>
                                          )}
                                      />
                                  )}
                              </div>
                              {renderIf(
                                  isAdmin,
                                  <ContextGroupMenu
                                      organization={organization}
                                      group_name={o}
                                      products={_.orderBy(groupByProducts[o], [sortBy === "product_catalog" ? "product_line" : "product_catalog"], ['asc'])}
                                      sortBy={sortBy}
                                  />
                              )}
                          </div>
                          {isToggleCollapse && (
                              <div className="space-y-3">
                                  {isToggleCollapse &&
                                  _.orderBy(groupByProducts[o], [sortBy === "product_catalog" ? "product_line" : "product_catalog"], ['asc']).map(
                                      product => {
                                          const id = productModel.getId(product)
                                          const visible = !filter || (product.title + product.idname).includes(filter)
                                          if (!visible) return null
                                          return (
                                              <SelectEntityItem
                                                  key={id}
                                                  item={id}
                                                  schema={productSchema}>
                                                  {(item = product) => {
                                                      return !!item && <ProductSaleSettingHeadlineView item={item} />
                                                  }}
                                              </SelectEntityItem>
                                          )
                                      }
                                  )}
                              </div>
                          )}
                      </div>
                  )
                }
              </Toggle>
          )
        })}
      </div>
    </section>
  )
}
