import { message } from 'antd'
import copyToClipboard from 'copy-to-clipboard'
export const createFacebookShareLink = url =>
  `https://www.facebook.com/sharer/sharer.php?u=${url}`
export const createTwitterShareLink = url =>
  `https://twitter.com/intent/tweet?url=${url}`
export const copyLink = url => {
  if (copyToClipboard(url)) {
    message.success('copied')
  } else {
    message.error(
      'can not copy that link'
    )
  }
}
export const callNativeShare = ({
  title,
  url,
  description
}) => {
  if (navigator.share) {
    navigator
      .share({
        title: title || 'feedin',
        description,
        url: url
      })
      .then(() =>
        console.log('Successful share')
      )
      .catch(error =>
        console.log(
          'Error sharing',
          error
        )
      )
  } else {
    alert(
      `your device don't support this!`
    )
  }
}
