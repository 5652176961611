import Icon from '@ant-design/icons'
import {post_createRepost_Api} from 'apis'
import DefaultLayout from 'components/layouts/Default'
import ContentLayout from 'components/layouts/Default/ContentLayout'
import Modal from 'components/Modal/Modal'
import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import Null from 'components/NullComponent'
import logFunc from 'helpers/logFunc'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useContext, useEffect} from 'react'
import {MdClose} from 'react-icons/md'
import Loadable from 'react-loadable'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {useLocation} from 'react-use'
import {fromRenderProps, mapProps, nest, pure, withProps} from 'recompose'
import {compose} from 'redux'
import {getLogin} from 'redux/selectors'
import Account from 'views/Account'
import AccountSettings from 'views/Account/AccountSettings'
import CreateNewArticle from 'views/Articles/CreateNewArticle.lazy'
import {ForgetPassword, Login, Logout, ResetPassword} from 'views/Auth'
import {AddPaymentFailure, AddPaymentSuccess, AddPaymentWithPlanSuccess, Billing} from 'views/Billing'
import {Bookmark} from 'views/Bookmark'
import Category from 'views/Category'
import {Channel} from 'views/Channel'
import ChannelSetting from 'views/ChannelSetting'
import {ChartLazy} from 'views/Chart'
import CreatePoll from 'views/CreatePoll'
import CreateProvider from 'views/CreateProvider'
import {DataLoadable} from 'views/Data'
import DatasetSetting from 'views/DatasetSetting'
import DemoPage from 'views/DemoPage'
import DiscoveryExplorer from 'views/DiscoveryExplorer/DiscoveryExplorer'
import DocumentEditor from 'views/DocumentEditor'
import {Event} from 'views/Event'
import Feed from 'views/Feed'
import GetQuotes from 'views/GetQuotes'
import Invite from 'views/Invite'
import InviteDataset from 'views/InviteDataset'
import InviteOrganization from 'views/InviteOrganization'
import LandingPage from 'views/LandingPage'
import LoadingPage from 'views/LoadingPage'
import MainPage from 'views/MainPage'
import ProductsSearch from 'views/Market/ProductsSearch.lazy'
import {marketRoutes} from 'views/Market/routes'
import Menu from 'views/Menu'
import Messages from 'views/Messages/Messages.lazy'
import NewTemplate from 'views/NewTemplate'
import NoFoundPage from 'views/NoFoundPage'
import Notification from 'views/Notification'
import {Organization} from 'views/Organization'
import {HeaderButton} from 'views/Organization/components/PageHeader'
import Settings from 'views/Organization/Settings'
import QuickLink from 'views/QuickLink'
import QuickLinkSetting from 'views/QuickLinkSetting'
import SelectCategory from 'views/SelectCategory'
import TestApi from 'views/TestApi/TestApi'
import TestFunc from 'views/TestApi/TestFunc'
import User from 'views/User'
import ValidateAccount from 'views/ValidateAccount'
import withTranslate from '../modules/local/withTranslate'
import ContactProvider from '../views/ContactProvider'
import {EventSetting} from '../views/Event'
import {CreateEventFormModal} from '../views/Event/Events'
import {CreateChannelFormModal} from '../views/Organization/components/Channels'
import RedirectPage from '../views/RedirectPage'
import TestScreen from '../views/Test/Screen/TestScreen.lazy'
import {createRequiredLogin, ModalForgetPassword, ModalLogin, ModalSignUp,} from './createRequiredLogin'
import {createRequiredNonLogin} from './createRequiredNonLogin'
import {REQUIRED_LOGIN, REQUIRED_NON_LOGIN} from './routesValidate'
import {staticPaths} from './staticPaths'
import {NotificationActionTypes, successNotify} from "../views/AppLocals/factory/createNotification";
import {notifyOnError} from "../views/AppLocals/factory/createErrorEvent";
import CRUD from "../views/Shared/enums/CRUD";
import {gConfigs} from "../views/AppLocals/configs";
import Report from "../views/Organization/Report/Report";

const Topic = Loadable({
  loader: () =>
    import('../views/Topic/Topic'),
  loading: ({isLoading}) =>
    isLoading && <LoadingPage/>
})
const Search = Loadable({
  loader: () =>
    import('../views/Search/Search'),
  loading: ({isLoading}) =>
    isLoading && <LoadingPage/>
})
export const RequiredLogin = Loadable({
  loader: () => import('../views/403'),
  loading: ({isLoading}) =>
    isLoading && <LoadingPage/>
})

const Navigation = Loadable({
  loader: () =>
    import(
      '../views/Navigation/Navigation'
      ),
  loading: ({isLoading}) =>
    isLoading && <LoadingPage/>
})

const PageDetail = Loadable({
  loader: () =>
    import('../views/User/PageDetail'),
  loading: ({isLoading}) =>
    isLoading && <LoadingPage/>
})
const FeedSetting = Loadable({
  loader: () =>
    import('../views/Feed/FeedSetting'),
  loading: Null
})
const ChartSetting = Loadable({
  loader: () =>
    import(
      '../views/DatasetSetting/ChartSetting/ChartSetting'
      ),
  loading: Null
})

const EnchanedQuickLink = () => {
  const login = useSelector(getLogin)
  const {handleGoBack} = useContext(NavigationContext)
  const {
    success,
    result,
    isLoading,
    response,
    handleAsyncAction: handleAsyncPost,
  } = useAsyncAction({
    apiInfo: post_createRepost_Api,
    query: {
      ':prop': login._type,
      ':id': login.id
    },
    onSuccess: (result) => {
      successNotify(NotificationActionTypes.CREATE, t)
    },
    onError: notifyOnError(t),
  })
  const history = useHistory()
  useEffect(() => {
    if (success) {
      history.replace({
        pathname: `/news/post/${_.get(response, 'data.data.content.id')}`,
        // state: { isModal: true },
      })
    }
  }, [history, response, result, success])
  const t = useTranslate()
  return (
    <FullSideModalLayout
      title={t('quick link')}
      center
      onCancel={handleGoBack}>
      <QuickLink
        isLoading={isLoading}
        onSubmit={handleAsyncPost}
      />
    </FullSideModalLayout>
  )
}
const EnchanedQuickLinkSetting = () => {
  const login = useSelector(getLogin)
  const {handleGoBack} = useContext(NavigationContext)
  const {
    success,
    result,
    isLoading,
    response,
    handleAsyncAction: handleAsyncPost,
  } = useAsyncAction({
    apiInfo: post_createRepost_Api,
    query: {
      ':prop': login._type,
      ':id': login.id,
    },
    onSuccess: (result) => {
      successNotify(NotificationActionTypes.INFORMATION, t)
    },
    onError: notifyOnError(t),
  })
  const history = useHistory()
  useEffect(() => {
    if (success) {
      history.replace({
        pathname: `/news/post/${_.get(response, 'data.data.content.id')}`,
        // state: { isModal: true },
      })
    }
  }, [history, response, result, success])
  const t = useTranslate()
  return (
    <FullSideModalLayout
      title={t('quick link setting')}
      center
      onCancel={handleGoBack}>
      <QuickLinkSetting
        isLoading={isLoading}
        onSubmit={handleAsyncPost}
      />
    </FullSideModalLayout>
  )
}

export const routesConfig = [
  {
    path: '/deposit/:orderId/success',
    name: 'AddPaymentSuccess',
    component: AddPaymentSuccess,
  },
  {
    path: '/class-subscription-confirm/:planId/success',
    breadcrumbName: 'payment subscription',
    component: AddPaymentWithPlanSuccess,
  },
  {
    path: '/deposit/:orderId/failed',
    name: 'activate',
    component: AddPaymentFailure,
  },
  {
    path: '/register/:username/activate/:activation_reset_code',
    name: 'activate',
    component: ValidateAccount,
  },
  {
    path: '/account/billing',
    name: 'billing',
    component: createRequiredLogin()(DefaultLayout),
    routes: [
      {
        path: '/account/billing',
        name: 'billing',
        component: Billing,
        exact: true,
      },
      {
        path: '/account/billing/:sub',
        name: 'billing',
        component: Billing,
      },
    ],
  },
  {
    key: 'reset-pwd',
    name: 'reset password',
    path: '/reset-pwd',
    exact: true,
    component: ResetPassword,
  },
  {
    path: '/invite/:id',
    name: 'invite',
    component: Invite,
    exact: true,
  },
  {
    path: '/invite/organization/:id',
    name: 'invite',
    component: InviteOrganization,
    exact: true,
  },
  {
    path: '/invite/dataset/:id',
    name: 'invite',
    component: InviteDataset,
    exact: true,
  },
  {
    key: 'select_category',
    name: 'select-category',
    path: '/select-category',
    exact: true,
    component: () => {
      return (
        <Translate>
          {(t) => (
            <NavigationContext.Consumer>
              {({handleGoBack}) => {
                return (
                  <FullSideModalLayout
                    title={t('categories')}
                    // center
                    onCancel={handleGoBack}>
                    <SelectCategory onSuccess={handleGoBack}/>
                  </FullSideModalLayout>
                )
              }}
            </NavigationContext.Consumer>
          )}
        </Translate>
      )
    },
    meta: {
      validate: [REQUIRED_LOGIN],
    },
  },
  {
    path: '/messages',
    name: 'messages',
    exact: true,
    component: createRequiredLogin(ModalLogin)(nest(DefaultLayout, Messages)),
  },
  {
    path: '/',
    name: 'home',
    component: DefaultLayout,
    withSubDomain: true,
    routes: [
      ...marketRoutes,
      // {
      //   path: staticPaths.myActivities.pathname,
      //   name: 'my-activities',
      //   component: createRequiredLogin()(
      //     nest(
      //       withProps(() => ({
      //         renderHeader: () => <Header/>,
      //         renderMenu: Null,
      //         renderRight: Null,
      //       }))(ContentLayout),
      //       MyActivities
      //     )
      //   ),
      //   exact: true,
      // },
      // {
      //   path: '/my-bookmarks',
      //   name: 'my-bookmarks',
      //   component: createRequiredLogin()(
      //     nest(
      //       withProps(() => ({
      //         renderHeader: () => <Header/>,
      //         renderMenu: Null,
      //         renderRight: Null,
      //       }))(ContentLayout),
      //       MyBookmarks
      //     )
      //   ),
      //   exact: true,
      // },
      {
        path:
          '/mart/get-quotes/product/:id',
        name: 'getQuotes',
        component: GetQuotes
      },
      {
        path:
          '/mart/contact-provider/:id',
        name: 'contact-provider',
        component: ContactProvider
      },
      {
        key: 'chart',
        name: 'chart',
        path: '/chart/:id',
        withSubDomain: true,
        exact: true,
        component: ChartLazy
      },
      {
        key: 'topics',
        name: 'topics',
        path: '/t/:id',
        exact: true,
        component: Topic
      },
      {
        key: 'welcome',
        name: 'welcome',
        path: '/welcome',
        exact: true,
        component: createRequiredNonLogin(
          () => (
            <Redirect
              to={staticPaths.home}
            />
          )
        )(LandingPage)
      },
      {
        key: 'demo',
        name: 'demo',
        path: '/demo',
        exact: true,
        component: DemoPage
      },
      {
        name: 'document',
        path: '/document/:id',
        component: DocumentEditor
      },
      {
        name: 'document',
        path: '/document',
        component: DocumentEditor
      },
      {
        path: '/account',
        name: 'account',
        component: createRequiredLogin()(
          Account
        )
      },
      // {
      //   path: '/user/:id',
      //   name: 'user',
      //   component: User,
      //   exact: true
      // },
      {
        path: '/menu',
        name: 'menu',
        withSubDomain: true,
        component: createRequiredLogin(
          withProps({
            to: '/login',
            state: {isModal: true}
          })(Redirect)
        )(Menu),
        exact: true
      },
      {
        path: '/forgotpassword',
        name: 'forgot password',
        component: ForgetPassword,
        exact: true,
        meta: {
          validate: [REQUIRED_NON_LOGIN]
        }
      },
      {
        path: '/user/account',
        name: 'account center',
        component: PageDetail,
        exact: true,
        meta: {
          validate: [REQUIRED_LOGIN]
        }
      },

      {
        path: '/discovery',
        name: 'discovery',
        exact: true,
        component: DiscoveryExplorer
      },
      // {
      //   path: '/organization/:id',
      //   name: 'provider',
      //   component: Organization
      // },
      {
        key: 'category',
        name: 'category',
        path: '/category',
        exact: true,
        component: Category
      },
      {
        path: '/login',
        name: 'login',
        component: Login,
        exact: true,
        withSubDomain: true,
        meta: {
          validate: [REQUIRED_NON_LOGIN]
        }
      },
      {
        path: '/bookmark',
        name: 'bookmark',
        component: Bookmark,
        exact: true,
        meta: {
          validate: [REQUIRED_LOGIN]
        }
      },
      {
        path: '/bookmark/:id',
        name: 'bookmark',
        component: Bookmark,
        exact: true,
        meta: {
          validate: [REQUIRED_LOGIN]
        }
      },
      {
        key: 'menu',
        name: 'menu',
        path: '/navigationMenu',
        exact: true,
        withSubDomain: true,
        component: Navigation
      },
      {
        path: '/data/:id/settings',
        name: 'data setting',
        withSubDomain: true,
        component: createRequiredLogin(
          ModalLogin
        )(
          compose(
            fromRenderProps(
              NavigationContext.Consumer,
              ({handleGoBack}) => ({
                onCancel: logFunc(
                  handleGoBack
                )
              })
            ),
            withProps(props => {
              return {
                id: _.get(
                  props,
                  'match.params.id'
                )
              }
            })
          )(DatasetSetting)
        )
      },
      {
        key: 'data',
        name: 'data',
        path: '/data/:id',
        withSubDomain: true,
        component: DataLoadable
      },
      {
        key: 'loading',
        name: 'loading',
        path: '/loading',
        exact: true,
        component: LoadingPage
      },
      {
        path: '/channel/:id',
        name: 'channel',
        component: Channel,
        exact: true,
        withSubDomain: true
      },
      {
        path:
          '/channel/:channel_id/new-article',
        name: 'new-article',
        component: createRequiredLogin()((props) => (
          <CreateNewArticle
            {...props}
            action={CRUD.CREATE}
          />
        )),
        exact: true
      },
      {
        path: '/article/:id/edit',
        key: 'edit-article',
        component: createRequiredLogin()(CreateNewArticle),
        exact: true,
      },
      {
        path: '/testTemplate',
        name: 'test-template',
        component: createRequiredLogin(
          ModalLogin
        )(NewTemplate),
        exact: true
      },
      // {
      //   path: '/event/:id',
      //   name: 'event',
      //   component: Event,
      //   exact: true
      // },
      // {
      //   path: '/event/:id/settings',
      //   name: 'event setting',
      //   component: createRequiredLogin(
      //     ModalLogin
      //   )(
      //     compose(
      //       fromRenderProps(
      //         NavigationContext.Consumer,
      //         ({handleGoBack}) => ({
      //           onCancel: handleGoBack
      //         })
      //       ),
      //       withProps(props => {
      //         return {
      //           id: _.get(
      //             props,
      //             'match.params.id'
      //           )
      //         }
      //       })
      //     )(EventSetting)
      //   ),
      //   exact: true
      // },
      {
        path: '/notifications',
        name: 'notifications',
        component: createRequiredLogin(
          () => (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  isModal: true
                }
              }}
            />
          )
        )(Notification),
        exact: true
      },
      {
        path: '/testApi',
        name: 'test api',
        component: TestApi,
        exact: false
      },
      {
        path: '/testFunc',
        name: 'test api',
        component: TestFunc,
        exact: false
      },
      {
        path: '/testApi/:id',
        name: 'test api',
        component: TestApi,
        exact: false
      },

      {
        path: '/DatasetManager',
        key: 'DatasetManager',
        name: 'pageNotFound',
        component: Loadable({
          loader: () =>
            import(
              '../views/DatasetManager'
              ),
          loading: ({isLoading}) =>
            isLoading && <LoadingPage/>
        })
      },
      {
        path:
          '/channel/:channel_id/article/:id/edit',
        key: 'channel-edit-article',
        component: createRequiredLogin(
          ModalLogin
        )(CreateNewArticle),
        exact: true
      },
      {
        path: '/news/article/:id',
        name: 'news',
        component: Loadable({
          loader: () =>
            import('../views/Article'),
          loading: LoadingPage
        }),
        withSubDomain: true
      },
      {
        path: '/news/:type/:id',
        name: 'post',
        component: Feed,
        exact: true,
        withSubDomain: true
      },

      // {
      //   path: '/messages',
      //   name: 'messages',
      //   exact: true,
      //   component: createRequiredLogin(
      //     ModalLogin
      //   )(Messages)
      // },

      // {
      //   path: '/article',
      //   name: 'news',
      //   component: Loadable({
      //     loader: () =>
      //       import('../views/Article'),
      //     loading: Null
      //   })
      // },
      {
        path:
          '/success-sign-up/redirect',
        key: 'success-sign-up-redirect',
        name: 'pageRedirect',
        component: nest(
          withProps(() => ({
            renderHeader: Null,
            renderMenu: Null,
            renderRight: Null
          }))(ContentLayout),
          compose(
            withTranslate,
            mapProps(
              ({
                 translate: t,
                 ...props
               }) => {
                return {
                  title: t(
                    'Successfully Sign Up'
                  ),
                  description: t(
                    'Thank you for registering, Website will redirect to login page'
                  ),
                  redirectPath:
                    '/login',
                  redirectLabel:
                    'login',
                  ...props
                }
              }
            ),
            pure
          )(RedirectPage)
        )
      },
      {
        path: '/404',
        key: '404',
        name: 'pageNotFound',
        component: nest(
          withProps(() => ({
            renderHeader: Null,
            renderMenu: Null,
            renderRight: Null
          }))(ContentLayout),
          NoFoundPage
        )
      },

      {
        ...MainPage
      },

      {
        // must be at bottom
        path: '/',
        name: 'NoFoundPage',
        component: nest(
          withProps(() => ({
            renderHeader: Null,
            renderMenu: Null,
            renderRight: Null
          }))(ContentLayout),
          NoFoundPage
        )
      }
    ]
  }
]
const PageModal = ({
                     children,
                     ...props
                   }) => (
  <NavigationContext.Consumer>
    {({handleGoBack}) => (
      <Modal
        visible="true"
        footer={null}
        header={
          <HeaderButton
            style={{
              position: 'absolute',
              top: 0,
              right: 0
            }}
            onClick={handleGoBack}>
            <Icon
              style={{
                fontWeight: 700,
                fontSize: '1.2em'
              }}
              component={MdClose}
            />
          </HeaderButton>
        }
        onCancel={handleGoBack}
        {...props}>
        <div className="mx-auto flex flex-1 flex-col">
          {children}
        </div>
      </Modal>
    )}
  </NavigationContext.Consumer>
)

export const modalRoutesConfig = [
  {
    path: '/login',
    name: 'login',
    withSubDomain: true,
    component: createRequiredNonLogin(
      () => {
        const history = useHistory()
        const location = useLocation()
        const redirectUrl = _.get(
          location,
          'state.state.from'
        )
        if (!!redirectUrl) {
          history.push(redirectUrl)
        }
        const {
          handleGoBack
        } = useContext(
          NavigationContext
        )
        useEffect(() => {
          if (!redirectUrl) {
            handleGoBack()
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [handleGoBack])

        return null
      }
    )(ModalLogin),
    exact: true
  },
  {
    path: '/register',
    name: 'register',
    component: createRequiredNonLogin(
      () => {
        const {
          handleGoBack
        } = useContext(
          NavigationContext
        )
        useEffect(() => {
          handleGoBack()
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [handleGoBack])
        return null
      }
    )(ModalSignUp),
    exact: true
  },
  {
    key: 'logout',
    name: 'logout',
    path: '/logout',
    withSubDomain: true,
    exact: true,
    component: nest(PageModal, Logout)
  },
  {
    path: '/forgotpassword',
    name: 'forgotpass',
    component: createRequiredNonLogin(
      () => {
        const {
          handleGoBack
        } = useContext(
          NavigationContext
        )
        useEffect(() => {
          handleGoBack()
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [handleGoBack])
        return null
      }
    )(ModalForgetPassword),
    exact: true
  },
  {
    path: '/account/settings',
    name: 'account settings',
    component: createRequiredLogin(
      ModalLogin
    )(
      compose(
        fromRenderProps(
          NavigationContext.Consumer,
          ({handleGoBack}) => ({
            onCancel: handleGoBack
          })
        ),
        withTranslate,
        withProps(({translate, ...props}) => ({
          ...props,
          maskClosable: false,
          title: translate('account settings'),
        }))
      )(
        nest(
          FullSideModalLayout,
          AccountSettings
        )
      )
    )
  },
  {
    path: '/channel/:id/settings',
    name: 'channel setting',
    withSubDomain: true,
    component: createRequiredLogin(
      ModalLogin
    )(
      compose(
        fromRenderProps(
          NavigationContext.Consumer,
          ({handleGoBack}) => ({
            onCancel: handleGoBack
          })
        ),
        withProps(props => {
          return {
            id: _.get(
              props,
              'match.params.id'
            )
          }
        })
      )(ChannelSetting)
    )
    // exact: true
  },
  {
    path: '/organization/:id/settings',
    name: 'provider setting',
    component: createRequiredLogin(
      ModalLogin
    )(
      compose(
        fromRenderProps(
          NavigationContext.Consumer,
          ({handleGoBack}) => ({
            onCancel: () =>
              handleGoBack()
          })
        ),
        withProps(props => {
          return {
            id: _.get(
              props,
              'match.params.id'
            )
          }
        })
      )(Settings)
    )
    // exact: true
  },
  {
    path: '/organization/:id/price-quotes',
    name: 'price quotes',
    component: createRequiredLogin(
      ModalLogin
    )(
      compose(
        fromRenderProps(
          NavigationContext.Consumer,
          ({handleGoBack}) => ({
            onCancel: () =>
              handleGoBack()
          })
        ),
        withProps(props => {
          const searchParam = new URLSearchParams(props?.location?.search)
          const reportId = searchParam.get('reportId')
          return {
            reportId: reportId
          }
        })
      )(Report)
    )
    // exact: true
  },
  {
    path: '/create-channel',
    name: 'create channel',
    component: createRequiredLogin(
      ModalLogin
    )(CreateChannelFormModal)
  },
  {
    path: '/create-event',
    name: 'create event',
    component: createRequiredLogin(
      ModalLogin
    )(CreateEventFormModal)
  },

  {
    path: '/create-provider',
    name: 'Create provider profile',
    component: createRequiredLogin(
      ModalLogin
    )(nest(PageModal, CreateProvider)),
    exact: true
  },
  ...gConfigs.gModalRoutes,
  {
    path: '/createPoll',
    name: 'Create Poll',
    component: createRequiredLogin(
      ModalLogin
    )(nest(PageModal, CreatePoll)),
    exact: true
  },
  // {
  //   path: '/createArticle',
  //   name: 'Write a article',
  //   component: createRequiredLogin(
  //     ModalLogin
  //   )(EnchanedCreateArticle),
  //   exact: true
  // },
  {
    path: '/quickLink',
    name: 'quick link',
    component: createRequiredLogin(
      ModalLogin
    )(EnchanedQuickLink),
    exact: true
  },
  {
    path: '/quickLink/:id/settings',
    name: 'setting quick link',
    component: createRequiredLogin(
      ModalLogin
    )(EnchanedQuickLinkSetting),
    exact: true
  },
  {
    path: '/navigationMenu',
    name: 'menu',
    component: nest(
      PageModal,
      Navigation
    ),
    exact: true
  },
  // {
  //   path: '/news/article/:id/edit',
  //   name: 'post',
  //   component: EditArticle,
  //   exact: true,
  //   withSubDomain: true
  // },
  {
    path: '/news/:type/:id/edit',
    name: 'post',
    component: createRequiredLogin()(
      FeedSetting
    ),
    exact: true,
    withSubDomain: true
  },
  {
    path: '/chart/:id/edit',
    name: 'chart',
    withSubDomain: true,
    component: createRequiredLogin()(
      ChartSetting
    ),
    exact: true
  },

  {
    path: '/search',
    name: 'search',
    component: compose(
      fromRenderProps(
        NavigationContext.Consumer,
        ({handleGoBack}) => ({
          onCancel: handleGoBack
        })
      ),
      withProps(props => ({}))
    )(Search),
    exact: true
  },
  {
    path: '/mart/search',
    name: 'search products',
    component: compose(
      fromRenderProps(
        NavigationContext.Consumer,
        ({handleGoBack}) => ({
          onCancel: handleGoBack
        })
      )
    )(ProductsSearch),
    exact: true
  },
  {
    path: '/test-screen',
    name: 'test screen',
    component: compose(
      fromRenderProps(
        NavigationContext.Consumer,
        ({handleGoBack}) => ({
          onCancel: handleGoBack
        })
      )
    )(TestScreen),
    exact: true
  }
]
