import {Alert, Button, DatePicker, Form, Select} from 'antd'
import moment from 'moment'
import React, {useState} from 'react'
import useTranslate from "../../../../../../modules/local/useTranslate";
import {ProductPriceSetChart} from "../ProductPriceSetChart";
import _ from "lodash";
import {BsCalendar2Week} from "react-icons/bs";

export const PriceSetGroupCopyAndInsertForm = ({
    effectMomentDate,
    product,
    productPriceSet,
    productPriceGroup,
    toggle,
    isToggle,
    update,
    handleOnCopySubmitLocation
}) => {

  const t = useTranslate();
  const [refreshChart, setRefreshChart] = useState(null);
  const [estEndDate, setEstEndDate] = useState(_.cloneDeep(effectMomentDate).add(1, 'days'));

  return (
    <div className="w-full p-3">
      <Form
          initialValues={{
              eff_at: effectMomentDate,
              end_at: estEndDate
          }}
          onFinish={(values) => {
              handleOnCopySubmitLocation(
                  {
                      product_price_set_id: productPriceGroup.product_price_set_id,
                      eff_at: values.eff_at,
                      end_at: estEndDate.format("YYYY-MM-DD") === "2099-12-31" ? null : estEndDate
                  }
              )
              toggle()
          }}
          {...{
              labelCol: {span: 10},
              wrapperCol: {span: 14},
          }}>
        {(values) => (
            <React.Fragment>
                <div className="space-x-2 text-sm italic">
                    <span className="font-semibold text-color-100">
                        {`${t('note')} : `}
                    </span>
                    <span className="text-color-400">
                        {t('Generate new updated price data effective as of the date below. Data will be temporarily recorded according to the price of the most recent day.')}
                    </span>
                </div>
                <Form.Item
                    label={
                        <span className="text-sm text-color-400 italic">
                            {t('date')}
                        </span>
                    }
                    className="flex-1"
                    name="eff_at">
                    <DatePicker
                        className="rounded-md"
                        suffixIcon={<BsCalendar2Week className="text-color-400"/>}
                        disabledDate={(current) => {
                            return current && current < moment(productPriceGroup.eff_at).add(1, 'days')
                        }}
                        format={['DD/MM/YYYY']}
                    />
                </Form.Item>

                <Form.Item
                    label={
                        <span className="text-sm text-color-400 italic">
                            {t('This pricing information will expire in')}
                        </span>
                    }
                    className="flex-1"
                    name="period_type">
                    <Select
                        className="flex-1"
                        placeholder={t('period')}
                        defaultValue={"1D"}
                        options={
                            [
                                {value: '0U', label: t("no expired date")},
                                {value: '1D', label: '1 ' + t("days")},
                                {value: '7D', label: '7 ' + t("days")},
                                {value: '1M', label: '1 ' + t("months")},
                                {value: '1Q', label: '1 ' + t("quarters")},
                                {value: '1Y', label: '1 ' + t("years")}
                            ]
                        }
                        onChange={value => {
                            const effectMomentEndDate = _.cloneDeep(effectMomentDate);
                            switch (value) {
                                case "0U":
                                    setEstEndDate(moment("31/12/2099", "DD/MM/YYYY"));
                                    break;
                                case "1Y":
                                    setEstEndDate(effectMomentEndDate.add(1, 'years'));
                                    break;
                                case "1Q":
                                    setEstEndDate(effectMomentEndDate.add(3, 'months'));
                                    break;
                                case "1M":
                                    setEstEndDate(effectMomentEndDate.add(1, 'months'));
                                    break;
                                case "7D":
                                    setEstEndDate(effectMomentEndDate.add(7, 'days'));
                                    break;
                                case "1D":
                                default:
                                    setEstEndDate(effectMomentEndDate.add(1, 'days'));
                            }
                        }}>
                    </Select>
                </Form.Item>

                <Form.Item
                    label={
                        <span className="text-sm text-color-400 italic">
                            {t('This pricing information will expire at')}
                        </span>
                    }
                    className="flex-1"
                    name="period_type">
                    <div className="font-bold text-color-000">
                        {estEndDate.format("DD/MM/YYYY")}
                    </div>
                </Form.Item>

                <div className="flex justify-center gap-3 mt-4">
                    <Button
                        onClick={() => setRefreshChart(Date.now())}
                        className="button-rounded-md no-border">
                        {t('refresh chart')}
                    </Button>

                    <Button
                        type="primary"
                        className="button-rounded-md no-border px-6"
                        htmlType="submit">
                        {t('save')}
                    </Button>
                </div>

                {!!refreshChart && (
                    <div id={refreshChart} className="flex flex-col gap-3 p-3 border border-color-50 shadow-items-md rounded-lg mt-3">
                        <div className="font-bold text-xs text-color-400 uppercase">
                            {t('charts')}
                        </div>
                        <ProductPriceSetChart
                            product={product}
                            product_id={product.id}
                            {...productPriceSet}
                            location_type={_.get(productPriceGroup, 'location._type')}
                            location_id={_.get(productPriceGroup, 'location.id')}
                            unit_id={_.get(productPriceSet, 'unit.id')}
                            price_unit_id={_.get(productPriceSet, 'price_unit.id')}
                            unit2_id={_.get(productPriceSet, 'unit2.id')}
                            price_unit2_id={_.get(productPriceSet, 'price_unit2.id')}
                            eventId={refreshChart}
                            additionalData={[values.eff_at.utc().format(), _.get(productPriceGroup, 'data.0.price')]}
                            additionalData2={[values.eff_at.utc().format(), _.get(productPriceGroup, 'data.0.price2')]}
                        />
                    </div>
                )}
            </React.Fragment>
        )}
      </Form>
    </div>
  )
}
