import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { PageContext } from '../PageContext'

const PageLink = ({ item, to, ...props }) => {
  const { pageRoot } = useContext(PageContext)
  return (
    <Link
      to={
        to && to.pathname ? {
          ...to,
          pathname: pageRoot + to.pathname,
        } : pageRoot + to

      } {...props}>
    </Link>
  )
}

export default PageLink
