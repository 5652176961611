import {
  nest,
  withProps,
} from 'recompose'
import { DelayRender } from 'views/Discovery/DelayRender'
import WorkspaceOperatingContent from './WorkspaceOperatingContent'

export default nest(
  withProps({ lazy: true })(
    DelayRender
  ),
  WorkspaceOperatingContent
)
