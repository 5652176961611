import classNames from 'classnames'
import {Field} from 'formik'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo} from 'react'
import {renderElse, renderOwnChild} from 'views/Shared'
import {defaultInputProps} from './defaultInputProps'
import FieldDecorator, {renderDecoratedLabel} from './FieldDecorator'

const defaultFormSchema = [
  {
    title: 'email',
    children: {
      type: 'email',
      ...defaultInputProps.email,
      name: 'email',
    },
  },
  {
    title: 'fullname',
    children: {
      type: 'fullname',
      ...defaultInputProps.fullname,
      name: 'fullname',
    },
  },
  {
    title: 'personal profile',
    children: {
      type: 'text',
      ...defaultInputProps.textArea,
      placeholder: 'brief introduction to yourself',
      name: 'personal_profile',
    },
  },
  {
    title: 'country',
    children: {
      type: 'text',
      ...defaultInputProps.country,
      name: 'country',
    },
  },
  {
    title: 'address',
    children: [
      {
        type: 'province',
        ...defaultInputProps.fullname,
        name: 'province',
      },
      {
        type: 'district',
        ...defaultInputProps.fullname,
        name: 'district',
      },
    ],
  },
  {
    title: 'phone',
    children: [
      {
        ...defaultInputProps.prefixPhoneNumber,
        name: 'prefixPhoneNumber',
      },
      {
        ...defaultInputProps.phone,
        name: 'phone_number',
      },
    ],
  },
]

const renderDefaultLabel = (label, params = {}) =>
  renderDecoratedLabel(label, {
    ...params,
  })

export const FieldHeader = ({
                              title,
                              style,
                              children,
                              className,
                              extra = [],
                              renderLabel = renderDecoratedLabel,
                              Wrapper = renderOwnChild,
                            }) => (
  <Wrapper>
    <div
      style={style}
      className={classNames('flex items-end gap-2', className)}>
      {renderLabel(title)}
      <div className="flex-1"/>
      {!_.isEmpty(extra) && (
        <div className="flex gap-2 mb-1">
          {_.isArray(extra) &&
          extra.map((item, index) => (
            <React.Fragment key={index}>{item}</React.Fragment>
          ))}
        </div>
      )}
    </div>
    {children}
  </Wrapper>
)

const renderChildren = (
  {
    component: C,
    render,
    className,
    label,
    description,
    errors,
    isRequired,
    withProps = {},
    name,
    isInvisble = (values) => false,
    withErrors = (errors) => ({}),
    hideError = false,
    Wrapper = renderOwnChild,
    renderLabel = renderDefaultLabel,
    ...props
  },
  translate
) => (
  <Field name={name}>
    {({
        field,
        form: {touched = {}, errors = {}, ...rest} = {
          touched: {},
          errors: {},
        },
      }) =>
      renderElse(
        isInvisble(rest.values),
        <FieldDecorator
          className={className}
          hasError={!hideError}
          key={name}
          {...{
            ...(_.isEmpty(label)
              ? {}
              : {
                label: renderLabel(label, {
                  isRequired,
                  getValue: (name) => _.get(rest.values, name),
                }),
              }),
            ...(_.isEmpty(description)
              ? {}
              : {
                description,
              }),
            errors: errors[name],
            isRequired,
          }}>
          <Wrapper>
            {render ? (
              render({
                ...field,
                errors,
                touched,
                form: rest,
              })
            ) : (
              <C
                {...field}
                {...withProps}
                {...withErrors(errors)}
                {...props}
                form={rest}
              />
            )}
          </Wrapper>
        </FieldDecorator>
      )
    }
  </Field>
)
const renderChildrens = (children, translate) => {
  return (
    <div>
      {children.map((child, i) => (
        <div key={i}>{renderChildren(child, translate)}</div>
      ))}
    </div>
  )
}

const renderInlineChildren = (
  children,
  translate,
  className,
  itemClassNames = []
) => {
  const getClassName = (index) => {
    return _.isArray(itemClassNames)
      ? _.get(itemClassNames, `[${index}]`, '')
      : itemClassNames
  }

  return (
    <div className={className}>
      {children.map((child, i) => (
        <div
          className={getClassName(i)}
          key={i}>
          {renderChildren(child, translate)}
        </div>
      ))}
    </div>
  )
}

const RenderWrapper = ({children, ...props}) => (
  <div
    className="w-full"
    {...props}>
    {children}
  </div>
)

const FieldsFactory = ({
  renderTitle,
  Wrapper = RenderWrapper,
  formSchema = defaultFormSchema,
  renderInline = renderInlineChildren,
}) => {
  const translate = useTranslate()

  const renderItemWrapper = ({children}) => children

  const result = useMemo(
    () =>
      formSchema
        .filter((e) => !e.invisible)
        .map(
          (
            {
              title,
              children,
              inline = false,
              className,
              itemClassNames,
              ItemWrapper = renderItemWrapper,
            },
            i
          ) => {
            const render = _.isArray(children)
              ? inline
                ? renderInline
                : renderChildrens
              : renderChildren
            return (
              <Wrapper key={i}>
                {_.isEmpty(title)
                  ? null
                  : renderTitle
                    ? renderTitle(translate(title))
                    : renderDefaultLabel(translate(title))}
                <ItemWrapper t={translate}>
                  {render(children, translate, className, itemClassNames)}
                </ItemWrapper>
              </Wrapper>
            )
          }
        ),
    [formSchema, renderInline, renderTitle, translate]
  )

  return <React.Fragment>{result}</React.Fragment>
}
export default FieldsFactory
