import {ElementTypes} from '../types'

const withMentions = (editor) => {
  const {isInline, isVoid, markableVoid, deleteForward, deleteBackward} =
    editor

  editor.isInline = (element) => {
    return element.type === ElementTypes.MENTION ? true : isInline(element)
  }

  editor.isVoid = (element) => {
    return element.type === ElementTypes.MENTION ? true : isVoid(element)
  }

  editor.markableVoid = (element) => {
    return element.type === ElementTypes.MENTION || markableVoid(element)
  }

  return editor
}

export default withMentions
