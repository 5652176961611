import Icon, {EditOutlined, SettingOutlined} from '@ant-design/icons'
import {Breadcrumb, Button, Carousel, Image, message, Modal, Skeleton} from 'antd'
import {channel_add_Api} from 'apis'
import {channelModel, organizationModel} from 'apis/model'
import {getId, getType} from 'apis/model/base'
import classNames from "classnames"
import ApiInfoForm from 'components/form/ApiInfoForm'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useContext} from 'react'
import {FcStatistics} from "react-icons/fc"
import {Link, Route, Switch, useParams, useRouteMatch} from 'react-router-dom'
import CreateChannelForm from 'views/CreateChannelForm'
import {DelayRender} from 'views/Discovery/DelayRender'
import OrganizationProvider from 'views/Organization/OrganizationProvider'
import ServerContext from 'views/Server/ServerContext'
import {auth_updateOrg_Api, post_getPosts_Api} from "../../apis"
import {getChannelsByProps_Api} from "../../apis/channel"
import {getLinkToDetail} from "../../apis/model/organization"
import productModel from "../../apis/model/product"
import {productSchema} from "../../apis/schema"
import AppIcon from "../../components/AppIcon"
import EmptyHolder from "../../components/EmptyHolder"
import Description from "../../components/Feed/Description"
import {FollowButton} from "../../components/Feed/FollowButton"
import {createTimeLineFeed} from "../../components/Feed/TimeLineFeed"
import ImageLoader from "../../components/ImageLoader"
import {LayoutContext} from "../../components/layouts/Default/LayoutContext"
import {LoginContext} from "../../components/LoginContext"
import NullComponent from "../../components/NullComponent"
import {Pure} from "../../components/Pure"
import EmptyFolderStateSvg from "../../components/Svgs/EmptyFolderStateSvg"
import PhotoSvg from "../../components/Svgs/PhotoSvg"
import {Photo} from "../../conts/imageBase64"
import {ROOT_URL} from "../../envs/_current/config"
import CustomLazyPaginationWrapper from "../../modules/asyncCache/components/CustomLazyPaginationWrapper"
import useAsyncAction from "../../modules/asyncCache/useAsyncAction"
import {ChartContainer, ChartContext} from "../Chart/ChartContext"
import {ChartStateless} from "../Chart/Charts"
import {Copyright} from "../Chart/components/Copyright"
import ProductLink from "../Market/components/ProductLink"
import SpotRequestForm, {SpotRequestTypes} from "../Market/components/SpotBid/SpotRequestForm"
import OrderRequestProvider from "../OrderRequest/OrderRequestProvider"
import {SubDomainContext} from "../SubDomain/SubDomainContext"
import {AboutUs} from './AboutUs/AboutUs'
import {News} from './Channel/News'
import {Contact} from './Contact/Contact'
import {ContactDetail} from "./Contact/ContactDetail"
import SimpleContactForm from "./Contact/SimpleContactForm"
import {Data} from './Dataset/Data'
import {validateServiceType} from "./functions/validateServiceType"
import {Header} from './Header'
import {OrganizationContext} from "./OrganizationContext"
import {OrganizationLayout} from './OrganizationLayout'
import {Product} from './Product/Product'
import {Products} from './Product/Products'
import Report from './Report'
import {getPageIcon} from "./Settings/Profile/LinkedPages"
import {Slider} from './Slider'

const breakpointProductLayoutConfig = {
  xs: {
    productSize: 2
  },
  sm: {
    productSize: 2
  },
  md: {
    productSize: 4
  },
  lg: {
    productSize: 6
  }
}

const ChartWrapper = ({
  children,
  header,
  hideshowmore,
  hideIcon,
  className,
  hideTitle,
  hideactionbar,
  hidedescription,
  isPlaceholder = false,
  ...props
}) => {
  const { item } = useContext(
    ChartContext
  )
  const t = useTranslate()
  return (
    <div
      className={classNames(
        'verticalList flex flex-col w-full mt-5',
        className
      )}
      {...props}>
      <div className="relative">
        {!!!hideTitle && (
          <h2 className="flex p-1">
            <FcStatistics
              className="mr-3 text-2xl w-7 h-7"
            />
            <Link
              to={isPlaceholder ? '' : `/chart/${item.idname}`}
              className="text-lg font-bold leading-tight hover:text-primary">
              {isPlaceholder ? '' : item.title}
            </Link>
          </h2>
        )}
        {!!!Boolean(
          hidedescription
        ) && (
            <Description type="dataset">
              {item.description}
            </Description>
          )}
      </div>
      <div className="flex-1 flex flex-col">
        {header && header}
        <div
          style={
            {
              minHeight: _.get(item, 'chart_type') === 'Table' ? "auto" : 360,
              height: _.get(item, 'chart_type') === 'Table' ? "auto" : 0,
            }
          }
          className="flex flex-col">
          {children}
        </div>
      </div>
      <Copyright />

    </div>
  )
}

const LinkedPagesInfo = ({
  item,
  Wrapper = ({ children }) => children,
  children,
  ...props
}) => {
  const t = useTranslate()
  const { linked_pages = [] } = item

  const ignoredEmptyItems = linked_pages.filter(e => !_.isEmpty(e))

  const renderItem = ({ page, url }, key) => {
    return (
      <div
        {...{ key }}
        className="flex justify-start gap-x-3 items-center">
        {
          getPageIcon(page)
        }
        <a
          target={"_blank"}
          href={url}
          className="italic font-light truncate">
          {t(url || 'others')}
        </a>
      </div>
    )
  }

  return (
    <Wrapper>
      {children(renderItem, item)}
      {ignoredEmptyItems.map(renderItem)}
    </Wrapper>
  )
}

const Feed = createTimeLineFeed({
  Container: NullComponent,
  Wrapper: ({
    className,
    children
  }) => (
    <div>
      {children}
    </div>
  ),
  ContentMeta: NullComponent,
  Footer: NullComponent,
  ContentLayout: ({
    style,
    children
  }) => {
    return (
      <div
        style={style}
        className="verticalList__small border-b pb-3 mb-3">
        {children}
      </div>
    )
  }
})

const renderItem = item => {
  return (
    <Pure
      key={item.id}
      input={[
        item &&
        item.content
      ]}>
      <Feed item={item.content} />
    </Pure>
  )
}

export const OrganizationHome = () => {
  const { item } = useContext(
    ServerContext
  );
  const login = useContext(LoginContext);
  const { organization, ...restOrg } = useContext(
    OrganizationContext
  );
  const history = useHistory();
  const t = useTranslate();

  const { breakpoint } = useContext(LayoutContext);
  const chartIndex = 0; //useMemo(() => Math.floor(Math.random()*(_.get(organization, 'charts.length'))))

  return (
    <div className="w-full space-y-3 md:py-0">

      {/*IMAGES*/}
      {
        _.get(item, 'photos.length') > 0 && (
          <section className="flex flex-col w-full pb-3 overflow-hidden border-0"
            style={{ minHeight: 'min(25Vw, 25Vh)' }}>
            <div className="flex">
              <div className="flex-1 w-0">
                <Carousel dotPosition={"top"} autoplay>
                  {
                    _.get(item, 'photos', []).map(e => !!e && (
                      <div key={e} className="w-full background-100"
                        style={{ height: 'min(30Vw, 25Vh)' }}
                      >
                        <img key={e} className="background-100 object-cover w-full border-0"
                          src={e}
                          alt=""
                        />
                      </div>
                    ))
                  }
                </Carousel>
              </div>
            </div>
          </section>
        )
      }

      {/*DATASET*/}
      {
        !!_.get(organization, 'charts.length') && (
          <section className="w-full body-font relative p-3">
            <div className="flex justify-center items-center font-semibold text-2xl uppercase mb-6 mt-8">
              {
                t('data')
              }
            </div>
            <div className="lg:px-6 items-center justify-center flex flex-col">
              <div className="p-3 rounded hover:shadow w-full h-full border min-h">
                <ChartContainer
                  key={chartIndex}
                  WrapperHodler={ChartWrapper}
                  id={_.get(organization, 'charts.' + chartIndex + '.idname')}>
                  <ChartContext.Consumer>
                    {({
                      item,
                      ...props
                    }) => {
                      return (
                        <ChartStateless
                          hideshowmore={true}
                          key={item.idname}
                          item={item}
                          CWrapper={ChartWrapper}
                        />
                      )
                    }}
                  </ChartContext.Consumer>
                </ChartContainer>
              </div>

              <div className="flex items-center justify-center p-3">
                <Link to={`${getLinkToDetail(organization)}/data`}
                  className="px-8 py-1 border rounded-full hover:border-primary no-underline font-light">
                  <div className="font-light">{t('show more')}</div>
                </Link>
              </div>
            </div>
          </section>
        )
      }

      {/*PRODUCTS*/}
      {
        validateServiceType(_.get(item, 'service_type'), "product") && (
          <section className="w-full body-font relative p-3">
            <div className="flex justify-center items-center font-bold text-2xl text-color-000 uppercase tracking-widest mb-6 mt-8">
              {t('products')}
            </div>
            <div className="mb-6">
              <Slider />
            </div>

            {_.get(restOrg, 'groups', []).map(e => (
                <div key={e.id} className="flex flex-col space-y-1 mb-8">
                  <div className="font-medium uppercase mb-3 border-b border-gray-300 pb-2 tracking-wide">
                    {e.title}
                  </div>
                  <div
                    className="grid items-start content-start grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
                    {
                      _.get(e, 'products', [])
                        .slice(0, _.get(breakpointProductLayoutConfig, breakpoint + '.productSize', 6))
                        .map(p => (
                          <div
                            key={p.id}
                            className="background-white w-full col-span-1 h-full flex flex-col rounded-md hover:shadow-lg border border-gray-200">
                            <div
                              style={{
                                paddingTop:
                                  '100%'
                                // margin: '-1px',
                              }}
                              className="w-full overflow-hidden relative">
                              <ImageLoader
                                className="w-full h-full object-cover absolute left-0 top-0 rounded-tl-md rounded-tr-md border-0"
                                src={p.avatar}
                              />
                            </div>
                            <div className="space-y-3 px-3 py-3 flex-1 flex flex-col md:text-lg text-sm">
                              <h2 className="font-medium">
                                <ProductLink
                                  item={p}>
                                  {p.title}
                                </ProductLink>
                              </h2>
                              <div className="flex-1"></div>
                              <div className="text-primary text-center ">
                                <OrderRequestProvider organization={organization}>
                                  <SpotRequestForm
                                    target={{
                                      id: productModel.getId(p),
                                      schema: productSchema
                                    }}
                                    childOnly={!!!organization}
                                    id={getId(organization)}
                                    type={getType(organization)}
                                    requestType={SpotRequestTypes.PRICE}>
                                    {(toggle) => {
                                      return (
                                        <Button
                                          size="medium"
                                          type="button"
                                          className="text-sm border border-primary rounded-full no-shadow px-4"
                                          style={{
                                            background: 'transparent',
                                            color: '#1da57a',
                                            fontWeight: '400',
                                          }}
                                          onClick={toggle}>
                                          {t('Get Best Price')}
                                        </Button>
                                      )
                                    }}
                                  </SpotRequestForm>
                                </OrderRequestProvider>
                                {/*<GetQuoteButton*/}
                                {/*{...{*/}
                                {/*t,*/}
                                {/*item: p*/}
                                {/*}}*/}
                                {/*style={{ border: 'solid 1px #1da57a', background: 'transparent', color: '#1da57a', fontWeight: '400', borderRadius: '99999px' }}*/}
                                {/*>*/}
                                {/*{t(*/}
                                {/*'Get Best Price'*/}
                                {/*)}*/}
                                {/*</GetQuoteButton>*/}
                              </div>
                            </div>
                          </div>
                        ))
                    }
                  </div>

                </div>
            ))}

            <div className="flex items-center justify-center mb-3 mt-10">
              <Link to={`${getLinkToDetail(organization)}/products`}
                className="px-8 py-1 border rounded-full hover:border-primary no-underline">
                <div className="font-light">{t('show more')}</div>
              </Link>
            </div>
          </section>
        )
      }

      {/*CHANNELS*/}
      {
        !!_.get(item, 'channels.length') && (
          <section className=" body-font relative pt-6 space-y-3 p-3">
            <div className="flex justify-center items-center font-semibold text-2xl uppercase mb-6 mt-8">
              {
                t('channels')
              }
            </div>
            <div className="flex grid grid-cols-1 lg:grid-cols-4 gap-y-3 lg:gap-3 relative">

              <div className="col-span-1 md:col-span-3 m-0 md:mr-6">
                <CustomLazyPaginationWrapper
                  sample={3}
                  alwaysVisible={true}
                  id={post_getPosts_Api.path + '_type_organization_id_' + organization.id}
                  key={post_getPosts_Api.path + '_type_organization_id_' + organization.id}
                  renderEmpty={() => (
                    <div className="items-center justify-center space-y-6" style={{ minHeight: '12rem' }}>
                      <div className="p-6 rounded-md background-100">
                        <Skeleton />

                        <EmptyHolder
                          icon={() => (
                            <EmptyFolderStateSvg
                              style={{ opacity: 0.5 }}
                              width="3rem"
                              height="3rem"
                            />
                          )}
                          title={null}
                        />
                      </div>
                    </div>
                  )}
                  apiInfo={post_getPosts_Api}
                  query={{
                    ':prop': 'organization',
                    ':id': organization.idname
                  }}
                  // values={{
                  //   pinned: 0
                  // }}
                  renderItem={renderItem}
                  renderWrap={children => {
                    return (
                      <div className="flex flex-col">
                        <div className="space-y-3">
                          {children}
                        </div>
                        <div className="flex items-center justify-center">
                          <Link to={`${getLinkToDetail(organization)}/news`}
                            className="px-8 py-1 border rounded-full hover:border-primary no-underline">
                            <div className="font-light">{t('show more')}</div>
                          </Link>
                        </div>
                      </div>
                    )
                  }}
                />
              </div>
              <div className="flex flex-col space-y-6 p-3 rounded-lg background-100">
                <div className="font-semibold text-2xl text-primary">
                  {_.get(organization, 'title')}
                </div>
                <LinkedPagesInfo
                  item={organization}
                  Wrapper={({ children }) => {
                    return (
                      <div className="flex flex-col space-y-2">
                        {children}
                      </div>
                    )
                  }}>
                  {(renderItem, { website }) => {
                    return renderItem({
                      page: website,
                      url: website
                    })
                  }}
                </LinkedPagesInfo>
                <CustomLazyPaginationWrapper
                  sample={3}
                  alwaysVisible={true}
                  id={
                    getChannelsByProps_Api.path.replace(":type", 'organization')
                      .replace(":id", organization.idname)
                  }
                  key={
                    getChannelsByProps_Api.path.replace(":type", 'organization')
                      .replace(":id", organization.idname)
                  }
                  renderEmpty={() => (
                    <div className="flex w-full h-48 justify-center items-center">
                      Empty Block
                    </div>
                  )}
                  apiInfo={
                    getChannelsByProps_Api
                  }
                  query={{
                    ':type':
                      'organization',
                    ':id':
                      organization.idname
                  }}
                  renderLoading={() => (
                    <>
                      <div className="col-span-1 h-16 background-100 rounded p-3" />
                      <div className="col-span-1 h-16 background-100 rounded p-3" />
                    </>
                  )}
                  renderItem={item => (
                    <div className="border-gray-300 cursor-pointer py-3 flex flex-col border-b">
                      <div className="flex items-center space-x-3">
                        <AppIcon className="text-sm" item={item} />
                        <Link
                          to={channelModel.getLinkToDetail(
                            item
                          )}
                          className="font-light">
                          {item.title}
                        </Link>
                      </div>
                      <Description
                        type={
                          'channel'
                        }
                        mentions={
                          item &&
                          item.mentions
                        }>
                        {
                          item.description
                        }
                      </Description>
                      <div className="flex">
                        <div className="flex-1" />
                        {
                          login &&
                          !!!_.get(
                            item,
                            'follow_status'
                          ) && (
                            <FollowButton
                              size="small"
                              className="float-right"
                              item={item}
                            />
                          )
                        }
                      </div>
                    </div>
                  )}
                  renderWrap={children => {
                    return (
                      <div className="flex flex-col space-y-3">
                        {children}

                        <div className="flex items-center justify-center p-3">
                          <Link to={`${getLinkToDetail(organization)}/news`}
                            className="px-8 py-1 border rounded-full hover:border-primary no-underline">
                            <div className="font-light">{t('show more')}</div>
                          </Link>
                        </div>
                      </div>
                    )
                  }}
                />
              </div>
            </div>

          </section>
        )
      }

      {
        (item.edit || _.get(item, 'about')) && (
          <section className="space-y-3 p-3">
            <h2 className="flex justify-center items-center font-semibold text-2xl uppercase mb-6 mt-8">
              {
                t('provider profile')
              }
            </h2>
            <AboutUs />
          </section>
        )
      }
      {
        !!_.get(item, 'contacts.length') && (
          <section className=" body-font relative">
            <div
              className="absolute"
              style={{
                top: '-50px'
              }}
              id="contact"
            />
            <Contact />
          </section>
        )
      }
    </div>
  )
}

export const OrganizationBanner = () => {
  const { item } = useContext(
    ServerContext
  )
  const t = useTranslate()

  return (
    <div className="w-full space-y-6 md:py-0">
      <div className="flex p-3 w-full h-full flex-col justify-center items-center space-y-10">

        <div className="max-w-2xl grid grid-cols-2 gap-3 py-6 text-color-300 font-bold">
          <Link className="py-3 px-6 flex justify-center items-center border rounded-full shadow-md"
            to={
              organizationModel.getLinkToSetting(
                item
              )
            }>{t('manage page')}</Link>
          <Link className="py-3 px-6 flex justify-center items-center border rounded-full shadow-md"
            to={
              organizationModel.getLinkToDetail(
                item
              )
            }>{t('main page')}</Link>
        </div>
        <div className="table border rounded-lg">
          <Image preview={false}
            width={160}
            height={160}
            src={organizationModel.getFullAvatarUrl(
              item
            ) || "#"}
            className="object-contain w-full h-full rounded-lg border-white table"
            placeholder={<PhotoSvg />}
            fallback={Photo}
          />
        </div>

        <div className="flex flex-col px-3 justify-center items-center space-y-3">
          <h2 className="p-3 title-font text-base md:text-lg font-bold">
            {item.title}
          </h2>
          <div className="text-base">
            {item.description}
          </div>
        </div>
      </div>
    </div>
  )
}

export const OrganizationStateless = ({ id, children }) => {
  let { url } = useRouteMatch()
  const t = useTranslate();
  const history = useHistory();
  const {
    item: organization
  } = useContext(ServerContext)

  const {
    isLoading,
    handleAsyncAction: handleAsyncPost
  } = useAsyncAction({
    apiInfo: auth_updateOrg_Api,
    onSuccess: result => {
      // message.info(t('success'))
    },
    onError: () =>
      message.error(t('error'))
  })

  const setPrimaryOrg = organization_id => {
    handleAsyncPost({ organization_id })
  };

  if (url === "/") url = "";

  const renderHomeLayout = children => (
    <OrganizationLayout
      admin={
        !!organization &&
        !!_.get(
          organization,
          'edit',
          false
        ) && (
          <SubDomainContext.Consumer>
            {
              ({ diff_root, isSubDomain }) => (
                <div className="p-2 px-3 background-100 font-bold flex space-x-2 items-center">
                  <div>{t('admin')}</div>
                  <div className="flex-1" />
                  <Button
                    onClick={() => {
                      setPrimaryOrg(
                        _.get(
                          organization,
                          'id'
                        )
                      );

                      setTimeout(
                        () => {
                          if (!!diff_root) {
                            window.location.replace(ROOT_URL + "/workspace/manage?rel=product-list&tab=listed-products");
                          } else {
                            history.push("/workspace/manage?rel=product-list&tab=listed-products");
                          }
                        },
                        1000
                      )
                    }}
                    icon={
                      <SettingOutlined />
                    }>
                    {t('setting')}
                  </Button>
                </div>
              )
            }
          </SubDomainContext.Consumer>
        )
      }
      header={<Header url={url} />}>
      <DelayRender time={300}>
        {children}
      </DelayRender>
    </OrganizationLayout>
  )

  return (
    <React.Fragment>
      {organization && (
        <Switch>
          <Route
            path={url + '/news'}
            render={() =>
              renderHomeLayout(
                <div>
                  <News />
                  <Contact />
                </div>
              )
            }
          />
          <Route
            path={url + '/data'}
            render={() =>
              renderHomeLayout(
                <div>
                  <Data />
                  <Contact />
                </div>
              )
            }
          />
          <Route
            path={url + '/products'}
            render={() =>
              renderHomeLayout(
                <>
                  <Products />
                  <Contact />
                </>
              )
            }
          />
          <Route
            path={url + '/price-report/:reportId'}
            render={({ match }) =>
              renderHomeLayout(
                <>
                  <Report reportId={match.params.reportId} />
                </>
              )
            }
          />
          <Route
            path={
              url +
              '/products/:productId'
            }
            render={() =>
              renderHomeLayout(
                <Product />
              )
            }
          />
          <Route
            path={url + '/about-us'}
            render={() =>
              renderHomeLayout(
                <div className="p-3 space-y-6">
                  <Breadcrumb className="font-light text-sm">
                    <Breadcrumb.Item>
                      <Link
                        to={url} />
                      {organizationModel.getTitle(
                        organization
                      )}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {t('about us')}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <AboutUs />
                </div>
              )
            }
          />
          <Route
            path={url + '/contact'}
            render={() =>
              renderHomeLayout(
                <div className="p-3 space-y-6">
                  <Breadcrumb className="font-light text-sm">
                    <Breadcrumb.Item>
                      <Link
                        to={url} />
                      {organizationModel.getTitle(
                        organization
                      )}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      {t('contact')}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <ContactDetail />

                  <SimpleContactForm />

                  <div className="mb-16" />
                </div>
              )
            }
          />
          <Route
            path={url}
            render={() =>
              renderHomeLayout(
                <OrganizationHome />
              )
            }
          />
        </Switch>
      )}
    </React.Fragment>
  )
}
const FloatButton = ({ type, id }) => {
  const history = useHistory()
  return <div
    style={{ padding: '1em' }}
    className="fixed z-50 bottom-0 right-0 pointer-events-none">
    <Toggle>
      {(isToggle, toggle) => (
        <>
          <button
            onClick={toggle}
            data-effect="ripple"
            style={{
              marginBottom:
                'var(--header-height)'
            }}
            className="w-12 flex items-center justify-center bg-primary text-white shadow-xl h-12 rounded-full pointer-events-auto border border-white">
            <Icon
              component={
                EditOutlined
              }
            />
          </button>
          <Modal
            onCancel={toggle}
            visible={isToggle}
            destroyOnClose
            footer={null}>
            <ApiInfoForm
              onSuccess={([
                __,
                data
              ]) => {
                Modal.destroyAll()
                history.push(
                  channelModel.getLinkToDetail(
                    data.response
                      .data
                  ) +
                  `?initialCurrent=posting`
                )
              }}
              apiInfo={
                channel_add_Api
              }
              onPreSubmit={values => {
                return {
                  ...values,
                  privacy:
                    values.privacy ===
                      '1'
                      ? 2
                      : 1
                }
              }}
              initialValues={{
                owner_id: id,
                owner_type:
                  type
              }}>
              {({ ...props }) => {
                return (
                  <CreateChannelForm {...props} />
                )
              }}
            </ApiInfoForm>
          </Modal>
        </>
      )}
    </Toggle>
  </div>
}
export default () => {
  const { id } = useParams()
  return (
    <React.Fragment key={id}>
      <OrganizationProvider {...{ id }}>
        {React.useMemo(
          () => (
            <OrganizationStateless
              {...{
                id,
              }}
            />
          ),
          [id]
        )}
        <ServerContext.Consumer>
          {
            ({ item }) => item && item.edit ? <FloatButton {...{ type: 'organization', id }} /> : null
          }
        </ServerContext.Consumer>

      </OrganizationProvider>
    </React.Fragment>

  )
}
