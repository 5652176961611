import { getId } from 'apis/model/base'
import Pure from 'components/Pure'
import _ from 'lodash'
import React from 'react'
import { branch, compose, fromRenderProps } from 'recompose'
import LinesChart from 'views/Chart/Charts/ChartComponents/LinesChart'
import { ContentNotFound } from 'views/AppLocals/ContentNotFound'
import { OrganizationContext } from 'views/Organization/OrganizationContext'
import { InsightsBlockHeader } from './WorkspaceInsights'

export const InsightsChartTypes = Object.freeze({
  DEFAULT: 'default',
  TYPE_1: 'type-1',
  TYPE_2: 'type-2',
})

const WorkspaceInsightsChart = ({ title, chartInfo, Wrapper = 'div' }) => (
  <Wrapper className="flex flex-col">
    <InsightsBlockHeader title={title} />
    <Pure input={[chartInfo]}>
      <LinesChart chartInfo={chartInfo} />
    </Pure>
  </Wrapper>
)

const InsightsChartFactory = ({
  chartInfo,
  type = InsightsChartTypes.DEFAULT,
}) => {
  switch (type) {
    case InsightsChartTypes.TYPE_1:
      return (
        <WorkspaceInsightsChart
          title="new articles"
          chartInfo={chartInfo}
        />
      )
    case InsightsChartTypes.TYPE_2:
      return (
        <WorkspaceInsightsChart
          title="interactive"
          chartInfo={chartInfo}
        />
      )
    case InsightsChartTypes.DEFAULT:
    default:
      return null
  }
}

export default compose(
  fromRenderProps(OrganizationContext.Consumer, ({ organization }) => ({
    notFound: _.isEmpty(organization),
    organization_id: getId(organization),
  })),
  branch(
    ({ notFound }) => !!notFound,
    () => () => <ContentNotFound />
  )
)(InsightsChartFactory)
