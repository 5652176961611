import {Select} from "antd"
import Null from "components/NullComponent"
import _ from "lodash"
import React from "react"

const RenderItem = ({
  id,
  label,
  displayName,
  ...props
}) => {
  return (
    <Select.Option
      key={id}
      value={id}
      label={label}
      {...props}>
      {displayName}
    </Select.Option>
  )
}

export const RenderCountries = ({
  common_name,
  country_code,
  telephone_code,
  ...props
}) => {
  const telCode = _.replace(telephone_code, '+', '')
  const displayName = `${country_code} (+${telCode})`
  return RenderItem({
    ...props,
    displayName,
    label: common_name,
    'data-country_code': country_code
  })
}

export const RenderUnits = ({
  code,
  name,
  ...props
}) => {
  return RenderItem({
    ...props,
    name,
    displayName: name,
    'data-unit_code': code
  })
}

const SpotSelect = ({
  onChange = Null,
                      items = [],
  renderItem = RenderItem,
  defaultFirstOption = false,
  ...props
}) => {
  const isLoading = _.isEmpty(items)

  const defaultValue = defaultFirstOption && !isLoading
    ? _.get(
      _.first(items),
      'id',
    )
    : null

  return (
    <Select
      showSearch
      loading={isLoading}
      optionFilterProp="children"
      onChange={(value) => {
        onChange({ value })
      }}
      defaultValue={defaultValue}
      {...props}>
      {items.map(renderItem)}
    </Select>
  )
}

export default SpotSelect