import useTranslate from 'modules/local/useTranslate'
import React, { useCallback, useContext } from 'react'
import { IoBarChartOutline, IoNotificationsOutline } from 'react-icons/io5'
import { SettingsMenu } from 'views/AppLocals/factory/createSettingLayout'
import DefaultTemplate from 'views/AppLocals/Templates/DefaultTemplate'
import renderLoading from 'views/MainPage/functions/renderPagingLoading'
import MainContext from 'views/MainPage/MainContext'
import { renderOwnChild } from 'views/Shared'
import CustomSiderMenu from 'views/Shared/components/CustomSiderMenu'
import { Queries } from 'views/Shared/enums/Queries'
import { useMenuHandler } from 'views/Shared/handlers/useMenuHandler'
import WorkspaceInsights from '../Insights/WorkspaceInsights'
import WorkspaceHomeGroup, { WorkspaceHomeGroups } from './WorkspaceHomeGroup'

const ItemKeys = Object.freeze({
  NOTIFICATION: 'notification',
  INSIGHTS: 'insights',
})

const menuItems = [
  {
    key: WorkspaceHomeGroups.NOTIFICATION,
    label: 'latest updates',
    icon: <IoNotificationsOutline size={16} />,
  },
  {
    key: WorkspaceHomeGroups.INSIGHTS,
    label: 'insights',
    icon: <IoBarChartOutline size={16} />,
  },
]

const WorkspaceHomeContent = () => {
  const t = useTranslate()
  return (
    <div className="w-full space-y-6">
      {[
        [
          {
            label: 'articles',
            type: WorkspaceHomeGroups.LASTED_ARTICLES,
          },
          {
            label: 'reposts',
            type: WorkspaceHomeGroups.LASTED_REPOSTS,
          },
        ],
        [
          {
            label: 'poll list',
            type: WorkspaceHomeGroups.POLL,
          },
        ],
        [
          {
            label: 'comment',
            type: WorkspaceHomeGroups.COMMENTS,
          },
          {
            label: 'latest followed user',
            type: WorkspaceHomeGroups.FOLLOW,
          },
        ],
      ].map((array, index) => (
        <div
          key={index}
          className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-6">
          {Array.from(array).map(({ type, label }, index) => (
            <div
              className="flex flex-col gap-2"
              id={type}
              key={index}>
              <div className="font-semibold text-sm text-color-300 uppercase tracking-wide border-b">
                {t(label)}
              </div>
              <WorkspaceHomeGroup type={type} />
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

const routeEntities = [
  {
    key: ItemKeys.NOTIFICATION,
    component: WorkspaceHomeContent,
  },
  {
    key: ItemKeys.INSIGHTS,
    component: WorkspaceInsights,
  },
].map(({ key, ...rest }) => ({
  key,
  path: key,
  ...rest,
}))

const WorkspaceHome = () => {
  const { isLoading } = useContext(MainContext)

  const { activeKey, Component, onMenuSelect } = useMenuHandler({
    routes: routeEntities,
    actionName: Queries.REL,
  })

  const renderMenu = useCallback(
    () => (
      <CustomSiderMenu
        deps={[activeKey]}
        Wrapper={renderOwnChild}>
        <SettingsMenu
          items={menuItems}
          onSelect={onMenuSelect}
          defaultSelectedKeys={[activeKey]}
        />
      </CustomSiderMenu>
    ),
    [menuItems, activeKey]
  )

  return (
    <DefaultTemplate renderMenu={renderMenu}>
      <div className="space-y-3 px-3 md:px-0 md:pr-3">
        {!!isLoading ? renderLoading() : <Component />}
      </div>
    </DefaultTemplate>
  )
}

export default WorkspaceHome
