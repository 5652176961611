import { notification } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useState } from 'react'
import { Null } from 'views/Shared'

export const AcceptFileTypes =
  Object.freeze({
    DOC: '.doc',
    DOC_APP: 'application/msword',
    DOCX: '.docx',
    DOCX_APP:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    XSL: '.xsl',
    XSL_APP: 'application/vnd.ms-excel',
    XSLX_APP:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    PDF: '.pdf',
    PDF_APP: 'application/pdf',
    IMG_PNG: 'image/png',
    IMG_JPEG: 'image/jpeg',
    PNG: '.png',
    JPEG: '.jpeg',
    IMAGE: 'image/*',
  })

export const MessageDropModes =
  Object.freeze({
    NONE: 'none',
    IMAGE: 'image',
    CUSTOM: 'custom',
  })

export const useDragEffect = (
  initialValue = 0
) => {
  const [dragActive, setDragActive] =
    React.useState(initialValue)

  const handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (
      e.type === 'dragenter' ||
      e.type === 'dragover'
    ) {
      e.dataTransfer.dropEffect = 'copy'
      setDragActive(1)
    } else if (
      e.type === 'dragleave' &&
      !e.currentTarget.contains(
        e.relatedTarget
      )
    ) {
      setDragActive(0)
    }
  }

  const handleDrop =
    (handleFiles = Null) =>
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDragActive(0)
      if (
        e.dataTransfer.files &&
        e.dataTransfer.files[0]
      ) {
        handleFiles(
          e.dataTransfer.files
        )
      }
    }

  return {
    dragActive,
    handleDrag,
    handleDrop,
    setDragActive,
  }
}

export const DragHoverZone = ({
  children,
  className,
  activeClass,
  Wrapper = 'div',
}) => {
  const {
    dragActive,
    handleDrag,
    handleDrop,
  } = useDragEffect()

  return (
    <Wrapper
      {...{
        dragActive,
        handleDrag,
        handleDrop,
      }}
      className={classNames(
        className,
        !!dragActive && activeClass
      )}>
      {_.isFunction(children)
        ? children({
            dragActive,
            handleDrag,
            handleDrop,
          })
        : children}
    </Wrapper>
  )
}

const DragDropFile = ({
  style,
  children,
  className,
  accept_types,
  onChange = Null,
  ContentWrapper = 'label',
  uploadInputId = 'input-file-upload',
  dragActive = false,
  handleDrag = Null,
  handleDrop = Null,
}) => {
  const t = useTranslate()

  const [refreshKey, setRefreshKey] =
    useState()

  const [current, setCurrent] =
    React.useState([])
  const inputRef = React.useRef(null)

  const onButtonClick = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    inputRef.current.click()
  }

  const handleFiles = (files) => {
    const array = Array.from(
      files || []
    )
    const not_allowed =
      !_.isEmpty(accept_types) &&
      _.find(array, ({ type }) => {
        const allowed_types = _.split(
          String(accept_types),
          ','
        )
        return !allowed_types.includes(
          type
        )
      })
    if (not_allowed) {
      notification.error({
        message: t(
          'not allowed file types'
        ),
      })
    } else {
      const typed_image = _.every(
        array,
        ({ type }) =>
          _.startsWith(type, 'image/')
      )
      setCurrent(array)
      onChange(
        array,
        typed_image
          ? MessageDropModes.IMAGE
          : MessageDropModes.CUSTOM
      )
    }
  }

  const handleChange = (e) => {
    e.preventDefault()
    if (
      e.target.files &&
      e.target.files[0]
    ) {
      handleFiles(e.target.files)
    }
  }

  const clear = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setCurrent([])
    setRefreshKey(Date.now())
  }

  const isActive =
    Number(dragActive) === 1

  return (
    <form
      style={style}
      className={classNames(
        'relative w-full',
        className
      )}
      onDragEnter={handleDrag}
      onSubmit={(e) =>
        e.preventDefault()
      }>
      <input
        type="file"
        key={refreshKey}
        ref={inputRef}
        multiple={true}
        className="hidden"
        id={uploadInputId}
        onChange={handleChange}
        accept={Array.from(
          accept_types || ['*']
        ).join(',')}
      />
      <ContentWrapper
        id="label-file-upload"
        htmlFor={uploadInputId}
        className={classNames(
          'flex h-full items-center justify-center',
          isActive &&
            'bg-white border-dashed border'
        )}
        data={current}
        dragactive={dragActive}>
        {_.isFunction(children)
          ? children({
              clear,
              isActive,
              files: current.map(
                ({ name }) => name
              ),
              open: onButtonClick,
            })
          : children}
      </ContentWrapper>
      {isActive && (
        <div
          style={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderRadius: '1rem',
          }}
          onDrop={handleDrop(
            handleFiles
          )}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
        />
      )}
    </form>
  )
}

export default DragDropFile
