import React from "react";

export const TechnologyAdditives = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 256 256"
		>
			<path
				fill="#939598"
				d="M227.143 185.44H28.857v-42.928a6 6 0 016-6h186.286a6 6 0 016 6z"
			></path>
			<path
				fill="#e6e7e8"
				d="M159.474 69.477H96.526V27.632a6 6 0 016-6h50.948a6 6 0 016 6z"
			></path>
			<path
				fill="#fff"
				d="M102.53 52.95a2 2 0 01-2-2V27.63c0-.52.21-1.04.58-1.41s.89-.59 1.42-.59h26.68c1.1 0 2 .9 2 2s-.9 2-2 2h-24.68v21.32c0 1.1-.9 2-2 2zm34.68-23.32c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
			></path>
			<path fill="#d1d3d4" d="M96.526 69.477h62.948v67.034H96.526z"></path>
			<path
				fill="#808285"
				d="M140.79 57.668h-25.58a6 6 0 01-6-6V39.361a6 6 0 016-6h25.58a6 6 0 016 6v12.307a6 6 0 01-6 6z"
			></path>
			<path
				fill="#58595b"
				d="M141.718 33.433c.047.303.072.613.072.928v12.307a6 6 0 01-6 6h-25.58c-.316 0-.626-.025-.928-.072a6.002 6.002 0 005.928 5.072h25.58a6 6 0 006-6V39.361a6 6 0 00-5.072-5.928z"
			></path>
			<path
				fill="#ffa91a"
				d="M120.556 136.511l-14.166 42.846h43.22l-14.167-42.846z"
			></path>
			<path
				fill="#f79219"
				d="M124.216 136.511l-7.202 42.846h21.972l-7.202-42.846z"
			></path>
			<path fill="#ffa91a" d="M104.243 85.079h47.515v6.083h-47.515z"></path>
			<path fill="#ef7816" d="M104.243 100.423h47.515v6.083h-47.515z"></path>
			<path fill="#e63950" d="M104.243 115.766h47.515v6.083h-47.515z"></path>
			<path
				fill="#d1d3d4"
				d="M151.757 185.44h-47.515v-4.083a2 2 0 012-2h43.515a2 2 0 012 2z"
			></path>
			<path fill="#d10028" d="M124.685 57.668h6.63v121.688h-6.63z"></path>
			<path
				fill="#58595b"
				d="M221.143 234.368H34.857a6 6 0 01-6-6V185.44h198.286v42.928a6 6 0 01-6 6z"
			></path>
			<path
				fill="#414042"
				d="M217.143 185.44v32.928a6 6 0 01-6 6H28.857v4a6 6 0 006 6h186.286a6 6 0 006-6V185.44z"
			></path>
			<path
				fill="#f1f2f2"
				d="M147.757 205.856h-39.515a4 4 0 01-4-4V185.44h47.515v16.416a4 4 0 01-4 4z"
			></path>
			<path
				fill="#e6e7e8"
				d="M141.757 185.44v6.416a4 4 0 01-4 4h-33.515v6a4 4 0 004 4h39.515a4 4 0 004-4V185.44z"
			></path>
			<path
				fill="#d1d3d4"
				d="M146.757 185.44v11.416a4 4 0 01-4 4h-38.515v1a4 4 0 004 4h39.515a4 4 0 004-4V185.44z"
			></path>
			<g>
				<path
					fill="#e63950"
					d="M99.716 217.07h28.512v6.083H99.716z"
					transform="rotate(90 113.972 220.112)"
				></path>
				<path
					fill="#e63950"
					d="M127.771 217.07h28.512v6.083h-28.512z"
					transform="rotate(90 142.028 220.112)"
				></path>
			</g>
			<g>
				<path
					fill="#e6e7e8"
					d="M37.269 146.699v30.657a2 2 0 002 2h47.112a2 2 0 012 2v4.083h8.145v-38.74a2 2 0 00-2-2H39.269a2 2 0 00-2 2z"
				></path>
			</g>
			<g>
				<path
					fill="#e6e7e8"
					d="M218.731 146.699v30.657a2 2 0 01-2 2h-47.112a2 2 0 00-2 2v4.083h-8.145v-38.74a2 2 0 012-2h55.258a2 2 0 011.999 2z"
				></path>
			</g>
			<g>
				<path
					fill="#fff"
					d="M128 38.269a7.466 7.466 0 100 14.932 7.466 7.466 0 000-14.932zm0 10.737a3.27 3.27 0 110-6.54 3.27 3.27 0 010 6.54z"
				></path>
				<path
					fill="#e6e7e8"
					d="M128 38.269c.761 0 1.495.115 2.187.327l-.47-2.329a.766.766 0 00-.751-.614h-1.931a.767.767 0 00-.751.614l-.47 2.329a7.442 7.442 0 012.186-.327zM135.266 44.043l1.821-1.607a.766.766 0 00.157-.957l-.965-1.672a.765.765 0 00-.907-.343l-2.324.781a7.45 7.45 0 012.218 3.798zM120.734 44.043a7.45 7.45 0 012.219-3.798l-2.324-.781a.765.765 0 00-.907.343l-.965 1.672a.765.765 0 00.157.957zM135.277 47.387a7.45 7.45 0 01-2.163 3.779l2.199.739a.765.765 0 00.907-.343l.965-1.672a.765.765 0 00-.157-.957zM120.723 47.387l-1.752 1.545a.766.766 0 00-.157.957l.965 1.672a.765.765 0 00.907.343l2.199-.739a7.449 7.449 0 01-2.162-3.778zM128 53.202a7.453 7.453 0 01-2.187-.327l.47 2.329a.766.766 0 00.751.614h1.931a.767.767 0 00.751-.614l.47-2.329a7.475 7.475 0 01-2.186.327z"
				></path>
				<g>
					<path
						fill="#e6e7e8"
						d="M128 40.938a4.798 4.798 0 100 9.596 4.798 4.798 0 000-9.596zm0 8.068a3.27 3.27 0 110-6.54 3.27 3.27 0 010 6.54z"
					></path>
				</g>
			</g>
		</svg>
	);
}

export default TechnologyAdditives;
