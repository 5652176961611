import {ExclamationCircleOutlined} from '@ant-design/icons'
import {Modal} from 'antd'
import React, {useCallback} from 'react'
import NullComponent from './NullComponent'

export const handleconfirm = ({
  native,
  title,
  icon = <ExclamationCircleOutlined />,
  content,
  okText,
  cancelText,
  onOk = () => {
    console.log('OK')
  },
  onCancel = () => {
    console.log('Cancel')
  },
                                ...props
}) => {
  if (native) {
    if (window.confirm(title)) {
      onOk()
    }
  } else {
    Modal.confirm({
      title,
      icon,
      content,
      okText,
      cancelText,
      onOk,
      onCancel,
      ...props
    })
  }
}
const Confirm = ({
  native,
                   children = NullComponent
}) => {
  const handleconfirm = useCallback(
    ({
      title = 'Confirm',
      icon = (
        <ExclamationCircleOutlined />
      ),
      content = 'Bla bla ...',
      okText = 'ok',
      cancelText = 'cancel',
      onOk = () => {
        console.log('OK')
      },
      onCancel = () => {
        console.log('Cancel')
      }
    }) => {
      handleconfirm({
        title,
        icon,
        content,
        okText,
        cancelText,
        onOk,
        onCancel
      })
    },
    []
  )
  return children(handleconfirm)
}

export default Confirm
