import asyncCacheReducers from 'modules/asyncCache/reducer'
import socket from 'modules/socket/reducer'
import { combineReducers } from 'redux'
import { i18nState } from 'redux-i18n'
import app from 'redux/app/reducer'
import auth from 'redux/auth/reducers'
import entities from 'redux/entities/reducer'

const getReducers = history => {
  return combineReducers({
    config: (state = {}, action) => {
      if (action.appconfig) {
        return {
          ...state,
          ...action.appconfig
        }
      }
      return state
    },
    app,
    i18nState,
    auth,
    entities,
    socket,
    ...asyncCacheReducers
  })
}

export default getReducers
