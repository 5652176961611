import {Input, Modal} from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useRef} from 'react'
import {Null} from 'views/Shared'

const Caption = ({
                   value,
                   onChange,
                   readOnly = false,
                   withModal = Null,
                   withInput = Null,
                 }) => {
  const t = useTranslate()

  const inputRef = useRef()

  const handleSubmit = async () => {
    const caption = inputRef.current?.input?.value
    onChange(caption)
    Modal.destroyAll()
    return
  }

  const createCaption = () => {
    Modal.confirm({
      icon: null,
      title: 'New caption',
      okText: t('save'),
      cancelText: t('cancel'),
      className: 'custom-modal',
      okButtonProps: {
        type: 'primary',
        className: 'button-rounded-lg no-border',
      },
      cancelButtonProps: {
        className: 'button-rounded-lg no-border',
      },
      ...(withModal() ?? {}),
      content: (
        <form
          onSubmit={(event) => {
            event.preventDefault()
            handleSubmit()
          }}>
          <Input
            key={value}
            ref={inputRef}
            defaultValue={value}
            {...(withInput({value}) ?? {})}
          />
          <input
            hidden
            type="submit"
          />
        </form>
      ),
      onOk: () => {
        handleSubmit()
      },
    })
  }

  return (
    <figcaption
      style={{
        marginTop: '0.25em',
      }}
      className="flex justify-center w-full text-center leading-tight">
      <span onClick={readOnly ? undefined : createCaption}>
        {readOnly ? (
          <small>
            <span className=" leading-none">{value}</span>
          </small>
        ) : (
          <React.Fragment>
            {_.isEmpty(value) ? (
              <span className="mt-2">
                <small className="text-color-300 italic cursor-pointer hover:text-primary">
                  {t('add caption')}
                </small>
              </span>
            ) : (
              <small>
                <span className=" leading-none">{value}</span>
                <span className="underline text-primary ml-2 italic cursor-pointer">
                  {t('edit')}
                </span>
              </small>
            )}
          </React.Fragment>
        )}
      </span>
    </figcaption>
  )
}

export default Caption
