import {Input} from 'antd'
import {ChannelIcon} from 'components/icons/ChannelIcon'
import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import Null from 'components/NullComponent'
import {fnum} from 'helpers/fnum'
import React from 'react'
import {FiRss} from 'react-icons/fi'

const { Search } = Input
export const ChannelContext = React.createContext(
  {
    item: {},
    isAdmin: false,
    getProductChannelUrl: Null,
    contextMenu: null,
    setSelect: Null,
    handleSelect: {
      cancel: console.log.bind(
        null,
        null
      ),
      detail: console.log.bind(
        null,
        'detail'
      ),
      pinnedPost: console.log.bind(
        null,
        'pinnedPost'
      ),
      memberList: console.log.bind(
        null,
        'memberList'
      ),
      share: console.log.bind(
        null,
        'share'
      )
    }
  }
)

export const ModalLayout = ({
  name,
  children
}) => (
  <ChannelContext.Consumer>
    {({
      handleSelect,
      product,
      item
    }) => (
      <FullSideModalLayout
        right
        title={
          name || (
            <span className="font-semibold  flex-1 j">
              <div
                style={{
                  fontSize: '0.8em'
                }}
                className="text-color-300 capitalize">
                {item.title}
              </div>
              {product && (
                <div className="flex items-center  ">
                  <span className="flex justify-center items-center">
                    {product.icon || (
                      <ChannelIcon
                        premium={
                          item.premium
                        }
                        className="mr-2 text-color-100 text-3xl w-8 h-8"
                      />
                    )}
                  </span>
                  <span className="pl-1 flex-1 capitalize">
                    {product.title}
                  </span>
                  {item.follow_status ? (
                    <FiRss
                      style={{
                        color: '#36B303'
                      }}
                    />
                  ) : (
                    <FiRss
                      style={{
                        color: '#bfbfbf'
                      }}
                    />
                  )}
                  <span className="px-1">
                    {fnum(
                      item.total_followers
                    )}
                  </span>
                </div>
              )}
            </span>
          )
        }
        onCancel={handleSelect.cancel}>
        {children}
      </FullSideModalLayout>
    )}
  </ChannelContext.Consumer>
)

export default ChannelContext
