import {Button} from 'antd'
import {createValue} from 'components/form/utils'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {IoPricetags} from 'react-icons/io5'
import {SelectWidget} from 'views/AppLocals/custom/ReactSelectComponents'
import {Null} from 'views/Shared'
import PostPrivacy from './PostPrivacy'

export const AttachedActions = ({
                                  values,
                                  fieldNames = {
                                    tags: 'tags',
                                    privacy: 'privacy',
                                  },
                                  onChange = Null,
                                }) => {
  const t = useTranslate()
  const tags = Array.from(_.get(values, fieldNames.tags) ?? [])
  return (
    <Toggle>
      {(isToggle, toggle) => (
        <div className="flex flex-col gap-2 mt-3">
          <div
            id="AttachedActions"
            className="flex gap-2">
            <PostPrivacy
              defaultValue={_.get(values, fieldNames.privacy)}
              onSelect={(value) => {
                onChange(createValue(fieldNames.privacy, Number(value)))
              }}
            />
            <Button
              onClick={toggle}
              icon={<IoPricetags size={18}/>}
              type={isToggle ? 'primary' : 'default'}
              className="flex items-center gap-2 button-rounded-lg">
              {t('hashtags')}
            </Button>
          </div>
          {isToggle && (
            <SelectWidget
              mode="tags"
              value={tags}
              allowClear={true}
              className="w-full"
              name={fieldNames.tags}
              onChange={(value) => {
                onChange(createValue(fieldNames.tags, value))
              }}
            />
          )}
        </div>
      )}
    </Toggle>
  )
}
