import {MenuUnfoldOutlined} from '@ant-design/icons'
import {Layout} from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React, {useEffect, useMemo} from 'react'
import {Switch} from 'react-router-dom'
import useToggle from 'react-use/lib/useToggle'
import {DelayRender} from 'views/Discovery/DelayRender'

export default function SettingsLayout({
  contextMenu,
  defaultToggle,
  routes,
  select,
  children
}) {
  const t = useTranslate()
  const [collapsed, toggle] = useToggle(
    defaultToggle
  )

  const location = useMemo(
    () => ({
      pathname: select
    }),
    [select]
  )
  useEffect(() => {
    toggle(true)
  }, [select, toggle])
  return (
    <Layout className="h-full">
      <Layout.Sider
        collapsed={
          collapsed &&
          window.innerWidth <= 1024
        }
        collapsible
        trigger={null}
        collapsedWidth={0}>
        {contextMenu}
      </Layout.Sider>
      <main
        style={{
          flex: 1,
          minWidth: '300px',
          flexShrink: 0
        }}>
        <div
          style={{
            minHeight: 50
          }}
          className="flex items-center background z-10">
          <div
            style={{
              width: 50,
              height: 50
            }}>
            <MenuUnfoldOutlined
              style={{
                width: 50,
                height: 50,
                display: 'flex',
                justifyContent:
                  'center',
                alignItems: 'center',
                lineHeight: '50px',
                fontSize: '1.2rem'
                // background: 'var(--)'
              }}
              className="trigger"
              type={
                collapsed
                  ? 'menu-unfold'
                  : 'menu-fold'
              }
              onClick={toggle}
            />
          </div>
          <div className="pl-3 font-bold flex-1 capitalize">
            {t(select || "")}
          </div>
        </div>
        <DelayRender time={200}>
          <div className="min-h-screen p-3">
            {routes && (
              <Switch
                location={location}>
                {routes}
              </Switch>
            )}
            {children}
          </div>
        </DelayRender>
      </main>
    </Layout>
  )
}
