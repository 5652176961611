import Null from 'components/NullComponent'
import React from 'react'

export const LayoutContext = React.createContext({
  width: 480,
  breakpoint: 'sm',
  breakpoints: {},
  leftSideBarWidth: 'var(--leftSideBar-width)',
  rightSideBarWidth: 'var(--leftSideBar-width)',
  smallLayout: false,
  LeftLayout: Null,
  RightLayout: Null,
  stickyOffset: 'var(--sticky-offset)',
  visibleLeftDrawer: false,
  setVisibleLeftDrawer: Null,
  visibleRightDrawer: false,
  setVisibleRightDrawer: Null,
  openMenu: false,
  setOpenMenu: Null,
})
