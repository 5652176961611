import Null from 'components/NullComponent'
import React from 'react'

export const LocalContext = React.createContext(
  {
    lang: 'vi',
    translate: Null,
    changeLang: Null
  }
)
