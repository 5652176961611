import {Avatar, Button, Col, Form, Input, Modal, Popconfirm, Row, Select, Table, Typography} from 'antd'
import UseState from 'components/UseState'
import {API_ROOT_URL} from 'envs/_current/config'
import {useFormikContext} from 'formik'
import _ from 'lodash'
import useAsyncWithCache from 'modules/asyncCache/useAsyncWithCache'
import useTranslate from 'modules/local/useTranslate'
import React, {useEffect, useState} from 'react'
import {AiFillDownCircle, AiFillUpCircle, AiOutlineDelete, AiOutlineDown, AiOutlineUp} from "react-icons/ai";
import {FaClipboardList} from "react-icons/fa";
import {TiLockClosed} from "react-icons/ti";
import {PlusOutlined} from "@ant-design/icons";
import useDispatchAsyncActionWithNotify from "../../../modules/asyncCache/useDispatchAsyncActionWithNotify";
import Toggle from "../../../components/Toggle";
import {createValue} from "../../../components/form/utils";
import {ToggleField} from "../../../components/form/fields/ToggleField";
import {createAsyncAction} from "../../../modules/asyncCache";
import {create_product_specs_updateSortOrder_Api, create_product_update_Api} from "../../../apis";
import LookupField from "../../../components/LookupField";
import DebounceSelect, {fetchLookupList} from "../../../components/LookupField/DebounceSelect";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import {BsCaretDownFill, BsCaretUpFill, BsClipboardCheck, BsTrash3} from "react-icons/bs";
import {FiEdit3} from "react-icons/fi";

export const mapspec_type = {
  others: '0', technical: '1', functional: '2'
}
const {Option} = Select

export const children = []

for (let i = 10; i < 36; i++) {
  children.push(
    <Option key={i.toString(36) + i}>
      {i.toString(36) + i}
    </Option>
  )
}


export const Specifications = ({item, product, options, update, setUpdate, handleSortOrder}) => {
  const t = useTranslate();

  const [__, dispatch] = useDispatchAsyncActionWithNotify();

  const [form] = Form.useForm();
  const [addDetailForm] = Form.useForm();

  const handleOnEditDetailSubmit = () => {
    const data = [
      ..._.get(product, 'specifications', [])
        .filter(e => !(String(e.spec_type) === String(item.spec_type) && e.attribute_group_name === item.attribute_group_name))
        .map(e => {
          return {...e, attribute_id: _.get(e, 'attribute.id')}
        }),
      ..._.get(form.getFieldsValue(), 'data', [])
    ];

    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_update_Api(),
          query: {
            ':id':
            product.product_id
          },
          values: {
            specifications: JSON.stringify(data)
          }
        }
      )
    )

    setTimeout(() => {
      setUpdate(Date.now())
    }, 500)
  }

  const handleOnAddDetailSubmit = (values) => {

    const data = [
      ..._.get(product, 'specifications', [])
        .map(e => {
          return {...e, attribute_id: _.get(e, 'attribute.id')}
        }),
      {
        ...values,
        spec_type: item.spec_type,
        attribute_id: _.get(values, 'attribute.id'),
        sort_order: 999,
        group_sort_order: 999
      }
    ];

    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_update_Api(),
          query: {
            ':id':
            product.product_id
          },
          values: {
            specifications: JSON.stringify(data)
          }
        }
      )
    )

    setTimeout(() => {
      addDetailForm.resetFields();
      setUpdate(Date.now())
    }, 1000)
  };

  useEffect(() => {
    form.setFieldsValue({
      data: item.data
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);


  return (
      <div className="flex flex-col">
          <Form
              key={update}
              className="flex flex-col gap-3"
              form={form}
              initialValues={{ ...item }}
              name={"item.spec_type" + item.spec_type}>
              <Form.List name="data">
                  {(fields, {add, remove}) => {
                      return (
                          <div className="space-y-3">
                              <Table
                                  size="small"
                                  pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
                                  columns={[
                                      {
                                          title: (
                                              (Boolean(item.attribute_group_name) && item.attribute_group_name !== 'null') ? (
                                                  <span className="font-bold text-sm text-color-000">
                                                      {_.get(item, 'attribute_group_name')}
                                                  </span>
                                              ) : (
                                                  <span className="font-bold text-sm text-color-000">
                                                      {t('attribute')}
                                                  </span>
                                              )
                                          ),
                                          dataIndex: 'attribute_name',
                                          key: 'attribute_name',
                                          visible: true,
                                          render: (__, record) => {
                                              const _rec = form.getFieldValue('data')[record.name];
                                              return (
                                                  <div className="flex flex-col">
                                                      <span className="font-semibold text-sm text-color-100 leading-snug">
                                                          {_rec.attribute_name}
                                                      </span>
                                                      <span className="flex flex-wrap leading-tight">
                                                          {!!_rec.unit_name && (
                                                              <span className="text-xs text-color-400 italic">
                                                                  {`${t('unit')} : ${_rec.unit_name}`}
                                                              </span>
                                                          )}
                                                      </span>
                                                  </div>
                                              )
                                          },
                                      },
                                      {
                                          // title: t('value'),
                                          dataIndex: 'attribute_value',
                                          key: 'attribute_value',
                                          visible: true,
                                          render: (__, record) => {
                                              const _rec = form.getFieldValue('data')[record.name];
                                              return (
                                                  <div className="justify-center items-start w-auto flex flex-col">
                                                      <div className="space-x-1 flex flex-wrap">
                                                          <span className="font-semibold text-sm text-color-100 leading-snug">
                                                              {_rec.attribute_value}
                                                          </span>
                                                          {!!_rec.operation && (
                                                              <span className="text-sm text-color-400 italic">
                                                                  ({_rec.operation})
                                                              </span>
                                                          )}
                                                      </div>
                                                      <span className="flex flex-wrap leading-tight">
                                                          {!!_rec.method_type && (
                                                              <span className="text-xs text-color-400 italic">
                                                                  {t('method')}: {_rec.method_type}
                                                              </span>
                                                          )}
                                                      </span>
                                                  </div>
                                              )
                                          },
                                      },
                                      {
                                          title: (
                                              <div className="flex justify-end gap-3">
                                                  <Avatar
                                                      size={22}
                                                      className="flex flex-center background-200 hover:bg-primary text-color-400 hover:text-white cursor-pointer"
                                                      onClick={() => {
                                                          handleSortOrder({
                                                              attribute_group_name: item.attribute_group_name,
                                                              direction: -1
                                                          });
                                                      }}
                                                      icon={ <BsCaretUpFill size={10} /> }
                                                  />
                                                  <Avatar
                                                      size={22}
                                                      className="flex flex-center background-200 hover:bg-primary text-color-400 hover:text-white cursor-pointer"
                                                      onClick={() => {
                                                          handleSortOrder({
                                                              attribute_group_name: item.attribute_group_name,
                                                              direction: 1
                                                          });
                                                      }}
                                                      icon={ <BsCaretDownFill size={10} /> }
                                                  />
                                              </div>
                                          ),
                                          dataIndex: 'action',
                                          align: 'right',
                                          key: 'action',
                                          visible: true,
                                          render: (__, record) => {
                                              const _rec = form.getFieldValue('data')[record.name];
                                              return (
                                                  <div className="flex flex-row justify-end gap-2">
                                                      <div className="flex flex-col md:flex-row gap-2">
                                                          <Avatar
                                                              size={22}
                                                              className="flex flex-center background-200 hover:bg-primary text-color-400 hover:text-white cursor-pointer"
                                                              onClick={() => {
                                                                  handleSortOrder({attribute_id: _rec.id, direction: -1});
                                                              }}
                                                              icon={ <BsCaretUpFill size={10} /> }
                                                          />
                                                          <Avatar
                                                              size={22}
                                                              className="flex flex-center background-200 hover:bg-primary text-color-400 hover:text-white cursor-pointer"
                                                              onClick={() => {
                                                                  handleSortOrder({attribute_id: _rec.id, direction: 1});
                                                              }}
                                                              icon={ <BsCaretDownFill size={10} /> }
                                                          />
                                                      </div>
                                                      <div className="flex flex-col md:flex-row gap-2">
                                                          <Popconfirm
                                                              title={
                                                                  <div className="font-medium text-sm text-color-100 italic">
                                                                      {t('sure to remove')}
                                                                  </div>
                                                              }
                                                              okText={t('sure')}
                                                              cancelText={t('no')}
                                                              okButtonProps={{
                                                                  className: 'button-rounded-md no-border text-xs font-medium',
                                                              }}
                                                              cancelButtonProps={{
                                                                  className: 'button-rounded-md no-border text-xs font-medium',
                                                              }}
                                                              onConfirm={() => {
                                                                  remove(record.name);
                                                                  setTimeout(() => {
                                                                      handleOnEditDetailSubmit();
                                                                  }, 1000);
                                                              }}>
                                                              <Avatar
                                                                  size={22}
                                                                  className="flex flex-center background-200 hover:bg-red-600 text-color-400 hover:text-white cursor-pointer"
                                                                  icon={<BsTrash3 size={10}/>}
                                                              />
                                                          </Popconfirm>
                                                          <Toggle>
                                                              {( isToggle, toggle ) => (
                                                                  isToggle ? (
                                                                      <Modal
                                                                          key={update}
                                                                          width={600}
                                                                          className="custom-modal"
                                                                          title={(
                                                                              <div className="text-center font-bold text-color-000 uppercase">
                                                                                  {_rec.attribute_name}
                                                                              </div>
                                                                          )}
                                                                          destroyOnClose
                                                                          onCancel={toggle}
                                                                          visible={isToggle}
                                                                          footer={null}>
                                                                          <div className="w-full">
                                                                              <Form
                                                                                  form={form}
                                                                                  initialValues={{
                                                                                      attribute_name: _rec.attribute_name,
                                                                                      attribute_value: _rec.attribute_value,
                                                                                      attribute_group_name: _rec.attribute_group_name,
                                                                                      visible: !!_rec.visible,
                                                                                      unit_name: _rec.unit_name,
                                                                                      operation: _rec.operation,
                                                                                      method_type: _rec.method_type
                                                                                  }}
                                                                                  onFinish={(values) => {
                                                                                      handleOnEditDetailSubmit();
                                                                                      toggle();
                                                                                  }}
                                                                                  {...{
                                                                                      labelCol: {span: 8},
                                                                                      wrapperCol: {span: 16},
                                                                                  }}>
                                                                                  {(values) => {
                                                                                      const isSelectCtl = (!!_rec.attribute && !!_rec.attribute.default_values && !!_rec.attribute.default_values.length)
                                                                                      const isMulti = (isSelectCtl && !!_rec.attribute.multiple)

                                                                                      return (
                                                                                          <div className="space-y-3">
                                                                                              <Form.Item
                                                                                                  className="flex-1"
                                                                                                  name={["data", String(record.name), "attribute_name"]}
                                                                                                  label={
                                                                                                      <span className="text-sm text-color-400 italic">
                                                                                                          {t('attribute')}
                                                                                                      </span>
                                                                                                  }>
                                                                                                  {(isSelectCtl) ? (
                                                                                                      <UseState initialValue={{
                                                                                                          value: _rec.attribute_name
                                                                                                      }}>
                                                                                                          {([ localState, setState ]) => {
                                                                                                              return (
                                                                                                                  <Select
                                                                                                                      showSearch
                                                                                                                      optionFilterProp={"label"}
                                                                                                                      value={localState.value}
                                                                                                                      onSearch={value => {
                                                                                                                          setState({
                                                                                                                              ...localState,
                                                                                                                              customOption: value && value.length ? {
                                                                                                                                  value: value,
                                                                                                                                  label: `${t('create option') + ' ' + value}`
                                                                                                                              } : null
                                                                                                                          })
                                                                                                                      }}
                                                                                                                      placeholder={t('custom specs')}
                                                                                                                      className="block"
                                                                                                                      onChange={(__, option) => {

                                                                                                                          setState({
                                                                                                                              ...localState,
                                                                                                                              value: option.value
                                                                                                                          });

                                                                                                                          const _fields = form.getFieldsValue();
                                                                                                                          const {data} = _fields;


                                                                                                                          Object.assign(data[record.name], {
                                                                                                                              attribute_name: option.value
                                                                                                                          })

                                                                                                                          form.setFieldsValue({
                                                                                                                              data
                                                                                                                          })
                                                                                                                      }}
                                                                                                                      options={
                                                                                                                          _.uniqBy(
                                                                                                                              [
                                                                                                                                  {...localState.customOption},
                                                                                                                                  {
                                                                                                                                      label: _rec.attribute_name,
                                                                                                                                      value: _rec.attribute_name
                                                                                                                                  }
                                                                                                                              ],
                                                                                                                              'value'
                                                                                                                          )
                                                                                                                      }

                                                                                                                  />
                                                                                                              )
                                                                                                          }}
                                                                                                      </UseState>
                                                                                                  ) : (
                                                                                                      <Input/>
                                                                                                  )}
                                                                                              </Form.Item>

                                                                                              <Form.Item
                                                                                                  className="flex-1"
                                                                                                  name={["data", String(record.name), "attribute_value"]}
                                                                                                  label={
                                                                                                      <span className="text-sm text-color-400 italic">
                                                                                                          {_rec.attribute_name}
                                                                                                      </span>
                                                                                                  }>

                                                                                                  {(isSelectCtl) ? (
                                                                                                          <UseState initialValue={{
                                                                                                              value: isMulti ? _rec.attribute_value.split(",") : _rec.attribute_value
                                                                                                          }}>
                                                                                                              {([
                                                                                                                    localState,
                                                                                                                    setState
                                                                                                                ]) => {
                                                                                                                  const attr = _rec.attribute.default_values;
                                                                                                                  return isMulti ? (
                                                                                                                      <Select
                                                                                                                          showSearch
                                                                                                                          optionFilterProp={"label"}
                                                                                                                          value={localState.value}
                                                                                                                          mode={"multiple"}
                                                                                                                          onSearch={value => {
                                                                                                                              setState({
                                                                                                                                  ...localState,
                                                                                                                                  customOption: value && value.length ? {
                                                                                                                                      value: value,
                                                                                                                                      label: `${t('create option') + ': ' + value}`
                                                                                                                                  } : null
                                                                                                                              })
                                                                                                                          }}
                                                                                                                          placeholder={t('custom specs')}
                                                                                                                          className="block"
                                                                                                                          onChange={(__, option) => {

                                                                                                                              setState({
                                                                                                                                  ...localState,
                                                                                                                                  value: option.map(e => e.value)
                                                                                                                              });

                                                                                                                              const _fields = form.getFieldsValue();
                                                                                                                              const {data} = _fields;

                                                                                                                              Object.assign(data[record.name], {
                                                                                                                                  attribute_value: option.map(e => e.value).join(",")
                                                                                                                              })

                                                                                                                              form.setFieldsValue({
                                                                                                                                  data
                                                                                                                              })
                                                                                                                          }}
                                                                                                                          options={
                                                                                                                              _.uniqBy(
                                                                                                                                  [
                                                                                                                                      {...localState.customOption},
                                                                                                                                      ...[...attr, ..._rec.attribute_value.split(",")
                                                                                                                                          .filter(e => !!!attr.includes(e))
                                                                                                                                      ].map(e => {
                                                                                                                                          return {
                                                                                                                                              label: e, value: e
                                                                                                                                          }
                                                                                                                                      })
                                                                                                                                  ], 'value'
                                                                                                                              )
                                                                                                                          }

                                                                                                                      />
                                                                                                                  ) : (
                                                                                                                      <Select
                                                                                                                          showSearch
                                                                                                                          optionFilterProp={"label"}
                                                                                                                          value={localState.value}
                                                                                                                          onSearch={value => {
                                                                                                                              setState({
                                                                                                                                  ...localState,
                                                                                                                                  customOption: value && value.length ? {
                                                                                                                                      value: value,
                                                                                                                                      label: `${t('create option') + ' ' + value}`
                                                                                                                                  } : null
                                                                                                                              })
                                                                                                                          }}
                                                                                                                          placeholder={t('custom specs')}
                                                                                                                          className="block"
                                                                                                                          onChange={(__, option) => {

                                                                                                                              setState({
                                                                                                                                  ...localState,
                                                                                                                                  value: option.value
                                                                                                                              });

                                                                                                                              const _fields = form.getFieldsValue();
                                                                                                                              const {data} = _fields;


                                                                                                                              Object.assign(data[record.name], {
                                                                                                                                  attribute_value: option.value
                                                                                                                              })

                                                                                                                              form.setFieldsValue({
                                                                                                                                  data
                                                                                                                              })
                                                                                                                          }}
                                                                                                                          options={
                                                                                                                              _.uniqBy(
                                                                                                                                  [
                                                                                                                                      {...localState.customOption},
                                                                                                                                      {
                                                                                                                                          label: _rec.attribute_value,
                                                                                                                                          value: _rec.attribute_value
                                                                                                                                      },
                                                                                                                                      ...attr.map(e => {
                                                                                                                                          return {
                                                                                                                                              label: e, value: e
                                                                                                                                          }
                                                                                                                                      })
                                                                                                                                  ],
                                                                                                                                  'value'
                                                                                                                              )
                                                                                                                          }

                                                                                                                      />
                                                                                                                  )
                                                                                                              }}
                                                                                                          </UseState>

                                                                                                      ) : (
                                                                                                          <Input/>
                                                                                                      )
                                                                                                  }
                                                                                              </Form.Item>

                                                                                              <Form.Item
                                                                                                  name={["data", String(record.name), "visible"]}
                                                                                                  label={
                                                                                                      <span className="text-sm text-color-400 italic">
                                                                                                          {t("visible")}
                                                                                                      </span>
                                                                                                  }>
                                                                                                  <ToggleField
                                                                                                      // defaultValue={!!_rec.visible}
                                                                                                      titleIcon={
                                                                                                          <TiLockClosed
                                                                                                              style={{
                                                                                                                  color: '#b0b0b0',
                                                                                                                  fontSize: '1em'
                                                                                                              }}
                                                                                                          />
                                                                                                      }
                                                                                                      name={["data", String(record.name), "visible"]}
                                                                                                      title={t('visible')}
                                                                                                      description={t('displayed on product information')}
                                                                                                      onChange={(value) => {
                                                                                                          const _fields = form.getFieldsValue();
                                                                                                          const {data} = _fields;

                                                                                                          Object.assign(data[record.name], {
                                                                                                              visible: value ? 1 : 0
                                                                                                          })

                                                                                                          form.setFieldsValue({
                                                                                                              data
                                                                                                          })
                                                                                                      }}
                                                                                                  />
                                                                                              </Form.Item>

                                                                                              <Form.Item
                                                                                                  key={"attribute_group_name"}
                                                                                                  className="flex-1"
                                                                                                  name={["data", String(record.name), "attribute_group_name"]}
                                                                                                  label={
                                                                                                      <span className="text-sm text-color-400 italic">
                                                                                                          {t("attribute group")}
                                                                                                      </span>
                                                                                                  }>
                                                                                                  <DebounceSelect
                                                                                                      name={"attribute_group_name"}
                                                                                                      type={"attribute_group_name"}
                                                                                                      // mode="multiple"
                                                                                                      disabled={_rec.attribute}
                                                                                                      value={
                                                                                                          _rec["attribute_group_name"] ||
                                                                                                          ''
                                                                                                      }
                                                                                                      placeholder={t("attribute group")}
                                                                                                      fetchOptions={fetchLookupList}
                                                                                                      onChange={(newValue) => {

                                                                                                          const _fields = form.getFieldsValue();
                                                                                                          const {data} = _fields;

                                                                                                          Object.assign(data[record.name], {
                                                                                                              "attribute_group_name": newValue
                                                                                                          })

                                                                                                          form.setFieldsValue({
                                                                                                              data
                                                                                                          })

                                                                                                      }}
                                                                                                      style={{
                                                                                                          width: '100%',
                                                                                                      }}
                                                                                                  />
                                                                                              </Form.Item>

                                                                                              {[
                                                                                                  {name: 'unit_name', label: t('unit'), disabled: false},
                                                                                                  {name: 'operation', label: t('operation'), disabled: false},
                                                                                                  {name: 'method_type', label: t('method type'), disabled: false}
                                                                                              ].map(e => (
                                                                                                  <Form.Item
                                                                                                      key={e.name}
                                                                                                      className="flex-1"
                                                                                                      name={["data", String(record.name), e.name]}
                                                                                                      label={
                                                                                                          <span className="text-sm text-color-400 italic">
                                                                                                                  {t(e.label)}
                                                                                                              </span>
                                                                                                      }>
                                                                                                      <LookupField
                                                                                                          disabled={e.disabled}
                                                                                                          defaultValue={
                                                                                                              _rec[e.name] ||
                                                                                                              ''
                                                                                                          }
                                                                                                          name={e.name}
                                                                                                          handleChange={(e) => {

                                                                                                              const _fields = form.getFieldsValue();
                                                                                                              const {data} = _fields;

                                                                                                              Object.assign(data[record.name], {
                                                                                                                  [e.target.name]: e.target.value
                                                                                                              })

                                                                                                              form.setFieldsValue({
                                                                                                                  data
                                                                                                              })

                                                                                                          }}
                                                                                                      />
                                                                                                  </Form.Item>
                                                                                              ))}

                                                                                              <div className="text-center mt-3">
                                                                                                  <Button
                                                                                                      type="primary"
                                                                                                      className="button-rounded-md no-border"
                                                                                                      htmlType="submit">
                                                                                                      {t('confirm')}
                                                                                                  </Button>
                                                                                              </div>
                                                                                          </div>
                                                                                      )
                                                                                  }}
                                                                              </Form>
                                                                          </div>
                                                                      </Modal>
                                                                  ) : (
                                                                      <Avatar
                                                                          size={22}
                                                                          className="flex flex-center background-200 hover:bg-primary-50 text-color-400 hover:text-primary cursor-pointer"
                                                                          onClick={() => {
                                                                              toggle()
                                                                          }}
                                                                          icon={<FiEdit3 size={10}/>}
                                                                      />
                                                                  )
                                                              )}
                                                          </Toggle>
                                                      </div>
                                                  </div>
                                              )
                                          }
                                      },
                                  ].filter(e => e.visible)}
                                  dataSource={fields}
                                  footer={() => (
                                      <Toggle>
                                          {( isToggle, toggle ) => (
                                              isToggle ? (
                                                  <Modal
                                                      width={600}
                                                      className="custom-modal"
                                                      title={(
                                                          <div className="text-center font-bold text-color-000 uppercase">
                                                              {t('add new specification')}
                                                          </div>
                                                      )}
                                                      destroyOnClose
                                                      onCancel={toggle}
                                                      visible={isToggle}
                                                      footer={null}>
                                                      <div className="w-full">
                                                          <UseState
                                                              initialValue={{
                                                                  attribute: null,
                                                                  attribute_group_name: item.attribute_group_name,
                                                                  attribute_id: null,
                                                                  attribute_name: null,
                                                                  attribute_value: null,
                                                                  unit_name: null,
                                                                  operation: null,
                                                                  method_type: null,
                                                                  visible: 1
                                                              }}>
                                                              {([ localState, setState ]) => {
                                                                  return (
                                                                      <Form
                                                                          form={addDetailForm}
                                                                          initialValues={{}}
                                                                          onFinish={(values) => {
                                                                              handleOnAddDetailSubmit({...localState});
                                                                              add({...localState});
                                                                              toggle();
                                                                          }}
                                                                          {...{
                                                                              labelCol: {span: 8},
                                                                              wrapperCol: {span: 16},
                                                                          }}>
                                                                          {(values) => {
                                                                              const handleChange = (value) => {
                                                                                  setState({...localState, [value.target.name]: value.target.value});
                                                                              };

                                                                              const selectedSpec = options.find(e => _.get(e, 'id') === values.attribute);

                                                                              const isSelectCtl = selectedSpec && (
                                                                                  selectedSpec.default_values
                                                                                  && selectedSpec.default_values.length
                                                                              )
                                                                              const isMulti = selectedSpec && (
                                                                                  isSelectCtl && !!selectedSpec.multiple
                                                                              )
                                                                              return (
                                                                                  <div className="flex flex-col gap-3">
                                                                                      <Form.Item
                                                                                          className="flex-1"
                                                                                          name={"attribute"}
                                                                                          label={
                                                                                              <span className="text-sm text-color-400 italic">
                                                                                                  {t("attribute")}
                                                                                              </span>
                                                                                          }
                                                                                          rules={[
                                                                                              {
                                                                                                  required: true,
                                                                                                  message: t('Please input attribute name'),
                                                                                              },
                                                                                          ]}>
                                                                                          <Select
                                                                                              showSearch
                                                                                              optionFilterProp={"label"}
                                                                                              onSearch={value => {
                                                                                                  setState({
                                                                                                      ...localState,
                                                                                                      customOption: value && value.length ? {
                                                                                                          item: {
                                                                                                              is_default: false,
                                                                                                              id: value,
                                                                                                              spec_type: item.spec_type,
                                                                                                              attribute_name: value
                                                                                                          },
                                                                                                          value: value,
                                                                                                          label: `${t('create option') + ' ' + value}`
                                                                                                      } : null
                                                                                                  })
                                                                                              }}
                                                                                              placeholder={t('custom specs')}
                                                                                              className="block"
                                                                                              onChange={(__, option) => {
                                                                                                  setState({
                                                                                                      ...localState,
                                                                                                      selected: option.item,
                                                                                                      attribute_id: option.item.id,
                                                                                                      attribute_name: option.item.attribute_name
                                                                                                  })
                                                                                              }}
                                                                                              options={[
                                                                                                  {...localState.customOption},
                                                                                                  ...options.filter(e => String(e.spec_type) === String(item.spec_type))
                                                                                                      .map(e => {
                                                                                                          return {
                                                                                                              item: e,
                                                                                                              label: e.attribute_name, value: e.id
                                                                                                          }
                                                                                                      })
                                                                                              ].filter(Boolean)}
                                                                                          />
                                                                                      </Form.Item>
                                                                                      <Form.Item
                                                                                          className="flex-1"
                                                                                          name={"attribute_value"}
                                                                                          label={
                                                                                              <span className="text-sm text-color-400 italic">
                                                                                                  {t("attribute value")}
                                                                                              </span>
                                                                                          }
                                                                                          rules={[
                                                                                              {
                                                                                                  required: true,
                                                                                                  message: t('Please input attribute value'),
                                                                                              },
                                                                                          ]}>
                                                                                          {
                                                                                              (isSelectCtl) ? (
                                                                                                  <UseState initialValue={{
                                                                                                      value: []
                                                                                                  }}>
                                                                                                      {([
                                                                                                            localState,
                                                                                                            setState
                                                                                                        ]) => {
                                                                                                          const attr = selectedSpec.default_values;
                                                                                                          return isMulti ? (
                                                                                                              <Select
                                                                                                                  showSearch
                                                                                                                  optionFilterProp={"label"}
                                                                                                                  value={localState.value}
                                                                                                                  mode={"multiple"}
                                                                                                                  onSearch={value => {
                                                                                                                      setState({
                                                                                                                          ...localState,
                                                                                                                          customOption: value && value.length ? {
                                                                                                                              value: value,
                                                                                                                              label: `${t('create option') + ' ' + value}`
                                                                                                                          } : null
                                                                                                                      })
                                                                                                                  }}
                                                                                                                  placeholder={t('custom specs')}
                                                                                                                  className="block"
                                                                                                                  onChange={(__, option) => {

                                                                                                                      setState({
                                                                                                                          ...localState,
                                                                                                                          value: option.map(e => e.value)
                                                                                                                      });

                                                                                                                      handleChange(createValue("attribute_value", option.map(e => e.value).join(",")))
                                                                                                                  }}
                                                                                                                  options={
                                                                                                                      _.uniqBy(
                                                                                                                          [
                                                                                                                              {...localState.customOption},
                                                                                                                              ...[...attr, ...localState.value
                                                                                                                                  .filter(e => !!!attr.includes(e))
                                                                                                                              ].map(e => {
                                                                                                                                  return {
                                                                                                                                      label: e, value: e
                                                                                                                                  }
                                                                                                                              })
                                                                                                                          ], 'value'
                                                                                                                      )
                                                                                                                  }

                                                                                                              />
                                                                                                          ) : (
                                                                                                              <Select
                                                                                                                  showSearch
                                                                                                                  optionFilterProp={"label"}
                                                                                                                  value={localState.value}
                                                                                                                  onSearch={value => {
                                                                                                                      setState({
                                                                                                                          ...localState,
                                                                                                                          customOption: value && value.length ? {
                                                                                                                              value: value,
                                                                                                                              label: `${t('create option') + ' ' + value}`
                                                                                                                          } : null
                                                                                                                      })
                                                                                                                  }}
                                                                                                                  placeholder={t('custom specs')}
                                                                                                                  className="block"
                                                                                                                  onChange={(__, option) => {

                                                                                                                      setState({
                                                                                                                          ...localState,
                                                                                                                          value: option.value
                                                                                                                      });

                                                                                                                      handleChange(createValue("attribute_value", option.value))
                                                                                                                  }}
                                                                                                                  options={
                                                                                                                      _.uniqBy(
                                                                                                                          [
                                                                                                                              {...localState.customOption},
                                                                                                                              ...attr.map(e => {
                                                                                                                                  return {
                                                                                                                                      label: e, value: e
                                                                                                                                  }
                                                                                                                              })
                                                                                                                          ],
                                                                                                                          'value'
                                                                                                                      )
                                                                                                                  }

                                                                                                              />
                                                                                                          )
                                                                                                      }}
                                                                                                  </UseState>

                                                                                              ) : (
                                                                                                  <Input
                                                                                                      onChange={e => {
                                                                                                          handleChange(createValue("attribute_value", e.target.value))
                                                                                                      }}
                                                                                                  />
                                                                                              )
                                                                                          }
                                                                                      </Form.Item>
                                                                                      <Form.Item
                                                                                          label={
                                                                                              <span className="text-sm text-color-400 italic">
                                                                                                  {t('visible')}
                                                                                              </span>
                                                                                          }
                                                                                          name="visible">
                                                                                          <ToggleField
                                                                                              onChange={value => {
                                                                                                  handleChange(createValue("visible", value ? 1 : 0))
                                                                                              }}
                                                                                              name={'visible'}
                                                                                              title={t('visible')}
                                                                                              description={t('displayed on product information')}
                                                                                          />
                                                                                      </Form.Item>

                                                                                      {[
                                                                                              {
                                                                                                  name: 'attribute_group_name',
                                                                                                  label: t('attribute group'),
                                                                                                  disabled: !!selectedSpec
                                                                                              },
                                                                                              {name: 'unit_name', label: t('unit'), disabled: false},
                                                                                              {name: 'operation', label: t('operation'), disabled: false},
                                                                                              {name: 'method_type', label: t('method type'), disabled: false}
                                                                                          ].map(e => (
                                                                                          <Form.Item
                                                                                              key={e.name}
                                                                                              name={e.name}
                                                                                              label={
                                                                                                  <span className="text-sm text-color-400 italic">
                                                                                                      {t(e.label)}
                                                                                                  </span>
                                                                                              }>
                                                                                              <LookupField
                                                                                                  disabled={e.disabled}
                                                                                                  defaultValue={localState[e.name] || ''}
                                                                                                  name={e.name}
                                                                                                  handleChange={handleChange}
                                                                                              />
                                                                                          </Form.Item>
                                                                                      ))}

                                                                                      <div className="text-center my-3">
                                                                                          <Button
                                                                                              type="primary"
                                                                                              className="button-rounded-md no-border"
                                                                                              htmlType="submit">
                                                                                              {t('confirm')}
                                                                                          </Button>
                                                                                      </div>
                                                                                  </div>
                                                                              )
                                                                          }}
                                                                      </Form>
                                                                  )
                                                              }}
                                                          </UseState>


                                                      </div>
                                                  </Modal>
                                              ) : (
                                                  <div className="text-center">
                                                      <Button
                                                          type="primary"
                                                          className="button-rounded-md no-border"
                                                          onClick={() => {toggle()}}>
                                                          {t('add new specification')}
                                                      </Button>
                                                  </div>
                                              )
                                          )}
                                      </Toggle>
                                  )}
                              />
                          </div>
                      )
                  }}
              </Form.List>
          </Form>
      </div>
  )
}


const spec_type_mapping = {
  others: '0', technical: '1', functional: '2'
}

function SpecificationDetails({}) {
  const {
    values,
    handleChange
  } = useFormikContext()
  const t = useTranslate()

  const [__, dispatch] = useDispatchAsyncActionWithNotify();

  const {response} = useAsyncWithCache({
    cacheId: '/products/get-default-specs-by-categories?categories=' + values.categories.toString(),
    apiInfo: {
      root: API_ROOT_URL,
      path:
        '/products/get-default-specs-by-categories?categories=' + values.categories.toString(),
      method: 'GET',
    }
  })
  // console.log({ response, values })
  const default_specifications = _.get(response, 'data.data', []);
  const specifications = values.specifications;

  const handleSortOrder = ({attribute_group_name, attribute_id, direction = 1}) => {
    let vals = {};
    if (!!attribute_group_name) vals.attribute_group_name = attribute_group_name;
    if (!!attribute_id) vals.attribute_id = attribute_id;
    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_specs_updateSortOrder_Api(),
          query: {
            ':id':
            values.product_id,
            ':direction': direction
          },
          values: vals
        }
      )
    )
    setUpdate(Date.now());
  };


  const [update, setUpdate] = useState()

  return (
      <div className="w-full space-y-3 relative">
          {[
                  {
                      name: "Specifications",
                      label: t(
                          'technical specs'
                      ),
                      spec_type: mapspec_type.technical
                  },
                  {
                      name: "Specifications",
                      label: t(
                          'functional specs'
                      ),
                      spec_type: mapspec_type.functional
                  }, {
                  name: "Specifications",
                  label: t(
                      'other specs'
                  ),
                  spec_type: mapspec_type.others
              },
              ].map((props) => {
              const data = [
                  ...specifications.reduce((result, spec) => {
                      if (String(spec.spec_type) === String(props.spec_type)) {
                          result.push(spec);
                          return result
                      }
                      return result
                  }, [])
              ];

              let _group_sort_order = 0;
              let attribute_group_names = _.chain(data)
                  .groupBy("attribute_group_name")
                  .map((value, key) => {
                      _group_sort_order += 1;
                      return ({
                          attribute_group_name: key === 'null' ? null : key,
                          group_sort_order: _group_sort_order,
                          data: value
                      });
                  })
                  .value()

              if (_.isEmpty(attribute_group_names)) attribute_group_names = [{
                  attribute_group_name: null,
                  group_sort_order: 0,
                  data: []
              }];

              return {
                  ...props,
                  attribute_group_names
              }
              }).map((e, i) => {
                  return (
                      <Toggle key={i} defaultValue={!!e.attribute_group_names.length}>
                          {( isToggle, toggle ) => (
                              <div className="flex flex-col gap-2">
                                  <div className="flex items-center flex-1 gap-2">
                                      <BsClipboardCheck className="text-primary-600"/>
                                      <span className="font-bold text-sm text-primary uppercase leading-tight">
                                          {t(e.label)}
                                      </span>
                                      <div className="flex-1 border-t"/>
                                      <Avatar
                                          size={22}
                                          className="flex flex-center background-200 hover:bg-primary text-color-400 hover:text-white cursor-pointer"
                                          onClick={() => {toggle()}}
                                          icon={ isToggle ? <BsCaretUpFill size={10} /> : <BsCaretDownFill size={10} /> }
                                      />
                                  </div>
                                  {isToggle && (
                                      e.attribute_group_names.map((eGroup, i) => (
                                          <Specifications
                                              key={i}
                                              item={{...e, ...eGroup}}
                                              handleSortOrder={handleSortOrder}
                                              product={values}
                                              options={default_specifications}
                                              update={update} setUpdate={setUpdate}
                                          />
                                      ))
                                  )}
                              </div>
                          )}
                      </Toggle>
                  )
              }
          )}
      </div>
  )
}

export default SpecificationDetails
