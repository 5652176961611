import {Editor, Node, Transforms} from 'slate'
import {createParagraphNode, ensureNotEmpty, getNode} from '../functions'
import {ElementTypes} from '../types'

const withCodeQuote = (editor) => {
  const {isVoid, insertBreak, deleteBackward, deleteForward} = editor

  editor.deleteBackward = (...args) => {
    const node = getNode(editor, ElementTypes.BLOCK_QUOTE)
    const [parentNode, parentPath] = Editor.parent(
      editor,
      editor.selection.focus.path
    )
    if (node && (isVoid(parentNode) || !Node.string(parentNode).length)) {
      Transforms.removeNodes(editor, {at: parentPath})
      ensureNotEmpty(editor)
    } else {
      deleteBackward(...args)
    }
  }

  editor.deleteForward = (...args) => {
    const node = getNode(editor, ElementTypes.BLOCK_QUOTE)
    const [parentNode, parentPath] = Editor.parent(
      editor,
      editor.selection.focus.path
    )
    if (node && (isVoid(parentNode) || !Node.string(parentNode).length)) {
      Transforms.removeNodes(editor, {at: parentPath})
      ensureNotEmpty(editor)
    } else {
      deleteForward(...args)
    }
  }

  editor.insertBreak = (...args) => {
    const node = getNode(editor, ElementTypes.BLOCK_QUOTE)
    if (node) {
      const selectedLeaf = Node.descendant(editor, editor.selection.anchor.path)
      if (selectedLeaf.text.length === editor.selection.anchor.offset) {
        if (!selectedLeaf.text.length) {
          const [__, path] = node ?? [null, null]
          Transforms.removeNodes(editor, {at: path})
        }
        Transforms.insertNodes(editor, createParagraphNode())
        return
      }
    }
    insertBreak(...args)
  }
  return editor
}

export default withCodeQuote
