import {Editor, Range, Transforms} from 'slate'
import {Null} from 'views/Shared'
import {ElementTypes} from '../types'
import {getNode} from './editor'

export function insertMention(editor, params) {
  const node = {
    children: [{text: ''}],
    type: ElementTypes.MENTION,
    ...(params ?? {}),
  }
  Transforms.insertNodes(editor, node, {select: true})
}

export function updateMention(editor, params) {
  const {selection} = editor
  if (!!selection) {
    const node = getNode(editor, ElementTypes.MENTION)
    if (node) {
      const [__, path] = node
      Transforms.setNodes(editor, params ?? {}, {at: path})
      const rows = Array.from(params?.rows ?? [])
      if (rows.length) {
        Transforms.insertNodes(
          editor,
          rows.map((text) => ({
            children: [{text}],
            className: 'ListItem',
            type: ElementTypes.SENTENCE,
          }))
        )
      }
    }
  }
}

export function editMentionContent(editor, content) {
  const {selection} = editor
  if (!!selection) {
    const node = getNode(editor, ElementTypes.MENTION)
    if (node) {
      const [__, path] = node
      Transforms.setNodes(
        editor,
        {
          content,
        },
        {at: path}
      )
    }
  }
}

export function removeMention(editor, options) {
  const {selection} = editor
  if (!!selection) {
    Transforms.removeNodes(editor, options)
  }
}

export function withSuggestion(
  editor,
  {onMatched = Null, onCancel = Null, prefix = '@'} = {}
) {
  if (!editor) return
  const {selection} = editor
  if (selection && Range.isCollapsed(selection)) {
    const [start] = Range.edges(selection)

    let before, wordBefore, beforeRange

    if (prefix) {
      before = Editor.before(editor, start, {unit: 'character'})
      const next_word = before && Editor.before(editor, start, {unit: 'word'})
      wordBefore =
        next_word && Editor.string(editor, {anchor: next_word, focus: start})

      const word = String(wordBefore ?? '')

      if (word.startsWith(prefix) || word.endsWith(` ${prefix}`)) {
        beforeRange = before && Editor.range(editor, before, start)
        onMatched({
          target: beforeRange,
          search: prefix,
        })
        return
      }
    }

    wordBefore = Editor.before(editor, start, {unit: 'word'})
    before = wordBefore && Editor.before(editor, wordBefore)
    beforeRange = before && Editor.range(editor, before, start)
    const beforeText = beforeRange && Editor.string(editor, beforeRange)
    const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/)
    const after = Editor.after(editor, start)
    const afterRange = Editor.range(editor, start, after)
    const afterText = Editor.string(editor, afterRange)
    const afterMatch = afterText.match(/^(\s|$)/)

    if (beforeMatch && afterMatch) {
      onMatched({
        target: beforeRange,
        search: beforeMatch[1],
      })
      return
    }
  }
  onCancel()
}
