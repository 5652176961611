import Loadable from 'react-loadable'
import LoadingPage from 'views/LoadingPage'

export const Login = Loadable({
  loader: () => import('./Login'),
  loading: LoadingPage
})

export const ForgetPassword = Loadable({
  loader: () =>
    import('./ForgetPassword'),
  loading: LoadingPage
})

export const Logout = Loadable({
  loader: () => import('./Logout'),
  loading: LoadingPage
})

export const ResetPassword = Loadable({
  loader: () =>
    import('./ResetPassword'),
  loading: LoadingPage
})
