import {ArrowRightOutlined} from '@ant-design/icons'
import {Button} from 'antd'
import classnames from 'classnames'
import Null from 'components/NullComponent'
import useTranslate from 'modules/local/useTranslate'
import React, {useEffect, useRef} from 'react'
import {PageCard} from '../Organization/components/PageCard'

const defaultRenderItem = (
  item = {}
) => (
  <PageCard
    style={{
      fontSize: '0.9em',
      width: '156px',
      height: '184px'
    }}
    {...item}
  />
)
export const MoreItem = ({
  onClick,
  handleScrollRight,
  background
}) => {
  const t = useTranslate()
  return (
    <>
      <div
        onClick={onClick}
        className="capitalize font-bold "
        style={{
          background:
            background || 'inherit',
          zIndex: 1,
          flexShrink: 0,
          height: '50px',
          width: '166px',
          scrollSnapAlign: 'end',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        {t('see more')}
      </div>
      <div
        className="capitalize font-bold text-color-300"
        style={{
          background: 'inherit',
          zIndex: 1,
          height: '50px',
          flexShrink: 0,
          width: '200px'
        }}
      />
      <div
        style={{
          flexShrink: 0,
          height: '100%',
          width: '0',
          position: 'sticky',
          right: '0.6em',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}>
        <Button
          style={{
            border: 'none'
          }}
          onClick={handleScrollRight}
          className="shadow flex-shrink-0"
          shape="circle"
          icon={<ArrowRightOutlined />}
        />
      </div>
    </>
  )
}
export const HorizontalList = ({
  onMoreClick = Null,
  currentIndex = 0,
                                 style = {},
                                 list = [],
  height = 'unset',
  background = '',
  renderItem = defaultRenderItem,
  renderMore,
  className
}) => {
  // const [
  // 	currentIndex,
  // 	setCurrentIndex
  // ] = useState(0);
  const scollerRef = useRef()
  const listRef = useRef([0])

  const handleScrollRight = () => {
    scollerRef.current.scrollLeft +=
      scollerRef.current.clientWidth
  }
  useEffect(() => {
    if (
      scollerRef.current &&
      listRef.current[currentIndex]
    ) {
      const scrollerRect = scollerRef.current.getBoundingClientRect()
      const currentRect = listRef.current[
        currentIndex
      ].getBoundingClientRect()

      scollerRef.current.scrollLeft =
        scollerRef.current.scrollLeft +
        currentRect.left -
        scollerRef.current.clientWidth /
          2 +
        listRef.current[currentIndex]
          .clientWidth /
          2 +
        -scrollerRect.left
    }
  }, [currentIndex])
  return (
    <div
      ref={scollerRef}
      style={{
        // scrollBehavior: 'smooth',
        height: height,
        background: background,
        // scrollSnapType: 'x mandatory',
        ...style
      }}
      className={classnames(
        'w-full items-center flex overflow-y-hidden overflow-x-auto',
        className
      )}>
      {list
        .filter(item => item)
        .map((item = {}, i) => (
          <div
            ref={node =>
              (listRef.current[
                i
              ] = node)
            }
            key={i}
            style={{
              scrollSnapAlign:
                i === 0
                  ? 'start'
                  : 'center'
            }}
            className="flex-shrink-0 p-2">
            {renderItem(item, i)}
          </div>
        ))}
      {renderMore ? (
        renderMore()
      ) : (
        <MoreItem
          onClick={onMoreClick}
          background={background}
          handleScrollRight={
            handleScrollRight
          }
        />
      )}
    </div>
  )
}
