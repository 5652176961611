import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {ValidateComponent} from './routesValidate'

export default function RouteWithSubRoutes({
                                             routes = [],
                                             component: C,
                                             render,
                                             meta,
                                             ...route
                                           }) {
  console.log(1111111111131, route)
  return (
    <Route
      path={route.path}
      {...route}
      render={props => {
        if (render) {
          return (
            <ValidateComponent
              {...route}
              meta={meta}
            >
              {render(props)}
            </ValidateComponent>
          )
        }

        console.log(111111111113, route, meta)
        return (
          // pass the sub-routes down to keep nesting
          <ValidateComponent
            {...route}
            meta={meta}
          >
            <C
              {...props}
              children={
                <Switch
                  location={
                    props.location
                  }>
                  {routes.map(
                    (route, i) => {
                      return (
                        <RouteWithSubRoutes key={i}
                                            {...route}
                        />
                      )
                    }
                  )}
                  {/* <Route
										component={
											NoFoundPage
										}
									/> */}
                </Switch>
              }
            />
          </ValidateComponent>
        )
      }}
    />
  )
}
