import {MessageOutlined} from '@ant-design/icons'
import {Avatar, Button, Form, Input, Modal, notification} from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import {useAppConfig} from 'modules/local'
import React, {useContext, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import {create_message_sendRequestToOrg_Api} from "../../../apis"
import {organizationModel, userModel} from '../../../apis/model'
import EmptyHolder from "../../../components/EmptyHolder"
import {API_ROOT_URL} from '../../../envs/_current/config'
import {createAsyncAction} from '../../../modules/asyncCache'
import {AsyncByActionWithNotify} from '../../../modules/asyncCache/components/Async'
import useDispatchAsyncAction from "../../../modules/asyncCache/useDispatchAsyncAction"
import useTranslate from '../../../modules/local/useTranslate'
import ServerContext from '../../../views/Server/ServerContext'
import {useRequiredLogin} from "../../Server/useRequiredLogin"
import {ImHeadphones} from "react-icons/im";
import {LoginContext} from "../../../components/LoginContext";
import Toggle from "../../../components/Toggle";
import {ReactReduxContext} from "react-redux";

const SubmitRequirement = ({
                             item,
                             textAreaRef,
                             children
                           }) => {
  const t = useTranslate()
  const [
    {response, isLoading},
    asyncSendRequestToProvider
  ] = useDispatchAsyncAction({
    onSuccess: (__, res) => {
      notification.success({
        message: t(
          'Your message have been sent.'
        ),
      })
    },
    onError: (...args) => {
      const [
        error,
        errorObj = {
          error_code: 'System error!',
          error: '-1'
        }
      ] = args;

      notification.error({
        message:
          typeof errorObj.error_code ===
          'string'
            ? errorObj.error_code
            : JSON.stringify(
            errorObj.error_code
            ),
        description:
          typeof errorObj.error ===
          'string'
            ? errorObj.error
            : JSON.stringify(
            errorObj.error
            ),
        duration: 3,
        placement: 'topRight'
      })
    }
  })
  const requireLogin = useRequiredLogin()
  const handleSendRequestToProvider = () => {

    const node = textAreaRef.current;
    if (node) {
      asyncSendRequestToProvider(
        createAsyncAction({
          apiInfo: create_message_sendRequestToOrg_Api(),
          query: {
            ':organization_id':
            item.idname
          },
          values: {
            content: _.get(node, 'resizableTextArea.textArea.value')
          }
        })
      )
    } else {
      console.error(
        '#messages not found'
      )
    }
  }

  const handleAnonymousSendRequestToProvider = (values) => {
    asyncSendRequestToProvider(
      createAsyncAction({
        apiInfo: create_message_sendRequestToOrg_Api(),
        query: {
          ':organization_id':
          item.idname
        },
        values: values
      })
    )
  }
  return children({
    handleSendRequestToProvider,
    handleAnonymousSendRequestToProvider,
    isLoading
  })
}

export const QuickContactBox = React.memo(
  ({prefixCls}) => {
    const t = useTranslate()
    const {item} = useContext(
      ServerContext
    );
    const login = useContext(LoginContext);
    const history = useHistory();

    const textAreaRef = useRef();

    const appConfig = useAppConfig()


    const role_titles = _.get(appConfig, 'role_titles', []);

    return (
      <div className={classNames(prefixCls, "rounded background-100 space-y-3 w-full h-full")}>
        <div className="leading-relaxed font-bold">
          {t('contact provider')}
        </div>
        {
          _.get(item, 'contacts.length') > 0
            ? (
              <React.Fragment>
                <div className="space-y-3">
                  <div className="flex items-center">
                    <Avatar
                      shape="circle"
                      size="large"
                      src={
                        _.get(
                          item,
                          'contacts.0.contact_member.user.avatar'
                        )
                      }
                      className="mr-3 flex-shrink-0 text-blue-600 bg-blue-200"/>
                    <div className="flex-col">
                      <div className="font-semibold">
                        {
                          _.has(item, 'contacts.0.prefix_name') && !_.isEmpty(_.get(item, 'contacts.0.prefix_name')) && (
                            <span className="pr-1">{t(_.get(item, 'contacts.0.prefix_name'))}</span>
                          )
                        }
                        {
                          _.has(item, 'contacts.0.name') && !_.isEmpty(_.get(item, 'contacts.0.name')) && (
                            <span className="pr-1">{_.get(item, 'contacts.0.name')}</span>
                          )
                        }
                      </div>
                      <div className="text-color-300 text-sm mb-2">
                        {
                          role_titles[
                            _.get(
                              item,
                              'contacts.0.role_title'
                            )
                            ]
                        }
                      </div>

                        <AsyncByActionWithNotify
                            onSuccess={([
                                            __,
                                            res
                                        ]) => {
                                const conversationId = _.get(
                                    res,
                                    'response.data.id'
                                )
                                history.push(
                                    `/messages?conversationId=${conversationId}`
                                )
                            }}>
                            {(
                                data,
                                dispatch
                            ) => {
                                return (
                                    <div
                                        onClick={() => {
                                            dispatch(
                                                createAsyncAction(
                                                    {
                                                        apiInfo: {
                                                            backend: ``,
                                                            root: API_ROOT_URL,
                                                            path:
                                                                '/messages/conversation/create',
                                                            method:
                                                                'POST'
                                                        },
                                                        values: {
                                                            organization_id: organizationModel.getId(
                                                                item
                                                            ),
                                                            receiver_id: userModel.getId(
                                                                _.get(
                                                                    item,
                                                                    'contacts.0.contact_member.user',
                                                                    _.get(
                                                                        item,
                                                                        'owner'
                                                                    )
                                                                )
                                                            )
                                                        }
                                                    }
                                                )
                                            )
                                        }}
                                        className="text-primary block cursor-pointer 1">
                                        <MessageOutlined/>{' '}
                                        {t(
                                            'leave a message'
                                        )}
                                    </div>
                                )
                            }}
                        </AsyncByActionWithNotify>

                    </div>



                  </div>
                </div>
                <div className="space-y-3 flex flex-col">
                  <Input.TextArea
                    id="messages"
                    className="block"
                    ref={textAreaRef}
                    rows={4}
                    placeholder={t(
                      'Enter message text with minimum requirement of 20, maximum of 4000 characters'
                    )}/>
                  <SubmitRequirement
                    textAreaRef={textAreaRef}
                    item={item}>
                    {({
                        handleSendRequestToProvider,
                        handleAnonymousSendRequestToProvider,
                        isLoading
                      }) => {


                      return (
                        <React.Fragment>
                          {
                            !!login ? (
                              <Button
                                onClick={() =>
                                  handleSendRequestToProvider()
                                }
                                loading={
                                  isLoading
                                }
                                type="primary"
                                className="self-end rounded-full">
                                {t(
                                  'Submit requirement'
                                )}
                              </Button>
                            ) : (
                              <Toggle>
                                {(
                                  isToggle,
                                  toggle
                                ) => {

                                  const node = textAreaRef.current;
                                  let content = "";
                                  if (node) {
                                    content = _.get(node, 'resizableTextArea.textArea.value');
                                  }
                                  return (
                                    <React.Fragment>
                                      {
                                        !!!isToggle ? (
                                          <Button
                                            onClick={() =>
                                              toggle()
                                            }
                                            loading={
                                              isLoading
                                            }
                                            type="primary"
                                            className="self-end">
                                            {t(
                                              'Submit requirement'
                                            )}
                                          </Button>
                                        ) : (
                                          <ReactReduxContext.Consumer>
                                            {({store}) => (
                                              <Modal
                                                destroyOnClose
                                                footer={null}
                                                title={t('contact information')}
                                                maskClosable
                                                visible={isToggle}
                                                onCancel={() => {
                                                  toggle()
                                                }}>
                                                <div className="flex flex-col space-y-3">
                                                  <div>
                                                    {
                                                      t('Leave your information so we can contact you again')
                                                    }
                                                  </div>

                                                  <Form className="space-y-3"
                                                    name="contactToProvider"
                                                    onFinish={(values) => {
                                                      handleAnonymousSendRequestToProvider(values)
                                                    }}
                                                    initialValues={{
                                                      content,
                                                      sender_name: "",
                                                      sender_email: "",
                                                      sender_phone: "",
                                                    }}
                                                    {...{
                                                      labelCol: {span: 8},
                                                      wrapperCol: {span: 16},
                                                    }}
                                                    scrollToFirstError>

                                                    <Form.Item
                                                      name="content"
                                                      label={t('content')}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: 'required!'
                                                        }
                                                      ]}>
                                                      <Input.TextArea
                                                        rows={4}
                                                        style={{
                                                          width: '100%',
                                                          // minHeight: 150
                                                        }}
                                                      />
                                                    </Form.Item>

                                                    <Form.Item
                                                      name="sender_phone"
                                                      label={t('phone')}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: t('required!')
                                                        }
                                                      ]}
                                                    >
                                                      <Input
                                                        placeholder={t('phone')}
                                                      />
                                                    </Form.Item>

                                                    <Form.Item
                                                      name="sender_email"
                                                      label={t("email")}
                                                      rules={[
                                                        {
                                                          type: 'email',
                                                          message: t(
                                                            'invalid email address'
                                                          )
                                                        },
                                                        {
                                                          required: true,
                                                          message: t('required!')
                                                        }
                                                      ]}>
                                                      <Input
                                                        inputMode="email"
                                                      />
                                                    </Form.Item>

                                                    <Form.Item
                                                      name="sender_name"
                                                      label={t('name')}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message: t('required!')
                                                        }
                                                      ]}>
                                                      <Input/>
                                                    </Form.Item>

                                                    <div className="flex items-center">
                                                      <div className="flex-1"/>
                                                      <Form.Item>
                                                        <Button
                                                          type="primary"
                                                          htmlType="submit">
                                                          {t('send')}
                                                        </Button>
                                                      </Form.Item>
                                                    </div>
                                                  </Form>

                                                </div>
                                              </Modal>
                                            )}
                                          </ReactReduxContext.Consumer>
                                        )
                                      }
                                    </React.Fragment>
                                  )
                                }
                                }

                              </Toggle>
                            )
                          }


                        </React.Fragment>
                      )
                    }}
                  </SubmitRequirement>
                </div>
              </React.Fragment>
            )
            : (
              <React.Fragment>
                <EmptyHolder
                  icon={
                    () => (
                      <div
                        style={{
                          fontSize: '3rem',
                          color: 'var(--text-color-300)'
                        }}>
                        <ImHeadphones/>
                      </div>
                    )
                  }
                  title={null} subTitle={null}/>
              </React.Fragment>
            )
        }
      </div>
    )
  }
);