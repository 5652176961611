import React from 'react'
import Loadable from 'react-loadable'
import { DelayRender } from 'views/Discovery/DelayRender'
import LoadingPage from 'views/LoadingPage'
export const BookmarkLazy = Loadable({
  loader: () => import('./Bookmark'),
  loading: LoadingPage
})
export const Bookmark = props => (
  <DelayRender>
    <BookmarkLazy {...props} />
  </DelayRender>
)
