import Icon from '@ant-design/icons'
import {Image} from 'antd'
import _ from 'lodash'
import React, {useContext} from 'react'
import {FaFacebookF, FaShareAlt, FaTwitter} from 'react-icons/fa'
import {Link, NavLink} from 'react-router-dom'
import {organizationModel} from '../../apis/model'
import PhotoSvg from "../../components/Svgs/PhotoSvg"
import {Photo} from "../../conts/imageBase64"
import {callNativeShare} from '../../helpers'
import useTranslate from '../../modules/local/useTranslate'
import {HeaderButton} from '../../views/Organization/components/PageHeader'
import {validateServiceType} from "./functions/validateServiceType"
import {OrganizationContext} from './OrganizationContext'
import {IoCheckmarkDoneOutline, IoLocationOutline} from "react-icons/io5";
import {SubDomainContext} from "../SubDomain/SubDomainContext";
import {HiOutlineLink} from "react-icons/hi";

export const Header = React.memo(
  function Header({url}) {
    const t = useTranslate()
    const {
      organization: item
    } = useContext(OrganizationContext)

    const shareLink = organizationModel.getLinkToShare(
      item
    );

    return React.useMemo(
      () => (
        <header className="background z-30 w-full relative">
          <nav className="flex flex-wrap flex-col md:flex-row items-center ">
            <div className="w-full flex-wrap md:flex-no-wrap  items-start flex mb-4 md:mb-0">
              <div
                style={{
                  minWidth: 300
                }}
                className="flex-1 ">
                <div
                  style={{zIndex: 11}}
                  className="w-full relative HomeHeader">
                  <div className="background overflow-hidden relative w-full h-auto"
                       style={{
                         zIndex: 11,
                       }}>
                    <div id="shadow" className="absolute w-full top-0 left-0 object-cover h-auto"
                         style={{
                           backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 20%,  var(--background) 100%)`
                         }}
                    />
                    <div className="flex p-3 w-full flex-col">
                      <div className="flex">
                        <div className="table">
                          <Image preview={false}
                                 width={80}
                                 height={80}
                                 src={organizationModel.getFullAvatarUrl(
                              item
                            ) || "#"}
                                 className="object-contain w-full h-full rounded-lg border-white table"
                                 placeholder={<PhotoSvg/>}
                                 fallback={Photo}
                          />
                        </div>

                        <div className="flex flex-col px-3">
                          <Link
                            title={
                              item.title
                            }
                            to={organizationModel.getLinkToDetail(
                              item
                            )}
                            className="">
                            <h2 className="title-font text-base md:text-xl font-semibold hover:text-primary">
                              {item.title}
                            </h2>
                          </Link>

                          {
                            !!item.sub_domain && (
                              <SubDomainContext.Consumer>
                                {
                                  ({isSubDomain}) => (
                                    <a
                                      href={
                                        [
                                          window.location.protocol,
                                          "//",
                                          !!isSubDomain ? "" : item.idname + ".",
                                          window.location.host
                                        ].join("")
                                      }
                                      className="flex flex-1 text-sm space-x-1 leading-tight text-primary">
                                      <HiOutlineLink/>
                                      <div>
                                        {
                                          [
                                            !!isSubDomain ? "" : item.idname + ".",
                                            window.location.host
                                          ].join("")
                                        }
                                      </div>
                                    </a>
                                  )
                                }
                              </SubDomainContext.Consumer>
                            )
                          }
                          <div className="flex flex-col text-sm">
                            {!!_.get(
                              item,
                              'locations[0]'
                            ) && (
                              <div className="flex-row items-start">
                                  <React.Fragment>
                                      <IoLocationOutline
                                          className="text-primary mr-2"
                                      />
                                      <span>
                        {
                            [
                                _.get(
                                    item,
                                    'locations.0.address',
                                    null
                                ),
                                [
                                    _.get(
                                        item,
                                        'locations.0.ward.type',
                                        null
                                    ),
                                    _.get(
                                        item,
                                        'locations.0.ward.name',
                                        null
                                    )
                                ].filter(el => !!el).join(" "),
                                [
                                    _.get(
                                        item,
                                        'locations.0.district.type',
                                        null
                                    ),
                                    _.get(
                                        item,
                                        'locations.0.district.name',
                                        null
                                    )
                                ].filter(el => !!el).join(" "),
                                [
                                    _.get(
                                        item,
                                        'locations.0.province.type',
                                        null
                                    ),
                                    _.get(
                                        item,
                                        'locations.0.province.name',
                                        null
                                    )
                                ].filter(el => !!el).join(" "),
                                _.get(
                                    item,
                                    'locations.0.country.common_name',
                                    null
                                )
                            ].filter(el => !!el).join(", ")
                        }
                      </span>
                                  </React.Fragment>
                              </div>
                            )}

                            {item.tax_code && (
                              <div className="flex-row items-start">
                                  <React.Fragment>
                                      <IoCheckmarkDoneOutline
                                          className="text-primary mr-2"/>
                                      <span>
                        {t('tax code')}{' '}
                                          <b className="font-semibold">
                          {
                              item.tax_code
                          }
                        </b>
                      </span>
                                  </React.Fragment>
                              </div>
                            )}
                            {item.certified > 0 && (
                              <div className="flex-row items-start">
                                  <React.Fragment>
                                      <IoCheckmarkDoneOutline
                                          className="text-primary mr-2"/>
                                      <span>
                        <b className="font-bold">
                          {t(
                              'verified'
                          )}{' '}
                        </b>
                                          {t('provider')}
                      </span>
                                  </React.Fragment>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="relative rounded-lg w-full text-base mt-1 flex justify-end items-center">
                        <div className="flex w-full space-x-2 items-center ">
                          {
                            [
                              {
                                id: 'facebook',
                                icon: <Icon
                                  component={
                                    FaFacebookF
                                  }/>
                              },
                              {
                                id: 'twitter',
                                icon: <Icon
                                  component={
                                    FaTwitter
                                  }/>
                              }
                            ].map((e, i) => !!_.get(item, e.id) && (
                              <HeaderButton key={i}
                                            component="a"
                                            href={_.get(item, e.id)}
                                            target="_blank"
                                            width={34}
                                            height={34}
                                            icon={e.icon}
                              />
                            ))
                          }
                          <HeaderButton
                            onClick={() => {
                              callNativeShare(
                                {
                                  title: organizationModel.getTitle(
                                    item
                                  ),
                                  url
                                }
                              )
                            }}
                            width={34}
                            height={34}
                            icon={
                              <Icon
                                component={
                                  FaShareAlt
                                }></Icon>
                            }
                          />
                        </div>

                        {
                          !_.isEmpty(_.get(item, 'contacts.0.phone', null))
                          && (
                            <div
                              className="py-3 text-lg flex flex-1 float-right md:flex-none justify-center lg:justify-end sm:flex-col lg:flex-row">
                              <button
                                style={{
                                  minWidth: 150
                                }}
                                className="truncate flex-1 sm:flex-none py-1 md:flex-1 lg:flex-none leading-tight border max-w-md  px-3 rounded text-primary hover:border-2 border-primary flex flex-col items-center"
                                data-effect="ripple">

                                <div className="font-bold text-sm">
                                  {t('Call')}
                                  <a className="font-bold px-1 tracking-tight"
                                     href={"tel:" + _.get(item, 'contacts.0.phone')}>
                                    {
                                      _.get(item, 'contacts.0.phone')
                                    }
                                  </a>
                                </div>
                              </button>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <nav className="md:mr-auto py-1 flex flex-row text-base overflow-auto w-full px-4">
              <NavLink
                to={url}
                exact
                activeClassName="border-primary text-primary border-b "
                className="flex-shrink-0 uppercase mt-2 mr-3 px-4 py-2 font-medium background-100 hover:background-200 hover:text-primary no-underline">
                {t('home')}
              </NavLink>
              {
                validateServiceType(_.get(item, 'service_type', '0000'), "product") && (
                  <NavLink
                    to={url + `/products`}
                    activeClassName="border-primary text-primary border-b"
                    className="flex-shrink-0 uppercase mt-2 mr-3 px-4 py-2 font-medium background-100 hover:background-200 hover:text-primary no-underline">
                    {t('products')}
                  </NavLink>
                )
              }
              {
                validateServiceType(_.get(item, 'service_type', '0000'), "channel") && (
                  <NavLink
                    to={url + `/news`}
                    activeClassName="border-primary text-primary border-b"
                    className="flex-shrink-0 uppercase mt-2 mr-3 px-4 py-2 font-medium background-100 hover:background-200 hover:text-primary no-underline">
                    {t('news')}
                  </NavLink>
                )
              }
              {
                validateServiceType(_.get(item, 'service_type', '0000'), "dataset") && (
                  <NavLink
                    to={url + `/data`}
                    activeClassName="border-primary text-primary border-b"
                    className="flex-shrink-0 uppercase mt-2 mr-3 px-4 py-2 font-medium background-100 hover:background-200 hover:text-primary no-underline">
                    {t('data')}
                  </NavLink>
                )
              }

              <NavLink
                to={url + `/about-us`}
                activeClassName="border-primary text-primary border-b"
                className="flex-shrink-0 uppercase mt-2 mr-3 px-4 py-2 font-medium background-100 hover:background-200 hover:text-primary no-underline">
                {t('about us')}
              </NavLink>

              {Boolean(
                _.get(
                  item,
                  'contacts.length'
                )
              ) && (
                <NavLink
                  to={url + `/contact`}
                  activeClassName="border-primary text-primary border-b"
                  className="flex-shrink-0 uppercase mt-2 mr-3 px-4 py-2 font-medium background-100 hover:background-200 hover:text-primary no-underline">
                  {t('contact')}
                </NavLink>
              )}
            </nav>
          </nav>
        </header>
      ),
      [item, shareLink, t, url]
    )
  }
)
