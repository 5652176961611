import {Node, Transforms} from 'slate'
import {getNextNode, getPrevNode} from '../functions'
import {ElementTypes} from '../types'

const withImages = (editor) => {
  const {isVoid, deleteForward, deleteBackward} = editor

  editor.isVoid = (element) =>
    element.type === ElementTypes.IMAGE ? true : isVoid(element)

  editor.deleteBackward = (unit) => {
    const {selection} = editor
    const [prevNode, prevPath] = getPrevNode(editor) ?? [null, null]
    if (
      selection.anchor.offset === 0 &&
      [ElementTypes.IMAGE].includes(prevNode?.type)
    ) {
      Transforms.removeNodes(editor, {at: prevPath})
      return
    }
    deleteBackward(unit)
  }

  editor.deleteForward = (unit) => {
    const {selection} = editor
    const [rel_node, rel_path] = getNextNode(editor) ?? [null, null]
    if ([ElementTypes.IMAGE].includes(rel_node?.type)) {
      const selectedLeaf = Node.descendant(editor, selection.anchor.path)
      if (selection.anchor.offset === selectedLeaf?.text?.length) {
        Transforms.removeNodes(editor, {at: rel_path})
        return
      }
    }
    deleteForward(unit)
  }

  return editor
}

export default withImages
