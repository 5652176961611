import {Button, Result, Spin} from 'antd'
import {auth_depositDatasetSubscriptionWithVNPayt_Api, auth_subscribeDataset_Api, auth_validateDataset_Api} from 'apis'
import Modal from 'components/Modal/Modal'
import logParams from 'helpers/logParams'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useAsync from 'modules/asyncCache/useAsync'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import React, {useEffect} from 'react'
import formatCurrency from '../Billing/components/formatCurrency'

export const SubscribeModal = ({
  item,
  onClose,
  visible
}) => {
  const t = useTranslate()
  const {
    response,
    isLoading,
    success,
    errorMessages,
    result
  } = useAsync({
    apiInfo: auth_validateDataset_Api,
    query: {
      ':id': item.idname
    }
  })
  const [
    subscribeDatasetAsync,
    subscribeDatasetDispath
  ] = useDispatchAsyncActionWithNotify()
  const [
    payAndsubscribeChanneldata,
    payAndubscribeChannelDispath
  ] = useDispatchAsyncActionWithNotify()
  const gateway_checkout_url = _.get(
    payAndsubscribeChanneldata,
    'response.data.checkout_url'
  )
  useEffect(() => {
    if (gateway_checkout_url) {
      window.location.href = gateway_checkout_url
    }
  }, [gateway_checkout_url])
  return (
    <Modal
      title={t('subscribe channel')}
      destroyOnClose
      onCancel={onClose}
      open={visible}
      footer={[
        <Button
          disabled={!success}
          key="back"
          onClick={onClose}>
          {t('cancel')}
        </Button>,
        success &&
          !_.get(
            logParams(response),
            'data.deposit_required'
          ) && (
            <Button
              disabled={!success}
              key="submit"
              type="primary"
              loading={
                subscribeDatasetAsync.isLoading
              }
              onClick={() => {
                subscribeDatasetDispath(
                  createAsyncAction({
                    apiInfo: auth_subscribeDataset_Api,
                    query: {
                      ':id': item.idname
                    }
                  })
                )
              }}>
              {t('subscribe')}
            </Button>
          ),
        success &&
          _.get(
            logParams(response),
            'data.deposit_required'
          ) && (
            <Button
              disabled={!success}
              key="submit"
              type="primary"
              loading={
                payAndsubscribeChanneldata.isLoading
              }
              onClick={() => {
                payAndubscribeChannelDispath(
                  createAsyncAction({
                    apiInfo: auth_depositDatasetSubscriptionWithVNPayt_Api,
                    query: {
                      ':id': item.idname
                    },
                    values: {
                      amount: _.get(
                        response,
                        'data.deposit_amount'
                      )
                    }
                  })
                )
              }}>
              {t('Pay and subscribe')}
            </Button>
          )
      ]}>
      <Spin spinning={isLoading}>
        <div>
          {success &&
            !_.get(
              logParams(response),
              'data.deposit_required'
            ) && (
              <div className="verticalList__large">
                <h2 className="text-2xl font-bold">
                  {t(
                    'subscribing this channel will cost you'
                  )}{' '}
                  <span className="color-primary">
                    {formatCurrency(
                      item.price
                    )}
                  </span>
                  {', '}
                  <span className="lowercase">
                    {t(
                      item.billing_period
                    )}
                  </span>
                </h2>

                <div className="text-color-300 text-sm">
                  {t(
                    'By clicking Subscribe, you agree to {term1} and {term2}.',
                    {
                      term1: (
                        <a
                          className="hover:underline color-primary"
                          href="#">
                          {t(
                            'terms of service'
                          )}
                        </a>
                      ),
                      term2: (
                        <a
                          className="hover:underline color-primary"
                          href="#">
                          {t(
                            'privacy policy'
                          )}
                        </a>
                      )
                    }
                  )}
                </div>
              </div>
            )}
          {success &&
            _.get(
              logParams(response),
              'data.deposit_required'
            ) && (
              <div className="verticalList__large">
                <h2 className="text-2xl font-bold">
                  {t(
                    'You need {amount} to be able subscribe this channel.',
                    {
                      amount: (
                        <span className="color-primary">
                          {formatCurrency(
                            _.get(
                              response,
                              'data.deposit_amount'
                            )
                          )}
                        </span>
                      )
                    }
                  )}
                </h2>

                <div className="text-color-300 text-sm">
                  {t(
                    'By clicking Subscribe, you agree to {term1} and {term2}.',
                    {
                      term1: (
                        <a
                          className="hover:underline color-primary"
                          href="#">
                          {t(
                            'terms of service'
                          )}
                        </a>
                      ),
                      term2: (
                        <a
                          className="hover:underline color-primary"
                          href="#">
                          {t(
                            'privacy policy'
                          )}
                        </a>
                      )
                    }
                  )}
                </div>
              </div>
            )}
          {errorMessages && (
            <Result
              type="error"
              message="validate error"></Result>
          )}
        </div>
      </Spin>
    </Modal>
  )
}
