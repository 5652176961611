import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {AiOutlineFileSearch} from 'react-icons/ai'
import {FaBoxArchive} from "react-icons/fa6";

const EmptyHolder = ({
                       icon = () => (
                           <FaBoxArchive size={50} style={{ color: '#efefef' }}/>
                       ),
                       title = 'no data',
                       subTitle = 'no data is available now',
                       children
                     }) => {
  const t = useTranslate()
  return (
    <div className="pb-8 pt-6 px-4 m-3 flex flex-col space-y-2 items-center m-auto max-w-2xl text-center">
      {icon && (
          icon()
      )}
      {title && (
        <div className="font-semibold text-base text-color-300 italic leading-snug">
          {typeof title === 'string'
            ? t(title)
            : title()}
        </div>
      )}
      {subTitle && (
        <div className="text-xs text-color-500 italic leading-tight">
          {typeof subTitle === 'string'
            ? t(subTitle)
            : subTitle()}
        </div>
      )}
      {
        !!children && (
          children
        )
      }
    </div>
  )
}

export const renderEmptyHolder = (withBorder = true) =>
  withBorder ? (
    <EmptyHolder/>
  ) : (
    <div className="w-full justify-center items-center">
      <EmptyHolder/>
    </div>
  )

export const renderAdminOnlyHolder = (withBorder = true) =>
  withBorder ? (
    <EmptyHolder title={'Admin-Exclusive Content'} subTitle={'This section is accessible only to admins'}/>
  ) : (
    <div className="w-full justify-center items-center">
      <EmptyHolder title={'Admin-Exclusive Content'} subTitle={'This section is accessible only to admins'}/>
    </div>
  )
export default EmptyHolder
