import {getId, getType} from 'apis/model/base'
import _ from 'lodash'
import React from 'react'
import HostActionRow from 'views/AppLocals/Host/Settings/HostActionRow'
import {Null} from 'views/Shared'
import {HostActionTypes} from '../HostContext'

const renderHostRequest =
  (handleAction = Null, renderActions = undefined, {canLeave} = {}) =>
    ({host, status, deleted, ...item}) => {
      if (!!_.get(item, 'deleted')) {
        return null
      }

      const owner = _.get(item, 'owner') || {}

      const [id, prop] = [getId(owner), getType(owner)]

      const onLeave = () => {
        handleAction(HostActionTypes.LEAVE, {
          id,
          prop,
          host_id: getId(item),
        })
      }

      return (
        <HostActionRow
          {...item}
          key={getId(item)}
          item={owner}
          subItem={host}
          status={status}
          canLeave={canLeave}
          params={{
            id,
            prop,
            host_id: getId(item),
          }}
          Actions={
            _.isFunction(renderActions)
              ? () =>
                renderActions({
                  onLeave,
                })
              : undefined
          }
        />
      )
    }

export const renderHostRequests = (list, request_status, footer) => {
  if (_.isEmpty(list)) return null
  const renderItem = renderHostRequest(request_status)
  const dataSource = Array.from(
    list.filter(
      ({deleted, status}) =>
        !Boolean(deleted) && Number(request_status) === Number(status)
    )
  )
  return (
    <React.Fragment>
      {dataSource.map(renderItem)}
      {footer}
    </React.Fragment>
  )
}

export default renderHostRequest
