import _ from 'lodash'
import {SPC} from 'modules/socket/types'
import {LOGIN_SUCCESS} from 'redux/auth/types'
import {UPDATE_APP} from './types'

const INITIAL_STATE = { online: true }
export default function (
  state = INITIAL_STATE,
  action
) {
  if (action.type === UPDATE_APP) {
    const {
      payload = {}
    } = action
    return {
      ...state,
      ...payload
    }
  }
  if (action.type === LOGIN_SUCCESS) {
    const {
      payload = {}
    } = action
    return {
      ...state,
      count_notifications: _.get(payload, 'user.count_notifications'),
      count_msg_notifications: _.get(payload, 'user.count_msg_notifications')
    }
  }
  if (action.type === 'socket/' + SPC) {
    const {
      socket = {}
    } = action
    return {
      ...state,
      count_notifications: _.get(socket, 'payload.count_notifications'),
      count_msg_notifications: _.get(socket, 'payload.count_msg_notifications')
    }
  }
  return state
}
