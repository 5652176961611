import { Radio } from 'antd'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import React from 'react'

export const getDiscoveryRadioOption = ({ ...option }) => ({
  ...option,
  style: { whiteSpace: 'nowrap', flex: '1 1' },
})

export const DiscoveryRadioGroup = ({
  name,
  value,
  options,
  onChange,
  ...props
}) => (
  <LayoutContext.Consumer>
    {({ isSm }) => (
      <Radio.Group
        name={name}
        value={value}
        options={options}
        optionType="button"
        buttonStyle="solid"
        onChange={onChange}
        {...props}
      />
    )}
  </LayoutContext.Consumer>
)
