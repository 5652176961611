import { auth_subscribe_Api } from 'apis'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { createAsyncAction } from '../asyncCache'
import { createMessaging } from './init-fcm'

function isTokenSentToServer() {
  return (
    window.localStorage.getItem(
      'sentToServer'
    ) === '1'
  )
}
function requestPermission(cb) {
  console.log(
    'Requesting permission...'
  )
  // [START request_permission]
  Notification.requestPermission().then(
    permission => {
      if (permission === 'granted') {
        console.log(
          'Notification permission granted.'
        )
        cb && cb()
      } else {
        console.log(
          'Unable to get permission to notify.'
        )
      }
    }
  )
  // [END request_permission]
}
function setTokenSentToServer(sent) {
  window.localStorage.setItem(
    'sentToServer',
    sent ? '1' : '0'
  )
}

const FireBase = () => {
  const dispath = useDispatch()
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register(
          '/firebase-messaging-sw.js'
        )
        .then(function(registration) {
          console.log(
            'Registration successful, scope is:',
            registration.scope
          )
        })
        .catch(function(err) {
          console.log(
            'Service worker registration failed, error:',
            err
          )
        })
    }
    const messaging = createMessaging()
    if (messaging) {
      requestPermission(
        async function() {
          const token = await messaging.getToken()
          if (token) {
            if (
              !isTokenSentToServer()
            ) {
              console.log(
                'Sending token to server...'
              )
              dispath(
                createAsyncAction({
                  apiInfo: auth_subscribe_Api,
                  values: {
                    token: token,
                    topic: 'global'
                  }
                })
              )

              setTokenSentToServer(true)
            } else {
              console.log(
                "Token already sent to server so won't send it again " +
                  'unless it changes'
              )
            }
          } else {
            // Show permission request.
            console.log(
              'No registration token available. Request permission to generate one.'
            )
            // Show permission UI.

            setTokenSentToServer(false)
          }
        }
      )

      navigator.serviceWorker.addEventListener(
        'message',
        message => console.log(message)
      )
      messaging.onMessage(payload =>
        console.log(
          'Message received. ',
          payload
        )
      )
    }
  }, [dispath])
  return null
}

export default FireBase
