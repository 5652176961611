import Icon, {SearchOutlined} from '@ant-design/icons';
import {Avatar, Button, Checkbox, Input, Modal, Select, Spin} from 'antd';
import ImageLoader from 'components/ImageLoader';
import {API_ROOT_URL} from 'envs/_current/config';
import preventDefaultEvent from 'helpers/preventDefaultEvent';
import _ from 'lodash';
import {createAsyncAction} from 'modules/asyncCache';
import useDispatchAsyncAction from 'modules/asyncCache/useDispatchAsyncAction';
import useTranslate from 'modules/local/useTranslate';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {MdClose} from 'react-icons/md';
import {useToggle} from 'react-use';
import classNames from 'classnames'

export const SelectCategories = ({type, selected: externalSelected = [], onChange, categoryOptions = []}) => {
    const [selected, setSelected] = useState(externalSelected);
    useEffect(() => {
        setSelected(externalSelected)
    }, [externalSelected])
    const entitesRef = useRef({
        ...selected.reduce((result, value) => {
            result[value] = {
                id: value, name: value
            };
            return result;
        }, {}), ...categoryOptions.reduce((result, value) => {
            result[value.id] = value;
            return result;
        }, {})
    });
    const [keyword, setkeyword] = useState('');
    const t = useTranslate();
    const handleChange = (id) => {
        const findIndex = selected.findIndex(item => item === id);
        let newValues
        if (findIndex > -1) {
            newValues = (selected.filter((item, i) => findIndex !== i));
            setSelected(newValues)
            // return onChange(newValues)
        } else {
            newValues = [...selected, id];
            setSelected(newValues);
        }
        // return onChange(newValues)
    };
    const [isToggle, toggle] = useToggle();
    const [{result = [], isLoading}, dispatch] = useDispatchAsyncAction();
    entitesRef.current = {
        ...entitesRef.current,
        ...result.reduce((result, value) => {
            result[value.id] = value;
            return result;
        }, {})
    };
    const options = selected.map(id => {
        const item = entitesRef.current[id];
        if (!item)
            return null;
        return ({...item, label: item.name, value: item.id});
    }).filter(Boolean);
    const categories = keyword.length ? result : categoryOptions
    const debouncedispatch = useMemo(() => {
        const handler = (keyword) => {
            setkeyword(keyword);
            dispatch(createAsyncAction({
                apiInfo: {
                    root: API_ROOT_URL,
                    path: '/categories/search?keyword=' + keyword + (type ? `&&type=${type}` : ""),
                    method: 'GET',
                }
            }));
        };
        return _.debounce(handler);
    }, []);
    return (
        <>
            <Select
                options={options}
                open={false}
                onClick={toggle}
                {...{
                    value: selected,
                }}
                mode="multiple"
                placeholder={t('please select')}
                onChange={value => {
                    setSelected(value)
                    return onChange(value)
                }}
                style={{
                    width: '100%'
                }}>
            </Select>
            <Modal
                width={720}
                className="custom-modal"
                title={
                    <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                        {t('select categories')}
                    </div>
                }
                maskClosable
                destroyOnClose
                onOk={toggle}
                footer={null}
                visible={isToggle}
                onCancel={toggle}
                okText={t('save')}
                cancelText={t('close')}
                okButtonProps={{
                    type: 'primary',
                    className: 'button-rounded-lg',
                }}
                cancelButtonProps={{
                    className: 'button-rounded-lg no-border bg-transparent',
                }}>
                <div className="flex flex-col gap-3 mb-4">
                    <Input
                        className="rounded-md"
                        autoFocus
                        placeholder={t('please select')}
                        addonAfter={<SearchOutlined/>}
                        onChange={e => debouncedispatch(e.target.value)}
                    />
                    <div className="flex flex-wrap items-center gap-3">
                        {options.map(item => (
                            <div
                                key={item.id}
                                style={{ padding: '4px 10px' }}
                                className="flex flex-center gap-3 background hover:background-100 hover:shadow-items-md border rounded-md cursor-pointer">
                                <span className="font-semibold text-sm text-color-200">
                                    {item.name}
                                </span>
                                <MdClose
                                    onClick={() => {
                                      handleChange(item.id)
                                    }}
                                    className="text-color-400 hover:text-red-600 cursor-pointer"
                                />
                            </div>
                        ))}
                    </div>
                    {isLoading && (
                        <Spin spinning>
                            <div className="border background-100 rounded p-3 flex space-x-3 h-24"/>
                        </Spin>
                    )}
                    <div className="grid grid-cols-2 gap-2">
                        {categories.map(item => {
                            const {
                                id, avatar,
                                name, alt_idname, description
                            } = item;
                            const checked = selected.includes(id);
                            return (
                                <div
                                    key={id}
                                    onClick={(e) => {
                                        preventDefaultEvent(e);
                                        handleChange(id);
                                    }}
                                    className={
                                        classNames(
                                            "flex items-center gap-3 rounded-md p-2 border",
                                            checked ? "border-primary hover:bg-primary-50" : "border-color-50 hover:shadow-items-md"
                                        )
                                    }>
                                    <Avatar
                                        size={30}
                                        className="flex flex-center background-200 rounded-md"
                                        src={avatar}
                                    />
                                    <div className="flex flex-col flex-1">
                                        <span className="font-bold text-color-000">{name}</span>
                                        {!!description && (
                                            <span className="text-sm text-color-500 italic">{description}</span>
                                        )}
                                    </div>
                                    <Checkbox checked={checked} value={id} id={id} name={id}/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="text-center mt-3">
                        <Button
                            type="primary"
                            className="button-rounded-md no-border"
                            onClick={() => {
                                onChange(selected)
                                toggle()
                            }}>
                            {t('save')}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    )
};
