import React from 'react'
import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'
import * as serviceWorker from 'serviceWorker'
import App from './App'

function render() {
  const renderMethod = window.__SERVER_STATE__
    ? // $FlowIssue
      ReactDOM.hydrate
    : ReactDOM.render

  return renderMethod(
    <App />,
    document.getElementById('root')
  )
}

Loadable.preloadReady()
  .then(render)
  .catch(err => {
    console.error(err)
  })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (
  !process.env.NODE_ENV ||
  process.env.NODE_ENV === 'development'
) {
  serviceWorker.unregister()
  // dev code
} else {
  // production code
  window.addEventListener(
    'beforeinstallprompt',
    e => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault()
      // Stash the event so it can be triggered later.
      console.log(
        0,
        'START to beforeinstallprompt'
      )
      window.deferredInstallPrompt = e
    }
  )
  window.addEventListener(
    'appinstalled',
    evt => {
      console.log(0, 'a2hs installed')
    }
  )
}
