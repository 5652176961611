import Null from 'components/NullComponent'
import React from 'react'

export const NavigationContext = React.createContext(
  {
    canBack: false,
    history: {},
    location: {},
    mainLocation: {},
    routesConfig: {},
    modalRoutesConfig: {},
    handleGoBack: Null,
    isModal: false
  }
)
