import { CloseOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useContext } from 'react'
import { Null } from 'views/Shared'
import DiscoveryExplorerContext from '../DiscoveryExplorerContext'

const renderFilterTag =
  ({ onClose = Null }) =>
  ({ id, label }) =>
    (
      <Tag
        key={id}
        closable={true}
        onClose={onClose}
        closeIcon={
          <CloseOutlined className="p-1 rounded-full hover:bg-primary hover:text-white" />
        }
        style={{
          margin: 0,
          padding: '0.25em 1em',
        }}
        className="border-primary text-primary font-medium">
        <span className="mr-1">{label}</span>
      </Tag>
    )

const DiscoveryExplorerTagsFilter = () => {
  const t = useTranslate()

  const { filter = {}, setFilter = Null } = useContext(DiscoveryExplorerContext)

  const filterValues = Object.values(filter).filter(
    (item) => !!_.get(item, 'value')
  )

  const hasFilter = !_.isEmpty(filterValues)

  return (
    <div className="flex flex-wrap gap-2 items-center justify-center">
      {filterValues.map((item, index) =>
        renderFilterTag({
          onClose: () => {
            setFilter({
              ..._.omit(filter, [item.id]),
            })
          },
        })(item, index)
      )}
      {hasFilter && (
        <span
          onClick={() => {
            setFilter({})
          }}
          className="px-2 cursor-pointer no-underline text-color-300 hover:text-primary italic text-xs">
          {t('clear all')}
        </span>
      )}
    </div>
  )
}

export default DiscoveryExplorerTagsFilter
