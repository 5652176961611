import {Carousel} from 'antd'
import classnames from 'classnames'
import React, {useContext} from 'react'
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import ImageLoader from '../../components/ImageLoader'
import productImageHolder from '../../views/Market/productImageHolder'
import {OrganizationContext} from './OrganizationContext'
import './Slider.css'
import useTranslate from "../../modules/local/useTranslate";
import {emptyArray} from "../../helpers/emptyObjects";

function SampleNextArrow(props) {
  const {
    className,
    style,
    onClick
  } = props
  return (
    <div
      className={classnames(
        'shadow opacity-75 hover:opacity-100',
        className
      )}
      style={{
        ...style,
        marginTop: '-20px',
        right: 0,
        zIndex: 10,
        background: 'var(--background)',
        width: '40px',
        height: '40px',
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#545454',
        transform: 'trans'
      }}
      onClick={onClick}>
      <FaAngleRight size={24} />
    </div>
  )
}

function SamplePrevArrow(props) {
  const {
    className,
    style,
    onClick
  } = props
  return (
    <div
      className={classnames(
        'shadow opacity-75 hover:opacity-100',
        className
      )}
      style={{
        ...style,
        marginTop: '-20px',
        left: 0,
        zIndex: 10,
        background: 'var(--background)',
        width: '40px',
        height: '40px',
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#545454',
        transform: 'trans'
      }}
      onClick={onClick}>
      <FaAngleLeft size={24} />
    </div>
  )
}
export function Slider() {
  const t = useTranslate();
  const {
    allProducts,
    url,
    id
  } = useContext(OrganizationContext);

  const _allProducts = allProducts;
  while (_allProducts.length < 6) {
    _allProducts.push(null);
  }

  return (
    <div className="flex">
      <div className="flex-1 w-0">
        <Carousel
          className="Slider "
          arrows
          infinite={true}
          {...{
            autoplay: true,
            dots: false,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            nextArrow: (
              <SampleNextArrow />
            ),
            prevArrow: (
              <SamplePrevArrow />
            ),
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4
                }
              },
              {
                breakpoint: 800,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  arrows: false
                }
              }
            ]
          }}>

          {(_allProducts || emptyArray).map(
            (item, index) => {
              if (!item)
                return (
                  <div
                    key={index}
                    className="p-2">
                    <div className="border border-gray-300 rounded-lg relative overflow-hidden">
                      <div
                        className="block relative overflow-hidden"
                        style={{
                          paddingTop:
                            '10' +
                            '' +
                            '0%'
                        }}>
                        <ImageLoader
                          alt="ecommerce"
                          className="object-cover absolute top-0 left-0 w-full h-full block"
                          src={
                            productImageHolder
                          }
                        />
                      </div>
                      <div
                        style={{
                          background: `linear-gradient(
                        rgba(255, 255, 255, 0) 20%,
                        rgb(42 42 42) 100%
                      )`
                        }}
                        className="p-2 pt-6 flex items-end absolute left-0 bottom-0 w-full">
                        <h2 className="hover:underline text-white title-font md:text-lg font-medium text-black">
                          {t('product')}
                        </h2>
                      </div>
                    </div>
                  </div>
                )

              return (
                <div
                  key={item.id}
                  className="p-2">
                  <div className="border border-gray-300 rounded-lg relative overflow-hidden">
                    <div
                      className="block relative overflow-hidden"
                      style={{
                        paddingTop:
                          '100%'
                      }}>
                      <ImageLoader
                        alt="ecommerce"
                        className="object-cover absolute top-0 left-0 w-full h-full block"
                        src={
                          item.avatar ||
                          productImageHolder
                        }
                      />
                    </div>
                    <div
                      style={{ background: `linear-gradient(rgba(255, 255, 255, 0) 20%, rgb(42 42 42) 100%)` }}
                      className="p-2 pt-6 flex items-end absolute left-0 bottom-0 w-full">
                      <Link
                          className="no-underline"
                          to={`/mart/product/${item.idname}`}>
                        <h2 className="font-medium text-white leading-tight opacity-75 hover:opacity-100">
                          {item.title}
                        </h2>
                      </Link>
                    </div>
                  </div>
                </div>
              )
            }
          )}
        </Carousel>
      </div>
    </div>
  )
}
