import {
  create_follow_add_Api_action,
  create_follow_remove_Api_action
} from 'apis/follow'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useRequiredLogin } from './useRequiredLogin'
export const useFollow = ({
  follow_status,
  owner_type,
  owner_id
}) => {
  const dispatch = useDispatch()
  const handleFollow = useCallback(() => {
    !follow_status
      ? dispatch(
          create_follow_add_Api_action()(
            {
              values: {
                owner_type,
                owner_id
              }
            }
          )
        )
      : dispatch(
          create_follow_remove_Api_action()(
            {
              values: {
                owner_type,
                owner_id
              }
            }
          )
        )
  }, [
    follow_status,
    dispatch,
    owner_type,
    owner_id
  ])
  return useRequiredLogin(handleFollow)
}
