import {Modal} from 'antd'
import React from 'react'
import {renderSelf} from 'views/Shared'
import {PiTrash} from "react-icons/pi";

const createConfirmDialog = ({
                               onOk,
                               title,
                               message,
                               translate = renderSelf,
                               ...props
                             }) =>
  Modal.confirm({
    icon: <PiTrash className="anticon"/>,
    className: 'custom-modal',
    title: (
      <div className="font-bold text-orange-500">
        {translate(title)}
      </div>
    ),
    content: (
      <div className="text-sm font-light italic text-color-300">
        {translate(message)}
      </div>
    ),
    onOk,
    ...props,
  })


export const showConfirmDialog = ({
                                    onOk,
                                    title = 'are you sure',
                                    message = 'are you sure to continue executing?',
                                    translate = renderSelf,
                                    ...props
                                  }) =>
  createConfirmDialog({
    ...props,
    onOk,
    className: 'custom-modal',
    title: translate(title),
    message: translate(message),
    okText: translate('sure'),
    cancelText: translate('cancel'),
    okButtonProps: {
      type: 'text',
      className: 'rounded-lg no-shadow no-text-shadow no-border text-red-500',
    },
    cancelButtonProps: {
      type: 'primary',
      danger: true,
      className: 'rounded-lg no-shadow no-text-shadow no-border',
    },
  })

export const showDeleteConfirmDialog = ({
                                          onOk,
                                          title = 'are you sure',
                                          message = 'are you sure you want to delete it permanently',
                                          translate = renderSelf,
                                          ...props
                                        }) =>
  createConfirmDialog({
    ...props,
    onOk,
    className: 'custom-modal',
    title: translate(title),
    message: translate(message),
    okText: translate('sure'),
    cancelText: translate('ignore'),
    okButtonProps: {
      type: 'primary',
      danger: true,
      className: 'rounded-lg no-shadow no-text-shadow no-border',
    },
    cancelButtonProps: {
      className: 'rounded-lg no-shadow no-text-shadow no-border',
    },
  })

export default createConfirmDialog
