import {Avatar} from 'antd'
import {getId, getType} from 'apis/model/base'
import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import {FollowButton} from 'components/Feed/FollowButton'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {AppEntities} from 'views/AppLocals/enums'
import {renderElse, renderIf} from 'views/Shared'
import {ItemTemplate} from './renderSearchResultItem'
import {BsCalendar2WeekFill, BsVectorPen} from "react-icons/bs";
import {FaWarehouse} from "react-icons/fa6";

export const ResultCompactItem = ({item}) => {
  const t = useTranslate()

  const {isSm} = useContext(LayoutContext)

  const type = getType(item)

  const expired = _.get(item, 'is_expired')

  const {org_types = {}} = useAppConfig()

  const org_type = _.get(org_types, _.get(item, 'org_type', '900'))

  let label = ''
  let description = null
  let moreInfo
  let extra = null
  let icon
  let showBanner
  let target = item

  let renderLink = (item) =>
    renderElse(
      _.isEmpty(item),
      <Link
        to={getLinkToDetail(item)}
        className="font-bold text-color-000 max-lines-2 no-underline">
        {getTitle(item)}
      </Link>
    )

  switch (type) {
    case AppEntities.ORGANIZATION:
      icon = <FaWarehouse className="text-color-400"/>
      label = ''
      description = org_type
      extra = (
        <FollowButton
          item={item}
          size="small"
        />
      )
      break
    case AppEntities.KEYWORD:
      break
    case AppEntities.CHANNEL:
      label = ""
      description = getTitle(_.get(item, 'owner'))
      extra = (
        <FollowButton
          item={item}
          size="small"
        />
      )
      break
    case AppEntities.EVENT:
      label = 'organized by'
      description = _.get(item, 'owner.title')
      moreInfo = (
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-6">
            <div className="flex items-center gap-1 italic leading-tight">
              <span className="font-normal text-xs text-color-400">
                {`${t('start')} : `}
              </span>
              <span className="text-xs text-color-000 font-medium max-lines-1">
                {moment(_.get(item, 'time_open')).format('HH:mm - DD/MM/YYYY')}
              </span>
            </div>
            <div className="flex items-center gap-1 italic leading-tight">
              <span className="font-normal text-xs text-color-400">
                {`${t('type of event')} : `}
              </span>
              <span className="text-xs text-color-000 font-medium max-lines-1">
                {t(_.get(item, 'event_type'))}
              </span>
            </div>
          </div>
          <div className="text-xs text-color-400 max-lines-2 italic">
            {_.get(item, 'description')}
          </div>
        </div>
      )
      icon = (
        <BsCalendar2WeekFill
          size={13}
          className="text-color-400"
        />
      )
      showBanner = false
      extra = (
        <div className="flex flex-row md:flex-col items-center md:items-end justify-between gap-2">
          <span
            className={classNames(
              'text-sm italic font-medium leading-tight mr-2',
              expired === true ? 'text-red-600' : 'text-blue-600'
            )}>
            {expired === true ? t('closed') : t('opening')}
          </span>
          <FollowButton
            item={item}
            size="small"
          />
        </div>
      )
      break
    case AppEntities.ARTICLE:
      icon = <BsVectorPen className="text-color-400"/>
      label = 'by'
      description = _.get(item, 'creator.name')
      break
    case AppEntities.PRODUCT:
      label = 'owner'
      description = getTitle(item?.owner)
      break
    default:
      break
  }

  return (
    <ItemTemplate
      item={item}
      showBanner={showBanner}>
      <div className="flex items-center gap-3">
        {renderElse(
          isSm,
          <Avatar
            className={classNames(
              'flex flex-center border border-color-50 background-200',
              type === 'organization' && 'rounded-md'
            )}
            icon={icon}
            shape={type === 'organization' ? 'square' : 'circle'}
            src={getAvatar(item)}>
            {!icon && (
              <span className="capitalize font-semibold text-color-100">
                {_.first(getTitle(item))}
              </span>
            )}
          </Avatar>
        )}
        <div className="w-full flex flex-1 flex-col md:flex-row justify-between gap-2 md:gap-3">
          <div className="flex flex-col justify-center flex-1 gap-1">
            {renderLink(target)}
            {renderIf(
              description,
              <div className="flex items-center gap-1 italic leading-tight">
                {renderIf(
                  label,
                  <span className="text-xs text-color-400">
                    {t(label)}
                  </span>
                )}
                <span className="text-xs text-primary font-medium max-lines-1">
                  {description}
                </span>
              </div>
            )}
            {moreInfo ?? null}
          </div>
          <div className="text-right my-auto">{extra}</div>
        </div>
      </div>
    </ItemTemplate>
  )
}

const createSearchResultComponent = (item, index) => {
  if (!!!getId(item)) return null

  const type = getType(item)

  let Component = () => <EmptyHolder/>

  switch (type) {
    case AppEntities.USER:
    case AppEntities.ORGANIZATION:
    case AppEntities.KEYWORD:
    case AppEntities.CHANNEL:
    case AppEntities.EVENT:
    case AppEntities.ARTICLE:
    case AppEntities.PRODUCT:
    case AppEntities.DATASET:
      Component = ResultCompactItem
      break
    default:
      break
  }

  return (
    <div
      key={index}
      className="flex w-full mb-3">
      <Component {...{item}} />
    </div>
  )
}

export default createSearchResultComponent
