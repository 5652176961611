import { Statistic, Tag } from "antd"
import { getId } from "apis/model/base"
import Null from "components/NullComponent"
import _ from "lodash"
import useTranslate from "modules/local/useTranslate"
import React from "react"
import { useHistory } from "react-router-dom"
import { useToggle } from "react-use"
import { SpotRequestTypes } from "views/Market/components/SpotBid/SpotRequestForm"
import { fromOrderRequestType, getRequestDetailStatus, RequestStatusOptions } from "views/OrderRequest/Settings/OrderRequestStatus"
import { ResponseRequestContent, withConfirmModal } from "../Settings/components/RequestModals"
import RequestSummary from "../Settings/components/RequestSummary"
import RequestWidgets, { MessageWrapper } from "../Settings/components/RequestWidgets"
import SenderInfo from "../Settings/components/SenderInfo"
import renderRequestActions from "./renderRequestActions"
import renderRequestStatus from "./renderRequestStatus"

const RequestDetailHeader = ({
  item = {}
}) => {

  const t = useTranslate()

  const [isToggle, toggle] = useToggle(false)

  const {
    id,
    created,
    sender_name,
    sender_email,
    sender_phone,
    status = -1,
    feedback_message,
    conversation = {},
    order_request_type: type
  } = item

  const history = useHistory()

  const conversationId = getId(conversation)

  const requestStatus = getRequestDetailStatus(status, {})

  const isReponded = requestStatus.name === RequestStatusOptions.RESPONDED

  const actions = [
    {
      title: "responses",
      withProps: {
        type: "primary",
      },
      invisible: isReponded,
      onClick: toggle
    },
    {
      title: "send message",
      withProps: {
        type: "primary",
      },
      invisible: _.isEmpty(conversationId),
      onClick: () => {
        const path = '/workspace/contact-messages?conversationId='
        history.push(
          `${path}${conversationId}`
        )
      }
    }
  ].filter(e => !!!e.invisible)

  return (
    <React.Fragment>
      <SenderInfo
        className="flex flex-col col-span-12 md:col-span-8 mb-3 md:mb-0"
        sender={{
          created,
          sender_name,
          sender_email,
          sender_phone,
        }} />
      <div className="ml-0 md:ml-3 col-span-6 md:col-span-2">
        {renderRequestStatus(requestStatus)}
      </div>
      <div className="ml-0 md:ml-3 col-span-6 md:col-span-2 space-y-3">
        {renderRequestActions(actions)}
      </div>

      {isToggle && withConfirmModal({
        id,
        type,
        toggle,
        title: 'feedback',
        onSuccess: toggle,
        message: feedback_message,
        Description: () => {
          return (
            <div className="flex mb-3 justify-between">
              <div className="flex">
                <Statistic
                  title={t('from')}
                  value={sender_name}
                  className="font-bold" />
              </div>
              <div className="flex">
                <SenderInfo
                  Header={Null}
                  sender={{
                    created,
                    sender_name,
                    sender_email,
                    sender_phone,
                  }} />
              </div>
            </div>
          )
        },
      })(ResponseRequestContent)}
    </React.Fragment>
  )
}

const MessageContent = ({
  children,
  message = '',
}) => {
  const t = useTranslate()
  return (
    <div className="w-full">
      <div>
        <strong className="pr-2">
          {`${t('title')} : `}
        </strong>
        {message}
      </div>
      {children}
    </div>
  )
}

const RequestDetailType = ({
  type
}) => {
  const t = useTranslate()

  const item = fromOrderRequestType(type)

  if (!!!item) return null

  return (
    <div className="flex">
      <span className="font-light text-sm pr-2">
        {`${t('request type')} : `}
      </span>
      <Tag color={item.color}>
        {t(item.label)}
      </Tag>
    </div>
  )
}

export const renderOrderRequestDetail = item => {
  const {
    meta = {},
    price = 0,
    message = '',
    order_request_type: type,
  } = item

  const hasSummary = type === SpotRequestTypes.PRICE

  return (
    <div className="flex flex-col items-center border rounded-lg relative">
      <RequestWidgets.ResponsiveRow>
        <RequestDetailHeader item={item} />
        <MessageWrapper>
          <MessageContent
            message={message}>
            <RequestDetailType
              className="w-full"
              type={type} />
            {hasSummary && (
              <RequestSummary
                item={{
                  ...meta,
                  price
                }} />
            )}
          </MessageContent>
        </MessageWrapper>
      </RequestWidgets.ResponsiveRow>
    </div>
  )
}