import React from "react";

export const Cocoa = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#8bc932"
				d="M125.5 92.37v.2H92.37A66.87 66.87 0 0125.5 25.7v-.2h33.131A66.87 66.87 0 01125.5 92.37z"
			></path>
			<path
				fill="#80b524"
				d="M110.66 92.573H92.367A66.869 66.869 0 0125.5 25.707V25.5h18.294v.207a66.869 66.869 0 0066.866 66.866z"
			></path>
			<path
				fill="#9e653b"
				d="M149.212 25.5a9.923 9.923 0 00-8.839 5.324 139.99 139.99 0 00-15.555 64.136v41.321h30V94.96a139.994 139.994 0 0111.5-55.568A9.982 9.982 0 00157.11 25.5z"
			></path>
			<path
				fill="#c1773d"
				d="M255.5 293.242c0-61.3-35.854-119.5-66.622-158.158a61.444 61.444 0 01-11.547-23.529A19.918 19.918 0 00157.959 96.5h-34.918a19.918 19.918 0 00-19.372 15.055 61.444 61.444 0 01-11.547 23.529C61.354 173.741 25.5 231.938 25.5 293.242c0 89.551 46.765 149.926 87.1 167.508 1.671 3.48 3.346 6.9 4.664 9.424 11.35 21.768 35.13 21.768 46.481 0a433.505 433.505 0 004.783-9.475C208.829 443.048 255.5 382.7 255.5 293.242z"
			></path>
			<path
				fill="#9e653b"
				d="M205.5 293.242c0-61.3-20.265-119.5-37.656-158.158a87.232 87.232 0 01-6.526-23.529c-1.241-8.873-5.783-15.055-10.95-15.055h-19.736c-5.167 0-9.709 6.182-10.95 15.055a87.232 87.232 0 01-6.526 23.529C95.765 173.741 75.5 231.938 75.5 293.242c0 89.551 51.119 174.4 51.864 176.932 6.416 21.768 19.857 21.768 26.272 0 .912-3.094 51.864-87.474 51.864-176.932z"
			></path>
			<path
				fill="#c1773d"
				d="M170.5 293.242c0-61.3-9.353-119.5-17.38-158.158a172.509 172.509 0 01-3.012-23.529c-.572-8.873-2.669-15.055-5.053-15.055h-9.11c-2.384 0-4.481 6.182-5.053 15.055a172.509 172.509 0 01-3.012 23.529c-8.027 38.657-17.38 96.854-17.38 158.158 0 89.551 23.594 174.4 23.937 176.932 2.961 21.768 9.165 21.768 12.126 0 .421-3.094 23.937-87.474 23.937-176.932z"
			></path>
			<path
				fill="#aa6f3d"
				d="M45.5 293.24c0-61.3 52.14-165.09 46.62-158.16-30.77 38.66-66.62 96.86-66.62 158.16 0 89.55 46.76 149.93 87.1 167.51 1.67 3.48-67.1-77.96-67.1-167.51z"
			></path>
			<path
				fill="#f19b40"
				d="M410.474 422.783c43.349-43.349 59.148-109.853 64.726-158.944a61.445 61.445 0 018.473-24.8 19.919 19.919 0 00-3.053-24.343L455.929 190a19.917 19.917 0 00-24.343-3.053 61.444 61.444 0 01-24.8 8.473C357.693 201 291.188 216.8 247.84 260.148c-63.322 63.323-72.946 139.082-56.86 180.033-1.28 3.642-2.511 7.243-3.366 9.961-7.367 23.419 9.449 40.234 32.868 32.866a436.554 436.554 0 0010.082-3.317c40.98 16.019 116.651 6.351 179.91-56.908z"
			></path>
			<path
				fill="#aa6f3d"
				d="M445.393 260.458a91.349 91.349 0 016.08-23.869 9.993 9.993 0 00-2.194-10.81l-4.435-4.435a9.993 9.993 0 00-10.81-2.194 91.349 91.349 0 01-23.869 6.08c-79.239 9.009-120.477 35.5-141.111 56.13-55.452 55.453-52.7 123.15-48.365 151.847a19.935 19.935 0 0016.727 16.726c28.7 4.338 96.394 7.088 151.846-48.364 20.638-20.634 47.122-61.869 56.131-141.111z"
			></path>
			<rect
				width="60"
				height="40"
				x="330.794"
				y="289.828"
				fill="#f3eade"
				rx="10"
				transform="rotate(45 360.795 309.828)"
			></rect>
			<path
				fill="#f1ddc8"
				d="M372.867 339.7a9.988 9.988 0 01-12.07-1.581l-28.29-28.29a10.006 10.006 0 010-14.139l14.15-14.14a9.976 9.976 0 0112.07-1.591 9.453 9.453 0 00-2.07 1.591l-7.08 7.069a20 20 0 000 28.281l21.22 21.219a9.721 9.721 0 002.07 1.581z"
			></path>
			<path
				fill="#f3eade"
				d="M346.652 380.54l21.213 21.213a10 10 0 010 14.142l-7.07 7.07a20 20 0 01-28.285 0l-14.142-14.141a10 10 0 010-14.142l14.142-14.142a10 10 0 0114.142 0z"
			></path>
			<path
				fill="#f1ddc8"
				d="M325.439 415.894l-7.071-7.071a10 10 0 010-14.142l14.142-14.142a10 10 0 0114.142 0l-21.213 21.213a10 10 0 000 14.142z"
			></path>
			<path
				fill="#f3eade"
				d="M261.8 352.255l-14.142-14.142a20 20 0 010-28.285l7.07-7.07a10 10 0 0114.143 0l21.213 21.213a10 10 0 010 14.142l-14.142 14.142a10 10 0 01-14.142 0z"
			></path>
			<path
				fill="#f1ddc8"
				d="M254.728 345.184l-7.071-7.071a20 20 0 010-28.285l7.071-7.071a10.005 10.005 0 0114.143 0l7.071 7.071a10 10 0 00-14.142 0l-7.072 7.071a20 20 0 000 28.285z"
			></path>
			<path
				fill="#f3eade"
				d="M247.657 394.68l28.284 28.285a10 10 0 010 14.142l-7.071 7.071a20 20 0 01-28.284 0l-14.142-14.142a20 20 0 010-28.284l7.07-7.071a10 10 0 0114.143 0z"
			></path>
			<path
				fill="#f1ddc8"
				d="M259.727 449.405a20 20 0 01-19.14-5.229l-14.14-14.141a20 20 0 010-28.279l7.07-7.07a9.978 9.978 0 0112.07-1.591 9.453 9.453 0 00-2.07 1.591l-7.07 7.07a20 20 0 000 28.279l14.14 14.141a19.815 19.815 0 009.14 5.229z"
			></path>
			<rect
				width="40"
				height="23"
				x="363.068"
				y="377.171"
				fill="#f3eade"
				rx="10"
				transform="rotate(-45 383.068 388.671)"
			></rect>
			<path
				fill="#f1ddc8"
				d="M372.907 405.9a9.947 9.947 0 01-2.92-2.029l-2.121-2.122a10 10 0 010-14.142l14.142-14.142a10 10 0 0114.142 0l2.121 2.121a9.923 9.923 0 012.03 2.921 10.016 10.016 0 00-11.222 2.029l-14.142 14.142a10.015 10.015 0 00-2.03 11.222z"
			></path>
			<rect
				width="40"
				height="23"
				x="261.952"
				y="276.055"
				fill="#f3eade"
				rx="10"
				transform="rotate(-45 281.952 287.555)"
			></rect>
			<path
				fill="#f1ddc8"
				d="M271.791 304.787a9.906 9.906 0 01-2.92-2.03l-2.122-2.121a10 10 0 010-14.142l14.142-14.142a10.005 10.005 0 0114.143 0l2.121 2.121a9.909 9.909 0 012.029 2.92 10.013 10.013 0 00-11.221 2.03l-14.143 14.142a10.016 10.016 0 00-2.029 11.222z"
			></path>
			<path
				fill="#f3eade"
				d="M297.155 387.61l31.113 31.113a10 10 0 010 14.143l-7.071 7.07a20 20 0 01-28.285 0l-24.041-24.041a10 10 0 010-14.142l14.142-14.142a10 10 0 0114.142 0z"
			></path>
			<path
				fill="#f1ddc8"
				d="M312.057 445.166a20 20 0 01-19.14-5.23l-24.05-24.04a10.007 10.007 0 010-14.14l14.15-14.15a9.986 9.986 0 0112.07-1.58 9.716 9.716 0 00-2.07 1.58l-7.08 7.08a20 20 0 000 28.28l16.98 16.97a19.876 19.876 0 009.14 5.23z"
			></path>
			<path
				fill="#f3eade"
				d="M254.73 401.753l-24.043-24.042a20 20 0 010-28.284l7.072-7.071a10 10 0 0114.142 0l31.112 31.112a10 10 0 010 14.142l-14.142 14.143a10 10 0 01-14.142 0z"
			></path>
			<path
				fill="#f1ddc8"
				d="M266.8 403.336a9.993 9.993 0 01-12.07-1.58l-24.04-24.04a20.008 20.008 0 010-28.29l7.07-7.07a9.962 9.962 0 0112.07-1.58 9.716 9.716 0 00-2.07 1.58l-7.07 7.07a20.008 20.008 0 000 28.29l24.04 24.04a9.749 9.749 0 002.07 1.58z"
			></path>
			<path
				fill="#f3eade"
				d="M403.221 323.97l11.314 11.314a20 20 0 010 28.284l-7.071 7.071a10 10 0 01-14.142 0l-18.385-18.384a10 10 0 010-14.143l14.142-14.142a10 10 0 0114.142 0z"
			></path>
			<path
				fill="#f1ddc8"
				d="M405.387 372.226a9.99 9.99 0 01-12.07-1.591l-18.38-18.379a9.99 9.99 0 010-14.141l14.14-14.139a9.963 9.963 0 0112.07-1.58 9.683 9.683 0 00-2.07 1.58l-14.14 14.139a9.99 9.99 0 000 14.141l18.38 18.379a10.112 10.112 0 002.07 1.591z"
			></path>
			<path
				fill="#f3eade"
				d="M318.368 295.686l-18.385-18.385a10 10 0 010-14.142l7.071-7.07a20 20 0 0128.284 0l11.314 11.313a10 10 0 010 14.142l-14.142 14.142a10 10 0 01-14.142 0z"
			></path>
			<path
				fill="#f1ddc8"
				d="M307.054 284.373l-7.071-7.071a10.005 10.005 0 010-14.143l7.071-7.071a20 20 0 0128.285 0l7.071 7.071a20 20 0 00-28.285 0l-7.071 7.071a10.005 10.005 0 000 14.143z"
			></path>
			<rect
				width="60"
				height="40"
				x="323.723"
				y="339.328"
				fill="#f3eade"
				rx="10"
				transform="rotate(45 353.724 359.327)"
			></rect>
			<path
				fill="#f1ddc8"
				d="M365.8 389.2a9.988 9.988 0 01-12.07-1.58l-28.29-28.29a10.007 10.007 0 010-14.14l14.15-14.14a9.974 9.974 0 0112.069-1.59 9.466 9.466 0 00-2.069 1.59l-7.08 7.07a20 20 0 000 28.28l21.22 21.22a9.716 9.716 0 002.07 1.58z"
			></path>
			<rect
				width="60"
				height="40"
				x="281.297"
				y="296.901"
				fill="#f3eade"
				rx="10"
				transform="rotate(45 311.297 316.9)"
			></rect>
			<path
				fill="#f1ddc8"
				d="M323.369 346.768a9.988 9.988 0 01-12.07-1.58l-28.29-28.29a10.007 10.007 0 010-14.14l14.15-14.14a9.976 9.976 0 0112.07-1.591 9.484 9.484 0 00-2.07 1.591l-7.08 7.07a20 20 0 000 28.28l21.22 21.22a9.749 9.749 0 002.07 1.58z"
			></path>
			<rect
				width="60"
				height="40"
				x="274.226"
				y="346.401"
				fill="#f3eade"
				rx="10"
				transform="rotate(45 304.226 366.4)"
			></rect>
			<path
				fill="#f1ddc8"
				d="M316.3 396.268a9.988 9.988 0 01-12.07-1.58l-28.29-28.29a10.007 10.007 0 010-14.14l14.15-14.14a9.976 9.976 0 0112.07-1.591 9.484 9.484 0 00-2.07 1.591l-7.08 7.07a20 20 0 000 28.28l21.22 21.22a9.749 9.749 0 002.07 1.58z"
			></path>
			<path
				fill="#f3eade"
				d="M367.865 288.615l-26.87-26.87a10 10 0 010-14.142l7.071-7.071a20 20 0 0128.284 0l19.8 19.799a10 10 0 010 14.142l-14.143 14.142a10 10 0 01-14.142 0z"
			></path>
			<path
				fill="#f1ddc8"
				d="M379.937 290.2a9.99 9.99 0 01-12.07-1.581L341 261.745a9.988 9.988 0 010-14.139l7.07-7.071a20 20 0 0119.14-5.23 20 20 0 00-9.14 33.511l19.8 19.8a9.721 9.721 0 002.067 1.584z"
			></path>
			<path
				fill="#f3eade"
				d="M410.292 274.473l19.799 19.799a20 20 0 010 28.284l-7.071 7.071a10 10 0 01-14.142 0l-26.87-26.87a10 10 0 010-14.142l14.142-14.142a10 10 0 0114.142 0z"
			></path>
			<path
				fill="#f1ddc8"
				d="M420.947 331.216a9.981 9.981 0 01-12.07-1.59l-26.87-26.87a10.008 10.008 0 010-14.141l14.14-14.139a9.994 9.994 0 0112.08-1.591 10.051 10.051 0 00-2.08 1.591l-7.07 7.07a20 20 0 000 28.279l19.8 19.8a9.479 9.479 0 002.07 1.591z"
			></path>
			<path
				fill="#f3eade"
				d="M424.434 232.047l14.143 14.142a20 20 0 010 28.284l-7.072 7.071a10 10 0 01-14.142 0L389.08 253.26a10 10 0 010-14.142l7.071-7.071a20 20 0 0128.284 0z"
			></path>
			<path
				fill="#f1ddc8"
				d="M429.437 283.126a9.989 9.989 0 01-12.07-1.58l-28.29-28.29a10.008 10.008 0 010-14.141l7.07-7.069a19.969 19.969 0 0119.14-5.221 20 20 0 00-9.14 33.511l21.22 21.21a9.749 9.749 0 002.07 1.58z"
			></path>
		</svg>
	);
}

export default Cocoa;
