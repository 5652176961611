import classNames from 'classnames'
import Translate from 'modules/local/Translate'
import React from 'react'

const HeaderItemInfo = ({
  style,
  title,
  children,
  className,
  renderTitle,
  divider = true,
  hidden = false,
}) => {
  if (Boolean(hidden)) return null
  return (
    <Translate>
      {(t) => (
        <div className="w-full flex flex-col">
          <div className="flex mb-1">
            <span className="uppercase text-sm font-semibold text-color-300 tracking-wide">
              {renderTitle ? renderTitle(t) : t(title)}
            </span>
            {!!divider && <span className="border-b w-full flex-1 my-3 ml-3" />}
          </div>
          <div
            style={style}
            className={classNames('ml-2 md:ml-3', className)}>
            {children}
          </div>
        </div>
      )}
    </Translate>
  )
}

export default HeaderItemInfo
