import { schema } from 'normalizr'
import { channelSchema } from './channel'
import { userSchema } from './user'

export const organizationSchema = new schema.Entity(
  'organizations',
  {
    channels: [channelSchema],
    creator: userSchema
  },
  {
    idAttribute: 'idname'
  }
)
export const organizationSchemaArray = [
  organizationSchema
]
