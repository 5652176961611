import {CloseOutlined, PlusOutlined} from '@ant-design/icons'
import {Button, Col, Input, Row, Select} from 'antd'
import FieldDecorator from 'components/form/FieldDecorator'
import FormActionBar from 'components/form/FormActionBar'
import {createValue} from 'components/form/utils'
import {useFormikContext} from 'formik'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {BsPlusLg} from "react-icons/bs";

const {Option, OptGroup} = Select

export const children = []

for (let i = 10; i < 36; i++) {
  children.push(
    <Option key={i.toString(36) + i}>
      {i.toString(36) + i}
    </Option>
  )
}

function AdditionalDetails({}) {
  const {
    values,
    handleChange
  } = useFormikContext()
  const t = useTranslate()

  return (
      <div className="w-full space-y-6">
          <div className="flex flex-col gap-4">
              <div className="flex items-center gap-2">
                  <span className="flex-1 font-semibold text-sm text-color-400 uppercase tracking-wide">
                      {t('additional details')}
                  </span>
                  <div
                      style={{ height: 26, padding: '0 10px' }}
                      className="flex flex-center gap-1 bg-primary-50 hover:bg-primary-100 rounded-md cursor-pointer"
                      onClick={() =>
                          handleChange(
                              createValue(
                                  'attributes',
                                  [
                                      ...(values.attributes ||
                                          []),
                                      {
                                          name: '',
                                          value: ''
                                      }
                                  ]
                              )
                          )
                      }>
                      <BsPlusLg size={11} className="text-primary"/>
                      <span className="font-bold text-xs text-primary">{t('add more')}</span>
                  </div>
              </div>
              {!!( values.attributes && values.attributes.length ) && (
                  <div className="flex flex-col gap-3">
                      {values.attributes.map(
                          (attr, i) => (
                              <div key={i} className="grid grid-cols-12 gap-2">
                                  <div className="col-span-4">
                                      <Input
                                          placeholder={t('name')}
                                          defaultValue={attr.title}
                                          onBlur={e => {
                                              handleChange(
                                                  createValue(
                                                      'attributes',
                                                      values.attributes.map(
                                                          (
                                                              attr,
                                                              index
                                                          ) => {
                                                              if (
                                                                  index ===
                                                                  i
                                                              ) {
                                                                  return {
                                                                      ...attr,
                                                                      title:
                                                                      e
                                                                          .target
                                                                          .value
                                                                  }
                                                              }
                                                              return attr
                                                          }
                                                      )
                                                  )
                                              )
                                          }}
                                      />
                                  </div>
                                  <div className="col-span-8">
                                      <Input
                                          defaultValue={
                                              attr.value
                                          }
                                          onBlur={e => {
                                              handleChange(
                                                  createValue(
                                                      'attributes',
                                                      values.attributes.map(
                                                          (
                                                              attr,
                                                              index
                                                          ) => {
                                                              if (
                                                                  index ===
                                                                  i
                                                              ) {
                                                                  return {
                                                                      ...attr,
                                                                      value:
                                                                      e
                                                                          .target
                                                                          .value
                                                                  }
                                                              }
                                                              return attr
                                                          }
                                                      )
                                                  )
                                              )
                                          }}
                                          placeholder={t('value')}
                                          addonAfter={
                                              <CloseOutlined
                                                  onClick={() => {
                                                      const newArray = [
                                                          ...values.attributes
                                                      ]
                                                      newArray.splice(
                                                          i,
                                                          1
                                                      )
                                                      handleChange(
                                                          createValue(
                                                              'attributes',
                                                              newArray
                                                          )
                                                      )
                                                  }}
                                              />
                                          }
                                      />
                                  </div>
                              </div>
                          )
                      )}
                  </div>
              )}
          </div>
          <FormActionBar/>
      </div>
  )
}

export default AdditionalDetails
