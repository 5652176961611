import {Select, Spin} from 'antd'
import {organization_fetchStatistics_Api} from 'apis'
import classNames from 'classnames'
import Pure from 'components/Pure'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo} from 'react'
import {IoChatboxEllipsesOutline, IoChevronDown, IoEyeOutline, IoLogoRss,} from 'react-icons/io5'
import {nest, withProps} from 'recompose'
import {DelayRender} from 'views/Discovery/DelayRender'
import {bindQueryParam} from 'views/AppLocals/functions/routerHelper'
import {deepTranslate, getResponseItem, Null, renderSelf} from 'views/Shared'
import WorkspaceContext, {WorkspaceFilterParams,} from 'views/Workspace/WorkspaceContext'
import WorkspaceInsightsChart, {InsightsChartTypes,} from './WorkspaceInsightsChart'
import WorkspaceInsightsMetaSummary from './WorkspaceInsightsMetaSummary'
import {AiOutlineLike} from "react-icons/ai";

export const SummaryFilterOptions = Object.freeze({
  YESTERDAY: {
    label: 'yesterday',
    value: 'YESTERDAY',
  },
  TODAY: {
    label: 'today',
    value: 'TODAY',
  },
  LAST7DAYS: {
    label: 'last 7 days',
    value: 'LAST7DAYS',
  },
  THISMONTH: {
    label: 'this month',
    value: 'THISMONTH',
  },
  LASTMONTH: {
    label: 'last month',
    value: 'LASTMONTH',
  },
})

export const InsightsBlockHeader = ({
                                      title,
                                      style,
                                      children,
                                      className,
                                      renderTitle = renderSelf,
                                    }) => (
  <div
    style={style}
    className={classNames('flex items-center justify-between mb-3', className)}>
    <Translate>
      {(t) => (
        <div className="text-color-200 uppercase font-medium text-lg">
          {renderTitle(t(title))}
        </div>
      )}
    </Translate>
    {children}
  </div>
)

export const PeriodFilterHeader = ({
                                     title,
                                     value,
                                     defaultValue,
                                     onSelect = Null,
                                     options = [
                                       SummaryFilterOptions.YESTERDAY,
                                       SummaryFilterOptions.TODAY,
                                       SummaryFilterOptions.LAST7DAYS,
                                       SummaryFilterOptions.THISMONTH,
                                       SummaryFilterOptions.LASTMONTH,
                                     ],
                                   }) => {
  const t = useTranslate()

  const hasFilter = !_.isEmpty(options)

  const _options = useMemo(() => {
    return Array.from(options).map(deepTranslate(t))
  }, [t, options])

  return (
    <InsightsBlockHeader title={title}>
      {hasFilter && (
        <Select
          size="small"
          value={value}
          options={_options}
          onSelect={onSelect}
          style={{width: 110}}
          defaultValue={defaultValue}
          className="text-xs text-color-300"
          suffixIcon={<IoChevronDown className="text-color-300"/>}
        />
      )}
    </InsightsBlockHeader>
  )
}

const InsightsCharts = ({
                          style,
                          data = [],
                          className,
                          isLoading = false,
                          organization_id,
                        }) => {
  const [chart_1, chart_2] = [
    InsightsChartTypes.TYPE_1,
    InsightsChartTypes.TYPE_2,
  ].map((name) => ['workspace', organization_id, name].join('/'))
  return (
    <div
      style={style}
      className={className}>
      <Spin spinning={!!isLoading}>
        <WorkspaceInsightsChart
          type={InsightsChartTypes.TYPE_1}
          chartInfo={{
            id: chart_1,
            idname: chart_1,
            data_values: Array.from(data).map(({log_date, ...rest}) => ({
              time: log_date,
              articles: rest.total_articles,
              polls: rest.total_polls,
              reposts: rest.total_reposts,
            })),
            data_columns: [
              {
                id: 'time',
                dataIndex: 'time',
                type: 'date',
              },
              {
                id: 'articles',
                dataIndex: 'articles',
                type: 'number',
                valueType: 'value',
              },
              {
                id: 'polls',
                dataIndex: 'polls',
                type: 'number',
                valueType: 'value',
              },
              {
                id: 'reposts',
                dataIndex: 'reposts',
                type: 'number',
                valueType: 'value',
              },
            ],
          }}
        />
      </Spin>
      <Spin spinning={!!isLoading}>
        <WorkspaceInsightsChart
          type={InsightsChartTypes.TYPE_2}
          chartInfo={{
            id: chart_2,
            idname: chart_2,
            data_values: Array.from(data).map(({log_date, ...rest}) => ({
              time: log_date,
              views: rest.total_views,
              votes: rest.total_votes,
              follows: rest.total_follows,
              comments: rest.total_comments,
            })),
            data_columns: [
              {
                id: 'time',
                dataIndex: 'time',
                type: 'date',
              },
              {
                id: 'views',
                dataIndex: 'views',
                type: 'number',
                valueType: 'value',
              },
              {
                id: 'votes',
                dataIndex: 'votes',
                type: 'number',
                valueType: 'value',
              },
              {
                id: 'follows',
                dataIndex: 'follows',
                type: 'number',
                valueType: 'value',
              },
              {
                id: 'comments',
                dataIndex: 'comments',
                type: 'number',
                valueType: 'value',
              },
            ],
          }}
        />
      </Spin>
    </div>
  )
}

const WorkspaceInsights = ({Wrapper = 'div'}) => {
  const {
    organization_id,
    getPeriod = Null,
    setPeriod = Null,
  } = useContext(WorkspaceContext)

  const period = getPeriod(WorkspaceFilterParams.OVERVIEW)

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Async
        {...{
          apiInfo: organization_fetchStatistics_Api,
          query: bindQueryParam({
            id: organization_id,
          }),
          values: {
            period,
          },
        }}>
        {({response, isLoading, handleAsyncAction = Null}) => {
          const {data, summary} = getResponseItem(response)
          return (
            <React.Fragment>
              <Pure input={[period, summary, isLoading, handleAsyncAction]}>
                <WorkspaceInsightsMetaSummary
                  summary={summary}
                  isLoading={isLoading}
                  className="grid grid-cols-2 md:grid-cols-4 gap-4"
                  Header={({title}) => (
                    <PeriodFilterHeader
                      title={title}
                      value={period}
                      onSelect={(period) => {
                        setPeriod(WorkspaceFilterParams.OVERVIEW, period)
                        handleAsyncAction({
                          values: {
                            period,
                          },
                        })
                      }}
                    />
                  )}
                  field_params={[
                    {
                      name: 'total_views',
                      label: 'total views',
                      extra_name: 'total_views_prev',
                      icon: <IoEyeOutline className="text-color-500 text-xl"/>,
                    },
                    {
                      name: 'total_votes',
                      label: 'total votes',
                      extra_name: 'total_votes_prev',
                      icon: <AiOutlineLike/>,
                    },
                    {
                      name: 'total_comments',
                      label: 'total comments',
                      extra_name: 'total_comments_prev',
                      icon: (
                        <IoChatboxEllipsesOutline className="text-color-500 text-xl"/>
                      ),
                    },
                    {
                      name: 'total_follows',
                      label: 'total follows',
                      extra_name: 'total_follows_prev',
                      icon: <IoLogoRss className="text-color-500 text-xl"/>,
                    },
                  ]}
                />
              </Pure>
              <Pure input={[data]}>
                <InsightsCharts
                  data={data}
                  isLoading={isLoading}
                  className="grid grid-cols-1 md:grid-cols-2 gap-2"
                  organization_id={organization_id}
                />
              </Pure>
            </React.Fragment>
          )
        }}
      </Async>
      <div className="h-20"/>
    </Wrapper>
  )
}

export default nest(withProps({lazy: true})(DelayRender), WorkspaceInsights)
