import Count from 'components/Feed/Count'
import Timestamp from 'components/Feed/Timestamp'
import ImageLoader from 'components/ImageLoader'
import getLinkToDetail from 'helpers/getLinkToDetail'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {useHistory} from 'react-router'
import {Link} from 'react-router-dom'
import {APP_NAME} from '../../../envs/_current/config'
import {paths} from "../../MainPage/contains";
import {GiIronCross} from "react-icons/gi";
import {RiMessage3Line} from "react-icons/ri";
import {BsChatText, BsDiamondFill} from "react-icons/bs";
import {renderIf} from "../../Shared";

function ArticleItem({
                       auth,
                       title,
                       subTitle = `${APP_NAME}`,
                       categories = [],
                       keywords = [],
                       image,
                       created,
                       description,
                       url,
                       auth_avatar,
                       auth_name,
                       total_comments,
                     }) {
  const history = useHistory()
  const t = useTranslate()
  const getUrl = (category) => {
    const {id, language, alt_idname} = category
    const url = paths.categoryPath
      .replace(':category', id)
      .replace(':refcategory', alt_idname)

    return language ? `${url}?lang=${language}` : url
  }

  return (
    <div className="py-2 flex flex-col border-b verticalList__small">
      <div className="flex items-center gap-1">
        <div className="flex flex-col flex-1 gap-1">
          <Link
            className="font-bold max-lines-2 leading-tight no-underline"
            to={{pathname: url}}>
            {title}
          </Link>
          <div className="text-color-400 flex items-center gap-1 leading-tight">
            {[
                total_comments > 0 && (
                    <Count
                        defaultNumber={total_comments}
                        onClick={() => {
                            history.push(url)
                        }}
                        key="message"
                        className="m-0"
                        component={() => (
                            <BsChatText size={12} />
                        )}
                    />
                ),
                <Timestamp className="text-2xs text-color-400 italic" timestamp={created}/>,
                renderIf(
                    auth_name,
                    <div className="flex items-center gap-1 text-2xs">
                        <span className="text-color-400 font-light italic">{t('send by')}</span>
                        <a href={getLinkToDetail(auth)}
                           className="font-medium text-primary truncate">
                            {auth_name}
                        </a>
                    </div>
                ),
            ]
              .filter((item) => item)
              .map((item, i, arr) => {
                return (
                  <React.Fragment key={i}>
                    {item}
                    {i < arr.length - 1 && ' - '}
                  </React.Fragment>
                )
              })}
          </div>
        </div>
        {image && (
          <Link
            to={{pathname: url,}}
            className="w-16 h-16 rounded background-100 overflow-hidden flex-shrink-0">
            <ImageLoader
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              alt={title}
              src={image}
            />
          </Link>
        )}
      </div>
      {categories.length > 0 && (
        <div className="flex flex-wrap gap-x-2 hidden lg:flex">
          {categories.map((item) => (
            <Link
              key={item.id}
              to={getUrl(item)}
              className="flex items-center text-primary italic font-medium text-xs">
              <BsDiamondFill size={7} className="mr-2"/>
              {item.name}
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default ArticleItem
