import { API_ROOT_URL } from 'envs/_current/config'

// Route::post('auth/vote/up', 'VoteController@add');
export const about_us_articles_Api = {
  backend:
    'systems/about-us-articles/${current}/get',
  root: API_ROOT_URL,
  path:
    '/systems/about-us-articles/:current/get',
  method: 'GET'
}
