import {getId, getType} from 'apis/model/base'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {bindQueryParams} from 'views/AppLocals/functions/routerHelper'
import OrganizationContainerContext from '../OrganizationContainerContext'
import EntityList from "../../EntityList";
import {renderIfElse, renderSelf} from "../../../Shared";
import {referral_getReferrals_Api} from "../../../../apis";
import Translate from "../../../../modules/local/Translate";
import getTitle from "../../../../helpers/getTitle";
import {ItemInformation} from "../OrganizationEntity";
import copyToClipboard from "copy-to-clipboard";
import {ROOT_URL} from "../../../../envs/_current/config";
import {message, Tooltip} from "antd";
import {MdOutlineFileCopy} from "react-icons/md";


export const renderReferralItem =
  ({} = {}) =>
    (item, index) => {
      const {limit, total_accepts, member_group_type, no_expired, timeout, referral_code} = item ?? {}
      const time_limit = timeout / 3600

      return (
        <Translate key={index}>
          {(t) => (
            <div className="background border border-color-50 rounded-md p-2 hover:shadow-items relative">
              <div className="flex flex-col gap-1">
                <div className="font-bold text-sm text-color-000 no-underline leading-tight truncate">
                  {getTitle(item)}
                </div>
                <ItemInformation label="member type" value={member_group_type} className="text-xs"/>
                {renderIfElse(
                  no_expired === 1,
                  <ItemInformation label="timeout" value={t('unlimited')} className="text-xs"/>,
                  <ItemInformation label="timeout" value={`${time_limit} ${t('hours')}`} className="text-xs"/>
                )}
                {renderIfElse(
                  limit > 0,
                  <ItemInformation label="volume" value={`${total_accepts}/${limit}`} className="text-xs"/>,
                  <ItemInformation label="volume" value={`${total_accepts} ${t('member')}`} className="text-xs"/>,
                )}
                <Tooltip title={t('copy')}>
                  <MdOutlineFileCopy
                    size={16}
                    onClick={() => {
                      copyToClipboard(ROOT_URL + '/invite/organization/' + referral_code)
                      message.success(t('copied'))
                    }}
                    className="text-color-500 hover:text-color-100 cursor-pointer absolute bottom-5 right-5"
                  />
                </Tooltip>
              </div>
            </div>
          )}
        </Translate>
      )
    }

const Pager = ({
                 renderItem = renderSelf,
                 apiInfo = referral_getReferrals_Api,
                 ...props
               }) => (
  <EntityList
    apiInfo={apiInfo}
    renderItem={renderReferralItem({})}
    RenderEntity={React.Fragment}
    {...props}
  />
)

export const MemberInvitation = ({
                                   query,
                                   values,
                                   apiInfo,
                                   renderItem,
                                   ...props
                                 }) => (
  <Pager
    {...{query, values, apiInfo, renderItem}}
    {...props}
  />
)

const OrganizationInvitations = () => {
  const t = useTranslate()
  const {organization} = useContext(OrganizationContainerContext)
  const owner_id = getId(organization)
  const owner_type = getType(organization)

  return (
    <MemberInvitation
      values={{public: 1}}
      query={bindQueryParams([{id: owner_id}, {prop: owner_type}])}
      renderWrap={(children, __, count) => {
        return !!count && (
          <div className="flex flex-col space-y-2">
            <div className="uppercase text-sm font-medium text-color-400 border-b mb-2">
              {t('invitations')}
            </div>
            {children}
          </div>
        )
      }}
    />
  )
}

export default OrganizationInvitations
