import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import chartSize from 'conts/chartSize'
import viewModes from 'conts/viewModes'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import {ThemeContext} from 'modules/theme/ThemeContext'
import React, {useContext} from 'react'
import ReactGoogleChart from 'react-google-charts'
import {Autosizer} from 'views/ChartsPlayground/Autosizer'
import {ChartContext} from '../../../ChartContext'
import mapGeoChart from '../../../mapParams/mapGeoChart'
import mapGoogleChart from '../../../mapParams/mapGoogleChart'
import mapGoogleTable from '../../../mapParams/mapGoogleTable'

export const GoogleChartStateless = ({
  viewmode = viewModes.BLOCK,
  width,
  height,
  ...props
}) => {
  const { getEffectTheme } = useContext(
    ThemeContext
  )
  const theme = getEffectTheme()
  const {
    item,
    setChartWrap
  } = useContext(ChartContext)
  let chartC = null
  const t = useTranslate()
  const { isSm } = useContext(
    LayoutContext
  )
  const _chartSize = _.get(
    props,
    'size',
    isSm || viewmode === viewModes.BLOCK
      ? chartSize.SMALL
      : chartSize.NORMAL
  )

  if (
    !item.data_columns ||
    !item.data_columns.length
  ) {
    chartC = (
      <EmptyHolder
        title={null}
        subTitle={t('no data found')}
      />
    )

    return (
      <div
        style={{
          minHeight:
            viewmode ===
              viewModes.FULLSCREEN &&
            _chartSize !==
              chartSize.SMALL
              ? 'calc(100Vh - 320px)'
              : _chartSize ===
                chartSize.SMALL
              ? '240px'
              : '45Vh'
        }}
        className={classNames(
          props.className,
          'h-full w-full flex-1'
        )}>
        {chartC}
      </div>
    )
  }

  switch (item.chart_type) {
    case 'GeoChart':
      return (
        <ReactGoogleChart
          {...mapGeoChart({
            theme,
            chart: item,
            ...props,
            size: _chartSize,
            viewmode: viewmode
          })}
          getChartWrapper={setChartWrap}
        />
      )
      break
    case 'Table':
      let _style = {}
      if (theme === 'dark') {
        _style = {
          border:
            '1px solid var(--text-color)'
        }
      }
      return (
        <div style={_style}>
          <ReactGoogleChart
            {...mapGoogleTable({
              theme,
              chart: item,
              ...props,
              size: _chartSize,
              viewmode: viewmode
            })}
            {...{ width, height }}
            getChartWrapper={
              setChartWrap
            }
          />
        </div>
      )
      break
    default:
      const chartConfig = mapGoogleChart(
        {
          theme,
          chart: item,
          ...props,
          size: _chartSize,
          viewmode: viewmode
        }
      )
      const _last = _.last(
        chartConfig.data
      )

      return (
        <React.Fragment>
          <Autosizer>
            {([
              ref,
              { width, height }
            ]) => (
              <div
                ref={ref}
                className="flex-1 flex flex-col h-full">
                <ReactGoogleChart
                  {..._.merge(
                    {},
                    chartConfig,
                    {
                      options: {
                        width,
                        height
                      }
                    }
                  )}
                  getChartWrapper={
                    setChartWrap
                  }
                  {...{ width, height }}
                />
              </div>
            )}
          </Autosizer>
          {!_.isEmpty(
            item.data_columns
          ) &&
            _.filter(
              item.data_columns,
              'showCloseVal'
            ).length > 0 && (
              <div className="w-full flex flex-wrap items-center justify-center text-sm leading-tight">
                <span className="pr-3 font-medium">
                  {' '}
                  {t(
                    'last updated'
                  )}:{' '}
                </span>
                {_.get(item, 'data_columns', []).map(
                  (e, i) => {
                    if (_.isObject(e)) {
                      if (
                        e.showCloseVal
                      ) {
                        switch (
                          _.get(
                            e,
                            'type',
                            'string'
                          )
                        ) {
                          case 'date':
                            return (
                              <span
                                key={i}
                                className="mr-3 block">
                                <span className="px-1 text-color-300">
                                  {
                                    e.label
                                  }
                                  :
                                </span>
                                <span className="px-1 text-gray-700">
                                  {
                                    _last[
                                      i
                                    ]
                                  }
                                </span>
                              </span>
                            )
                          case 'number':
                            return (
                              <span
                                key={i}
                                className="mr-3 block">
                                <span className="px-1 text-color-300">
                                  {
                                    e.label
                                  }
                                  :
                                </span>
                                <span className="px-1 text-gray-700">
                                  {parseFloat(
                                    _last[
                                      i
                                    ]
                                  ).toLocaleString()}
                                </span>
                              </span>
                            )
                          default:
                            return (
                              <span
                                key={i}
                                className="mr-3 block">
                                <span className="px-1 text-color-300">
                                  {
                                    e.label
                                  }
                                  :
                                </span>
                                <span className="px-1 text-gray-700">
                                  {
                                    _last[
                                      i
                                    ]
                                  }
                                </span>
                              </span>
                            )
                        }
                      }
                    }
                  }
                )}
              </div>
            )}
        </React.Fragment>
      )
      break
  }
}

export default props => {
  return (
    <GoogleChartStateless {...props} />
  )
}
