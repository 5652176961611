import React, { useState } from 'react'
import { useLocation, useSearchParam } from 'react-use'
import { getURL } from '../functions'

export const filterQuery = 'filter'

export const langQuery = 'lang'

export const categoryQuery = 'category'

export const withFilter = (url, type) => {
  return getURL(url, { [filterQuery]: type })
}

export const FilterTypes = Object.freeze({
  DOCUMENT: 'document',
  CONTENT: 'content',
  TOPIC: 'topic',
  MYSPACE: 'my-space',
  CATEGORY: 'category',
})

const FilterContext = React.createContext({})

export const FilterProvider = ({ type, children, ...props }) => {
  const location = useLocation()
  const filterSearchParam = useSearchParam(filterQuery)
  const [selected, setSelected] = useState()
  return (
    <FilterContext.Provider
      key={location.search}
      value={{
        type,
        ...props,
        selected,
        setSelected,
        filterQuery: filterSearchParam,
      }}>
      {children}
    </FilterContext.Provider>
  )
}

export default FilterContext
