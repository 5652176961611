import React from 'react';
import PropTypes from 'prop-types';

const Translate = ({ children }, { t: translate }) => {
  return children(translate);
};
Translate.contextTypes = {
  t: PropTypes.func
};

export default Translate;
