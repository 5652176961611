import { RightOutlined } from '@ant-design/icons'
import { Button, Image } from 'antd'
import PhotoSvg from 'components/Svgs/PhotoSvg'
import { Photo } from 'conts/imageBase64'
import React, { useContext } from 'react'
import { VscArrowLeft } from 'react-icons/vsc'
import useToggle from 'react-use/lib/useToggle'
import ImagesLightBox from '../ImagesLightBox/ImagesLightBox'
import { LayoutContext } from '../layouts/Default/LayoutContext'
import { ImageEmbed } from './ImageEmbed'

export function GalleryEmbed({ images }) {
  const [open, toggle] = useToggle()
  const { isSm } = useContext(LayoutContext)
  if (!images || images.length === 0) return null
  if (images.length === 1) return <ImageEmbed image={images[0]} />
  return (
    <>
      <div
        onClick={toggle}
        style={{
            maxHeight: 300,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
        }}
        className="relative w-full background-200 rounded-lg overflow-hidden">
        <Image
          preview={false}
          wrapperClassName="w-full"
          style={{
              minHeight: isSm ? '7em' : '15em',
              height: 'auto',
              maxHeight: 300,
              width: '100%',
              objectFit: 'cover'
          }}
          fallback={Photo}
          placeholder={<PhotoSvg />}
          className="rounded-lg"
          src={images[0]}
        />
        <Button.Group
          size="small"
          className="z-10 absolute bottom-0 right-0 p-4">
          <Button
            type="primary"
            style={{
              border: 'none',
              background: 'rgba(0, 0, 0, 0.53)',
            }}
            icon={<VscArrowLeft />}
          />
          <Button
            type="primary"
            style={{
              border: 'none',
              background: 'rgba(0, 0, 0, 0.53)',
            }}>
            <span
              style={{
                fontWeight: 500,
              }}>
              1-{images.length} images
            </span>
          </Button>
          <Button
            type="primary"
            style={{
              border: 'none',
              background: 'rgba(0, 0, 0, 0.53)',
            }}
            icon={<RightOutlined />}
          />
        </Button.Group>
      </div>
      {open && (
        <ImagesLightBox
          images={images}
          onCloseRequest={toggle}
        />
      )}
    </>
  )
}
