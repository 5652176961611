import React from "react";

export const Chicken = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#fed500"
				d="M247.772 497.146c-5.845-3.825-13.582-6.59-22.264-8.076l21.202-59.131a7.612 7.612 0 00-14.33-5.138l-22.598 63.026c-14.371.107-27.676 3.486-36.59 9.319-3.518 2.302-4.503 7.02-2.2 10.538s7.02 4.503 10.538 2.2c6.547-4.286 17.371-6.844 28.953-6.844s22.406 2.558 28.954 6.844a7.607 7.607 0 0010.538-2.2 7.615 7.615 0 00-2.203-10.538z"
			></path>
			<path
				fill="#ff5426"
				d="M93.822 126.537c0 15.721-8.496 23.721-18.977 23.721s-18.977-8-18.977-23.721 7.618-44.042 18.098-44.042 19.856 28.321 19.856 44.042z"
			></path>
			<path
				fill="#f04424"
				d="M73.966 82.495c-1.018 0-2.007.274-2.967.77 5.671 9.623 9.651 25.206 9.651 35.447 0 15.721-8.496 23.721-18.977 23.721-.573 0-1.137-.034-1.697-.082 3.476 5.243 8.838 7.906 14.868 7.906 10.481 0 18.977-8 18.977-23.721s-9.374-44.041-19.855-44.041z"
			></path>
			<path
				fill="#ff5426"
				d="M112.815 0C102.407 0 93.42 5.992 89.051 14.701a21.057 21.057 0 00-12.162-3.857c-11.696 0-21.177 9.481-21.177 21.177 0 .291.032.574.044.862a18.165 18.165 0 00-1.624-.082c-9.983 0-18.075 8.092-18.075 18.075s8.093 18.075 18.075 18.075c9.388 0 17.101-7.158 17.987-16.314 1.536.354 3.128.56 4.771.56 6.81 0 12.853-3.228 16.727-8.222 4.842 5.058 11.646 8.222 19.2 8.222 14.69 0 26.599-11.909 26.599-26.599C139.414 11.909 127.506 0 112.815 0z"
			></path>
			<path
				fill="#f04424"
				d="M112.815 0c-3.202 0-6.267.571-9.107 1.61 10.191 3.726 17.465 13.508 17.465 24.988 0 11.479-7.271 21.259-17.459 24.987a26.414 26.414 0 009.101 1.612c14.69 0 26.599-11.909 26.599-26.599C139.414 11.909 127.506 0 112.815 0z"
			></path>
			<path
				fill="#ffd500"
				d="M82.97 100.78H41.783c-4.414 0-7.173-4.795-4.93-8.597 4.769-8.081 13.829-19.505 28.405-23.438z"
			></path>
			<path
				fill="#fbc700"
				d="M65.258 68.745a39.365 39.365 0 00-4.455 1.513l5.279 9.548c2.721 4.921-.838 10.957-6.462 10.957H37.718a68.21 68.21 0 00-.865 1.42c-2.243 3.802.516 8.597 4.93 8.597H82.97z"
			></path>
			<path
				fill="#efe2dd"
				d="M452.819 192.059H255.651a44.296 44.296 0 01-39.117-23.514l-38.646-72.751C160.5 63.06 126.462 42.599 89.397 42.599h-7.945c-11.925 0-22.614 8.035-25.239 19.667a25.324 25.324 0 008.924 25.446l22.1 17.574a7.098 7.098 0 012.26 7.961l-11.415 31.68a185.01 185.01 0 00-.177 124.945L97.73 325.38l.002.005c22.587 81.8 101.413 130.285 195.194 130.285 111.397 0 162.559-68.406 162.559-179.802 0-18.029 11.86-35.504 18.72-52.134 6.229-15.103-5.049-31.675-21.386-31.675z"
			></path>
			<path
				fill="#e1d3ce"
				d="M452.819 192.059h-16.226c-6.451 13.303-13.728 27.086-13.728 41.209 0 111.396-51.162 179.803-162.559 179.803-59.13 0-112.308-19.282-149.2-54.03 32.565 61.266 101.735 96.629 181.819 96.629 111.397 0 162.558-68.406 162.558-179.802 0-18.029 11.86-35.504 18.72-52.134 6.231-15.103-5.047-31.675-21.384-31.675zM55.883 64.02a25.378 25.378 0 00.195 8.849l.801-2.222a7.082 7.082 0 00-.996-6.627z"
			></path>
			<circle cx="93.173" cy="72.434" r="8.068" fill="#554e56"></circle>
			<path
				fill="#fed500"
				d="M345.252 498.018c-6.244-4.086-14.642-6.97-24.053-8.369v-67.471a7.612 7.612 0 10-15.224 0v66.54c-13.877.293-26.657 3.64-35.304 9.3a7.61 7.61 0 00-2.201 10.537 7.604 7.604 0 0010.537 2.201c6.548-4.285 17.373-6.843 28.955-6.843 11.583 0 22.407 2.558 28.953 6.843a7.615 7.615 0 0010.538-2.2 7.614 7.614 0 00-2.201-10.538z"
			></path>
			<path
				fill="#e1d3ce"
				d="M381.38 244.078c-3.552-15.172-17.083-25.901-32.665-25.901h-89.1c-37.555 0-68 30.445-68 68s30.445 68 68 68c15.749 0 30.236-5.369 41.763-14.356l.009.016s5.068-4.288 7.278-6.59l62.613-56.694a33.548 33.548 0 0010.102-32.475z"
			></path>
			<path
				fill="#cdbfba"
				d="M381.38 244.078c-2.318-9.901-8.886-17.907-17.5-22.277a33.546 33.546 0 01-8.763 36.831l-62.613 56.693c-2.21 2.302-7.278 6.59-7.278 6.59l-.009-.015c-11.527 8.987-26.014 14.356-41.763 14.356-15.69 0-30.127-5.328-41.633-14.254 11.996 19.31 33.388 32.175 57.793 32.175 15.749 0 30.236-5.369 41.763-14.356l.009.016s5.068-4.288 7.278-6.59l62.613-56.694a33.547 33.547 0 0010.103-32.475z"
			></path>
		</svg>
	);
}

export default Chicken;
