const logParams = (
  param,
  trace = false
) => {
  if (trace) {
    console.trace('param', param)
  } else {
    console.table({ param })
  }
  return param
}
export default logParams
