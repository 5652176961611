import Loadable from 'react-loadable'
import LoadingPage from 'views/LoadingPage'

export const Organization = Loadable(
  {
    loader: () =>
      import('./Organization.lazy'),
    loading: LoadingPage
  }
)
