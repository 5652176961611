import { API_ROOT_URL } from 'envs/_current/config'

// Route::post('payments/deposit-with-paypal', 'PaymentController@payWithPaypal'); // description, amount
export const payments_payWithPayPal_Api = {
  name: 'payments_payWithPayPal',
  backend: ``,
  root: API_ROOT_URL,
  path: '/payments/deposit-with-paypal',
  method: 'POST',
  fileds: ['description', 'amount']
}
// Route::post('payments/deposit-with-paypal/class/{class_id}/subscribe', 'PaymentController@depositClassSubscriptionWithPaypal');
// description, amount
export const billing_depositClassSubscriptionWithPaypal_Api = {
  name:
    'depositClassSubscriptionWithPaypal',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/payments/deposit-with-paypal/class/:code/subscribe',
  method: 'POST',
  fileds: ['description', 'amount']
}

// Route::post('payments/vnpay/deposit', 'VNPayController@depositWithVNPay'); // description, amount
export const payments_payViaGateway_Api = {
  name: 'payments_payViaGateway',
  backend: ``,
  root: API_ROOT_URL,
  path: '/payments/vnpay/deposit',
  method: 'POST',
  fileds: ['description', 'amount']
}
// Route::post('payments/vnpay/deposit/class/{class_id}/subscribe', 'VNPayController@depositClassSubscriptionWithVNPay'); // description, amount
export const billing_classSubscriptionViaGateway_Api = {
  name:
    'billing_classSubscriptionViaGateway',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/payments/vnpay/deposit/class/:code/subscribe',
  method: 'POST',
  fileds: ['description', 'amount']
}
