import classnames from 'classnames'
import React from 'react'
import './Cate.css'

export const Cate = ({
  handleItemClick,
  item,
  active,
  itemRef
}) => (
  <div
    ref={itemRef}
    onClick={handleItemClick}
    data-id={item.id}
    data-refid={item.alt_idname}
    data-effect="ripple"
    className={classnames(
      'px-3 py-2 cursor-pointer  rounded Cate capitalize',
      { active }
    )}>
    {item.name}
  </div>
)
