import { ROOT_URL } from 'envs/_current/config'
import { productSchema } from '../schema'
import base, {
  get,
  getDescription,
  getFullAvatarUrl,
  getLinkToShareGenerator,
  getMetaGenerator,
  getTitle,
  getType
} from './base'

const getId = get('idname')
export const getLinkToDetail = item =>
  `/mart/product/${getId(item)}`
export const getLinkToSetting = item =>
  `/mart/product/${getId(item)}`
export const getLinkToShare = getLinkToShareGenerator(
  { rootUrl: ROOT_URL, getType, getId }
)
export default {
  ...base,
  getLinkToShare,
  getLinkToDetail,
  getLinkToSetting,
  schema: productSchema,
  getId,
  getMeta: getMetaGenerator({
    getTitle,
    getDescription,
    getImage: getFullAvatarUrl,
    getUrl: getLinkToShare
  })
}
