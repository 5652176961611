import { Avatar, Button } from 'antd'
import classNames from 'classnames'
import ImageUpload from 'components/form/fields/ImageUpload'
import { createValue } from 'components/form/utils'
import ImageLoader from 'components/ImageLoader'
import { PhotoPlaceholderApp } from 'conts/imageBase64'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { BsCloudArrowUp, BsPersonFill, BsTrash } from 'react-icons/bs'
import { Null, renderSelf } from 'views/Shared'
import {PiCloudArrowUp, PiTrash} from "react-icons/pi";

const PhotoUploadWrapper = ({ children }) => (
  <div
    style={{ minHeight: 150 }}
    className="flex flex-col relative space-y-3 py-3">
    {children}
  </div>
)

const PhotoField = ({
  name,
  value,
  values,
  style,
  className,
  onChange = Null,
  getPreviewName = (name) => `${name}_preview`,
  Wrapper = PhotoUploadWrapper,
}) => {
  const t = useTranslate()

  const previewName = getPreviewName(name)

  const previewImage = _.get(values, previewName, value)

  return (
    <Wrapper>
      <div
        style={{
          position: 'relative',
          width: '100%',
          maxHeight: '15vw',
          ...(style || {}),
        }}
        className={classNames(
          'border rounded-md flex flex-1 justify-center items-center p-3 overflow-hidden',
          className
        )}>
        {previewImage && (
          <ImageLoader
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              background: '#f1f1f1',
              borderRadius: '0.375rem',
            }}
            src={previewImage}
            alt="photo upload"
          />
        )}
        <div className="flex flex-col justify-center items-center absolute">
          <Button
            danger={true}
            type="primary"
            className="rounded-md no-shadow no-text-shadow flex flex-center"
            icon={<BsTrash />}
            onClick={() => {
              onChange(createValue(name, null))
              onChange(createValue(previewName, null))
            }}
          />
          <ImageUpload
            name={name}
            value={value}
            maxWidth={1920}
            maxHeight={384}
            onChange={onChange}
            renderPreview={Null}
            showUploadList={false}
            getPreviewName={getPreviewName}>
            <Button className="leading-none flex items-center gap-1 no-border button-rounded-md btn-upload-img hover:btn-upload-img">
              <BsCloudArrowUp size={16} />
              {`${t('add photo')}`}
            </Button>
          </ImageUpload>
        </div>
      </div>
    </Wrapper>
  )
}

const defautltFieldNames = {
  avatar: 'avatar',
  cover_photo: 'cover_photo',
  avatar_preview: 'avatar_preview',
  cover_photo_preview: 'cover_photo_preview',
}

export const PhotoUploadComposite = ({
  title,
  values,
  onChange,
  renderAvatar = renderSelf,
  renderCoverUploader = renderSelf,
  renderAvatarUploader = renderSelf,
  fieldNames = defautltFieldNames,
  fallback_cover = PhotoPlaceholderApp,
}) => {
  const cover_photo_preview = _.get(values, fieldNames.cover_photo_preview)
  return (
    <div
      style={{ zIndex: 11 }}
      className="w-full relative HomeHeader overflow-hidden">
      <div
        className="w-full relative overflow-hidden rounded-md"
        style={{
          height: '200px',
          background: 'var(--background)',
        }}>
        <div
          key={cover_photo_preview}
          className="flex"
          style={{
            top: 0,
            left: 0,
            position: 'absolute',
            width: '100%',
            height: '200px',
            objectFit: 'cover',
            background: 'var(--background)',
            backgroundImage: `url(${cover_photo_preview ?? fallback_cover})`,
              backgroundSize: 'cover',
              backgroundPosition: '50% 50%',
          }}
        />
        <div
          className="w-full absolute top-0 left-0"
          style={{
            height: '200px',
            objectFit: 'cover',
          }}
        />
        <div
          className="w-full flex flex-col absolute bottom-0 left-0"
          style={{
            padding: '0.9em',
          }}>
          {renderAvatar(
            <Avatar
              size={80}
              className="border-2 border-color-50 rounded-lg background-200"
              src={_.get(values, fieldNames.avatar_preview)}
              icon={<BsPersonFill className="text-color-400" />}
              shape="square"
            />
          )}
          <Translate>
            {(t) => (
              <div className="flex justify-around">
                {renderAvatarUploader(
                  <ImageUpload
                    onChange={onChange}
                    renderPreview={Null}
                    showUploadList={false}
                    name={fieldNames.avatar}>
                    <div className="flex items-center gap-2 px-3 py-1 rounded-lg btn-upload-img hover:btn-upload-img">
                      <BsCloudArrowUp size={20} className="text-white" />
                      <div className="flex flex-col leading-tight text-white">
                        <span className="font-medium text-xs">
                          {t('avatar')}
                        </span>
                        <span className="text-2xs font-light italic opacity-50">
                          {'( 128 x 128 )'}
                        </span>
                      </div>
                    </div>
                  </ImageUpload>
                )}
                {renderCoverUploader(
                  <ImageUpload
                    onChange={onChange}
                    maxWidth={1920}
                    maxHeight={384}
                    renderPreview={Null}
                    showUploadList={false}
                    name={fieldNames.cover_photo}>
                    <div className="flex items-center gap-2 px-3 py-1 rounded-lg btn-upload-img hover:btn-upload-img">
                      <BsCloudArrowUp size={20} className="text-white" />
                      <div className="flex flex-col leading-tight text-white">
                        <span className="font-medium text-xs">
                          {t('cover')}
                        </span>
                        <span className="text-2xs font-light italic opacity-50">
                          {'( 1280 x 256 )'}
                        </span>
                      </div>
                    </div>
                  </ImageUpload>
                )}
              </div>
            )}
          </Translate>
        </div>
      </div>
    </div>
  )
}

export const PhotoArrayUpload = ({
  values,
  onChange,
  slots = [],
  name: fieldName,
}) => {
  const t = useTranslate()
  return (
    <div id="photos" className="flex flex-col gap-4 relative">
        <div className="flex flex-col">
            <span className="font-bold text-lg text-color-000">
                {t('Pictures on the home page')}
            </span>
            <span className="text-color-300 text-sm italic">
                {t("The banner image will be placed on the company's homepage. Best view with 256 x 1280 pixels resolution. Image type: PNG, JPG.")}
            </span>
        </div>
        <div className="flex flex-col gap-4">
            {Array.from(slots || []).map(({ name, preview }, index) => {
                const previewPhoto = _.get(values, preview)
                return (
                    <div
                        key={index}
                        className="w-full relative background-100 overflow-hidden rounded-lg"
                        style={{ height: '234px' }}>
                        {previewPhoto && (
                            <ImageLoader
                                style={{ height: '234px' }}
                                prefixCls="flex"
                                className="w-full absolute background object-cover top-0 left-0 rounded-lg"
                                src={previewPhoto}
                            />
                        )}
                        <div className="flex flex-col h-full flex-center space-y-2 rounded-md cursor-pointer">
                            <div className="flex flex-center p-1 rounded-md custom-btn-trash">
                                <PiTrash
                                    size={20}
                                    onClick={() => {
                                        onChange(createValue(name, null))
                                        onChange(createValue(preview, null))
                                    }}
                                />
                            </div>
                            <ImageUpload
                                name={name}
                                showUploadList={false}
                                onChange={onChange}
                                renderPreview={Null}
                                maxWidth={1280}
                                maxHeight={256}>
                                <Button className="flex items-center gap-2 button-rounded-lg no-border btn-upload-img">
                                    <PiCloudArrowUp size={20} />
                                    <span className="font-medium text-sm flex items-center">
                    {t('add photo')} {index}
                                        <span className="ml-2 italic font-light text-xs">
                      ( 1280 x 256 )
                    </span>
                  </span>
                                </Button>
                            </ImageUpload>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>
  )
}

export default PhotoField
