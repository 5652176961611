import Icon from '@ant-design/icons'
import {Select} from 'antd'
import classNames from 'classnames'
import Count from 'components/Feed/Count'
import filterOption from 'helpers/filterOption'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo, useRef, useState} from 'react'
import {FaMapMarkerAlt} from 'react-icons/fa'
import {useProvinces} from 'views/Organization/Settings/SelectProvinces'
import {Button} from 'antd'
import {LayoutContext} from "../../../components/layouts/Default/LayoutContext";

export function SelectLocation({
  value: province,
  onChange,
  related_provinces
}) {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)
  const cacherelated_provincesRef = useRef(
    related_provinces
  )
  if (!_.isEmpty(related_provinces)) {
    cacherelated_provincesRef.current = related_provinces
  }
  const {
    provinceList,
    isLoading,
    handleAsyncAction,
    onChange: handleChange
  } = useProvinces({
    onChange: onChange
  })
  const options = useMemo(() => {
    return [
      {
        value: '',
        label: t('all vietnam')
      },
      ...provinceList
    ].map(o => ({
      ...o,
      customlabel: (
        <span>
          <span className="hidden md:inline text-sm font-semibold mr-1 text-color-300 capitalize">
            {`${t('location')} : `}
          </span>
          <span>{o.label}</span>
        </span>
      )
    }))
  }, [provinceList, t])
  const [fosus, setfocus] = useState()
  const customfilterOption = filterOption
  return (
    <div className="w-full m-auto flex gap-3 overflow-y-hidden overflow-x-auto">
      <div
          tabIndex={0}
          onClick={() => handleChange('')}
          style={{ cursor: 'pointer', borderRadius: '0.375rem', fontWeight: 500, fontSize: '0.85rem' }}
          className="px-4 flex flex-center border hover:border-primary-600 background hover:bg-primary-50 text-color-300 hover:text-primary-600">
        {t('all vietnam')}
      </div>
      <Select
          showSearch
          onDropdownVisibleChange={
            setfocus
          }
          style={{ width: isSm ? 160 : 180, fontSize: '0.85rem' }}
          className="w-full rounded-md"
          bordered={true}
          virtual={false}
          value={province}
          optionFilterProp="searchString"
          filterOption={customfilterOption}
          defaultValue={''}
          placeholder={t('provinces')}
          suffixIcon={ <FaMapMarkerAlt size={14}/> }
          onChange={handleChange}
          options={options}
      />

      {(cacherelated_provincesRef.current || provinceList)
        .filter((item, i) => i < 6).map(({ value, label }) => {
          return (
            <Count
              active={
                province === '' + value
              }
              color="text-primary province-button"
              key={value}>
              <div
                onClick={() =>
                  handleChange(value)
                }
                tabIndex={0}
                className={classNames(
                  'px-4 py-2 rounded-full background-100 hover:background-200 text-xs md:text-base flex-shrink-0 truncate opacity-75'
                )}>
                {label}
              </div>
            </Count>
          )
        })}
    </div>
  )
}
