import _ from 'lodash'

export const AppEntities = Object.freeze({
  USER: 'user',
  POST: 'post',
  EVENT: 'event',
  ARTICLE: 'article',
  CHANNEL: 'channel',
  TOPIC: 'topic',
  KEYWORD: 'keyword',
  CATEGORY: 'category',
  UNDEFINED: 'undefined',
  PRODUCT: 'product',
  DATASET: 'dataset',
  ORGANIZATION: 'organization',
})

export const customLabels = Object.freeze({
  user: 'user',
  post: 'post',
  event: 'event',
  article: 'articles',
  channel: 'channel',
  topic: 'topic',
  keyword: 'keyword',
  category: 'category',
  undefined: 'undefined',
  organization: 'organization',
  product: 'product-service',
})

export const fromAppEntity = (type, which) => {
  let value
  switch (type) {
    default:
      value = {
        pathname: type,
        contextName: type,
        displayName: type,
      }
      break
  }

  return which ? _.get(value, which) : value
}

export const MaterialTypes = Object.freeze({
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
})

export const LAWMessageTypes = Object.freeze({
  EMPTY: 'empty',
  ERROR: 'error',
})
