import React from "react";

export const OilSeeds = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#e2a975"
				d="M428.448 370.265c-30.355 16.672-64.654 22.976-96.577 17.748-29.838-4.886-50.064-33.035-45.178-62.872s33.033-50.064 62.872-45.178c16.239 2.659 38.526-9.582 44.993-24.713 11.883-27.803 44.055-40.706 71.855-28.823 27.802 11.883 40.706 44.054 28.823 71.856-12.714 29.746-36.434 55.31-66.788 71.982z"
			></path>
			<path
				fill="#6dc17d"
				d="M330.594 280.175a39.499 39.499 0 003.992-.585 55.044 55.044 0 00-3.992.585z"
			></path>
			<path
				fill="#d19a6e"
				d="M466.414 226.427a54.489 54.489 0 00-34.888-2.74c2.727.693 5.435 1.6 8.103 2.74 27.802 11.883 40.706 44.054 28.823 71.856-12.714 29.746-36.434 55.31-66.788 71.982-19.241 10.568-40.066 16.964-60.867 18.903 29.318 2.815 60.109-3.775 87.652-18.903 30.354-16.672 54.075-42.236 66.789-71.982 11.882-27.802-1.022-59.973-28.824-71.856z"
			></path>
			<path
				fill="#d19a6e"
				d="M392.818 308.135a7.725 7.725 0 01-6.799-4.045 7.726 7.726 0 013.109-10.475l2.056-1.115c8.576-5.411 15.737-12.309 20.718-19.959a7.728 7.728 0 0112.95 8.432c-6.236 9.577-15.111 18.135-25.664 24.749a8.024 8.024 0 01-.419.245l-2.275 1.233a7.7 7.7 0 01-3.676.935z"
			></path>
			<path
				fill="#75cff9"
				d="M108.739 285.821a20.72 20.72 0 01-8.952-2.037C41.65 255.997 14.409 186.065 1.794 132.291c-4.707-20.069-.052-40.857 12.771-57.034 12.867-16.234 32.124-25.545 52.834-25.545h121.67c11.485 0 20.794 9.31 20.794 20.794S200.553 91.3 189.069 91.3H67.399c-7.933 0-15.311 3.568-20.241 9.789-4.887 6.165-6.663 14.076-4.874 21.705 8.625 36.768 29.895 101.701 75.436 123.466 10.362 4.953 14.747 17.367 9.795 27.728-3.57 7.468-11.017 11.832-18.776 11.833z"
			></path>
			<path
				fill="#91dafa"
				d="M367.159 269.528l-135.519 30-142.093-30 44.813-47.57c23.66-25.13 36.99-59.45 36.99-95.27v-87.81c0-5.94 4.81-10.74 10.74-10.74H314.2c9.49 0 17.18 7.69 17.18 17.18 0 5.47-2.61 10.61-7.01 13.85l-13.44 9.86a62.685 62.685 0 00-25.58 50.52v7.14c0 35.82 13.33 70.14 37 95.27z"
			></path>
			<path
				fill="#75cff9"
				d="M367.159 269.528l-26.363 4.362-8.664-4.362-44.811-47.57c-23.67-25.13-37-59.45-37-95.27v-7.14c0-19.95 9.5-38.71 25.58-50.52l13.44-9.87c4.41-3.23 7.01-8.37 7.01-13.84 0-9.49-7.69-17.18-17.18-17.18h35.03c9.49 0 17.18 7.69 17.18 17.18 0 5.47-2.61 10.61-7.01 13.85l-13.44 9.86a62.685 62.685 0 00-25.58 50.52v7.14c0 35.82 13.33 70.14 37 95.27z"
			></path>
			<path
				fill="#f5de46"
				d="M40.881 321.189c-16.545 17.564-25.862 41.557-25.862 66.598 0 35.668 18.824 68.225 48.519 83.915 30.687 16.213 298.941 16.214 329.628 0 29.695-15.689 48.519-48.246 48.519-83.914 0-25.041-9.318-49.034-25.863-66.599l-48.663-51.661H89.547z"
			></path>
			<path
				fill="#ecc52e"
				d="M415.822 321.189l-48.663-51.661h-35.028l48.664 51.661c16.545 17.564 25.863 41.558 25.863 66.599 0 35.668-18.825 72.346-48.519 88.035-2.989 1.579-8.241 3.004-15.3 4.276 25.008-2.001 43.517-4.8 50.326-8.397 29.695-15.689 48.519-48.246 48.519-83.914.001-25.042-9.317-49.035-25.862-66.599z"
			></path>
			<path
				fill="#e2a975"
				d="M397.823 476.607c-34.632 0-67.729-10.987-93.193-30.939-23.8-18.647-27.977-53.056-9.33-76.856 18.646-23.8 53.055-27.978 76.856-9.33 12.953 10.149 38.38 10.149 51.333 0 23.8-18.648 58.21-14.469 76.857 9.33 18.647 23.8 14.47 58.21-9.33 76.857-25.464 19.951-58.561 30.938-93.193 30.938z"
			></path>
			<g fill="#d19a6e">
				<path d="M500.346 368.812c-14.832-18.93-39.633-25.431-61.14-17.92 13.751 1.061 27.115 7.276 36.93 18.461 19.944 22.725 17.689 57.314-5.036 77.257-14.321 12.568-31.46 22.175-50.194 28.346 26.061-3.754 50.378-13.826 70.11-29.286 23.8-18.649 27.977-53.058 9.33-76.858z"></path>
				<path d="M397.822 406.103c-14.46 0-28.232-3.28-39.829-9.485a7.726 7.726 0 117.29-13.625c9.362 5.01 20.614 7.657 32.539 7.657 11.922 0 23.178-2.648 32.55-7.659a7.725 7.725 0 0110.457 3.172 7.726 7.726 0 01-3.171 10.456c-11.607 6.204-25.381 9.484-39.836 9.484z"></path>
			</g>
		</svg>
	);
}

export default OilSeeds;
