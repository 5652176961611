import {LoadingOutlined} from '@ant-design/icons'
import {Avatar, Button, Card, Spin} from 'antd'
import {search_fetchObjects_Api, search_getTags_Api, url_getMetaTags_Api,} from 'apis'
import {articleModel} from 'apis/model'
import {getId, getType} from 'apis/model/base'
import classNames from 'classnames'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import {permissionCodes} from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {useContext, useEffect, useMemo, useRef, useState,} from 'react'
import * as ReactDOM from 'react-dom'
import {HiPaperAirplane} from 'react-icons/hi2'
import {IoIosClose} from 'react-icons/io'
import {PiPlusBold} from 'react-icons/pi'
import {RiWechatLine,} from 'react-icons/ri'
import Loadable from 'react-loadable'
import ReactPlayer from 'react-player'
import {connect, ReactReduxContext} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import useClickAway from 'react-use/lib/useClickAway'
import {branch} from 'recompose'
import {getLogin} from 'redux/selectors'
import ChannelContext from 'views/Channel/ChannelContext'
import LoadingPage from 'views/LoadingPage'
import {SelectList} from 'views/Search/SelectList'
import {useDeBounceValue} from 'views/Search/useDeBounceValue'
import {getResponseItems, Null} from 'views/Shared'
import {renderIf, renderIfElse} from '../views/Shared'
import {BaseEmbed} from './Feed/BaseEmbed'
import ContentEditableInput from './Feed/InputBoard/ContentEditableInput'
import {encryptMentionTitle, mark_as_mention, MentionSuggestionItem, renderMention,} from './Feed/Mention'
import MentionDataContext, {MentionDataProvider,} from './Feed/MentionDataContext'
import {VideoEmbed} from './Feed/VideoEmbed'
import './InputBar.css'
import {LayoutContext} from './layouts/Default/LayoutContext'
import NullComponent from './NullComponent'
import {CodiSelectorActions, useInputUtils,} from './RichSlateEditor/utils/useEditorUtils'
import {BsBarChartLineFill, BsImage, BsLink45Deg, BsNewspaper} from "react-icons/bs";

const EmojiPicker = Loadable({
  loader: () => import('./EmojiPicker'),
  loading: ({isLoading}) => isLoading && <LoadingPage/>,
})

export const gridStyle = {
  width: '100%',
  padding: '0.3em 1em',
  lineHeight: '2em',
}
export const useSuggestMentions = (value) => {
  const apiInfo = search_fetchObjects_Api()
  const [data, setData] = useState([])
  const debounceValue = useDeBounceValue(value, 500)
  const {
    response,
    isLoading = false,
    handleAsyncAction,
  } = useAsyncAction({
    apiInfo,
    onSuccess: (__, data) => {
      setData([
        ...Array.from(getResponseItems(data?.response) ?? [])
          .map((item) => ({
            name: getTitle(item),
            type: _.get(item, 'owner._type'),
            owner_id: _.get(item, 'owner.id'),
            id:
              _.get(item, 'owner.username') ||
              _.get(item, 'owner.idname') ||
              _.get(item, 'owner.id'),
            link: `/${_.get(item, 'owner._type')}/${
              _.get(item, 'idname') || _.get(item, 'owner.username')
            }`,
            label: getTitle(item),
            avatar: getAvatar(item),
          }))
          .filter((item, i) => i < 7),
      ])
    },
  })

  useEffect(() => {
    if (debounceValue && debounceValue.length > 0) {
      handleAsyncAction({
        keyword: debounceValue,
      })
    } else {
      setData([])
    }
  }, [debounceValue])

  return {
    isLoading,
    data,
  }
}
export const useTagSuggestMentions = (value = '') => {
  const debounceValue = useDeBounceValue(value, 500)
  const {
    response,
    isLoading = false,
    handleAsyncAction,
  } = useAsyncAction({
    apiInfo: search_getTags_Api,
  })
  useEffect(() => {
    if (debounceValue && debounceValue.length > 0) {
      handleAsyncAction(
        {},
        {
          keyword: debounceValue,
        }
      )
    }
  }, [debounceValue])

  const suggestions = [
    {
      label: value,
      name: '#' + value,
      link: `/t/${value}`,
    },
    ..._.get(response, 'data.data', [])
      .map((item) => ({
        item,
        label: _.get(item, 'tags'),
        name: '#' + _.get(item, 'tags'),
        link: `/t/${_.get(item, 'tags')}`,
      }))
      .filter((item, i) => _.get(item, 'tags') !== value && i < 7),
  ]
  return {isLoading, data: suggestions}
}

function withModifier(text, modifier = '@', min = 0) {
  const string = String(text ?? '')
  if (string.length > _.defaultTo(min, 0)) {
    return string[0] === modifier || string.endsWith(`\n${modifier}`)
  } else {
    return false
  }
}

export const useInputBar = ({
                              name,
                              value,
                              mention_count,
                              onChange = Null,
                              onMatched = Null,
                              willDismiss = Null,
                              onMentioned = Null,
                            }) => {
  const uid = useRef(Number(mention_count ?? 0))

  const [selection, setSelection] = useState([, ,])

  const handleSelect = (e) => {
    setSelection([e.target.selectionStart, e.target.selectionEnd])
    const str = e.target.value
    let end = Math.max(e.target.selectionEnd, 0)
    for (
      let i = end;
      i < str.length && str[i] !== ' ' && str[i] !== '\n';
      i++
    ) {
      end += 1
    }

    let start = end
    let text = ''
    if (str && end) {
      for (let i = end - 1; i >= 0 && str[i] !== ' ' && str[i] !== '\n'; i--) {
        start = i
        text = (str[i] ?? '') + (text ?? '')
      }
      const selected_text = {
        start,
        end,
        text: text.substring(1),
      }
      willDismiss(str, text)
      if (withModifier(text, '@', 0)) {
        onMatched(selected_text, '@')
      }
      if (withModifier(text, '#', 1)) {
        onMatched(selected_text, '#')
      }
    } else if (_.isEmpty(str)) {
      onMatched('')
    }
  }

  const {store} = useContext(ReactReduxContext)

  const mention = (values) => {
    const {owner, link, content} = values ?? {}
    const owner_id = getId(owner)
    const owner_type = getType(owner)
    // const name = encryptMentionTitle(link)
    const string = `@${owner_type}:${owner_id}:${encodeURI(link)}`
    const key = mark_as_mention(link, {
      owner_id,
      owner_type,
      index: uid.current,
    })
    uid.current = uid.current + 1
    const _text =
      (String(content ?? '').length ? `${content} ` : ` `) + `${string} `
    onMentioned({
      text: _text,
      mention: {[key]: string},
    })
  }

  const utils = useInputUtils({
    store,
    hasSelection:
      Array.from(selection ?? []).length > 1
        ? selection[0] === selection[1]
        : false,
    handleSubmit: (action, values) => {
      switch (action) {
        default:
          break
      }
    },
  })

  const actions = Array.from(utils?.actions ?? [])

  return {
    name,
    actions,
    mention,
    text: value,
    handleSelect,
    handlechange: onChange,
  }
}

export const InputBarContainer = React.forwardRef(
  ({children, ...props}, ref) => {
    const data = useInputBar({...props, ref})
    return children(data)
  }
)

const InputBar = ({
                    rows = 3,
                    style = {},
                    defaultHeight = 45,
                    defaultValue = '',
                    textOnly,
                    mention_count,
                    message = false,
                    bordered = true,
                    isLoading = false,
                    placement = 'top',
                    placeholder = 'share discussion',
                    handleSubmit: onSubmit = NullComponent,
                    onItemClick = NullComponent,
                    className = 'background',
                  }) => {
  const translate = useTranslate()
  const history = useHistory()
  const {item} = useContext(ChannelContext)
  const {isSm} = useContext(LayoutContext)
  const [loading, setLoading] = useState(false)
  const [text, setText] = useState(defaultValue)
  const [linkMeta, setLinkMeta] = useState()
  const mentionRef = useRef()
  const textAreaRef = useRef()
  const popupRef = useRef(null)
  useClickAway(popupRef, () => {
    handleOnClicks.none()
  })
  // file,emoji
  const [select, setSelect] = useState(null)
  const [selection, setSelection] = useState([,,])
  const [height, setHeight] = useState(defaultHeight)
  const handleOnClicks = useMemo(
    () => ({
      none: setSelect.bind(null, null),
      options: setSelect.bind(null, 'options'),
      file: setSelect.bind(null, 'file'),
      emoji: setSelect.bind(null, 'emoji'),
    }),
    []
  )
  const isReady = !_.isEmpty(text)
  useEffect(() => {
    if (!text || text.length === 0) {
      setHeight(defaultHeight)
    } else {
      setHeight(
        Math.min(
          Math.max(
            textAreaRef.current.scrollHeight ?? defaultHeight,
            defaultHeight
          ),
          200
        )
      )
    }
  }, [text])

  const handleOnEmojiSelect = (emoji) => {
    setText(text + emoji.native || emoji.colons)
  }
  const handleOnchange = (e) => {
    setText(e.target.value)
  }

  const {data: suggesstions, isLoading: mentionLoading = false} =
    useSuggestMentions(mentionRef.current?.text)

  const isOpenedSuggestions = mentionRef.current?.prefix === '@'

  const {data: tags, isLoading: tagsLoading = false} = useTagSuggestMentions(
    mentionRef.current?.text
  )

  const isOpenedTagSuggestions = mentionRef.current?.prefix === '#'

  const uid = useRef(Number(mention_count ?? 0))

  const {push = Null} = useContext(MentionDataContext)

  const handleSubmit = (text) => {
    if (!String(text ?? '').length || !!isLoading) return
    setLoading(true)
    onSubmit({
      description: text,
      embed_data: linkMeta ? JSON.stringify(linkMeta) : undefined,
    })
  }

  const {store} = useContext(ReactReduxContext)

  const {actions = []} = useInputUtils({
    store,
    hasSelection:
      Array.from(selection ?? []).length > 1
        ? selection[0] === selection[1]
        : false,
    handleSubmit: (action, values) => {
      const {owner, link, content} = values ?? {}
      const owner_id = getId(owner)
      const owner_type = getType(owner)
      switch (action) {
        default:
          break
      }
    },
  })

  const insertText = (value, suffix = ' ') => {
    const ref = ReactDOM.findDOMNode(textAreaRef.current)
    const _value = textAreaRef.current?.props?.value ?? ''
    const _mentionStr = `${mentionRef.current?.prefix ?? ''}${
      mentionRef.current?.text ?? ''
    }`
    const newValue = _value
      .split(' ')
      .map((e) => {
        const ee = e
          .split('\n')
          .map((e) => {
            if (e === _mentionStr) return ` ${value}${suffix}`
            return e
          })
          .join('\n')

        if (ee === _mentionStr) return `${value}${suffix}`
        return ee
      })
      .join(` `)
    setText(newValue)
    mentionRef.current = {}
    if (ref) ref.focus()
  }

  const insertHash = (value) => {
    insertText(value)
  }

  const insertMention = (value) => {
    insertText(value)
  }

  const handleOnKeyDown = (e, params) => {
    if (loading) return

    if (mentionRef.current?.prefix === '@') {
      if ([40, 38, 13].includes(e.keyCode) || (e.key === 'Enter')) {
        e.preventDefault()
        return
      }
    }
    if (mentionRef.current?.prefix === '#') {
      if ([40, 38, 13].includes(e.keyCode) || (e.key === 'Enter')) {
        e.preventDefault()
        return
      }
    }
    if (e.key === 'Escape') {
      mentionRef.current = {}
    }
    if (!!isSm) {
      if (e.key === 'Enter') {
        e.preventDefault()
        document.execCommand('insertLineBreak')
      }
    } else {
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault()
        document.execCommand('insertLineBreak')
      } else if (e.key === 'Enter') {
        e.preventDefault()
        if(!textAreaRef.current?.state?.isLoading) handleSubmit(params?.value)
      }
    }
  }

  const {isLoading: loadingMeta, handleAsyncAction: getMetaFromUrl} =
    useAsyncAction({
      apiInfo: url_getMetaTags_Api,
      onSuccess: (result, asyncApi) => {
        const {response} = asyncApi
        const linkMeta = _.get(response, 'data')
        if (linkMeta) {
          setLinkMeta(linkMeta)
        }
      },
    })

  const canSubmit = !_.some(
    [loading, isLoading, tagsLoading, mentionLoading],
    Boolean
  )

  const handleMentionClick = ({id, type, name}) => {
    if (mentionRef.current?.prefix && mentionRef.current?.text) {
      const string = `@${type}:${id}:${encodeURI(name)}`
      insertMention(string)
    }
  }

  const {handleSelect} = useInputBar({
    value: text,
    onChange: handleOnchange,
    mention_count,
    name: 'description',
    onMatched: (value, prefix) => {
      switch (prefix) {
        case '#':
        case '@':
          mentionRef.current = {
            ...(value ?? {}),
            prefix,
          }
          break
        default:
          mentionRef.current = {}
          break
      }
    },
    willDismiss: (str, text) => {
      if (isOpenedTagSuggestions || isOpenedSuggestions) {
        if (_.isEmpty(text ?? '')) {
          mentionRef.current = {}
        } else if (
          _.isEmpty(
            str.substring(mentionRef.current?.start, mentionRef.current?.end)
          )
        ) {
          mentionRef.current = {}
        }
      }
    },
  })

  return (
    <div
      className={classNames('w-full flex flex-col InputBar relative', className, {
        ' background': linkMeta || loadingMeta,
      })}>
      <div
        style={{
          height,
          borderRadius: '0.5rem',
          ...style,
        }}
        className={classNames(
          'w-full flex  relative',
          className,
          bordered && 'border'
        )}>
        {!textOnly && (
          <div
            onClick={handleOnClicks.options}
            className="px-2 py-2 my-2 flex flex-center cursor-pointer border-r">
            <PiPlusBold
              size={20}
              className="text-color-400 hover:text-color-100"
            />
          </div>
        )}
        <ContentEditableInput
            placeholder={`${translate(placeholder)}${renderIfElse(
                isSm || rows === 1,
                '',
                ' \n' + translate('Use Shift+Enter to break line')
            )}`}
          value={text}
          ref={textAreaRef}
          onChange={handleOnchange}
          onSelect={handleSelect}
          onKeyDown={handleOnKeyDown}
          style={{
            height: rows !== 1 && height - 3,
            fontSize: rows === 1 ? 13 : 14,
            whiteSpace: rows === 1 ? 'nowrap' : 'pre-wrap',
            width: rows === 1 ? '98%' : '100%',
            overflowX: rows === 1 && 'hidden',
            paddingRight: rows !== 1 && 32,
            ...style
          }}
          className={classNames("pl-3 py-3 flex-1 bg-transparent outline-none ", rows > 1 &&  "overflow-auto scrollBarThin")}
          onPaste={(e) => {
            e.preventDefault()
            const text = e.clipboardData.getData('Text')
            const LINK_DETECTION_REGEX = /(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*/g
            switch (true) {
              case !!LINK_DETECTION_REGEX.exec(text):
                getMetaFromUrl({
                  content: text,
                })
                break
              default:
                break
            }
          }}
        />
        {isOpenedSuggestions && (
          <div
            style={{
              ...(placement === 'top'
                ? {top: height}
                : placement === 'bottom'
                  ? {bottom: height}
                  : {}),
              maxWidth: isSm ? '100%' : '500px',
            }}
            className="my-2 absolute background shadow-md border border-color-50 rounded-md z-10">
            <Spin
              indicator={null}
              spinning={!!mentionLoading}>
              <SelectList
                onSelected={(e) => {
                  e.props.onClick()
                }}>
                {[
                  ...(actions.length
                    ? [{..._.first(actions), fixed: true}]
                    : []),
                  ...suggesstions,
                ].map(renderMention({onClick: handleMentionClick}))}
              </SelectList>
            </Spin>
          </div>
        )}
        {renderIfElse(
          !message,
            <Button
                icon={
                  canSubmit ? (
                      <HiPaperAirplane
                          size={20}
                          className={isReady ? 'text-primary' : 'text-color-400'}
                      />
                  ) : (
                      <LoadingOutlined size={20}/>
                  )
                }
                style={{
                  position: 'absolute',
                  padding: 0,
                  right: 2,
                  bottom: height === 45 ? '50%' : 2,
                  transform: height === 45 && 'translate(0%, 50%)',
                  background: 'transparent',
                }}
                type="primary"
                className={classNames(
                    'button-rounded-md no-border flex flex-center no-shadow',
                    isReady ? 'cursor-pointer' : 'cursor-not-allow'
                )}
                onClick={() => {
                  handleSubmit(text)
                }}
            />,
            <Button
                icon={
                  canSubmit ? (
                      <HiPaperAirplane
                          size={20}
                          className={isReady ? 'text-primary' : 'text-color-400'}
                      />
                  ) : (
                      <LoadingOutlined size={20}/>
                  )
                }
                style={{
                  position: 'absolute',
                  padding: 0,
                  right: 0,
                  bottom: 0,
                  background: 'transparent',
                }}
                type="primary"
                className={classNames(
                    'button-rounded-md no-border flex flex-center no-shadow',
                    isReady ? 'cursor-pointer' : 'cursor-not-allow'
                )}
                onClick={() => {
                  handleSubmit(text)
                }}
            />
        )}
      </div>
      {renderIf(
        placement,
        <div
          style={{
            ...(placement === 'top'
              ? {top: height + 5}
              : placement === 'bottom'
                ? {bottom: height + 5}
                : {}),
            maxWidth: isSm ? '100%' : '500px',
          }}
          className="my-2 absolute background shadow-md border border-color-50 rounded-md z-10">
          {isOpenedTagSuggestions && (
            <Spin
              indicator={null}
              spinning={!!tagsLoading}>
              <SelectList
                onSelected={(e) => {
                  e.props.onClick()
                }}>
                {tags.map(({name, id, type, label, avatar}, index) => (
                  <MentionSuggestionItem
                    key={index}
                    title={label}
                    {...{
                      id,
                      name,
                      type,
                      avatar,
                    }}
                    showAvatar={false}
                    onClick={() => {
                      if (
                        mentionRef.current?.prefix &&
                        mentionRef.current?.text
                      ) {
                        insertHash(name)
                      }
                    }}
                  />
                ))}
              </SelectList>
            </Spin>
          )}
          {loadingMeta && (
            <div className="p-3 flex">
              <LoadingPage/>
            </div>
          )}
          {linkMeta && (
            <>
              {!!ReactPlayer.canPlay(linkMeta.url) ? (
                <>
                  <VideoEmbed
                    title={_.get(linkMeta, 'title')}
                    url={_.get(linkMeta, 'url')}
                    subTitle={
                      _.get(linkMeta, 'host_url') || _.get(linkMeta, 'url')
                    }
                    description={_.get(linkMeta, 'description')}
                    image={_.get(linkMeta, 'photo')}
                  />
                  <Avatar
                    onClick={() => setLinkMeta(null)}
                    style={{
                      position: 'absolute',
                      right: -5,
                      top: -5,
                    }}
                    icon={<IoIosClose size={14}/>}
                    size={20}
                    className="flex flex-center text-color-300 background-100 hover:background-200 cursor-pointer"
                  />
                </>
              ) : (
                <div className="p-2 relative">
                  <BaseEmbed
                    title={_.get(linkMeta, 'title')}
                    subTitle={
                      _.get(linkMeta, 'host_url') || _.get(linkMeta, 'url')
                    }
                    description={_.get(linkMeta, 'description')}
                    image={_.get(linkMeta, 'photo')}
                  />
                  <Avatar
                    onClick={() => setLinkMeta(null)}
                    style={{
                      position: 'absolute',
                      right: -5,
                      top: -5,
                    }}
                    icon={<IoIosClose size={14}/>}
                    size={20}
                    className="flex flex-center text-color-300 background-100 hover:background-200 cursor-pointer"
                  />
                </div>
              )}
            </>
          )}
          {select && (
            <div
              ref={popupRef}
              className="flex">
              {renderIf(
                select === 'emoji',
                <EmojiPicker onSelect={handleOnEmojiSelect}/>
              )}
              {renderIf(
                select === 'options',
                <Card className="font-bold  animated faster fadeInUp cursor-pointer">
                  <Card.Grid
                    className="font-semibold"
                    onClick={() => {
                      onItemClick('createImagePost')
                    }}
                    style={gridStyle}>
                    <BsImage className="mr-3 text-primary-500" />
                    {translate('image')}
                  </Card.Grid>
                  <Card.Grid
                    className="font-semibold"
                    onClick={() => {
                      onItemClick('quickLink')
                    }}
                    style={gridStyle}>
                    <BsLink45Deg className="mr-3 text-primary-500" />
                    {translate('link')}
                  </Card.Grid>
                  <Card.Grid
                    className="font-semibold"
                    onClick={() => {
                      onItemClick('createPoll')
                    }}
                    style={gridStyle}>
                    <BsBarChartLineFill className="mr-3 text-primary-500" />
                    {translate('poll')}
                  </Card.Grid>
                  <PermissionContext.Consumer>
                    {(permissions) =>
                      !!_.get(permissions, permissionCodes.canPostArticle) && (
                        <Card.Grid
                          className="font-semibold"
                          onClick={() => {
                            const createArticleURL =
                              articleModel.getLinkToCreate(item)
                            history.push({
                              pathname: createArticleURL,
                            })
                          }}
                          style={gridStyle}>
                          <BsNewspaper className="mr-3 text-primary-500" />
                          {translate('articles')}
                        </Card.Grid>
                      )
                    }
                  </PermissionContext.Consumer>
                </Card>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
const LoginHolder = () => {
  const t = useTranslate()

  return (
    <div className="w-full flex flex-col InputBar">
      <div
        className="w-full flex items-stretch bg-gray-300 border-gray-500  border background"
        style={{
          height: 45,
          borderRadius: 4,
        }}>
        <div className="flex-1">{t('need login')}</div>
        <Link
          to={{
            pathname: '/login',
            state: {isModal: true},
          }}>
          {t('need login')}
        </Link>
      </div>
    </div>
  )
}
export default connect((state) => {
  return {
    isLogin: !!getLogin(state),
  }
})(
  branch(
    ({isLogin}) => false && !isLogin,
    () => LoginHolder
  )(({mention_data, ...props}) => (
    <MentionDataProvider initialValue={mention_data}>
      <InputBar
        mention_count={Object.keys(mention_data ?? {}).length}
        {...props}
      />
    </MentionDataProvider>
  ))
)
