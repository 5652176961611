import { Drawer } from 'antd'
import React from 'react'
import { DrawerInner } from './DrawerInner'
import { LayoutContext } from './LayoutContext'
export const LeftDrawer = () => (
  <LayoutContext.Consumer>
    {({
      setVisibleLeftDrawer,
      visibleLeftDrawer,
      leftInner
    }) => (
      <Drawer
        className="LeftDrawer"
        destroyOnClose
        bodyStyle={{
          padding: 0,
          overflow: 'hidden'
        }}
        placement="left"
        closable={false}
        onClose={() =>
          setVisibleLeftDrawer(false)
        }
        visible={visibleLeftDrawer}>
        {/* <RegAsModal> */}
        <>
          <DrawerInner
            siderInner={leftInner}
          />
        </>
        {/* </RegAsModal> */}
      </Drawer>
    )}
  </LayoutContext.Consumer>
)
