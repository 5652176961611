export default {
  "{n} categories": '{n} categories',
  "{n} languages": '{n} languages',
  "{n} provider(s)": '{n} organization(s)',
  "1 year": 'Yearly',
  "1D": 'Daily',
  "1M": 'Monthly',
  "1Y": '1 year',
  "2 years": '2 years',
  "2Y": '2 years',
  "3 months": '3 months',
  "3M": '3 months',
  "6 months": '6 months',
  "6M": '6 months',
  "about FeedIn": 'About Feedin',
  "about us": 'About Us',
  "about-us": 'About Us',
  "accept": 'Accept',
  "accept invitation": 'Accept invitation',
  "accepted as member": 'accepted as member',
  "access denied": 'Access denied',
  "account": 'Account',
  "account settings": 'Account Settings',
  "active": 'Active',
  "active subscription": 'Active subscription',
  "activity": 'Activity',
  "add": 'Add',
  "add chart": 'Add chart',
  "add image": 'Add Image',
  "add link": 'Add Link',
  "add map": 'Add map',
  "add member": 'Add member',
  "add money to wallet": 'Add money to wallet',
  "add more": 'Add more',
  "Add new and manage members here {here}": 'Add new and manage members here {here}',
  "add new answer": 'Add new answer',
  "add new contact": 'Add new contact',
  "add new location": 'Add new location',
  "add payment to keep using {subscription} or change back to free": 'Add payment to keep using {subscription} or change back to free',
  "add payment to keep using or change back to free": 'Add payment to keep using or change back to free',
  "add photo": 'Add photo',
  "add price": 'Add price',
  "Add product photos": 'Add product photos',
  "add subscriber": 'Add Subscriber',
  "Add Tax Number": 'Add Tax Number',
  "Add to Home Screen": 'Add to Home Screen',
  "add video": 'Add video',
  "adding content to the channel is limited due to its policies": 'Adding content to the channel is limited due to its policies',
  "additional details": 'additional details',
  "address": 'Address',
  "admin": 'Admin',
  "admin description": 'Administrator role',
  "advanced filter": 'Advanced Filter',
  "After select, click Save to finish": 'After select, click Save to finish',
  "after your trial ends": 'After your trial ends',
  "all": 'All',
  "all categories": 'All Categories',
  "all dataset": 'All dataset',
  "all products": 'All products',
  "all vietnam": 'All in Vietnam',
  "allow add new answers": 'Allow add new answers',
  "Allow channel content to be found through search or suggestion": 'Allow channel content to be found through search or suggestion',
  "Allow users to find your channel via search function.": 'Allow users to find your channel via search function.',
  "allowed be found": 'Allowed be found',
  "Alternate Email": 'Alternate Email',
  "Alternate Mobile No.": 'Alternate Mobile No.',
  "Alternate Phone Number": 'Alternate Phone Number',
  "alternative phone": 'Alternative Phone',
  "amount": 'Amount',
  "and": 'and',
  "Annual Turnover": 'Annual Turnover',
  "another": 'Another',
  "answers": 'Answers',
  "Anyone can view the content of the data. Only members can post articles and comment freely on this": 'Anyone can view the content of the data. Only members can post articles and comment freely on this',
  "apply": 'Apply',
  "approved": 'Approved',
  "Are you sure delete this article?": 'Are you sure delete this article?',
  "Are you sure delete this chart?": 'Are you sure delete this chart?',
  "Are you sure delete this poll?": 'Are you sure delete this poll?',
  "Are you sure delete this task?": 'Are you sure delete this task?',
  "are you sure delete this template": 'Are you sure delete this template ?',
  "article": 'Article',
  "articles": 'Articles',
  "ask for the best deal": 'For the best deal',
  "automatically register with Google, Facebook... account": 'Automatically register with Google, Facebook... account',
  "available balance": 'Available balance',
  "avatar": 'Avatar',
  "back": 'Back',
  "Back Home": 'Back Home',
  "back to account": 'Back to account',
  "ban": 'Ban',
  "banned": 'Banned',
  "banned. can not post any": 'Banned. Can not post any',
  "bans": 'Bans',
  "Basic": 'Basic',
  "basic": 'Basic',
  "basic info": 'Basic Info',
  "because you are looking for {keyword}": 'Because you are looking for {keyword}',
  "Become a provider?": 'Become an organization?',
  "Become a verified seller and get {check1} Higher Listing on FeedIn {check2} More Buyer Enquiries {check3} Preferred Number Service": 'Become a verified seller and get {check1} Higher Listing on Feedin {check2} More Buyer Enquiries {check3} Preferred Number Service',
  "bill estimate": 'Bill estimate',
  "billing": 'Billing',
  "billing address": 'Billing address',
  "billing contact": 'Billing contact',
  "billing details": 'Billing Details',
  "billing history": 'Billing history',
  "billing information": 'Billing information',
  "billing overview": 'Billing overview',
  "billing period": 'Billing period',
  "birthday": 'Birthday',
  "bookmark": 'Bookmark',
  "bookmark all": 'Bookmark all',
  "bookmarked": 'Bookmarked',
  "brief introduction to yourself": 'Brief introduction to yourself',
  "bulk price update": 'Bulk Price Update',
  "bulletin": 'Bulletin',
  "by categories": 'By categories',
  "by clicking Submit, you agree to the policy of FeedIn": 'By clicking Submit, you agree to the policy of FeedIn',
  "By clicking Submit, you agree to the policy of FeedIn {term1} and {term2}.": 'By clicking Submit, you agree to the policy of FeedIn {term1} and {term2}.',
  "By clicking Subscribe, you agree to {term1} and {term2}.": 'By clicking Subscribe, you agree to {term1} and {term2}.',
  "by making a channel private, only members will have access to this channel": 'By making a channel private, only members will have access to this channel',
  "by using FeedIn, you agree to our": 'By using FeedIn, you agree to our',
  "Call": 'Call',
  "Call verification": 'Call verification',
  "cancel": 'Cancel',
  "catalog": 'Catalog',
  "categories": 'Categories',
  "categories affect how your story appears in public": 'Categories affect how your story appears in public',
  "category": 'Category',
  "category groups": 'Category groups',
  "change address": 'Change address',
  "change display title / subtitle / display image / categories": 'Change display title / subtitle / display image / categories',
  "change following categories": 'Change following categories',
  "change password": 'Change password',
  "change preview photo": 'Change preview photo',
  "change role": 'Change role',
  "change subscription": 'Change subscription',
  "changes here will affect how your story appears in public places like Article's homepage - not the story itself.": 'Changes here will affect how your story appears in public places like Article\'s homepage - not the story itself.',
  "Changes to the watchlist are allowed within {m} hours. And the next change is made in {d} days.": 'Changes to the watchlist are allowed within {m} hours. And the next change is made in {d} days.',
  "Changing the language on this screen will change the labels displayed on the application to suit the language you have selected. To set up content filtering by language, please access the account's configuration to edit": 'Changing the language on this screen will change the labels displayed on the application to suit the language you have selected. To set up content filtering by language, please access the account\'s configuration to edit',
  "channel": 'Channel',
  "channel name": 'Channel name',
  "channel subscriptions": 'Channel subscriptions',
  "channels": 'Channels',
  "channels in category": 'Channels in category',
  "Channels that you may be interested in": 'Channels that you may be interested in',
  "channels to follow": 'Channels to follow',
  "charged fee data": 'Charged fee Data',
  "charges apply": 'Charges apply',
  "chart management": 'Chart management',
  "charts": 'Charts',
  "chat with provider": 'Chat with organization',
  "check all": 'Check All',
  "check your email and click on the activation link to complete the registration": 'Check your email and click on the activation link to complete the registration',
  "check your email and click on the link": 'Check your email and click on the link',
  "choose": 'Choose',
  "choose another FeedIn subscription": 'Choose another FeedIn subscription',
  "choose file": 'Choose file',
  "Choose language": 'Choose language',
  "Choose one from your existing conversations, or start a new one.": 'Choose one from your existing conversations, or start a new one.',
  "Choose the channel for posting": 'Choose the channel for posting',
  "Choose to follow the information catalog to get more news on your wall": 'Choose to follow the information catalog to get more news on your wall',
  "choose your default skin tone": 'Choose your default skin tone',
  "city": 'City',
  "clear": 'Clear',
  "clear all": 'Clear All',
  "clear role permissions": 'Clear role permissions',
  "click here": 'Click here',
  "Click the {icon} button on the chart page you want to keep track of, the chart will be updated regularly here.": 'Click the {icon} button on the chart page you want to keep track of, the chart will be updated regularly here.',
  "click to upload": 'Click to Upload',
  "close": 'Close',
  "code of conduct": 'Code of conduct',
  "color theme": 'Color theme',
  "comment": 'Comment',
  "commented your post": 'commented your post',
  "Commodity market information": 'Commodity market information',
  "company": 'Company',
  "company profile": 'Company Profile',
  "confidentiality of member information": 'Confidentiality of member information',
  "configure content by language": 'Configure content by language',
  "confirm password": 'Confirm password',
  "Confirmation change email address has been sent to": 'Confirmation change email address has been sent to',
  "Confirmation letter will be sent to your email address": 'Confirmation letter will be sent to your email address',
  "contact": 'Contact',
  "contact details": 'Contact Details',
  "contact email": 'Contact Email',
  "contact info": 'Contact information',
  "contact messages": 'Contact Messages',
  "contact name": 'Contact name',
  "contact now": 'Contact Now',
  "contact phone": 'Contact Phone',
  "contact provider": 'Contact Organization',
  "contact to member": 'Contact to member',
  "contact us": 'Contact us',
  "Contact us about your bill": 'Contact us about your bill',
  "contact with provider": 'Contact with Organization',
  "contact-info": 'Contact information',
  "contacts": 'Contacts',
  "content": 'Content',
  "content allowed to be found": 'Content allowed to be found',
  "content displayed by language": 'Content displayed by language',
  "content is for members only": 'Content is for members only',
  "Content language": 'Content language',
  "Continue to {provider}": 'Continue to {provider}',
  "Continue to PayPal": 'Continue to PayPal',
  "conversations": 'Conversations',
  "cookies policy": 'Cookies policy',
  "cookies-policy": 'Cookies Policy',
  "copied": 'Copied',
  "copy": 'Copy',
  "Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel": 'Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel',
  "copy link": 'Copy Link',
  "copyright": 'Copyright',
  "country": 'Country',
  "cover": 'Cover',
  "create": 'Create',
  "create a free channel": 'Create a free channel',
  "Create a new chart for dataset": 'Create a new chart for dataset',
  "Create a new provider so you can post products or manage your information channels.": 'Create an organization so you can post products or manage your information channels.',
  "create a poll": 'Create a poll',
  "create category": 'Create category',
  "create channel": 'Create channel',
  "create chart": 'Create chart',
  "create dataset": 'Create Dataset',
  "create new": 'Create New',
  "Create new channel": 'Create new channel',
  "create new group": 'Create New Group',
  "create one": 'Create One',
  "create post": 'Create Post',
  "create product": 'Create Product',
  "create role": 'Create Role',
  "Create provider profile": 'Create Organization Profile',
  "credit/debit card": 'Credit/Debit Card',
  "custom url": 'Custom URL',
  "daily": 'Daily',
  "Daily update, the latest commodity, agriculture and finance market prices every day at FeedIn.": 'Daily update, the latest commodity, agriculture and finance market prices every day at FeedIn.',
  "dark mode": 'Dark mode',
  "dark skin tone": 'Dark Skin Tone',
  "data": 'Data',
  "data & charts": 'Data & Charts',
  "Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this": 'Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this',
  "data explorer": 'Data Explorer',
  "data frequency": 'Data frequency',
  "data in category": 'Data in category',
  "data security": 'Data security',
  "Data set you might be interested in": 'Data set you might be interested in',
  "dataset": 'Dataset',
  "dataset list": 'Dataset List',
  "dataset management": 'Dataset Management',
  "dataset subscriptions": 'Dataset Subscriptions',
  "date": 'Date',
  "deactive": 'Deactive',
  "Declare the location for your provider": 'Declare the location for your organization',
  "default": 'Default',
  "default skin tone": 'Default Skin Tone',
  "delete": 'Delete',
  "delete channel": 'Delete channel',
  "delete data": 'Delete Data',
  "delete dataset": 'Delete dataset',
  "delete provider": 'Delete organization',
  "Deleted article are gone forever. Are you sure?": 'Deleted article are gone forever. Are you sure?',
  "Deleted channel are gone forever. Are you sure?": 'Deleted channel are gone forever. Are you sure?',
  "Deleted dataset are gone forever. Are you sure?": 'Deleted dataset are gone forever. Are you sure?',
  "Deleted provider are gone forever. Are you sure?": 'Deleted organization are gone forever. Are you sure?',
  "delivery frequency": 'Delivery frequency',
  "delivery_time": 'Delivery Time',
  "description": 'Description',
  "designed and provided by": 'Designed and provided by',
  "desktop app": 'Desktop App',
  "detail": 'Detail',
  "discovery": 'Discovery',
  "Discovery": 'Discovery',
  "discovery more providers": 'Discovery more organizations',
  "do not show members information for each other members": 'Do not show members information for each other members',
  "do you want cancel the subscription?": 'Do you want cancel the subscription?',
  "do you want to cancel?": 'Do you want to cancel?',
  "Do you want to deactivate this product?": 'Do you want to deactivate this product?',
  "do you want to logout": 'Do you want to logout ?',
  "document (PDF)": 'document (PDF)',
  "documentation": 'Documentation',
  "Doesn't have any bookmarked item": 'Doesn\'t have any bookmarked item',
  "done": 'Done',
  "downgrade": 'Downgrade',
  "downgrade subscription": 'Downgrade subscription',
  "downgrade your subscription to": 'Downgrade your subscription to',
  "download": 'Download',
  "draft": 'Draft',
  "draft products": 'Draft Products',
  "edit": 'Edit',
  "edit message": 'Edit Message',
  "edit name": 'Edit name',
  "edit your post": 'Edit your post',
  "editor choices": 'Editor choices',
  "email": 'Email',
  "email (example@company.com)": 'Email (example@company.com)',
  "email account": 'Email Account',
  "emailplaceholder": 'Email (example@company.com)',
  "Emoji categories": 'Emoji categories',
  "en": 'English',
  "Encouraging business initiatives creating social impact. Geared towards building a sustainable living environment for generations today and tomorrow.": 'Encouraging business initiatives creating social impact. Geared towards building a sustainable living environment for generations today and tomorrow.',
  "english": 'English',
  "enter an url": 'Enter an url',
  "enter channel name": 'Enter channel name',
  "Enter Dataset Name": 'Enter Data Name',
  "Enter message text with minimum requirement of 20, maximum of 4000 characters": 'Enter message text with minimum requirement of 20, maximum of 4000 characters',
  "Enter products / services search": 'Enter products / services search',
  "Enter some information so the provider knows your requirements": 'Enter some information so the organization knows your requirements',
  "enter provider name": 'Enter organization name',
  "enter your email": 'Enter your email',
  "enter your keyword": 'Enter your keyword',
  "expires": 'Expires',
  "Explore related products from verified Companies": 'Explore related products from verified Organizations',
  "Explore similar products": 'Explore similar products',
  "explore similar products": 'Explore similar products',
  "extra request": 'Extra Request',
  "failure purchase!": 'Failure Purchase!',
  "FAQ": 'FAQ',
  "favorites categories": 'Favorites categories',
  "Featured Categories": 'Featured Categories',
  "featured charts": 'Featured charts',
  "featured topics": 'Featured topics',
  "features": 'Features',
  "fee": 'Fee',
  "fee management": 'Fee Management',
  "fee settings": 'Fee Settings',
  "feed": 'Feed',
  "FeedIn gives you the latest information on market data.": 'FeedIn gives you the latest information on market data.',
  "FeedIn is a platform that connects buyers with providers, creating a leading information sharing network about agribusiness.": 'FeedIn is a platform that connects buyers with organizations, creating a leading information sharing network about agribusiness.',
  "FeedIn Member Since": 'Feedin Member Since',
  "FeedIn will update the latest and fastest information and data about the goods market to you": 'FeedIn will update the latest and fastest information and data about the goods market to you',
  "feeds": 'Feeds',
  "female": 'Female',
  "file upload failed": 'file upload failed',
  "file uploaded successfully": 'file uploaded successfully',
  "filter": 'Filter',
  "Filter results": 'Filter results',
  "first subscription on": 'First subscription on',
  "Flags": 'Flags',
  "folders": 'Folder',
  "follow": 'Follow',
  "follow more categories": 'Follow more categories',
  "Follow to get notifications from this channel!": 'Follow to get notifications from this channel!',
  "followers": 'Followers',
  "following": 'Following',
  "following categories": 'Following categories',
  "following channels": 'Following channels',
  "For buyers, investors and those interested. Just select the area of interest, you are suggested to connect with the professionals and the latest news of the market.": 'For buyers, investors and those interested. Just select the area of interest, you are suggested to connect with the professionals and the latest news of the market.',
  "For inquiring about product quotes, sending product information, or contacting cooperation, please contact us": 'For inquiring about product quotes, sending product information, or contacting cooperation, please contact us',
  "for sales": 'For Sales',
  "for you": 'For you',
  "forgotpass": 'Forgot password',
  "free": 'Free',
  "frequently questions": 'Frequently questions',
  "Frequently Used": 'Frequently Used',
  "frequently-questions": 'Frequently Questions',
  "from": 'From',
  "From - To": 'From - To',
  "from the sender": 'From the sender',
  "fullname": 'Fullname',
  "gender": 'Gender',
  "general permission": 'General permission',
  "general settings": 'General settings',
  "Get a quote": 'Get a quote',
  "Get Best Price": 'Get Best Price',
  "Get in touch with us": 'Get in touch with us',
  "Get latest price": 'Get latest price',
  "Get Quotes": 'Get Quotes',
  "Get quotes from seller": 'Get quotes from seller',
  "get started": 'Get started',
  "get started with {name}": 'Get started with {name}',
  "Get your business details verified with us for higher rankings in FeedIn!": 'Get your business details verified with us for higher rankings in FeedIn!',
  "go": 'Go',
  "go modal": 'Go Modal',
  "go to": 'Go to',
  "group": 'group',
  "haven't comments yet": 'Haven\'t comments yet',
  "head office": 'Head office',
  "hello": 'Hello',
  "Hello you": 'Hello you',
  "help": 'Help',
  "Help people update the latest market information, multi-dimensional, confidential and customized according to need.": 'Help people update the latest market information, multi-dimensional, confidential and customized according to need.',
  "helps": 'Helps',
  "Here is a list of all invite links. You can revoke any one.": 'Here is a list of all invite links. You can revoke any one.',
  "hide interaction between members": 'Hide interaction between members',
  "Hide interactions between members. But, retaining interaction between members and administrators": 'Hide interactions between members. But, retaining interaction between members and administrators',
  "history": 'History',
  "home": 'Home',
  "hometown": 'Hometown',
  "If you need assistance, please feel free to contact us via": 'If you need assistance, please feel free to contact us via',
  "If you want to switch to annual payment, {contactus}": 'If you want to switch to annual payment, {contactus}',
  "ignore": 'Ignore',
  "image": 'Image',
  "image must smaller than": 'Image must smaller than',
  "inactive": 'Inactive',
  "incoming requests": 'Incoming Requests',
  "index type": 'Index type',
  "index types": 'Index types',
  "info chart editing": 'Info Chart Editing',
  "interested": 'Interested',
  "Interface language": 'Interface language',
  "introduce": 'Introduce',
  "invalid email address": 'Invalid email address',
  "invalid template": 'invalid template',
  "invite": 'Invite',
  "invite and share": 'Invite and share',
  "invite code": 'Invite code',
  "invite members": 'Invite members',
  "invite people": 'Invite People',
  "invite people to join this channel": 'Invite people to join this channel',
  "invite you to join": 'invite you to join',
  "Invite your friends to this page": 'Invite your friends to this page',
  "invited": 'Invited',
  "invited to become member": 'Invited to become member',
  "inviter": 'Inviter',
  "invites": 'Invites',
  "is served by clicking on a": 'is served by clicking on a',
  "item not found": 'Item Not Found',
  "join": 'Join',
  "join channel": 'Join channel',
  "Join for free now": 'Join for free now',
  "Join the channel to share the article, comment or click to follow so that the system will automatically update the channel's latest news to you": 'Join the channel to share the article, comment or click to follow so that the system will automatically update the channel\'s latest news to you',
  "Join this channel to start chatting": 'Join this channel to start chatting',
  "joined": 'Joined',
  "keep subscription": 'Keep subscription',
  "keyword": 'Keyword',
  "keywords": 'Keywords',
  "kick": 'Kick',
  "kicked": 'Kicked',
  "language": 'Language',
  "languages": 'Languages',
  "last updated": 'Last updated',
  "latest": 'Latest',
  "latest updates": 'Latest Updates',
  "Latest updates on the commodity market": 'Latest updates on the commodity market',
  "leave": 'Leave',
  "leave a message": 'Leave a Message',
  "Leave a Message, we will contact you back!": 'Leave a Message, we will contact you back!',
  "leave channel": 'Leave channel',
  "Legal Status of Firm": 'Legal Status of Firm',
  "less": 'Less',
  "license": 'License',
  "licenses": 'Licenses',
  "light skin tone": 'Light Skin Tone',
  "link": 'Link',
  "Link original post": 'Link original post',
  "list of data": 'List of Data',
  "listed products": 'Listed Products',
  "unlisted products": 'Unlisted Products',
  "location": 'Location',
  "Location (Province/district/ward)": 'Location (Province/district/ward)',
  "location & contact": 'Location & Contact',
  "location name": 'Location name',
  "login": 'Login',
  "Login now": 'Login now',
  "Login now to select and follow the content that you interested in": 'Login now to select and follow the content that you interested in',
  "login with account": 'Login with account',
  "login with Facebook": 'login with Facebook',
  "login with Google": 'login with Google',
  "logout": 'Logout',
  "Make your channel get more exciting activities by inviting other members": 'Make your channel get more exciting activities by inviting other members',
  "Make your provider get more exciting activities by inviting other members": 'Make your organization get more exciting activities by inviting other members',
  "male": 'Male',
  "manage": 'Manage',
  "manage dataset": 'Manage Dataset',
  "manage products": 'Manage Products',
  "manage services": 'Manage Services',
  "ManageChannelSubscriptions": 'Channel Subscriptions',
  "managed channels": 'Managed channels',
  "management": 'Management',
  "mark all as read": 'Mark all as read',
  "mark as read": 'Mark as read',
  "market": 'Market',
  "market summary": 'Market Summary',
  "mart": 'Mart',
  "me to the channel": 'Me to the channel',
  "medium skin tone": 'Medium Skin Tone',
  "medium-dark skin tone": 'Medium-Dark Skin Tone',
  "medium-light skin tone": 'Medium-Light Skin Tone',
  "member": 'Member',
  "member description": 'Member Description',
  "member management": 'Member management',
  "members": 'Members',
  "members list": 'Members List',
  "mention": 'mention',
  "menu": 'Menu',
  "Message": 'Message',
  "messages": 'Messages',
  "min_order_qtty": 'Minimum Order Quantity',
  "Minimum payment required": 'Minimum payment required',
  "miss": 'Miss.',
  "modal": 'Modal',
  "monthly": 'Monthly',
  "more": 'More',
  "More data from": 'More data from',
  "More like This": 'More like This',
  "mr": 'Mr.',
  "mrs": 'Mrs.',
  "ms": 'Ms.',
  "my channels": 'My Channels',
  "my page": 'My page',
  "my post": 'My post',
  "name": 'Name',
  "name of chart": 'Name of chart',
  "name of your company": 'Name of your company',
  "Nature of Business": 'Nature of Business',
  "new answer": 'New answer',
  "New contact": 'New contact',
  "new contact": 'New contact',
  "new folder": 'New Folder',
  "new group": 'New group',
  "New location": 'New location',
  "new password": 'New password',
  "New template": 'New template',
  "newest data": 'Newest Data',
  "news": 'News',
  "news page": 'News page',
  "next": 'Next',
  "next change": 'Next change',
  "No categories have been selected": 'No categories have been selected',
  "no categorized": 'No Categorized',
  "No channel being followed": 'No channel being followed',
  "no channels": 'No channels',
  "No Data": 'No Data',
  "No data available at this time.": 'No data available at this time.',
  "no data found": 'No Data Found',
  "No data set are being followed": 'No data set are being followed',
  "no dataset": 'No dataset',
  "no description": 'No description',
  "No Emoji Found": 'No Emoji Found',
  "No filter available": 'No filter available',
  "no invites yet": 'No invites yet',
  "no item found": 'No item found',
  "No news": 'No news',
  "no office found": 'No office found',
  "No posts were found": 'No posts were found',
  "no results found": 'no results found',
  "not a member yet?": 'Not a member yet?',
  "note": 'Note',
  "notes about chart!": 'Notes about chart!',
  "notification": 'Notification',
  "Notifications": 'Notifications',
  "Number of Employees": 'Number of Employees',
  "Number of your channels:": 'Number of your channels:',
  "Objects": 'Objects',
  "offices": 'Offices',
  "OK": 'OK',
  "old password": 'Old password',
  "oldest data": 'Oldest Data',
  "on": 'On',
  "One more step to become a Verified Seller": 'One more step to become a Verified Seller',
  "Only members can view data content, post articles or comment on this": 'Only members can view data content, post articles or comment on this',
  "oops...": 'Oops..',
  "opps, no channels available": 'Opps, No channels available',
  "or": 'Or',
  "or continue with": 'or continue with',
  "organization": 'Organization',
  "other": 'Other',
  "other categories": 'Other categories',
  "other channels": 'Other channels',
  "other charts": 'Other charts',
  "others": 'Others',
  "otherSettings": 'Other Settings',
  "Our Channels": 'Our Channels',
  "Our data": 'Our Data',
  "Our mission is to:": 'Our mission is to:',
  "Our Videos": 'Our Videos',
  "overview": 'Overview',
  "owner": 'Owner',
  "owner description": 'Owner role',
  "package in use": 'Package in use',
  "packaging_details": 'Packaging Details',
  "paid channel": 'Paid Channel',
  "partner": 'Partner',
  "password": 'Password',
  "Password and confirm password does not match": 'Password and confirm password does not match',
  "Password must have min 6 characters": 'Password must have min 6 characters',
  "password required": 'Password is required',
  "passwordplaceholder": 'Password',
  "Passwords must match": 'Passwords must match',
  "paste your link here": 'Paste your link here',
  "pay": 'Pay',
  "Pay and subscribe": 'Pay and subscribe',
  "payment detail": 'Payment detail',
  "payment subscription": 'Payment subscription',
  "Payment via {provider}": 'Payment via {provider}',
  "Payment via Paypal": 'Payment via Paypal',
  "Payment will be sent to continue on {provider}. Please confirm the amount to pay": 'Payment will be sent to continue on {provider}. Please confirm the amount to pay',
  "Payment will be sent to continue on Paypal. Please confirm the amount to pay": 'Payment will be sent to continue on Paypal. Please confirm the amount to pay',
  "paypal": 'PayPal',
  "pending": 'Pending',
  "pending approval": 'Pending Approval',
  "people to follow": 'People to follow',
  "Permanent link": 'Permanent link',
  "permission denied": 'Permission denied',
  "permissions": 'Permissions',
  "personal": 'Personal',
  "personal channels": 'Personal channels',
  "personal profile": 'Personal profile',
  "phone": 'Phone',
  "Phone Number": 'Phone Number',
  "Phone number how we can contact you": 'Phone number how we can contact you',
  "photos": 'Photos',
  "Pictures on the home page": 'Pictures on the home page',
  "pin": 'Pin',
  "Pin code": 'Pin code',
  "pinned": 'Pinned',
  "pinned posts": 'Pinned posts',
  "pinnedPosts": 'Pinned posts',
  "Please check the information and follow the steps to subscribe.": 'Please check the information and follow the steps to subscribe.',
  "Please choose to follow related channels for the system to filter the updates to you": 'Please choose to follow related channels for the system to filter the updates to you',
  "Please choose to save the interest charts so you can view them easily each day": 'Please choose to save the interest charts so you can view them easily each day',
  "please enter name of product": 'Please enter name of Product',
  "please input your email": 'Please input your email',
  "please input your phone number": 'Please input your phone number',
  "Please log in to FeedIn to leave your comment in the post": 'Please log in to FeedIn to leave your comment in the post',
  "Please login to use the message function in the system.": 'Please login to use the message function in the system.',
  "Please register to view this content": 'Please register to view this content',
  "please select": 'Please select',
  "Please select at least 1 topic to complete. FeedIn will help you find relevant information": 'Please select at least 1 topic to complete. FeedIn will help you find relevant information',
  "Please select the unit you want to choose as the default": 'Please select the unit you want to choose as the default',
  "policy": 'Policy',
  "poll": 'Poll',
  "popular": 'Popular',
  "post": 'post',
  "Post something": 'Post something',
  "post your images": 'Post your images',
  "postal code": 'Postal Code',
  "posted": 'Posted',
  "posted by": 'Posted by',
  "posts": 'Posts',
  "Premium": 'Premium',
  "premium": 'Premium',
  "Premium channel, when enabled, allow users to join the channel for a fee. The owner of the channel will receive revenue from the members.": 'Premium channel, when enabled, allow users to join the channel for a fee. The owner of the channel will receive revenue from the members.',
  "premium providers": 'Premium organizations',
  "press enter to search": 'Press ENTER to search',
  "preview description": 'Preview Description',
  "preview title": 'Preview Title',
  "price": 'Price',
  "pricing": 'Pricing',
  "Primary E-mail": 'Primary E-mail',
  "Primary Mobile No.": 'Primary Mobile No.',
  "privacy": 'Privacy',
  "privacy policy": 'Privacy Policy',
  "privacy settings": 'Privacy settings',
  "privacy-policy": 'Privacy Policy',
  "private": 'Private',
  "private channel": 'Private Channel',
  "pro": 'Pro',
  "Pro": 'Pro',
  "product": 'Product',
  "product catalogs": 'Product Catalogs',
  "product certificates": 'Product Certificates',
  "product description": 'Product Description',
  "product details": 'Product details',
  "product filter": 'Product Filter',
  "product images": 'Product Images',
  "product must have at least five character": 'product must have at least five character',
  "product must have at least one image": 'product must have at least one image',
  "product name": 'Product name',
  "product settings": 'Product settings',
  "product specifications": 'Product Specifications',
  "Product/Service Name": 'Product/Service Name',
  "production_capacity": 'Production Capacity',
  "products": 'Products',
  "products list": 'Products List',
  "products page": 'Products page',
  "Professional network in the industry": 'Professional network in the industry',
  "profile": 'Profile',
  "profile settings": 'Profile settings',
  "Promote people to share knowledge and experience, so that people can continuously learn and prosper together.": 'Promote people to share knowledge and experience, so that people can continuously learn and prosper together.',
  "provided services": 'Provided services',
  "Province/City": 'Province/City',
  "Province/district/ward": 'Province/District/Ward',
  "provinces": 'Provinces',
  "public": 'Public',
  "public timeline": 'Public timeline',
  "publish": 'Publish',
  "published by": 'Published by',
  "publisher": 'Publisher',
  "quick link": 'Quick link',
  "quote": 'Quote',
  "read": 'read',
  "read more": 'Read More',
  "read news": 'Read News',
  "ready to publish?": 'Ready to publish?',
  "rebookmark": 'Rebookmark',
  "recent news": 'Recent news',
  "recently news": 'Recently news',
  "Recommendations": 'Recommendations',
  "refreshed": 'Refreshed',
  "region": 'Region',
  "regions": 'Regions',
  "register": 'Register',
  "registered": 'Registered',
  "Registering to use advanced data, you will have to pay": 'Registering to use advanced data, you will have to pay',
  "rejected": 'Rejected',
  "related articles": 'Related articles',
  "related categories": 'Related Categories',
  "related channels": 'Related channels',
  "related charts": 'Related charts',
  "related keywords": 'Related keywords',
  "Related to items you've viewed": 'Related to items you\'ve viewed',
  "relevant": 'Relevant',
  "remove": 'Remove',
  "remove all": 'Remove All',
  "removed": 'Removed',
  "renew / expires on": 'Renew / Expires on',
  "replied": 'Replied',
  "reply": 'Reply',
  "request": 'Request',
  "Request a call": 'Request a call',
  "Request a quote": 'Request a quote',
  "request have been sent successfully!": 'Request have been sent successfully!',
  "request to join": 'Request to join',
  "request-to-join": 'Request to join',
  "requested to join": 'Request to join',
  "required field": 'Required Field',
  "Requirement Details": 'Requirement Details',
  "resend": 'Resend',
  "reset": 'Reset',
  "reset your password": 'Reset your password',
  "response rate": 'Response Rate',
  "responses": 'Responses',
  "restricted": 'Restricted',
  "results": 'Results',
  "return home": 'Return home',
  "return owner page": 'Return owner page',
  "return user page": 'Return user page',
  "retype password": 'Retype password',
  "Review": 'Review',
  "role": 'Role',
  "role name": 'Role name',
  "role title": 'Role Title',
  "roles": 'Roles',
  "sales information": 'Sales Information',
  "save": 'Save',
  "save & complete": 'Save & Complete',
  "Save and continue": 'Save and continue',
  "schedule to cancel": 'Schedule to cancel',
  "search": 'Search',
  "search anythings in FeedIn": 'search anythings in FeedIn',
  "Search by Name": 'Search by Name',
  "Search Results": 'Search Results',
  "secret channel": 'Secret channel',
  "section": 'Section',
  "See all": 'See All',
  "See all charts in the data": 'See all charts in the data',
  "see less": 'See less',
  "see more": 'See more...',
  "select / create a group": 'select / create a group',
  "Select a content category or follow the authors to get relevant information": 'Select a content category or follow the authors to get relevant information',
  "select category": 'Select category',
  "select preview photo": 'Select preview photo',
  "Select the display order in the category": 'Select the display order in the category',
  "Select the type of content you want to see. And FeedIn will collect and display news that suits your interests.": 'Select the type of content you want to see. And FeedIn will collect and display news that suits your interests.',
  "select your country": 'Select your country',
  "selected": 'Selected',
  "seller": 'seller',
  "Seller Contact Details": 'Seller Contact Details',
  "seller name": 'seller name',
  "send": 'Send',
  "Send Email": 'Send Email',
  "Send invitation to join the channel to your friends, by sending a copy of the page link below": 'Send invitation to join the channel to your friends, by sending a copy of the page link below',
  "Send invitation to join the provider to your friends, by sending a copy of the page link below": 'Send invitation to join the organization to your friends, by sending a copy of the page link below',
  "send request": 'Send request',
  "Send your message to this provider": 'Send your message to this organization',
  "sending": 'Sending',
  "sent a request to become member": 'Sent a request to become member',
  "services": 'Services',
  "set main": 'set main',
  "set up content selection by language": 'Set up content selection by language',
  "setting": 'Setting',
  "settings": 'Settings',
  "share": 'Share',
  "share a post": 'Share a post',
  "share button": 'share button',
  "share your ideas": 'Share your ideas',
  "Share your knowledgeable or your information to enhance your presence, build a brand, make friends with potential partners or even provide information for a fee.": 'Share your knowledgeable or your information to enhance your presence, build a brand, make friends with potential partners or even provide information for a fee.',
  "Sharing the information": 'Sharing the information',
  "short description": 'Short description',
  "Short description about chart!": 'Short description about chart!',
  "Short description about your company!": 'Short description about your company!',
  "Short description about your dataset!": 'Short description about your dataset!',
  "show in category": 'Show in category',
  "show more": 'Show more',
  "show this post": 'Show this post',
  "sign in": 'Sign in',
  "sign in with": 'Sign in with',
  "sign up": 'Sign up',
  "signin / signup": 'Signin / Signup',
  "site": 'Website',
  "sku": 'SKU',
  "Smileys & People": 'Smileys & People',
  "sold by": 'sold by',
  "some messages...some messages...": 'some messages...some messages...',
  "Sorry, the page you visited does not exist.": 'Sorry, the page you visited does not exist.',
  "Sorry, the page you visited has expired or does not exist.": 'Sorry, the page you visited has expired or does not exist.',
  "source": 'Source',
  "Specification Details": 'Specification Details',
  "Additional Details": 'Additional Details',
  "Specifications": 'Specifications',
  "start free trial": 'Start Free Trial',
  "start trial": 'Start trial',
  "start your no-cost {subscription} trial today": 'Start your no-cost {subscription} trial today',
  "start your no-cost trial today": 'Start your no-cost trial today',
  "state": 'State',
  "status": 'Status',
  "stay connected": 'Stay connected',
  "submit": 'Submit',
  "Submit requirement": 'Submit Requirement',
  "subscribe": 'Subscribe',
  "subscribe channel": 'Subscribe Channel',
  "Subscribe to the categories to display the charts you're interested in": 'Subscribe to the categories to display the charts you\'re interested in',
  "Subscribe to the channels for information displayed on this page": 'Subscribe to the channels for information displayed on this page',
  "subscribed data": 'Subscribed Data',
  "subscribers": 'Subscribers',
  "subscribing this channel will cost you": 'Subscribing this channel will cost you',
  "subscriptions": 'Subscriptions',
  "success": 'Success',
  "successful": 'Successful',
  "successfully purchase!": 'Successfully Purchase!',
  "suggested products": 'Suggested Products',
  "summary": 'Summary',
  "Summary of the latest news today": 'Summary of the latest news today',
  "supplied by": 'Supplied by',
  "provider": 'Organization',
  "provider address": 'Organization Address',
  "provider details": 'Organization Details',
  "provider homepage": 'Organization Homepage',
  "provider name": 'Organization Name',
  "provider profile": 'Organization Profile',
  "provider profile was created successful": 'Organization profile was created successful',
  "provider profiles": 'Organization Profiles',
  "provider's channels": 'Organization\'s channels',
  "providers": 'Organizations',
  "Supporting brand promotion and market information to enhance business and networking opportunities.": 'Supporting brand promotion and market information to enhance business and networking opportunities.',
  "Symbols": 'Symbols',
  "table of contents": 'Table of contents',
  "Tap": 'Tap',
  "tax": 'Tax',
  "tax code": 'Tax Code',
  "Tax ID": 'Tax ID',
  "tax inclusive": 'Tax inclusive',
  "tax number": 'Tax Number',
  "Tell us what you need": 'Tell us what you need',
  "template": 'template',
  "terms of service": 'Terms of Service',
  "terms-of-service": 'Terms of Service',
  "the {package} subscription will be continue effect until {date}": 'The {package} subscription will be continue effect until {date}',
  "the activation email has been sent to": 'the activation email has been sent to',
  "The banner image will be placed on the company's homepage. Best view with 256 x 1280 pixels resolution. Image type: PNG, JPG.": 'The banner image will be placed on the company\'s homepage. Best view with 256 x 1280 pixels resolution. Image type: PNG, JPG.',
  "the channel has been deleted": 'The channel has been deleted',
  "the channel so that the system will automatically help you update with the latest news": 'the channel so that the system will automatically help you update with the latest news',
  "The chart is enabled and ready to be displayed to the user": 'The chart is enabled and ready to be displayed to the user',
  "The chart marked as typical and used to represent the data set": 'The chart marked as typical and used to represent the data set',
  "The content (images, posts ...) is shared in the channel, only members of the channel see, discuss. Other users cannot view or respond to this content": 'The content (images, posts ...) is shared in the channel, only members of the channel see, discuss. Other users cannot view or respond to this content',
  "the information that interests you": 'The information that interests you',
  "The interaction between that member and the channel administrator of each member will be strictly confidential. Only the administrator or the individual in the channel can see each other's information.": 'The interaction between that member and the channel administrator of each member will be strictly confidential. Only the administrator or the individual in the channel can see each other\'s information.',
  "the latest news": 'The latest news',
  "The members shown here include: Owner, administrator, member as contact person.": 'The members shown here include: Owner, administrator, member as contact person.',
  "The page content is not yet available or being written": 'The page content is not yet available or being written',
  "The page you are looking for does not exist. Tap the button below to return to the homepage.": 'The page you are looking for does not exist. Tap the button below to return to the homepage.',
  "the restore email has been sent to": 'the restore email has been sent to',
  "The state of the data set. If \"On\", the dataset is ready to use": 'The state of the data set. If \"On\", the dataset is ready to use',
  "the provider has been deleted": 'The organization has been deleted',
  "the providers i am involved in": 'The organizations i am involved in',
  "The tax code value has been saved successfully": 'The tax code value has been saved successfully',
  "The verification call is pending": 'The verification call is pending',
  "This account has not registered an email address for authentication, please register an email address to verify the ownership of the account": 'This account has not registered an email address for authentication, please register an email address to verify the ownership of the account',
  "This channel content is limited to members only, join now to view this channel content.": 'This channel content is limited to members only, join now to view this channel content.',
  "This channel doesn't have any pinned post": 'This channel doesn\'t have any pinned post',
  "this channel supports communication in the form of hidden interactions between members": 'This channel supports communication in the form of hidden interactions between members',
  "this channel to your friends": 'this channel to your friends',
  "This channel will charge you": 'This channel will charge you',
  "This is a description.": 'This is a description.',
  "this is a regular channel": 'This is a regular channel',
  "This is an error message": 'This is an error message',
  "This product will be removed from your Catalog and won't be visible to buyers. You will also stop receiving BuyLeads for this product gradually. Are you sure you want to continue?": 'This product will be removed from your Catalog and won\'t be visible to buyers. You will also stop receiving BuyLeads for this product gradually. Are you sure you want to continue?',
  "Time for updating the tracking list:": 'Time for updating the tracking list:',
  "timeline": 'Timeline',
  "timeline is only viewable with accounts that are members of this channel": 'Timeline is only viewable with accounts that are members of this channel',
  "timeline is viewed internally only": 'Timeline is viewed internally only',
  "title": 'Title',
  "title or question": 'Question statement',
  "to channel": 'To channel',
  "to create new channel on your company": 'to create new channel on your company',
  "to create your new channel": 'to create your new channel',
  "To edit your details, please visit {profile}": 'To edit your details, please visit {profile}',
  "to find content that interests you": 'to find content that interests you',
  "To join the channel and follow the content regularly, you must Login / Register (free) account to become a member": 'To join the channel and follow the content regularly, you must Login / Register (free) account to become a member',
  "To make changes to your subscriptions , go to": 'To make changes to your subscriptions , go to',
  "To manage products visit at {manage_products} or continue with {call_verification}": 'To manage products visit at {manage_products} or continue with {call_verification}',
  "To stay on your current plan after your trial ends, add payment now.": 'To stay on your current plan after your trial ends, add payment now.',
  "to upgrade. This subscription cost you": 'to upgrade. This subscription cost you',
  "topic": 'Topic',
  "total": 'Total',
  "Total due": 'Total due',
  "Total due {date}": 'Total due {date}',
  "Travel & Places": 'Travel & Places',
  "trial": 'Trial',
  "trial days": 'Trial days',
  "trust and safe": 'Trust and Safe',
  "trust-safe": 'Trust and safe',
  "tutorials": 'Tutorials',
  "type product name": 'Type product name',
  "type your message": 'Type your message',
  "typify chart": 'Typify chart',
  "unban": 'Unban',
  "unbookmark": 'UnBookmark',
  "unbookmarked": 'UnBookmarked',
  "unclassified products": 'Unclassified Products',
  "unfollow": 'Unfollow',
  "unit": 'Unit',
  "unlimited": 'Unlimited',
  "unpin": 'Unpin',
  "unpin this?": 'Unpin this?',
  "unsave": 'Unsave',
  "unsubscribe": 'Unsubscribe',
  "update": 'Update',
  "update about success": 'Update about success',
  "update dataset": 'Update dataset',
  "update fail": 'Update fail',
  "update success": 'update success',
  "Updates to you the information you have chosen to follow": 'Updates to you the information you have chosen to follow',
  "upgrade": 'Upgrade',
  "upgrade now. This subscription cost you": 'Upgrade now. This subscription cost you',
  "upgrade subscription": 'Upgrade subscription',
  "upgrade this channel": 'Upgrade this channel',
  "upgrade to": 'Upgrade to',
  "upgrade to {name}": 'Upgrade to {name}',
  "upload": 'Upload',
  "url": 'Url',
  "us to share the news in this channel": 'us to share the news in this channel',
  "Usage: for direct communication purposes through messaging.": 'Usage: for direct communication purposes through messaging.',
  "use email": 'use email',
  "use the selected system language": 'Use the selected system language',
  "used group": 'Used group',
  "user": 'User',
  "user choices": 'User choices',
  "user management": 'User Management',
  "uses": 'Uses',
  "value": 'Value',
  "Verification request has been sent, please wait for a response": 'Verification request has been sent, please wait for a response',
  "verified provider": 'Verified organization',
  "Verify Account": 'Verify Account',
  "Verify on Call": 'Verify on Call',
  "Verify sales information": 'Verify sales information',
  "vi": 'Tiếng Việt',
  "video url": 'Video URL (Youtube Link)',
  "vietnamese": 'Tiếng Việt',
  "view": 'View',
  "view all": 'View all',
  "View all categories": 'View All Categories',
  "View all products in {name}": 'View all products in {name}',
  "View complete detail": 'View complete detail',
  "view full estimate": 'View full estimate',
  "view more charts": 'View more charts',
  "view product page": 'View product page',
  "views": 'Views',
  "visit page": 'Visit page',
  "Visit the page to preview": 'Visit the page to preview',
  "vnpay": 'VNPay',
  "volume": 'Volume',
  "vote": 'Vote',
  "voted": 'Voted',
  "waiting": 'Waiting',
  "waiting admin accept your request": 'Waiting admin accept your request',
  "waiting members list": 'Waiting members list',
  "wanna more features?": 'Wanna more features?',
  "we will upgrade you immediately, you won't have to pay anything until your trial ends": 'We will upgrade you immediately, you won\'t have to pay anything until your trial ends',
  "we will upgrade you to {subscription} immediately, you won't have to pay anything until your trial ends": 'We will upgrade you to {subscription} immediately, you won\'t have to pay anything until your trial ends',
  "website": 'Website',
  "welcome": 'Welcome',
  "what are you looking for": 'What are you looking for ?',
  "what is your chart name?": 'What is your chart name?',
  "what is your company name?": 'What is your company name?',
  "what is your name?": 'What is your name?',
  "what is your question?": 'What is your question?',
  "what your channel name?": 'What your channel name?',
  "what your product name?": 'What your product name?',
  "when you're ready": 'When you\'re ready',
  "which categories are your interests": 'Which categories are your interests ?',
  "with account": 'With account',
  "write a response": 'Write a response',
  "write article": 'Write article',
  "write description for this page": 'Write description for this page',
  "write something about this page": 'Write something about this page',
  "Write your sharing": 'Write your sharing',
  "written by": 'Written by',
  "Year of Establishment": 'Year Of Establishment',
  "You are not choosing to follow this chart category. Please choose follow up in advance": 'You are not choosing to follow this chart category. Please choose follow up in advance',
  "you can not change your following categories before {time}": 'You can not change your following categories before {time}',
  "you can not follow more {n} categories": 'You can not follow more {n} categories',
  "You can only upload JPG/PNG file": 'You can only upload JPG/PNG file',
  "you commented on {owner}'s post": 'You commented on {owner}\'s post',
  "you currently have a maximum limit of {n} channels allowed to own. You can change it by upgrading your account": 'You currently have a maximum limit of {n} channels allowed to own. You can change it by upgrading your account',
  "you currently have a maximum limit of {n} providers allowed to own. You can change it by upgrading your account": 'You currently have a maximum limit of {n} providers allowed to own. You can change it by upgrading your account',
  "you didn't enter the channel name correctly": 'You didn\'t enter the channel name correctly',
  "you didn't enter the provider name correctly": 'You didn\'t enter the provider name correctly',
  "You do not have a conversation selected": 'You do not have a conversation selected',
  "you don't have permission to post": 'You don\'t have permission to post',
  "you have not any notification!": 'You do not have any notifications!',
  "You have the maximum of {n} items to follow. The news will be filtered according to the category you choose.": 'You have the maximum of {n} items to follow. The news will be filtered according to the category you choose.',
  "You have the maximum of {n} items to follow. The news will be filtered according to the language you choose.": 'You have the maximum of {n} items to follow. The news will be filtered according to the language you choose.',
  "You need {amount} to be able subscribe this channel.": 'You need {amount} to be able subscribe this channel.',
  "you need login first": 'You need login first',
  "Your account has been activated": 'Your account has been activated',
  "your answer": 'Your answer',
  "Your business information has been fully updated, thank you": 'Your business information has been fully updated, thank you',
  "your chart": 'Your chart',
  "Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you": 'Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you',
  "Your current active subscription package will continue to hold until it expires": 'Your current active subscription package will continue to hold until it expires',
  "your device don't support this!": 'Your device don\'t support this!',
  "your invite link expires": 'Your invite link expires',
  "your message": 'Your message',
  "Your phone number": 'Your phone number',
  "your post": 'your post',
  "your request is pending approval": 'Your request is pending approval',
  "your subscription will be renew on {date}": 'Your subscription will be renew on {date}',
  "new template": 'New template',
  "Your message have been sent": 'Your message have been sent',
  "verified": 'Verified',
  "price history": 'Price history',
  "price type": 'Price type',
  "product groups": 'Product Groups',
  "representative": 'Representative',
  "representative chart": 'Representative Chart',
  "the content will be delivered to you in the language you choose": 'The content will be delivered to you in the language you choose',
  "favorites": 'Favorites',
  "chart name": 'Chart name',
  "select categories": 'Select categories',
  "the dataset has been deleted": 'The dataset has been deleted',
  "create article": 'Create Article',
  "have sent you invitation": 'have sent you invitation',
  "related dataset": 'Related dataset',
  "Send invitation to your friends, by sending a copy of the page link below": 'Send invitation to your friends, by sending a copy of the page link below',
  "Copy and share the page link below to your friends.": 'Copy and share the page link below to your friends.',
  "Response from provider will be sent via {email}": 'Response from organization will be sent via {email}',
  "options": 'Options',
  "action": 'Action',
  "timeout": 'Timeout',
  "limit": 'Limit',
  "change": 'Change',
  "none": 'none',
  "5 minutes": '5 minutes',
  "30 minutes": '30 minutes',
  "1h": '1h',
  "1day": '1day',
  "Join As Member": 'Join As Member',
  "Join As Admin": 'Join As Admin',
  "others data": 'Others data',
  "channel management": 'Channel Management',
  "channel list": 'Channel List',
  "all channels": 'All channels',
  "technical specs": 'Technical Specifications',
  "functional specs": 'Functional Specifications',
  "other specs": 'Other Specifications',
  "custom specs": 'Custom Specs',
  "delivery": 'Delivery',
  "shipment": 'Shipment',
  "period": 'Period',
  "spot": 'Spot',
  "forward": 'Forward',
  "basis": 'Basis',
  "select period": 'Select Period',
  "from date": 'From Date',
  "to date": 'To Date',
  "Select the category in which the system will provide you with the most current market data": 'Select the category in which the system will provide you with the most current market data',
  "Thanks sponsors bring freely these charts to you": 'Thanks sponsors bring freely these charts to you',
  "Thanks sponsors for updating this news to you": 'Thanks sponsors for updating this news to you',
  "let's start for free": 'Let\'s start for free',
  "created": 'Created',
  "quotes of provider": 'Quotes of organization',
  "announce": 'Announce',
  "Name": 'Name',
  "product price announcement": 'Product price announcement',
  "create report": 'Create report',
  "reports": 'Reports',
  "the recipient email": 'The recipient\'s email',
  "new email": 'New email',
  "price_type": 'Price type',
  "province": 'Province',
  "Pricing Reports": 'Pricing Reports',
  "Create price alerts to send them to buyers, buying partners, or make product prices announce": 'Create price alerts to send them to buyers, buying partners, or make product prices announce',
  "products quotation": 'Products Quotation',
  "effected date": 'Effected Date',
  "price update": 'Price Update',
  "no price tag": 'No price tag',
  "updated at": 'Updated at',
  "reference price": 'Reference Price',
  "month": 'Month',
  "basis price": 'Basis Price',
  "forward price": 'Forward Price',
  "delivery type": 'Delivery Type',
  "general basic prices": 'General basic prices',
  "leave blank to select all": 'Leave blank to select all',
  "This information is confidential and is only for the member to be submitted": 'This information is confidential and is only for the member to be submitted',
  "sure to remove": 'Sure to remove ?',
  "Enter a price for the period": 'Enter a price for the period',
  "advanced entry": 'Advanced Entry',
  "flat price": 'Flat price',
  "quoted prices": 'Quoted prices',
  "start (/end) date in term months": 'Start (/end) date in term months',
  "select provinces": 'Select Provinces',
  "spot price": 'Spot Price',
  "confirm": 'Confirm',
  "delivery type option (delivery is default): this option affects the product price": 'Delivery Type Option (Delivery is default): This option affects the product price',
  "delivery price": 'Delivery price',
  "shipment price": 'Shipment price',
  "add spot price": 'Add spot price',
  "add forward price": 'Add forward price',
  "period range": 'Period range',
  "This information is adjusted within the scope of the report, the content of the original information has not been changed": 'This information is adjusted within the scope of the report, the content of the original information has not been changed',
  "original price": 'Original Price',
  "feedin users": 'Feedin Users',
  "choose user": 'Choose users',
  "Select the term for the price input": 'Select the term for the price input',
  "price unit": 'Price Unit',
  "enter new price": 'Enter new price',
  "Enter the price / update price in this screen, the price will be updated to the latest at the current date for each price type (spot, forward)": 'Enter the price / update price in this screen, the price will be updated to the latest at the current date for each price type (spot, forward)',
  "incoterm": 'Incoterm',
  "transport type": 'Transport type',
  "unit of the basis price": 'Unit of the basic price',
  "new price record by location": 'New price record by location',
  "This location has been declared, cannot be added": 'This location has been declared, cannot be added',
  "choose another location": 'Choose another location',
  "new price set": 'New price set',
  "choose another option for new price set": 'choose another option for new price set',
  "These options are already declared, cannot be added": 'These options are already declared, cannot be added',
  "add new forward price": 'Add new forward price',
  "send to": 'Send to',
  "edit quotes": 'Edit quotes',
  "other unit": 'other unit',
  "if basis price is required": 'If basis price is required',
  "range": 'Range',
  "editor's choice": 'Editor\'s choice',
  "remove the 2nd unit": 'Remove the 2nd unit',
  "hidden price": 'Hidden price',
  "wholesale": 'Wholesale',
  "contact provider for wholesale price": 'Contact organization for wholesale price',
  "provided by": 'Provided by',
  "about product": 'About product',
  "brief description": 'Brief description',
  "manufactor": 'Manufactor',
  "add new specification": 'Add new specification',
  "selecting...": 'Selecting...',
  "select": 'Select',
  "method type": 'Method type',
  "operation": 'Operation',
  "visible": 'Visible',
  "attribute": 'Attribute',
  "attribute value": 'Attribute Value',
  "trademark": 'Trademark',
  "factory": 'Factory',
  "Specification/Additional Details": 'Specification/Additional Details',
  "tap on here to start your articles": 'Tap on here to start your articles',
  "attribute group": 'Attribute Group',
  "displayed on product information": 'Displayed on product information',
  "page not found": 'Page not found',
  "The page you are opening does not exist, please check the link or return to the homepage": 'The page you are opening does not exist, please check the link or return to the homepage',
  "catalog group": 'Catalog group',
  "manage page": 'Manage Page',
  "main page": 'Main Page',
  "use for": 'Use For',
  "set_channel": 'Update posting channel',
  "added value": 'Added value',
  "product line": 'Product Line',
  "2nd unit": '2nd unit',
  "2nd price unit": '2nd price unit',
  "The effective date of the current price set will be adjusted to the date below": 'The effective date of the current price set will be adjusted to the date below',
  "Generate new updated price data effective as of the date below. Data will be temporarily recorded according to the price of the most recent day.": 'Generate new updated price data effective as of the date below. Data will be temporarily recorded according to the price of the most recent day.',
  "Copy the current priceset for another location with the same priceset": 'Copy the current priceset for another location with the same priceset',
  "Please contact the administrator of FeedIn to put the data on the site": 'Please contact the administrator of FeedIn to put the data on the site',
  "Paste a link to embed content from another site (e.g. Twitter) and click Ok":'Paste a link to embed content from another site (e.g. Twitter) and click Ok',
  "Paste a video link to embed content from another site (e.g. Youtube) and click Ok":'Paste a video link to embed content from another site (e.g. Youtube) and click Ok',
  "Announcement of information":'Announcement of information',
  "Sell products, supply items to the market":'Sell products, supply items to the market',
  "Provide published information and data for the industry":'Provide published information and data for the industry',
  "event": 'Event',
  "events":'Events',
  "create event":'Create an event',
  "event name":'Event name',
  "what is your event name?":'What is your event name?',
  "end date and time":'end date and time',
  "time":'Time',
  "back to home":'Back to home',
  "meeting":'Meeting',
  "online":'Online',
  "online event?":'Online event?',
  "online event":'Online event',
  "co-hosts":'Co-Hosts',
  "created by":'Created by',
  "milestone":'Milestone',
  "start date":'Start date',
  "start time":'Start time',
  "end date":'End date',
  "end time":'End time',
  "Select event type (online / meeting)":'Select event type (online / meeting)',
  "Event, what?, when?":'Event, what?, when?',
  "Event name, start time ":'Event name, start time ',
  "Add a physical location for people to join your event. Or how to meet together in online event":'',
  "Event, where?":'Event, where?',
  "Provide more information about your event so guests know what to expect.":'',
  "Event, how?":'Event, how?',
  "Everyone meeting on the internet.":'Everyone meeting on the internet.',
  "Everyone meet together at a specific location.":'Everyone meet together at a specific location.',
  "required":'Required',
  "If you have completely forgotten your old password. Please log out and use the \"{1}\" function to reset the password if necessary":'If you have completely forgotten your old password. Please log out and use the \"{1}\" function to reset the password if necessary',
  "Please select the language you want as the display language on the application":'Please select the language you want as the display language on the application',
  "farm":'Farm',
  "recover login password":'Recover login password',
  "individual":'Individual',
  "you are":'You are',
  "organization name":'Organization name',
  "attention: please check your activation email in the spam area":'Attention: please check your activation email in the Spam Area',
  "with your email":'With your email',
  "no products, please add products to your page":'No products, please add products to your page',
  "price set history management": 'Price set history management',
  "product configuration": 'Product configuration',
  "events in category":'Events in category',
  "no events":'No events',
  "select / create":'Select / Create',
  "co-admins":'Co-Admins',
  "weekly":'Weekly',
  "quarterly":'Quarterly',
  "yearly":'Yearly',
  "the event will repeat in":'The event will repeat in',
  "finish time":'Finish time',
  "updating date": 'Updating date',
  "price is valid from the date": 'Price is valid from the date',
  "contract type": 'Contract Type',
  "reminder": 'Reminder',
  "type of event": 'Type of event',
  "invisible": 'Invisible',
  "speakers":'Speakers',
  "select user": 'Select user',
  "event_types": 'Type of event?',
  "something is wrong, want to delete it?": 'Something is wrong, want to delete it?',
  "successfully deleted": 'successfully deleted',
  "are you sure to delete this?": 'Are you sure to delete this?',
  "deletion is ignored": 'Deletion is ignored',
  "insert here": 'Insert here',
  "not milestone": 'Not milestone',
  "The next event is scheduled in": 'The next event is scheduled in',
  "The next event is scheduled to take place at {1}": 'The next event is scheduled to take place at {1}',
  "start": 'Start',
  "finish": 'Finish',
  "products can be searched": 'Products can be searched',
  "your product has been declared complete, you can put it on the store": 'Your product has been declared complete, you can put it on the store',
  "Do you want to activate this product?": 'Do you want to activate this product?',
  "This product will add to your Activated Catalog and will be visible to buyers. You will also start receiving BuyLeads for this product. Thank you": 'This product will add to your Activated Catalog and will be visible to buyers. You will also start receiving BuyLeads for this product. Thank you',
  "published": 'Published',
  "profiles": 'Profiles',
  "incoming requests management": 'Incoming requests management',
  "waiting members": 'Waiting members',
  "sales": 'Sales',
  "purchases": 'Purchases',
  "quote prices": 'Quote Prices',
  "market prices": 'Market prices',
  "Contact information has not been declared, it is good to declare contact information so that customers can contact you directly": 'Contact information has not been declared, it is good to declare contact information so that customers can contact you directly',
  "members management": 'Members management',
  "partners": 'Partners',
  "vendor": 'Vendor',
  "vendors": 'Vendors',
  "customer": 'Customer',
  "customers": 'Customers',
  "contact books": 'Contact Books',
  "add new contact book": 'Add new Contact Book',
  "connect to user": 'Connect to User',
  "connect to organization": 'Connect to Organization',
  "connect to partner": 'Connect to Partner',
  "new contact book": 'New contact book',
  "new partner": 'New partner',
  "partner type": 'Partner Type',
  "partner name": 'Partner Name',
  "edit contact book": 'Edit contact book',
  "edit partner": 'Edit partner',
  "all partners": 'All Partners',
  "add new partner": 'Add new partner',
  "for sales notes": 'For sales notes',
  "for purchase notes": 'For purchase notes',
  "notes": 'Notes',
  "Note: The effective date of the price being updated is different from the price entry date": 'Note: The effective date of the price being updated is different from the price entry date',
  "product catalog": 'Product Catalog',
  "group by": 'Group by',
  "type of product use for sales / purchases": 'Type of product use for sales / purchases',
  "sales / purchases": 'Sales / Purchases',
  "added": 'Added',
  "feedin organizations": 'FeedIn Organizations',
  "refresh chart": 'Refresh chart',
  "incoming quotes": 'Incoming quotes',
  "purchasing planning": 'Purchasing planning',
  "manage outgoing quotes": 'Manage outgoing quotes',
  "product group title": 'Product Group Title',
  "Successfully Sign Up": 'Successfully Sign Up',
  "redirect in {1} seconds": 'redirect in {1} seconds',
  "Thank you for registering, Website will redirect to login page after 3 seconds": 'Thank you for registering, Website will redirect to login page after 3 seconds',
  "add products":'Add products',
  "price offer partners":'Price offer partners',
  "Select the products for which you want to send price information to your partner":'Select the products for which you want to send price information to your partner',
  "products for partner":'Products for partner',
  "the list below shows only partners linked to the organizations on FeedIn": 'The partners in this list are available on the FeedIn system',
  "bookmarks":'Bookmarks',
  "my space":'My Space',
  "all bookmarks":'All bookmarks',
  "quotations for partners":'Quotations for partners',
  "favourites": 'Favourites',
  "vietnam market": 'Vietnam Market',
  "workspace": 'Workspace',
  "updates": 'Updates',
  "for search optimization": 'for search optimization',
  "to optimize product display": 'to optimize product display',
  "to group products with the same properties, compare prices": 'to group products with the same properties, compare prices',
  "be sent from partners": 'be sent from partners',
  "owned": 'Owned',
  "Prices below are average prices with selected location or selected period is All": 'Prices below are average prices with selected location or selected period is All',
  "Can follow the latest news and events in the industry": 'Can follow the latest news and events in the industry',
  "Explore dataset and charts": 'Explore dataset and charts',
  "to help you manage": 'To help you manage',
  "or create an organization as": 'OR Create an Organization as',
  "create organization": 'Create organization',
  "farmer": 'Farmer',
  "buyer": 'Buyer',
  "Create events to reach the commodity market":'Create events to reach the commodity market',
  "Create news channels to announce and share information to the commodity market":'Create a news channel to announce and share information to the commodity market',
  "Cooperate with FeedIn to share and provide commodity data to customers":'Cooperate with FeedIn to share and provide commodity data to customers',
  "Please contact the administrator of FeedIn":'Please contact the administrator of FeedIn',
  "name required more than {1} characters":'Name required more than {1} characters',
  "set subscription": 'Set subscription',
  "subscribed": 'Subscribed',
  "edit contact": 'Edit contact',
  "Information gathered from the declaration in the contact information": 'Information gathered from the declaration in the contact information',
  "The information will be linked to the information of partner": 'The information will be linked to the information of partner',
  "not ready": 'Not Ready',
  "product had basic information and ready to launch": 'product had basic information and ready to launch',
  "internal use": 'Internal Use',
  "{launch} the product to display on the store": '{launch} the product to display on the store',
  "OR {activate} to input the price and manage it internally": 'OR {activate} to input the price and manage it internally',
  "launch": 'Launch',
  "activate only": 'Activate only',
  "{deactivate} product": '{deactivate} product',
  "deactivate": 'Deactivate',
  "set effect date": 'Set effect date',
  "insert at": 'Insert at',
  "expire at": 'Expire at',
  "the page does not exist or the page is for internal use, you must be a member to view the content of this page.": 'The page does not exist OR the page is for internal use, you must be a member to view the content of this page.',
  "hybrid event": 'Hybrid event',
  "no data yet": 'No data yet',
  "Please login to view this content": 'Please login to view this content',
  "no data is available now": 'There is currently no data to display',
  "latest tracking news":'Latest tracking news',
  "activate": 'Activate',
  "Buy goods, collect quotes from sellers": 'Buy goods, collect quotes from sellers',
  "following charts": 'Following charts',
  "Leave your information so we can contact you again": 'Leave your information so we can contact you again',
  "contact information": 'Contact information',
  "purchase": 'Purchase',
  "enter spot price": 'Enter spot price',
  "present": 'Present',
  "weather": 'Weather',
  "Temperature - weather, tap to see more": 'Temperature - weather, tap to see more',
  "Rain (thunder) - weather, tap to see more": 'Rain (thunder) - weather, tap to see more',
  "Wind - weather, tap to see more": 'Wind - weather, tap to see more',
  "temperature": 'Temperature',
  "rain": 'Rain',
  "wind": 'Wind',
  "edit & save": 'Edit & Save',
  "create from": 'Create from',
  "remove expired": 'Remove expired',
  "delete prices at": 'Delete prices at',
  "copy prices at": 'Copy prices at',
  "enter forward price": 'Enter forward price',
  "affiliate products": 'Affiliate products',
  "at date": 'at date',
  "days": 'days',
  "months": 'months',
  "This pricing information will expire in": 'This pricing information will expire in',
  "This pricing information will expire at": 'This pricing information will expire at',
  "no expired date": 'No expired date',
  "quarters": 'quarters',
  "years": 'years',
  "you have an article in progress": 'You have an article in progress',
  "Create an information channel to promote your information and products to everyone": 'Create an information channel to promote your information and products to everyone',
  "get {free} quotes from multiple sellers": 'Get {free} quotes from multiple sellers',
  "Tell us your Requirement": 'Tell us your Requirement',
  "Tell us what You Need": 'Tell us what You Need',
  "Receive free quotes from sellers": 'Receive free quotes from sellers',
  "Seal the Deal": 'Seal the Deal',
  "Enter product/ Service name": 'Enter product/ Service name',
  "Your email address": 'Your email address',
  "Enter your name": 'Enter your name',
  "Your live in": 'Your live in',
  "Submit Requirement": 'Submit Requirement',
  "We connect": 'We connect',
  "Buyers Sellers": 'Buyers & Sellers',
  "Trusted Platform": 'Trusted Platform',
  "Safe Secure": 'Safe Secure',
  "Quick Assistance": 'Quick Assistance',
  "Create events so people can participate and learn more about your organization": 'Create events so people can participate and learn more about your organization',
  "my articles": 'My Articles',
  "Use keyboard shortcuts": 'Use keyboard shortcuts',
  "edited by": 'Edited by',
  "Publishing to:": 'Publishing to:',
  "no data": 'No data',
  "write caption for image": 'Write caption for image',
  "caption of image": 'Caption of image',
  "write caption for video": 'Write caption for video',
  "caption of video": 'Caption of video',
  "video url is invalid!": 'Video url is invalid!',
  "enter video url": 'Enter video url',
  "add caption": 'Add caption',
  "related products": 'Related Products',
  "products from the same organization":"Products from the same organization",
  "get direction":'Get direction',
  "export purchasing data":'Export purchasing data',
  "export sales data":'Export sales data',
  "select page":'Select page',
  "edit location":'Edit location',
  "send requirement":'Send requirement',
  "detail requirement":'Detail requirement',
  "attach file":'Attach file',
  "how it work":'How it work',
  "tell us what you need by filling the form":'Tell us what you need by filling the form',
  "receive verified supplier details":'Receive verified supplier details',
  "compare quotations and seal the deal":'Compare quotations and seal the deal',
  "your information":'Your information',
  "choosing a category will help the article stay on topic and more accessible to users":'Choosing a category will help the article stay on topic and more accessible to users',
  "please add images to make the article more vivid and realistic to attract more viewers":'Please add images to make the article more vivid and realistic to attract more viewers',
  "the data has been generate":'The data has been generate',
  "please click the {download} button below to download to your device":'Please click the {download} button below to download to your device',
  "article preview":'article preview',
  "back to edit":'Back to edit',
  "please login so the supplier can contact you":'Please login so the supplier can contact you',
  "product information":'Product information',
  "please provide a few details to get the most accurate response from the supplier":'Please provide a few details to get the most accurate response from the supplier',
  "article has no images":'Article has no images',
  "this channel belongs to":'This channel belongs to',
  "responded":'Responded',
  "unit price":'Unit price',
  "posted at":'Posted at',
  "respond at":'Respond at',
  "view website":'View website',
  "send message":'Send message',
  "reject":'Reject',
  "resend request":'Resend request',
  "close request":'Close request',
  "view detail":'View detail',
  "hide detail":'Hide detail',
  "quantity":'Quantity',
  "not sure":'Not sure',
  "sure":'Sure',
  "you have found a supplier for this product and you want to close this request":'You have found a supplier for this product and you want to close this request',
  "are you sure":'Are you sure',
  "company name (if you buy for the company)":'company name: (if any, if you are buying for company)',
  "Request a quote / Order":'Request a quote / Order',
  "avatar is selected from the post, go back and add an image to make the post more lively and attractive":'Avatar is selected from the post, go back and add an image to make the post more lively and attractive',
  "get the latest and most accurate quotes from suppliers for the product you are interested in":'Get the latest and most accurate quotes from suppliers for the product you are interested in',
  "filtered by":'Filtered by',
  "closed":'Closed',
  "my order request":'My order request',
  "keyword: request id, organization id (name)":'Keyword: request id, organization id (name)',
  "order requests management":'Order requests management',
  "press enter to create new page":'Press enter to create new page',
  "add new page":'Add new page',
  "product info":'Product info',
  "order request type":'Order request type',
  "request type":'Request type ',
  "received at":'Received at',
  "product price":'Product price',
  "min quantity":'Min quantity',
  "feedback":'Feedback',
  "productinfo":'Product info',
  "deleted":'Deleted',
  "alert drafting article description":'This article is subject to change and has not yet been published. You can click here to continue drafting and publishing content.',
  "delete article":'Delete article',
  "language linking":'Language linking',
  "articles in other languages":'Articles in other languages',
  "Are you sure remove this related article?":'Are you sure remove this related article?',
  "Type the title of the article in other languages to search and add the article you want to link to.": 'Type the title of the article in other languages to search and add the article you want to link to.',
  "linked articles": 'Linked articles',
  "edit article setting": 'Edit article settings',
  "features group":'Features group',
  "lasted articles":'Lasted articles',
  "learn more":'Learn more',
  "Information channel is a function to support information sharing and disclosure of organizations and groups":'Information channel is a function to support information sharing and disclosure of organizations and groups',
  "Information channels are maintained throughout":'Information channels are maintained throughout',
  "Groups, Organizations... can set up 1 or more channels":'Groups, Organizations... can set up 1 or more channels',
  "Event is an activity for the purpose of sharing information, recording progress for participants and interested communities":'Event is an activity for the purpose of sharing information, recording progress for participants and interested communities',
  "The event took place in a finite time":'The event took place in a finite time',
  "Events are created from Groups, Organizations, ...":'Events are created from Groups, Organizations, ...',
  "Organization is a Group, Association, Community..., established to share commodity price information to farmers":'Organization is a Group, Association, Community..., established to share commodity price information to farmers',
  "Organize activities throughout, be recognized for the group activities":'Organize activities throughout, be recognized for the group activities',
  "Organizations are created by individuals, and can be made up of 1 to many individuals or organizations, can be used as a branch of another organization":'Organizations are created by individuals, and can be made up of 1 to many individuals or organizations, can be used as a branch of another organization',
  "Organizations use tools: Events, Information Channels, Articles to operate and share information with others":'Organizations use tools: Events, Information Channels, Articles to operate and share information with others',
  "organized by":'organized by',
  "suggested dataset":'Suggested dataset',
  "by":'by',
  "insights":'Insights',
  "reposts":'Reposts',
  "q-and-a":'Q and A',
  "poll list":'Poll list',
  "reposts by":'reposts by',
  "total views":'Total views',
  "total votes":'Total votes',
  "total comments":'Total comments',
  "total follows":'Total follows',
  "commented":'commented',
  "in post":'in post',
  "followed":'followed',
  "yesterday":'Yesterday',
  "today":'Today',
  "last 7 days":'Last 7 days',
  "this month":'This month',
  "last month":'Last month',
  "new articles":'New articles',
  "interactive":'Interactive',
  "recent products":'Recent products',
  "messaged":'messaged',
  "recent channels":'Recent channels',
  "keyword: name, tax number, phone number, email":'Keyword: name, tax number, phone number, email,...',
  "exit conversation":'Exit conversation',
  "are you sure delete this message":'Are you sure delete this message ?',
  "are you sure delete this":'Are you sure delete this ?',
  "alternative email":'Alternative email',
  "recent dataset":'Recent dataset',
  "short name":'Short name',
  "first name":'First name',
  "last name":'Last name',
  "---Choose One---":'---Choose One---',
  "not update":'Not update',
  "create account" : "Create account",
  "explore the community directory" : "Explore the community directory",
  "by using Feedin, you agree to our":"By using Feedin, you agree to our",
  "do you want to register as" : "Do you want to register as",
  "register as a member of {app_name}" : "Register as a member of {app_name}",
  "or register to create an organization/group/association" : "Or register to create an organization/group/association",
  "register note description" : "You are creating an account as an organization, which means you will create a new organization and this account will be its owner. If you only want to create a personal account, please go back and select the \"Individual\" option.",
  "type to search" : "Type to search...",
  "notifications" : "Notifications",
  "mine" : "Mine",
  "recently watched" : "Recently watched",
  "suggested channels" : "Suggested channels",
  "lasted-articles" : "Lasted articles",
  "create articles" : "Create articles",
  "opening" : "Opening",
  "my following events" : "My following events",
  "participants" : "Participants",
  "you have registered to attend the event" : "You have registered to attend the event",
  "no data available to display" : "No data available to display",
  "list of categories" : "List of categories",
  "choose categories description" : "FeedIn has grouped the information by categories of goods to help you better access information and avoid missing information. Please choose the categories you like below.",
  "unfollow all categories" : "Unfollow all categories",
  "understood" : "Understood",
  "create new folder" : "Create new folder",
  "order information" : "Order information",
  "list of channel" : "List of channel",
  "change folder name" : "Change folder name",
  "do you want delete this folder" : "Do you want delete this folder ?",
  "this will permanently delete it but its items will still be in your saved list" : "This will permanently delete it, but its items will still be in your saved list.",
  "admin of" : "Admin of",
  "poll_question" : "Poll question",
  "choose organization" : "Choose organization",
  "activities" : "Activities",
  "organizational structure" : "Organizational structure",
  "operating" : "Operating",
  "latest followed user" : "Latest followed user",
  "showing" : "Showing",
  "of" : "of",
  "record(s)" : "record(s)",
  "list of event" : "List of event",
  "list of dataset" : "List of dataset",
  "add new" : "Add new",
  "list of co-hosting":"List of owners (represented by)",
  "list of co-owners":"List of members",
  "activities of member":"Activities of member",
  "related events" : "Related events",
  "peer organization":"Peer organization",
  "add host" : "Add host",
  "type account" : "Type account",
  "date desc" : "Date desc",
  "date asc" : "Date asc",
  "co-owners" : "Co-owners",
  "user of feedin" : "User of FeedIn",
  "ex: email":"Ex: example_email@gmail.com",
  "this email address is associated with the account" : "This email address is associated with the account",
  "enter email address" : "Enter email address",
  "not found email description" : "The email address could not be found in the system. Please check if you have entered the correct email address.",
  "full name" : "Full name",
  "date of birth" : "Date of birth",
  "member type" : "Member type",
  "ex: phone":"Ex: 0987654321",
  "type name and enter" : "Type name and enter",
  "update information" : "Update information",
  "no limit" : "No limit",
  "click to expanded" : "Click to expanded",
  "click to edit" : "Click to edit",
  "products catalog" : "Products catalog",
  "edit products catalog title" : "Edit products catalog title",
  "product catalog title" : "product catalog title",
  "order request management" : "Order request management",
  "outgoing quotes management" : "Outgoing quotes management",
  "list of report" : "List of report",
  "report at" : "Report at",
  "view report" : "View report",
  "delete report" : "Delete report",
  "edit report" : "Edit report",
  "are you sure delete report this" : "Are you sure delete report this ?",
  "yes" : "Yes",
  "no" : "No",
  "are you sure delete partner this" : "Are you sure delete partner this ?",
  "click to select" : "Click to select",
  "partner can view" : "Partner can view",
  "products can view" : "Products can view",
  "view chart" : "View chart",
  "are you sure to remove the expired" : "Are you sure to remove the expired ?",
  "confirm to expire at" : "Confirm to expire at : ",
  "copy prices" : "Copy prices",
  "add new unit" : "Add new unit",
  "type content" : "Type content ...",
  "input search loading default" : "Input search loading default",
  "do you want delete this product" : "Do you want delete this product ?",
  "create option" : "Create option : ",
  "price supplying partners" : "Price supplying partners",
  "add new partner description" : "Partners are organizations, companies, individuals, etc., who are related to and collaborate on the work of your organization or company.",
  "update products catalog" : "Update products catalog",
  "view chart of catalog" : "View chart of products catalog",
  "Tap here to write content. \nType @ to mention" : "Tap here to write content. \nType @ to mention",
  "organization type" : "Organization type",
  "linked pages" : "Linked pages",
  "location list" : "Location list",
  "contacts list" : "Contacts list",
  "are you sure delete this location" : "Are you sure delete this location ?",
  "are you sure delete this contact" : "Are you sure delete this contact ?",
  "hosts" : "Hosts",
  "launch the product to display on the store" : "Launch the product to display on the store",
  "missing" : "Missing",
  "link account" : "Link account",
  "are you sure delete this contact info" : "Are you sure delete this contact info ?",
  "are you sure delete this partners" : "Are you sure delete this partners ?",
  "invite information" : "Invite information",
  "confirmation" : "Confirmation",
  "{type} will delete will be gone forever. are you sure" : "{type} will delete will be gone forever. Are you sure ?",
  "are you sure you want to delete it permanently" : "Are you sure you want to delete it permanently ?",
  "what your channel name" : "What your channel name ?",
  "create new post" : "Create new post",
  "administration panel" : "Administration panel",
  "do you want to leave" : "Do you want to leave ?",
  "you will no longer be a member of this community" : "You will no longer be a member of this community",
  "news update" : "News update",
  "You can click see more to see this full information" : "You can click \"See more\" to see this full information",
  "related units" : "Related units",
  "total org members":"Total org members",
  "total peer organization" : "total peer organization",
  "peer organization list" : "Peer organization list",
  "create short news or share links from other sources" : "Create short news or share links from other sources",
  "poll question" : "Poll question",
  "publish a post" : "Publish a post",
  "posted on the channel" : "posted on the channel",
  "create post setting" : "Create post setting",
  "Only members can view data content, post articles or comments on this" : "Only members can view data content, post articles or comments on this",
  "note create poll description" : "Creating a poll is presenting an issue with various solutions and relying on the choice of the majority to provide an answer to your problem.",
  "questions/voting content" : "Questions / Voting content",
  "questions/ content" : "Questions/ content",
  "yes/no questions description" : "YES: The answer has 2 options: True - False; NO: Answer is optional",
  "answers can be voted on" : "Answers can be voted on",
  "you" : "you",
  "is yes/no question" : "Is yes/no question ?",
  "number of possible answers in a poll" : "Number of possible answers in a poll",
  "input answer" : "Input answer",
  "one answer":"One answer",
  "multiple answer":"Multiple answer",
  "number of choices":"Number of choices",
  "copy path":"Copy path",
  "position" : "Position",
  "Short description about your organization!":"Short description about your organization!",
  "organization info" : "Organization information",
  "managed by" : "Managed by",
  "add new location description" : "Adding office locations, branches, and subsidiaries helps improve accessibility to your organization for other users.",
  "add new contact description" : "Adding contact information allows other users to reach specific departments, divisions, or units within your organization.",
  "default select all" : "Default selected all",
  "This product removed from your Catalog and won't be visible to buyers" : "This product removed from your Catalog and won't be visible to buyers",
  "edit product" : "Edit product",
  "quick view" : "Quick view",
  "set as no primary" : "Set as no primary",
  "set as primary" : "Set as primary",
  "go down" : "Go down",
  "go up" : "Go up",
  "invite your friends to this channel" : "Invite your friends to this channel",
  "waiting-members" : "Waiting members",
  "secure" : "Secure",
  "permission" : "Permission",
  "price (VND)" : "Price (VND)",
  "change-password" : "Change password",
  "configure-content-by-language" : "Configure language",
  "do you want to join this event" : "Do you want to join this event ?",
  "webinar" : "Webinar",
  "list of members" : "List of members",
  "about event" : "About event",
  "registration enabled" : "Registration enabled",
  "are you sure delete this speaker" : "Are you sure delete this speaker ?",
  "list of register to participate" : "List of register to participate",
  "ex: address" : "Ex: No. 01, street 01, ward 01, district 01,...",
  "create participant" : "Create participant",
  "sign up for this event" : "sign up for this event",
  "approve participant" : "Approve participant",
  "approve" : "Approve",
  "reject registration" : "Reject registration",
  "are you sure you want to reject this registration" : "Are you sure you want to reject this registration ?",
  "delete registration" : "Delete registration",
  "are you sure you want to delete this registration" : "Are you sure you want to delete this registration ?",
  "Invite your friends to this event" : "Invite your friends to this event",
  "skip" : "Skip",
  "Select date" : "Select date...",
  "edit owner" : "Edit owner",
  "remove this item timetable" : "Remove this item timetable ?",
  "pending list" : "Pending list",
  "invite link description event":"Send an invitation to friends to join the event by sharing a copy of the link below.",
  "direct link description event":"Copy and share the link below to your friends to make it easy for everyone you want to share with to access and follow this channel.",
  "sign up to participate" : "Sign up to participate",
  "title article" : "Title article",
  "enter title" : "Enter title",
  "This article is currently a draft, and will be saved in {n1}. When you {n2}, the new content or changes become the official version." : "This article is currently a draft, and will be saved in {n1}. When you {n2}, the new content or changes become the official version.",
  "local saved" : "My Articles / Draft",
  "saved" : "Saved",
  "typing" : "Typing...",
  "information" : "Information",
  "select preview photo description" : "The list of images below is extracted from the article content. If you want images to be displayed in the article, please insert them into the article content first.",
  "classifying content" : "classifying content",
  "published status" : "Published status",
  "hidden" : "Hidden",
  "display" : "Display",
  "choosing a category will help the articles stay on topic and more accessible to users":"Choosing a category will help the articles stay on topic and more accessible to users",
  "please add images to make the articles more vivid and realistic to attract more viewers":"Please add images to make the articles more vivid and realistic to attract more viewers",
  "type cd":"Article type",
  "memo" : "Memo",
  "form" : "Form",
  "research" : "Research",
  "edit your article" : "Edit your article",
  "unfinished draft":"Unfinished draft",
  "comments" : "Comments",
  "is_me" : "Me",
  "alert drafting articles description":"This articles is subject to change and has not yet been published. You can click here to continue drafting and publishing content.",
  "do not repeat" : "Do not repeat",
  "time takes place" : "Time takes place",
  "end" : "End",
  "Use Shift+Enter to break line" : "Use Shift+Enter to break line",
  "file" : "File",
  "attached image" : "Attached image",
  "attached file" : "Attached file",
  "responding" : "Responding",
  "are you sure delete this conversation" : "Are you sure delete this conversation ?",
  "group of members" : "Group of members",
  "description roles" : "Join with the role of a member or administrator.",
  "description timeout" : "Remaining time for you to register for participation.",
  "description limit" : "If you do not want to limit the quantity, enter the number 0.",
  "description group of members" : "Click to select or type a new type and press Enter to add a new one.",
  "description public" : "When selecting 'public,' the information will be displayed on the organization's homepage.",
  "description title" : "Title to display on the homepage. Ex: Invite you to join is member...",
  "send by" : "send by",
  "sponsors" : "Sponsors",
  "choose shortcut chart" : "Choose shortcut chart",
  "what your chart name" : "What your chart name ?",
  "select the category you interested in and then click follow" : "Select the category you're interested in and then click 'Follow'",
  "search as a user" : "Search as a user",
  "search as a channel" : "Search as a channel",
  "search as a organization" : "Search as a organization",
  "type comment" : "Type comment",







}