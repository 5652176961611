import classnames from 'classnames';
import React from 'react';
import './ContentContainer.css';
export default function ContentContainer({
	className,
	...props
}) {
	return (
		<div
			className={classnames(
				'ContentContainer',
				className
			)}
			{...props}
		/>
	);
}
