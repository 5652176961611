import {channel_getChannelsByAdmin_Api, poll_add_Api} from 'apis'
import {getId, getType} from 'apis/model/base'
import DidOnMount from 'components/DidOnMount'
import CreatePostForm from 'components/Feed/InputBoard/CreatePostForm'
import {InputBoardContext} from 'components/Feed/InputBoard/InputBoardContext'
import {PostPrivacyTypes} from 'components/Feed/InputBoard/PostPrivacy'
import {LoginContext} from 'components/LoginContext'
import {Formik, FormikConsumer} from 'formik'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import React, {useContext} from 'react'
import {createRequiredLogin, ModalLayout} from 'routes/createRequiredLogin'
import ChannelCreatePost from 'views/Channel/components/ChannelCreatePost'
import CreatePoll from 'views/CreatePoll'
import {DelayRender} from 'views/Discovery/DelayRender'
import NoFoundPage from 'views/NoFoundPage'
import ServerContext from 'views/Server/ServerContext'
import {getResponseItem, Null, renderOwnChild} from 'views/Shared'
import {ObserverContext} from 'views/Shared/components/ObservedList'
import {notifyOnError} from './createErrorEvent'
import {NotificationActionTypes, successNotify} from './createNotification'
import {LayoutContext} from "../../../components/layouts/Default/LayoutContext";
import {renderIfElse} from "../../Shared";
import {FullSideModalLayout} from "../../../components/Modal/SideModalLayout";

export const PostTypes = Object.freeze({
  POLL: 'poll',
  EMBED: 'embed',
  IMAGES: 'images',
  DEFAULT: 'default',
  ARTICLE: 'article',
})

const PollPost = ({id, type, onDismiss, onSuccess = Null, Wrapper = renderOwnChild}) => {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const {addPost = Null} = useContext(ObserverContext)

  const {isLoading, handleAsyncAction} = useAsyncAction({
    apiInfo: poll_add_Api,
    onSuccess: (result, {response}) => {
      const newItem = getResponseItem(response)
      successNotify(NotificationActionTypes.CREATE, t)
      addPost(newItem)
      onSuccess(newItem)
    },
    onError: notifyOnError(t),
  })

  const {options} = useContext(InputBoardContext)

  const container = {id, type}

  const initialValues = {
    container_id: id,
    containers: options,
    container_type: type,
    default_owner_id: getId(login),
    default_container_id: getId(container),
    privacy: PostPrivacyTypes.public.value,
  }

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}>
        <FormikConsumer>
          {({values}) => (
            <CreatePoll
              initialValues={values}
              isLoading={isLoading}
              onSubmit={(values) => {
                const {
                  owner,
                  container,
                  containers,
                  container_id,
                  container_type,
                  default_owner_id,
                  default_container_id,
                  ...rest
                } = values ?? {}
                handleAsyncAction(
                  {
                    ...rest,
                    owner_id: getId(owner?.data),
                    owner_type: getType(owner?.data),
                  },
                  {id: getId(container?.data), prop: getType(container?.data)}
                )
              }}
              onCancel={onDismiss}
            />
          )}
        </FormikConsumer>
      </Formik>
    </Wrapper>
  )
}


const SimplePost = ({id, type, onDismiss}) => {
  const login = useContext(LoginContext)
  return (
    <CreatePostForm
      onCancel={onDismiss}
      initialValues={{
        default_container_id: id,
        default_owner_id: getId(login),
      }}
    />
  )
}

const createPost = (type) => {
  let Component = Null
  switch (type) {
    case PostTypes.DEFAULT:
      Component = SimplePost
      break
    case PostTypes.POLL:
      Component = PollPost
      break
    default:
      return (
        <div className="flex w-full justify-center">
          <NoFoundPage/>
        </div>
      )
  }

  return createRequiredLogin()((props) => (
    <ServerContext.Consumer>
      {({setCurrent = Null, ...rest}) => {
        const onCancel = () => {
          setCurrent(null)
        }

        return (
          <InputBoardContext.Consumer>
            {({current, options, setCurrent = Null}) => {
              const {container} = current ?? {}
              const id = getId(container)
              const type = getType(container)
              const containers = Array.from(options ?? [])

              return Boolean(container || containers.length) ? (
                <LayoutContext.Consumer>
                  {({isSm}) => (
                    renderIfElse(
                      isSm,
                      <FullSideModalLayout renderHeader={() => null}>
                        <div className="p-3 stickyTop w-full relative">
                          <NavigationContext.Consumer>
                            {({history}) => (
                              <DelayRender lazy={true}>
                                <Component
                                  {...props}
                                  {...{
                                    id,
                                    type,
                                    onDismiss: () => {
                                      onCancel()
                                      setCurrent(null)
                                    },
                                    onSuccess: (item) => {
                                      onCancel()
                                      setCurrent(null)
                                      history.push({
                                        state: {
                                          refreshToken: Date.now(),
                                        },
                                      })
                                    },
                                  }}
                                />
                              </DelayRender>
                            )}
                          </NavigationContext.Consumer>
                        </div>
                      </FullSideModalLayout>,
                      <ModalLayout
                        width={600}
                        closable={false}
                        onCancel={() => {
                          onCancel()
                          setCurrent(null)
                        }}>
                        <NavigationContext.Consumer>
                          {({history}) => (
                            <DelayRender lazy={true}>
                              <Component
                                {...props}
                                {...{
                                  id,
                                  type,
                                  onDismiss: () => {
                                    onCancel()
                                    setCurrent(null)
                                  },
                                  onSuccess: (item) => {
                                    onCancel()
                                    setCurrent(null)
                                    history.push({
                                      state: {
                                        refreshToken: Date.now(),
                                      },
                                    })
                                  },
                                }}
                              />
                            </DelayRender>
                          )}
                        </NavigationContext.Consumer>
                      </ModalLayout>
                    )
                  )}
                </LayoutContext.Consumer>
              ) : (
                <ChannelCreatePost
                  key={[container, containers.length]}
                  onClose={() => {
                    onCancel()
                    setCurrent(null)
                  }}
                  linkTo={(channel) => {
                    setCurrent({
                      container: channel,
                    })
                  }}
                  apiInfo={channel_getChannelsByAdmin_Api}>
                  {(toggle) => <DidOnMount handler={() => toggle(true)}/>}
                </ChannelCreatePost>
              )
            }}
          </InputBoardContext.Consumer>
        )
      }}
    </ServerContext.Consumer>
  ))
}

const EnhancedPost = createPost

export default EnhancedPost
