import logParams from 'helpers/logParams'
import {Pure} from 'components/Pure'
import React, {useEffect, useRef, useState} from 'react'
import useKey from 'react-use/lib/useKey'

export const SelectList = ({
                             children = [],
  onSelected = logParams
}) => {
  const [
    version,
    setVersion
  ] = useState(Date.now())
  const ref = useRef({
    selectedkey: 0
  })
  useEffect(() => {
    ref.current.children = children
    ref.current.length = children.length
    ref.current.selectedkey = Math.min(
      ref.current.selectedkey,
      children.length - 1
    )
    setVersion(Date.now())
  }, [children])
  useKey('ArrowUp', () => {
    ref.current.selectedkey =
      (ref.current.length -
        1 +
        ref.current.selectedkey) %
      ref.current.length
    setVersion(Date.now())
  })
  useKey('Enter', () => {
    onSelected(
      ref.current.children[
        ref.current.selectedkey
      ],
      ref.current.selectedkey
    )
  })
  useKey('ArrowDown', () => {
    ref.current.selectedkey =
      (ref.current.length +
        1 +
        ref.current.selectedkey) %
      ref.current.length
    setVersion(Date.now())
  })
  return (
    <Pure input={[version]}>
      <div className="flex flex-col gap-2">
        {children.map((e, i) => (
          <div key={e.props.id}>
            {e}
          </div>
        ))}
      </div>
    </Pure>
  )
}
