import {Avatar, Button, DatePicker, Form, Input, Modal, Select, Tabs, Tooltip} from 'antd'
import {create_product_getProducts_Api, create_product_updatePrice_Api} from 'apis'
import FieldDecorator from "components/form/FieldDecorator"
import ImageLoader from 'components/ImageLoader'
import Toggle from 'components/Toggle'
import {API_ROOT_URL} from 'envs/_current/config'
import logParams from 'helpers/logParams'
import useQueryString from 'hooks/useQueryString'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import {Pure} from "components/Pure"
import useAsync from 'modules/asyncCache/useAsync'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import numeral from "numeral"
import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {SelectCountries} from "views/Organization/Settings/SelectCountries"
import {SelectProvinces} from "views/Organization/Settings/SelectProvinces"
import {PageContext} from '../../PageContext'
import {SellerContext} from '../SellerContext'
import Async, {AsyncWithCache} from "../../../../modules/asyncCache/components/Async";
import {ProductPriceHistory} from "./ProductPriceHistory";
import {productSchema} from "../../../../apis/schema";
import {useSelectEntities} from "../../../../redux/entities/useSelectEntities";
import {AiFillDownCircle, AiFillUpCircle} from "react-icons/ai";
import {FaClipboardList} from "react-icons/fa";
import {ProductPriceSetGroupComponent} from "./components/ProductPriceSetGroupComponent";
import {SelectEntityItem} from "../../../../components/SelectEntityItem";
import classNames from "classnames"
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";
import {create_product_price_getById_Api} from "../../../../apis";
import {AddProductPriceSetForm} from "./ManageProductPrices/AddProductPriceSetForm";
import PlusCircleOutlined from "@ant-design/icons/lib/icons/PlusCircleOutlined";
import {FullSideModalLayout} from "../../../../components/Modal/SideModalLayout";
import {emptyArray, emptyObject} from "../../../../helpers/emptyObjects";
import {BsCalendar2Week, BsCaretDownFill, BsCaretRightFill, BsPlusCircle} from "react-icons/bs";
import {renderIfElse} from "../../../Shared";
import productImageHolder from "../../productImageHolder";

const {Option} = Select

export const PriceAdvancedInputForm = ({item}) => {
  const t = useTranslate();
  const [effectDate, setEffectDate] = useState(moment())

  const {
    product_units = [],
    product_price_units = [],
    contract_types = [],
    delivery_types = [],
    incoterm_types = [],
    transport_types = [],
    countries = [],
    provinces = [],
  } = useAppConfig()

  const [__, dispatch] = useDispatchAsyncActionWithNotify();

  const product = useSelectEntities(
    item.idname,
    productSchema
  ) || item;

  const [update, setUpdate] = useState(Date.now());
  const [form] = Form.useForm();
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  const handleOnNewSubmitSetGroup = (values) => {
    const {
      contract_type_cd, delivery_type_cd, incoterm_type_cd, transport_type_cd,
      unit_id, unit2_id, price_unit_id, price_unit2_id, country_id, province_id
    } = values;

    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_updatePrice_Api(),
          query: {
            ':id':
            item.id
          },
          values: {
            contract_type_cd, delivery_type_cd, incoterm_type_cd, transport_type_cd,
            unit_id, unit2_id, price_unit_id, price_unit2_id, country_id, province_id,

            eff_at: effectDate.format("YYYY-MM-DD"),

            data: JSON.stringify([])
          }
        }
      )
    )
  }

  return (
    <div key={effectDate.format("YYYY-MM-DD")} className="p-3 space-y-6">
      <Async
        apiInfo={create_product_price_getById_Api()}
        query={{':id': item.id, ':effect_date': effectDate.format("YYYY-MM-DD")}}
      />

      <div className="px-3 py-2 flex items-center background border-2 border-primary-600 rounded-lg hover:shadow-items-md stickyTop z-3">
        <div className="flex-1 font-semibold text-color-100 italic">
          {t('effected date')}
        </div>
        <DatePicker
            style={{ minWidth: 200 }}
            className="rounded-md"
            suffixIcon={<BsCalendar2Week className="text-color-400"/>}
            allowClear={false}
            onChange={(value) =>  setEffectDate(value)}
            defaultValue={effectDate} format={dateFormatList}
        />
      </div>

      {
        [
          {
            id: 'spot',
            label: 'spot price'
          },
          {
            id: 'forward',
            label: 'forward price'
          }
        ].map((eType) => {
            const productPriceSetsByContractType = _.reduce(_.get(item, 'prices', []), (res, {data: eData, ...e}) => {
              if (e.contract_type_cd === eType.id) {
                const _locGroup = _.reduce(eData, (res1, ee) => {
                  const {data: locData, id: product_price_set_id, ...loc} = ee;
                  const {data: groupData, id: product_price_group_id, ...group} = _.get(locData, '0', {});
                  if (!!loc.max_eff_at) {
                    res1.push({
                      product_price_set_id, ...loc,
                      product_price_group_id, ...group,
                      data: groupData
                    })
                  }
                  return res1
                }, [])
                res.push({ ...e, data: _locGroup })
                return res
              }
              return res
            }, [])

            return (
              <Toggle key={item.id + '_' + eType.id + '_' + update} defaultValue={!!productPriceSetsByContractType}>
                {( isToggle, toggle ) => (
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center gap-2">
                        <div className="flex items-center gap-2">
                          <Tooltip title={t('click to expanded')} overlayClassName="font-medium text-xs italic">
                            <div
                                style={{ width: 26, height: 26 }}
                                className="flex flex-center bg-primary-50 text-primary-600 hover:bg-primary-600 hover:text-white rounded-md cursor-pointer"
                                onClick={toggle}>
                              {renderIfElse(
                                  isToggle,
                                  <BsCaretDownFill size={12} />,
                                  <BsCaretRightFill size={12} />
                              )}
                            </div>
                          </Tooltip>
                          <div className="font-bold text-color-000">
                            {t(eType.label)}
                          </div>
                        </div>
                        <div className="flex-1 border-b border-color-50"/>
                        <Toggle>
                          {( isToggleNewPriceSet, toggleNewPriceSet ) => (
                              <>
                                <Tooltip title={t('add new')} overlayClassName="font-medium text-xs italic" placement="left">
                                  <BsPlusCircle
                                      size={16}
                                      onClick={() =>  toggleNewPriceSet()}
                                      className="text-color-400 hover:text-primary-600 cursor-pointer"
                                  />
                                </Tooltip>
                                {isToggleNewPriceSet && (
                                    <Modal
                                        width={620}
                                        className="custom-modal"
                                        onCancel={toggleNewPriceSet}
                                        maskClosable={false}
                                        title={
                                          <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                                            {t('new price set')}
                                          </div>
                                        }
                                        destroyOnClose
                                        visible={isToggleNewPriceSet}
                                        footer={null}>
                                      <AddProductPriceSetForm
                                          product={product}
                                          contractTypeCode={eType.id}
                                          handleOnNewSubmitSetGroup={handleOnNewSubmitSetGroup}
                                          productPriceSetsByContractType={productPriceSetsByContractType}
                                          update={update}
                                          setUpdate={setUpdate}
                                          isToggle={isToggleNewPriceSet}
                                          toggle={toggleNewPriceSet}
                                      />
                                    </Modal>
                                )}
                              </>
                          )}
                        </Toggle>
                      </div>
                      {isToggle && (
                          (productPriceSetsByContractType || emptyArray).map(productPriceSetGroup => (
                              <React.Fragment key={JSON.stringify(productPriceSetGroup)}>
                                <ProductPriceSetGroupComponent
                                    update={update}
                                    setUpdate={setUpdate}
                                    item={product}
                                    productPriceSet={productPriceSetGroup}
                                    effectMomentDate={effectDate}
                                />
                              </React.Fragment>
                          ))
                      )}
                    </div>
                )}
              </Toggle>
            )
        })
      }

    </div>
  )
}

const ProductList = ({values, organizationId, renderItem, country_id, setCountryId, province_id = null, setProvinceId,}) => {
  const t = useTranslate();

  return (
    <div className="w-full space-y-3">
      <div className="p-2 grid grid-cols-1 md:grid-cols-2 gap-3 border border-color-50 shadow-items-md rounded-md">
        <FieldDecorator
            name="country_id"
            labelProps={{ className: undefined }}
            label={
              <div className="font-semibold text-sm text-color-300 mb-1">
                {t('country')}
              </div>
            }
            style={{
              minWidth: 160,
              width: '100%'
            }}>
          <SelectCountries
              style={{
                minWidth: 160,
                width: '100%'
              }}
              defaultValue={country_id}
              value={
                values.country_id || country_id
              }
              onChange={value => {
                setProvinceId(null);
                setCountryId(value)
              }}
          />
          <div id="getPopupContainer"/>
        </FieldDecorator>
        <FieldDecorator
            key={"province_id_by_" + country_id}
            name="province_id"
            labelProps={{ className: undefined }}
            label={(
                <div className="flex items-baseline gap-1 mb-1">
                  <span className="font-semibold text-sm text-color-300">{t('provinces')}</span>
                  <span className="text-xs text-color-400 italic">
                      {`(${t('default select all')})`}
                    </span>
                </div>
            )}
            style={{
              minWidth: 160,
              width: '100%'
            }}>
          <SelectProvinces
              countryId={country_id}
              style={{
                minWidth: 160,
                width: '100%'
              }}
              defaultValue={province_id}
              value={
                values.province_id || province_id
              }
              onChange={value => {
                setProvinceId(value);
              }}
          />
          <div id="getPopupContainer"/>
        </FieldDecorator>
      </div>
      <div className="flex flex-col space-y-3">
        <div className="px-2 py-1 border-b flex items-center justify-between text-sm uppercase">
          <div className="font-bold text-color-100">
            {t('product name')}
          </div>
          <span className="font-medium text-color-400 italic">
            {t('price')} / {t('unit')}
          </span>
        </div>
        <Pure input={[organizationId, country_id, province_id, values]}>
          <LazyPagination
              {...{
                // key: values.eventId,
                apiInfo: create_product_getProducts_Api(),
                query: {
                  ':type': 'organization',
                  ':id': organizationId
                },
                values
              }}
              key={'/products/organization/:id/get-products'.replace(":id", organizationId) + JSON.stringify(values)}
              renderItem={(item) => {
                return (
                    <SelectEntityItem
                        item={item.idname}
                        schema={productSchema}>
                      {renderItem}
                    </SelectEntityItem>
                )
              }}
          />
        </Pure>
      </div>
    </div>
  )
}

export const ManageProductPrices = ({organizationId, rootPath}) => {
  const t = useTranslate()
  const [country_id, setCountryId] = useState(237);
  const [province_id, setProvinceId] = useState(null);
  const {isSm} = useContext(LayoutContext)

  const [key, setKey] = useState(
    'listed products'
  )
  const {
    response: responseGroup
  } = useAsync({
    key: `/products/organization/${organizationId}/get-product-catalogs`,
    id: `/products/organization/${organizationId}/get-product-catalogs`,
    apiInfo: {
      root: API_ROOT_URL,
      path: `/products/organization/${organizationId}/get-product-catalogs`,
      method: 'GET'
    }
  })

  const groups = _.get(
    responseGroup,
    'data.data',
    []
  )
  const [form] = Form.useForm()

  const appConfig = useAppConfig()

  const {product_categories = [],} = appConfig || {product_categories: [],}

  const [values, setValues] = useQueryString()

  const onReset = () => {setValues({})}

  useEffect(() => {
    form.resetFields()
  }, [form, values])

  const [update, setUpdate] = useState()

  const enhancedRenderItem = item => {
    let _location_type = "country";
    let _location_id = "237";
    if (!!province_id) {
      _location_type = "province";
      _location_id = province_id;
    } else if (!!country_id) {
      _location_type = "country";
      _location_id = country_id;
    }

    const spotItem = _.reduce(_.get(item, 'prices', []), (res, {data: eData, ...e}) => {
      if (e.contract_type_cd === 'spot') {

        const {data: locData, ...loc} = _.head(_.filter(eData, (ee) => {
          return ee.location && ee.location._type === _location_type && ee.location.id == _location_id
        })) || emptyObject;

        if (locData) {
          const {data: rData, ...r} = _.get(locData, '0', {})
          const raw = _.get(rData, '0', {})

          return {
            product_price_set_id: loc.id, product_price_group_id: r.id, ...e,
            ...r,
            ...raw
          }
        }

      }

      return res;
    }, []);

    const forwardItem = _.reduce(_.get(item, 'prices', []), (res, {data: eData, ...e}) => {
      if (e.contract_type_cd === 'forward') {

        const {data: locData, ...loc} = _.head(_.filter(eData, (ee) => {
          return ee.location && ee.location._type === _location_type && ee.location.id == _location_id
        })) || emptyObject;

        if (locData) {
          const raw = _.get(locData, '0.data.0', {})

          return {
            ...e, ...loc, ...raw
          }
        }
      }
    }, []);

    const lastUpdated = !!(spotItem && spotItem.eff_at) ? moment(spotItem.eff_at) : (
      !!(forwardItem && forwardItem.eff_at) ? moment(forwardItem.eff_at) : null
    )

    return (
      <div
          key={item.id + "_" + _location_type + "_" + _location_id}
          className="flex items-center gap-x-2 border border-color-50 rounded-md hover:shadow-items-md relative">
        <ImageLoader
            style={{ width: 50, height: 50 }}
            className="object-cover w-full h-full rounded-l-md rounded-r-0 fadeIn animated"
            src={_.get(item, 'photos.0.path') || productImageHolder}
        />

        <div className="flex flex-col flex-1">
          <span className="font-bold text-color-000 truncate">
            {item.title}
          </span>
          <div className="flex items-baseline gap-3">
              {!!(spotItem && spotItem.eff_at) && (
                  <div className="text-xs italic">
                      <span className="font-bold text-color-000">{numeral(_.get(spotItem, 'price')).format("0,0[.]00")}</span>
                      <span className="text-color-400">
                          {_.get(spotItem, 'price_unit.name', t('price unit'))} / {_.get(spotItem, 'unit.name', t('unit'))}
                      </span>
                  </div>
              )}
              {!((spotItem && spotItem.eff_at) || (forwardItem && forwardItem.eff_at)) && (
                  <span className="font-medium text-xs text-blue-500 italic">
                      {`* ${t('no price tag')}`}
                  </span>
              )}
              <ProductPriceHistory {...{item}} />
          </div>
        </div>

        <div className="font-normal flex-col px-1 text-right leading-tight">
          <Toggle key={item.id}>
            {( isTogglePriceInput, togglePriceInput ) => (
                <>
                  <div
                      onClick={() => { togglePriceInput() }}
                      className="font-bold text-xs text-color-400 hover:text-color-100 italic cursor-pointer pr-2">
                    {t('advanced entry')}
                  </div>
                  {isTogglePriceInput && (
                      <FullSideModalLayout
                        right
                        onCancel={togglePriceInput}
                        maskClosable={false}
                        width={720}
                        title={
                          <div className="font-bold text-color-000">
                            {_.get(item, 'title')}
                          </div>
                        }
                        destroyOnClose
                        visible={isTogglePriceInput}
                        footer={null}>
                        <PriceAdvancedInputForm {...{item}} />
                      </FullSideModalLayout>
                  )}
                </>
              )
            }
          </Toggle>
          {!!lastUpdated && (
              <div className={classNames("px-1 flex flex-col leading-tight",
                lastUpdated.isBefore(moment().subtract(30, 'days')) ? "text-gray-500" : (
                  lastUpdated.isBefore(moment().subtract(7, 'days')) ? "text-red-500" : (
                    lastUpdated.isBefore(moment().subtract(1, 'days')) ? "text-orange-500" : (
                      lastUpdated.isBefore(moment(), "day") ? "text-blue-500" : (
                        "text-green-500"
                      )
                    )
                  )
                )
              )}
              >
                <span className="text-sm">{lastUpdated.format("DD/MM/YYYY")}</span>
                <span className="text-sm font-light">({lastUpdated.fromNow()})</span>
              </div>
          )}
        </div>
      </div>
    )
  }

  const history = useHistory()
  const active =
    key === 'listed products' ? '1' : '0'
  return (
    <Tabs
      activeKey={key}
      onChange={setKey}
      animated={false}>
      <Tabs.TabPane
        tab={t('listed products')}
        key="listed products">
        <div className="p-3 border border-color-50 shadow-items-md rounded-lg mt-4">
          <Form
            form={form}
            className=" max-w-2xl space-y-3"
            {...{
              labelCol: {span: 8},
              wrapperCol: {
                span: 16
              }
            }}
            name="basic"
            initialValues={values}
            onFinish={setValues}
            onFinishFailed={logParams}>
            <Form.Item
              {...{
                wrapperCol: {
                  offset: 8,
                  span: 16
                }
              }}>
              <div className="font-bold text-color-000 text-xl">
                {t('filter')}
              </div>
            </Form.Item>
            <Form.Item
              label={
                <span className="text-sm text-color-400 italic">
                  {t('title')}
                </span>
              }
              name="keyword">
              <Input/>
            </Form.Item>
            <Form.Item
              label={
                <span className="text-sm text-color-400 italic">
                  {t('categories')}
                </span>
              }
              name="categories">
              <Select
                showSearch
                virtual={false}
                optionFilterProp="label"
                mode="multiple"
                placeholder={t(
                  'please select'
                )}
                style={{
                  width: '100%'
                }}>
                {product_categories &&
                product_categories.map(
                  item => {
                    const {
                      id,
                      name
                    } = item
                    return (
                      <Select.Option
                        key={id}
                        value={id}
                        label={name}>
                        {name}
                      </Select.Option>
                    )
                  }
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <span className="text-sm text-color-400 italic">
                  {t('catalog')}
                </span>
              }
              name="product_catalog_id">
              <Select>
                <Option value={''}>
                  {t('all')}
                </Option>
                {groups.map(item => (
                  <Option
                    key={item.id}
                    value={item.id}>
                    {`${item.title}`}
                    {_.has(item, 'products') &&
                    ` (${item.products.length})`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              {...{
                wrapperCol: {
                  offset: 8,
                  span: 16
                }
              }}>
              <div className="space-x-3">
                <Button
                    type="primary"
                    className="button-rounded-md no-border"
                    htmlType="submit">
                  {t('search')}
                </Button>
                <Button
                    htmlType="button"
                    className="button-rounded-md no-border"
                    onClick={onReset}>
                  {t('clear')}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <div className="w-full space-y-1 mt-4">
          <ProductList
            {...{
              values: {
                ...values,
                title: values.keyword,
                active,
                update
              },
              country_id, setCountryId,
              province_id, setProvinceId,
              organizationId,
              rootPath,
              renderItem: enhancedRenderItem
            }}
          />
        </div>
      </Tabs.TabPane>
    </Tabs>
  )
}

export default () => {
  const {
    organizationId,
    rootPath
  } = useContext(SellerContext)
  const {pageRoot} = useContext(PageContext);

  return (
    <div className="p-3">
      <div className="max-w-6xl mx-auto">
        <AsyncWithCache
          cacheId="/products/get-price-periods?day=1"
          apiInfo={{
            root: API_ROOT_URL,
            path: `/products/get-price-periods?day=1`,
            method: 'GET'
          }}>
        </AsyncWithCache>
        <ManageProductPrices
          rootPath={rootPath || pageRoot}
          organizationId={
            organizationId
          }/>
      </div>
    </div>
  )
}

