import {ElementTypes} from '../types'

const withEmbeds = (editor) => {
  const {isVoid} = editor
  editor.isVoid = (element) =>
    element.type === ElementTypes.VIDEO ? true : isVoid(element)
  return editor
}

export default withEmbeds
