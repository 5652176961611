import {Badge} from 'antd'
import classNames from 'classnames'
import React from 'react'
import {FaCrown} from 'react-icons/fa'
import {ChannelFillSvg, ChannelSvg} from '../Svgs/ChannelSvg'

export const ChannelIcon = ({
                              premium = 0,
                              className,
                              fill = null,
                              strokeWidth = 1,
                              ...props
                            }) => {
  if (premium !== 1) {
    return (
      <div
        className={classNames(
          className,
          'flex items-center justify-center'
        )}
        {...props}>
        {
          !!fill ? <ChannelFillSvg {...strokeWidth}/> : <ChannelSvg {...strokeWidth}/>
        }
      </div>
    )
  } else {
    return (
      <Badge
        className={classNames(
          className,
          'flex items-center justify-center'
        )}
        {...props}
        count={
          <FaCrown
            style={{
              fontSize: '0.78rem',
              fill: '#ffb600'
            }}
          />
        }>
        <div>
          <ChannelSvg {...strokeWidth}/>
        </div>
      </Badge>
    )
  }
}
