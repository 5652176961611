import { host_getHostingStats_Api } from 'apis'
import { getId, getType } from 'apis/model/base'
import Pure from 'components/Pure'
import useAsync from 'modules/asyncCache/useAsync'
import React, { useContext } from 'react'
import { bindQueryParam } from 'views/AppLocals/functions/routerHelper'
import { getResponseItem, Null } from 'views/Shared'
import WorkspaceInsightsMetaSummary from '../Insights/WorkspaceInsightsMetaSummary'
import { PeriodFilterHeader } from '../Insights/WorkspaceInsightsSummary'
import WorkspaceContext, { WorkspaceFilterParams } from '../WorkspaceContext'

const WorkspaceHostStatistic = () => {
  const {
    organization,
    getPeriod = Null,
    setPeriod = Null,
  } = useContext(WorkspaceContext)

  const period = getPeriod(WorkspaceFilterParams.OVERVIEW)

  const owner_id = getId(organization)

  const owner_type = getType(organization)

  const {
    response,
    isLoading = false,
    handleAsyncAction = Null,
  } = useAsync({
    apiInfo: host_getHostingStats_Api,
    query: bindQueryParam({
      organization_id: owner_id,
    }),
  })

  const { summary } = getResponseItem(response)

  return (
    <React.Fragment>
      <Pure input={[period, summary, isLoading, handleAsyncAction]}>
        <WorkspaceInsightsMetaSummary
          summary={summary}
          isLoading={isLoading}
          className="grid grid-cols-2 md:grid-cols-3 gap-3 mb-8"
          Header={({ title }) => (
            <PeriodFilterHeader
              title={title}
              value={period}
              onSelect={(period) => {
                setPeriod(WorkspaceFilterParams.OVERVIEW, period)
                handleAsyncAction({
                  values: {
                    period,
                  },
                })
              }}
            />
          )}
          field_params={[
            {
              name: 'total_host_count',
              label: 'total hosts',
              extra_name: 'total_host_count_prev',
            },
            {
              name: 'total_member_count',
              label: 'total members',
              extra_name: 'total_member_count_prev',
            },
            {
              name: 'total_peer_member_count',
              label: 'total peer organization',
              extra_name: 'total_peer_member_count_prev',
            },
          ]}
        />
      </Pure>
    </React.Fragment>
  )
}

export default WorkspaceHostStatistic
