import _ from 'lodash'

export default function getItemKeyId (
	item = {}
) {
	switch (item._type) {
		default:
			return _.get(item, 'id');
	}
}
