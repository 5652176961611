import Null from "components/NullComponent"
import _ from "lodash"
import React, { useContext } from "react"
import { renderRequestSummary } from "views/OrderRequest/functions/renderRequestSummary"
import OrderRequestContext from "views/OrderRequest/OrderRequestContext"
import { renderOwnChild } from 'views/Shared'
import {renderIf} from "../../../Shared";

const RequestSummary = ({
  item = {},
  Header = Null,
  Wrapper = renderOwnChild
}) => {

  const {
    functions = {}
  } = useContext(OrderRequestContext)

  if (_.isEmpty(item)) {
    return null
  }

  const amount = functions.getProductAmount(item)

  const attributes = [
    {
      label: 'quantity',
      value: amount,
      param: _.get(item, 'qtty', 0),
      validate: ({ param }) => param > 0,
    },
    {
      label: 'unit price',
      validate: ({ value }) => value > 0,
      value: _.get(item, 'price', 0),
    }
  ].filter(({
    validate = () => true,
    ...rest
  }) => validate(rest))

  return (
      renderIf(
          item,
          <div className="flex flex-col gap-1 mt-3">
            <Header />
            {renderRequestSummary(attributes)}
          </div>
      )
  )
}

export default RequestSummary