import {default as Null} from 'components/NullComponent'
import React from 'react'

const renderSelf = (me) => me

const renderOwnChild = ({children}) => children

const renderIf = (condition = false, value) => (!!condition ? value : null)

const renderElse = (condition = false, value) => renderIf(!condition, value)

const renderIfElse = (condition = false, trueValue, falseValue) =>
  !!condition ? trueValue : falseValue

const renderWhere = (condition, render = Null) =>
  !!condition ? render() : null

const withPagerWrapper =
  (render = renderSelf) =>
    (children, __, total = 0) =>
      renderWhere(total > 0, () => render(children))

const Visible = ({visible = false, invisible = false}, __) =>
  !!visible || !!!invisible

const renderCallback = (fn = Null) => fn()

const renderTrue = () => true

const renderFalse = () => false

const renderPhone = (phone, renderBody = renderSelf, Wrapper = 'a') =>
  renderIf(
    phone,
    <Wrapper href={['tel:', phone].join('')}>{renderBody(phone)}</Wrapper>
  )

const renderMail = (mail, renderBody = renderSelf, Wrapper = 'a') =>
  renderIf(
    mail,
    <Wrapper href={['mailto:', mail].join('')}>{renderBody(mail)}</Wrapper>
  )

export const pagerControls = {
  search: {
    component: null,
    keyword: undefined,
  },
}

export {
  Null,
  Visible,
  renderIf,
  renderElse,
  renderIfElse,
  renderMail,
  renderPhone,
  renderTrue,
  renderSelf,
  renderFalse,
  renderWhere,
  renderCallback,
  renderOwnChild,
  withPagerWrapper,
}
