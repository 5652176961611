import {Avatar, Button, Form, Input, Modal, TextArea, Typography} from 'antd'
import React from 'react'
import useTranslate from "../../../../../../modules/local/useTranslate";
import {createAsyncAction} from "../../../../../../modules/asyncCache";
import useDispatchAsyncActionWithNotify from "../../../../../../modules/asyncCache/useDispatchAsyncActionWithNotify";
import {create_product_updateProductPriceGroupComment_Api} from "../../../../../../apis";
import moment from "moment";
import _ from "lodash";
import MessageFilled from "@ant-design/icons/lib/icons/MessageFilled";
import MessageOutlined from "@ant-design/icons/lib/icons/MessageOutlined";
import Toggle from "../../../../../../components/Toggle";
import classNames from "classnames";
import {IoChatboxEllipses, IoChatboxEllipsesOutline} from "react-icons/io5";

export const ProductPriceGroupCommentForm = ({
    productPriceGroup,
    isToggle,
    toggle
}) => {

  const t = useTranslate();
  const [__, dispatch] = useDispatchAsyncActionWithNotify();

  const handleOnSubmit = (productPriceGroup, values) => {
    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_updateProductPriceGroupComment_Api(),
          query: {
            ':id':
            productPriceGroup.product_price_group_id
          },
          values: {
            ...values
          }
        }
      )
    )
  }

  return (
    <div className="w-full">
      <Form onFinish={(values) => {
        handleOnSubmit(
          productPriceGroup,
          values
        );

        toggle();
      }}>
        {(values, form) => {
            return (
              <div className="flex flex-col gap-6">
                <Form.Item
                  className="flex-1"
                  name="notes">
                  <Input.TextArea
                    showCount
                    defaultValue={productPriceGroup.notes}
                    maxLength={500}
                    allowClear={true}
                    rows={8}
                    placeholder={t('type content')}
                    className="rounded-md scrollBarThin"
                  />
                </Form.Item>
                <div className="text-center my-3">
                  <Button
                      type="primary"
                      className="button-rounded-md no-border"
                      htmlType="submit">
                    {t('save')}
                  </Button>
                </div>
              </div>
            )
          }}
      </Form>
    </div>
  )
}


export const ProductPriceGroupCommentComponent = ({
    productPriceGroup,
    effectMomentDate = moment(),
    children
}) => {
  const t = useTranslate();
  const disable = !!!productPriceGroup.eff_at || moment(productPriceGroup.eff_at).diff(effectMomentDate, 'days')

  return (
    <Toggle>
      {( isToggle, toggle ) => (
        <React.Fragment>
          <Typography.Text disabled={disable}>
            {
              !!children ? children : (
                <Avatar
                    onClick={() => (!disable) && toggle()}
                    size={26}
                    className={classNames(
                        "flex flex-center",
                        disable ? 'cursor-not-allow' : 'cursor-pointer',
                        disable ? 'background-200 text-color-300' :
                            'bg-primary-50 text-primary-600 hover:bg-primary-100'
                    )}
                    icon={ !_.isEmpty(productPriceGroup.notes) ? <IoChatboxEllipsesOutline size={14} /> : <IoChatboxEllipsesOutline size={14} /> }
                />
              )
            }
          </Typography.Text>
          <Modal
            title={(
                <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                    {t('notes')}
                </div>
            )}
            className="custom-modal"
            maskClosable={false}
            destroyOnClose
            onCancel={toggle}
            visible={isToggle}
            footer={null}>
            <ProductPriceGroupCommentForm
              productPriceGroup={productPriceGroup}
              {...{isToggle, toggle}}
            />
          </Modal>
        </React.Fragment>
      )}
    </Toggle>
  )
}
