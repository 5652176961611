import {List} from 'antd'
import {event_add_Api, event_getEventsByProps_Api} from 'apis/event'
import {getId, getType} from 'apis/model/base'
import eventModel from 'apis/model/event'
import AppIcon from 'components/AppIcon'
import ApiInfoForm from 'components/form/ApiInfoForm'
import BaseItemLayout from 'components/ItemLayout/BaseItemLayout'
import Modal from 'components/Modal/Modal'
import Toggle from 'components/Toggle'
import getItemKeyId from 'helpers/getItemKeyId'
import getItemType from 'helpers/getItemType'
import {useQueryString} from 'hooks'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import * as moment from 'moment'
import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-use'
import {nest, withProps} from 'recompose'
import {modalWidth} from 'views/AppLocals/configs'
import ServerContext from 'views/Server/ServerContext'
import {Block} from '../Organization/components/Block'
import {EditAbleBlockHolder} from '../Organization/components/EditAbleBlockHolder'
import CreateEventForm from './CreateEventForm'

export const CreateEventFormModal = ({onCancel, onSuccess}) => {
  const history = useHistory()
  return (
    <Toggle defaultValue={true}>
      {(isToggle, toggle) => (
        <CreateEventFormWithModal
          visible={isToggle}
          onSuccess={(event) => {
            if (!!onSuccess) {
              onSuccess(event)
            } else {
              const [, data, __] = event
              const item = _.get(data, 'response.data')
              history.push({
                pathname: eventModel.getLinkToDetail(item),
              })
            }
          }}
          onCancel={() => {
            toggle()
            if (onCancel) {
              onCancel()
            } else {
              history.goBack()
            }
          }}
        />
      )}
    </Toggle>
  )
}

const CreateEventModalContent = (props) => {
  const location = useLocation()

  const [{owner_id, owner_type}] = useQueryString(location.pathname)

  const initialValues = _.omitBy(
    {
      owner_id,
      owner_type,
      default_owner_id: owner_id,
    },
    _.isUndefined
  )
  return (
    <ServerContext.Consumer>
      {({item}) => (
        <Translate>
          {(t) => (
            <div className="px-3">
              <ApiInfoForm
                onSuccess={props.onSuccess}
                prefixStr="CreateEventFormModal"
                apiInfo={event_add_Api}
                validate={({
                             default_owner_id,
                             default_owner_type,
                             ...values
                           }) => {
                  let errors = {}

                  if (values.currentIndex === 1) {
                    if (_.isEmpty(_.get(values, 'title'))) {
                      errors = {
                        ...errors,
                        title: t('required'),
                      }
                    }

                    if (_.get(values, 'title.length') < 10) {
                      errors = {
                        ...errors,
                        title: t('name required more than {1} characters', {
                          1: 10,
                        }),
                      }
                    }

                    if (
                      _.some(['owner_id', 'owner_type'], (name) =>
                        _.isEmpty(_.get(values, name))
                      )
                    ) {
                      errors = {
                        ...errors,
                        owner: t('required'),
                      }
                    }
                  }

                  return errors
                }}
                onPreSubmit={({
                                default_owner_id,
                                default_owner_type,
                                ...values
                              }) => {
                  let _values = values
                  if (!!values.cohosts) {
                    _values = {
                      ..._values,
                      cohosts: JSON.stringify(
                        values.cohosts.map((e) => {
                          return {
                            type: e._type,
                            id: e.id,
                          }
                        })
                      ),
                    }
                  }
                  if (
                    !!values.start_date &&
                    moment.isMoment(values.start_date) &&
                    !!values.start_time &&
                    moment.isMoment(values.start_time)
                  ) {
                    _values = {
                      ..._values,
                      time_open: moment(
                        values.start_date.format('YYYY-MM-DD') +
                        ' ' +
                        values.start_time.format('HH:mm')
                      )
                        .utc()
                        .format(),
                    }
                  }
                  if (
                    !!values.end_date &&
                    moment.isMoment(values.end_date) &&
                    !!values.end_time &&
                    moment.isMoment(values.end_time)
                  ) {
                    _values = {
                      ..._values,
                      time_close: moment(
                        values.end_date.format('YYYY-MM-DD') +
                        ' ' +
                        values.end_time.format('HH:mm')
                      )
                        .utc()
                        .format(),
                    }
                  }
                  return {
                    ..._values,
                  }
                }}
                initialValues={{
                  owner_id: getId(item),
                  owner_type: getType(item),
                  privacy: 1,
                  event_type: 'meeting',
                  start_date: moment(),
                  start_time: moment(),
                  ...initialValues,
                }}>
                {({...props}) => <CreateEventForm {...props} />}
              </ApiInfoForm>
            </div>
          )}
        </Translate>
      )}
    </ServerContext.Consumer>
  )
}

const CreateEventFormWithModal = nest(
  withProps(({visible, onCancel}) => ({
    visible: visible,
    footer: null,
    maskClosable: false,
    onCancel: onCancel,
    width: modalWidth.medium,
  }))(Modal),
  CreateEventModalContent
)

export function Events() {
  const t = useTranslate()
  const [forceRefreshKey, setForceRefreshKey] = useState(new Date().getTime())
  const {item, type, id} = useContext(ServerContext)
  const {
    action,
    isLoading,
    success,
    errorMessages,
    result = [],
    handleAsyncAction,
    error,
  } = useAsyncAction({
    apiInfo: event_getEventsByProps_Api,
    query: {
      ':type': getItemType(item),
      ':id': getItemKeyId(item),
    },
  })
  useEffect(() => {
    handleAsyncAction()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefreshKey])
  const dataSource = (item.events || []).map((item) => {
    const {title, description, id, _type} = item
    const to = eventModel.getLinkToDetail(item)
    return (
      <BaseItemLayout
        className="p-3 hover:shadow"
        {...{
          title: (
            <Link
              to={to}
              className="flex ">
              <span
                style={{
                  fontSize: '1.5em',
                }}
                className="mr-1 flex align-self-start ">
                <AppIcon item={item}/>
              </span>
              {title}
            </Link>
          ),
          description,
          to,
        }}
      />
    )
  })
  return (
    <Block>
      <div className="flex w-full flex flex-col  font-semibold text-color-300">
        <h2 className="text-2xl py-3 font-semibold flex-1">{t('events')}</h2>
        <List
          grid={{
            gutter: 36,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          dataSource={dataSource}
          renderItem={(item) => (
            <List.Item
              style={{
                height: '150px',
              }}>
              {item}
            </List.Item>
          )}
        />
      </div>
      {item.edit && (
        <Toggle>
          {(isToggle, toggle) => (
            <>
              <EditAbleBlockHolder
                onClick={toggle}
                title={t('create event')}
                description={`i take some issue with how they've defined "rewrite" in there.`}
              />
              {isToggle && (
                <CreateEventFormModal
                  onSuccess={() => setForceRefreshKey(new Date().getTime())}
                  footer={null}
                  onCancel={toggle}
                  visible={true}
                />
              )}
            </>
          )}
        </Toggle>
      )}
    </Block>
  )
}

export default Events
