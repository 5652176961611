import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import * as moment from 'moment'
import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {withProps} from 'recompose'
import {CustomEditorRenderer} from 'views/AppLocals/custom/CustomRichEditor'
import createPagerFooter from 'views/AppLocals/factory/createPagerFooter'
import {renderIf, renderSelf} from 'views/Shared'
import EventContext from '../EventContext'
import EventNews from './EventNews'
import {isRawContentEmpty} from "../../../SlateEditor/functions/parseRawContent";
import {paths} from "../../../MainPage/contains";
import categoryIcons from "../../../../conts/categoryIcons";


const MetaItem = ({label, value, transform = renderSelf}) =>
  renderIf(
    value,
    <Translate>
      {(t) => (
        <div className="flex items-baseline gap-1">
          <span className="text-xs text-color-400 italic">
            {`${t(label)} : `}
          </span>
          <span className="font-medium text-sm text-color-000">
            {transform(value)}
          </span>
        </div>
      )}
    </Translate>
  )

function toDateString(value) {
  return value ? moment(value).format('hh:mm - DD/MM/YYYY') : null
}

function toHref(value) {
  return value ? (
    <a
      href={value}
      target="_blank"
      className="flex-1 text-xs text-color-400 italic">
      {value}
    </a>
  ) : null
}

function renderIntervalWeekly(value) {
  let label = ''
  switch (value) {
    case '1000000':
      label = 'monday'
      break
    case '0100000':
      label = 'tuesday'
      break
    case '0010000':
      label = 'wednesday'
      break
    case '0001000':
      label = 'thursday'
      break
    case '0000100':
      label = 'friday'
      break
    case '0000010':
      label = 'saturday'
      break
    case '0000001':
      label = 'saturday'
      break
    default :
      label = null
      break
  }
  return label ? label : null
}

function renderInterval(value) {
  let label
  switch (value) {
    case 'D':
      label = 'daily'
      break
    case 'W':
      label = 'weekly'
      break
    case 'M':
      label = 'monthly'
      break
    default :
      label = 'do not repeat'
      break
  }
  return (
    <Translate>
      {(t) => t(label)}
    </Translate>
  )
}


const EventInfo = () => {


  const t = useTranslate()
  const {event} = useContext(EventContext)
  const {
    about,
    time_open,
    time_close,
    categories,
    description,
    external_link,
    location_name,
    event_type,
    interval_type,
    interval_daily,
  } = event ?? {}

  return (
    <div className="flex flex-col items-start space-y-6">
      <HeaderItemInfo title="detail">
        <div className="space-y-3 mb-2">
          {renderIf(
            description,
            <span className="text-sm text-color-400 italic">{description}</span>
          )}
          {[
            {
              label: 'type of event',
              value: t(event_type),
            },
            {
              label: 'location',
              value: location_name,
            },
            {
              label: 'link',
              value: external_link,
              transform: toHref,
            },
            {
              label: 'start',
              value: time_open,
              transform: (value) => {
                let label = ''
                if (interval_type === 'D') {
                  label = moment(value).format('HH:mm')
                } else if (interval_type === 'W') {
                  label = [
                    moment(value).format('HH:mm'),
                    t(renderIntervalWeekly(interval_daily))
                  ].join(' - ')
                } else if (interval_type === 'M') {
                  label = [
                    moment(value).format('HH:mm'),
                    '-',
                    t('date'),
                    moment(value).format('DD')
                  ].join(' ')
                } else {
                  label = moment(value).format('HH:mm - DD/MM/YYYY')
                }
                return (
                  <span>
                                {label}{' '}
                    <span className="text-color-300 italic">({renderInterval(interval_type)})</span>
                            </span>
                )
              },
            },
            {
              label: 'end',
              value: time_close,
              transform: toDateString,
            },
          ].map(({label, value, transform}, index) => (
            <MetaItem
              key={index}
              {...{
                label,
                value,
                transform,
              }}
            />
          ))}
        </div>
        <div className="px-3 text-right">
          <Link
            to={getLinkToDetail(event) + '/timeline'}
            className="font-medium text-xs text-color-400 italic no-underline">
            {t('see more')}
            {' ...'}
          </Link>
        </div>
      </HeaderItemInfo>

      {!isRawContentEmpty(about) && (
        <CustomEditorRenderer
          rawValue={about}
          Wrapper={withProps({
            className: 'border-transparent py-2',
            title: 'about us',
          })(HeaderItemInfo)}
        />
      )}

      <HeaderItemInfo
        title="website"
        hidden={_.isEmpty(external_link)}>
        <Link
          target="_blank"
          to={external_link ?? '#'}
          className="flex-1 text-xs text-color-400 italic">
          {external_link}
        </Link>
      </HeaderItemInfo>

      <HeaderItemInfo
        title="categories"
        className="flex flex-wrap gap-3"
        hidden={_.isEmpty(categories)}>
        {Array.from(categories ?? []).map((e, i) => {
          const {name, language, alt_idname} = e
          const id = e?.id
          const pathname = paths.categoryPath.replace(':category', id).replace(':refcategory', alt_idname)
          const Icon = _.get(categoryIcons, e.idname, categoryIcons["default"])

          return (
            <Link
              key={i}
              to={{
                pathname,
                search: `?lang=${language}`,
                state: {refId: alt_idname},
              }}
              className="w-fit background-100 hover:bg-primary-50 rounded-md text-color-000 hover:text-primary no-underline truncate">
              <div style={{padding: '5px 12px'}} className="flex flex-center gap-3">
                <Icon size={20}/>
                <span className="font-medium pr-2">{name}</span>
              </div>
            </Link>
          )
        })}
      </HeaderItemInfo>

      <EventNews
        sample={2}
        Footer={withProps({
          pathname: getLinkToDetail(event) + '/news',
        })(({pathname}) =>
          createPagerFooter({
            to: pathname,
            description:
              'You can click see more to see this full information',
          })
        )}
        Wrapper={withProps({
          title: 'news update',
        })(HeaderItemInfo)}
      />
    </div>
  )
}

export default EventInfo
