import _ from 'lodash'
import {Editor, Path, Transforms} from 'slate'
import {ReactEditor} from 'slate-react'
import {ElementTypes} from '../types'
import {getNextNode, getNode, updateNode} from './editor'
import {createParagraphNode} from './paragraph'

function createHeader(index) {
  return {text: 'untitled', id: Date.now() + index}
}

export function insertRowAbove(editor, params) {
  if (editor) {
    const {text = '', columns = 0, withPath = (path) => path} = params ?? {}
    const [, path] = getNode(editor, ElementTypes.TABLE_ROW)
    Transforms.insertNodes(
      editor,
      {
        type: ElementTypes.TABLE_ROW,
        children: new Array(columns).fill().map(() => ({
          type: ElementTypes.TABLE_CELL,
          children: [{text}],
        })),
      },
      {at: withPath(path)}
    )
  }
}

export function insertRowBelow(editor, params) {
  insertRowAbove(editor, {
    ...(params ?? {}),
    withPath: (path) => Path.next(path),
  })
}

function insertColumn(editor, params) {
  if (editor) {
    const {
      table,
      position,
      text = '',
      withPath = (path) => path,
    } = params ?? {}

    const [root, root_path] = getNode(editor, ElementTypes.TABLE)

    const [, path] = getNode(editor, ElementTypes.TABLE_CELL)

    const column_index = _.last(path)

    const groups = Array.from(params?.groups ?? [])

    Array.from(root?.children ?? []).forEach((__, index) => {
      const new_path = [...(root_path ?? []), index, column_index]
      Transforms.insertNodes(
        editor,
        {
          type: ElementTypes.TABLE_CELL,
          children: [{text}],
        },
        {at: withPath(new_path)}
      )
    })

    let next_column_index
    switch (position) {
      case 'left':
        next_column_index = column_index
        break
      case 'right':
        next_column_index = column_index + 1
        break
      default:
        next_column_index = column_index
        break
    }

    updateNode({
      groups: [
        ...groups.slice(0, next_column_index),
        createHeader(groups.length + 1),
        ...groups.slice(next_column_index),
      ],
    })(editor, table)
  }
}

export function insertColumnLeft(editor, params) {
  insertColumn(editor, {
    ...(params ?? {}),
    position: 'left',
  })
}

export function insertColumnRight(editor, params) {
  insertColumn(editor, {
    ...(params ?? {}),
    position: 'right',
    withPath: (path) => Path.next(path),
  })
}

export function deleteRow(editor) {
  if (editor) {
    const [, path] = getNode(editor, ElementTypes.TABLE_ROW)
    Transforms.delete(editor, {at: path})
  }
}

export function deleteColumn(editor, params) {
  if (editor) {
    const {table} = params ?? {}

    const [root, root_path] = getNode(editor, ElementTypes.TABLE)

    const [, path] = getNode(editor, ElementTypes.TABLE_CELL)

    const column_index = _.last(path)

    const groups = Array.from(params?.groups ?? [])

    Array.from(root?.children ?? []).forEach((__, index) => {
      const new_path = [...(root_path ?? []), index, column_index]
      Transforms.removeNodes(editor, {at: new_path})
    })

    updateNode({
      groups: groups.filter((__, index) => index !== column_index),
    })(editor, table)
  }
}

export function insertTable(editor, options) {
  const {selection} = editor ?? {}
  const rows = new Array(options?.rows ?? 2).fill()
  const cols = new Array(options?.cols ?? 3).fill()
  const table_id = _.uniqueId(Date.now())
  const node = {
    id: table_id,
    type: ElementTypes.TABLE,
    children: rows.map((__) => ({
      type: ElementTypes.TABLE_ROW,
      children: cols.map((__) => ({
        type: ElementTypes.TABLE_CELL,
        children: [{text: ''}],
      })),
    })),
    groups: cols.map((__, index) => createHeader(index)),
  }

  ReactEditor.focus(editor)

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path
    )

    if (parentNode.type === ElementTypes.TABLE) {
      Transforms.removeNodes(editor, {at: parentPath})
    } else if (parentNode.type === ElementTypes.TABLE_CELL) {
      Transforms.removeNodes(editor, {
        match: (n) => n?.type === ElementTypes.TABLE,
      })
      Transforms.removeNodes(editor, {
        match: (n) =>
          n?.type === ElementTypes.PARAGRAPH && n?.rel === parentNode?.rel,
      })
    }

    if (editor.isVoid(parentNode)) {
      Transforms.insertNodes(editor, createParagraphNode([node]), {
        at: Path.next(parentPath),
        select: true,
      })
    } else {
      Transforms.insertNodes(editor, node, {
        select: true,
      })
    }

    const [__, tablePath] = getNode(editor, ElementTypes.TABLE) ?? [null, null]

    if (tablePath && !getNextNode(editor)) {
      Transforms.insertNodes(
        editor,
        createParagraphNode([{text: '', rel: table_id}]),
        {
          at: Path.next(tablePath),
        }
      )
    }
  }
}
