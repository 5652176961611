import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {Link} from 'react-router-dom'
import {renderOwnChild} from 'views/Shared'
import {FlexCol} from '../Templates/ItemTemplate'

const PagerLink = ({
                     to,
                     onClick,
                     description,
                     title = 'see more',
                     Wrapper = renderOwnChild,
                     Component = renderOwnChild,
                   }) => {
  const t = useTranslate()
  return (
    <Wrapper>
      {!_.isEmpty(description) && (
        <span className="font-light text-color-400 text-xs italic text-center">
          {t(description)}
        </span>
      )}
      <div
        className="text-center mb-3"
        onClick={onClick}>
        <Component
          to={to}
          className="font-medium text-xs text-color-400 hover:text-color-100 italic no-underline cursor-pointer">
          {t(title)}
        </Component>
      </div>
    </Wrapper>
  )
}

const createPagerFooter = ({
                             to,
                             onClick,
                             description,
                             Component = Link,
                           }) => (
  <PagerLink
    {...{
      to,
      onClick,
      description,
      Wrapper: ({
                  children,
                  ...rest
                }) => (
        <FlexCol
          className="gap-2 mb-6"
          {...rest}>
          {children}
        </FlexCol>
      ),
      Component,
    }}
  />
)

export const DonorPagerFooter = ({
                                   pathname,
                                 }) =>
  createPagerFooter({
    to: pathname,
    description:
      'You can click see more to see this full information',
  })

export const DonationPagerFooter = ({
                                      pathname,
                                    }) =>
  createPagerFooter({
    to: pathname,
    description:
      'You can click see more to see this full information',
  })

export const GivingPagerFooter = ({
                                    pathname,
                                  }) =>
  createPagerFooter({
    to: pathname,
    description:
      'You can click see more to see this full information',
  })

export const RecruitmentPagerFooter = ({
                                         pathname,
                                       }) =>
  createPagerFooter({
    to: pathname,
    description:
      'You can click see more to see this full information',
  })

export default createPagerFooter
