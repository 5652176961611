import {Select} from 'antd'
import {categories_getTopTags_api} from 'apis'
import {createValue} from 'components/form/utils'
import filterOption from 'helpers/filterOption'
import _ from 'lodash'
import useAsyncWithCache from 'modules/asyncCache/useAsyncWithCache'
import {useAppConfig} from 'modules/local'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo} from 'react'
import CreatableSelect from 'react-select/creatable'
import {getResponseItems, Null} from 'views/Shared'
import {mapEntityOptions} from '../factory/createSelectEntityProps'
import AntdConfigWrapper from './AntdConfigWrapper'
import {dropdownComponents, SelectWidget} from './ReactSelectComponents'

export const MultiCategoryPicker = ({
                                      name,
                                      value,
                                      onChange = Null,
                                      ...props
                                    }) => {
  const t = useTranslate()

  const {categories = []} = useAppConfig()

  const options = useMemo(() => mapEntityOptions(categories), [categories])

  const defaultValue = _.isArray(value)
    ? Array.from(value).map((item) => item?.value || item)
    : []

  return (
    <AntdConfigWrapper>
      <SelectWidget
        mode="multiple"
        showSearch={true}
        value={defaultValue}
        className="w-full"
        allowClear={true}
        filterOption={filterOption}
        onChange={(value) => {
          onChange(createValue(name, value))
        }}
        placeholder="select categories"
        {...props}>
        {options.map(({label, value}, index) => (
          <Select.Option
            key={index}
            value={value}
            label={label}>
            {t(label)}
          </Select.Option>
        ))}
      </SelectWidget>
    </AntdConfigWrapper>
  )
}

const TagsPicker = ({name, value, onChange = Null}) => {
  const apiInfo = categories_getTopTags_api

  const cacheId = [apiInfo.path].join('/')

  const {response} = useAsyncWithCache({
    cacheId,
    apiInfo,
  })

  const options = useMemo(() => {
    const array = _.isArray(value) ? Array.from(value) : []
    const items = Array.from(getResponseItems(response) || []).filter(
      ({tags}) => !array.includes(tags)
    )
    return [...array, ...items.map(({tags}) => tags)]
  }, [value, response])

  return (
    <SelectWidget
      mode="tags"
      value={value}
      showSearch={true}
      className="w-full"
      allowClear={true}
      filterOption={filterOption}
      onChange={(value) => {
        onChange(createValue(name, value))
      }}>
      {options.map((tag, index) => (
        <Select.Option
          key={index}
          value={tag}
          label={tag}>
          {'#' + tag}
        </Select.Option>
      ))}
    </SelectWidget>
  )
}

export const TagCreator = ({
                             createPrefix = 'add',
                             displayPrefix = '#',
                             ...props
                           }) => (
  <Translate>
    {(t) => (
      <CreatableSelect
        isMulti={true}
        noOptionsMessage={Null}
        placeholder={
          <span className="text-color-500 italic text-sm">
            {t('addTags1, addTags2,...')}
          </span>
        }
        formatCreateLabel={(text) => `${t(createPrefix)} "#${text}"`}
        {...props}
        styles={{
          multiValueLabel: (styles) => {
            return {
              ...styles,
              ...{
                alignItems: 'center',
                display: 'flex',
                ':before': {
                  content: `"${displayPrefix}"`,
                  display: 'block',
                },
              },
            }
          },
        }}
        components={dropdownComponents}
      />
    )}
  </Translate>
)

export default TagsPicker
