import { Avatar, List } from 'antd'
import { getType } from 'apis/model/base'
import classNames from 'classnames'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import moment from 'moment'
import React from 'react'
import { IoPerson } from 'react-icons/io5'
import { Link } from 'react-router-dom'

export const renderHomeFollower = (container, index) => {
  if (_.isEmpty(container)) {
    return null
  }

  const { owner, creator: item } = container

  const isOrg = getType(_.get(container, 'creator')) === 'organization'

  return (
    <Translate key={index}>
      {(t) => (
          <div className="flex items-center gap-2 md:gap-3">
            <Avatar
                src={getAvatar(item)}
                shape={isOrg ? 'square' : 'circle'}
                size={40}
                icon={<IoPerson className="text-color-500" />}
                className={classNames(
                    'flex items-center justify-center background-200 ',
                    isOrg ? 'rounded-md' : 'rounded-full'
                )}
            />
            <div className="flex flex-col flex-1 truncate">
              <div className="flex items-baseline gap-1 italic">
                <Link
                    to={getLinkToDetail(item)}
                    className="font-medium text-xs text-primary-600 max-lines-1">
                  {getTitle(item)}
                </Link>
                <span className="flex-1 text-2xs text-color-400 whitespace-no-wrap lowercase">
                    {t('followed')}
                </span>
                <span className="text-2xs text-color-400 italic ml-3 whitespace-no-wrap">
                    {moment(_.get(container, 'created')).fromNow()}
                  </span>
              </div>
              <Link
                  to={getLinkToDetail(owner)}
                  className="flex-1 font-bold text-sm text-color-000 no-underline leading-tight truncate">
                {getTitle(owner)}
              </Link>
            </div>
          </div>
      )}
    </Translate>
  )
}
