import {Spin, Tooltip} from 'antd'
import {url_getMetaTags_Api} from 'apis'
import classNames from 'classnames'
import {createValue} from 'components/form/utils'
import {useInputBar, useSuggestMentions, useTagSuggestMentions,} from 'components/InputBar'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {useFormikContext} from 'formik'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo, useRef, useState} from 'react'
import * as ReactDOM from 'react-dom'
import {BsEmojiSmile, BsImages} from 'react-icons/bs'
import {IoCloseCircleOutline} from 'react-icons/io5'
import ReactPlayer from 'react-player'
import {useToggle} from 'react-use'
import LoadingPage from 'views/LoadingPage'
import {SelectList} from 'views/Search/SelectList'
import {getResponseItem, Null, renderIf} from 'views/Shared'
import {BaseEmbed} from '../BaseEmbed'
import {mark_as_mention, MentionSuggestionItem, renderMention,} from '../Mention'
import {VideoEmbed} from '../VideoEmbed'
import AttachedImages from './AttachedImages'
import EmojiWrapper from './EmojiWrapper'
import ContentEditableInput from './ContentEditableInput'

const MetaPreview = ({linkMeta, onClosed, isLoading}) => {
  const closeButton = useMemo(
    () => (
      <div
        style={{
          right: 0,
          top: 0,
          position: 'absolute',
        }}>
        <div className="cursor-pointer p-1">
          <IoCloseCircleOutline
            className="text-color-500 hover:text-red-600"
            size={18}
            onClick={onClosed}
          />
        </div>
      </div>
    ),
    [onClosed]
  )

  if (isLoading) {
    return (
      <div className="p-3 flex items-center">
        <LoadingPage/>
      </div>
    )
  }

  if (
    _.isEmpty(linkMeta?.url) &&
    _.isEmpty(linkMeta?.photo) &&
    _.isEmpty(linkMeta?.title) &&
    _.isEmpty(linkMeta?.description)
  )
    return null

  const {url, photo, title, host_url, description} = linkMeta

  if (!!ReactPlayer.canPlay(linkMeta.url)) {
    return (
      <div className="relative w-full border rounded-lg pt-5">
        <VideoEmbed
          url={url}
          title={title}
          image={photo}
          subTitle={host_url || url}
          description={description}
        />
        {closeButton}
      </div>
    )
  }

  return (
    <div className="relative w-full border rounded-lg pt-5 px-2 pb-2">
      <BaseEmbed
        url={url}
        title={title}
        image={photo}
        subTitle={host_url || url}
        description={description}
      />
      {closeButton}
    </div>
  )
}

const Portal = ({children}) => {
  const {isSm} = useContext(LayoutContext)
  return (
    <div
      style={{
        top: 'calc(100% - 0.5rem)',
        maxWidth: isSm ? '100%' : '500px',
      }}
      className="my-2 absolute background shadow-md border border-color-50 rounded-md z-10">
      {children}
    </div>
  )
}

const MegaTextarea = ({
                        data,
                        name,
                        value,
                        style,
                        values,
                        className,
                        onMentioned,
                        mention_count,
                        onChange = Null,
                        fieldNames = {
                          embed_data: 'embed_data',
                        },
                        renderActions = (actions, params) => actions,
                        ...props
                      }) => {
  const t = useTranslate()

  const textAreaRef = useRef()

  const mentionRef = useRef()

  const {isSm} = useContext(LayoutContext)

  const [linkMeta, setLinkMeta] = useState()

  const [isUploading, toggleUploading] = useToggle(
    Array.from(values?.fileList ?? []).length
  )

  const [currentIndex, setCurrentIndex] = useState(0)

  const form = useFormikContext()

  const {isLoading: isMetaLoading, handleAsyncAction: getMetaFromUrl} =
    useAsyncAction({
      apiInfo: url_getMetaTags_Api,
      onSuccess: (__, {response}) => {
        const linkMeta = getResponseItem(response)
        if (linkMeta) {
          setLinkMeta(linkMeta)
          onChange(createValue(fieldNames.embed_data, JSON.stringify(linkMeta)))
        }
      },
    })

  const {data: suggestions, isLoading: mentionLoading = false} =
    useSuggestMentions(mentionRef.current?.text)

  const isOpenedSuggestions = mentionRef.current?.prefix === '@'

  const {data: tags, isLoading: tagsLoading = false} = useTagSuggestMentions(
    mentionRef.current?.text
  )

  const isOpenedTagSuggestions = mentionRef.current?.prefix === '#'

  const insertText = (value, suffix = '') => {
    const ref = ReactDOM.findDOMNode(textAreaRef.current)
    const _value = textAreaRef.current?.props?.value ?? ''
    const _mentionStr = `${mentionRef.current?.prefix ?? ''}${
      mentionRef.current?.text ?? ''
    }`
    const newValue = _value
      .split(' ')
      .map((e) => {
        const ee = e
          .split('\n')
          .map((e) => {
            if (e === _mentionStr) return ` ${value}${suffix}`
            return e
          })
          .join('\n')

        if (ee === _mentionStr) return `${value}${suffix}`
        return ee
      })
      .join(` `)
    onChange(createValue(name, newValue))
    mentionRef.current = {}
    ref.focus()
  }

  const insertHash = (value) => {
    insertText(value, ` `)
  }

  const insertMention = (value) => {
    insertText(value, ` `)
  }

  const {actions = [], handleSelect} = useInputBar({
    value,
    onChange,
    mention_count,
    onMentioned: ({text, mention}) => {
      onMentioned(mention)
      insertMention(text)
    },
    name: 'description',
    onMatched: (value, prefix) => {
      switch (prefix) {
        case '#':
        case '@':
          mentionRef.current = {
            ...(value ?? {}),
            prefix,
          }
          break
        default:
          mentionRef.current = {}
          break
      }
    },
    willDismiss: (str, text) => {
      if (isOpenedTagSuggestions || isOpenedSuggestions) {
        if (_.isEmpty(text ?? '')) {
          mentionRef.current = {}
        } else if (
          _.isEmpty(
            str.substring(mentionRef.current?.start, mentionRef.current?.end)
          )
        ) {
          mentionRef.current = {}
        }
      }
    },
  })

  const handleMentionClick = ({id, type, name}) => {
    if (mentionRef.current?.prefix && mentionRef.current?.text) {
      const string = `@${type}:${id}:${encodeURI(name)}`
      const key = mark_as_mention(name, {
        owner_id: id,
        owner_type: type,
      })
      onMentioned({
        [key]: string,
      })
      insertMention(string)
    }
  }

  const handleKeyDown = (event, params) => {
    if (isOpenedSuggestions) {
      if ([40, 38, 13].includes(event.keyCode)) {
        event.preventDefault()
        return
      }
    }
    if (isOpenedTagSuggestions) {
      if ([40, 38, 13].includes(event.keyCode)) {
        event.preventDefault()
        return
      }
    }

    if (!!isSm) {
      if (event.key === 'Enter') {
        event.preventDefault()
        document.execCommand('insertLineBreak')
      }
    } else {
      if (event.key === 'Enter' && event.shiftKey) {
        event.preventDefault()
        document.execCommand('insertLineBreak')
      } else if (event.key === 'Enter') {
        event.preventDefault()
        if (form) {
          const {handleSubmit = Null} = form ?? {}
          handleSubmit()
        }
      }
    }
  }

  const emoji = useMemo(
    () => (
      <EmojiWrapper
        content={value}
        Component={BsEmojiSmile}
        currentIndex={currentIndex}
        onChange={(text) => {
          onChange(createValue(name, text))
        }}
      />
    ),
    [name, value, currentIndex]
  )

  return (
    <React.Fragment>
      <div className="relative space-y-3">
        {renderIf(
          isUploading,
          <AttachedImages
            values={values}
            onChange={onChange}
          />
        )}
        <MetaPreview
          linkMeta={linkMeta}
          isLoading={isMetaLoading}
          onClosed={() => setLinkMeta(null)}
        />
        <div className="p-2 md:p-3 flex flex-col gap-4 border border-color-50 rounded-lg shadow-items-xs">
          <ContentEditableInput
            name={name}
            value={value}
            style={{
              minHeight: isSm ? 70 : 50,
              ...style
            }}
            ref={textAreaRef}
            onChange={onChange}
            onSelect={handleSelect}
            onKeyDown={handleKeyDown}
            className={classNames(
              'w-full rounded-md custom-textarea scrollBarThin',
              className
            )}
            onPaste={(e) => {
              e.preventDefault()
              const text = e.clipboardData.getData('Text')
              // const LINK_DETECTION_REGEX =
              //   /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/gi
              const LINK_DETECTION_REGEX =
                /(?:https?|ftp):\/\/[^\s/$.?#].[^\s]*/g
              switch (true) {
                case !!LINK_DETECTION_REGEX.exec(text):
                  getMetaFromUrl({
                    content: text,
                  })
                  break
                default:
                  break
              }
            }}
            {...props}
          />
          {renderActions(
            <div className="flex items-center gap-3 relative">
              <Tooltip
                title={t('add image')}
                placement="right"
                overlayInnerStyle={{
                  minHeight: 30,
                  fontSize: 12,
                  fontStyle: 'italic',
                }}>
                <BsImages
                  size={18}
                  onClick={toggleUploading}
                  className="text-green-300 hover:text-green-600 cursor-pointer"
                />
              </Tooltip>
            </div>,
            {}
          )}
        </div>
        {renderIf(
          isOpenedSuggestions,
          <Portal>
            <Spin
              indicator={null}
              spinning={!!mentionLoading}>
              <SelectList
                onSelected={(e) => {
                  e.props.onClick()
                }}>
                {[
                  ...(actions.length
                    ? [{..._.first(actions), fixed: true}]
                    : []),
                  ...suggestions,
                ].map(renderMention({onClick: handleMentionClick}))}
              </SelectList>
            </Spin>
          </Portal>
        )}
        {renderIf(
          isOpenedTagSuggestions,
          <Portal>
            <Spin
              indicator={null}
              spinning={!!tagsLoading}>
              <SelectList
                onSelected={(e) => {
                  e.props.onClick()
                }}>
                {tags.map(({name, id, type, label, avatar}, index) => (
                  <MentionSuggestionItem
                    key={index}
                    title={label}
                    {...{
                      id,
                      name,
                      type,
                      avatar,
                    }}
                    showAvatar={false}
                    onClick={() => {
                      if (
                        mentionRef.current?.prefix &&
                        mentionRef.current?.text
                      ) {
                        insertHash(name)
                      }
                    }}
                  />
                ))}
              </SelectList>
            </Spin>
          </Portal>
        )}
      </div>
    </React.Fragment>
  )
}

export default MegaTextarea
