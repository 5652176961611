import {Button, Input, Modal} from 'antd'
import {organization_Delete_Api} from 'apis'
import {event_delete_Api} from 'apis/event'
import {getId, getType} from 'apis/model/base'
import {showConfirm} from 'components/Feed/TimeLineFeed'
import FieldDecorator from 'components/form/FieldDecorator'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useState} from 'react'
import {mapStringParams, Null} from 'views/Shared'
import CustomButton from '../custom/CustomButton'
import {AppEntities, fromAppEntity} from '../enums'
import {bindQueryParam} from '../functions/routerHelper'
import {notifyOnError} from './createErrorEvent'
import {channel_delete_Api} from "../../../apis";

const patterns = {
  types: ['organization'],
  name: 'type',
  confirmLabel: '{type} name',
  successMessage: 'the {type} has been deleted',
  errorMessage: "you didn't enter the {type} name correctly",
  description: '{type} will delete will be gone forever. are you sure',
}

const bindingMessage = (message, value) =>
  mapStringParams(message, [{name: patterns.name, value}])

const DeleteEntity = ({
                        id,
                        type,
                        query,
                        values,
                        apiInfo,
                        onSuccess,
                        validate = Null,
                        title = 'confirmation',
                      }) => {
  const t = useTranslate()

  const history = useHistory()

  const [error, setError] = useState()

  const {displayName} = fromAppEntity(type)

  const translated = displayName ? t(displayName) : displayName

  const {description, confirmLabel, errorMessage, successMessage} = {
    confirmLabel: bindingMessage(t(patterns.confirmLabel), translated),
    successMessage: bindingMessage(t(patterns.successMessage), translated),
    errorMessage: bindingMessage(t(patterns.errorMessage), translated),
    description: bindingMessage(t(patterns.description), translated),
  }

  const {isLoading, handleAsyncAction = Null} = useAsyncAction({
    query,
    values,
    apiInfo,
    onSuccess: (result, data) => {
      Modal.success({
        content: successMessage,
        okText: t('OK'),
        okButtonProps: {
          type: 'primary',
          className: 'rounded-lg no-shadow no-text-shadow',
        },
      })
      if (_.isFunction(onSuccess)) {
        onSuccess(result, data)
      } else {
        history.replace('/')
      }
    },
    onError: notifyOnError(t),
  })

  return (
    <div className="p-3 flex flex-col space-y-3 items-center text-center">
      <div className="w-full">
        <div className="text-2xl font-bold text-color-000 capitalize">{t(title)}</div>
        <div className="text-sm text-color-400 italic">{description}</div>
        <br/>
        <div className="text-left">
          <FieldDecorator
            errors={[error]}
            label={confirmLabel}>
            <Input
              id="delete_input"
              autoComplete="off"
              placeholder={t(confirmLabel)}
            />
          </FieldDecorator>
        </div>
      </div>
      <Button
        onClick={() => {
          const input = document.querySelector('#delete_input') || {}
          const value = input.value
          if (validate(value)) {
            showConfirm({
              title: t('are you sure you want to delete it permanently'),
              okText: t('yes'),
              cancelText: t('no'),
              okButtonProps: {
                type: 'primary',
                className: 'rounded-full no-shadow no-text-shadow',
              },
              cancelButtonProps: {
                className: 'rounded-full no-shadow no-text-shadow',
              },
              onOk: () => {
                handleAsyncAction()
              },
            })
          } else {
            setError(errorMessage)
          }
        }}
        loading={Boolean(isLoading)}
        type="primary"
        danger={true}
        className="button-rounded-md px-6">
        {t('delete')}
      </Button>
    </div>
  )
}

const createDeleteEntity = (item, params = {}) => {
  const [id, type] = [getId(item), getType(item)]

  let apiInfo = {}

  switch (type) {
    case AppEntities.EVENT:
      apiInfo = event_delete_Api
      break
    case AppEntities.ORGANIZATION:
      apiInfo = organization_Delete_Api
      break
    case AppEntities.CHANNEL:
      apiInfo = channel_delete_Api
      break
    default:
      break
  }

  return (
    <DeleteEntity
      id={id}
      type={type}
      apiInfo={apiInfo}
      validate={(value) => _.isEqual(value, getTitle(item))}
      query={bindQueryParam({id})}
      {...params}
    />
  )
}

export default createDeleteEntity
