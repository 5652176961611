import { Drawer, Layout, Menu } from 'antd'
import { default as classNames, default as classnames } from 'classnames'
import Count from 'components/Feed/Count'
import { LoginContext } from 'components/LoginContext'
import { RegAsModal } from 'components/Modal/Modal'
import Null from 'components/NullComponent'
import ShadowScrollbar from 'components/ShadowScrollbar'
import _ from 'lodash'
import useRouter from 'modules/navigation/useRouter'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { VscArrowLeft } from 'react-icons/vsc'
import { UserMenu } from 'views/Account/UserMenu'
import { DelayRender } from 'views/Discovery/DelayRender'
import { renderIf } from 'views/Shared'
import { LayoutContext } from './LayoutContext'
import {IoArrowBack} from "react-icons/io5";

export const MenuWrapper = ({
  children,
  onClose = Null,
  Scrollbar = ShadowScrollbar,
}) => (
  <LayoutContext.Consumer>
    {({ breakpoint, openMenu, setOpenMenu }) =>
      ['xs', 'sm', 'md'].find((value) => value === breakpoint) ? (
        <Drawer
          bodyStyle={{
            padding: 0,
            overflow: 'hidden',
          }}
          width={280}
          placement="left"
          closable={false}
          onClose={() => {
            setOpenMenu(false)
            onClose()
          }}
          visible={openMenu}>
          {openMenu && <RegAsModal />}
          <Scrollbar className="absolute top-0 left-0">{children}</Scrollbar>
        </Drawer>
      ) : (
        <div className="sticky top-0 h-screen ">
          <Scrollbar className="RightSider">
            {children}
          </Scrollbar>
        </div>
      )
    }
  </LayoutContext.Consumer>
)

export const PageHeader = ({ title, extra, onClose = Null }) => {
  const { canBack, history, handleGoBack } = useRouter()
  return (
    <div
      style={{ height: 'var(--header-height)' }}
      className="PageHeader flex background stickyTop items-center border-b border-color-50 shadow">
      <div
          onClick={() =>handleGoBack()}
          style={{ width: 24, height: 24 }}
          className="flex flex-center text-color-300 hover:text-color-000 cursor-pointer mx-3">
        <IoArrowBack />
      </div>
      <span className="flex items-center flex-1 font-bold text-sm max-lines-1">
        {title}
      </span>
      {extra}
    </div>
  )
}

const RightSiderMenu = ({
  items,
  width,
  onClick,
  onSelect,
  children,
  menuProps,
  contentWidth,
  toggle = Null,
  collapsedWidth,
  isToggle = false,
  renderMenu = (menu, content, params) => menu,
  ...props
}) => {
  return (
    <Layout.Sider
      theme="light"
      collapsed={true}
      collapsedWidth={width}
      className="z-50 background"
      {...props}>
      {renderMenu(
        <Menu
          items={items}
          mode="inline"
          onClick={onClick}
          onSelect={onSelect}
          prefixCls="RightSiderMenu SiderMenu"
          {...(menuProps ?? {})}
        />,
        children,
        { items, toggle, isToggle, contentWidth, collapsedWidth }
      )}
    </Layout.Sider>
  )
}

export const useRightSider = ({
  items,
  onSelect,
  menuProps,
  searchQuery,
  collapsedWidth,
  contentWidth = 0,
  dependencies = [],
  defaultSelectedKey,
  withSider = (props) => ({}),
  renderContent = (content, props) => content,
  renderMenu = (menu, content, params) => menu,
  ...props
}) => {
  const [isToggle, toggle] = useState(!!defaultSelectedKey)

  const [selectedKey, setSelectedKey] = useState(defaultSelectedKey)

  let searchParams = new URLSearchParams(window.location.search)

  const defaultOpenKey = searchParams.get(searchQuery)

  useEffect(() => {
    if (searchQuery && defaultOpenKey) {
      toggle(true)
      setSelectedKey(defaultOpenKey)
    }
  }, [searchQuery, defaultOpenKey])

  const content = useMemo(() => {
    const current = _.find(items, { key: selectedKey })
    const Component = current?.Component ?? Null
    return renderContent(<Component />, {
      current: _.find(items, { key: selectedKey }),
    })
  }, [items, selectedKey, renderContent])

  const willRedirect =
    (trigger = false) =>
    ({ current }) => {
      if (trigger) {
        if (current) {
          searchParams.set(searchQuery, current)
        } else {
          searchParams.delete(searchQuery)
        }
        const nextURL = [
          window.location.pathname,
          searchParams.toString().length && '?',
          searchParams.toString(),
          window.location.hash,
        ]
          .filter(Boolean)
          .join('')
        window.history.replaceState(null, '', nextURL)
        // history.push({
        //   ...location,
        //   search: '?' + searchParams.toString(),
        // })
      }
    }

  const menu = useMemo(() => {
    const menuItems = Array.from(items ?? []).map((item) =>
      _.omit(item, ['Component'])
    )
    return (
      <RightSiderMenu
        {...props}
        items={menuItems}
        contentWidth={contentWidth}
        collapsedWidth={collapsedWidth}
        width={_.defaultTo(
          isToggle ? Number(collapsedWidth + contentWidth) : collapsedWidth,
          0
        )}
        onClick={({ key }) => {
          const nextState = key !== selectedKey
          if (_.isFunction(onSelect)) {
            onSelect(key, isToggle)
          }
          toggle(nextState)
          setSelectedKey(key === selectedKey ? undefined : key)
          willRedirect(searchQuery)({
            current: nextState ? key : undefined,
          })
        }}
        menuProps={{
          ...(menuProps ?? {}),
          selectedKeys: selectedKey ? [selectedKey] : undefined,
        }}
        {...{ toggle, isToggle, renderMenu }}
        {...(withSider({ toggle, isToggle, items: menuItems }) ?? {})}>
        <DelayRender lazy={true}>{content}</DelayRender>
      </RightSiderMenu>
    )
  }, [
    items,
    onSelect,
    isToggle,
    menuProps,
    withSider,
    renderMenu,
    selectedKey,
    defaultSelectedKey,
    ...dependencies,
  ])
  return {
    menu,
    selectedKey,
    defaultOpenKey,
    setSelectedKey: (value) => {
      setSelectedKey(value)
      willRedirect(searchQuery)({
        current: value,
      })
    },
    setExpanded: toggle,
    isExpanded: isToggle,
    onChange: (value) => {
      willRedirect(searchQuery)({
        current: value,
      })
    },
  }
}

export const ToggleSider = ({
  menu,
  style,
  children,
  className,
  contentWidth,
  collapsedWidth,
  isToggle = false,
  withContent = () => ({}),
  renderContent = (children, props) => <div {...props}>{children}</div>,
}) => {
  if (!menu) {
    return null
  }

  const defaultProps = {
    style: {
      margin: 0,
      overflow: 'hidden auto',
      width: contentWidth ?? 'auto',
    },
    className: 'h-full shadow background',
  }

  return (
    <div
      className={classNames('flex h-screen justify-end', className)}
      style={{
        position: 'sticky',
        top: 0,
        ...(style ?? {}),
      }}>
      {renderIf(
        isToggle,
        renderContent(children, {
          ...defaultProps,
          ...(withContent({ ...defaultProps, children }) ?? {}),
        })
      )}
      <div style={{ width: collapsedWidth }}>{menu}</div>
    </div>
  )
}

export default function ContentLayout({
  title,
  extra,
  renderHeader,
  renderMenu,
  renderRight,
  children,
  left,
  right,
  menu,
  mainStyle,
  rightMenu,
}) {
  const { isLg, visibleRightSide, setVisibleRightSide } =
    useContext(LayoutContext)

  const pageHeader = React.useMemo(
    () =>
      renderHeader ? (
        renderHeader({ title, extra })
      ) : (
        <PageHeader {...{ title, extra }} />
      ),
    [extra, renderHeader, title]
  )

  return (
    <>
      {renderMenu ? (
        renderMenu(menu)
      ) : menu ? (
        <MenuWrapper>
          <div className="w-full border-r border-color-50 min-h-screen">
            {menu}
          </div>
        </MenuWrapper>
      ) : (
        <LoginContext.Consumer>
          {(login) =>
            login ? (
              <MenuWrapper>
                <div className="w-full">
                  <UserMenu />
                </div>
              </MenuWrapper>
            ) : null
          }
        </LoginContext.Consumer>
      )}
      <main
        className={classnames(
          isLg
            ? 'flex flex-1 min-h-screen z-10 w-0 flex-col justify-start'
            : 'w-full container mx-auto'
        )}
        style={mainStyle}>
        {pageHeader}
        <div className="flex-1 flex flex-col h-full">{children}</div>
        {/* <PageFooter /> */}
      </main>
      {visibleRightSide &&
        (renderRight ? (
          renderRight()
        ) : (
          <LayoutContext.Consumer>
            {({ RightLayout = Null }) => {
              return <RightLayout>{right ?? left ?? null}</RightLayout>
            }}
          </LayoutContext.Consumer>
        ))}
      {rightMenu}
    </>
  )
}
