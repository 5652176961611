import {Button, Input, message} from 'antd'
import { referral_getReferralCode_Api } from 'apis'
import { getType } from 'apis/model/base'
import { ChannelIcon } from 'components/icons/ChannelIcon'
import { UpdateReferalCode } from 'components/UpdateReferalCode'
import copyToClipboard from 'copy-to-clipboard'
import { ROOT_URL } from 'envs/_current/config'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, { useContext, useLayoutEffect } from 'react'
import { IoCalendarOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import useToggle from 'react-use/lib/useToggle'
import { AppEntities } from 'views/AppLocals/enums'
import LoadingPage from 'views/LoadingPage'
import ServerContext from 'views/Server/ServerContext'
import { CalendarAvatar } from '../components/EventBanner'

const invitation_args = [
  {
    type: AppEntities.CHANNEL,
    withItem: (item) => ({
      avatar: (
        <ChannelIcon
          premium={item.premium}
          className="text-6xl"
        />
      ),
      title: 'Invite your friends to this page',
      invite_link:
        'Send invitation to join the channel to your friends, by sending a copy of the page link below',
      direct_link:
        'Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel',
    }),
  },
  {
    type: AppEntities.EVENT,
    withItem: (item) => ({
      avatar: <IoCalendarOutline className="text-6xl" />,
      title: 'Invite your friends to this event',
      invite_link: 'invite link description event',
      direct_link: 'direct link description event',
    }),
  },
]

const InviteForm = ({ item: initialValue }) => {
  const t = useTranslate()
  const { item: _item } = useContext(ServerContext)
  const [isToggle, toggle] = useToggle()
  const [isToggleCopy2, toggleCopy2] = useToggle(false)
  const item = initialValue ?? _item
  const { response } = useAsync({
    apiInfo: referral_getReferralCode_Api,
    values: {
      target_id: item?.id,
      target_type: getType(item),
    },
  })
  useLayoutEffect(() => {
    let timeout
    if (isToggle) {
      timeout = setTimeout(() => toggle(false), 800)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isToggle, toggle])

  useLayoutEffect(() => {
    let timeout
    if (isToggleCopy2) {
      timeout = setTimeout(() => toggleCopy2(false), 800)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isToggleCopy2, toggleCopy2])

  const data = _.get(response, 'data')
  if (!data) return <LoadingPage />
  const inviteLink = ROOT_URL + '/invite/' + _.get(data, 'referral_code')
  const timeout = moment(_.get(data, 'created'))
    .add(_.get(data, 'timeout'), 'second')
    .fromNow()

  const { withItem = (item) => ({}) } =
    _.find(invitation_args, { type: getType(item) }) ?? {}

  const values = withItem(item) ?? {}

  return (
    <div className="flex flex-col px-5">
      <div className="text-center text-xl uppercase pr-5 font-bold text-color-000 pt-3 mb-6">
        {t(values?.title)}
      </div>
      <UpdateReferalCode referral={data} />
      <div className="p-3 w-full flex items-center gap-4 border border-primary rounded-lg my-6">
        <CalendarAvatar
          time={_.get(item, 'time_open')}
          color="#fff"
        />
        <div className="flex flex-col flex-1 gap-3">
          <Link
            to={getLinkToDetail(item)}
            className="font-bold text-xl text-primary">
            {getTitle(item)}
          </Link>
          <div
            style={{ padding: '2px 12px' }}
            className="w-fit rounded-md background-200 text-sm text-color-100 space-x-2">
            <span className="font-bold">
              {_.defaultTo(item?.total.members, 0)}
            </span>
            <span className="font-medium italic">{t('members')}</span>
          </div>
        </div>
      </div>
      {item?.description && (
          <div className="flex flex-col flex-1 mb-6">
            <span className="font-bold text-sm text-color-300 uppercase">
              {t('introduce')}
            </span>
            <span className="text-xs text-color-400 italic">
              {item?.description}
            </span>
          </div>
      )}
      <div className="mt-3">
        <div className="mb-1 font-medium text-sm text-color-000 italic">
          {t(values?.invite_link)}
        </div>
        <div
          onClick={() => {
            copyToClipboard(inviteLink)
            message.success(t('copied'))
            toggle(true)
          }}>
          <Input
            className="rounded-lg"
            value={inviteLink}
            suffix={
              <Button
                ghost={isToggle}
                size="small"
                className="button-rounded-full flex flex-center"
                type="primary">
                <span className="text-xs font-medium px-2 italic leading-tight">
                  {t(isToggle ? 'copied' : 'copy')}
                </span>
              </Button>
            }
          />
        </div>
        <div className="mt-1 mb-2 text-xs text-color-300 italic">
          {_.get(data, 'timeout', 0) === 0 || _.get(data, 'no_expired', 0) === 1
            ? t('Permanent link')
            : `${t('your invite link expires')} ${timeout}`}
        </div>
        <div className="mt-4">
          <div className="mb-1 font-medium text-sm text-color-000 italic">
            {t(values?.direct_link)}
          </div>
          <div
            onClick={() => {
              copyToClipboard(ROOT_URL + getLinkToDetail(item))
              message.success(t('copied'))
              toggleCopy2(true)
            }}>
            <Input
              className="rounded-lg"
              value={ROOT_URL + getLinkToDetail(item)}
              suffix={
                <Button
                  ghost={isToggleCopy2}
                  size="small"
                  className="button-rounded-full flex flex-center"
                  type="primary">
                  <span className="text-xs font-medium px-2 italic leading-tight">
                    {t(isToggleCopy2 ? 'copied' : 'copy')}
                  </span>
                </Button>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default InviteForm
