import {Alert, Button} from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {Link} from 'react-router-dom'
import {links} from '../routes'

export const ConfirmTrialPlan = () => {
  const t = useTranslate()
  return (
    <div
      style={{ fontWeight: 500 }}
      className="w-full text-gray-800 verticalList__large max-w-md mx-auto ">
      <h2 className="text-4xl text-center block font-bold">
        {t('start trial')}
      </h2>
      <div className="verticalList__small">
        <h2 className="text-2xl font-bold">
          {t('get started')}
        </h2>
        <div>
          <Alert
            type="info"
            message={
              <div>
                <div className="font-bold">
                  {t(
                    'start your no-cost trial today'
                  )}
                </div>
                <div className="text-color-300">
                  {t(
                    "we will upgrade you immediately, you won't have to pay anything until your trial ends"
                  )}
                </div>
              </div>
            }
          />
        </div>
      </div>
      <div>
        <div className="font-bold">
          {t('after your trial ends')}
        </div>
        <div className="text-color-300">
          {t(
            'add payment to keep using or change back to free'
          )}
          .
        </div>
      </div>
      <div className="text-color-300 text-sm">
        {t(
          'By clicking Submit, you agree to the policy of FeedIn {term1} and {term2}.',
          {
            term1: (
              <a
                className="hover:underline color-primary"
                href="#">
                {t('terms of service')}
              </a>
            ),
            term2: (
              <a
                className="hover:underline color-primary"
                href="#">
                {t('privacy policy')}
              </a>
            )
          }
        )}
      </div>
      <div className="flex justify-end">
        <Link to={links.changePlan}>
          <Button
            type="ghost"
            className="mr-3">
            {t('back')}
          </Button>
        </Link>
        <Button type="primary">
          {t('start trial')}
        </Button>
      </div>
    </div>
  )
}

export default ConfirmTrialPlan
