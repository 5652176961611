import _ from 'lodash'
import React, {useCallback, useEffect, useState} from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import NullComponent from './NullComponent'

function LazyComponent({
  bottom = 0,
                         onVisible = NullComponent,
  children,
  reversed,
  offset = 200,
  // height = '1px',
  placeholder
}) {
  const [
    isVisible,
    setDisplay
  ] = useState()
  useEffect(
    () => {
      if (isVisible) {
        onVisible()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVisible]
  )
  const handleSetVisible = useCallback(
    visisble => {
      if (isVisible) return
      if (visisble) setDisplay(visisble)
    },
    [isVisible]
  )

  if (isVisible)
    return _.isFunction(children)
      ? children()
      : children
  if (reversed)
    return (
      <div
        style={{ margin: 0 }}
        className="relative h-0 w-full stickyBottom z-20">
        <VisibilitySensor
          offset={-offset}
          onChange={handleSetVisible}>
          <div
            style={{
              position: 'absolute',
              top: bottom,
              left: 'calc(50% - 1px)',
              width: '2px',
              height: '2px'
            }}
          />
        </VisibilitySensor>
        {placeholder}
      </div>
    )
  return (
    <div
      style={{ margin: 0 }}
      className="relative h-0 w-full stickyTop2">
      <VisibilitySensor
        offset={offset}
        onChange={handleSetVisible}>
        <div
          style={{
            position: 'absolute',
            bottom,
            left: 'calc(50% - 1px)',
            width: '2px',
            height: '2px'
          }}
        />
      </VisibilitySensor>
      {placeholder}
    </div>
  )
}
export default LazyComponent
