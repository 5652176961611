import {renderSelf} from 'views/Shared'
import getTitle from "../../helpers/getTitle";

export const target_content_options = {
  name: {
    label: 'title',
    value: 'name',
  },
  attach_link: {
    label: 'attach link',
    value: 'attach_link',
  },
}

export const createTargetContent = (option, translate = renderSelf) => {

  return getTitle(option)
}
