import React from 'react'
import './Quote.css'

export const QuoteElement = ({style, children, attributes}) => (
  <blockquote
    style={style}
    {...attributes}
    className="inline-flex">
    {children}
  </blockquote>
)

