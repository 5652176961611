import {Button, Form, Spin, Steps} from 'antd'
import Schema from 'async-validator'
import ForDevelop from 'envs/ForDevelop'
import {Formik} from 'formik'
import _, {isEmpty} from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React, {useCallback, useContext, useMemo, useState} from 'react'
import {compose, mapProps} from 'recompose'
import {BoxPlaceholder, Null, renderElse, renderOwnChild, renderSelf,} from 'views/Shared'
import ContentAlert from '../ContentAlert'

Schema.warning = function () {
}

export const StepActionTypes = Object.freeze({
  NEXT: 'next',
  BACK: 'back',
  SUBMIT: 'submit',
})

export const StepFormContext = React.createContext({})

export const StepFormProvider = ({
                                   item,
                                   onBack,
                                   onNext,
                                   getStep,
                                   children,
                                   currentStep,
                                   handleSubmit,
                                   formInstance,
                                   initialValues,
                                   validationSchema,
                                   ...props
                                 }) => (
  <Formik
    enableReinitialize={true}
    initialValues={initialValues}
    onSubmit={(values, actions) => {
      handleSubmit(values)
    }}
    // validateOnBlur={false}
    // validateOnMount={false}
    // validateOnChange={false}
    validationSchema={validationSchema}
    {...props}>
    {({handleSubmit: onSubmit, ...form}) => (
      <StepFormContext.Provider
        value={{
          ...{
            ...form,
            item,
            onBack,
            onNext,
            getStep,
            onSubmit,
            currentStep,
            formInstance,
            initialValues,
          },
        }}>
        {children}
      </StepFormContext.Provider>
    )}
  </Formik>
)

export const StepInnerForm = ({children}) => (
  <StepFormContext.Consumer>
    {({formInstance}) => (
      <Form
        form={formInstance}
        className="StepForm">
        {children}
      </Form>
    )}
  </StepFormContext.Consumer>
)

const DefaultComponent = compose(
  withTranslate,
  mapProps(({name, translate}) => ({
    title: translate(name),
  }))
)(BoxPlaceholder)

const defaultSteps = [
  {
    index: 0,
    name: 'step-1',
    title: 'step 1',
    component: DefaultComponent,
    actions: [
      {
        value: StepActionTypes.NEXT,
        type: 'primary',
      },
    ],
  },
  {
    index: 1,
    name: 'step-2',
    title: 'step 2',
    component: DefaultComponent,
    actions: [
      {
        value: StepActionTypes.BACK,
        type: 'default',
      },
      {
        label: 'submit',
        value: StepActionTypes.SUBMIT,
        type: 'primary',
      },
    ],
  },
]

const enhancedWrapper =
  (Wrapper) =>
    ({children, ...props}) => {
      const child = _.isFunction(children) ? children(props) : children
      return <Wrapper>{child}</Wrapper>
    }

const CurrentStep = ({Wrapper = renderOwnChild}) => {
  const t = useTranslate()
  const {
    values = {},
    errors = {},
    currentStep,
    formInstance,
    onNext = Null,
    onBack = Null,
    onSubmit = Null,
  } = useContext(StepFormContext)
  const {actions = [], component: Component, ...rest} = currentStep || {}

  const handleClick = useCallback(
    async (action) => {
      switch (action) {
        case StepActionTypes.NEXT:
          if (formInstance) {
            try {
              const result = await formInstance.validateFields()
              if (result) {
                onNext()
              }
            } catch (e) {
            }
          } else {
            onNext()
          }
          break
        case StepActionTypes.BACK:
          onBack()
          break
        case StepActionTypes.SUBMIT:
          onSubmit()
          break
        default:
          break
      }
    },
    [onBack, onNext, onSubmit, formInstance]
  )

  const summaryErrors = useMemo(() => {
    return (
      <div className="capitalize">
        <span className="text-lg font-bold text-red-500 mb-3">
          {t('error')}
        </span>
        <ul
          style={{
            listStyle: 'circle',
            marginLeft: '0.75rem',
          }}>
          {_.isString(errors)
            ? String(errors)
            : Object.keys(errors).map((name, index) => (
              <li key={index}>{`${t(name)}: ${errors[name]}`}</li>
            ))}
        </ul>
      </div>
    )
  }, [errors, t])

  const actionButtons = useMemo(() => {
    return actions.map(
      ({type, value, isDisabled, requireds = [], ...action}, index) => {
        const label = _.get(action, 'label', value)
        const disabled = Boolean(
          isDisabled
            ? isDisabled(values)
            : !requireds.every((name) => {
              const val = _.get(values, name)
              if (_.isBoolean(val)) {
                return val === true
              } else if (_.isNumber(val)) {
                return Number(val) > 0
              } else {
                return !isEmpty(val)
              }
            })
        )
        return (
          <Button
            key={index}
            {...action}
            className="no-shadow no-text-shadow border-none rounded-lg px-8"
            type={disabled ? 'default' : type}
            onClick={
              !!disabled
                ? null
                : () => {
                  handleClick(value)
                }
            }
            disabled={disabled}>
            {t(label)}
          </Button>
        )
      }
    )
  }, [t, errors, actions, values, handleClick])

  return (
    <Wrapper>
      <ForDevelop>
        <ContentAlert
          className="my-2"
          closable={false}
          message={summaryErrors}
          visible={!_.isEmpty(errors)}
        />
      </ForDevelop>
      <Component {...rest} />
      <div className="flex justify-center gap-2 mt-2">{actionButtons}</div>
    </Wrapper>
  )
}

const StepFormWizard = ({
                          formInstance,
                          Header = Null,
                          finishedStage,
                          useAsync = true,
                          onCancel = Null,
                          onSubmit = Null,
                          validationSchema,
                          asyncConfigs = {},
                          initialValues = {},
                          steps = defaultSteps,
                          onPreSubmit = renderSelf,
                          Wrapper = renderOwnChild,
                          ...props
                        }) => {
  const [current, setCurrent] = useState(0)

  const [isFinished, setFinished] = useState(false)

  const getStep = useCallback(
    (value) => _.find(steps, {index: value}),
    [steps]
  )

  const onSuccess = _.get(asyncConfigs, 'onSuccess')

  const [item, setItem] = useState()

  const forward = (item) => {
    setItem(item)
    setFinished(true)
  }

  const mergedConfigs = onSuccess
    ? {
      ...asyncConfigs,
      onSuccess: (result, data) => {
        onSuccess(result, data, forward)
      },
    }
    : asyncConfigs

  const EnhancedWrapper = useMemo(() => enhancedWrapper(Wrapper), [Wrapper])

  const onNext = useCallback(() => {
    setCurrent(Math.min(steps.length - 1, current + 1))
  }, [steps, current])

  const onBack = useCallback(() => {
    setCurrent(Math.max(0, current - 1))
  }, [current])

  const handleSubmit = (callback) => (values) => {
    const _values = onPreSubmit(values)
    if (_.isFunction(callback) && !!useAsync) {
      callback(_values)
    } else {
      onSubmit(_values)
    }
  }

  const t = useTranslate()

  const content = useMemo(() => {
    if (isFinished) {
      const {component: FinalStage} = finishedStage
      return <FinalStage onCancel={onCancel}/>
    }
    const isSingle = steps.length === 1
    return (
      <div className="w-full h-full lg:p-3">
        {renderElse(
          isSingle,
          <Steps
            style={{
              justifyContent: 'center',
            }}
            responsive={false}
            size="small"
            className="max-w-2xl mx-auto py-6"
            current={current}
            labelPlacement="vertical"
            direction="horizontal">
            {steps.map(({title}, index) => (
              <Steps.Step
                key={index}
                title={t(title)}
              />
            ))}
          </Steps>
        )}
        <Header/>
        <CurrentStep Wrapper={Wrapper}/>
      </div>
    )
  }, [steps, Wrapper, current, isFinished, finishedStage])

  const {isLoading, handleAsyncAction} = useAsyncAction(mergedConfigs)

  return (
    <EnhancedWrapper>
      <StepFormProvider
        {...{
          item,
          onBack,
          onNext,
          getStep,
          formInstance,
          initialValues,
          validationSchema,
          ...props,
        }}
        currentStep={getStep(current)}
        handleSubmit={handleSubmit(handleAsyncAction)}>
        <Spin spinning={!!isLoading}>{content}</Spin>
      </StepFormProvider>
    </EnhancedWrapper>
  )
}

const DefaultFormWrapper = ({children}) => (
  <div className="lg:p-2 space-y-3">{children}</div>
)

const createStepForm = (
  asyncConfigs,
  {
    steps,
    Header,
    onSubmit,
    onPreSubmit,
    initialValues,
    validationSchema,
    Wrapper = DefaultFormWrapper,
    ...props
  }
) => {
  const useAsync = !_.isEmpty(asyncConfigs)

  const allStages = Array.from(steps || [])

  const finishedStage = _.find(allStages, {finished: true})

  const visibleStages = allStages.filter(({finished}) => !!!finished)

  return (
    <StepFormWizard
      {...{
        Header,
        Wrapper,
        useAsync,
        onSubmit,
        onPreSubmit,
        asyncConfigs,
        initialValues,
        finishedStage,
        validationSchema,
        ...props,
      }}
      steps={visibleStages}
    />
  )
}

export default createStepForm
