import {Modal} from 'antd'
import DidOnMount from 'components/DidOnMount'
import {LoginContext} from 'components/LoginContext'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import React, {useContext, useState} from 'react'
import {AiOutlineFileSearch} from 'react-icons/ai'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {useSearchParam} from 'react-use'
import {branch} from 'recompose'
import {compose} from 'redux'
import {getLogin} from 'redux/selectors'
import Login from 'views/Auth/Login'
import NoFoundPage from 'views/NoFoundPage'
import {Null, renderIf} from 'views/Shared'
import AppTextLogoSvg from '../appConfigs/svgs/AppTextLogoSvg'
import ForgetPassword from '../views/Auth/ForgetPassword'
import LoginOrSkip from '../views/Auth/LoginOrSkip'
import {DelayRender} from '../views/Discovery/DelayRender'
import {staticPaths} from './staticPaths'

export const ModalLayout = ({name, onCancel, children, width, ...props}) => (
  <NavigationContext.Consumer>
    {({handleGoBack}) => (
      <Modal
        className="custom-modal"
        footer={null}
        width={width}
        title={name}
        {...props}
        visible={true}
        onCancel={onCancel || handleGoBack}
        maskClosable={false}
        destroyOnClose={true}>
        <div className="h-full md:h-auto lg:p-3">
          {_.isFunction(children) ? children(handleGoBack) : children}
        </div>
      </Modal>
    )}
  </NavigationContext.Consumer>
)
export const ModalForgetPassword = (props) => {
  const translate = useTranslate()
  return (
    <ModalLayout
      name={
        <div className="font-bold text-center text-color-000 uppercase">
          {translate('recover login password')}
        </div>
      }
      {...props}>
      <ForgetPassword/>
    </ModalLayout>
  )
}

export const ModalLogin = ({onSuccess, isRequired = true, ...props}) => {
  const isSignUpDefault = _.get(props, 'location.state.isSignUpDefault', false)
  return (
    <DelayRender time={1000}>
      <ModalLayout
        width={520}
        name={<AppTextLogoSvg size={20}/>}
        {...props}>
        {(handleGoBack = Null) => (
          <Login
            isRequired={isRequired}
            isSignUpDefault={isSignUpDefault}
            onSuccess={() => {
              if (_.isFunction(onSuccess)) {
                onSuccess()
              } else {
                handleGoBack()
              }
            }}
          />
        )}
      </ModalLayout>
    </DelayRender>
  )
}

export const ModalLoginOrSkip = ({onSuccess, ...props}) => (
  <DelayRender time={1000}>
    <ModalLayout
      width={520}
      name={<AppTextLogoSvg size={16}/>}
      {...props}>
      {(handleGoBack = Null) => (
        <LoginOrSkip
          onSuccess={() => {
            if (_.isFunction(onSuccess)) {
              onSuccess()
            } else {
              handleGoBack()
            }
          }}
        />
      )}
    </ModalLayout>
  </DelayRender>
)

export const NotRequiredLoginModal = ({
                                        callback = Null,
                                        onSuccess = Null,
                                        Component = NoFoundPage,
                                        ...props
                                      }) => {
  const [ready, setReady] = useState()

  const login = useContext(LoginContext)

  if (!!login || ready) {
    return <Component {...props} />
  }

  return (
    <ModalLoginOrSkip
      {...props}
      onSuccess={() => {
        callback()
        setReady(true)
      }}
    />
  )
}

export const createOptionalLoginRequired =
  (callback = Null, params) =>
    (Component) => {
      const login = useContext(LoginContext)
      const {withProps = Null} = params ?? {}
      return (
        <NavigationContext.Consumer>
          {({history}) => {
            const {invoked} = history?.location?.state ?? {}
            return (
              <React.Fragment>
                {renderIf(
                  login && invoked,
                  <DidOnMount
                    handler={() => {
                      callback()
                    }}
                  />
                )}
                <Toggle defaultValue={false}>
                  {(isToggle, toggle) => {
                    if (!login && isToggle) {
                      return (
                        <NotRequiredLoginModal
                          Component={Component}
                          callback={() => {
                            callback()
                            toggle()
                          }}
                          onCancel={() => {
                            toggle(false)
                          }}
                          {...(withProps({isToggle, toggle}) ?? {})}
                        />
                      )
                    }
                    return (
                      <Component
                        callback={() => {
                          if (login) {
                            callback()
                          } else {
                            history.push({
                              state: {
                                ...(history?.location?.state ?? {}),
                                invoked: true,
                              },
                            })
                          }
                          toggle()
                        }}
                      />
                    )
                  }}
                </Toggle>
              </React.Fragment>
            )
          }}
        </NavigationContext.Consumer>
      )
    }

export const ModalSignUp = (props) => {
  const type = useSearchParam('type')
  return (
    <DelayRender time={1000}>
      <ModalLayout
        width={650}
        name={<AppTextLogoSvg size={16}/>}
        {...props}>
        <Login
          isSignUpDefault={true}
          initOrgType={type}
        />
      </ModalLayout>
    </DelayRender>
  )
}

export const RequiredLoginPlaceholder = () => (
  <Translate>
    {(t) => (
      <div className="flex flex-col flex-center border border-dashed rounded-lg px-2 py-6 my-2">
        <div className="py-6">
          <AiOutlineFileSearch
            style={{fontSize: '3rem', color: 'rgba(226, 232, 240, 1)'}}
          />
        </div>
        <div className="text-center text-sm text-color-400 italic mb-2">
          {t('Please login to view this content')}
        </div>
        <Link
          to={staticPaths.login}
          className="rounded-md px-3 py-1 bg-primary text-white text-sm no-text-shadow hover:text-white no-underline shadow-lg">
          {t('Login now')}
        </Link>
      </div>
    )}
  </Translate>
)

export const createRequiredLogin = (FallBackComponent = ModalLogin) =>
  compose(
    connect((state) => ({
      login: !!getLogin(state),
    })),
    branch(
      (props) => !props.login,
      (props) => (props) => <FallBackComponent {...props} />
    )
  )
export const AuthOnly = createRequiredLogin(Null)(({children}) => children)
export const RequiredLogin = createRequiredLogin()(({children}) => children)
