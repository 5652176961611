import {Avatar, Button, Menu, Radio} from 'antd'
import {article_getDraftArticlesByUser_Api, article_getPublishedArticlesByUser_Api, auth_channels_Api,} from 'apis'
import {articleModel} from 'apis/model'
import {getTitle} from 'apis/model/base'
import DrawMenu from 'components/drawer/DrawMenu'
import EmptyHolder from 'components/EmptyHolder'
import Count from 'components/Feed/Count'
import FeedContext from 'components/Feed/FeedContext'
import {createTimeLineFeed} from 'components/Feed/TimeLineFeed'
import Timestamp from 'components/Feed/Timestamp'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import CustomLazyPaginationWrapper from 'modules/asyncCache/components/CustomLazyPaginationWrapper'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {useContext, useState} from 'react'
import {IoEllipsisHorizontal} from 'react-icons/io5'
import {RiAddFill, RiUser3Fill} from 'react-icons/ri'
import {Link} from 'react-router-dom'
import ChannelCreatePost from 'views/Channel/components/ChannelCreatePost'
import renderPagingLoading from '../functions/renderPagingLoading'
import {PiNotePencil, PiTrash} from "react-icons/pi";
import {renderIfElse} from "../../Shared";
import {getType} from "../../../apis/model/base";

const ArticleStatus = Object.freeze({
  DRAFT: 'draft',
  PUBLISHED: 'published',
})

const ArticleActions = Object.freeze({
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
})

export const MdmArticleContentMeta = () => {
  const {item, created, creatorName, handleClick} = useContext(FeedContext)
  const permissions = useContext(PermissionContext)

  const t = useTranslate()

  const history = useHistory()

  const container = _.get(item, 'container')

  const handleMenuClick = (e) => {
    const { key } = e

    switch (key) {
      case ArticleActions.EDIT:
        history.push({
          pathname: articleModel.getEditingURL(item),
        })
        break
      default:
        handleClick(key)
    }
  }

  const availbleActions = [
    [PiNotePencil, ArticleActions.EDIT],
    [PiTrash, ArticleActions.DELETE],
  ].filter(([, key]) => permissions[key])

  return (
    <div className="w-full mt-2 px-2">
      <div className="flex items-center gap-2 mr-10">
        <Avatar
          size={30}
          src={_.get(item, 'creator.avatar')}
          icon={<RiUser3Fill className="text-color-400"/>}
          fallback={<RiUser3Fill className="text-color-400"/>}
          className="flex flex-center background-200"/>
        {renderIfElse(
          !["organization"].includes(getType(item?.container?.owner)),
          <div className="flex flex-col flex-1">
            <div className="flex items-baseline gap-1 leading-tight">
              <Link
                to={getLinkToDetail(item?.owner)}
                className="font-bold text-xs text-primary no-underline cursor-pointer">
                {getTitle(item?.owner)}
              </Link>
              <span className="whitespace-no-wrap text-2xs text-color-500 italic lowercase">
              {t('posted')}
            </span>
            </div>
            <Timestamp
              timestamp={created}
              className="whitespace-no-wrap text-2xs text-color-500 italic"
            />
          </div>,
          <div className="flex flex-col flex-1">
            <div className="flex items-baseline gap-1 leading-tight">
              <Link
                to={getLinkToDetail(item?.owner)}
                className="font-bold text-xs text-primary no-underline cursor-pointer">
                {getTitle(item?.owner)}
              </Link>
              <span className="whitespace-no-wrap text-2xs text-color-500 italic">
              {t('posted on channel')}
            </span>
            </div>
            <div className="flex items-baseline gap-1 text-xs leading-tight">
              <Link
                className="font-medium text-xs text-color-000 no-underline max-lines-1 cursor-pointer"
                to={getLinkToDetail(item?.container)}>
                {getTitle(item?.container)}
              </Link>
              {' - '}
              <Timestamp
                timestamp={created}
                className="whitespace-no-wrap text-2xs text-color-500 italic"
              />
            </div>
          </div>
        )}
      </div>
      {!_.isEmpty(availbleActions) && (
        <DrawMenu
          component={
            <Count className="extraIcon mt-1">
              <IoEllipsisHorizontal/>
            </Count>
          }>
          {(isToggle, toggle) => (
            <Menu
              onClick={(e) => {
                handleMenuClick(e)
                toggle()
              }}>
              {availbleActions.map(([Icon, action]) => (
                <Menu.Item
                  icon={<Icon size={14}/>}
                  key={action}>
                  {t(action || '')}
                </Menu.Item>
              ))}
            </Menu>
          )}
        </DrawMenu>
      )}
    </div>
  )
}

export const ArticleItemContent = createTimeLineFeed({
  ContentMeta: MdmArticleContentMeta,
  Footer: () => <div className="py-2"/>,
})

const MyArticles = ({...props}) => {
  const t = useTranslate()

  const history = useHistory()

  const {isSm} = useContext(LayoutContext)

  const [tab, setTabs] = useState(ArticleStatus.DRAFT)

  const apiInfo =
    tab === ArticleStatus.DRAFT
      ? article_getDraftArticlesByUser_Api
      : article_getPublishedArticlesByUser_Api

  const lookupKey = [apiInfo.path, `type=${tab}`].join('/')

  return (
    <div className="flex flex-col gap-3 h-full">
      <div className="flex items-center w-full">
        <div className="flex-1" />
        <ChannelCreatePost
            apiInfo={auth_channels_Api}
            linkTo={(item) => {
              const url = articleModel.getLinkToCreate(item)
              history.push(url)
            }}>
          {(toggle) => (
              <Button
                  style={{height: 30, padding: '2px 10px', border: 0}}
                  onClick={toggle}
                  type="primary"
                  className="button-rounded-md flex flex-center gap-2 font-semibold text-sm">
                <RiAddFill/>
                {t('create articles')}
              </Button>
          )}
        </ChannelCreatePost>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex items-center w-full RoundedBorderRadioGroup">
          <Radio.Group
            className="custom-radio-my-activity"
            optionType="button"
            buttonStyle="solid"
            defaultValue={tab}
            onChange={(e) => {
              setTabs(e.target.value)
            }}>
            <Radio.Button value={ArticleStatus.DRAFT}>
              {t(ArticleStatus.DRAFT)}
            </Radio.Button>
            <Radio.Button value={ArticleStatus.PUBLISHED}>
              {t(ArticleStatus.PUBLISHED)}
            </Radio.Button>
          </Radio.Group>
        </div>
        <CustomLazyPaginationWrapper
          id={lookupKey}
          key={lookupKey}
          alwaysVisible={true}
          apiInfo={apiInfo}
          renderEmpty={() => <EmptyHolder/>}
          renderLoading={renderPagingLoading}
          renderItem={(item, index) => (
            <ArticleItemContent key={index} item={item}/>
          )}
        />
      </div>
    </div>
  )
}

export default MyArticles
