import { Alert } from 'antd'
import { getId, getType } from 'apis/model/base'
import { orderRequest_getOrderRequests_Api } from 'apis/orderRequest'
import Tabbar, { TabbarItem } from 'components/Tabbar'
import _ from 'lodash'
import CustomLazyPaginationWrapper from 'modules/asyncCache/components/CustomLazyPaginationWrapper'
import useTranslate from 'modules/local/useTranslate'
import React, { useCallback, useContext, useState } from 'react'
import { SpotRequestTypes } from 'views/Market/components/SpotBid/SpotRequestForm'
import ServerContext from "../../Server/ServerContext"
import { renderOrderRequestDetail } from '../functions/renderOrderRequestIDetail'
import OrderRequestProvider from '../OrderRequestProvider'
import { FilterTags } from './components/FilterWidget'
import OrderRequestSearch from './components/OrderRequestSearch'
import { requestDetailStatusOptions } from './OrderRequestStatus'
import ListHeader from "../../AppLocals/Templates/items/ListHeader";

const OrderRequests = () => {

  const t = useTranslate()

  const { item } = useContext(ServerContext)

  const [
    filter,
    setFilter
  ] = useState({
    tabIndex: 0,
    status: null,
    keyword: null,
    order_request_type: null,
  })

  const mergeFilter = params => {
    setFilter({
      ...filter,
      ...params,
    })
  }

  const {
    tabIndex = 0,
    order_request_type
  } = filter

  const getFilters = useCallback(() => {
    const {
      status = {},
      keyword = {},
      order_request_type = {},
    } = filter
    return {
      ...{
        status,
        keyword,
        order_request_type
      }
    }
  }, [filter])

  const lookupKey = JSON.stringify(filter)

  const id = getId(item)

  const type = getType(item)

  return (
    <div
      id="this-element"
      className="w-full flex flex-col space-y-3">

      <div className="flex flex-col gap-3">
        <ListHeader
            title="order request management"
        />
        {!!!_.get(item, 'contacts.length') && (
          <Alert
            closable
            showIcon
            type="warning"
            style={{ padding: '0.5rem' }}
            message={t("Contact information has not been declared, it is good to declare contact information so that customers can contact you directly")} />
        )}
      </div>

      <OrderRequestSearch
        onSearch={keyword => {
          mergeFilter({
            keyword,
          })
        }} />

      <div className="flex items-center gap-2">
        <span className="text-sm text-color-300 italic">
          {`${t('order request type')} : `}
        </span>
        <FilterTags
          compactResult={false}
          selectedValue={order_request_type}
          className="flex flex-wrap items-center"
          onClear={result => {
            mergeFilter({
              order_request_type: null,
            })
          }}
          onItemClick={({ value }) => {
            mergeFilter({
              order_request_type: value,
            })
          }}
          tags={Object.values(SpotRequestTypes).map((value) => {
            return {
              value,
              label: value,
            }
          })}
        />
      </div>

      <Tabbar
        activeKey={tabIndex}>
        {_.concat(
          { name: 'all', value: null },
          requestDetailStatusOptions
        ).map(({ name, value }, index) => {
          return (
            <TabbarItem
              key={index}
              onClick={() => {
                mergeFilter({
                  status: value,
                  tabIndex: index,
                })
              }}>
              {t(name)}
            </TabbarItem>
          )
        })}
      </Tabbar>

      <OrderRequestProvider organization={item}>
        <CustomLazyPaginationWrapper
          key={lookupKey}
          id={lookupKey}
          apiInfo={orderRequest_getOrderRequests_Api}
          query={{
            ':id': id,
            ':prop': type,
          }}
          values={getFilters()}
          renderWrap={children => {
            return (
              <div className="grid gap-4">
                {children}
              </div>
            )
          }}
          renderItem={renderOrderRequestDetail} />
      </OrderRequestProvider>
    </div>
  )
}

export default OrderRequests