import React from "react";

export const TreeRefreshment = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<circle cx="401.472" cy="379.449" r="34.626" fill="#fea95c"></circle>
			<path
				fill="#fe9738"
				d="M416.068 410.859a34.515 34.515 0 01-14.587 3.214c-19.131 0-34.635-15.504-34.635-34.624 0-19.13 15.504-34.624 34.635-34.624a34.496 34.496 0 0114.587 3.214c-11.837 5.501-20.047 17.492-20.047 31.41-.001 13.907 8.21 25.909 20.047 31.41z"
			></path>
			<path
				fill="#bc7c63"
				d="M302.599 512H192.826c-2.65 0-4.627-2.498-3.961-5.063a20.181 20.181 0 013.351-6.98 20.186 20.186 0 0110.405-7.263l1.628-.474a20.15 20.15 0 0014.371-18.069l19.513-316.592a4.075 4.075 0 014.068-3.825h11.023a4.075 4.075 0 014.068 3.825l15.862 257.427c.238 3.864 5.223 5.251 7.422 2.065l53.367-77.311a4.076 4.076 0 015.543-1.123l8.54 5.436a4.076 4.076 0 011.329 5.497l-72.591 124.004.041.597a20.148 20.148 0 0014.371 18.069l1.628.474a20.162 20.162 0 0113.756 14.242c.666 2.565-1.31 5.064-3.961 5.064z"
			></path>
			<path
				fill="#ab695d"
				d="M247.767 153.734a4.075 4.075 0 014.073 4.218l-11.03 316.199c-.299 8.448-3.554 15.659-8.149 18.069l-.917.474c-2.421 1.278-4.461 3.874-5.893 7.263-1.028 2.439-1.747 5.287-2.072 8.346-.222 2.092-1.951 3.697-4.055 3.697h-26.9c-2.65 0-4.627-2.498-3.961-5.063a20.181 20.181 0 013.351-6.98 20.186 20.186 0 0110.405-7.263l1.628-.474a20.15 20.15 0 0014.371-18.069l19.513-316.592a4.075 4.075 0 014.068-3.825z"
			></path>
			<path
				fill="#9be69d"
				d="M52.573 271.752a35.54 35.54 0 00-3.551 15.532c0 19.706 15.975 35.682 35.682 35.682 2.853 0 5.624-.345 8.284-.977.408 14.786 12.513 26.65 27.398 26.65a27.3 27.3 0 0017.322-6.168c1.911 13.275 13.328 23.478 27.131 23.478a27.31 27.31 0 0011.452-2.507c5.202 16.041 20.26 27.643 38.035 27.643 16.859 0 31.27-10.439 37.15-25.201 4.942 7.196 13.225 11.918 22.614 11.918 9.606 0 18.053-4.944 22.949-12.423 3.212 11.574 13.817 20.072 26.414 20.072 14.068 0 25.655-10.598 27.229-24.244 8.423 9.128 20.483 14.85 33.883 14.85 25.461 0 46.1-20.64 46.1-46.1a46.21 46.21 0 00-1.261-10.697 27.276 27.276 0 0013.76 3.706c15.142 0 27.417-12.275 27.417-27.417a27.315 27.315 0 00-7.045-18.345c21.751-3.701 38.315-22.627 38.315-45.432 0-25.461-20.64-46.1-46.1-46.1-1.3 0-2.584.067-3.857.173 0-.058.004-.115.004-.173 0-15.142-12.275-27.417-27.417-27.417-8.625 0-16.316 3.987-21.342 10.214.004-.203.015-.405.015-.609 0-15.142-12.275-27.418-27.417-27.418-8.575 0-16.227 3.938-21.255 10.102.003-.185.014-.368.014-.554 0-14.861-9.087-27.596-22.006-32.962a45.873 45.873 0 005.974-22.687c0-25.46-20.64-46.1-46.1-46.1a45.887 45.887 0 00-23.91 6.677 35.498 35.498 0 005.636-19.235C274.09 15.975 258.115 0 238.408 0c-13.22 0-24.752 7.195-30.918 17.878a27.283 27.283 0 00-15.234-4.622c-15.142 0-27.417 12.275-27.417 27.417 0 .413.013.823.031 1.231-7.33-6.541-16.994-10.522-27.591-10.522-22.899 0-41.463 18.563-41.463 41.462 0 2.67.262 5.278.744 7.806a35.613 35.613 0 00-11.856-2.025c-19.706 0-35.682 15.975-35.682 35.682a35.52 35.52 0 006.118 19.984 27.468 27.468 0 00-6.118-.694c-15.142 0-27.417 12.275-27.417 27.418 0 10.141 5.51 18.99 13.696 23.733-14.927 7.631-25.153 23.147-25.153 41.063 0 24.221 18.685 44.065 42.425 45.941z"
			></path>
			<path
				fill="#6fd7a3"
				d="M236.294 32.136c10.858 0 20.748 4.079 28.248 10.776-.01-.422-.031-.845-.031-1.267 0-7.211 2.72-13.794 7.211-18.77C266.571 9.493 253.601.005 238.416.005c-13.227 0-24.755 7.191-30.926 17.874a27.258 27.258 0 00-15.226-4.615c-15.144 0-27.423 12.269-27.423 27.413 0 .412.021.824.031 1.236-7.325-6.542-16.988-10.529-27.588-10.529-22.901 0-41.465 18.564-41.465 41.465 0 2.668.268 5.275.742 7.809a35.555 35.555 0 00-11.847-2.03c-19.707 0-35.685 15.978-35.685 35.686a35.516 35.516 0 006.119 19.985 27.266 27.266 0 00-6.119-.7c-15.144 0-27.413 12.28-27.413 27.423 0 10.137 5.501 18.986 13.691 23.735-14.927 7.624-25.157 23.138-25.157 41.053 0 24.23 18.688 44.071 42.433 45.946a35.5 35.5 0 00-3.554 15.535c0 19.707 15.978 35.675 35.685 35.675 2.854 0 5.625-.34 8.283-.968.402 14.783 12.506 26.64 27.392 26.64a27.326 27.326 0 0017.328-6.16c1.906 13.269 13.32 23.478 27.125 23.478 4.09 0 7.974-.906 11.456-2.514 5.202 16.04 20.264 27.65 38.034 27.65 15.133 0 28.299-8.416 35.078-20.83-6.717-4.296-11.487-11.384-12.671-19.625a27.96 27.96 0 01-17.74 6.315c-15.236 0-27.629-12.146-28.052-27.28-2.72.649-5.553.999-8.478.999-20.171 0-36.53-16.359-36.53-36.53a36.38 36.38 0 013.637-15.906c-24.312-1.917-43.443-22.241-43.443-47.038 0-18.337 10.477-34.222 25.755-42.042-8.376-4.852-14.021-13.918-14.021-24.302 0-15.494 12.568-28.062 28.072-28.062 2.153 0 4.244.247 6.264.711a36.379 36.379 0 01-6.264-20.469c0-20.171 16.359-36.53 36.53-36.53 4.255 0 8.344.741 12.146 2.07a42.512 42.512 0 01-.762-7.984c-.003-23.446 18.994-42.453 42.441-42.453z"
			></path>
			<circle cx="153.593" cy="356.466" r="34.626" fill="#fea95c"></circle>
			<path
				fill="#fe9738"
				d="M168.189 387.876a34.515 34.515 0 01-14.587 3.214c-19.131 0-34.635-15.504-34.635-34.624 0-19.13 15.504-34.624 34.635-34.624a34.496 34.496 0 0114.587 3.214c-11.837 5.501-20.047 17.492-20.047 31.41 0 13.908 8.21 25.909 20.047 31.41z"
			></path>
			<circle cx="277.65" cy="277.316" r="34.626" fill="#fea95c"></circle>
			<path
				fill="#fe9738"
				d="M292.245 308.726a34.515 34.515 0 01-14.587 3.214c-19.131 0-34.635-15.504-34.635-34.624 0-19.13 15.504-34.624 34.635-34.624a34.496 34.496 0 0114.587 3.214c-11.837 5.501-20.047 17.492-20.047 31.41 0 13.907 8.21 25.909 20.047 31.41z"
			></path>
			<g>
				<circle cx="118.967" cy="208.063" r="34.626" fill="#fea95c"></circle>
				<path
					fill="#fe9738"
					d="M133.562 239.473a34.515 34.515 0 01-14.587 3.214c-19.131 0-34.635-15.504-34.635-34.624 0-19.13 15.504-34.624 34.635-34.624a34.496 34.496 0 0114.587 3.214c-11.837 5.502-20.047 17.492-20.047 31.41 0 13.907 8.211 25.909 20.047 31.41z"
				></path>
			</g>
			<g>
				<circle cx="257.439" cy="153.734" r="34.626" fill="#fea95c"></circle>
				<path
					fill="#fe9738"
					d="M272.035 185.144a34.515 34.515 0 01-14.587 3.214c-19.131 0-34.635-15.504-34.635-34.624 0-19.13 15.504-34.624 34.635-34.624a34.496 34.496 0 0114.587 3.214c-11.837 5.502-20.047 17.492-20.047 31.41 0 13.907 8.21 25.909 20.047 31.41z"
				></path>
			</g>
			<g>
				<circle cx="401.472" cy="242.691" r="34.626" fill="#fea95c"></circle>
				<path
					fill="#fe9738"
					d="M416.068 274.101a34.515 34.515 0 01-14.587 3.214c-19.131 0-34.635-15.504-34.635-34.624 0-19.13 15.504-34.624 34.635-34.624a34.496 34.496 0 0114.587 3.214c-11.837 5.502-20.047 17.492-20.047 31.41-.001 13.908 8.21 25.909 20.047 31.41z"
				></path>
			</g>
		</svg>
	);
}

export default TreeRefreshment;
