import Icon, {SearchOutlined} from '@ant-design/icons'
import {Button, Checkbox, Input, Modal, Select, Spin} from 'antd'
import {API_ROOT_URL} from 'envs/_current/config'
import preventDefaultEvent from 'helpers/preventDefaultEvent'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useDispatchAsyncAction from 'modules/asyncCache/useDispatchAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useToggle} from 'react-use'
import classNames from "classnames";
import categoryIcons from "../../../conts/categoryIcons";
import {CgClose} from "react-icons/cg";

export const SelectCategories = ({
                                   type,
                                   selected: externalSelected = [],
                                   onChange,
                                   categoryOptions = [],
                                 }) => {
  const [selected, setSelected] = useState(externalSelected)
  useEffect(() => {
    setSelected(externalSelected)
  }, [externalSelected])
  const entitesRef = useRef({
    ...selected.reduce((result, value) => {
      result[value] = {
        id: value,
        name: value,
      }
      return result
    }, {}),
    ...categoryOptions.reduce((result, value) => {
      result[value.id] = value
      return result
    }, {}),
  })
  const [keyword, setkeyword] = useState('')
  const t = useTranslate()
  const handleChange = (id) => {
    const findIndex = selected.findIndex((item) => item === id)
    let newValues
    if (findIndex > -1) {
      newValues = selected.filter((item, i) => findIndex !== i)
      setSelected(newValues)
      // return onChange(newValues)
    } else {
      newValues = [...selected, id]
      setSelected(newValues)
    }
    // return onChange(newValues)
  }
  const [isToggle, toggle] = useToggle()
  const [{result = [], isLoading}, dispatch] = useDispatchAsyncAction()
  entitesRef.current = {
    ...entitesRef.current,
    ...result.reduce((result, value) => {
      result[value.id] = value
      return result
    }, {}),
  }
  const options = selected
    .map((id) => {
      const item = entitesRef.current[id]
      if (!item) return null
      return {...item, label: item.name, value: item.id}
    })
    .filter(Boolean)
  const categories = keyword.length ? result : categoryOptions
  const debouncedispatch = useMemo(() => {
    const handler = (keyword) => {
      setkeyword(keyword)
      dispatch(
        createAsyncAction({
          apiInfo: {
            root: API_ROOT_URL,
            path:
              '/categories/search?keyword=' +
              keyword +
              (type ? `&&type=${type}` : ''),
            method: 'GET',
          },
        })
      )
    }
    return _.debounce(handler)
  }, [])
  return (
    <>
      <Select
        options={options}
        open={false}
        onClick={toggle}
        {...{
          value: selected,
        }}
        mode="multiple"
        placeholder={t('please select')}
        onChange={(value) => {
          setSelected(value)
          return onChange(value)
        }}
        style={{width: '100%'}}
      />
      <Modal
        className="custom-modal"
        bodyStyle={{padding: 0}}
        destroyOnClose
        onOk={toggle}
        footer={null}
        visible={isToggle}
        onCancel={toggle}
        title={
          <div className="text-center font-bold text-color-000 uppercase font-light">{t('select categories')}</div>
        }>
        <div // style={{height: 'calc(100vh - 155px)'}}
          className="space-y-3 p-3 flex flex-col mb-16">
          <Input
            autoFocus
            placeholder={t('please select')}
            addonAfter={<SearchOutlined/>}
            onChange={(e) => debouncedispatch(e.target.value)}
            className="rounded-lg"
          />
          <div className="flex flex-wrap items-center gap-2">
            {options.map((item) => {
              const Icon = _.get(categoryIcons, item.id, categoryIcons['default'])

              return (
                <div
                  key={item.id}
                  style={{padding: '4px 6px 4px 8px'}}
                  className="flex items-center gap-2 cursor-pointer border rounded-md italic">
                  <Icon size={16}/>
                  <span className="font-medium text-sm text-color-100">{item.name}</span>
                  <CgClose
                    size={12}
                    className="text-color-500 hover:text-primary"
                    onClick={() => {
                      handleChange(item.id)
                    }}
                  />
                </div>
              )
            })}
          </div>
          {isLoading && (
            <Spin spinning>
              <div className="border background-100 rounded p-3 flex space-x-3 h-24"/>
            </Spin>
          )}
          {categories.map((item) => {
            const {id, name, description} = item
            const checked = selected.includes(id)
            const Icon = _.get(categoryIcons, id, categoryIcons['default'])

            return (
              <div
                key={id}
                onClick={(e) => {
                  preventDefaultEvent(e)
                  handleChange(id)
                }}
                className={classNames(
                  'flex items-center gap-3 border background hover:background-100 rounded-lg p-3', checked && 'border-primary'
                )}>
                <Icon/>
                <div className="flex-1">
                  <div className="font-bold">{name}</div>
                  {!!description && (
                    <div className="font-bold text-color-300">
                      {description}
                    </div>
                  )}
                </div>
                <div className="self-center">
                  <Checkbox
                    checked={checked}
                    value={id}
                    id={id}
                    name={id}
                  />
                </div>
              </div>
            )
          })}
          <div className="flex-1"/>
          <div className="flex justify-end">
            <Button
              type="primary"
              className="rounded-lg px-5"
              onClick={() => {
                onChange(selected)
                toggle()
              }}>
              {t('save')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
