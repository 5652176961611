import { search_loadFilterData_Api } from 'apis'
import EmptyHolder from 'components/EmptyHolder'
import _ from 'lodash'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import queryString from 'query-string'
import React, {
  useContext
} from 'react'
import {
  useLocation,
  useSearchParam
} from 'react-use'
import DataSetResult from 'views/Discovery/components/DataSetResult'
import { DelayRender } from 'views/Discovery/DelayRender'
import renderSearchResultItem from 'views/Discovery/functions/renderSearchResultItem'
import {
  Recommendation,
  RecommendationFilterTypes
} from 'views/Discovery/Recommendation'
import { renderLoading } from 'views/MainPage/functions/renderPagingLoading'
import {
  SearchParams,
  SearchTypes
} from 'views/Search/SearchProvider'
import { renderIf } from 'views/Shared'
import DiscoveryExplorerContext from '../DiscoveryExplorerContext'

const DiscoveryExplorerResult = ({
  type,
  filter,
  keyword,
  filterRow,
  categories
}) => {
  const showResult = !_.isEmpty(keyword)

  const showRecomendation = _.some(
    [
      _.every(
        [keyword, categories],
        _.isEmpty
      ),
      type === SearchTypes.ORGANIZATION
    ],
    Boolean
  )

  const createApiInfo = () => {
    const searchString = queryString.stringify(
      {
        keyword: keyword,
        ...Array.from(
          filterRow || []
        ).reduce((result, value) => {
          if (
            !filter[value.id] ||
            !filter[value.id].value
          ) {
            return result
          } else if (
            value.type === 'checkbox'
          ) {
            if (
              !result[value.groupId]
            ) {
              result[value.groupId] = []
            }
            result[value.groupId].push(
              value.id
            )
          }
          return result
        }, {})
      },
      { arrayFormat: 'comma' }
    )
    let path = search_loadFilterData_Api.path.replace(
      ':type',
      type || ''
    )
    if (!_.isEmpty(searchString)) {
      path = [path, searchString].join(
        '?'
      )
    }
    return {
      ...search_loadFilterData_Api,
      path
    }
  }
  const apiInfo = createApiInfo()

  const location = useLocation()

  const default_filter =
    type === SearchTypes.ARTICLE
      ? RecommendationFilterTypes.LASTED
      : RecommendationFilterTypes.MINE

  return (
    <div className="flex flex-col gap-3 justify-center">
      {showResult ? (
        <LazyPagination
          refreshKey={[
            type,
            keyword,
            categories
          ]}
          renderLoading={renderLoading}
          renderEmpty={() => (
            <React.Fragment>
              <EmptyHolder />
              {showRecomendation && (
                <DelayRender>
                  <Recommendation
                    type={type}
                    default_filter={
                      default_filter
                    }
                  />
                </DelayRender>
              )}
            </React.Fragment>
          )}
          apiInfo={apiInfo}
          renderItem={
            renderSearchResultItem
          }>
          {render => render()}
        </LazyPagination>
      ) : (
        renderIf(
          showRecomendation,
          <DelayRender>
            <Recommendation
              type={type}
              key={location.search}
              default_filter={
                default_filter
              }
            />
          </DelayRender>
        )
      )}
    </div>
  )
}

export default props => {
  const { filterRow, filter } =
    useContext(
      DiscoveryExplorerContext
    ) || {}

  const type = useSearchParam(
    SearchParams.TYPE
  )

  const keyword = useSearchParam(
    SearchParams.KEYWORD
  )

  const categories = useSearchParam(
    SearchParams.CATEGORIES
  )

  const params = {
    ...props,
    type,
    filter,
    keyword,
    filterRow,
    categories
  }

  switch (type) {
    // case SearchTypes.DATASET:
    //   return (
    //     <DataSetResult {...params} />
    //   )
    default:
      return (
        <DiscoveryExplorerResult
          {...params}
        />
      )
  }
}
