import { API_ROOT_URL } from 'envs/_current/config'
import { schema } from 'normalizr'
import { createListTransform } from './utils/createListTransform'

export const iframeSchema = new schema.Entity(
  'iframes',
  {},
  {
    idAttribute: 'idname'
  }
)


// Route::get('iframes/list/get-by-cates', 'IframeController@getIframes');
export const iframes_getIframesByCategories_Api = {
  backend: `Route::get('iframes/list/get-by-cates', 'IframeController@getIframes');`,
  root: API_ROOT_URL,
  path: '/iframes/list/get-by-cates',
  method: 'GET',
  paramsFields: [
    'category_id',
    'ref_category_id',
    'category_type'
  ],
  schema: iframeSchema,
  transform: createListTransform(
    iframeSchema
  )
}