import {SelectEntityItem} from 'components/SelectEntityItem'
import _ from 'lodash'
import React from 'react'
import {createBaseItemSchema} from '../../../apis/schema'
import {createTransform} from '../../../apis/utils/createTransform'
import {API_ROOT_URL} from '../../../envs/_current/config'
import Async from '../../../modules/asyncCache/components/Async'
import TransformToCardProps from './TransformToCardProps'

export const WidgetList = ({ props }) => {
  const path = `/widgets/container/market-summary/gets`;
  return (
    <>
      <Async
        key={path}
        apiInfo={{
          root: API_ROOT_URL,
          path: path,
          transform: createTransform(
            createBaseItemSchema({
              idAttribute: () =>
                path
            }),
            'data'
          )
        }}
      />
      <SelectEntityItem item={path}>
        {item => (item || null) && (
            _.get(item, 'data', []).map(
                (data, i) => (
                    <TransformToCardProps
                        key={i}
                        data={data}
                    />
                )
            )
          )
        }
      </SelectEntityItem>
    </>
  )
}
