import React from 'react';
import Loadable from "react-loadable";
import {useSearchParam} from 'react-use';
import LoadingPage from 'views/LoadingPage';

const ProductReport = Loadable({
  loader: () => import('./ProductReport'),
  loading: LoadingPage
})
const ProductReportDetailSetting = Loadable({
  loader: () => import('./ProductReportDetailSetting'),
  loading: LoadingPage
})

export default (props) => {
  const reportId = useSearchParam('reportId');

  if (reportId) {
    return <ProductReportDetailSetting key={reportId} reportId={reportId}  {...props} />
  }
  return <ProductReport {...props} />
}
