import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import { CustomToggleField } from 'components/form/fields/ToggleField'
import { createValue } from 'components/form/utils'
import ForDevelop from 'envs/ForDevelop'
import _ from 'lodash'
import {
  mapProps,
  nest,
} from 'recompose'
import {
  BoxPlaceholder,
  fromObject,
  Null,
  renderSelf,
} from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import AdvancedFormGroup, {
  withAdvancedOptions,
} from '../custom/AdvancedFormGroup'
import RadioOptions from '../custom/RadioOptions'
import { createControlledFormField } from '../factory/createFormField'
import {
  requiredNumber,
  requiredString,
} from '../factory/createValidationSchema'

const createGroup = ({
  status,
  privacy,
  ...values
}) => [
  {
    invisible: true,
    children: [
      {
        name: 'invisible',
        component: nest(
          ForDevelop,
          BoxPlaceholder
        ),
      },
    ],
  },
  {
    inline: true,
    invisible: true,
    className: 'grid grid-cols-2 gap-2',
    children: [
      {
        label: 'privacy',
        name: 'privacy',
        component: withAdvancedOptions(
          baseFieldTypes.privacy
        )(RadioOptions),
        value: privacy,
      },
      {
        label: 'status',
        name: 'status',
        component: withAdvancedOptions(
          baseFieldTypes.status
        )(RadioOptions),
        value: status,
      },
    ],
  },
]

const advancedSchema = {
  children: [
    {
      name: 'advanced',
      component: mapProps(
        ({ form, onChange }) => {
          const _values = fromObject(
            _.get(form, 'values'),
            ['privacy'].map((e) => ({
              name: e,
            }))
          )
          return {
            onChange,
            groupValues:
              createGroup(_values),
          }
        }
      )(AdvancedFormGroup),
    },
  ],
}

export const organizationValidationSchema =
  {
    [CRUD.CREATE]: (
      translate = renderSelf
    ) => {
      return {
        title:
          requiredString(translate),
        privacy:
          requiredNumber(translate),
      }
    },
  }

export const organizationStepFormSchema =
  {
    infoStage: [
      {
        title: 'title',
        children: [
          {
            name: 'title',
            component:
              createControlledFormField(
                {
                  placeholder: 'what is your organization name',
                }
              ),
          },
        ],
      },
      {
        title: 'privacy',
        children: [
          {
            name: 'privacy',
            component:
              createControlledFormField(
                {
                  Component:
                    CustomToggleField,
                  title:
                    'private',
                  description:
                    'private organization description',
                  withProps: ({
                    name,
                    value,
                    onChange = Null,
                  }) => ({
                    value:
                      Number(value) ===
                      2,
                    onChange: (value) =>
                      onChange(
                        createValue(
                          name,
                          Boolean(value)
                            ? 2
                            : 1
                        )
                      ),
                  }),
                }
              ),
          },
        ],
      },
      advancedSchema,
    ],
  }
