export default function createPath({
  query = {},
  path
}) {
  const url = Object.keys(query).reduce(
    (result, key) => {
      if (
        query[key]
        // &&
        // query[key].length > 0
      )
        return result.replace(
          key,
          query[key]
        )
      return result
    },
    path
  )
  return url
}
