import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import React from 'react'
import { bindQueryParam } from 'views/AppLocals/functions/routerHelper'
import { getResponseItem } from '../functions'
import { renderIf } from '../typedefs'

export const FetchEntityById = ({
  id,
  values,
  apiInfo,
  children,
  ...props
}) => {
  const query = props.query ?? bindQueryParam({ id })
  return renderIf(
    id,
    <Async
      {...{
        query,
        values,
        apiInfo,
      }}>
      {({ response, isLoading }) =>
        _.isFunction(children)
          ? children({
              isLoading,
              item: getResponseItem(response),
            })
          : children ?? null
      }
    </Async>
  )
}
