import React from "react";

export const CottonFabric = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 510 510"
		>
			<linearGradient id="lg1">
				<stop offset="0" stopColor="#eaf6ff"></stop>
				<stop offset="1" stopColor="#b3dafe"></stop>
			</linearGradient>
			<linearGradient
				id="SVGID_1_"
				x1="172.735"
				x2="355.161"
				y1="49.884"
				y2="232.31"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg1"
			></linearGradient>
			<linearGradient id="lg2">
				<stop offset="0" stopColor="#d8ecfe" stopOpacity="0"></stop>
				<stop offset="1" stopColor="#678d98"></stop>
			</linearGradient>
			<linearGradient
				id="SVGID_00000040564712260578125250000001832428461508629126_"
				x1="235.723"
				x2="248.196"
				y1="14.673"
				y2="87.436"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg2"
			></linearGradient>
			<linearGradient id="lg3">
				<stop offset="0" stopColor="#ade194"></stop>
				<stop offset="1" stopColor="#97d729"></stop>
			</linearGradient>
			<linearGradient
				id="SVGID_00000164488456009671361340000014685660328174764442_"
				x1="209.017"
				x2="243.839"
				y1="337.758"
				y2="391.29"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg3"
			></linearGradient>
			<linearGradient id="lg4">
				<stop offset="0" stopColor="#026841" stopOpacity="0"></stop>
				<stop offset="0.238" stopColor="#076743" stopOpacity="0.238"></stop>
				<stop offset="0.52" stopColor="#156349" stopOpacity="0.52"></stop>
				<stop offset="0.823" stopColor="#2d5d52" stopOpacity="0.823"></stop>
				<stop offset="1" stopColor="#3e5959"></stop>
			</linearGradient>
			<linearGradient
				id="SVGID_00000116918285877333658300000009749504035252740001_"
				x1="177.89"
				x2="181.008"
				y1="398.425"
				y2="350.09"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg4"
			></linearGradient>
			<linearGradient
				id="SVGID_00000049189906023337304910000009586642774957285522_"
				x1="343.764"
				x2="379.39"
				y1="315.642"
				y2="351.267"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg3"
			></linearGradient>
			<linearGradient
				id="SVGID_00000047054693045342316240000002371680298906310294_"
				x1="379.626"
				x2="351.809"
				y1="351.503"
				y2="323.687"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg4"
			></linearGradient>
			<linearGradient
				id="SVGID_00000155142165447613703440000007282646733440847495_"
				x1="101.937"
				x2="239.138"
				y1="93.314"
				y2="230.515"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg1"
			></linearGradient>
			<linearGradient
				id="SVGID_00000168838476997104709510000003156679792300936859_"
				x1="118.53"
				x2="163.228"
				y1="85.272"
				y2="153.877"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg2"
			></linearGradient>
			<linearGradient
				id="SVGID_00000008860592556077263580000009821730487638607550_"
				x1="282.758"
				x2="419.959"
				y1="93.314"
				y2="230.515"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg1"
			></linearGradient>
			<linearGradient
				id="SVGID_00000037654868812152910300000007409306830006977190_"
				x1="354.47"
				x2="347.194"
				y1="84.786"
				y2="141.957"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg2"
			></linearGradient>
			<linearGradient
				id="SVGID_00000099636340960125856590000009200669862239842237_"
				x1="66.432"
				x2="233.532"
				y1="178.881"
				y2="345.982"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg1"
			></linearGradient>
			<linearGradient
				id="SVGID_00000062902550841856146810000016438725517915812247_"
				x1="290.957"
				x2="458.057"
				y1="178.881"
				y2="345.982"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg1"
			></linearGradient>
			<linearGradient
				id="SVGID_00000123414177299275509620000010790310084008359848_"
				x1="456.869"
				x2="320.699"
				y1="247.663"
				y2="259.097"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg2"
			></linearGradient>
			<linearGradient
				id="SVGID_00000020399385221176917430000009255027369682637990_"
				x1="163.499"
				x2="363.93"
				y1="218.277"
				y2="418.708"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg3"
			></linearGradient>
			<linearGradient
				id="SVGID_00000083086991013750958280000006530338102961218966_"
				x1="272.277"
				x2="432.355"
				y1="273.404"
				y2="450.633"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg4"
			></linearGradient>
			<linearGradient
				id="SVGID_00000002368316623178107040000005983333741965500304_"
				x1="171.696"
				x2="354.122"
				y1="102.067"
				y2="284.493"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg1"
			></linearGradient>
			<linearGradient
				id="SVGID_00000049221275594678461550000007339425651641655219_"
				x1="325.611"
				x2="206.072"
				y1="373.872"
				y2="315.662"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg4"
			></linearGradient>
			<linearGradient
				id="SVGID_00000116927969728030367530000016230839031662204067_"
				x1="270.484"
				x2="307.905"
				y1="241.623"
				y2="340.372"
				gradientUnits="userSpaceOnUse"
				xlinkHref="#lg2"
			></linearGradient>
			<circle
				cx="256.039"
				cy="133.188"
				r="133.188"
				fill="url(#SVGID_1_)"
			></circle>
			<circle
				cx="256.039"
				cy="133.188"
				r="133.188"
				fill="url(#SVGID_00000040564712260578125250000001832428461508629126_)"
			></circle>
			<ellipse
				cx="184.259"
				cy="299.697"
				fill="url(#SVGID_00000164488456009671361340000014685660328174764442_)"
				rx="97.393"
				ry="120.059"
			></ellipse>
			<ellipse
				cx="184.259"
				cy="299.697"
				fill="url(#SVGID_00000116918285877333658300000009749504035252740001_)"
				rx="97.393"
				ry="120.059"
			></ellipse>
			<ellipse
				cx="327.82"
				cy="299.697"
				fill="url(#SVGID_00000049189906023337304910000009586642774957285522_)"
				rx="97.393"
				ry="120.059"
			></ellipse>
			<ellipse
				cx="327.82"
				cy="299.697"
				fill="url(#SVGID_00000047054693045342316240000002371680298906310294_)"
				rx="97.393"
				ry="120.059"
			></ellipse>
			<circle
				cx="164.589"
				cy="155.967"
				r="100.17"
				fill="url(#SVGID_00000155142165447613703440000007282646733440847495_)"
			></circle>
			<circle
				cx="164.589"
				cy="155.967"
				r="100.17"
				fill="url(#SVGID_00000168838476997104709510000003156679792300936859_)"
			></circle>
			<circle
				cx="345.411"
				cy="155.967"
				r="100.17"
				fill="url(#SVGID_00000008860592556077263580000009821730487638607550_)"
			></circle>
			<circle
				cx="345.411"
				cy="155.967"
				r="100.17"
				fill="url(#SVGID_00000037654868812152910300000007409306830006977190_)"
			></circle>
			<g>
				<circle
					cx="142.738"
					cy="255.187"
					r="121.999"
					fill="url(#SVGID_00000099636340960125856590000009200669862239842237_)"
				></circle>
				<circle
					cx="367.262"
					cy="255.187"
					r="121.999"
					fill="url(#SVGID_00000062902550841856146810000016438725517915812247_)"
				></circle>
				<circle
					cx="367.262"
					cy="255.187"
					r="121.999"
					fill="url(#SVGID_00000123414177299275509620000010790310084008359848_)"
				></circle>
			</g>
			<path
				fill="url(#SVGID_00000020399385221176917430000009255027369682637990_)"
				d="M375.059 272.411c0-67.275-55.333-121.626-122.971-120.024-65.012 1.539-117.45 55.57-117.145 120.599.233 49.685 30.653 92.22 73.859 110.244 17.781 7.417 29.456 24.675 29.456 43.941v66.087c0 9.741 8.321 17.54 18.242 16.676 8.736-.76 15.242-8.463 15.242-17.232V427.17c0-19.159 11.485-36.495 29.185-43.827 43.516-18.028 74.132-60.896 74.132-110.932z"
			></path>
			<path
				fill="url(#SVGID_00000083086991013750958280000006530338102961218966_)"
				d="M138.051 245.508c7.3 65.643 56.856 116.434 116.949 116.434 60.133 0 109.716-50.859 116.964-116.566-12.476-54.185-61.59-94.369-119.876-92.989-55.577 1.316-101.934 41.005-114.037 93.121z"
			></path>
			<circle
				cx="255"
				cy="185.371"
				r="133.188"
				fill="url(#SVGID_00000002368316623178107040000005983333741965500304_)"
			></circle>
			<path
				fill="url(#SVGID_00000049221275594678461550000007339425651641655219_)"
				d="M363.966 222.042a119.195 119.195 0 018.006 23.341c-7.25 65.705-56.833 116.566-116.971 116.566v148.046c.499 0 .998-.016 1.497-.062 8.732-.764 15.249-8.466 15.249-17.229V427.17c0-19.163 11.476-36.501 29.188-43.829 43.502-18.024 74.124-60.887 74.124-110.937 0-17.995-3.985-35.046-11.093-50.362z"
			></path>
			<path
				fill="url(#SVGID_00000116927969728030367530000016230839031662204067_)"
				d="M121.962 191.315c3.112 70.797 61.474 127.244 133.038 127.244s129.926-56.447 133.038-127.244z"
			></path>
		</svg>
	);
}

export default CottonFabric;
