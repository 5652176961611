import React, {lazy, Suspense} from 'react'

const TestScreenLazy = lazy(() =>
  import('./TestScreen')
)

const TestScreen = props => (
  <Suspense fallback={null}>
    <TestScreenLazy {...props} />
  </Suspense>
)

export default TestScreen
