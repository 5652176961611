import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import useRouter from "../../../../../modules/navigation/useRouter";
import productModel from "../../../../../apis/model/product";
import DrawMenu from "../../../../../components/drawer/DrawMenu";
import {Avatar, Menu, Popconfirm, Tooltip} from "antd";
import {ProductSettingContainer} from "../../Product";
import Toggle from "../../../../../components/Toggle";
import {AiOutlineAreaChart, AiOutlineLineChart} from "react-icons/ai";
import {PriceAdvancedInputForm} from "../ManageProductPrices";
import {useToggle} from "react-use";
import {ProductPriceSetChart} from "../components/ProductPriceSetChart";
import {IoBarChartOutline, IoCogOutline, IoEllipsisHorizontalSharp, IoToggle} from "react-icons/io5";
import {FullSideModalLayout} from "../../../../../components/Modal/SideModalLayout";
import {UseHook} from "../../../../../components/UseHook";
import useDispatchAsyncActionWithNotify from "../../../../../modules/asyncCache/useDispatchAsyncActionWithNotify";
import {createAsyncAction} from "../../../../../modules/asyncCache";
import {create_product_update_Api} from "../../../../../apis";
import {BsBox2Fill, BsGear} from "react-icons/bs";
import classNames from 'classnames'
import {FaChartArea} from "react-icons/fa6";
import Count from "../../../../../components/Feed/Count";
import {MdOutlineToggleOff, MdToggleOn} from "react-icons/md";
import {BiSolidToggleRight, BiToggleLeft} from "react-icons/bi";


export const ContextMenu = ({item}) => {
  const t = useTranslate()
  const {
    handleGoBack,
    history
  } = useRouter();
  return (
    <DrawMenu
      container={"workspace_product_list"}
      component={
        <Count>
          <IoEllipsisHorizontalSharp size={12} className="text-color-400"/>
        </Count>
      }>
      {(isToggle, toggle) => (
        <Menu style={{ minWidth: 250 }}>
          <Menu.Item
            key="0"
            className="flex mt-2">
            <Toggle key={item.id}>
              {( isTogglePriceInput, togglePriceInput ) => (
                  <div className="w-full">
                    <div className="w-full flex items-center gap-3" onClick={togglePriceInput}>
                      <IoBarChartOutline />
                      <span>{t('price set history management')}</span>
                    </div>
                    {isTogglePriceInput && (
                        <FullSideModalLayout
                          right
                          onCancel={togglePriceInput}
                          maskClosable={false}
                          width={720}
                          title={
                            <div className="font-bold text-color-000">
                              {_.get(item, 'title')}
                            </div>
                          }
                          destroyOnClose
                          visible={isTogglePriceInput}
                          footer={null}>
                          <PriceAdvancedInputForm {...{item}} />
                        </FullSideModalLayout>
                    )}
                  </div>
                )
              }
            </Toggle>
          </Menu.Item>

          <Menu.Item
            key="2"
            className="flex mt-2">
            <Toggle>
              {( isToggleProductEdit, toggleProductEdit ) => (
                  <div className="w-full">
                    <div onClick={toggleProductEdit} className="w-full flex items-center gap-3">
                      <BsGear />
                      <span>{t('product configuration')}</span>
                    </div>
                    {isToggleProductEdit && (
                        <FullSideModalLayout
                          right
                          onCancel={toggleProductEdit}
                          maskClosable={false}
                          width={900}
                          title={
                            <div className="font-bold text-color-000">
                              {_.get(item, 'title')}
                            </div>
                          }
                          destroyOnClose
                          visible={isToggleProductEdit}
                          footer={null}>
                          <ProductSettingContainer id={_.get(item, 'idname')}/>
                        </FullSideModalLayout>
                    )}
                  </div>
                )
              }
            </Toggle>
          </Menu.Item>

          {!!_.get(item, 'active') && (
              <Menu.Item
                key="deactivate"
                className="flex mt-2">
                <UseHook hook={useDispatchAsyncActionWithNotify}>
                  {([{ success, isLoading }, dispatch ]) => (
                    <Popconfirm
                      title={
                        <div className="font-medium text-sm text-color-100 italic">
                          {`${t('deactivate')} ?`}
                        </div>
                      }
                      onConfirm={() => {
                        dispatch(
                          createAsyncAction(
                            {
                              apiInfo: create_product_update_Api(),
                              query: {
                                ':id':
                                item.id
                              },
                              values: {
                                active: 0
                              }
                            }
                          )
                        )
                      }}
                      okText={t('confirm')}
                      cancelText={t('no')}
                      okButtonProps={{
                        type: 'primary',
                        className: 'button-rounded-md no-border text-xs font-medium px-3',
                      }}
                      cancelButtonProps={{
                        className: 'button-rounded-md no-border text-xs font-medium px-3',
                      }}>
                      <div className="w-full flex items-center gap-3">
                        <BiToggleLeft />
                        <span>{t('deactivate')}</span>
                      </div>
                    </Popconfirm>
                  )}
                </UseHook>
              </Menu.Item>
          )}
          {!_.get(item, 'active') && (
              <Menu.Item
                key="activate"
                className="flex mt-2">
                <UseHook hook={useDispatchAsyncActionWithNotify}>
                  {([{ success, isLoading }, dispatch]) => (
                    <Popconfirm
                      title={
                        <div className="font-medium text-sm text-color-100 italic">
                          {`${t('activate')} ?`}
                        </div>
                      }
                      onConfirm={() => {
                        dispatch(
                          createAsyncAction(
                            {
                              apiInfo: create_product_update_Api(),
                              query: {
                                ':id':
                                item.id
                              },
                              values: {
                                active: 1
                              }
                            }
                          )
                        )
                      }}
                      okText={t('confirm')}
                      cancelText={t('no')}
                      okButtonProps={{
                        type: 'primary',
                        className: 'button-rounded-md no-border text-xs font-medium px-3',
                      }}
                      cancelButtonProps={{
                        className: 'button-rounded-md no-border text-xs font-medium px-3',
                      }}>
                      <div className="w-full flex items-center gap-3">
                        <BiSolidToggleRight />
                        <span>{t('activate')}</span>
                      </div>
                    </Popconfirm>
                  )}
                </UseHook>
              </Menu.Item>
          )}
        </Menu>
      )}
    </DrawMenu>
  )
}

export const ProductSaleSettingHeadlineView = ({item, rootPath, setUpdate}) => {
  const t = useTranslate();
  const isAdmin = !!_.get(item, 'owner.edit');
  const [isToggle, toggle] = useToggle();
  const [eventId, setEventId] = useState(Date.now());

  return (
    <div id={item.idname} className="rounded-lg background ml-3">
      {item && (
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2 border border-color-50 hover:shadow-items-md rounded-md relative">
            <Avatar
                size={45}
                className="flex flex-center rounded-l-md rounded-r-0 background-200"
                src={_.get(item, 'avatar')}
                icon={ <BsBox2Fill size={20} className="text-color-500"/> }
            />
            <div className="flex flex-col flex-1">
              <Link
                  className="font-bold text-sm text-color-000 no-underline"
                  to={productModel.getLinkToDetail(item)}>
                {item.title}
              </Link>
              {!item.hidden_price && (
                  <div className="space-x-6">
                    {!item.hidden_price && !!item.price && (
                        <div className="flex items-center gap-1 text-xs text-color-400 italic leading-tight">
                          <span className="font-medium text-color-300">{Number(item.price).toLocaleString()}</span>
                          <span className="font-medium text-color-300">{_.get(item, 'price_unit.name', t('price unit'))}</span>
                          {' / '}
                          <span>{_.get(item, 'unit.name', t('unit'))}</span>
                        </div>
                    )}
                    {!!item.product_line && (
                        <span className="font-medium text-xs text-orange-500 italic leading-tight">
                            {item.product_line}
                        </span>
                    )}
                  </div>
              )}
            </div>

            {isAdmin && (
                <div className="flex items-center gap-3 pr-1">
                  <Tooltip title={t('view chart')} overlayClassName="font-medium text-xs italic">
                    <AiOutlineAreaChart
                        size={18}
                        onClick={toggle}
                        className={classNames('cursor-pointer', isToggle ? "text-primary-600" : "text-color-400 hover:text-color-100")}
                    />
                  </Tooltip>
                  <ContextMenu item={item}/>
                </div>
            )}
          </div>

          {isToggle && (
              <div className="flex flex-col gap-3 p-2 border rounded-lg">
                <div className="font-bold text-xs text-color-400 uppercase">
                  {t('charts')}
                </div>
                <ProductPriceSetChart
                  product={item}
                  product_id={item.id}
                  optionByProduct={true}
                  eventId={eventId}
                  setEventId={setEventId}
                />
              </div>
          )}
        </div>
      )}
    </div>
  )
}

