import {Button, Form, Modal, Select} from 'antd'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'

export const UnitComponent = ({item, priceUnitField = 'price_unit', unitField = 'unit', placeHolder, children, onHandleSubmit}) => {
  const t = useTranslate();
  const {product_price_units = [], product_units = []} = useAppConfig();

  return (
    <div className="flex flex-row px-1 justify-center items-center">
      {
        placeHolder || (
          <div className="px-3 flex flex-col leading-none">
            <span>{_.get(item, priceUnitField + '.name', t('price unit'))}</span>
            <span> / {_.get(item, unitField + '.name', t('unit'))}</span>
          </div>
        )
      }

      {
        (!_.isEmpty(item, priceUnitField) || !_.isEmpty(item, unitField)) && (
          <Toggle>
            {(
              isToggle,
              toggle
            ) => (
              isToggle ? (
                <Modal
                  width={480}
                  className="custom-modal"
                  maskClosable={false}
                  destroyOnClose
                  footer={null}
                  icon={false}
                  closable={true}
                  onCancel={toggle}
                  title={(
                    <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                      {t('price unit')} / {t('unit')}
                    </div>
                  )}
                  visible={isToggle}>
                  <div className="w-full pt-6">
                    <Form className="space-y-3"
                      initialValues={
                        {
                          [priceUnitField + '_id']: _.get(item, priceUnitField + '.id'),
                          [unitField + '_id']: _.get(item, unitField + '.id')
                        }
                      }
                      onFinish={(values) => {
                        onHandleSubmit(values);
                      }}
                      {...{
                        labelCol: {span: 8},
                        wrapperCol: {span: 16},
                      }} >
                      <Form.Item
                        label={
                          <span className="text-sm text-color-400 italic">{`${t('price unit')} `}</span>
                        }
                        className="flex-1"
                        name={priceUnitField + '_id'}>
                        <Select
                          showSearch
                          optionFilterProp={"label"}
                          size="middle"
                          style={{minWidth: 90}}
                          className="flex-1 flex w-full"
                          placeholder={t('VND')}
                          onChange={value => {
                            // setPriceUnitId(value)
                          }}
                          options={product_price_units.map(
                            ({
                               id: value,
                               name: label
                             }) => ({
                              value,
                              label
                            })
                          )}
                        />
                      </Form.Item>

                      <Form.Item
                        label={
                          <span className="text-sm text-color-400 italic">{`${t('unit')} `}</span>
                        }
                        className="flex-1"
                        name={unitField + '_id'}>
                        <Select
                          showSearch
                          optionFilterProp={"label"}
                          size="middle"
                          style={{minWidth: 90}}
                          className="flex-1 flex w-full"
                          placeholder={t('unit')}
                          onChange={value => {
                            // setUnitId(value)
                          }}
                          options={product_units.map(
                            ({
                               id: value,
                               name: label
                             }) => ({
                              value,
                              label
                            })
                          )}
                        />
                      </Form.Item>
                      <div className="text-center mb-4">
                        <Button
                            type="primary"
                            className="button-rounded-md no-border">
                          {t('update')}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </Modal>
              ) : (
                <div onClick={() => { toggle() }}>
                  {children}
                </div>
              )
            )
            }
          </Toggle>
        )
      }

    </div>
  )
}


