import {Button, message} from 'antd'
import {organization_Edit_Api} from 'apis'
import {createAsyncAction} from 'modules/asyncCache'
import {getAsynCacheSelector} from 'modules/asyncCache/selectors'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {OrganizationSettingContext} from './OrganizationSettingContext'
import _ from "lodash";
import {convertFromString, convertToString, findNodes} from "../../SlateEditor/functions";
import {ElementTypes} from "../../SlateEditor/types";
import SlateEditor from "../../SlateEditor/SlateEditor";

function useAsyncAction() {
  const dispatch = useDispatch()
  const [
    asyncId,
    setAsyncId
  ] = useState()
  const data = useSelector(state =>
    getAsynCacheSelector(state, asyncId)
  )
  const handleAction = useCallback(
    ({
       prefixStr,
       values,
       apiInfo,
       query
     }) => {
      const action = createAsyncAction({
        prefixStr,
        values,
        apiInfo,
        query
      })
      setAsyncId(action.asyncId)
      dispatch(action)
    },
    [dispatch]
  )

  return {data, handleAction}
}

export function About({className}) {
  const t = useTranslate()
  const {item} = useContext(OrganizationSettingContext)
  const [editorState, setEditorState] = useState(() =>
    convertFromString(item?.about)
  )

  const {data, handleAction} = useAsyncAction()

  const imageSrcList = useMemo(
    () => findNodes(editorState, ElementTypes.IMAGE),
    [editorState]
  )

  const handleSubmit = (value) => {
    handleAction({
      prefixStr: 'update_about',
      values: {
        about: value,
        photo_ids_string: _.join(_.map(imageSrcList, 'imageId'), ','),
      },
      apiInfo: organization_Edit_Api,
      query: {
        ':id': item.id,
      },
    })
  }

  useEffect(() => {
    if (data.success) {
      message.success(t('update about success'))
    }
  }, [data.success, t])
  return (
      <div className="flex flex-col gap-6 relative">
          <SlateEditor
              name="about"
              defaultValue={editorState}
              onChange={(event) => {
                  setEditorState(event?.target?.value)
              }}
          />
          <div className="flex justify-end mx-3">
              <Button
                  key="submit"
                  type="primary"
                  className="button-rounded-md no-border"
                  loading={data.isLoading}
                  onClick={() => handleSubmit(convertToString(editorState))}>
                  {t('save')}
              </Button>
          </div>
      </div>
  )
}
