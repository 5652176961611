import React from 'react'
import createStickySider from 'views/AppLocals/factory/createStickySider'
import { AppEntities } from '../../enums'
import createStatusBar from '../../factory/createStatusBar'
import OrganizationContainerContext from '../OrganizationContainerContext'
import ForDevelop from "../../../../envs/ForDevelop";
import OrganizationInvitations from "../holders/OrganizationInvitations";
import {EventShareWidget, OrganizationShareWidget} from "../../factory/createShareWidget";
import Translate from "../../../../modules/local/Translate";

export const OrganizationStatusBar = createStatusBar(
  AppEntities.ORGANIZATION,
  OrganizationContainerContext
)

const OrganizationSticker = () => (
  <React.Fragment>
      <OrganizationStatusBar />
      <OrganizationInvitations />
      <div className="h-3" />
      <OrganizationShareWidget
          className="gap-2"
          header={
              <Translate>
                  {(t) => (
                      <span className="text-sm text-color-400 italic">
                          {`${t('share')} : `}
                      </span>
                  )}
              </Translate>
          }
      />
  </React.Fragment>
)

const OrganizationSider = ({ Wrapper = 'div', stickySider = true }) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(OrganizationSticker)

export default OrganizationSider
