import {Avatar} from 'antd'
import {channel_fetchContainerByOwner_Api} from 'apis'
import {getId, getType} from 'apis/model/base'
import classNames from 'classnames'
import AppIcon from 'components/AppIcon'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {RiUserFill} from 'react-icons/ri'
import {nest, withProps} from 'recompose'
import {DelayRender} from 'views/Discovery/DelayRender'
import {OrgType} from 'views/AppLocals/Organization/components/OrgType'
import {getResponseItems} from 'views/Shared'
import PostContainer from '../Post/PostContainer'
import {FaWarehouse} from "react-icons/fa6";

export const InputBoardContext = React.createContext({container: {}})

export const InputBoardProvider = nest(
  withProps({
    lazy: true,
  })(DelayRender),
  ({children, container, ...props}) => {
    const t = useTranslate()

    const [current, setCurrent] = useState({container: undefined})

    const {id, type} = container ?? {}

    const {response, isLoading, handleAsyncAction} = useAsyncAction({
      apiInfo: channel_fetchContainerByOwner_Api,
    })

    useEffect(() => {
      const entity = current?.container

      if (entity) {
        handleAsyncAction({
          id: getId(entity),
          type: getType(entity),
        })
      } else if (id && type) {
        handleAsyncAction({id, type})
      } else {
        handleAsyncAction()
      }
    }, [id, type, current])

    const renderLabel = useCallback(
      (item) => {
        let title = getTitle(item)
        let target = item
        return (
          <div className="flex gap-2 items-center">
            <AppIcon
              item={target}
              size={'small'}
            />
            <div className="truncate text-sm font-medium">{title}</div>
          </div>
        )
      },
      [t, type]
    )

    const options = useMemo(() => {
      const array = Array.from(getResponseItems(response))
      return array.map((option) => {
        const {creators, owner, ...data} = option ?? {}
        return {
          creators,
          data: option,
          id: getId(data),
          value: getId(data),
          type: getType(data),
          owner_id: getId(owner),
          label: renderLabel(data, owner),
          is_default: data?.is_default ?? 0,
        }
      })
    }, [type, response, renderLabel])

    const getCreators = useCallback(
      (parent) => {
        const source = Array.from(options)
        const {creators: array = []} =
        source.find(({id}) => _.isEqual(id, parent)) ?? {}

        return array.map((option) => {
          const {owner} = option ?? {}
          return {
            data: option,
            id: getId(owner),
            value: getId(owner),
            type: getType(owner),
            label: (
              <div className="p-1 flex gap-2 items-center">
                <Avatar
                  size={30}
                  icon={
                    getType(owner) === 'organization' ? (
                      <FaWarehouse className="text-color-500"/>
                    ) : (
                      <RiUserFill className="text-color-500"/>
                    )
                  }
                  src={_.get(owner, 'avatar')}
                  className={classNames(
                    'flex flex-center background-200',
                    getType(owner) === 'organization'
                      ? 'rounded-md'
                      : 'rounded-full'
                  )}
                />
                <div
                  className="flex flex-col"
                  style={{
                    lineHeight: '1rem',
                  }}>
                  <div className="truncate text-xs text-primary font-bold">
                    {getTitle(owner)}
                  </div>
                  <div className="text-2xs italic text-color-400">
                    {getType(owner) === 'organization' ? (
                      <OrgType item={owner}/>
                    ) : (
                      t(getType(owner))
                    )}
                  </div>
                </div>
              </div>
            ),
          }
        })
      },
      [options]
    )

    return (
        <InputBoardContext.Provider
            value={{
                current,
                options,
                container,
                isLoading,
                setCurrent,
                getCreators,
                ...props,
            }}>
            <PostContainer {...{id, type}}>{children}</PostContainer>
        </InputBoardContext.Provider>
    )
  }
)
