import { List } from 'antd'
import BaseItemLayout from 'components/ItemLayout/BaseItemLayout'
import Toggle from 'components/Toggle'
import _ from "lodash"
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { mapProps, nest, withProps } from 'recompose'
import ServerContext from 'views/Server/ServerContext'
import { FullSideModalLayout } from "../../../components/Modal/SideModalLayout"
import Translate from "../../../modules/local/Translate"
import ProductCreateForm from "../../Market/Seller/ProductCreateForm"
import ExportPriceForm from '../Product/components/ExportPriceForm'
import { EditAbleBlockHolder } from './EditAbleBlockHolder'

export const CreateProductModal = nest(
  withProps({
    right: true,
    footer: null,
    maskClosable: false,
    width: 720,
    title: (
      <Translate>
        {
          t => (
            <div className="font-bold text-color-000">
                {t('create product')}
            </div>
          )
        }
      </Translate>
    ),
    destroyOnClose: true
  })(FullSideModalLayout),
  ProductCreateForm
)

export const ExportPriceModal = nest(
  mapProps(({ asyncConfigs, ...props }) => {
    return {
      right: true,
      footer: null,
      maskClosable: false,
      width: 720,
      destroyOnClose: true,
      ...props,
    }
  })(FullSideModalLayout),
  ExportPriceForm
)

export function Products() {
  const t = useTranslate()
  return (
    <ServerContext.Consumer>
      {({
        item,
        getProductChannelUrl
      }) => {
        return (
          <div
            className="background-200 p-4"
            style={{
              borderRadius: '0.3em',
              width: '100%',
              overflowWrap:
                'break-word',
              overflow: 'hidden'
            }}>
            <div className="flex w-full flex flex-col  font-semibold text-color-300">
              <h2 className="text-lg  font-bold header mt-1 mb-6  ">
                {t('products')}
              </h2>

              <List
                grid={{
                  gutter: 36,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 2,
                  xl: 2,
                  xxl: 2
                }}
                dataSource={[
                  <BaseItemLayout />,
                  ..._.get(item, 'products', []).map(
                    ({
                      avatar,
                      title,
                      description,
                      id
                    }) => (
                      <BaseItemLayout
                        {...{
                          avatar,
                          title,
                          description
                        }}
                        to={getProductChannelUrl(
                          id
                        )}
                      />
                    )
                  )
                ]}
                renderItem={item => (
                  <List.Item
                    style={{
                      height: '150px'
                    }}>
                    {item}
                  </List.Item>
                )}
              />
            </div>
            <Toggle>
              {(isToggle, toggle) => (
                <>
                  <EditAbleBlockHolder
                    onClick={toggle}
                    title={t(
                      'create product'
                    )}
                    description={t(
                      `i take some issue with how they've defined "rewrite" in there.`
                    )}
                  />
                  {isToggle && (
                    <CreateProductModal
                      right
                      onCancel={toggle}
                      visible={isToggle}
                    />
                  )}
                </>
              )}
            </Toggle>
          </div>
        )
      }}
    </ServerContext.Consumer>
  )
}

export default Products
