import {Button} from 'antd'
import {getId, getType} from 'apis/model/base'
import classNames from 'classnames'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useState} from 'react'
import {IoHeart, IoHeartOutline} from 'react-icons/io5'
import {RxEnter} from 'react-icons/rx'
import {useJoin, useVote} from 'views/Server/ServerContainer'
import {useFollow} from 'views/Server/useFollow'
import {Null, renderOwnChild} from 'views/Shared'
import {RiHeartFill, RiHeartLine, RiRssFill} from "react-icons/ri";

const ToggleActionButton = React.forwardRef(
  (
    {
      isToggle,
      active = {
        label: 'active',
        icon: <IoHeart/>,
        type: 'default',
        className: 'text-color-400 no-border',
        params: {},
      },
      inactive = {
        label: 'inactive',
        icon: <IoHeartOutline/>,
        type: 'primary',
          className: 'no-border',
        params: {},
      },
      onClick = Null,
      useDebounce = true,
      ...props
    },
    ref
  ) => {
    const t = useTranslate()

    const [isLoading, setLoading] = useState(false)

    const debounce = _.debounce(() => {
      onClick()
      setLoading(false)
    }, 200)

    const {icon, label, type, className, params} = isToggle ? active : inactive

    return (
      <Button
        ref={ref}
        block={true}
        icon={icon}
        type={type}
        disabled={isLoading}
        className={classNames("button-rounded-lg flex flex-center gap-2 capitalize", className)}
        {...params}
        {...props}
        onClick={
          useDebounce
            ? () => {
              setLoading(true)
              debounce()
            }
            : onClick
        }>
        {label ? t(label) : null}
      </Button>
    )
  }
)

export const UseVoteHandler = ({children, ...props}) => {
  const action = useVote({...props}) || Null
  return _.isFunction(children) ? children(action) : children
}

export const UseFollowHandler = ({children, ...props}) => {
  const action = useFollow({...props}) || Null
  return _.isFunction(children) ? children(action) : children
}

export const VoteButton = ({
                                item,
                                owner_id: id,
                                owner_type: type,
                                vote_status: status,
                                Wrapper = renderOwnChild,
                                Component = ToggleActionButton,
                              }) => {
  const owner_id = getId(item) || id

  const owner_type = getType(item) || type

  const vote_status = _.get(item, 'vote_status') || status;

  console.log(1111111111, vote_status, item)

  if (_.isEmpty(owner_id) || _.isEmpty(owner_type)) {
    return null
  }
  return (
    <UseVoteHandler
      {...{
        owner_id,
        owner_type,
        vote_status,
      }}>
      {(handleVote = Null) => (
        <Wrapper>
          <Component
            isToggle={vote_status}
            active={{
                label: 'voted',
                icon: <RiHeartFill/>,
                type: 'default',
                className: 'text-color-400 no-border'
                // params: { ghost: true },
            }}
            inactive={{
                label: 'vote',
                icon: <RiHeartLine/>,
                type: 'primary',
                className: 'no-border',
            }}
            onClick={() => handleVote()}
          />
        </Wrapper>
      )}
    </UseVoteHandler>
  )
}

export const FollowButton = ({
                                  item,
                                  owner_id: id,
                                  owner_type: type,
                                  follow_status: status,
                                  Wrapper = renderOwnChild,
                                  Component = ToggleActionButton,
                                }) => {
  const owner_id = getId(item) || id

  const owner_type = getType(item) || type

  const follow_status = _.get(item, 'follow_status') || status

  if (_.isEmpty(owner_id) || _.isEmpty(owner_type)) {
    return null
  }
  return (
    <Wrapper>
      <UseFollowHandler
        {...{
          owner_id,
          owner_type,
          follow_status,
        }}>
        {(handleFollow = Null) => (
          <Component
            isToggle={follow_status}
            active={{
                label: 'following',
                icon: <RiRssFill/>,
                type: 'default',
                className: 'text-color-400 no-border',
                // params: { ghost: true},
            }}
            inactive={{
                label: 'follow',
                icon: <RiRssFill/>,
                type: 'primary',
                className: 'no-border',
            }}
            onClick={() => handleFollow()}
          />
        )}
      </UseFollowHandler>
    </Wrapper>
  )
}

export const JoinButton = ({
                             id,
                             type,
                             item,
                             style,
                             className,
                             icon = <RxEnter/>,
                             ...props
                           }) => {
  const t = useTranslate()

  const status = item?.joined_status

  const params = item
    ? {
      id,
      type,
      status,
    }
    : {}
  const handleJoin = useJoin(params)
  return (
    <Button
      icon={icon}
      size="small"
      type="primary"
      style={style}
      onClick={() => {
        handleJoin()
      }}
      className={classNames(
        'button-rounded-full flex flex-center text-xs px-2 gap-1',
        className
      )}
      {...props}>
      {status ? t('leave') : t('join')}
    </Button>
  )
}

export default ToggleActionButton
