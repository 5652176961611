import classNames from 'classnames'
import React, {PureComponent} from 'react'
import ReactDOM from 'react-dom'

export class CaptureWindowScrollEvent extends PureComponent {
  componentDidMount = () => {
    this._onEvent()
  }

  componentWillUnmount = () => {
    this._offEvent()
  }

  componentDidUpdate = () => {
    this._handleSpy()
  }

  render() {
    return null
  }

  _handleSpy = (event) => {
    this.props.onWindowScroll(event)
  }
  _offEvent = () => {
    try {
      const el = document
      el.removeEventListener('scroll', this._handleSpy)
    } catch (error) {
    }
  }

  _onEvent = () => {
    try {
      const el = document
      el.addEventListener('scroll', this._handleSpy)
    } catch (error) {
    }
  }
}

export class SyncScrollWindow extends PureComponent {
  style = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  }

  render() {
    const {children} = this.props

    return (
      <div
        ref={this._bindWrap}
        style={this.style}>
        <CaptureWindowScrollEvent onWindowScroll={this._handleOnWindowScroll}/>
        {children}
      </div>
    )
  }

  _bindWrap = (node) => {
    if (node) {
      this.wrap = node
    }
  }
  _handleOnWindowScroll = (event) => {
    requestAnimationFrame(() => {
      if (this.wrap) {
        this.wrap.scrollTo({
          top: window.pageYOffset,
          behavior: this.props.smooth ? 'smooth' : 'auto',
        })
      }
    })
  }
}

export class FlyWrapper extends PureComponent {
  state = {
    atBottom: false,
  }
  componentDidMount = () => {
    this.offsetTop = this.props.top === 0 ? 0 : this.props.top || 58
    this.offsetBottom = 0
  }

  render() {
    const {children, className, disabled = false} = this.props

    if (!!disabled)
      return <div className={classNames('', className)}>{children}</div>

    return (
      <div
        ref={this._bindWrap}
        className={classNames(className, 'relative')}>
        <CaptureWindowScrollEvent onWindowScroll={this._handleOnWindowScroll}/>
        <div ref={this._bindRef}>{children}</div>
      </div>
    )
  }

  _bindRef = (node) => {
    if (node) {
      this.fly = node
      node.style.position = `relative`
      node.style.width = `unset`
      const dom = ReactDOM.findDOMNode(node)
      if (dom) {
        const rect = dom.getBoundingClientRect()
        // this.offsetTop = rect.top
        this.width = rect.width
      }
    }
  }
  _bindWrap = (node) => {
    if (node) {
      this.wrap = node
      node.style.height = `unset`
    }
  }
  _calcRect = (rect, windowInnerHeight) => {
    return windowInnerHeight - (rect.height + rect.top)
  }
  _handleOnWindowScroll = (event) => {
    if (this.props.onWindowScroll) {
      this.props.onWindowScroll(event)
    }
    // if (this.fly) {
    // 	const dom = ReactDOM.findDOMNode(this.fly)
    // 	if (dom) {
    // 		const rect = dom.getBoundingClientRect()
    // 		this.offsetTop = rect.top
    // 	}
    // }
    const dom = ReactDOM.findDOMNode(this)
    if (dom) {
      const rect = dom.getBoundingClientRect()
      let marginTop = 0
      const realHeight = this.fly.clientHeight + this.offsetTop
      if (realHeight > window.innerHeight) {
        if (-rect.top - (realHeight - window.innerHeight) > 0) {
          // console.log(
          // 	`%c-rect.top - (realHeight - window.innerHeight`,
          // 	'background: green; color: white; display: block;',
          // 	-rect.top - (realHeight - window.innerHeight)
          // )
          this.wrap.style.height = `${realHeight}px`
          this.fly.style.width = `${this.fly.clientWidth}px`
          this.fly.style.position = 'fixed'
          this.fly.style.top = `${window.innerHeight - realHeight}px`
        } else {
          // console.log(
          // 	`%c-rect.top - (realHeight - window.innerHeight`,
          // 	'background: green; color: white; display: block;',
          // 	-rect.top - (realHeight - window.innerHeight)
          // )

          if (this.fly.style.position === 'relative') return
          this.fly.style.position = 'relative'
          this.fly.style.top = `0px`
          this.wrap.style.height = `unset`
        }
      } else {
        if (rect.top - this.offsetTop <= 0) {
          this.fly.style.position = 'fixed'
          this.fly.style.width = `${this.width}px`
          this.fly.style.top = `${this.offsetTop}px`
          this.wrap.style.height = `${realHeight}px`
        } else {
          // console.log(
          // 	`%c-rect.top - (realHeight - window.innerHeight`,
          // 	'background: green; color: white; display: block;',
          // 	-rect.top - (realHeight - window.innerHeight)
          // )

          if (this.fly.style.position === 'relative') return
          this.fly.style.position = 'relative'
          this.fly.style.top = `0px`
          this.wrap.style.height = `unset`
        }
      }
    }
  }
  // _boudedHandleOnWindowScroll =_.debounce(this._handleOnWindowScroll,20)
}

export default FlyWrapper
