import {Avatar, Input} from 'antd'
import {SelectEntityItem} from 'components/SelectEntityItem'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useState} from 'react'
import {partners_getPriceProviders_Api} from "../../../../apis/organizationPartner";
import {LazyPagination} from "../../../../modules/asyncCache/components/LazyPagination";
import {organizationSchema} from "../../../../apis/schema";
import ServerContext from "../../../Server/ServerContext";
import AppIcon from "../../../../components/AppIcon";
import {Link} from "react-router-dom";
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {FaWarehouse} from "react-icons/fa6";
import {OrgType} from "../../../AppLocals/Organization/components/OrgType";
import getAvatar from "../../../../helpers/getAvatar";

const Item = ({e}) => {
  const t = useTranslate();

  return (
    <div className="w-full flex items-center gap-3 border border-color-50 hover:shadow-items rounded-lg">
        <Avatar
            size={60}
            className="flex flex-center background-200 rounded-l-lg rounded-r-0"
            src={getAvatar(e)}
            icon={ <FaWarehouse className="text-color-500"/> }
        />
        <div className="flex flex-col flex-1">
            <Link to={getLinkToDetail(e)} className="font-bold text-color-000 no-underline">
                {_.get(e, 'title')}
            </Link>
            <OrgType item={e} className="text-xs text-color-500 italic leading-tight"/>
        </div>
    </div>
  )
}

const PartnerProductProvider = ({type}) => {
  const t = useTranslate();
  const {item} = useContext(ServerContext)

  const [keyword, setKeyword] = useState("");

  return (
    <div className="flex flex-col gap-3">
        <Input.Search
            className="w-full"
            placeholder={t('keyword: name, tax number, phone number, email')}
            allowClear
            onSearch={(e) => {setKeyword(e)}}
        />
      <div key={keyword} className="flex flex-col space-y-3">
        <LazyPagination
          prefixStr={`Partners{created:${keyword}}`}
          apiInfo={partners_getPriceProviders_Api}
          query={{ ':id': item.idname }}
          values={{ keyword }}
          renderItem={(e) => (
            <SelectEntityItem key={e.idname} item={e.idname} schema={organizationSchema}>
              {(e) => <Item e={e}/> }
            </SelectEntityItem>
          )}
        />
      </div>
    </div>
  )
}

export default PartnerProductProvider
