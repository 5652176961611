import {Skeleton} from 'antd'
import chartSize from 'conts/chartSize'
import viewModes from 'conts/viewModes'
import _ from 'lodash'
import React from 'react'
import {decrypt} from './dataHelpers'

const mapGoogleTable = ({
  theme,
  chart,
  size = chartSize.NORMAL,
  viewmode = viewModes.BLOCK
}) => {
  let params = chart.params
  // console.log({ params })
  let chartParams = _.cloneDeep(params)
  // if (
  //   !chartParams.options.height ||
  //   chartParams.options.height ===
  //     'auto'
  // ) {
  //   chartParams.options.height =
  //     viewmode === viewModes.FULLSCREEN
  //       ? window.screen.height - 280
  //       : Math.floor(
  //           window.screen.height * 0.42
  //         )
  // }

  chartParams.options.cssClassNames = {
    ..._.get(
      chartParams,
      'options.cssClassNames',
      {}
    ),
    headerRow: 'headerRow',
    tableRow: 'tableRow',
    oddTableRow: 'oddTableRow',
    selectedTableRow:
      'selectedTableRow',
    // hoverTableRow: 'hoverTableRow',
    headerCell: 'headerCell',
    tableCell: 'tableCell',
    rowNumberCell: 'rowNumberCell'
  }
  let chartConfig = {
    chartType: chart.chart_type,
    loader: <Skeleton />,
    ...chartParams,
    rootProps: {
      'data-google-id': chart.id
    }
  }

  const data_values =
    Number(chart.data_encrypted) !== 1
      ? chart.data_values
      : decrypt(
          chart.data_values,
          chart.data_passphrase
        )

  if (
    _.has(chart, 'spreadsheet_url') &&
    _.has(
      chart,
      'spreadsheet_query_params'
    )
  ) {
    chartConfig.spreadSheetUrl = _.get(
      chart,
      'spreadsheet_url'
    )
    chartConfig.spreadSheetQueryParameters = _.get(
      chart,
      'spreadsheet_query_params'
    )
  } else if (
    _.has(chart, 'data_columns') &&
    !_.isEmpty(chart.data_columns) &&
    _.has(chart, 'data_values')
  ) {
    chartConfig.data = [
      chart.data_columns,
      ...data_values
    ]
  } else {
    chartConfig = {
      chartType: 'TreeMap',
      loader: <Skeleton />,
      data: [
        //give a datatable with string 'No Data' to display
        ['', '', ''],
        ['no data', null, 100]
      ],
      options: {
        width: '100%',
        height: '100%'
      },
      rootProps: {
        'data-google-id': chart.id
      }
    }
  }

  return chartConfig
}
export default mapGoogleTable
