import { Select } from 'antd';
import React from 'react';
import { createValue } from './utils';
export const CustomSelect = ({ onChange, ...props }) => {
  return (
    <Select
      {...props}
      onChange={value => onChange(createValue(props.name, value))}
    />
  );
};
