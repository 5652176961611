import _ from 'lodash'
import {Null, renderSelf} from 'views/Shared'
import * as Yup from 'yup'
import {AppEntities} from '../enums'
import {organizationValidationSchema} from '../Organization/organizationSchemas'

export const requiredString = (t = renderSelf, message = 'required field') =>
  Yup.string().required(t(message))

export const requiredNumber = (t = renderSelf, message = 'required field') =>
  Yup.number().nullable().required(t(message))

export const yupMoreThan = (
  t = renderSelf,
  message = 'required field',
  target = 0
) => Yup.number().nullable().moreThan(target, t(message))

export const requiredEmail = (
  t = renderSelf,
  message = 'invalid email address'
) => Yup.string().nullable().email(t(message))

export const requiredOneOf = (array = [], t = renderSelf, yup = Yup.string()) =>
  yup.oneOf(array, t('invalid')).required(t('required field'))

export const yupShape = (params) => Yup.object().shape(params)

const createValidationSchema = (type, action) => (translate) => {
  let fn = () => {
  }
  switch (type) {
    case AppEntities.ORGANIZATION:
      fn = _.get(organizationValidationSchema, action, Null)
      break
    default:
      break
  }
  return Yup.object().shape(fn(translate))
}

export default createValidationSchema
