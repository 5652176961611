import { Input } from 'antd'
import React, {useContext, useState} from 'react'
import {branch, compose, fromRenderProps, mapProps, renderNothing} from "recompose";
import organizationModel, {getId, getType} from "../../../../apis/model/base";
import OrganizationContainerContext from 'views/AppLocals/Organization/OrganizationContainerContext'
import {OrganizationContext} from "../../../Organization/OrganizationContext";
import ServerContext from "../../../Server/ServerContext";
import useAsyncWithCache from "../../../../modules/asyncCache/useAsyncWithCache";
import {create_product_getProducts_Api} from "../../../../apis";
import {API_ROOT_URL} from "../../../../envs/_current/config";
import useTranslate from "../../../../modules/local/useTranslate";
import EmptyHolder from "../../../../components/EmptyHolder";
import {ProductPublicView} from "../../../Organization/Product/components/ProductPublicView";
import {useHistory} from "../../../../modules/navigation/useRouter";
import _ from "lodash"

export function OrganizationProducts({view = "block"}) {
  const history = useHistory()
  const {
    groups = [],
    organization
  } = useContext(OrganizationContext)
  const {
    item,
    handleActionClick,
    contextMenu
  } = useContext(ServerContext)
  const oId = organizationModel.getId(
    organization
  )
  useAsyncWithCache({
    cacheId: `create_product_getProducts_Api[organization,${oId}]`,
    apiInfo: create_product_getProducts_Api(),
    query: {
      ':type': 'organization',
      ':id': oId
    }
  })
  useAsyncWithCache({
    cacheId: `/products/get-price-periods?day=1`,
    apiInfo: {
      root: API_ROOT_URL,
      path: `/products/get-price-periods?day=1`,
      method: 'GET'
    }
  })
  const t = useTranslate()
  const [filter, setFilter] = useState();

  return (
    <div className="mt-6">

      {/*<div className="flex items-center">*/}
      {/*  <Breadcrumb className="text-sm text-color-400 italic">*/}
      {/*    <Breadcrumb.Item>*/}
      {/*      {organizationModel.getTitle(organization)}*/}
      {/*    </Breadcrumb.Item>*/}
      {/*    <Breadcrumb.Item>*/}
      {/*      {t('products')}*/}
      {/*    </Breadcrumb.Item>*/}
      {/*  </Breadcrumb>*/}
      {/*</div>*/}

      <div className="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-3">
        <section className="hidden lg:block w-full">
          <div className="font-semibold text-sm text-color-400 uppercase tracking-wide mb-2">
            {t('product groups')}
          </div>
          <div className="w-full flex flex-row md:flex-col flex-1 gap-2 sticky top-0 overflow-auto">
            <Input.Search
              className="block px-2"
              placeholder={t('type product name')}
              allowClear
              onSearch={value => {setFilter(value)}}
            />
            <div className="px-2 flex flex-col gap-4">
              {_.isEmpty(groups) && (
                  <EmptyHolder />
              )}
              {groups.map((o, i) => (
                <div key={i} className="flex flex-col gap-1">
                  <a
                    id={o.title}
                    href={'#' + o.title}
                    className="font-bold text-color-000 hover:text-primary-600 cursor-pointer no-underline">
                    {o.title}
                  </a>
                  <div className="ml-1 flex flex-col gap-1">
                    {o.products.map((p, i) => (
                        <a
                            // id={p.idname}
                            key={'#' + p.idname}
                            href={'#' + p.idname}
                            className="flex items-center text-color-300 italic no-underline max-lines-1">
                          {' • '}
                          {p.title}
                        </a>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <ProductPublicView view={view}/>
      </div>
    </div>
  )
}


export default compose(
  fromRenderProps(
    OrganizationContainerContext.Consumer,
    ({organization}) => ({organization})
  ),
  branch(({organization}) => _.isEmpty(organization), renderNothing),
  mapProps(({organization}) => ({
    owner_id: getId(organization),
    owner_type: getType(organization),
  }))
)(OrganizationProducts)
