import {Spin} from 'antd'
import _ from 'lodash'
import React, {Component} from 'react'
import ContentEditable from 'react-contenteditable'
import * as ReactDOM from 'react-dom'
import Translate from '../../../modules/local/Translate'
import {createValue} from '../../form/utils'
import {LayoutContext} from '../../layouts/Default/LayoutContext'
import CaretPositioning from './EditCaretPositioning'

class ContentEditableInput extends Component {
  constructor(props) {
    super(props)
    let html = this.transformText2Html(this.props.value)
    const currentValueWithoutEncode = this.stripHTMLWithoutEncode(html);
    if (html.endsWith('>')) {
      html += ' ';
    }

    this.state = {
      isLoading: false,
      value: html,
      //stores positions(s) of caret to handle reload after onChange end
      caretPosition: {
        start: currentValueWithoutEncode?.length,
        end: currentValueWithoutEncode?.length,
      },
    }
    this.debouncedHandleInputChange = _.debounce(this.onChangeHandler, 150)
    this.contentEditableRef = React.createRef()
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.value !== this.props.value) return true
    if (nextState.value !== this.state.value) return true
    if (nextState.caretPosition !== this.state.caretPosition) return true
    if (nextState.isLoading !== this.state.isLoading) return true
    // Logic kiểm tra và trả về true hoặc false
    return false
  }

  componentDidUpdate(prevProps, prevState) {
    // Kiểm tra nếu props thay đổi
    if (this.props.value !== prevProps.value) {
      if (!this.state.isLoading) {
        this.setState({ isLoading: true })

        const html = this.transformText2Html(this.props.value)
        const value = this.stripHTMLWithoutEncode(html)

        // Cập nhật state dựa trên props mới
        this.setState(
          {
            value: html,
            isLoading: false,
          },
          () => {
            // console.log(
            //   1,
            //   'CaretPositioning.restoreSelection',
            //   this.state.caretPosition
            // )
            CaretPositioning.restoreSelection(
              ReactDOM.findDOMNode(this.contentEditableRef.current),
              {
                start: this.state.caretPosition?.start,
                end: this.state.caretPosition?.end,
              }
            )
            this.props.onSelect({
              target: {
                selectionStart: this.state.caretPosition?.start,
                selectionEnd: this.state.caretPosition?.end,
                value: value,
              },
            })
          }
        )
      }
    } else if (this.state.caretPosition !== prevState.caretPosition) {
      if (this.state.caretPosition?.start !== this.state.caretPosition?.end)
      CaretPositioning.restoreSelection(
        ReactDOM.findDOMNode(this.contentEditableRef.current),
        {
          start: this.state.caretPosition?.start,
          end: this.state.caretPosition?.end,
        }
      )

    }
  }

  stripHTML = (html) => {
    // Chuyển HTML thành đối tượng DOM để trích xuất nội dung
    const doc = new DOMParser().parseFromString(html, 'text/html')

    // // Lấy tất cả các thẻ <div> trong HTML: vì phần này mà nó nhãy chữ khi xuống hàng. do chèn thêm 1 ký tự trong khi vị trí chưa ghi nhận đổi
    // const divElements = doc.body.querySelectorAll('div');
    // Array.from(divElements).forEach((div, index) => {
    //   div.textContent = '\n' + div.textContent;
    // });

    // Lấy tất cả các thẻ <span> trong HTML
    const spanElements = doc.body.querySelectorAll('span')

    // Mã hóa nội dung textContent của mỗi thẻ <span>
    const encodedContentArray = Array.from(spanElements).map((span) => {
      return span.dataset.lexicalOwner + encodeURI(span.textContent)
    })

    // Thay thế nội dung textContent trong mỗi thẻ <span> bằng URL-encoded string
    Array.from(spanElements).forEach((span, index) => {
      span.textContent = encodedContentArray[index]
    })
    // Trả về HTML đã được xử lý

    return doc.body.textContent
  }

  stripHTMLWithoutEncode = (html) => {
    // Chuyển HTML thành đối tượng DOM để trích xuất nội dung
    const doc = new DOMParser().parseFromString(html, 'text/html')

    // Trả về HTML đã được xử lý
    return doc.body.textContent
  }

  transformText2Html = (text) => {
    if (!!!text) return ''
    const originalString = this.stripHTML(text)
    const wordArray = originalString.split(' ')
    // Xử lý từng phần tử trong mảng
    const processedWords = wordArray.map((word) => {
      if (word.startsWith('@') && word.includes(':')) {
        const phraseArray = word.split(':')
        // const type = phraseArray.shift();
        // const id = phraseArray.shift();
        const content = phraseArray.pop()
        // const owner = `@${type}:${id}:`
        const owner = phraseArray.join(':') + ':'
        // console.log(1, 'phraseArray', {phraseArray, word, content, owner})

        let wordHtml =
          '<span ' +
          'data-lexical-text="true"  ' +
          'data-lexical-owner="' +
          owner +
          '" ' +
          'style="font-weight: 500; color: var(--color-primary);">' +
          decodeURI(content) +
          '</span>'

        return wordHtml
      }
      return word
    })

    return processedWords.join(' ')
  }

  onChangeHandler = (event) => {
    // if (event?.type === 'input') {
      // Thực hiện xử lý format,
      //save caret position(s), so can restore when component reloads
      let savedCaretPosition = CaretPositioning.saveSelection(
        event.currentTarget
      )
      const newValue = this.stripHTML(event.currentTarget.innerHTML)
      const html = this.transformText2Html(newValue)
      // ReactDOM.findDOMNode(this.contentEditableRef.current)
      this.setState(
        {
          value: html,
          caretPosition: savedCaretPosition,
          // isLoading: false
        },
        () => {
          this.props.onChange(createValue(this.props.name, newValue))
        }
      )
    // }
  }

  render() {
    const {
      name,
      className,
      onBlur,
      onChange,
      onKeyDown,
      onPaste,
      placeholder,
      style,
      rows,
    } = this.props

    return (
      <Spin
        spinning={this.state.isLoading}
        wrapperClassName="w-full">
        <LayoutContext.Consumer>
          {({ isSm }) => (
            <Translate>
              {(t) => {
                return (
                  <ContentEditable
                    {...{
                      name,
                      className,
                      onBlur,
                      onChange,
                      onKeyDown,
                      onPaste,
                      style,
                      rows,
                    }}
                    placeholder={placeholder}
                    disabled={this.state.isLoading}
                    ref={this.contentEditableRef}
                    html={this.state.value}
                    onChange={this.debouncedHandleInputChange}
                    onKeyDown={(event) => {
                      const currentValue = this.stripHTML(this.state.value); // lấy ra giá trị hiện tại. so sánh với prop xem đã update chưa, mói cho phép truyền ra ngoài giá trị để gửi.
                      const currentValueWithoutEncode = this.stripHTMLWithoutEncode(this.state.value); // lấy ra giá trị hiện tại. so sánh với prop xem đã update chưa, mói cho phép truyền ra ngoài giá trị để gửi.
                      if (event.key === ' ') {
                        if (currentValueWithoutEncode.length === this.state.caretPosition.start) {
                          if (this.state?.value?.endsWith('>')) {
                            this.setState({value: this.state.value + ' ',
                              caretPosition: {start: this.state.caretPosition.start + 1, end: this.state.caretPosition.end + 1}})
                          }
                        }
                      }
                      onKeyDown(event, {
                        value: currentValue,
                      })

                    }}
                    onPaste={(event) => {
                      // Ngăn chặn hành động mặc định của sự kiện paste
                      event.preventDefault()
                      onPaste(event)

                      // Lấy dữ liệu được dán
                      var clipboardData =
                        event.clipboardData || window.clipboardData
                      var pastedText = clipboardData.getData('text')

                      // Xử lý dữ liệu đã dán (ví dụ: chuyển đổi thành chữ in hoa)
                      var modifiedText = pastedText.toString()

                      // Thêm văn bản đã sửa đổi vào vị trí con trỏ hiện tại
                      document.execCommand('insertText', false, modifiedText)
                      const newValue = this.stripHTML(
                        event.currentTarget.innerHTML
                      )
                      this.props.onChange(
                        createValue(this.props.name, newValue)
                      )
                    }}
                    tagName="div"
                    role="textbox"
                    style={{
                      border: 'none !important',
                      userSelect: 'text',
                      wordBreak: 'break-word',
                      ...style
                    }}
                  />
                )
              }}
            </Translate>
          )}
        </LayoutContext.Consumer>
      </Spin>
    )
  }
}

export default ContentEditableInput
