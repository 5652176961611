import { baseModel } from 'apis/model'
import React from 'react'
import PageLink from './PageLink'

const ProductLink = ({ item, ...props }) => {
  return (
    <PageLink
      to={
        '/product/' +
        baseModel.getId(item)
      } {...props}>
    </PageLink>
  )
}

export default ProductLink
