import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../../../LoadingPage";

const PartnerLazy = Loadable({
  loader: () => import('./Partner'),
  loading: LoadingPage
})

const Partner = (props) => (
  <PartnerLazy {...props} />
)

export default Partner
