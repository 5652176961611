import Icon from '@ant-design/icons'
import {Select} from 'antd'
import filterOption from 'helpers/filterOption'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo, useRef, useState} from 'react'
import {BsFolderFill} from "react-icons/bs";
import {HiViewGridAdd} from "react-icons/hi";
import {LayoutContext} from "../../../components/layouts/Default/LayoutContext";

export function SelectCategory({
                                 value: category_id,
                                 onChange,
                                 categoryList
                               }) {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)
  const cacheCategoryListRef = useRef(
    categoryList
  )
  if (!_.isEmpty(categoryList)) {
    cacheCategoryListRef.current = categoryList
  }

  const options = useMemo(() => {
    const result = [
      {
        key: '',
        value: '',
        label: t('all categories'),
        customClassName: "font-medium text-xs text-color-100",
        customlabel: (
          <div className="flex items-center gap-1">
            <span className="font-normal text-xs text-color-500 italic">
                {`${t('category')} : `}
            </span>
            <span>{t('all categories')}</span>
          </div>
        )
      },
      ..._.flatMap(categoryList, ({id, name, children}) =>
        [
          {
            key: id,
            value: id,
            label: name,
            customlabel: (
              <div className="font-semibold text-sm text-primary-600 uppercase py-1">
                {name}
              </div>
            )
          },
          ..._.map(children, cat => ({
            key: id + '_' + cat.id,
            value: cat.id,
            label: cat.name,
            customlabel: (
              <div className="text-sm text-color-300 pl-2 py-1">
                {cat.name}
              </div>
            )
          }))
        ]
      )
    ];

    return result;
  }, [categoryList, t])
  const [fosus, setfocus] = useState()
  const customfilterOption = filterOption
  return (
      <div
          style={{ minWidth: isSm ? '100%' : 200 }}
          className="flex items-center border border-color-50 hover:border-color-base hover:shadow-items-md rounded-md">
          <Select
              showSearch
              onDropdownVisibleChange={setfocus}
              style={{ minWidth: isSm ? '100%' : 200, fontSize: '0.85rem' }}
              className="custom-select-categories overflow-hidden"
              bordered={false}
              virtual={false}
              value={category_id}
              optionFilterProp="label"
              optionLabelProp={"label"}
              filterOption={customfilterOption}
              defaultValue={''}
              placeholder={t('categories')}
              suffixIcon={<HiViewGridAdd size={14}/>}
              onChange={onChange}>
              {options.map(item => (
                  <Select.Option
                      key={item.key}
                      value={item.value}
                      label={item.label}
                      className={item.customClassName}
                      children={item.customlabel}
                  />
              ))}
          </Select>
      </div>
  )
}
