import { createLazyComponent } from 'helpers/createLazyComponent'
import React from 'react'

const UserLazy = createLazyComponent(
  () => import('./User')
)

const User = (props) => (
  <UserLazy {...props} />
)

export default User
