import classNames from 'classnames'
import React from 'react'
import {Null} from 'views/Shared'

const ClickerWrapper = ({
                          item,
                          children,
                          className,
                          ...props
                        }) => (
  <div
    className={classNames(
      'Clicker',
      className
    )}
    {...props}>
    {children}
  </div>
)

const renderClicker =
  ({
     onClick = Null,
     Wrapper = ClickerWrapper,
     renderItem = Null,
   }) =>
    (item, index) =>
      (
        <Wrapper
          key={index}
          item={item}
          onClick={() => onClick(item)}>
          {renderItem(item, index)}
        </Wrapper>
      )

export default renderClicker
