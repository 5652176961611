import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled'
import EditOutlined from "@ant-design/icons/EditOutlined"
import {Alert, message, Radio} from 'antd'
import {
  categories_addShortcutChartToCategory_Api,
  categories_getShortcutChartsByChartId_Api,
  categories_removeShortcutChartToCategory_Api
} from 'apis'
import Modal from 'components/Modal/Modal'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {useEffect, useState} from 'react'
import {nest, withProps} from 'recompose'
import {FullSideModalLayout} from "../../components/Modal/SideModalLayout"
import Toggle from "../../components/Toggle"
import ChartContext from '../Chart/ChartContext'
import SelectCategory from "../SelectCategory/SelectCategory"
import withTranslate from "../../modules/local/withTranslate";
import Translate from "../../modules/local/Translate";
import EmptyHolder from "../../components/EmptyHolder";
import {FiEdit3} from "react-icons/fi";

const ShortcutChart = ({
  activeCategory,
  chart_id,
  item,
  idx,
  ...props
}) => {
  const {
    handleAsyncAction: handleAsyncAdd
  } = useAsyncAction({
    apiInfo: categories_addShortcutChartToCategory_Api,
    query: {
      ':cate_id': activeCategory.id,
      ':id': chart_id,
      ':position': idx.toString()
    },
    onSuccess: result => {
      props.onSuccess()
    },
    onError: (error, errorMessages) => {
      message.error(errorMessages.error)
    }
  })

  const {
    handleAsyncAction: handleAsyncRemove
  } = useAsyncAction({
    apiInfo: categories_removeShortcutChartToCategory_Api,
    query: {
      ':cate_id': activeCategory.id,
      ':position': idx.toString()
    },
    onSuccess: result => {
      props.onSuccess()
    },
    onError: (error, errorMessages) => {
      message.error(errorMessages.error)
    }
  })

  return (
    <div
      key={idx}
      onClick={e => {
        handleAsyncAdd()
      }}>
      {_.isEmpty(item) ? (
          <div className="border border-color-50 rounded-lg">
            <EmptyHolder />
          </div>
      ) : (
          <div className="p-3 w-full h-full flex flex-col justify-end border border-color-50 rounded-md hover:border-primary background-500 relative">
            <CloseCircleFilled
              className="text-red-500 hover:text-red-600 absolute"
              style={{ top: 8, right: 8 }}
              onClick={e => {
                e.stopPropagation()
                handleAsyncRemove()
              }}
            />
            <div className="flex flex-col gap-1">
              <span className="font-semibold text-sm text-color-100">
                {item.title}
              </span>
              <span className="text-xs text-color-500 italic max-lines-2">
                {item.description}
              </span>
            </div>
          </div>
        )}
    </div>
  )
}

function ChooseShortcutChartList({
  id,
  type,
  item,
  ...props
}) {
  const t = useTranslate()
  const [
    activeCategory,
    setActiveCategory
  ] = useState()

  const {
    response,
    success
  } = useAsync({
    apiInfo: categories_getShortcutChartsByChartId_Api,
    query: {
      ':id': id
    }
  })

  useEffect(() => {
    if (success) {
      const categories = _.get(
        response,
        'data.data',
        []
      )
      setActiveCategory(
        categories[0] || null
      )
      // setCharts(shortcut_charts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  if (!success) return null

  const categories = _.get(
    response,
    'data.data',
    []
  )

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  }

  return (
    <div className="mb-6 space-y-6">
      <div className="p-2 background border border-color-50 rounded-md shadow-items-md">
        {!activeCategory ? (
            <div className="flex flex-col">
              <div className="font-semibold text-sm text-color-400 italic">
                {t('You are not choosing to follow this chart category. Please choose follow up in advance')}
              </div>
              <Toggle>
                {(isToggle, toggle) => {
                  return (
                      <>
                        <div
                            style={{ padding: '4px 10px', borderRadius: '0.375rem', margin: '1rem 0.25rem 0.75rem 0.25rem' }}
                            className="flex items-center border border-color-50 hover:border-color-base text-color-300 hover:text-color-000 cursor-pointer"
                            onClick={toggle}>
                          <div className="flex flex-col flex-1">
                            <span className="font-bold text-sm flex-1">
                              {t('select category')}
                            </span>
                            <span className="text-xs text-color-500 italic">
                              {t('select the category you interested in and then click follow')}
                            </span>
                          </div>
                          <FiEdit3 />
                        </div>
                        {isToggle && (
                            <FullSideModalLayout
                                onCancel={
                                  toggle
                                }>
                              <SelectCategory
                                  onSuccess={
                                    toggle
                                  }
                              />
                            </FullSideModalLayout>
                        )}
                      </>
                  )
                }}
              </Toggle>
            </div>
        ) : (
            <div className="font-semibold text-sm text-color-400 italic">
              {t('Select the display order in the category')}
            </div>
        )}
        {!!activeCategory && (
            <Radio.Group
                onChange={e => {
                  const selectedCate = categories.filter(
                      (c, i) =>
                          c.id ===
                          e.target.value
                  )
                  if (selectedCate)
                    setActiveCategory(
                        selectedCate
                    )
                }}
                value={
                  activeCategory.id
                }>
              {categories.map(e => {
                return (
                    <Radio
                        className="font-medium"
                        key={e.id}
                        style={radioStyle}
                        value={e.id}>
                      {e.name}
                    </Radio>
                )
              })}
            </Radio.Group>
        )}
      </div>
      <div className="grid grid-cols-2 gap-2">
        {!!activeCategory &&
          !_.isEmpty(
            activeCategory.shortcut_charts
          ) &&
          activeCategory.shortcut_charts.map(
            (item, i) => {
              return (
                <ShortcutChart
                  {...props}
                  item={item}
                  idx={i.toString()}
                  activeCategory={
                    activeCategory
                  }
                  chart_id={id}
                  key={id}
                />
              )
            }
          )}
      </div>
    </div>
  )
}

export default ChooseShortcutChartList

export const ChooseShortcutChartListModal = nest(
  withProps({
    width: 640,
    title: (
        <Translate>
          {t => (
              <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                {t('choose shortcut chart')}
              </div>
          )}
        </Translate>
    ),
    className: 'custom-modal',
    visible: true,
    footer: null
  })(Modal),
  props => (
    <ChartContext.Consumer>
      {({ id, type, item }) => {
        return (
          <ChooseShortcutChartList
            {...props}
            item={item}
            id={id}
            type={type}
          />
        )
      }}
    </ChartContext.Consumer>
  )
)
