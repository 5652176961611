import {
  auth_getAuthenticatedUser_Api,
  refreshTokenApi
} from 'apis'
import {
  getTokenKey,
  saveLanguageKey,
  setTokenKey
} from 'helpers/localStorage'
import localforage from 'localforage'
import { callApi } from 'modules/asyncCache/callApi'
import {
  call,
  fork,
  put,
  select,
  takeLatest
} from 'redux-saga/effects'
import { emptyObject } from '../../helpers/emptyObjects'
// import socket from '../socket/socket'
import * as types from '../types'
import {
  loginFailure,
  loginSuccess
} from './actions'

function* loginAsync() {
  try {
    const data = yield call(callApi, {
      apiInfo: auth_getAuthenticatedUser_Api
    }) || emptyObject
    switch (data.status) {
      case 200:
        yield put(
          loginSuccess({
            user: data.data,
            token: getTokenKey()
          })
        )
        break
      case 400:
      case 401:
        yield put({
          type:
            types.REQUEST_REFRESH_TOKEN
        })
        break
      case 404:
        if (
          (!!data.data &&
            !!data.data.error &&
            data.data.error ===
              'user_not_found') ||
          (!!data.error &&
            data.error ===
              'user_not_found')
        ) {
          // force logout
          yield put({ type: 'LOGOUT' })
        }
        break
      default:
        console.log(
          'ERROR: loginFailure()'
        )
        yield put(loginFailure())
        break
    }
  } catch (error) {
    yield put(loginFailure())
  }
}
function* login() {
  yield takeLatest('LOGIN', loginAsync)
}
function* refreshTokenAsync() {
  try {
    const results = yield call(
      refreshTokenApi
    )
    console.log(
      'refreshTokenApi:',
      results
    )
    if (results.status === 200) {
      const token = results.data.token
      setTokenKey(token)
      yield put({
        type:
          types.UPDATE_REFRESH_TOKEN,
        token
      })
    } else {
      yield put({
        type:
          types.CHECK_AUTHENTICATE_FAIL,
        results
      })
      yield put({ type: 'LOGOUT' })
    }
  } catch (error) {
    console.error(error)
    yield put({
      type:
        types.CHECK_AUTHENTICATE_FAIL,
      error
    })
    yield put({ type: 'LOGOUT' })
  }
}
function* refreshToken() {
  yield takeLatest(
    types.REQUEST_REFRESH_TOKEN,
    refreshTokenAsync
  )
}

function* logoutAsync() {
  yield window.localStorage.clear()
  yield localforage.clear()
  const lang = yield select(
    state => state.i18nState.lang
  )
  saveLanguageKey(lang)
  window.location.reload()
}

function* logout() {
  yield takeLatest(
    'LOGOUT',
    logoutAsync
  )
}

export default [
  fork(login),
  fork(refreshToken),
  fork(logout)
]
