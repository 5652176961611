import {Modal} from 'antd'
import {LoginContext} from 'components/LoginContext'
import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {nest} from 'recompose'
import ServerContext from 'views/Server/ServerContext'
import {Null} from 'views/Shared'
import EventSubscribeForm from './ActionForms/EventSubscribeForm'
import InviteForm from './ActionForms/InviteForm'
import EventContext from './EventContext'
import EventInfo from './holders/EventInfo'
import EventNews from './holders/EventNews'
import EventTimeline from './holders/EventTimeline'

const ModalLayout = ({title, children, ...props}) => {
  const t = useTranslate()

  return (
    <Modal
      footer={null}
      destroyOnClose={true}
      className="custom-modal"
      title={
        <div className="text-center font-bold text-color-000 uppercase tracking-wide">
          {_.isString(title) ? t(title) : title}
        </div>
      }
      {...props}>
      {children}
    </Modal>
  )
}

const withRouteModal =
  (params) =>
    (Component = Null, props, withProps = Null) =>
      (
        <LoginContext.Consumer>
          {(login) => (
            <EventContext.Consumer>
              {({
                  current,
                  event: item,
                  handleAction,
                  setCurrent = Null,
                  ...rest
                }) => {
                const onCancel = () => {
                  setCurrent(null)
                }

                return (
                  <ModalLayout
                    {...(params ?? {})}
                    visible={current}
                    onCancel={onCancel}>
                    <Component
                      {...(props ?? {})}
                      {...(withProps({
                        item,
                        login,
                        onCancel,
                        handleAction,
                        ...rest,
                      }) ?? {})}
                    />
                  </ModalLayout>
                )
              }}
            </EventContext.Consumer>
          )}
        </LoginContext.Consumer>
      )

export const routes = {
  share: {
    path: 'share',
    name: 'share',
    component: nest(
      ({children}) => (
        <ServerContext.Consumer>
          {({setCurrent}) => (
            <FullSideModalLayout
              right={true}
              onCancel={() => {
                setCurrent(null)
              }}
              title={<Translate>{(t) => t('invite')}</Translate>}>
              {children}
            </FullSideModalLayout>
          )}
        </ServerContext.Consumer>
      ),
      InviteForm
    ),
    exact: true,
  },
  register: {
    name: 'register',
    path: 'register',
    component: () =>
      withRouteModal({
        title: 'sign up for this event',
        width: 600,
      })(EventSubscribeForm, {}, ({item, onCancel}) => ({
        item,
        onCancel,
      })),
    exact: true,
  },
}

export const eventRouteEntities = [
  {
    path: 'overview',
    component: EventInfo,
    breadcrumbName: (t) => t('overview'),
  },
  {
    path: 'timeline',
    component: EventTimeline,
    isInvisible: ({item}) => !!item?.invisible_timeline,
    breadcrumbName: (t) => t('timeline'),
  },
  {
    path: 'news',
    component: EventNews,
    breadcrumbName: (t) => t('news'),
  },
]
