import {UploadOutlined} from '@ant-design/icons'
import {Button, message, Upload} from 'antd'
import Null from 'components/NullComponent'
import {Field} from 'formik'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {createValue} from '../utils'
import Resizer from "react-image-file-resizer";

export const getBase64 = (file, maxWidth, maxHeight) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

// export function getBase64(file) {
//   return new Promise(
//     (resolve, reject) => {
//       const reader = new FileReader()
//       console.log({ file });
//
//       // const _file = resizeFile(file);
//       // console.log({ _file });
//       const _file = file;
//
//       reader.readAsDataURL(_file);
//       reader.onload = () =>
//         resolve(reader.result)
//       reader.onerror = error =>
//         reject(error)
//     }
//   )
// }

// function dataURLtoFile(dataurl, filename) {
//   var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
//     bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
//   while(n--){
//     u8arr[n] = bstr.charCodeAt(n);
//   }
//   return new File([u8arr], filename, {type:mime});
// }
export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], {type: mimeString});
}

export default function ImageUpload({
                                      value,
                                      name,
                                      onChange,
                                      success = Null,
                                      error = Null,
                                      renderPreview,
                                      showUploadList = true,
                                      maxWidth = 256, maxHeight = 256,
                                      children
                                    }) {
  const translate = useTranslate();


  const handleOnchange = async function (
    info
  ) {
    // console.log({ info })
    if (!!info && !info.file.preview) {
      const file = await getBase64(info.file, maxWidth, maxHeight);
      const image = dataURItoBlob(file);
      console.log({image})
      onChange(
        createValue(
          name + '_preview',
          file
        )
      )
      onChange(
        createValue(
          name,
          image
        )
      )
      // await getBase64(
      //   info.file
      // ).then(result => {
      //   console.log({result})
      //
      //   onChange(
      //     createValue(
      //       name + '_preview',
      //       result
      //     )
      //   )
      //   onChange(
      //     createValue(
      //       name,
      //       info.file
      //     )
      //   )
      // })
    }
    if (
      !!info && info.file.status !== 'uploading'
    ) {
      console.log(1,
        info.file,
        info.fileList
      )
    }
    if (!!info && info.file.status === 'done') {
      success(
        `${info.file.name} ${translate(
          'file uploaded successfully'
        )}`
      )
    } else if (
      !!info && info.file.status === 'error'
    ) {
      !!info &&
      error(
        `${info.file.name} ${translate(
          'file upload failed'
        )}.`
      )
    }
  }
  const beforeUpload = function beforeUpload(
    file
  ) {
    const isLt2M =
      file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error(
        `${translate(
          'image must smaller than'
        )} 2MB!`
      )
    }
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error(
        `${translate(
          'You can only upload JPG/PNG file'
        )}!`
      )
    }

    return false
  }
  return (
    <div className="flex flex-col gap-4 items-center">
      <Field
        name={name + '_preview'}
        render={({
                   field,
                   form: {
                     touched = {},
                     errors = {}
                   } = {
                     touched: {},
                     errors: {}
                   }
                 }) =>
          renderPreview ? (
            renderPreview({
              src: field.value
            })
          ) : (
            <img
              style={{
                width: '100%',
                minHeight: '300px',
                height: 'auto',
                objectFit: 'cover'
              }}
              src={field.value}
              size={100}
              className="bg-gray-300"
              alt={field.value}
            />
          )
        }
      />
      <Upload
        {...{
          showUploadList,
          beforeUpload,
          name: 'file',
          onChange: handleOnchange
        }}>
        {children ? (
          children
        ) : (
          <Button>
            <UploadOutlined />{' '}
            {translate(
              'click to upload'
            )}
          </Button>
        )}
      </Upload>
    </div>
  )
}
