import {PlusOutlined} from '@ant-design/icons'
import {Avatar, Input, Modal, Tooltip, Typography} from 'antd'
import {createValue} from 'components/form/utils'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useState} from 'react'
import {Pure} from "components/Pure";
import preventParentEvent from "../../helpers/preventParentEvent";
import {useDeBounceValue} from "../../views/Search/useDeBounceValue";
import useAsyncWithCache from "../../modules/asyncCache/useAsyncWithCache";
import {API_ROOT_URL} from "../../envs/_current/config";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import {useToggle} from "react-use";
import {BsPlusLg} from "react-icons/bs";

const LookupResult = ({type, keyword, createIfNotExists, toggle, onFinish, apiPath = null}) => {
    const t = useTranslate();

    const path = !!apiPath ? (
        `${apiPath}?keyword=${keyword}`
    ) : (
        `/lookup/${type}/gets?keyword=${keyword}`
    )
    const {
        success,
        result,
        action,
        isLoading,
        response,
        handleDispatchAsyncAction
    } = useAsyncWithCache({
        cacheId: path,
        apiInfo: {
            root: API_ROOT_URL,
            path: path,
            method: 'GET',
        },
    });
    const options = _.uniq(
        _.filter(
            _.get(
                response,
                'data.data',
                []
            )
            , function (o) {
                return o.id !== '';
            }).map(
            ({name: label, id: value, ...props}) => {
                return {...props, label, value}
            }
        )
    );

    return (
        <div key={keyword} className="w-full pt-6 flex flex-col gap-3">
            {!!createIfNotExists && !!(
                keyword &&
                keyword.length
            ) && (
                <div key="@create new" className="flex border border-color-50 rounded-md hover:shadow-items px-3 py-2">
                    <div className="flex items-center gap-1 flex-1">
                        <BsPlusLg size={13} className="text-color-300"/>
                        <span className="text-sm text-color-200 italic">{t('create new')}</span>
                        <span className="font-bold text-color-000">{keyword}</span>
                    </div>
                    <div
                        className="font-semibold text-sm text-primary hover:text-primary-700 cursor-pointer"
                        onClick={() => {
                            onFinish(createValue(type, keyword || ''))
                            toggle()
                        }}>
                        {t('select')}
                    </div>
                </div>
            )}

            {options.map(
                o => (
                    <div className="flex items-center gap-3 p-2 border border-color-50 hover:shadow-items-md rounded-md" key={o.value}>
                        {!!_.get(o, 'avatar') &&
                            <Avatar
                                size="small"
                                shape="circle"
                                src={_.get(o, 'avatar')}
                            />
                        }
                        <span className="font-bold text-xs text-color-000 flex-1">
                            {o.label}
                        </span>
                        <div
                            className="font-bold text-xs text-primary hover:text-primary-600 cursor-pointer"
                            onClick={() => {
                                onFinish(
                                    {target: {
                                            ...o,
                                            name: type,
                                            value: o.label || '',
                                            id: o.value
                                        }}
                                )
                                toggle()
                            }}>
                            {t('select')}
                        </div>
                    </div>
                )
            )}
        </div>
    )
}

const Lookup = ({isToggle, toggle, createIfNotExists, type, title, onFinish, apiPath = null}) => {
    const t = useTranslate();
    // State and setters for ...
    // Search term
    const [searchTerm, setSearchTerm] = useState("");
    // Debounce search term so that it only gives us latest value ...
    // ... if searchTerm has not been updated within last 500ms.
    // The goal is to only have the API call fire when user stops typing ...
    // ... so that we aren't hitting our API rapidly.
    const debouncedSearchTerm = useDeBounceValue(searchTerm, 300);

    return (
        <Modal
            width={520}
            className="custom-modal"
            maskClosable={false}
            title={
                <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                    {t(title) || t('select')}
                </div>
            }
            destroyOnClose
            onCancel={toggle}
            visible={isToggle}
            footer={null}>
            <Input.Search
                autoClear
                onPressEnter={(e) => {
                    onFinish(
                        createValue(
                            type,
                            searchTerm || ''
                        )
                    )
                    toggle()
                }}
                autoFocus={true}
                onChange={e => {
                    preventParentEvent(e);
                    if (e && e.target && e.target.value) {
                        setSearchTerm(e.target.value);
                    }
                }}
                placeholder={t('input search loading default')}/>
            <Pure key={debouncedSearchTerm} input={[debouncedSearchTerm]}>
                <LookupResult
                    type={type}
                    createIfNotExists={createIfNotExists}
                    keyword={debouncedSearchTerm}
                    toggle={toggle}
                    onFinish={onFinish} apiPath={apiPath}
                />
            </Pure>
        </Modal>
    )
}

const LookupField = ({name, bordered, defaultValue, disabled = false, createIfNotExists = true, handleChange, apiPath = null}) => {
    const t = useTranslate();

    return (
        <Toggle>
            {(isToggle, toggle) => (
                isToggle ? (
                        <React.Fragment>
                            <div className="font-medium text-color-400 italic">{t('selecting...')}</div>
                            <Lookup type={name}
                                    isToggle={isToggle}
                                    toggle={toggle}
                                    createIfNotExists={createIfNotExists}
                                    onFinish={handleChange}
                                    apiPath={apiPath}
                            />
                        </React.Fragment>
                    )
                    : (
                        <Input.Search
                            value={
                                defaultValue
                            }
                            disabled={disabled}
                            onFocus={() => {
                                toggle();
                            }}
                            className="w-full"
                            placeholder={t(
                                'select / create'
                            )}
                            enterButton={
                                <Tooltip title="remove">
                                    <CloseOutlined onClick={() => handleChange(createValue(name, null))}/>
                                </Tooltip>
                            }
                            onSearch={(value, event) => {
                                handleChange(createValue(name, null))
                            }}
                        >
                        </Input.Search>
                    )
            )}
        </Toggle>
    )
}

export const useLookupField = ({
                                   name,
                                   title,
                                   handleChange,
                                   defaultValue,
                                   apiPath = null,
                                   createIfNotExists = true,
                                   ...props
                               }) => {
    const [isToggle, toggle] = useToggle(false)
    return {
        toggle,
        isToggle,
        modal: (
            <Lookup
                type={name}
                title={title}
                toggle={toggle}
                apiPath={apiPath}
                isToggle={isToggle}
                onFinish={handleChange}
                selectedOption={defaultValue}
                createIfNotExists={createIfNotExists}
                {...props}
            />
        ),
    }
}
export default LookupField
