import Modal from 'components/Modal/Modal'
import {Formik} from 'formik'
import {createOnError} from 'helpers'
import React from 'react'
import {postSchema} from 'apis/schema'
import {SelectEntityItem} from 'components/SelectEntityItem'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import {feed_updateChannel_Api} from "../../apis";
import {createValue} from "../../components/form/utils";
import {Button} from "antd";
import LookupField from "../../components/LookupField";

const FeedSetChannelForm = ({
                              type,
                              id,
                              onCancel
                            }) => {
  const t = useTranslate()

  return (
    <NavigationContext.Consumer>
      {({handleGoBack}) => (
        <Modal
          visible={true}
          title={t('set_channel')}
          footer={null}
          onCancel={
            onCancel || handleGoBack
          }>
          <SelectEntityItem
            item={id}
            schema={postSchema}>
            {item => (
              <div className="pt-3">
                {item && (
                  <AsyncAction
                    onSuccess={
                      onCancel ||
                      handleGoBack
                    }
                    onError={createOnError()}
                    apiInfo={
                      feed_updateChannel_Api
                    }
                    query={{
                      ':type':
                      item._type,
                      ':id':
                      item.id
                    }}>
                    {({
                        handleAsyncAction,
                        isLoading
                      }) => (
                      <Formik
                        onSubmit={
                          handleAsyncAction
                        }
                        initialValues={{
                          channel_id: item.container
                            .id
                        }}>
                        {({
                            values,
                            handleSubmit,
                            handleChange
                          }) => (
                          <React.Fragment>
                            {/*<QuerySelect*/}
                            {/*  name={"channel_id"}*/}
                            {/*  onChange={(value) => {*/}
                            {/*    handleChange(*/}
                            {/*      createValue('channel_id', value)*/}
                            {/*    )*/}
                            {/*  }}*/}
                            {/*  apiInfo={create_channel_getChannelsByProps_Api(*/}
                            {/*    {*/}
                            {/*      query: {*/}
                            {/*        ':type': item.container.owner*/}
                            {/*          ._type,*/}
                            {/*        ':id': item.container.owner*/}
                            {/*          .id*/}
                            {/*      }*/}
                            {/*    }*/}
                            {/*  )}*/}
                            {/*/>*/}

                            <LookupField
                              createIfNotExists={false}
                              defaultValue={
                                values.channel_name
                              }
                              apiPath={`/channels/lookup`}
                              name={"channel_id"}
                              handleChange={(e) => {
                                handleChange(
                                  createValue('channel_name', e.target.value)
                                )
                                handleChange(
                                  createValue('channel_id', e.target.id)
                                )
                              }}
                            />

                            <div className="flex w-full justify-end float-right py-3">
                              <Button
                                onClick={handleSubmit}
                                type="primary"
                                className="font-bold">
                                {t('submit')}
                              </Button>
                            </div>

                          </React.Fragment>
                        )}
                      </Formik>
                    )}
                  </AsyncAction>
                )}
              </div>
            )}
          </SelectEntityItem>
        </Modal>
      )}
    </NavigationContext.Consumer>
  )
}
export default FeedSetChannelForm
