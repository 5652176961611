import _ from 'lodash'
import {notEmpty} from 'views/Shared'

const yesOrno = [
  {
    value: 1,
    label: 'true',
  },
  {
    value: 0,
    label: 'false',
  },
]

const noOrYes = yesOrno.reverse()

const periods = [
  {
    value: 'H',
    label: 'hour',
  },
  {
    value: 'D',
    label: 'day',
  },
  {
    value: 'M',
    label: 'month',
  },
  {
    value: 'Y',
    label: 'year',
  },
].map(({label, ...rest}) => ({
  label: `per ${label}`,
  ...rest,
}))

export const getDefaultField = (array = [], key = 'isDefault') => {
  const reduced = array.map((e) => _.get(e, key)).filter(notEmpty)
  if (_.isEmpty(reduced)) {
    return _.first(array)
  }
  return _.find(array, {[key]: true})
}

export const getDefaultFieldValue = (array = [], key = 'isDefault') => {
  const item = getDefaultField(array, key)
  return _.get(item, 'value')
}

export const GenderTypes = Object.freeze({
  Male: {
    name: 'male',
    label: 'male',
    value: 1,
  },
  Female: {
    name: 'female',
    label: 'female',
    value: 2,
  },
  Others: {
    name: 'others',
    label: 'others',
    value: 3,
  },
})


export const IdentifierTypes = Object.freeze({
  Passport: {
    name: 'Passport',
    label: 'Passport',
    value: 'passport',
  },
  IdentityCard: {
    name: 'IdentityCard',
    label: 'Identity Card',
    value: 'identitycard',
  },
  DriverLicense: {
    name: 'DriverLicense',
    label: 'Driver License',
    value: 'driverlicense',
  },
})

export const SyncContentTypes = Object.freeze({
  PENDING: {
    value: 0,
    label: 'pending',
  },
  PROGRESSING: {
    value: -1,
    label: 'progressing',
  },
  SUCCESS: {
    value: 1,
    label: 'success',
  },
  DRAFTING: {
    value: -10,
    label: 'drafting',
  },
})

export const SyncFixedContentTypes = Object.freeze({
  PENDING: {
    value: 0,
    label: 'pending',
  },
  PROGRESSING: {
    value: -1,
    label: 'progressing',
  },
  SUCCESS: {
    value: 1,
    label: 'success',
  },
})

export const SyncStatusTypes = Object.freeze({
  PENDING_0: {
    value: 0,
    label: 'pending',
  },
  PENDING: {
    value: -9,
    label: 'pending',
  },
  SUCCESS: {
    value: 1,
    label: 'success',
  },
  PROGRESSING: {
    value: -1,
    label: 'processing',
  },
  ERROR: {
    value: -2,
    label: 'error',
  },
})

export const getProp =
  (object = {}, which = 'value') =>
    (value, conditionName = 'value') => {
      const array = _.isArray(object) ? Array.from(object) : Object.values(object)
      const result = _.find(array, {
        [conditionName]: value,
      })
      return _.get(result, which)
    }

export const appFieldTypes = Object.freeze({
  periods,
  yesOrno,
  noOrYes,
  required: yesOrno,
})
