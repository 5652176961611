import {Avatar} from "antd"
import _ from "lodash"
import useTranslate from "modules/local/useTranslate"
import React from "react"
import classNames from 'classnames'


const SpotProductInfo = ({
  item,
  className,
}) => {

  const t = useTranslate()

  if (_.isEmpty(item)) {
    return null
  }

  const {
    attributes = [],
    specifications = [],
    use_for: attribute_value = '',
  } = item

  return (
    <div className={classNames('flex item-center gap-3', className)}>
      <Avatar
          size={60}
          src={_.get(item, 'photos.0.path')}
          className="flex flex-center rounded-md background-200 object-cover"
      />
      <div className="flex flex-col">
        <span className="font-bold text-color-000">{item.title}</span>
        {[
          ...[
            ...specifications,
            ...attributes
          ].filter((item, i) => i < 3),
          {
            attribute_name: t('use for'),
            attribute_value: attribute_value,
            visible: !_.isEmpty(attribute_value)
          }
        ].filter(e => !!e.visible).map(
            (attr, index) => (
                <div key={index} className="space-x-1">
                  <span className="text-primary">{' • '}</span>
                  <span className=" text-sm text-color-300 italic">
                    {_.get(attr, 'attribute_value')}
                  </span>
                </div>
            )
        )}
      </div>
    </div>
  )
}

export default SpotProductInfo