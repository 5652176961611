import classNames from 'classnames'
import React, { useRef } from 'react'

export const TabbarItem = ({
  flexColumn,
  disabled = false,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'TabbarItem',
        !!flexColumn
          ? 'flex flex-col'
          : '',
        !!disabled
          ? 'pointer-events-none text-color-300'
          : ''
      )}
      {...props}
    />
  )
}

let count = 0
const Tabbar = ({
    className,
    size,
    type,
    activeKey,
    ...props
}) => {
  const nameRef = useRef(
    `Tabbar_${count++}`
  )
  return (
    <div
      className={classNames("overflow-x-auto flex relative Tabbar", nameRef.current, className)}
      {...props}>
      {props.children}
      {type === 'button' ? (
        <style>
          {`
            .${nameRef.current} {
              box-shadow: inset 0 -1px 0 0 var(--border-color-base);
              overflow:hidden;
            }
            .${nameRef.current} .TabbarItem {
              margin-right:1em;
              padding: 0.5em 0;
              font-size: 14px;
              white-space: nowrap;
              position: relative;
              cursor: pointer;
              display: flex;
              align-items: center;
              -webkit-transition: -webkit-transform .3s linear;
              transition: -webkit-transform .3s linear;
              transition: transform .3s linear;
              transition: transform .3s linear,-webkit-transform .3s linear;
            }
            .${
              nameRef.current
            } .TabbarItem:nth-child(${activeKey +
            1}){
              color: var(--color-primary);
            }
            .${
              nameRef.current
            } .TabbarItem:nth-child(${activeKey +
            1}):before{
                height: 0.2em!important;
                    background:var(--color-primary)!important;
                }
            .${
              nameRef.current
            }  .TabbarItem:hover:before{
                height: 0.2em;
                background: var(--border-color-base);
                z-index:1;
            }
            .${
              nameRef.current
            }  .TabbarItem div{
              z-index:1;
          }
            .${
              nameRef.current
            } .TabbarItem:before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              height: 0;
              -webkit-transition: all .2s ease-in-out;
              transition: all .2s ease-in-out;
          `}
        </style>
      ) : (
        <style>
          {`
            .${nameRef.current} {
              box-shadow: inset 0 -1px 0 0 var(--border-color-base);
              ${
                size === 'small'
                  ? 'font-size: 0.9em;'
                  : 'font-weight: 500;'
              }
            }
            .${
              nameRef.current
            } .TabbarItem {
              margin-right:1em;
              font-size: 14px;
              height: 40px;
              white-space: nowrap;
              position: relative;
              cursor: pointer;
              display: flex;
              align-items: center;
              -webkit-transition: -webkit-transform .3s linear;
              transition: -webkit-transform .3s linear;
              transition: transform .3s linear;
              transition: transform .3s linear,-webkit-transform .3s linear;
            }
            .${
              nameRef.current
            } .TabbarItem:nth-child(${activeKey +
            1}){
              color:var(--color-primary);
            }
            .${
              nameRef.current
            } .TabbarItem:nth-child(${activeKey +
            1}):after,.${
            nameRef.current
          } .TabbarItem:hover:after{
                border-bottom-width: 3px;
            }
            .${
              nameRef.current
            } .TabbarItem:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              height: 0;
              background: currentColor;
              border: 0 solid;
              -webkit-transition: border .2s ease-in-out;
              transition: border .2s ease-in-out;
          `}
        </style>
      )}
    </div>
  )
}

export default Tabbar
