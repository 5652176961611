import {createLazyComponent} from 'helpers/createLazyComponent'
import _ from 'lodash'
import React, {useRef} from 'react'
import {GrEmoji} from 'react-icons/gr'
import {useClickAway, useToggle,} from 'react-use'
import {Null} from 'views/Shared'

const EmojiPicker = createLazyComponent(
  () => import('components/EmojiPicker')
)

function splitAt(it, index) {
  if (it) {
    return [
      it.slice(0, index),
      it.slice(index),
    ]
  } else {
    return [,]
  }
}

function getContent(emo, cursorIndex) {
  return (currentText) => {
    const emoji =
      emo.native || emo.colons

    if (!emoji) {
      return currentText
    }

    const [left, right] = splitAt(
      currentText,
      cursorIndex
    )
    return _.join(
      [left, emoji, right],
      ''
    )
  }
}

const EmojiWrapper = ({
                        content,
                        onChange = Null,
                        currentIndex = 0,
                        Component = GrEmoji,
                        size = 18,
                      }) => {
  const ref = useRef()

  const [isToggle, toggle] = useToggle()

  useClickAway(ref, () => {
    toggle(false)
  })

  return (
    <div className="flex items-center">
      <Component
        size={size}
        onClick={toggle}
        className="text-orange-300 hover:text-orange-600 cursor-pointer"
      />
      {isToggle && (
        <div
          ref={ref}
          style={{left: 0, top: 35}}
          className="absolute z-10">
          <EmojiPicker
            onSelect={(selected) => {
              toggle(false)
              const text = getContent(
                selected,
                currentIndex
              )(content)
              onChange(text)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default EmojiWrapper
