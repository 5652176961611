import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {Link} from 'react-router-dom'
import {nest} from 'recompose'
import PageLink from "../Market/components/PageLink";
import {GetQuoteButton} from "../Market/components/GetQuoteButton";
import ImageLoader from "../../components/ImageLoader";
import organizationModel from "../../apis/model/base";

const ProductItem = ({
                       item,
                       className
                     }) => {
  const t = useTranslate();

  return (
    <div
      key={item.id}
      className="background-100 w-full col-span-1  h-full  flex flex-col">
      <div
        style={{
          paddingTop: '70%'
          // margin: '-1px',
        }}
        className="w-full  overflow-hidden relative">
        <ImageLoader
          className="w-full h-full object-cover absolute left-0 top-0"
          src={item.avatar}
        />
      </div>
      <div className="verticalList py-3 flex-1 flex flex-col md:text-lg text-sm">
        <h2 className=" px-3 font-bold">
          <PageLink
            to={
              '/product/' +
              item.idname
            }>
            {item.title}
          </PageLink>
        </h2>
        <div className="px-3 flex-1"></div>
        {!!_.get(
          item,
          'owner.title'
        ) && (
          <div className="px-3 italic text-xs">
            {t('provided by')}{' '}
            <b>
              {_.get(
                item,
                'owner.title'
              )}
            </b>
          </div>
        )}
        <div className="px-3">
          <GetQuoteButton
            {...{
              item
            }}>
            {t('Get Best Price')}
          </GetQuoteButton>
        </div>
      </div>
    </div>
  )
}

export const FeedMoreSuggestedProducts = nest(
  ({list, item}) => {
    const t = useTranslate()

    return (
      <React.Fragment>
        {list.map((e, i) => (
          <ProductItem
            key={i}
            item={e}
          />
        ))}
        {!!item && (
          <div className="bottom-0 flex w-full">
            <div className="py-3  cursor-pointer px-3 flex-wrap flex w-full justify-center color-primary">
              <div className="flex-1"/>
              <Link
                to={
                  organizationModel.getLinkToDetail(
                    item
                  ) + '/products'
                }>
                <span>
                  {t('see more')}
                </span>
                <DoubleRightOutlined/>
              </Link>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
)
