import { EllipsisOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import DrawMenu from 'components/drawer/DrawMenu'
import useTranslate from 'modules/local/useTranslate'
import React, { useMemo } from 'react'
import { deepTranslate, Null, renderSelf } from 'views/Shared'

export const ContextActionTypes = Object.freeze({
  VIEW: 'view',
  EDIT: 'edit',
  COPY: 'copy',
  DELETE: 'delete',
  EXPORT: 'export',
  SETTINGS: 'settings',
  CREATE_CHANNEL: 'create-channel',
  CREATE_EVENT: 'create-event',
  CREATE_ORGANIZATION: 'create-provider',
})

const CustomContextMenu = ({ icon, items = [], onMenuSelect = Null }) => {
  const t = useTranslate()

  const menu = useMemo(
    () =>
      Array.from(items || [])
        .map(deepTranslate(t))
        .map(({ label, renderLabel = renderSelf, ...rest }) => ({
          ...rest,
          label: renderLabel(label),
        })),
    [t, items]
  )

  return (
    <DrawMenu component={icon}>
      {(__, toggle) => (
        <Menu
          onClick={({ key }) => {
            onMenuSelect(key)
            toggle()
          }}
          items={menu}
        />
      )}
    </DrawMenu>
  )
}

const DefaultIcon = ({ onClick }) => (
  <div
    onClick={onClick}
    className="px-1 cursor-pointer rounded-full background hover:background-200 my-auto">
    <EllipsisOutlined className="my-auto cursor-pointer text-color-300 hover:text-color-100" />
  </div>
)

const createContextMenu = (items, onMenuSelect, icon = <DefaultIcon />) => (
  <CustomContextMenu
    items={items}
    icon={icon}
    onMenuSelect={onMenuSelect}
  />
)

export const ItemContextMenu = ({
  icon,
  item,
  items,
  hidden = false,
  onMenuSelect = Null,
}) =>
  hidden
    ? null
    : createContextMenu(
        items,
        (key) =>
          onMenuSelect(key, {
            item,
          }),
        icon
      )

export default createContextMenu
