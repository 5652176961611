import {Editor, Node, Point, Range, Transforms} from 'slate'
import {shallowDiff} from 'views/Shared'
import {getClipboard, getNextNode, getNode, getPrevNode} from '../functions'
import {ElementTypes} from '../types'

const withTable = (editor) => {
  const {
    insertBreak,
    deleteForward,
    deleteFragment,
    deleteBackward,
    insertFragment,
    insertText,
  } = editor

  editor.insertText = (text) => {
    const {selection} = editor
    if (selection) {
      const cell = getNode(editor, ElementTypes.TABLE_CELL)
      if (cell && !Range.isCollapsed(selection)) {
        const diff = shallowDiff(
          {data: selection?.anchor?.path},
          {data: selection?.focus?.path}
        )
        if (Object.keys(diff).length) {
          return
        }
      }
    }
    insertText(text)
  }

  editor.deleteBackward = (unit) => {
    const {selection} = editor
    if (selection && Range.isCollapsed(selection)) {
      const cell = getNode(editor, ElementTypes.TABLE_CELL)
      if (cell) {
        const [, cellPath] = cell
        const start = Editor.start(editor, cellPath)
        if (Point.equals(selection.anchor, start)) {
          return
        }
      }
    }
    const [prevNode, prevPath] = getPrevNode(editor) ?? [null, null]
    if (
      prevNode &&
      selection.anchor.offset === 0 &&
      [ElementTypes.TABLE].includes(prevNode?.type)
    ) {
      Transforms.removeNodes(editor, {at: prevPath})
      return
    }
    deleteBackward(unit)
  }

  editor.deleteForward = (unit) => {
    const {selection} = editor
    if (selection && Range.isCollapsed(selection)) {
      const cell = getNode(editor, ElementTypes.TABLE_CELL)
      if (cell) {
        const [, cellPath] = cell
        const end = Editor.end(editor, cellPath)
        if (Point.equals(selection.anchor, end)) {
          return
        }
      }
    }
    const [next_node, next_path] = getNextNode(editor) ?? [null, null]
    if ([ElementTypes.TABLE].includes(next_node?.type)) {
      const selectedLeaf = Node.descendant(editor, selection.anchor.path)
      if (selection.anchor.offset === selectedLeaf?.text?.length) {
        Transforms.removeNodes(editor, {at: next_path})
        return
      }
    }
    deleteForward(unit)
  }

  editor.deleteFragment = (...args) => {
    const {selection} = editor
    const node = getNode(editor, ElementTypes.TABLE_CELL)
    if (node) {
      Transforms.delete(editor, {
        at: selection.anchor.path,
        unit: 'word',
      })
      return
    }
    deleteFragment(...args)
  }

  editor.insertFragment = (...args) => {
    const {selection, insertText} = editor
    if (selection) {
      const cell = getNode(editor, ElementTypes.TABLE_CELL)
      const text = getClipboard(editor)
      if (cell && text.length) {
        insertText(text)
        return
      }
    }
    insertFragment(...args)
  }

  editor.insertBreak = (...args) => {
    const {selection} = editor
    if (selection) {
      const table = getNode(editor, ElementTypes.TABLE)
      if (table) {
        return
      }
    }
    insertBreak(...args)
  }

  return editor
}

export default withTable
