import * as am4core from "@amcharts/amcharts4/core";
import FeedInLogo from "../../../assets/svgs/applogo.svg";
import _ from "lodash";

export const addWatermarkLogoConfig = (item, thisChart, align = 'left', valign = 'bottom') => {
  try {
    if (thisChart) {
      let labelWatermark = thisChart.plotContainer.createChild(
        am4core.Image
      );
      labelWatermark.href = FeedInLogo;
      labelWatermark.scale = 2;
      labelWatermark.align = align;
      labelWatermark.valign = valign;
      labelWatermark.opacity = 0.3;
      labelWatermark.dy = 30;
      labelWatermark.dx = 10;
      labelWatermark.zIndex = 99;
      labelWatermark.clickable = false;
      labelWatermark.focusable = false;


      if (
        _.has(
          item,
          'dataset.copyright'
        )
        && !_.isEmpty(
        item.dataset.copyright
        )
      ) {

        let labelCopyright = thisChart.plotContainer.createChild(
          am4core.Label
        );
        labelCopyright.align = 'right';
        labelCopyright.valign = 'bottom';
        labelCopyright.dx = -3;
        labelCopyright.opacity = 0.3;
        labelCopyright.zIndex = 0;
        labelCopyright.fontSize = "0.68rem";
        labelCopyright.text =
          'Source: ' +
          item.dataset.copyright;
        labelCopyright.zIndex = 99;
        labelCopyright.clickable = false;
        labelCopyright.focusable = false;
      }

    }

  } catch (e) {
  }
}