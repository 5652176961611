export const staticPaths = {
  account: {
    pathname: '/account'
  },
  accountOrganization: {
    pathname: '/account/organization'
  },
  accountOrganizationMember: {
    pathname:
      '/account/organization/member'
  },
  accountOrganizationAdmin: {
    pathname: '/account/organization'
  },
  accountChannel: {
    pathname: '/account/channel'
  },
  accountChannelFollow: {
    pathname:
      '/account/channel?type=follow'
  },
  accountChannelAdmin: {
    pathname: '/account/channel'
  },
  accountSettings: {
    pathname: '/account/settings',
  },
  createOrganization: {
    pathname: '/create-provider',
    state: {
      isModal: true,
    },
  },
  createChannel: {
    pathname: '/create-channel',
    state: {
      isModal: true,
    },
  },
  createEvent: {
    pathname: '/create-event',
    state: {
      isModal: true,
    },
  },
  createProduct: {
    pathname: '/create-product',
    state: {
      isModal: true
    }
  },

  login: {
    pathname: '/login',
    state: {
      isModal: true,
    },
  },
  register: {
    pathname: '/register',
    state: {
      isModal: true,
    },
  },
  select_category: {
    pathname: '/select-category',
  },
  category: {
    pathname: '/category',
  },
  logout: {
    pathname: '/logout',
    state: {
      isModal: true,
    },
  },
  home: {
    pathname: '/',
  },
  myspace: {
    pathname: '/myspace',
  },
  workspace: {
    pathname: '/workspace',
  },
  discovery: {
    pathname: '/discovery',
  },
  bookmark: {
    pathname: '/bookmark',
  },
  myBookmarks: {
    pathname: '/my-bookmarks',
  },
  myActivities: {
    pathname: '/my-activities',
  },
  myChannels: {
    pathname: '/my-channels',
  },
  myArticles: {
    pathname: '/my-activities?rel=articles',
  },
  start: {
    pathname: '/start',
  },
  welcome: {
    pathname: '/welcome',
  },
  news: {
    pathname: '/news',
  },
  data_board: {
    pathname: '/data-board'
  },
  about: {
    pathname: '/document/about-us'
  },
  policy: {
    pathname: '/document/privacy-policy'
  },
  tutorials: {
    pathname: '/document/tutorials'
  },
  messages: {
    pathname: '/messages',
  },
  selectPrimaryOrganization: {
    pathname: '/select-primary-organization',
    state: {
      isModal: true,
    },
  },
  search: {
    pathname: '/search',
  }
}
