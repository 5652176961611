import React from "react";

export const TraceMinerals = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 497 497"
		>
			<path
				fill="#c1e4fa"
				d="M280.127 300.82l-63.254-104.64H30c-3.507 0-6.871.608-10 1.714l-5 263.413 263.413-5a29.941 29.941 0 001.714-10z"
			></path>
			<path
				fill="#91b5f9"
				d="M20 442.098V197.894C8.349 202.013 0 213.119 0 226.18v220.127c0 16.569 13.431 30 30 30h220.127c13.061 0 24.167-8.349 28.286-20H34.209c-7.847 0-14.209-6.361-14.209-14.209z"
			></path>
			<path
				fill="#f7f9fa"
				d="M228.873 288.82l266.413-8a29.945 29.945 0 001.714-10V50.693c0-16.569-13.431-30-30-30H246.873c-3.507 0-6.871.608-10 1.714z"
			></path>
			<path
				fill="#dce6eb"
				d="M280.127 300.82H467c13.061 0 24.167-8.349 28.286-20H280.127l-43.255-84.641V22.407c-11.651 4.119-20 15.225-20 28.286V196.18z"
			></path>
			<path
				fill="#dce6eb"
				d="M250.127 196.18h-13.255l-5 89.641 48.255-5V226.18c0-16.569-13.431-30-30-30z"
			></path>
			<path
				fill="#b9c3cd"
				d="M246.873 300.82h33.255v-20h-29.046c-7.847 0-14.209-6.361-14.209-14.209V196.18h-20v74.641c0 16.568 13.431 29.999 30 29.999z"
			></path>
			<path
				fill="#6985c8"
				d="M125.686 340.224l35.448-32.583a7.5 7.5 0 00-10.151-11.043l-31.349 28.815v-24.324a7.5 7.5 0 00-15 0v81.655a7.5 7.5 0 0015 0v-27.516l34.116 32.914a7.477 7.477 0 005.207 2.103 7.5 7.5 0 005.208-12.897z"
			></path>
			<g fill="#7e8b96">
				<path d="M360.392 178.767a7.5 7.5 0 00-10.566.925 25.268 25.268 0 01-2.557 2.639c-4.625 4.119-10.485 6.208-17.419 6.208-18.377 0-33.328-14.951-33.328-33.328s14.951-33.328 33.328-33.328a33.146 33.146 0 0118.628 5.688 7.5 7.5 0 0010.413-2.016 7.499 7.499 0 00-2.016-10.413c-7.997-5.403-17.342-8.259-27.026-8.259-26.648 0-48.328 21.68-48.328 48.328s21.68 48.328 48.328 48.328c10.573 0 20.046-3.46 27.394-10.005a40.371 40.371 0 004.073-4.2 7.5 7.5 0 00-.924-10.567zM439.651 133.875c-3.731 0-6.817 2.727-7.394 6.295a34.629 34.629 0 00-19.937-6.295c-19.206 0-34.831 15.625-34.831 34.831s15.625 34.831 34.831 34.831a34.631 34.631 0 0019.937-6.295c.578 3.568 3.663 6.295 7.394 6.295a7.5 7.5 0 007.5-7.5v-54.662a7.5 7.5 0 00-7.5-7.5zm-27.331 54.663c-10.935 0-19.831-8.896-19.831-19.831s8.896-19.831 19.831-19.831 19.831 8.896 19.831 19.831-8.896 19.831-19.831 19.831z"></path>
			</g>
		</svg>
	);
}

export default TraceMinerals;
