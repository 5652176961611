import {Avatar, Badge, Button, Input, message} from 'antd'
import {
  organization_ExportPriceData_Api,
  organization_ExportPurchasePriceData_Api,
  poll_add_Api,
  post_create_Api,
  referral_getReferralCode_Api
} from 'apis'
import {organizationModel} from 'apis/model'
import {ChannelIcon} from 'components/icons/ChannelIcon'
import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import Null from 'components/NullComponent'
import copyToClipboard from 'copy-to-clipboard'
import {ROOT_URL} from 'envs/_current/config'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import * as moment from 'moment'
import React, {useContext, useLayoutEffect, useState} from 'react'
import {nest, withProps} from 'recompose'
import {createRequiredLogin} from 'routes/createRequiredLogin'
import {notifyOnError} from 'views/AppLocals/factory/createErrorEvent'
import {NotificationActionTypes, successNotify,} from 'views/AppLocals/factory/createNotification'
import LoadingPage from 'views/LoadingPage'
import ServerContext from 'views/Server/ServerContext'
import {Login} from '../Auth'
import CreateImagePost from '../CreateImagePost/CreateImagePost'
import CreatePoll from '../CreatePoll'
import {CreateEventFormModal} from "../Event/Events"
import Members from '../MemberSetting/Members.lazy'
import {CreateChannelFormModal} from './components/Channels'
import {CreateProductModal, ExportPriceModal} from "./components/Products"
import Settings from './Settings/Settings'
import {MdGroups} from "react-icons/md";
import channelModel from "../../apis/model/channel";
import Report from "./Report";
import {NavigationContext} from 'modules/navigation/NavigationContext'

export const ModalLayout = ({name, children}) => (
  <ServerContext.Consumer>
    {({setCurrent, product, item}) => (
      <FullSideModalLayout
        right
        title={
          name || (
            <span className="font-semibold flex-1">
              <div
                style={{
                  fontSize: '0.8em',
                }}
                className="text-color-300 capitalize">
                {item.title}
              </div>
              {product && (
                <div className="flex items-center  ">
                  <span className="flex justify-center items-center">
                    {product.icon || (
                      <ChannelIcon
                        premium={item.premium}
                        className="mr-2 text-color-100 text-3xl w-8 h-8"
                      />
                    )}
                  </span>
                  <span className="pl-1 flex-1 capitalize">
                    {product.title}
                  </span>
                </div>
              )}
            </span>
          )
        }
        onCancel={() => setCurrent(null)}>
        {children}
      </FullSideModalLayout>
    )}
  </ServerContext.Consumer>
)
export const ModalLogin = () => {
  const translate = useTranslate()
  return (
    <ModalLayout name={translate('you need login first')}>
      <Login/>
    </ModalLayout>
  )
}
const EnchanedCreateImagePost = () => {
  const t = useTranslate()
  const {item, addNewPost, setCurrent} = useContext(ServerContext)
  const {
    time,
    isLoading,
    handleAsyncAction: handleAsyncPost,
  } = useAsyncAction({
    apiInfo: post_create_Api,
    query: {
      ':prop': item._type,
      ':id': item.id,
    },
    onSuccess: (result) => {
      successNotify(NotificationActionTypes.CREATE, t)
      addNewPost(result)
      setCurrent(null)
    },
    onError: notifyOnError(t),
  })
  return (
    <div className="p-6">
      <CreateImagePost
        isLoading={isLoading}
        handleAsyncPost={(values) => {
          const {fileList, ...rest} = values ?? {}
          handleAsyncPost(rest)
        }}
      />
    </div>
  )
}
const EnchanedSettings = () => {
  const {setCurrent} = useContext(ServerContext)
  return <Settings onCancel={() => alert(setCurrent(null))}/>
}
const EnchanedCreatePoll = () => {
  const t = useTranslate()
  const {item, addNewPost, setCurrent} = useContext(ServerContext)
  const {
    time,
    isLoading,
    handleAsyncAction: handleAsyncPost,
  } = useAsyncAction({
    apiInfo: poll_add_Api,
    query: {
      ':prop': item._type,
      ':id': item.id,
    },
    onSuccess: (result) => {
      successNotify(NotificationActionTypes.CREATE, t)
      addNewPost(result)
      setCurrent(null)
    },
    onError: notifyOnError(t),
  })
  return (
    <div className="p-6">
      <CreatePoll
        isLoading={isLoading}
        onSubmit={handleAsyncPost}
      />
    </div>
  )
}
const Invite = () => {
  const t = useTranslate()
  const {item} = useContext(ServerContext)
  const [toggle, setToggle] = useState(false)
  const [toggleCopy2, setToggleCopy2] = useState(false)

  const {response} = useAsync({
    apiInfo: referral_getReferralCode_Api,
    values: {
      target_id: item.id,
      target_type: item._type,
    },
  })

  useLayoutEffect(() => {
    let timeout
    if (toggle) {
      timeout = setTimeout(() => setToggle(false), 1500)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [toggle])
  useLayoutEffect(() => {
    let timeout
    if (toggleCopy2) {
      timeout = setTimeout(() => setToggleCopy2(false), 1500)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [toggleCopy2])

  const data = _.get(response, 'data')
  if (!data) return <LoadingPage/>

  const inviteLink = ROOT_URL + '/invite/' + _.get(data, 'referral_code')

  const timeout = moment(_.get(data, 'created'))
    .add(_.get(data, 'timeout'), 'second')
    .fromNow()

  return (
    <div className="flex flex-col py-3 px-5 pb-5 verticalList__large">
      <div className="text-center font-bold text-color-000 pt-3 mb-6 uppercase">
        {t('invite your friends join to this organization')}
      </div>
      <div className="w-full flex flex-col rounded-lg">
        <div
          className="w-full relative flex justify-center items-center"
          style={{height: 200}}>
          <img
            className="rounded-lg background-200"
            alt={item.title}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            src={
              item.cover_photo
            }></img>
          <Avatar
            size={80}
            className="flex flex-center rounded-lg shadow-md background-100 border border-color-50"
            src={item.avatar}
            icon={
              <MdGroups
                size={60}
                className="text-color-500"
              />
            }
          />
        </div>
        <div className="verticalList">
          <div className="font-bold text-lg">
            {item.title}{' '}
          </div>
          <div
            style={{
              justifySelf: 'flex-end',
              display: 'flex'
            }}>
            <div className="flex items-center mr-3 rounded-full px-3 py-1 background-200">
              <Badge
                color="blue"
                text={
                  <span
                    style={{
                      fontWeight: '400'
                    }}>
                    {item.total_members}{' '}
                    {t('members')}
                  </span>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-3">
        <div className="mb-2">
          {t(
            'Send invitation to join the provider to your friends, by sending a copy of the page link below'
          )}
        </div>
        <div
          onClick={() => {
            copyToClipboard(inviteLink)
            message.success(t('copied'))
            setToggle(true)
          }}>
          <Input
            value={inviteLink}
            suffix={
              <Button
                ghost={toggle}
                className="button-rounded-full text-xs font-medium px-3"
                size="small"
                type="primary">
                <span className="leading-tight italic">
                  {t(toggle ? 'copied' : 'copy')}
                </span>
              </Button>
            }
          />
        </div>
        <div className="mb-2 text-xs text-color-300 italic">
          {_.get(data, 'timeout', 0) === 0 || _.get(data, 'no_expired', 0) === 1
            ? t('Permanent link')
            : `${t('your invite link expires')} ${timeout}`}
        </div>

        <div className="pt-5">
          <div className="mb-1 font-medium text-sm text-color-000 italic leading-tight">
            {t(
              'or you can copy the link of the page here to send it to your friends in other ways'
            )}
          </div>
          <div
            onClick={() => {
              copyToClipboard(
                ROOT_URL + organizationModel.getLinkToDetail(item)
              )
              message.success(t('copied'))
              setToggleCopy2(true)
            }}>
            <Input
              className="rounded-lg"
              value={ROOT_URL + organizationModel.getLinkToDetail(item)}
              suffix={
                <Button
                  ghost={toggleCopy2}
                  className="button-rounded-full text-xs font-medium px-3"
                  size="small"
                  type="primary">
                  <span className="leading-tight italic">
                    {t(toggleCopy2 ? 'copied' : 'copy')}
                  </span>
                </Button>
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}
const EnchanedPriceQuotes = () => {
  const {match, location, history} = useContext(NavigationContext);
  console.log(11111111, match, location, history)
  return <Report reportId={location?.search?.id}/>
}
export const routes = [
  {
    path: 'settings',
    name: 'settings',
    component: EnchanedSettings,
    exact: true,
  },

  {
    path: 'memberList',
    name: 'members',
    component: withProps({
      name: 'members',
    })(nest(ModalLayout, Members)),
    exact: true,
  },

  {
    path: 'share',
    name: 'share',
    component: createRequiredLogin(ModalLogin)(
      withProps({})(nest(ModalLayout, Invite))
    ),
    exact: true,
  },
  {
    path: 'createPoll',
    name: 'Create Poll',
    component: createRequiredLogin(ModalLogin)(
      nest(ModalLayout, EnchanedCreatePoll)
    ),
    exact: true,
  },
  {
    path: 'createImagePost',
    name: 'createImagePost',
    component: createRequiredLogin(ModalLogin)(
      nest(ModalLayout, EnchanedCreateImagePost)
    ),
    exact: true,
  },
  {
    path: 'create-channel',
    name: 'create-channel',
    component: createRequiredLogin(
      ModalLogin
    )(() => {
      const {setCurrent} = useContext(
        ServerContext
      )
      const history = useHistory()
      return (
        <CreateChannelFormModal
          {...{
            onCancel: () =>
              setCurrent(null),
            onSuccess: ([__, data]) => {
              history.push(
                channelModel.getLinkToDetail(
                  data.response.data
                )
              )
              setCurrent(null)
            }
          }}
        />
      )
    }),
    exact: true
  },
  {
    path: 'create-product',
    name: 'create-product',
    component: createRequiredLogin(
      ModalLogin
    )(() => {
      const {setCurrent} = useContext(
        ServerContext
      )
      const history = useHistory()
      return (
        <CreateProductModal
          {...{
            onCancel: () =>
              setCurrent(null),
            onSuccess: ([__, data]) => {
              history.replace(
                `/workspace/manage?rel=product-list&tab=unlisted-products`
              );
              setCurrent(null)
            }
          }}
        />
      )
    }),
    exact: true
  },
  {
    path: 'createPurchaseProduct',
    name: 'createPurchaseProduct',
    component: createRequiredLogin(
      ModalLogin
    )(() => {
      const {setCurrent} = useContext(
        ServerContext
      )
      const history = useHistory()
      return (
        <CreateProductModal
          {...{
            onCancel: () =>
              setCurrent(null),
            onSuccess: ([__, data]) => {
              history.replace(
                `/workspace/purchases/products`
              );
              setCurrent(null)
            },
            initialValues: {
              product_type: '01'
            }
          }}
        />
      )
    }),
    exact: true,
  },
  {
    path: 'create-event',
    name: 'create-event',
    component: createRequiredLogin(ModalLogin)(() => {
      const {setCurrent} = useContext(ServerContext)
      const history = useHistory()
      return (
        <CreateEventFormModal
          {...{
            onCancel: () => setCurrent(null),
            onSuccess: ([__, data]) => {
              history.push(getLinkToDetail(data.response.data))
              setCurrent(null)
            },
          }}
        />
      )
    }),
    exact: true
  },
  {
    path: 'exportPriceData',
    name: 'exportPriceData',
    component: createRequiredLogin(
      ModalLogin
    )(() => {
      const {
        setCurrent,
        downloadFile = Null,
      } = useContext(ServerContext)

      const t = useTranslate()

      return (
        <ExportPriceModal
          title={(
            <div className="font-bold">
              {t('export sales data')}
            </div>
          )}
          asyncConfigs={({id}) => {
            return {
              apiInfo: organization_ExportPriceData_Api,
              query: {
                ':id': id
              }
            }
          }}
          {...{
            onCancel: () => setCurrent(null),
            onSuccess: ({downloadURL}) => {
              downloadFile(downloadURL)
            }
          }} />
      )
    }),
    exact: true
  },
  {
    path: 'exportPurchasingPriceData',
    name: 'exportPurchasingPriceData',
    component: createRequiredLogin(
      ModalLogin
    )(() => {
      const {
        setCurrent,
        downloadFile = Null,
      } = useContext(ServerContext)

      const t = useTranslate()

      return (
        <ExportPriceModal
          title={(
            <div className="font-bold">
              {t('export purchasing data')}
            </div>
          )}
          asyncConfigs={({id}) => {
            return {
              apiInfo: organization_ExportPurchasePriceData_Api,
              query: {
                ':id': id
              }
            }
          }}
          {...{
            onCancel: () => setCurrent(null),
            onSuccess: ({downloadURL}) => {
              downloadFile(downloadURL)
            }
          }} />
      )
    }),
    exact: true
  },
  {
    path: 'price-quotes',
    name: 'price-quotes',
    component: EnchanedPriceQuotes,
    // exact: true,
  },
]
