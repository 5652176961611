export const REQUEST_LOGIN_SOCIAL =
  'REQUEST_LOGIN_SOCIAL'
export const REQUEST_LOGIN_TOKEN =
  'REQUEST_LOGIN_TOKEN'
export const LOGIN_SUCCESS =
  'LOGIN_SUCCESS'
export const LOGIN_FAILURE =
  'LOGIN_FAILURE'
export const REQUEST_REFRESH_TOKEN =
  'REQUEST_REFRESH_TOKEN'
export const UPDATE_REFRESH_TOKEN =
  'UPDATE_REFRESH_TOKEN'
export const LOGOUT = 'LOGOUT'
export const SET_LOGIN = 'SET_LOGIN'
export const CHECK_AUTHENTICATE_FAIL =
  'CHECK_AUTHENTICATE_FAIL'
