import {Space, Tag} from 'antd'
import Description from '../../../components/Feed/Description'
import {DatasetIcon} from '../../../components/icons/DatasetIcon'
import _ from 'lodash'
import useTranslate from '../../../modules/local/useTranslate'
import React from 'react'
import {Link} from 'react-router-dom'
import {paths} from "../../MainPage/contains";

export const DatasetItem = ({
                              className,
                              item
                            }) => {
  const t = useTranslate()

  return (
    <div
      className={
        'verticalList__small w-full'
      }>
      <div className="flex items-start">
        <div
          style={{
            width: '40px'
          }}>
          <DatasetIcon
            className="mr-2 text-color-100 text-3xl w-8 h-8"
            premium={_.get(
              item,
              'premium',
              0
            )}
          />
        </div>
        <div className="flex flex-col">
          <div className="">
            <Link
              to={`/data/${item.idname}`}
              className="font-medium flex-1 ">
              {item.title}
            </Link>
            <div className="text-sm text-color-300">
              <Description type="dataset">
                {item.description}
              </Description>
            </div>
          </div>
          <div className="flex flex-wrap pt-2">
            {_.get(
              item,
              'categories',
              []
            ).map((e, i) => (
              <Space key={i}>
                <Link
                  to={paths.categoryPath.replace(
                    ':category',
                    e.id
                  ).replace(
                    ':refcategory',
                    e.alt_idname
                  )
                  + "?lang=" + e.language}>
                  <Tag>{t(e.name)}</Tag>
                </Link>
              </Space>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
