import DucksAndOtherPoultry from "../components/Svgs/Categories/DucksAndOtherPoultry";
import Wheat from "../components/Svgs/Categories/Wheat";
import Corn from "../components/Svgs/Categories/Corn";
import CottonFabric from "../components/Svgs/Categories/CottonFabric";
import TraceMinerals from "../components/Svgs/Categories/TraceMinerals";
import SugarCane from "../components/Svgs/Categories/SugarCane";
import AminoAcids from "../components/Svgs/Categories/AminoAcids";
import Pig from "../components/Svgs/Categories/Pig";
import Buffalo from "../components/Svgs/Categories/Buffalo";
import Chicken from "../components/Svgs/Categories/Chicken";
import Fish from "../components/Svgs/Categories/Fish";
import Cocoa from "../components/Svgs/Categories/Cocoa";
import Rubber from "../components/Svgs/Categories/Rubber";
import Vitamin from "../components/Svgs/Categories/Vitamin";
import SoyBean from "../components/Svgs/Categories/SoyBean";
import Shrimp from "../components/Svgs/Categories/Shrimp";
import Coffee from "../components/Svgs/Categories/Coffee";
import Rice from "../components/Svgs/Categories/Rice";
import Steel from "../components/Svgs/Categories/Steel";
import Pepper from "../components/Svgs/Categories/Pepper";
import Gold from "../components/Svgs/Categories/Gold";
import Forex from "../components/Svgs/Categories/Forex";
import Stock from "../components/Svgs/Categories/Stock";
import RealEstate from "../components/Svgs/Categories/RealEstate";
import Cereal from "../components/Svgs/Categories/Cereal";
import Nuts from "../components/Svgs/Categories/Nuts";
import TreeRefreshment from "../components/Svgs/Categories/TreeRefreshment";
import Fruits from "../components/Svgs/Categories/Fruits";
import Petroleum from "../components/Svgs/Categories/Petroleum";
import Cashew from "../components/Svgs/Categories/Cashew";
import OilSeeds from "../components/Svgs/Categories/Oilseeds";
import MilkProducts from "../components/Svgs/Categories/MilkProducts";
import TechnologyAdditives from "../components/Svgs/Categories/TechnologyAdditives";
import SensoryAdditives from "../components/Svgs/Categories/SensoryAdditives";
import TechnicalAdditives from "../components/Svgs/Categories/TechnicalAdditives";
import {BsFillPatchQuestionFill} from "react-icons/bs";

const categoryIcons = {
  "default": BsFillPatchQuestionFill,
    "vit-va-gia-cam-khac": DucksAndOtherPoultry,
    "hat-lua-mi": Wheat,
    "hat-bap-ngo": Corn,
    "bong-vai": CottonFabric,
    "zootechnical-additives": TechnicalAdditives,
    "technological-additives": TechnologyAdditives,
    "khoang-va-vi-luong": TraceMinerals,
    "mia-duong": SugarCane,
    "amino-acid": AminoAcids,
    "heo-lon": Pig,
    "trau-bo": Buffalo,
    "ga": Chicken,
    "ca": Fish,
    "ca-cao": Cocoa,
    "sensory-additives": SensoryAdditives,
    "cao-su": Rubber,
    "vitamin": Vitamin,
    "hat-dau-nanh": SoyBean,
    "tom": Shrimp,
    "ca-phe-nhan-xo": Coffee,
    "gao": Rice,
    "thep": Steel,
    "ho-tieu": Pepper,
    "vang": Gold,
    "ngoai-hoi": Forex,
    "chung-khoan": Stock,
    "bat-dong-san": RealEstate,
    "cac-loai-ngu-coc-khac": Cereal,
    "cac-loai-hat-khac": Nuts,
    "xang-dau": Petroleum,
    "cay-trong-giai-khat-khac": TreeRefreshment,
    "cac-loai-trai-cay-khac": Fruits,
    "hat-dieu": Cashew,
    "hat-co-dau-khac": OilSeeds,
    "cac-san-pham-tu-sua": MilkProducts,
};
export default categoryIcons;