import React from "react";

export const Steel = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 64 64"
		>
			<path fill="#8a8b8e" d="M62 2H52L39 15l7 3z"></path>
			<path fill="#e6e8ef" d="M2 52h10v10H2z"></path>
			<path fill="#c8c9cd" d="M12 62H2c0-5.523 4.477-10 10-10z"></path>
			<path fill="#8a8b8e" d="M17.005 46.995L12 52H2l12-12z"></path>
			<path fill="#98999b" d="M62 12L12 62V52L62 2z"></path>
			<path fill="#e6e8ef" d="M17 52h10v10H17z"></path>
			<path fill="#c8c9cd" d="M27 62H17c0-5.523 4.477-10 10-10z"></path>
			<path fill="#98999b" d="M27 62.001l35-35.002.001-8.999L27 52.001z"></path>
			<path fill="#75767a" d="M62.001 18L27 52.001H17l36-34z"></path>
			<path fill="#e6e8ef" d="M14 37h10v10H14z"></path>
			<path fill="#66676c" d="M14 37l23-22h9L24 37z"></path>
			<path fill="#c8c9cd" d="M24 47H14c0-5.523 4.477-10 10-10z"></path>
			<path fill="#86878c" d="M24 47V37l22-22v11z"></path>
		</svg>
	);
}

export default Steel;
