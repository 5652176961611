import {Form, Select} from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {useAppConfig} from "../../../modules/local";

export const CountrySelect = ({
  label,
  name,
  form,
  defaultValue,
  disabled,
  onChange,
  rules = [],
  ...rest
}) => {
  const t = useTranslate();

  const {countries = []} = useAppConfig();

  return (
    <Form.Item
      label={label}
      className="flex-1"
      name={name}
      noStyle
      hasFeedback
      rules={rules} {...rest}>
      <Select
        optionFilterProp={"label"}
        // defaultValue={defaultValue}
        // value={defaultValue}
        disabled={disabled}
        loading={_.isEmpty(countries)}
        showSearch
        style={{
          minWidth: '160px',
          width: '100%'
        }}
        onChange={(value) => {
          onChange({value})
        }}
        placeholder={t(
          'country'
        )}>
        {countries.map(
          ({
             country_code,
             id,
             common_name,
             telephone_code
           }) => (
            <Select.Option
              data-country_code={
                country_code
              }
              key={id}
              label={common_name}
              value={id}>
              {common_name} (
              {country_code}) +
              {telephone_code}
            </Select.Option>
          )
        )}
      </Select>
    </Form.Item>
  )
}
