const env = 'BETA';
const ENV_LABEL = 'DEV';
const APP_NAME = 'dev.feedin.app'
const IS_CORDOVA = false;
const API_ROOT_URL =
  'https://dev.feedin.app/api/v1';
const ROOT_URL =
  'https://dev.feedin.app';
const STORAGE_URL =
  'https://dev.feedin.app/storage';
const STORAGE_GEO_VN_DATA_URL =
  'https://feedin.me/storage/geodata/json/vietnamLow.json'
const STORAGE_GEO_US_DATA_URL =
  'https://feedin.me/storage/geodata/json/usaLow.json'
const SOCKET_URL =
  'https://dev-socket.feedin.app';
const FB_ID = '807464506358167';
const GG_ID =
  '759535059502-ohstjkt8dbfitibjht4fduliea04kg8l.apps.googleusercontent.com';
const ANDROID_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=feedin.app';
const APPLE_ITUNES_LINK =
  'https://itunes.apple.com/us/app/feedin-app/id1401245325';
const GOOGLE_API_KEY =
  'AIzaSyBncj7sGxT_yYhplM4JrP5ZH8KZEf5I8nE';
const FIREBASE_MESSAGING_SENDER_ID =
  '713485216987';
const CONTACT_EMAIL =
  'contact@feedin.me'
const CONTACT_PHONE =
  '090 9 31 35 18'
const GOOGLE_DATA_AD_CLIENT =
  'ca-pub-89557972611569631'
const GOOGLE_DATA_AD_ENABLED = false
const GOOGLE_DATA_AD_SLOT_RECOMMENDED_UNIT = '59972704721'
const GOOGLE_DATA_AD_SLOT_IN_FEED_UNIT = '58252394581'
const GOOGLE_DATA_AD_SLOT_IN_ARTICLE_UNIT = '30207810151'
const APP_COPYRIGHT = 'Copyright ©2024 FeedIn'

export {
  env,
  ENV_LABEL,
  APP_NAME,
  IS_CORDOVA,
  ROOT_URL,
  API_ROOT_URL,
  STORAGE_URL,
  SOCKET_URL,
  STORAGE_GEO_VN_DATA_URL,
  STORAGE_GEO_US_DATA_URL,
  FB_ID,
  GG_ID,
  ANDROID_PLAY_LINK,
  APPLE_ITUNES_LINK,
  GOOGLE_API_KEY,
  FIREBASE_MESSAGING_SENDER_ID,
  CONTACT_EMAIL,
  CONTACT_PHONE,
  GOOGLE_DATA_AD_CLIENT,
  GOOGLE_DATA_AD_ENABLED,
  GOOGLE_DATA_AD_SLOT_RECOMMENDED_UNIT,
  GOOGLE_DATA_AD_SLOT_IN_FEED_UNIT,
  GOOGLE_DATA_AD_SLOT_IN_ARTICLE_UNIT,
  APP_COPYRIGHT
}
