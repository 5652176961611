import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import React, { useContext } from 'react'
import { IoHome } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import moment from "moment";
import useTranslate from "../../../../modules/local/useTranslate";

const ActionButtons = ({ item }) => (
  <div className="inline-flex flex-row space-x-1">
    <Link
      to={getLinkToDetail(item)}
      className="rounded-full w-10 h-10 flex items-center justify-center background-200 hover:bg-primary text-color-500 hover:text-white">
      <IoHome  />
    </Link>
  </div>
)

const CalendarAvatar = ({ time }) => {
    const { isSm } = useContext(LayoutContext)
    const dt = moment(time) || moment()

    const LeftPattern = () => (
        <div
            style={{
                position: 'absolute',
                width: 8,
                height: 8,
                top: 8,
                left: 16,
                borderRadius: 8,
                backgroundColor: 'var(--background)',
            }}
        />
    )

    const RightPattern = () => (
        <div
            style={{
                position: 'absolute',
                width: 8,
                height: 8,
                top: 8,
                right: 16,
                borderRadius: 8,
                backgroundColor: 'var(--background)',
            }}
        />
    )

    var month = dt.format('MMMM')
    var day = dt.format('DD')

    return (
        <div
            style={{
                zIndex: 1,
                width: isSm ? 65 : 80,
                height: isSm ? 65 : 80,
                boxShadow: 'rgba(0, 0, 0, 0.15) 0px 6px 6px 0px',
                borderRadius: '0.65rem',
            }}
            className="flex flex-col flex-center gap-1 bg-primary-700 text-white p-2 relative">
            <LeftPattern />
            <RightPattern />
            <div className="flex flex-center font-bold text-2xs md:text-sm uppercase truncate mt-1">
                {month}
            </div>
            <div
                style={{width: '90%',lineHeight: 1.4}}
                className="flex flex-center rounded-md font-bold text-xs md:text-xl text-primary-700 bg-white tracking-wide">
                {day}
            </div>
        </div>
    )
}

const EventSettingHeader = ({ event }) => {

    const t = useTranslate()
    const item = event || {}

    if (_.isEmpty(item)) return null

    return (
        <div className="flex py-3 w-full container mx-auto flex-col">
            <div className="flex items-center gap-3">
                <CalendarAvatar time={ _.get(event, 'time_open')} />
                <div className="flex flex-col gap-0 md:gap-1 flex-1">
                    <Link
                        className="text-base md:text-xl font-bold text-color-000 hover:text-primary no-underline flex-1"
                        title={item.title}
                        to={getLinkToDetail(item)}>
                        {getTitle(item)}
                    </Link>
                    <div className="flex flex-col md:flex-row gap-x-2 uppercase text-xs md:text-sm text-color-100 opacity-75 font-semibold tracking-wide">
                        <span>
                            <span className="font-normal capitalize italic opacity-50 mr-2">{t('from')}</span>
                            {moment(_.get(event, 'time_open')).format('hh:mm a - Do MMMM, YYYY')}
                        </span>
                        {_.get(event, 'time_close') && (
                            <span>
                                <span className="font-normal lowercase italic opacity-50 mr-2">{t('to')}</span>
                                {moment(_.get(event, 'time_close')).format('hh:mm a - Do MMMM, YYYY')}
                            </span>
                        )}
                    </div>
                </div>
                <ActionButtons {...{ item }} />
            </div>
        </div>
    )
}

export default EventSettingHeader
