import Modal from 'antd/lib/modal/Modal';
import ErrorBoundary from 'components/error/ErrorBoundary';
import ContentLayout from 'components/layouts/Default/ContentLayout';
import ScrollTopOnChange from 'components/ScrollTopOnChange';
import GlobalAmchartConfig from 'modules/chart/GlobalAmchartConfig.lazy';
import useTranslate from 'modules/local/useTranslate';
import NavigationProvider from 'modules/navigation/NavigationProvider';
import GlobalShortCuts from 'modules/shortcuts';
import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import {nest, withProps} from 'recompose';
import NoFoundPage from 'views/NoFoundPage';
import LoadMeta from '../meta/LoadMeta';
import {CheckEmail} from './CheckEmail';
import RouteWithSubRoutes from './RouteWithSubRoutes';
import FirstSelectCategory from "../views/SelectCategory/FirstSelectCategory";
import {compose} from "redux";


export const RedirectToModal = compose(
  withProps(
    props => ({
      to: {
        ...props.location,
        state: {
          isModal: true
        }
      }
    })
  )
)(Redirect)

export const createRoutes = ({modalRoutesConfig = [], routesConfig = []}) => {
  const modalRoutes = modalRoutesConfig.map(
    (route, i) => (
      <RouteWithSubRoutes
        key={i}
        {...route} />
    )
  );
  const redirectModalRoutes = modalRoutesConfig.map(
    (route, i) => (
      <Route
        key={i}
        {...route}
        component={RedirectToModal}
      />
    )
  );
  const mainRoutes = [
    ...redirectModalRoutes,
    ...routesConfig.map((route, i) => (
      <RouteWithSubRoutes
        key={i}
        {...route} />
    )),
    <Route
      key="page_not_found"
      path="/"
      withSubDomain={true}
      render={nest(
        ContentLayout,
        NoFoundPage
      )}/>
  ];
  const Routes = ({
                    match,
                    location,
                    history
                  }) => {
    const isModal = !!(
      location.state &&
      location.state.isModal
    );
    const saveLocationRef = useRef(
      location
    );

    if (!isModal) {
      saveLocationRef.current = location;
    }

    const mainLocation = isModal
      ? saveLocationRef.current
      : location;
    return (
      <ErrorBoundary>
        <NavigationProvider
          {...{
            match,
            history,
            location,
            isModal,
            mainLocation,
            saveLocation: saveLocationRef.current,
            routesConfig,
            modalRoutesConfig
          }}>
          <ErrorBoundary>
            <CheckEmail/>
          </ErrorBoundary>
          <ErrorBoundary>
            <GlobalAmchartConfig/>
          </ErrorBoundary>
          <ErrorBoundary>
            <LoadMeta/>
          </ErrorBoundary>
          <ErrorBoundary>
            <FollowStater/>
          </ErrorBoundary>
          <ErrorBoundary>
            <GlobalShortCuts/>
          </ErrorBoundary>
          <ErrorBoundary>
            <ScrollTopOnChange
              value={mainLocation.pathname}/>
          </ErrorBoundary>
          <ErrorBoundary>
            <Switch
              location={mainLocation}>
              {mainRoutes}
            </Switch>
          </ErrorBoundary>
          {isModal && (
            <ErrorBoundary>
              <Switch location={location}>
                {modalRoutes}
              </Switch>
            </ErrorBoundary>
          )}
        </NavigationProvider>
      </ErrorBoundary>
    );
  };
  return Routes;
};


const FollowStater = () => {
  const [open, setOpen] = useState()
  const t = useTranslate()
  const showSelectCategories = useSelector(state => state.auth && state.auth.showSelectCategories)

  useEffect(() => {
    let to
    if (showSelectCategories)
      to = setTimeout(() => {
        setOpen(true)
      }, 2000)
  }, [showSelectCategories])
  return (
    <>
      <Modal
        className="custom-modal"
        visible={open}
        maskClosable={false}
        centered
        width={720}
        footer={null}
        closable
        onCancel={() => {
          setOpen(false)
        }}
        destroyOnClose
        title={
          <div className="text-center font-bold text-color-000 uppercase">
            {t('select category')}
          </div>
        }
      >
        <FirstSelectCategory
          onSuccess={() => {
            setOpen(false)
          }}
        />
      </Modal>
    </>
  )
}