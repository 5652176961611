import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {createAsyncAction} from '.'
import {getAsynCacheSelector, getCacheSelector} from './selectors'
import useDispatchAsyncActionOnly from './useDispatchAsyncActionOnly'

function useAsyncWithCache({
  once = true,
  cacheId,
                             query = {},
  values,
                             apiInfo,
}) {
  const data = useSelector(
    (state) => {
      return getAsynCacheSelector(state, cacheId)
    },
    (left, right) => left.updated === right.updated
  )
  const cache = useSelector((state) => {
    return getCacheSelector(state, cacheId)
  })
  const handleDispatchAsyncAction = useDispatchAsyncActionOnly()
  const handleAction = () => {
    const action = createAsyncAction({
      asyncId: cacheId,
      cacheId,
      query,
      values,
      apiInfo: {
        ...apiInfo,
      },
    })
    handleDispatchAsyncAction(action)
  }
  useEffect(() => {
    if (once) {
      if (!data.success) {
        const action = createAsyncAction({
          asyncId: cacheId,
          cacheId,
          query,
          values,
          apiInfo: {
            ...apiInfo,
          },
        })
        if (Object.values(action?.query || {}).includes(null)) {
          return
        }
        handleDispatchAsyncAction(action)
      }
    } else {
      const action = createAsyncAction({
        asyncId: cacheId,
        cacheId,
        query,
        values,
        apiInfo: {
          ...apiInfo,
        },
      })
      handleDispatchAsyncAction(action)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheId])
  return {
    ...data,
    cache,
    handleDispatchAsyncAction: handleAction,
  }
}

export default useAsyncWithCache
