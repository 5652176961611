import _ from 'lodash'

export default function getLinkToDetail(
  item = {}
) {
  const idname = _.get(item, 'idname')
  const { _type } = item || {}
  switch (_type) {
    case 'user':
      return (
        '/user/' +
        (idname
          ? idname
          : _.get(item, 'username'))
      )
    case 'poll_question':
      return (
        '/news/poll_question/' +
        (idname
          ? idname
          : _.get(item, 'id'))
      )
    case 'post':
      return (
        '/news/post/' +
        (idname
          ? idname
          : _.get(item, 'id'))
      )
    case 'article':
      return (
        '/news/article/' +
        (idname
          ? idname
          : _.get(item, 'id'))
      )
    case 'keyword':
      return (
        '/t/' +
        (idname
          ? idname
          : _.get(item, 'id'))
      )
    case 'channel':
      if (
        [
          'organization',
        ].includes(
          _.get(item, 'owner._type', '')
        )
      ) {
        return '/channel/' + (idname ? idname : _.get(item, 'id'))
      }

      return getLinkToDetail(
        _.get(item, 'owner')
      )
    case 'organization':
      return (
        '/organization/' +
        (idname
          ? idname
          : _.get(item, 'idname'))
      )
    case 'dataset':
      return (
        '/data/' +
        (idname
          ? idname
          : _.get(item, 'username'))
      )
    default:
      return (
        '/' +
        _.get(item, '_type') +
        '/' +
        (idname
          ? idname
          : _.get(item, 'idname'))
      )
  }
}
