import {paths} from './contains'
import Main from './Main.lazy'

export default {
  paths,
  path: [
    '/',
    paths.newPath,
    paths.followingPath,
    paths.mySpacePath,
    paths.mySpaceNewsPath,
    paths.mySpaceDataPath,
    paths.mySpaceVietnamMarketPath,
    paths.workspacePath,
    paths.followingCategoryPath,
    paths.categoryPath,
    paths.defaultCategoryPath,
  ],
  withSubDomain: true,
  component: Main,
  // exact: true
}
