import { useEffect } from 'react'
export const DidOnMount = ({
  onMount
}) => {
  useEffect(() => {
    onMount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}
