import classNames from 'classnames'
import React from 'react'
import './Menu.css'

const Menu = React.forwardRef(({className, ...props}, ref) => (
  <div
    {...props}
    ref={ref}
    className={classNames('inline-block', className)}
  />
))

export default Menu
