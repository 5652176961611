import {Button, Form, Modal, Select} from 'antd'
import Toggle from 'components/Toggle'
import UseState from 'components/UseState'
import _ from 'lodash'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import PlusSquareOutlined from "@ant-design/icons/lib/icons/PlusSquareOutlined";
import MinusSquareOutlined from "@ant-design/icons/lib/icons/MinusSquareOutlined";
import {ProductPriceSetInfo} from "../components/ProductPriceSetInfo";
import {emptyArray} from "../../../../../helpers/emptyObjects";
import {BsDashSquare, BsPlusSquare} from "react-icons/bs";

export const AddProductPriceSetForm = ({
    product, contractTypeCode, handleOnNewSubmitSetGroup,
    productPriceSetsByContractType,
    update, setUpdate,
    isToggle, toggle
}) => {
  const t = useTranslate();
  const [form] = Form.useForm();
  const {
    product_units = [],
    product_price_units = [],
    contract_types = [],
    delivery_types = [],
    incoterm_types = [],
    transport_types = [],
    countries = [],
    provinces = [],
  } = useAppConfig()

  return (
    <div className="w-full p-3">
      <ProductPriceSetInfo
        product={product}
      />
      <UseState initialValue={{ step: 0, value: {} }}>
        {(([{step, value}, setState]) => (
            <div className="w-full pt-6 overflow-y-auto">
                {step === 0 && (
                  <Form className="space-y-3"
                        form={form}
                        initialValues={{
                            contract_type_cd: contractTypeCode,
                            delivery_type_cd: null,
                            incoterm_type_cd: null,
                            transport_type_cd: null,
                            unit_id: null,
                            unit2_id: null,
                            price_unit_id: null,
                            price_unit2_id: null,
                            country_id: 237,
                            province_id: null
                        }}
                        onFinish={(values) => {
                            const productPriceSetFilter = _.filter(productPriceSetsByContractType || emptyArray, (e => {
                                if (
                                    e.delivery_type_cd === values.delivery_type_cd
                                    && e.incoterm_type_cd === values.incoterm_type_cd
                                    && e.transport_type_cd === values.transport_type_cd
                                    && _.get(e, 'unit.id') === values.unit_id
                                    && _.get(e, 'unit2.id') === values.unit2_id
                                    && _.get(e, 'price_unit.id') === values.price_unit_id
                                    && _.get(e, 'price_unit2.id') === values.price_unit2_id
                                ) {
                                    const locationFilter = _.filter(e.data || emptyArray, (e => {
                                        if (!!values.province_id) {
                                            if (e.location._type === 'province' && e.location.id === values.province_id) {
                                                return true;
                                            }
                                        } else {
                                            if (e.location._type === 'country' && e.location.id === values.country_id) {
                                                return true;
                                            }
                                        }

                                        return false;
                                    }));

                                    if (locationFilter.length > 0) return true;
                                }
                                return false;
                            }));
                            if (productPriceSetFilter.length > 0) {
                                Modal.warning({
                                    title: t('choose another option for new price set'),
                                    content: t('These options are already declared, cannot be added'),
                                });
                            } else {
                                handleOnNewSubmitSetGroup(
                                    {
                                        contract_type_cd: contractTypeCode,
                                        delivery_type_cd: null,
                                        incoterm_type_cd: null,
                                        transport_type_cd: null,
                                        unit_id: null,
                                        unit2_id: null,
                                        price_unit_id: null,
                                        price_unit2_id: null,
                                        country_id: 237,
                                        province_id: null,
                                        ...values
                                    }
                                );
                                setUpdate(Date.now());
                                toggle();
                            }
                        }}
                        {...{
                            labelCol: {span: 8},
                            wrapperCol: {span: 16},
                        }}>
                        {(values) => {
                            const isSpotContract = (contractTypeCode === 'spot');
                            return (
                                <div className="flex flex-col">
                                    {!isSpotContract && (
                                        <div className="flex flex-col">
                                            <Form.Item
                                                label={
                                                    <span className="text-sm text-color-400 italic">
                                                        {t('delivery type')}
                                                    </span>
                                                }
                                                className="flex-1"
                                                name="delivery_type_cd">
                                                <Select
                                                    showSearch
                                                    virtual={false}
                                                    optionFilterProp="label"
                                                    placeholder={t('please select')}
                                                    style={{
                                                        width: '100%'
                                                    }}>
                                                    {!_.isEmpty(delivery_types) &&
                                                    Object.keys(delivery_types).map(
                                                        (value, key) => {
                                                            return (
                                                                <Select.Option
                                                                    key={value}
                                                                    value={value}
                                                                    label={delivery_types[value]}>
                                                                    {delivery_types[value]}
                                                                </Select.Option>
                                                            )
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label={
                                                    <span className="text-sm text-color-400 italic">
                                                        {t('incoterm')}
                                                    </span>
                                                }
                                                className="flex-1"
                                                name="incoterm_type_cd">
                                                <Select
                                                    showSearch
                                                    allowClear={true}
                                                    virtual={false}
                                                    optionFilterProp="label"
                                                    placeholder={t(
                                                        'incoterm'
                                                    )}
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                >
                                                    {!_.isEmpty(incoterm_types) &&
                                                    Object.keys(incoterm_types).map(
                                                        (value, key) => {
                                                            return (
                                                                <Select.Option
                                                                    key={value}
                                                                    value={value}
                                                                    label={value + ' - ' + incoterm_types[value]}>
                                                                    {value + ' - ' + incoterm_types[value]}
                                                                </Select.Option>
                                                            )
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label={
                                                    <span className="text-sm text-color-400 italic">
                                                        {t('transport type')}
                                                    </span>
                                                }
                                                className="flex-1"
                                                name="transport_type_cd">
                                                <Select
                                                    showSearch
                                                    allowClear={true}
                                                    virtual={false}
                                                    optionFilterProp="label"
                                                    placeholder={t('transport type')}
                                                    style={{
                                                        width: '100%'
                                                    }}>
                                                    {!_.isEmpty(transport_types) &&
                                                    Object.keys(transport_types).map(
                                                        (value, key) => {
                                                            return (
                                                                <Select.Option
                                                                    key={value}
                                                                    value={value}
                                                                    label={transport_types[value]}>
                                                                    {transport_types[value]}
                                                                </Select.Option>
                                                            )
                                                        }
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    )}

                                    <Form.Item
                                        label={
                                            <span className="text-sm text-color-400 italic">
                                                {t('price unit')}
                                            </span>
                                        }
                                        className="flex-1"
                                        name={'price_unit_id'}>
                                        <Select
                                            showSearch
                                            optionFilterProp={"label"}
                                            size="middle"
                                            style={{
                                                width: '100%'
                                            }}
                                            className="flex-1 flex w-full"
                                            placeholder={t(
                                                'VND'
                                            )}
                                            onChange={value => {
                                                // setPriceUnitId(value)
                                            }
                                            }
                                            options={product_price_units.map(
                                                ({
                                                     id: value,
                                                     name: label
                                                 }) => ({
                                                    value,
                                                    label
                                                })
                                            )}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label={
                                            <span className="text-sm text-color-400 italic">
                                                {t('unit')}
                                            </span>
                                        }
                                        className="flex-1"
                                        name={'unit_id'}>
                                        <Select
                                            showSearch
                                            optionFilterProp={"label"}
                                            size="middle"
                                            style={{
                                                width: '100%'
                                            }}
                                            className="flex-1 flex w-full"
                                            placeholder={t(
                                                'unit'
                                            )}
                                            onChange={value => {
                                                // setUnitId(value)
                                            }
                                            }
                                            options={product_units.map(
                                                ({
                                                     id: value,
                                                     name: label
                                                 }) => ({
                                                    value,
                                                    label
                                                })
                                            )}
                                        />
                                    </Form.Item>
                                    {!isSpotContract && (
                                        <Toggle>
                                            {(isToggle, toggle) => (
                                                <>
                                                    <Form.Item
                                                        label={
                                                            <span className="flex flex-col italic">
                                                                <span className="font-semibold text-sm text-color-100 leading-tight">
                                                                    {t('basis price')}
                                                                </span>
                                                                <span className="font-normal text-2xs text-color-400 leading-tight">
                                                                    {t('if basis price is required')}
                                                                </span>
                                                            </span>
                                                        }>
                                                        <div
                                                            className="flex items-center"
                                                            onClick={toggle}>
                                                            {isToggle ? (
                                                                <span className="flex items-center gap-2 text-color-100 hover:text-primary-600 cursor-pointer">
                                                                    <BsDashSquare />
                                                                    <span className="font-medium italic">{t('remove the 2nd unit')}</span>
                                                                </span>
                                                            ) : (
                                                                <span className="flex items-center gap-2 text-color-100 hover:text-primary-600 cursor-pointer">
                                                                    <BsPlusSquare />
                                                                    <span className="font-medium italic">{t('add new unit')}</span>
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Form.Item>
                                                    {isToggle && (
                                                        <React.Fragment>
                                                            <Form.Item
                                                                label={
                                                                    <span className="text-sm italic">
                                                                        {t('2nd price unit')}
                                                                    </span>
                                                                }
                                                                className="flex-1"
                                                                name={'price_unit2_id'}>
                                                                <Select
                                                                    showSearch
                                                                    optionFilterProp={"label"}
                                                                    size="middle"
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                    className="flex-1 flex w-full"
                                                                    placeholder={t(
                                                                        'VND'
                                                                    )}
                                                                    onChange={value => {
                                                                        // setPriceUnitId(value)
                                                                    }
                                                                    }
                                                                    options={product_price_units.map(
                                                                        ({
                                                                             id: value,
                                                                             name: label
                                                                         }) => ({
                                                                            value,
                                                                            label
                                                                        })
                                                                    )}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label={
                                                                    <span className="text-sm italic">
                                                                        {t('2nd unit')}
                                                                    </span>
                                                                }
                                                                className="flex-1"
                                                                name={'unit2_id'}>
                                                                <Select
                                                                    showSearch
                                                                    optionFilterProp={"label"}
                                                                    size="middle"
                                                                    style={{
                                                                        width: '100%'
                                                                    }}
                                                                    className="flex-1 flex w-full"
                                                                    placeholder={t(
                                                                        'unit'
                                                                    )}
                                                                    onChange={value => {
                                                                        // setUnitId(value)
                                                                    }
                                                                    }
                                                                    options={product_units.map(
                                                                        ({
                                                                             id: value,
                                                                             name: label
                                                                         }) => ({
                                                                            value,
                                                                            label
                                                                        })
                                                                    )}
                                                                />
                                                            </Form.Item>
                                                        </React.Fragment>
                                                    )}
                                                </>
                                            )}
                                        </Toggle>
                                    )}
                                    <Form.Item
                                        key={"country_id"}
                                        id={"country_id"}
                                        label={
                                            <span className="text-sm text-color-400 italic">
                                                {t('country')}
                                            </span>
                                        }
                                        name="country_id">
                                        <Select
                                            showSearch
                                            virtual={false}
                                            optionFilterProp="label"
                                            placeholder={t(
                                                'please select'
                                            )}
                                            style={{
                                                width: '100%'
                                            }}>
                                            {!_.isEmpty(countries) &&
                                            countries.map(
                                                item => {
                                                    const {
                                                        id,
                                                        location_name: name
                                                    } = item
                                                    return (
                                                        <Select.Option
                                                            key={id}
                                                            value={id}
                                                            label={name}>
                                                            {name}
                                                        </Select.Option>
                                                    )
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        key={"province_id_by_" + values.country_id}
                                        id={"province_id_by_" + values.country_id}
                                        label={
                                            <span className="text-sm text-color-400 italic">
                                                {t('province')}
                                            </span>
                                        }
                                        name="province_id">
                                        <Select
                                            showSearch
                                            virtual={false}
                                            optionFilterProp="label"
                                            placeholder={t(
                                                'please select'
                                            )}
                                            style={{
                                                width: '100%'
                                            }}>
                                            {!_.isEmpty(provinces) &&
                                            provinces.filter(e => e.country_id === values.country_id).map(
                                                item => {
                                                    const {
                                                        id,
                                                        location_name: name
                                                    } = item
                                                    return (
                                                        <Select.Option
                                                            key={id}
                                                            value={id}
                                                            label={name}>
                                                            {name}
                                                        </Select.Option>
                                                    )
                                                }
                                            )}
                                        </Select>
                                    </Form.Item>

                                    <div className="text-center my-3">
                                        <Button
                                            className="button-rounded-md no-border"
                                            type="primary" htmlType="submit">
                                            {t('create')}
                                        </Button>
                                    </div>
                                </div>
                            )
                        }}
                    </Form>
                )}
                {step === 1 && (
                    <div className="w-full">
                        success
                    </div>
                )}
            </div>
        ))}
      </UseState>
    </div>
  )
}


