import {createValue} from 'components/form/utils'
import _ from 'lodash'
import {callApi} from 'modules/asyncCache/callApi'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'
import CreatableSelect from 'react-select/creatable'
import {compose, mapProps, pure} from 'recompose'
import {deepTranslate, getResponseItems, Null, renderElse} from 'views/Shared'
import {dropdownComponents, reactSelectComponents, reactSelectOnTop, SelectWidget,} from './ReactSelectComponents'

const SelectDataSource = ({
                            name,
                            onChange,
                            defaultValue,
                            flex = true,
                            dataSource = [],
                            hiddenOnEmpty = false,
                            ...props
                          }) => {
  const t = useTranslate()
  const style = !!flex ? {width: '100%'} : undefined

  const options = useMemo(
    () => Array.from(dataSource).map(deepTranslate(t)),
    [t, dataSource]
  )

  const isHidden = _.isEmpty(dataSource) && !!hiddenOnEmpty

  const placeholder = _.get(props, 'placeholder', 'select')

  const defaultActiveFirstOption = _.get(
    props,
    'defaultActiveFirstOption',
    options.length === 1
  )

  return renderElse(
    isHidden,
    <SelectWidget
      {...{
        name,
        style,
        options,
        defaultValue,
        defaultActiveFirstOption,
      }}
      onChange={(value) => {
        onChange(createValue(name, value))
      }}
      placeholder={t(placeholder)}
      {...props}
    />
  )
}

export const ReactSelectDataSource = ({
                                        name,
                                        value,
                                        options,
                                        placeholder,
                                        onChange = Null,
                                        ...props
                                      }) => {
  const t = useTranslate()

  const dataSource = Array.from(options || []).map(deepTranslate(t))

  const defaultValue = useMemo(
    () => dataSource.filter(({value: option}) => _.includes(value, option)),
    [value, dataSource]
  )
  return (
    <ReactSelect
      options={dataSource}
      value={defaultValue}
      placeholder={t(placeholder)}
      onChange={({value}) => onChange(createValue(name, value))}
      components={reactSelectComponents}
      menuPlacement="auto"
      {...reactSelectOnTop}
      {...props}
    />
  )
}

export const createControlledReactSelect = ({
                                              placeholder,
                                              withProps = () => {
                                              },
                                              ...props
                                            }) =>
  compose(
    withTranslate,
    mapProps(({name, onChange, translate, ...rest}) => ({
      name,
      menuPlacement: 'auto',
      onChange: ({value}) => {
        onChange(createValue(name, value))
      },
      ...(placeholder
        ? {
          placeholder: translate(placeholder),
        }
        : {}),
      ...withProps({
        name,
        onChange,
        translate,
        ...rest,
      }),
      ...props,
      ...reactSelectOnTop,
      components: reactSelectComponents,
    })),
    pure
  )(ReactSelect)

export const createControlledCreatableReactSelect = ({
                                                       placeholder,
                                                       withProps = () => {
                                                       },
                                                       ...props
                                                     }) =>
  compose(
    withTranslate,
    mapProps(({name, translate, ...rest}) => ({
      name,
      menuPlacement: 'auto',
      ...(placeholder
        ? {
          placeholder: translate(placeholder),
        }
        : {}),
      ...withProps({
        name,
        translate,
        ...rest,
      }),
      ...props,
      ...reactSelectOnTop,
      components: reactSelectComponents,
    }))
  )(CreatableSelect)

const cancellablePromise = (promise) => {
  const isCancelled = {value: false}
  const wrappedPromise = new Promise((res, rej) => {
    promise
      .then((d) => {
        return isCancelled.value ? rej(isCancelled) : res(d)
      })
      .catch((e) => {
        rej(isCancelled.value ? isCancelled : e)
      })
  })

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled.value = true
    },
  }
}

export const AsyncCreatableDataSource = ({
                                           value,
                                           query,
                                           style,
                                           values,
                                           apiInfo,
                                           className,
                                           placeholder,
                                           defaultValue,
                                           menuPlacement,
                                           debounce = 300,
                                           onChange = Null,
                                           creatable = true,
                                           fieldValue = 'id',
                                           fieldLabel = 'name',
                                           searchParam = 'keyword',
                                           ...props
                                         }) => {
  const t = useTranslate()

  const [defaultOptions, setDefaultOptions] = useState()

  const getDataSource = useCallback(
    (keyword) =>
      callApi({
        apiInfo,
        query,
        values: _.isEmpty(searchParam)
          ? values
          : {
            ...values,
            [searchParam]: keyword,
          },
      }).then((response) => {
        const result = Array.from(getResponseItems(response) || []).map(
          (item) => ({
            label: _.get(item, fieldLabel),
            value: _.get(item, fieldValue),
            ...item,
          })
        )
        if (!value || _.find(result, {value})) {
          return result
        } else {
          return [
            {
              value,
              label: value,
            },
            ...result,
          ]
        }
      }),
    [apiInfo, query, values, fieldLabel, fieldValue, searchParam]
  )

  const onSearch = _.debounce((callback = Null) => {
    callback()
  }, debounce)

  const promiseOptions = (keyword) =>
    new Promise((resolve) => {
      onSearch(() => {
        resolve(getDataSource(keyword))
      })
    })

  const _defaultValue = useMemo(
    () => _.find(Array.from(defaultOptions || []), {value}),
    [value, defaultOptions]
  )

  const Component = creatable ? AsyncCreatableSelect : AsyncSelect

  async function init() {
    const response = await getDataSource()
    setDefaultOptions(response)
  }

  useEffect(() => {
    if (!defaultOptions) {
      init()
    }
  }, [])

  // useEffectOnce(() => {
  //   if (!defaultOptions) {
  //     getDataSource().then((value) =>
  //       setDefaultOptions(value)
  //     )
  //   }
  // })

  return (
    <div
      style={style}
      className={className}>
      <Component
        key={defaultOptions}
        cacheOptions={true}
        defaultOptions={true}
        formatCreateLabel={(text) => `${t('create')} "${text}"`}
        defaultValue={_defaultValue}
        onChange={({value}) => {
          onChange(value)
        }}
        backspaceRemovesValue={true}
        placeholder={t(placeholder)}
        loadOptions={promiseOptions}
        menuPlacement="auto"
        components={dropdownComponents}
        {...reactSelectOnTop}
        {...props}
      />
    </div>
  )
}

export const MultiSelectDataSource = ({
                                        name,
                                        value,
                                        options = [],
                                        onChange = Null,
                                        placeholder = 'select',
                                        ...props
                                      }) => {
  const t = useTranslate()

  const defaultValue = useMemo(
    () => options.filter(({value: option}) => _.includes(value, option)),
    [value, options]
  )

  const handleChange = useCallback(
    (value) =>
      onChange(
        createValue(
          name,
          _.isEmpty(value) ? null : value.map((e) => e.value).join(',')
        )
      ),
    [name, onChange]
  )

  return (
    <ReactSelect
      isMulti={true}
      options={options}
      value={defaultValue}
      onChange={handleChange}
      placeholder={t(placeholder)}
      {...props}
      {...reactSelectOnTop}
      components={dropdownComponents}
    />
  )
}

export default SelectDataSource
