import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {Null} from 'views/Shared'

const PartnerFilter = ({
                         owner_id,
                         owner_type,
                         value: filter = {},
                         onChange: setValues = Null,
                       }) => {
  const t = useTranslate()

  return (
    <div className="flex items-start md:items-center flex-col md:flex-row justify-center gap-x-2 gap-y-1">
      <div className="flex text-color-300 font-medium text-sm italic">
        {`${t('filter')} : `}
      </div>
    </div>
  )
}

export default PartnerFilter
