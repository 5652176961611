import { feed_fetchFeedByContainer_Api } from 'apis'
import { getId, getType } from 'apis/model/base'
import { renderEmptyHolder } from 'components/EmptyHolder'
import InputBoardForm from 'components/Feed/InputBoard/InputBoardForm'
import Pure from 'components/Pure'
import _ from 'lodash'
import { permissionCodes } from 'modules/permissions/contants'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, { useContext, useMemo } from 'react'
import { nest } from 'recompose'
import EntityList from 'views/AppLocals/EntityList'
import { bindQueryParams } from 'views/AppLocals/functions/routerHelper'
import { renderFeedItem } from 'views/MainPage/functions/renderFeedItem'
import { Null } from 'views/Shared'
import EventContext from '../EventContext'
import { useLocation } from 'react-router-dom'

const ActivityNews = ({ sample, Wrapper = 'div', Footer = Null }) => {
  const { event } = useContext(EventContext)

  const location = useLocation()

  const [id, prop] = [getId(event), getType(event)]

  const apiInfo = feed_fetchFeedByContainer_Api

  const refreshKey = useMemo(
    () =>
      [
        apiInfo.path.replace(':id', id).replace(':prop', prop),
        `refreshToken=${location?.state?.refreshToken}`,
      ].join('/'),
    [id, prop, apiInfo, location?.state]
  )

  if (_.isEmpty(event)) return null

  const { available_actions } = event

  return (
    <Wrapper className="space-y-3">
      {!!!sample && (
        <PermissionProvider
          permissions={{
            [permissionCodes.canPost]: _.get(
              available_actions,
              permissionCodes.canPost
            ),
          }}>
          <InputBoardForm container={{ id, type: prop }} />
        </PermissionProvider>
      )}

      <Pure input={[id, prop, refreshKey]}>
        <EntityList
          key={refreshKey}
          sample={sample}
          apiInfo={apiInfo}
          renderItem={renderFeedItem}
          RenderEntity={React.Fragment}
          renderEmpty={renderEmptyHolder}
          query={bindQueryParams([{ id }, { prop }])}
          renderWrap={(child, __, count) => (
            <div className="space-y-3">
              {child}
              {count ? <Footer /> : null}
              <div className="h-12"/>
            </div>
          )}
        />
      </Pure>
    </Wrapper>
  )
}

export default nest(Pure, ActivityNews)
