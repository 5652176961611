import Icon, {PlayCircleFilled, PlayCircleOutlined, StarFilled,} from '@ant-design/icons'
import {Avatar, Input, Tooltip} from 'antd'
import {getId} from 'apis/model/base'
import {organizationSchema} from 'apis/schema'
import classNames from 'classnames'
import {AppTextLogoIcon} from 'components/icons/AppTextLogoIcon'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {LoginContext} from 'components/LoginContext'
import getAvatar from 'helpers/getAvatar'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useContext} from 'react'
import {RiSearch2Line} from 'react-icons/ri'
import {useSelector} from 'react-redux'
import {Link, NavLink} from 'react-router-dom'
import {useLocation} from 'react-use'
import {withProps} from 'recompose'
import {getLogin, selectEntities} from 'redux/selectors'
import {staticPaths} from 'routes/staticPaths'
import {DiscoveryContextMenu} from 'views/DiscoveryExplorer/components/DiscoveryExplorerHeader'
import {HeaderButton} from 'views/Organization/components/PageHeader'
import {SearchTypes} from 'views/Search/SearchProvider'
import {Null, renderElse, renderFalse, renderIf, renderSelf,} from 'views/Shared'
import {useDrawerDetector} from 'views/Shared/components/CustomSiderMenu'
import {Queries} from 'views/Shared/enums/Queries'
import WorkspacePicker from 'views/Workspace/components/WorkspacePicker'
import {LocalContext} from '../../modules/local/LocalContext'
import HeaderNavigation from './HeaderNavigation'
import {TiInfoLarge} from "react-icons/ti";
import {auth_getMyJoinedOrganization_Api} from "../../apis";
import {API_ROOT_URL} from "../../envs/_current/config";
import getTitle from "../../helpers/getTitle";
import getLinkToDetail from "../../helpers/getLinkToDetail";
import {BsThreeDots} from "react-icons/bs";
import {AsyncWithCache} from "../../modules/asyncCache/components/Async";
import {TbMenu2} from "react-icons/tb";
import {FaWarehouse} from "react-icons/fa6";

const HeaderToggleOptions = Object.freeze({
  MYSPACE: {
    label: 'Dashboard',
    path: staticPaths.home,
  },
  WORKSPACE: {
    label: 'workspace',
    path: staticPaths.workspace,
    isInvisble: (login, __) => Boolean(!_.get(login, 'primary_org')),
  },
  STARTSPACE: {
    label: 'start',
    path: staticPaths.start,
  },
})

export const
  DrawerHeader = () => {
  const {toggle = Null, hasDrawer = false} = useDrawerDetector()

  const {visibleLeftDrawer, ...rest} = useContext(LayoutContext);


  const showDrawer = Boolean(visibleLeftDrawer && hasDrawer)
  return (
    <div className={classNames(!showDrawer && 'ml-3')}>
      {renderIf(
        showDrawer,
        <HeaderButton
          width={30}
          height={30}
          icon={<TbMenu2 size={15}/>}
          onClick={() => toggle(true)}
        />
      )}
    </div>
  )
}

const HeaderWrapper = ({children, className, Component = 'header'}) => (
  <Component className={className}>
    <div className="flex background-100 items-center">
      <div className="w-full flex flex-wrap items-center justify-between px-3">
        {children}
      </div>
    </div>
  </Component>
)

const StarterNav = ({path, label, isInvisble = renderFalse}) => {
  const t = useTranslate()

  const {isLg} = useContext(LayoutContext)

  const location = useLocation()

  const isActive = location.pathname === _.get(path, 'pathname')

  const Icon = isActive ? PlayCircleFilled : PlayCircleOutlined

  return renderElse(
    isInvisble(true),
    <nav
      style={{
        borderRadius: '0 0 0 20px',
        height: 'var(--header-height)',
        boxShadow: ' inset -4px -10px 9px -5px var(--border-color-base)',
      }}
      className="w-full flex-1 flex background-100 noSrollbar overflow-auto"
      id="headernav">
      <NavLink
        to={path}
        activeClassName="text-primary font-medium border-current px-3"
        tabIndex={3}
        style={{
          borderRadius: '-0.25rem',
        }}
        isActive={(match, location) =>
          location.pathname === _.get(path, 'pathname')
        }
        className={classNames(
          'flex items-center px-1 space-x-2 font-normal flex-shrink-0 truncate border-transparent',
          'md:border-b-4 hover:opacity-75 focus:outline-none'
        )}>
        <div
          className={classNames(
            'border-5 rounded-full flex items-center justify-center',
            'text-color-300'
          )}>
          <Icon
            className={classNames(
              'text-3xl',
              isActive ? 'text-primary' : 'text-color-500'
            )}
          />
        </div>
        <div>{Boolean(isLg) && t(label)}</div>
      </NavLink>
      <div className="flex flex-1 justify-end">
        <LoginNow/>
      </div>
    </nav>
  )
}

const LoginNow = () => {
  const t = useTranslate()
  const history = useHistory()
  const login = useContext(LoginContext)
  if (login) return null
  return (
    <div
      onClick={() => {
        history.push(staticPaths.login)
      }}
      className="flex items-center h-full text-white bg-primary px-2 cursor-pointer">
      {t('Login now')}
    </div>
  )
}

export const ToggleHeader = ({
                               children,
                               options = [HeaderToggleOptions.MYSPACE, HeaderToggleOptions.WORKSPACE],
                             }) => {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const location = useLocation()

  return (
    <HeaderWrapper>
      {children}
      <div
        style={{
          height: 'var(--header-height)',
          borderRadius: `0px 20px 0px 0px`,
          boxShadow: 'var(--border-color-base) 4px 10px 9px -5px inset',
        }}
        className="flex space-x-1 flex-shrink-0 cursor-pointer items-center justify-center truncate background-100">
        {Array.from(options || []).map(
          (
            {label, isInvisble = renderFalse, path = staticPaths.home},
            index
          ) => {
            const _currentPath = path.pathname
            const isActive = location.pathname.startsWith(_currentPath)
            return renderElse(
              isInvisble(login),
              <NavLink
                key={index}
                className="flex flex-wrap items-center"
                to={path}>
                <Avatar
                  size={40}
                  shape="circle"
                  className={classNames(
                    'background-100 m-1 text-color-500 flex-shrink-0 flex items-center justify-center border-5 rounded-full border-blue-100',
                    isActive && 'border-blue-400 text-blue-500 bg-blue-500'
                  )}
                  style={
                    isActive
                      ? {
                        color: '#4172d6',
                      }
                      : {
                        borderColor: 'var(--text-color-500)',
                      }
                  }
                  icon={<StarFilled/>}
                />
                {isActive && (
                  <div className="flex pl-1 pr-2 font-bold text-blue-500 no-underline">
                    {t(label)}
                  </div>
                )}
              </NavLink>
            )
          }
        )}
      </div>
      <StarterNav {...HeaderToggleOptions.STARTSPACE} />
    </HeaderWrapper>
  )
}


export const ShortCutsWidget = () => {
  const t = useTranslate()
  const login = useContext(LoginContext)
  const {isSm} = useContext(LayoutContext)

  const apiPath = auth_getMyJoinedOrganization_Api.path;
  const path = apiPath + '?filter=member&sort_by=recently'

  return (
    <div className="flex flex-row items-center gap-2 px-2">
      {
        renderIf(
          login,
          <AsyncWithCache
            cacheId={path}
            apiInfo={{
              root: API_ROOT_URL,
              path: path,
              method: 'GET',
            }}
          >
            {({result, response}) => {
              const login_id = login?.primary_org?.id;
              const len = isSm ? 6 : 2; // Nếu mobile thì hiển thị 6 cái, còn desktop thì hiển thị 2 cái
              const memberOrgs = _.get(response, 'data.data', []).filter(e => e.id !== login_id);
              const defaultList = memberOrgs.slice(0, len);

              return renderIf(
                defaultList?.length,
                defaultList.map((e, i) => (
                  <Tooltip
                    key={i}
                    title={getTitle(e)}
                    overlayClassName="text-xs italic">
                    <Link to={getLinkToDetail(e)}>
                      <Avatar
                        size={28}
                        className={
                          classNames("rounded-md flex flex-center background-100 cursor-pointer border border-color-50 hover:shadow-lg", isSm && 'shadow-md')
                        }
                        src={getAvatar(e)}
                        icon={<FaWarehouse size={20} className="text-color-500"/>}
                      />
                    </Link>
                  </Tooltip>
                ))
              )
            }}
          </AsyncWithCache>
        )
      }

      <Link className="flex items-center" to={`${staticPaths.discovery.pathname}?type=organization`}>
        <Avatar.Group shape="square" size={28} maxCount={3} className="items-center">
          <Avatar
            size={28}
            shape={"circle"}
            className="rounded-full background-200 flex flex-center cursor-pointer border border-color-50 shadow"
            icon={<FaWarehouse size={14} className="text-color-400"/>}
          />
          <Tooltip title={t('see more')} overlayClassName="text-xs text-color-200 italic">
            <Avatar
              size={28}
              shape={"circle"}
              className="rounded-full flex flex-center cursor-pointer custom-show-more-org border border-color-50 shadow"
              icon={<BsThreeDots size={14} className="text-color-500"/>}
            />
          </Tooltip>
        </Avatar.Group>
      </Link>
    </div>
  );

}

export const SearchWidget = ({type = SearchTypes.KEYWORD}) => {
  const t = useTranslate()
  const history = useHistory()
  const searchParams = new URLSearchParams({type})
  return (
    <Input
      allowClear={true}
      prefix={<RiSearch2Line/>}
      placeholder={t('type to search')}
      className="custom-search-header rounded-full"
      onKeyDown={(event) => {
        const {value: keyword} = event.target
        if (_.isEmpty(keyword)) {
          return
        } else if (event.key === 'Enter') {
          searchParams.set(Queries.KEYWORD, keyword)
          history.push({
            pathname: staticPaths.discovery.pathname,
            search: '?' + searchParams.toString(),
          })
        }
      }}
    />
  )
}

export const LogoHeader = ({
                             style,
                             className,
                             LogoIcon = AppTextLogoIcon,
                           }) => {
  const t = useTranslate()
  const mainLocation = useLocation()
  const {isSm} = useContext(LayoutContext)
  const {primary_org} = useSelector(getLogin) || {}
  const {lang} = useContext(LocalContext)

  const primary_id = _.isString(primary_org) ? primary_org : getId(primary_org)

  const primary_organization =
    useSelector((state) =>
      selectEntities(state, primary_id, organizationSchema)
    ) || {}

  return (
    <div
      style={style}
      className={classNames('w-full m-auto', className)}>
      <div className="w-full flex flex-wrap items-center justify-between px-0 md:px-3">
        <div className="flex items-center gap-1 md:gap-3 justify-start">
          {isSm && (
            <div className="ml-2 flex items-center gap-2">
              {/*<DrawerHeader/>*/}
              <div className="flex flex-center flex-1">
                <LogoIcon to={staticPaths.start}/>
              </div>
            </div>
          )}
        </div>

        {!isSm && (
          <div
            style={{
              top: '100%',
              left: 0,
              right: 0,
              height: 'var(--header-height)',
            }}
            className="w-full px-3 background flex justify-center gap-4 leading-relaxed relative">
            {!_.isEmpty(primary_organization) ? (
              <div className="flex flex-center gap-4 flex-shrink-0 sticky left-0 z-30 truncate">
                <div className="flex flex-wrap flex-row items-center cursor-pointer">
                  <WorkspacePicker
                    primary_organization={primary_organization}
                    IconComponent={({item, isSm}) => (
                      <Tooltip
                        title={`${t('admin of')} ${getTitle(item)}`}
                        overlayClassName="font-medium text-sm"
                        placement="bottomRight">
                        <Avatar
                          size={30}
                          src={getAvatar(item)}
                          icon={
                            <FaWarehouse size={18} className="text-color-500" />
                          }
                          style={{
                              border: mainLocation.pathname.startsWith(staticPaths.workspace.pathname) ?
                                  '2px solid var(--color-primary-600)' :
                                  '2px solid var(--border-color-base)'
                          }}
                          className="flex flex-center rounded-md background-200 z-3"
                        />
                      </Tooltip>
                    )}
                  />
                </div>
              </div>
            ) : (
              <Link
                className="flex flex-wrap flex-row items-center no-underline"
                to={staticPaths.workspace}>
                <Avatar
                  size={30}
                  shape="square"
                  icon={
                    <FaWarehouse size={18} className="text-color-500" />
                  }
                  style={{
                      border: mainLocation.pathname.startsWith(staticPaths.workspace.pathname) ?
                          '2px solid var(--color-primary-600)' :
                          '2px solid var(--border-color-base)'
                  }}
                  className="flex flex-center rounded-md background-200 z-3"
                />
              </Link>
            )}
            <div className="w-full flex items-center justify-between">
              <div className="flex items-center gap-3">
                <Link
                  to={staticPaths.start.pathname}
                  className="flex items-center no-underline gap-2 leading-none">
                  <Avatar
                    size={30}
                    icon={
                      <Icon
                        style={{width: 14, height: 14}}
                        className={
                          mainLocation.pathname.startsWith(
                            staticPaths.start.pathname
                          )
                            ? 'text-primary'
                            : 'text-color-400'
                        }
                        component={TiInfoLarge}
                      />
                    }
                    className={classNames(
                      'flex flex-center hover:background-200 rounded-md',
                      mainLocation.pathname.startsWith(
                        staticPaths.start.pathname
                      )
                        ? 'border-primary bg-primary-50'
                        : 'border-color-50 background-100'
                    )}
                  />
                  {mainLocation.pathname.startsWith(
                    staticPaths.start.pathname
                  ) && (
                    <span className="text-sm font-semibold text-primary">
                        {t('about FeedIn')}
                      </span>
                  )}
                </Link>
              </div>
              <div className="flex items-center gap-3">
                {renderElse(isSm, <ShortCutsWidget/>)}
                <SearchWidget type={SearchTypes.ARTICLE}/>
                <DiscoveryContextMenu/>
              </div>
            </div>
          </div>
        )}

        {!!isSm && <HeaderNavigation/>}
      </div>
    </div>
  )
}

const MainHeader = ({
                      style,
                      className,
                      Title = Null,
                      Footer = Null,
                      renderFooter = renderSelf,
                    }) => {
  const _footerProps = {
    style: {
      zIndex: 31,
      // height: gConfigs.styles.headerHeight,
    },
    className: 'background PageHeader stickyTop shadow flex items-center',
  }
  return (
    <React.Fragment>
      <Title/>
      {renderFooter(
        <div {..._footerProps}>
          <Footer/>
        </div>,
        _footerProps
      )}
    </React.Fragment>
  )
}

export const AppHeader = withProps({
  Footer: LogoHeader,
})(MainHeader)

export default MainHeader
