import {Divider} from 'antd'
import Pure from 'components/Pure'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React, {useContext} from 'react'
import {withRouter} from 'react-router-dom'
import {compose, nest, withProps} from 'recompose'
import {createRequiredLogin, ModalLayout, ModalLogin,} from 'routes/createRequiredLogin'
import CustomBreadcrumb from 'views/MainPage/MySpace/components/CustomBreadcrumb'
import {BoxPlaceholder, Null, renderSelf} from 'views/Shared'
import {CustomKeys} from './CustomKeys'
import {ModuleLayoutWrapper} from './factory/createModule'
import createModuleLayout from './factory/createModuleLayout'
import createSettingLayout from './factory/createSettingLayout'
import {mapPathParam} from './functions/routerHelper'
import AddHostModal from './Host/components/AddHostModal'
import PageTemplate from './Templates/PageTemplate'
import {EditOwnerModal} from "./custom/OwnerPicker";
import GoBackAction from "./custom/GoBackAction";
import {renderIf} from "../Shared";
import MainHeader, {LogoHeader} from "../MainPage/MainHeader";
import {LayoutContext} from "../../components/layouts/Default/LayoutContext";
import {AuthOrganiationsModal} from "../Workspace/components/WorkspacePicker";
import {staticPaths} from "../../routes/staticPaths";
import EventContext, {EventProvider} from "./Event/EventContext";
import EventSettingsLazy from "./Event/Settings";
import Event from './Event'
import {AppEntities} from "./enums";
import EventNav from "./Event/components/EventNav";
import EventSider from "./Event/components/EventSider";
import EventBanner from "./Event/components/EventBanner";
import EventLayout from "./Event/EventLayout";
import EventContent from "./Event/components/EventContent";
import {eventRouteEntities} from "./Event/eventRouteEntities";
import {eventSettings} from "./Event/Settings/EventSettings";
import EventSettingsContent from "./Event/Settings/EventSettingContent";
import DefaultTemplate from "./Templates/DefaultTemplate";
import EventSettingHeader from "./Event/Settings/EventSettingHeader";
import User from './User'
import Organization from './Organization'
import OrganizationSettingsLazy from "./Organization/Settings";
import UserNav from "./User/components/UserNav";
import UserSider from "./User/components/UserSider";
import UserBanner from "./User/components/UserBanner";
import UserLayout from "./User/UserLayout";
import UserContent from "./User/components/UserContent";
import UserContext, {UserProvider} from "./User/UserContext";
import {userRouteEntities} from "./User/userRouteEntities";
import {userSettings} from "./User/Settings/UserSettings";
import OrganizationBanner from './Organization/components/OrganizationBanner'
import OrganizationContent from './Organization/components/OrganizationContent'
import OrganizationNav from './Organization/components/OrganizationNav'
import OrganizationSider from './Organization/components/OrganizationSider'
import CreateOrganization from './Organization/CreateOrganization'
import OrganizationContainerContext, {
  OrganizationContainerProvider,
} from './Organization/OrganizationContainerContext'
import OrganizationLayout from "./Organization/OrganizationLayout";
import {organizationRouteEntities} from "./Organization/organizationRouteEntities";
import {organizationSettings} from "./Organization/Settings/OrganizationSettings";
import OrganizationSettingHeader from "./Organization/Settings/OrganizationSettingHeader";
import OrganizationSettingsContent from "./Organization/Settings/OrganizationSettingContent";
import UserSettingHeader from "./User/Settings/UserSettingHeader";
import UserSettingsContent from "./User/Settings/UserSettingContent";

export const gPaths = Object.freeze({
  user: '/user/:id',
  event: '/event/:id',
  category: '/category/:id',
  organization_domain: '',
  organization: '/organization/:id',
  userSettings: '/user/:id/settings',
  organizationSettings_domain: '/settings',
  eventSettings: '/event/:id/settings',
  organizationSettings: '/organization/:id/settings',
})

export const {modalWidth, headerHeight} = {
  modalWidth: {
    small: 560,
    medium: 640,
    large: 720,
  },
  headerHeight: 'var(--header-height)',
}

export const withModalLayout = (Component, props) =>
  nest(
    Pure,
    withProps({
      width: modalWidth.large,
      ...props,
    })(ModalLayout),
    withProps(props)(Component)
  )

const settingRoutes = [
  {
    path: gPaths.eventSettings,
    Provider: EventProvider,
    component: EventSettingsLazy,
  },
  {
    path: gPaths.organizationSettings,
    Provider: OrganizationContainerProvider,
    component: OrganizationSettingsLazy,
  },
].map(({component: Component, Provider, ...route}) => ({
  ...route,
  component: withRouter(({match}) => (
    <Provider item={match.params.id}>
      <Component/>
    </Provider>
  )),
}))

const settingModalRoutes = [
  {
    ...CustomKeys.add_host,
    component: AddHostModal,
  },
  {
    ...CustomKeys.edit_owner,
    component: EditOwnerModal,
  },
].map(({name, path, component}) => ({
  name,
  path,
  component,
}))

export const gSettingModalRoutes = (name, state = {}) => {
  const routes = settingModalRoutes.reduce((prev, {name, path}) => {
    return {
      ...prev,
      [name]: {
        pathname: path,
      },
    }
  }, {})
  return {
    ..._.get(routes, name, {}),
    state: {
      isModal: true,
      ...state,
    },
  }
}

export const gConfigs = {
  fixedHeader: false,
  styles: {
    headerHeight,
    tabBar: {
      zIndex: 11,
      top: `calc(${headerHeight} - 1px)`,
      position: 'sticky',
      background: 'var(--background)',
    },
  },
  styles1: {
    tabBar: {
      zIndex: 11,
      top: 0,
      position: 'sticky',
      background: 'var(--background)',
    },
  },
  styles2: {
    headerHeight,
    tabBar: {
      zIndex: 11,
      top: headerHeight,
      position: 'sticky',
      background: 'var(--background)',
    },
  },
  dateFormat: 'DD/MM/YYYY',
  timeFormat: 'HH:mm',
  impCatePath: 'impact_categories',
  settingRoute: {
    name: 'settings',
    path: '/settings',
    withPath:
      ({prop, id}) =>
        (route = '/:prop/:id/settings') =>
          mapPathParam(route, {
            prop,
            id,
          }),
  },
  gRoutes: [
    ...settingRoutes,
    {
      path: gPaths.user,
      component: User,
    },
    {
      path: gPaths.event,
      component: Event,
    },
    {
      path: gPaths.organization,
      component: Organization,
    },
  ],
  gModalRoutes: [
    {
      name: 'select-primary-organization',
      component: AuthOrganiationsModal,
      path: staticPaths.selectPrimaryOrganization.pathname,
      params: {
        width: modalWidth.small,
      },
    },
    ...settingModalRoutes,
  ].map(({component, params, ...route}) => ({
    ...route,
    exact: true,
    withSubDomain: true,
    component: createRequiredLogin(ModalLogin)(
      withModalLayout(component, {
        ...params,
      })
    ),
  })),
}

const DefaultModuleLayout = compose(withTranslate)(
  ({translate, location, history, match, ...props}) =>
    createModuleLayout({
      Sider: withProps({
        title: translate('Sider'),
      })(BoxPlaceholder),
      Banner: withProps({
        title: translate('Banner'),
      })(BoxPlaceholder),
      Template: PageTemplate,
      ...props,
    })(<BoxPlaceholder title={translate('Content')}/>)
)

const LayoutRenderer = ({
                          extra = null,
                          Content = Null,
                          Layout = DefaultModuleLayout,
                          location,
                          history,
                          match,
                          ...props
                        }) => (
  <Pure>
    <Layout {...props}>
      <Content/>
      {extra}
    </Layout>
  </Pure>
)

const CustomLayoutRenderer = ({
                                extra = null,
                                Content = Null,
                                Layout = DefaultModuleLayout,
                                location,
                                history,
                                match,
                                ...props
                              }) => (
  <Pure>
    <Layout
      {...props}
      {...{Content}}>
      {extra}
    </Layout>
  </Pure>
)

const SettingLayoutRenderer = ({
                                 Content = Null,
                                 Header = Null,
                                 Layout = DefaultModuleLayout,
                                 withBreadcrumbs = renderSelf,
                                 location,
                                 history,
                                 match,
                                 ...props
                               }) => {
  const translate = useTranslate()
  const {value} = useContext(ModuleContext)
  const current = _.get(value, 'Context')
  const context = useContext(current) || {}
  return (
    <div className="h-ful">
      <ModuleLayoutWrapper
        Footer={withProps({
          Title: (props) => (
            <LayoutContext.Consumer>
              {({isSm}) => renderIf(isSm, <LogoHeader {...props} />)}
            </LayoutContext.Consumer>
          ),
          renderFooter: Null,
        })(MainHeader)}
        visibleLeftDrawer={true}
      />
      <div className="h-full space-y-3">
        <div
          style={{zIndex: 31, height: 'var(--header-height)'}}
          className="flex flex-shrink-0 px-2 shadow stickyTop background">
          <GoBackAction/>
          <CustomBreadcrumb
            rootClassName="flex items-center space-x-2 text-primary uppercase font-extrabold"
            routes={withBreadcrumbs(context, translate)}
          />
        </div>
        <div className="h-full p-2 lg:py-2">
          <Header {...context} />
          <Divider type="horizontal"/>
          <Pure>
            <Layout
              {...props}
              {...{Content}}
            />
          </Pure>
        </div>
      </div>
    </div>
  )
}

const renderBreadcrumbs = (context, translate = renderSelf) => [
  {
    path: '',
    breadcrumbName: translate('settings'),
  },
]

const SettingLayout = ({
                         Template,
                         menuItems,
                         Content = Null,
                         routeEntities,
                         ...props
                       }) =>
  createSettingLayout({
    menuItems,
    Template,
    routes: routeEntities,
    ...props,
  })(Content)

const gModules = {
  [AppEntities.USER]: {
    Nav: UserNav,
    Sider: UserSider,
    Banner: UserBanner,
    Layout: UserLayout,
    Template: PageTemplate,
    Content: UserContent,
    Context: UserContext,
    Provider: UserProvider,
    LayoutRenderer,
    routeEntities: userRouteEntities,
    entity: AppEntities.USER,
    settings: userSettings,
  },
  [AppEntities.EVENT]: {
    Nav: EventNav,
    Sider: EventSider,
    Banner: EventBanner,
    Layout: EventLayout,
    Template: PageTemplate,
    Content: EventContent,
    Context: EventContext,
    Provider: EventProvider,
    LayoutRenderer,
    routeEntities: eventRouteEntities,
    entity: AppEntities.EVENT,
    settings: eventSettings,
  },
  [AppEntities.ORGANIZATION]: {
    Nav: OrganizationNav,
    Sider: OrganizationSider,
    Banner: OrganizationBanner,
    Layout: OrganizationLayout,
    Template: PageTemplate,
    Content: OrganizationContent,
    Context: OrganizationContainerContext,
    Provider: OrganizationContainerProvider,
    LayoutRenderer,
    routeEntities: organizationRouteEntities,
    entity: AppEntities.ORGANIZATION,
    settings: organizationSettings,
  },
}

const settingModules = {
  [AppEntities.EVENT]: {
    bindingName: 'event-settings',
    Layout: SettingLayout,
    Template: DefaultTemplate,
    Content: EventSettingsContent,
    Context: EventContext,
    Provider: EventProvider,
    LayoutRenderer: SettingLayoutRenderer,
    Header: EventSettingHeader,
    withBreadcrumbs: renderBreadcrumbs,
    menuItems: eventSettings.menuItems,
    routeEntities: eventSettings.routeEntities,
    entity: AppEntities.EVENT,
  },
  [AppEntities.ORGANIZATION]: {
    bindingName: 'organization-settings',
    Layout: SettingLayout,
    Template: DefaultTemplate,
    Content: OrganizationSettingsContent,
    Context: OrganizationContainerContext,
    Provider: OrganizationContainerProvider,
    LayoutRenderer: SettingLayoutRenderer,
    Header: OrganizationSettingHeader,
    withBreadcrumbs: renderBreadcrumbs,
    menuItems: organizationSettings.menuItems,
    routeEntities: organizationSettings.routeEntities,
    entity: AppEntities.ORGANIZATION,
  },
  [AppEntities.USER]: {
    bindingName: 'user-settings',
    Layout: SettingLayout,
    Template: DefaultTemplate,
    Content: UserSettingsContent,
    Context: UserContext,
    Provider: UserProvider,
    LayoutRenderer: SettingLayoutRenderer,
    Header: UserSettingHeader,
    withBreadcrumbs: renderBreadcrumbs,
    menuItems: userSettings.menuItems,
    routeEntities: userSettings.routeEntities,
    entity: AppEntities.USER,
  },
}

export const ModuleContainer = ({
                                  name,
                                  children,
                                  useSettings = false,
                                  configs = gConfigs,
                                  modules = gModules,
                                }) => {
  return children({
    name,
    configs,
    modules,
    useSettings,
    settingModules,
  })
}

export const ModuleContext = React.createContext({})

export const ModuleProvider = ({value, children}) => (
  <ModuleContext.Provider
    key={value}
    value={{value}}>
    {children}
  </ModuleContext.Provider>
)
