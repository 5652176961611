import Null from "components/NullComponent"
import React from "react"

const OrderRequestContext = React.createContext(
  {
    item: {},
    dataSource: {},
    organization: {},
    contextMenu: null,
    functions: {},
    setHandler: Null,
    setSelect: Null,
    handleSelect: {
      cancel: console.log.bind(
        null,
        null
      ),
      detail: console.log.bind(
        null,
        'detail'
      ),
      share: console.log.bind(
        null,
        'share'
      )
    }
  }
)

export default OrderRequestContext