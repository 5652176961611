import {GG_ID} from 'envs/_current/config'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback} from 'react'
import {LoginSocialGoogle,} from 'reactjs-social-login';
import {ROOT_URL} from "../../../envs/_current/config";
import {FcGoogle} from "react-icons/fc";

export default function GoogleLoginBtn({
                                         onSuccess
}) {
  const t = useTranslate()
  const responseGoogle = response => {
    if (response['accessToken']) {
      onSuccess({
        token: response.accessToken,
        provider: 'google'
      })
    }
  }

  const onLoginStart = useCallback(() => {
    // alert('login start');
  }, []);
  return (
    <LoginSocialGoogle
      className="mx-auto"
      client_id={GG_ID}
      onLoginStart={onLoginStart}
      redirect_uri={ROOT_URL}
      typeResponse="accessToken"
      scope="openid profile email"
      discoveryDocs="claims_supported"
      onResolve={({provider, data}) => {
        if (data && data.access_token) {
          onSuccess({
            token: data.access_token,
            provider: provider
          })
        }
      }}
      onReject={err => {
        console.log(1, err);
      }}>
      <div
        style={{minWidth: '20em'}}
        className="w-fit flex hover:shadow-items border text-color-300 hover:text-color-100 rounded-lg cursor-pointer relative">
        <div className="flex flex-center p-2 border-r border-color-50">
          <FcGoogle size={18}/>
        </div>
        <div className="flex flex-1 flex-center px-4 font-semibold tracking-wide">
          {t('login with Google')}
        </div>
      </div>
    </LoginSocialGoogle>
  )
}
