import React from 'react';

export const AppTextLogoSvg = ({size, ...props}) => (
  <svg
    // width={8 * size}
    height={size}
    {...props}
    viewBox="0 0 457.5 131.3">
    {/*<defs>*/}
    {/*  <style*/}
    {/*    dangerouslySetInnerHTML={{*/}
    {/*      __html:*/}
    {/*        '.accfb2db-bc26-4385-b7e4-c20a3348e16e{fill:#97c33d;}.\\33 ad42ba2-cc2c-4cf8-918c-47a449befec4{fill:#459dd6;}.\\34 ca0fe7b-31d6-408e-8a17-973a75262386{fill:#00a551;}'*/}
    {/*    }}*/}
    {/*  />*/}
    {/*</defs>*/}
    {/*<title>feedin</title>*/}
    <path
      className="accfb2db-bc26-4385-b7e4-c20a3348e16e"
      d="M395.5,190.9l-.3-.4c-8.3,3.9-18.1,11.7-13.5,20.4s10.3,15.4,5.5,23.8c8.6-6.5,8.3-13.4,4.8-20.7,6.3,7.1,3.4,14-2.4,20.9,1.8-1.2,4.5-2.3,6-3.6,7.9-6.8,7.3-14.1,1.5-21.4S391.7,197.5,395.5,190.9Z"
      transform="translate(-27.3 -190.3)"
    />
    <path
      className="3ad42ba2-cc2c-4cf8-918c-47a449befec4"
      d="M399.1,247.5a7.8,7.8,0,0,0-2.3-5.7,7.7,7.7,0,0,0-5.7-2.2,7.4,7.4,0,0,0-5.7,2.3,7.9,7.9,0,0,0-2.1,4.5h0v67.2a7.9,7.9,0,0,0,2.3,5.7,8.1,8.1,0,0,0,11.4-.1,8.2,8.2,0,0,0,2.1-4.5Z"
      transform="translate(-27.3 -190.3)"
    />
    <path
      className="4ca0fe7b-31d6-408e-8a17-973a75262386"
      d="M360.4,198.2a7.9,7.9,0,0,0-7.9-7.9,7.6,7.6,0,0,0-7.8,6.8V248a39.4,39.4,0,0,0-23.5-8.7c-11.3-.4-21.2,3.6-29.4,11.9a41.7,41.7,0,0,0-.9,58,37.9,37.9,0,0,0,27.8,12.4h1.6c10.6,0,20-4,28-11.8a39.8,39.8,0,0,0,10.8-17.7,41.9,41.9,0,0,0,1.4-11.2ZM320.5,305.9h-1.4a22.9,22.9,0,0,1-16.8-7.9,25.4,25.4,0,0,1-6.8-17.9,24,24,0,0,1,7.4-17.6,23.4,23.4,0,0,1,17-7.3h.5a22,22,0,0,1,16.7,7.2,25.6,25.6,0,0,1,7.3,17.6,24.3,24.3,0,0,1-7.3,18.6A21.6,21.6,0,0,1,320.5,305.9Z"
      transform="translate(-27.3 -190.3)"
    />
    <path
      className="4ca0fe7b-31d6-408e-8a17-973a75262386"
      d="M176.8,271.9l-.5-1a7.3,7.3,0,0,0-4.3-4.3,8.4,8.4,0,0,0-10.5,4.6,7.8,7.8,0,0,0-.3,3.8v.2l.3,1.1c1.8,8.3-.3,15.4-6.6,21.8a9.3,9.3,0,0,1-1.4,1.4,25.4,25.4,0,0,1-15.2,6.4h-.2a23.6,23.6,0,0,1-14.2-3.6l39.8-39.8,1.5-1.5h.1a7.4,7.4,0,0,0,1.6-4.6,6.8,6.8,0,0,0-1.9-4.8l-1.1-1.1c-7-7-16-10.7-26.9-11h-.8a41.5,41.5,0,0,0-27.8,10.2c-8.7,7.8-13.2,17.9-13.3,30.2a40.1,40.1,0,0,0,12.5,30.1l.3.3a40.9,40.9,0,0,0,10.6,7.5,42.3,42.3,0,0,0,11.3,3.4,44.6,44.6,0,0,0,7.2.6h.4c1.8-.1,3.7-.2,5.8-.5a38.7,38.7,0,0,0,18.6-7.8,54.8,54.8,0,0,0,4.3-3.7,49.9,49.9,0,0,0,4.6-5.7,38.9,38.9,0,0,0,4.6-9.1,38.3,38.3,0,0,0,1.9-7.2A40.1,40.1,0,0,0,176.8,271.9Zm-58.2-9.6a25.3,25.3,0,0,1,15.5-7.3h2.1a25,25,0,0,1,11,2.6l-33.8,33.8a14.2,14.2,0,0,1-.8-1.8,23.4,23.4,0,0,1-1.7-11.6,25.3,25.3,0,0,1,7.7-15.6Z"
      transform="translate(-27.3 -190.3)"
    />
    <path
      className="4ca0fe7b-31d6-408e-8a17-973a75262386"
      d="M268.6,271.9l-.4-1a7.6,7.6,0,0,0-4.3-4.3,7.9,7.9,0,0,0-6.2.2,7.5,7.5,0,0,0-4.3,4.4,6.7,6.7,0,0,0-.4,3.8v.2l.3,1.1c1.8,8.3-.3,15.4-6.6,21.8l-1.5,1.4a25.2,25.2,0,0,1-15.1,6.4h-.3a23.3,23.3,0,0,1-14.1-3.6l39.8-39.8L257,261h.2a7.9,7.9,0,0,0,1.5-4.6,7.2,7.2,0,0,0-1.9-4.9l-1-1c-7-7-16-10.7-26.9-11H228a41.2,41.2,0,0,0-27.7,10.2c-8.7,7.8-13.2,17.9-13.3,30.2a40.2,40.2,0,0,0,12.5,30.1l.2.3a41.4,41.4,0,0,0,10.7,7.5,42.8,42.8,0,0,0,11.2,3.4,45.9,45.9,0,0,0,7.3.6h.4c1.8-.1,3.6-.2,5.7-.5a39,39,0,0,0,18.7-7.8,55,55,0,0,0,4.2-3.7,41,41,0,0,0,4.6-5.7,39.3,39.3,0,0,0,4.7-9.1,44.9,44.9,0,0,0,1.9-7.2A38.3,38.3,0,0,0,268.6,271.9Zm-58.2-9.6a25.8,25.8,0,0,1,15.5-7.3h2.2a24.9,24.9,0,0,1,10.9,2.6l-33.8,33.8-.8-1.8a24.5,24.5,0,0,1-1.6-11.6,28.2,28.2,0,0,1,2-7.7A27.1,27.1,0,0,1,210.4,262.3Z"
      transform="translate(-27.3 -190.3)"
    />
    <path
      className="3ad42ba2-cc2c-4cf8-918c-47a449befec4"
      d="M474.6,250a33.9,33.9,0,0,0-25.2-10.4c-10.1,0-18.7,3.5-25.5,10.4s-10.1,15.5-10.1,25.7v37.9a7.9,7.9,0,0,0,2.3,5.7,8.2,8.2,0,0,0,5.8,2.3,7.7,7.7,0,0,0,5.8-2.4,8.1,8.1,0,0,0,2.2-5.7V274.7a18.5,18.5,0,0,1,5.7-13.7,18.7,18.7,0,0,1,13.7-5.7,18.2,18.2,0,0,1,13.3,5.7,18.6,18.6,0,0,1,5.9,13.7v39a8.1,8.1,0,0,0,2.3,5.7,7.9,7.9,0,0,0,5.7,2.3,7.5,7.5,0,0,0,5.8-2.4,7.8,7.8,0,0,0,2.3-5.7V275.7C484.8,265.5,481.3,256.8,474.6,250Z"
      transform="translate(-27.3 -190.3)"
    />
    <path
      className="4ca0fe7b-31d6-408e-8a17-973a75262386"
      d="M107.5,212.5l-.3-.7a44.1,44.1,0,0,0-37.6-21.5H66.8a12.4,12.4,0,0,0-2.7.3h-.3l-3.1.6h-.4a43.3,43.3,0,0,0-33,33.7v88.2a8.4,8.4,0,0,0,2.3,5.9,8.2,8.2,0,0,0,5.8,2.3,8.5,8.5,0,0,0,5.9-2.4,8.3,8.3,0,0,0,2.3-5,3.7,3.7,0,0,0,.1-1.1V256.3H87a8.1,8.1,0,0,0,6.1-2.5,7.3,7.3,0,0,0,1.6-2.3,9.7,9.7,0,0,0,.3-6.7,8.1,8.1,0,0,0-1.9-3.1,10.1,10.1,0,0,0-2-1.4,9.5,9.5,0,0,0-4.1-1H43.7v-10l.6-2.8a24.9,24.9,0,0,1,7.6-12.8l.4-.4h0a26.6,26.6,0,0,1,17.9-6.4h.6a24.6,24.6,0,0,1,14.7,4.8,4.1,4.1,0,0,1,1.2.9l1.5,1.1a21.1,21.1,0,0,1,2.5,2.6,30.8,30.8,0,0,1,3,4.1l.5.6a5.9,5.9,0,0,0,1.9,1.3,8.7,8.7,0,0,0,11.1-4.3A8.6,8.6,0,0,0,107.5,212.5Z"
      transform="translate(-27.3 -190.3)"
    />
  </svg>
);

export default AppTextLogoSvg
