import { API_ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import { normalize, schema } from 'normalizr'
import { baseFieldTypes } from './base/baseFieldTypes'
import {
  articleSchema,
  baseItemSchema,
  categorySchema,
  channelSchema,
  createBaseItemSchema,
  feedSchema,
  homeFeed,
  pollSchema,
  postSchema,
} from './schema'
import { commentSchema } from './schema/commentSchema'
import { eventSchema } from './schema/event'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

const transform = createTransform(feedSchema, 'data')
transform.toString = () => 'post_transform'
const transformArray = createTransform([feedSchema])
transformArray.toString = () => 'post_transformArray'

const post_container_schema = new schema.Union(
  {
    channel: channelSchema,
  },
  '_type'
)

// $data['description'] = $request->get('description'); <-- nội dung bài post.
// $data['embed_data'] = $request->get('embed_data'); // json data <-- nếu có link nhúng thì lưu toàn bộ data vào đây, trước đây lưu vào description
// if ($request->get('privacy')) $data['privacy'] = $request->get('privacy'); <-- 1,2,3 default 1
// $data['user_id'] = $user->id; <-- tự fill
// $data['action'] = 'create'; --> tự fill
// $data['container_type'] = 'user'; --> API này post trên chính tường của mình --> tự fill là user
// $data['container_id'] = $user->id; --> API này post trên chính tường của mình --> tự fill là user
// $data['owner_type'] = 'user'; --> API này post trên chính tường của mình --> tự fill là user
// $data['owner_id'] = $user->id; --> API này post trên chính tường của mình --> tự fill là user
const fields = [
  'description',
  'embed_data',
  'privacy',
  'user_id',
  'action',
  'content',
  'photos',
  'container_type',
  'container_id',
  'owner_type',
  'owner_id',
]

const fieldTypes = {
  photos: baseFieldTypes.image,
  description: '',
  embed_data: baseFieldTypes.object,
  privacy: '',
  content: baseFieldTypes.string,
  user_id: baseFieldTypes.auto,
  action: baseFieldTypes.auto,
  pinned: [0, 1],
  container_type: '',
  container_id: '',
  owner_type: '',
  owner_id: '',
}
// Route::post('posts/post/{prop}/{id}/create', 'PostController@createPost');
export const post_create_Api = {
  backend: `Route::post('posts/post/{prop}/{id}/create', 'PostController@createPost');`,
  root: API_ROOT_URL,
  path: '/posts/post/:prop/:id/create',
  method: 'POST',
  fields,
  fieldTypes,
  transform: (response) => {
    const { entities, ...rest } =
      createTransform(feedSchema, 'data')(response) ?? {}
    return {
      ...rest,
      entities: {
        ...(entities ?? {}),
        ...(normalize(response?.data?.content?.container, post_container_schema)
          ?.entities ?? {}),
      },
    }
  },
}
export const create_post_create_Api_action =
  () =>
  ({ prefixStr = 'post_create_Api', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@post_create_Api',
      query,
      values,
      apiInfo: post_create_Api,
      maxAge,
    })

// Route::post('posts/post/share', 'PostController@share');

// Route::post('pinned-articles/{prop}/{id}/mark', 'PinController@mark');
// Route::post('pinned-articles/{prop}/{id}/unmark', 'PinController@unmark');
// Route::post('posts/post/{prop}/{id}/{post_id}/delete', 'PostController@deletePost');
export const post_deletePost_Api = {
  backend: `Route::post('posts/post/{post_id}/delete', 'PostController@deletePost');`,
  root: API_ROOT_URL,
  path: '/posts/post/:post_id/delete',
  method: 'POST',
  transform: (response, { query }) => {
    return {
      response,
      entities: {
        ...(normalize(response?.data?.container, post_container_schema)
          ?.entities ?? {}),
        posts: {
          [query[':post_id']]: {
            isDeleted: true,
            update: Date.now(),
          },
        },
      },
    }
  },
}
export const create_post_deletePost_Api_action =
  () =>
  ({ prefixStr = '', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@post_deletePost_Api',
      query,
      values,
      apiInfo: post_deletePost_Api,
      maxAge,
    })

export const feed_updateChannel_Api = {
  backend: `Route::post('feeds/{type}/{id}/set-channel', 'PostController@updateChannel');`,
  root: API_ROOT_URL,
  path: '/feeds/:type/:id/set-channel',
  method: 'POST',
  fields: ['channel_id'],
  fieldTypes,
  transform: createTransform(postSchema, 'data'),
}

// Route::post('posts/post/{prop}/{id}/{post_id}/update', 'PostController@updatePost');
export const post_update_Api = {
  backend: `Route::post('posts/post/{prop}/{id}/{post_id}/update', 'PostController@updatePost');`,
  root: API_ROOT_URL,
  path: '/posts/post/:prop/:id/:post_id/update',
  method: 'POST',
  fields: ['description'],
  fieldTypes,
  schema: postSchema,
  transform: createTransform(postSchema, 'data'),
}

export const create_post_update_Api_action =
  () =>
  ({ prefixStr = 'post_update_Api', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@post_update_Api',
      query,
      values,
      apiInfo: post_update_Api,
      maxAge,
    })
// Route::post('posts/post/{post_id}/change-tags', 'PostController@updatePostTags');

// Route::get('feeds/gets', 'FeedController@dashboard');
export const post_dashboard_Api = {
  backend: `Route::get('feeds/gets', 'FeedController@dashboard');`,
  root: API_ROOT_URL,
  path: '/feeds/gets',
  method: 'GET',
  paramsFields: ['cate_id', 'content_type', 'following_only', 'languages'],
  fields,
  fieldTypes,
  schema: homeFeed,
  transform: createListTransform(homeFeed),
}
export const create_post_dashboard_Api_action =
  (tranformApiInfo) =>
  ({ prefixStr = 'post_dashboard_Api', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@post_dashboard_Api',
      query,
      values,
      apiInfo: tranformApiInfo
        ? tranformApiInfo(post_dashboard_Api)
        : post_dashboard_Api,
      maxAge,
    })

// Route::get('feeds/get-editor-choices-widgets', 'FeedController@fetchEditorChoicesWidgetFeed');
export const get_editorChoiceWidgetFeeds_Api = {
  backend: `Route::get('feeds/get-editor-choices-widgets', 'FeedController@fetchEditorChoicesWidgetFeed');`,
  root: API_ROOT_URL,
  path: '/feeds/get-editor-choices-widgets',
  method: 'GET',
  paramsFields: ['category_id', 'ref_category_id', 'languages'],
  fields,
  fieldTypes,
  schema: baseItemSchema,
  transform: createListTransform(baseItemSchema),
}

// Route::get('feeds/get-auth-choices-widgets', 'FeedController@fetchAuthUserChoicesWidgetFeed');
export const get_authUserChoiceWidgetFeeds_Api = {
  backend: `Route::get('feeds/get-auth-choices-widgets', 'FeedController@fetchAuthUserChoicesWidgetFeed');`,
  root: API_ROOT_URL,
  path: '/feeds/get-auth-choices-widgets',
  method: 'GET',
  paramsFields: ['category_id'],
  fields,
  fieldTypes,
  schema: baseItemSchema,
  transform: createListTransform(baseItemSchema),
}

// Route::get('feeds/typical-charts/gets', 'FeedController@fetchTypicalChartsFeed');
export const get_typicalChartsFeeds_Api = {
  backend: `Route::get('feeds/typical-charts/gets', 'FeedController@fetchTypicalChartsFeed');`,
  root: API_ROOT_URL,
  path: '/feeds/typical-charts/gets',
  method: 'GET',
  paramsFields: ['category_id', 'ref_category_id', 'following', 'view'],
  fields,
  fieldTypes,
  schema: baseItemSchema,
  transform: createListTransform(baseItemSchema),
}

// Route::get('feeds/{prop}/{id}/gets', 'FeedController@feeds');
export const post_getPosts_Api = {
  backend: `Route::get('feeds/{prop}/{id}/gets', 'FeedController@feeds')`,
  root: API_ROOT_URL,
  path: '/feeds/:prop/:id/gets',
  method: 'GET',
  paramsFields: ['pinned', 'content_type'],
  fields,
  fieldTypes,
  schema: feedSchema,
  transform: createListTransform(feedSchema),
}

// Route::get('feeds/{prop}/{id}/get-sample-feeds', 'FeedController@fetchFeedsByContainerOwner');
export const feed_fetchFeedsByOwner_Api = {
  backend: `Route::get('feeds/{prop}/{id}/get-sample-feeds', 'FeedController@fetchFeedsByContainerOwner')`,
  root: API_ROOT_URL,
  path: '/feeds/:prop/:id/get-sample-feeds',
  method: 'GET',
  fields,
  fieldTypes,
  schema: feedSchema,
  transform: createListTransform(feedSchema),
}

export const post_getActivities_Api = {
  backend: `UserController@getActivities)`,
  root: API_ROOT_URL,
  path: '/auth/activity/:type/gets',
  method: 'GET',
  schema: createBaseItemSchema({
    schema: {
      content: new schema.Union(
        {
          post: postSchema,
          poll_question: pollSchema,
          article: articleSchema,
          comment: commentSchema,
        },
        '_type'
      ),
    },
  }),
}
export const create_post_getPosts_Api_action =
  () =>
  ({ prefixStr = 'post_getPosts_Api', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@post_getPosts_Api',
      query,
      values,
      apiInfo: post_getPosts_Api,
      maxAge,
    })

// Route::get('posts/post/{post_id}', 'FeedController@showPostById');
export const post_getPostbyId_Api = {
  backend: `Route::get('posts/post/{post_id}', 'FeedController@showPostById')`,
  root: API_ROOT_URL,
  path: '/posts/post/:post_id',
  method: 'GET',
  schema: postSchema,
  fields,
  fieldTypes,
  transform: createTransform(postSchema, 'data'),
}
export const create_post_getPostbyId_Api_action =
  () =>
  ({ prefixStr = 'post_getPostbyId_Api', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@post_getPostbyId_Api',
      query,
      values,
      apiInfo: post_getPostbyId_Api,
      maxAge,
    })

// Route::get('posts/post/{post_id}/get', 'FeedController@getPostId');
export const post_getById_Api = {
  backend: `// Route::get('posts/post/{post_id}/get', 'FeedController@getPostId');`,
  root: API_ROOT_URL,
  path: '/posts/post/:post_id/get',
  method: 'GET',
  fields,
  fieldTypes,
  schema: postSchema,
  transform: createTransform(postSchema, 'data'),
}
export const create_post_getById_Api_action =
  () =>
  ({ prefixStr = 'post_getById_Api', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@post_getById_Api',
      query,
      values,
      apiInfo: post_getById_Api,
      maxAge,
    })

// Route::get('posts/post/{post_id}/replies/{comment_id}/get', 'PostController@getReplies');

// Route::get('posts/post/{prop}/{id}/{post_id}/get', 'PostController@getPost');

// Route::get('posts/post/{post_id}/comments', 'PostController@getComments');
// Route::get('posts/post/{prop}/{id}/get-articles', 'PostController@getArticles');

// Route::get('feeds/news/{id}/get', 'FeedController@getFeedById');
export const feed_getFeedById_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/feeds/feed/:id/get',
  method: 'GET',
  fields,
  fieldTypes,
  transform,
}
export const create_feed_getFeedById_Api_action =
  () =>
  ({ prefixStr = '', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@feed_getFeedById_Api',
      query,
      values,
      apiInfo: feed_getFeedById_Api,
      maxAge,
    })
// Route::get('feeds/following-categories/gets', 'FeedController@fetchFeedByFollowingCategory');
export const feed_fetchFeedByFollowingCategory_Api = {
  backend: `Route::get('feeds/following-categories/gets', 'FeedController@fetchFeedByFollowingCategory');`,
  root: API_ROOT_URL,
  path: '/feeds/following-categories/gets',
  method: 'GET',
  fields,
  fieldTypes,
  transform,
}

// Route::get('feeds/news/gets', 'FeedController@fetchNewsData');
export const feed_fetchNewsData_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/feeds/news/gets',
  method: 'GET',
  paramsFields: ['category_id', 'ref_category_id', 'languages'],
  schema: baseItemSchema,
  fieldTypes,
  transform: createListTransform(baseItemSchema),
}

// Route::get('feeds/news/get-spotlights', 'FeedController@fetchSpotlightNewsData');
export const feed_fetchSpotlightNewsData_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/feeds/news/get-spotlights',
  method: 'GET',
  paramsFields: ['category_id', 'ref_category_id', 'languages'],
  schema: feedSchema,
  fieldTypes,
  transform: createListTransform(feedSchema),
}

export const create_feed_fetchNewsData_Api_action =
  () =>
  ({ prefixStr = 'feed_fetchNewsData_Api', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@feed_fetchNewsData_Api',
      query,
      values,
      apiInfo: feed_fetchNewsData_Api,
      maxAge,
    })
// Route::get('feeds/initial-news/gets', 'FeedController@fetchInitialNews'); // goi cho trang NEWS
const initialNewsSchema = new schema.Entity(
  'initialNews',
  {
    categories: [
      new schema.Entity(
        'initialNews.categories',
        {
          articles: [feedSchema],
        },
        {
          idAttribute: 'id',
        }
      ),
    ],
    latest_news: [feedSchema],
    for_you: [feedSchema],
    latest_news_more: [feedSchema],
  },
  {
    idAttribute: () => 'initialNews',
    processStrategy: (entity) => {
      return entity
    },
  }
)
export const feed_fetchInitialNews_Api = {
  backend: `Route::get('feeds/initial-news/gets', 'FeedController@fetchInitialNews'); // goi cho trang NEWS`,
  root: API_ROOT_URL,
  path: '/feeds/initial-news/gets',
  method: 'GET',
  fields,
  fieldTypes,
  schema: initialNewsSchema,
  transform: createTransform(initialNewsSchema, 'data'),
}
export const create_feed_fetchInitialNews_Api_action =
  () =>
  ({ prefixStr = 'feed_fetchInitialNews_Api', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@feed_fetchInitialNews_Api',
      query,
      values,
      apiInfo: feed_fetchInitialNews_Api,
      maxAge,
    })
// Route::post('posts/post/share-external-link', 'PostController@shareExternalLink'); // description, embed_data, categories
export const post_shareExternalLink_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/posts/post/share-external-link',
  method: 'POST',
  fields: ['description', 'embed_data', 'categories'],
  fieldTypes,
  transform,
}
export const create_post_shareExternalLink_Api_action =
  () =>
  ({ prefixStr = 'post_shareExternalLink_Api', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@post_shareExternalLink_Api',
      query,
      values,
      apiInfo: post_shareExternalLink_Api,
      maxAge,
    })

// Route::get('feeds/news/gets', 'FeedController@fetchNewsData'); // goi de xem theo tung categories.
// Route::post('posts/post/{prop}/{id}/create-re-post', 'PostController@createRePost');
export const post_createRepost_Api = {
  backend: `Route::post('posts/post/{prop}/{id}/create-re-post', 'PostController@createRePost');`,
  root: API_ROOT_URL,
  path: '/posts/post/:prop/:id/create-re-post',
  method: 'POST',
  fields,
  fieldTypes,
  schema: feedSchema,
  transform: createTransform(feedSchema, 'data'),
}
export const create_post_createRepost_Api_action =
  () =>
  ({ prefixStr = '', query, values, maxAge = -1 }) =>
    createAsyncAction({
      prefixStr: prefixStr + '@post_createRepost_Api',
      query,
      values,
      apiInfo: post_createRepost_Api,
      maxAge,
    })

// Route::get('feeds/graph/{id}/get', 'FeedController@fetchFeedByGraph');
export const feed_getFeedsByGraph_Api = {
  backend: `// Route::get('feeds/graph/{id}/get', 'FeedController@fetchFeedByGraph');`,
  root: API_ROOT_URL,
  path: '/feeds/graph/:id/get',
  method: 'GET',
  schema: feedSchema,
  transform: createListTransform(feedSchema),
}

// ------------------MainPage ---------------------------------
const feedsByCategorySchema = new schema.Entity(
  'feedsByCategory',
  {
    data: [feedSchema],
  },
  {
    idAttribute: 'id',
  }
)

// Route::get('news/feeds/articles-by-categories/gets', 'NewsController@getArticlesGroupByCategories');
export const feed_fetchArticlesByCategories_Api = {
  backend: `Route::get('news/feeds/articles-by-categories/gets', 'NewsController@getArticlesGroupByCategories');`,
  root: API_ROOT_URL,
  path: '/news/feeds/articles-by-categories/gets',
  method: 'GET',
  schema: feedsByCategorySchema,
  transform: createListTransform(feedsByCategorySchema),
}
// Route::get('news/feeds/get-articles', 'NewsController@fetchArticles');
export const feed_fetchArticles_Api = {
  backend: `Route::get('news/feeds/get-articles', 'NewsController@fetchArticles');`,
  root: API_ROOT_URL,
  path: '/news/feeds/get-articles',
  method: 'GET',
  paramsFields: ['languages'],
  schema: feedSchema,
  transform: createListTransform(feedSchema),
}
// Route::get('news/feeds/get-events', 'NewsController@fetchEvents');
export const feed_fetchEvents_Api = {
  backend: `Route::get('news/feeds/get-events', 'NewsController@fetchEvents');`,
  root: API_ROOT_URL,
  path: '/news/feeds/get-events',
  method: 'GET',
  paramsFields: ['cate_id'],
  schema: eventSchema,
  transform: createListTransform(eventSchema),
}
// Route::get('news/feeds/latest-articles/gets', 'NewsController@getLatestArticles');
export const feed_fetchLatestArticles_Api = {
  backend: `Route::get('news/feeds/latest-articles/gets', 'NewsController@getLatestArticles');`,
  root: API_ROOT_URL,
  path: '/news/feeds/latest-articles/gets',
  method: 'GET',
  schema: feedSchema,
  transform: createListTransform(feedSchema),
}
// Route::get('news/feeds/latest-interested-articles/gets', 'NewsController@getLatestInterestedArticles');
export const feed_fetchLatestInterestedArticles_Api = {
  backend: `Route::get('news/feeds/latest-interested-articles/gets', 'NewsController@getLatestInterestedArticles');`,
  root: API_ROOT_URL,
  path: '/news/feeds/latest-interested-articles/gets',
  method: 'GET',
  schema: feedSchema,
  transform: createListTransform(feedSchema),
}
// Route::get('news/feeds/articles-by-concern/gets', 'NewsController@getArticlesByInterestedKeyword');
export const feed_fetchArticlesByConcern_Api = {
  backend: `Route::get('news/feeds/articles-by-concern/gets', 'NewsController@getArticlesByInterestedKeyword');`,
  root: API_ROOT_URL,
  path: '/news/feeds/articles-by-concern/gets',
  method: 'GET',
  schema: feedSchema,
  transform: createListTransform(feedSchema),
}
// Route::get('news/feeds/get-menu', 'NewsController@getNewsCategory');
export const feed_fetchNewsMenu_Api = {
  backend: `Route::get('news/feeds/get-menu', 'NewsController@getNewsCategory');`,
  root: API_ROOT_URL,
  path: '/news/feeds/get-menu',
  method: 'GET',
  transform: (response) => {
    const { status } = response
    const categories = _.get(response, 'data.categories')
    if (categories) {
      const { entities, result } = normalize(categories, [categorySchema])
      response.entities = entities
      response.result = result
    }
    return {
      ...response,
      ...(status !== 200
        ? {
            error: true,
            errorMessages: response.data,
          }
        : {
            success: true,
          }),
      status,
      entities: response.entities,
      result: response.result,
    }
  },
}

export const feed_fetchFeedByContainer_Api = {
  backend: `Route::get('feeds/{prop}/{id}/gets', 'FeedController@fetchFeedByContainer');`,
  root: API_ROOT_URL,
  path: '/feeds/:prop/:id/gets',
  method: 'GET',
  schema: feedSchema,
  paramsFields: ['content_type'],
  transform: createListTransform(feedSchema),
}

export const commentOwnerSchema = new schema.Union(
  {
    post: postSchema,
  },
  '_type'
)

export const post_setBestComment_Api = {
  backend: `Route::post('posts/post/{post_id}/comment/{comment_id}/set-best-answer', 'PostController@setBestComment');`,
  backend2: `Route::post('posts/ask/{post_id}/comment/{comment_id}/set-best-answer', 'PostController@setBestComment');`,
  root: API_ROOT_URL,
  path: '/posts/:type/:id/comment/:comment_id/set-best-answer',
  method: 'POST',
  schema: commentOwnerSchema,
  transform: createTransform(commentOwnerSchema, 'data'),
}

export const post_unsetBestComment_Api = {
  backend: `Route::post('posts/post/{post_id}/comment/{comment_id}/unset-best-answer', 'PostController@unsetBestComment');`,
  backend2: `Route::post('posts/ask/{post_id}/comment/{comment_id}/unset-best-answer', 'PostController@unsetBestComment');`,
  root: API_ROOT_URL,
  path: '/posts/:type/:id/comment/:comment_id/unset-best-answer',
  method: 'POST',
  schema: commentOwnerSchema,
  transform: createTransform(commentOwnerSchema, 'data'),
}
