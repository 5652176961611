import {PlusOutlined, SettingOutlined, UserAddOutlined} from '@ant-design/icons'
import MailOutlined from '@ant-design/icons/lib/icons/MailOutlined'
import PhoneOutlined from '@ant-design/icons/lib/icons/PhoneOutlined'
import {Button, Menu, Modal} from 'antd'
import {organization_getById_Api} from 'apis'
import {organizationSchema} from 'apis/schema'
import Null from 'components/NullComponent'
import _ from 'lodash'
import {AsyncWithCache} from 'modules/asyncCache/components/Async'
import useAsync from 'modules/asyncCache/useAsync'
import Translate from 'modules/local/Translate'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, {useCallback, useEffect, useRef} from 'react'
import {useSelector} from 'react-redux'
import {useRouteMatch} from 'react-router-dom'
import {useSelectEntities} from 'redux/entities/useSelectEntities'
import Loading from '../../components/Loading'
import {AppTextLogoSvg} from '../../appConfigs/svgs/AppTextLogoSvg'
import Toggle from '../../components/Toggle'
import {API_ROOT_URL, CONTACT_EMAIL, CONTACT_PHONE} from '../../envs/_current/config'
import {getAsynCacheSelector} from '../../modules/asyncCache/selectors'
import useTranslate from '../../modules/local/useTranslate'
import {useHistory} from '../../modules/navigation/useRouter'
import {getLogin} from '../../redux/auth/selectors'
import ServerContainer from '../Server/ServerContainer'
import {OrganizationContext} from './OrganizationContext'
import {routes} from './routes'
import {permissionCodes} from "../../modules/permissions/contants";
import {FaShare} from "react-icons/fa6";
import {BsGear, BsPlusLg} from "react-icons/bs";

const Error404 = ({ ...props }) => {
  const { t } = props
  return (
    <div className=" px-3 py-16 flex flex-col absolute inset-0 w-full h-full justify-center items-center">
      <div
        style={{
          fontSize: '9rem'
        }}>
        <AppTextLogoSvg size={10}/>
      </div>
      <div className=" p-6 flex justify-center items-center font-bold text-2xl">
        {t('page not found')}
      </div>
      <div className=" p-3 flex justify-center items-center text-color-300 text-center">
        {t(
          'The page you are opening does not exist, please check the link or return to the homepage'
        )}
      </div>
      <Button
        onClick={() => {
          window.location.href = '/'
        }}
        type="primary">
        {t('home')}
      </Button>
    </div>
  )
}

const Error401 = ({ ...props }) => {
  const { t, history, login } = props

  return (
    <div className="px-6 flex flex-col space-y-3 absolute inset-0 w-full h-full justify-center items-center">
      <div
        style={{
          fontSize: '9rem'
        }}>
        <AppTextLogoSvg size={10}/>
      </div>
      <div className="p-3 flex justify-center items-center font-bold text-3xl">
        {t('page not found')}
      </div>
      <div className="p-3 flex justify-center items-center text-color-300 text-center text-lg">
        {t(
          'the page does not exist or the page is for internal use, you must be a member to view the content of this page.'
        )}
      </div>
      <div className="flex items-center justify-center space-x-3">
        <Button
          onClick={() => {
            window.location.href = '/'
          }}
          type="dashed">
          {t('return home')}
        </Button>
        {!!!login && (
          <Button
            onClick={() => {
              history.push('/login')
            }}
            type={'primary'}>
            <div className="px-6">
              {t('login')}
            </div>
          </Button>
        )}
      </div>
    </div>
  )
}

const Error403 = ({ ...props }) => {
  const { t } = props

  return (
    <div className="px-6 flex flex-col space-y-6 absolute inset-0 w-full h-full justify-center items-center">
      <div
        style={{
          fontSize: '9rem'
        }}>
        <AppTextLogoSvg/>
      </div>
      <div className="p-3 flex justify-center items-center font-bold text-3xl">
        {t('page not found')}
      </div>
      <div className="p-3 flex justify-center items-center text-color-300 text-center text-lg">
        {t(
          'the page does not exist or the page is for internal use, you must be a member to view the content of this page.'
        )}
      </div>
      <div className="flex items-center justify-center space-x-3">
        <Button
          onClick={() => {
            window.location.href = '/'
          }}
          type="dashed">
          {t('return home')}
        </Button>
        <Toggle>
          {(isToggle, toggle) => (
            <React.Fragment>
              <Button
                onClick={toggle}
                type={'primary'}>
                <div className="px-6">
                  {t('contact')}
                </div>
              </Button>
              <Modal
                title={t('contact')}
                maskClosable
                className="space-y-10"
                destroyOnClose
                visible={isToggle}
                onCancel={toggle}
                onOk={toggle}
                cancelButtonProps={{
                  hidden: true
                }}>
                <div className="py-6">
                  {t(
                    'Please contact the administrator of FeedIn'
                  )}
                </div>
                <div className="flex flex-1 items-center space-x-3">
                  <MailOutlined />
                  <p>{CONTACT_EMAIL}</p>
                </div>
                <div className="flex flex-1 items-center space-x-3">
                  <PhoneOutlined />
                  <p>{CONTACT_PHONE}</p>
                </div>
              </Modal>
            </React.Fragment>
          )}
        </Toggle>
      </div>
    </div>
  )
}

const showModal = ({
  title,
  status,
  params = {}
}) => {
  let render = Null
  switch (status) {
    case 401:
      render = Error401
      break
    case 403:
      render = Error403
      break
    case 404:
    case 500:
      render = Error404
      break
  }

  Modal.info({
    bodyStyle: { minHeight: '60vh' },
    width: 720,
    // maskClosable: true,
    footer: null,
    okButtonProps: { hidden: true },
    cancelButtonProps: { hidden: true },
    title: title,
    content: render({
      ...params
    })
  })
}

const OrganizationProvider = ({
  id,
  children
}) => {
  const groupsCacheId = `/products/organization/${id}/get-product-catalog-with-products`
  let { url } = useRouteMatch()
  const history = useHistory()
  const t = useTranslate()
  const login = useSelector(getLogin)
  const item = useSelectEntities(
    id,
    organizationSchema
  )
  const {
    result: groups = []
  } = useSelector(
    state => {
      return getAsynCacheSelector(
        state,
        groupsCacheId
      )
    },
    (left, right) =>
      left.updated === right.updated
  )

  const e = useRef(null)
  const newE = item && (
    <PermissionProvider
      permissions={{
        [permissionCodes.canJoin]:
        Number(item?.privacy === 1) || item?.joined_status,
        [permissionCodes.canInvite]: Number(
          item?.privacy === 1 || item?.edit || item?.join_status === 1
        ),
        [permissionCodes.canEdit]: Number(item?.edit),
      }}>
      <ServerContainer
        // item={item}
        {...{
          item,
          schema: organizationSchema,
          id: item.id,
          type: item._type,
          routes: routes,
          createMenuContext: ({
            setCurrent,
            handleActionClick
          }) =>
            item && (
              <Menu
                onClick={({ key }) => {
                  switch (key) {
                    default:
                      handleActionClick(key)
                      break
                  }
                }}>
                {[
                  [
                    'share',
                    'share',
                    <FaShare size={12} className="text-color-400" />
                  ],
                  ...(item.edit
                    ? [
                        [
                          'create-channel',
                          'create channel',
                          <BsPlusLg size={13} className="text-color-400" />
                        ],
                        [
                          'create-product',
                          'create product',
                          <BsPlusLg size={13} className="text-color-400"/>
                        ],
                        [
                          'create-event',
                          'create event',
                          <BsPlusLg size={13} className="text-color-400"/>
                        ],
                        ['divider'],
                        [
                          'setting',
                          'general settings',
                          <BsGear size={12} className="text-color-400"/>
                        ]
                      ]
                    : [])
                ].map(( [key, label, icon], i ) =>
                    key === 'divider' ? (
                      <Menu.Divider key={i} />
                    ) : (
                      <Menu.Item key={key} icon={icon}>
                          {t(label)}
                      </Menu.Item>
                    )
                )}
              </Menu>
            )
        }}>
        {children}
      </ServerContainer>
    </PermissionProvider>
  )
  if (newE && newE !== null) {
    e.current = newE
  }

  const data = useAsync({
    apiInfo: organization_getById_Api,
    query: {
      ':id': id
    }
  })

  const {
    error,
    response,
    isLoading = false
  } = data

  const renderCached = useCallback(() => {
    const status = _.get(
      response,
      'status'
    )
    return !data || status === 200
  }, [data])

  useEffect(() => {
    if (
      error &&
      !isLoading &&
      !!response
    ) {
      const status = _.get(
        response,
        'status'
      )
      if (status) {
        showModal({
          status,
          title: t(status),
          params: {
            t,
            login,
            history
          }
        })
      }
    }
  }, [isLoading, response, error])

  return (
    <OrganizationContext.Provider
      value={{
        id,
        organization: item,
        url: url,
        groups: groups.filter(
          group =>
            group.products &&
            group.products.length
        ),
        allProducts: groups
          .flatMap(group => {
            const {
              title,
              products = []
            } = group
            return products.map(p => ({
              ...p,
              groupName: title,
              group
            }))
          })
          .map((p, i) => ({
            ...p,
            label: p.title,
            value: i
          }))
      }}>
      {renderCached() && (
        <>
          <AsyncWithCache
            cacheId={groupsCacheId}
            apiInfo={{
              root: API_ROOT_URL,
              path: groupsCacheId,
              method: 'GET'
            }}
          />
        </>
      )}
      {e.current || (
        <div className=" p-3 flex flex-col absolute inset-0 w-full h-full justify-center items-center">
          <Loading />
        </div>
      )}
    </OrganizationContext.Provider>
  )
}

export default OrganizationProvider
