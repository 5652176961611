export const validateServiceType = (_type = "0000", _service_name) => {
  if (_service_name === "channel" && (_type).substring(0, 1) === '1') return true;
  if (_service_name === "dataset" && (_type).substring(1, 2) === '1') return true;
  if (_service_name === "product" && (_type).substring(2, 3) === '1') return true;
  if (_service_name === "purchase" && (_type).substring(3, 4) === '1') return true;
  return false;
}

export const getServiceTypes = (_type = "0000") => {
  let _service_names = {};
  if ((_type).substring(0, 1) === '1') _service_names = {..._service_names, "channel-allowable": true};
  if ((_type).substring(1, 2) === '1') _service_names = {..._service_names, "dataset-allowable": true};
  if ((_type).substring(2, 3) === '1') _service_names = {..._service_names, "product-allowable": true};
  if ((_type).substring(3, 4) === '1') _service_names = {..._service_names, "purchase-allowable": true};
  return _service_names;
}