import ErrorBoundary from 'components/error/ErrorBoundary'
import Null from 'components/NullComponent'
import _ from 'lodash'
import React from 'react'
import AmChartStateless from '../AmChart/components/AmChartStateless'
import ChartWrapper from '../ChartWrapper/ChartWrapper'

export const LinesChartStateless = ({
  header = Null,
  item,
  CWrapper = ChartWrapper,
  ...props
}) => {
  return (
    <ErrorBoundary
      fallbackComponent={() => {
        return (
          <div className="w-full h-full flex justify-center items-center font-bold text-color-300 text-2xl">
            Error
          </div>
        )
      }}>
      <div
        style={{
          minHeight: 360,
          height: 0,
        }}
        className="flex-1 flex flex-col">
        <AmChartStateless
          item={item}
          setChartWrap={() => null}
          {...props}
        />
      </div>
    </ErrorBoundary>
  )
}

export const LinesChart = ({ chartInfo, ...props }) => {
  let item = _.cloneDeep(chartInfo)
  item.chart_source = 'AmChart'
  item.data_encrypted = 0
  item.pivot_data = 1
  item.chart_type = 'PivotXYChart'
  item.subscribe_required = 0
  item.is_static_data = true
  item.data_empty = 0
  item.version = Date.now()
  item._type = 'chart'

  item.params = chartInfo.params || {
    colors: {
      step: 2,
    },
    numberFormatter: {
      numberFormat: '#.##a',
    },
    leftAxesContainer: {
      layout: 'vertical',
    },
    bottomAxesContainer: {
      reverseOrder: true,
    },
    seriesContainer: {
      children: [
        {
          type: 'Label',
          text: item.title,
          textAlign: 'start',
          align: 'left',
          fontSize: '0.68rem',
          fontWeight: '600',
          paddingLeft: 5,
          opacity: 0.5,
        },
      ],
    },
    xAxes: [
      {
        id: 'x1',
        type: 'DateAxis',
        baseInterval: {
          timeUnit: 'day',
          count: 1,
        },
        keepSelection: true,
        dateFormats: {
          day: 'dd-MM-yyyy',
        },
        periodChangeDateFormats: {
          day: 'MMM dt',
        },
        dataFields: {
          date: 'time',
        },
        renderer: {
          fontSize: '0.68em',
          grid: {
            template: {
              disabled: true,
            },
          },
          ticks: {
            template: {
              length: 8,
              disabled: false,
              strokeOpacity: 0.1,
            },
          },
        },
        opacity: 0.5,
      },
    ],
    yAxes: [
      {
        type: 'ValueAxis',
        id: 'y1',
        zIndex: 3,
        extraMax: 0.01,
        tooltip: {
          disabled: true,
        },
        renderer: {
          fontSize: '0.68em',
          grid: {
            location: 0,
            strokeOpacity: 0.2,
            stroke: '#111',
            strokeWidth: 0.5,
          },
          line: {
            strokeOpacity: 0.5,
          },
          ticks: {
            disabled: false,
            strokeOpacity: 0.5,
            length: 2,
          },
        },
        opacity: 0.5,
      },
    ],
    series: [
      {
        type: 'LineSeries',
        id: 's1',
        yAxis: 'y1',
        name: 'pivot_column',
        strokeWidth: 1,
        tensionX: 0.95,
        dataFields: {
          valueY: 'value',
          dateX: 'time',
        },
        bullets: [
          {
            type: 'Rectangle',
            width: 3,
            height: 3,
            horizontalCenter: 'middle',
            verticalCenter: 'middle',
          },
          {
            type: 'LabelBullet',
            label: {
              text: '[font-size: 10px]{value}[/]',
              dy: -20,
            },
          },
          {
            type: 'CircleBullet',
            fillOpacity: 0,
            strokeOpacity: 0,
            circle: {
              radius: 3,
              strokeWidth: 1,
              stroke: '#fff',
            },
            states: {
              hover: {
                properties: {
                  scale: 1.7,
                  fillOpacity: 1,
                  strokeOpacity: 1,
                },
              },
            },
          },
        ],
        tooltipText:
          "[font-size: 9px]({dateX})[/] [bold font-size: 10px]{name}:[/] [bold font-size: 10px]{valueY.value.formatNumber('#,###.##')}[/]",
        tooltip: {
          background: {
            fillOpacity: 0.3,
          },
          propertyFields: {
            pointerOrientation: 'orientation',
            dy: 'offset',
          },
        },
      },
    ],
    legend: {
      fontSize: '0.68rem',
      scrollable: true,
      maxHeight: 100,
    },
    cursor: {
      type: 'XYCursor',
      lineX: {
        stroke: '#3c8f30',
        strokeOpacity: 0.5,
        strokeDasharray: '',
      },
      lineY: {
        disabled: true,
      },
    },
  }

  item.data_columns = chartInfo.data_columns || [
    {
      id: 'time',
      dataIndex: 'log_date',
      type: 'date',
    },
    {
      id: 'value1',
      dataIndex: 'total_donation_value',
      type: 'number',
      valueType: 'value',
    },
    {
      id: 'value2',
      dataIndex: 'total_giving_value',
      type: 'number',
      valueType: 'value',
    },
  ]

  return (
    <LinesChartStateless
      item={item}
      CWrapper={ChartWrapper}
      {...props}
    />
  )
}

export default React.memo(LinesChart)
