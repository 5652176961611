import classNames from 'classnames'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import {renderIf} from 'views/Shared'
import {renderIfElse} from "../../../Shared";

const ListHeader = ({
                      title,
                      extra,
                      style,
                      className,
                      renderTitle,
                      description,
                    }) => (
  <Translate>
    {(t) => (
      <div className="flex flex-col gap-1">
        <div
          style={style}
          className={classNames(
            'flex items-center text-lg font-bold text-primary uppercase',
            className
          )}>
          {_.isFunction(renderTitle)
            ? renderTitle()
            : renderIf(
              title,
              _.isString(title) ? <span>{t(title)}</span> : title
            )}
          {extra && <div className="flex flex-1 justify-end">{extra}</div>}
        </div>
        {renderIf(
          description,
          <div className="ml-2 text-sm text-color-500 italic">
            {`* ${t(description)}`}
          </div>
        )}
      </div>
    )}
  </Translate>
)

export const ListHeaderWithLine = ({
                                     title,
                                     extra,
                                     style,
                                     className,
                                     renderTitle,
                                     line = true
                                   }) => (
  <Translate>
    {(t) => (
      <div
        style={style}
        className={classNames('flex items-center gap-3', className)}>
        {_.isFunction(renderTitle)
          ? renderTitle()
          : renderIf(
            title,
            _.isString(title) ? (
              <span className="font-medium text-color-300 uppercase italic">
                  {t(title)}
                </span>
            ) : (
              title
            )
          )}
        {
          renderIfElse(
            line,
            <div className="flex-1 border-b"/>,
            <div className="flex-1"/>
          )
        }
        {extra}
      </div>
    )}
  </Translate>
)

export const ListHeaderLineAndDes = ({
                                       title,
                                       description,
                                       extra,
                                       style,
                                       className,
                                       renderTitle,
                                     }) => (
  <Translate>
    {(t) => (
      <div className="flex flex-col gap-1">
        <div
          style={style}
          className={classNames('flex items-center gap-3', className)}>
          {_.isFunction(renderTitle)
            ? renderTitle()
            : renderIf(
              title,
              _.isString(title) ? (
                <span className="font-medium text-sm text-color-300 uppercase italic">
                    {t(title)}
                  </span>
              ) : (
                title
              )
            )}
          <div className="flex-1 border-b"/>
          {extra}
        </div>
        <div className="ml-2 text-xs text-color-500 italic">
          {`* ${t(description)}`}
        </div>
      </div>
    )}
  </Translate>
)

export default ListHeader
