import { Tabs } from 'antd'
import { feed_fetchFeedByContainer_Api } from 'apis'
import { getId, getType } from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import InputBoardForm from 'components/Feed/InputBoard/InputBoardForm'
import Pure from 'components/Pure'
import { useQueryString } from 'hooks'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import { permissionCodes } from 'modules/permissions/contants'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { nest } from 'recompose'
import { feedContentTypes } from 'views/Feed/Feed'
import EntityList from 'views/AppLocals/EntityList'
import { bindQueryParams } from 'views/AppLocals/functions/routerHelper'
import { Null } from 'views/Shared'
import { renderFeedByType } from '../../../MainPage/functions/renderFeedItem'
import OrganizationContainerContext from '../OrganizationContainerContext'

const OrganizationNews = ({ Wrapper = 'div', sample, Footer = Null }) => {
  const t = useTranslate()

  const location = useLocation()

  const [queryString] = useQueryString()

  const content_type = _.get(queryString, 'content-type')

  const { organization } = useContext(OrganizationContainerContext)

  const [id, prop] = [getId(organization), getType(organization)]

  const apiInfo = feed_fetchFeedByContainer_Api

  const refreshKey = useMemo(
    () =>
      [
        apiInfo.path.replace(':id', id).replace(':prop', prop),
        content_type,
        `refreshToken=${location?.state?.refreshToken}`,
      ].join('/'),
    [id, prop, apiInfo, content_type, location?.state]
  )

  if (_.isEmpty(organization)) return null

  const { available_actions } = organization

  return (
    <Wrapper className="space-y-3">
      {!!!sample && (
        <PermissionProvider
          permissions={{
            [permissionCodes.canPost]: _.get(
              available_actions,
              permissionCodes.canPost
            ),
          }}>
          <InputBoardForm
            container={{ type: getType(organization), id: getId(organization) }}
          />
        </PermissionProvider>
      )}

      <NavigationContext.Consumer>
        {({ history }) => (
          <Tabs
            activeKey={content_type}
            tabBarStyle={{ margin: 0 }}
            destroyInactiveTabPane={true}
            onChange={(activeKey) => {
              const searchParams = new URLSearchParams(
                _.get(history, 'location.search')
              )
              searchParams.set('content-type', activeKey)
              history.push({
                search: '?' + searchParams.toString(),
              })
            }}>
            {feedContentTypes.map(({ label, value }) => (
              <Tabs.TabPane
                key={value}
                tab={
                  <span className="space-x-1">
                    <span>{t(label)}</span>
                  </span>
                }
              />
            ))}
          </Tabs>
        )}
      </NavigationContext.Consumer>

      <Pure input={[id, prop, content_type, refreshKey]}>
        <EntityList
          sample={sample}
          key={refreshKey}
          values={{
            content_type,
          }}
          apiInfo={apiInfo}
          query={bindQueryParams([{ id }, { prop }])}
          renderItem={renderFeedByType(content_type || 'all')}
          renderEmpty={() => (
            <div className="w-full justify-center items-center">
              <EmptyHolder />
            </div>
          )}
          renderWrap={(child, __, count) => (
            <div className="space-y-3">
              {child}
              {count ? <Footer /> : null}
            </div>
          )}
        />
      </Pure>

      <div className="h-20" />
    </Wrapper>
  )
}

export default nest(Pure, OrganizationNews)
