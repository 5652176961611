import _ from 'lodash';
import {
	AUTHENTICATE,
	GET_COMMENTS,
	JOIN_ROOM,
	SEND_COMMENT,
	VOTE_COMMENT
} from './types';

export const authenticate = ({
	token
}) => ({
	type: AUTHENTICATE,
	payload: { token }
});
export const startRoom = ({
	roomId,
	roomType
}) => ({
	type: JOIN_ROOM,
	payload: { roomId, roomType }
});
export const getComments = ({
	parent_id,
	message
}) => ({
	type: GET_COMMENTS,
	payload: { parent_id, message }
});
export const sendComment = ({
	id = _.uniqueId(),
	parent_id,
	message
}) => ({
	type: SEND_COMMENT,
	payload: {
		parent_id,
		message,
		id
	}
});

export const voteComment = ({
	comment_id
}) => ({
	type: VOTE_COMMENT,
	payload: {
		comment_id
	}
});
