import React from "react";

export const SugarCane = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="-14 0 512 512"
		>
			<path
				fill="#b4de87"
				d="M416.305 443.57l-105.621-18.625c-4.524-.797-6.856-5.875-4.504-9.828a73.47 73.47 0 009.199-24.781l14.945-84.762a73.492 73.492 0 00-.168-26.433c-.855-4.516 3.067-8.493 7.594-7.696l105.621 18.625c4.524.797 6.852 5.875 4.504 9.828a73.47 73.47 0 00-9.2 24.782L423.73 409.44a73.426 73.426 0 00.168 26.438c.86 4.512-3.066 8.488-7.593 7.691zm0 0"
			></path>
			<path
				fill="#92d18a"
				d="M365.66 276.367l-27.91-4.922c-4.523-.797-8.45 3.18-7.594 7.696a73.492 73.492 0 01.168 26.433l-14.945 84.762a73.47 73.47 0 01-9.2 24.781c-2.35 3.953-.023 9.031 4.505 9.828l86.218 15.203c-22.109-52.195-33.53-99.816-31.242-163.78zm0 0"
			></path>
			<path
				fill="#8ec15d"
				d="M443.371 290.07L337.75 271.45c-4.523-.802-6.852-5.88-4.504-9.829a73.47 73.47 0 009.2-24.781l14.945-84.762a73.426 73.426 0 00-.168-26.437c-.856-4.512 3.066-8.489 7.593-7.692l105.621 18.625c4.528.797 6.856 5.875 4.504 9.824a73.49 73.49 0 00-9.199 24.786l-14.945 84.761a73.492 73.492 0 00.168 26.434c.855 4.516-3.067 8.488-7.594 7.691zm0 0"
			></path>
			<path
				fill="#57b33b"
				d="M393.695 123.043l-28.879-5.094c-4.527-.797-8.449 3.18-7.593 7.692a73.514 73.514 0 01.168 26.437l-14.946 84.762a73.395 73.395 0 01-9.203 24.781c-2.347 3.95-.02 9.027 4.508 9.828l54.914 9.684c-12.781-35.551-7.25-114.09 1.031-158.09zm0 0"
			></path>
			<path
				fill="#b4de87"
				d="M478.031 128.883a73.514 73.514 0 01-.168-26.438l5.711-32.386L382.812 7.91l-13.3 75.434a73.425 73.425 0 01-9.203 24.781c-2.348 3.95-.02 9.027 4.507 9.824l105.621 18.625c4.524.797 8.45-3.176 7.594-7.691zm0 0"
			></path>
			<path
				fill="#92d18a"
				d="M412.426 26.176L382.812 7.91l-13.3 75.434a73.425 73.425 0 01-9.203 24.781c-2.348 3.95-.02 9.027 4.507 9.824l45.102 7.953c-13.527-29.918-5.512-69.828 2.508-99.726zm0 0"
			></path>
			<path
				fill="#8ec15d"
				d="M376.629 469.203l-105.621 18.625c-4.524.797-8.45-3.18-7.594-7.691a73.426 73.426 0 00.172-26.438l-14.95-84.761a73.5 73.5 0 00-9.199-24.782c-2.347-3.949-.019-9.027 4.508-9.824l105.621-18.625c4.524-.8 8.45 3.176 7.594 7.691a73.404 73.404 0 00-.172 26.434l14.95 84.762a73.49 73.49 0 009.199 24.785c2.347 3.95.02 9.027-4.508 9.824zm0 0"
			></path>
			<path
				fill="#57b33b"
				d="M281.426 327.723l-37.485 6.609c-4.523.797-6.851 5.875-4.504 9.824a73.49 73.49 0 019.2 24.785l14.945 84.762a73.405 73.405 0 01-.168 26.434c-.855 4.515 3.07 8.492 7.594 7.691l61.308-10.808c-35.425-28.043-48.984-100.793-50.89-149.297zm0 0"
			></path>
			<path
				fill="#b4de87"
				d="M349.563 315.707L243.94 334.332c-4.523.797-8.449-3.18-7.593-7.691a73.426 73.426 0 00.172-26.438l-14.95-84.762a73.47 73.47 0 00-9.199-24.78c-2.348-3.95-.02-9.028 4.508-9.829L322.5 162.211c4.523-.8 8.45 3.176 7.594 7.691a73.404 73.404 0 00-.168 26.434l14.945 84.762a73.52 73.52 0 009.2 24.785c2.347 3.949.019 9.027-4.509 9.824zm0 0"
			></path>
			<path
				fill="#92d18a"
				d="M254.36 174.227l-37.481 6.609c-4.527.797-6.856 5.875-4.508 9.824a73.5 73.5 0 019.2 24.781l14.945 84.766a73.405 73.405 0 01-.168 26.434c-.856 4.511 3.07 8.488 7.593 7.691l61.309-10.809c-35.426-28.046-48.984-100.793-50.89-149.296zm0 0"
			></path>
			<path
				fill="#8ec15d"
				d="M327.004 152.383a73.47 73.47 0 01-9.2-24.781l-12.964-73.52-100.762 62.145 5.371 30.48a73.404 73.404 0 01-.168 26.434c-.855 4.515 3.07 8.492 7.594 7.691l105.621-18.621c4.527-.8 6.856-5.875 4.508-9.828zm0 0"
			></path>
			<path
				fill="#57b33b"
				d="M204.078 116.23l5.375 30.477a73.404 73.404 0 01-.172 26.434c-.855 4.515 3.07 8.492 7.598 7.695l61.305-10.813c-19.352-15.316-32.168-43.984-40.133-74.746zm0 0"
			></path>
			<path
				fill="#f8eed1"
				d="M169.984 267.05a48.876 48.876 0 00-38.312 18.528L98.25 327.77a663.08 663.08 0 00-81.996 133.253L1.219 493.516C-2.773 502.14 3.527 512 13.035 512H326.93c9.508 0 15.808-9.86 11.816-18.484l-15.031-32.493a663.275 663.275 0 00-82-133.253l-33.422-42.192a48.874 48.874 0 00-38.309-18.527zm0 0"
			></path>
			<path
				fill="#edd8a4"
				d="M144 274.531a48.868 48.868 0 00-12.328 11.047L98.25 327.77a663.098 663.098 0 00-82 133.253L1.219 493.516C-2.773 502.14 3.527 512 13.035 512h248.102C126.113 464.066 112.297 335.297 144 274.531zm0 0"
			></path>
			<path
				fill="#f8eed1"
				d="M483.574 70.059c-2.293 13-26.707 9.625-54.531-7.536C401.219 45.36 380.52 20.91 382.813 7.91c2.289-13 26.707-9.625 54.53 7.535 27.825 17.164 48.524 41.614 46.231 54.614zm0 0M204.078 116.23c2.293 12.997 26.707 9.625 54.531-7.539 27.825-17.16 48.524-41.609 46.23-54.609-2.288-13-26.706-9.625-54.53 7.535-27.825 17.164-48.524 41.613-46.23 54.613zm0 0M71.305 478.137c0 4.105-3.325 7.43-7.43 7.43s-7.434-3.325-7.434-7.43 3.329-7.434 7.434-7.434 7.43 3.328 7.43 7.434zm0 0M99.305 409.2c0 4.105-3.325 7.433-7.43 7.433s-7.434-3.328-7.434-7.434 3.329-7.43 7.434-7.43 7.43 3.325 7.43 7.43zm0 0M130.57 454.906c0 4.102-3.328 7.43-7.433 7.43s-7.434-3.328-7.434-7.43a7.434 7.434 0 1114.867 0zm0 0"
			></path>
			<g fill="#edd8a4">
				<path d="M243.54 462.336c0 4.105-3.33 7.434-7.435 7.434s-7.43-3.329-7.43-7.434c0-4.102 3.325-7.43 7.43-7.43s7.434 3.328 7.434 7.43zm0 0M169.98 326.11c0 4.105-3.324 7.433-7.43 7.433s-7.433-3.328-7.433-7.434a7.433 7.433 0 0114.863 0zm0 0M208.21 371.195a7.433 7.433 0 11-14.861.002 7.433 7.433 0 0114.862-.002zm0 0M270.418 63.188c-1.191-2.063-5.04-2.075-8.594-.02-3.554 2.05-5.469 5.39-4.277 7.453s5.039 2.074 8.594.024c3.554-2.055 5.468-5.391 4.277-7.457zm0 0M234.234 100.297c-1.195-2.063-5.043-2.074-8.593-.02-3.555 2.051-5.473 5.387-4.282 7.453 1.192 2.063 5.04 2.075 8.594.02 3.555-2.05 5.473-5.387 4.281-7.453zm0 0M404.46 9.465c-1.194 2.066.724 5.402 4.278 7.453 3.555 2.055 7.403 2.043 8.594-.02 1.191-2.066-.723-5.402-4.277-7.453-3.555-2.054-7.403-2.043-8.594.02zm0 0M460.254 56.117c-1.192 2.063.723 5.399 4.277 7.453 3.555 2.051 7.403 2.043 8.594-.023 1.195-2.063-.723-5.402-4.277-7.453-3.555-2.051-7.403-2.043-8.594.023zm0 0M422.344 41.75c-1.192 2.066.722 5.402 4.277 7.453 3.555 2.055 7.402 2.043 8.594-.02 1.191-2.066-.723-5.402-4.277-7.453-3.555-2.054-7.403-2.042-8.594.02zm0 0"></path>
			</g>
		</svg>
	);
}

export default SugarCane;
