import React from 'react'
import { CustomPagingHeader, Null, renderOwnChild } from 'views/Shared'
import SeeMore from '../MySpace/components/SeeMore'

export const renderLimiter = (limit, renderItem) => {
  if (limit > 0) {
    return (
      <SeeMore
        Component={(props) => <div {...props} />}
        renderItem={renderItem}
      />
    )
  } else {
    return null
  }
}

export const renderPagingHeaderWrapper =
  ({
    title,
    Header = Null,
    ContentWrapper = renderOwnChild,
    HeaderWrapper = CustomPagingHeader,
  }) =>
  (children) =>
    (
      <div className="space-y-3">
        <HeaderWrapper title={title}>
          <Header />
        </HeaderWrapper>
        <ContentWrapper>{children}</ContentWrapper>
      </div>
    )

export const renderSeeMoreWrapper = (title, content, action) => (
  <React.Fragment>
    {title && <CustomPagingHeader title={title} />}
    <div className="space-y-3 mt-2">
      {content}
      {action}
    </div>
  </React.Fragment>
)

const renderPagingWrapper =
  (title, limit = 0, setLimit = Null) =>
  (content) =>
    renderSeeMoreWrapper(
      title,
      content,
      renderLimiter(limit, (render) =>
        render({
          onClick: () => {
            setLimit(0)
          },
        })
      )
    )

export default renderPagingWrapper
