import {Button, Modal, Select, Spin} from 'antd'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useState} from 'react'
import {API_ROOT_URL} from "../../../../../envs/_current/config";
import LinesChart from "../../../../Chart/Charts/ChartComponents/LinesChart";
import {DelayRender} from "../../../../Discovery/DelayRender";
import useAsyncWithCache from "../../../../../modules/asyncCache/useAsyncWithCache";
import * as moment from "moment";
import {PriceAdvancedInputForm} from "../ManageProductPrices";
import {FullSideModalLayout} from "../../../../../components/Modal/SideModalLayout";
import {emptyObject} from "../../../../../helpers/emptyObjects";

export const ProductPriceSetChart = ({
    product,
    product_id,
    optionByProduct = false,
    contract_type_cd,
    delivery_type_cd,
    incoterm_type_cd,
    transport_type_cd,
    unit_id,
    unit2_id,
    price_unit_id,
    price_unit2_id,
    location_type,
    location_id,
    eventId,
    setEventId,
    additionalData = [],
    additionalData2 = [],
}) => {

    const t = useTranslate();
    const [location_option_id, setLocationOptionId] = useState();
    const [price_set, setPriceSet] = useState({
        contract_type_cd,
        delivery_type_cd,
        incoterm_type_cd,
        transport_type_cd,
        unit_id,
        unit2_id,
        price_unit_id,
        price_unit2_id,
        location_type,
        location_id
    });

    const params = {
        product_id,
        location_option_id,
        ...price_set,
        eventId
    };
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

    const apiPath = `/products/product-price-set/get-price-set-history-data?${queryString}`;

    const {
        response, success, isLoading
    } = useAsyncWithCache({
        once: false,
        cacheId: apiPath,
        apiInfo: {
            root: API_ROOT_URL,
            path: apiPath,
            method: 'GET'
        },
        query: params
    });

    const {
        data = [],
        data2 = [],
        data_columns = [],
        unit_names = [],
        location_options = [],
        price_set_options = []
    } = _.get(response, 'data', {});

    const activePriceSet = (!!_.get(price_set, 'contract_type_cd') ? price_set : _.get(price_set_options, '0')) || emptyObject;
    const activePriceSetId = _.get(activePriceSet, 'id');
    const activeLocationOptionId = location_option_id || _.get(location_options, '0.value');

    let _l_data = data;
    let _l_updated_row = [];
    if (!_.isEmpty(additionalData)) {
        _l_data = data.filter(e => !moment(e[0]).isSame(moment(additionalData[0])));

        if (_.get(price_set, 'contract_type_cd') === "spot") {
            _l_data = _.concat(_l_data, [additionalData]);
        } else {
            if (!_.isEmpty(data)) {
                _l_updated_row = _.cloneDeep(_.first(data.filter(e => moment(e[0]).isSame(moment(additionalData[0])))));
                const updatedIndex = _.findIndex(data_columns, function (o) {
                    return o.dataIndex === (additionalData[2] + "/" + _.padStart(additionalData[3], 2, '0'))
                });

                if (!!_l_updated_row && updatedIndex > -1) {
                    _l_updated_row[updatedIndex] = additionalData[1];
                    _l_data = _.concat(_l_data, [_l_updated_row]);
                } else {
                    _l_updated_row = _.cloneDeep(data[0]);
                    _l_updated_row[0] = additionalData[0];
                    _l_data = _.concat(_l_data, [_l_updated_row]);
                }
            } else {
                _l_data = [additionalData];
            }
        }
        _l_data.sort(function (a, b) {
            return moment(a[0]).diff(moment(b[0]));
        });
    }
    let _l_data2 = data2;
    if (!_.isEmpty(additionalData2)) {
        _l_data2 = data2.filter(e => !moment(e[0]).isSame(moment(additionalData2[0])));
        if (_.get(price_set, 'contract_type_cd') === "spot") {
            _l_data2 = _.concat(_l_data2, [additionalData2]);
        } else {
            if (!_.isEmpty(data)) {
                _l_updated_row = _.cloneDeep(_.first(data2.filter(e => moment(e[0]).isSame(moment(additionalData2[0])))));
                const updatedIndex = _.findIndex(data_columns, function (o) {
                    return o.dataIndex === (additionalData2[2] + "/" + _.padStart(additionalData2[3], 2, '0'))
                });

                if (!!_l_updated_row && updatedIndex > -1) {
                    _l_updated_row[updatedIndex] = additionalData2[1];
                    _l_data2 = _.concat(_l_data2, [_l_updated_row]);
                } else {
                    _l_updated_row = _.cloneDeep(data2[0]);
                    _l_updated_row[0] = additionalData2[0];
                    _l_data2 = _.concat(_l_data2, [_l_updated_row]);
                }
            } else {
                _l_data2 = [additionalData2];
            }
        }
        _l_data2.sort(function (a, b) {
            return moment(a[0]).diff(moment(b[0]));
        });
    }

    return (
        <DelayRender time={300}>
          <Spin className="flex items-center justify-center" spinning={isLoading}>
            {success
            && !_.isEmpty(data_columns)
            && !_.isEmpty(_l_data)
            && (
                <div className="flex flex-col space-y-3">
                  {!!optionByProduct && (
                      <React.Fragment>
                        {!!activePriceSet && (
                            <div className="flex items-center space-x-3">
                              <Select
                                  onChange={(value, option) => {
                                    setPriceSet(option.paramobj);
                                  }}
                                  defaultValue={_.get(price_set, 'id') || activePriceSetId}
                                  showArrow
                                  size="large"
                                  style={{width: '100%'}}>
                                  {price_set_options.map((eee, eeei) => (
                                      <Select.Option
                                          key={eeei} value={eee.id} paramobj={eee}
                                          label={t(eee.contract_type_cd || "")}>
                                          <div className="flex flex-col text-xs italic leading-tight">
                                              <span className="space-x-2 text-color-400 leading-tight">
                                                  {`${t("contract type")} #${eeei + 1} : `}
                                                  <span className="font-bold text-color-000">
                                                      {t(eee.contract_type_cd || "")}
                                                  </span>
                                              </span>
                                              {_.get(eee, 'contract_type_cd') !== "spot" && (
                                                  <span className="space-x-2 leading-tight">
                                                      {[
                                                          t(_.get(eee, 'delivery_type_cd')) || t("delivery type"),
                                                          t(_.get(eee, 'incoterm_type_cd')) || t("incoterm"),
                                                          t(_.get(eee, 'transport_type_cd')) || t("transport type")
                                                      ].join(" / ")}
                                                  </span>
                                              )}
                                              <span className="space-x-2 text-color-400 leading-tight">
                                                  {`${t("unit")} : `}
                                                  <span className="font-bold text-color-000">
                                                      {[
                                                          t(eee.price_unit_name || "price unit"),
                                                          t(eee.unit_name || "unit")
                                                      ].join("/")}
                                                  </span>
                                                  {_.get(eee, 'contract_type_cd') !== "spot" && !!eee.price_unit2_name && (
                                                      <span className="font-bold text-color-000">
                                                          {[
                                                              t(eee.price_unit2_name || "price unit"),
                                                              t(eee.unit2_name || "unit")
                                                          ].join("/")}
                                                      </span>
                                                  )}
                                              </span>
                                          </div>
                                      </Select.Option>
                                  ))}
                              </Select>
                            </div>
                        )}
                        <div className="flex items-center gap-3">
                          <span className="text-sm text-color-400 italic whitespace-no-wrap">
                              {`${t('location')} : `}
                          </span>
                          <Select
                              onChange={setLocationOptionId}
                              defaultValue={location_option_id || activeLocationOptionId}
                              showArrow
                              style={{minWidth: 200, width: '100%'}}
                              options={location_options}
                          />
                        </div>
                      </React.Fragment>
                  )}

                  {((location_option_id || activeLocationOptionId) === "A" || !(location_option_id || activeLocationOptionId)) && (
                      <div className="italic text-red-500 text-sm">
                        * {t('Prices below are average prices with selected location or selected period is All')}
                      </div>
                  )}

                  <div className="w-full flex flex-center font-bold text-color-100">
                    {t('flat price')}
                  </div>

                  <LinesChart
                      chartInfo={
                        {
                          id: "01" + apiPath,
                          idname: "01" + apiPath + "-price-history",
                          title: " (" + t('price history') + ")",
                          data_values: _l_data,
                          data_columns: data_columns
                        }
                      }
                  />

                  {_.get(activePriceSet, 'contract_type_cd') !== "spot" && (
                      <React.Fragment>
                        <div
                            className="flex items-center justify-center w-full text-primary font-bold">{t('basis price')}</div>
                        <LinesChart
                            chartInfo={
                              {
                                id: "02" + apiPath,
                                idname: "02" + apiPath + "-price-history",
                                title: " (" + t('price history') + ")",
                                data_values: _l_data2,
                                data_columns: data_columns
                              }
                            }
                        />
                      </React.Fragment>
                  )}

                  <div className="flex flex-col gap-3">
                    <div className="flex items-center gap-1">
                      <span className="text-xs text-color-400 italic">
                        {`${t('last updated')} : `}
                      </span>
                      <span className="flex-1 text-xs text-color-100 font-bold">
                        {moment(_l_data[0][0]).format("DD/MM/YYYY")}
                      </span>
                      <Toggle key={product.id}>
                        {( isTogglePriceInput, togglePriceInput ) => (
                            <React.Fragment>
                              <Button
                                  size="small"
                                  type="primary"
                                  className="button-rounded-md font-medium text-xs"
                                  onClick={togglePriceInput}>
                                {t('update')}
                              </Button>
                              {isTogglePriceInput && (
                                  <Modal
                                      width={720}
                                      className="custom-modal"
                                      onCancel={togglePriceInput}
                                      maskClosable={false}
                                      title={
                                        <div
                                            className="text-center font-bold text-color-000 uppercase tracking-wide">
                                          {_.get(product, 'title')}
                                        </div>
                                      }
                                      destroyOnClose
                                      visible={isTogglePriceInput}
                                      footer={null}>
                                    <PriceAdvancedInputForm
                                        {...{
                                          item: product
                                        }}
                                    />
                                  </Modal>
                              )}
                            </React.Fragment>
                        )}
                      </Toggle>
                    </div>
                    {data_columns.map((e, i) => (
                        <div
                            key={i}
                            className={
                              _.get(activePriceSet, 'contract_type_cd', "") !== "spot"
                                ? "grid grid-cols-3 gap-3 border-b border-dashed text-sm"
                                : "grid grid-cols-2 gap-3 border-b border-dashed text-sm"
                            }>
                          <div className="font-medium">
                            {e.id === 'time' ? t("period") : t(e.id)}
                          </div>
                          {i === 0
                              ? (
                                  <div className="truncate text-right 111">
                                    {unit_names[0]}
                                  </div>
                              ) : (
                                  <div className="truncate text-right 222">
                                    {Number(_l_data[0][i]) > 0 ? Number(_l_data[0][i]).toLocaleString() : null}
                                  </div>
                              )
                          }
                          {i === 0
                              ? (
                                  <div className="truncate text-right 333">
                                    {unit_names[1]}
                                  </div>
                              ) : (
                                  <div className="truncate text-right 444">
                                    {Number(_l_data2[0][i]) > 0 ? Number(_l_data2[0][i]).toLocaleString() : null}
                                  </div>
                              )
                          }
                        </div>
                    ))}
                  </div>

                </div>
            )}
          </Spin>
        </DelayRender>
    )
}
