import {Avatar} from 'antd'
import {articleGroups_CategoriesSchema} from 'apis'
import {articleSchema} from 'apis/schema'
import {SelectEntityItem} from 'components/SelectEntityItem'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import {BsDiamondFill} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import {renderIf} from 'views/Shared'
import {ItemTemplate} from '../functions/renderSearchResultItem'
import {paths} from "../../MainPage/contains";
import EmptyHolder from "../../../components/EmptyHolder";
import {IoNewspaper} from "react-icons/io5";

const renderArticleGroup = (item, index) => {
  return (
    <Translate key={index}>
      {(t) => (
        <SelectEntityItem
          item={item}
          schema={articleGroups_CategoriesSchema}>
          {(item) => {
            if (_.some([item, _.get(item, 'articles')], _.isEmpty)) {
              return null
            }
            return (
              <div className="flex flex-col">
                <span className="border-b pb-1 mb-3 text-color-300 uppercase font-medium">
                  {t(getTitle(item))}
                </span>
                <div className="flex flex-col gap-4 px-2">
                  {Array.from(_.get(item, 'articles', [])).map((sub, index) => (
                    <ItemTemplate
                      showBanner={false}
                      item={sub}
                      key={index}>
                      <div className="flex items-center gap-2">
                        <Avatar
                          icon={<IoNewspaper className="text-color-400"/>}
                          src={_.get(sub, 'cover_photo')}
                          className="flex flex-center background-200"
                        />
                        <div className="flex flex-col flex-1">
                          <Link
                            to={getLinkToDetail(sub)}
                            className="max-lines-1 font-medium text-color-000 no-underline">
                            {getTitle(sub)}
                          </Link>
                          <span className="text-color-500 italic text-xs font-light">
                            {t('by')}
                            <span className="text-color-300 font-medium ml-1">
                              {_.get(sub, 'creator.name')}
                            </span>
                          </span>
                        </div>
                      </div>
                    </ItemTemplate>
                  ))}
                </div>
              </div>
            )
          }}
        </SelectEntityItem>
      )}
    </Translate>
  )
}

const ArticleResult = ({
                         latest_articles = [],
                         categories: article_groups = [],
}) => (
  <div className="flex flex-col w-full space-y-4">
    <Translate>
      {(t) => (
        latest_articles.length > 0 ?
          <div className="flex flex-col">
                  <span className="mb-2 text-color-300 font-medium italic">
                    {t('lasted-articles')}
                  </span>
            <div className="space-y-4 px-2">
              {Array.from(latest_articles).map((transformed_id, index) => (
                <div key={index}>
                  <SelectEntityItem
                    item={transformed_id}
                    schema={articleSchema}>
                    {(item) =>
                      renderIf(
                        item,
                        <ItemTemplate
                          showBanner={false}
                          item={item}>
                          <div className="flex items-center gap-2">
                            <Avatar
                              icon={<IoNewspaper className="text-color-400"/>}
                              src={_.get(item, 'cover_photo')}
                              className="flex flex-center background-200"
                            />
                            <div className="flex flex-col flex-1">
                              {_.get(item, 'categories', []).length > 0 && (
                                <div className="flex flex-wrap gap-3">
                                  {_.get(item, 'categories', []).map((e, index) => (
                                    <div
                                      key={index}
                                      className="flex items-center text-primary">
                                      <BsDiamondFill
                                        size={5}
                                        className="mr-1"
                                      />
                                      <Link
                                        to={
                                          paths.categoryPath
                                            .replace(':category', e.id)
                                            .replace(':refcategory', e.alt_idname) +
                                          '?lang=' +
                                          e.language
                                        }
                                        key={e.id}
                                        className="italic font-medium text-xs">
                                        {e.name}
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              )}
                              <Link
                                to={getLinkToDetail(item)}
                                className="max-lines-1 font-medium text-color-000 no-underline">
                                {getTitle(item)}
                              </Link>
                              <span className="text-color-500 italic text-xs font-light">
                                                          {t('by')}
                                <span className="text-color-300 font-medium ml-1">
                                                            {_.get(item, 'creator.name')}
                                                          </span>
                                                      </span>
                            </div>
                          </div>
                        </ItemTemplate>
                      )
                    }
                  </SelectEntityItem>
                </div>
              ))}
            </div>
          </div>
          : <EmptyHolder/>
      )}
    </Translate>
    {Array.from(article_groups).map(renderArticleGroup)}
  </div>
)

export default ArticleResult
