import _ from 'lodash'
import React, {
  useEffect,
  useState
} from 'react'
import {
  Redirect,
  withRouter
} from 'react-router-dom'
import {
  withProps
} from 'recompose'


const FixAppNavigation = withRouter(
  ({ match, location, history, Routes }) => {
    const [ready, setReady] = useState(
      () => history.length > 2
    )

    useEffect(() => {
      if (!ready) {
        history.replace({
          pathname: '/',
          state: {
            ready: true,
            location
          }
        })
      }
    }, [history, location, ready])
    const getReady = _.get(
      location,
      'state.ready'
    )
    useEffect(() => {
      if (!ready) {
        if (getReady) {
          setReady(true)
          history.push(
            location.state.location
          )
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getReady])
    if (!ready) return null
    return (
      <Routes
        {...{
          match,
          location,
          history
        }}
      />
    )
  }
)

export const RedirectToModal = withProps(
  props => ({
    to: {
      ...props.location,
      state: {
        isModal: true
      }
    }
  })
)(Redirect)

export default FixAppNavigation
