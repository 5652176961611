import React from 'react'
import {withProps} from 'recompose'

const ListElement = ({style, children, attributes, Component = 'ul'}) => (
  <Component
    style={style}
    {...attributes}>
    {children}
  </Component>
)

export const ListItemElement = withProps({Component: 'li'})(ListElement)

export const OrderListElement = withProps({Component: 'ol'})(ListElement)

export const UnOrderListElement = withProps({Component: 'ul'})(ListElement)

export default ListElement
