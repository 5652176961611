import { Spin } from 'antd'
import React from 'react'
const LoadingPage = () => {
  return (
    <div className="p-10 w-full flex justify-center ">
      <Spin />
    </div>
  )
}

export default React.memo(LoadingPage)
