import {ConfigProvider, Empty, Select} from 'antd'
import Null from 'components/NullComponent'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'

const customizeRenderEmpty = () => (
  <Empty
    imageStyle={{
      height: 60
    }}
    description={
      <span>
        Customize{' '}
        <a href="#API">Description</a>
      </span>
    }/>
)
export const MultipleSelect = ({
                                 value,
                                 options = [],
                                 onChange = Null,
                                 renderItem,
                                 ...rest
                               }) => {
  const t = useTranslate()

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Select
            {...rest}
            optionFilterProp="label"
            defaultOpen={false}
            value={value}
            notFoundContent={null}
            loading={false}
            onFocus={() => null}
            mode="multiple"
            placeholder={t('please select')}
            onChange={onChange}
            style={{width: '100%'}}>
            {options.map(({id, name}) => (
                <Select.Option
                    className="m-1 rounded overflow-hidden"
                    key={id}
                    value={id}
                    label={t(name)}>
                    {renderItem ? renderItem({value: id, label: name}) : t(name)}
                </Select.Option>
            ))}
        </Select>
    </ConfigProvider>
  )
}
