import { schema } from 'normalizr'
import { chartSchema } from '../chart'

export const widgetSchema = new schema.Entity(
  'widgets',
  {
    owner: new schema.Union(
      {
        chart: chartSchema
      },
      '_type'
    )
  },
  {
    idAttribute: 'id'
  }
)
