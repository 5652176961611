import React, { useContext } from 'react'
import AdminPanel from 'views/AppLocals/AdminPanel'
import LawAlert from 'views/AppLocals/AppAlert'
import { LoadingWrapper, renderOwnChild } from 'views/Shared'
import EventContext from '../EventContext'
import EventNav from './EventNav'

const EventContent = ({ Wrapper = renderOwnChild }) => {
  const { event } = useContext(EventContext)
  return (
    <Wrapper>
      <LoadingWrapper
        center={false}
        isLoading={!event}>
        <LawAlert entity={event}>
          <AdminPanel />
        </LawAlert>
      </LoadingWrapper>
      <div className="space-y-3">
        <EventNav />
      </div>
    </Wrapper>
  )
}

export default EventContent
