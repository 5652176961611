import React from "react";

export const Cashew = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<g>
				<path
					fill="#fae5ca"
					d="M145.669 325.039c5.997-28.52 37.23-47.755 66.366-48.416 31.894-.723 62.109 19.699 93.995 14.539 15.755-2.55 31.25-13.01 35.042-28.513 8.423-34.437-39.557-58.198-41.133-115.815-.577-21.11 6.58-42.841 21.603-57.683 19.89-19.649 51.297-24.702 78.182-17.027s49.499 26.496 66.671 48.56c40.881 52.528 53.01 126.113 31.151 188.982s-77.024 113.057-141.674 128.892c-64.651 15.837-233.102-4.629-210.203-113.519z"
				></path>
				<path
					fill="#cfa773"
					d="M340.633 314.648c-26.131 10.429-60.192-.738-74.255-27.064 13.074 3.382 26.492 5.734 39.679 3.554 15.711-2.523 31.251-12.959 35.034-28.498 8.169-33.336-35.896-55.354-40.826-109.92 9.977 15.194 24.484 27.409 38.131 39.679 16.743 15.138 33.486 32.454 38.303 54.53 5.849 27.179-10.263 57.455-36.066 67.719z"
				></path>
				<path
					fill="#fae5ca"
					d="M296.765 291.925c-27.09.875-56.073-15.874-84.754-15.297-29.146.644-60.332 19.914-66.343 48.416-3.757 18.089-2.308 33.762 3.113 47.234-6.012-.537-11.97-1.396-17.874-2.63-39.505-8.213-75.79-31.937-98.173-65.538-28.449-42.618-57.863-169.884 26.945-181.908 22.168-3.167 44.283 14.385 52.441 35.318 7.212 18.734 3.972 39.184 9.125 58.936-1.713 3.426 11.747 32.244 33.601 30.81.429.054.859 0 1.288-.053 25.241-2.738 32.731-40.234 64.518-56.145.054-.107.215-.107.269-.161 1.337-.763 3.744-1.909 3.972-1.985.107 0 .107-.054.107-.054 40.873-19.638 81.274 6.541 84.379 50.777 1.214 18.284-3.931 35.897-12.614 52.28z"
				></path>
				<path
					d="M304.172 275.179c-2.039 5.743-4.562 11.379-7.407 16.747-27.09.875-56.073-15.874-84.754-15.297-29.146.644-60.332 19.914-66.343 48.416-3.757 18.089-2.308 33.762 3.113 47.234-6.012-.537-11.97-1.396-17.874-2.63-4.187-14.815-4.562-30.971-1.02-47.932 8.159-38.7 48.255-60.439 81.802-61.191h2.04c33.364-.001 65.909 19.565 90.443 14.653z"
					opacity="0.1"
				></path>
				<path
					fill="#cfa773"
					d="M118.362 260.558c-14.407-16.173-15.266-43.754.094-60.842 3.58 37.65 22.782 49.152 37.663 47.522 26.269-2.839 30.636-40.508 68.831-58.399-18.652 24.957-20.909 62.882-48.115 80.044-18.132 11.367-44.272 7.654-58.473-8.325z"
				></path>
			</g>
		</svg>
	);
}

export default Cashew;
