import { SearchOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import { LocalContext } from 'modules/local/LocalContext'
import useRouter from 'modules/navigation/useRouter'
import React, {
  useContext
} from 'react'
import './SearchInput.css'
function SearchInput() {
  const { translate } = useContext(
    LocalContext
  )
  const { history } = useRouter()
  return (
    <Input
      onFocus={() =>
        history.push({
          pathname: '/search',
          state: { isModal: true }
        })
      }
      size="large"
      className="headerSeachInput"
      placeholder={translate(
        'search...'
      )}
      prefix={
        <SearchOutlined
          style={{
            fontSize: '1.4em',
            color: 'rgba(0,0,0,.25)'
          }}
        />
      }
    />
  )
}
export default SearchInput
