import {Avatar, Badge} from 'antd'
import {getId} from 'apis/model/base'
import {organizationSchema} from 'apis/schema'
import classNames from 'classnames'
import {LanguageSwitch} from 'components/layouts/Default/SiderMenu/LanguageSwitch'
import getAvatar from 'helpers/getAvatar'
import _ from 'lodash'
import {LocalContext} from 'modules/local/LocalContext'
import Translate from 'modules/local/Translate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useCallback, useContext} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-use'
import {getLogin, selectEntities} from 'redux/selectors'
import {staticPaths} from 'routes/staticPaths'
import {Null} from 'views/Shared'
import WorkspacePicker from 'views/Workspace/components/WorkspacePicker'
import {RiSearch2Line} from "react-icons/ri";
import {FaWarehouse} from "react-icons/fa6";
import {FlagIcon} from "../../components/icons/FlagIcon";
import {IoChatbubblesOutline} from "react-icons/io5";

const defaultItems = [
  // {
  //   label: 'introduce',
  //   to: {
  //     pathname: '/start',
  //   },
  // },
  // {
  //   label: 'discovery',
  //   to: {
  //     pathname:
  //       staticPaths.discovery.pathname,
  //   },
  // },
]

const DefaultActions = () => {
  const history = useHistory()

  const {lang} = useContext(LocalContext)

  const mainLocation = useLocation()

  const {primary_org} = useSelector(getLogin) || {}

  const primary_id = _.isString(primary_org) ? primary_org : getId(primary_org)

  const primary_organization =
    useSelector((state) =>
      selectEntities(state, primary_id, organizationSchema)
    ) || {}

  const renderAvatar = useCallback(
    (item) => (
      <Avatar
        size={30}
        src={getAvatar(item)}
        icon={<FaWarehouse className="text-color-300"/>}
        shape="square"
        style={{
          padding: '0.2em',
          zIndex: 3
        }}
        className={classNames(
          'flex flex-center rounded-md border background-100 custom-avatar-workspace',
          mainLocation.pathname.startsWith(staticPaths.workspace.pathname)
            ? 'border-primary'
            : 'border-color-500'
        )}
      />
    ),
    [mainLocation.pathname]
  )

  return (
    <div className="md:hidden flex items-center gap-3 mr-3 md:mr-0">
        {!_.isEmpty(primary_organization) ? (
            <div className="flex flex-center gap-4 flex-shrink-0 ">
                <div className="flex flex-wrap flex-row items-center cursor-pointer">
                    <WorkspacePicker
                        primary_organization={primary_organization}
                        IconComponent={({item, isSm}) => renderAvatar(item)}
                    />
                </div>
            </div>
        ) : (
            <Link to={staticPaths.workspace.pathname}>
                <Avatar
                    size={30}
                    icon={<FaWarehouse size={18} className="text-color-300"/>}
                    shape="square"
                    className="flex flex-center rounded-md background-100 custom-avatar-workspace"
                />
            </Link>
        )}
        <LanguageSwitch
            title={
                <Avatar
                    size={28}
                    icon={ <FlagIcon type={lang} size={27}/> }
                    className="flex flex-center"
                />
            }
        />
        <Link
            to={`/messages`}
            className="flex items-center">
            <Badge count={0}>
                <Avatar
                    size={30}
                    icon={<IoChatbubblesOutline size={14} className="text-color-400"/>}
                    className="flex flex-center background-200 hover:background-100 custom-avatar-workspace"
                />
            </Badge>
        </Link>
        <div
            onClick={() => {
                history.push({
                    pathname: staticPaths.search.pathname,
                    state: { isModal: true },
                })
            }}
            className="flex items-center justify-center">
            <Avatar
                size={30}
                icon={<RiSearch2Line size={14} className="text-color-400"/>}
                className="flex flex-center background-200 hover:background-100 custom-avatar-workspace"
            />
        </div>
    </div>
  )
}

const HeaderNavigation = ({items = defaultItems, ExtraActions = Null}) => (
  <React.Fragment>
    <Translate>
      {(t) => (
        <nav className="hidden md:flex items-center">
          {items.map(({label, to}, index) => (
            <h2 key={index}>
              <Link
                to={to.pathname}
                className={classNames(
                  'px-6 font-bold uppercase',
                  index === 0
                    ? 'color-primary'
                    : 'text-color-200 hover:text-primary'
                )}>
                {t(label)}
              </Link>
            </h2>
          ))}
        </nav>
      )}
    </Translate>
    <div className="flex items-center">
      <DefaultActions/>
      <ExtraActions/>
    </div>
  </React.Fragment>
)
export default HeaderNavigation
