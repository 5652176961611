import { organizationSchema } from 'apis/schema'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import React from 'react'
import { notEmpty } from 'views/Shared'
import useTranslate from "../../../modules/local/useTranslate";
import classNames from "classnames";
import {renderIf} from "../../Shared";
import {Popconfirm, Tooltip} from "antd";
import {createAsyncAction} from "../../../modules/asyncCache";
import {create_product_update_Api} from "../../../apis";
import {BsCaretDownFill, BsCaretUpFill, BsCheckCircleFill, BsInfoCircleFill, BsXCircleFill} from "react-icons/bs";
import Toggle from "../../../components/Toggle";



const OrganizationEntity = ({
  item,
  children,
  defaultItem,
}) => {
  return (
    <SelectEntityItem
      item={item}
      defaultItem={defaultItem}
      schema={organizationSchema}>
      {(data) => children(data)}
    </SelectEntityItem>
  )
}

export const ItemInformation = ({ label, value, className = 'text-sm' }) => {

  const t = useTranslate()

  return (
      renderIf(
          value,
          <div className={classNames('flex item-center gap-1 italic', className)}>
            <span className="text-color-500">{`${t(label)} : `}</span>
            <span className="font-bold text-color-100">{value}</span>
          </div>
      )
  )
}

export const MiniButton = ({ className, labelClassName, icon, label, onClick, type, disable = false }) => {

    let _className
    switch (type) {
        case 'primary':
            _className = "bg-primary-50 text-primary-600 hover:bg-primary-100"
            break;
        case 'danger':
            _className = "bg-red-100 text-red-500 hover:bg-red-200"
            break;
        case 'info':
            _className = "bg-blue-100 text-blue-500 hover:bg-blue-200"
            break;
        default :
            _className = "background-200 hover:bg-primary-50 text-color-300 hover:text-primary-600"
            break;
    }
    return (
        <div
            onClick={onClick}
            style={{ padding: '0 8px', height: 23 }}
            className={classNames(
                "flex flex-center gap-2 rounded-md",
                disable === true ? 'cursor-not-allow' : 'cursor-pointer',
                disable === true ? 'background-200 text-color-300 opacity-50' : _className,
                className
            )}>
            {icon}
            <span className={classNames("font-medium text-xs", labelClassName, icon && "hidden md:block")}>
                {label}
            </span>
        </div>
    )
}

export const IconMiniButton = ({ title, className, icon, onClick, type, disable = false }) => {

    let _className
    switch (type) {
        case 'primary':
            _className = "bg-primary-50 text-primary-600 hover:bg-primary-100"
            break;
        case 'danger':
            _className = "bg-red-100 text-red-500 hover:bg-red-200"
            break;
        case 'info':
            _className = "bg-blue-100 text-blue-500 hover:bg-blue-200"
            break;
        default :
            _className = "border border-color-50 background-100 hover:background-200 text-color-300 hover:text-color-100"
            break;
    }

    return (
        <Tooltip title={title} overlayClassName="font-medium text-xs italic">
            <div
                onClick={onClick}
                style={{ width: 23, height: 23 }}
                className={classNames(
                    "flex flex-center rounded cursor-pointer",
                    disable === true ? 'background-200 text-color-300 opacity-50' : _className,
                    className
                )}>
                {icon}
            </div>
        </Tooltip>
    )
}

export const PopupConfirm = ({ icon, title, children, onConfirm }) => {
    const t = useTranslate()
    return (
        <Popconfirm
            icon={icon}
            title={
                <div className="font-medium text-sm text-color-100 italic">
                    {`${t(title)} ?`}
                </div>
            }
            onConfirm={onConfirm}
            okText={t('confirm')}
            cancelText={t('no')}
            okButtonProps={{
                type: 'primary',
                className: 'button-rounded-md no-border text-xs font-medium px-3',
            }}
            cancelButtonProps={{
                className: 'button-rounded-md no-border text-xs font-medium px-3',
            }}>
            {children}
        </Popconfirm>
    )
}

export const CustomAlert = ({ icon, type, title, description, extra }) => {

    let _icon, textColor, borderColor

    switch (type) {
        case 'success' :
            _icon = <BsCheckCircleFill size={14} className="text-primary" />;
            textColor = 'text-primary-600';
            borderColor = 'border-primary-600';
            break;
        case 'error':
            _icon = <BsXCircleFill size={14} className="text-red-600" />;
            textColor = 'text-red-600';
            borderColor = 'border-red-600';
            break;
        default:
            _icon = <BsInfoCircleFill size={14} className="text-blue-500" />;
            textColor = 'text-blue-600';
            borderColor = 'border-blue-600';
            break;
    }

    return (
        <div
            style={{ padding: '4px 6px' }}
            className={classNames("flex items-center gap-2 border rounded-md", borderColor)}>
            {icon ? icon : _icon}
            <div className="flex flex-col flex-1 italic">
                {renderIf(
                    title,
                    <span className={classNames("font-bold text-xs leading-snug", textColor)}>
                        {title}
                    </span>
                )}
                {renderIf(
                    description,
                    <span className="text-2xs text-color-300 leading-snug">
                        {description}
                    </span>
                )}
            </div>
            {extra}
        </div>
    )
}

export const HighLightTitle = ({title, extra}) => {
    const t = useTranslate()
    return (
        <div
            style={{ padding: '4px 10px' }}
            className="flex items-center gap-2 border-l-4 border-primary-600 bg-primary-50 rounded-r-md">
            <span className="flex-1 font-medium text-sm text-primary-600 tracking-wide uppercase">
                {t(title)}
            </span>
            {extra}
        </div>
    )
}

export const ToggleCollapse = ({title, icon, children}) => {
    const t = useTranslate()

    return (
        <Toggle>
            {(isToggle, toggle) => (
                <div className="p-2 space-y-3">
                    <div
                        onClick={toggle}
                        style={{ padding: '0.4rem', cursor: 'pointer', borderRadius: '0.375rem' }}
                        className="flex items-center gap-2 text-color-100 hover:text-primary-600 border border-transparent hover:border-color-50 hover:shadow-items">
                        <div className="flex items-center gap-3 flex-1">
                            {icon}
                            <span className="font-bold text-sm uppercase leading-tight">
                                {t(title)}
                            </span>
                        </div>
                        {isToggle ? (
                            <BsCaretUpFill onClick={toggle} size={10} />
                        ) : (
                            <BsCaretDownFill onClick={toggle} size={10} />
                        )}
                    </div>
                    {isToggle && (
                        children
                    )}
                </div>
            )}
        </Toggle>
    )
}

export const OrganizationEntities = ({
  items,
  children,
  defaultItem,
}) => {
  return (
    <SelectEntityItem
      item={items}
      defaultItem={defaultItem}
      schema={[organizationSchema]}>
      {(data = []) =>
        children(
          _.filter(data, notEmpty)
        )
      }
    </SelectEntityItem>
  )
}

export default OrganizationEntity
