import {ENV_LABEL} from 'envs/_current/config'
import _ from 'lodash'
import React from 'react'
import {Link} from 'react-router-dom'
import AppTextLogoSvg from "../../appConfigs/svgs/AppTextLogoSvg";

export const AppTextLogoIcon = ({to = "/start"}) => {

  return (
    <Link
      to={to}
      style={{
        fontSize: '1em',
        height:
          'var(--header-height)'
      }}
      className="order-first relative inline-flex items-center flex-shrink-0 px-3 text-center">
      <AppTextLogoSvg size={16}/>
      {!_.isEmpty(ENV_LABEL) && (
        <div
          style={{
            position: 'absolute',
            top: '4px',
            left: '40px'
          }}
          className="p-1 rounded text-xs font-bold text-orange-500">
          <h1 className="hidden">
            FeedIn
          </h1>
          {ENV_LABEL}
        </div>
      )}
    </Link>
  )
}
