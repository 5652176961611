import { getId, getType } from 'apis/model/base'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import { ROOT_URL } from 'envs/_current/config'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import * as moment from 'moment'
import React, { useContext } from 'react'
import { BsEnvelopeFill } from 'react-icons/bs'
import ServerContext from 'views/Server/ServerContext'
import { Null } from 'views/Shared'
import { EventActionTypes } from '..'

export const CalendarAvatar = ({ time, color }) => {
  const { isSm } = useContext(LayoutContext)
  const dt = moment(time) || moment()

  const LeftPattern = () => (
    <div
      style={{
          position: 'absolute',
          width: 8,
          height: 8,
          top: 8,
          left: 16,
          borderRadius: 8,
          backgroundColor: color || '#215f3f',
      }}
    />
  )

  const RightPattern = () => (
    <div
      style={{
          position: 'absolute',
          width: 8,
          height: 8,
          top: 8,
          right: 16,
          borderRadius: 8,
          backgroundColor: color || '#215f3f',
      }}
    />
  )

  var month = dt.format('MMMM')
  var day = dt.format('DD')

  return (
    <div
      style={{
        zIndex: 1,
        width: isSm ? 80 : 100,
        height: isSm ? 80 : 100,
        boxShadow: '0px 6px 10px 2px rgb(0 0 0 / 15%)',
        borderRadius: '0.75rem',
      }}
      className="flex flex-col flex-center gap-1 bg-primary-700 p-2 relative">
      <LeftPattern />
      <RightPattern />
      <div className="flex flex-center font-bold text-xs md:text-lg text-white uppercase">
        {month}
      </div>
      <div
        style={{ width: '90%', lineHeight: 1.4 }}
        className="flex flex-center rounded-md font-bold text-xl md:text-2xl text-primary-700 bg-white tracking-wide">
        {day}
      </div>
    </div>
  )
}

const EventBanner = React.memo(({ Wrapper = 'div' }) => {
  const t = useTranslate()
  const { isSm } = useContext(LayoutContext)

  const { item: event, handleActionClick = Null } = useContext(ServerContext)

  const [id, type] = [getId(event), getType(event)]

  const cover_photo =
    _.get(event, 'cover_photo') ?? `${ROOT_URL}/images/default/event-bg.jpg`

  const { time_open, time_close, is_expired } = event ?? {}

  return (
    <Wrapper className="flex flex-col">
      <div className="w-full bg-green-700">
        <div className="container mx-auto px-4 md:px-5 py-4 flex items-start gap-2 md:gap-3">
          <CalendarAvatar time={time_open} color="#fff"/>
          <div className="flex flex-col flex-1 mt-0 md:mt-1">
            <h1 className="text-normal md:text-2xl font-bold text-primary-700 max-lines-1">
              {getTitle(event)}
            </h1>
            <div className="flex flex-col md:flex-row gap-x-2 uppercase text-xs text-color-100 opacity-75 font-medium tracking-wide">
              <span className="leading-tight md:leading-normal">
                <span className="font-normal capitalize italic text-color-000 opacity-50 mr-2">
                  {t('from')}
                </span>
                {moment(time_open).format('hh:mm a - Do MMMM, YYYY')}
              </span>
              {time_close && (
                <span className="leading-tight md:leading-normal">
                  <span className="font-normal lowercase italic text-color-000 opacity-50 mr-2">
                    {t('to')}
                  </span>
                  {moment(time_close).format('hh:mm a - Do MMMM, YYYY')}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-green-700 h-4 mt-2" />
      <div
        style={{
          width: isSm ? 'calc(100% - 1rem)' : '100%',
          minHeight: isSm ? '12rem' : '16rem',
          maxHeight: '20rem',
          marginTop: isSm ? '-4rem' : '-4.5rem',
          backgroundImage: `url(${cover_photo})`,
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
        }}
        className="container mx-auto rounded-lg shadow-md"
      />
      <div className="container mx-auto p-2 mt-2">
        <div className="background-200 rounded-md px-3 py-2">
          <div className="flex flex-col md:flex-row gap-1 md:gap-3">
            <div className="flex-1 truncate font-medium text-sm text-color-200 italic">
              {t('do you want to join this event')}
            </div>
            <div className="flex items-center justify-end gap-3">
              <div
                onClick={() => {
                  handleActionClick(EventActionTypes.INVITE)
                }}
                style={{
                  padding: '2px 10px',
                  fontStyle: 'italic',
                  fontWeight: 500,
                  borderRadius: 5,
                }}
                className="flex flex-center border border-primary text-xs text-primary hover:text-white background hover:bg-primary cursor-pointer">
                <BsEnvelopeFill className="mr-2" />
                {t('invite')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
})

export default EventBanner
