import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from "../modules/navigation/useRouter";
import {Button, Spin} from "antd";
import {useDispatch} from "react-redux";
import {LoginContext} from "../components/LoginContext";

const RedirectPage = ({title, description, redirectPath = '/login', redirectLabel = 'login'}) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const login = useContext(LoginContext);
  const history = useHistory();
  const [seconds, setSeconds] = useState(10);
  const [isCancelled, setIsCancelled] = useState(false);

  useEffect(() => {
    // setSeconds(3);
    if (login) {
      dispatch({
        type: 'LOGOUT'
      });
    }

    return () => {
      setIsCancelled(true);
    }
  }, []);
  useEffect(() => {

    setTimeout(() => {
      if (!isCancelled) {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          history.push({
            pathname: redirectPath,
            state: { isModal: true, from: '/' }
          });
        }
      }
    }, 1000);

  }, [seconds]);

  return (
    <main
      style={{
        minHeight: 'calc(100Vh - 100px)'
      }}
      className="flex flex-col space-y-3 items-center justify-center overflow-hidden">
      {
        !!seconds && (
          <div className="flex items-center space-x-3">
            <Spin/>
            <div>
              {
                t("redirect in {1} seconds", {1: seconds})
              }
            </div>
          </div>
        )
      }
      {
        !!title && (
          <div className="flex items-center space-x-3 text-4xl font-bold text-primary">
            <div>{title}</div>
          </div>
        )
      }
      {
        !!description && (
          <div className="text-base">
            {description}
          </div>
        )
      }
      <Button onClick={()=>{
        setIsCancelled(true);
        history.push({
          pathname: redirectPath,
          state: { isModal: true, from: '/' }
        });
      }} type={"primary"} size={"large"} >
        <div className="px-6">{t(redirectLabel)}</div>
      </Button>
    </main>
  )
}

export default RedirectPage
