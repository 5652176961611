import { FullscreenExitOutlined } from '@ant-design/icons'
import Count from 'components/Feed/Count'
import SideModal from 'components/Modal/SideModal'
import React from 'react'
import { Autosizer } from './Autosizer'
export default function FullChartModal({
  actions = [],
  onAction,
  title,
  onClose,
  children
}) {
  return (
    <SideModal
      full
      onCancel={onClose}
      header={null}
      footer={null}
      visible={true}
      destroyOnClose
      style={{
        transition: 'none'
      }}
      zIndex={9999}
      bodyStyle={{ padding: 0 }}>
      {({ handleCancel }) => (
        <div className=" flex flex-col fixed top-0 left-0 bottom-0 right-0 background overflow-y-scroll">
          <div className="flex space-x-3 background-100">
            <div className="flex-1 font-bold truncate px-3 flex items-center">
              {title}
            </div>
            <div className="flex space-x-3 pr-3">
              <div className="flex items-center">
                {actions.map(
                  ({
                    key,
                    component,
                    label
                  }) => (
                    <div key={key}>
                      <Count
                        onClick={
                          onAction
                            ? () => {
                                onAction(
                                  key
                                )
                              }
                            : null
                        }
                        title={label}
                        component={
                          component
                        }></Count>
                    </div>
                  )
                )}
              </div>
              <div className="py-3 px-1">
                <Count
                  title="close"
                  className="text-2xl"
                  onClick={handleCancel}
                  component={
                    FullscreenExitOutlined
                  }></Count>
              </div>
            </div>
          </div>
          <div className="flex-1 relative">
            <Autosizer auto>
              {([
                ref,
                { width, height }
              ]) => {
                return (
                  <div
                    className="absolute inset-0"
                    ref={ref}>
                    {children({
                      width,
                      height
                    })}
                  </div>
                )
              }}
            </Autosizer>
          </div>
        </div>
      )}
    </SideModal>
  )
}
