import {API_ROOT_URL} from 'envs/_current/config'
import {schema} from 'normalizr'
import {baseFieldTypes} from './base/baseFieldTypes'
import {chartSchema} from './chart'
import {articleSchema, channelSchema, organizationSchema, pollSchema, postSchema} from './schema'
import {commentSchema} from './schema/commentSchema'
import {createTransform} from './utils/createTransform'
import {eventSchema} from "./schema/event";

const voteSchema = new schema.Union(
  {
    post: postSchema,
    poll: pollSchema,
    article: articleSchema,
    comment: commentSchema,
    organization: organizationSchema,
    channel: channelSchema,
    event: eventSchema,
    chart: chartSchema
  },
  '_type'
)

const fields = [
  'owner_type',
  'owner_id'
]

const fieldTypes = {
  owner_id: baseFieldTypes.string,
  owner_type: ['post']
}


// Route::post('auth/vote/up', 'VoteController@add');
export const vote_add_Api = {
  backend: `Route::post('auth/vote/up', 'VoteController@add');`,
  root: API_ROOT_URL,
  path: '/auth/vote/up',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    voteSchema,
    'data'
  )
}

// Route::post('auth/vote/down', 'VoteController@remove');
export const vote_remove_Api = {
  backend: `// Route::post('auth/vote/down', 'VoteController@remove');`,
  root: API_ROOT_URL,
  path: '/auth/vote/down',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(
    voteSchema,
    'data'
  )
}
