import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import {Link} from 'react-router-dom'
// import * as Yup from 'yup'
import {Mention} from './Mention'

// const testUrl = (text) => Yup.string().url().isValidSync(text)

function testUrl(string) {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

const tranformDescriptionText = (str = '') => {
  const words = str
    .replace(/\r\n/g, ' \r\n ')
    .replace(/\n/g, ' \n ')
    .split(' ')
    .reduce((result, word, i) => {
      let start
      let end
      switch (true) {
        case word && word.length > 0 && word[0] === '@':
          start = str.indexOf(word)
          end = start + word.length
          result.push({
            range: [start, end],
            type: 'mention',
            prefix: '@',
            text: _.trim(word, '@'),
          })
          break
        case word &&
          word.length > 0 &&
        word[0] === '#' &&
        word.includes('#url'):
          start = str.indexOf(word)
          end = start + word.length
          const word_breaks = _.split(word, '$')

          result.push({
            range: [start, end],
            type: 'url',
            url: word_breaks[1],
            text: word_breaks[2],
          })
          break
        case word && word.length > 0 && word[0] === '#':
          start = str.indexOf(word)
          end = start + word.length
          result.push({
            range: [start, end],
            type: 'tag',
            text: word,
          })
          break
        case word && word.length > 0 && testUrl(word):
          start = str.indexOf(word)
          end = start + word.length
          result.push({
            range: [start, end],
            type: 'url',
            text: word,
          })
          break
        default:
          if (word && word.length > 0) {
            start = str.indexOf(word)
            end = start + word.length
            result.push({
              range: [start, end],
              type: 'text',
              text: word,
            })
          }
          break
      }
      return result
    }, [])
  return words
}

export const mapKeywords = (children = '', keywords = [], setKeywords) => {
  if (!children || children === null) return null

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const text = keywords.reduce((res, keyword) => {
    const arr = _.get(keyword, 'keyword_alt', '').split(',')
    const logRes = res
    for (var i = 0; i < arr.length; i++) {
      const regex = new RegExp(' ' + escapeRegExp(arr[i].trim() + ' '), 'i')
      res = res.replace(regex, function (match) {
        return ` @keyword:${keyword.idname}:${encodeURI(match)} `
      })
      if (res !== logRes) {
        if (setKeywords) {
          _.pullAt(arr, i)
          keywords = [
            ...keywords.filter((e) => e.idname !== keyword.idname),
            {
              ...keyword,
              keyword_alt: arr.join(', '),
            },
          ]
          setKeywords(keywords)
        }
        break
      }
    }

    return res
  }, children)

  return text
}

export const mapDocuments = (children = '', documents = [], setDocuments) => {
  if (!children || children === null) return null

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const text = documents.reduce((res, document) => {
    const regex = new RegExp(
      ' ' + escapeRegExp(document.document_no.trim() + ' '),
      'i'
    )
    res = res.replace(regex, function (match) {
      return ` @docs:${document.idname}:${encodeURI(match)} `
    })

    return res
  }, children)

  return text
}

export const mapReferenceLinks = (
  children = '',
  referenceLinks = [],
  setReferenceLinks
) => {
  if (!children || children === null) return null

  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  const text = referenceLinks.reduce((res, referenceLink) => {
    const regex = new RegExp(escapeRegExp(referenceLink.value), 'i')
    res = res.replace(regex, function (match) {
      return ` #url$${referenceLink.url}$${encodeURI(match)} `
    })

    return res
  }, children)

  return text
}

const Description = ({
  className,
                       style = {},
                       mentions = [],
                       children = '',
  placeholder = null,
}) => {
  if (_.isEmpty(children ?? undefined)) {
    return placeholder
  }

  const text = children

  const entities = tranformDescriptionText(text)

  return (
    <div
      style={style}
      className={classNames(
        'overflow-hidden whitespace-pre-line pl-1',
        className
      )}>
      {entities.map((entity, i) => {
        return (
          <React.Fragment key={i}>
            {(() => {
              switch (true) {
                case entity && entity.type === 'text':
                  return entity.text
                case entity && entity.type === 'mention':
                  return (
                    <Mention
                      prefix={entity.prefix}
                      mentions={mentions}>
                      {entity.text}
                    </Mention>
                  )
                case entity && entity.type === 'tag':
                  return (
                    <Link
                      className="hover:underline text-color-100 text-sm"
                      to={`/t/${entity.text.substring(1)}`}>
                      {entity.text}
                    </Link>
                  )
                case entity && entity.type === 'doc':
                  return (
                    <Link
                      className="italic hover:underline text-primary text-sm font-light "
                      to={`/docs/${entity.text.substring(1)}`}>
                      {entity.text}
                    </Link>
                  )
                case entity && entity.type === 'url':
                  return (
                    <a
                      className="italic underline text-color-100 text-sm "
                      target="_blank"
                      href={entity.url}>
                      {decodeURI(entity.text)}
                    </a>
                  )
                default:
                  return JSON.stringify(entity)
              }
            })()}
            {i < entities.length - 1 && ' '}
          </React.Fragment>
        )
      })}
    </div>
  )
}
export default Description
