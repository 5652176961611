import { Modal } from 'antd'
import {
  participant_cancelParticipant_Api,
  participant_createParticipant_Api,
  participant_getById_Api,
  participant_manageParticipantAccept_Api,
  participant_manageParticipantCreate_Api,
  participant_manageParticipantDelete_Api,
  participant_manageParticipantEdit_Api,
  participant_manageParticipantReject_Api,
} from 'apis'
import { getId, getType } from 'apis/model/base'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, { useMemo, useState } from 'react'
import { withProps } from 'recompose'
import LoadingPage from 'views/LoadingPage'
import { Null } from 'views/Shared'
import { FetchEntityById } from 'views/Shared/components/FetchEntityById'
import { EventActionTypes } from '.'
import { showDeleteConfirmDialog } from '../factory/createConfirmDialog'
import { notifyOnError } from '../factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from '../factory/createNotification'
import { bindQueryParams } from '../functions/routerHelper'
import {
  ApproveParticipant,
  CreateParticipant,
} from './ActionForms/ParticipantForm'

export const FetchParticipantById = withProps({
  apiInfo: participant_getById_Api,
})(FetchEntityById)

export const useEventParticipant = ({
  onSubscribed = Null,
  onUnsubscribed = Null,
} = {}) => {
  const t = useTranslate()

  const [refreshKey, setRefreshKey] = useState()

  const onRefresh = () => {
    setRefreshKey(Date.now())
  }

  const onError = notifyOnError(t)

  const subscribeAsync = useAsyncAction({
    onError,
    apiInfo: participant_createParticipant_Api,
    onSuccess: (...args) => {
      onSubscribed(...args)
    },
  })

  const unsubscribeAsync = useAsyncAction({
    apiInfo: participant_cancelParticipant_Api,
    onError,
    onSuccess: (...args) => {
      successNotify(NotificationActionTypes.INFORMATION, t)
      onRefresh()
      onUnsubscribed(...args)
    },
  })

  const { handleAsyncAction: onSubscribe } = subscribeAsync ?? {}
  const { handleAsyncAction: onUnsubscribe } = unsubscribeAsync ?? {}

  return {
    onRefresh,
    refreshKey,
    onSubscribe,
    onUnsubscribe,
    subscribeAsync,
    unsubscribeAsync,
  }
}

export const useEventParticipantManager = ({
  owner,
  onCreated = Null,
  onUpdated = Null,
  onDeleted = Null,
  onApproved = Null,
  onRejected = Null,
  autoRefresh = true,
}) => {
  const t = useTranslate()

  const [refreshKey, setRefreshKey] = useState()

  const [action, setAction] = useState({
    type: undefined,
    value: undefined,
  })

  const [owner_id, owner_type] = [getId(owner), getType(owner)]

  const onCancel = () => setAction({})

  const onError = notifyOnError(t)

  const onRefresh = () => {
    if (autoRefresh) {
      setRefreshKey(Date.now())
    }
    onCancel()
  }

  const createAsync = useAsyncAction({
    onError,
    apiInfo: participant_manageParticipantCreate_Api,
    query: bindQueryParams([{ id: owner_id }, { type: owner_type }]),
    onSuccess: (...args) => {
      successNotify(NotificationActionTypes.CREATE, t)
      onRefresh()
      onCreated(...args)
    },
  })

  const updateAsync = useAsyncAction({
    onError,
    apiInfo: participant_manageParticipantEdit_Api,
    query: bindQueryParams([{ id: owner_id }, { type: owner_type }]),
    onSuccess: (...args) => {
      successNotify(NotificationActionTypes.UPDATE, t)
      onCancel()
      onUpdated(...args)
    },
  })

  const deleteAsync = useAsyncAction({
    onError,
    apiInfo: participant_manageParticipantDelete_Api,
    onSuccess: (...args) => {
      successNotify(NotificationActionTypes.DELETE, t)
      onRefresh()
      onDeleted(...args)
    },
  })

  const approveAsync = useAsyncAction({
    apiInfo: participant_manageParticipantAccept_Api,
    onError,
    onSuccess: (...args) => {
      successNotify(NotificationActionTypes.INFORMATION, t)
      onCancel()
      onApproved(...args)
    },
  })

  const rejectAsync = useAsyncAction({
    apiInfo: participant_manageParticipantReject_Api,
    onError,
    onSuccess: (...args) => {
      successNotify(NotificationActionTypes.INFORMATION, t)
      onCancel()
      onRejected(...args)
    },
  })

  const { handleAsyncAction: onUpdate } = updateAsync ?? {}
  const { handleAsyncAction: onDelete } = deleteAsync ?? {}
  const { handleAsyncAction: onReject } = rejectAsync ?? {}
  const { creatingLoading, handleAsyncAction: onCreate } = createAsync ?? {}
  const { approvingLoading, handleAsyncAction: onApprove } = approveAsync ?? {}

  const handleAction = (type, params) => {
    const id = getId(params)
    switch (type) {
      case EventActionTypes.PARTICIPANT_CREATE:
      case EventActionTypes.PARTICIPANT_APPROVE:
        setAction({
          type,
          value: params,
        })
        break
      case EventActionTypes.PARTICIPANT_DELETE:
        showDeleteConfirmDialog({
          translate: t,
          title: 'delete registration',
          message: 'are you sure you want to delete this registration',
          onOk: () => {
            onDelete({}, { id })
          },
        })
        break
      case EventActionTypes.PARTICIPANT_REJECT:
        showDeleteConfirmDialog({
          translate: t,
          title: 'reject registration',
          message: 'are you sure you want to reject this registration',
          onOk: () => {
            onReject({}, { id })
          },
        })
        break
      default:
        break
    }
  }

  const modal = useMemo(() => {
    const { type, value } = action ?? {}
    let modal_params = {}
    let params = {
      onSubmit: Null,
    }
    let Content = Null
    let Wrapper = ({ children }) => children({ item: {} })
    const id = getId(value)
    switch (type) {
      case EventActionTypes.PARTICIPANT_CREATE:
        modal_params = {
          title: (
            <div className="text-center font-bold text-color-000 uppercase">
              {t('create participant')}
            </div>
          ),
        }
        params = {
          onSubmit: onCreate,
          isSubmitting: creatingLoading,
        }
        Content = CreateParticipant
        break
      case EventActionTypes.PARTICIPANT_APPROVE:
        modal_params = {
          title: (
            <div className="text-center font-bold text-color-000 uppercase">
              {t('approve participant')}
            </div>
          ),
        }
        params = {
          onSubmit: (values) => {
            onApprove(values, {
              id,
            })
          },
          isSubmitting: approvingLoading,
        }
        Content = ApproveParticipant
        Wrapper = withProps({ id })(FetchParticipantById)
        break
      default:
        return null
    }
    return (
      <Modal
        width={620}
        footer={null}
        onCancel={onCancel}
        destroyOnClose={true}
        visible={action?.type}
        className="custom-modal"
        {...modal_params}>
        <Wrapper>
          {({ item, isLoading }) => {
            if (!!isLoading) {
              return <LoadingPage />
            }
            return (
              <Content
                {...params}
                {...{ item, owner, onCancel, owner_id, owner_type }}
              />
            )
          }}
        </Wrapper>
      </Modal>
    )
  }, [owner, action, owner_id, owner_type, creatingLoading, approvingLoading])

  return {
    modal,
    onCreate,
    onUpdate,
    onDelete,
    onReject,
    onApprove,
    refreshKey,
    createAsync,
    updateAsync,
    deleteAsync,
    rejectAsync,
    approveAsync,
    handleAction,
  }
}
