import {
  organization_fetchLatestComments_Api,
  organization_fetchLatestFeeds_Api,
  organization_fetchLatestFollowers_Api,
} from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { branch, compose, fromRenderProps } from 'recompose'
import { ContentNotFound } from 'views/AppLocals/ContentNotFound'
import EntityList from 'views/AppLocals/EntityList'
import { bindQueryParam } from 'views/AppLocals/functions/routerHelper'
import { Null } from 'views/Shared'
import WorkspaceContext from '../WorkspaceContext'
import { renderHomeArticle } from './renderHomeArticle'
import { renderHomeAsk } from './renderHomeAsk'
import { renderHomeComment } from './renderHomeComment'
import { renderHomeFeed } from './renderHomeFeed'
import { renderHomeFollower } from './renderHomeFollower'
import { renderHomeRepost } from './renderHomeRepost'

export const WorkspaceHomeGroups = Object.freeze({
  NOTIFICATION: 'notification',
  INSIGHTS: 'insights',
  POLL: 'poll',
  GENERAL: 'general',
  COMMENTS: 'comments',
  FOLLOW: 'follow',
  LASTED_ARTICLES: 'lasted-articles',
  LASTED_REPOSTS: 'lasted-reposts',
})

const WorkspaceHomeGroup = ({
  id,
  item,
  query,
  values,
  apiInfo,
  renderItem,
  Wrapper = 'div',
  ...props
}) => {
  const refreshKey = useMemo(
    () =>
      [apiInfo.path, id, JSON.stringify(query), JSON.stringify(values)].join(
        '/'
      ),
    [id, query, values, apiInfo]
  )

  if (_.isEmpty(item)) return null

  return (
    <Wrapper className="space-y-4">
      <EntityList
        query={query}
        values={values}
        key={refreshKey}
        apiInfo={apiInfo}
        refreshKey={refreshKey}
        renderItem={renderItem}
        RenderEntity={React.Fragment}
        {...props}
      />
    </Wrapper>
  )
}

export const WorkspaceLastedArticles = ({ type, query, ...props }) => (
  <WorkspaceHomeGroup
    query={query}
    values={{
      content_type: 'article',
    }}
    apiInfo={organization_fetchLatestFeeds_Api}
    renderItem={renderHomeArticle}
    {...props}
  />
)

export const WorkspaceLastedReposts = ({ type, query, ...props }) => (
  <WorkspaceHomeGroup
    query={query}
    values={{
      content_type: 'repost',
    }}
    apiInfo={organization_fetchLatestFeeds_Api}
    renderItem={renderHomeRepost}
    {...props}
  />
)


export const WorkspacePolls = ({ type, query, ...props }) => (
  <WorkspaceHomeGroup
    query={query}
    values={{
      content_type: 'poll_question',
    }}
    apiInfo={organization_fetchLatestFeeds_Api}
    renderItem={renderHomeFeed}
    {...props}
  />
)

export const WorkspaceComments = ({ type, query, ...props }) => (
  <WorkspaceHomeGroup
    query={query}
    values={{
      content_type: 'poll_question',
    }}
    apiInfo={organization_fetchLatestComments_Api}
    renderItem={renderHomeComment}
    {...props}
  />
)

export const WorkspaceFollowers = ({ type, query, ...props }) => (
  <WorkspaceHomeGroup
    query={query}
    apiInfo={organization_fetchLatestFollowers_Api}
    renderItem={renderHomeFollower}
    {...props}
  />
)

const WorkspaceGroupFactory = ({
  type = WorkspaceHomeGroups.LASTED_ARTICLES,
  ...props
}) => {
  let Component = Null
  switch (type) {
    case WorkspaceHomeGroups.LASTED_ARTICLES:
      Component = WorkspaceLastedArticles
      break
    case WorkspaceHomeGroups.LASTED_REPOSTS:
      Component = WorkspaceLastedReposts
      break
    case WorkspaceHomeGroups.POLL:
      Component = WorkspacePolls
      break
    case WorkspaceHomeGroups.COMMENTS:
      Component = WorkspaceComments
      break
    case WorkspaceHomeGroups.FOLLOW:
      Component = WorkspaceFollowers
      break
    default:
      break
  }
  return (
    <Component
      {...props}
      {...{ type }}
    />
  )
}

export default compose(
  fromRenderProps(WorkspaceContext.Consumer, ({ organization }) => ({
    id: getId(organization),
    item: organization,
    query: bindQueryParam({
      id: getId(organization),
    }),
  })),
  branch(
    ({ item }) => _.isEmpty(item),
    () => () => <ContentNotFound />
  )
)(WorkspaceGroupFactory)
