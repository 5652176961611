import {FeedContent} from 'components/Feed/FeedCard'
import {FeedCard} from 'components/Feed/TimeLineFeed'
import LazyComponent from 'components/LazyComponent'
import Pure from 'components/Pure'
import _ from 'lodash'
import React from 'react'
import {FeedActionTypes, FeedContentTypes} from 'views/Feed/Feed'
import {GoogleAdsInFeedUnit} from '../../../components/GoogleAds/GoogleAds'
import {FeedWidget} from '../../NewFeed/FeedWidget'
import {renderHeadlineFeedItem} from './renderNewsItem'

const renderExcludedItem = (item, index) => {
  switch (item.action) {
    case FeedActionTypes.ADS:
      return <GoogleAdsInFeedUnit/>
    case FeedActionTypes.STICKY:
      return (
        <LazyComponent>
          <Pure>
            <FeedWidget item={item} />
          </Pure>
        </LazyComponent>
      )
    default:
      return null
  }
}

const renderCard = (item, customComponents = {}) => (
  <FeedCard
    key={item.id}
    item={item.content}
    customComponents={customComponents}
  />
)

export const renderFeedItem = (item, index) => {
  if (!item) return null
  if (_.includes(Object.values(FeedActionTypes), item.action)) {
    return renderExcludedItem(item, index)
  }
  return renderCard(item, {
    Content: FeedContent,
  })
}

export const renderFeedByType = (type) => (item, index) => {
  if (!item) return null

  if (_.includes(Object.values(FeedActionTypes), item.action)) {
    return renderExcludedItem(item, index)
  } else {
    const _type = type === 'all' ? _.get(item, 'content._type') : type
    switch (_type) {
      case FeedContentTypes.ARTICLE:
        return renderCard(item, {
          Content: () => <FeedContent/>,
        })
      default:
        return renderFeedItem(item, index)
    }
  }
}

export const renderFeedHeadlineByType = (type) => (item, index) => {
  if (!item) return null

  if (_.includes(Object.values(FeedActionTypes), item.action)) {
    return renderExcludedItem(item, index)
  } else {
    let content = renderHeadlineFeedItem(item)
    const _type = type === 'all' ? _.get(item, 'content._type') : type
    switch (_type) {
      case FeedContentTypes.ARTICLE:
      default:
        break
    }

    return <React.Fragment key={index}>{content}</React.Fragment>
  }
}
