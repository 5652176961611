import {API_ROOT_URL} from 'envs/_current/config'
import createPath from 'helpers/createPath'
import {createAsyncAction} from 'modules/asyncCache'
import {schema} from 'normalizr'
import {baseFieldTypes} from './base/baseFieldTypes'
import {articleSchema, channelSchema, organizationSchema, userSchema,} from './schema'
import {eventSchema} from './schema/event'
import {createListTransform} from './utils/createListTransform'
import {createTransform} from './utils/createTransform'
import {datasetSchema} from "./dataset";

const fields = ['keyword']
const fieldTypes = {
  keyword: baseFieldTypes.string
}
const transform = createTransform()
// Route::get('search/keyword', 'SearchController@getRecommendKeywords');
export const search_getRecommendKeywords_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/search/keyword/:params',
  method: 'GET',
  fields: ['keyword'],
  fieldTypes: {
    keyword: baseFieldTypes.string,
  },
  transform,
}
export const create_search_getRecommendKeywords_Api_action =
  () =>
    ({prefixStr = '', query, values, maxAge = -1}) =>
      createAsyncAction({
        prefixStr: prefixStr + '@search_getRecommendKeywords_Api',
        query,
        values,
        apiInfo: search_getRecommendKeywords_Api,
        maxAge,
      })

// Route::get('search/object/{type}', 'SearchController@getDataWithType');
export const search_getDataWithType_ApiS_schema = new schema.Entity(
  'searchItems',
  {
    owner: new schema.Union(
      {
        article: articleSchema,
        user: userSchema,
        channel: channelSchema,
        organization: organizationSchema
      },
      '_type'
    )
  },
  {
    idAttribute: (value) => value.username || value.idname || value.id,
    // idAttribute: (value) => `${value.owner_type}_${value.owner_id}`,
  }
)
export const search_getDataWithType_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/search/object/:type',
  method: 'GET',
  fields,
  paramsFields: ['keyword', 'categories'],
  fieldTypes,
  schema: search_getDataWithType_ApiS_schema,
  transform: createListTransform(search_getDataWithType_ApiS_schema),
}
export const create_search_getDataWithType_Api_action =
  () =>
    ({prefixStr = 'search_getDataWithType_Api', query, values, maxAge = -1}) =>
      createAsyncAction({
        prefixStr: prefixStr + '@search_getDataWithType_Api',
        query,
        values,
        apiInfo: search_getDataWithType_Api,
        maxAge,
      })

// search/filter-data/{type?}
export const search_loadFilterData_Api = {
  name: 'search_loadFilterData',
  backend: ``,
  root: API_ROOT_URL,
  path: '/search/filter-data/:type',
  method: 'GET',
  paramsFields: ['category', 'keyword'],
  schema: search_getDataWithType_ApiS_schema,
  transform: createListTransform(
    search_getDataWithType_ApiS_schema
  )
}

// Route::get('search/filter-params/{type?}', 'SearchController@getFilterParams');
export const search_filterParams_Api = {
  name: 'search_filterParams',
  backend: ``,
  root: API_ROOT_URL,
  path: '/search/filter-params/:type',
  method: 'GET',
}

export const search_getTags_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/tags/keyword/:keyword/search',
  method: 'GET',
}

// Route::get('search/keyword', 'SearchController@getSuggestedKeywords'); // param: keyword
export const create_getSuggestedKeywords_Api = ({query, ...props} = {}) => ({
  backend: ``,
  method: 'GET',
  root: API_ROOT_URL,
  paramsFields: ['keyword'],
  path: createPath({
    path: '/search/keyword',
  }),
  ...props,
})

// Route::get('search/keyword', 'SearchController@getSuggestedKeywords');
export const search_getSuggestedKeywords_Api = {
  backend: ``,
  method: 'GET',
  root: API_ROOT_URL,
  paramsFields: ['keyword', 'type'],
  path: '/search/keyword',
}

export const search_fetchObjects_Api = () => ({
  backend: `Route::get('search/objects', 'SearchController@fetchObjects');`,
  method: 'GET',
  root: API_ROOT_URL,
  paramsFields: ['keyword', 'owner_type', 'owner_id'],
  path: createPath({
    path: '/search/objects',
  }),
})

const _searchSchema = new schema.Union(
  {
    user: userSchema,
  },
  '_type'
)

export const search_searchObjects_Api = {
  backend: `Route::get('search/objects', 'SearchController@fetchObjects');`,
  root: API_ROOT_URL,
  method: 'GET',
  path: '/search/objects',
  paramsFields: ['keyword', 'type'],
  fields,
  fieldTypes,
  schema: _searchSchema,
  transform: createListTransform(_searchSchema),
}

export const search_fetchAvailableOwners_Api = {
  backend: `Route::get('search/available-owners/{type}/{id}', 'SearchController@fetchAvailableOwners'); // param: keyword`,
  path: '/search/available-owners/:type/:id',
  method: 'GET',
  root: API_ROOT_URL,
  paramsFields: ['keyword'],
}


const search_fetchFeaturedItemsByType_Api = (type, schema) => {
  return {
    name:
      'search_fetchFeaturedItemsByType_Api',
    backend: `Route::get('search/featured-items/{type?}', 'PromoteController@fetchFeaturedItems');`,
    root: API_ROOT_URL,
    path: `/search/featured-items/${type}`,
    method: 'GET',
    schema: schema,
    transform: createListTransform(
      schema
    )
  }
}

export const search_fetchFeaturedChannels_Api = search_fetchFeaturedItemsByType_Api("channel", channelSchema)
export const search_fetchFeaturedArticles_Api = search_fetchFeaturedItemsByType_Api("article", articleSchema)
export const search_fetchFeaturedOrganizations_Api = search_fetchFeaturedItemsByType_Api("organization", organizationSchema)
export const search_fetchFeaturedEvents_Api = search_fetchFeaturedItemsByType_Api("event", eventSchema)
export const search_fetchFeaturedDatasets_Api = search_fetchFeaturedItemsByType_Api("dataset", datasetSchema)