import React from "react";

export const SensoryAdditives = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#ed553b"
				d="M395.235 56.575a8 8 0 00-5.946 14.854c25.7 10.287 42.972 39.453 42.972 72.572s-17.269 62.285-42.972 72.574a8 8 0 105.945 14.853c31.717-12.7 53.027-47.83 53.027-87.427s-21.309-74.729-53.026-87.426z"
			></path>
			<path
				fill="#ed553b"
				d="M379.361 80.626a8 8 0 10-6.2 14.75c16.208 6.809 27.1 26.355 27.1 48.625s-10.89 41.812-27.1 48.625a8 8 0 106.2 14.75c22.072-9.277 36.9-34.746 36.9-63.375s-14.828-54.096-36.9-63.375z"
			></path>
			<path
				fill="#ed553b"
				d="M353.017 179.395a7.993 7.993 0 0010.638 3.852c12.571-5.888 21.017-21.659 21.017-39.245s-8.445-33.355-21.018-39.245a8 8 0 10-6.786 14.489c6.95 3.257 11.8 13.436 11.8 24.755s-4.853 21.5-11.8 24.755a8 8 0 00-3.851 10.639z"
			></path>
			<circle
				cx="344"
				cy="144.002"
				r="8"
				fill="#ed553b"
				transform="rotate(-45 344 144.001)"
			></circle>
			<path
				fill="#ed553b"
				d="M117.026 231.429a8 8 0 005.946-14.854C97.27 206.288 80 177.122 80 144s17.269-62.285 42.972-72.574a8 8 0 10-5.945-14.853C85.31 69.271 64 104.406 64 144s21.308 74.732 53.026 87.429z"
			></path>
			<path
				fill="#ed553b"
				d="M132.9 207.378a8 8 0 106.2-14.75C122.892 185.818 112 166.272 112 144s10.89-41.812 27.1-48.625a8 8 0 10-6.2-14.75C110.828 89.9 96 115.373 96 144s14.828 54.1 36.9 63.378z"
			></path>
			<path
				fill="#ed553b"
				d="M159.244 108.609a7.993 7.993 0 00-10.638-3.852c-12.571 5.888-21.017 21.659-21.017 39.245s8.445 33.355 21.018 39.245a8 8 0 106.786-14.489c-6.95-3.257-11.8-13.436-11.8-24.755s4.853-21.5 11.8-24.755a8 8 0 003.851-10.639z"
			></path>
			<circle
				cx="168.261"
				cy="144.002"
				r="8"
				fill="#ed553b"
				transform="rotate(-45 168.262 144.002)"
			></circle>
			<path
				fill="#f6d55b"
				d="M296 330.729V69.644A37.644 37.644 0 00258.356 32h-4.712A37.644 37.644 0 00216 69.644v261.085a80 80 0 1080 0z"
			></path>
			<circle cx="256" cy="400" r="40" fill="#ed553b"></circle>
			<path
				fill="#3cafa3"
				d="M296 144v16h-32a8 8 0 010-16zM296 184v16h-32a8 8 0 010-16zM296 224v16h-32a8 8 0 010-16zM296 264v16h-32a8 8 0 010-16z"
			></path>
			<path
				fill="#fff"
				d="M256 360V32h-2.356A37.644 37.644 0 00216 69.644v261.085a79.97 79.97 0 00-10.031 131.676C262.019 420.331 256 360 256 360z"
				opacity="0.3"
			></path>
		</svg>
	);
}

export default SensoryAdditives;
