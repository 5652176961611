import React from 'react'

const InlineChromiumBugfix = () => (
  <span
    style={{fontSize: 0}}
    contentEditable={false}>
    {String.fromCodePoint(160) /* Non-breaking space */}
  </span>
)
export default InlineChromiumBugfix
