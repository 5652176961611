import Null from 'components/NullComponent'
import {useEffect, useRef} from 'react'

export const DidOnChange = ({
                              input = [],
  handler = Null
}) => {
  const handlerRef = useRef(handler)
  handlerRef.current = handler
  useEffect(() => {
    handlerRef.current()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, input)
  return null
}
