import {auth_updateLang_Api} from 'apis'
import {createTransform} from 'apis/utils/createTransform'
import {LoginContext} from 'components/LoginContext'
import {API_ROOT_URL} from 'envs/_current/config'
import {saveLanguageKey} from 'helpers/localStorage'
import logParams from 'helpers/logParams'
import translations from 'locales'
import getMomentLocale from 'locales/getMomentLocale'
import _ from 'lodash'
import {AsyncWithCache} from 'modules/asyncCache/components/Async'
import {getCacheSelector} from 'modules/asyncCache/selectors'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, {useCallback, useContext, useEffect, useMemo, useState,} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useSearchParam} from 'react-use'
import I18n, {setLanguage} from 'redux-i18n'
import {getLang, getLogin} from 'redux/selectors'
import {DelayRender} from 'views/Discovery/DelayRender'
import {emptyObject} from '../../helpers/emptyObjects'
import {getContentLanguageKey, getLanguageKey, saveContentLanguageKey,} from '../../helpers/localStorage'
import {createAsyncAction} from '../asyncCache'
import {LocalContext} from './LocalContext'

const configurationTransform =
  ({lang, configsVersion, dateVersion}) =>
    (response) => {
      const result = createTransform(null, 'data')(response)
      return {
        ...result,
        result: ((entity) => {
          const {hierarchy_categories = []} = entity
          entity.lang = lang
          entity.configsVersion = configsVersion
          entity.dateVersion = dateVersion
          entity.categoryEntities = hierarchy_categories
            .flatMap((cate) => {
            cate.breadcrumbs = []
            const catChildren = _.get(cate, 'children', [])
            return [
              cate,
              ...catChildren.flatMap((cate2) => {
                cate2.breadcrumbs = [cate.id]
                const cat2Children = _.get(cate2, 'children', [])
                return [
                  cate2,
                  ...cat2Children.map((cate3) => {
                    cate3.breadcrumbs = [cate.id, cate2.id]
                    return cate3
                  }),
                ]
              }),
            ]
            })
            .reduce((re, cate) => {
              re[cate.idname] = cate
              return re
            }, {})
          return entity
        })(result.result),
      }
    }

// Route::get('apps/get-configs-interval-daily', 'AppController@getDailyConfigParams')
const get_dailyConfig_api = (props) => {
  return {
    root: API_ROOT_URL,
    path: '/apps/get-configs-interval-daily',
    method: 'GET',
    transform: (response) =>
      configurationTransform({
        ...props,
      })(response),
  }
}

// Route::get('apps/get-configs', 'AppController@getConfigParams')
const get_app_config_api = (props) => {
  return {
    root: API_ROOT_URL,
    path: '/apps/get-configs',
    method: 'GET',
    transform: (response) =>
      configurationTransform({
        ...props,
      })(response),
  }
}

const LOCALES = {
  en_US: {
    bcp47: 'en-US',
    displayName: 'English (US)\u200e',
    englishName: 'English (US)',
    rtl: false,
  },
  en: {
    bcp47: 'en-US',
    displayName: 'English (US)\u200e',
    englishName: 'English (US)',
    rtl: false,
  },
  vi: {
    bcp47: 'vi',
    displayName: 'Tiếng Việt\u200e',
    englishName: 'Vietnamese (VN)',
    rtl: false,
  },
}
const LocalProvider = ({children}, {t: translate}) => {
  const lang = useSelector(getLang)
  const isLogin = !!useContext(LoginContext)
  const dispatch = useDispatch()
  const changeLang = useCallback(
    (value) => {
      dispatch(setLanguage(value))
      saveLanguageKey(value)
      !!isLogin &&
        dispatch(
          createAsyncAction({
            apiInfo: auth_updateLang_Api,
            values: {
              language: value,
            },
          })
        )
    },
    [dispatch, isLogin]
  )
  const changeContentLang = useCallback(
    (value) => {
      saveContentLanguageKey(value)
      isLogin &&
        dispatch(
          createAsyncAction({
            apiInfo: auth_updateLang_Api,
            values: {
              content_languages: value,
            },
          })
        )
    },
    [dispatch, isLogin]
  )
  const contentLang = getContentLanguageKey()
  useEffect(() => {
    moment.updateLocale(lang, getMomentLocale(lang))
    const html = document.getElementsByTagName('html')[0]
    if (html != null) {
      html.lang = LOCALES[lang].bcp47
    }
  }, [lang])
  const appConfig = useSelector((state) =>
    getCacheSelector(state, 'appConfig', {})
  )
  const {
    configsVersion: currentConfigsVersion,
    dateVersion: currentDailyVersion,
    lang: currentLang,
  } = appConfig

  return (
    <LocalContext.Provider
      value={{
        lang: lang,
        changeLang,
        contentLang: contentLang,
        changeContentLang,
        appConfig,
        translate: !localStorage.getItem('debug')
          ? translate
          : (string, ...args) => {
            setTimeout(() => {
              if (!translations.en[string]) {
                window.translateRequired =
                  window.translateRequired || emptyObject
                window.translateRequired[string] = string
              }
            })
            return `@${string}@`
          },
      }}>
      <DelayRender
        time={300}
        lazy={true}>
        {useMemo(
          () =>
            lang && (
              <AsyncWithCache
                key={lang}
                cacheId="configs-version"
                apiInfo={{
                  root: API_ROOT_URL,
                  path: '/apps/get-configs-version',
                  method: 'GET',
                }}>
                {({response, success}) => {
                  if (!success) return null
                  const configsVersion = _.get(
                    logParams(response),
                    'data.configs-version'
                  )
                  const dateVersion = _.get(
                    logParams(response),
                    'data.date-version'
                  )

                  const versionParams = {lang, configsVersion, dateVersion}

                  const dailyFromCached =
                    currentDailyVersion === dateVersion && lang === currentLang

                  const appFromCached =
                    currentConfigsVersion === configsVersion &&
                    lang === currentLang

                  return (
                    <React.Fragment key={lang}>
                      {!appFromCached && (
                        <AsyncWithCache
                          cacheId={'appConfig'}
                          apiInfo={get_app_config_api(versionParams)}
                        />
                      )}
                      {!dailyFromCached && (
                        <AsyncWithCache
                          cacheId={'dailyConfig'}
                          apiInfo={get_dailyConfig_api(versionParams)}
                        />
                      )}
                    </React.Fragment>
                  )
                }}
              </AsyncWithCache>
            ),
          [lang, currentConfigsVersion, currentDailyVersion, currentLang]
        )}
        {children}
      </DelayRender>
    </LocalContext.Provider>
  )
}

LocalProvider.contextTypes = {
  t: PropTypes.func,
}
const selectUserInterfaceLang = (state) => {
  const login = getLogin(state)
  const localStorageLang = getLanguageKey()
  const lang = login ? login.language : localStorageLang || 'vi'
  return lang
}
const selectUserContentLang = (state) => {
  const login = getLogin(state)
  const localStorageLang = getLanguageKey()
  const langArray = login
    ? login.following_languages
    : !!localStorageLang
      ? [localStorageLang]
      : ['vi']
  return langArray
}

const getSubCategories = (categories) => {
  return categories.reduce((acc, current) => {
    const total = _.get(current, 'total_products', 0)
    const children = _.get(current, 'children', [])
    const subs = getSubCategories(children)
    if (total > 0) {
      return [
        ...acc,
        ...subs,
        {
          [current.id]: total,
          is_category: _.get(current, 'is_category', false),
        },
      ]
    } else {
      return [...acc]
    }
  }, [])
}

export const getReducedCategories = (categories = []) => {
  let array = getSubCategories(categories)

  _.forEach(categories, (e) => {
    const total = _.get(e, 'total_products', 0)
    const children = _.get(e, 'children', [])
    const subCategories = getSubCategories(children)
    if (total > 0 || !_.isEmpty(subCategories)) {
      array.push({
        [e.id]: total,
        children: subCategories,
      })
    }
  })
  return array
}

export const useAppConfig = () => {
  const { appConfig = {} } = useContext(LocalContext)
  return appConfig
}
export default ({ children }) => {
  const searchParamLang = useSearchParam('lang')
  const userLang = useSelector(selectUserInterfaceLang)
  const lang = searchParamLang || userLang
  const [initialLang, setInitialLang] = useState()
  useEffect(() => {
    saveLanguageKey(lang)
    setInitialLang(lang)
  }, [lang])
  return (
    <I18n
      key={initialLang}
      translations={translations}
      initialLang={initialLang}
      fallbackLang={'vi'}>
      {(initialLang || null) && <LocalProvider>{children}</LocalProvider>}
    </I18n>
  )
}
