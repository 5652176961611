import { API_ROOT_URL } from 'envs/_current/config'
import { memberSchema } from './member'
import { createTransform } from './utils/createTransform'

// permissions
// Route::post('permissions/permission/{prop}/{id}/{m_id}/{permission_name}/allow', 'PermissionController@allow');
export const permission_allow_Api = {
  name: 'permission_allow',
  backend:
    "Route::post('permissions/permission/{prop}/{id}/{m_id}/{permission_name}/allow', 'PermissionController@allow');",
  root: API_ROOT_URL,
  path:
    '/permissions/permission/:prop/:id/:m_id/:permission_name/allow',
  method: 'POST',
  transform: createTransform(
    memberSchema,
    'data'
  )
}
// Route::post('permissions/permission/{prop}/{id}/{m_id}/{permission_name}/deny', 'PermissionController@deny');
export const permission_deny_Api = {
  name: 'permission_deny',
  backend:
    "Route::post('permissions/permission/{prop}/{id}/{m_id}/{permission_name}/deny', 'PermissionController@deny')",
  root: API_ROOT_URL,
  path:
    '/permissions/permission/:prop/:id/:m_id/:permission_name/deny',
  method: 'POST',
  transform: createTransform(
    memberSchema,
    'data'
  )
}
// Route::post('permissions/permission/{prop}/{id}/{m_id}/{permission_name}/revoke', 'PermissionController@revoke');
export const permission_revoke_Api = {
  backend:
    "Route::post('permissions/permission/{prop}/{id}/{m_id}/{permission_name}/revoke', 'PermissionController@revoke')",
  root: API_ROOT_URL,
  path:
    '/permissions/permission/:prop/:id/:m_id/:permission_name/revoke',
  method: 'POST',
  transform: createTransform(
    memberSchema,
    'data'
  )
}
// Route::get('permissions/permission/{prop}/{id}/{m_id}/gets', 'PermissionController@getPermissions');
export const permission_getPermissions_Api = {
  name: 'permission_getPermissions',
  backend:
    "Route::get('permissions/permission/{prop}/{id}/{m_id}/gets', 'PermissionController@getPermissions')",
  root: API_ROOT_URL,
  path:
    '/permissions/permission/:prop/:id/:m_id/gets',
  method: 'POST'
}

// Route::get('permissions/permission/{prop}/{id}/get-members', 'PermissionController@getMembers');
export const permission_getMembers_Api = {
  name: 'permission_getMembers',
  backend:
    "Route::get('permissions/permission/{prop}/{id}/get-members', 'PermissionController@getMembers')",
  root: API_ROOT_URL,
  path:
    '/permissions/permission/:prop/:id/get-members',
  method: 'GET'
}
