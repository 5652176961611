import {Avatar, Button, Form, Image, Input, Modal} from 'antd'
import {baseModel, productModel, userModel} from 'apis/model'
import Toggle from 'components/Toggle'
import {API_ROOT_URL} from 'envs/_current/config'
import logFunc from 'helpers/logFunc'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {useSelector} from 'react-redux'
import {getLogin} from 'redux/selectors'
import {Photo} from "../../../conts/imageBase64"
import formatCurrency from "../../Billing/components/formatCurrency"
import {CountrySelect} from './CountrySelect'
import {UnitSelect} from './UnitSelect'
import {emptyObject} from "../../../helpers/emptyObjects";

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 6}
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 18}
  }
}
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 6
    }
  }
}
const GetQuotesForm = ({
                         disabledNames = {},
                         item,
                         content,
                         sender_name,
                         sender_email,
                         sender_phone,
                         sender_company_name,
                         sender_country_id,
                         request_type,
                         price,
                         unit_id,
                         volume,
                         onFinish
                       }) => {
  const t = useTranslate()
  const [form] = Form.useForm()
  const handleFinish = values => {
    onFinish(values)
    console.log(
      'Received values of form: ',
      values
    )
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="getQuotes"
      onFinish={handleFinish}
      initialValues={{
        content,
        sender_name,
        sender_email,
        sender_phone,
        sender_company_name,
        sender_country_id,
        request_type,
        price,
        unit_id,
        volume
      }}
      scrollToFirstError>
      <Form.Item
        name="label_0"
        label=""
      >
        <div className="text-base text-color-100">
          {t(
            'Enter some information so the provider knows your requirements'
          )}
        </div>
      </Form.Item>
      <Form.Item
        label={t('organization')}
      >
        <a target="_blank" href={baseModel.getLinkToDetail(item.owner)} className="flex space-x-3 items-center">
          <Avatar className="border border-gray-300" shape={"square"}
                  src={baseModel.getFullAvatarUrl(item.owner)}></Avatar>
          <div className="font-bold">{baseModel.getTitle(item.owner)}</div>
        </a>
      </Form.Item>
      <Form.Item
        name="basic_info"
        label={t('product')}>
        <div
          style={{height: '120px'}}
          className="w-full background-100 border rounded-lg flex flex-1">
          <div className="p-3 overflow-hidden" style={{height: '100px', width: '100px'}}>
            <div className="flex w-full h-full">
              <Image
                size={80}
                src={
                  item.avatar
                }
                className="object-contain w-full h-full rounded-lg"
                fallback={Photo}
              />
            </div>
          </div>
          <div className="p-3 flex flex-col">
            <div className="text-xl font-bold">
              {item.title}
            </div>
            {Number(item.price) > 0 ? (
              <div className="rounded-md background-200 px-2">
                <b>
                  {formatCurrency(
                    item.price
                  )}
                </b>
                /{_.get(item, 'unit.name', t('unit'))}
              </div>
            ) : (
                <span className="font-medium text-sm text-blue-500 italic">
                      {`* ${t('no price tag')}`}
                    </span>
            )}
            <div className="italic">
              {item.owner &&
              item.owner.title}
            </div>
          </div>
        </div>
      </Form.Item>

      <Form.Item
        name="volume"
        label={t('volume')}
      >
        <Input
          addonAfter={
            <UnitSelect
              {...{
                form,
                name: 'unit',

                rules: [
                  {
                    required: true,
                    message: t(
                      'required field'
                    )
                  }
                ]
              }} />
          }
          type="number"
          // style={{width: 200}}
          placeholder={t(
            'volume'
          )}
        />
      </Form.Item>
      <Form.Item
        name="content"
        label={t('content')}
        rules={[
          {
            required: true,
            message: 'required!'
          }
        ]}>
        <Input.TextArea
          rows={4}
          style={{
            width: '100%',
            // minHeight: 150
          }}
        />
      </Form.Item>

      <Form.Item
        name="sender_phone"
        label={t('phone')}
        disabled={
          disabledNames[
            'sender_phone'
            ]
        }>
        <Input
          addonBefore={
            <CountrySelect
              {...{
                form,
                defaultValue: sender_country_id,
                name: 'sender_country_id',
                width: 200,
                disabled:
                  disabledNames[
                    'sender_country_id'
                    ],
                onChange: ({value}) => {
                  form.setFieldsValue({
                    'sender_country_id':
                    value
                  });
                },
                rules: [
                  {
                    required: true,
                    message: t('required field')
                  }
                ]
              }}
            />
          }
          placeholder={t('phone')}
        />
      </Form.Item>

      <Form.Item
        name="sender_email"
        label={t("email")}
        rules={[
          {
            type: 'email',
            message: t(
              'invalid email address'
            )
          },
          {
            required: true,
            message: t('required!')
          }
        ]}>
        <Input
          inputMode="email"
          disabled={
            disabledNames[
              'sender_email'
              ]
          }
        />
      </Form.Item>
      <Form.Item
        name="sender_name"
        label={t('name')}>
        <Input
          disabled={
            disabledNames['sender_name']
          }
        />
      </Form.Item>
      <Form.Item
        name="sender_company_name"
        label={t('company')}>
        <Input
          disabled={
            disabledNames[
              'sender_company_name'
              ]
          }
        />
      </Form.Item>
      <Form.Item
        {...tailFormItemLayout}>
        <Button
          type="primary"
          htmlType="submit" className="rounded-full">
          {t('send')}
        </Button>
      </Form.Item>
    </Form>
  )
}
const ModalGetQuote = ({
                         item,
                         onCancel
                       }) => {
  const t = useTranslate()
  const login =
    useSelector(getLogin) || emptyObject
  const product = item
  const initialValues = {
    content: '',
    sender_name: userModel.getTitle(
      login
    ),
    sender_email: login.email,
    sender_phone: login.phone_number,
    sender_company_name: login.company,
    sender_country_id: _.get(
      login,
      'locations[0].country.id', 237
    ),
    request_type: [1],
    price: product.price,
    unit_id: _.get(product, 'unit.id'),
    volume: 0
  }
  window.isEmpty = _.isEmpty
  const disabledNames = {
    sender_name: !_.isEmpty(
      userModel.getTitle(login)
    ),
    sender_email: !_.isEmpty(
      login.email
    ),
    sender_phone: !_.isEmpty(
      login.phone_number
    ),
    sender_company_name: !_.isEmpty(
      login.company
    ),
    sender_country_id: !logFunc(
      _.isEmpty
    )(
      '' +
      _.get(
        login,
        'locations[0].country.id',
        ''
      )
    )
  }
  const [
    data,
    dispatch
  ] = useDispatchAsyncActionWithNotify({
    onSuccess: onCancel
  })

  return (
    <Modal
      destroyOnClose
      centered
      maskClosable={false}
      width={800}
      visible
      onCancel={onCancel}
      header={null}
      footer={null}>
      <div className="">
        <div className="py-6 space-y-3 flex flex-col">
          <div className="text-xl font-bold">
            {t(
              'contact with provider'
            )}
          </div>

          <GetQuotesForm
            disabledNames={
              disabledNames
            }
            onFinish={values => {
              dispatch(
                createAsyncAction({
                  apiInfo: {
                    root: API_ROOT_URL,
                    path: `/messages/product/${productModel.getId(
                      item
                    )}/send-request`,
                    method: 'POST'
                  },
                  values
                })
              )
            }}
            item={product}
            {...initialValues}
          />
        </div>
      </div>
    </Modal>
  )
}

export function GetPriceQuoteButton({
                                      item,
                                      button,
                                      t,
                                      ...props
                                    }) {
  return (
    <Toggle>
      {(isToggle, toggle) => {
        return (
          <>
            {button ? (
              React.cloneElement(
                button,
                {
                  ...button.props,
                  onClick: toggle
                }
              )
            ) : (
              <Button
                {...props}
                size="large"
                onClick={toggle}
                className="block w-full text-center font-bold text-sm md:text-base rounded"
                type="dashed"
              />
            )}
            {isToggle && (
              <ModalGetQuote
                item={item}
                onCancel={
                  toggle
                }
              />
            )}
          </>
        )
      }}
    </Toggle>
  )
}

export function GetQuoteButton({
     variant = 'price',
     item,
     button,
     ...props
   }) {
  switch (variant) {
    case 'price':
      return (
        <GetPriceQuoteButton
          {...{
            item,
            button,
            ...props
          }}
        />
      )
    case 'contact-provider':
      const {t, ...restProps} = props;
      return (
        <a
          href={
            '/mart/contact-provider/' +
            _.get(item, 'owner.idname')
          }
          target="_blank"
          {...restProps}
        />
      )
    default:
      return null
  }
}
