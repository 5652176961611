import { MessageOutlined } from '@ant-design/icons'
import { Avatar, Button } from 'antd'
import {
  organizationModel,
  userModel
} from 'apis/model'
import { API_ROOT_URL } from 'envs/_current/config'
import { emptyObject } from 'helpers/emptyObjects'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import { AsyncByActionWithNotify } from 'modules/asyncCache/components/Async'
import { useAppConfig } from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext
} from 'react'
import {
  Link,
  useHistory
} from 'react-router-dom'
import ServerContext from 'views/Server/ServerContext'
import { routeEntities } from '../../Organization/Settings/routes'

export const ContactDetail = React.memo(
  () => {
    const t = useTranslate()
    const { item } = useContext(
      ServerContext
    )
    const history = useHistory()
    const appConfig =
      useAppConfig() || emptyObject
    const role_titles = _.get(
      appConfig,
      'role_titles',
      []
    )

    return (
      Boolean(
        _.get(item, 'contacts.length')
      ) && (
        <div className="space-y-3 flex flex-col">
          <h2 className="text-2xl font-semibold">
            {t('contact details')}
          </h2>

          {_.get(
            item,
            'contacts',
            []
          ).map((eContact, index) => {
            return (
              <div
                key={index}
                className="flex background-100 grid grid-cols-1 md:grid-cols-3 rounded-md border-0 p-3">
                <div className="col-span-2 space-y-3 p-3 md:border-r">
                  {!!_.get(
                    eContact,
                    'location'
                  ) && (
                    <div className="flex flex-col">
                      <div className="text-color-100 font-light uppercase text-xs mb-3">
                        {t('address')}:
                      </div>
                      <div className="flex flex-1">
                        {!_.isEmpty(
                          _.get(
                            eContact,
                            'location'
                          )
                        ) && (
                          <div>
                            <div className="font-bold">
                              {_.get(
                                eContact,
                                'location.location_name'
                              )}
                            </div>
                            <div className="">
                              {_.get(
                                eContact,
                                'location.address'
                              )}
                            </div>
                            <div className="flex flex-1">
                              {_.has(
                                eContact,
                                'location.ward'
                              ) &&
                                !_.isEmpty(
                                  eContact
                                    .location
                                    .ward
                                ) && (
                                  <span className="pr-1">
                                    <span className="pr-1">
                                      {_.get(
                                        eContact,
                                        'location.ward.type'
                                      )}
                                    </span>
                                    <span className="pr-1">
                                      {_.get(
                                        eContact,
                                        'location.ward.name'
                                      )}
                                    </span>
                                    ,
                                  </span>
                                )}
                              {_.has(
                                eContact,
                                'location.district'
                              ) &&
                                !_.isEmpty(
                                  eContact
                                    .location
                                    .district
                                ) && (
                                  <span className="pr-1">
                                    <span className="pr-1">
                                      {_.get(
                                        eContact,
                                        'location.district.type'
                                      )}
                                    </span>
                                    <span className="pr-1">
                                      {_.get(
                                        eContact,
                                        'location.district.name'
                                      )}
                                    </span>
                                    ,
                                  </span>
                                )}
                              {_.has(
                                eContact,
                                'location.province'
                              ) &&
                                !_.isEmpty(
                                  eContact
                                    .location
                                    .province
                                ) && (
                                  <span className="pr-1">
                                    <span className="pr-1">
                                      {_.get(
                                        eContact,
                                        'location.province.type'
                                      )}
                                    </span>
                                    <span className="pr-1">
                                      {_.get(
                                        eContact,
                                        'location.province.name'
                                      )}
                                    </span>
                                    ,
                                  </span>
                                )}
                              {_.has(
                                eContact,
                                'location.country'
                              ) &&
                                !_.isEmpty(
                                  eContact
                                    .location
                                    .country
                                ) && (
                                  <span className="pr-1">
                                    <span className="pr-1">
                                      {_.get(
                                        eContact,
                                        'location.country.common_name'
                                      )}
                                    </span>
                                    ,
                                  </span>
                                )}
                              {_.has(
                                eContact,
                                'location.postal_code'
                              ) &&
                                !_.isEmpty(
                                  eContact
                                    .location
                                    .postal_code
                                ) && (
                                  <span className="pr-1">
                                    <span className="pr-1">
                                      {_.get(
                                        eContact,
                                        'location.postal_code'
                                      )}
                                    </span>
                                    .
                                  </span>
                                )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {!!_.get(
                    eContact,
                    'phone'
                  ) && (
                    <div className="flex flex-col">
                      <div className="text-color-300 font-bold uppercase text-xs">
                        {t('phone')}:
                      </div>
                      <div className="flex flex-1">
                        {!!_.get(
                          eContact,
                          'phone'
                        ) && (
                          <span className="pr-1">
                            <span className="pr-1">
                              {_.get(
                                eContact,
                                'phone'
                              )}
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {!!_.get(
                    eContact,
                    'phone2'
                  ) && (
                    <div className="flex flex-col">
                      <div className="text-color-300 font-bold uppercase text-xs">
                        {t(
                          'alternative phone'
                        )}
                        :
                      </div>
                      <div className="flex flex-1">
                        <span className="pr-1">
                          <span className="pr-1">
                            {_.get(
                              eContact,
                              'phone2'
                            )}
                          </span>
                        </span>
                      </div>
                    </div>
                  )}

                  {!!_.get(
                    eContact,
                    'email'
                  ) && (
                    <div className="flex flex-col">
                      <div className="text-color-300 font-bold uppercase text-xs">
                        {t('email')}:
                      </div>
                      <div className="flex flex-1">
                        {!!_.get(
                          eContact,
                          'email'
                        ) && (
                          <span className="pr-1">
                            <span className="pr-1">
                              {_.get(
                                eContact,
                                'email'
                              )}
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-span-1 rounded p-3 flex-col space-y-3">
                  <div className="flex flex-1">
                    <Avatar
                      shape="circle"
                      size="large"
                      src={_.get(
                        eContact,
                        'contact_member.user.avatar'
                      )}
                      className="mr-3 flex-shrink-0 text-blue-600 bg-blue-200"
                    />
                    <div className="flex flex-col">
                      <h2 className="flex flex-1 font-bold">
                        {_.has(
                          eContact,
                          'prefix_name'
                        ) &&
                          !_.isEmpty(
                            eContact.prefix_name
                          ) && (
                            <span className="pr-1">
                              <span className="pr-1">
                                {t(
                                  _.get(
                                    eContact,
                                    'prefix_name'
                                  )
                                )}
                              </span>
                            </span>
                          )}
                        {_.has(
                          eContact,
                          'name'
                        ) &&
                          !_.isEmpty(
                            eContact.name
                          ) && (
                            <span className="pr-1">
                              <span className="pr-1">
                                {_.get(
                                  eContact,
                                  'name'
                                )}
                              </span>
                            </span>
                          )}
                      </h2>

                      {!!_.get(
                        eContact,
                        'role_title'
                      ) && (
                        <div className="flex flex-1 text-sm">
                          <span className="pr-1">
                            {
                              role_titles[
                                _.get(
                                  eContact,
                                  'role_title'
                                )
                              ]
                            }
                          </span>
                        </div>
                      )}

                      {!_.isEmpty(
                        _.get(
                          eContact,
                          'contact_member.user',
                          null
                        )
                      ) && (
                        <AsyncByActionWithNotify
                          onSuccess={([
                            __,
                            res
                          ]) => {
                            const conversationId = _.get(
                              res,
                              'response.data.id'
                            )
                            history.push(
                              `/messages?conversationId=${conversationId}`
                            )
                          }}>
                          {(
                            data,
                            dispatch
                          ) => {
                            return (
                              <div
                                onClick={() => {
                                  dispatch(
                                    createAsyncAction(
                                      {
                                        apiInfo: {
                                          backend: ``,
                                          root: API_ROOT_URL,
                                          path:
                                            '/messages/conversation/create',
                                          method:
                                            'POST'
                                        },
                                        values: {
                                          organization_id: organizationModel.getId(
                                            item
                                          ),
                                          receiver_id: userModel.getId(
                                            _.get(
                                              eContact,
                                              'contact_member.user'
                                            )
                                          )
                                        }
                                      }
                                    )
                                  )
                                }}
                                className="text-primary block cursor-pointer mt-2">
                                <MessageOutlined className="pr-1" />
                                {t(
                                  'chat with provider'
                                )}
                              </div>
                            )
                          }}
                        </AsyncByActionWithNotify>
                      )}
                    </div>
                  </div>

                  {_.has(
                    eContact,
                    'email'
                  ) &&
                    !_.isEmpty(
                      eContact.email
                    ) && (
                      <Link
                        to={
                          routeEntities
                            .contacts
                            .path
                        }
                        className="flex mt-6">
                        <Button
                          type="primary"
                          size="large"
                          className="mx-1 w-full rounded-full no-shadow">
                          {t(
                            'contact now'
                          )}
                        </Button>
                      </Link>
                    )}
                </div>
              </div>
            )
          })}
        </div>
      )
    )
  }
)
