import {List} from 'antd'
import {channel_add_Api, getChannelsByProps_Api} from 'apis'
import ApiInfoForm from 'components/form/ApiInfoForm'
import {ChannelIcon} from 'components/icons/ChannelIcon'
import BaseItemLayout from 'components/ItemLayout/BaseItemLayout'
import Modal from 'components/Modal/Modal'
import Pure from 'components/Pure'
import Toggle from 'components/Toggle'
import getItemKeyId from 'helpers/getItemKeyId'
import getItemType from 'helpers/getItemType'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {nest, withProps} from 'recompose'
import CreateChannelForm from 'views/CreateChannelForm'
import {modalWidth} from 'views/AppLocals/configs'
import ServerContext from 'views/Server/ServerContext'
import {Block} from './Block'
import {EditAbleBlockHolder} from './EditAbleBlockHolder'
import {useHistory} from "../../../modules/navigation/useRouter";
import _ from "lodash";
import Translate from "../../../modules/local/Translate";
import getLinkToDetail from "../../../helpers/getLinkToDetail";
import {useLocation} from "react-use";
import useQueryString from "../../../hooks/useQueryString";

export const CreateChannelFormModal = ({onCancel, onSuccess}) => {
  const history = useHistory()

  return (
    <Toggle defaultValue={true}>
      {(isToggle, toggle) => (
        <CreateChannelFormWithModal
          visible={isToggle}
          onSuccess={(event) => {
            if (!!onSuccess) {
              onSuccess(event, () => {
                toggle(false)
              })
            } else {
              const [, data, __] = event
              const item = _.get(data, 'response.data')
              history.push({
                pathname: getLinkToDetail(item),
              })
            }
          }}
          onCancel={() => {
            toggle()
            if (onCancel) {
              onCancel()
            } else {
              history.goBack()
            }
          }}
        />
      )}
    </Toggle>
  )
}

const ChannelModalContent = (props) => {
  const location = useLocation()

  const [{owner_id, owner_type}] = useQueryString(location.pathname)

  const initialValues = _.omitBy(
    {
      owner_id,
      owner_type,
      default_owner_id: owner_id,
    },
    _.isUndefined
  )

  return (
    <Translate>
      {(t) => (
        <ServerContext.Consumer>
          {({item}) => (
            <div className="px-3">
              <ApiInfoForm
                onSuccess={props.onSuccess}
                prefixStr="CreateChannelFormModal"
                apiInfo={channel_add_Api}
                onPreSubmit={({
                                default_owner_id,
                                default_owner_type,
                                ...values
                              }) => ({
                  ...values,
                })}
                initialErrors={{
                  title: t('required'),
                }}
                validate={async (values) => {
                  let errors = {}

                  if (!_.get(values, 'title')) {
                    errors = {
                      ...errors,
                      title: t('required'),
                    }
                  }

                  if (_.get(values, 'title.length') < 6) {
                    errors = {
                      ...errors,
                      title: t('name required more than {1} characters', {
                        1: 6,
                      }),
                    }
                  }

                  if (!_.get(values, 'owner') && !_.get(values, 'owner_id')) {
                    errors = {
                      ...errors,
                      owner: t('required'),
                    }
                  }

                  return errors
                }}
                initialValues={{
                  default_owner_id: item.id,
                  owner_id: item.id,
                  default_owner_type: item._type,
                  owner_type: item._type,
                  privacy: 1,
                  ...initialValues,
                }}>
                {({...props}) => (
                  <Pure>
                    <CreateChannelForm {...props} />
                  </Pure>
                )}
              </ApiInfoForm>
            </div>
          )}
        </ServerContext.Consumer>
      )}
    </Translate>
  )
}

export const CreateChannelFormWithModal = nest(
  withProps(({visible, onCancel}) => ({
    visible: visible,
    footer: null,
    maskClosable: false,
    onCancel: onCancel,
    width: modalWidth.medium,
  }))(Modal),
  ChannelModalContent
)

export function Channels() {
  const t = useTranslate()
  const [forceRefreshKey, setForceRefreshKey] = useState(new Date().getTime())
  const {item, type, id} = useContext(ServerContext)
  const {
    action,
    isLoading,
    success,
    errorMessages,
    result = [],
    handleAsyncAction,
    error,
  } = useAsyncAction({
    apiInfo: getChannelsByProps_Api,
    query: {
      ':type': getItemType(item),
      ':id': getItemKeyId(item),
    },
  })
  useEffect(() => {
    handleAsyncAction()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefreshKey])
  const dataSource = (item.channels || []).map((item) => {
    const {title, description, id, _type} = item
    const to = getLinkToDetail(item)
    return (
      <BaseItemLayout
        className="p-3 hover:shadow"
        {...{
          title: (
            <Link
              to={to}
              className="flex ">
              <span
                style={{
                  fontSize: '1.5em',
                }}
                className="mr-1 flex align-self-start ">
                {/*{channelPrefix}*/}
                <ChannelIcon
                  premium={item.premium}
                  className="mr-2 text-color-100 text-3xl w-8 h-8"
                />
              </span>
              {title}
            </Link>
          ),
          description,
          to,
        }}
      />
    )
  })
  return (
    <Block>
      <div className="flex w-full flex flex-col  font-semibold text-color-300">
        <h2 className="text-2xl py-3 font-semibold flex-1">{t('channels')}</h2>
        <List
          grid={{
            gutter: 36,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 2,
            xl: 2,
            xxl: 2,
          }}
          dataSource={dataSource}
          renderItem={(item) => (
            <List.Item
              style={{
                height: '150px',
              }}>
              {item}
            </List.Item>
          )}
        />
      </div>
      {item.edit && (
        <Toggle>
          {(isToggle, toggle) => (
            <>
              <EditAbleBlockHolder
                onClick={toggle}
                title={t('create channel')}
                description={`i take some issue with how they've defined "rewrite" in there.`}
              />
              {isToggle && (
                <CreateChannelFormModal
                  onSuccess={() => setForceRefreshKey(new Date().getTime())}
                  footer={null}
                  onCancel={toggle}
                  visible={true}
                />
              )}
            </>
          )}
        </Toggle>
      )}
    </Block>
  )
}

export default Channels
