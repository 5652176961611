export const defaultCategories = [
	{
		_type: 'category',
		id: '998405e0-afed-11e8-a1bb-33966c1d7477',
		parent: [],
		idname: 'materials',
		name: 'Materials',
		avatar: 'photo/2019/06/07/998405e0-afed-11e8-a1bb-33966c1d7477_photo_20190607053107-original.jpg',
		category_type: null,
		total_objects: 1,
		total_followers: 0,
		follow_status: true
	},
	{
		_type: 'category',
		id: '9984b170-afed-11e8-b387-51852b22cced',
		parent: {
			_type: 'category',
			id: '998405e0-afed-11e8-a1bb-33966c1d7477',
			idname: 'materials',
			name: 'Materials',
			avatar: 'photo/2019/06/07/998405e0-afed-11e8-a1bb-33966c1d7477_photo_20190607053107-original.jpg',
			category_type: null
		},
		idname: 'soybean-meal',
		name: 'Soybean Meal',
		avatar: 'photo/2019/06/06/9984b170-afed-11e8-b387-51852b22cced_photo_20190606060406-original.jpg',
		category_type: null,
		total_objects: 171,
		total_followers: 154,
		follow_status: true
	},
	{
		_type: 'category',
		id: '9984ee10-afed-11e8-8c8e-eda8def49577',
		parent: {
			_type: 'category',
			id: '998405e0-afed-11e8-a1bb-33966c1d7477',
			idname: 'materials',
			name: 'Materials',
			avatar: 'photo/2019/06/07/998405e0-afed-11e8-a1bb-33966c1d7477_photo_20190607053107-original.jpg',
			category_type: null
		},
		idname: 'wheat',
		name: 'Wheat',
		avatar: 'photo/2019/06/07/9984ee10-afed-11e8-8c8e-eda8def49577_photo_20190607050718-original.jpg',
		category_type: null,
		total_objects: 162,
		total_followers: 191,
		follow_status: true
	},
	{
		_type: 'category',
		id: '99851b10-afed-11e8-a82e-a7f01d3ac79c',
		parent: {
			_type: 'category',
			id: '998405e0-afed-11e8-a1bb-33966c1d7477',
			idname: 'materials',
			name: 'Materials',
			avatar: 'photo/2019/06/07/998405e0-afed-11e8-a1bb-33966c1d7477_photo_20190607053107-original.jpg',
			category_type: null
		},
		idname: 'rice',
		name: 'Rice',
		avatar: 'photo/2019/06/07/99851b10-afed-11e8-a82e-a7f01d3ac79c_photo_20190607095731-original.jpg',
		category_type: null,
		total_objects: 281,
		total_followers: 264,
		follow_status: true
	},
	{
		_type: 'category',
		id: '99855fd0-afed-11e8-b40a-119127fbca7f',
		parent: {
			_type: 'category',
			id: '998405e0-afed-11e8-a1bb-33966c1d7477',
			idname: 'materials',
			name: 'Materials',
			avatar: 'photo/2019/06/07/998405e0-afed-11e8-a1bb-33966c1d7477_photo_20190607053107-original.jpg',
			category_type: null
		},
		idname: 'coffee',
		name: 'Coffee',
		avatar: 'photo/2019/06/07/99855fd0-afed-11e8-b40a-119127fbca7f_photo_20190607100933-original.jpg',
		category_type: null,
		total_objects: 139,
		total_followers: 141,
		follow_status: true
	},
	{
		_type: 'category',
		id: '998578c0-afed-11e8-8a1e-b741727c08d0',
		parent: [],
		idname: 'animals',
		name: 'Animals',
		avatar: 'photo/2019/06/06/998578c0-afed-11e8-8a1e-b741727c08d0_photo_20190606050625-original.jpg',
		category_type: null,
		total_objects: 1,
		total_followers: 0,
		follow_status: true
	},
	{
		_type: 'category',
		id: '99858dc0-afed-11e8-8a01-7d951cd4ebfe',
		parent: {
			_type: 'category',
			id: '998405e0-afed-11e8-a1bb-33966c1d7477',
			idname: 'materials',
			name: 'Materials',
			avatar: 'photo/2019/06/07/998405e0-afed-11e8-a1bb-33966c1d7477_photo_20190607053107-original.jpg',
			category_type: null
		},
		idname: 'cotton',
		name: 'Cotton',
		avatar: 'photo/2019/06/06/99858dc0-afed-11e8-8a01-7d951cd4ebfe_photo_20190606044343-original.jpg',
		category_type: null,
		total_objects: 224,
		total_followers: 235,
		follow_status: true
	},
	{
		_type: 'category',
		id: '9985a080-afed-11e8-bbf5-39348da59a3f',
		parent: {
			_type: 'category',
			id: '998578c0-afed-11e8-8a1e-b741727c08d0',
			idname: 'animals',
			name: 'Animals',
			avatar: 'photo/2019/06/06/998578c0-afed-11e8-8a1e-b741727c08d0_photo_20190606050625-original.jpg',
			category_type: null
		},
		idname: 'pig',
		name: 'Pig',
		avatar: 'photo/2019/06/06/9985a080-afed-11e8-bbf5-39348da59a3f_photo_20190606044744-original.jpg',
		category_type: null,
		total_objects: 135,
		total_followers: 154,
		follow_status: true
	},
	{
		_type: 'category',
		id: '9985b2a0-afed-11e8-94f1-77901cb95e85',
		parent: {
			_type: 'category',
			id: '998578c0-afed-11e8-8a1e-b741727c08d0',
			idname: 'animals',
			name: 'Animals',
			avatar: 'photo/2019/06/06/998578c0-afed-11e8-8a1e-b741727c08d0_photo_20190606050625-original.jpg',
			category_type: null
		},
		idname: 'chicken',
		name: 'Chicken',
		avatar: 'photo/2019/06/06/9985b2a0-afed-11e8-94f1-77901cb95e85_photo_20190606045457-original.jpg',
		category_type: null,
		total_objects: 131,
		total_followers: 126,
		follow_status: true
	},
	{
		_type: 'category',
		id: '99863670-afed-11e8-a685-43b6be0de4b2',
		parent: {
			_type: 'category',
			id: '998578c0-afed-11e8-8a1e-b741727c08d0',
			idname: 'animals',
			name: 'Animals',
			avatar: 'photo/2019/06/06/998578c0-afed-11e8-8a1e-b741727c08d0_photo_20190606050625-original.jpg',
			category_type: null
		},
		idname: 'fish',
		name: 'Fish',
		avatar: 'photo/2019/06/07/99863670-afed-11e8-a685-43b6be0de4b2_photo_20190607094344-original.jpg',
		category_type: null,
		total_objects: 191,
		total_followers: 185,
		follow_status: true
	},
	{
		_type: 'category',
		id: '430231a0-f85c-11e8-8e5e-9bbde0f3d596',
		parent: {
			_type: 'category',
			id: '998405e0-afed-11e8-a1bb-33966c1d7477',
			idname: 'materials',
			name: 'Materials',
			avatar: 'photo/2019/06/07/998405e0-afed-11e8-a1bb-33966c1d7477_photo_20190607053107-original.jpg',
			category_type: null
		},
		idname: 'soybean',
		name: 'Soybean',
		avatar: 'photo/2019/06/07/430231a0-f85c-11e8-8e5e-9bbde0f3d596_photo_20190607094656-original.jpg',
		category_type: null,
		total_objects: 127,
		total_followers: 117,
		follow_status: true
	},
	{
		_type: 'category',
		id: '105ce460-8cc6-11e9-9c2c-310acc29697d',
		parent: {
			_type: 'category',
			id: '998405e0-afed-11e8-a1bb-33966c1d7477',
			idname: 'materials',
			name: 'Materials',
			avatar: 'photo/2019/06/07/998405e0-afed-11e8-a1bb-33966c1d7477_photo_20190607053107-original.jpg',
			category_type: null
		},
		idname: 'corn',
		name: 'Corn',
		avatar: 'photo/2019/06/12/105ce460-8cc6-11e9-9c2c-310acc29697d_photo_20190612040811-original.jpg',
		category_type: null,
		total_objects: 122,
		total_followers: 74,
		follow_status: true
	}
];
