import {getId, getType,} from 'apis/model/base'
import _ from 'lodash'
import React from 'react'
import {Null} from 'views/Shared'
import {HostActionTypes} from '../HostContext'
import HostActionRow from '../Settings/HostActionRow'

const renderHostItem =
  (
    handleAction = Null,
    renderActions = undefined
  ) =>
    (item, index) => {
      if (!!_.get(item, 'deleted')) {
        return null
      }

      const host = _.get(item, 'host', {})

      const owner = _.get(
        item,
        'owner',
        {}
      )

      const [id, prop] = [
        getId(owner),
        getType(owner),
      ]

      const onLeave = () => {
        handleAction(
          HostActionTypes.LEAVE,
          {
            id,
            prop,
            host_id: getId(item),
          }
        )
      }

      return (
        <div key={index}>
          <HostActionRow
            {...item}
            item={host}
            subItem={owner}
            params={{
              host_id: getId(item),
            }}
            Actions={
              _.isFunction(renderActions)
                ? () =>
                  renderActions({
                    onLeave,
                  })
                : undefined
            }
            canLeave={false}
            className="relative space-y-3 rounded-lg shadow-items hover:shadow-out"
          />
        </div>
      )
    }

export default renderHostItem
