import Loadable from 'react-loadable'
import LoadingPage from 'views/LoadingPage'

export const Billing = Loadable({
  loader: () => import('./Billing'),
  loading: LoadingPage
})
export const AddPaymentFailure = Loadable(
  {
    loader: () =>
      import(
        './components/AddPaymentFailure'
      ),
    loading: LoadingPage
  }
)
export const AddPaymentSuccess = Loadable(
  {
    loader: () =>
      import(
        './components/AddPaymentSuccess'
      ),
    loading: LoadingPage
  }
)
export const AddPaymentWithPlanSuccess = Loadable(
  {
    loader: () =>
      import(
        './components/AddPaymentWithPlanSuccess'
      ),
    loading: LoadingPage
  }
)
