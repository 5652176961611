import { search_filterParams_Api } from 'apis'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParam } from 'react-use'
import { useFilterHandler } from 'views/Discovery/FilterHandler'
import LoadingPage from 'views/LoadingPage'
import { SearchTypes } from 'views/Search/SearchProvider'
import { getResponseItem, Null } from 'views/Shared'
import { bindQueryParam } from 'views/Shared/routerHelper'
import DiscoveryExplorerMenu from './components/DiscoveryExplorerMenu'

const DiscoveryExplorerContext = React.createContext({
  item: {},
})

export const DiscoveryExplorerProvider = ({
  type = SearchTypes.ALL,
  children,
  ...props
}) => {
  const [values, setValues] = useState({
    type: undefined,
    keyword: undefined,
  })

  const categories = useSearchParam('categories')

  const { response, isLoading, handleAsyncAction } = useAsyncAction({
    apiInfo: search_filterParams_Api,
  })

  const item = getResponseItem(response)

  const filterMenu = useMemo(
    () => (Boolean(isLoading) ? <LoadingPage /> : <DiscoveryExplorerMenu />),
    [isLoading]
  )

  useEffect(() => {
    if (type) {
      handleAsyncAction({}, bindQueryParam({ type }))
    }
  }, [type])

  const {
    filter,
    filterRow,
    filterRows,
    setFilter = Null,
  } = useFilterHandler({
    item,
    categories,
  })

  const onChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    })
  }

  return (
    <DiscoveryExplorerContext.Provider
      value={{
        ...props,
        item,
        values,
        filter,
        filterRow,
        filterRows,
        filterMenu,
        onChange,
        setValues,
        setFilter,
        isLoading,
      }}>
      {children}
    </DiscoveryExplorerContext.Provider>
  )
}

export default DiscoveryExplorerContext
