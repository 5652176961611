import {Avatar, Badge} from 'antd'
import Description from 'components/Feed/Description'
import VerifiedIcon from 'components/icons/VerifiedIcon'
import ImageLoader from 'components/ImageLoader'
import Translate from 'modules/local/Translate'
import React from 'react'
import {Link} from 'react-router-dom'

export const PageCard = React.memo(
  ({
    totals,
    avatar,
    cover,
    title,
    description,
    verified,
    id,
    className,
    style
  }) => (
    <Translate>
      {t => {
        return (
          <div
            className={className}
            style={{
              overflow: 'hidden',
              position: 'relative',
              width: '256px',
              height: '284px',
              background:
                'rgb(218, 218, 218)',
              // margin: '1em',
              borderRadius: '0.3em',
              ...style
            }}>
            <ImageLoader
              style={{
                top: 0,
                left: 0,
                position: 'absolute',
                width: '100%',
                height: '144px',
                objectFit: 'cover'
              }}
              src={cover}
              alt=""
            />
            <div
              style={{
                top: 0,
                left: 0,
                position: 'absolute',
                width: '100%',
                height: '145px',
                objectFit: 'cover',
                backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 20%,  rgb(218, 218, 218) 100%)`
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                bottom: 0,
                left: 0,
                position: 'absolute',
                width: '100%',
                padding: '0.9em'
              }}>
              <div className="pb-3 flex items-end">
                <Avatar
                  size="large"
                  style={{
                    marginRight: '1em',
                    background: '#fff',
                    color: '#545454',
                    fontWeight: 'bold'
                  }}
                  src={avatar}
                  shape="square">
                  {id}
                </Avatar>
              </div>
              <div className="pb-3 flex items-center">
                {!!verified && (
                  <span>
                    <VerifiedIcon />
                  </span>
                )}
                <Link
                  to={
                    '/organization/' +
                    id +
                    '/home'
                  }
                  style={{
                    textOverflow:
                      'ellipsis'
                  }}
                  className="font-bold whitespace-no-wrap hover:underline cursor-pointer ">
                  {title}
                </Link>
              </div>
              <div
                style={{
                  overflow: 'hidden',
                  maxHeight: '4.3em',
                  minHeight: '4.3em',
                  flex: 1,
                  textOverflow:
                    'ellipsis'
                }}
                className="mb-3">
                <Description type="dataset">
                  {description}
                </Description>
              </div>
              {totals && (
                <div
                  style={{
                    justifySelf:
                      'flex-end',
                    display: 'flex'
                  }}>
                  <div className="flex items-center mr-3">
                    <Badge
                      color="blue"
                      text={
                        <span
                          style={{
                            fontSize:
                              '0.8em',
                            fontWeight:
                              'bold'
                          }}>
                          24,000{' '}
                          {t('posts')}
                        </span>
                      }
                    />
                  </div>
                  <div className="flex items-center">
                    <Badge
                      color="gray"
                      text={
                        <span
                          style={{
                            fontSize:
                              '0.8em',
                            fontWeight:
                              'bold'
                          }}>
                          2,000{' '}
                          {t(
                            'subscribers'
                          )}
                        </span>
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      }}
    </Translate>
  )
)
