import EyeInvisibleOutlined from '@ant-design/icons/lib/icons/EyeInvisibleOutlined'
import EyeTwoTone from '@ant-design/icons/lib/icons/EyeTwoTone'
import {Button, Input} from 'antd'
import {auth_register_Api} from 'apis'
import ApiInfoForm from 'components/form/ApiInfoForm'
import FieldDecorator from 'components/form/FieldDecorator'
import React from 'react'
import * as Yup from 'yup'
import useTranslate from '../../modules/local/useTranslate'
import './RegisterForm.css'

export const Register = ({
                           org_type = undefined, setBack = () => {
  }
                         }) => {
  const t = useTranslate()

  return (
    <ApiInfoForm
      apiInfo={auth_register_Api}
      initialValues={{
        email: '',
        name: '',
        password: '',
        confirm_password: '',
        org_type,
        organization_name: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('invalid email address'))
          .required(t('required field')),
        password: Yup.string()
          .min(8, t('Password must have min 8 characters'))
          .required(t('password required')),
        name: Yup.string().required(t('required field')),
        confirm_password: Yup.string().oneOf(
          [Yup.ref('password'), null],
          t('Password and confirm password does not match')
        ),
      })}>
      {(props) => {
        const {
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          success,
        } = props
        return (
          <div>
            {success ? (
              <div
                className="flex flex-col"
                style={{
                  display: 'inline-block',
                  padding: '16px 0',
                  color: '#161616',
                }}>
                <div>
                  <span>{t('the activation email has been sent to')}</span>
                  <span className="px-1 color-primary font-bold">
                    {values.email}.
                  </span>
                </div>
                <div>
                  {t(
                    'check your email and click on the activation link to complete the registration'
                  )}
                </div>
                <div className="py-3 text-sm italic">
                  {t(
                    'attention: please check your activation email in the spam area'
                  )}
                </div>
              </div>
            ) : (
              <form
                onSubmit={handleSubmit}
                className="space-y-3">
                <div className="flex-1 space-x-1 pb-3 mb-6 border-b ">
                  <span className="font-bold text-color-100 italic">
                      {`${t('note')} : `}
                  </span>
                  <span className="font-medium leading-tight text-red-500 italic">
                    {t('register note description')}
                  </span>
                </div>
                <FieldDecorator
                  isRequired={true}
                  errors={errors.email}
                  labelProps={{ className: undefined }}
                  label={t('account')}>
                  <Input
                    inputMode="email"
                    name="email"
                    value={values.email}
                    onChange={
                      handleChange
                    }
                    onBlur={handleBlur}
                    className={`register-control rounded-md ${
                      errors.email
                        ? 'register-control'
                        : ''
                    }`}
                    type="text"
                    placeholder={t(
                      'email account'
                    )}
                  />
                </FieldDecorator>
                <FieldDecorator
                  isRequired={true}
                  errors={
                    errors.password
                  }>
                  <Input.Password
                    type="password"
                    name="password"
                    className="rounded-md"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('password')}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                    }
                  />
                </FieldDecorator>
                <FieldDecorator
                  className="law-field errors"
                  errors={errors.confirm_password}>
                  <Input.Password
                    type="password"
                    name="confirm_password"
                    className="rounded-md"
                    value={values.confirm_password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('retype password')}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                    }
                  />
                </FieldDecorator>
                {!!org_type && !isNaN(org_type) ? (
                  <FieldDecorator
                    isRequired={!!org_type && !isNaN(org_type)}
                    className="law-field errors"
                    labelProps={{ className: undefined }}
                    label={t(
                      !!org_type && !isNaN(org_type)
                        ? 'provider name'
                        : undefined
                    )}
                    errors={errors.name}>
                    <Input
                      name="name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="register-control rounded-md"
                      placeholder={t(!!org_type ? 'provider name' : 'fullname')}
                    />
                  </FieldDecorator>
                ) : (
                  <FieldDecorator
                    isRequired={!!org_type && !isNaN(org_type)}
                    className="law-field errors"
                    errors={errors.name}>
                    <Input
                      name="name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="register-control rounded-md"
                      placeholder={t(
                        !!org_type && !isNaN(org_type)
                          ? 'provider name'
                          : 'fullname'
                      )}
                    />
                  </FieldDecorator>
                )}

                <div className="flex gap-3 pt-6">
                  <Button
                    onClick={setBack}
                    className="button-rounded-md no-border">
                    {t('back')}
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    className="w-full flex-1 button-rounded-md">
                    {isSubmitting ? t('waiting') : t('register')}
                  </Button>
                </div>
              </form>
            )}
          </div>
        )
      }}
    </ApiInfoForm>
  )
}

export default Register
