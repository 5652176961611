import {ROOT_URL} from 'envs/_current/config'
import {AppEntities} from '../enums'

export const ImageTypes = Object.freeze({
  ICON: 'icon',
  BANNER: 'banner',
  AVATAR: 'avatar',
})

export const createDefaultImage = ({type}) => {
  const prefix = `${ROOT_URL}/images/default`
  let imagePath = 'no-image.jpg'
  switch (type) {
    case AppEntities.USER:
      imagePath = 'no-image.jpg'
      break
    case AppEntities.ORGANIZATION:
      imagePath = 'no-image.jpg'
      break
    default:
      break
  }
  return [prefix, imagePath].join('/')
}
