import { Button } from 'antd'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import useTranslate from 'modules/local/useTranslate'
import React, { useContext } from 'react'
import { createOptionalLoginRequired } from 'routes/createRequiredLogin'
import { Null, renderIf } from 'views/Shared'
import { EventActionTypes } from '..'
import EventContext from '../EventContext'
import DrawMenu from "../../../../components/drawer/DrawMenu";

const EventSubscription = ({ alwaysVisible = true }) => {
  const t = useTranslate()

  const { isSm } = useContext(LayoutContext)

  const { event, handleAction = Null } = useContext(EventContext)

  const { is_expired, registration_status, registration_enabled } = event ?? {}

  const disabled = Boolean(
    is_expired || registration_status || !registration_enabled
  )

  const action_button = createOptionalLoginRequired(() => {
    if (disabled) return
    handleAction(EventActionTypes.SHOW_SUBSCRIBE_MODAL)
  })(({ callback }) => (
    <Button
      disabled={disabled}
      size={isSm ? 'default' : 'large'}
      onClick={
        (disabled && isSm) ?
            undefined :
            callback
      }
      className="button-rounded-lg no-border bg-primary hover:bg-primary-500 text-white hover:text-white leading-tight px-4">
      {t('sign up to participate')}
    </Button>
  ))

  return renderIf(
      !disabled,
      <div
          className="p-2 md:p-4 mx-2 md:mx-auto flex items-center gap-2 md:container background rounded-lg sticky z-10 border border-color-50 shadow-items"
          style={{ bottom: isSm ? 60 : 15 }}>
        <span className="flex-1 font-semibold text-sm md:text-base text-color-100 italic capitalize">
          {t('sign up for this event')}
        </span>
        <div className="my-auto">{action_button}</div>
      </div>
  )
}

export default EventSubscription
