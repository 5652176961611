import {Checkbox, Col, Input, InputNumber, Row, Select} from 'antd'
import {image_upload_Api} from 'apis'
import FieldDecorator from 'components/form/FieldDecorator'
import FormActionBar from 'components/form/FormActionBar'
import {createValue} from 'components/form/utils'
import Null from 'components/NullComponent'
import {FB_ID} from 'envs/_current/config'
import {useFormikContext} from 'formik'
import _ from 'lodash'
import {callApi} from 'modules/asyncCache/callApi'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import ReactPlayer from 'react-player'
import {ImagesAndFiles} from 'views/Market/components/Product'
import {ProductContext} from "./ProductContainer";
import {formatterNumber} from "../../../helpers/formatterNumber";
import {SelectCategories} from "./SelectCategories";
import {OrganizationContext} from "../../Organization/OrganizationContext";
import {parseNumber} from "../../../helpers/parseNumber";
import {emptyObject} from "../../../helpers/emptyObjects";

export const ProductBasicCreateForm = ({
                                         submitText
                                       }) => {
  const t = useTranslate();
  const {organization} = useContext(OrganizationContext);
  const {
    values,
    errors,
    handleChange,
    ...rest
  } = useFormikContext();
  const appConfig = useAppConfig()
  const {
    product_units = [],
    product_price_units = [],
    product_categories = [],
  } = appConfig ||
  {
    product_units: [],
    product_price_units: [],
    product_categories: []
  };

  let _product_categories = product_categories
    .filter(e => !!e && _.get(e, _.get(organization, 'org_type'), 1) === 1);


  return (
    <div className="flex flex-col gap-3">
      <FieldDecorator
        errors={errors.title}
        name="title"
        labelProps={{ className: 'capitalize' }}
        label={t('product name')}>
        <Input
          name="title"
          value={values.title}
          maxLength={120}
          minLength={3}
          placeholder={t('title')}
          onChange={handleChange}
        />
      </FieldDecorator>

      <FieldDecorator
        name="categories"
        labelProps={{ className: 'capitalize' }}
        label={t('categories')}>
        <SelectCategories
            type="product"
            categoryOptions={[..._product_categories]}
            selected={values.categories}
            onChange={value => handleChange(createValue('categories', value))}
        />
      </FieldDecorator>

      <FieldDecorator
        name="description"
        labelProps={{ className: 'capitalize' }}
        label={t('brief description')}>
        <Input.TextArea
          rows={3}
          name="description"
          value={
            values.description
          }
          maxLength={5000}
          onChange={handleChange}
          allowClear
        />
      </FieldDecorator>

      <FieldDecorator
        errors={errors.photos}
        name="photos"
        labelProps={{ className: 'capitalize' }}
        label={t('photos')}>
        <ImagesAndFiles
          files={(
            values.photos ||
            []
          ).map(item => {
            const url = _.get(
              item,
              'data.url'
            )
            if (url) return url
            return item
          })}
          onAdd={files =>
            handleChange(
              createValue('photos', [
                ...(values.photos ||
                  []),
                ...[...files].map(
                  file => {
                    const fileWrap = {
                      loading: true
                    }
                    const promise = callApi(
                      {
                        apiInfo: image_upload_Api,
                        values: {
                          'photos[]': file
                        }
                      }
                    )

                    fileWrap.promise = promise
                    const read = (
                      cb = Null
                    ) => {
                      return (
                        cb(
                          fileWrap.data
                        ) ||
                        fileWrap.promise
                          .then(
                            res => {
                              fileWrap.data = {
                                id: _.get(
                                  res,
                                  'data.ids[0]'
                                ),
                                url: _.get(
                                  res,
                                  'data.urls[0]'
                                )
                              }

                              cb(
                                res.data
                              )
                            }
                          )
                          .catch(
                            error => {
                              fileWrap.error = error
                            }
                          )
                          .finally(
                            () => {
                              fileWrap.loading = false
                            }
                          )
                      )
                    }
                    fileWrap.read = read
                    return fileWrap
                  }
                )
              ])
            )
          }
          onItemClick={(
            index,
            type
          ) => {
            let newPhotos = [
              ...values.photos
            ]
            switch (type) {
              case 'up':
                if (
                  newPhotos[index - 1]
                ) {
                  newPhotos[
                  index - 1
                    ] =
                    values.photos[
                      index
                      ]
                  newPhotos[index] =
                    values.photos[
                    index - 1
                      ]
                  handleChange(
                    createValue(
                      'photos',
                      newPhotos
                    )
                  )
                }

                break
              case 'down':
                if (
                  newPhotos[index + 1]
                ) {
                  newPhotos[
                  index + 1
                    ] =
                    values.photos[
                      index
                      ]
                  newPhotos[index] =
                    values.photos[
                    index + 1
                      ]
                  handleChange(
                    createValue(
                      'photos',
                      newPhotos
                    )
                  )
                }
                break
              case 'main':
                newPhotos.splice(
                  index,
                  1
                )
                newPhotos.unshift(
                  values.photos[index]
                )
                handleChange(
                  createValue(
                    'photos',
                    newPhotos
                  )
                )
                break
              case 'delete':
                newPhotos.splice(
                  index,
                  1
                )
                handleChange(
                  createValue(
                    'photos',
                    newPhotos
                  )
                )
                break

              default:
                break
            }
          }}
          onAddVideo={video_url => {
            handleChange(
              createValue(
                'video_url',
                video_url
              )
            )
          }}
          onAddPDF={pdf_path => {
            handleChange(
              createValue(
                'pdf_path',
                pdf_path[0]
              )
            )
          }}
        />
      </FieldDecorator>

      <FieldDecorator
        name="price"
        labelProps={{ className: 'capitalize' }}
        label={t('price')}>
        <Input.Group compact className="w-full flex flex-center custom-input-group">
          <InputNumber
            name="price"
            style={{width: 'calc(100% - 240px)'}}
            formatter={formatterNumber}
            parser={parseNumber}
            value={values.price}
            onChange={value =>
              handleChange(
                createValue(
                  'price',
                  value
                )
              )
            }
          />
          <Select
              style={{width: 120, borderRightWidth: 0}}
              showSearch
              className="flex-1"
              placeholder={t('price unit')}
              defaultValue={_.get(values, 'price_unit_id')}
              options={product_price_units.map(
                  ({
                       id: value,
                       name: label
                   }) => ({
                      value,
                      label
                  })
              )}
              onChange={value => handleChange(createValue('price_unit_id', value))}
          />
          <Select
              style={{width: 120, borderRightWidth: 0}}
              showSearch
              className="flex-1"
              placeholder={t('unit')}
              defaultValue={_.get(values, 'unit_id')}
              options={product_units.map(
                  ({ id: value, name: label }) => ({
                      value,
                      label
                  })
              )}
              onChange={value => handleChange(createValue('unit_id', value))}
          />
        </Input.Group>
      </FieldDecorator>

      <FormActionBar
        className="w-full"
        submitText={submitText}
      />
    </div>
  )
}

export const ProductBasicForm = ({
    submitText
}) => {
  const t = useTranslate()
  const {
    values,
    errors,
    handleChange,
    ...rest
  } = useFormikContext()
  const appConfig = useAppConfig()
  const {
    product_units = [],
    product_price_units = [],
  } = appConfig ||
  {
    product_units: [],
    product_price_units: []
  }
  const product = useContext(ProductContext) || emptyObject;

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-2">
          <FieldDecorator errors={errors.photos} name="photos">
              <ImagesAndFiles
                  files={(values.photos || []).map(item => {
                      const url = _.get(item, 'data.url')
                      if (url) return url
                      return item
                  })}
                  onAdd={files =>
                      handleChange(
                          createValue('photos', [
                              ...(values.photos || []),
                              ...[...files].map( file => {
                                  const fileWrap = {
                                      loading: true
                                  }
                                  const promise = callApi(
                                      {
                                          apiInfo: image_upload_Api,
                                          values: {
                                              'photos[]': file
                                          }
                                      }
                                  )
                                  fileWrap.promise = promise
                                  const read = (cb = Null) => {
                                      return (cb(fileWrap.data) ||
                                          fileWrap.promise
                                              .then(
                                                  res => {
                                                      fileWrap.data = {
                                                          id: _.get(res, 'data.ids[0]'),
                                                          url: _.get(res, 'data.urls[0]')
                                                      }
                                                      cb(res.data)
                                                  }
                                              )
                                              .catch(error => {fileWrap.error = error})
                                              .finally(() => {fileWrap.loading = false})
                                      )
                                  }
                                  fileWrap.read = read
                                  return fileWrap
                              })
                          ])
                      )
                  }
                  onItemClick={( index, type ) => {
                      let newPhotos = [...values.photos]
                      switch (type) {
                          case 'up':
                              if (
                                  newPhotos[index - 1]
                              ) {
                                  newPhotos[
                                  index - 1
                                      ] =
                                      values.photos[
                                          index
                                          ]
                                  newPhotos[index] =
                                      values.photos[
                                      index - 1
                                          ]
                                  handleChange(
                                      createValue(
                                          'photos',
                                          newPhotos
                                      )
                                  )
                              }

                              break
                          case 'down':
                              if (
                                  newPhotos[index + 1]
                              ) {
                                  newPhotos[
                                  index + 1
                                      ] =
                                      values.photos[
                                          index
                                          ]
                                  newPhotos[index] =
                                      values.photos[
                                      index + 1
                                          ]
                                  handleChange(
                                      createValue(
                                          'photos',
                                          newPhotos
                                      )
                                  )
                              }
                              break
                          case 'main':
                              newPhotos.splice(
                                  index,
                                  1
                              )
                              newPhotos.unshift(
                                  values.photos[index]
                              )
                              handleChange(
                                  createValue(
                                      'photos',
                                      newPhotos
                                  )
                              )
                              break
                          case 'delete':
                              newPhotos.splice(
                                  index,
                                  1
                              )
                              handleChange(
                                  createValue(
                                      'photos',
                                      newPhotos
                                  )
                              )
                              break
                          default:
                              break
                      }
                  }}
                  onAddVideo={video_url => {
                      handleChange(
                          createValue(
                              'video_url',
                              video_url
                          )
                      )
                  }}
                  onAddPDF={pdf_path => {
                      handleChange(
                          createValue(
                              'pdf_path',
                              pdf_path[0]
                          )
                      )
                  }}
              />
          </FieldDecorator>
          <div className="w-full h-full verticalList">
              <FieldDecorator
                  errors={errors.title}
                  name="title"
                  labelProps={{ className: undefined }}
                  label={t('product name')}>
                  <Input
                      name="title"
                      defaultValue={values.title}
                      maxLength={120}
                      minLength={3}
                      placeholder={t('title')}
                      onChange={handleChange}
                  />
              </FieldDecorator>

              <FieldDecorator
                  name="product_type_codes"
                  labelProps={{ className: undefined }}
                  label={t('type of product use for sales / purchases')}>
                  <Select
                      showSearch
                      mode="multiple"
                      allowClear
                      className="flex-1 w-full"
                      placeholder={t('sales / purchases')}
                      defaultValue={_.get(values, 'product_type_codes')}
                      options={['sales', 'purchases'].map(e => ({
                          value: e,
                          label: t(e)
                      }))}
                      onChange={value => {handleChange(createValue('product_type_codes', value))}}
                  />
              </FieldDecorator>

              <FieldDecorator
                  name="description"
                  labelProps={{ className: undefined }}
                  label={t('brief description')}>
                  <Input.TextArea
                      rows={4}
                      name="description"
                      defaultValue={
                          values.description
                      }
                      maxLength={5000}
                      onChange={handleChange}
                      allowClear
                  />
              </FieldDecorator>

              <FieldDecorator
                  name="public"
                  labelProps={{ className: undefined }}
                  label={t('products can be searched')}>
                  <Checkbox
                      name={"public"}
                      checked={!!values.public}
                      onChange={(e) => {
                          handleChange(
                              createValue(
                                  'public',
                                  e.target.checked ? 1 : 0
                              )
                          )
                      }}>
                      <span className="font-medium">{`${t('public')} ?`}</span>
                  </Checkbox>
              </FieldDecorator>

              <FieldDecorator
                  name="price"
                  labelProps={{ className: undefined }}
                  label={t('price')}>
                  <Input.Group compact className="flex items-center gap-2 custom-input-group">
                      <InputNumber
                          name="price"
                          style={{width: 'calc(100% - 240px)'}}
                          formatter={formatterNumber}
                          parser={parseNumber}
                          defaultValue={values.price}
                          onChange={value => handleChange(createValue('price', value))}
                      />
                      <Select
                          style={{ width: 120, borderRightWidth: 0 }}
                          showSearch
                          className="flex-1"
                          placeholder={t('price unit')}
                          defaultValue={_.get(values, 'price_unit_id')}
                          options={product_price_units.map(
                              ({ id: value, name: label }) => ({
                                  value,
                                  label
                              })
                          )}
                          onChange={value =>
                              handleChange(
                                  createValue(
                                      'price_unit_id',
                                      value
                                  )
                              )
                          }
                      />
                      <Select
                          style={{ width: 120, borderRightWidth: 0 }}
                          showSearch
                          className="flex-1"
                          placeholder={t('unit')}
                          defaultValue={_.get(values, 'unit_id')}
                          options={product_units.map(
                              ({id: value, name: label}) => ({value, label})
                          )}
                          onChange={value =>
                              handleChange(
                                  createValue(
                                      'unit_id',
                                      value
                                  )
                              )
                          }
                      />
                  </Input.Group>
              </FieldDecorator>

              <div className="grid grid-cols-2 gap-2">
                  <FieldDecorator
                      name="hidden_price">
                      <Checkbox
                          name={"hidden_price"}
                          checked={!!values.hidden_price}
                          onChange={(e) => {
                              handleChange(
                                  createValue(
                                      'hidden_price',
                                      e.target.checked ? 1 : 0
                                  )
                              )
                          }}>
                          <span className="font-medium">{`${t('hidden price')} ?`}</span>
                      </Checkbox>
                  </FieldDecorator>
                  <FieldDecorator
                      name="wholesale">
                      <Checkbox
                          name={"wholesale"}
                          checked={!!values.wholesale}
                          onChange={(e) => {
                              handleChange(
                                  createValue(
                                      'wholesale',
                                      e.target.checked ? 1 : 0
                                  )
                              )
                          }}>
                          <span className="font-medium">{`${t('wholesale')} ?`}</span>
                      </Checkbox>
                  </FieldDecorator>
              </div>

              <FieldDecorator
                  name="video url"
                  errors={errors.video_url}
                  labelProps={{ className: undefined }}
                  label={t('video url')}>
                  <Input
                      rows={4}
                      inputMode="url"
                      name="video_url"
                      defaultValue={
                          values.video_url
                      }
                      onChange={handleChange}
                  />
                  {values.video_url && (
                      <ReactPlayer
                          className=" mt-2 background-200 border border-gray-300 shadow"
                          style={{
                              fontSize: '1em'
                          }}
                          width="100%"
                          height={400}
                          url={values.video_url}
                          config={{
                              youtube: {
                                  playerVars: {
                                      controls: 1,
                                      // autoplay: 1,
                                      showinfo: 1
                                  }
                              },
                              facebook: {
                                  appId: FB_ID
                              }
                          }}
                      />
                  )}
              </FieldDecorator>

              <FieldDecorator
                  name="contact_seller_id"
                  labelProps={{ className: undefined }}
                  label={t('seller')}>
                  <Select
                      name="contact_seller_id"
                      showSearch
                      virtual={false}
                      optionFilterProp="label"
                      placeholder={t('please select')}
                      defaultValue={values.contact_seller_id}
                      style={{width: '100%'}}
                      onChange={(value) => {handleChange(createValue('contact_seller_id', value))}}>
                      {!!_.get(product, 'owner.contacts.length') && _.get(product, 'owner.contacts', [])
                          .filter(el => _.get(el, 'contact_member.user.id') !== _.get(product, 'contact_user.user_id'))
                          .map((el, key) => {
                                  return (
                                      <Select.Option
                                          key={_.get(el, 'contact_member.user.id')}
                                          value={_.get(el, 'contact_member.user.id')}
                                          label={_.get(el, 'contact_member.user.name')}>
                                          {_.get(el, 'contact_member.user.name')}
                                      </Select.Option>
                                  )
                              }
                      )}
                      {!!_.get(product, 'contact_user') && (
                              <Select.Option
                                  key={_.get(product, 'contact_user.user_id')}
                                  value={_.get(product, 'contact_user.user_id')}
                                  label={_.get(product, 'contact_user.name')}>
                                  {_.get(product, 'contact_user.name')}
                              </Select.Option>
                      )}
                  </Select>
              </FieldDecorator>
          </div>
      </div>
      <FormActionBar
        className="w-full mt-4"
        submitText={submitText}
      />
    </div>
  )
}

function ProductBasicInfo() {
  return (
    <ProductBasicForm/>
  )
}

export default ProductBasicInfo
