import {
  CameraFilled,
  CheckOutlined,
  CrownOutlined,
  DeleteOutlined,
  DownOutlined,
  LoadingOutlined,
  MoreOutlined,
  UpOutlined
} from '@ant-design/icons'
import {Alert, Button, Col, Dropdown, Menu, Popconfirm, Row, Space, Tag, Tooltip} from 'antd'
import {create_product_publish_Api} from 'apis'
import {baseModel, productModel} from 'apis/model'
import classNames from 'classnames'
import ImagesLightBox from 'components/ImagesLightBox/ImagesLightBox'
import {UseHook} from 'components/UseReducer'
import logFunc from 'helpers/logFunc'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useEffect, useState} from 'react'
import {useToggle} from 'react-use'
import formatCurrency from 'views/Billing/components/formatCurrency'
import {Link} from "react-router-dom";
import ProductContainer, {ProductContext} from "../ProductContainer";
import {dataURItoBlob, getBase64} from "../../../../components/form/fields/ImageUpload";
import {ProductSettingContainer} from "../Product";
import Toggle from "../../../../components/Toggle";
import {FullSideModalLayout} from "../../../../components/Modal/SideModalLayout";
import Translate from "../../../../modules/local/Translate";
import {emptyArray} from "../../../../helpers/emptyObjects";
import {BsCameraFill, BsCaretDownFill, BsCaretUpFill, BsCheck2, BsCheckLg, BsTrash3} from "react-icons/bs";
import {GoTrash} from "react-icons/go";
import Pure from "../../../../components/Pure";

export function Product({
                          item: product,
                          handleAction,
                          deactive
                        }) {
  const t = useTranslate()
  const overlay = (
    <Menu onClick={handleAction}>
      <Menu.Item key={'preview'}>
        {t('view product page')}
      </Menu.Item>
      {deactive ? (
        <Menu.Item key="active">
          {t('active')}
        </Menu.Item>
      ) : (
        <Menu.Item key="deactive">
          {t('deactive')}
        </Menu.Item>
      )}
    </Menu>
  )
  const showrequires = !_.isEmpty(
    product.requireStrings
  )

  return (
    <ProductContainer item={product}>
      <div>
        <Row className="relative border border-transparent hover:border-gray-300">
          <Col xs={24} md={8}>
            <ImagesAndFiles
              readyOnly
              files={(
                product.photos ||
                []
              ).map(item => {
                const url = _.get(
                  item,
                  'path'
                )
                if (url) return url
                return item
              })}
              className="h-full"
            />
          </Col>
          <Col xs={24} md={16}>
            <Detail/>
          </Col>
          <Col
            xs={24}
            md={24}
            className="flex flex-wrap">
            {[
              {
                label: t('sku'),
                value: product.sku
              },
              {
                label: t('trademark'),
                value: product.trademark
              },
              {
                label: t('product line'),
                value: product.product_line
              }
            ].map(
              ({label, value}) => {
                return (
                  <div
                    key={label}
                    className=" py-1 px-3 flex flex-col border border-white background-100 w-1/2 md:w-auto">
                    {label}:{' '}
                    <span className="font-bold whitespace-pre-line">
                      {value ||
                      '--------'}
                    </span>
                  </div>
                )
              }
            )}
          </Col>
          <Action
            overlay={overlay}
            handleAction={handleAction}
            product={product}
          />
        </Row>
        {product.is_ready && (
          <Alert
            type="success"
            message={
              <div className="flex justify-between space-x-3 1">
                <div>
                  •{' '}
                  {t(
                    'your product has been declared complete, you can put it on the store'
                  )}
                </div>
                {'' + product.status !==
                '1' && (
                  <UseHook
                    hook={
                      useDispatchAsyncActionWithNotify
                    }>
                    {([
                        {
                          success,
                          isLoading
                        },
                        dispatch
                      ]) => (
                      <Popconfirm
                        title={`${t('publish')} ?`}
                        onConfirm={() => {
                          dispatch(
                            createAsyncAction(
                              {
                                apiInfo: create_product_publish_Api(),
                                query: {
                                  ':id':
                                  product.id
                                }
                              }
                            )
                          )
                        }}
                        okText="OK"
                        cancelText="Cancel">
                        <Button
                          loading={
                            isLoading
                          }
                          type="primary">
                          {t('publish')}
                        </Button>
                      </Popconfirm>
                    )}
                  </UseHook>
                )}
              </div>
            }
          />
        )}
        {showrequires && (
          <Alert
            type="error"
            message={
              <div className="flex items-start space-x-3">
                <ul className="text-red-800 flex-1">
                  {Object.values(
                    product.requireStrings
                  ).map(text => (
                    <li key={text}>
                      • {text}
                    </li>
                  ))}
                </ul>
                {'' + product.status !==
                '1' && (
                  <Button
                    disabled
                    type="primary">
                    {t('publish')}
                  </Button>
                )}
              </div>
            }
          />
        )}
      </div>
    </ProductContainer>
  )
}

function Detail() {
  const t = useTranslate()
  const product =
    useContext(ProductContext) ||
    {};
  return (
    <div
      style={{
        height: '100%'
      }}
      className=" w-full background-100 border border-white ">
      <div className="w-full p-3 space-y-1">
        <Space
          direction="horizontal"
          size="large">
          <Link to={productModel.getLinkToDetail(
            product
          )}>
            <h3 className="m-0 text-lg font-bold">
              {productModel.getTitle(
                product
              )}
            </h3>
          </Link>
          {
            !!!product.hidden_price && (
              Number(product.price) > 0 ? (
                <div className="rounded-md background-200 px-2">
                  <b>
                    {formatCurrency(
                      product.price
                    )}
                  </b>
                  /{_.get(product, 'unit.name', t('unit'))}
                </div>
              ) : (
                  <span className="font-medium text-sm text-blue-500 italic">
                      {`* ${t('no price tag')}`}
                    </span>
              )
            )
          }
        </Space>
        <Pure input={[product]}>
          {[
            (Number(product.status) === 1 && Number(product.active) === 1)
            && (
              <Tag
                // key={product.id + "_1"}
                icon={<CheckOutlined/>}
                color="success">
                {t('published')}
              </Tag>
            ),
            (Number(product.active) === 0)
            && (
              <Tooltip
                // key={product.id + "_2"}
                title={t(
                  `This product removed from your Catalog and won't be visible to buyers`
                )}>
                <Tag color="error">
                  {t('inactive')}
                </Tag>
              </Tooltip>
            )
          ].map((children, i) =>
            !!children && (
              <div key={i}>{children}</div>
            ))
          }
        </Pure>

        <div>
          {productModel.get(
            product,
            'description'
          )}
        </div>
      </div>
      <Row className="border-t border-white  ">
        <Col
          className="p-3 space-y-2"
          xs={24}
          md={10}>
          <div className="font-bold text-color-300">
            {t('category')}
          </div>
          <div className="verticalList space-y-2 ">
            {productModel
              .get(
                product,
                'categories', []
              )
              .map((cate,i) => (
                <Tag key={i}
                  className="mt-2"
                  key={baseModel.get(
                    cate,
                    'id'
                  )}>
                  {baseModel.get(
                    cate,
                    'name'
                  )}
                </Tag>
              ))}
          </div>
        </Col>
        <Col
          xs={24}
          md={14}
          className="p-3">
          <Space
            direction="vertical"
            className="w-full">
            <div className="font-bold text-color-300">
              {t(
                'Specification/Additional Details'
              )}
            </div>
            <div className="w-full flex flex-col">
              {productModel
                .get(
                  product,
                  'attributes',
                  []
                )
                .map(
                  (item, i) =>
                    (i < 4 || null) && (
                      <Row
                        key={i}
                        gutter={8}
                        className="border-t border-gray-300 p-1">
                        <Col
                          xs={8}
                          md={8}
                          className="truncate">
                          {baseModel.get(
                            item,
                            'attribute_name'
                          )}
                        </Col>
                        <Col
                          xs={16}
                          md={16}>
                          {baseModel.get(
                            item,
                            'attribute_value'
                          )}
                        </Col>
                      </Row>
                    )
                )}
              {productModel
                .get(
                  product,
                  'specifications',
                  []
                )
                .map(
                  (item, i) =>
                    (i < 4 || null) && (
                      <Row key={i}
                           gutter={8}
                           className="border-t border-gray-300 p-1">
                        <Col
                          xs={8}
                          md={8}
                          className="truncate">
                          {baseModel.get(
                            item,
                            'attribute_name'
                          )}
                        </Col>
                        <Col
                          xs={16}
                          md={16}>
                          {baseModel.get(
                            item,
                            'attribute_value'
                          )}
                        </Col>
                      </Row>
                    )
                )}
            </div>
          </Space>
        </Col>
      </Row>
    </div>
  )
}

function Action({
                  overlay,
                  handleAction,
                  product
                }) {
  const t = useTranslate()
  if (!overlay) return null
  return (
    <div className="absolute right-0 top-0 flex justify-center items-center cursor-pointer">
      {
        !!product.is_primary ? (
          <label title={t('set as no primary')}
                 onClick={() =>
                   handleAction({key: 'is_not_primary'})
                 }
                 className="w-10 h-10  background-200 border border-white hover:shadow flex justify-center items-center text-orange-600">
            <CrownOutlined/>
          </label>
        ) : (
          <label title={t('set as primary')}
                 onClick={() =>
                   handleAction({key: 'is_primary'})
                 }
                 className="w-10 h-10  background-200 border border-white hover:shadow flex justify-center items-center">
            <CrownOutlined/>
          </label>
        )
      }
      <label title={t('go down')}
             onClick={() =>
               handleAction({key: 'sort_down'})
             }
             className="w-10 h-10  background-200 border border-white hover:shadow flex justify-center items-center">
        <DownOutlined/>
      </label>
      <label title={t('go up')}
             onClick={() =>
               handleAction({key: 'sort_up'})
             }
             className="w-10 h-10  background-200 border border-white hover:shadow flex justify-center items-center">
        <UpOutlined/>
      </label>
      <Toggle>
        {
          (
            isToggleProductEdit,
            toggleProductEdit
          ) => (
            <div className="w-full">
              <div title={t('edit')}
                   onClick={toggleProductEdit}
                   className=" h-10  right-0 px-3 top-0 background-200 border border-white hover:shadow flex justify-center items-center">
                {t('edit')}
              </div>
              {
                isToggleProductEdit && (
                  <FullSideModalLayout
                    right
                    onCancel={toggleProductEdit}
                    maskClosable={false}
                    width={900}
                    title={
                      <div className="font-bold">
                        {_.get(product, 'title')}
                      </div>
                    }
                    destroyOnClose
                    visible={isToggleProductEdit}
                    footer={null}
                  >
                    <ProductSettingContainer
                      id={
                        _.get(product, 'idname')
                      }
                    />
                  </FullSideModalLayout>
                )
              }
            </div>
          )
        }
      </Toggle>

      <Dropdown
        placement="bottomRight"
        overlay={overlay}>
        <div className="w-10 h-10  background-200 border border-white hover:shadow flex justify-center items-center">
          <MoreOutlined/>
        </div>
      </Dropdown>
    </div>
  )
}

const ImagePreview = ({
    className,
    file,
    holder = (
        <Translate>
            {t => (
                <div className="flex flex-col items-center gap-1">
                    <BsCameraFill
                        size={15}
                        className="text-color-500"
                    />
                    <span className="font-medium text-2xs text-color-300 leading-none">
                        {t('add')}
                    </span>
                </div>
            )}
        </Translate>
    )
}) => {
  const [url, setUrl] = useState(file)
  useEffect(() => {
    if (file && file.read) {
      file.read(
        logFunc(data =>
          setUrl(_.get(data, 'urls[0]'))
        )
      )
    } else {
      if (typeof file === 'string')
        setUrl(file)
    }
  }, [file])

  if (!file) {
    return holder
  }
  if (file && !url) {
    return (
      <LoadingOutlined spin="true"/>
    )
  }
  return (
    <img
      src={url}
      className={classNames("w-full h-full object-cover", className)}
    />
  )
}

function ImagesAndFiles({
                          files = [],
                          className,
                          onChange,
                          onAdd,
                          onItemClick,
                          readyOnly
                        }) {
  const [id, setId] = useState(
    '' + Date.now()
  )
  const [mainId] = useState(
    'main-' + id
  )
  const t = useTranslate()
  const handleAdd = async (files) => {
    if (onAdd) {
      console.log({files})
      let loadedFiles = [];
      for (let i = 0; i < files.length; i++) {
        console.log({file: files[i]})
        const file = await getBase64(files[i], 1024, 1024);
        const image = dataURItoBlob(file);
        loadedFiles.push(image)
      }
      console.log({loadedFiles})
      onAdd(loadedFiles)
    }
  }
  const [isToggle, toggle] = useToggle()
  return (
      <div style={{ height: 300 }} className={classNames('flex flex-col', className)}>
          <div className="flex items-center gap-1">
              <div className="flex flex-col">
                  {new Array(6)
                      .fill(undefined)
                      .map((v, i) => {
                          const file = files[i]
                          return (
                              <div key={i} onClick={readyOnly ? () => {toggle()} : null} className="w-full withHover relative">
                                  <label
                                      key={i}
                                      onClick={readyOnly ? () => {toggle()} : null}
                                      htmlFor={!file ? id : undefined}
                                      style={{ width: 50, height: 50 }}
                                      className="w-full flex flex-col flex-center background-100 border border-white hover:border-color-base rounded cursor-pointer">
                                      <ImagePreview file={file} className="rounded"/>
                                  </label>
                                  {file && !readyOnly && (
                                      <div
                                          style={{ left: 50, top: '50%', transform: 'translate(0, -50%)' , whiteSpace: 'nowrap' }}
                                          className="visible flex text-xs shadow-items-xs border background rounded-md absolute z-10">
                                          <div
                                              onClick={() => onItemClick(i, 'up')}
                                              data-effect="ripple"
                                              className="px-3 flex flex-center hover:background-200 cursor-pointer">
                                              <BsCaretUpFill className="text-color-400" />
                                          </div>
                                          <div className="border-l" />
                                          <div
                                              onClick={() =>
                                                  onItemClick(
                                                      i,
                                                      'down'
                                                  )
                                              }
                                              data-effect="ripple"
                                              className="px-3 flex flex-center hover:background-200 cursor-pointer">
                                              <BsCaretDownFill className="text-color-400" />
                                          </div>
                                          <div className="border-l" />
                                          <div
                                              onClick={() =>
                                                  onItemClick(
                                                      i,
                                                      'main'
                                                  )
                                              }
                                              data-effect="ripple"
                                              className="p-2 flex flex-center hover:background-200 cursor-pointer">
                                              <BsCheckLg className="mr-2 text-primary-600" />
                                              <span className="font-medium">{t('set main')}</span>
                                          </div>
                                          <div className="border-l" />
                                          <div
                                              onClick={() =>
                                                  onItemClick(
                                                      i,
                                                      'delete'
                                                  )
                                              }
                                              data-effect="ripple"
                                              className="p-2 flex flex-center hover:background-200 cursor-pointer">
                                              <GoTrash className="mr-2 text-red-500" />
                                              <span className="font-medium">{t('delete')}</span>
                                          </div>
                                      </div>
                                  )}
                              </div>
                          )
                      })}
              </div>
              <div style={{ height: 300 }} className="flex-1">
                  <label
                      onClick={ readyOnly ? () => {toggle()} : null}
                      htmlFor={mainId}
                      className="h-full flex flex-center flex-col background-100 border border-white hover:border-color-base rounded-md cursor-pointer">
                      <ImagePreview
                          className="rounded-md"
                          holder={
                              <div className="flex flex-col items-center gap-1">
                                  <BsCameraFill
                                      size={50}
                                      style={{ color: '#e9e9e9' }}
                                  />
                                  <span className="text-2xs text-color-400 italic leading-tight">
                                    {'( 512 x 512 )'}
                                </span>
                                  <span className="font-bold text-xs text-color-300 leading-none">
                                    {t('add')}
                                </span>
                              </div>
                          }
                          file={files[0]}
                      />
                  </label>
              </div>
          </div>
          {readyOnly ? null : (
              <>
                  <input
                      hidden
                      multiple
                      onChange={e => {
                          console.log(
                              'images',
                              e.target.files
                          )
                          handleAdd(e.target.files)
                      }}
                      id={id}
                      name={id}
                      type="file"
                      accept="image/*"
                  />
                  <input
                      hidden
                      id={mainId}
                      name={mainId}
                      onChange={e => {
                          console.log(
                              'images',
                              e.target.files
                          )
                          handleAdd(e.target.files)
                      }}
                      type="file"
                      accept="image/*"
                  />
              </>
          )}
          {isToggle && (
              <ImagesLightBox
                  images={files}
                  onCloseRequest={toggle}
              />
          )}
      </div>
  )
}

export {
  Detail,
  Action,
  ImagesAndFiles
}

