import {Col, Layout, Row} from 'antd'
import {Content} from 'antd/lib/layout/layout'
import classNames from 'classnames'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {Pure} from 'components/Pure'
import _ from 'lodash'
import React, {createContext, useCallback, useContext, useMemo} from 'react'
import {nest, withProps} from 'recompose'
import {DelayRender} from 'views/Discovery/DelayRender'
import {fullGrid, oneThirdGrid} from './antdConfigs'
import {BoxPlaceholder} from './placeholders'
import {Null, renderSelf} from './typedefs'
import {withPureComp} from './wrappers'

export const TemplateContext = createContext({})

export const TemplateProvider = ({children, drawer = false, ...props}) => {
  const sidersWidth = 360
  return (
    <TemplateContext.Provider
      value={{
        drawer,
        sidersWidth,
        leftSiderWidth: sidersWidth,
        rightSiderWidth: sidersWidth,
        ...props,
      }}>
      {children}
    </TemplateContext.Provider>
  )
}

const LayoutBuilder = ({
  value,
  builder,
  orElse = Null,
  children,
  ...props
}) => {
  const { isLg, isSm } = useContext(LayoutContext)

  const boolVal = _.isFunction(value) ? value({ isLg, isSm }) : !!value

  if (builder) {
    const render = !!boolVal ? builder : orElse
    return render({
      ...props,
      isLg,
      isSm,
    })
  }
  return children
}

export const WidgetPositions = Object.freeze({
  MID: 'mid',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  BOTTOM: 'bottom',
})

export const TemplateTypes = Object.freeze({
  DEFAULT: 'default',
  LEFT_SIDER: 'left-sider',
  RIGHT_SIDER: 'right-sider',
  BOTH_SIDER: 'both-sider',
  CUSTOM: 'custom',
})

export const withRightSider = (Component) => ({
  [WidgetPositions.RIGHT]: withPureComp(Component),
})

export const withLeftSider = (Component) => ({
  [WidgetPositions.LEFT]: withPureComp(Component),
})

export const withSiders = (Left) => (Right) => ({
  [WidgetPositions.LEFT]: withLeftSider(Left),
  [WidgetPositions.RIGHT]: withRightSider(Right),
})

const renderFragment = (item, index) => (
  <React.Fragment key={index}>{item}</React.Fragment>
)

const MainLayout = ({
  groups = [],
  components = [],
  customGrid = [],
  dependencies = [],
  withLayout = renderSelf,
}) => {
  const { isSm } = useContext(LayoutContext)

  const defaultGrids = {
    lg: oneThirdGrid,
    span: fullGrid,
  }

  const columns = Object.keys(components)
  const gridOf = useCallback(
    (index) => {
      return _.get(customGrid, `[${index}]`, {})
    },
    [customGrid]
  )

  const defaultItem = () => <BoxPlaceholder />

  const items = useMemo(() => {
    if (_.isEmpty(groups)) {
      return columns.map((name) => (
        <React.Fragment key={name}>
          {_.get(components, name, defaultItem)}
        </React.Fragment>
      ))
    } else {
      return columns.map((name) => {
        const selectedItems = _.get(components, name, [])
        return selectedItems.map((name) => {
          const { component: Component, render } = _.get(groups, name, {
            render: defaultItem,
          })
          return (
            <React.Fragment key={name}>
              {Component ? <Component /> : render()}
            </React.Fragment>
          )
        })
      })
    }
  }, [components, columns, groups])

  const getPadding = useCallback(
    (index, lastIndex, value = '0.75rem') => {
      if (isSm) {
        return null
      }
      // Anh fix thành 1 cái, để nó khỏi bị nhỏ đi cột ở giữa do bị padding ra 2 bên.
      if (index === 0) {
        return {
          paddingLeft: 0,
          paddingRight: value,
        }
      } else if (columns.length > 2 && index === lastIndex) {
        return {
          paddingLeft: value,
          paddingRight: 0,
        }
      } else {
        return {
          paddingLeft: 0,
          paddingRight: 0,
        }
      }
    },
    [isSm]
  )

  const content = useMemo(
    () => (
      <Row
        style={{
          marginLeft: 0,
          marginRight: 0,
        }}
        className={classNames('px-0', isSm && 'space-y-3')}
        gutter={[30, 0]}>
        {items.map((item, index) => {
          const size = items.length

          // const inner = index > 0 && index < items.length - 1
          // const xPadding = !isSm && inner ? '0.75rem' : 0
          const padding = getPadding(index, size - 1)
          const gridProps = gridOf(index)
          return (
            <Col
              key={index}
              {...defaultGrids}
              {...gridProps}
              className={classNames(
                'space-y-3',
                !!isSm && 'p-col-0',
                _.get(gridProps, 'className')
              )}
              style={!!padding && padding}>
              {item}
            </Col>
          )
        })}
      </Row>
    ),
    [items, isSm, gridOf, getPadding, defaultGrids]
  )

  return withLayout(content)
}

const withDelayRender =
  (time = 100) =>
  (next) =>
    nest(
      Pure,
      withProps(() => ({
        time,
        lazy: true,
      }))(DelayRender),
      next
    )

const SpacingLayoutWrapper = ({children, ...props}) => (
  <Layout
    className="p-2 gap-4 zero-spacing-x container mx-auto"
    {...props}>
    {children}
  </Layout>
)

const BaseLayoutContent = ({
  siders = {
    [WidgetPositions.LEFT]: Null,
    [WidgetPositions.RIGHT]: Null,
  },
                             sider,
  Header: HeaderBody,
  Footer: FooterBody,
  Content: ContentBody = Null,
  isInnerSiders = false,
                             isContentFirst = true,
  type = TemplateTypes.DEFAULT,
}) => {
  const defaultWidth = 360

  const {
    sidersWidth = defaultWidth,
    leftSiderWidth = defaultWidth,
    rightSiderWidth = defaultWidth,
  } = useContext(TemplateContext)

  const {isSm} = useContext(LayoutContext)

  const hasSider = !!!isSm

  const canSwap = hasSider && !!isContentFirst

  const noHeader = !!!HeaderBody

  const noFooter = !!!FooterBody

  const Sider = ({children, width = sidersWidth, ...props}) => {
    if (!hasSider) {
      return <Content {...props}>{children}</Content>
    }
    return (
      <Layout.Sider
        style={{
          backgroundColor: 'transparent',
        }}
        width={width}
        {...props}>
        {children}
      </Layout.Sider>
    )
  }

  const cHeader = useMemo(() => (noHeader ? null : <HeaderBody/>), [noHeader])

  const cFooter = useMemo(() => (noFooter ? null : <FooterBody/>), [noFooter])

  const innerLayout = useMemo(
    () => (
      <Layout>
        {cHeader}
        <Content>
          <ContentBody />
        </Content>
        {cFooter}
      </Layout>
    ),
    [cHeader, cFooter]
  )

  const getSider = useCallback(
    (defaultValue) => {
      const singleSider =
        !!sider &&
        [TemplateTypes.LEFT_SIDER, TemplateTypes.RIGHT_SIDER].includes(type)
      if (singleSider) {
        return sider
      }
      return defaultValue
    },
    [type, sider]
  )

  const Left = getSider(_.get(siders, WidgetPositions.LEFT, Null))

  const Right = getSider(_.get(siders, WidgetPositions.RIGHT, Null))

  const withSider = useMemo(() => {
    return ({ reversed = false }) => {
      const width = reversed ? rightSiderWidth : leftSiderWidth

      const Comp = reversed ? Right : Left

      const render = (input = []) =>
        (reversed && canSwap ? input.reverse() : input).map(renderFragment)

      if (isInnerSiders) {
        return (
          <Layout>
            {cHeader}
            <SpacingLayoutWrapper hasSider={hasSider}>
              {render([
                <Sider width={width}>
                  <Comp />
                </Sider>,
                <Content>
                  <ContentBody />
                </Content>,
              ])}
            </SpacingLayoutWrapper>
            {cFooter}
          </Layout>
        )
      } else {
        return (
          <SpacingLayoutWrapper hasSider={hasSider}>
            {render([
              <Sider width={width}>
                <Comp />
              </Sider>,
              innerLayout,
            ])}
          </SpacingLayoutWrapper>
        )
      }
    }
  }, [
    Left,
    Right,
    canSwap,
    cHeader,
    cFooter,
    hasSider,
    innerLayout,
    isInnerSiders,
    leftSiderWidth,
    rightSiderWidth,
  ])

  const sidersLayout = useMemo(() => {
    if (isInnerSiders) {
      return (
        <Layout>
          {cHeader}
          <SpacingLayoutWrapper hasSider={hasSider}>
            <Sider width={leftSiderWidth}>
              <Left />
            </Sider>
            <Content>
              <ContentBody />
            </Content>
            <Sider width={rightSiderWidth}>
              <Right />
            </Sider>
          </SpacingLayoutWrapper>
          {cFooter}
        </Layout>
      )
    } else {
      return (
        <SpacingLayoutWrapper hasSider={hasSider}>
          <Sider width={leftSiderWidth}>
            <Left />
          </Sider>
          {innerLayout}
          <Sider width={rightSiderWidth}>
            <Right />
          </Sider>
        </SpacingLayoutWrapper>
      )
    }
  }, [
    cHeader,
    cFooter,
    hasSider,
    innerLayout,
    isInnerSiders,
    leftSiderWidth,
    rightSiderWidth,
  ])

  switch (type) {
    case TemplateTypes.LEFT_SIDER:
      return withSider({
        reversed: false,
      })
    case TemplateTypes.RIGHT_SIDER:
      return withSider({
        reversed: true,
      })
    case TemplateTypes.BOTH_SIDER:
      return sidersLayout
    case TemplateTypes.CUSTOM:
      return (
        <Layout>
          {cHeader}
          <SpacingLayoutWrapper hasSider>
            <Sider width={leftSiderWidth}>
              <Left />
            </Sider>
            <Content>
              <ContentBody />
            </Content>
            <Sider width={rightSiderWidth}>
              <Right />
            </Sider>
          </SpacingLayoutWrapper>
          {cFooter}
        </Layout>
      )
    default:
      return innerLayout
  }
}

const BaseLayout = nest(TemplateProvider, BaseLayoutContent)

const DefaultLayout = ({
                         type,
                         Header,
                         Footer,
                         siders,
                         Content,
                         isInnerSiders,
                         isContentFirst,
                         ...props
                       }) => (
  <Pure>
    <BaseLayout
      {...{
        type,
        Header,
        Footer,
        Content,
        siders,
        isInnerSiders,
        ...props,
      }}
    />
  </Pure>
)

export {BaseLayout, MainLayout, DefaultLayout, LayoutBuilder, withDelayRender}
