import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {LayoutContext} from '../layouts/Default/LayoutContext'
import './FeedCard.css'
import FeedContext from './FeedContext'
import Description from "./Description";
import {GalleryEmbed} from "./GalleryEmbed";
import ReactPlayer from "react-player";
import {VideoEmbed} from "./VideoEmbed";
import {BaseEmbed, DetailEmbed} from "./BaseEmbed";
import {PollEmbed} from "./PollEmbed";
import preventParentEvent from "../../helpers/preventParentEvent";
import {CategoriesXS} from "../Categories/CategoriesXS";
import ImageLoader from "../ImageLoader";
import {notEmpty, renderIf, renderIfElse} from "../../views/Shared";
import {Widget} from "../../views/NewFeed/FeedWidget";
import {compose, fromRenderProps} from "recompose";
import classNames from "classnames"
import {Vote} from "./Vote";
import Count from "./Count";
import {RiEyeLine, RiMessage3Line} from "react-icons/ri";
import {Share} from "./Share";
import {getId, getType} from "../../apis/model/base";
import PermissionContext from "../../modules/permissions/PermissionContext";
import {PiBookmarks, PiFloppyDiskFill, PiNotePencil, PiTrash} from "react-icons/pi";
import {TiPinOutline} from "react-icons/ti";
import {Link} from "react-router-dom";
import getTitle from "../../helpers/getTitle";
import DrawMenu from "../drawer/DrawMenu";
import {IoEllipsisHorizontal} from "react-icons/io5";
import {Avatar, Menu, Popconfirm, Tooltip} from "antd";
import getLinkToDetail from "../../helpers/getLinkToDetail";
import Timestamp from "./Timestamp";
import {PinnedIcon} from "../../assets/svgs/PinnedIcon";
import {BsChatText, BsDiamondFill, BsEye, BsPersonFill, BsThreeDots} from "react-icons/bs";
import _ from "lodash"
import {BookmarkAction} from "./BookmarkAction";
import Null from 'components/NullComponent'
import {paths} from 'views/MainPage/contains'

export const FeedFooter = () => {
  const {item, actions, handleClick} = useContext(FeedContext)
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)

  return (
    <div className="FeedCardFooter flex items-end gap-4 color-primary w-full font-semibold mb-3">
      <FeedActions/>
      {actions.to && (
        <div
          onClick={() => handleClick('to')}
          className="text-right truncate italic text-color-500 hover:text-color-100 font-normal text-xs cursor-pointer">
          {isSm ? t('view') : t('show this post')}
        </div>
      )}
    </div>
  )
}


export const FeedContentMeta = () => {
  const {item, handleClick} = useContext(FeedContext)
  const permissions = useContext(PermissionContext)


  const handleMenuClick = (e) => {
    handleClick(e.key)
  }
  const t = useTranslate()
  const availbleActions = [
    // [icon,label,key]
    item.bookmark_status
      ? [
        PiBookmarks,
        'unbookmark',
        {
          hasPermission: (permissions) => true,
        },
      ]
      : [PiBookmarks, 'bookmark'],
    [PiBookmarks, 'rebookmark'],
    item.pin_status ? [TiPinOutline, 'unpin'] : [TiPinOutline, 'pin'],
    item.edit ? [PiNotePencil, 'edit'] : [],
    [PiTrash, 'delete'],
    [PiFloppyDiskFill, 'set_channel'],
  ].filter(
    ([
       Icon,
       key,
       {hasPermission = (permissions, key) => permissions[key]} = {},
     ]) => hasPermission(permissions, key)
  )


  let Meta = DefaultMetaItem

  return (
    <div className="w-full mt-2 px-2 relative">
      <Meta/>
      {availbleActions.length && (
        <DrawMenu
          component={
            <Count className="extraIcon mt-0">
              <BsThreeDots size={12}/>
            </Count>
          }>
          {(isToggle, toggle) => (
            <Menu
              onClick={(e) => {
                handleMenuClick(e)
                toggle()
              }}>
              {availbleActions.map(([Icon, key, {label} = {}]) => (
                <Menu.Item
                  icon={<Icon size={14}/>}
                  key={key || label}>
                  {t(label || key || '')}
                </Menu.Item>
              ))}
            </Menu>
          )}
        </DrawMenu>
      )}
    </div>
  )
}


export const FeedActions = ({className}) => {
  const {actions, shareUrl, handleClick, item} = useContext(FeedContext)
  const t = useTranslate()

  return (
    <div className={classNames('flex items-center flex-1 w-full', className)}>
      {actions.vote && <Vote id={item.id}/>}
      {actions.message && (
        <Count
          onClick={() => handleClick('to')}
          defaultNumber={_.get(item, 'total_comments')}
          key="message"
          color="text-primary"
          component={BsChatText}
          size={12}
        />
      )}
      {actions.view &&
      _.has(item, 'total_views') &&
      _.get(item, 'total_views', 0) > 0 && (
        <Count
          defaultNumber={_.get(item, 'total_views')}
          key="view"
          color="text-primary"
          component={BsEye}
          size={12}
        />
      )}
      {actions.share && (
        <Share
          url={shareUrl}
          id={item.id}
        />
      )}
      {actions.bookmark && <BookmarkAction/>}
      <div className="flex-1"/>
      <span
        style={{padding: '2px 10px'}}
        className="capitalize rounded-full font-medium text-2xs text-primary bg-primary-50 italic truncate">
        {t(getType(item))}
      </span>
    </div>
  )
}

const BlockRenderer = ({
                         description,
                         photos,
                         embed_data,
                         blocks: [
                           renderFirstBlock = Null,
                           renderSecondBlock = Null,
                           renderThirdBlock = Null,
                         ],
                         Wrapper = ({children}) => children,
                       }) => {
  return (
    <Wrapper>
      {description && renderFirstBlock()}
      {!_.isEmpty(photos) && renderSecondBlock()}
      {embed_data && renderThirdBlock()}
    </Wrapper>
  )
}

const embedWithProps =
  ({item, keywords, categories}) =>
    (Wrapper) => {
      const {url, photo, title, host_url, description} = item

      return (
        <Wrapper
          {...{
            title,
            url,
            subTitle: host_url || url,
            description,
            image: photo,
            categories,
            keywords,
          }}
        />
      )
    }

export const FeedEmbed = ({renderBlocks = BlockRenderer}) => {
  const {
    item,
    article,
    cover,
    type,
    photos,
    handleClick,
    embed_data,
    title,
    poll,
    shareUrl,
    description,
    mentions,
  } = useContext(FeedContext)

  const t = useTranslate()

  switch (type) {
    case "post":

      const {
        photo,
        keywords,
        categories,
      } = item

      const withEmbedData = embedWithProps({
        photo,
        keywords,
        categories,
        item: embed_data,
      })

      return renderBlocks({
        description,
        photos,
        embed_data,
        Wrapper: ({children}) => {
          return (
            <div className="flex flex-col mb-2">
              {children}
            </div>
          )
        },
        blocks: [
          () => (
              _.get(item, 'action') !== 're-post' && (
                  <Description
                      className="font-normal text-sm"
                      type="post"
                      mentions={mentions}>
                      {description}
                  </Description>
              )
          ),
          () => (
            <div className="pt-2">
              <GalleryEmbed
                images={photos.map(
                  item => item.path
                )}/>
            </div>
          ),
          () => (
            !!ReactPlayer.canPlay(embed_data.url)
              ? withEmbedData(VideoEmbed)
              : withEmbedData(BaseEmbed)
          ),
        ]
      })
    case "poll_question":
      return (
        <div className="flex flex-col">
          {description && (
            <Description
              type="poll_question"
              mentions={mentions}>
              {description}
            </Description>
          )}
          {
            poll && (
              <PollEmbed poll={poll}/>
            )
          }
        </div>
      )
    case "article":
      const article_categories = _.get(article, 'categories', [])
      const coverPhoto = _.get(article, 'cover_photo', cover)
      if (!article) {
        return null
      }
      return (
        <div className="flex flex-row justify-between items-center">
          <div className="flex-col mr-5">
              {!_.isEmpty(title) && (
                  <a
                      onClick={e => {
                          preventParentEvent(e)
                          handleClick('to')
                      }}
                      href={shareUrl}
                      className="font-bold text-color-000 no-underline">
                      {title}
                  </a>
              )}
              {!_.isEmpty(description) && (
                  <Description
                      type="article"
                      className="text-sm text-color-300 italic hidden md:block mb-5">
                      {description}
                  </Description>
              )}
              {renderIf(
                  article_categories.length > 0,
                  <div className="flex items-center gap-4">
                      {!_.isEmpty(article_categories) && ( article_categories.map((e, i) => (
                          <div key={i} className="flex items-center gap-1 text-primary">
                              <BsDiamondFill size={6} />
                              <Link
                                  className="font-medium text-xs italic"
                                  to={
                                      paths.categoryPath
                                          .replace(':category', e.id)
                                          .replace(':refcategory', e.alt_idname) + '?lang=' + e.language
                                  }>
                                  {e.name}
                              </Link>
                          </div>
                      )))}
                  </div>
              )}
          </div>
          {coverPhoto && (
            <ImageLoader
              alt={title}
              src={coverPhoto}
              className="w-20 md:w-28 h-20 object-cover rounded-md"
            />
          )}
        </div>
      )
    default:
      return (
        <React.Fragment>
          {description && (
            <Description mentions={mentions}  className="font-normal text-sm">{description}</Description>
          )}
        </React.Fragment>
      )
  }
}

export const FeedContent = () => (
  <div className="space-y-3 overflow-hidden mb-2">
    <FeedEmbed/>
  </div>
)

export const DetailFeedContent = () => {
  const {
    item,
    type,
    photos,
    embed_data,
    content_data,
    poll,
    description,
    mentions,
  } = useContext(FeedContext)

  let contentArr = []
  if (!_.isEmpty(content_data)) {
    contentArr = content_data || []
  }
  const withEmbed = !_.isEmpty(Object.values(embed_data || {}).filter(notEmpty))

  return (
    <div className="space-y-3 overflow-hidden">

      {(type === 'post' || type === 're-post') && (
        <div className="mt-2 space-y-1">
          {description && (
            <Description
              type={type}
              mentions={mentions}>
              {description}
            </Description>
          )}
          {photos && <GalleryEmbed images={photos.map((item) => item.path)}/>}
          {withEmbed &&
          (!!ReactPlayer.canPlay(embed_data.url) ? (
            <VideoEmbed
              className="mt-3"
              title={_.get(embed_data, 'title')}
              url={_.get(embed_data, 'url')}
              subTitle={
                _.get(embed_data, 'host_url') || _.get(embed_data, 'url')
              }
              description={_.get(embed_data, 'description')}
              image={_.get(embed_data, 'photo')}
              categories={_.get(item, 'categories')}
              isShowPlayer={true}
            />
          ) : (
            <DetailEmbed
              id={_.get(item, 'id')}
              title={_.get(embed_data, 'title')}
              url={_.get(embed_data, 'url')}
              subTitle={
                _.get(embed_data, 'host_name') ||
                _.get(embed_data, 'host_url') ||
                _.get(embed_data, 'url')
              }
              description={_.get(embed_data, 'description')}
              keywords={_.get(item, 'keywords')}
              content={contentArr}
              image={_.get(embed_data, 'photo')}
              categories={_.get(item, 'categories')}
              others={_.get(item, 'others_in_container', [])}
            />
          ))}
        </div>
      )}
      {type === 'poll_question' &&
      poll && (
        <PollEmbed poll={poll}/>
      )}
      <div className="verticalList__small">
        {_.get(item, 'widgets', []).map(
          (e, i) => {
            return (
              <Widget
                key={i}
                index={i}
                item={e}
              />
            )
          }
        )}
      </div>
    </div>
  )
}

export const FeedContentLayout = ({
                                    style,
                                    children
                                  }) => {
  return (
    <div
      style={style}
      className="FeedCardContent verticalList__small border border-color-50 hover:border-color-base">
      {children}
    </div>
  )
}
export const withFeedContext = compose(
  fromRenderProps(
    FeedContext.Consumer,
    props => props
  )
)
export const FeedWrapper = ({
                              className,
                              children
                            }) => {
  const {item} = useContext(
    FeedContext
  )
  return (
    <div
      onContextMenu={
        item.content_disabled
          ? preventParentEvent
          : undefined
      }
      className={classNames(
        'FeedCard',
        item.content_disabled &&
        'content_disabled',
        className
      )}>
      {children}
    </div>
  )
}


const MetaActions = () => {
  const t = useTranslate()
  const {item, actions, handleClick} = useContext(FeedContext)
  return (
    !!item.pin_status &&
    (actions.pin && !!item.edit ? (
      <Popconfirm
        placement="bottomLeft"
        title={<span className="font-medium italic">{t('unpin this')}</span>}
        onConfirm={() => handleClick('unpin')}
        okText={t('yes')}
        cancelText={t('no')}
        okButtonProps={{
          type: 'primary',
          className: 'button-rounded-md no-border text-xs px-3',
        }}
        cancelButtonProps={{
          className: 'button-rounded-md no-border text-xs text-color-400 px-3',
        }}>
        <Tooltip title={t('unpin the post')}>
          <PinnedIcon
            size={18}
            className="cursor-pointer custom-pin-icon ml-1 md:ml-3"
          />
        </Tooltip>
      </Popconfirm>
    ) : (
      <span/>
    ))
  )
}

const DefaultMetaItem = () => {
  const t = useTranslate()
  const {item, created, creatorName} = useContext(FeedContext)

  return (
    <div className="flex items-center gap-2 mr-10">
      <Avatar
        size={30}
        src={_.get(item, 'owner.avatar')}
        shape={"circle"}
        icon={
          <BsPersonFill
            size={14}
            className="text-color-400"
          />
        }
        fallback={
          <BsPersonFill
            size={14}
            className="text-color-400"
          />
        }
        className="flex flex-center background-200 font-bold text-sm text-color-400 uppercase"
      />
      {renderIfElse(
        !["organization"].includes(getType(item?.container?.owner)),
        <div className="flex flex-col">
          <div className="flex items-baseline gap-1 leading-tight">
            <Link
              to={getLinkToDetail(item?.owner)}
              className="font-bold text-xs text-primary no-underline cursor-pointer">
              {getTitle(item?.owner)}
            </Link>
            <span className="whitespace-no-wrap text-2xs text-color-500 italic lowercase">
              {t('posted')}
            </span>
          </div>
          <Timestamp
            timestamp={created}
            className="whitespace-no-wrap text-2xs text-color-500 italic"
          />
        </div>,
        <div className="flex flex-col">
          <div className="flex items-baseline gap-1 leading-tight">
            <Link
              to={getLinkToDetail(item?.owner)}
              className="font-bold text-xs text-primary no-underline cursor-pointer">
              {getTitle(item?.owner)}
            </Link>
            <span className="whitespace-no-wrap text-2xs text-color-500 italic">
              {t('posted on channel')}
            </span>
          </div>
          <div className="flex items-baseline gap-1 text-xs leading-tight">
            <Link
              className="font-medium text-xs text-color-000 no-underline max-lines-1 cursor-pointer"
              to={getLinkToDetail(item?.container)}>
              {getTitle(item?.container)}
            </Link>
            {' - '}
            <Timestamp
              timestamp={created}
              className="whitespace-no-wrap text-2xs text-color-500 italic"
            />
          </div>
        </div>
      )}
      <MetaActions/>
    </div>
  )
}