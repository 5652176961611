import {getId, getType} from 'apis/model/base'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useMemo, useState} from 'react'
import {compose, fromRenderProps} from 'recompose'
import ListHeader from 'views/AppLocals/Templates/items/ListHeader'
import WorkspaceContext from '../WorkspaceContext'
import {ClusteredProducts} from "../../Organization/Product/ClusteredProducts";

const WorkspaceProductList = ({owner_id, owner_type, Wrapper = 'div', ...props}) => {
  const t = useTranslate()

  const history = useHistory()

  const [sortBy, setSortBy] = useState()

  const [keyword, setKeyword] = useState()

  const refreshKey = useMemo(() => {
    return [owner_id, owner_type, sortBy, keyword].join('/')
  }, [owner_id, owner_type, sortBy, keyword])

  return (
    <Wrapper className="flex flex-col space-y-3">
      <ListHeader
        title="products catalog"
      />
      <ClusteredProducts view={"list"} {...props} />
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(WorkspaceContext.Consumer, ({organization}) => ({
    owner_id: getId(organization),
    owner_type: getType(organization),
  }))
)(WorkspaceProductList)
