import classNames from 'classnames'
import React from 'react'
import {useSlate} from 'slate-react'
import {Null} from 'views/Shared'
import {isBlockActive, isMarkActive, toggleBlock, toggleMark, withAlign,} from 'views/SlateEditor/functions'
import {insertTable} from 'views/SlateEditor/functions/table'
import {ElementTypes, NodeBlockTypes, NodeInlineTypes,} from 'views/SlateEditor/types'
import {ImageButton} from '../Image'
import {LinkButton} from '../Link'
import {VideoButton} from '../Video'
import HoveringToolbar from './HoveringToolbar'
import {RxTable} from "react-icons/rx";
import {
  RiAlignCenter,
  RiAlignJustify,
  RiAlignLeft,
  RiAlignRight,
  RiBold,
  RiCodeLine,
  RiDoubleQuotesL,
  RiH1,
  RiH2,
  RiH3,
  RiItalic,
  RiListOrdered2,
  RiListUnordered,
  RiUnderline
} from "react-icons/ri";

export const ToolbarButton = ({
                                style,
                                onClick,
                                className,
                                Icon = Null,
                                active = false,
                                disabled = false,
                                ...props
                              }) => (
  <button
    {...props}
    style={style}
    disabled={disabled}
    onClick={disabled ? undefined : onClick}
    className={classNames(
      'flex flex-center px-2 py-1 rounded font-medium text-primary',
      active ? 'bg-primary-700 text-white' : 'hover:bg-primary-50',
      disabled && 'disable',
      className
    )}>
    <Icon size={18}/>
  </button>
)

const MarkButton = ({format, ...props}) => {
  const editor = useSlate()
  const active = isMarkActive(editor, format)
  return (
    <ToolbarButton
      {...props}
      active={active}
      onClick={() => toggleMark(editor, format)}
    />
  )
}

const BlockButton = ({format, ...props}) => {
  const editor = useSlate()
  const active = isBlockActive(editor, format, withAlign(format) ?? 'type')
  return (
    <ToolbarButton
      {...props}
      active={active}
      onClick={(event) => {
        // event.preventDefault()
        toggleBlock(editor, format)
      }}
    />
  )
}

const TableButton = () => {
  const editor = useSlate()
  const disabled = !!!editor?.selection
  const isActive = isBlockActive(editor, ElementTypes.TABLE)
  return (
    <ToolbarButton
      Icon={RxTable}
      active={isActive}
      disabled={disabled}
      onClick={(event) => {
        // event.preventDefault()
        insertTable(editor)
      }}
    />
  )
}

const BasicToolbar = () => (
  <React.Fragment>
    {[
      {
        Icon: RiBold,
        format: NodeInlineTypes.BOLD,
      },
      {
        Icon: RiItalic,
        format: NodeInlineTypes.ITALIC,
      },
      {
        Icon: RiUnderline,
        format: NodeInlineTypes.UNDERLINED,
      },
      {
        Icon: RiCodeLine,
        format: NodeInlineTypes.CODE,
      },
    ].map(({format, Icon}, index) => (
      <MarkButton
        key={index}
        Icon={Icon}
        format={format}
      />
    ))}
    {[
      {
        Icon: RiH1,
        format: NodeBlockTypes.HEADING_ONE,
      },
      {
        Icon: RiH2,
        format: NodeBlockTypes.HEADING_TWO,
      },
      {
        Icon: RiH3,
        format: NodeBlockTypes.HEADING_THREE,
      },
      {
        Icon: RiDoubleQuotesL,
        format: NodeBlockTypes.BLOCK_QUOTE,
      },
      {
        Icon: RiListOrdered2,
        format: NodeBlockTypes.ORDERED_LIST,
      },
      {
        Icon: RiListUnordered,
        format: NodeBlockTypes.UNORDERED_LIST,
      },
      {
        Icon: RiAlignLeft,
        format: NodeBlockTypes.LEFT,
      },
      {
        Icon: RiAlignCenter,
        format: NodeBlockTypes.CENTER,
      },
      {
        Icon: RiAlignRight,
        format: NodeBlockTypes.RIGHT,
      },
      {
        Icon: RiAlignJustify,
        format: NodeBlockTypes.JUSTIFY,
      },
    ].map(({format, Icon}, index) => (
      <BlockButton
        key={index}
        Icon={Icon}
        format={format}
      />
    ))}
  </React.Fragment>
)

const Toolbar = React.forwardRef(({className, children, ...props}, ref) => {
  return (
    <div
      {...props}
      ref={ref}
      className={classNames(className, 'w-full relative block rounded-t-lg background-100')}>
      <HoveringToolbar/>
      <div
        className={classNames(
          'w-full flex flex-wrap items-center gap-1 p-1 relative border-b border-color-50'
        )}>
        <BasicToolbar/>
        <LinkButton/>
        <VideoButton/>
        <ImageButton/>
        <TableButton/>
      </div>
    </div>
  )
})

export default Toolbar
