import {Alert, Button, Form, InputNumber} from 'antd'
import moment from 'moment'
import React from 'react'
import useTranslate from "../../../../../../modules/local/useTranslate";
import {ProductPriceSetChart} from "../ProductPriceSetChart";
import _ from "lodash";
import {ProductPriceSetInfo} from "../ProductPriceSetInfo";
import {formatterNumber} from "../../../../../../helpers/formatterNumber";
import classNames from "classnames";
import {parseNumber} from "../../../../../../helpers/parseNumber";

export const PriceDetailSpotPriceEditForm = ({
    effectMomentDate,
    product,
    productPriceSet,
    productPriceGroup,
    tableRecord,
    chartAddedData, setChartAddedData,
    toggle,
    isToggle,
    update,
    form,
    handleOnEditSubmitLocationAsSingleRow
}) => {

  const t = useTranslate()

  return (
    <div className="w-full">
      <ProductPriceSetInfo productPriceSet={productPriceSet}/>
      <Form className="space-y-3"
          form={form}
          onFinish={(values) => {
              handleOnEditSubmitLocationAsSingleRow(
                  productPriceGroup,
                  {
                      rangePicker: [moment(), moment()],
                      period_code: "T0",
                      price: 0,
                      price2: 0,
                      from_date: moment().format("YYYY-MM-DD"),
                      to_date: moment().format("YYYY-MM-DD"),
                      year: moment().year(),
                      month: moment().month()
                  }
              );

              toggle();
          }}
          {...{
              labelCol: {span: 6},
              wrapperCol: {span: 18},
              gutter: [0, 16],
          }}>
        {(values) => (
            <React.Fragment>
                <div className="flex flex-col space-y-1 pb-6 mt-3">
                    {moment(productPriceGroup.eff_at).diff(effectMomentDate, 'days')
                        ? <Alert
                            type={"error"}
                            message={t('Note: The effective date of the price being updated is different from the price entry date')}
                        />
                        : null
                    }
                    <div className="flex item-center gap-3 ml-4">
                        <div className="text-color-400 text-sm italic">
                            {`${t('price is valid from the date')} : `}
                        </div>
                        <div className={classNames("font-bold text-sm",
                            moment(productPriceGroup.eff_at).diff(effectMomentDate, 'days')
                                ? "text-primary" : "text-color-100"
                        )}>
                            {moment(productPriceGroup.eff_at).format("DD/MM/YYYY")}
                        </div>
                    </div>
                    <div className="flex item-center gap-3 ml-4">
                        <div className="text-color-400 text-sm italic">
                            {`${t('updating date')} : `}
                        </div>
                        <div className={classNames("font-bold text-sm",
                            moment(productPriceGroup.eff_at).diff(effectMomentDate, 'days')
                                ? "text-red-500" : "text-color-100"
                        )}>
                            {effectMomentDate.format("DD/MM/YYYY")}
                        </div>
                    </div>

                </div>
                <Form.Item
                    label={
                        <div className="flex flex-col italic">
                            <span className="font-bold text-color-100 leading-tight">{t('price')}</span>
                            <span className="font-normal text-2xs text-color-400 leading-tight">
                                ({_.get(productPriceSet, 'price_unit.name')}/{_.get(productPriceSet, 'unit.name')})
                            </span>
                        </div>
                    }
                    className="flex-1"
                    name={["data", String(tableRecord.name), "data", "0", "price"]} >
                    <InputNumber
                        name={["data", String(tableRecord.name), "data", "0", "price"]}
                        style={{width: '100%'}}
                        formatter={formatterNumber}
                        parser={parseNumber}
                    />
                </Form.Item>
                <div className="flex w-full py-3 gap-3 justify-end">
                    <Button
                        onClick={() => {
                            setChartAddedData({
                                additionalData: [moment(productPriceGroup.eff_at).utc().format(), _.get(productPriceGroup, 'data.0.price')],
                                additionalData2: [moment(productPriceGroup.eff_at).utc().format(), _.get(productPriceGroup, 'data.0.price2')]
                            })
                        }}
                        className="button-rounded-md no-border">
                        {t('refresh chart')}
                    </Button>
                    <Button
                        type="primary"
                        className="button-rounded-md no-border"
                        htmlType="submit">
                        {t('save')}
                    </Button>
                </div>
                <div className="flex flex-col gap-3 p-3 border border-color-50 shadow-items-md rounded-lg mt-3">
                    <div className="font-bold text-xs text-color-400 uppercase">
                        {t('charts')}
                    </div>
                    {!_.isEmpty(_.get(chartAddedData, 'additionalData', [])) && (
                        <ProductPriceSetChart
                            product={product}
                            product_id={product.id}
                            {...productPriceSet}
                            location_type={_.get(productPriceGroup, 'location._type')}
                            location_id={_.get(productPriceGroup, 'location.id')}
                            unit_id={_.get(productPriceSet, 'unit.id')}
                            price_unit_id={_.get(productPriceSet, 'price_unit.id')}
                            unit2_id={_.get(productPriceSet, 'unit2.id')}
                            price_unit2_id={_.get(productPriceSet, 'price_unit2.id')}
                            eventId={update}
                            additionalData={chartAddedData.additionalData}
                            additionalData2={chartAddedData.additionalData2}
                        />
                    )}
                </div>
            </React.Fragment>
        )}
      </Form>
    </div>
  )
}
