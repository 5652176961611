import {Select, Spin} from 'antd'
import Toggle from 'components/Toggle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useState} from 'react'
import {API_ROOT_URL} from "../../../../../envs/_current/config";
import LinesChart from "../../../../Chart/Charts/ChartComponents/LinesChart";
import {DelayRender} from "../../../../Discovery/DelayRender";
import useAsyncWithCache from "../../../../../modules/asyncCache/useAsyncWithCache";
import * as moment from "moment";
import {emptyObject} from "../../../../../helpers/emptyObjects";

export const SharedProductPriceSetGroupChart = ({
                                                  product_group_title,
                                                  product_receiver_type,
                                                  product_receiver_id
                                         }) => {

  const t = useTranslate();
  const [period_id, setPeriodId] = useState();
  const [location_option_id, setLocationOptionId] = useState();
  const [price_set, setPriceSet] = useState();

  const queryParams = {
    product_group_title,
    ...price_set,
    location_option_id,
    period_id,
    product_receiver_type,
    product_receiver_id
  };

  const queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');

  const apiPath = `/products/product-price-set/get-shared-product-price-history-data?${queryString}`;

  const {
    response, success, isLoading
  } = useAsyncWithCache({
    cacheId: apiPath,
    apiInfo: {
      root: API_ROOT_URL,
      path: apiPath,
      method: 'GET'
    },
    query: queryParams
  });

  const {
    data = [],
    data2 = [],
    data_columns = [],
    unit_names = [],
    period_options = [],
    location_options = [],
    price_set_options = []
  } = _.get(response, 'data', {});

  const activePriceSet = price_set || _.get(price_set_options, '0') || emptyObject;
  const activePriceSetId = _.get(activePriceSet, 'id');
  const activePeriodId = _.get(period_options, '0.value');
  const activeLocationOptionId = _.get(location_options, '0.value');

  return (
    <div className="py-3">

      <DelayRender lazy time={100}>
        <Spin className="flex items-center justify-center" spinning={isLoading}>
          {success
          && !_.isEmpty(data_columns)
          && !_.isEmpty(period_options)
          && (
            <Toggle>
              {
                (isToggle, toggle) => (
                  <div className="flex flex-col space-y-3">

                    {
                      !!activePriceSet && (
                        <div className="flex items-center space-x-3">
                          <Select
                            onChange={(value, option) => {
                              setPriceSet(option.paramobj);
                            }}
                            defaultValue={activePriceSetId}
                            showArrow
                            style={{width: '100%'}}
                          >
                            {
                              price_set_options.map((eee,eeei) => {
                                return (
                                  <Select.Option key={eeei} value={eee.id} paramobj={eee} label={t(eee.contract_type_cd || "")}>
                                    <div className="flex flex-col text-xs">
                                      <div className="flex space-x-2">
                                        <div className="font-medium">{t("contract type")} #{eeei + 1}:</div>
                                        <div className="font-bold">{t(eee.contract_type_cd || "")}</div>
                                      </div>

                                      {
                                        _.get(eee, 'contract_type_cd') !== "spot" && (
                                          <div className="flex space-x-2">
                                            {
                                              [
                                                t(_.get(eee, 'delivery_type_cd')) || t("delivery type"),
                                                t(_.get(eee, 'incoterm_type_cd')) || t("incoterm"),
                                                t(_.get(eee, 'transport_type_cd')) || t("transport type")
                                              ].join(" / ")
                                            }
                                          </div>
                                        )
                                      }

                                      <div className="flex space-x-2">
                                        <div className="font-light">{t("unit")}:</div>
                                        <div className={!!eee.price_unit_name ? "font-medium" : "font-light"}>{
                                          [
                                            t(eee.price_unit_name || "price unit"),
                                            t(eee.unit_name || "unit")
                                          ].join("/")
                                        }</div>
                                        {
                                          _.get(eee, 'contract_type_cd') !== "spot" && !!eee.price_unit2_name && (
                                            <div className={!!eee.price_unit2_name ? "font-medium" : "font-light"}>{
                                              [
                                                t(eee.price_unit2_name || "price unit"),
                                                t(eee.unit2_name || "unit")
                                              ].join("/")
                                            }</div>
                                          )
                                        }
                                      </div>

                                    </div>
                                  </Select.Option>
                                )
                              })
                            }
                          </Select>
                        </div>
                      )
                    }

                    <div className="flex items-center space-x-3">
                      <div className="flex-1"/>
                      <div className="flex">
                        {
                          t('location')
                        }
                      </div>
                      <Select
                        onChange={setLocationOptionId}
                        defaultValue={location_option_id || activeLocationOptionId}
                        showArrow
                        style={{minWidth: 200, width: '70%'}}
                        options={location_options}
                      />
                    </div>

                    {
                      _.get(activePriceSet, 'contract_type_cd') !== "spot" && (
                        <div className="flex items-center space-x-3">
                          <div className="flex-1"/>
                          <div className="flex">
                            {
                              t('period')
                            }
                          </div>
                          <Select
                            onChange={setPeriodId}
                            defaultValue={period_id || activePeriodId}
                            showArrow
                            style={{minWidth: 200, width: '70%'}}
                            options={period_options}
                          />
                        </div>
                      )
                    }

                    {
                      ((location_option_id || activeLocationOptionId) === "A"
                        || !(location_option_id || activeLocationOptionId)
                        || (period_id || activePeriodId) === "A"
                        || !(period_id || activePeriodId)) && (
                        <div className="italic text-red-500 text-sm">
                          * {
                            t('Prices below are average prices with selected location or selected period is All')
                          }
                        </div>
                      )
                    }

                    {/*{*/}
                    {/*  _.get(activePriceSet, 'contract_type_cd') !== "spot" && (*/}
                    {/*    <div className="flex items-center justify-center w-full">*/}
                    {/*      <Radio.Group size="small" buttonStyle={"solid"} onChange={toggle} defaultValue={!isToggle}*/}
                    {/*                   style={{marginTop: 16}}>*/}
                    {/*        <Radio.Button className="px-6" value={true}>{t('flat price')}</Radio.Button>*/}
                    {/*        <Radio.Button className="px-6" value={false}>{t('basis price')}</Radio.Button>*/}
                    {/*      </Radio.Group>*/}
                    {/*    </div>*/}
                    {/*  )*/}
                    {/*}*/}

                    <div
                      className="flex items-center justify-center w-full text-primary font-bold">{t('flat price')}</div>
                    <LinesChart
                      chartInfo={
                        {
                          id: apiPath + "01",
                          idname: apiPath + "-price-history-1",
                          title: " (" + t('price history') + ")",
                          data_values: data,
                          data_columns: data_columns
                        }
                      }
                    />

                    {
                      _.get(activePriceSet, 'contract_type_cd') !== "spot" && (
                        <DelayRender time={500}>
                          <div
                            className="flex items-center justify-center w-full text-primary font-bold">{t('basis price')}</div>
                          <LinesChart
                            chartInfo={
                              {
                                id: apiPath + "02",
                                idname: apiPath + "-price-history-2",
                                title: " (" + t('price history') + ")",
                                data_values: data2,
                                data_columns: data_columns
                              }
                            }
                          />
                        </DelayRender>
                      )
                    }

                    {
                      !_.isEmpty(data) && (
                        <div className="flex flex-col space-y-1 text-sm md:text-base">
                          <div className="text-xs italic">
                            {t('last updated')}: {moment(_.get(data, '0.0')).format("DD/MM/YYYY")}
                          </div>
                          {
                            data_columns.map((e, i) => (
                              <div className={_.get(activePriceSet, 'contract_type_cd', "") !== "spot"
                                ? "grid grid-cols-3 gap-3 border-b border-dashed text-sm"
                                : "grid grid-cols-2 gap-3 border-b border-dashed text-sm"} key={i}>
                                <div className="font-medium">
                                  {
                                    e.id === 'time'
                                      ? (((period_id || activePeriodId) === "A" && activePriceSet.contract_type_cd !== "spot") ? t("period") : t("product"))
                                      : t(e.id)
                                  }
                                </div>
                                {
                                  i === 0
                                    ? (
                                      <div className="truncate text-right">
                                        {unit_names[0]}
                                      </div>
                                    )
                                    : (
                                      <div className="truncate text-right">
                                        {
                                          Number(data[0][i]) > 0 ? Number(data[0][i]).toLocaleString() : null
                                        }
                                      </div>
                                    )
                                }
                                {
                                  i === 0
                                    ? (
                                      <div className="truncate text-right">
                                        {unit_names[1]}
                                      </div>
                                    )
                                    : (
                                      <div className="truncate text-right">
                                        {
                                          Number(data2[0][i]) > 0 ? Number(data2[0][i]).toLocaleString() : null
                                        }
                                      </div>
                                    )
                                }
                              </div>
                            ))
                          }
                        </div>
                      )
                    }

                  </div>
                )
              }
            </Toggle>
          )}
        </Spin>
      </DelayRender>
    </div>
  )
}
