import { Modal } from 'antd'
import ErrorBoundary from 'components/error/ErrorBoundary'
import { IsAppReady } from 'components/IsAppReady'
import FireBase from 'modules/firebase/FireBase'
import LocalProvider from 'modules/local'
import ThemeProvider from 'modules/theme/ThemeProvider'
import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { initStore } from 'store'


const createApp = ({ device, config, history }) => {
  const { store, persistor } = initStore(
    {
      config
    },
    history
  )
  if (window) {
    window.store = store
  }
  history.listen(() => {
    Modal.destroyAll()
  })
  return function App({
    children
  }) {
    return (
      <Provider store={store}>
        <>
          <ErrorBoundary>
            <FireBase />
          </ErrorBoundary>
          <IsAppReady>
            <LocalProvider>
              <PersistGate
                loading={null}
                persistor={persistor}>
                <ThemeProvider
                  device={device}>
                  {children}
                </ThemeProvider>
              </PersistGate>
            </LocalProvider>
          </IsAppReady>
        </>
      </Provider>
    )
  }
}
export default createApp
