import {Button, notification} from 'antd'
import {create_message_sendRequestToOrg_Api} from 'apis'
import _ from 'lodash'
import React, {useContext} from 'react'
import {FaDirections} from 'react-icons/fa'
import {IoLocationSharp} from "react-icons/io5"
import {useHistory} from 'react-router-dom'
import logFunc from '../../../helpers/logFunc'
import {createAsyncAction} from '../../../modules/asyncCache'
import useDispatchAsyncAction from '../../../modules/asyncCache/useDispatchAsyncAction'
import useTranslate from '../../../modules/local/useTranslate'
import ServerContext from '../../../views/Server/ServerContext'
import {useRequiredLogin} from '../../../views/Server/useRequiredLogin'
import {QuickContactBox} from "./QuickContactBox"
import {MdEmail, MdLocationPin, MdPhone} from "react-icons/md";
import {getPageIcon} from "../Settings/Profile/LinkedPages";


export const Contact = React.memo(
  () => {
    const t = useTranslate()
    const { item } = useContext(
      ServerContext
    )

    const history = useHistory()
    const [
      { response, isLoading },
      asyncCreateConversation
    ] = useDispatchAsyncAction({
      onSuccess: logFunc((__, res) => {
        const node = document.querySelector(
          '#messages'
        )
        node.value = ''
        const id = _.get(
          res,
          'response.data.id'
        )
        notification.success({
          message: t(
            'Your message save been sent'
          ),
          description: (
            <a
              className="float-right"
              href={`/messages?conversationId=${id}`}
              target="_blank">
              <Button>
                {'Go to conversation'}
              </Button>
            </a>
          )
        })
      }),
      onError: (...args) => {
        const [
          error,
          errorObj = {
            error_code: 'System error!',
            error: '-1'
          }
        ] = args
        notification.error({
          message:
            typeof errorObj.error_code ===
              'string'
              ? errorObj.error_code
              : JSON.stringify(
                errorObj.error_code
              ),
          description:
            typeof errorObj.error ===
              'string'
              ? errorObj.error
              : JSON.stringify(
                errorObj.error
              ),
          duration: 3,
          placement: 'topRight'
        })
      }
    })
    const requireLogin = useRequiredLogin()
    const handleCreateConversation = () => {
      const node = document.querySelector(
        '#messages'
      )
      if (node) {
        requireLogin(() =>
          asyncCreateConversation(
            createAsyncAction({
              apiInfo: create_message_sendRequestToOrg_Api(),
              query: {
                ':organization_id':
                  item.idname
              },
              values: {
                message: node.value
              }
            })
          )
        )
      }
    };

    return (
      Boolean(
        _.get(item, 'locations.length')
      ) && (
        <div className="p-3 md:p-6 mt-12">
          <div className="flex flex-col text-center w-full mb-3 lg:mb-6">
            <div className="flex justify-center items-center font-semibold text-2xl uppercase ">
              {
                t('Get in touch with us')
              }
            </div>
            <p className="lg:w-2/3 mx-auto leading-relaxed font-light text-color-300">
              {t(
                'For inquiring about product quotes, sending product information, or contacting cooperation, please contact us'
              )}
            </p>
          </div>
            <div className="mb-3">
                <img src={item.avatar} style={{width: '100px', height: '100px'}} />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
                <div className="mb-8">
                    <div className="font-medium text-color-100 text-sm">
                        {t('contact info')}{(' : ')}
                    </div>
                    <p className="font-semibold text-base md:text-3xl text-primary">
                        {item.title}
                    </p>
                    <div className="flex flex-row items-center mb-2">
                        <MdLocationPin className="text-lg text-color-500 mr-2"/>
                        <span className="font-light text-sm md:text-base">{
                            [
                                _.get(
                                    item,
                                    'locations.0.address',
                                    null
                                ),
                                [
                                    _.get(
                                        item,
                                        'locations.0.ward.type',
                                        null
                                    ),
                                    _.get(
                                        item,
                                        'locations.0.ward.name',
                                        null
                                    )
                                ].filter(el => !!el).join(" "),
                                [
                                    _.get(
                                        item,
                                        'locations.0.district.type',
                                        null
                                    ),
                                    _.get(
                                        item,
                                        'locations.0.district.name',
                                        null
                                    )
                                ].filter(el => !!el).join(" "),
                                [
                                    _.get(
                                        item,
                                        'locations.0.province.type',
                                        null
                                    ),
                                    _.get(
                                        item,
                                        'locations.0.province.name',
                                        null
                                    )
                                ].filter(el => !!el).join(" "),
                                _.get(
                                    item,
                                    'locations.0.country.common_name',
                                    null
                                )
                            ].filter(el => !!el).join(", ")
                        }</span>
                    </div>
                  {
                    !!_.get(item, 'contacts.0.phone') && (
                      <div className="flex flex-row items-center mb-2">
                        <MdPhone className="text-lg text-color-500 mr-2"/>
                        <span className="font-light text-sm md:text-base">
                            {_.get(item, 'contacts.0.phone')}</span>
                      </div>
                    )
                  }
                  {
                    !!_.get(item, 'contacts.0.email') && (
                      <div className="flex flex-row items-center mb-5">
                        <MdEmail className="text-lg text-color-500 mr-2"/>
                        <span className="font-light text-sm md:text-base">
                            {_.get(item, 'contacts.0.email')}</span>
                      </div>
                    )
                  }

                    <div className="flex space-x-3">
                        {_.get(item, 'linked_pages', []).map((e, index) => {
                            return (
                              <div
                                {...{key: index}}
                                className="flex justify-start gap-x-3 items-center">
                                {
                                  getPageIcon(e.page)
                                }
                                <a
                                  target={"_blank"}
                                  href={e.url}
                                  className="italic font-light truncate">
                                  {e.url}
                                </a>
                              </div>
                            )
                        })}
                    </div>

                </div>



                <div className="flex flex-col space-y-3 mb-8">
                    <div className="font-medium text-color-100 text-sm">
                        {t('offices')}{(' : ')}
                    </div>
                    {
                        _.get(item, 'locations', []).map((e, i) => (
                            <div className="flex flex-col" key={i}>
                                <div className="font-semibold text-primary">
                                    {_.get(e, 'location_name')}
                                </div>
                                <div className="flex gap-1 text-gray-400 font-light text-sm items-center">
                                    <div className="px-1"><IoLocationSharp /></div>

                                    {
                                        [
                                            _.get(e, 'address'),
                                            [
                                                _.get(e, 'ward.type'),
                                                _.get(e, 'ward.name')
                                            ].join(" "),
                                            [
                                                _.get(e, 'district.type'),
                                                _.get(e, 'district.name')
                                            ].join(" "),
                                            [
                                                _.get(e, 'province.type'),
                                                _.get(e, 'province.name')
                                            ].join(" "),
                                            _.get(e, 'country.common_name')
                                        ].join(", ")
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>


            <div className="font-medium text-color-100 text-sm mb-3">
                {t('get direction')} {(' : ')}
            </div>
            <div className="mx-auto flex sm:flex-no-wrap flex-wrap mb-8">
                <div className="lg:w-2/3 md:w-1/2 background-200 rounded-lg overflow-hidden sm:mr-10 p-2 flex flex-col md:flex-row md:items-end justify-start relative">
                    <img
                        alt=""
                        className="md:absolute inset-0 object-cover w-full h-full"
                        src={_.get(
                            item,
                            'locations.0.coordinate.static_map_url',
                            '---- ---- ----'
                        )}
                    />
                    <div className="background relative flex-wrap py-3 shadow space-y-3">
                        <div className="w-full px-3">
                            <h3 className="title-font text-color-300 tracking-widest text-xs leading-none">
                                {t('address')}
                            </h3>
                            <p className="leading-relaxed">
                                {
                                    [
                                        _.get(item, 'locations.0.address'),
                                        [
                                            _.get(item, 'locations.0.ward.type'),
                                            _.get(item, 'locations.0.ward.name')
                                        ].join(" "),
                                        [
                                            _.get(item, 'locations.0.district.type'),
                                            _.get(item, 'locations.0.district.name')
                                        ].join(" "),
                                        [
                                            _.get(item, 'locations.0.province.type'),
                                            _.get(item, 'locations.0.province.name')
                                        ].join(" "),
                                        _.get(item, 'locations.0.country.common_name')
                                    ].join(", ")
                                }
                                {' '}
                                <a
                                    target="_blank"
                                    href={`https://www.google.com/maps/dir//${_.get(
                                        item,
                                        'locations.0.coordinate.latitude',
                                        '---- ---- ----'
                                    )},${_.get(
                                        item,
                                        'locations.0.coordinate.longitude',
                                        '---- ---- ----'
                                    )}/@${_.get(
                                        item,
                                        'locations.0.coordinate.latitude',
                                        '---- ---- ----'
                                    )},${_.get(
                                        item,
                                        'locations.0.coordinate.longitude',
                                        '---- ---- ----'
                                    )}`}
                                    className="block underline text-primary">
                                    <FaDirections className="text-lg" />{' '}
                                    {t(
                                        'Get Direction'
                                    )}
                                </a>
                            </p>
                        </div>
                        {/*<div className="w-full px-3">*/}
                        {/*  <h3 className="title-font font-sm text-color-300 tracking-widest text-sm leading-none">*/}
                        {/*    {t('email')}*/}
                        {/*  </h3>*/}
                        {/*  <p className="text-primary leading-relaxed">*/}
                        {/*    {_.get(*/}
                        {/*      item,*/}
                        {/*      'contacts.0.email',*/}
                        {/*      '---- ---- ----'*/}
                        {/*    )}*/}
                        {/*  </p>*/}
                        {/*</div>*/}
                        {/*<div className="w-full px-3">*/}
                        {/*  <h3 className="title-font font-medismum text-color-300 tracking-widest text-sm mt-4 leading-none">*/}
                        {/*    {t('phone')}*/}
                        {/*  </h3>*/}
                        {/*  <p className="leading-relaxed">*/}
                        {/*    {_.get(*/}
                        {/*      item,*/}
                        {/*      'contacts.0.phone',*/}
                        {/*      '---- ---- ----'*/}
                        {/*    )}*/}
                        {/*  </p>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="lg:w-1/3 md:w-1/2  flex flex-col md:ml-auto w-full">
                    <QuickContactBox prefixCls="p-3" />
                </div>
            </div>



        </div>
      )
    )
  }
)
