import { Select } from 'antd'
import { CustomSelect } from 'components/form/CustomSelect'
import _ from 'lodash'
import { useAppConfig } from 'modules/local'
import React, { useCallback, useMemo } from 'react'

const filterOption = (input, option) =>
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

export const useCountries = () => {
  const appConfig = useAppConfig()
  const countries = _.get(appConfig, 'countries', [])

  const getCountry = useCallback(
    (id) => {
      if (_.isEmpty(countries)) return [,]
      const item = countries.find((e) => e.id == id)
      return [item, _.get(item, 'common_name')]
    },
    [countries]
  )

  return {
    countries,
    getCountry,
  }
}

export const withCountries = (Component) => (props) => {
  const params = useCountries()
  return (
    <Component
      {...props}
      {...{ ...params }}
    />
  )
}

export const withProvinces = (Component) => (props) => {
  const params = useProvinces()
  return (
    <Component
      {...props}
      {...{ ...params }}
    />
  )
}

export const useProvinces = () => {
  const appConfig = useAppConfig()
  const provinces = _.get(appConfig, 'provinces', [])

  const getProvince = useCallback(
    (id) => {
      if (_.isEmpty(provinces)) return null
      const item = [...provinces].find((e) => e.id == id)
      return [item, _.get(item, 'name')]
    },
    [provinces]
  )

  return {
    provinces,
    getProvince,
  }
}

const ProfileSelectCountry = ({ ...props }) => {
  const { countries = [] } = useCountries()

  const items = useMemo(() => {
    return countries.map(({ id, common_name }) => {
      return (
        <Select.Option
          key={id}
          value={id}>
          {common_name}
        </Select.Option>
      )
    })
  }, [countries])

  return (
    <CustomSelect
      {...{
        filterOption,
        style: { width: '100%' },
        optionFilterProp: 'children',
        children: items,
      }}
      {...props}
    />
  )
}

const ProfileSelectProvince = ({ countryId, ...props }) => {
  const { provinces = [] } = useProvinces()
  const items = useMemo(() => {
    return provinces
      .filter((e) => _.get(e, 'country_id') == countryId)
      .map(({ id, name, code }) => {
        return (
          <Select.Option
            key={code}
            value={id}>
            {name}
          </Select.Option>
        )
      })
  }, [countryId, provinces])
  return (
    <CustomSelect
      {...{
        filterOption,
        style: { width: '100%' },
        optionFilterProp: 'children',
        children: items,
      }}
      {...props}
    />
  )
}

export { ProfileSelectCountry, ProfileSelectProvince }
