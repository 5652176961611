import React from 'react'

const ProductContext = React.createContext(
  {}
)

const {
  Consumer: ProductConsumer,
  Provider: ProductProvider,
} = ProductContext

export {
  ProductContext,
  ProductConsumer,
  ProductProvider,
}

export default function ProductContainer({
  item,
  children,
}) {
  return (
    <ProductProvider value={item}>
      {children}
    </ProductProvider>
  )
}
