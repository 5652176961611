import _ from 'lodash'

const useTabHandler = ({
  history,
  pathname,
  query = ':tab',
  search,
  state,
}) => {
  const getPath = (tab) => {
    if (_.isEmpty(pathname)) return tab
    return pathname.replace(query, tab)
  }

  const onTabChange = (pathname) => {
    history.push({
      pathname,
      search,
      state,
    })
  }
  return {
    getPath,
    onTabChange,
  }
}

export default useTabHandler
