import React from 'react'
import { LayoutContext } from './LayoutContext'
import './RightSider.css'
export const RightSider = ({
  children
}) => {
  return (
    <LayoutContext.Consumer>
      {({ rightInner }) => (
        <div className="RightSider">
          {/* <ShadowScrollbar className="w-full h-screen">
            {rightInner || null}
            {children}
          </ShadowScrollbar> */}
          {rightInner || null}
          {children}
        </div>
      )}
    </LayoutContext.Consumer>
  )
}
