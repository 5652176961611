import _ from "lodash"
import { useAppConfig } from "modules/local"
import React from "react"
import OrderRequestContext from "./OrderRequestContext"

const getProductAmount = (
  item,
  product_units = [],
  transform = ([quantity, unit]) => {
    return `${quantity} ${unit}`
  }
) => {

  if (!item || _.isEmpty(product_units)) {
    return ''
  }

  const {
    qtty = 0,
    unit_id,
  } = item

  const unit = product_units.find(e => e.id === unit_id)

  return unit
    ? transform([
      qtty,
      _.get(unit, 'name', '')
    ])
    : qtty
}

const OrderRequestProvider = ({
  item,
  children,
  organization,
}) => {

  const {
    countries = [],
    price_units = [],
    product_units = [],
    product_price_units = [],
  } = useAppConfig()

  return (
    <OrderRequestContext.Provider
      value={{
        item,
        price_units,
        organization,
        product_units,
        dataSource: {
          countries,
          price_units,
          product_units,
          product_price_units
        },
        functions: {
          getProductAmount: item => {
            return getProductAmount(
              item,
              product_units
            )
          },
        }
      }}>
      {children}
    </OrderRequestContext.Provider>
  )
}

export default OrderRequestProvider