import {
  useEffect,
  useMemo,
  useState,
} from 'react'

const useDismiss = ({
  isLoading = true,
  timeout = 5000,
}) => {
  const [isDone, done] = useState(false)

  const isWaiting = useMemo(
    () => Boolean(isLoading) && !isDone,
    [isDone, isLoading]
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isDone && !!isLoading) {
        done(true)
      }
    }, timeout)
    return () => clearTimeout(timer)
  }, [done, isDone, isLoading, timeout])

  return [isWaiting, isDone, done]
}

export default useDismiss
