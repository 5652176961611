import { useEffect } from 'react';

const ScrollTopOnChange = ({
	value,
	children = null
}) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [value]);
	return children;
};
export default ScrollTopOnChange;
