import {Alert, Avatar} from 'antd';
import {createBaseItemSchema} from 'apis/schema';
import {createTransform} from 'apis/utils/createTransform';
import {API_ROOT_URL} from 'envs/_current/config';
import _ from 'lodash';
import Async from 'modules/asyncCache/components/Async';
import {useAppConfig} from 'modules/local';
import useTranslate from 'modules/local/useTranslate';
import React, {useMemo} from 'react';
import {useSelectEntities} from 'redux/entities/useSelectEntities';
import {useProvinces} from '../Settings/SelectProvinces';
import {useDispatch} from "react-redux";
import useDispatchAsyncActionWithNotify from "../../../modules/asyncCache/useDispatchAsyncActionWithNotify";
import {createAsyncAction} from "../../../modules/asyncCache";
import {ProductPriceSetInfo} from "../../Market/Seller/Product/components/ProductPriceSetInfo";
import Toggle from "../../../components/Toggle";
import {AiFillDownCircle, AiFillUpCircle} from "react-icons/ai";
import {FaClipboardList} from "react-icons/fa";
import {ProductPriceSetGroupReportComponent} from "../../Market/Seller/Product/components/ProductPriceSetGroupComponent";
import {ReportHeader} from "../Settings/ProductReport/ProductReportDetailSetting";
import {emptyArray, emptyObject} from "../../../helpers/emptyObjects";

export default ({reportId}) => {
  const t = useTranslate()
  const path = `/products/price-report/${reportId}/get`
  const report = useSelectEntities(
    path
  ) || {
    query_params: {
      contract_types: "spot,delivery",
      delivery_types: "delivery,shipment",
      incoterm_types: "",
      transport_types: ""
    },
    columns: [],
    products: [],
    warnings: [],
    emails: [],
    errors: []
  }
  const warnings = useMemo(
    () => report.warnings,
    [_.get(report, 'warnings', [])]
  )
  const errors = useMemo(
    () => report.errors,
    [_.get(report, 'errors', [])]
  )
  const emails = useMemo(
    () => report.emails || emptyArray,
    [report.emails]
  )
  const query_params = useMemo(
    () => report.query_params || emptyObject,
    [report.query_params]
  )
  const organization = report.owner;


  const dispatch = useDispatch()
  const [
    data,
    dispatchAsyncActionWithNotify
  ] = useDispatchAsyncActionWithNotify({
    onSuccess: () => {
      dispatch(
        createAsyncAction({
          apiInfo: {
            root: API_ROOT_URL,
            path: `/products/price-report/${reportId}/get`,
            method: 'GET',
            transform: createTransform(
              createBaseItemSchema({
                idAttribute: () =>
                  `/products/price-report/${reportId}/get`
              }),
              'data'
            )
          }
        })
      )
    }
  })
  const {
    contract_types = [],
    delivery_types = [],
    incoterm_types = [],
    transport_types = [],
    countries = []
  } = useAppConfig();
  const {
    provinceList,
  } = useProvinces({
    countryId: 237,
  })
  const country = countries.find(item => String(item.id) === String(_.get(report, 'query_params.country_id')))
  const province = provinceList.find(item => String(item.value) === String(_.get(report, 'query_params.province_id')))

  return (
    <>
      <React.Fragment key={path}>
        <Async apiInfo={{
          root: API_ROOT_URL,
          path: path,
          method: 'GET',
          transform: createTransform(createBaseItemSchema({
            idAttribute: () => path
          }), 'data')
        }}>
        </Async>
      </React.Fragment>
      <div className="p-3">
        <ReportHeader report={report}/>
      </div>
      <div className="p-3 w-full space-y-6 pb-24">


        {
          (report.products || emptyArray).map((eProduct, i) => {

            return (
              <div key={eProduct.id} className="flex flex-col space-y-3 p-3 rounded-md border">

                <Alert type={"success"} message={
                  <ProductPriceSetInfo product={eProduct}/>
                }/>


                {
                  [
                    {
                      id: 'spot',
                      label: 'spot price'
                    },
                    {
                      id: 'forward',
                      label: 'forward price'
                    }
                  ].map(
                    eType => {

                      const productPriceSetsByContractType = _.reduce(
                        _.get(eProduct, 'prices', [])
                          .map(
                            price => ({
                              key: price.id,
                              product: {..._.pick(eProduct, ['title', 'id', 'idname'])},
                              ...price
                            })
                          ),
                        (res, {data: eData, ...e}) => {
                          if (e.contract_type_cd === eType.id) {

                            const _locGroup = _.reduce(eData, (res1, ee) => {
                              const {data: locData, id: product_price_set_id, ...loc} = ee;
                              const {data: groupData, id: product_price_group_id, ...group} = _.get(locData, '0', {});

                              res1.push({
                                product_price_set_id, ...loc,
                                product_price_group_id, ...group,
                                data: groupData
                              });

                              return res1;

                            }, []);

                            res.push(
                              {
                                ...e,
                                data: _locGroup
                              }
                            );

                            return res;

                          }

                          return res;
                        },
                        []);

                      if (_.isEmpty(productPriceSetsByContractType)) return null;

                      const product_price_set_ids = productPriceSetsByContractType.map(e => e.id);

                      return (
                        <Toggle
                          key={JSON.stringify(product_price_set_ids)}
                          defaultValue={!!productPriceSetsByContractType}>
                          {(
                            isToggle,
                            toggle
                          ) => {

                            return (
                              <div key={eType.id} className="flex flex-col space-y-5">
                                <div className="flex flex-col space-y-1 pb-3">

                                  {
                                    !isToggle ? (
                                      <div className="flex flex-col">
                                        <div
                                          className="font-bold text-primary flex flex-row items-center">
                                          <FaClipboardList onClick={() => {
                                            toggle()
                                          }}/>
                                          <div className="px-3 flex-1">
                                            {t(eType.label)}
                                          </div>
                                        </div>
                                        <div className="flex flex-1 items-center pb-3">
                                          <div className="w-full border-t mx-3" style={{lineHeight: 0.5}}/>

                                          <Avatar size={"small"}
                                                  className="flex items-center justify-center background-200"
                                                  onClick={() => {
                                                    toggle()
                                                  }}
                                                  icon={<AiFillDownCircle/>}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <React.Fragment>
                                        <div
                                          className="font-bold text-primary flex flex-row items-center">
                                          <FaClipboardList onClick={() => {
                                            toggle()
                                          }}/>
                                          <div className="px-3 flex-1">
                                            {t(eType.label)}
                                          </div>
                                        </div>
                                        <div className="flex flex-1 items-center pb-3">
                                          <div className="w-full border-t mx-3" style={{lineHeight: 0.5}}/>

                                          <Avatar size={"small"}
                                                  className="flex items-center justify-center background-200"
                                                  onClick={() => {
                                                    toggle()
                                                  }}
                                                  icon={<AiFillUpCircle/>}
                                          />
                                        </div>
                                        {
                                          (productPriceSetsByContractType || emptyArray).map(productPriceSetGroup => {
                                            return (
                                              <React.Fragment key={JSON.stringify(productPriceSetGroup)}>
                                                <ProductPriceSetGroupReportComponent
                                                  report={report}
                                                  item={productPriceSetsByContractType.product}
                                                  productPriceSet={productPriceSetGroup}/>
                                              </React.Fragment>
                                            )
                                          })
                                        }

                                      </React.Fragment>
                                    )
                                  }
                                </div>

                              </div>
                            )
                          }
                          }
                        </Toggle>
                      )
                    }
                  )
                }
              </div>
            )
          })
        }

      </div>
    </>
  )
}