export const paths = {
  followingPath: '/home',
  mySpacePath: '/myspace',
  mySpaceNewsPath: '/myspace/follow/news',
  mySpaceDataPath: '/myspace/follow/data',
  mySpaceVietnamMarketPath: '/myspace/follow/market',
  workspacePath: '/workspace',
  followingCategoryPath:
    '/home?category=:category',
  mySpaceCategoryPath:
    '/myspace/follow/home?category=:category',
  startPagePath: '/start',
  newPath: '/news/daily',
  defaultCategoryPath: '/category/:category',
  categoryPath: '/category/:category/:refcategory'
}
