import Icon from '@ant-design/icons'
import {Avatar, Badge, Button, Menu} from 'antd'
import {userModel} from 'apis/model'
import classNames from 'classnames'
import ErrorBoundary from 'components/error/ErrorBoundary'
import {FlagIcon} from 'components/icons/FlagIcon'
import {LoginContext} from 'components/LoginContext'
import {InfoSvg} from 'components/Svgs/InfoSvg'
import {isDevelop} from 'envs/ForDevelop'
import _ from 'lodash'
import {LocalContext} from 'modules/local/LocalContext'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useContext, useMemo} from 'react'
import {BsBag, BsBagFill, BsBell, BsBellFill, BsInfoCircle, BsSearch} from 'react-icons/bs'
import {FaSearch} from 'react-icons/fa'
import {FiLogOut} from 'react-icons/fi'
import {IoChatbubbles, IoChatbubblesOutline} from "react-icons/io5"
import {TiPlus} from "react-icons/ti"
import {ReactReduxContext} from 'react-redux'
import {matchPath} from 'react-router'
import {Link} from "react-router-dom"
import {useLocation} from 'react-use'
import {nest, withProps} from 'recompose'
import {getLoginBoolean, getMessageNotifications, getNotifications} from 'redux/selectors'
import {Selector} from 'views/Discovery/Recommendation'
import organizationModel from "../../../../apis/model/base"
import {organizationSchema, userSchema} from "../../../../apis/schema"
import Translate from "../../../../modules/local/Translate"
import {HistoryContext} from "../../../../modules/navigation/NavigationProvider"
import {SelectEntityItem} from "../../../SelectEntityItem"
import {LanguageSwitch} from './LanguageSwitch'
import './SiderMenu.css'
import {AppLogoSvg} from "../../../../appConfigs/svgs/AppLogoSvg";
import {RiNotification2Fill, RiNotification2Line, RiQuillPenLine} from "react-icons/ri";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import {ExcludedMenuTypes} from "./functions";
import ChannelCreatePost from "../../../../views/Channel/components/ChannelCreatePost";
import {NavigationContext} from 'modules/navigation/NavigationContext'
import articleModel from "../../../../apis/model/article";
import {auth_channels_Api} from "../../../../apis";

const MenuItemIcon = ({
                        path,
                        children,
                        component,
                        subs = [],
                        activeComponent,
                        style,
                        sizeIcon = '1.5rem',
                        ...props
                      }) => {
  const location = useLocation()

  const isActive = _.isEmpty(subs)
    ? matchPath(location.pathname, {
      path,
    })
    : _.some(
      subs,
      ({to = {}}) => !!matchPath(location.pathname, {path: to.pathname})
    )
  if (children) {
    return children(isActive)
  }
  return (
    <div className="SiderMenu-icon">
      <Icon
        style={{
          fontSize: sizeIcon,
          color: isActive && 'var(--color-primary)',
          ...style,
        }}
        component={isActive ? activeComponent || component : component}
        {...props}
      />
    </div>
  )
}

const NotificationIcon = ({path}) => {
  return (
    <Selector selector={getNotifications}>
      {(count) => (
        <MenuItemIcon path={path}>
          {(active) => (
            <div className="SiderMenu-icon">
              <Badge count={count} className="custom-badge">
                <Icon
                  style={{
                    fontSize: '1.5rem',
                    color: active
                      ? 'var(--color-primary)'
                      : 'var(--text-color-300)',
                  }}>
                  {!active ? <RiNotification2Line/> : <RiNotification2Fill/>}
                </Icon>
              </Badge>
            </div>
          )}
        </MenuItemIcon>
      )}
    </Selector>
  )
}

const User = () => {
  const login = useContext(LoginContext)
  const src = userModel.getFullAvatarUrl(login)
  return (
    <span>
      <Avatar
        src={src}
        size={50}
        className="background-200 hover:border-red-500 border border-gray-300 background-200">
        <UserOutlined className="text-black"/>
      </Avatar>
      <span className="SiderMenu-label">{userModel.getTitle(login)}</span>
    </span>
  )
}

const Lang = withProps(() => {
  const login = useContext(LoginContext)
  const {lang} = useContext(
    LocalContext
  )
  const _languages = _.get(login, 'following_languages', ['vi'])
  const following_languages = _.isEmpty(_languages)
    ? ['vi']
    : _languages
  return {
    title: (
        <Avatar
            className="flex flex-center"
            icon={ <FlagIcon type={lang} size={30}/> }
        />
    )
  }
})(LanguageSwitch)

const menuEntities = {
  home: {
    key: 'home',
    label: 'home',
    to: {
      pathname: '/'
    },
    type: 'item',
    icon: (
      <MenuItemIcon
        {...{
          component: AppLogoSvg,
          path: '/'
        }}
      />
    ),
    valid: []
  },
  mart: {
    key: 'mart',
    label: 'mart',
    to: {
      pathname: '/mart'
    },
    icon: (
      <MenuItemIcon
        {...{
          component: BsBag,
          activeComponent: BsBagFill,
          path: '/mart'
        }}
      />
    ),
    valid: []
  },
  messages: {
    key: 'messages',
    label: 'messages',
    to: { pathname: '/messages' },
    icon: (
      <Selector
        selector={getMessageNotifications}>
        {count => (
          <MenuItemIcon path={'/messages'}>
            {active => (
              <div className="SiderMenu-icon">
                <Badge count={count}>
                  <Icon
                    {...{
                      style: { fontSize: '1.6rem' }
                    }}
                    className={active ? 'text-primary' : 'text-color-300'}>
                    {!active ? <IoChatbubblesOutline/> : <IoChatbubbles/>}
                  </Icon>
                </Badge>
              </div>
            )}
          </MenuItemIcon>
        )}
      </Selector>
    ),
    valid: ['login']
  },
  user: {
    key: 'user',
    label: '',
    to: {
      pathname: '/user/home'
    },
    component: User,
    valid: ['login']
  },
  search: {
    key: 'search',
    label: 'search',
    to: {
      pathname: '/search',
      state: {
        isModal: true
      }
    },
    icon: (
      <MenuItemIcon
        {...{
          component: BsSearch,
          activeComponent: FaSearch,
        }}
      />
    ),
    valid: []
  },
  notifications: {
    key: 'notifications',
    label: 'notifications',
    to: { pathname: '/notifications' },
    icon: (
      <Selector
        selector={getNotifications}>
        {count => (
          <MenuItemIcon path={'/notifications'}>
            {active => (
              <div className="SiderMenu-icon">
                <Badge count={count} className="custom-badge-count">
                  <Icon
                    {...{
                      style: { fontSize: '1.5rem' }
                    }}
                    className={ active ? 'text-primary' : 'text-color-300' }>
                    {!active ? <BsBell/>  : <BsBellFill/>}
                  </Icon>
                </Badge>
              </div>
            )}
          </MenuItemIcon>
        )}
      </Selector>
    ),
    valid: ['login']
  },
  discovery: {
    key: 'discovery',
    label: 'discovery',
    to: {
      pathname: '/discovery'
    },
    icon: (
      <MenuItemIcon
        {...{
          component: BsSearch,
          activeComponent: FaSearch,
          path: '/discovery'
        }}
      />
    ),
    valid: []
  },
  provider: {
    key: 'provider',
    label: 'provider',
    render: () => {
      return (
        <Menu.Item
          key={'provider'}
          title={'provider'}>
          <LoginContext.Consumer>
            {login => {
              if (login.primary_org) {
                return (
                  <HistoryContext.Consumer>
                    {history => (
                      <Translate>
                        {t => (
                          <SelectEntityItem item={login.username} schema={userSchema}>
                            {
                              login => (login || null) && <SelectEntityItem
                                item={
                                  login.primary_org
                                }
                                schema={
                                  organizationSchema
                                }>
                                {item => (
                                  <>
                                    <div
                                      className="SiderMenu-icon"
                                      onClick={() =>
                                        history.push(
                                          '/workspace'
                                        )
                                      }>
                                      <Avatar
                                        shape={
                                          'square'
                                        }
                                        className="rounded-lg border"
                                        style={{
                                          width:
                                            '80%',
                                          height:
                                            '80%'
                                        }}
                                        src={organizationModel.getThumbnailUrl(
                                          item
                                        )}
                                      />
                                    </div>
                                    <span className="SiderMenu-label">
                                      {t(
                                        'provider'
                                      )}
                                    </span>
                                  </>
                                )}
                              </SelectEntityItem>
                            }
                          </SelectEntityItem>

                        )}
                      </Translate>
                    )}
                  </HistoryContext.Consumer>
                )
              }
              return (
                <Link
                  to={{
                    pathname:
                      '/CreateProvider',
                    state: {
                      isModal: true
                    }
                  }}>
                  <MenuItemIcon
                    {...{
                      style: {
                        fontSize:
                          '1.4em',
                        color:
                          'var(--color-primary)'
                      },
                      component: TiPlus
                    }}
                  />
                  <span className="SiderMenu-label">
                    {'provider'}
                  </span>
                </Link>
              )
            }}
          </LoginContext.Consumer>
        </Menu.Item>
      )
    },
    valid: ['login']
  },
  start: {
    key: 'start',
    label: 'welcome',
    to: {
      pathname: '/start'
    },
    valid: []
  },
  about: {
    key: 'about',
    label: 'about us',
    to: {
      pathname: '/document/about-us'
    },
    valid: []
  },
  policy: {
    key: 'policy',
    label: 'privacy policy',
    to: {
      pathname:
        '/document/privacy-policy'
    },
    valid: []
  },
  help: {
    key: 'help',
    label: 'helps',
    to: {
      pathname: '/document/tutorials'
    },
    valid: []
  },
  cookies_policy: {
    key: 'cookies_policy',
    label: 'cookies policy',
    to: {
      pathname:
        '/document/cookies-policy'
    },
    valid: []
  },
  terms_of_service: {
    key: 'terms_of_service',
    label: 'terms of service',
    to: {
      pathname:
        '/document/terms-of-service'
    },
    valid: []
  },
  frequently_questions: {
    key: 'frequently_questions',
    label: 'frequently questions',
    to: {
      pathname:
        '/document/frequently-questions'
    },
    valid: []
  },
  contact_info: {
    key: 'contact_info',
    label: 'contact info',
    to: {
      pathname: '/document/contact-info'
    },
    valid: []
  },
  trust_safe: {
    key: 'trust_safe',
    label: 'trust and safe',
    to: {
      pathname: '/document/trust-safe'
    },
    valid: []
  },
  logout: {
    key: 'logout',
    label: 'logout',
    to: {
      pathname: '/logout'
    },
    icon: (
      <MenuItemIcon
        {...{
          style: {
            fontSize: '1.4em'
          },
          component: FiLogOut
        }}
      />
    ),
    valid: ['login']
  },
  language: {
    key: 'language',
    label: 'language',
    icon: (
      <MenuItemIcon
        {...{
          style: {
            fontSize: '1.4em'
          },
          component: Lang
        }}
      />
    )
  },
  article: {
    key: 'article',
    label: 'create new post',
    icon: (
      <NavigationContext.Consumer>
        {({history}) => (
          <ChannelCreatePost
            apiInfo={auth_channels_Api}
            linkTo={(item) => {
              const url = articleModel.getLinkToCreate(item)
              history.push(url)
            }}>
            {(toggle) => (
              <Button
                onClick={toggle}
                type="primary"
                icon={<RiQuillPenLine size={20}/>}
                className="flex flex-center create-article-button-sider mb-2"
              />
            )}
          </ChannelCreatePost>
        )}
      </NavigationContext.Consumer>
    ),
  },
}

const ExcludedMenuKeys = Object.freeze({
  SPACE: 'space',
})

const menuConfig = [
  menuEntities.home,
  menuEntities.mart,
  menuEntities.discovery,
  menuEntities.notifications,
  menuEntities.messages,
  {
    key: 'helps',
    label: 'helps',
    icon: (
      <MenuItemIcon
        {...{
          style: {
            fontSize: '1.4em'
          },
          component: BsInfoCircle,
          path: '/helps'
        }}
      />
    ),
    subMenu: [
      menuEntities.start,
      menuEntities.about,
      menuEntities.contact_info,
      menuEntities.terms_of_service,
      menuEntities.policy,
      menuEntities.cookies_policy,
      menuEntities.trust_safe,
      menuEntities.help,
      menuEntities.frequently_questions
    ]
  },

  {
    key: ExcludedMenuKeys.SPACE,
    label: 'space',
    type: 'space',
    render: ({key}) => (
      <Menu.Item
        key={key}
        className="flex-1"/>
    )
  },
  menuEntities.article,
  menuEntities.language,
  // menuEntities.provider
]

const validEntities = {
  login: ({isLogin}) => isLogin,
  dev: isDevelop
}
const validateMenu = ({
                        valid,
                        ...rest
                      }) => {
  const isValid = valid
    ? !valid.find(
      key =>
        !validEntities[key]({
          ...rest
        })
    )
    : true
  return isValid
}
const renderMenuItem = ({
                          isLogin,
                          t
                        }) => (
  {
    key,
    label,
    type,
    subMenu,
    valid,
    component,
    render,
    icon
  } = {},
  i
) => {
  const isValid = validateMenu({
    valid,
    isLogin
  })
  if (!isValid) return null
  if (render) return render({key})
  if (type === 'divider')
    return (
      <Menu.Divider
        key={_.uniqueId('Menu.Divider')}
      />
    )
  const itemInner = (
    <>
      {icon && icon}
      <span className="SiderMenu-label">
          {t(label)}
        </span>
    </>
  )
  return !subMenu ? (
    <Menu.Item
      key={key}
      title={t(label)}>
      {itemInner}
    </Menu.Item>
  ) : (
    <Menu.SubMenu
      popupClassName="relative"
      key={key}
      title={itemInner}>
      {subMenu.map(
        (
          {key, label, icon, valid},
          i
        ) =>
          validateMenu({
            valid,
            isLogin
          }) ? (
            <Menu.Item
              title={t(label)}
              key={key}>
              {icon && icon}
              <span className="SiderSubMenu-label cursor-pointer">
                  {t(label)}
                </span>
            </Menu.Item>
          ) : null
      )}
    </Menu.SubMenu>
  )
}
const SiderMenu = () => {
    const t = useTranslate()
    const history = useHistory()
  const {store} = useContext(ReactReduxContext)
  const isLogin = getLoginBoolean(store.getState())

  const ignoredKeys = Object.values(ExcludedMenuTypes)

  const validEntities = {
    login: ({isLogin}) => isLogin,
    dev: isDevelop,
  }

  const validateMenu = (valid = false, params = {}) => {
    const isValid = valid
      ? !valid.find(
        (key) =>
          !validEntities[key]({
            isLogin,
            ...params,
          })
      )
      : true
    return isValid
  }

    return useMemo(
      () => (
        <Menu
          className="flex-1"
          prefixCls="SiderMenu"
          theme="light"
          defaultSelectedKeys={['1']}
          onClick={({key}) => {
            if (_.includes(ignoredKeys, key)) return
            if (menuEntities[key].to) {
              history.push(menuEntities[key].to)
            }
          }}>
          {menuConfig.map(
            renderMenuItem({
              isLogin,
              t
            })
          )}
        </Menu>
      ),
      [isLogin]
    )
  }

export default nest(ErrorBoundary, SiderMenu)