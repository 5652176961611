import {
  Checkbox,
  Collapse,
  Input,
  List,
} from 'antd'
import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import { GoogleAdsRecommendedUnit } from 'components/GoogleAds/GoogleAds'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { DelayRender } from 'views/Discovery/DelayRender'
import { Null } from 'views/Shared'
import DiscoveryExplorerContext from '../DiscoveryExplorerContext'
import './DiscoveryExplorerMenu.css'

const FilterMenuTypes = Object.freeze({
  CHECKBOX: 'checkbox',
  GROUP_TITLE: 'groupTitle',
})

const EmptyFilterPlaceholder = ({
  className,
}) => {
  return <div className={className} />
}

const renderFilterCheckbox =
  (props) => (item, index) => {
    const { name, label } = item
    return (
      <div
        className="p-1"
        key={index}>
        <Checkbox
          {...props}
          value={name}>
          {label}
        </Checkbox>
      </div>
    )
  }

const FilterMenuGroup = ({
  renderItem,
  group = {},
  ghost = false,
  options = [],
  expanded = false,
  invisible = false,
  searchable = false,
}) => {
  const t = useTranslate()

  const { isSm } = useContext(
    LayoutContext
  )

  const [text, setText] = useState()

  const { group_label } = group

  const getDataSource = useMemo(() => {
    return () => {
      if (_.isEmpty(text)) {
        return options
      } else {
        return options.filter(
          ({ label }) => {
            const keyword =
              _.lowerCase(text)
            return _.includes(
              _.lowerCase(label),
              keyword
            )
          }
        )
      }
    }
  }, [text, options])

  if (!!invisible) return null

  const handleChange = (event) => {
    const value = event.target.value
    setText(value)
  }

  const dataSource = getDataSource()

  const listStyles = classNames(
    'p-1 w-full scrollBarThin',
    searchable ? 'h-64' : 'h-auto',
    isSm
      ? 'overflow-auto'
      : 'overflow-hidden hover:overflow-auto'
  )

  return (
    <div className="flex flex-col space-y-3 content_disabled">
      <Collapse
        ghost={ghost}
        className="my-1 rounded-lg custom-filter-menu"
        defaultActiveKey={
          expanded ? 0 : false
        }
        expandIconPosition="end">
        <Collapse.Panel
          key={0}
          header={t(group_label)}
          className="font-bold text-sm text-color-300">
          {searchable && (
            <div className="p-2">
              <Input
                allowClear
                placeholder={t('search')}
                onChange={handleChange}
                className="rounded-md"
              />
            </div>
          )}
          {_.isEmpty(dataSource) ? (
            <EmptyFilterPlaceholder
              className={listStyles}
            />
          ) : (
            <List
              className={listStyles}
              dataSource={dataSource}
              itemLayout="horizontal"
              renderItem={renderItem}
            />
          )}
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

const DiscoveryExplorerMenu = ({
  onSelect = Null,
}) => {
  const {
    filter,
    setFilter,
    filterRows,
  } = useContext(
    DiscoveryExplorerContext
  )

  const t = useTranslate()

  const handleChange = useCallback(
    (item) => (event) => {
      const id = item.id
      const value = event.target.checked
      if (value) {
        setFilter({
          ...filter,
          [id]: {
            ...item,
            value,
          },
        })
      } else {
        setFilter({
          ..._.omit(filter, [id]),
        })
      }
    },
    [filter, setFilter]
  )

  const renderOption = useCallback(
    (item, index) => {
      const { id, type } = item
      switch (type) {
        case FilterMenuTypes.CHECKBOX:
          const checked =
            filter[id] &&
            filter[id].value
          return renderFilterCheckbox({
            checked,
            onChange: (event) => {
              handleChange(item)(event)
              onSelect(item)
            },
          })(item, index)
        default:
          return null
      }
    },
    [filter, handleChange]
  )

  const filterMenu = useMemo(() => {
    if (_.isEmpty(filterRows)) {
      return (
        <EmptyHolder
          icon={Null}
          subTitle={
            'No filter available'
          }
        />
      )
    } else {
      return filterRows.map(
        (item, index) => {
          const options = _.get(
            item,
            'options',
            []
          )
          return (
            <FilterMenuGroup
              key={index}
              group={item}
              options={options}
              expanded={index === 0}
              renderItem={renderOption}
              invisible={_.isEmpty(
                options
              )}
              searchable={
                options.length > 8
              }
            />
          )
        }
      )
    }
  }, [filterRows, renderOption])

  return (
    <div className="p-3 pb-40 flex flex-col space-y-3">
      <div className="font-bold mt-1 text-2xl uppercase">
        {t('Filter results')}
      </div>
      {filterMenu}
      <DelayRender time={3000}>
        <GoogleAdsRecommendedUnit />
      </DelayRender>
    </div>
  )
}

export default DiscoveryExplorerMenu
