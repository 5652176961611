import _ from 'lodash'
import {useMemo} from 'react'
import {useLocation} from 'react-use'
import {Null} from 'views/Shared'
import {getLastSegment, PathParamTypes,} from '../functions/routerHelper'

/// Examples:
/// route => /root/something/settings/:tab => [anchor: 'something']
/// route => /root/another/:menu => [anchor: 'another']

const useNavRouteAction = ({
                             anchor,
                             defaultAction,
                             getActionParam,
                             isAction = Null,
                             type = PathParamTypes.ACTION,
                           }) => {
  const {pathname, state} =
    useLocation()

  const actionParam = _.isFunction(
    getActionParam
  )
    ? getActionParam(pathname)
    : getLastSegment(pathname, anchor)

  const currentAction = useMemo(() => {
    if (!!isAction(actionParam)) {
      return actionParam
    }
    return defaultAction
  }, [
    isAction,
    actionParam,
    defaultAction,
  ])

  const getState = (actionType) =>
    _.get(
      state,
      actionType,
      currentAction
    )

  const actionState = getState(type)

  const isCurrentAction = (action) =>
    _.isEqual(action, actionParam)

  return {
    getState,
    actionParam,
    actionState,
    currentAction,
    isCurrentAction,
  }
}

export default useNavRouteAction
