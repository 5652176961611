import Icon from '@ant-design/icons/es'
import { organizationPermissionCodes } from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, { useCallback } from 'react'
import { BsDiagram2Fill, BsDiagram3, BsDiagram3Fill } from 'react-icons/bs'
import {
  IoBarChartOutline,
  IoCalendarOutline,
  IoListOutline,
} from 'react-icons/io5'
import {
  createCheckPermissions,
  invalidPermissions,
} from 'views/AppLocals/factory/createCheckPermissions'
import { SettingsMenu } from 'views/AppLocals/factory/createSettingLayout'
import DefaultTemplate from 'views/AppLocals/Templates/DefaultTemplate'
import { renderFalse, renderOwnChild } from 'views/Shared'
import CustomSiderMenu from 'views/Shared/components/CustomSiderMenu'
import { Queries } from 'views/Shared/enums/Queries'
import { useMenuHandler } from 'views/Shared/handlers/useMenuHandler'
import HostRelatedEvents from './HostRelatedEvents'
import WorkspaceCoHostList from './WorkspaceCoHostList'
import WorkspaceCoOwnerList from './WorkspaceCoOwnerList'
import WorkspaceHostPeerMembers from './WorkspaceHostPeerMembers'
import WorkspaceHostStatistic from './WorkspaceHostStatistic'

const ItemKeys = Object.freeze({
  STATISTIC: 'statistic',
  CO_HOST_LIST: 'co-host-list',
  CO_HOST_LIST_GROUP: 'co-host-list-group',
  CO_OWNER_LIST: 'co-owner-list',
  CO_OWNER_LIST_GROUP: 'co-owner-list-group',
  RELATED_EVENTS: 'related-events',
  PEER_MEMBERS: 'peer-members',
  PEER_MEMBERS_GROUP: 'peer-members-group',
  ACT_GROUP: 'act-group',
})

const with_menu_items = ({ isDisabled = renderFalse }) => [
  {
    key: ItemKeys.STATISTIC,
    label: 'insights',
    icon: <IoBarChartOutline size={16} />,
  },
  {
    key: ItemKeys.CO_HOST_LIST,
    label: 'list of co-hosting',
    icon: (
      <Icon
        component={BsDiagram2Fill}
        rotate={180}
        style={{ fontSize: 16 }}
      />
    ),
  },
  {
    key: ItemKeys.CO_OWNER_LIST_GROUP,
    label: 'list of co-owners',
    icon: (
      <Icon
        component={BsDiagram3Fill}
        style={{ fontSize: 16 }}
      />
    ),
    disabled: isDisabled({
      required_permissions: [
        organizationPermissionCodes.organizationManageMember,
      ],
    }),
    children: [
      {
        key: ItemKeys.CO_OWNER_LIST,
        label: 'list of co-owners',
        icon: (
          <Icon
            component={BsDiagram3Fill}
            style={{ fontSize: 16 }}
          />
        ),
      },
      {
        key: ItemKeys.ACT_GROUP,
        label: 'activities of member',
        icon: (
          <Icon
            component={IoListOutline}
            style={{ fontSize: 16 }}
          />
        ),
        children: [
          {
            key: ItemKeys.RELATED_EVENTS,
            label: 'related events',
            icon: (
              <Icon
                component={IoCalendarOutline}
                style={{ fontSize: 16 }}
              />
            ),
          },
        ].map(({ ...rest }) => ({
          ...rest,
          disabled: isDisabled({
            required_permissions: [
              organizationPermissionCodes.organizationManageMember,
            ],
          }),
        })),
      },
    ].map(({ ...rest }) => ({
      ...rest,
      disabled: isDisabled({
        required_permissions: [
          organizationPermissionCodes.organizationManageMember,
        ],
      }),
    })),
  },
  {
    key: ItemKeys.PEER_MEMBERS_GROUP,
    label: 'peer organization',
    icon: (
      <Icon
        component={BsDiagram3}
        rotate={180}
        style={{ fontSize: 16 }}
      />
    ),
    disabled: isDisabled({
      required_permissions: [
        organizationPermissionCodes.organizationManagePeerStructure,
      ],
    }),
    children: [
      {
        key: ItemKeys.PEER_MEMBERS,
        label: 'peer organization',
        icon: (
          <Icon
            component={BsDiagram3}
            rotate={180}
            style={{ fontSize: 16 }}
          />
        ),
      },
    ].map(({ ...rest }) => ({
      ...rest,
      disabled: isDisabled({
        required_permissions: [
          organizationPermissionCodes.organizationManagePeerStructure,
        ],
      }),
    })),
  },
]

const routeEntities = [
  {
    key: ItemKeys.STATISTIC,
    component: WorkspaceHostStatistic,
  },
  {
    key: ItemKeys.CO_HOST_LIST,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageStructure,
    ])(WorkspaceCoHostList),
  },
  {
    key: ItemKeys.CO_OWNER_LIST,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageStructure,
    ])(WorkspaceCoOwnerList),
  },
  {
    key: ItemKeys.RELATED_EVENTS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(HostRelatedEvents),
  },
  {
    key: ItemKeys.PEER_MEMBERS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManagePeerStructure,
    ])(WorkspaceHostPeerMembers),
  },
].map(({ key, ...rest }) => ({
  key,
  path: key,
  ...rest,
}))

const WorkspaceHostsContent = () => {
  const { activeKey, Component, onMenuSelect } = useMenuHandler({
    routes: routeEntities,
    actionName: Queries.REL,
  })

  const renderMenu = useCallback(
    () => (
      <CustomSiderMenu
        deps={[activeKey]}
        Wrapper={renderOwnChild}>
        <PermissionContext.Consumer>
          {(permissions) => (
            <SettingsMenu
              items={with_menu_items({
                isDisabled: invalidPermissions(permissions),
              })}
              onSelect={onMenuSelect}
              defaultSelectedKeys={[activeKey]}
              defaultOpenKeys={[
                ItemKeys.ACT_GROUP,
                ItemKeys.CO_OWNER_LIST_GROUP,
                ItemKeys.PEER_MEMBERS_GROUP,
              ]}
            />
          )}
        </PermissionContext.Consumer>
      </CustomSiderMenu>
    ),
    [with_menu_items, activeKey]
  )

  return (
    <DefaultTemplate renderMenu={renderMenu}>
      <div className="px-3 lg:pr-3">
        <Component />
      </div>
    </DefaultTemplate>
  )
}

export default WorkspaceHostsContent
