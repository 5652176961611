import {Button} from 'antd'
import classNames from 'classnames'
import React from 'react'
import './CustomButton.css'

const CustomButton = ({
                        size,
                        className,
                        useDefaultStyles = true,
                        ...props
                      }) => {
  return (
    <Button
      size={size ? size : 'default'}
      {...props}
      className={classNames(
        'CustomButton',
        useDefaultStyles &&
        'px-2 rounded-md text-sm font-medium uppercase',
        className
      )}
    />
  )
}

export default CustomButton
