import { organization_Edit_Api } from 'apis'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import React, { useContext, useMemo } from 'react'
import CustomRichEditor from 'views/AppLocals/custom/CustomRichEditor'
import createEditableForm from 'views/AppLocals/factory/createEditableForm'
import { bindQueryParam } from 'views/AppLocals/functions/routerHelper'
import OrganizationContainerContext from 'views/AppLocals/Organization/OrganizationContainerContext'
import { convertFromString, convertToString } from 'views/SlateEditor/functions'

const formFields = [{ name: 'about' }, { name: 'resetKey' }]

const formSchema = [
  {
    title: 'about us',
    children: [
      {
        name: 'about',
        component: ({ name, value, form, onChange }) => {
          const { resetKey } = form?.values ?? {}
          return (
            <CustomRichEditor
              {...{
                name,
                value,
                resetKey,
                onChange,
              }}
              placeholder="about us"
            />
          )
        },
      },
    ],
  },
]

const OrganizationSettingAbout = () => {
  const { organization } = useContext(OrganizationContainerContext)

  const validationSchema = undefined

  const body = useMemo(() => {
    if (_.isEmpty(organization)) return null
    return createEditableForm(
      formFields,
      formSchema
    )({
      validationSchema,
      item: organization,
      query: bindQueryParam({
        id: getId(organization),
      }),
      apiInfo: organization_Edit_Api,
      initialized: ({ about, ...rest }) => ({
        ...rest,
        about: convertFromString(about),
      }),
      onPreSubmit: ({ about, resetKey, ...rest }) => ({
        ...rest,
        about: about ? convertToString(about) : undefined,
      }),
    })
  }, [organization])
  return body
}

export default OrganizationSettingAbout
