import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import React, {useContext} from 'react'
import {Null, renderOwnChild} from 'views/Shared'
import FlyWrapper from '../Templates/FlyWrapper'

const defaultRender = (Header, content) => (
  <React.Fragment>
    <Header/>
    {content}
  </React.Fragment>
)

const createStickySider =
  ({Wrapper = 'div', stickySider = true, render = defaultRender, ...props}) =>
    (Content, Header = Null) => {
      const Outer = Boolean(stickySider) ? FlyWrapper : renderOwnChild

      const {isLg} = useContext(LayoutContext)

      return (
        <Wrapper className="space-y-3 h-full">
          {render(
            Header,
            <Outer
              disabled={!Boolean(isLg)}
              {...props}>
              <div className="space-y-3">
                <Content/>
              </div>
            </Outer>
          )}
        </Wrapper>
      )
    }

export default createStickySider
