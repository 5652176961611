import _ from 'lodash'

export const getApp = state => {
  return state.app
}
export const getOnline = _.flow([
  getApp,
  app => app.online
])
export const getNotifications = _.flow([
  getApp,
  app => app.count_notifications
])
export const getMessageNotifications = _.flow([
  getApp,
  app => app.count_msg_notifications
])
