import React from 'react'

export default function BarChartSvg(
  props
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 280.027 280.027"
      version="1.1"
      viewBox="0 0 280.027 280.027"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#26A6D1"
        d="M17.502 105.009h35.003c9.661 0 17.502 7.832 17.502 17.502v113.761c0 9.67-7.841 17.502-17.502 17.502H17.502C7.841 253.774 0 245.942 0 236.272V122.511c0-9.67 7.841-17.502 17.502-17.502z"
      ></path>
      <path
        fill="#3DB39E"
        d="M122.512 26.252h35.003c9.661 0 17.502 7.841 17.502 17.502v192.519c0 9.67-7.841 17.502-17.502 17.502h-35.003c-9.661 0-17.502-7.832-17.502-17.502V43.753c0-9.661 7.841-17.501 17.502-17.501z"
      ></path>
      <path
        fill="#E2574C"
        d="M227.522 70.006h35.003c9.661 0 17.502 7.841 17.502 17.502v148.765c0 9.67-7.841 17.502-17.502 17.502h-35.003c-9.661 0-17.502-7.832-17.502-17.502V87.508c.001-9.661 7.841-17.502 17.502-17.502z"
      ></path>
    </svg>
  )
}
