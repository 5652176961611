import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {useAppConfig} from '../../../../modules/local'


export const OrgType = ({item, className}) => {
  const t = useTranslate()


  const {org_types = {}} = useAppConfig()

  if (!_.has(item, 'org_type')) return null;

  const org_type = _.get(
    org_types,
    _.get(item, 'org_type', '900')
  )

  return (
    <span className={className}>
      {t(org_type || 'group')}
    </span>
  )
}

