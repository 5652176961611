import { Input, Spin } from "antd"
import { userModel } from "apis/model"
import { getId, getType } from "apis/model/base"
import { productSchema } from "apis/schema"
import FieldsFactory from "components/form/FieldsFactory"
import { createValue } from "components/form/utils"
import { LoginContext } from "components/LoginContext"
import Null from "components/NullComponent"
import getTitle from "helpers/getTitle"
import _ from "lodash"
import { useAppConfig } from "modules/local"
import useTranslate from "modules/local/useTranslate"
import withTranslate from "modules/local/withTranslate"
import React, { useContext } from "react"
import { useToggle } from "react-use"
import { branch, compose, mapProps, renderNothing, withProps } from "recompose"
import { useSelectEntities } from "redux/entities/useSelectEntities"
import OrderRequestContext from "views/OrderRequest/OrderRequestContext"
import { withConfirmModal } from "views/OrderRequest/Settings/components/RequestModals"
import { fromOrderRequestType } from "views/OrderRequest/Settings/OrderRequestStatus"
import SpotContactForm from "./SpotContactForm"
import SpotFormHeader from "./SpotFormHeader"
import SpotLastedPriceForm from "./SpotLastedPriceForm"
import SpotProductInfo from "./SpotProductInfo"
import SpotSelect, { RenderCountries } from "./SpotSelect"

export const RequestFieldTypes = Object.freeze({
  Ref_type: 'ref_type',
  Ref_id: 'ref_id',
  Sender_email: 'sender_email',
  Sender_name: 'sender_name',
  Sender_country_id: 'sender_country_id',
  Sender_phone: 'sender_phone',
  Sender_organization_name: 'sender_organization_name',
  Subject: 'subject',
  Message: 'message',
  Order_request_type: 'order_request_type',
  Quantity: 'qtty',
  Unit_id: 'unit_id'
})

const withAppConfig = Component => {
  return function WrapperComponent(
    props
  ) {
    const appConfig = useAppConfig()
    return (
      <Component
        {...props}
        appConfig={appConfig} />
    )
  }
}

const userFormSchema = (isInvisibled = Null) => [
  {
    title: 'name',
    invisible: !!isInvisibled(
      RequestFieldTypes.Sender_name
    ),
    children: [
      {
        name: RequestFieldTypes.Sender_name,
        component: compose(
          withTranslate,
          mapProps(
            ({ translate, onChange, value, name }) => ({
              name,
              value,
              onChange,
              className: "rounded",
              placeholder: translate('name'),
            })
          )
        )(Input)
      }
    ]
  },
  {
    title: 'email',
    invisible: !!isInvisibled(
      RequestFieldTypes.Sender_email
    ),
    children: [
      {
        name: RequestFieldTypes.Sender_email,
        component: compose(
          withTranslate,
          mapProps(
            ({ translate, onChange, value, name }) => ({
              name,
              value,
              onChange,
              type: "email",
              inputMode: "email",
              className: "rounded",
              placeholder: translate('email'),
            })
          )
        )(Input)
      }
    ]
  },
  {
    title: 'phone',
    invisible: !!isInvisibled(
      RequestFieldTypes.Sender_phone
    ),
    inline: true,
    className: "flex gap-2",
    itemClassNames: [
      "flex-1",
      "flex-1",
    ],
    ItemWrapper: ({ children }) => {
      return (
        <Input.Group className="flex flex-col">
          {children}
        </Input.Group>
      )
    },
    children: [
      {
        name: RequestFieldTypes.Sender_country_id,
        component: compose(
          withAppConfig,
          withTranslate,
          mapProps(
            ({
              name,
              value,
              onChange,
              translate,
              appConfig,
            }) => {
              return {
                value,
                onChange: ({ value }) => {
                  onChange(
                    createValue(
                      name,
                      value
                    )
                  )
                },
                items: _.get(
                  appConfig,
                  'countries',
                  [],
                ),
                className: "w-full",
                renderItem: RenderCountries,
                placeholder: translate('country')
              }
            })
        )(SpotSelect)
      },
      {
        name: RequestFieldTypes.Sender_phone,
        component: compose(
          withTranslate,
          mapProps(
            ({ translate, onChange, value, name }) => ({
              name,
              value,
              onChange,
              type: "number",
              className: "rounded",
              placeholder: translate('phone'),
            })
          )
        )(Input)
      }
    ]
  },
]

const DefaultWrapper = ({ children }) => (children)

const BasicWrapper = ({
  children,
  className = "flex flex-col gap-3",
}) => {
  return (
    <div className={className}>
      {children}
    </div>
  )
}

export const SpotRequestTypes = Object.freeze({
  PRICE: 'price',
  PRODUCT_INFO: 'productinfo',
})

const UserRequiredInfo = () => {

  const login = useContext(LoginContext)

  const invisibleFields = [
    { id: 'name', name: RequestFieldTypes.Sender_name },
    { id: 'email', name: RequestFieldTypes.Sender_email },
    { id: 'phone_number', name: RequestFieldTypes.Sender_phone },
  ].map(({ id, name }) => {
    const item = _.get(login, id, '')
    return {
      name,
      invisible: !_.isEmpty(item)
    }
  })

  const isInvisibled = name => {
    const foundItem = _.find(invisibleFields, e => e.name === name)
    return _.get(foundItem, 'invisible', false)
  }

  return (
    <FieldsFactory
      formSchema={userFormSchema(isInvisibled)}
      Wrapper={({ children }) => {
        return (
          <div>
            {children}
          </div>
        )
      }}
      renderTitle={title => (
        <p className="text-sm font-medium mb-1">
          {title}
        </p>
      )} />
  )
}

const SpotUnauthenticatedForm = ({
  RequestForm = DefaultWrapper,
  Header = DefaultWrapper,
  Wrapper = DefaultWrapper
}) => {
  return (
    <Wrapper>
      <Header />
      <RequestForm />
    </Wrapper>
  )
}

const SpotAuthenticatedForm = ({
  RequestForm = DefaultWrapper,
  Header = DefaultWrapper,
  Wrapper = DefaultWrapper
}) => {
  return (
    <Wrapper>
      <Header />
      <RequestForm />
    </Wrapper>
  )
}

const requestUseCase = ({
  id,
  type,
  target,
  hideModal,
  onSuccess,
  requestType,
}) => WrappedComponent => {

  let params = {}

  const login = useContext(LoginContext)

  const { dataSource = {} } = useContext(OrderRequestContext)

  const { product_units = [] } = dataSource

  const senderName = userModel.getTitle(login)

  const refValue = _.get(target, 'value', {})

  const refId = _.get(target, 'id', getId(refValue))

  // _type: product
  const refItem = _.get(
    target,
    'value',
    useSelectEntities(
      refId,
      _.get(target, 'schema', productSchema),
      {},
    )
  )

  if (_.isEmpty(refItem)) {
    return null
  }

  const formProps = {
    id,
    type,
    hideModal,
    onSuccess,
    initialValues: {
      [RequestFieldTypes.Ref_id]: refId,
      [RequestFieldTypes.Ref_type]: getType(refItem),
      [RequestFieldTypes.Subject]: getTitle(refItem),
      [RequestFieldTypes.Sender_name]: senderName,
      [RequestFieldTypes.Sender_country_id]: 237,
      [RequestFieldTypes.Unit_id]: _.get(
        _.first(product_units),
        'id'
      ),
      [RequestFieldTypes.Order_request_type]: requestType,
      [RequestFieldTypes.Sender_email]: _.get(login, 'email'),
      [RequestFieldTypes.Sender_phone]: _.get(login, 'phone_number'),
    }
  }

  const FormWrapper = ({ children }) => {
    return (
      <BasicWrapper>
        <UserRequiredInfo />
        {children}
      </BasicWrapper>
    )
  }

  switch (requestType) {
    case SpotRequestTypes.PRODUCT_INFO:
      params = {
        ...params,
        RequestForm: props => {
          return (
            <SpotContactForm
              {...formProps}
              Wrapper={FormWrapper} />
          )
        }
      }
      break
    case SpotRequestTypes.PRICE:
      params = {
        ...params,
        RequestForm: props => {
          return (
            <SpotLastedPriceForm
              {...formProps}
              Wrapper={FormWrapper} />
          )
        }
      }
      break
  }

  return (
    <Spin spinning={false}>
      <WrappedComponent
        target={target}
        Header={() => (
          <SpotFormHeader id={id}>
            <SpotProductInfo item={refItem} />
          </SpotFormHeader>
        )}
        {...params} />
    </Spin>
  )
}

const SpotRequestForm = ({
  children,
  Component,
  childOnly = false,
  Wrapper = ({ children }) => (children),
  requestType = SpotRequestTypes.DEFAULT_REQUEST,
}) => {

  const [isToggle, toggle] = useToggle(false)

  const t = useTranslate()

  if (!!childOnly) {
    return (
      <Wrapper>
        {children}
      </Wrapper>
    )
  }

  const {
    title,
    subTitle
  } = fromOrderRequestType(requestType)

  return (
    <Wrapper>
      {children(() => toggle())}
      {isToggle && withConfirmModal({
        toggle,
        sideModal: true,
        title: t(title)
      })(() => (
        <div className="flex flex-col gap-4">
          <div className="font-medium text-center text-color-100 italic mb-1">
            {t(subTitle)}
          </div>
          <Component hideModal={toggle} />
        </div>
      ))}
    </Wrapper>
  )
}

export default compose(
  branch(
    ({ target }) => !!!target,
    renderNothing
  ),
  withProps(({ target, id, type, requestType }) => {
    const login = useContext(LoginContext)
    const Widget = !!login
      ? SpotAuthenticatedForm
      : SpotUnauthenticatedForm

    return {
      Component: ({ hideModal, onSuccess }) => requestUseCase({
        id,
        type,
        target,
        hideModal,
        onSuccess,
        requestType,
      })(Widget)
    }
  })
)(SpotRequestForm)