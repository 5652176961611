import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'
import LoadingPage from 'views/LoadingPage'
import AddPaymentSuccess from './components/AddPaymentSuccess'
import AddPaymentWithPlan from './components/AddPaymentWithPlan'
import ChoosePlanConfirm from './components/ChoosePlanConfirm'
import ChoosePlanTrial from './components/ChoosePlanTrial'
import ConfirmDowngradePlan from './components/ConfirmDowngradePlan'
import ConfirmPlanAmount from './components/ConfirmPlanAmount'
import ConfirmTrialPlan from './components/ConfirmTrialPlan'
import ManageSubscriptionChannels from './components/ManageSubscriptionChannels'
import ManageSubscriptionDatasets from './components/ManageSubscriptionDatasets'

const LazyBillingOverview = React.lazy(
  () =>
    import(
      './components/BillingOverview'
    )
)

const LazyManageBillDetails = React.lazy(
  () =>
    import(
      './components/ManageBillDetails'
    )
)
const LazyManageBillHistory = React.lazy(
  () =>
    import(
      './components/ManageBillHistory'
    )
)
const LazyManageSubscriptions = React.lazy(
  () =>
    import(
      './components/ManageSubscriptions'
    )
)
const LazyManageBillEstimate = React.lazy(
  () =>
    import(
      './components/ManageBillEstimate'
    )
)

const LazyAddPayment = React.lazy(() =>
  import('./components/AddPayment')
)
const LazyChangeBillAddress = React.lazy(
  () =>
    import(
      './components/ChangeBillAddress'
    )
)
const LazyChangePlan = React.lazy(() =>
  import('./components/ChangePlan')
)
const LazyConfirmPlan = React.lazy(() =>
  import('./components/ConfirmPlan')
)
const pageRoot = '/account/billing/'

export const routeEntities = {
  overview: {
    name: 'overview',
    path: pageRoot + 'overview',
    breadcrumbName: 'overview',
    component: LazyBillingOverview
  },
  billDetail: {
    name: 'billing-details',
    path: pageRoot + 'billing-details',
    breadcrumbName: 'billing details',
    component: LazyManageBillDetails
  },
  billHistory: {
    name: 'billing-history',
    path: pageRoot + 'billing-history',
    breadcrumbName: 'billing history',
    component: LazyManageBillHistory
  },
  billEstimate: {
    name: 'bill-estimate',
    path: pageRoot + 'bill-estimate',
    breadcrumbName: 'bill estimate',
    component: LazyManageBillEstimate
  },
  manageSubscriptions: {
    name: 'manage-subscriptions',
    path:
      pageRoot + 'manage-subscriptions',
    breadcrumbName: 'manage services',
    component: LazyManageSubscriptions
  },
  addPayment: {
    name: 'add-payment',
    path: pageRoot + 'add-payment',
    breadcrumbName:
      'add money to wallet',
    component: LazyAddPayment
  },
  changePlan: {
    name: 'change-plan',
    path: pageRoot + 'change-plan',
    breadcrumbName:
      'change subscription',
    component: LazyChangePlan
  },
  choosePlan: {
    name: 'change-plan',
    path:
      pageRoot + 'change-plan/:planId',
    breadcrumbName:
      'change subscription',
    component: ChoosePlanConfirm
  },
  choosePlanTrial: {
    name: 'choose-trial-plan',
    path:
      pageRoot +
      'choose-trial-plan/:planId',
    breadcrumbName:
      'change subscription',
    component: ChoosePlanTrial
  },
  changeBillAddress: {
    name: 'change-bill-address',
    path:
      pageRoot + 'change-bill-address',
    breadcrumbName: 'change address',
    component: LazyChangeBillAddress
  },
  startTrial: {
    name: 'start-trial',
    path: pageRoot + 'start-trial',
    breadcrumbName: 'start trial',
    component: ConfirmTrialPlan
  },
  downgradePlan: {
    name: 'downgrade-plan',
    path:
      pageRoot +
      'downgrade-plan/:planId',
    breadcrumbName:
      'downgrade subscription',
    component: ConfirmDowngradePlan
  },
  upgradePlan: {
    name: 'upgrade-plan',
    path:
      pageRoot + 'upgrade-plan/:planId',
    breadcrumbName:
      'upgrade subscription',
    exact: true,
    component: LazyConfirmPlan
  },
  upgradePlanAmount: {
    name: 'upgrade-plan',
    path:
      pageRoot +
      'upgrade-plan/:planId/:amount',
    breadcrumbName:
      'upgrade subscription',
    component: ConfirmPlanAmount
  },
  billingPaymentDetail: {
    name: 'payment-detail',
    path: pageRoot + 'payment-detail',
    breadcrumbName: 'payment detail',
    component: AddPaymentSuccess
  },
  paymentWithPlan: {
    name: 'payment-plan',
    path:
      pageRoot +
      'payment-plan/:planId/:amount',
    breadcrumbName:
      'payment subscription',
    component: AddPaymentWithPlan
  },
  manageChannelSubscriptions: {
    name: 'manageChannelSubscriptions',
    path:
      pageRoot +
      'manageChannelSubscriptions',
    breadcrumbName:
      'channel subscriptions',
    component: ManageSubscriptionChannels
  },
  manageDatasetSubscriptions: {
    name: 'manageDatasetSubscriptions',
    path:
      pageRoot +
      'manageDatasetSubscriptions',
    breadcrumbName:
      'dataset subscriptions',
    component: ManageSubscriptionDatasets
  }
}

export const routes = [
  routeEntities.overview,
  routeEntities.billDetail,
  routeEntities.billHistory,
  routeEntities.billEstimate,
  routeEntities.manageSubscriptions,
  routeEntities.addPayment,
  routeEntities.changePlan,
  routeEntities.choosePlan,
  routeEntities.choosePlanTrial,
  routeEntities.changeBillAddress,
  routeEntities.startTrial,
  routeEntities.upgradePlan,
  routeEntities.upgradePlanAmount,
  routeEntities.downgradePlan,
  routeEntities.billingPaymentDetail,
  routeEntities.paymentWithPlan,
  routeEntities.manageChannelSubscriptions,
  routeEntities.manageDatasetSubscriptions
]
export const menuItems = [
  routeEntities.overview,
  routeEntities.billDetail,
  routeEntities.billHistory,
  routeEntities.billEstimate,
  routeEntities.addPayment,
  routeEntities.manageSubscriptions,
  routeEntities.changePlan,
  routeEntities.manageChannelSubscriptions,
  routeEntities.manageDatasetSubscriptions
]
export const links = {
  overview: routeEntities.overview.path,
  billDetail:
    routeEntities.billDetail.path,
  billHistory:
    routeEntities.billHistory.path,
  billEstimate:
    routeEntities.billEstimate.path,
  manageSubscriptions:
    routeEntities.manageSubscriptions
      .path,
  addPayment:
    routeEntities.addPayment.path,
  changePlan:
    routeEntities.changePlan.path,
  changeBillAddress:
    routeEntities.changeBillAddress
      .path,
  startTrial:
    routeEntities.startTrial.path,
  downgradePlan:
    routeEntities.downgradePlan.path,
  upgradePlan:
    routeEntities.upgradePlan.path,
  paymentWithPlan:
    routeEntities.paymentWithPlan.path
}

export const Routes = () => {
  return (
    <Suspense
      fallback={<LoadingPage />}>
      <>
        {routes.map(
          ({
            exact,
            path,
            breadcrumbName,
            component
          }) => (
            <Route
              key={path}
              exact={true}
              path={path}
              component={component}
            />
          )
        )}
        <Route
          exact
          {...routes[0]}
          path="/account/billing"
        />
      </>
    </Suspense>
  )
}
