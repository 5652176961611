import Null from 'components/NullComponent'
import {connect} from 'react-redux'
import {branch, compose} from 'recompose'
import {getLogin} from 'redux/selectors'

export const createRequiredNonLogin = (
  FallBackComponent = Null
) =>
  compose(
    connect(state => ({
      login: !!getLogin(state)
    })),
    branch(
      props => props.login,
      () => FallBackComponent
    )
  )
