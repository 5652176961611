import {Card} from 'antd'
import React from 'react'
import './CustomCard.css'

const CustomCard = props => {
  return (
    <div className="CustomCard">
      <Card size={"small"} {...props}></Card>
    </div>
  )
}
CustomCard.propTypes = Card.propTypes
export default CustomCard
