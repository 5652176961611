import {Editor, Element as SlateElement, Transforms} from 'slate'
import {ElementTypes, NodeBlockTypes} from '../types'
import {withAlign} from './editor'

const ListTypes = [NodeBlockTypes.ORDERED_LIST, NodeBlockTypes.UNORDERED_LIST]

export function isMarkActive(editor, format) {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

export function toggleMark(editor, format) {
  const isActive = isMarkActive(editor, format)

  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export function isBlockActive(editor, format, blockType = 'type') {
  const {selection} = editor
  if (!selection) return false

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  )

  return !!match
}

export function toggleBlock(editor, format) {
  const align = withAlign(format)
  const isActive = isBlockActive(editor, format, align ?? 'type')
  const isList = (type) => ListTypes.includes(type)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      isList(n.type) &&
      !align,
    split: true,
  })

  const newProperties = align
    ? {align: isActive ? undefined : format}
    : {
      type: isActive
        ? ElementTypes.PARAGRAPH
        : isList(format)
          ? ElementTypes.LIST_ITEM
          : format,
    }

  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList(format)) {
    const block = {type: format, children: []}
    Transforms.wrapNodes(editor, block)
  }
}
