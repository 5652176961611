import React from "react";

export const Rice = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<g fill="#FFC850">
				<path d="M362.973 140.291c-21.296-21.032-48.619-30.205-81.291-27.364-68.076 6.009-109.239 90.341-125.726 132.66a961.09 961.09 0 013.446 33.425c1.952-1.004 3.519-2.745 4.19-5.02.254-.86 16.423-54.456 47.585-96.185 9.606-12.864 24.457-20.852 40.511-20.762 12.237.069 25.752 2.861 37.349 10.237 69.334 44.093 107.599 171.243 107.977 172.518a8.315 8.315 0 008.852 5.909 8.304 8.304 0 007.407-7.638c.425-5.626 9.748-138.475-50.3-197.78z"></path>
				<path d="M154.958 329.133a8.314 8.314 0 01-8.304-8.02c-.097-2.784-2.216-68.924 12.696-140.933C179.493 82.901 221.63 22.776 281.203 6.298c48.416-13.394 93.163-5.268 126.005 22.875 38.797 33.24 55.697 89.924 47.592 159.611a8.31 8.31 0 01-7.533 7.322c-3.982.39-7.561-2.127-8.69-5.909C411.692 99.941 313.022 38.976 247.24 48.014c-5.529.76-10.753 3.237-14.558 7.32-74.836 80.277-69.491 262.999-69.412 265.195.158 4.586-3.43 8.442-8.016 8.596-.101.008-.199.008-.296.008z"></path>
			</g>
			<path
				fill="#D4F582"
				d="M107.823 14.919C97.114 50.82 78.09 138.056 96.777 262.635l33.249 157.931 33.249-83.121c-40.411-121.232-47.647-269.75-48.939-321.742-.095-3.799-5.426-4.426-6.513-.784z"
			></path>
			<path
				fill="#AFD755"
				d="M204.451 261.077c-57.584 11.772-66.114 142.865-66.114 142.865l33.249 99.746s23.206-190.971 47.758-195.336c24.136-4.291 127.182 75.312 172.463 111.46 3.085 2.463 7.137-1.534 4.699-4.639-39.017-49.699-136.69-165.414-192.055-154.096z"
			></path>
			<path
				fill="#BEE86E"
				d="M55.227 114.279c-.317 61.399 5.634 221.92 67.859 386.97 2.439 6.469 8.706 10.751 15.619 10.751h37.759c5.914 0 9.894-5.977 7.643-11.445-64.225-156.06-107.312-324.364-122.333-387.06-.916-3.827-6.526-3.151-6.547.784z"
			></path>
			<g fill="#FFDC64">
				<path d="M452.579 193.455c-2.88-86.252-33.014-158.365-97.087-173.95-51.913-12.627-99.399 11.776-119.496 32.94 3.292-2.37 7.179-3.873 11.244-4.432 65.781-9.038 164.452 51.927 191.337 142.183 1.128 3.783 4.708 6.299 8.69 5.909 2.109-.182 3.949-1.187 5.312-2.65z"></path>
				<path d="M298.866 129.122c-36.475-.73-61.302 16.105-81.998 42.326 9.321-9.044 21.638-14.478 34.819-14.404 12.237.069 25.752 2.861 37.349 10.237 69.334 44.093 107.599 171.243 107.977 172.518a8.315 8.315 0 008.852 5.909c2.806-.297 5.118-2.017 6.385-4.378-3.767-191.428-87.408-211.689-113.384-212.208z"></path>
			</g>
		</svg>
	);
}

export default Rice;
