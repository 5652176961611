import { schema } from 'normalizr'

export const userSchema = new schema.Entity(
  'users',
  {},
  {
    idAttribute: 'username'
  }
)
export const userArraySchema = [
  userSchema
]
