import { schema } from 'normalizr'

const messageSchema = new schema.Entity(
  'messages',
  {},
  {
    idAttribute: 'id'
  }
)

export default messageSchema
