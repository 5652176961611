import Null from 'components/NullComponent'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {createAsyncAction} from '.'
import {getAsynCacheSelector} from './selectors'

function useAsyncAction({
                          query = {},
                          values = {},
                          apiInfo,
                          onSuccess = Null,
                          onError = Null
                        }) {
  const [
    action = {},
    setAction
  ] = useState()
  const {
    isLoading,
    success,
    errorMessages,
    result,
    time,
    error,
    ...rest
  } = useSelector(state =>
    getAsynCacheSelector(
      state,
      action.asyncId
    )
  )
  const dispatch = useDispatch()
  const handleAsyncAction = (
    _values = {},
    _query = {}
  ) => {
    const action = createAsyncAction({
      query: {
        ...query,
        ..._query
      },
      apiInfo,
      values: {
        ...values,
        ..._values
      }
    })
    setAction(action)
    dispatch(action)
    return action
  }

  useEffect(() => {
    if (error) {
      // message.error('errorMessages')
      console.error(errorMessages)
      onError(error, errorMessages)
    }
    if (success) {
      onSuccess(result, {
        action,
        isLoading,
        success,
        errorMessages,
        result,
        error,
        time,
        ...rest,
        handleAsyncAction
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])
  return {
    action,
    isLoading,
    success,
    errorMessages,
    result,
    error,
    time,
    ...rest,
    handleAsyncAction
  }
}

export default useAsyncAction
