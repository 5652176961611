import {Avatar, Breadcrumb, Skeleton} from 'antd'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, {useContext, useMemo, useState} from 'react'
import {BsCalendar2Plus, BsCalendar2Week} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import ServerContext from 'views/Server/ServerContext'
import {CustomPagingHeader} from 'views/Shared'
import {getChannelsByProps_Api, post_getPosts_Api} from '../../../apis'
import {event_getEventsByProps_Api} from '../../../apis/event'
import {organizationModel} from '../../../apis/model'
import AppIcon from '../../../components/AppIcon'
import EmptyHolder from '../../../components/EmptyHolder'
import Description from '../../../components/Feed/Description'
import {ChannelNewsFeed} from '../../../components/Feed/TimeLineFeed'
import {ChannelIcon} from '../../../components/icons/ChannelIcon'
import Pure from '../../../components/Pure'
import StickyFrame from '../../../components/StickyFrame'
import EmptyFolderStateSvg from '../../../components/Svgs/EmptyFolderStateSvg'
import UseState from '../../../components/UseState'
import CustomLazyPaginationWrapper from '../../../modules/asyncCache/components/CustomLazyPaginationWrapper'
import {LazyPagination} from '../../../modules/asyncCache/components/LazyPagination'
import useTranslate from '../../../modules/local/useTranslate'
import {renderEventItem} from '../../MainPage/functions/renderEventItem'
import {OrganizationContext} from '../OrganizationContext'
import getLinkToDetail from 'helpers/getLinkToDetail'

const renderLoading = () => (
  <>
    <div className="col-span-1 h-16 background-100 rounded p-3"/>
    <div className="col-span-1 h-16 background-100 rounded p-3"/>
  </>
)

const UserEmptyPlaceholder = ({children}) => {
  return (
    <div className="border w-full m-3 rounded-lg">
      <EmptyHolder
        icon={() => (
          <div
            style={{
              fontSize: '3rem',
              color: 'rgb(185,190,198)',
            }}>
            {children}
          </div>
        )}
      />
    </div>
  )
}

const AdminEmptyPlaceholder = ({children, title, description, onClick}) => {
  return (
    <div className="bg-primary rounded-lg p-5 flex flex-wrap relative">
      <span
        className="mb-5 text-white font-semibold tracking-wide text-lg"
        style={{zIndex: 1}}>
        {description}
      </span>
      <button
        onClick={onClick}
        className="border border-white hover:background-white text-white hover:text-primary font-medium text-md py-1 px-3 rounded-full ">
        {title}
      </button>
      <div className="circle-hafl absolute right-1rem bottom-0 flex justify-center items-center">
        {children}
      </div>
    </div>
  )
}

const ActionWrapper = ({title, Icon, onClick}) => {
  return (
    <CustomPagingHeader title={title}>
      {!!Icon && (
        <div
          className="cursor-pointer"
          onClick={onClick}>
          <Avatar
            shape="square"
            icon={<Icon/>}
            className="bg-transparent"
          />
        </div>
      )}
    </CustomPagingHeader>
  )
}

const EmptyPlaceholder = ({
                            action,
                            children,
                            value = false,
                            components = [],
                            ...props
                          }) => {
  const [admin, user] = components
  const {handleActionClick} = useContext(ServerContext)
  return (
    <div className="flex w-full h-48 justify-center items-center">
      {value ? (
        <AdminEmptyPlaceholder
          onClick={() => handleActionClick(action)}
          {...props}>
          {admin}
        </AdminEmptyPlaceholder>
      ) : (
        <UserEmptyPlaceholder>{user}</UserEmptyPlaceholder>
      )}
    </div>
  )
}

const renderItem = (item) =>
  (item || null) && (
    <Pure
      key={item.id}
      input={[
        item && item.content && item.content.updated,
        _.get(item, 'content.pin_status'),
      ]}>
      <ChannelNewsFeed item={item.content}/>
    </Pure>
  )

const renderSeeMore = (onClick) => {
  return (
    <Translate>
      {(t) => (
        <div className="bottom-0 flex w-full px-3">
          <div className="mt-3 cursor-pointer flex-wrap flex w-full justify-center">
            <div className="flex-1"/>
            <div
              className="italic text-color-500 font-light text-sm mb-2"
              onClick={onClick}>
              <span>{t('see more')}</span>
            </div>
          </div>
        </div>
      )}
    </Translate>
  )
}

export function News() {
  const {organization} = useContext(OrganizationContext)
  const [eventId, setEventId] = useState(Date.now())

  const t = useTranslate()

  const editable = !!_.get(organization, 'edit', false)

  const channelActions = useMemo(() => {
    return (
      <EmptyPlaceholder
        action="create-channel"
        title={t('create channel')}
        description={t(
          'Create an information channel to promote, share information and discuss with everyone'
        )}
        value={editable}
        components={[
          <ChannelIcon
            strokeWidth={3}
            className="icon-add text-white opacity-75 mt-5 font-bold"
          />,
          <ChannelIcon/>,
        ]}
      />
    )
  }, [t, editable])

  const eventActions = useMemo(() => {
    return (
      <EmptyPlaceholder
        action="create-event"
        title={t('create event')}
        description={t(
          'Create events so people can participate and learn more about your organization'
        )}
        value={editable}
        components={[
          <BsCalendar2Plus className="icon-add text-white opacity-75 mt-5"/>,
          <BsCalendar2Week/>,
        ]}
      />
    )
  }, [t, editable])

  return (
    <div className="p-3 space-y-6">
      <Breadcrumb className="font-light text-sm">
        <Breadcrumb.Item>
          <Link to={organizationModel.getLinkToDetail(organization)}>
            {organizationModel.getTitle(organization)}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{t('news')}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-y-3 md:gap-3 items-start">
        <section
          style={{
            overflow: 'initial',
          }}
          className="h-full space-y-6">
          <StickyFrame className="space-y-3">
            <div className="flex flex-col space-y-3">
              <ActionWrapper title="events"/>
              <UseState initialValue={2}>
                {([limit, setLimit]) => (
                  <CustomLazyPaginationWrapper
                    sample={limit}
                    alwaysVisible={true}
                    id={
                      event_getEventsByProps_Api.path
                        .replace(':type', 'organization')
                        .replace(':id', organization.idname) + eventId
                    }
                    key={
                      event_getEventsByProps_Api.path
                        .replace(':type', 'organization')
                        .replace(':id', organization.idname) + eventId
                    }
                    renderEmpty={() => eventActions}
                    apiInfo={event_getEventsByProps_Api}
                    query={{
                      ':type': 'organization',
                      ':id': organization.idname,
                    }}
                    renderLoading={renderLoading}
                    renderItem={renderEventItem}
                    renderWrap={(children, startAction, listLength) => {
                      return (
                        <div className="space-y-3">
                          {children}
                          {limit > 0 &&
                          !!listLength &&
                          renderSeeMore(() => {
                            setLimit(0)
                          })}
                        </div>
                      )
                    }}
                  />
                )}
              </UseState>
            </div>
            <div className="flex flex-col space-y-3">
              <ActionWrapper title="channels"/>
              <UseState initialValue={2}>
                {([limit, setLimit]) => (
                  <CustomLazyPaginationWrapper
                    sample={limit}
                    alwaysVisible={true}
                    id={
                      getChannelsByProps_Api.path
                        .replace(':type', 'organization')
                        .replace(':id', organization.idname) + eventId
                    }
                    key={
                      getChannelsByProps_Api.path
                        .replace(':type', 'organization')
                        .replace(':id', organization.idname) + eventId
                    }
                    renderEmpty={() => channelActions}
                    apiInfo={getChannelsByProps_Api}
                    query={{
                      ':type': 'organization',
                      ':id': organization.idname,
                    }}
                    renderLoading={renderLoading}
                    renderItem={(item) => (
                      <div className="cursor-pointer p-2 flex flex-col rounded-md hover:background-100">
                        <div className="flex flex-wrap items-center space-x-3">
                          <AppIcon item={item}/>
                          <Link
                            to={getLinkToDetail(item)}
                            className="font-semibold text-lg">
                            {item.title}
                          </Link>
                        </div>
                        <Description
                          type={'channel'}
                          mentions={item && item.mentions}>
                          {item.description}
                        </Description>
                      </div>
                    )}
                    renderWrap={(children, startAction, listLength) => {
                      return (
                        <div className="space-y-3">
                          {children}
                          {limit > 0 &&
                          !!listLength &&
                          renderSeeMore(() => {
                            setLimit(0)
                          })}
                        </div>
                      )
                    }}
                  />
                )}
              </UseState>
            </div>
          </StickyFrame>
        </section>
        <section className="space-y-5 col-span-2">
          <h2 className="py-1 border-b-2 border-primary text-primary flex-1 font-medium uppercase mb-5">
            {t('the latest news')}
          </h2>
          <LazyPagination
            id={
              post_getPosts_Api.path +
              '_type_organization_id_' +
              organization.id
            }
            key={
              post_getPosts_Api.path +
              '_type_organization_id_' +
              organization.id
            }
            renderEmpty={() => (
              <div
                className="items-center justify-center space-y-6"
                style={{minHeight: '12rem'}}>
                <div className="p-6 rounded-md background-100">
                  <Skeleton/>

                  <EmptyHolder
                    icon={() => (
                      <EmptyFolderStateSvg
                        style={{opacity: 0.5}}
                        width="3rem"
                        height="3rem"
                      />
                    )}
                    title={null}
                  />
                </div>
              </div>
            )}
            apiInfo={post_getPosts_Api}
            query={{
              ':prop': 'organization',
              ':id': organization.idname,
            }}
            values={{
              pinned: 0,
            }}
            renderItem={renderItem}
          />
        </section>
      </div>
    </div>
  )
}
