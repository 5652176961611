import React from 'react'
import {compose} from 'recompose'
import {CoHostList} from 'views/Workspace/Host/WorkspaceCoHostList'
import {CoOwnerList} from 'views/Workspace/Host/WorkspaceCoOwnerList'
import {HostProvider} from '../Host/HostContext'
import {DefaultSettingHeader} from './createSettingContent'

const withHostSetting = (Component) => {
  return function Wrapper({item, ...props}) {
    return (
      <HostProvider container={item}>
        <Component
          {...{
            Header: ({title, extra}) => (
              <DefaultSettingHeader
                title={title}
                extra={extra}
              />
            ),
          }}
          {...props}
        />
      </HostProvider>
    )
  }
}

export const CoHosts = compose(withHostSetting)(CoHostList)

export const CoOwners = compose(withHostSetting)(CoOwnerList)
