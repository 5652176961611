import {Radio} from 'antd'
import {createValue} from 'components/form/utils'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useEffect} from 'react'
import {useToggle} from 'react-use'
import {Null, renderOwnChild} from 'views/Shared'
import '../../Shared/styles/Radio.css'
import {appFieldTypes} from '../appFieldTypes'

const RadioOptions = ({
                        name,
                        value,
                        optionType,
                        defaultValue,
                        onChange = Null,
                        noTranslate = false,
                        buttonStyle = 'solid',
                        Wrapper = renderOwnChild,
                        options = appFieldTypes.yesOrno,
                      }) => {
  const t = useTranslate()
  return (
    <Wrapper>
      <Radio.Group
        name={name}
        value={value}
        onChange={onChange}
        optionType={optionType}
        buttonStyle={buttonStyle}
        defaultValue={defaultValue}>
        {options.map(({label, value, activeLabel, disabled}, index) => (
          <Radio.Button
            disabled={disabled}
            style={
              index === 0
                ? {
                  borderTopLeftRadius: '0.375rem',
                  borderBottomLeftRadius: '0.375rem',
                }
                : index === options.length - 1
                ? {
                  borderTopRightRadius: '0.375rem',
                  borderBottomRightRadius: '0.375rem',
                }
                : {
                  borderRadius: 0,
                }
            }
            checked={defaultValue == value}
            value={value}
            key={index}>
            {noTranslate
              ? defaultValue == value && !!activeLabel
                ? activeLabel
                : label
              : defaultValue == value && !!activeLabel
                ? t(activeLabel)
                : t(label)}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Wrapper>
  )
}

export const MixedOptionGroup = ({
                                   value,
                                   children,
                                   options = [],
                                   subChains = [],
                                   expanded = false,
                                   Wrapper = renderOwnChild,
                                   ...props
                                 }) => {
  const t = useTranslate()

  const [isToggle, toggle] = useToggle()

  const current = _.get(options, '[0]', {})

  const handleChange = (expandable = false) => {
    toggle(!!expandable)
    if (!_.has(props, 'onChange')) return
    subChains.forEach(({name, value, getValue}) => {
      props.onChange(
        createValue(name, getValue ? getValue(expandable, value) : value)
      )
    })
  }

  const _value = value ? value : JSON.stringify(current.value)

  useEffect(() => {
    const first = _.get(options, '[0].expandable')
    if (!isToggle && !expanded) {
      handleChange(first)
    }
  }, [])

  if (_.isEmpty(options)) return null

  return (
    <Wrapper>
      <Radio.Group
        {...props}
        value={_value}>
        {options.map(({label, value, expandable}, index) => (
          <Radio
            key={index}
            value={JSON.stringify(value)}
            onClick={() => {
              if (expandable) {
                handleChange(true)
              }
            }}
            onChange={() => handleChange(expandable)}>
            <span className="font-medium">{t(label)}</span>
          </Radio>
        ))}
      </Radio.Group>
      {Boolean(expanded || isToggle) ? children : null}
    </Wrapper>
  )
}

export default RadioOptions
