import {Button, Spin} from 'antd'
import {create_loadMore_Api_action} from 'apis'
import {getId} from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import Null from 'components/NullComponent'
import {SelectEntityItem} from 'components/SelectEntityItem'
import _ from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {MdSignalWifiConnectedNoInternet0} from 'react-icons/md'
import {useDispatch} from 'react-redux'
import {RequiredLoginPlaceholder} from 'routes/createRequiredLogin'
import {Selector} from 'views/Discovery/Recommendation'
import {renderIf, renderOwnChild} from 'views/Shared'
import {createAsyncAction} from '..'
import Translate from '../../local/Translate'
import useTranslate from '../../local/useTranslate'
import {getAsynCacheSelector} from '../selectors'
import useAsyncList from '../useAsyncList'
import {AsyncByAction, Error, Loading, Success} from './Async'
import {DidOnMount} from './DidOnMount'
import {LazyList} from './LazyList'

const cache = {}

export const renderDefaultError = (children, data) => {
  const status = _.defaultTo(Number(data?.status), 0)
  const errorMessages = data?.response?.errorMessages
  switch (status) {
    case 401:
      return <RequiredLoginPlaceholder/>
    case 500:
      return (
        <Translate>
          {(t) => (
            <div className="flex flex-col flex-center gap-3 border border-dashed rounded-lg px-3 py-8">
              <MdSignalWifiConnectedNoInternet0
                style={{color: '#e5e5e5'}}
                size={65}
              />
              <span className="font-medium text-lg text-color-500 italic">
                {errorMessages?.error || t('lost connect')}
              </span>
              <span className="text-sm text-color-500 italic">
                {errorMessages?.error_code || t('error 500')}
              </span>
            </div>
          )}
        </Translate>
      )
    default:
      break
  }
  return children
}

export const RetryButton = ({
                              onClick,
                              label = 'retry',
                              title = 'Something went wrong. Please try again.',
                              ...props
                            }) => {
  const t = useTranslate()
  return (
    <div className="flex flex-col flex-center gap-2">
      <EmptyHolder
        icon={Null}
        title={title}
        subTitle={() => (
          <Button
            type="primary"
            onClick={onClick}
            className="rounded-lg font-medium px-3 flex items-center justify-center mt-2"
            {...props}>
            {t(label)}
          </Button>
        )}
      />
    </div>
  )
}

export const LazyPagination = ({
                                 auto = true,
                                 refreshKey,
                                 reversed,
                                 cacheId,
                                 onDone,
                                 limit,
                                 sample,
                                 onChange,
                                 renderWrap,
                                 renderEmpty = () => <EmptyHolder/>,
                                 renderNoMore,
                                 renderLoading = () => (
                                   <div className="w-full flex justify-center">
                                     <Spin className="m-auto"/>
                                   </div>
                                 ),
                                 query,
                                 values,
                                 apiInfo,
                                 children,
                                 renderList,
                                 Header = Null,
                                 renderItem = Null,
                                 PaginationMeta = Null,
                                 renderLoadMore = Null,
                                 Wrapper = renderOwnChild,
                                 renderError = renderDefaultError,
                               }) => {
  const [error, setError] = useState()
  const [errorAction, setErrorAction] = useState()
  const [current, setCurrent] = useState({
    index: 0,
    rows: 0,
    nextUrl: null,
    isLoading: false,
  })
  const firstAction = useMemo(
    () =>
      createAsyncAction({
        prefixStr: apiInfo.path,
        query,
        values,
        apiInfo,
      }),
    [apiInfo, query, values]
  )
  const [done, setDone] = useState()
  useEffect(() => {
    done && onDone && onDone()
  }, [done, onDone])
  const [map, {set, reset}] = useAsyncList(
    cache[cacheId] ? cache[cacheId] : []
  )
  useEffect(() => {
    if (cacheId) {
      cache[cacheId] = map
    }
    onChange && onChange(map)
  }, [cacheId, map, onChange])
  useEffect(() => {
    reset()
    set(0, firstAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey])
  const dispatch = useDispatch()

  const action_array = useMemo(
    () => Object.values(map).filter((_, i) => !limit || i < limit),
    [map, limit]
  )

  const current_action =
    _.last(Boolean(done) ? action_array : action_array.slice(0, -1)) || {}

  const [items, set_items] = useState([])

  const [initialized, set_initialized] = useState()

  const render = () => (
    <>
      <LazyList reversed={reversed}>
        {action_array.map((action, i) => {
          const schema = _.get(action, 'apiInfo.schema')
          return (
            <AsyncByAction
              key={action.asyncId}
              action={action}
              changeNotifier={(data) => {
                const list = Array.from(
                  (schema
                    ? data?.result
                    : _.get(data?.response, 'data.data')) ?? []
                )
                set_items(_.uniq([...(items ?? []), ...list]))
              }}>
              {!auto && <Loading/>}
              <Error>
                {({...rest}) => (
                  <DidOnMount
                    onMount={() => {
                      setError(rest)
                      setErrorAction(action)
                    }}
                  />
                )}
              </Error>
              <Success>
                {(data) => {
                  const {success, response, result = []} = data
                  const nextUrl = _.get(
                    response,
                    'data.meta.pagination.links.next'
                  )
                  const list = schema
                    ? result
                    : _.get(response, 'data.data') || []
                  if (i === 0 && success && list.length === 0) {
                    return (
                      <React.Fragment>
                        <DidOnMount
                          onMount={() => {
                            setDone(true)
                            set_initialized(true)
                          }}>
                          <div/>
                        </DidOnMount>
                        <Wrapper
                          setDone={setDone}
                          count={items?.length}>
                          <Selector
                            selector={(state) =>
                              getAsynCacheSelector(
                                state,
                                current_action.asyncId
                              )
                            }>
                            {(data) =>
                              renderIf(
                                !!success && list.length,
                                <Header
                                  {...{
                                    data,
                                    meta: (
                                      <PaginationMeta
                                        {...{
                                          data,
                                        }}
                                      />
                                    ),
                                  }}
                                />
                              )
                            }
                          </Selector>
                          {renderEmpty()}
                        </Wrapper>
                      </React.Fragment>
                    )
                  }
                  const nextid = i + 1
                  return (
                    <React.Fragment>
                      <DidOnMount
                        onMount={() => {
                          if (success) {
                            set_initialized(true)
                          }
                          setCurrent({
                            ...current,
                            nextUrl,
                            index: i + 1,
                            isLoading: false,
                            rows: list.length,
                          })
                        }}
                      />
                      <Wrapper
                        setDone={setDone}
                        count={items?.length}>
                        {i === 0 && (
                          <Selector
                            selector={(state) =>
                              getAsynCacheSelector(
                                state,
                                current_action.asyncId
                              )
                            }>
                            {(data) =>
                              renderIf(
                                !!success && list.length,
                                <Header
                                  {...{
                                    data,
                                    meta: (
                                      <PaginationMeta
                                        {...{
                                          data,
                                        }}
                                      />
                                    ),
                                  }}
                                />
                              )
                            }
                          </Selector>
                        )}
                        {renderList
                          ? renderList(list, schema)
                          : list
                            .filter(
                              (_, iList) =>
                                !sample || (i === 0 && iList < sample)
                            )
                            .map((item, i) =>
                                schema ? (
                                  <SelectEntityItem
                                    key={i}
                                    item={item}
                                    schema={schema}>
                                    {(item) => {
                                      return renderItem(
                                        item,
                                        _.indexOf(items, getId(item)),
                                        action
                                      )
                                    }}
                                  </SelectEntityItem>
                                ) : (
                                  renderItem(item, i, action)
                                )
                            )}
                        {i === 0 && success && list.length !== 0 && !!sample ? (
                          <>
                            <DidOnMount onMount={() => setDone(true)}>
                              <div/>
                            </DidOnMount>
                          </>
                        ) : null}
                        {!map[nextid] && nextUrl && !sample && auto && (
                          <DidOnMount
                            onMount={() => {
                              set(
                                nextid,
                                create_loadMore_Api_action({
                                  prefixStr: firstAction.asyncId,
                                  path: nextUrl,
                                  apiInfo: action.apiInfo,
                                })
                              )
                            }}>
                            <div/>
                          </DidOnMount>
                        )}
                        {!(i === 0 && success && list.length === 0) &&
                        success &&
                        !nextUrl && (
                          <>
                            <DidOnMount onMount={() => setDone(true)}>
                              <div/>
                            </DidOnMount>
                            {!!renderNoMore && renderNoMore()}
                          </>
                        )}
                      </Wrapper>
                    </React.Fragment>
                  )
                }}
              </Success>
            </AsyncByAction>
          )
        })}
      </LazyList>
      {!!errorAction &&
      renderError(
        <div className="flex flex-col flex-center gap-2">
          <RetryButton
            onClick={() => {
              setErrorAction(null)
              dispatch(errorAction)
            }}
          />
        </div>,
        error
      )}
      {!auto &&
      !done &&
      !current?.isLoading &&
      current?.nextUrl &&
      _.defaultTo(current?.rows, 0) > 0 &&
      renderLoadMore({
        onClick: () => {
          setCurrent({
            ...current,
            isLoading: true,
          })
          set(
            current?.index,
            create_loadMore_Api_action({
              prefixStr: firstAction.asyncId,
              path: current?.nextUrl,
              apiInfo: firstAction.apiInfo,
            })
          )
        },
      })}
      {!errorAction && !done && auto && renderLoading()}
    </>
  )
  if (children) {
    return children(render, map)
  }

  if (renderWrap) {
    return renderWrap(render(), Null, initialized ? items?.length : 1)
  }

  return render()
}
