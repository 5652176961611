import {Alert, Avatar, Modal, Spin, Table, Tabs} from 'antd'
import {API_ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import numeral from "numeral"
import React from 'react'
import {ProductPriceSetInfo} from "./components/ProductPriceSetInfo";
import Toggle from "../../../../components/Toggle";
import {emptyArray} from "../../../../helpers/emptyObjects";
import getAvatar from "../../../../helpers/getAvatar";
import productImageHolder from "../../productImageHolder";

export const ProductPriceHistory = ({item}) => {
  const t = useTranslate();
  return (
      <Toggle>
          {( isToggle, toggle ) => (
              <React.Fragment>
                  <div
                      onClick={() => {toggle()}}
                      className="font-medium text-xs text-primary hover:text-primary-700 cursor-pointer">
                      {t('price history')}
                  </div>
                  <Modal
                      width={640}
                      className="custom-modal"
                      title={
                          <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                              {t('price history')}
                          </div>
                      }
                      footer={null}
                      icon={false}
                      closable={true}
                      visible={isToggle}
                      onCancel={toggle}
                      destroyOnClose
                      maskClosable={false}>
                      <Async {...{
                          apiInfo: {
                              root: API_ROOT_URL,
                              path: '/products/product/:id/get-price-history',
                              method: 'GET',
                          },
                          query: { ':id': item.id },
                      }}>
                          {({response, isLoading}) => {
                              let dataSource = _.get(response, 'data.data', [])
                              return (
                                  <Spin spinning={isLoading}>
                                      {!_.isEmpty(dataSource) ? (
                                          <div>
                                              <div className="flex flex-col gap-1">
                                                  <span className="font-semibold text-sm text-color-400 uppercase tracking-wide">
                                                      {t('products')}
                                                  </span>
                                                  <div className="flex items-center gap-3 p-2 border border-color-50 shadow-items-md rounded-lg">
                                                      <Avatar
                                                          size={30}
                                                          src={getAvatar(item) || productImageHolder}
                                                          className="rounded-md"
                                                      />
                                                      <span className="font-bold text-color-000">
                                                          {_.get(item, 'title')}
                                                      </span>
                                                  </div>
                                              </div>
                                              <Tabs
                                                  animated={false}>
                                                  {[
                                                      {
                                                          id: 'spot',
                                                          label: 'spot price'
                                                      },
                                                      {
                                                          id: 'forward',
                                                          label: 'forward price'
                                                      }
                                                  ].map(eType => {
                                                      const productPriceSetsByContractType =
                                                          _.reduce(dataSource || emptyArray, (res, {data: eData, ...eSetGroup}) => {
                                                              if (eSetGroup.contract_type_cd === eType.id) {

                                                                  const _locGroup = _.reduce(eData, (res1, eSetGroupLoc) => {
                                                                      const {data: locData, id: product_price_set_id, ...loc} = eSetGroupLoc;

                                                                      const _groups = _.reduce(locData, (res2, eGroupRec) => {
                                                                          const {data: groupData, ...groupRec} = eGroupRec;

                                                                          res2.push({
                                                                              product_price_set_id,
                                                                              ...loc,
                                                                              ...groupRec,
                                                                              data: groupData
                                                                          });

                                                                          return res2;

                                                                      }, []);

                                                                      return [...res1, ..._groups];

                                                                  }, []);
                                                                  let groupByDate0 = _.groupBy(_locGroup, function (item) {
                                                                      return item.eff_at;
                                                                  });

                                                                  // let product_price_set_ids = [];
                                                                  let oData = [];
                                                                  const groupByDate = Object.keys(groupByDate0).sort().map(e => {
                                                                      const product_price_set_ids = groupByDate0[e].map(ee => ee.product_price_set_id);
                                                                      const xData = oData.filter(ee => {
                                                                          return !product_price_set_ids.includes(ee.product_price_set_id)
                                                                              && moment(ee.eff_at) <= moment(e)
                                                                              && (_.isNull(ee.end_at) || moment(ee.end_at) > moment(e))
                                                                      });
                                                                      const data = [
                                                                          ...groupByDate0[e],
                                                                          ...xData
                                                                      ];
                                                                      oData = data;
                                                                      return {
                                                                          id: e,
                                                                          data: data
                                                                      }
                                                                  });

                                                                  res.push(
                                                                      {
                                                                          ...eSetGroup,
                                                                          groupByDate: groupByDate
                                                                      }
                                                                  );

                                                                  return res;

                                                              }
                                                              return res;
                                                          }, []);
                                                      return (
                                                          <Tabs.TabPane
                                                              key={eType.id}
                                                              tab={
                                                                  <span className="font-medium">{t(eType.label)}</span>
                                                              }>
                                                              <div className="space-y-6 flex flex-col">
                                                                  {(productPriceSetsByContractType || emptyArray).map(productPriceSetGroup => {
                                                                      const _key = productPriceSetGroup.contract_type_cd +
                                                                          productPriceSetGroup.delivery_type_cd +
                                                                          productPriceSetGroup.incoterm_type_cd +
                                                                          productPriceSetGroup.transport_type_cd +
                                                                          _.get(productPriceSetGroup, 'unit.id') +
                                                                          _.get(productPriceSetGroup, 'unit2.id') +
                                                                          _.get(productPriceSetGroup, 'price_unit.id') +
                                                                          _.get(productPriceSetGroup, 'price_unit2.id');

                                                                      const chartProductPriceSetApiPath = `/products/product-price-set/get-price-set-history-data`
                                                                          + `?contract_type_cd=${productPriceSetGroup.contract_type_cd}`
                                                                          + `&delivery_type_cd=${productPriceSetGroup.delivery_type_cd}`
                                                                          + `&incoterm_type_cd=${productPriceSetGroup.incoterm_type_cd}`
                                                                          + `&transport_type_cd=${productPriceSetGroup.transport_type_cd}`
                                                                          + `&unit_id=${_.get(productPriceSetGroup, 'unit.id')}`
                                                                          + `&unit2_id=${_.get(productPriceSetGroup, 'unit2.id')}`
                                                                          + `&price_unit_id=${_.get(productPriceSetGroup, 'price_unit.id')}`
                                                                          + `&price_unit2_id=${_.get(productPriceSetGroup, 'price_unit2.id')}`;

                                                                      return (
                                                                          <div key={_key} className="space-y-3">
                                                                              <ProductPriceSetInfo productPriceSet={productPriceSetGroup}/>
                                                                              <Table
                                                                                  key={_key}
                                                                                  size="small"
                                                                                  pagination={false}
                                                                                  showHeader={false}
                                                                                  columns={
                                                                                      [
                                                                                          {
                                                                                              title: t('date'),
                                                                                              dataIndex: "id",
                                                                                              type: 'date',
                                                                                              render: (_, record) =>
                                                                                                  <div className="flex justify-start items-center">
                                                                                                      {moment(record.id).format("DD/MM/YYYY")}
                                                                                                  </div>,
                                                                                          },
                                                                                      ]
                                                                                  }
                                                                                  dataSource={productPriceSetGroup.groupByDate.reverse().map((e, i) => ({...e, key: i}))}
                                                                                  expandable={{
                                                                                      columnWidth: 5,
                                                                                      expandedRowRender: (record) => (
                                                                                          <div key={record.id}
                                                                                               className="flex flex-col space-y-3 pb-3">
                                                                                              <Table
                                                                                                  key={record.id}
                                                                                                  size="small"
                                                                                                  pagination={false}
                                                                                                  showHeader={productPriceSetGroup.contract_type_cd === 'spot'}
                                                                                                  columns={
                                                                                                      [
                                                                                                          {
                                                                                                              visible: true,
                                                                                                              title: t('location'),
                                                                                                              dataIndex: ["location", "location_name"],
                                                                                                          },
                                                                                                          {
                                                                                                              visible: productPriceSetGroup.contract_type_cd === 'spot',
                                                                                                              title: (
                                                                                                                  <div className="flex flex-col">
                                                                                                                      <div>
                                                                                                                          {t('price')}
                                                                                                                      </div>
                                                                                                                      <div className="font-light">
                                                                                                                          (
                                                                                                                          <span>{
                                                                                                                              _.get(productPriceSetGroup, 'price_unit.name', t('price unit'))
                                                                                                                          }</span> /
                                                                                                                          <span>{
                                                                                                                              _.get(productPriceSetGroup, 'unit.name', t('unit'))
                                                                                                                          }</span>
                                                                                                                          )
                                                                                                                      </div>
                                                                                                                  </div>
                                                                                                              ),
                                                                                                              dataIndex: 'price',
                                                                                                              key: 'price',
                                                                                                              render: (__, record) => (
                                                                                                                  <div
                                                                                                                      className="leading-snug text-sm space-2">
                                                                                                                      {
                                                                                                                          numeral(_.get(record, 'data.0.price')).format("0,0[.]00")
                                                                                                                      }
                                                                                                                  </div>
                                                                                                              )
                                                                                                          }
                                                                                                      ].filter(e => e.visible)
                                                                                                  }
                                                                                                  dataSource={record.data.map((e, i) => ({...e, key: i}))}
                                                                                                  expandable={{
                                                                                                      columnWidth: 5,
                                                                                                      rowExpandable: record => productPriceSetGroup.contract_type_cd === 'forward',
                                                                                                      expandedRowRender: (record) => (
                                                                                                          <div
                                                                                                              key={record.product_price_set_id}
                                                                                                              className="flex flex-col space-y-3 pb-3">
                                                                                                              <Table
                                                                                                                  bordered
                                                                                                                  className="text-xs"
                                                                                                                  pagination={{
                                                                                                                      defaultPageSize: 10,
                                                                                                                      hideOnSinglePage: true
                                                                                                                  }}
                                                                                                                  dataSource={record.data.map((e, i) => ({
                                                                                                                      ...e,
                                                                                                                      key: i
                                                                                                                  }))}
                                                                                                                  columns={[
                                                                                                                      {
                                                                                                                          visible: _.get(productPriceSetGroup, 'contract_type_cd', 'spot') === 'spot',
                                                                                                                          title: (
                                                                                                                              <div
                                                                                                                                  className="flex">
                                                                                                                                  {t('month')}
                                                                                                                              </div>
                                                                                                                          ),
                                                                                                                          dataIndex: 'month',
                                                                                                                          key: 'month',
                                                                                                                          render: (_, record) =>
                                                                                                                              <div
                                                                                                                                  className="flex flex-col leading-none">
                                                                                                                                  <div
                                                                                                                                      className="flex flex-row">{t('spot price')}</div>
                                                                                                                              </div>,
                                                                                                                      },
                                                                                                                      {
                                                                                                                          visible: _.get(productPriceSetGroup, 'contract_type_cd', 'spot') !== 'spot',
                                                                                                                          title: (
                                                                                                                              <div
                                                                                                                                  className="flex">
                                                                                                                                  {t('month')}
                                                                                                                              </div>
                                                                                                                          ),
                                                                                                                          dataIndex: 'month',
                                                                                                                          key: 'month',
                                                                                                                          render: (_, record) =>
                                                                                                                              <div
                                                                                                                                  className="flex flex-col leading-none">
                                                                                                                                  <div
                                                                                                                                      className="flex flex-row">{record.month}-{record.year}</div>
                                                                                                                                  <div
                                                                                                                                      className="text-xs font-light">
                                                                                                                                      {`${moment(record.from_date).format('DD/MM')}-${moment(record.to_date).format('DD/MM')}`}
                                                                                                                                  </div>
                                                                                                                              </div>,
                                                                                                                      },
                                                                                                                      {
                                                                                                                          visible: _.get(productPriceSetGroup, 'contract_type_cd', 'spot') === 'spot',
                                                                                                                          title: (
                                                                                                                              <div
                                                                                                                                  className="flex flex-col">
                                                                                                                                  <div>
                                                                                                                                      {t('price')}
                                                                                                                                  </div>
                                                                                                                                  <div
                                                                                                                                      className="font-light">
                                                                                                                                      (
                                                                                                                                      <span>{
                                                                                                                                          _.get(productPriceSetGroup, 'price_unit.name')
                                                                                                                                      }</span> /
                                                                                                                                      <span>{
                                                                                                                                          _.get(productPriceSetGroup, 'unit.name')
                                                                                                                                      }</span>
                                                                                                                                      )
                                                                                                                                  </div>
                                                                                                                              </div>
                                                                                                                          ),
                                                                                                                          dataIndex: 'price',
                                                                                                                          key: 'flat',
                                                                                                                          render: (__, record) => (
                                                                                                                              <div
                                                                                                                                  className="leading-snug text-sm space-2">
                                                                                                                                  {
                                                                                                                                      numeral(record.price).format("0,0[.]00")
                                                                                                                                  }
                                                                                                                              </div>
                                                                                                                          )
                                                                                                                      },
                                                                                                                      {
                                                                                                                          visible: _.get(productPriceSetGroup, 'contract_type_cd', 'spot') !== 'spot',
                                                                                                                          title: (
                                                                                                                              <div
                                                                                                                                  className="flex flex-col">
                                                                                                                                  <div>
                                                                                                                                      {t('flat price')}
                                                                                                                                  </div>
                                                                                                                                  <div
                                                                                                                                      className="font-light">
                                                                                                                                      (
                                                                                                                                      <span>{
                                                                                                                                          _.get(productPriceSetGroup, 'price_unit.name')
                                                                                                                                      }</span> /
                                                                                                                                      <span>{
                                                                                                                                          _.get(productPriceSetGroup, 'unit.name')
                                                                                                                                      }</span>
                                                                                                                                      )
                                                                                                                                  </div>
                                                                                                                              </div>
                                                                                                                          ),
                                                                                                                          dataIndex: 'price',
                                                                                                                          key: 'flat',
                                                                                                                          render: (__, record) => (
                                                                                                                              <div
                                                                                                                                  className="leading-snug text-sm space-2">
                                                                                                                                  {
                                                                                                                                      numeral(record.price).format("0,0[.]00")
                                                                                                                                  }
                                                                                                                              </div>
                                                                                                                          )
                                                                                                                      },
                                                                                                                      {
                                                                                                                          visible: !_.isEmpty(_.get(productPriceSetGroup, 'unit2')) && !_.isEmpty(_.get(productPriceSetGroup, 'price_unit2')),
                                                                                                                          title: (
                                                                                                                              <div
                                                                                                                                  className="flex flex-col">
                                                                                                                                  <div>
                                                                                                                                      {t('basis price')}
                                                                                                                                  </div>
                                                                                                                                  <div
                                                                                                                                      className="font-light">
                                                                                                                                      (
                                                                                                                                      <span>{
                                                                                                                                          _.get(productPriceSetGroup, 'price_unit2.name')
                                                                                                                                      }</span> /
                                                                                                                                      <span>{
                                                                                                                                          _.get(productPriceSetGroup, 'unit2.name')
                                                                                                                                      }</span>
                                                                                                                                      )
                                                                                                                                  </div>
                                                                                                                              </div>
                                                                                                                          ),
                                                                                                                          dataIndex: 'price2',
                                                                                                                          key: 'basic',
                                                                                                                          render: (__, record) => (
                                                                                                                              <div
                                                                                                                                  className="leading-snug text-sm space-2">
                                                                                                                                  {
                                                                                                                                      !!record.price2 && (
                                                                                                                                          <div>
                                                                                                                                              {
                                                                                                                                                  numeral(record.price2).format("0,0[.]00")
                                                                                                                                              }{' '}
                                                                                                                                              <span
                                                                                                                                                  className="font-light">({record.period_code})</span>
                                                                                                                                          </div>
                                                                                                                                      )
                                                                                                                                  }
                                                                                                                              </div>
                                                                                                                          )
                                                                                                                      },
                                                                                                                  ].filter(e => e.visible)}
                                                                                                              />
                                                                                                          </div>
                                                                                                      ),
                                                                                                  }}
                                                                                              />
                                                                                          </div>
                                                                                      )
                                                                                  }}
                                                                              />
                                                                          </div>
                                                                      )
                                                                  })}

                                                              </div>

                                                          </Tabs.TabPane>
                                                      )
                                                  })}
                                              </Tabs>
                                          </div>
                                      ) : (
                                          <span className="font-medium text-sm text-blue-500 italic">
                                              {`* ${t('no price tag')}`}
                                          </span>
                                      )}

                                  </Spin>
                              )
                          }}
                      </Async>
                  </Modal>
              </React.Fragment>
          )}
      </Toggle>
  )
}