import Icon, {PlusOutlined} from '@ant-design/icons'
import {Button, Divider, Input, Select, Typography} from "antd"
import classnames from "classnames"
import Null from "components/NullComponent"
import _ from 'lodash'
import useTranslate from "modules/local/useTranslate"
import React, {useCallback, useEffect, useState} from "react"
import {AiOutlineGlobal, AiOutlineLink} from "react-icons/ai"
import {BsFacebook, BsLinkedin, BsTwitter} from "react-icons/bs"
import {MdOutlineAdd, MdOutlineRemove} from 'react-icons/md'
import {emptyArray} from "../../../../helpers/emptyObjects";

const LinkedOptions = Object.freeze({
  FACEBOOK: 'facebook',
  LINKED_IN: 'linkedin',
  TWITTER: 'twitter',
  // WEBSITE: 'website'
})

const availablePages = [
  LinkedOptions.FACEBOOK,
  LinkedOptions.LINKED_IN,
  LinkedOptions.TWITTER,
  // LinkedOptions.WEBSITE,
]

const fromPage = page => {
  switch (page) {
    case LinkedOptions.FACEBOOK:
      return {
        inputPlaceholder: 'FB url...'
      }
    case LinkedOptions.LINKED_IN:
      return {
        inputPlaceholder: 'LinkedIn url...'
      }
    case LinkedOptions.TWITTER:
      return {
        inputPlaceholder: 'Twitter url...'
      }
    default:
      return {}
  }
}


export const getPageIcon = page => {
  switch (page) {
    case LinkedOptions.FACEBOOK:
      return <BsFacebook />
    case LinkedOptions.LINKED_IN:
      return <BsLinkedin />
    case LinkedOptions.TWITTER:
      return <BsTwitter />
    case "website":
      return <AiOutlineGlobal />
    default:
      return <AiOutlineLink />
  }
}

export const sampleData = [
  {
    page: LinkedOptions.FACEBOOK,
    url: 'https://wwww.facebook.com/'
  },
  {
    page: LinkedOptions.LINKED_IN,
    url: 'https://www.linkedin.com/'
  },
  {
    page: LinkedOptions.TWITTER,
    url: 'https://twitter.com/'
  }
]

const getDefaultPages = ([key, value]) => {
  return availablePages.map(e => {
    return {
      [key]: e,
      [value]: ''
    }
  })
}

const mergePages = (pages, [key, value]) => {
  const keys = pages.map(e => _.get(e, key))
  const union = _.union(availablePages, keys)
  return union.map(page => {
    const current = _.find(
      pages,
      e => _.isEqual(page, _.get(e, key)),
    )
    return {
      [key]: page,
      [value]: _.get(
        current,
        value,
        '',
      )
    }
  })
}

function mapPages(pages, [name, value]) {
  return pages.map(
    item => ({
      [name]: item[name],
      [value]: item[value]
    })
  )
}

const RenderDropdown = menu => {
  const t = useTranslate()
  return (
    <>
      {menu}
      <Divider className="my-1" />
      <div className="flex px-2 py-1">
        <Input
          // onBlur={e => {
          //   setOther('')
          // }}
          // onPressEnter={e => {
          //   addOther(other)
          // }}
          // onChange={e => {
          //   setOther(e.target.value)
          // }}
          // value={other}
          placeholder={t('others')} />
        <Typography.Link
          className="px-2 my-auto"
          // onClick={() => {
          //   addOther(other)
          // }}
          style={{ whiteSpace: 'nowrap' }}>
          <PlusOutlined /> {t('add')}
        </Typography.Link>
      </div>
    </>
  )
}

const RenderChildOnly = ({ children }) => (children)

const WithChild = props => Child => {
  return (
    <Child {...props} />
  )
}

const NewPageItem = ({
  className,
  filterOption,
  onClick = Null,
                       options = [],
  ...props
}) => {

  const [value, setValue] = useState(null)

  const t = useTranslate()

  const buttonStyle = {
    width: '46px',
    borderTopRightRadius: '0.375rem',
    borderBottomRightRadius: '0.375rem',
  }

  const newLine = value => {
    onClick(value)
  }

  return (
      <Input.Group
          compact
          className={classnames('custom-input-group', className)}>
        <Select
            {...{
              showSearch: true,
              defaultValue: value,
              notFoundContent: t('press enter to create new page'),
              optionFilterProp: "children",
              onChange: val => {
                setValue(val)
              },
              onSearch: val => {
                setValue(val)
              },
              onSelect: page => {
                newLine(page)
              },
              onInputKeyDown: e => {
                if (e.key === 'Enter') {
                  const { value } = e.target
                  newLine(value)
                }
              },
              filterOption: filterOption,
              children: options.map((page) => {
                return (
                    <Select.Option
                        key={page}
                        value={page}>
                      {page}
                    </Select.Option>
                )
              }),
              placeholder: t('add new page'),
              style: {
                width: `calc(100% - ${buttonStyle.width})`,
                borderRightWidth: 0,
              },
            }}
        />
        <Button
            type="primary"
            disabled={_.isEmpty(value)}
            style={buttonStyle}
            onClick={() => {
              newLine(value)
            }}>
          <Icon component={MdOutlineAdd} />
        </Button>
      </Input.Group>
  )
}

const LinkedPages = ({
  fieldName = 'page',
  fieldValue = 'url',
                       data = [],
  onChange = Null,
  onSearch = Null,
  getPages = mapPages,
 classNames = [],
  renderPrimary = WithChild,
  renderSecondary = WithChild,
  renderWrapper = RenderChildOnly
}) => {

  const t = useTranslate()

  const fields = [fieldName, fieldValue]

  const defaultValues = getPages(data, fields)

  // const pages = defaultValues

  // const pages = _.isEmpty(data)
  //   ? getDefaultPages(fields)
  //   : mergePages(defaultValues, fields)

  const filterOption = (input, { children }) => {
    const left = children.toLowerCase()
    const right = input.toLowerCase()
    return left.indexOf(right) >= 0
  }

  const [currentPages, setPages] = useState()

  const [isReady, setReady] = useState(false)

  const [autoFocus, setAutoFocus] = useState(false)

  useEffect(() => {
    if (!isReady) {
      setReady(true)
    } else if (!_.isEqual(
      currentPages,
      defaultValues
      // pages,
    )) {
      // setPages(pages)
      setPages(defaultValues)
      setReady(false)
    }
  }, [data, isReady])

  const existingPage = page => {
    return _.find(
      currentPages,
      e => _.isEqual(e.page, page),
    )
  }

  const addOther = page => {
    if (_.isEmpty(page)) {
      return
    }
    if (!existingPage(page)) {
      const newPages = [
        ...currentPages,
        {
          [fieldName]: page,
          [fieldValue]: ''
        }
      ]
      setPages(newPages)
      setAutoFocus(true)
    }
  }

  const remove = page => {
    if (existingPage(page)) {
      const newPages = [...currentPages]
      const index = _.findIndex(
        newPages,
        e => _.isEqual(e.page, page)
      )
      newPages.splice(index, 1)
      const rest = [...newPages]
      setPages(rest)
      onChange(rest)
    }
  }

  const handleChange = (target, item) => {
    let newPages = currentPages
    const { page, value } = item

    if (target === fieldName) {
      if (existingPage(page)) {
        newPages = newPages.map(e => {
          return _.isEqual(e.page, page)
            ? {
              [fieldName]: value,
              [fieldValue]: e.url,
            }
            : e
        })
      } else {
        newPages = [
          ...newPages,
          {
            [fieldName]: page,
            [fieldValue]: '',
          }
        ]
      }

    } else if (target === fieldValue) {
      // const error = !!!_.startsWith('http', value)
      newPages = newPages.map(e => {
        return _.isEqual(e.page, page)
          ? {
            [fieldName]: e.page,
            [fieldValue]: value,
          }
          : e
      })
    }

    setPages(newPages)
    onChange(newPages)
  }

  const Wrapper = useCallback(props => {
    return renderWrapper({
      ...props
    })
  }, [])

  const [pageClassName, urlClassName] = classNames

  const removeButtonStyle = {
    width: '46px',
    borderTopRightRadius: '0.375rem',
    borderBottomRightRadius: '0.375rem',
  }

  const isNewLine = page => {
    return !_.includes(currentPages, page)
  }

  const hasError = page => {
    const error = _.get(existingPage(page), 'error')
    return error ? 'error' : null
  }

  // const selectURI = (
  //   <Select defaultValue="http://">
  //     {['http://', 'https://'].map((item, index) => {
  //       return (
  //         <Select.Option
  //           key={index}
  //           value={item}>
  //           {item}
  //         </Select.Option>
  //       )
  //     })}
  //   </Select>
  // )

  return (
    <Wrapper>
      {(currentPages || defaultValues).map((item, index) => {
        const page = _.get(item, fieldName)
        const url = _.get(item, fieldValue)

        const newLine = isNewLine(page)

        const { inputPlaceholder } = fromPage(page)

        return (
          <div
            key={index}
            className={classnames(pageClassName)}>
            {renderPrimary({
                showSearch: true,
                defaultValue: page,
                notFoundContent: '',
                placeholder: t('select page'),
                optionFilterProp: "children",
                onChange: value => {
                  handleChange(
                    fieldName, {
                    page,
                    value
                  })
                },
                onInputKeyDown: e => {
                  if (e.key === 'Enter') {
                    const { value } = e.target
                    addOther(value)
                  }
                },
                onSearch: onSearch,
                filterOption: filterOption,
                // dropdownRender: RenderDropdown,
                children: availablePages
                  .filter(e => !existingPage(e))
                  .map((page) => {
                    return (
                      <Select.Option
                        key={page}
                        value={page}>
                        {page}
                      </Select.Option>
                    )
                  })
              })(Select)}
            <Input.Group
              compact
              className={classnames(urlClassName)}>
              {
                renderSecondary({
                  key: isReady,
                  disabled: !isReady,
                  defaultValue: url,
                  allowClear: true,
                  autoFocus: autoFocus,
                  status: hasError(page),
                  placeholder: inputPlaceholder,
                  onChange: e => {
                    handleChange(
                      fieldValue, {
                      page,
                      value: e.target.value
                    })
                  },
                  ...(
                    newLine
                      ? {
                        style: {
                          width: `calc(100% - ${removeButtonStyle.width})`,
                          borderRightWidth: 0,
                          borderTopLeftRadius: '0.375rem',
                          borderBottomLeftRadius: '0.375rem',
                        }
                      }
                      : {}
                  ),
                })(Input)
              }
              {newLine && (
                  <Button
                      type="primary"
                      style={removeButtonStyle}
                      onClick={() => {
                        remove(page)
                      }}>
                    <Icon component={MdOutlineRemove} />
                  </Button>
              )}
            </Input.Group>
          </div>
        )
      })}
      <NewPageItem
        key={(currentPages || emptyArray).length}
        onClick={addOther}
        options={
          availablePages.filter(e => {
            return !existingPage(e)
          })
        }
        filterOption={filterOption}
      />
    </Wrapper>
  )
}

export default LinkedPages