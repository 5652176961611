import {Avatar, Steps} from 'antd'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import * as moment from 'moment'
import React, {useContext} from 'react'
import {AiOutlineArrowRight} from 'react-icons/ai'
import {FaPlayCircle, FaRegDotCircle, FaStopCircle,} from 'react-icons/fa'
import {FaCircleDot} from 'react-icons/fa6'
import {renderElse, renderIf} from 'views/Shared'
import EventContext from '../EventContext'
import {renderSelf} from "../../../Shared";
import {BsPersonFill} from "react-icons/bs";
import {SlateRenderer} from "../../../SlateEditor";
import {convertFromString} from "../../../SlateEditor/functions";
import {isRawContentEmpty} from "../../../SlateEditor/functions/parseRawContent";

export const timeline_steps = Object.freeze({
  START: 'START',
  FINISH: 'FINISH',
})

const DateRange = ({start, end}) => (
  <div className="flex flex-wrap gap-3">
    {renderIf(
      start,
      <div className="text-xs text-color-300 italic">
        {moment(start).format('HH:mm - DD/MM/YYYY')}
      </div>
    )}
    {renderIf(
      end,
      <div className="flex text-xs text-color-300 font-light italic gap-3 items-center">
        <AiOutlineArrowRight/>
        <span>{moment(end).format('HH:mm - DD/MM/YYYY')}</span>
      </div>
    )}
  </div>
)

function toDateString(value) {
  return value ? moment(value).format('hh:mm - DD/MM/YYYY') : null
}

function toHref(value) {
  return value ? (
    <a href={value}
       target="_blank"
       className="flex-1 text-xs text-color-400 italic">
      {value}
    </a>
  ) : null
}

const renderTitle = (item) => (
  <Translate>
    {(t) => (
      <div className="flex flex-col">
        <div className="flex flex-row space-x-2">
          <span className="font-bold text-primary">{t(getTitle(item))}</span>
        </div>
        <DateRange
          start={item?.start_time}
          end={item?.finish_time}
        />
      </div>
    )}
  </Translate>
)

const Speakers = ({items}) => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        {Array.from(items ?? []).map((speaker, index) => (
            <div key={index} className="flex items-center gap-2">
                <Avatar
                    size={25}
                    src={getAvatar(speaker)}
                    icon={<BsPersonFill size={15} className="text-color-300"/>}
                    className="flex flex-center background-200"
                />
                <div className="text-color-000 text-sm font-medium flex-1">
                    {speaker?.speaker?.name ?? speaker?.speaker_name ?? speaker?.value}
                </div>
            </div>
        ))}
    </div>
)

const MetaItem = ({label, value, transform = renderSelf}) =>
  renderIf(
    value,
    <Translate>
      {(t) => (
        <div className="flex items-baseline gap-1">
          <span className="text-xs text-color-400 italic">
            {`${t(label)} : `}
          </span>
          <span className="font-medium text-sm text-color-000">
            {transform(value)}
          </span>
        </div>
      )}
    </Translate>
  )

const EventTimeline = () => {
  const t = useTranslate()

  const {event} = useContext(EventContext)

  const {milestone, timetables, invisible_timeline, description, external_link, location_name, event_type,} = event ?? {}

  const steps = Array.from(timetables ?? [])

  const pastTimetables = steps.filter((item) => {
    if (item?.mandatory === 1 && [timeline_steps.START].includes(item?.title)) {
      return true
    }
    return moment(item?.start_time).isBefore(moment.now())
  })

  return (
    <div className="flex flex-col space-y-2">
      <span className="text-sm text-color-400 italic">{description}</span>
      {[
        {
          label: 'type of event',
          value: t(event_type),
        },
        {
          label: 'location',
          value: location_name,
        },
        {
          label: 'link',
          value: external_link,
          transform: toHref
        },
      ].map(({label, value, transform}, index) => (
        <MetaItem
          key={index}
          {...{
            label,
            value,
            transform,
          }}
        />
      ))}
      {renderElse(
        invisible_timeline,
        <React.Fragment>
          <div className="py-1 font-semibold text-sm uppercase text-color-400">
            {t('timeline')}
          </div>
          <div className="flex flex-col space-y-3 px-0 md:px-3">
            <Steps
                className="custom-step-timetable"
                size="small"
                direction="vertical"
                current={Math.max(pastTimetables.length - 1, 0)}>
              {steps.map((item, index) => {
                const {description} = item ?? {}
                if (item?.mandatory === 1) {
                  switch (item?.title) {
                    case timeline_steps.START:
                      return (
                        <Steps.Step
                          key={index}
                          icon={<FaPlayCircle/>}
                          title={renderTitle(item)}
                        />
                      )
                    case timeline_steps.FINISH:
                      return (
                        <Steps.Step
                          key={index}
                          title={renderTitle(item)}
                          icon={<FaStopCircle className="text-red-500"/>}
                        />
                      )
                    default:
                      break
                  }
                } else {
                  return (
                    <Steps.Step
                      key={index}
                      title={
                        <div className="flex flex-col gap-2">
                          {renderTitle(item)}
                          <Speakers items={item?.speakers}/>
                        </div>
                      }
                      icon={
                        Boolean(Number(milestone) === 1) ? (
                          <FaRegDotCircle/>
                        ) : (
                          <FaCircleDot/>
                        )
                      }
                      description={
                        description &&
                        isRawContentEmpty(description) ? null : (
                          <SlateRenderer
                            value={convertFromString(description)}
                          />
                        )
                      }
                    />
                  )
                }
              })}
            </Steps>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default EventTimeline
