import { Image } from "antd"
import _ from 'lodash'
import { useAppConfig } from "modules/local"
import moment from 'moment'
import React, { useContext } from 'react'
import ReactPlayer from "react-player"
import PhotoSvg from "../../../components/Svgs/PhotoSvg"
import { Photo } from "../../../conts/imageBase64"
import { FB_ID } from "../../../envs/_current/config"
import useTranslate from '../../../modules/local/useTranslate'
import { OrganizationContext } from '../OrganizationContext'
import { Article } from './Article'

export function AboutUs() {
  const { organization } = useContext(
    OrganizationContext
  )
  const appConfig = useAppConfig()
  const t = useTranslate()

  return (
    <div className="flex grid grid-cols-1 lg:grid-cols-4 gap-y-3 lg:gap-3 relative">
      <div id="company-profiles"
        className="flex col-span-1 md:col-span-3 flex-col space-y-3 m-0 md:mr-6">

        <div id="about-company" className="flex rounded h-full">
          <Article className="p-3 relative w-0 flex-1" />
        </div>

        {Boolean(organization.video_url) && (
          <section className=" body-font relative">
            <div id="video-company" className="flex">
              <div className="flex flex-col text-center w-full mb-6 md:mb-12">
                <h2 className="sm:text-3xl text-2xl font-medium title-font mb-2 ">
                  {t('Videos')}
                </h2>

                <div
                  style={{
                    position: 'relative',
                    paddingTop: '60%'
                  }}
                  className="w-full background-200">
                  <div className="w-full h-full absolute inset-0">
                    <ReactPlayer
                      style={{
                        fontSize: '1em'
                      }}
                      width="100%"
                      height="100%"
                      url={organization.video_url}
                      config={{
                        youtube: {
                          playerVars: {
                            controls: 1,
                            showinfo: 1
                          }
                        },
                        facebook: {
                          appId: FB_ID
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

            </div>
          </section>
        )}

      </div>

      <div id="business-profiles"
        className="flex flex-col background-100 rounded p-3">
        <div style={{
          minHeight: 110,
          maxHeight: '21Vw',
          minWidth: 110,
          maxWidth: '21Vw',
        }}
          className="flex col-span-1 items-center justify-center">
          {
            !!_.get(organization, 'avatar') ? (
              <Image preview={false}
                src={_.get(organization, 'avatar')}
                className="object-contain rounded-lg h-full w-full flex items-center justify-center"
                fallback={Photo}
              />
            ) : (
              <PhotoSvg
                style={{
                  minHeight: 100,
                  height: '100%',
                  minWidth: 100,
                  width: '100%',
                  opacity: 0.5
                }}
              />
            )
          }

        </div>
        <div className="flex mt-5">
          <div className="flex flex-col w-full space-y-3">
            {[
              [
                t('Legal Status of Firm'),
                appConfig &&
                appConfig.company_types &&
                appConfig.company_types[
                organization
                  .company_type
                ]
              ],
              [
                t('Nature of Business'),
                appConfig &&
                appConfig.business_types &&
                appConfig.business_types[
                organization
                  .business_type
                ]
              ],
              [
                t('Number of Employees'),
                appConfig &&
                appConfig.number_of_employees &&
                appConfig
                  .number_of_employees[
                organization
                  .number_of_employees
                ]
              ],
              [
                t('Annual Turnover'),
                appConfig &&
                appConfig.annual_turnover &&
                appConfig.annual_turnover[
                organization
                  .annual_turnover
                ]
              ],
              [
                t('Year of Establishment'),
                organization.established_year
              ],
              [
                t('FeedIn Member Since'),
                moment(
                  organization.created
                ).format('MMMM YYYY')
              ],
              [
                t('Website'),
                organization.website
              ],
            ]
              .filter(
                ([__, value]) =>
                  !_.isEmpty(value)
              )
              .map(([label, value], i) => (
                <div
                  key={i}
                  className="flex flex-col">
                  <div className="col-span-1 flex items-center">
                    {/*<BsDot className="text-xl mx-2"/>*/}
                    <div className="text-color-500 text-xs">
                      {label}:
                    </div>
                  </div>
                  <div className="col-span-1 font-bold">
                    {value}
                  </div>
                </div>
              ))}
          </div>
        </div>

      </div>

    </div>
  )
}
