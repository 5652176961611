import Null from 'components/NullComponent'
import React from 'react'
import {connect} from 'react-redux'
import {Redirect, Route} from 'react-router-dom'
import {branch, fromRenderProps, mapProps} from 'recompose'
import {compose} from 'redux'
import {getLogin} from 'redux/selectors'
import RequiredLogin from 'views/403'
import {SubDomainContext} from "../views/SubDomain/SubDomainContext";
import _ from "lodash"
import {ROOT_URL} from "../envs/_current/config";

const FallbackOnlyNonLoginComponent = RequiredLogin
const FallbackOnlyLoginComponent = () => (
  <Redirect to={'/'}/>
)
export const REQUIRED_LOGIN =
  'REQUIRED_LOGIN'
export const REQUIRED_NON_LOGIN =
  'REQUIRED_NON_LOGIN'
export const getValidate = props =>
  props.meta && props.meta.validate
    ? props.meta.validate
    : []
export const createTestValidate = type => props =>
  getValidate(props).includes(type)

export const withValidateRoute = compose(
  connect(state => ({
    login: getLogin(state)
  })),
  mapProps(props => {
    if (
      !props.login &&
      createTestValidate(
        REQUIRED_LOGIN
      )(props)
    ) {
      return {
        ...props,
        component: RequiredLogin
      }
    }
    if (
      props.login &&
      createTestValidate(
        REQUIRED_NON_LOGIN
      )(props)
    ) {
      return {
        ...props,
        component: FallbackOnlyLoginComponent
      }
    }
    return props
  })
)
export const ValidateRoute = withValidateRoute(
  Route
)
export const ValidateComponent = compose(
  connect(state => ({
    login: getLogin(state)
  })),
  fromRenderProps(
    SubDomainContext.Consumer,
    ({url_domain, url_pathname, isSubDomain, id, diff_root}) => ({
      url_domain, url_pathname, isSubDomain, id, diff_root
    })
  ),
  mapProps(props => {
    if (!!!props.withSubDomain) {
      if (!!props.diff_root) {
        window.location.replace(ROOT_URL + _.get(props, 'url_pathname', '/'));
      }
    }
    if (!!!props.isSubDomain) {
      if (!!props.diff_root) {
        window.location.replace(ROOT_URL + _.get(props, 'url_pathname', '/'));
      }
    }
    if (
      !props.login &&
      createTestValidate(
        REQUIRED_LOGIN
      )(props)
    ) {
      return {
        ...props,
        component: RequiredLogin
      }
    }
    if (
      props.login &&
      createTestValidate(
        REQUIRED_NON_LOGIN
      )(props)
    ) {
      return {
        ...props,
        component: FallbackOnlyLoginComponent
      }
    }
    return props
  })
)(({component: C, children, ...propss}) =>
  C ? <C/> : children
);

export const RequiredLoginComponent = compose(
  connect(state => ({
    login: getLogin(state)
  })),
  branch(
    props => !props.login,
    ({fallbackComponent: C = Null}) =>
      C
  )
)(({children}) => children)
