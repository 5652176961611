import React, { useCallback } from 'react'
import { SettingsMenu } from 'views/AppLocals/factory/createSettingLayout'
import DefaultTemplate from 'views/AppLocals/Templates/DefaultTemplate'
import { renderOwnChild } from 'views/Shared'
import CustomSiderMenu from 'views/Shared/components/CustomSiderMenu'
import { Queries } from 'views/Shared/enums/Queries'
import { useMenuHandler } from 'views/Shared/handlers/useMenuHandler'
import {organizationSettings} from "../../AppLocals/Organization/Settings/OrganizationSettings";

const WorkspaceSettingContent = () => {
  const { menuItems, routeEntities } = organizationSettings

  const { activeKey, Component, onMenuSelect } = useMenuHandler({
    actionName: Queries.REL,
    routes: routeEntities,
  })

  const renderMenu = useCallback(
    () => (
      <CustomSiderMenu
        deps={[activeKey]}
        Wrapper={renderOwnChild}>
        <SettingsMenu
          items={menuItems}
          onSelect={onMenuSelect}
          defaultSelectedKeys={[activeKey]}
        />
      </CustomSiderMenu>
    ),
    [menuItems, activeKey]
  )

  return (
    <DefaultTemplate renderMenu={renderMenu}>
      <div className="space-y-3 px-3 lg:pr-3">
        <Component />
      </div>
    </DefaultTemplate>
  )
}

export default WorkspaceSettingContent
