import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4core from '@amcharts/amcharts4/core'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import Loading from 'components/Loading'
import chartSize from 'conts/chartSize'
import viewModes from 'conts/viewModes'
import _ from 'lodash'
import moment from 'moment'
import React, {
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { usePrevious } from 'react-use'
import { decrypt } from 'views/Chart/mapParams/dataHelpers'
import { emptyArray } from '../../../../../helpers/emptyObjects'
import { addGlobalAmChartConfig } from '../../../funcs/addGlobalAmChartConfig'
import { addWatermarkLogoConfig } from '../../../funcs/addWatermarkLogoConfig'

export const AmChartStateless = ({
  item,
  theme,
  setChartWrap,
  viewmode = viewModes.BLOCK,
  ...props
}) => {
  const [chart, setChart] = useState()
  const [data, setData] = useState()
  const [
    cParams,
    setCParams
  ] = useState(null)
  const [id] = useState(
    _.uniqueId('chart_' + item.id)
  )

  const [isReady, setReady] = useState()

  const { isSm } = useContext(
    LayoutContext
  )
  const _chartSize = _.get(
    props,
    'size',
    isSm || viewmode === viewModes.BLOCK
      ? chartSize.SMALL
      : chartSize.NORMAL
  )

  useEffect(() => {
    if (chart) return () => {}
    let thisChart
    let groupData = []
    let chartParams = item.params
    if (!chartParams) return () => {}
    chartParams = JSON.parse(
      JSON.stringify(chartParams)
    )
    let data_values = []
    switch (item.chart_type) {
      case 'SortBarChart':
        thisChart = am4core.createFromConfig(
          {
            width: '100%',
            height: '100%',
            ...chartParams,
            tapToActivate: true,
            tapTimeout: 3000
          },
          id,
          am4charts.XYChart
        )
        thisChart.padding(5, 5, 5, 5)
        thisChart.margin(5, 5, 5, 5)

        thisChart.series.values[0].columns.template.adapter.add(
          'fill',
          function(fill, target) {
            if (
              target.dataItem &&
              target.dataItem.valueX < 0
            ) {
              return '#a55'
            } else {
              return fill
            }
          }
        )

        thisChart.series.values[0].bullets.values[0].label.adapter.add(
          'dx',
          function(defValue, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? -10
              : 10
          }
        )
        thisChart.series.values[0].bullets.values[0].label.adapter.add(
          'horizontalCenter',
          function(defValue, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? 'right'
              : 'left'
          }
        )

        thisChart.series.values[0].columns.template.column.adapter.add(
          'cornerRadiusBottomLeft',
          function(radius, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? 5
              : 0
          }
        )
        thisChart.series.values[0].columns.template.column.adapter.add(
          'cornerRadiusTopLeft',
          function(radius, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? 5
              : 0
          }
        )
        thisChart.series.values[0].columns.template.column.adapter.add(
          'cornerRadiusBottomRight',
          function(radius, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? 0
              : 5
          }
        )
        thisChart.series.values[0].columns.template.column.adapter.add(
          'cornerRadiusTopRight',
          function(radius, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? 0
              : 5
          }
        )

        thisChart.series.values[0].bullets.values[0].label.adapter.add(
          'dx',
          function(defValue, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? -10
              : 10
          }
        )
        thisChart.series.values[0].bullets.values[0].label.adapter.add(
          'horizontalCenter',
          function(defValue, target) {
            return target.dataItem &&
              target.dataItem.valueX < 0
              ? 'right'
              : 'left'
          }
        )

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        thisChart.data = data_values

        break
      case 'BubbleChart':
        thisChart = am4core.createFromConfig(
          {
            width: '100%',
            height: '100%',
            ...chartParams,
            tapToActivate: true,
            tapTimeout: 3000
          },
          id,
          am4charts.XYChart
        )
        thisChart.padding(5, 5, 5, 5)
        thisChart.margin(5, 5, 5, 5)

        thisChart.series.values[0].bullets.values[0].events.on(
          'over',
          function(event) {
            let target = event.target
            thisChart.plotContainer.children.values[0].radius =
              target.pixelRadius + 2
            thisChart.plotContainer.children.values[0].x =
              target.pixelX
            thisChart.plotContainer.children.values[0].y =
              target.pixelY
            thisChart.plotContainer.children.values[0].show()
          }
        )

        thisChart.series.values[0].bullets.values[0].adapter.add(
          'fill',
          function(fill, target) {
            return thisChart.colors.getIndex(
              target.dataItem.index
            )
          }
        )

        thisChart.series.values[0].bullets.values[0].events.on(
          'out',
          function(event) {
            thisChart.plotContainer.children.values[0].hide()
          }
        )

        let hoverState0 = thisChart.series.values[0].bullets.values[0].states.create(
          'hover'
        )
        hoverState0.properties.fillOpacity = 1
        hoverState0.properties.strokeOpacity = 1

        thisChart.series.values[0].heatRules.push(
          {
            target:
              thisChart.series.values[0]
                .bullets.values[0],
            min: 2,
            max: 60,
            property: 'radius'
          }
        )

        thisChart.series.values[0].bullets.values[0].adapter.add(
          'tooltipY',
          function(tooltipY, target) {
            return -target.radius
          }
        )

        thisChart.series.values[0].bullets.values[1].adapter.add(
          'dy',
          function(dy, bullet) {
            if (
              bullet.dataItem
                .dataContext
                .showLabel == 1
            ) {
              bullet.visible = true
              bullet.label.text =
                bullet.dataItem.dataContext.title
            } else {
              bullet.label.text = ''
              bullet.visible = false
            }
            return dy
          }
        )

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        thisChart.data = data_values

        break
      case 'BarChartRace':
        let stepDuration1 = 4000
        let dataIndex1 = 0
        let interval1
        let group_id
        let label1
        let playButton1
        const groupByKey = _.find(
          item.data_columns,
          ['group', true]
        )
        groupData = data_values.reduce(
          (r, a) => {
            r[a[groupByKey.id]] = [
              ...(r[a[groupByKey.id]] ||
                []),
              a
            ]
            return r
          },
          {}
        )

        thisChart = am4core.createFromConfig(
          {
            width: '100%',
            height: '100%',
            ...chartParams,
            tapToActivate: true,
            tapTimeout: 3000
          },
          id,
          am4charts.XYChart
        )
        thisChart.padding(5, 5, 5, 5)
        thisChart.margin(5, 5, 5, 5)

        label1 = thisChart.plotContainer.createChild(
          am4core.Label
        )
        label1.x = am4core.percent(97)
        label1.y = am4core.percent(95)
        label1.horizontalCenter =
          'right'
        label1.verticalCenter = 'middle'
        label1.dx = -15
        label1.fontSize = 50

        playButton1 = thisChart.plotContainer.createChild(
          am4core.PlayButton
        )
        playButton1.x = am4core.percent(
          97
        )
        playButton1.y = am4core.percent(
          95
        )
        playButton1.dy = -2
        playButton1.verticalCenter =
          'middle'
        playButton1.events.on(
          'toggled',
          function(event) {
            if (event.target.isActive) {
              play1()
            } else {
              stop1()
            }
          }
        )

        thisChart.xAxes.values[0].rangeChangeEasing =
          am4core.ease.linear
        thisChart.xAxes.values[0].rangeChangeDuration = stepDuration1

        thisChart.series.values[0].interpolationDuration = stepDuration1
        thisChart.series.values[0].interpolationEasing =
          am4core.ease.linear

        thisChart.series.values[0].columns.template.adapter.add(
          'fill',
          function(fill, target) {
            return thisChart.colors.getIndex(
              target.dataItem.index
            )
          }
        )

        group_id = Object.keys(
          groupData
        )[dataIndex1]
        label1.text = group_id

        const play1 = () => {
          interval1 = setInterval(
            function() {
              nextStep1()
            },
            stepDuration1
          )
          nextStep1()
        }

        const stop1 = () => {
          if (interval1) {
            clearInterval(interval1)
          }
        }

        const nextStep1 = () => {
          group_id = Object.keys(
            groupData
          )[dataIndex1]

          let newData =
            groupData[group_id]
          let itemsWithNonZero = 0
          let _data
          _data =
            thisChart.data || emptyArray
          for (
            let i = 0;
            i < _data.length;
            i++
          ) {
            const newItem = _.find(
              newData,
              function(e) {
                return (
                  e.id.trim() ===
                  _data[i].id.trim()
                )
              }
            )
            if (!_.isEmpty(newItem)) {
              _data[i].value =
                newItem.value
            }

            if (_data[i].value > 0) {
              itemsWithNonZero++
            }
          }
          for (
            let i = 0;
            i < newData.length;
            i++
          ) {
            const newItem = _.find(
              _data,
              function(e) {
                return (
                  e.id.trim() ===
                  newData[i].id.trim()
                )
              }
            )
            if (!_.isEmpty(newItem)) {
              thisChart.data.push(
                newData[i]
              )

              itemsWithNonZero++
            }
          }

          if (dataIndex1 === 0) {
            thisChart.series.values[0].interpolationDuration =
              stepDuration1 / 4
            thisChart.xAxes.values[0].rangeChangeDuration =
              stepDuration1 / 4
          } else {
            thisChart.series.values[0].interpolationDuration = stepDuration1
            thisChart.xAxes.values[0].rangeChangeDuration = stepDuration1
          }

          thisChart.invalidateRawData()
          label1.text = group_id

          thisChart.xAxes.values[0].zoom(
            {
              start: 0,
              end:
                itemsWithNonZero /
                thisChart.xAxes
                  .values[0].dataItems
                  .length
            }
          )

          dataIndex1++
          if (
            dataIndex1 >=
            Object.keys(groupData)
              .length
          ) {
            dataIndex1 = 0
            stop1()
          }
        }

        thisChart.yAxes.values[0].sortBySeries =
          thisChart.series.values[0]

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        if (!_.isEmpty(groupData)) {
          thisChart.data = JSON.parse(
            JSON.stringify(
              groupData[group_id]
            )
          )
          thisChart.xAxes.values[0].zoom(
            {
              start: 0,
              end:
                1 /
                thisChart.data.length
            }
          )
        }

        break
      case 'XYChart':
        thisChart = am4core.createFromConfig(
          {
            width: '100%',
            height: '100%',
            responsive: {
              enabled: true
            },
            ...chartParams,
            tapToActivate: true,
            tapTimeout: 3000
          },
          id,
          am4charts.XYChart
        )
        thisChart.padding(5, 5, 5, 5)
        thisChart.margin(5, 5, 5, 5)

        if (
          thisChart.series.values
            .length === 1 &&
          thisChart.series.values[0]
            .columns &&
          thisChart.series.values[0]
            .columns.template &&
          !(
            thisChart.series.values[0]
              .columns.template
              .propertyFields &&
            thisChart.series.values[0]
              .columns.template
              .propertyFields.fill
          )
        ) {
          thisChart.series.values[0].columns.template.adapter.add(
            'fill',
            function(fill, target) {
              return thisChart.colors.getIndex(
                target.dataItem.index
              )
            }
          )
        }

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        // thisChart.data = data_values;

        break
      case 'PivotStackedColumnChart':
        let columnSeries = {
          type: 'ColumnSeries',
          id: 'id',
          name: 'name',
          dataFields: {
            valueY: 'valueY',
            categoryX: 'time'
          },
          sequencedInterpolation: true,
          stacked: true,
          columns: {
            template: {
              width: '80%',
              tooltipText:
                '[bold]{name}[/]\n[font-size:0.68rem]{categoryX}: {valueY}'
            }
          }
        }
        if (
          chartParams &&
          chartParams.series &&
          chartParams.series.length > 0
        ) {
          columnSeries =
            chartParams.series[0]
        }
        chartParams.series = []

        const createSeries = (
          chartParams,
          series,
          field
        ) => {
          if (chartParams) {
            let _s = _.cloneDeep(series)
            _s.id = field
            _s.name = field

            if (
              _.has(
                _s,
                'dataFields.valueX'
              )
            )
              _s.dataFields.valueX = field
            if (
              _.has(
                _s,
                'dataFields.valueY'
              )
            )
              _s.dataFields.valueY = field

            chartParams.series.push(_s)
          }
        }

        _.get(
          item,
          'data_columns',
          []
        ).map(c => {
          if (c.valueType === 'value') {
            createSeries(
              chartParams,
              columnSeries,
              c.id
            )
          }
        })

        thisChart = am4core.createFromConfig(
          {
            width: '100%',
            height: '100%',
            responsive: {
              enabled: true
            },
            ...chartParams,
            tapToActivate: true,
            tapTimeout: 3000
          },
          id,
          am4charts.XYChart
        )
        thisChart.padding(5, 5, 5, 5)
        thisChart.margin(5, 5, 5, 5)

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        break
      case 'PivotXYChart':
        // chartParams =
        // LOOP PIVOT COLUMN TO SERIES
        if (
          chartParams.series &&
          chartParams.series.length > 0
        ) {
          let _ind = 0
          let origSeries = _.cloneDeep(
            chartParams.series
          )
          _.forEach(
            item.data_columns,
            (e, i) => {
              if (
                typeof e === 'object' &&
                e.valueType === 'value'
              ) {
                let _origS = _.cloneDeep(
                  origSeries[0]
                )
                _origS = JSON.stringify(
                  _origS
                )
                _origS = (
                  _origS || ''
                ).replaceAll(
                  'pivot_column',
                  e.id
                )
                _origS = JSON.parse(
                  _origS
                )
                if (
                  _origS.dataFields
                    .valueX
                )
                  _origS.dataFields.valueX =
                    e.id
                if (
                  _origS.dataFields
                    .openValueX
                )
                  _origS.dataFields.openValueX =
                    e.id
                if (
                  _origS.dataFields
                    .valueY
                )
                  _origS.dataFields.valueY =
                    e.id
                if (
                  _origS.dataFields
                    .openValueY
                )
                  _origS.dataFields.openValueY =
                    e.id

                chartParams.series[
                  _ind
                ] = {
                  ..._origS,
                  name: e.id,
                  id: 's' + _ind
                }
                _ind += 1
              } else if (
                typeof e === 'object' &&
                e.valueType ===
                  'valueObj'
              ) {
                let _origSeries = _.cloneDeep(
                  origSeries
                )
                _origSeries = JSON.stringify(
                  _origSeries
                )
                _origSeries = _origSeries.replaceAll(
                  'pivot_column',
                  e.id
                )
                _origSeries = JSON.parse(
                  _origSeries
                )

                _.flatMap(
                  _origSeries,
                  origS => {
                    let _origS = _.cloneDeep(
                      origS
                    )
                    if (
                      _origS.dataFields
                        .valueX
                    )
                      _origS.dataFields.valueX =
                        e.id +
                        '-' +
                        _origS
                          .dataFields
                          .valueX
                    if (
                      _origS.dataFields
                        .openValueX
                    )
                      _origS.dataFields.openValueX =
                        e.id +
                        '-' +
                        _origS
                          .dataFields
                          .openValueX
                    if (
                      _origS.dataFields
                        .valueY
                    )
                      _origS.dataFields.valueY =
                        e.id +
                        '-' +
                        _origS
                          .dataFields
                          .valueY
                    if (
                      _origS.dataFields
                        .openValueY
                    )
                      _origS.dataFields.openValueY =
                        e.id +
                        '-' +
                        _origS
                          .dataFields
                          .openValueY

                    chartParams.series[
                      _ind
                    ] = {
                      ..._origS,
                      name: e.id,
                      id: 's' + _ind
                    }
                    _ind += 1
                  }
                )
              }
            }
          )

          // setCParams(chartParams);
        }

        thisChart = am4core.createFromConfig(
          {
            width: '100%',
            height: '100%',
            responsive: {
              enabled: true
            },
            tapToActivate: true,
            tapTimeout: 3000,
            ...chartParams
          },
          id,
          am4charts.XYChart
        )
        thisChart.padding(5, 5, 5, 5)
        thisChart.margin(5, 5, 5, 5)

        if (
          thisChart.series.values
            .length === 1 &&
          thisChart.series.values[0]
            .columns &&
          thisChart.series.values[0]
            .columns.template
        ) {
          thisChart.series.values[0].columns.template.adapter.add(
            'fill',
            function(fill, target) {
              return thisChart.colors.getIndex(
                target.dataItem.index
              )
            }
          )
        }

        addGlobalAmChartConfig(
          item,
          thisChart
        )

        addWatermarkLogoConfig(
          item,
          thisChart
        )

        thisChart.data = data_values

        break
      default:
        break
    }
    if (thisChart) {
      thisChart.events.onAll(event => {
        if (event === 'ready') {
          setReady(true)
        }
      })
      thisChart.svgContainer.autoResize = false
      thisChart.exporting.useWebFonts = false
      thisChart.tapToActivate = true
      thisChart.tapTimeout = 1000
      thisChart.dragGrip.disabled = false
      setChartWrap({
        getChart: () => thisChart,
        type: 'amchart'
      })
      setChart(thisChart)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    _chartSize,
    chart,
    id,
    isSm,
    item
    // setChartWrap
  ])
  const predata = usePrevious(data)
  useEffect(() => {
    if (!item) return null
    const data_values = (() => {
      let data_values =
        Number(item.data_encrypted) !==
        1
          ? item.data_values
          : decrypt(
              item.data_values,
              item.data_passphrase
            )

      if (!!item.is_static_data) return data_values

      const data_types = _.get(
        item,
        'data_columns',
        []
      ).map(c => c.type || 'string')
      const transpose = (r, a) => {
        return a.map((v, i) => {
          if (r[i]) {
            if (
              data_types[i] ===
              'datetime'
            ) {
              return [
                ...r[i],
                moment
                  .utc(v)
                  .local()
                  .valueOf()
              ]
            } else {
              return [...r[i], v]
            }
          } else {
            return [...[], v]
          }
        })
      }

      data_values = (
        data_values || emptyArray
      ).map(e =>
        Object.fromEntries(
          [
            _.get(
              item,
              'data_columns',
              []
            ).map(c => c.id),
            e
          ].reduce(transpose, [])
        )
      )

      if (data_values.length) {
        // show last value
        data_values[
          data_values.length - 1
        ]['bulletDisabled'] = false

        // show first of high and low
        const valCols = _.filter(
          item.data_columns,
          function(o) {
            return (
              o.id && o['bulletEnabled']
            )
          }
        )

        let lastMaxObj = null
        let lastMinObj = null
        let lastMaxVal = null
        let lastMinVal = null
        for (
          let j = 0;
          j < valCols.length;
          j++
        ) {
          if (valCols[j].id) {
            const maxObj = _.maxBy(
              data_values,
              function(o) {
                return o[valCols[j].id]
              }
            )
            if (maxObj) {
              if (!lastMaxObj) {
                lastMaxObj = maxObj
                lastMaxVal =
                  maxObj[valCols[j].id]
              }

              if (
                maxObj[valCols[j].id] >
                lastMaxVal
              ) {
                lastMaxObj = maxObj
              }
            }
            const minObj = _.minBy(
              data_values,
              function(o) {
                return o[valCols[j].id]
              }
            )
            if (minObj) {
              if (!lastMinObj) {
                lastMinObj = minObj
                lastMinVal =
                  minObj[valCols[j].id]
              }
              if (
                minObj[valCols[j].id] <
                lastMinVal
              )
                lastMinObj = minObj
            }
          }
        }
        const maxIndex = data_values.indexOf(
          lastMaxObj
        )
        if (
          maxIndex &&
          data_values[maxIndex]
        )
          data_values[maxIndex][
            'bulletDisabled'
          ] = false

        const minIndex = data_values.indexOf(
          lastMinObj
        )
        if (
          minIndex &&
          data_values[minIndex]
        )
          data_values[minIndex][
            'bulletDisabled'
          ] = false
      }

      // tính cộng dồn lại với data_columns có thuộc tính 'cumulated': true
      let _found_cols
      if (!_.isEmpty(data_values)) {
        _found_cols = _.filter(
          item.data_columns,
          { cumulated: true }
        )
        _.forEach(_found_cols, function(
          col
        ) {
          let _prevValue = 0
          data_values = (
            data_values || emptyArray
          ).map(e => {
            _prevValue =
              _prevValue + e[col.id]
            return {
              ...e,
              [col.id]: _prevValue
            }
          })
        })
      }

      // flatten object fields. nếu valueType OR type = valueObj
      if (!_.isEmpty(data_values)) {
        _found_cols = _.filter(
          item.data_columns,
          { valueType: 'valueObj' }
        )
        if (_found_cols.length) {
          data_values = _.flatMap(
            data_values,
            e => {
              return _.reduce(
                e,
                (result, val, key) => {
                  return {
                    ...result,
                    ...(typeof val !==
                    'object'
                      ? { [key]: val }
                      : _.reduce(
                          val,
                          (
                            result1,
                            val1,
                            key1
                          ) => {
                            return {
                              ...result1,
                              [key +
                              '-' +
                              key1]: val1
                            }
                          },
                          {}
                        ))
                  }
                },
                {}
              )
            }
          )
        }
      }

      if (!_.isEmpty(data_values)) {
        _found_cols = _.filter(
          item.data_columns,
          { sort: true }
        )

        if (_found_cols.length > 0) {
          const _sorts = _found_cols.map(
            e => e.id
          )
          data_values = _.orderBy(
            data_values,
            _sorts
          )
        }
      }

      return data_values
    })()

    if (
      JSON.stringify(predata) !==
      JSON.stringify(data_values)
    ) {
      setData(data_values)
    }
  }, [
    item.data_columns,
    item.data_encrypted,
    item.data_passphrase,
    item.data_values,
    predata
  ])

  useEffect(() => {
    if (chart && data) {
      chart.data = data
      if (
        chart &&
        chart.svgContainer &&
        !!!chart.svgContainer.autoResize
      )
        chart.svgContainer.autoResize = true
    }
    // console.log({
    //   count: (() => {
    //     window.count = window.count || 0
    //     window.count = window.count + 1
    //     return window.count
    //   })()
    // })
  }, [chart, data])
  useEffect(() => {
    return () => {
      if (chart) {
        setTimeout(() =>
          chart.dispose()
        )
      }
    }
  }, [chart])

  const body = useMemo(
    () => (
      <>
        <div
          className="flex-1"
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            minHeight: '200px'
          }}
          id={id}></div>
        {!isReady && (
          <div className="flex absolute inset-0 items-center justify-center">
            <Loading />
          </div>
        )}
      </>
    ),
    [id, isReady]
  )

  return body
}

export default AmChartStateless
