import { Tabs } from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useCallback, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { gConfigs, gPaths } from 'views/AppLocals/configs'
import { mapPathParam } from 'views/AppLocals/functions/routerHelper'
import useNavRouteAction from 'views/AppLocals/hooks/useNavRouteAction'
import { NavRoutes } from 'views/AppLocals/hooks/useNavRoutes'
import { notEmpty, Null } from 'views/Shared'
import EventContext from '../EventContext'

const EventNav = ({ Wrapper = 'div' }) => {
  const t = useTranslate()

  const params = useParams()

  const history = useHistory()

  const id = _.get(params, 'id')

  const rootPath = gPaths.event

  const {
    event,
    routeEntities = [],
    isValidRoute = Null,
    getDefaultAction = Null,
  } = useContext(EventContext)

  const defaultAction = getDefaultAction(rootPath)

  const { currentAction } = useNavRouteAction({
    anchor: id,
    defaultAction,
    isAction: isValidRoute,
  })

  const pathTransform = useCallback(
    (path, segment) =>
      [
        _.isEmpty(id)
          ? path
          : mapPathParam(path, {
              id,
            }),
        segment,
      ]
        .filter(notEmpty)
        .join('/'),
    [id]
  )

  const tabPanes = useMemo(
    () =>
      routeEntities
        .filter(
          ({ isInvisible = Null }) =>
            !isInvisible({
              item: event,
            })
        )
        .map(({ path, breadcrumbName = Null }) => (
          <Tabs.TabPane
            tab={breadcrumbName(t)}
            key={path}>
            <NavRoutes
              rootPath={rootPath}
              routes={routeEntities}
              transform={pathTransform}
              defaultAction={defaultAction}
            />
          </Tabs.TabPane>
        )),
    [event, rootPath, defaultAction, routeEntities, pathTransform]
  )

  return (
    <Wrapper
      id="content"
      className="space-y-3 background">
      <Tabs
        tabBarStyle={gConfigs.styles.tabBar}
        destroyInactiveTabPane={true}
        //key={currentAction}
        onChange={(activeKey) => {
          history.push({
            pathname: pathTransform(rootPath, activeKey),
            hash: '#content',
          })
        }}
        activeKey={currentAction}>
        {tabPanes}
      </Tabs>
    </Wrapper>
  )
}

export default EventNav
