import classnames from 'classnames'
import Null from 'components/NullComponent'
import React from 'react'
import {NavLink} from 'react-router-dom'
import './RightHeader.css'

const RightHeader = ({
  options = [
    // {
    // 		key: 'search',
    // 		icon: <Icon type="search" />
    // 	},
    // 	{
    // 		key: 'user',
    // 		icon: (
    // 			<Avatar
    // 				data-effect="ripple"
    // 				src={STORAGE_URL + '/avatar/2019/06/26/no.noo.nooo.yes.png'}
    // 			/>
    // 		)
    // 	}
  ],
  onItemClick = Null,
  currentIndex,
  children
}) => {
  return (
    <div className="navbar-right flex items-center pr-5">
      {options.map((option, i) =>
        option.to ? (
          <NavLink
            key={option.key}
            className="action"
            to={option.to}
            activeClassName="active">
            {option.icon}
          </NavLink>
        ) : (
          <div
            key={option.key}
            onClick={() =>
              onItemClick(i)
            }
            className={classnames(
              'action',
              {
                active:
                  i === currentIndex
              }
            )}>
            {option.icon}
          </div>
        )
      )}
      {children}
    </div>
  )
}

export default RightHeader
