import {Button} from 'antd'
import {getType} from 'apis/model/base'
import classNames from 'classnames'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {useEffect, useRef} from 'react'
import {IoCheckmarkCircleOutline} from 'react-icons/io5'
import NoFoundPage from 'views/NoFoundPage'
import {getResponseItem, Null, renderOwnChild} from 'views/Shared'
import {notifyOnError} from './createErrorEvent'
import {renderFromToTime, renderIf, renderIfElse} from "../../Shared";

const ExportContainer = ({item, query, apiInfo, values, children}) => {
  const t = useTranslate()

  const {
    response,
    isLoading,
    handleAsyncAction: handleExport,
  } = useAsyncAction({
    query,
    values,
    apiInfo,
    onError: notifyOnError(t),
  })

  if (_.isFunction(children)) {
    return children(handleExport, {
      isLoading,
      ...getResponseItem(response),
    })
  } else {
    return children || null
  }
}

const Downloader = ({
                      array,
                      children,
                      downloadURL,
                      delay = 3000,
                      auto = false,
                      onSuccess = Null,
                    }) => {
  const t = useTranslate()

  const timer = useRef()

  useEffect(() => {
    if (!!downloadURL && auto) {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      timer.current = setTimeout(() => {
        onSuccess({downloadURL})
      }, delay)
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [downloadURL, auto])

  const renderActions = (child) => {
    if (_.isEmpty(array)) {
      return child
    }
    return Array.from(array || []).map((path, index) => (
      <div
        key={index}
        className="flex items-center gap-2 w-full p-3 background border border-color-50 rounded-lg">
        <div className="flex flex-col items-start flex-1">
          <span className="text-xs text-color-400 font-light italic">{`${t(
            'file'
          )}:`}</span>
          <a
            className="no-underline flex-1 text-sm font-semibold italic truncate"
            target="_blank"
            href={path}
            rel="noopener noreferrer">
            {_.last(_.split(path, '/'))}
          </a>
        </div>
        <Button
          className="button-rounded-lg no-border"
          type="primary">
          <a
            className="no-underline"
            target="_blank"
            href={path}
            rel="noopener noreferrer">
            {t('download')}
          </a>
        </Button>
      </div>
    ))
  }

  return (
    <div>
      {!!downloadURL && (
        <div className="mt-3 mb-6 border-2 border-green-500 rounded-lg">
          <div className="flex items-center gap-2 p-3">
            <IoCheckmarkCircleOutline
              size={30}
              className="text-green-500"
            />
            <div className="flex flex-col items-start gap-1">
              <span className="font-bold text-green-600 leading-tight">
                {`${t('the data has been generate')} !`}
              </span>
              <span className="font-light text-xs text-green-500 italic">
                {t(
                  'please click the {download} button below to download to your device',
                  {
                    download: (
                      <span className="font-bold text-green-700">
                        {' '}
                        {t('download')}{' '}
                      </span>
                    ),
                  }
                )}
              </span>
            </div>
          </div>
        </div>
      )}

      <div
        className={classNames(
          _.isEmpty(array) ? 'flex justify-center' : 'flex flex-col'
        )}>
        {!!downloadURL
          ? renderActions(
            <Button
              className="no-shadow no-text-shadow rounded-lg"
              type="primary">
              <a
                className="no-underline"
                target="_blank"
                href={downloadURL}
                rel="noopener noreferrer">
                {t('download')}
              </a>
            </Button>
          )
          : children || null}
      </div>
    </div>
  )
}

const ExportModalContent = ({title, item, Wrapper = renderOwnChild, ...props}) => {

  const t = useTranslate()
  const {time_open, time_close} = item ?? {}

  if (!item || _.isEmpty(item)) {
    return <NoFoundPage/>
  }

  return (
    <Wrapper>
      <div className="flex flex-col w-full">
        <div className="flex flex-col gap-2">
          <span className="text-color-300 uppercase font-medium space-x-1">
            {t('export data')}{' '}{renderIfElse(title, t(title), t(getType(item)))}
          </span>
          <div className="background border border-color-50 shadow-items-md px-3 py-2 rounded-md">
            <div className="flex flex-col gap-1 flex-1">
              <span className="text-color-000 font-bold">
                {getTitle(item)}
              </span>
              <span className="font-bold text-primary text-xs italic">
                <span className="font-normal text-color-500 mr-1">
                  {t('organized by')}
                </span>
                {_.get(item, 'owner.title')}
              </span>
              {renderIf(
                _.some([time_open, time_close]),
                <span className="font-bold text-color-100 text-xs italic">
                    <span className="font-normal text-color-500 mr-1">
                      {`${t('time takes place')} : `}
                    </span>
                  {renderFromToTime(time_open, time_close)}
                  </span>
              )}

            </div>
          </div>
        </div>
        <ExportContainer
          item={item}
          {...props}>
          {(handleExport, {path, paths, isLoading}) => (
            <Downloader
              array={paths}
              downloadURL={path}>
              <Button
                type="primary"
                className="button-rounded-lg no-border mt-6"
                disabled={!!isLoading}
                onClick={() => {
                  handleExport()
                }}>
                {t('export data')}
              </Button>
            </Downloader>
          )}
        </ExportContainer>
      </div>
    </Wrapper>
  )
}


export const ExporterLoader = ({id, type}) => {
  if (_.isEmpty(id)) {
    return null
  }
  switch (type) {
    default:
      return null
  }
}
