import {Col, Tooltip} from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo} from 'react'
import {Link, withRouter} from 'react-router-dom'
import {nest} from 'recompose'
import {staticPaths} from 'routes/staticPaths'
import SearchContext from 'views/Search/SearchContext'
import SearchProvider, {defaultSearchType, SearchParams, SearchTypes} from 'views/Search/SearchProvider'
import DiscoveryExplorerHeader from './components/DiscoveryExplorerHeader'
import DiscoveryExplorerNavigator from './components/DiscoveryExplorerNavigator'
import DiscoveryExplorerResult from './components/DiscoveryExplorerResult'
import DiscoveryExplorerSearchBar from './components/DiscoveryExplorerSearchBar'
import SearchIntroduction from './components/SearchIntroduction'
import DiscoveryExplorerContext, {DiscoveryExplorerProvider} from './DiscoveryExplorerContext'
import DiscoveryExplorerLayout from './DiscoveryExplorerLayout'
import {SearchGroupIcon} from "../../components/AppIcon";
import classNames from "classnames"
import Null from 'components/NullComponent'
import {discoveryExplorerRouteEntities} from "./discoveryExplorerRouteEntities"

const ItemDescriptions = ({
                            items,
                            renderItem,
                            Wrapper = Col
                          }) => {
  const t = useTranslate()
  const array = useMemo(
    () =>
      Array.from(items || []).map(
        item =>
          _.isString(item)
            ? {
              value: item
            }
            : {
              ...item,
              value: _.get(
                item,
                'value'
              )
            }
      ),
    [t, items]
  )
  return (
    <Wrapper className="p-col-0">
      {array.map(
        renderItem
          ? renderItem
          : ({value}, index) => (
            <div
              key={index}
              className="flex flex-col text-sm italic mb-3">
                <span className="ml-3 font-light text-color-300 leading-tight">
                  {`- ${t(value)}`}
                </span>
            </div>
          )
      )}
    </Wrapper>
  )
}

const mapIntroSchema = [
  // {
  //   type: SearchTypes.DATASET,
  //   component: (
  //     <SearchIntroduction
  //       to={staticPaths.home.pathname}
  //       label="dataset"
  //       header="dataset"
  //       title="???"
  //       description={
  //         <ItemDescriptions
  //           items={[
  //             'dataset dataset dataset'
  //           ]}
  //         />
  //       }
  //     />
  //   )
  // },
  {
    type: SearchTypes.CHANNEL,
    component: (
      <SearchIntroduction
        to={
          staticPaths.createChannel
            .pathname
        }
        label="create channel"
        header="channel"
        title="???"
        description={
          <ItemDescriptions
            items={[
              'Information channel is a function to support information sharing and disclosure of organizations and groups',
              'Information channels are maintained throughout',
              'Groups, Organizations... can set up 1 or more channels'
            ]}
          />
        }
      />
    )
  },
  {
    type: SearchTypes.EVENT,
    component: (
      <SearchIntroduction
        to={
          staticPaths.createEvent?.pathname
        }
        label="create event"
        header="event"
        title="???"
        description={
          <ItemDescriptions
            items={[
              'Event is an activity for the purpose of sharing information, recording progress for participants and interested communities',
              'The event took place in a finite time',
              'Events are created from Groups, Organizations, ...'
            ]}
          />
        }
      />
    )
  },
  {
    type: SearchTypes.ORGANIZATION,
    component: (
      <SearchIntroduction
        to={
          staticPaths.createOrganization?.pathname
        }
        label="create organization"
        header="provider"
        title="???"
        description={
          <ItemDescriptions
            items={[
              'Organization is a Group, Association, Community..., established to share commodity price information to farmers',
              'Organize activities throughout, be recognized for the group activities',
              'Organizations are created by individuals, and can be made up of 1 to many individuals or organizations, can be used as a branch of another organization',
              'Organizations use tools: Events, Information Channels, Articles to operate and share information with others'
            ]}
          />
        }
      />
    )
  },
  {
    type: SearchTypes.ORGANIZATION,
    component: (
      <SearchIntroduction
        to={
          staticPaths.createProvider?.pathname
        }
        label="user"
        header="user"
        title="???"
        description={
          <ItemDescriptions
            items={['user user user']}
          />
        }
      />
    )
  }
]


const DiscoveryExplorer = () => {
  const t = useTranslate()
  const {searchParams} = useContext(SearchContext)

  const {component: meta = null} =
  _.find(mapIntroSchema, {
    type: searchParams.type,
  }) ?? {}

  const type = _.get(searchParams, SearchParams.TYPE) || defaultSearchType

  const keyword = _.get(searchParams, SearchParams.KEYWORD)

  const showSearchResult = Boolean(keyword || ![SearchTypes.ALL].includes(type))

  return (
    <DiscoveryExplorerProvider type={type}>
      <DiscoveryExplorerContext.Consumer>
        {({filterRows, filterMenu}) => (
          <DiscoveryExplorerLayout
            menu={filterMenu}
            renderMenu={_.isEmpty(filterRows) ? Null : undefined}
            Header={DiscoveryExplorerHeader}>
            <div className="flex flex-wrap justify-center gap-1 my-3 px-1">
              {discoveryExplorerRouteEntities.map(
                ({component: Component, ...route}, index) => (
                  <Tooltip
                    key={index}
                    placement="bottom"
                    title={t(route.hint ?? route.type)}>
                    <Link
                      to={route.href ?? route.path}
                      className={classNames(
                        'w-8 h-8 flex flex-center p-1 rounded-md cursor-pointer',
                        type === route.type
                          ? 'border border-primary bg-primary'
                          : 'border border-primary-300 hover:bg-primary-50'
                      )}>
                      <SearchGroupIcon
                        size={14}
                        type={route.type}
                        className={type === route.type && 'text-white'}
                      />
                    </Link>
                  </Tooltip>
                )
              )}
            </div>
            {meta && (
              <div className="w-full my-3 max-w-3xl mx-auto px-3">{meta}</div>
            )}
            <div className="space-y-3 min-h-screen h-full mx-3">
              <DiscoveryExplorerSearchBar key={type}/>
              {showSearchResult ? (
                <DiscoveryExplorerResult/>
              ) : (
                <DiscoveryExplorerNavigator/>
              )}
              <div className="h-20"/>
            </div>
          </DiscoveryExplorerLayout>
        )}
      </DiscoveryExplorerContext.Consumer>
    </DiscoveryExplorerProvider>
  )
}

export default nest(
  withRouter(SearchProvider),
  DiscoveryExplorer
)
