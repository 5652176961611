import {organization_Edit_Api} from 'apis'
import {getId} from 'apis/model/base'
import _ from 'lodash'
import React, {useContext} from 'react'
import {MultiCategoryPicker} from 'views/AppLocals/custom/TagsPicker'
import createEditableForm from 'views/AppLocals/factory/createEditableForm'
import {createControlledFormField} from 'views/AppLocals/factory/createFormField'
import {mapEntityOptions} from 'views/AppLocals/factory/createSelectEntityProps'
import {bindQueryParam} from 'views/AppLocals/functions/routerHelper'
import OrganizationContainerContext from '../OrganizationContainerContext'

const formFields = [
  {
    name: 'tags',
    defaultValue: undefined,
  },
  {
    name: 'categories',
    defaultValue: undefined,
  },
]

const formSchema = [
  // {
  //   title: 'tags',
  //   children: [
  //     {
  //       name: 'tags',
  //       component: createControlledFormField({
  //         Component: TagsPicker,
  //       }),
  //     },
  //   ],
  // },
  {
    title: 'categories',
    children: [
      {
        name: 'categories',
        component: createControlledFormField({
          Component: MultiCategoryPicker,
        }),
      },
    ],
  },
]

const OrganizationSettingCategories = () => {
  const {organization} = useContext(OrganizationContainerContext)

  const validationSchema = undefined

  const organization_id = getId(organization)

  if (_.isEmpty(organization)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: organization,
    validationSchema,
    query: bindQueryParam({
      id: organization_id,
    }),
    onPreSubmit: ({
                    tags,
                    categories,
                    subcategories,
                    defaultSubCategories,
                    ...values
                  }) =>
      _.omitBy(
        {
          ...values,
          tags: tags ? Array.from(tags || []).join(',') : undefined,
          categories: categories
            ? Array.from(categories || []).join(',')
            : undefined,
          subcategories: subcategories
            ? Array.from(subcategories || []).join(',')
            : undefined,
        },
        _.isUndefined
      ),
    initialized: ({tags, categories, subcategories, ...values}) => ({
      ...values,
      tags: Array.from(tags || []).map(({tags}) => tags),
      categories: mapEntityOptions(categories),
      subcategories: _.isArray(subcategories)
        ? Array.from(subcategories).map(getId)
        : undefined,
      defaultSubCategories: subcategories,
    }),
    apiInfo: organization_Edit_Api,
  })
}

export default OrganizationSettingCategories
