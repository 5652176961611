import FireOutlined from '@ant-design/icons/lib/icons/FireOutlined'
import {Avatar} from 'antd'
import _ from 'lodash'
import * as moment from 'moment'
import React from 'react'
import {FaHeadphonesAlt, FaUser} from 'react-icons/fa'
import {FcFlowChart, FcStatistics} from 'react-icons/fc'
import {ImLibrary} from 'react-icons/im'
import {IoApps, IoSpeedometer} from 'react-icons/io5'
import {SearchTypes} from 'views/Search/SearchProvider'
import {getFullAvatarUrl} from '../apis/model/base'
import {ChannelIcon} from './icons/ChannelIcon'
import {DatasetIcon} from './icons/DatasetIcon'
import {
    BsCalendar2Week,
    BsCalendar2WeekFill, BsDatabaseFill, BsDiagram3, BsDiagram3Fill, BsEasel2,
    BsFillBarChartLineFill,
    BsFillPersonFill, BsGear, BsGearFill, BsInboxes,
    BsMegaphoneFill, BsPeople, BsPeopleFill, BsPersonFill, BsRadioactive, BsSpeedometer2,
    BsVectorPen
} from "react-icons/bs";
import {ChannelFillSvg} from "./Svgs/ChannelSvg";
import classNames from 'classnames'
import {FaFileInvoiceDollar, FaWarehouse} from "react-icons/fa6";
import {WorkSpaceActions} from "../views/Workspace/Workspace";
import {GoPulse} from "react-icons/go";
import {MdShelves} from "react-icons/md";
import {RiQuillPenFill} from "react-icons/ri";

export const CalendarIcon = ({
                               time
                             }) => {
  const dt =
    moment(time).locale('en') ||
    moment().locale('en')
  var month = dt.format('MMM')
  var day = dt.format('DD')

  return (
    <div
      style={{
        fontSize: '0.625rem',
        width: '2rem'
      }}
      className="flex flex-col rounded bg-green-600 text-white">
      <div
        style={{fontSize: '0.625rem'}}
        className="inline-flex justify-center items-center leading-snug">
        {month}
      </div>
      <div
        style={{fontSize: '0.575rem'}}
        className="border border-green-600 rounded text-black inline-flex justify-center items-center bg-white font-normal leading-snug">
        {day}
      </div>
    </div>
  )
}

export default function AppIcon({ item, size = 24 }) {
  if (!!!item || !!!item._type)
    return null
  switch (item._type) {
    case 'channel':
      return (
          <Avatar
              size={size}
              className="flex flex-center background-200"
              icon={ <BsMegaphoneFill className="text-color-500"/> }
          />
      )
    case 'event':
      return (
          <Avatar
              size={size}
              className="flex flex-center background-200"
              icon={ <BsMegaphoneFill className="text-color-500"/> }
          />
      )
    case 'dataset':
      return (
          <Avatar
              size={size}
              className="flex flex-center background-200"
              icon={ <BsFillBarChartLineFill className="text-color-500"/> }
          />
      )
    case 'chart':
      return (
          <Avatar
              size={size}
              className="flex flex-center background-200"
              icon={ <FcStatistics className="text-color-500" /> }
          />
      )
    case 'user':
      return (
          <Avatar
              size={size}
              className="flex flex-center background-200"
              src={getFullAvatarUrl(item)}
              icon={ <BsPersonFill className="text-color-500" /> }
          />
      )
    case 'organization':
      return (
          <Avatar
              size={size}
              className="flex flex-center background-200 rounded-md"
              src={getFullAvatarUrl(item)}
              icon={ <FaWarehouse className="text-color-500" /> }
          />
      )
    default:
      return (
          <Avatar
              size={size}
              className="flex flex-center background-200 rounded-md"
              src={getFullAvatarUrl(item)}
              icon={ <FireOutlined className="text-color-500" /> }
          />
      )
    // return null;
  }
}

export const SearchGroupIcon = ({
    size = 20,
    className,
    type = SearchTypes.ALL
}) => {
  if (_.isEmpty(type)) return null
  switch (type) {
    case SearchTypes.ORGANIZATION:
      return (
        <FaWarehouse
          size={size}
          className={classNames('text-primary', className)}
        />
      )
    case SearchTypes.DATASET:
      return (
        <BsFillBarChartLineFill
          size={size}
          className={classNames('text-primary', className)}
        />
      )
    case SearchTypes.ARTICLE:
      return (
        <RiQuillPenFill
          size={size}
          className={classNames('text-primary', className)}
        />
      )
    case SearchTypes.EVENT:
      return (
        <BsCalendar2WeekFill
          size={size}
          className={classNames('text-primary', className)}
        />
      )
    case SearchTypes.CHANNEL:
      return (
        <BsMegaphoneFill
          size={size}
          className={classNames('text-primary', className)}
        />
      )
    case SearchTypes.USER:
      return (
        <BsFillPersonFill
          size={size}
          className={classNames('text-primary', className)}
        />
      )

    default:
      return (
        <IoApps
          size={size}
          className={classNames('text-primary', className)}
        />
      )
  }
}

export const WorkSpaceIcon = ({
    size = 20,
    className,
    type,
    style,
}) => {
    if (_.isEmpty(type)) return null
    switch (type) {
        case WorkSpaceActions.HOME:
            return (
                <IoSpeedometer
                    size={size}
                    style={style}
                    className={classNames('text-primary', className)}
                />
            )
        case WorkSpaceActions.ACTIVITIES:
            return (
                <GoPulse
                    size={size}
                    style={style}
                    className={classNames('text-primary', className)}
                />
            )
        case WorkSpaceActions.DATASET:
            return (
                <BsDatabaseFill
                    size={size}
                    style={style}
                    className={classNames('text-primary', className)}
                />
            )
        case WorkSpaceActions.EVENT:
            return (
                <BsDatabaseFill
                    size={size}
                    style={style}
                    className={classNames('text-primary', className)}
                />
            )
        case WorkSpaceActions.STRUCTURES:
            return (
                <BsDiagram3Fill
                    size={size}
                    style={style}
                    className={classNames('text-primary', className)}
                />
            )
        case WorkSpaceActions.MEMBER:
            return (
                <BsPeopleFill
                    size={size}
                    style={style}
                    className={classNames('text-primary', className)}
                />
            )
        case WorkSpaceActions.OPERATING:
            return (
                <BsRadioactive
                    size={size}
                    style={style}
                    className={classNames('text-primary', className)}
                />
            )
        case WorkSpaceActions.PURCHASES:
            return (
                <FaFileInvoiceDollar
                    size={size}
                    style={style}
                    className={classNames('text-primary', className)}
                />
            )
        case WorkSpaceActions.SALES:
            return (
                <MdShelves
                    size={size}
                    style={style}
                    className={classNames('text-primary', className)}
                />
            )
        case WorkSpaceActions.SETTING:
            return (
                <BsGearFill
                    size={size}
                    style={style}
                    className={classNames('text-primary', className)}
                />
            )

        default:
            return (
                <IoApps
                    size={size}
                    className={classNames('text-primary', className)}
                />
            )
    }
}
