import {channelModel} from "apis/model"
import _ from "lodash"
import React from "react"
import getLinkToDetail from "../../../helpers/getLinkToDetail";
import {BsDiamondFill} from "react-icons/bs";
import {Link} from "react-router-dom";
import {renderIf} from "../../Shared";

const renderChannelModalItem = (
  item, {
    t,
    index,
    onItemClick = item => null,
  }
) => {

    const {
        categories = [],
        title,
        total_followers,
        total_articles,
        total_posts,
        total_reports,
    } = item ?? {}

    const total = ( total_articles + total_posts + total_reports )

  return (
      <div
          key={index}
          onClick={() => onItemClick(item) }
          className="px-3 py-2 border border-color-50 hover:border-color-base background hover:shadow-items rounded-lg cursor-pointer">
          <a className="flex-1 font-bold text-color-000 no-underline">
              {title}
          </a>
          <div className="flex items-center gap-6 text-xs mb-1">
              {renderIf(
                  total_followers > 0,
                  <span className="text-color-500 italic mr-3">
                      <span className="font-bold text-color-000 mr-1">
                          {total_followers}
                      </span>
                      {t('members')}
                  </span>
              )}
              {renderIf(
                  total > 0,
                  <span className="text-color-500 italic mr-3">
                      <span className="font-bold text-color-000 mr-1">
                          {total}
                      </span>
                      {t('articles')}
                  </span>
              )}
          </div>
          {renderIf(
              categories.length > 0,
              <div className="flex flex-wrap gap-x-3 gap-y-1">
                  {categories.map((e, i) => (
                      <Link
                          key={i}
                          target="_blank"
                          to={getLinkToDetail(e)}
                          className="flex items-center gap-2 text-primary">
                          <BsDiamondFill size={6}/>
                          <span className="font-semibold text-xs italic">{e.name}</span>
                      </Link>
                  ))}
              </div>
          )}
      </div>
  )
}

export default renderChannelModalItem