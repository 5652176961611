import {Alert, Button, Popconfirm, Spin, Tabs} from 'antd'
import {create_product_getById_Api, create_product_publish_Api, create_product_update_Api} from 'apis'
import {productSchema} from 'apis/schema'
import ApiInfoForm from 'components/form/ApiInfoForm'
import {UseHook} from 'components/UseReducer'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import Async from 'modules/asyncCache/components/Async'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useCallback, useContext, useMemo, useState} from 'react'
import {TiChevronLeft} from 'react-icons/ti'
import {Route, Switch, useLocation, useParams, useRouteMatch} from 'react-router-dom'
import {useSelectEntities} from 'redux/entities/useSelectEntities'
import AdditionalDetails from './AdditionalDetails'
import createValidate from './createValidate'
import {ProductConsumer, ProductContext, ProductProvider} from './ProductContainer'
import ProductDelete from './ProductDelete'
import {ProductBasicForm} from './ProductBasicInfo'
import {ProductDetailForm} from './ProductDetailInfo'
import useAsyncAction from "../../../modules/asyncCache/useAsyncAction";
import SpecificationDetails from "./SpecificationDetails";
import {SelectEntityItem} from "../../../components/SelectEntityItem";
import SeparatedDot from "../../../components/SeparatedDot";
import {emptyArray} from "../../../helpers/emptyObjects";
import {convertFromString, convertToString, findNodes} from "../../SlateEditor/functions";
import {ElementTypes} from "../../SlateEditor/types";
import SlateEditor from "../../SlateEditor/SlateEditor";
import {PopupConfirm} from "../../AppLocals/Organization/OrganizationEntity";
import {BsInfoCircleFill} from "react-icons/bs";

export const ProductBasicInfo = () => {
  const t = useTranslate()
  return (
    <ProductConsumer>
      {item => (
        <ApiInfoForm
          key={JSON.stringify(item)}
          validate={createValidate(t)}
          onPreSubmit={({ photos = [], ...rest }) => ({
            ...rest,
            ...(photos
              ? {
                photos: photos.map(
                  file => {
                    let photo;
                    file.read(
                      file => {
                        photo = _.get(
                          file,
                          'id'
                        )
                      }
                    )
                    return photo
                  }
                )
              }
              : {})
          })}
          apiInfo={create_product_update_Api()}
          query={{
            ':id': item.id
          }}
          initialValues={{
            contact_seller_id: _.get(item, 'contact_user.id'),
            contact_seller_name: _.get(item, 'contact_user.name'),
            hidden_price: item.hidden_price,
            public: item.public,
            wholesale: item.wholesale,
            price: item.price,
            price_unit_id: _.get(item, 'price_unit.id'),
            description: item.description,
            product_type_codes: item.product_type_codes,
            title: item.title,
            unit_id: _.get(item, 'unit.id'),
            video_url: item.video_url,
            photos: (
              item.photos || emptyArray
            ).map(({id, path}) => ({
              data: {
                id,
                url: path
              },
              read: cb =>
                cb({
                  id,
                  url: path
                })
            }))
          }}>
          <ProductBasicForm/>
        </ApiInfoForm>
      )}
    </ProductConsumer>
  )
}

export const ProductDetailInfo = () => {
  const t = useTranslate()
  return (
    <ProductConsumer>
      {item => (
        <ApiInfoForm
          key={JSON.stringify(item)}
          // validate={createValidate(t)}
          onPreSubmit={({
                          categoryOptions,
                          specificationOptions,
                          ...rest
                        }) => ({
            ...rest
          })}
          apiInfo={create_product_update_Api()}
          query={{
            ':id': item.id
          }}
          initialValues={{
            trademark: item.trademark,
            sku: item.sku,
            product_line: item.product_line,
            factory: item.factory,
            use_for: item.use_for,
            min_order_qtty: item.min_order_qtty,
            production_capacity: item.production_capacity,
            packaging_details: item.packaging_details,
            pdf_path: item.pdf_path,
            product_catalog_title: !!(
              item.product_catalog &&
              item.product_catalog.title
            )
              ? item.product_catalog.title
              : '',
            categoryOptions: item.categories,
            categories: (
              item.categories || emptyArray
            ).map(item => item.id)
          }}>
          <ProductDetailForm/>
        </ApiInfoForm>
      )}
    </ProductConsumer>
  )
}

export const ProductDescription = () => {
  const t = useTranslate();
  const item =
    useContext(ProductContext) ||
    {};
  const [editorState, setEditorState] = useState(() =>
    convertFromString(item?.about)
  )
  const handleOnChange = useCallback(
    editorState => {
      setEditorState(editorState)
    },
    []
  )

  const {
    isLoading,
    handleAsyncAction
  } = useAsyncAction({
    apiInfo: create_product_update_Api(),
    query: {
      ':id': item.id
    }
  })


  const imageSrcList = useMemo(
    () => findNodes(editorState, ElementTypes.IMAGE),
    [editorState]
  )

  return (
      <div className="flex flex-col relative">
          <SlateEditor
              name="about"
              defaultValue={editorState}
              onChange={(event) => {
                  setEditorState(event?.target?.value)
              }}
          />
          <div className="text-right mt-3">
              <Button
                  key="submit"
                  type="primary"
                  className="button-rounded-md no-border"
                  loading={isLoading}
                  onClick={() => {
                      handleAsyncAction(
                          {
                              about: convertToString(editorState),
                              photo_ids_string: _.join(_.map(imageSrcList, 'imageId'), ',')
                          }
                      )
                  }
                  }>
                  {t('save')}
              </Button>
          </div>
      </div>
  )
}

export const ProductSpecs = () => {
  const item = useContext(
    ProductContext
  )

  const initialValues = useMemo(() => {
    const specifications = item.specifications.map(({...rest}) => {
      return ({
        ...rest
      })
    });

    return {
      product_id: item.id,
      categories: (
        item.categories || emptyArray
      ).map(item => item.id),
      specifications: specifications
    }
  }, [item])
  const query = useMemo(
    () => ({
      ':id': item.id
    }),
    [item.id]
  )
  const onPreSubmit = useCallback(
    ({
       specifications = [],
       ...values
     }) => {

      return ({
        ...values,

        specifications: JSON.stringify(
          specifications.filter(item => item.value && !!item.value.length)
        )
      })
    },
    []
  )

  return (
    <ApiInfoForm
      onPreSubmit={onPreSubmit}
      apiInfo={create_product_update_Api()}
      query={query}
      initialValues={initialValues}>
      <SpecificationDetails/>
    </ApiInfoForm>
  )
}

export const ProductAdditions = () => {
  const item = useContext(
    ProductContext
  )

  const initialValues = useMemo(() => {
    return {
      attributes: item.attributes.map(
        ({
           id,
           attribute_name: title,
           attribute_value: value
         }) => {
          return {
            id,
            title,
            value
          }
        }
      )
    }
  }, [item])
  const query = useMemo(
    () => ({
      ':id': item.id
    }),
    [item.id]
  )
  const onPreSubmit = useCallback(
    ({
       attributes = [],
       ...values
     }) => ({
      ...values,
      attributes: attributes.reduce(
        (
          result,
          atrr,
          index,
          array
        ) => {
          if (
            atrr.value &&
            atrr.value.length
          ) {
            if (!result) {
              result = []
            }
            const {
              id,
              title,
              value, spec_type
            } = atrr
            result.push({
              spec_type,
              attribute_id: id,
              attribute_name: title,
              attribute_value: value
            })
          }
          if (
            index ===
            array.length - 1
          ) {
            result = JSON.stringify(
              result
            )
          }
          return result
        },
        []
      )
    }),
    []
  )
  return (
    <ApiInfoForm
      onPreSubmit={onPreSubmit}
      apiInfo={create_product_update_Api()}
      query={query}
      initialValues={initialValues}>
      <AdditionalDetails/>
    </ApiInfoForm>
  )
}

const Basic = () => {
  return (
    <div>
      <ProductBasicInfo/>
    </div>
  )
}
const Detail = () => {
  return (
    <div>
      <ProductDetailInfo/>
    </div>
  )
}
const ProductAbout = () => {
  return (
    <div>
      <ProductDescription/>
    </div>
  )
}

const productRouteEntities = {
  basic: {
    name: 'basic',
    path: url => url,
    breadcrumbName: t =>
      t('basic info'),
    component: Basic,
    exact: true
  },
  about: {
    name: 'about',
    path: url => url + '/about',
    breadcrumbName: t =>
      t(
        'description'
      ),
    component: ProductAbout,
    exact: true
  },
  details: {
    name: 'details',
    path: url => url + '/details',
    breadcrumbName: t =>
      t(
        'details'
      ),
    component: Detail,
    exact: true
  },
  specs: {
    name: 'specs',
    path: url => url + '/specs',
    breadcrumbName: t =>
      t(
        'Specification Details'
      ),
    component: ProductSpecs,
    exact: true
  },
  additions: {
    name: 'additions',
    path: url => url + '/additions',
    breadcrumbName: t =>
      t(
        'Additional Details'
      ),
    component: ProductAdditions,
    exact: true
  },
  delete: {
    name: 'delete',
    path: url => url + '/delete',
    breadcrumbName: t => t('delete'),
    component: ProductDelete,
    exact: true
  }
}

const ProductSettingRoutes = () => {
  let {url} = useRouteMatch()
  const history = useHistory()
  const location = useLocation()
  const t = useTranslate();
  const item = useContext(ProductContext);

  const activeKey = location.pathname
  const routes = useMemo(
    () => [
      {
        ...productRouteEntities.basic,
        path: productRouteEntities.basic.path(
          url
        )
      },
      {
        ...productRouteEntities.details,
        path: productRouteEntities.details.path(
          url
        )
      },
      {
        ...productRouteEntities.specs,
        path: productRouteEntities.specs.path(
          url
        )
      },
      {
        ...productRouteEntities.additions,
        path: productRouteEntities.additions.path(
          url
        )
      },
      {
        ...productRouteEntities.about,
        path: productRouteEntities.about.path(
          url
        )
      },
      {
        ...productRouteEntities.delete,
        path: productRouteEntities.delete.path(
          url
        )
      }
    ],
    [url]
  );

  if (!item) return null;

  return (
    <div className="flex flex-col space-y-3">


      {item.is_ready && (
        <Alert
          type="success"
          description={
            <div className="flex justify-between space-x-3 3">
              <div>
                •{' '}
                {t(
                  'your product has been declared complete, you can put it on the store'
                )}
              </div>
              <UseHook
                hook={
                  useDispatchAsyncActionWithNotify
                }>
                {([
                    {
                      success,
                      isLoading
                    },
                    dispatch
                  ]) => (
                  <Popconfirm
                    title={`${t('publish')} ?`}
                    onConfirm={() => {
                      dispatch(
                        createAsyncAction(
                          {
                            apiInfo: create_product_publish_Api(),
                            query: {
                              ':id':
                              item.id
                            }
                          }
                        )
                      )
                    }}
                    okText="OK"
                    cancelText="Cancel">
                    <Button
                      loading={
                        isLoading
                      }
                      type="primary">
                      {t('publish')}
                    </Button>
                  </Popconfirm>

                )}
              </UseHook>
            </div>
          }
        />
      )}
      {!_.isEmpty(
        item.requireStrings
      ) && (
        <Alert
          type="error"
          description={
            <ul className="text-red-800">
              {item.requireStrings.map(
                text => (
                  <li key={text}>
                    • {text}
                  </li>
                )
              )}
            </ul>
          }
        />
      )}

      <Tabs
        animated={false}
        defaultActiveKey={activeKey}
        onChange={key => {
          history.replace({
            ...location,
            pathname: key
          })
        }}>
        <Tabs.TabPane
          tab={t('basic info')}
          key={productRouteEntities.basic.path(
            url
          )}
        />
        <Tabs.TabPane
          tab={t(
            'description'
          )}
          key={productRouteEntities.about.path(
            url
          )}
        />
        <Tabs.TabPane
          tab={t('detail')}
          key={productRouteEntities.details.path(
            url
          )}
        />
        <Tabs.TabPane
          tab={t(
            'Specification Details'
          )}
          key={productRouteEntities.specs.path(
            url
          )}
        />
        <Tabs.TabPane
          tab={t(
            'Additional Details'
          )}
          key={productRouteEntities.additions.path(
            url
          )}
        />
        <Tabs.TabPane
          tab={t('delete')}
          key={productRouteEntities.delete.path(
            url
          )}
        />
      </Tabs>
      <Switch>
        {routes.map((props, i) => (
          <Route key={i} {...props} />
        ))}
      </Switch>
    </div>
  )
}

export const ProductSettingRoutesContainer = ({id}) => {
  return (
    <React.Fragment>
      <Async
        key={id}
        apiInfo={create_product_getById_Api()}
        query={{':id': id}}
      />

      <div className="max-w-6xl mx-auto">
        <SelectEntityItem
          item={id}
          schema={
            productSchema
          }>
          {
            item => {
              return (
                <Spin spinning={!item}>
                  {!!item && !!item.id ? (
                    <ProductProvider
                      value={item}>
                      <ProductSettingRoutes/>
                    </ProductProvider>
                  ) : (
                    <div className="w-full"/>
                  )}
                </Spin>
              )
            }
          }
        </SelectEntityItem>

      </div>
    </React.Fragment>
  )
}


const ProductSetting = () => {
  const t = useTranslate();
  const item = useContext(ProductContext);
  const [current, setCurrent] = useState('basic');

  const C = useMemo(() => {
    if (!!_.get(productRouteEntities, current)) return productRouteEntities[current].component;

    return (<div className="w-full"/>)
  }, [current])

  if (!item) return null;

  return (
    <div className="flex flex-col space-y-3">

      {(Number(item.status) !== 1 || Number(item.active) !== 1) && (
        <Alert
          closable
          type="warning"
          className=""
          message={
            <div className="flex flex-col">
              {!!item.is_ready && (Number(item.status) !== 1 || Number(item.active) !== 1) && (
                <div className="font-medium">
                  {t("product had basic information and ready to launch")}
                </div>
              )}
              {Number(item.status) !== 1 && (
                  <div className="flex items-center gap-2">
                      <SeparatedDot/>
                      <span className="text-sm text-color-200 italic">
                        {t(
                            "{launch} the product to display on the store",
                            {launch: (
                                <UseHook hook={useDispatchAsyncActionWithNotify}>
                                    {([{ success, isLoading }, dispatch ]) => (
                                        <PopupConfirm
                                            icon={ <BsInfoCircleFill className="anticon text-blue-500"/> }
                                            title={t('launch')}
                                            onConfirm={() => {
                                                dispatch(
                                                    createAsyncAction(
                                                        {
                                                            apiInfo: create_product_publish_Api(),
                                                            query: {
                                                                ':id':
                                                                item.id
                                                            }
                                                        }
                                                    )
                                                )
                                            }}>
                                            <span
                                                style={{ padding: '2px 6px' }}
                                                className="font-bold text-xs bg-primary-50 hover:bg-primary-100 text-primary-600 rounded-md leading-snug cursor-pointer">
                                                {t('launch')}
                                            </span>
                                        </PopupConfirm>
                                    )}
                                </UseHook>
                            )}
                        )}
                      </span>
                  </div>
              )}

              {Number(item.active) !== 1 ? (
                  <div className="flex items-center gap-2">
                      <SeparatedDot/>
                      <span className="text-sm text-color-200 italic">
                        {
                            t("OR {activate} to input the price and manage it internally",
                                {activate: (
                                    <UseHook hook={useDispatchAsyncActionWithNotify}>
                                        {([{ success, isLoading }, dispatch ]) => (
                                            <PopupConfirm
                                                icon={ <BsInfoCircleFill className="anticon text-blue-500"/> }
                                                title={t('activate only')}
                                                onConfirm={() => {
                                                    dispatch(
                                                        createAsyncAction(
                                                            {
                                                                apiInfo: create_product_update_Api(),
                                                                query: {
                                                                    ':id':
                                                                    item.id
                                                                },
                                                                values: {
                                                                    active: 1
                                                                }
                                                            }
                                                        )
                                                    )
                                                }}>
                                                <span
                                                    style={{ padding: '2px 6px' }}
                                                    className="font-bold text-xs bg-primary-50 hover:bg-primary-100 text-primary-600 rounded-md leading-snug cursor-pointer">
                                                    {t('activate only')}
                                                </span>
                                            </PopupConfirm>
                                        )}
                                    </UseHook>
                                )}
                            )}
                    </span>
                  </div>
              ) : (
                  <div className="flex items-center gap-2">
                      <SeparatedDot/>
                      <span className="text-sm text-color-200 italic">
                        {t("{deactivate} product",
                            {deactivate: (
                                <UseHook hook={useDispatchAsyncActionWithNotify}>
                                    {([{  success, isLoading }, dispatch ]) => (
                                        <PopupConfirm
                                            icon={ <BsInfoCircleFill className="anticon text-blue-500"/> }
                                            title={t('deactivate')}
                                            onConfirm={() => {
                                                dispatch(
                                                    createAsyncAction(
                                                        {
                                                            apiInfo: create_product_update_Api(),
                                                            query: {
                                                                ':id':
                                                                item.id
                                                            },
                                                            values: {
                                                                active: 0
                                                            }
                                                        }
                                                    )
                                                )
                                            }}>
                                            <span
                                                style={{ padding: '2px 6px' }}
                                                className="font-bold text-xs bg-primary-50 hover:bg-primary-100 text-primary-600 rounded-md leading-snug cursor-pointer">
                                                {t('deactivate')}
                                            </span>
                                        </PopupConfirm>
                                    )}
                                </UseHook>
                            )}
                        )}
                    </span>
                  </div>
              )}
            </div>
          }
        />
      )}

      {!_.isEmpty(item.requireStrings) && (
          <div className="flex flex-col">
              <span className="font-bold text-xs text-red-600 italic">
                  {`* ${t('missing')} : `}
              </span>
              <span className="ml-3 flex flex-col leading-tight">
                  {Object.values(item.requireStrings).map(text => (
                      <span key={text} className="text-xs text-red-500 italic">
                          {`- ${text}.`}
                      </span>
                  ))}
              </span>
          </div>
      )}

      <Tabs
        animated={false}
        defaultActiveKey={current}
        onChange={key => {
          setCurrent(key)
        }}>
        <Tabs.TabPane
          tab={t('basic info')}
          key={productRouteEntities.basic.name}
        />
        <Tabs.TabPane
          tab={t(
            'description'
          )}
          key={productRouteEntities.about.name}
        />
        <Tabs.TabPane
          tab={t('detail')}
          key={productRouteEntities.details.name}
        />
        <Tabs.TabPane
          tab={t(
            'Specification Details'
          )}
          key={productRouteEntities.specs.name}
        />
        <Tabs.TabPane
          tab={t(
            'Additional Details'
          )}
          key={productRouteEntities.additions.name}
        />
        <Tabs.TabPane
          tab={t('delete')}
          key={productRouteEntities.delete.name}
        />
      </Tabs>

      <C/>
    </div>
  )
}
export const ProductSettingContainer = ({id}) => {

  return (
    <React.Fragment>
      <Async
        key={id}
        apiInfo={create_product_getById_Api()}
        query={{':id': id}}
      />

      <div className="max-w-6xl p-3">
        <SelectEntityItem
          item={id}
          schema={productSchema}>
          {item => (
              <Spin spinning={!item}>
                {!!item && !!item.id ? (
                    <ProductProvider
                        value={item}>
                      <ProductSetting/>
                    </ProductProvider>
                ) : (
                    <div className="w-full"/>
                )}
              </Spin>
          )}
        </SelectEntityItem>

      </div>
    </React.Fragment>
  )
}

export default () => {
  const {
    id = '#'
  } = useParams()
  const item = useSelectEntities(
    id,
    productSchema,
    {}
  )
  const t = useTranslate()
  const history = useHistory()

  return (
    <div className="p-3 ">
      <div className="mt-3 mb-6 space-y-3">
        <div className="flex flex-col gap-1">
          <div
            onClick={() => {
              if (history && history.goBack) {
                history.goBack();
              } else {
                history.push(
                  {
                    pathname: `/organization/${_.get(item, 'owner.idname')}/settings/manage-products`,
                    state: {
                      isModal: true
                    }
                  }
                );
              }
            }}
            className="flex items-center gap-1 text-color-300 hover:text-color-100 cursor-pointer">
            <TiChevronLeft />
            <span className="font-semibold text-sm">
              {t('back')}
            </span>
          </div>
          <div className="ml-5 text-lg font-bold text-color-100">
            <span className="font-normal text-base text-color-300 italic">
                {t('product')}
                <span className="px-2">/</span>
            </span>
            {item.title}
          </div>
        </div>

        <ProductSettingRoutesContainer id={id}/>
      </div>

    </div>
  )
}
