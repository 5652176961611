import { SearchGroupIcon } from 'components/AppIcon'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import { useHistory } from 'modules/navigation/useRouter'
import React from 'react'
import { renderOwnChild } from 'views/Shared'

export const DiscoveryEntities = Object.freeze(
  {
    USER: 'user',
    EVENT: 'event',
    ARTICLE: 'article',
    CHANNEL: 'channel',
    DATASET: 'dataset',
    UNDEFINED: 'undefined',
    ORGANIZATION: 'organization'
  }
)

export const fromDiscoveryEntity = (
  type,
  which
) => {
  let value
  switch (type) {
    case DiscoveryEntities.USER:
      value = {
        pathname: 'user',
        contextName: 'user',
        displayName: 'user'
      }
      break
    default:
      value = {
        pathname: type,
        contextName: type,
        displayName: type
      }
      break
  }

  return which
    ? _.get(value, which)
    : value
}

const renderDefaultItem = (
  type,
  { onClick }
) => {
  if (_.isEmpty(type)) return null
  return (
    <Translate>
      {t => (
        <div
          onClick={onClick}
          style={{ height: '8rem' }}
          className="flex flex-col items-center justify-center gap-2 rounded-lg hover:shadow-out text-color-000 hover:text-primary cursor-pointer background-100">
          <SearchGroupIcon
            type={type}
            size={30}
          />
          <span className="font-medium text-center text-sm">
            {t(type)}
          </span>
        </div>
      )}
    </Translate>
  )
}

export const renderFeatureItem = (
  type,
  { onClick }
) => {
  if (_.isEmpty(type)) return null
  return (
    <Translate>
      {t => (
        <div
          onClick={onClick}
          style={{ height: '6rem' }}
          className="flex flex-col flex-center gap-2 border border-color-50 rounded-lg hover:shadow-out hover:text-primary cursor-pointer background">
          <SearchGroupIcon
            type={type}
            size={30}
          />
          <span className="font-medium text-center text-xs">
            {t(type)}
          </span>
        </div>
      )}
    </Translate>
  )
}

const Explorer = ({
  type,
  path,
  renderItem,
  Wrapper = renderOwnChild
}) => {
  const history = useHistory()

  const onSelect = () => {
    if (path) {
      history.push(path)
    }
  }

  return (
    <Wrapper>
      {renderItem(type, {
        onClick: onSelect
      })}
    </Wrapper>
  )
}

const createDiscoveryExplorer = ({
  entity,
  renderItem = renderDefaultItem,
  ...props
}) => {
  const type = fromDiscoveryEntity(
    entity,
    'pathname'
  )
  return (
    <Explorer
      type={type}
      renderItem={renderItem}
      {...props}
    />
  )
}

export const GeneralExplorer = props =>
  createDiscoveryExplorer({
    entity: DiscoveryEntities.UNDEFINED,
    renderItem: renderFeatureItem,
    ...props
  })

export const DataSetExplorer = props =>
  createDiscoveryExplorer({
    entity: DiscoveryEntities.DATASET,
    renderItem: renderFeatureItem,
    ...props
  })

export const EventExplorer = props =>
  createDiscoveryExplorer({
    entity: DiscoveryEntities.EVENT,
    renderItem: renderFeatureItem,
    ...props
  })

export const ChannelExplorer = props =>
  createDiscoveryExplorer({
    entity: DiscoveryEntities.CHANNEL,
    renderItem: renderFeatureItem,
    ...props
  })

export const OrganizationExplorer = props =>
  createDiscoveryExplorer({
    entity:
      DiscoveryEntities.ORGANIZATION,
    renderItem: renderFeatureItem,
    ...props
  })

export const UserExplorer = props =>
  createDiscoveryExplorer({
    entity: DiscoveryEntities.USER,
    renderItem: renderFeatureItem,
    ...props
  })

export const ArticleExplorer = props =>
  createDiscoveryExplorer({
    entity: DiscoveryEntities.ARTICLE,
    renderItem: renderFeatureItem,
    ...props
  })

export default createDiscoveryExplorer
