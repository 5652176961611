import _ from 'lodash'

export const createValue = (name, value) => {
  return {
    target: {
      name,
      value,
    },
  }
}

export const createSelectValue = (name, value) =>
  createValue(name, _.isUndefined(value) ? null : value)
