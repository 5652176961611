import Icon, {FontSizeOutlined, MenuOutlined, SettingOutlined, TwitterOutlined} from '@ant-design/icons'
import {Avatar, Button, Drawer, Radio, Result, Spin} from 'antd'
import {article_getById_Api, poll_get_Api, post_getPostbyId_Api} from 'apis'
import {channelModel} from 'apis/model'
import {articleSchema, pollSchema, postSchema} from 'apis/schema'
import classNames from 'classnames'
import {Keywords} from 'components/Feed/BaseEmbed'
import Count from 'components/Feed/Count'
import Description from 'components/Feed/Description'
import {FeedProvider} from 'components/Feed/FeedProvider'
import {Message} from 'components/Feed/Message'
import {Share} from 'components/Feed/Share'
import {allActions, createTimeLineFeed, defaultDetailComponents} from 'components/Feed/TimeLineFeed'
import Timestamp from 'components/Feed/Timestamp'
import {Vote} from 'components/Feed/Vote'
import {ChannelIcon} from 'components/icons/ChannelIcon'
import ContentLayout from 'components/layouts/Default/ContentLayout'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import Null from 'components/NullComponent'
import {SelectEntityItem} from 'components/SelectEntityItem'
import SeparatedDot from 'components/SeparatedDot'
import Toggle from 'components/Toggle'
import {WithDetailPopup} from 'components/WithDetailPopup'
import {ROOT_URL} from 'envs/_current/config'
import {callNativeShare, createFacebookShareLink, createTwitterShareLink} from 'helpers'
import getTitle from 'helpers/getTitle'
import getUpperFirstChar from 'helpers/getUpperFirstChar'
import preventParentEvent from 'helpers/preventParentEvent'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useAsync from 'modules/asyncCache/useAsync'
import useTranslate from 'modules/local/useTranslate'
import useRouter, {useHistory} from 'modules/navigation/useRouter'
import React, {useEffect, useState} from 'react'
import {FaBookmark, FaFacebookF, FaRegBookmark, FaShareAlt} from 'react-icons/fa'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {branch, compose, mapProps} from 'recompose'
import {selectEntities} from 'redux/selectors'
import {staticPaths} from 'routes/staticPaths'
import LoadingPage from 'views/LoadingPage'
import {Cate} from '../NewFeed/Cate'
import {HorizontalList} from '../NewFeed/HorizontalList'
import {HeaderButton} from '../Organization/components/PageHeader'
import './Feed.css'
import {FeedMoreRecommends} from './FeedMoreRecommends'
import {Responses} from './Responses'
import {paths} from "../MainPage/contains"
import {FeedMoreSuggestedProducts} from "./FeedMoreSuggestedProducts";
import {GoogleAdsInArticleUnit, GoogleAdsRecommendedUnit} from "../../components/GoogleAds/GoogleAds";
import {SlateRenderer} from "../SlateEditor";
import {convertFromString} from "../SlateEditor/functions";
import TableOfContents from "../SlateEditor/Editors/TableOfContents";
import FeedContext from "../../components/Feed/FeedContext";
import {BsGear} from "react-icons/bs";
import {ImFacebook} from "react-icons/im";
import {RiFacebookFill, RiTwitterXFill} from "react-icons/ri";
import {PiShareFat, PiShareFatFill} from "react-icons/pi";
import {FaGear} from "react-icons/fa6";

export const FeedContentTypes = Object.freeze({
  ALL: 'all',
  POST: 'post',
  REPOST: 'repost',
  ARTICLE: 'article',
  CHANNEL: 'channel',
  POLL: 'poll_question',
})

export const FeedActionTypes = Object.freeze({
  ADS: 'advertise',
  STICKY: 'sticky',
})

export const isValidContentType = (type, excluded = [FeedContentTypes.ALL]) =>
  !_.isEmpty(type) && !excluded.includes(type)

export const feedContentTypes = [
  {
    label: 'all',
    value: FeedContentTypes.ALL,
    getTotal: ({
                 total_polls = 0,
                 total_posts = 0,
                 total_articles = 0,
               }) =>
      Number(
        _.defaultTo(total_polls, 0) +
        _.defaultTo(total_posts, 0) +
        _.defaultTo(total_articles, 0)
      ),
  },
  {
    label: 'articles',
    value: FeedContentTypes.ARTICLE,
    getTotal: ({total_articles}) => Number(_.defaultTo(total_articles, 0)),
  },
  {
    label: 'news',
    value: FeedContentTypes.REPOST,
    getTotal: ({total_posts}) => Number(_.defaultTo(total_posts, 0)),
  },
  {
    label: 'poll',
    value: FeedContentTypes.POLL,
    getTotal: ({total_polls}) => Number(_.defaultTo(total_polls, 0)),
  },
].map(({value, ...item}) => ({...item, value, name: value}))

export const fromFeedContentTypes = (type) => {
  return feedContentTypes.find((item) => type === _.get(item, 'value'))
}

const DetailFeed = createTimeLineFeed(
  defaultDetailComponents,
  {
    ...allActions,
    to: false,
    message: false
  }
)

const Article = ({id}) => {
  const t = useTranslate()
  const {
    handleGoBack,
    history
  } = useRouter()

  const [
    editorState,
    setEditorState
  ] = useState()
  const [
    textSize,
    setTextSize
  ] = useState('default')
  const item = useSelector(
    state =>
      selectEntities(
        state,
        id,
        articleSchema
      ) ||
      _.find(
        _.values(
          _.get(
            state,
            'entities.articles'
          )
        ),
        {idname: id}
      )
  )
  useEffect(() => {
    if (item) {
      setEditorState(convertFromString(item?.content))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item && item.update])

  const {
    isLoading,
    success,
    errorMessages,
    ...rest
  } = useAsync({
    apiInfo: article_getById_Api,
    query: {
      ':article_id': id
    }
  });
  return (
    <ContentLayout
      title={t('article')}
      renderMenu={Null}
      extra={
        item && (
          <>
            {item.edit && (
              <HeaderButton
                onClick={() =>
                  history.push(
                    `/article/${id}/edit`
                  )
                }>
                <SettingOutlined
                  style={{
                    fontSize: '1.4em'
                  }}
                />
              </HeaderButton>
            )}
            <div className="flex mr-2">
              <HeaderButton
                component="a"
                href={createFacebookShareLink(
                  `${ROOT_URL}/news/article/${id}`
                )}
                target="_blank"
                width={42}
                height={42}
                icon={
                  <div className="background-200 rounded-full w-12 h-12 py-2 px-3 m-2 mx-3">
                    <Icon
                      component={
                        FaFacebookF
                      }></Icon>
                  </div>
                }></HeaderButton>
              <HeaderButton
                component="a"
                href={createTwitterShareLink(
                  `${ROOT_URL}/news/article/${id}`
                )}
                target="_blank"
                width={42}
                height={42}
                icon={
                  <div className="background-200 rounded-full w-12 h-12 py-2 px-3 m-2 mx-3">
                    <Icon
                      component={
                        TwitterOutlined
                      }></Icon>
                  </div>
                }></HeaderButton>
              <HeaderButton
                onClick={() => {
                  callNativeShare({
                    title: channelModel.getTitle(
                      item
                    ),
                    url: `${ROOT_URL}/news/article/${id}`
                  })
                }}
                width={42}
                height={42}
                icon={
                  <div className="background-200 rounded-full w-12 h-12 py-2 px-3 m-2 mx-3">
                    <Icon
                      component={
                        FaShareAlt
                      }></Icon>
                  </div>
                }></HeaderButton>
            </div>
          </>
        )
      }
      right={
        item && (
          <>
            {!_.isEmpty(
              _.get(item, 'others_in_container')
            ) && (
              <>
                <h2 className="font-bold border-b-2 background-200 border-green-500 p-2 pl-3 md:text-lg text-xl">
                  {t(
                    'related articles'
                  )}
                </h2>
                <FeedMoreRecommends
                  item={item.container}
                  list={
                    item.others_in_container
                  }
                />
              </>
            )}
            <GoogleAdsInArticleUnit/>
            {!_.isEmpty(
              _.get(item, 'others_in_creator')
            ) && (
              <>
                <h2 className="font-bold border-b-2 background-200 border-green-500 p-2 pl-3 md:text-lg text-xl">
                  {t('from the sender')}
                </h2>
                <FeedMoreRecommends
                  item={item.creator}
                  list={
                    item.others_in_creator
                  }
                />
              </>
            )}
            <GoogleAdsRecommendedUnit/>
            {!_.isEmpty(
              _.get(item, 'suggested_products')
            ) && (
              <>
                <h2 className="font-bold border-b-2 background-200 border-green-500 p-2 pl-3 md:text-lg text-xl">
                  {t(
                    'products'
                  )}
                </h2>
                <FeedMoreSuggestedProducts
                  item={_.get(item, 'container.owner')}
                  list={
                    item.suggested_products
                  }
                />
              </>
            )}
          </>
        )
      }
      onCancel={handleGoBack}>
      <Spin spinning={isLoading}>
        {success &&
        item &&
        (editorState || null) && (
          <article>
            <div
              onContextMenu={
                item.content_disabled
                  ? preventParentEvent
                  : undefined
              }
              className={classNames(
                'flex mx-auto w-full',
                item.content_disabled &&
                'content_disabled'
              )}>
              <div className="w-full">
                <div className="px-3 relative">
                  <div className="verticalList">
                    <div
                      className="inline-flex p-1 pt-3 border-b w-full border-gray-600 hover:border-green-500 truncate items-center justify-start text-color-300 flex">
                      <div
                        style={{
                          marginRight:
                            '1rem',
                          fontSize:
                            '2rem'
                        }}
                        className="flex-shrink-0">
                        <ChannelIcon
                          premium={_.get(
                            item,
                            'container.premium',
                            0
                          )}
                          className="mr-2 text-color-100 text-3xl w-8 h-8"
                        />
                      </div>
                      <div className="flex-1">
                        <Link
                          to={channelModel.getLinkToDetail(
                            _.get(
                              item,
                              'container'
                            )
                          )}
                          className="font-bold cursor-pointer">
                          {_.get(
                            item,
                            'container.title'
                          ) ||
                          _.get(
                            item,
                            'container.name'
                          )}
                        </Link>
                        <div className="font-normal text-xs">
                          {_.get(
                            item,
                            'container.owner.title'
                          ) ||
                          _.get(
                            item,
                            'container.owner.name'
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="flex">
                      <HorizontalList
                        className="flex-1 pr-2"
                        renderMore={
                          Null
                        }
                        list={
                          item.categories
                        }
                        renderItem={cate => (
                          <Link
                            to={paths.categoryPath.replace(
                              ':category',
                              cate.id
                            ).replace(
                              ':refcategory',
                              cate.alt_idname
                            )
                            + "?lang=" + cate.language}>
                            {' '}
                            <Cate
                              item={
                                cate
                              }
                            />
                          </Link>
                        )}
                      />

                      <Toggle>
                        {(
                          isToggle,
                          toggle
                        ) => (
                          <>
                            <div className="background p-1">
                              <div
                                style={{
                                  top:
                                    'var(--header-height)'
                                }}
                                className="sticky flex items-center">
                                <div className="py-3 font-bold">
                                  <Radio.Group
                                    defaultValue={
                                      textSize
                                    }
                                    size="middle"
                                    onChange={e =>
                                      setTextSize(
                                        e
                                          .target
                                          .value
                                      )
                                    }>
                                    <Radio.Button value="default">
                                      <FontSizeOutlined className="text-xs"/>
                                    </Radio.Button>
                                    <Radio.Button value="large">
                                      <FontSizeOutlined className="text-lg"/>
                                    </Radio.Button>
                                  </Radio.Group>
                                </div>

                                <HeaderButton
                                  onClick={() =>
                                    toggle()
                                  }>
                                  <MenuOutlined/>
                                </HeaderButton>
                              </div>
                            </div>
                            {isToggle && (
                              <Drawer
                                title={t(
                                  'table of contents'
                                )}
                                visible="true"
                                onClose={() =>
                                  toggle()
                                }>
                                <TableOfContents
                                  editorState={
                                    editorState
                                  }
                                />
                              </Drawer>
                            )}
                          </>
                        )}
                      </Toggle>
                    </div>
                  </div>
                </div>
                <div
                  style={
                    textSize ===
                    'default'
                      ? {
                        fontSize:
                          '1em'
                      }
                      : {
                        fontSize:
                          '1.2em'
                      }
                  }>
                  <h1
                    style={{
                      fontSize: '2em'
                    }}
                    className="px-3 pt-3 font-bold leading-tight">
                    {item.title}
                  </h1>
                  <div className="px-3 text-color-300 text-sm">
                    <SeparatedDot/>
                    <Timestamp
                      className="font-normal"
                      timestamp={_.get(
                        item,
                        'created'
                      )}
                    />
                    {!!item.total_views && (
                      <>
                        <SeparatedDot/>
                        <span className="font-bold">
                            {
                              item.total_views
                            }
                          </span>{' '}
                        {t('views')}
                      </>
                    )}
                  </div>
                  <WithDetailPopup
                    item={item.owner}
                    style={{
                      textOverflow:
                        'ellipsis'
                    }}>
                    <div className="flex items-center px-3 py-1">
                      <Avatar
                        style={{
                          marginRight:
                            '1em'
                        }}
                        size="small"
                        shape="circle"
                        src={_.get(
                          item,
                          'owner.thumbnail'
                        )}>
                        {getUpperFirstChar(
                          getTitle(
                            item.owner
                          )
                        )}
                      </Avatar>
                      <div className="truncate flex-1 flex flex-col">
                          <span className="font-semibold ">
                            {_.get(
                              item,
                              'owner.name'
                            ) ||
                            _.get(
                              item,
                              'owner.title'
                            )}
                          </span>
                      </div>
                    </div>
                  </WithDetailPopup>
                  {item.description && (
                    <div className="p-3 ">
                      <Description
                        type={
                          'article'
                        }
                        mentions={
                          item &&
                          item.mentions
                        }>
                        {
                          item.description
                        }
                      </Description>
                    </div>
                  )}
                  <div className="p-3 w-full verticalList mx-auto ">
                    <SlateRenderer value={editorState}/>
                    <Keywords
                      keywords={_.get(
                        item,
                        'keywords',
                        []
                      )}
                    />
                  </div>
                </div>
                <SelectEntityItem
                  item={item.id}
                  schema={
                    articleSchema
                  }>
                  {item => (
                    <FeedProvider
                      item={item}
                      defaultActions={
                        allActions
                      }>
                      <FeedContext.Consumer>
                        {({
                            handleClick,
                            shareUrl
                          }) => (
                          <div className="sticky bottom-0 z-20 p-2 flex items-center background">
                            <Vote/>
                            <Message/>
                            <Share
                              url={
                                shareUrl
                              }
                            />
                            <Count
                              active={
                                item.bookmark_status
                              }
                              onClick={
                                !item.bookmark_status
                                  ? () =>
                                    handleClick(
                                      'bookmark'
                                    )
                                  : () =>
                                    handleClick(
                                      'unbookmark'
                                    )
                              }
                              key="bookmark"
                              color={
                                item.bookmark_status
                                  ? 'text-yellow-500'
                                  : 'text-color-300'
                              }
                              component={
                                item.bookmark_status
                                  ? FaBookmark
                                  : FaRegBookmark
                              }
                            />
                          </div>
                        )}
                      </FeedContext.Consumer>
                    </FeedProvider>
                  )}
                </SelectEntityItem>
              </div>
            </div>
            <div className="background-100">
              <div className="py-4 mx-auto mt-6 w-full verticalList p-3 flex flex-col">
                <h2 className="font-bold ">
                  {t('responses')}
                </h2>
                <Responses
                  type={item._type}
                  id={
                    item.id
                  }
                />
              </div>
            </div>
            <LayoutContext.Consumer>
              {({breakpoint}) =>
                [
                  'xs',
                  'sm',
                  'md'
                ].find(
                  value =>
                    value ===
                    breakpoint
                )
                  ? item && (
                  <>
                    {!_.isEmpty(
                      item.others_in_container
                    ) && (
                      <>
                        {!_.isEmpty(
                          item.others_in_container
                        ) && (
                          <>
                            <h2
                              className="font-bold border-b-2 background-200 border-green-500 p-2 pl-3 md:text-lg text-xl">
                              {t(
                                'related articles'
                              )}
                            </h2>
                            <FeedMoreRecommends
                              item={
                                item.container
                              }
                              list={
                                item.others_in_container
                              }
                            />
                          </>
                        )}
                        <GoogleAdsInArticleUnit/>
                        {!_.isEmpty(
                          item.others_in_creator
                        ) && (
                          <>
                            <h2
                              className="font-bold border-b-2 background-200 border-green-500 p-2 pl-3 md:text-lg text-xl">
                              {t(
                                'from the sender'
                              )}
                            </h2>
                            <FeedMoreRecommends
                              item={
                                item.creator
                              }
                              list={
                                item.others_in_creator
                              }
                            />
                          </>
                        )}
                      </>
                    )}

                    <GoogleAdsRecommendedUnit/>
                  </>
                )
                  : null
              }
            </LayoutContext.Consumer>
          </article>
        )}
        {errorMessages && (
          <Result
            status="404"
            subTitle={
              errorMessages['error']
            }
            extra={
              <Link
                to={staticPaths.home}>
                <Button type="primary">
                  {t('Back Home')}
                </Button>
              </Link>
            }
          />
        )}
      </Spin>
    </ContentLayout>
  )
}

export default compose(
  mapProps(props => ({
    id: _.get(props, 'match.params.id'),
    key: _.get(
      props,
      'match.params.id'
    ),
    type: _.get(
      props,
      'match.params.type'
    )
  })),

  branch(
    ({type}) => type === 'article',
    () => Article
  )
)(({id, type}) => {
  const history = useHistory()
  const t = useTranslate()
  const item = useSelector(state =>
    selectEntities(
      state,
      id,
      type === 'poll_question'
        ? pollSchema
        : type === 'article'
        ? articleSchema
        : postSchema
    )
  )
  const {handleGoBack} = useRouter()
  const {
    isLoading,
    errorMessages,
    ...rest
  } = useAsync(
    type === 'poll_question'
      ? createAsyncAction({
        apiInfo: poll_get_Api,
        query: {
          ':poll_question_id': id
        }
      })
      : type === 'article'
      ? createAsyncAction({
        apiInfo: article_getById_Api,
        query: {
          ':article_id': id
        }
      })
      : createAsyncAction({
        apiInfo: post_getPostbyId_Api,
        query: {
          ':post_id': id
        }
      })
  );

  return (
    <ContentLayout
      title={t('post')}
      renderMenu={Null}
      extra={
        item &&
        type === 'post' && (
            <div className="flex items-center gap-2 mr-2">
                <HeaderButton
                    component="a"
                    href={createFacebookShareLink(
                        `${ROOT_URL}/news/post/${id}`
                    )}
                    target="_blank"
                    width={26}
                    height={26}
                    icon={ <ImFacebook size={12} />}
                />
                <HeaderButton
                    component="a"
                    href={createTwitterShareLink(
                        `${ROOT_URL}/news/post/${id}`
                    )}
                    target="_blank"
                    width={26}
                    height={26}
                    icon={ <RiTwitterXFill size={12} /> }
                />
                <HeaderButton
                    onClick={() => {
                        callNativeShare({
                            title: channelModel.getTitle(
                                item
                            ),
                            url: `${ROOT_URL}/news/post/${id}`
                        })
                    }}
                    width={26}
                    height={26}
                    icon={ <PiShareFatFill size={12} /> }
                />
                {item.edit && (
                    <HeaderButton
                        width={26}
                        height={26}
                        onClick={() => history.push(`/news/post/${id}/edit`)}>
                        <FaGear size={12} />
                    </HeaderButton>
                )}
            </div>
        )
      }
      right={
        item && (
          <div className="ml-3 mr-2">
              <div className="h-3"/>
              {!_.isEmpty(item.others_in_container) && (
                  <div className="flex flex-col gap-2">
                      <div className="border-b font-bold text-xs text-color-400 uppercase tracking-wide">
                          {t('related articles')}
                      </div>
                      <FeedMoreRecommends
                          item={item.container}
                          list={
                              item.others_in_container
                          }
                      />
                  </div>
              )}
              <GoogleAdsInArticleUnit/>
              {!_.isEmpty(
                  item.others_in_creator
              ) && (
                  <div className="flex flex-col gap-2">
                      <div className="border-b font-bold text-xs text-color-400 uppercase tracking-wide">
                          {t('from the sender')}
                      </div>
                      <FeedMoreRecommends
                          item={item.creator}
                          list={
                              item.others_in_creator
                          }
                      />
                  </div>
              )}
              <GoogleAdsRecommendedUnit/>
          </div>
        )
      }
      onCancel={handleGoBack}>
      {item && (
        <>
          <div
            key={item.id}
            className="px-3 flex-1 w-full flex flex-col verticalList">
            {item ? (
              <>
                <div className="Feed verticalList">
                  <DetailFeed
                    detail={true}
                    item={item}
                  />
                  <Keywords
                    keywords={_.get(
                      item,
                      'keywords',
                      []
                    )}
                  />
                </div>
                <Responses
                  type={item._type}
                  id={item.id}
                />
              </>
            ) : (
              <LoadingPage/>
            )}
          </div>
          <LayoutContext.Consumer>
            {({breakpoint}) =>
              ['xs', 'sm', 'md'].find(
                value =>
                  value === breakpoint
              ) ? (
                <div className="flex flex-col gap-6">
                  {!_.isEmpty(
                    item.others_in_container
                  ) && (
                      <div className="flex flex-col gap-2">
                          <div className="border-b font-bold text-xs text-color-400 uppercase tracking-wide">
                              {t('related articles')}
                          </div>
                          <FeedMoreRecommends
                              item={item.container}
                              list={item.others_in_container}
                          />
                      </div>
                  )}
                  <GoogleAdsInArticleUnit/>
                  {!_.isEmpty(
                    item.others_in_creator
                  ) && (
                      <div className="flex flex-col gap-2">
                          <div className="border-b font-bold text-xs text-color-400 uppercase tracking-wide">
                              {t('from the sender')}
                          </div>
                          <FeedMoreRecommends
                              item={item.creator}
                              list={item.others_in_creator}
                          />
                      </div>
                  )}

                  <GoogleAdsRecommendedUnit/>
                </div>
              ) : null
            }
          </LayoutContext.Consumer>

        </>
      )}
      {errorMessages && (
        <Result
          status="404"
          subTitle={
            errorMessages['error']
          }
          extra={
            <Link to={staticPaths.home}>
              <Button type="primary" className="button-rounded-md no-border">
                {t('Back Home')}
              </Button>
            </Link>
          }
        />
      )}
    </ContentLayout>
  )
})
