import {create_product_create_Api} from 'apis'
import {baseModel} from 'apis/model'
import ApiInfoForm from 'components/form/ApiInfoForm'
import useTranslate from 'modules/local/useTranslate'
import useRouter, {useHistory} from 'modules/navigation/useRouter'
import React, {useContext} from 'react'
import {useRouteMatch} from 'react-router-dom'
import createValidate from './createValidate'
import {ProductBasicCreateForm} from './ProductBasicInfo'
import ServerContext from "../../Server/ServerContext";
import {useAppConfig} from "../../../modules/local";
import {useLocation} from "react-use";
import {emptyObject} from "../../../helpers/emptyObjects";

export const ProductCreateForm = ({onSuccess, initialValues = {}}) => {
  const {item: productOwner} = useContext(ServerContext);
  const history = useHistory()
  const location = useLocation()
  const { handleGoBack } = useRouter()
  const t = useTranslate()
  let {url} = useRouteMatch();

  const appConfig = useAppConfig()
  const {
    product_units = [],
    product_price_units = [],
  } = appConfig ||
  {
    product_units: [],
    product_price_units: []
  }

  return (
    <div className="flex flex-col space-y-3 p-3">
      <div className="max-w-5xl w-full mx-auto">
        <ApiInfoForm
          validate={createValidate(t)}
          onPreSubmit={({
                          photos = [],
            ...rest
          }) => ({
            ...rest,
            ...(photos
              ? {
                  photos: photos.map(
                    file => {
                      let photo
                      file.read(
                        file => {
                          photo = baseModel.get(
                            file,
                            'id'
                          )
                        }
                      )
                      return photo
                    }
                  )
                }
              : {})
          })}
          apiInfo={create_product_create_Api()}
          initialValues={{
            ...initialValues,
            title: '',
            description: '',
            owner_type: 'organization',
            owner_id: productOwner.id,
            hidden_price: 0,
            wholesale: 1,
            public: 1,
            categories: [],
            price_unit_id: (product_price_units.find(e => e.code === "VND") || emptyObject).id,
            unit_id: (product_units.find(e => e.code === "weight-kg") || emptyObject).id,
          }}
          onSuccess={([id]) => {
            history.replace(
              `/workspace/manage?rel=product-list&tab=unlisted-products`
            );
            window.location.reload();
          }}>
          <ProductBasicCreateForm
            submitText={t(
              'Save and continue'
            )}
          />
        </ApiInfoForm>
      </div>
    </div>
  )
}
export default ProductCreateForm
