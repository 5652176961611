import _ from 'lodash'
import React from 'react'
import useNavRouteAction from './useNavRouteAction'

const useSettingNavRoutes = (
  routes,
  params = {}
) => {
  const {
    pathField = 'path',
    anchor = '/settings',
    getDefaultRoute = _.first,
  } = params
  const defaultRoute =
    getDefaultRoute(routes)

  const defaultAction = _.get(
    defaultRoute,
    pathField
  )

  const {currentAction} =
    useNavRouteAction({
      anchor,
      defaultAction,
      isAction: (action) => {
        return routes
          .map(({path}) => path)
          .includes(action)
      },
    })

  if (_.isEmpty(routes)) return {}

  return {
    currentAction,
    defaultAction,
  }
}

export const withSettingNavRoutes =
  (Component) =>
    ({routes, ...props}) => {
      const {currentAction} =
        useSettingNavRoutes(routes)
      return (
        <Component
          {...props}
          {...{
            routes,
            currentAction,
          }}
        />
      )
    }

export default useSettingNavRoutes
