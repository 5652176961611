import Icon, {PaperClipOutlined} from '@ant-design/icons'
import {Avatar, Button, Menu, Modal, Skeleton} from 'antd'
import {baseModel, productModel} from 'apis/model'
import {createBaseItemSchema, productSchema} from 'apis/schema'
import EmptyHolder from 'components/EmptyHolder'
import Count from 'components/Feed/Count'
import Description from 'components/Feed/Description'
import Toggle from 'components/Toggle'
import {UseHook} from 'components/UseHook'
import {API_ROOT_URL} from 'envs/_current/config'
import useQueryString from 'hooks/useQueryString'
import _ from 'lodash'
import CustomLazyPagination from 'modules/asyncCache/components/CustomLazyPagination'
import {DidOnChange} from 'modules/asyncCache/components/DidOnChange'
import {getAsynCacheSelector} from 'modules/asyncCache/selectors'
import useTranslate from 'modules/local/useTranslate'
import queryString from 'query-string'
import React from 'react'
import {AiOutlineDollar, AiOutlineDown, AiOutlineFileSearch, AiOutlineSetting} from 'react-icons/ai'
import {BsChevronCompactRight, BsGear} from 'react-icons/bs'
import {FaYoutube} from 'react-icons/fa'
import {FcBinoculars} from "react-icons/fc"
import ReactPlayer from 'react-player'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-use'
import {useSelectEntities} from 'redux/entities/useSelectEntities'
import formatCurrency from 'views/Billing/components/formatCurrency'
import {Selector} from 'views/Discovery/Recommendation'
import DrawMenu from "../../../components/drawer/DrawMenu"
import {FullSideModalLayout} from "../../../components/Modal/SideModalLayout"
import {FB_ID} from "../../../envs/_current/config"
import {useAppConfig} from "../../../modules/local"
import useRouter from "../../../modules/navigation/useRouter"
import {HeaderButton} from "../../Organization/components/PageHeader"
import {GetQuoteButton} from '../components/GetQuoteButton'
import {Layout} from '../Layout'
import {SelectCategory} from "../MarketSearch/SelectCategory"
import {SelectLocation} from '../MarketSearch/SelectLocation'
import productImageHolder from '../productImageHolder'
import {ProductSettingContainer} from "../Seller/Product"
import {PriceAdvancedInputForm} from "../Seller/Product/ManageProductPrices"
import {getType, getId} from "../../../apis/model/base";
import {SpotRequestTypes} from "../components/SpotBid/SpotRequestForm";
import SpotRequestForm from "../components/SpotBid/SpotRequestForm";
import OrderRequestProvider from "../../OrderRequest/OrderRequestProvider";
import {IoEllipsisHorizontalSharp} from "react-icons/io5";
import {MiniButton} from "../../AppLocals/Organization/OrganizationEntity";
import {HiPaperClip} from "react-icons/hi2";
import {HiViewGrid} from "react-icons/hi";
import {FiChevronRight} from "react-icons/fi";

const ProductMenu = ({ item }) => {
  const t = useTranslate()

  return (
    <DrawMenu
      component={
          <Count>
              <BsGear size={12} className="text-color-400"/>
          </Count>
      }>
      {(isToggle, toggle) => (
        <Menu style={{ minWidth: 280 }}>
          <Menu.Item key="1" className="flex mt-2">
            <Toggle key={item.id}>
              {( isTogglePriceInput, togglePriceInput ) => (
                  <div className="w-full">
                    <div
                        onClick={togglePriceInput} 
                        className="flex items-center gap-2">
                        <AiOutlineDollar />
                        <span>{t('price set history management')}</span>
                    </div>
                    {isTogglePriceInput && (
                        <FullSideModalLayout
                          right
                          onCancel={togglePriceInput}
                          maskClosable={false}
                          width={720}
                          title={
                            <div className="font-bold text-color-000">
                              {_.get(item, 'title')}
                            </div>
                          }
                          destroyOnClose
                          visible={isTogglePriceInput}
                          footer={null}>
                          <PriceAdvancedInputForm {...{item}}/>
                        </FullSideModalLayout>
                    )}
                  </div>
              )}
            </Toggle>
          </Menu.Item>
          <Menu.Item key="2" className="flex mt-2">
            <Toggle>
              {( isToggleProductEdit, toggleProductEdit ) => (
                  <div className="w-full">
                    <div
                        onClick={toggleProductEdit}
                        className="flex items-center gap-2">
                        <AiOutlineSetting />
                        <span>{t('product configuration')}</span>
                    </div>
                    {isToggleProductEdit && (
                        <FullSideModalLayout
                          right
                          onCancel={toggleProductEdit}
                          maskClosable={false}
                          width={720}
                          title={
                            <div className="font-bold text-color-000">
                              {_.get(item, 'title')}
                            </div>
                          }
                          destroyOnClose
                          visible={isToggleProductEdit}
                          footer={null}>
                          <ProductSettingContainer id={_.get(item, 'idname')}/>
                        </FullSideModalLayout>
                    )}
                  </div>
              )}
            </Toggle>
          </Menu.Item>
        </Menu>
      )}
    </DrawMenu>
  )
}

export const ProductView = ({ item }) => {
  const t = useTranslate()
  const isAdmin = !!_.get(item, 'owner.edit') || _.get(item, 'edit');
  const owner = _.get(item, 'owner', {})

  return !!item && (
    <div id={item.idname} className="border-b pb-3 mb-2">
      <div className="flex flex flex-col gap-2 relative">
          {!!isAdmin && (
              <div style={{ top: 10 }} className="absolute right-5 z-3">
                  <ProductMenu item={item} />
              </div>
          )}
          <div className="flex gap-3">
              <div className="w-1/4">
                  <img
                      style={{ maxHeight: 160 }}
                      className="w-full h-full object-cover rounded-md"
                      src={_.get(item, 'thumbnail', productImageHolder) || productImageHolder}
                  />
              </div>
              <div className="flex flex-col flex-1">
                  <Link
                      className="font-bold text-lg text-color-000 no-underline max-lines-1 leading-snug mr-10"
                      to={productModel.getLinkToDetail(item)}>
                      {item.title}
                  </Link>
                  <div className="flex flex-col gap-2 mt-2 mb-3">
                      {!!!item.hidden_price && ( item.price > 0 ? (
                          <span className="flex items-center gap-1 text-sm">
                              <span className="font-bold text-primary-600">
                                  {formatCurrency(item.price)}
                              </span>
                              {'/'}
                              <span className="text-color-400 italic">
                                  {_.get(item, 'unit.name', t('unit'))}
                              </span>
                          </span>
                      ) : (
                          <span className="font-bold text-xs text-blue-500 italic">
                              {`* ${t('no price tag')}`}
                          </span>
                      ))}
                      <div className="flex items-center gap-2">
                          {!!item.wholesale && (
                              <span className="font-medium flex-1 text-sm text-color-300 italic">
                                  {t('contact provider for wholesale price')}
                              </span>
                          )}
                          <OrderRequestProvider organization={owner}>
                              <SpotRequestForm
                                  target={{ value: item }}
                                  id={getId(owner)}
                                  type={getType(owner)}
                                  requestType={SpotRequestTypes.PRICE}>
                                  {(toggle) => {
                                      return (
                                          <button
                                              className="font-medium text-primary hover:text-primary-700"
                                              onClick={toggle}>
                                              {t('Get Best Price')}
                                          </button>
                                      )
                                  }}
                              </SpotRequestForm>
                          </OrderRequestProvider>
                      </div>
                  </div>
                  <div className="flex items-center justify-between gap-2">
                      <div className="flex items-center gap-3">
                          {item.video_url && (
                              <Toggle>
                                  {( isToggle, toggle ) => (
                                      <div className="flex flex-col">
                                          <button
                                              onClick={() => toggle()}
                                              data-effect="ripple"
                                              style={{ padding: '4px 8px' }}
                                              className="flex items-center gap-2 border border-color-50 text-color-300 hover:text-primary hover:shadow-items-md rounded-md capitalize">
                                              <FaYoutube style={{ color: '#FF0000' }} />
                                              <span className="font-bold text-xs leading-tight">{t('video')}</span>
                                          </button>
                                          <Modal
                                              destroyOnClose
                                              maskClosable
                                              onCancel={toggle}
                                              closeIcon={null}
                                              bodyStyle={{ padding: 0 }}
                                              header={null}
                                              footer={null}
                                              visible={isToggle}>
                                              <ReactPlayer
                                                  style={{fontSize: '1em'}}
                                                  width="100%"
                                                  height={400}
                                                  url={item.video_url}
                                                  config={{
                                                      youtube: {playerVars: {controls: 1, showinfo: 1}},
                                                      facebook: {appId: FB_ID}
                                                  }}
                                              />
                                          </Modal>
                                      </div>
                                  )}
                              </Toggle>
                          )}
                          {item.pdf_path && (
                              <a
                                  download
                                  href={
                                      item.pdf_path
                                  }
                                  target="_blank"
                                  data-effect="ripple"
                                  style={{ padding: '4px 8px' }}
                                  className="flex items-center gap-2 border border-color-50 text-color-300 hover:text-primary hover:shadow-items-md rounded-md no-underline">
                                  <HiPaperClip className="text-blue-500"/>
                                  <span className="font-bold text-xs leading-tight">{'PDF'}</span>
                              </a>
                          )}
                      </div>
                      <SpotRequestForm
                          target={{
                              value: item,
                          }}
                          id={getId(owner)}
                          type={getType(owner)}
                          requestType={SpotRequestTypes.PRODUCT_INFO}>
                          {(toggle) => (
                              <MiniButton
                                  type="primary"
                                  onClick={toggle}
                                  labelClassName="font-bold italic"
                                  label={t('contact provider')}
                                  className="border border-primary-600"
                              />
                          )}
                      </SpotRequestForm>
                  </div>
              </div>
          </div>
          {item.description && (
              <Description className="hidden md:block text-color-300 max-lines-4">
                  {item.description}
              </Description>
          )}
      </div>
    </div>
  )
}

export const ProductViewRow = ({ item }) => {
  const t = useTranslate();
  const isAdmin = !!_.get(item, 'owner.edit');
  const {
    handleGoBack,
    history
  } = useRouter();
  return (
    <div id={item.idname} className="p-2 md:rounded background-100 rounded-lg">
      {item && (
        <div className="relative flex space-x-3">
          <div className="h-12 w-12">
            <div
              style={{
                paddingTop: '100%'
              }}
              className="w-full relative background-200 rounded-lg overflow-hidden">
              <div className="absolute w-full h-full top-0 left-0">
                <img
                  className="object-cover w-full h-full"
                  src={
                    _.get(
                      item,
                      'thumbnail',
                      productImageHolder
                    ) ||
                    productImageHolder
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className=" flex-1 ">
            <div className="h-full w-full flex flex-col">
              <div className="w-full ">
                <div
                  className="absolute"
                  style={{
                    top: '-50px'
                  }}></div>
                <h2 className="m-0 font-bold leading-tight">
                  <Link
                    className="text-primary"
                    to={productModel.getLinkToDetail(
                      item
                    )}>
                    {item.title}
                  </Link>
                </h2>
                <div className="text-xs leading-none">
                  {!!item.product_line && (
                    <span className="text-orange-500">
                      {item.product_line}
                    </span>
                  )}
                </div>
                {
                  !isAdmin && (
                    <div className="italic text-sm ">
                      {t('provided by')}{' '}
                      <b>
                        {_.get(
                          item,
                          'owner.title'
                        )}
                      </b>
                    </div>
                  )
                }
                <div className="text-sm">
                  {!!!item.hidden_price && (
                    item.price > 0 ? (
                      <span className="mr-6">
                        <b>
                          {formatCurrency(
                            item.price
                          )}
                        </b>
                        /{_.get(item, 'unit.name', t('unit'))}
                      </span>
                    ) : (
                      <span className="mr-6 text-color-300">
                        {`* ${t('no price tag')}`}
                      </span>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          {
            isAdmin && (
              <ProductMenu item={item} />
            )
          }
        </div>
      )}
    </div>
  )
}

const enhance = (data, map = []) =>
  data.reduce((result, value) => {
    result[value.id] = value
    result[value.id] = {
      ...result[value.id]
    }
    result[value.id].map = map
    if (
      value.children &&
      value.children.length
    ) {
      result = {
        ...result,
        ...enhance(value.children, [
          ...map,
          value.id
        ])
      }
    }
    return result
  }, {})

export function Result({ } = {}) {
  const t = useTranslate()
  const location = useLocation()
  const path =
    '/search/filter-data/product' +
    location.search
  const schema = createBaseItemSchema({
    idAttribute: 'owner_id',
    schema: {
      owner: productSchema
    }
  })
  const apiInfo = {
    backend: ``,
    root: API_ROOT_URL,
    path,
    method: 'GET',
    fields: [],
    paramsFields: [],
    fieldTypes: {},
    schema
  }
  const dispatch = useDispatch()

  return (
    <div id={"workspace"} className="space-y-2 md:space-y-6 w-full">
      <CustomLazyPagination
        cacheId={apiInfo.path}
        key={apiInfo.path}
        {...{
          apiInfo: apiInfo
        }}
        renderItem={({
          owner: item
        }) => {
          return (
            <ProductView
              key={item.id}
              {...{ item }}
            />
          )
        }}
        renderLoading={() => (
          <div className="pb-8 pt-6 px-4 m-3 flex flex-col items-center m-auto max-w-2xl text-center rounded-full">
            <div className="m-3">
              <div
                style={{
                  fontSize: '3rem',
                  color:
                    'rgba(226, 232, 240, 1)'
                }}>
                <AiOutlineFileSearch />
              </div>
            </div>

            <div className="text-2xl text-color mb-4">
              <Skeleton.Input
                style={{ width: 150 }}
                active="true"
                size={'small'}
              />
            </div>
            <div className="text-color-100">
              <Skeleton.Input
                style={{ width: 200 }}
                active="true"
                size={'small'}
              />
            </div>
          </div>
        )}
        renderNoMore={() => (
          <EmptyHolder
            icon={() => (
              <div
                style={{
                  opacity: 0.5,
                  fontSize: '3rem',
                  color: 'rgba(226, 232, 240, 1)'
                }}>
                <FcBinoculars />
              </div>
            )}
            subTitle={null}
            title={null}
          />
        )}>
        {(render, record) => {
          const { asyncId } = record

          return (
            <>
              {asyncId && (
                <Selector
                  selector={state =>
                    getAsynCacheSelector(
                      state,
                      asyncId
                    )
                  }>
                  {item => (
                    <DidOnChange
                      handler={() => {
                        dispatch({
                          type:
                            'update',
                          entities: {
                            baseItems: {
                              ['[market.search]' +
                                path]: baseModel.get(
                                  item,
                                  'response.data',
                                {}
                                )
                            }
                          }
                        })
                      }}
                      input={[
                        item
                      ]}></DidOnChange>
                  )}
                </Selector>
              )}

              {render()}
            </>
          )
        }}
      </CustomLazyPagination>
    </div>
  )
}

export function RelatedCategories({
  setQuery
} = {}) {
  const t = useTranslate()
  const location = useLocation()
  const item = useSelectEntities(
    '[market.search]/search/filter-data/product' +
    location.search
  )
  const related_categories = enhance(
    _.get(
      item,
      'filters.related_categories.options',
      []
    )
  )

  return (
    <div className="space-y-3  h-1/2">
      <nav
        className="w-full md:text-sm md:shadow-none flex flex-1 md:flex-col flex-row overflow-auto rounded  bg-transparent  ">
        <div
          className="flex truncate flex-shrink-0 items-center p-2 text-sm font-semibold font-semibold text-color-300">
          {t('related categories')}
        </div>
        {Object.keys(
          related_categories
        ).map(key => {
          const {
            id,
            label,
            map
          } = related_categories[key]
          return (
            <div
              title={label}
              key={id}
              onClick={() =>
                setQuery({
                  categories: id
                })
              }
              className={
                item.children
                  ? 'p-2 py-1 mr-2 md:rounded-none md:mr-0 flex-shrink-0 text-center rounded-lg border md:border-none md:text-left cursor-pointer md:border-b border-gray-300 flex items-center font-semibold'
                  : 'p-2 py-1 mr-2 md:rounded-none md:mr-0 flex-shrink-0 text-center rounded-lg border md:border-none md:text-left cursor-pointer md:border-b border-gray-300 flex items-center'
              }>
              <span className="truncate hover:underline w-full">
                {Boolean(map.length) &&
                  new Array(
                    map.length
                  ).fill('• ')}
                {label}
              </span>
            </div>
          )
        })}
      </nav>
    </div>
  )
}

export default function Impcat() {
  const t = useTranslate();
  const location = useLocation();
  const appConfig = useAppConfig();
  const item = useSelectEntities(
    '[market.search]/search/filter-data/product' +
    location.search
  )
  const related_provinces = _.get(
    item,
    'filters.related_provinces.options',
    []
  )
    .flatMap(({ id, label }) => {
      return {
        id,
        label,
        value: id
      }
    })
    .filter(item => !_.isEmpty(item));

  const searchParams = queryString.parse(location.search);

  return (
    <UseHook
      hook={useQueryString}
      deps={[]}>
      {([query, setQuery]) => {
        const data = _.get(
          appConfig,
          'hierarchy_product_categories',
          []
        )

        const enhancedData = enhance(
          data
        )
        const category =
          enhancedData[
          query.categories
          ]
        let categoryMap =
          category &&
          category.map.map(
            id => enhancedData[id]
          );

        if (_.isEmpty(categoryMap)) {
          categoryMap = [category];
        }

        return (
          <Layout>
            <div className=" mx-auto space-y-3">
              <div className="flex items-center space-x-3">
                <div className="w-full">
                  <div className="py-3 w-full flex items-center text-xs italic overflow-x-auto">
                    <Link
                        to={`/mart/c`}
                        className="text-color-400 no-underline">
                        {t('category')}
                    </Link>

                    {Boolean(categoryMap && categoryMap.length) && categoryMap.filter(e => !!e).map(cate => (
                          <React.Fragment key={cate.id}>
                            <FiChevronRight className="mx-1 text-color-200"/>
                            <Link
                              key={cate.id}
                              to={`/mart/impcat?categories=${cate.id}`}
                              className="text-color-400 no-underline">
                                {cate.name}
                            </Link>
                          </React.Fragment>
                        )
                      )}
                  </div>
                  {category && (
                    <div className="mt-2 p-3 flex flex-center gap-3 border border-color-50 shadow-items-md rounded-lg">
                        <Avatar
                            size={35}
                            src={category.avatar}
                            icon={<HiViewGrid size={20} className="text-color-500"/>}
                            className="flex flex-center background-200 rounded-md"
                        />
                        <Link
                            className="font-bold text-xl text-primary-700 no-underline"
                            to={`/mart/impcat?categories=${category.id}`}>
                            {category.name}
                        </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="space-y-3">

                <div className="flex flex-col md:flex-row items-center gap-3">
                    <SelectCategory
                        categoryList={categoryMap}
                        value={category.id}
                        onChange={value => {
                            setQuery({
                                ...searchParams,
                                categories: value
                            })
                        }}
                    />
                    <SelectLocation
                        related_provinces={
                            related_provinces
                        }
                        value={
                            query.province
                        }
                        onChange={value => {
                            setQuery({
                                ...searchParams,
                                province: value
                            })
                        }}
                    />
                </div>

                <div className=" m-auto block grid grid-cols-12 w-full gap-2 md:gap-6 ">
                  <div className="col-span-12 md:col-span-3 space-y-3">
                    <section
                      className="h-full md:block hidden"
                      style={{
                        overflow:
                          'initial'
                      }}>
                      <RelatedCategories
                        {...{
                          categories:
                            query.categories,
                          query,
                          setQuery
                        }}
                      />
                    </section>
                  </div>
                  <div className="col-span-12  md:col-span-9 ">
                    <Result
                      categories={
                        query.categories
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        )
      }}
    </UseHook>
  )
}
