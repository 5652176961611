import React from "react";

export const RealEstate = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 64 64"
		>
			<path fill="#fff" d="M14 23h8v25h-8z"></path>
			<path fill="#1e4b7c" d="M10 48h15v14H10z"></path>
			<path fill="#f7e6d5" d="M9 52H4v4a2 2 0 002 2h4v-4H9z"></path>
			<path fill="#2d72bc" d="M9 25.243V54h4.926V24z"></path>
			<path fill="#d6dee0" d="M55 11H37v14h6v-8h6v8h6V14z"></path>
			<path fill="#8c5d1e" d="M43 17h6v8h-6z"></path>
			<path fill="#2d72bc" d="M17.926 36l2-4-2-8-2 8z"></path>
			<path fill="#656b76" d="M57 11l-3-2.182V3h-4v2.909L46 3l-11 8h20z"></path>
			<path
				fill="#f7e6d5"
				d="M54.748 31.516l-1.228-1.037a1.343 1.343 0 00-1.652-.064l-2.494 1.797.097-.853c.127-1.111-1.08-1.881-2.034-1.299a1.34 1.34 0 00-.525.595l-2.902 5.202 3.127 3.655c1.834-.849 4.605-2.354 5.412-3.309l2.36-2.793a1.346 1.346 0 00-.161-1.894z"
			></path>
			<path
				fill="#1e4b7c"
				d="M43.233 34.949l-6.034 3.01L28 26l-1-.333v10.406L26.926 36 36 45l11.783-4.731z"
			></path>
			<path
				fill="#f7e6d5"
				d="M25.144 18.505a3.511 3.511 0 01-.181-1.113V9.485a6.442 6.442 0 01-3 1.479c-5 1-10 1-10 1l-2.553 8.51.313.428a7.474 7.474 0 006.034 3.062h6.24c.685 0 1.375-.16 1.951-.53a3.534 3.534 0 001.471-4.103z"
			></path>
			<path
				fill="#8c5d1e"
				d="M5.963 7.963c0 6 3 14 3 14l3-10s5 0 10-1a6.442 6.442 0 003-1.479c2.428-2.107 3-5.521 3-5.521h-11s-11-.123-11 4z"
			></path>
			<path fill="#183c63" d="M17 56h2v6h-2z"></path>
			<path fill="#656b76" d="M55 14v10H33v2h28V16z"></path>
			<path fill="#1e4b7c" d="M6 26L3 52h6V25.243z"></path>
			<path fill="#2d72bc" d="M27 25.667L22 24v30h4.926V36l.074.073z"></path>
		</svg>
	);
}

export default RealEstate;
