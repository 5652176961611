import Loadable from 'react-loadable'
import LoadingPage from 'views/LoadingPage'

export const Event = Loadable({
  loader: () => import('./Event'),
  loading: LoadingPage
})

export const EventSetting = Loadable({
  loader: () => import('./EventSetting'),
  loading: LoadingPage
})
