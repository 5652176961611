import {PlusOutlined} from '@ant-design/icons'
import {Breadcrumb, Button, Form, Input, Select, Tabs} from 'antd'
import {create_product_getProducts_Api} from 'apis'
import {API_ROOT_URL} from 'envs/_current/config'
import logParams from 'helpers/logParams'
import useQueryString from 'hooks/useQueryString'
import _ from 'lodash'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useEffect, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {PageContext} from '../../../PageContext'
import {AsyncWithCache} from "../../../../../modules/asyncCache/components/Async";
import {productSchema} from "../../../../../apis/schema";
import {SelectEntityItem} from "../../../../../components/SelectEntityItem";
import {organizationModel} from "../../../../../apis/model";
import ServerContext from "../../../../Server/ServerContext";
import {OrganizationContext} from "../../../../Organization/OrganizationContext";
import FieldDecorator from "../../../../../components/form/FieldDecorator";
import ToggleView from "../../../../MainPage/ToggleView";
import {ProductSettingListView} from "./ProductSettingListView";
import {ProductSettingHeadlineView} from "./ProductSettingHeadlineView";
import EmptyHolder from "../../../../../components/EmptyHolder";
import EmptyInboxStateSvg from "../../../../../components/Svgs/EmptyInboxStateSvg";
import {IoChevronDown} from "react-icons/io5";
import {renderIf, renderIfElse} from "../../../../Shared";
import ListHeader from "../../../../AppLocals/Templates/items/ListHeader";
import {MiniButton} from "../../../../AppLocals/Organization/OrganizationEntity";

const {Option} = Select;

export const workspaceManageProductRoutes = {
  "listed-products": {
    name: 'listed-products',
    path: '/workspace/manage?rel=product-list&tab=listed-products',
    breadcrumbName: 'listed products',
  },
  "unlisted-products": {
    name: 'unlisted-products',
    path: '/workspace/manage?rel=product-list&tab=unlisted-products',
    breadcrumbName: 'unlisted products',
  },
}

const ProductSettingList = ({organizationId, filters, renderItem}) => {
  const t = useTranslate();
  const {handleActionClick} = useContext(ServerContext);

  return (
      <div className="w-full space-y-1 mt-6">
        <LazyPagination
            {...{
              key: JSON.stringify(filters),
              apiInfo: create_product_getProducts_Api(),
              query: {
                ':type': 'organization',
                ':id': organizationId
              },
              values: filters
            }}
            renderEmpty={() => {
              return (
                  <EmptyHolder subTitle={t('no products, please add products to your page')}>
                    <div className="p-6">
                      <Button
                          type="primary"
                          className="button-rounded-md no-border"
                          onClick={() => handleActionClick('create-product')}>
                        {t('create product')}
                      </Button>
                    </div>
                  </EmptyHolder>
              )
            }}
            renderItem={(item) => {
              return (
                  <SelectEntityItem
                      key={item.idname}
                      item={item.idname}
                      schema={
                        productSchema
                      }>
                    {(
                        item
                    ) => !!item && !!renderItem && renderItem({item})}
                  </SelectEntityItem>
              )
            }}/>
      </div>
  )
}

export const OrgManageProducts = ({organizationId}) => {
  const t = useTranslate()
  const {
    groups = [],
    organization
  } = useContext(OrganizationContext)
  const {
    item,
    handleActionClick,
    contextMenu
  } = useContext(ServerContext)

  const [form] = Form.useForm()

  const appConfig = useAppConfig()
  const {
    product_categories = [],
  } = appConfig ||
  {
    product_categories: [],
  }

  const [
    values,
    setValues
  ] = useQueryString()
  const onReset = () => {
    setValues({})
  }
  useEffect(() => {
    form.resetFields()
  }, [form, values])

  const history = useHistory();
  const location = useLocation();

  const defaultPath = Object.values(workspaceManageProductRoutes).find(e => e.path === location.pathname + location.search)
    || workspaceManageProductRoutes["listed-products"];
  const defaultId = _.get(defaultPath, 'name');
  const [current, setCurrent] = useState(
    defaultId
  );

  const onTabChange = (key) => {
    setCurrent(key);
    history.push("/workspace/manage?rel=product-list&tab=" + key);
  }

  return (
    <div className="space-y-6">
      <ToggleView
          height={26}
        renderHeadlineView={props => <ProductSettingHeadlineView {...props} />}
        renderListView={props => <ProductSettingListView {...props} />}>
        {({switchComponent, renderItem}) => (
            <div id={"workspace_product_list"} className="space-y-3">
              <ListHeader
                  title="products"
                  extra={
                    !!_.get(organization, 'edit') && (
                      <Button
                          type="primary"
                          className="button-rounded-lg no-border"
                          onClick={() => {
                            setCurrent("draft products")
                            handleActionClick('create-product')
                          }}>
                        {t('create product')}
                      </Button>
                  )}
              />

              <div className="w-full p-3 border border-color-50 shadow-items rounded-lg">
                <div className="font-bold text-sm text-primary-600 uppercase tracking-wide border-b border-primary mb-4">
                  {t('filter')}
                </div>
                <div className="w-full flex flex-col gap-2 px-3">
                  <Form
                      form={form}
                      name="basic"
                      initialValues={values}
                      onFinish={setValues}
                      onFinishFailed={logParams}>
                    <FieldDecorator
                        className="grid grid-cols-10 items-center gap-3 mb-2"
                        labelProps={{ className: 'capitalize' }}
                        label={
                          <span className="col-span-2 text-sm text-color-400 italic">
                            {`${t('title')} : `}
                          </span>
                        }
                        name="keyword">
                      <Form.Item style={{ marginBottom: 0 }} className="col-span-8" name="keyword">
                        <Input className="rounded-md" name="keyword"/>
                      </Form.Item>
                    </FieldDecorator>
                    <FieldDecorator
                        className="grid grid-cols-10 items-center gap-3 mb-2"
                        labelProps={{ className: 'capitalize' }}
                        label={
                          <span className="col-span-2 text-sm text-color-400 italic">
                            {`${t('categories')} : `}
                          </span>
                        }
                        name="categories">
                      <Form.Item style={{ marginBottom: 0 }} className="col-span-8" name="categories">
                        <Select
                            showSearch
                            allowClear
                            virtual={false}
                            optionFilterProp="label"
                            mode="multiple"
                            name="categories"
                            placeholder={t('please select')}
                            style={{
                              width: '100%'
                            }}>
                          {product_categories &&
                          product_categories.map(
                              item => {
                                const {
                                  id,
                                  name
                                } = item
                                return (
                                    <Select.Option
                                        key={id}
                                        value={id}
                                        label={name}>
                                      {name}
                                    </Select.Option>
                                )
                              }
                          )}
                        </Select>
                      </Form.Item>
                    </FieldDecorator>
                    <FieldDecorator
                        className="grid grid-cols-10 items-center gap-3 mb-2"
                        labelProps={{ className: 'capitalize' }}
                        label={
                          <span className="col-span-2 text-sm text-color-400 italic">
                            {`${t('product catalogs')} : `}
                          </span>
                        }
                        name="product_catalog_id">
                      <Form.Item style={{ marginBottom: 0 }} className="col-span-8" name="product_catalog_id">
                        <Select
                            allowClear
                            suffixIcon={<IoChevronDown/>}
                            placeholder={t('please select')}
                            name="product_catalog_id" className="w-full">
                          <Option value={''}>
                            {t('all')}
                          </Option>
                          {groups.map(item => (
                              <Option
                                  key={item.id}
                                  value={item.id}>
                                {`${item.title}`}
                                {_.has(item, 'products') &&
                                ` (${item.products.length})`}
                              </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </FieldDecorator>
                    <div className="flex justify-end gap-3 mt-4">
                      <MiniButton
                          type="danger"
                          onClick={onReset}
                          labelClassName="italic"
                          label={t('delete')}
                      />
                      <MiniButton
                          type="primary"
                          onClick={onReset}
                          labelClassName="italic"
                          label={t('search')}
                      />
                    </div>
                  </Form>
                </div>
              </div>

              <Tabs
                activeKey={current}
                onChange={onTabChange}
                tabBarExtraContent={switchComponent && switchComponent}
                animated={false}>
                <Tabs.TabPane
                  tab={t('listed products')}
                  key="listed-products">
                </Tabs.TabPane>
                <Tabs.TabPane
                  tab={t('unlisted products')}
                  key="unlisted-products">
                </Tabs.TabPane>
              </Tabs>

              <ProductSettingList
                renderItem={renderItem}
                organizationId={organizationId}
                filters={
                  {
                    ...values,
                    title: values.keyword,
                    active: renderIfElse(current === "listed-products", "1", "0")
                  }
                }
              />

            </div>
        )}
      </ToggleView>
    </div>
  )
}

export const ManageProducts = ({organizationId, rootPath}) => {
  const t = useTranslate()
  const {
    groups = [],
    organization
  } = useContext(OrganizationContext)
  const {
    item,
    handleActionClick,
    contextMenu
  } = useContext(ServerContext)

  const [form] = Form.useForm()

  const appConfig = useAppConfig()
  const {
    product_categories = [],
  } = appConfig ||
  {
    product_categories: [],
  }

  const [
    values,
    setValues
  ] = useQueryString()
  const onReset = () => {
    setValues({})
  }
  useEffect(() => {
    form.resetFields()
  }, [form, values])

  const history = useHistory();
  const location = useLocation();

  const defaultPath = Object.values(workspaceManageProductRoutes).find(e => e.path === location.pathname)
    || workspaceManageProductRoutes["listed-products"];
  const defaultId = _.get(defaultPath, 'name');
  const [current, setCurrent] = useState(
    defaultId
  )

  const onTabChange = (key) => {
    setCurrent(key);
    history.push("/workspace/manage?rel=product-list&tab=" + key);
  }
  return (
    <div className="p-3 space-y-6">
      <div className="flex flex-shrink-0">
        <Breadcrumb>
          <Breadcrumb.Item>
            {organizationModel.getTitle(
              item
            )}
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t('products')}
          </Breadcrumb.Item>

          <Breadcrumb.Item>
            {t(workspaceManageProductRoutes[defaultId].breadcrumbName)}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-y-3 md:gap-3">
        <section className="w-full background-100">
          <div className="p-3 font-medium text-xl uppercase">
            {t('filter')}
          </div>
          <div className="w-full flex-col sticky md:shadow-none flex flex-1 md:flex-col flex-row rounded background-100">
            <div className="p-3 background-100 pt-3">
              <Form
                form={form}
                className=" max-w-2xl"
                name="basic"
                initialValues={values}
                onFinish={setValues}
                onFinishFailed={logParams}>
                <FieldDecorator
                  label={t('title')}
                  name="keyword">
                  <Form.Item
                    name="keyword"
                  >
                    <Input name="keyword"/>
                  </Form.Item>
                </FieldDecorator>
                <FieldDecorator
                  label={t('categories')}
                  name="categories">
                  <Form.Item
                    name="categories"
                  >
                    <Select
                      showSearch
                      allowClear
                      virtual={false}
                      optionFilterProp="label"
                      mode="multiple"
                      name="categories"
                      placeholder={t(
                        'please select'
                      )}
                      style={{
                        width: '100%'
                      }}>
                      {product_categories &&
                      product_categories.map(
                        item => {
                          const {
                            id,
                            name
                          } = item
                          return (
                            <Select.Option
                              key={id}
                              value={id}
                              label={name}>
                              {name}
                            </Select.Option>
                          )
                        }
                      )}
                    </Select>
                  </Form.Item>
                </FieldDecorator>
                <FieldDecorator
                  label={t('catalog')}
                  name="product_catalog_id">
                  <Form.Item
                    name="product_catalog_id"
                  >
                    <Select
                      allowClear
                      name="product_catalog_id" className="w-full">
                      <Option value={''}>
                        {t('all')}
                      </Option>
                      {groups.map(item => (
                        <Option
                          key={item.id}
                          value={item.id}>
                          {`${item.title}`}
                          {_.has(item, 'products') &&
                          ` (${item.products.length})`}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </FieldDecorator>

                <Form.Item>
                  <Button
                    htmlType="button"
                    onClick={onReset}>
                    {t('clear')}
                  </Button>
                  <div className="w-3 inline-block"/>
                  <Button
                    type="primary"
                    htmlType="submit">
                    {t('search')}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </section>
        <ToggleView
            height={26}
          renderHeadlineView={props => <ProductSettingHeadlineView {...props} />}
          renderListView={props => <ProductSettingListView {...props} />}>
          {({switchComponent, renderItem}) => (
              <section id={"workspace_product_list"} className="space-y-3 right col-span-3">
                <div className="flex py-1 font-medium text-xl border-b-2 border-green-500">
                  <div className="flex-1">
                    {
                      t('products')
                    }
                  </div>
                  <div className="flex space-x-2 px-2 text-2xl items-center">
                    {
                      !!_.get(organization, 'edit') && (
                        <Button
                          type="primary"
                          onClick={() => {
                            setCurrent("draft products");
                            handleActionClick(
                              'create-product'
                            )
                          }
                          }
                          icon={<PlusOutlined/>}>
                          {t('create product')}
                        </Button>
                      )
                    }
                    {
                      switchComponent && switchComponent
                    }
                  </div>
                </div>

                <Tabs
                  activeKey={current}
                  onChange={onTabChange}
                  animated={false}>
                  <Tabs.TabPane
                    tab={t('listed products')}
                    key="listed-products">
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    tab={t('unlisted products')}
                    key="unlisted-products">
                  </Tabs.TabPane>
                </Tabs>

                <ProductSettingList
                  renderItem={renderItem}
                  organizationId={organizationId}
                  filters={
                    {
                      ...values,
                      title: values.keyword,
                      active: renderIfElse(current === "listed-products", "1", "0")
                    }
                  }
                />

              </section>
          )}
        </ToggleView>
      </div>
    </div>
  )
}

export default () => {
  const {
    item
  } = useContext(ServerContext)
  const {pageRoot} = useContext(PageContext);

  return (
    <React.Fragment>
      <AsyncWithCache
        cacheId="/products/get-price-periods?day=1"
        apiInfo={{
          root: API_ROOT_URL,
          path: `/products/get-price-periods?day=1`,
          method: 'GET'
        }}>
      </AsyncWithCache>
      <OrgManageProducts
        organizationId={
          item.idname
        }/>
    </React.Fragment>
  )
}

