import {Avatar} from 'antd'
import {member_getMembers_Api} from 'apis'
import {getId, getType} from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import CustomLazyPaginationWrapper from 'modules/asyncCache/components/CustomLazyPaginationWrapper'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useState} from 'react'
import {BsPersonFill} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import {ConversationWith} from 'views/AppLocals/factory/createChatButton'
import createStickySider from 'views/AppLocals/factory/createStickySider'
import {bindQueryParams} from 'views/AppLocals/functions/routerHelper'
import {EventHosts} from 'views/AppLocals/Host/components/HostFactory'
import {renderShowMore} from 'views/MainPage/functions/renderLimitLoadMore'
import renderPagingLoading from 'views/MainPage/functions/renderPagingLoading'
import renderPagingWrapper from 'views/MainPage/functions/renderPagingWrapper'
import {renderSelf, withPagerWrapper} from 'views/Shared'
import EventContext from '../EventContext'
import {EventShareWidget} from '../../factory/createShareWidget'
import {renderIf} from '../../../Shared'
import {FaUserTie} from "react-icons/fa";
import {RiMessage3Line} from "react-icons/ri";
import RequiredAdmin from "../../../NewTemplate/components/RequiredAdmin";
import {FaWarehouse} from "react-icons/fa6";
import {OrgType} from "../../Organization/components/OrgType";

const EventMembers = ({renderItem, alwaysVisible = true}) => {
  const [limit, setLimit] = useState(5)

  const {event} = useContext(EventContext)

  const [id, prop] = [getId(event), getType(event)]

  const apiInfo = member_getMembers_Api

  const lookupKey = apiInfo.path.replace(':id', id).replace(':prop', prop)

  const renderWrap = (alwaysVisible ? renderSelf : withPagerWrapper)(
    renderPagingWrapper()
  )

  return (
    <CustomLazyPaginationWrapper
      auto={false}
      sample={limit}
      id={lookupKey}
      key={lookupKey}
      apiInfo={apiInfo}
      renderWrap={renderWrap}
      renderItem={renderItem}
      alwaysVisible={alwaysVisible}
      renderLoading={renderPagingLoading}
      renderEmpty={() => <EmptyHolder/>}
      query={bindQueryParams([{id}, {prop}])}
      renderLoadMoreButton={renderShowMore([limit, setLimit])}
    />
  )
}

const EventOwner = () => {
  const t = useTranslate()

  const {event} = useContext(EventContext)

  const {owner} = event ?? {}

  return (
    <div className="flex flex-col gap-1">
      <span className="uppercase text-sm font-medium text-color-400 border-b mb-2">
        {t('organized by')}
      </span>
      <div className="flex items-center gap-2">
        <Avatar
          src={getAvatar(owner)}
          icon={<FaWarehouse className="text-color-500"/>}
          className="flex flex-center background-200 border border-color-50 rounded-md"
        />
        <div className="flex flex-col flex-1">
            <Link
                className="text-primary font-bold text-sm max-lines-1 no-underline leading-tight"
                to={getLinkToDetail(owner)}>
                {getTitle(owner)}
            </Link>
            <OrgType item={owner} className="text-xs text-color-500 italic leading-tight"/>
        </div>
      </div>
    </div>
  )
}

const BannerMetaData = ({total}) => {
  const t = useTranslate()
  return (
    <div className="w-full flex justify-around">
      {[
        {
          label: 'followers',
          value: 'followers',
        },
        {
          label: 'member',
          value: 'members',
        },
        {
          label: 'votes',
          value: 'votes',
        },
      ].map(({label, value, icon}, index) => (
        <div
          key={index}
          className="flex flex-col items-center background-200 rounded-lg p-2">
          <span className="text-primary font-bold leading-tight">
            {_.defaultTo(_.get(total, value), 0)}
          </span>
          <span className="text-xs text-color-400 italic">{t(label)}</span>
        </div>
      ))}
    </div>
  )
}

const renderMember = (member) => {
  const {user: item} = member ?? {}

  return (
    <Translate key={getId(item)}>
      {(t) => (
        <div className="flex items-center gap-2">
          <Avatar
            size={28}
            src={getAvatar(item)}
            icon={<BsPersonFill size={14} className="text-color-400"/>}
            className="flex flex-center background-200"
          />
          <div className="flex flex-col flex-1 truncate">
            <Link
              to={getLinkToDetail(item)}
              className="font-bold text-sm text-color-100 leading-tight no-underline truncate">
              {getTitle(item)}
            </Link>
            {renderIf(
              item?.email,
              <span className="text-xs text-color-500 italic leading-tight">
                    {item.email}
                </span>
            )}
          </div>
          <RequiredAdmin>
            <ConversationWith
              params={{
                receiver_id: getId(item),
              }}
              render={(__, {onClick}) => (
                <div
                  onClick={onClick}
                  className="flex items-center gap-1 text-2xs text-color-300 hover:text-primary italic leading-tight cursor-pointer">
                  {t('send message')}
                  <RiMessage3Line size={10}/>
                </div>
              )}
            />
          </RequiredAdmin>
        </div>
      )}
    </Translate>
  )
}

const EventMember = () => {
  const t = useTranslate()
  const {event} = useContext(EventContext)

  return (
    <div className="flex flex-col gap-2">
      <span className="flex items-center border-b">
        <span className="flex-1 uppercase text-sm font-medium text-color-400">
          {t('list of members')}
        </span>
        <span className="font-medium text-2xs text-primary italic lowercase">
          {_.get(event, 'total.members')} {t('member')}
        </span>
      </span>
      <div className="flex flex-col gap-3">
        <EventMembers renderItem={renderMember}/>
      </div>
    </div>
  )
}

const EventSpeaker = () => {
  const t = useTranslate()

  const {event} = useContext(EventContext)

  const {speakers = []} = event ?? {}

  return renderIf(
    speakers.length > 0,
    <div className="flex flex-col gap-1">
      <span className="uppercase text-sm font-medium text-color-400 border-b mb-2">
        {t('speakers')}
      </span>
      {speakers.map((e, i) => (
        <div
          key={i}
          className="flex items-center gap-2 mb-2">
          <Avatar
            size={28}
            src={_.get(e, 'speaker.avatar')}
            icon={<FaUserTie size={12} className="text-color-400"/>}
            className="flex flex-center background-200"
          />
          <Link
            className="font-bold text-color-100 text-sm max-lines-1 no-underline leading-tight"
            to={getLinkToDetail(e.speaker)}>
            {_.get(e, 'speaker_name')}
          </Link>
        </div>
      ))}
    </div>
  )
}

const RightSider = () => (
  <React.Fragment>
    <EventOwner/>
    <EventHosts/>
    <EventSpeaker/>
    <EventMember/>
    <div className="h-6"/>
    <EventShareWidget
      className="gap-2"
      header={
        <Translate>
          {(t) => (
            <span className="text-sm text-color-400 italic">
                {`${t('share')} : `}
            </span>
          )}
        </Translate>
      }
    />
    <div className="h-24"/>
  </React.Fragment>
)

const EventSider = ({Wrapper = 'div', stickySider = true}) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(RightSider)

export default EventSider
