import { gPaths } from 'views/AppLocals/configs'
import createSettingContent from 'views/AppLocals/factory/createSettingContent'
import UserContext from '../UserContext'

const UserSettingsContent = ({ Wrapper, routes }) => {
  const rootPath = gPaths.userSettings
  return createSettingContent(UserContext, { routes, Wrapper, rootPath })
}

export default UserSettingsContent
