import { API_ROOT_URL } from 'envs/_current/config'

// Route::get('apps/get-meta-statistics', 'AppController@getMetaTotalSystems');
export const apps_getMetaTotalSystems_Api = {
  name: 'apps_getMetaTotalSystems',
  backend: ``,
  root: API_ROOT_URL,
  path: '/apps/get-meta-statistics',
  method: 'GET'
}
