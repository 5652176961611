import {createListTransform} from 'apis/utils/createListTransform'
import {createTransform} from 'apis/utils/createTransform'
import {API_ROOT_URL} from 'envs/_current/config'
import {hostSchema} from './schema'

export const host_getsbyOwner_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/hosts/:prop/:id/gets-by-object',
  method: 'GET',
  paramsFields: ['status', 'request_status', 'sort_by', 'keyword', 'host_type'],
  schema: hostSchema,
  transform: createListTransform(hostSchema),
}

export const host_approve_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/hosts/host/:host_id/approve',
  method: 'POST',
  schema: hostSchema,
  transform: createTransform(hostSchema, 'data'),
}

export const host_reject_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/hosts/host/:host_id/reject',
  method: 'POST',
  schema: hostSchema,
  transform: createTransform(hostSchema, 'data'),
}

export const host_add_api = {
  backend: `host_add_api`,
  root: API_ROOT_URL,
  path: '/hosts/:prop/:id/add',
  method: 'POST',
  schema: hostSchema,
  transform: createTransform(hostSchema, 'data'),
}

export const host_delete_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/hosts/:prop/:id/:host_id/delete',
  method: 'POST',
  schema: hostSchema,
  transform: createTransform(hostSchema, 'data'),
}

export const host_leaveHost_api = {
  backend: `Route::post('hosts/host/{host_id}/leave', 'HostController@leaveHost');`,
  root: API_ROOT_URL,
  path: '/hosts/host/:host_id/leave',
  method: 'POST',
  schema: hostSchema,
  transform: createTransform(hostSchema, 'data'),
}

export const host_resendAddHost_api = {
  backend: `Route::post('hosts/{prop}/{id}/{host_id}/re-request', 'HostController@resendAddHost');`,
  root: API_ROOT_URL,
  path: '/hosts/:prop/:id/:host_id/re-request',
  method: 'POST',
  schema: hostSchema,
  transform: createTransform(hostSchema, 'data'),
}

export const host_getsByHost_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/hosts/:prop/:id/gets-by-host',
  method: 'GET',
  paramsFields: [
    'status',
    'request_status',
    'owner_type',
    'sort_by',
    'keyword',
  ],
  schema: hostSchema,
  transform: createListTransform(hostSchema),
}

export const host_getPeerMembers_Api = {
  backend: `Route::get('hosts/{prop}/{id}/gets-peer-members', 'HostController@getPeerMembers');`,
  root: API_ROOT_URL,
  path: '/hosts/:prop/:id/gets-peer-members',
  method: 'GET',
  paramsFields: ['status', 'sort_by', 'keyword', 'owner_type', 'subcategories'],
  schema: hostSchema,
  transform: createListTransform(hostSchema),
}

export const host_searchHostsByOwner_api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/search/hosts-by-owner/:prop/:id',
  method: 'GET',
  paramsFields: ['keyword'],
  schema: hostSchema,
  transform: createListTransform(hostSchema),
}

export const host_getHostingStats_Api = {
  backend: `Route::get('organization/{organization_id}/hosts/get-host-stats', 'OrganizationInsightsController@getHostingStats');`,
  root: API_ROOT_URL,
  path: '/organization/:organization_id/hosts/get-host-stats',
  method: 'GET',
  paramsFields: ['period'],
}
