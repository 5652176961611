import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import {useAppConfig} from 'modules/local'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {Visible} from 'views/Shared'
import {discoveryExplorerRouteEntities} from '../discoveryExplorerRouteEntities'
import _ from "lodash";
import categoryIcons from "../../../conts/categoryIcons";
import {BsCaretDownFill} from "react-icons/bs";
import {renderIf} from "../../Shared";

const GroupWrapper = ({
  title,
  children,
  ...props
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="font-bold text-color-300 text-lg border-b-2 uppercase tracking-wide">
        {title}
      </div>
      <div className="mt-3 mb-6">
        {children}
      </div>
    </div>
  )
}

const EntitiesGroup = React.memo(
  ({ Wrapper = GroupWrapper }) => (
    <Wrapper
      title={
        <Translate>
          {t => t('features group')}
        </Translate>
      }>
      <div className="grid grid-cols-4 md:grid-cols-6 gap-3">
        {discoveryExplorerRouteEntities
          .filter(Visible)
          .map(
            (
              {
                component: Component,
                ...route
              },
              index
            ) => (
              <Component
                key={index}
                {...route}
              />
            )
          )}
      </div>
    </Wrapper>
  )
)
const CategoriesGroup = React.memo(
  ({ Wrapper = GroupWrapper }) => {
    const t = useTranslate()
    const {
      categories
    } = useAppConfig()

    const { isSm } = useContext(
      LayoutContext
    )

    return (
      <Wrapper title={t('categories')}>
        <div className="flex flex-col gap-3">
          {Array.from(categories).map(
            (item, index) => {

                const childrens = _.get(item, 'children', [])

                return (
                    <div key={index} className="flex flex-col gap-2">
                        <div
                            style={{ padding: '4px 10px' }}
                            className="flex items-center gap-2 bg-primary-50 rounded-md">
                            <span className="flex-1 font-bold text-lg text-primary uppercase leading-snug tracking-widest">
                                {getTitle(item)}
                            </span>
                        </div>
                        {renderIf(
                            childrens.length > 0,
                            <div className="grid grid-cols-6 gap-3">
                                {childrens.map((e, i) => {

                                    const Icon = _.get(categoryIcons, e.idname, categoryIcons["default"])
                                    return (
                                        <Link
                                            key={i}
                                            to={getLinkToDetail(e)}
                                            className="no-underline text-color-000 hover:text-primary">
                                            <div
                                                style={{ height: '8rem' }}
                                                className="flex flex-col items-center justify-center border border-color-50 gap-2 rounded-lg hover:shadow-out cursor-pointer background-100">
                                                <div style={{ width: isSm ? '2rem' : '3rem' }}>
                                                    <Icon />
                                                </div>
                                                <span className="font-medium text-center text-sm px-2 leading-tight">
                                              {getTitle(e)}
                                            </span>
                                            </div>
                                        </Link>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                )
            }
          )}
        </div>
      </Wrapper>
    )
  }
)

const DiscoveryExplorerNavigator = ({
  Wrapper = 'div'
}) => (
  <Wrapper className="flex flex-col space-y-3">
    <EntitiesGroup />
    <CategoriesGroup />
  </Wrapper>
)

export default DiscoveryExplorerNavigator
