import React from "react";

export const InfoSvg = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      x="0"
      y="0"
      enableBackground="new 0 0 58 58"
      viewBox="0 0 58 58"
      {...props}
    >
      <g>
        <path
          fill="#00a551"
          d="M26.64.094C12.722 1.194 1.399 12.368.125 26.271c-.537 5.864.678 11.405 3.176 16.168a6.928 6.928 0 01.35 5.603L0 58l10.55-3.517c1.747-.582 3.664-.472 5.304.365 4.77 2.434 10.299 3.589 16.144 3.001 13.793-1.389 24.814-12.666 25.908-26.485C59.322 13.488 44.515-1.32 26.64.094z"
        ></path>
        <circle cx="29" cy="29" r="24" fill="#FFF"></circle>
        <circle cx="29" cy="15" r="3" fill="#00a551"></circle>
        <path
          fill="#00a551"
          d="M29 46a3 3 0 01-3-3V26a3 3 0 116 0v17a3 3 0 01-3 3z"
        ></path>
      </g>
    </svg>
  );
}

