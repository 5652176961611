import _ from 'lodash'
import {Editor, Element as SlateElement, Node, Path, Transforms} from 'slate'
import {ReactEditor} from 'slate-react'
import {renderFalse} from 'views/Shared'
import {TextAlignTypes} from '../types'
import {createParagraphNode} from './paragraph'

export function isAstChange(editor) {
  return editor.operations.some((item) => item?.type !== 'set_selection')
}

export function isEmptyEditor(editor) {
  if (!editor) return true
  const children = Array.from(editor.children ?? [])
  return (
    children.length === 0 ||
    (children.length === 1 && Node.string(children[0]) === '')
  )
}

export function isEmptyContent(string) {
  const editorState = convertFromString(string)
  const children = Array.from(editorState ?? [])
  return (
    children.length === 0 ||
    (children.length === 1 && Node.string(children[0]) === '')
  )
}

export function ensureNotEmpty(editor) {
  if (!editor) return
  if (isEmptyEditor(editor)) {
    Transforms.insertNodes(editor, createParagraphNode())
  }
}

export function getNode(editor, type, options) {
  const [node] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && type === n.type,
    ...(options ?? {}),
  })
  return node
}

export function withAlign(format) {
  return Object.values(TextAlignTypes).includes(format) ? 'align' : null
}

export function updateNode(props) {
  return (editor, element) => {
    const path = ReactEditor.findPath(editor, element)
    Transforms.setNodes(editor, props, {
      at: path,
    })
  }
}

export function setClipboard(value) {
  localStorage.setItem('clipboard', value)
}

export function getClipboard(editor) {
  return String(localStorage.getItem('clipboard') ?? '')
}

export function getPrevNode(editor) {
  const parentPath = Path.parent(editor.selection.anchor.path)
  if (!Path.hasPrevious(parentPath)) {
    return null
  }
  const prevPath = Path.previous(parentPath)
  return [Node.get(editor, prevPath), prevPath]
}

export function getNextNode(editor) {
  const parentPath = Path.parent(editor.selection.anchor.path)
  try {
    const nextPath = Path.next(parentPath)
    return [Node.get(editor, nextPath), nextPath]
  } catch (error) {
    return null
  }
}

export function convertFromString(
  content,
  defaultValue = [createParagraphNode()]
) {
  try {
    const value = JSON.parse(content)
    return _.isEmpty(value ?? []) ? defaultValue : value
  } catch (error) {
    return defaultValue ?? null
  }
}

export function convertToString(value, defaultValue = [createParagraphNode()]) {
  return JSON.stringify(_.isEmpty(value ?? []) ? defaultValue : value)
}

export function findDeepNodes(array, match = renderFalse) {
  return Array.from(array ?? []).reduce((prev, node) => {
    if (match(node)) {
      return [...prev, node]
    }
    return [...prev, ...findDeepNodes(node?.children, match)]
  }, [])
}

export function findNodes(array, type) {
  return findDeepNodes(array, (node) => node?.type === type)
}

export function toBase64(text) {
  return Buffer.from(text).toString('base64')
}

export function fromBase64(text, encoding = 'utf8') {
  return Buffer.from(text, 'base64').toString(encoding)
}
