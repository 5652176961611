import CaretRightFilled from '@ant-design/icons/CaretRightFilled'
import CheckOutlined from '@ant-design/icons/CheckOutlined'
import {Avatar, Button, Input, List, message, Modal, Space, Switch, Tag, Tooltip} from 'antd'
import {auth_updateFollowedCategories_Api} from 'apis'
import {FlagIcon} from 'components/icons/FlagIcon'
import Null from 'components/NullComponent'
import mapObject from 'helpers/mapObject'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import {LocalContext} from 'modules/local/LocalContext'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {getAllPackages, getCurrentPackageIndex, getLogin} from 'redux/selectors'
import News from 'views/MainPage'
import {DelayRender} from '../Discovery/DelayRender'
import {useAppConfig} from "../../modules/local";
import {useRequiredLogin} from "../Server/useRequiredLogin";
import {emptyArray} from "../../helpers/emptyObjects";
import {BsCaretRightFill, BsCheckLg, BsCircleFill, BsFolder2Open, BsX, BsXLg} from "react-icons/bs";
import {HiOutlineViewGridAdd} from "react-icons/hi";

const normalizeString = str => {
  const newStr = _.lowerCase(str)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
  return newStr
}
const memoNormalizeString = _.memoize(
  normalizeString
)

function filter(parent, child) {
  return memoNormalizeString(
    parent
  ).includes(memoNormalizeString(child))
}

export const SelectCategory = ({
                                 onSuccess = Null,
                                 minItemNumber = 0,
                                 maxItemNumber = 2
                               }) => {
  const t = useTranslate()
  const history = useHistory()
  const login = useSelector(getLogin)
  const [
    selectedItems,
    setSelectedItems
  ] = useState(
    (
      _.get(login, 'categories', [])
    ).reduce((result, value) => {
      result[value.id] = true
      return result
    }, {})
  )
  const handleRequiredLogin = useRequiredLogin();

  const {hierarchy_categories = [], categories = []} = useAppConfig();

  const {translate} = useContext(
    LocalContext
  )

  const restCount = Math.max(
    minItemNumber -
    mapObject(selectedItems).filter(
      item => item
    ).length,
    0
  )

  const [
    selectedCateNumber,
    setSelectedCateNumber
  ] = useState(
    mapObject(selectedItems).filter(
      item => item
    ).length
  )

  const [
    toggleFinish,
    setToggleFinish
  ] = useState(false)

  const currentSelectedCateNumber = mapObject(
    selectedItems
  ).filter(item => item).length
  useEffect(() => {
    if (
      currentSelectedCateNumber >=
      _.get(
        login,
        'following_category_maximum',
        maxItemNumber
      ) &&
      selectedCateNumber !==
      currentSelectedCateNumber
    ) {
      setSelectedCateNumber(
        currentSelectedCateNumber,
        setToggleFinish(true)
      )
    }
    setSelectedCateNumber(
      currentSelectedCateNumber
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedCateNumber])

  const isValid =
    restCount === 0 &&
    mapObject(
      selectedItems,
      (value, key) =>
        value ? key : undefined
    ).filter(item => item).length <=
    _.get(
      login,
      'following_category_maximum',
      maxItemNumber
    )
  const handleSelectItem = id =>
    setSelectedItems({
      ...selectedItems,
      [id]: !selectedItems[id]
    })

  const updateCategoriesRequiredFields = useMemo(
    () => ({
      cate_ids: mapObject(
        selectedItems,
        (value, key) =>
          value ? key : undefined
      ).filter(item => item)
    }),
    [selectedItems]
  )

  const {
    success,
    isLoading,
    handleAsyncAction
  } = useAsyncAction({
    apiInfo: auth_updateFollowedCategories_Api,
    values: updateCategoriesRequiredFields,
    onSuccess: () => {
      onSuccess()
      // history.replace('/')
    },
    onError: (...args) =>
      message.error(
        _.get(args, '1.error')
      )
  })
  const momentObj = moment(
    _.get(login, 'following_category_available_change_at')
  )
  const isAfter = momentObj.isAfter(
    moment()
  )
  const [
    keyword,
    setKeyword
  ] = useState('')
  const [
    following,
    setFollowing
  ] = useState(false)

  const allPackages =
    getAllPackages(login) || emptyArray
  const current =
    getCurrentPackageIndex(login) || 0
  const currentPackage =
    _.get(allPackages, current) ||
    {}

  const following_category_maximum = _.get(
    currentPackage,
    'following_category_maximum'
  )
  const following_category_locked_in = _.get(
    currentPackage,
    'following_category_locked_in'
  )
  const following_category_allow_in = _.get(
    currentPackage,
    'following_category_allow_in'
  )

  return (
    <div className="w-full space-y-3 p-3 background relative">
      <div className="text-sm text-color-300 italic">
        {t('choose categories description')}
      </div>
      <h2 className="font-bold text-2xl text-color-000">
        {translate('which categories are your interests')}
      </h2>
      <div className="w-full flex flex-col">
        <Input
            className="rounded-md"
            placeholder={translate('filter')}
            onChange={e => setKeyword(e.target.value)}
        />
        <div className="flex items-center gap-4 py-3">
          <div className="flex flex-wrap items-center gap-2">
            <DelayRender>
              {() => _.get(login, 'categories', []).map((item, i) => {
                if (!selectedItems[item.id]) return null
                return (
                    <div
                        style={{ padding: '5px 8px' }}
                        className="w-fit flex items-center gap-2 border hover:shadow-items-md rounded-md">
                      <Avatar
                          size={20}
                          className="flex flex-center background-200"
                          src={item.avatar}
                          icon={ <BsFolder2Open className="text-color-400" /> }
                      />
                      <Link
                          to={News.paths.categoryPath.replace(':category', item.id).replace(':refcategory', item.alt_idname) + "?lang=" + item.language}
                          className="font-bold text-sm text-color-100 leading-tight no-underline">
                        {item.name}
                      </Link>
                      <BsXLg
                          size={10}
                          style={{ marginLeft: 10 }}
                          onClick={() => handleSelectItem(item.id)}
                          className="text-color-400 hover:text-red-600 cursor-pointer"
                      />
                    </div>
                )
              })}
            </DelayRender>
          </div>
          {!_.isEmpty(selectedItems) && (
              <Tooltip title={t('unfollow all categories')} overlayClassName="font-medium text-xs italic">
                <span
                    className="whitespace-no-wrap font-bold text-xs text-red-500 hover:text-primary-700 italic cursor-pointer"
                    onClick={() => setSelectedItems({})}>
                  {t('unfollow')}
                </span>
              </Tooltip>
          )}
        </div>
      </div>
      <div className="space-x-2 flex items-center justify-end px-3">
        <span className="font-medium text-xs text-color-400 italic">
          {`${translate('following')} : `}
        </span>
        <Switch
          size="default"
          defaultChecked={following}
          onChange={setFollowing}
        />
      </div>

      <div className="flex flex-col gap-3 pt-6">
        <div className="font-bold text-color-200 uppercase tracking-wide">
          {t('list of categories')}
        </div>
        <div className="flex flex-col gap-6">
          <DelayRender>
            {() => hierarchy_categories.map((item, i) => {
              const _categories = _.get(item, 'children', []).reduce(function (a, b) {return a.concat(b.children);}, [])
              if (_categories.filter(item => filter(item.name, keyword) && (!following || selectedItems[item.id])).length <= 0) return null

              return (
                  <div key={i} className="flex flex-col ml-2">
                    <div className="flex flex-col border-b border-primary-700 pb-1">
                      <span className="text-xs text-color-400 hover:text-color-100 italic cursor-pointer leading-snug">
                        {_.get(item, 'root.name')}
                      </span>
                      <span className="flex items-center text-xl font-bold text-primary-700 hover:text-primary cursor-pointer leading-snug">
                        <BsCaretRightFill
                            size={14}
                            className="mr-2"
                            style={{ marginLeft: -4 }}
                        />
                        {_.get(item, 'name')}
                      </span>
                    </div>
                    <div className="flex flex-col gap-3 ml-3">
                      <List
                          dataSource={
                            _categories.filter(item => filter(item.name, keyword) && (!following || selectedItems[item.id])).map((citem, ci) => {
                              return (
                                  <div key={ci} className="w-full">
                                    <div className="flex items-center gap-2 mb-2">
                                      <div className="flex-1">
                                        <span className="flex items-center gap-1 text-color-300 leading-snug">
                                          <BsCircleFill size={6} />
                                          <div className="space-x-1">
                                            {_.get(citem, 'segments', []).map((se) => (
                                                <span key={se.id} className="font-medium text-xs italic">
                                                  {`${se.name},`}
                                                </span>
                                            ))}
                                          </div>
                                        </span>
                                        <Link
                                            className="flex items-center gap-2 no-underline ml-3"
                                            to={ News.paths.categoryPath
                                                .replace(':category', citem.id)
                                                .replace(':refcategory', citem.alt_idname) + "?lang=" + citem.language }>
                                          <Avatar
                                              size={20}
                                              icon={ <HiOutlineViewGridAdd /> }
                                              src={citem.avatar}
                                              className="flex flex-center background-200"
                                          />
                                          <span className="font-bold text-color-000">
                                            {citem.name}
                                          </span>
                                        </Link>
                                      </div>
                                      <Button
                                          size="small"
                                          style={{
                                            height: 22,
                                            padding: '2px 8px',
                                          }}
                                          className="button-rounded-md no-border flex flex-center"
                                          onClick={() => handleRequiredLogin(() => handleSelectItem(citem.id)) }
                                          type={ selectedItems[citem.id] ? 'default' : 'primary' }>
                                        <span className="font-semibold text-xs">
                                          {selectedItems[citem.id] ? (
                                              <span className="flex items-center gap-1 text-color-400">
                                                <BsCheckLg size={10}/>
                                                <span>{translate('selected')}</span>
                                              </span>
                                          ) : (translate('follow'))}
                                        </span>
                                      </Button>
                                    </div>
                                    <div className="flex gap-6 ml-3">
                                      {citem && _.has(citem, 'extra_info') && _.get(citem, 'extra_info', []).map( einfo => (
                                          <div key={einfo.language} className="flex items-center gap-1">
                                            <FlagIcon size={16} type={einfo.language}/>
                                            <span className="text-xs text-color-400 italic leading-snug">
                                              <span className="font-bold text-color-000 mr-1">
                                                {_.get(einfo, 'total_channels')}
                                              </span>
                                              {translate('channels')}
                                            </span>
                                          </div>
                                      ))}
                                    </div>
                                  </div>
                              )
                            })
                          }
                          renderItem={ item => (
                              <List.Item key={item.id}>
                                {item}
                              </List.Item>
                          )}
                      />
                    </div>
                  </div>
              )
            })}
          </DelayRender>
        </div>
      </div>

      <div className="w-full flex items-center background sticky bottom-0 z-3 border-t border-color-50 p-4 font-semibold text-primary-700 italic">
        <div className="flex-1">
          {(isAfter && momentObj.isValid() && _.template(
              translate('you can not change your following categories before {time}',
                { time: momentObj.calendar() }
              )
            )({ count: restCount })) || (!isValid && _.template(
              translate('you can not follow more {n} categories',
                { n: _.get(login, 'following_category_maximum', maxItemNumber) }
              ))({ count: restCount })) || translate('After select, click Save to finish')}
        </div>
        <Button
            type="primary"
            className="button-rounded-md no-border"
            onClick={() => {
              if (_.isEmpty(selectedItems)) {
                Modal.info({
                  title: (
                      <div className="font-bold text-color-000">
                        {translate('No categories have been selected')}
                      </div>
                  ),
                  content: (
                      <div className="text-sm text-color-400 italic">
                        {translate('Please select at least 1 topic to complete. FeedIn will help you find relevant information')}
                      </div>
                  ),
                  okText: t('understood'),
                  okButtonProps: {
                    className: 'button-rounded-md no-border'
                  },
                  onOk() {}
                })
              } else {
                handleAsyncAction(updateCategoriesRequiredFields)
              }
            }}
            loading={isLoading}
            disabled={ !isValid || isAfter }>
          {translate('save')}
        </Button>
      </div>
    </div>
  )
}

export default SelectCategory
