import { Avatar, DatePicker, Input, Radio } from 'antd'
import { getId, getType } from 'apis/model/base'
import FieldsFactory from 'components/form/FieldsFactory'
import { FormActionGroups } from 'components/form/FormActionBar'
import { createValue } from 'components/form/utils'
import { LoginContext } from 'components/LoginContext'
import { Formik } from 'formik'
import getTitle from 'helpers/getTitle'
import { getDateString } from 'helpers/momentDatetime'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, { useContext, useMemo } from 'react'
import { IoCalendarOutline } from 'react-icons/io5'
import { RiUser3Fill } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { compose, mapProps } from 'recompose'
import { GenderTypes } from 'views/AppLocals/appFieldTypes'
import { gConfigs } from 'views/AppLocals/configs'
import { appClassNames } from 'views/AppLocals/custom/appClassNames'
import { createControlledFormField } from 'views/AppLocals/factory/createFormField'
import { confirmOnSuccess } from 'views/AppLocals/factory/createRedirectConfirm'
import { getResponseItem, Null } from 'views/Shared'
import { renderIf } from '../../../Shared'
import { useEventParticipant } from '../useEventParticipant'
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {LayoutContext} from "../../../../components/layouts/Default/LayoutContext";
import {BsCheckCircle} from "react-icons/bs";

const ItemInformation = ({ label, value }) =>
  renderIf(
    value,
    <Translate>
      {(t) => (
        <div className="flex items-center gap-1 text-xs italic">
          <span className="text-color-500">{`${t(label)} : `}</span>
          <span className="font-bold text-color-100">{value}</span>
        </div>
      )}
    </Translate>
  )

const Header = ({ item }) => {
  const t = useTranslate()
  const {
    title,
    time_open,
    time_close,
    event_type,
    location_name,
    external_link,
  } = item ?? {}

    return (
        <div className="flex flex-col">
            <span className="font-semibold text-sm text-color-300 tracking-wide uppercase">
                {t('you are registering to attend the event')}
            </span>
            <div className="flex flex-col gap-1 ml-3">
                <div className="font-bold text-xl text-green-700 italic">
                    {title}
                </div>
                <div className="flex flex-col md:flex-row gap-x-2 text-xs tracking-wide">
                    <span className="leading-tight font-medium text-color-100">
                        <span className="font-normal text-color-500 italic mr-1">
                          {t('time')}{' : '}
                        </span>
                        <span className="font-normal text-color-400 capitalize italic mr-2">
                          {t('from')}
                        </span>
                        {moment(time_open).format('hh:mm a - Do MMMM, YYYY')}
                    </span>
                    {renderIf(
                        time_close,
                        <span className="leading-tight font-medium text-color-100">
                            <span className="font-normal text-color-400 lowercase italic mr-2">
                                {t('to')}
                            </span>
                            {moment(time_close).format('hh:mm a - Do MMMM, YYYY')}
                        </span>
                    )}
                </div>
                <ItemInformation label="type of event" value={t(event_type)} />
                <ItemInformation label="address" value={location_name}
          />      <ItemInformation label="link" value={external_link} />
            </div>
        </div>
    )
}

const UserInfo = ({ item }) => {
  const t = useTranslate()
  const { avatar, name, email } = item ?? {}

  return (
    <div className="ml-3 flex flex-col gap-1">
      <span className="text-sm text-color-400 italic">
        {`${t('you are registering to attend the event with an account')} : `}
      </span>
      <div className="flex items-center gap-2 p-2 border border-color-50 hover:shadow-items-md rounded-md">
        <Avatar
          size={35}
          src={avatar}
          icon={<RiUser3Fill className="text-color-400" />}
          className="flex flex-center background-200"
        />
        <div className="flex flex-col italic">
          <Link
            to={getLinkToDetail(item)}
            className="font-bold text-primary">
            {name}
          </Link>
          <span className="text-xs text-green-300 leading-tight">{email}</span>
        </div>
      </div>
    </div>
  )
}

const formSchema = {
  user: {
    title: 'account',
    children: [
      {
        name: 'user',
        component: mapProps(({ value }) => {
          return { item: value }
        })(UserInfo),
      },
    ],
  },
  name: {
    title: 'full name',
    children: [
      {
        name: 'name',
        component: createControlledFormField({
          placeholder: 'name',
        }),
      },
    ],
  },
  info_group_1: {
    inline: true,
    className: appClassNames.inline_2,
    children: [
      {
        label: 'date of birth',
        name: 'date_of_birth',
        component: compose(
          withTranslate,
          mapProps(({ name, value, onChange, translate }) => ({
            name,
            value,
            className: 'w-full',
            format: gConfigs.dateFormat,
            suffixIcon: <IoCalendarOutline className="text-color-400" />,
            placeholder: `${translate('date of birth')} "DD/MM/YYYY"`,
            onChange: (date, dateString) => {
              onChange(createValue(name, date ? moment(new Date(date)) : null))
            },
          }))
        )(DatePicker),
      },
      {
        label: 'gender',
        name: 'gender',
        component: compose(
          withTranslate,
          mapProps(({ name, value, onChange, translate }) => ({
            name,
            value,
            onChange,
            buttonStyle: 'solid',
            className: 'custom-radio-rounded-md',
            children: Object.values(GenderTypes).map(
              ({ name, value, label }) => (
                <Radio.Button
                  key={name}
                  value={value}>
                  {translate(label)}
                </Radio.Button>
              )
            ),
          }))
        )(Radio.Group),
      },
    ],
  },
  info_group_2: {
    inline: true,
    className: appClassNames.inline_2,
    children: [
      {
        label: 'phone',
        name: 'contact_phone',
        component: ({ name, value, onChange }) => (
          <Translate>
            {(t) => (
              <Input
                {...{
                  name,
                  value,
                  onChange,
                }}
                type="tel"
                inputMode="tel"
                placeholder={t('ex: phone')}
              />
            )}
          </Translate>
        ),
      },
      {
        label: 'email',
        name: 'contact_email',
        component: ({ name, value, onChange }) => (
          <Translate>
            {(t) => (
              <Input
                {...{
                  name,
                  value,
                  onChange,
                }}
                type="email"
                inputMode="email"
                placeholder={t('ex: email')}
              />
            )}
          </Translate>
        ),
      },
    ],
  },
}

const AuthenticatedForm = ({
  isSubmitting,
  initialValues,
  onSubmit = Null,
}) => {
  const t = useTranslate()

  const validate = (values) => {
    let errors = {}

    const { user } = values ?? {}

    if (!user) {
      errors.user = t('required field')
    }

    return errors
  }

  return (
    <Formik
      onSubmit={(values) => {
        const { user, ...rest } = values ?? {}
        onSubmit(
          _.omitBy(
            {
              ...rest,
              user_id: getId(user),
            },
            _.isUndefined
          )
        )
      }}
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      initialValues={initialValues}>
      <div className="flex flex-col space-y-3">
        <FieldsFactory formSchema={[formSchema.user]} />
        <FormActionGroups
          isSubmitting={isSubmitting}
          resetProps={{ hidden: true }}
          submitProps={{ label: 'register', dirtyRequired: false }}
        />
      </div>
    </Formik>
  )
}

const UnauthenticatedForm = ({
  isSubmitting,
  initialValues,
  onSubmit = Null,
}) => {
  const t = useTranslate()

  const _initialValues = useMemo(
    () => ({
      gender: undefined,
      name: undefined,
      contact_phone: undefined,
      contact_email: undefined,
      date_of_birth: undefined,
      ...(initialValues ?? {}),
    }),
    [initialValues]
  )

  const validate = (values) => {
    let errors = {}

    const { ...rest } = values ?? {}

    const name = String(values?.name)

    if (_.isEmpty(name)) {
      errors.name = t('required field')
    }

    return errors
  }

  return (
    <Formik
      onSubmit={(values) => {
        const { gender, date_of_birth, ...rest } = values ?? {}
        onSubmit(
          _.omitBy(
            {
              ...rest,
              date_of_birth: getDateString(date_of_birth),
              gender: gender ? Number(gender) : undefined,
            },
            _.isUndefined
          )
        )
      }}
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      initialValues={_initialValues}>
      <div className="flex flex-col gap-2">
        <FieldsFactory
          formSchema={[
            formSchema.name,
            formSchema.info_group_1,
            formSchema.info_group_2,
          ]}
        />
        <div className="space-x-1 text-sm italic">
          <span className="font-medium text-color-100">
            {`* ${t('note')} : `}
          </span>
          <span className="text-color-400">
            {t('note register description')}
          </span>
        </div>
        <FormActionGroups
          isSubmitting={isSubmitting}
          resetProps={{ hidden: true }}
          submitProps={{ label: 'register' }}
        />
      </div>
    </Formik>
  )
}

const EventSubscribeForm = ({ item, onCancel = Null, ...props }) => {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)

  const login = useContext(LoginContext)

  const owner_id = getId(item)

  const owner_type = getType(item)

  const { subscribeAsync, onSubscribe = Null } = useEventParticipant({
    onSubscribed: (result, data) => {
      onCancel()
      const item = getResponseItem(data?.response)
      confirmOnSuccess({
        translate: t,
        Icon: BsCheckCircle,
        title: 'registration successful',
        description: 'registration successful content',
      })
    },
  })

  const handleSubmit = (values) => {
    onSubscribe(values, { id: owner_id, type: owner_type })
  }

  const Component = login ? AuthenticatedForm : UnauthenticatedForm

  return (
    <div className="flex flex-col gap-6">
        {renderIf(
            isSm,
            <Header item={item} />
        )}
        <Component
            {...props}
            onSubmit={handleSubmit}
            isSubmitting={subscribeAsync?.isLoading}
            initialValues={{
                user: login,
            }}
        />
    </div>
  )
}

export default EventSubscribeForm
