import {schema} from 'normalizr'
import {channelSchema} from './channel'
import {organizationSchema} from './organization'
import {userSchema} from './user'
import {widgetSchema} from './widget'

export const articleSchema = new schema.Entity(
  'articles',
  {
    creator: new schema.Union(
      {
        user: userSchema,
        organization: organizationSchema
      },
      '_type'
    ),
    container: new schema.Union(
      {
        channel: channelSchema
      },
      '_type'
    ),
    widgets: [widgetSchema]
  },
  {
    idAttribute: 'idname'
  }
)
