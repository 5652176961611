import React from 'react';
import Loadable from "react-loadable";
import LoadingPage from "../../LoadingPage";

const ChannelSettingsLazy = Loadable({
  loader: () => import('./ChannelSettings'),
  loading: LoadingPage
})

const ChannelSettings = (props) => (
  <ChannelSettingsLazy {...props} />
)

export default ChannelSettings
