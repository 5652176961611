import React from 'react'
import {FlagENSvg} from '../Svgs/FlagENSvg'
import {FlagVISvg} from '../Svgs/FlagVISvg'
import {FlagFRSvg} from "../Svgs/FlagFRSvg";
import {FlagKOSvg} from "../Svgs/FlagKOSvg";

export const FlagIcon = ({
    size,
    type,
    ...props
}) => {
  switch (type) {
    case 'vi':
      return <FlagVISvg size={size} {...props} />
    case 'en':
      return <FlagENSvg size={size} {...props} />
    case 'fr':
      return <FlagFRSvg size={size} {...props} />
    case 'ko':
      return <FlagKOSvg size={size} {...props} />
    default:
      return <FlagVISvg size={size} {...props} />
  }
}
