const env = 'DEV'
const ENV_LABEL = 'DEV'
const IS_CORDOVA = false
const API_ROOT_URL =
  'https://dev.feedin.app/api/v1'
const ROOT_URL = 'http://localhost:3000'
const STORAGE_URL =
  'https://dev.feedin.app/storage'
const SOCKET_URL =
  'https://dev-socket.feedin.app'
const STORAGE_GEO_VN_DATA_URL =
  'https://www.amcharts.com/lib/4/geodata/json/vietnamLow.json'
const FB_ID = '807464506358167'
const GG_ID =
  '759535059502-ohstjkt8dbfitibjht4fduliea04kg8l.apps.googleusercontent.com'
const ANDROID_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=wishare.app'
const APPLE_ITUNES_LINK =
  'https://itunes.apple.com/us/app/wishare-app/id1401245325'
const GOOGLE_API_KEY =
  'AIzaSyCYc3U2zpF5V8DiAsY9PSSq0SF_CeRbdkA'
const FIREBASE_MESSAGING_SENDER_ID =
  '593867816357'

export default {
  env,
  ENV_LABEL,
  IS_CORDOVA,
  ROOT_URL,
  API_ROOT_URL,
  STORAGE_URL,
  SOCKET_URL,
  STORAGE_GEO_VN_DATA_URL,
  FB_ID,
  GG_ID,
  ANDROID_PLAY_LINK,
  APPLE_ITUNES_LINK,
  GOOGLE_API_KEY,
  FIREBASE_MESSAGING_SENDER_ID
}
