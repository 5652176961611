import { baseItemSchema } from 'apis/schema'
import _ from 'lodash'
import { denormalize } from 'normalizr'

export const selectEntities = (
  state,
  item,
  schema = baseItemSchema,
  defaultValue
) => {

  try {
    return denormalize(
      item,
      schema,
      state.entities
    )
  } catch (e) {
    console.error(e)
    return defaultValue
  }
}

export const selectEntitiesByIdName = (
  state,
  item,
  schema
) => {
  try {
    let result = denormalize(
      item,
      schema,
      state.entities
    )

    if (
      !result &&
      _.indexOf(
        [
          'users',
          'channels',
          'organizations',
          'articles'
        ],
        schema._key
      ) !== -1
    ) {
      if (schema._key === 'users') {
        result = denormalize(
          _.findKey(
            _.get(
              state,
              'entities.' + schema._key
            ),
            ['username', item]
          ),
          schema,
          state.entities
        )
      } else {
        result = denormalize(
          _.findKey(
            _.get(
              state,
              'entities.' + schema._key
            ),
            ['idname', item]
          ),
          schema,
          state.entities
        )
      }
    }

    return result
  } catch (e) {
    return undefined
  }
}
