import {Avatar, Button, Input, Menu, Modal} from 'antd'
import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import {SelectEntityItem} from 'components/SelectEntityItem'
import Toggle from "components/Toggle"
import _ from 'lodash'
import {createAsyncAction} from "modules/asyncCache"
import useTranslate from 'modules/local/useTranslate'
import React, {useState} from 'react'
import {EditAbleBlock} from "../../components/EditAbleBlock"
import {EditAbleBlockHolder} from "../../components/EditAbleBlockHolder"
import Partner from "./Partner.lazy"
import {
  organizationPartnerSchema,
  partners_deletePartner_Api,
  partners_editPartner_Api,
  partners_getPartners_Api
} from "../../../../apis/organizationPartner";
import DrawMenu from "../../../../components/drawer/DrawMenu";
import {useDispatch} from "react-redux";
import {showConfirm} from "../../../../components/Feed/TimeLineFeed";
import {LazyPagination} from "../../../../modules/asyncCache/components/LazyPagination";
import PartnerView from "./PartnerView.lazy";
import {getLinkToDetail} from "../../../../apis/model/organization";
import {Link} from "react-router-dom";
import ListHeader from "../../../AppLocals/Templates/items/ListHeader";
import {BsFillPersonVcardFill, BsTrash3} from "react-icons/bs";
import {FiEdit3} from "react-icons/fi";
import Count from "../../../../components/Feed/Count";
import {IoEllipsisHorizontal, IoPricetag, IoPricetagOutline, IoPricetags} from "react-icons/io5";
import {IoIosEye} from "react-icons/io";
import {ItemInformation} from "../../../AppLocals/Organization/OrganizationEntity";
import {renderIf} from "../../../Shared";
import {notifyOnError} from "../../../AppLocals/factory/createErrorEvent";
import {NotificationActionTypes, successNotify} from "../../../AppLocals/factory/createNotification";
import useAsyncAction from "../../../../modules/asyncCache/useAsyncAction";

const Partners = ({item, title, type}) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const [update, setUpdate] = useState(Date.now());


  const {handleAsyncAction: handleDeletePartner} = useAsyncAction({
    apiInfo: partners_deletePartner_Api,
    onError: notifyOnError(t),
    onSuccess: (result, {response}) => {
      successNotify(NotificationActionTypes.DELETE, t)
    },
  })

  return (
    <div className="flex flex-col gap-3">
      <ListHeader
        title={title}
        extra={
          <Toggle>
            {(isToggle, toggle) => (
              <React.Fragment>
                <Button
                  type="primary"
                  className="button-rounded-md no-border px-6"
                  onClick={toggle}>
                  {t('create new')}
                </Button>
                {isToggle && (
                  <FullSideModalLayout
                    right
                    onCancel={toggle}
                    title={
                      <div className="font-bold text-color-000">
                        {t('new partner')}
                      </div>
                    }>
                    <Partner
                      item={item}
                      type={type}
                      onSuccess={() => {
                        setUpdate(Date.now());
                        toggle()
                      }}
                    />
                  </FullSideModalLayout>
                )}
              </React.Fragment>
            )}
          </Toggle>
        }
      />
      <Input.Search
        allowClear
        className="w-full"
        placeholder={t('keyword: name, tax number, phone number, email')}
        onSearch={(e) => {
          setKeyword(e)
        }}
      />
      <div key={update + keyword} className="flex flex-col gap-3">
        <LazyPagination
          prefixStr={`Partners{created:${update + keyword}}`}
          renderEmpty={() => (
            <div className="flex justify-center items-center">
              <Toggle>
                {(isToggleAdd, toggleAdd) => (
                  <EditAbleBlock
                    isToggle={isToggleAdd}
                    placeholder={
                      <div className="w-full max-w-3xl mx-auto">
                        <EditAbleBlockHolder
                          icon={() => <BsFillPersonVcardFill size={60} style={{color: '#e9e9e9'}}/>}
                          onClick={toggleAdd}
                          title={t("add new partner")}
                          description={t('add new partner description')}
                        />
                      </div>
                    }>
                    <FullSideModalLayout
                      right
                      onCancel={toggleAdd}
                      title={
                        <div className="font-bold text-color-000">
                          {t('new partner')}
                        </div>
                      }>
                      <Partner
                        item={item}
                        type={type}
                        onSuccess={() => {
                          setUpdate(Date.now());
                          toggleAdd()
                        }}
                      />
                    </FullSideModalLayout>
                  </EditAbleBlock>
                )}
              </Toggle>
            </div>
          )}
          apiInfo={partners_getPartners_Api}
          query={{':id': item.idname}}
          values={{
            partner_type: type,
            keyword
          }}
          renderItem={(item) => (
            <SelectEntityItem key={item.id} item={item.id} schema={organizationPartnerSchema}>
              {(e) => (
                <Toggle>
                  {(isToggle0, toggle0) => (
                    <div
                      className="p-3 flex flex-col gap-2 border border-color-50 hover:shadow-items rounded-lg relative">
                      <div style={{top: 12}} className="absolute right-5">
                        <DrawMenu
                          component={
                            <Count>
                              <IoEllipsisHorizontal size={14}/>
                            </Count>
                          }>
                          {(isToggle, toggle) => (
                            <Menu mode="inline"
                                  onClick={({key}) => {
                                    switch (key) {
                                      case "edit":
                                        toggle0();
                                        break;
                                      case "delete":
                                        showConfirm({
                                          title: t('are you sure delete this partners'),
                                          okText: t('sure'),
                                          cancelText: t('ignore'),
                                          okButtonProps: {
                                            type: 'primary',
                                            danger: true,
                                            className: 'button-rounded-md no-border',
                                          },
                                          cancelButtonProps: {
                                            className: 'button-rounded-md no-border',
                                          },
                                          onOk: () => {
                                            handleDeletePartner({}, {id: e.id})

                                            setTimeout(() => {
                                              setUpdate(Date.now());
                                            }, 2000)
                                          }
                                        })
                                        break;
                                      default:
                                    }
                                  }}
                                  style={{minWidth: 180}}>
                              <Menu.Item key="0">
                                <Toggle>
                                  {(isToggleView, toggleView) => (
                                    <React.Fragment>
                                      <div onClick={toggleView} className="w-full flex items-center gap-3">
                                        <IoIosEye/>
                                        <span>{t('view')}</span>
                                      </div>
                                      {!!isToggleView && (
                                        <Modal
                                          width={600}
                                          className="custom-modal"
                                          title={
                                            <div
                                              className="text-center font-bold text-color-000 uppercase tracking-wide">
                                              {t('view detail')}
                                            </div>
                                          }
                                          onCancel={toggleView}
                                          maskClosable={false}
                                          destroyOnClose
                                          visible={isToggleView}
                                          footer={null}>
                                          <div className="flex flex-col gap-3">
                                            <PartnerView
                                              item={item}
                                              partner={e}
                                            />
                                            <div className="flex justify-end m-3">
                                              <Button
                                                onClick={toggleView}
                                                className="button-rounded-md no-border">
                                                {t('close')}
                                              </Button>
                                            </div>
                                          </div>
                                        </Modal>
                                      )}
                                    </React.Fragment>
                                  )}
                                </Toggle>
                              </Menu.Item>
                              <Menu.Item key="edit">
                                <Toggle>
                                  {(isToggleEdit, toggleEdit) => (
                                    <React.Fragment>
                                      <div onClick={toggleEdit} className="w-full flex items-center gap-3">
                                        <FiEdit3/>
                                        <span>{t('edit')}</span>
                                      </div>
                                      {!!isToggleEdit && (
                                        <FullSideModalLayout
                                          right
                                          onCancel={toggleEdit}
                                          title={
                                            <div className="font-bold text-color-000">
                                              {t('edit partner')}
                                            </div>
                                          }>
                                          <Partner
                                            item={item}
                                            type={type}
                                            partner={e}
                                            onSuccess={() => {
                                              setUpdate(Date.now());
                                              toggleEdit()
                                            }}
                                          />
                                        </FullSideModalLayout>
                                      )}
                                    </React.Fragment>
                                  )}
                                </Toggle>
                              </Menu.Item>
                              <Menu.Item key="delete" icon={<BsTrash3/>}>
                                {t('delete')}
                              </Menu.Item>
                            </Menu>
                          )
                          }
                        </DrawMenu>
                      </div>
                      <div className="flex flex-col gap-1">
                        {!!_.get(e, 'partner_organization') ? (
                          <Link
                            className="font-bold text-color-000 no-underline"
                            to={getLinkToDetail(_.get(e, 'partner_organization'))}>
                            {_.get(e, 'partner_name')}
                          </Link>
                        ) : (
                          !!_.get(e, 'partner_name') && (
                            <span className="font-bold text-color-000">
                                                        {_.get(e, 'partner_name')}
                                                    </span>
                          )
                        )}
                        <div className="flex items-center gap-6">
                          {!!_.get(e, 'tax_code') && (
                            <ItemInformation
                              className="text-xs"
                              label="tax code"
                              value={_.get(e, 'tax_code')}
                            />
                          )}
                          {!!_.get(e, 'address') && (
                            <ItemInformation
                              className="text-xs"
                              label="address"
                              value={_.get(e, 'address')}
                            />
                          )}
                        </div>
                      </div>
                      {!!_.get(e, 'partner_tags') && (
                        <div className="flex space-x-3 text-xs">
                          {(e.partner_tags || "").split(",").map((e, i) => (
                            <div key={i}
                                 style={{padding: '2px 8px'}}
                                 className="flex items-center gap-1 border border-color-50 rounded-md">
                              <IoPricetag size={10} className="text-color-500"/>
                              <span className="font-bold text-xs text-color-300 italic">
                                                            {t(e)}
                                                        </span>
                            </div>
                          ))}
                          <DrawMenu
                            component={
                              <Avatar
                                size={26}
                                icon={<IoPricetags size={14}/>}
                                className="flex flex-center background-200 bg-primary-50 hover:bg-primary-100 text-color-300 hover:text-primary-600 cursor-pointer"
                              />
                            }>
                            {(isToggle, toggle) => (
                              <Menu
                                mode="inline"
                                onClick={({key}) => {
                                  switch (key) {
                                    case "vendor":
                                    case "customer":
                                      let partner_tags = (e.partner_tags || "").split(",");
                                      if (partner_tags.includes(key)) {
                                        partner_tags = partner_tags.filter(e => !!e && e !== key);
                                      } else {
                                        partner_tags.push(key)
                                      }
                                      partner_tags = partner_tags.filter(e => !!e);

                                      dispatch(
                                        createAsyncAction(
                                          {
                                            apiInfo: partners_editPartner_Api,
                                            query: {
                                              ':id':
                                              e.id
                                            },
                                            values: {
                                              partner_tags: partner_tags.join(",")
                                            }
                                          }
                                        )
                                      );
                                      setTimeout(() => {
                                        setUpdate(Date.now());
                                      }, 2000);
                                      break;
                                    default:
                                  }
                                }}
                                style={{minWidth: 180}}>
                                {['vendor', 'customer'].map((ee, eei) => (
                                  <Menu.Item key={ee}>
                                    <div className="flex items-center gap-2">
                                      {(e.partner_tags || "").includes(ee) ? (
                                        <span className="flex items-center gap-2 text-primary-600">
                                                                                <IoPricetag/>
                                                                                <span className="font-medium text-sm">
                                                                                    {t(ee)}
                                                                                </span>
                                                                            </span>
                                      ) : (
                                        <span className="flex items-center gap-2 text-color-400">
                                                                                <IoPricetagOutline/>
                                                                                <span className="font-medium text-sm">
                                                                                    {t(ee)}
                                                                                </span>
                                                                            </span>
                                      )}

                                    </div>
                                  </Menu.Item>
                                ))}
                              </Menu>
                            )
                            }
                          </DrawMenu>
                        </div>
                      )}
                      <div className="flex flex-col gap-1">
                        <div className="font-medium text-xs text-color-400 underline uppercase">
                          {`${t('contact info')} : `}
                        </div>
                        <div className="flex items-center gap-1 italic ml-3">
                          {!!_.get(e, 'prefix_title') && (
                            <span className="font-medium text-xs text-color-100">
                                                        {t(_.get(e, 'prefix_title'))}
                                                    </span>
                          )}
                          <span className="font-bold text-xs text-color-000">
                                                    {[
                                                      _.get(e, 'first_name'),
                                                      _.get(e, 'last_name'),
                                                    ].join(" ")}
                                                </span>
                        </div>
                        <div className="flex items-center gap-6 ml-3">
                          {!!_.get(e, 'email') && (
                            <ItemInformation
                              className="text-xs"
                              label="email"
                              value={`${_.get(e, 'email') || _.get(e, 'email2')}`}
                            />
                          )}
                          {!!_.get(e, 'phone') && (
                            <ItemInformation
                              className="text-xs"
                              label="phone"
                              value={`${_.get(e, 'phone') || _.get(e, 'phone2')}`}
                            />
                          )}
                        </div>
                      </div>
                      {renderIf(
                        _.some([!!_.get(e, 'notes'), !!_.get(e, 'for_sales_notes'), !!_.get(e, 'for_purchase_notes')]),
                        <div className="space-x-1 text-xs italic">
                                                <span className="font-bold text-color-100">
                                                    {`* ${t('notes')} : `}
                                                </span>
                          {renderIf(
                            !!_.get(e, 'notes'),
                            <span className="text-color-400">
                                                        {_.get(e, 'notes')}
                                                    </span>
                          )}
                          {renderIf(
                            !!_.get(e, 'for_sales_notes'),
                            <span className="text-color-400">
                                                        {_.get(e, 'for_sales_notes')}
                                                    </span>
                          )}
                          {renderIf(
                            !!_.get(e, 'for_purchase_notes'),
                            <span className="text-color-400">
                                                        {_.get(e, 'for_purchase_notes')}
                                                    </span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </Toggle>
              )}
            </SelectEntityItem>
          )}
        />
      </div>
    </div>
  )
}

export default Partners
