import classnames from 'classnames'
import React from 'react'
export const Block = ({
  children,
  className
}) => (
  <div
    className={classnames(
      'background-100 p-3',
      className
    )}
    style={{
      borderRadius: '0.3em',
      width: '100%',
      overflowWrap: 'break-word'
    }}>
    {children}
  </div>
)
