import Icon, {PlusOutlined} from '@ant-design/icons'
import {Alert, Avatar, Breadcrumb, Button, Card, Form, Input, InputNumber, message, Modal} from 'antd'
import {createBaseItemSchema} from 'apis/schema'
import {createTransform} from 'apis/utils/createTransform'
import Count from 'components/Feed/Count'
import {API_ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import Async from 'modules/asyncCache/components/Async'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useMemo, useState} from 'react'
import {MdClose} from 'react-icons/md'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {useSelectEntities} from 'redux/entities/useSelectEntities'
import {useProvinces} from '../SelectProvinces'
import {AiFillDownCircle, AiFillUpCircle} from "react-icons/ai";
import {FaClipboardList} from "react-icons/fa";
import {LazyPagination} from "../../../../modules/asyncCache/components/LazyPagination";
import LazyComponent from "../../../../components/LazyComponent";
import {
  priceReport_manageMemberAdd_Api,
  priceReport_searchOrganizations_Api,
  priceReport_searchUsers_Api
} from "../../../../apis/price-report";
import getUpperFirstChar from "../../../../helpers/getUpperFirstChar";
import AsyncAction from "../../../../modules/asyncCache/components/AsyncAction";
import preventParentEvent from "../../../../helpers/preventParentEvent";
import {ProductPriceSetGroupReportComponent} from "../../../Market/Seller/Product/components/ProductPriceSetGroupComponent";
import {ProductPriceSetInfo} from "../../../Market/Seller/Product/components/ProductPriceSetInfo";
import Toggle from "../../../../components/Toggle";
import EditFilled from "@ant-design/icons/lib/icons/EditFilled";
import UserOutlined from "@ant-design/icons/lib/icons/UserOutlined";
import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined";
import PartitionOutlined from "@ant-design/icons/lib/icons/PartitionOutlined";
import {parseNumber} from "../../../../helpers/parseNumber";
import {formatterNumber} from "../../../../helpers/formatterNumber";
import {emptyArray, emptyObject} from "../../../../helpers/emptyObjects";
import {ItemInformation} from "../../../AppLocals/Organization/OrganizationEntity";
import {renderIf} from "../../../Shared";
import classNames from "classnames";
import {TbListTree} from "react-icons/tb";
import {
    BsEnvelopeAtFill,
    BsPersonFill,
    BsPlus,
    BsSearch,
    BsStarFill,
    BsStarHalf,
    BsX,
    BsXCircle,
    BsXLg
} from "react-icons/bs";

export const EditReportNumberModal = ({reportId, productPriceDetailReportId, fieldName, fieldLabel, defaultValue, ...props}) => {
  const [form] = Form.useForm();
  const t = useTranslate();

  const dispatch = useDispatch()
  const [
    data,
    dispatchAsyncActionWithNotify
  ] = useDispatchAsyncActionWithNotify({
    onSuccess: () => {
      dispatch(
        createAsyncAction({
          apiInfo: {
            root: API_ROOT_URL,
            path: `/products/price-report/${reportId}/get`,
            method: 'GET',
            transform: createTransform(
              createBaseItemSchema({
                idAttribute: () =>
                  `/products/price-report/${reportId}/get`
              }),
              'data'
            )
          }
        })
      )
    }
  })

  const handleOnFinish = () => {
    const values = form.getFieldsValue();
    dispatchAsyncActionWithNotify(
      createAsyncAction(
        {
          apiInfo: {
            root: API_ROOT_URL,
            path: `/products/price-report/${reportId}/update-report-price/${productPriceDetailReportId}`,
            method:
              'POST'
          },
          values: values
        }
      )
    )
  }

  return (
    <div className="cursor-pointer" onClick={() => {
      Modal.info({
        style: {
          top: '100px',
          maxHeight: 'calc(100vh - 100px)'
        },
        okText: t('submit'),
        onOk: handleOnFinish,
        closable: true,
        title: t('Edit quotes'),
        icon: false,
        content: (
          <div className="w-full space-y-6">
            <Alert
                type={"warning"}
                message={(
                    <div>
                        {t('This information is adjusted within the scope of the report, the content of the original information has not been changed')}
                    </div>
                )}
            />

            <Form className="space-y-3"
                form={form}
                initialValues={{[fieldName]: defaultValue}}
                {...{
                    labelCol: {span: 8},
                    wrapperCol: {span: 16},
                }}
                onFinish={handleOnFinish}>
              <Form.Item
                label={t(fieldLabel)}
                className="flex-1"
                name={fieldName}
              >
                <InputNumber
                  style={{width: '100%'}}
                  formatter={formatterNumber}
                  parser={parseNumber}
                />
              </Form.Item>

            </Form>
          </div>
        )
      })
    }}>
      <Avatar
          size={"small"} className="background-200"
          style={{width: '1.325rem', height: '1.325rem', lineHeight: '1.325rem'}}
          icon={<EditFilled className="text-primary"/>}
      />
    </div>
  )
}

export const ReportHeader = ({report}) => {

    const t = useTranslate();

    const {
        product_units = [],
        product_price_units = [],
        contract_types = [],
        delivery_types = [],
        incoterm_types = [],
        transport_types = [],
        countries = [],
        provinces = [],
    } = useAppConfig();

    if (!!!report) return null;

    const country = countries.find(item => String(item.id) === String(_.get(report, 'query_params.country_id')))
    const province = provinces.find(item => String(item.id) === String(_.get(report, 'query_params.province_id')))

    const ItemInfoReport = ({ label, value, className = 'text-sm' }) => {
        const t = useTranslate()
        return (
            renderIf(
                value,
                <div className={classNames('grid grid-cols-2 gap-2 italic', className)}>
                    <div className="text-right text-color-500">{`${t(label)}  : `}</div>
                    <div className="font-bold text-color-100">{value}</div>
                </div>
            )
        )
    }

    return (
        <React.Fragment>
            {!!report && !!report.errors && !!report.errors.length && report.errors.map((item, i) => (
                <Alert
                    key={i}
                    type="error"
                    message={item.title}
                    description={item.description}
                />
            ))}
            {!!report && !!report.warnings && !!report.warnings.length && report.warnings.map((item, i) => (
                <Alert
                    key={i}
                    type="warning"
                    message={item.title}
                    description={item.description}
                />
            ))}
            <div className="space-y-3 pb-3">
                <div className="flex justify-center items-center space-x-3 font-bold text-3xl text-color-000">
                    {t('products quotation')}
                </div>
                <div className="space-y-2">
                    <ItemInfoReport label="provider" value={_.get(report, 'owner.title')} />
                    <ItemInfoReport label="announce" value={moment(_.get(report, 'report_at')).format("DD/MM/YYYY")} />
                    {!_.isEmpty(_.get(report, 'query_params.contract_types', '')) && (
                        <ItemInfoReport
                            label="contract type"
                            value={String(_.get(report, 'query_params.contract_types', '')).split(",").map(e => t(contract_types[e])).join(", ")}
                        />
                    )}
                    {!_.isEmpty(_.get(report, 'query_params.delivery_types', '')) && (
                        <ItemInfoReport
                            label="delivery type"
                            value={String(_.get(report, 'query_params.delivery_types', '')).split(",").map(e => t(delivery_types[e])).join(", ")}
                        />
                    )}
                    {!_.isEmpty(_.get(report, 'query_params.incoterm_types', '')) && (
                        <ItemInfoReport
                            label="incoterm"
                            value={String(_.get(report, 'query_params.incoterm_types', '')).split(",").map(e => t(incoterm_types[e])).join(", ")}
                        />
                    )}
                    {!_.isEmpty(_.get(report, 'query_params.transport_types', '')) && (
                        <ItemInfoReport
                            label="transport type"
                            value={String(_.get(report, 'query_params.transport_types', '')).split(",").map(e => t(transport_types[e])).join(", ")}
                        />
                    )}
                    {country && (
                        <ItemInfoReport
                            label="country"
                            value={country.common_name}
                        />
                    )}
                    {province && (
                        <ItemInfoReport
                            label="province"
                            value={province.name}
                        />
                    )}
                    {_.get(report, 'query_params.products', []).length > 0 && (
                        <ItemInfoReport
                            label="products"
                            value={_.get(report, 'query_params.products', []).map((e, i) => (
                                <span key={i}>
                                    {e.title}
                                    {renderIf( i < _.get(report, 'query_params.products', []).length - 1, ', ')}
                                </span>
                            ))}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

const UserSearchResult = ({
    value,
    reportId,
    users = [],
    emails = []
}) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const path = `/products/price-report/${reportId}/get`;

  return (
    <LazyPagination
      key={value}
      {...{
        apiInfo: priceReport_searchUsers_Api,
        query: {
          ':id': reportId
        },
        values: {keyword: value}
      }}
      renderItem={item => (
          <div className="p-2 flex items-center gap-2 rounded-md border border-color-50 hover:border-color-base hover:shadow-items-md">
              <Avatar
                  size={26}
                  src={item.thumbnail}
                  className="flex flex-center background-200"
                  icon={ <BsPersonFill className="text-color-400"/> }
              />
              <div className="flex-1 truncate">
                  <span className="font-bold text-sm text-color-000">
                      {item.name}
                  </span>
                  <span className="text-xs text-color-400 italic">
                      {item.email}
                  </span>
              </div>
              <AsyncAction
                  apiInfo={priceReport_manageMemberAdd_Api}
                  query={{ ':id': reportId }}
                  values={{
                      users: [...users.map(e => e.username), item.username].join(","),
                      emails: [...emails].join(",")
                  }}
                  onSuccess={() => {
                      dispatch(
                          createAsyncAction({
                              apiInfo: {
                                  root: API_ROOT_URL,
                                  path: path,
                                  method: 'GET',
                                  transform: createTransform(
                                      createBaseItemSchema({
                                          idAttribute: () =>
                                              path
                                      }),
                                      'data'
                                  )
                              }
                          })
                      );
                  }}>
                  {({ handleAsyncAction, success, isLoading, ...data }) => {
                      if (success)
                          return (
                              <span
                                  style={{ padding: '1px 8px' }}
                                  className="w-fit background-100 border rounded-md font-semibold text-xs text-color-300 italic">
                                  {t('member')}
                              </span>
                          )
                      return (
                          <Button
                              loading={isLoading}
                              onClick={() => handleAsyncAction()}
                              size="small"
                              type="primary"
                              className="flex flex-center gap-1 button-rounded-md font-bold text-xs pr-3"
                              icon={<BsPlus size={13}/>}>
                              {t('add')}
                          </Button>
                      )
                  }}
              </AsyncAction>
          </div>
      )}
    />
  )
}
const AddUser = ({item, users, emails}) => {
  const t = useTranslate()

  const reportId = item.idname
  const [value, setValue] = useState('');
  const setDebounceValue = _.debounce(function (value) {
    setValue(value)
  }, 300);

  const input = useMemo(
    () => (
      <div
        key="1"
        className="flex items-center w-full sticky top-0 z-20">
        <form
          className="flex-1"
          onSubmit={e => {
            preventParentEvent(e)
          }}>
          <Input.Search
            onChange={e => setDebounceValue(e.target.value)}
            autoFocus
            placeholder={t('enter your keyword')}
            className="custom-input-search-addon"
            allowClear={true}
            addonBefore={<BsPersonFill className="text-color-400"/>}
            enterButton={<BsSearch size={12} />}
          />
        </form>
      </div>
    ),
    [t]
  )
  const result = useMemo(() => {
    return (
      <div
        key="2"
        className="SearchContent flex flex-col gap-2">
        {
          !!(reportId && value && value.length > 2) && (
            <UserSearchResult
              value={value}
              {...{
                reportId,
                users,
                emails
              }}
            />
          )
        }
      </div>
    )
  }, [
    reportId,
    users,
    value
  ])

  return (
    <>
      {input}
      <LazyComponent>
        {result}
      </LazyComponent>
    </>
  )
}
const OrganizationSearchResult = ({
    value,
    reportId,
    organizations = [],
    emails = []
}) => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const path = `/products/price-report/${reportId}/get`;

  return (
    <LazyPagination
      key={value}
      {...{
        apiInfo: priceReport_searchOrganizations_Api,
        query: {
          ':id': reportId
        },
        values: {keyword: value}
      }}
      renderItem={item => {
        return (
          <div
            className="flex items-center hover:background-100 rounded border border-transparent hover:border-gray-300 py-2 pl-2">
            <Avatar
              shape="circle"
              src={item.thumbnail}>
              {getUpperFirstChar(
                item.title
              )}
            </Avatar>
            <div className="ml-2 flex-1 truncate">
              <div className="font-semibold">
                {item.title}
              </div>
              <div className="text-xs">
                @{item.idname}
              </div>
            </div>
            <div className="flex items-center pr-2">
              <AsyncAction
                apiInfo={
                  priceReport_manageMemberAdd_Api
                }
                query={{
                  ':id': reportId
                }}
                values={{
                  organizations: [
                    ...organizations.map(e => e.idname),
                    item.idname
                  ].join(",")
                }}
                onSuccess={() => {
                  dispatch(
                    createAsyncAction({
                      apiInfo: {
                        root: API_ROOT_URL,
                        path: path,
                        method: 'GET',
                        transform: createTransform(
                          createBaseItemSchema({
                            idAttribute: () =>
                              path
                          }),
                          'data'
                        )
                      }
                    })
                  );
                }}
              >
                {({
                    handleAsyncAction,
                    success,
                    isLoading,
                    ...data
                  }) => {
                  if (success)
                    return (
                      <Button disabled>
                        {t('added')}
                      </Button>
                    )
                  return (
                    <Button
                      loading={
                        isLoading
                      }
                      onClick={() => {
                        handleAsyncAction();
                      }}
                      type="primary"
                      icon={
                        <PlusOutlined/>
                      }>
                      {t('add')}
                    </Button>
                  )
                }}
              </AsyncAction>
            </div>
          </div>
        )
      }}/>
  )
}
const AddOrganization = ({item, organizations, emails}) => {
  const t = useTranslate()

  const reportId = item.idname
  const [value, setValue] = useState('');
  const setDebounceValue = _.debounce(function (value) {
    setValue(value)
  }, 300);

  const input = useMemo(
    () => (
      <div
        key="1"
        className="flex items-center w-full  background sticky top-0 z-20">
        <form
          className="flex-1"
          onSubmit={e => {
            preventParentEvent(e)
          }}>
          <Input.Search
            onChange={e =>
              setDebounceValue(e.target.value)
            }
            autoFocus
            placeholder={t('enter your keyword')}
            className="custom-input-search-addon"
            allowClear={true}
            addonBefore={<PartitionOutlined className="text-color-400" />}
            enterButton={<BsSearch size={12} />}
          />
        </form>
      </div>
    ),
    [t]
  )
  const result = useMemo(() => {
    return (
      <div
        key="2"
        className="SearchContent flex-1 py-3 verticalList__small">
        {
          !!(reportId && value && value.length > 2) && (
            <OrganizationSearchResult
              value={value}
              {...{
                reportId,
                organizations,
                emails
              }}
            />
          )
        }
      </div>
    )
  }, [
    reportId,
    organizations,
    value
  ])

  return (
    <>
      {input}
      <LazyComponent>
        {result}
      </LazyComponent>
    </>
  )
}

export default ({reportId}) => {
  const t = useTranslate();

  const dispatch = useDispatch();
  const path = `/products/price-report/${reportId}/get`;
  const [
    data,
    dispatchAsyncActionWithNotify
  ] = useDispatchAsyncActionWithNotify({
    onSuccess: () => {
      dispatch(
        createAsyncAction({
          apiInfo: {
            root: API_ROOT_URL,
            path: path,
            method: 'GET',
            transform: createTransform(
              createBaseItemSchema({
                idAttribute: () =>
                  path
              }),
              'data'
            )
          }
        })
      )
    }
  });

  const report = useSelectEntities(
    path
  ) || {
    query_params: {
      contract_types: "",
      delivery_types: "",
      incoterm_types: "",
      transport_types: ""
    },
    columns: [],
    products: [],
    warnings: [],
    emails: [],
    errors: []
  }
  const warnings = useMemo(
    () => report.warnings,
    [_.get(report, 'warnings', [])]
  )
  const errors = useMemo(
    () => report.errors,
    [_.get(report, 'errors', [])]
  )
  const emails = useMemo(
    () => report.emails || emptyArray,
    [report.emails]
  )
  const users = useMemo(
    () => report.users || emptyArray,
    [report.users]
  )
  const organizations = useMemo(
    () => report.organizations || emptyArray,
    [report.organizations]
  )
  const query_params = useMemo(
    () => report.query_params || emptyObject,
    [report.query_params]
  )
  const organization = report.owner;

  const { countries = [] } = useAppConfig();
  const { provinceList, } = useProvinces({countryId: 237,})

  const country = countries.find(item => String(item.id) === String(_.get(report, 'query_params.country_id')))
  const province = provinceList.find(item => String(item.value) === String(_.get(report, 'query_params.province_id')))

  return (
    <>
      <React.Fragment key={path}>
        <Async apiInfo={{
          root: API_ROOT_URL,
          path: path,
          method: 'GET',
          transform: createTransform(createBaseItemSchema({
            idAttribute: () => path
          }), 'data')
        }}>
        </Async>
      </React.Fragment>
      <div className="w-full space-y-6">
          <div className="flex items-center gap-2">
              <BsStarFill size={11} className="text-color-400"/>
              <Breadcrumb className="text-xs italic">
                  <Breadcrumb.Item>
                      <Link
                          className="font-bold text-color-300 no-underline"
                          to={ {search: '', state: {isModal: true} }}>
                          {t('reports')}
                      </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                      <span className="text-color-400">{_.get(report, 'idname')}</span>
                  </Breadcrumb.Item>
              </Breadcrumb>
          </div>
          <ReportHeader report={report}/>
          <div className="p-3 flex flex-col gap-6 border border-color-50 rounded-lg">
              <div className="flex flex-col gap-2">
                  <div className="font-semibold text-sm text-color-400 uppercase tracking-wide">
                      {`${t('feedin users')} : `}
                  </div>
                  {renderIf(
                      users.length > 0,
                      <div className="flex flex-wrap gap-x-2 gap-y-1">
                          {(users || emptyArray).map(obj => (
                              <div
                                  key={obj.id}
                                  style={{ padding: '4px 8px' }}
                                  className="w-fit flex items-center gap-3 background border border-color-50 hover:shadow-items-md rounded-md cursor-pointer">
                                  <div className="font-bold text-xs text-color-100 italic">
                                      {obj.name}
                                  </div>
                                  <BsXCircle
                                      size={12}
                                      className="text-color-300 hover:text-red-600 cursor-pointer"
                                      onClick={() => {
                                          dispatchAsyncActionWithNotify(
                                              createAsyncAction(
                                                  {
                                                      apiInfo: {
                                                          root: API_ROOT_URL,
                                                          path: `/products/price-report/${reportId}/update-set-emails`,
                                                          method:
                                                              'POST'
                                                      },
                                                      values: {
                                                          emails: emails.join(","),
                                                          users: [
                                                              ...users
                                                          ].filter(
                                                              item =>
                                                                  item.id !==
                                                                  obj.id
                                                          ).map(e => e.id).join(",")
                                                      }
                                                  }
                                              )
                                          )
                                      }}
                                  />
                              </div>
                          ))}
                      </div>
                  )}
                  <AddUser item={report} users={users} emails={emails}/>
              </div>
              <div className="flex flex-col gap-2">
                  <div className="font-semibold text-sm text-color-400 uppercase tracking-wide">
                      {`${t('the recipient email')} : `}
                  </div>
                  {renderIf(
                      emails.length > 0,
                      <div className="flex flex-wrap gap-x-2 gap-y-1">
                          {(emails || emptyArray).map(str => (
                              <div
                                  key={str}
                                  style={{ padding: '4px 8px' }}
                                  className="w-fit flex items-center gap-3 background border border-color-50 hover:shadow-items-md rounded-md cursor-pointer">
                                  <div className="font-bold text-xs text-color-100 italic">
                                      {str}
                                  </div>
                                  <BsXCircle
                                      size={12}
                                      className="text-color-300 hover:text-red-600 cursor-pointer"
                                      onClick={() => {
                                          dispatchAsyncActionWithNotify(createAsyncAction({
                                              apiInfo: {
                                                  root: API_ROOT_URL,
                                                  path: `/products/price-report/${reportId}/update-set-emails`,
                                                  method:'POST'
                                              },
                                              values: {
                                                  users: users.map(e => e.username).join(","),
                                                  emails: [...emails].filter(item => item !== str).join(",")
                                              }
                                          }))
                                      }}
                                  />
                              </div>
                          ))}
                      </div>
                  )}
                  <Input.Search
                      key={data.success}
                      loading={data.isLoading}
                      className="custom-input-search-addon"
                      type="email"
                      inputMode="email"
                      onSearch={str => {
                          const validate = email => {
                              let errors
                              if (!email) {
                                  errors = t(
                                      'Email Required'
                                  )
                              } else if (
                                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                      email
                                  )
                              ) {
                                  errors = t(
                                      'Invalid email address'
                                  )
                              }
                              return errors
                          }
                          const error = validate(
                              str
                          )
                          if (error) {
                              return message.error(
                                  error
                              )
                          }
                          dispatchAsyncActionWithNotify(
                              createAsyncAction({
                                  apiInfo: {
                                      root: API_ROOT_URL,
                                      path: `/products/price-report/${reportId}/update-set-emails`,
                                      method: 'POST'
                                  },
                                  values: {
                                      users: users.map(e => e.username).join(","),
                                      emails: [
                                          ...emails,
                                          str
                                      ]
                                  }
                              })
                          )
                      }}
                      addonBefore={<BsEnvelopeAtFill className="text-color-400"/>}
                      enterButton={<BsSearch size={12} />}
                  />
              </div>
          </div>
      </div>

      <div className="p-3 w-full space-y-6 pb-24">

        {
          (report.products || emptyArray).map((eProduct, i) => {

            return (
              <div key={eProduct.id} className="flex flex-col space-y-3 p-3 rounded-md border">

                <Alert type={"success"} message={
                  <ProductPriceSetInfo product={eProduct}/>
                }/>


                {
                  [
                    {
                      id: 'spot',
                      label: 'spot price'
                    },
                    {
                      id: 'forward',
                      label: 'forward price'
                    }
                  ].map(
                    eType => {

                      const productPriceSetsByContractType = _.reduce(
                        _.get(eProduct, 'prices', [])
                          .map(
                            price => ({
                              key: price.id,
                              product: {..._.pick(eProduct, ['title', 'id', 'idname'])},
                              ...price
                            })
                          ),
                        (res, {data: eData, ...e}) => {
                          if (e.contract_type_cd === eType.id) {

                            const _locGroup = _.reduce(eData, (res1, ee) => {
                              const {data: locData, id: product_price_set_id, ...loc} = ee;
                              const {data: groupData, id: product_price_group_id, ...group} = _.get(locData, '0', {});

                              res1.push({
                                product_price_set_id, ...loc,
                                product_price_group_id, ...group,
                                data: groupData
                              });

                              return res1;

                            }, []);

                            res.push(
                              {
                                ...e,
                                data: _locGroup
                              }
                            );

                            return res;

                          }

                          return res;
                        },
                        []);

                      if (_.isEmpty(productPriceSetsByContractType)) return null;

                      const product_price_set_ids = productPriceSetsByContractType.map(e => e.id);

                      return (
                        <Toggle
                          key={JSON.stringify(product_price_set_ids)}
                          defaultValue={!!productPriceSetsByContractType}>
                          {(
                            isToggle,
                            toggle
                          ) => {

                            return (
                              <div key={eType.id} className="flex flex-col space-y-5">
                                <div className="flex flex-col space-y-1 pb-3">

                                  {
                                    !isToggle ? (
                                      <div className="flex flex-col">
                                        <div
                                          className="font-bold text-primary flex flex-row items-center">
                                          <FaClipboardList onClick={() => {
                                            toggle()
                                          }}/>
                                          <div className="px-3 flex-1">
                                            {t(eType.label)}
                                          </div>
                                        </div>
                                        <div className="flex flex-1 items-center pb-3">
                                          <div className="w-full border-t mx-3" style={{lineHeight: 0.5}}/>

                                          <Avatar size={"small"}
                                                  className="flex items-center justify-center background-200"
                                                  onClick={() => {
                                                    toggle()
                                                  }}
                                                  icon={<AiFillDownCircle/>}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <React.Fragment>
                                        <div
                                          className="font-bold text-primary flex flex-row items-center">
                                          <FaClipboardList onClick={() => {
                                            toggle()
                                          }}/>
                                          <div className="px-3 flex-1">
                                            {t(eType.label)}
                                          </div>
                                        </div>
                                        <div className="flex flex-1 items-center pb-3">
                                          <div className="w-full border-t mx-3" style={{lineHeight: 0.5}}/>

                                          <Avatar size={"small"}
                                                  className="flex items-center justify-center background-200"
                                                  onClick={() => {
                                                    toggle()
                                                  }}
                                                  icon={<AiFillUpCircle/>}
                                          />
                                        </div>
                                        {
                                          (productPriceSetsByContractType || emptyArray).map(productPriceSetGroup => {
                                            return (
                                              <React.Fragment key={JSON.stringify(productPriceSetGroup)}>
                                                <ProductPriceSetGroupReportComponent
                                                  readOnly={false}
                                                  report={report}
                                                  item={productPriceSetsByContractType.product}
                                                  productPriceSet={productPriceSetGroup}/>
                                              </React.Fragment>
                                            )
                                          })
                                        }

                                      </React.Fragment>
                                    )
                                  }
                                </div>

                              </div>
                            )
                          }
                          }
                        </Toggle>
                      )
                    }
                  )
                }
              </div>
            )
          })
        }


      </div>
    </>
  )
}
