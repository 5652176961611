import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'
import * as serviceWorker from 'serviceWorker'

export default function startApp({
  isProduction = !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
  app,
  rootElement,
  rootId = 'root'
}) {
  function render() {
    const renderMethod = window.__SERVER_STATE__
      ? // $FlowIssue
      ReactDOM.hydrate
      : ReactDOM.render

    return renderMethod(
      app,
      rootElement ? rootElement : document.getElementById(rootId)
    )
  }

  Loadable.preloadReady()
    .then(render)
    .catch(err => {
      console.error(err)
    })
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA

  if (!isProduction) {
    // dev code
    serviceWorker.unregister()
  } else {
    // production code
    // serviceWorker.register();
  }
}
