import { userSchema } from 'apis/schema'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import React from 'react'
import { notEmpty } from 'views/Shared'

const UserEntity = ({
  item,
  children,
  defaultItem,
}) => {
  return (
    <SelectEntityItem
      item={item}
      defaultItem={defaultItem}
      schema={userSchema}>
      {(data) => children(data)}
    </SelectEntityItem>
  )
}

export const UserEntities = ({
  items,
  children,
  defaultItem,
}) => {
  return (
    <SelectEntityItem
      item={items}
      defaultItem={defaultItem}
      schema={[userSchema]}>
      {(data = []) =>
        children(
          _.filter(data, notEmpty)
        )
      }
    </SelectEntityItem>
  )
}

export default UserEntity
