import {Alert, Badge} from 'antd'
import {channelModel} from 'apis/model'
import {ChannelIcon} from 'components/icons/ChannelIcon'
import Toggle from 'components/Toggle'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {FaAngleDown, FaAngleUp} from 'react-icons/fa'
import {FiPlus} from 'react-icons/fi'
import {Link} from 'react-router-dom'
import {staticPaths} from 'routes/staticPaths'
import {HeaderButton} from 'views/Organization/components/PageHeader'

export const MyChannels = ({
  channels
}) => {
  const t = useTranslate()

  return (
    <div className="verticalList__small">
      <Toggle defaultValue={true}>
        {(isToggle, toggle) => (
          <>
            <div className="flex items-center h-6 mt-6 mb-3">
              <div className="px-2 uppercase font-bold text-sm text-color-300">
                {t('personal channels')}
              </div>
              <div className="flex-1" />
              <Link
                className="items-center justify-center"
                to={
                  staticPaths.createChannel
                }>
                <HeaderButton>
                  <FiPlus />
                </HeaderButton>
              </Link>
              <HeaderButton
                onClick={() =>
                  toggle()
                }>
                {isToggle ? (
                  <FaAngleUp />
                ) : (
                  <FaAngleDown />
                )}
              </HeaderButton>
            </div>
            {isToggle && (
              <div className="verticalList__small background-100">
                {!(
                  channels &&
                  channels.length
                ) > 0 ? (
                  <Alert
                    type="info"
                    closable
                    showIcon={false}
                    banner
                    className="text-center background-100"
                    message={
                      <div className="py-4">
                        <div className="text-sm m-auto flex flex-1">
                          <span className="whitespace-no-wrap pt-3">
                            {t('Tap')}
                          </span>
                          <Link
                            className="items-center justify-center"
                            to={
                              staticPaths.createChannel
                            }>
                            <HeaderButton>
                              <FiPlus />
                            </HeaderButton>
                          </Link>
                          <span className="flex pt-3">
                            {t(
                              'to create your new channel'
                            )}
                          </span>
                        </div>
                      </div>
                    }
                  />
                ) : (
                  channels.map(
                    (o, i) => (
                      <Link
                        to={channelModel.getLinkToDetail(
                          o
                        )}
                        key={i}
                        className="flex hover:background-100 items-center justify-center py-1 px-3">
                        <ChannelIcon
                          className="mr-2 text-color-100 text-3xl w-8 h-8"
                          premium={
                            o.premium
                          }
                        />
                        <h2 className="font-semibold flex-1">
                          {channelModel.getTitle(
                            o
                          )}
                        </h2>
                        {o.has_unread && (
                          <Badge
                            dot
                            color="green"
                          />
                        )}
                      </Link>
                    )
                  )
                )}
              </div>
            )}
          </>
        )}
      </Toggle>
    </div>
  )
}
