import { API_ROOT_URL } from 'envs/_current/config'
import { createAsyncAction } from 'modules/asyncCache'
import { pinSchema } from './schema'
import { createTransform } from './utils/createTransform'

// Route::post('pins/{container}/{container_id}/mark/{type}/{id}', 'PinController@mark');
export const pin_mark_Api = {
  backend: `// Route::post('pins/{container}/{container_id}/mark/{type}/{id}', 'PinController@mark');`,
  root: API_ROOT_URL,
  path:
    '/pins/:container/:container_id/mark/:type/:id',
  method: 'POST',
  fields: [],
  fieldTypes: {},
  transform: createTransform(
    pinSchema,
    'data'
  )
}
export const create_pin_mark_Api_action = () => ({
  prefixStr = 'pin_mark_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@pin_mark_Api',
    query,
    values,
    apiInfo: pin_mark_Api,
    maxAge
  })
// Route::post('pins/{container}/{container_id}/unmark/{type}/{id}', 'PinController@unMark')
export const pin_unMark_Api = {
  backend: `// Route::post('pins/{container}/{container_id}/unmark/{type}/{id}', 'PinController@unMark')`,
  root: API_ROOT_URL,
  path:
    '/pins/:container/:container_id/unmark/:type/:id',
  method: 'POST',
  fields: [],
  fieldTypes: {},
  transform: createTransform(
    pinSchema,
    'data'
  )
}
export const create_pin_unMark_Api_action = () => ({
  prefixStr = 'pin_unMark_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@pin_unMark_Api',
    query,
    values,
    apiInfo: pin_unMark_Api,
    maxAge
  })
