import React, {useCallback,} from 'react'
import {DefaultLayout, Null, TemplateTypes, withComp,} from 'views/Shared'
import {TemplateWrapper} from './TemplateWrapper'

const PageTemplate = ({
                        children,
                        Sider = Null,
                        Banner = Null,
                        Wrapper = TemplateWrapper,
                        ...props
                      }) => {
  const renderContent = useCallback(
    () => children,
    [children]
  )
  const sider = withComp(Sider)
  return (
    <Wrapper>
      <Banner/>
      <DefaultLayout
        sider={sider}
        Content={renderContent}
        isContentFirst={false}
        type={TemplateTypes.RIGHT_SIDER}
        {...props}
      />
    </Wrapper>
  )
}

export default PageTemplate
