import {schema} from 'normalizr'
import {chartSchema} from '../chart'
import {articleSchema} from './article'
import {channelSchema} from './channel'
import {organizationSchema} from './organization'
import {pollSchema} from './poll'
import {postSchema} from './post'
import {userSchema} from './user'
import {widgetSchema} from './widget'

export const feedContainerSchema = new schema.Union(
  {
    user: userSchema,
    channel: channelSchema,
    organization: organizationSchema,
  },
  '_type'
)

export const feedContentSchema = new schema.Union(
  {
    post: postSchema,
    poll_question: pollSchema,
    article: articleSchema,
    widget: widgetSchema,
  },
  '_type'
)

export const feedSchema = new schema.Entity(
  'feeds',
  {
    container: feedContainerSchema,
    content: feedContentSchema,
  },
  {
    idAttribute: 'id',
  }
)

export const recomendationUsersFeedSchema = new schema.Entity(
  'feeds',
  {
    content: [userSchema],
  },
  {
    idAttribute: 'id',
  }
)
export const recomendationChannelsFeedSchema = new schema.Entity(
  'feeds',
  {
    content: [channelSchema],
  },
  {
    idAttribute: 'id',
  }
)
export const widgetsFeedSchema = new schema.Entity(
  'feeds',
  {
    content: [widgetSchema],
  },
  {
    idAttribute: 'id',
  }
)
export const followingChartsFeedSchema = new schema.Entity(
  'feeds',
  {
    content: [chartSchema],
  },
  {
    idAttribute: 'id',
  }
)

export const homeFeed = new schema.Union(
  {
    feed: feedSchema,
    'recommendation-channels': recomendationChannelsFeedSchema,
    'recommendation-users': recomendationUsersFeedSchema,
    // 'widgets': widgetsFeedSchema,
    'following-charts': followingChartsFeedSchema,
  },
  (value) => {
    switch (value.action) {
      case 'recommendation-channels':
        return 'recommendation-channels'
      case 'recommendation-users':
        return 'recommendation-users'
      case 'following-charts':
        return 'following-charts'
      // case 'sticky':
      //   return 'widgets'

      default:
        return 'feed'
    }
  }
)
