import {Button, DatePicker, InputNumber, Popconfirm} from 'antd'
import {create_product_updatePrice_Api} from 'apis'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useState} from 'react'
import {
  create_product_copyPriceSet_Api,
  create_product_expirePriceSetGroup_Api,
  create_product_price_getById_Api,
  create_product_removeExpirePriceSetGroup_Api
} from "../../../../../apis";
import UseState from "../../../../../components/UseState";
import {formatterNumber} from "../../../../../helpers/formatterNumber";
import {parseNumber} from "../../../../../helpers/parseNumber";
import Async from "../../../../../modules/asyncCache/components/Async";
import {useSelectEntities} from "../../../../../redux/entities/useSelectEntities";
import {productSchema} from "../../../../../apis/schema";
import {ProductPriceGroupCommentComponent} from "../components/forms/ProductPriceGroupCommentForm";
import MessageFilled from "@ant-design/icons/lib/icons/MessageFilled";
import MessageOutlined from "@ant-design/icons/lib/icons/MessageOutlined";
import CloseCircleFilled from "@ant-design/icons/lib/icons/CloseCircleFilled";
import StopFilled from "@ant-design/icons/lib/icons/StopFilled";
import {CopyPriceGroupInputComponent} from "./CopyPriceGroupInputForm";
import {BsCalendar2Week, BsCalendar3, BsStopCircle, BsXCircle} from "react-icons/bs";
import {renderIf, renderIfElse} from "../../../../Shared";
import {FaMapMarkerAlt} from "react-icons/fa";
import {MiniButton} from "../../../../AppLocals/Organization/OrganizationEntity";
import {IoChatboxEllipsesOutline} from "react-icons/io5";

const SpotPriceInputByProduct = ({item, effectDate}) => {
  const t = useTranslate();
  const [__, dispatch] = useDispatchAsyncActionWithNotify();

  const product = useSelectEntities(
    item.idname,
    productSchema
  ) || item;

  const handleOnCopySubmitLocation = ({product_id, product_price_set_id, product_price_group_id, diff = 0, diff2 = 0, eff_at}) => {

    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_copyPriceSet_Api(),
          query: {
            ':id': product_id,
            ':product_price_set_id': product_price_set_id
          },
          values: {
            product_price_group_id, diff, diff2, eff_at
          }
        }
      )
    )

  }
  const handleOnEditPrice = ({product_id, ...values}) => {

    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_updatePrice_Api(),
          query: {
            ':id':
            product_id
          },
          values: values
        }
      )
    )

  }
  const handleOnExpirePriceSetGroup = ({product_price_group_id, eff_at}) => {
    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_expirePriceSetGroup_Api(),
          query: {
            ':product_price_group_id': product_price_group_id
          },
          values: {
            end_at: eff_at.format("YYYY-MM-DD")
          }
        }
      )
    )
  }
  const handleOnRemoveExpirePriceSetGroup = ({product_price_group_id, eff_at}) => {
    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_removeExpirePriceSetGroup_Api(),
          query: {
            ':product_price_group_id': product_price_group_id
          },
          values: {
            end_at: eff_at.format("YYYY-MM-DD")
          }
        }
      )
    )
  }
  const productPriceSetsByContractType = _.reduce(_.get(product, 'prices', []), (res, {data: eData, ...e}) => {

    if (e.contract_type_cd === "spot") {

      const _locGroup = _.reduce(eData, (res1, ee) => {
        const {data: locData, id: product_price_set_id, ...loc} = ee;
        const {data: groupData, id: product_price_group_id, ...group} = _.get(locData, '0', {});


        if (!!loc.max_eff_at) {
          res1.push({
            product_price_set_id,
            ...loc,
            product_price_group_id,
            ...group,
            data: groupData
          });
        }

        return res1;

      }, []);

      res.push(
        {
          ...e,
          data: _locGroup
        }
      );

      return res;

    }

    return res;
  }, []);

  return (
    <div key={product.id + effectDate.format("DD/MM/YYYY")} className="flex flex-col gap-3">
      {productPriceSetsByContractType.map((eByUnit, iByUnit) => (
          <div key={iByUnit + product.id + effectDate.format("DD/MM/YYYY")} className="flex flex-col gap-3">
              <div className="w-full flex items-center border-b-2 border-primary">
                  <span className="flex-1 font-bold text-primary-600">
                      {_.get(product, 'title')}
                  </span>
                  <div className="space-x-1 text-xs italic leading-snug">
                      <span className="text-color-400">
                          {`${t('unit')} : `}
                      </span>
                      {renderIf(
                          _.has(eByUnit, 'price_unit.name'),
                          <span className="font-bold text-color-100">
                              {_.get(eByUnit, 'price_unit.name', t('price unit'))}/{_.get(eByUnit, 'unit.name', t('unit'))}
                          </span>
                      )}
                  </div>
              </div>
              {_.get(eByUnit, 'data', []).map((eRec, iRec) => (
                  <div key={iRec} className={iRec > 0 ? 'border-t pt-3' : undefined}>
                      <UseState
                          key={JSON.stringify(eRec)}
                          initialValue={{ ..._.get(eRec, 'data.0', {}) }}>
                          {([values, setValues]) => (
                              <div className="flex flex-col gap-2">
                                  <div className="flex items-center justify-between gap-2">
                                      <div className="flex flex-col flex-1">
                                          <div className="flex items-center gap-1">
                                              <FaMapMarkerAlt size={12} className="text-primary-600"/>
                                              <span className="font-bold text-color-000 leading-snug">
                                                  {_.get(eRec, 'location.location_name')}
                                              </span>
                                          </div>
                                          <span className="ml-5 text-2xs text-color-400 italic leading-tight">
                                              {[
                                                  moment(_.get(eRec, 'eff_at')).format("DD/MM/YYYY"),
                                                  !!_.get(eRec, 'end_at')
                                                      ? moment(_.get(eRec, 'end_at')).format("DD/MM/YYYY")
                                                      : moment("31/12/2099", "DD/MM/YYYY").format("DD/MM/YYYY")
                                              ].join(" - ")}
                                          </span>
                                      </div>
                                      <div className="flex flex-col">
                                          {!!eRec.end_at && (
                                              <Popconfirm
                                                  title={
                                                      <div className="font-medium text-sm text-color-100 italic">
                                                          {t('are you sure to remove the expired')}
                                                      </div>
                                                  }
                                                  placement="left"
                                                  onConfirm={() => {
                                                      handleOnRemoveExpirePriceSetGroup(
                                                          {
                                                              product_price_group_id: eRec.product_price_group_id,
                                                              eff_at: effectDate
                                                          }
                                                      );
                                                  }}
                                                  okText={t('sure')}
                                                  cancelText={t('no')}
                                                  okButtonProps={{
                                                      type: 'primary',
                                                      danger: true,
                                                      className: 'button-rounded-md no-border text-xs font-medium px-3',
                                                  }}
                                                  cancelButtonProps={{
                                                      className: 'button-rounded-md no-border text-xs font-medium px-3',
                                                  }}>
                                                  <MiniButton
                                                      type="danger"
                                                      icon={<BsXCircle size={12}/>}
                                                      label={t('remove expired')}
                                                  />
                                              </Popconfirm>
                                          )}
                                          {!!eRec.eff_at && !!!eRec.end_at && (
                                              <Popconfirm
                                                  title={
                                                      <div className="font-medium text-sm text-color-100 italic">
                                                          {t('confirm to expire at') + effectDate.format("DD/MM/YYYY")}
                                                      </div>
                                                  }
                                                  placement="left"
                                                  onConfirm={() => {
                                                      handleOnExpirePriceSetGroup(
                                                          {
                                                              product_price_group_id: eRec.product_price_group_id,
                                                              eff_at: effectDate
                                                          }
                                                      );
                                                  }}
                                                  okText={t('sure')}
                                                  cancelText={t('no')}
                                                  okButtonProps={{
                                                      type: 'primary',
                                                      danger: true,
                                                      className: 'button-rounded-md no-border text-xs font-medium px-3',
                                                  }}
                                                  cancelButtonProps={{
                                                      className: 'button-rounded-md no-border text-xs font-medium px-3',
                                                  }}>
                                                  <MiniButton
                                                      type="danger"
                                                      icon={<BsStopCircle size={12}/>}
                                                      label={`${t('expire at')} ${effectDate.format("DD/MM/YYYY")}`}
                                                  />
                                              </Popconfirm>
                                          )}
                                      </div>
                                  </div>
                                  <div className="flex justify-end">
                                      <CopyPriceGroupInputComponent
                                          item={product}
                                          product_price_set_id={eRec.product_price_set_id}
                                          product_price_group_id={eRec.product_price_group_id}
                                          diff={values.price - Number(_.get(eRec, 'data.0.price', 0))}
                                          diff2={0}
                                          effectMomentDate={effectDate}
                                      />
                                  </div>
                                  <div className="flex flex-1 space-x-3 w-full">
                                      <InputNumber
                                          step="0.01"
                                          defaultValue={values.price}
                                          disabled={effectDate.diff(moment(eRec.max_eff_at), 'days') < 0}
                                          name="price"
                                          style={{width: '100%'}}
                                          formatter={formatterNumber}
                                          parser={parseNumber}
                                          onChange={value =>
                                              setValues({...values, price: value})
                                          }
                                      />

                                      <ProductPriceGroupCommentComponent
                                          productPriceGroup={eRec}
                                          effectMomentDate={moment(_.get(eRec, 'eff_at'))}>
                                          <Button
                                              className="button-rounded-md no-border flex items-center gap-2"
                                              icon={!!eRec.notes ? <IoChatboxEllipsesOutline/> : <IoChatboxEllipsesOutline/>}>
                                              {t('notes')}
                                          </Button>
                                      </ProductPriceGroupCommentComponent>

                                      <Button
                                          ghost
                                          type="primary"
                                          htmlType="submit"
                                          className="button-rounded-md font-medium"
                                          onClick={() => {
                                              const priceSetValue = {
                                                  product_id: product.id,
                                                  ...eByUnit,
                                                  unit_id: _.get(eByUnit, 'unit.id'),
                                                  unit2_id: null,
                                                  price_unit_id: _.get(eByUnit, 'price_unit.id'),
                                                  price_unit2_id: null,
                                                  country_id: _.get(eRec, 'location._type') === "country" ? _.get(eRec, 'location.id') : null,
                                                  province_id: _.get(eRec, 'location._type') !== "country" ? _.get(eRec, 'location.id') : null,
                                              }

                                              handleOnEditPrice(
                                                  {
                                                      ...priceSetValue,
                                                      eff_at: effectDate.format("YYYY-MM-DD"),
                                                      data: JSON.stringify([values])
                                                  }
                                              )

                                          }}>
                                          {t('edit & save')}
                                      </Button>
                                  </div>
                                  {!!_.get(eRec, 'notes') && (
                                      <span className="ml-2 text-xs text-color-400 italic leading-tight">
                                          * {_.get(eRec, 'notes')}
                                      </span>
                                  )}
                              </div>
                          )}
                      </UseState>
                  </div>
              ))}
          </div>
      ))}
    </div>
  )
}

export const SpotPriceInputFormMulti = ({organization, products}) => {
  const t = useTranslate();
  const [effectDate, setEffectDate] = useState(moment())

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  return (
    <div key={effectDate.format("DD/MM/YYYY")} className="p-3 space-y-6">
      <div className="px-3 py-2 flex items-center background border-2 border-primary-600 rounded-lg hover:shadow-items-md stickyTop z-3">
        <div className="flex-1 font-semibold text-color-100 italic">
          {t('effected date')}
        </div>
        <DatePicker
            style={{ minWidth: 200 }}
            className="rounded-md"
            suffixIcon={<BsCalendar2Week className="text-color-400"/>}
            allowClear={false}
            onChange={(value) => { setEffectDate(value) }}
            defaultValue={effectDate} format={dateFormatList}
        />
      </div>
      <div className="space-y-3">
        {products.map((item, i) => (
            <div key={i} className="flex flex-col">
                <Async
                    apiInfo={create_product_price_getById_Api()}
                    query={{':id': item.id, ':effect_date': effectDate.format("DD/MM/YYYY")}}
                />
                <SpotPriceInputByProduct item={item} effectDate={effectDate}/>
            </div>
        ))}
      </div>

    </div>
  )
}


