import { Modal as M } from 'antd'
import _ from 'lodash'
import React, { useEffect } from 'react'
import './Modal.css'
if (!window.listModal)
  window.listModal = []

export const RegAsModal = ({
  children = null
}) => {
  useEffect(() => {
    const modalId = _.uniqueId()
    let top
    let listModal = window.listModal
    window.requestAnimationFrame(() => {
      listModal.push(modalId)
      if (
        !listModal ||
        listModal.length === 1
      ) {
        const node = document.body
        if (node) {
          const rect = node.getBoundingClientRect()
          top = rect.top
          node.style.top = `${rect.top}px`
          node.style.position = 'fixed'
          node.style.width = '100%'
          window.scrollTo(0, 0)
        }
      }
    })
    return () => {
      let listModal = window.listModal
      window.listModal = listModal.filter(
        id => id !== modalId
      )
      if (_.isEmpty(window.listModal)) {
        const node = document.body
        if (node) {
          node.style.top = `0px`
          node.style.overflow = 'unset'
          node.style.position =
            'relative'
          window.scrollTo(0, 0 - top)
        }
      }
    }
  }, [])
  return children
}
const Modal = ({
  destroyOnClose = true,
  // maskClosable = true,
  onCancel,
  children,
  ...props
}) => {
  return (
    <M
        width={540}
        className="custom-modal"
        destroyOnClose={destroyOnClose}
        // maskClosable={maskClosable}
        onCancel={onCancel}
        {...props}>
      <RegAsModal>
        {children}
      </RegAsModal>
    </M>
  )
}

Modal.propTypes = M.propTypes
export default Modal
