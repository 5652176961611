import React from 'react'
import { useLocation } from 'react-router-dom'
const scrollToAnchor = (
  hash,
  e = document
) => {
  setTimeout(() => {
    const id = hash
      .replace('#', '')
      .trim()
    const element = document.getElementById(
      id
    )
    if (element)
      element.scrollIntoView()
  }, 0)
}
function ScrollToViewByHash({
  children
}) {
  const location = useLocation()
  React.useEffect(() => {
    if (location.hash)
      scrollToAnchor(location.hash)
  }, [location.hash])

  return null
}

export default ScrollToViewByHash
