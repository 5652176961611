import { API_ROOT_URL } from 'envs/_current/config';
import { chartSchema } from "./chart";
import { datasetSchema } from "./dataset";
import { channelSchema, feedSchema } from './schema';
import { eventSchema } from "./schema/event";
import { keywordSchema } from './schema/keyword';
import { createListTransform } from './utils/createListTransform';
import { createTransform } from './utils/createTransform';
//
export const topic_get_Api = {
  backend: `// Route::post('pins/{container}/{container_id}/mark/{type}/{id}', 'PinController@mark');`,
  root: API_ROOT_URL,
  path: '/keywords/keyword/:id/get',
  method: 'GET',
  schema: keywordSchema,
  transform: createTransform(
    keywordSchema,
    'data'
  )
}
// Route::get('feeds/topic/{id}/get', 'FeedController@fetchFeedByTopic');
export const feed_getFeedsByTopic_Api = {
  backend: `// Route::get('feeds/topic/{id}/get', 'FeedController@fetchFeedByTopic');`,
  root: API_ROOT_URL,
  path: '/feeds/topic/:id/get',
  method: 'GET',
  schema: feedSchema,
  transform: createListTransform(
    feedSchema
  )
}
// Route::get('feeds/topic/{id}/get-charts', 'FeedController@fetchChartsByTopic');
export const feed_getChartsByTopic_Api = {
  backend: `// Route::get('feeds/topic/{id}/get-charts', 'FeedController@fetchFeedByTopic');`,
  root: API_ROOT_URL,
  path: '/feeds/topic/:id/get-charts',
  method: 'GET',
  schema: feedSchema,
  transform: createListTransform(
    feedSchema
  )
}

// Route::get('keywords/last-searching-log/{type}/un-follow', 'KeywordController@unfollow');
export const keyword_unfollow_last_searching_Api = {
  backend: `// Route::get('keywords/last-searching-log/:type/un-follow', 'KeywordController@unfollow');`,
  root: API_ROOT_URL,
  path:
    '/keywords/last-searching-log/:type/un-follow',
  method: 'POST'
}

//     Route::get('events/topic/{topic}/get-events', 'EventController@getEventByTopic');
export const channel_getEventsByTopic_Api = {
  backend: `// Route::get('events/topic/{topic}/get-events', 'EventController@getEventByTopic');`,
  root: API_ROOT_URL,
  path: '/events/topic/:keyword/get-events',
  method: 'GET',
  schema: eventSchema,
  transform: createListTransform(
    eventSchema
  )
}
//     Route::get('channels/topic/{topic}/get-channels', 'ChannelController@getChannelByTopic');
export const channel_getChannelsByTopic_Api = {
  backend: `// Route::get('channels/topic/{topic}/get-channels', 'ChannelController@getChannelByTopic');`,
  root: API_ROOT_URL,
  path: '/channels/topic/:keyword/get-channels',
  method: 'GET',
  schema: channelSchema,
  transform: createListTransform(
    channelSchema
  )
}
//     Route::get('datasets/topic/{topic}/get-datasets', 'DatasetController@getDatasetByTopic');
export const dataset_getDatasetByTopic_Api = {
  backend: `// Route::get('datasets/topic/{topic}/get-datasets', 'DatasetController@getDatasetByTopic');`,
  root: API_ROOT_URL,
  path: '/datasets/topic/:keyword/get-datasets',
  method: 'GET',
  schema: datasetSchema,
  transform: createListTransform(
    datasetSchema
  )
}
//     Route::get('datasets/topic/{topic}/get-charts', 'DatasetController@getChartByTopic');
export const dataset_getChartsByTopic_Api = {
  backend: `// Route::get('datasets/topic/{topic}/get-charts', 'DatasetController@getChartByTopic');`,
  root: API_ROOT_URL,
  path: '/datasets/topic/:keyword/get-charts',
  method: 'GET',
  schema: chartSchema,
  transform: createListTransform(
    chartSchema
  )
}
