import { Avatar, List } from 'antd'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import moment from 'moment'
import React from 'react'
import { IoCreateOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import {BsUiRadios} from "react-icons/bs";

export const renderHomeFeed = (container, index) => {
  if (_.isEmpty(container)) {
    return null
  }

  const { owner, cover_photo, content: item } = container

  return (
    <Translate key={index}>
      {(t) => (
          <div className="flex items-center gap-2 md:gap-3">
            <Avatar
                src={cover_photo}
                shape={'square'}
                size={40}
                icon={<BsUiRadios className="text-color-500" />}
                className="flex items-center justify-center background-200 rounded-lg"
            />
            <div className="flex flex-col flex-1 truncate">
              <Link
                  to={getLinkToDetail(item)}
                  className="font-bold text-sm text-color-000 no-underline leading-tight truncate">
                {getTitle(item) || _.get(item, 'description')}
              </Link>
              <div className="flex items-baseline">
                <div className="flex items-baseline flex-1 gap-1 italic">
                  <span className="text-2xs text-color-400">
                    {t('by')}
                  </span>
                  <Link
                      to={getLinkToDetail(_.get(item, 'creator'))}
                      className="font-medium text-xs text-primary-600 flex-1 max-lines-1">
                    {getTitle(_.get(item, 'creator'))}
                  </Link>
                </div>
                <span className="text-2xs text-color-400 italic ml-3 whitespace-no-wrap">
                  {moment(_.get(item, 'created')).fromNow()}
                </span>
              </div>
            </div>
          </div>
      )}
    </Translate>
  )
}
