import { useEffect } from 'react'

const DidOnMount = ({ handler }) => {
  useEffect(() => {
    handler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}

export default DidOnMount
