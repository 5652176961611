import BulbFilled from "@ant-design/icons/lib/icons/BulbFilled";
import {Link} from "react-router-dom";
import React from "react";
import Translate from "../../modules/local/Translate";
import {MdPersonPin} from "react-icons/md";

const IndividualEmptyPlaceholder = ({}) => (
  <Translate>
    {t => (
      <div
        style={{height: '30rem'}}
        className="p-3 flex flex-center flex-col gap-6 overflow-hidden">
        <MdPersonPin size={100} style={{ color: '#e1e1e1' }}/>
        <div className="flex flex-col gap-1 p-6 border border-dashed border-primary-400 rounded-lg">
          {
            [
              'Can follow the latest news and events in the industry',
              'Explore dataset and charts'
            ].map((e, i) => (
              <div key={i} className="flex items-center gap-3">
                <BulbFilled size={30} className="text-yellow-500 px-3" />
                <span className="flex-1 text-color-300 py-1 italic">
                  {t(e)}
                </span>
              </div>
            ))
          }
        </div>
        <div className="px-8 text-center font-medium text-color-100 italic">
          {t('Login now to select and follow the content that you interested in')}
        </div>
        <Link
          to={'/login'}
          className="button-rounded-md no-border px-6 py-2 bg-primary hover:bg-primary-600 shadow no-underline">
          <span className="font-medium text-white">
            {t('Login now')}
          </span>
        </Link>
      </div>
    )}
  </Translate>
);

export default IndividualEmptyPlaceholder;