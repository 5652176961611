import { schema } from 'normalizr'
import { collectionSchema } from './collectionSchema'
export const collectionsSchema = new schema.Entity(
  'baseItems',
  {
    data: [collectionSchema]
  },
  {
    idAttribute: () => 'bookmarkByUser',
    processStrategy: e => {
      return e
    }
  }
)
