import UserProfile from 'views/User/UserProfile'
import UserNews from './holders/UserNews'

export const userRouteEntities = [
  {
    path: 'news',
    component: UserNews,
    breadcrumbName: (t) => t('news'),
  },
  {
    path: 'profile',
    component: UserProfile,
    breadcrumbName: (t) => t('profile'),
    isInvisible: ({ item }) => false,
  },
]
