import {Button, Modal, Select} from 'antd'
import {createAsyncAction} from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useState} from 'react'
import {create_product_copyPriceSet_Api} from "../../../../../apis";
import Toggle from "../../../../../components/Toggle";
import _ from "lodash"
import {MiniButton} from "../../../../AppLocals/Organization/OrganizationEntity";

export const CopyPriceGroupInputForm = ({item, product_price_set_id, product_price_group_id, effectMomentDate, diff = 0, diff2 = 0, toggle, isToggle}) => {
  const t = useTranslate();
  const [estEndDate, setEstEndDate] = useState(_.cloneDeep(effectMomentDate).add(1, 'days'));

  const [__, dispatch] = useDispatchAsyncActionWithNotify();

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  const handleOnCopySubmitLocation = ({product_price_set_id, product_price_group_id, diff = 0, diff2 = 0, eff_at, end_at}) => {
    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_copyPriceSet_Api(),
          query: {
            ':id': item.id,
            ':product_price_set_id': product_price_set_id
          },
          values: {
            product_price_group_id, diff, diff2, eff_at, end_at
          }
        }
      )
    );

    toggle();

  }

  return (
    <div key={estEndDate.format("DD/MM/YYYY")} className="p-3 flex flex-col space-y-3">

      <div className="p-2 flex items-center gap-2 border border-color-50 shadow-items-md rounded-md">
        <span className="flex-1 font-medium text-sm text-color-100 italic">
          {t('effected date')}
        </span>
        <span className="font-bold text-primary">
          {effectMomentDate.format("DD/MM/YYYY")}
        </span>
      </div>

      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-3">
          <span className="flex-1 text-sm text-color-400 italic">
            {`${t('This pricing information will expire in')} : `}
          </span>
          <Select
            className="flex-1"
            placeholder={t('period')}
            defaultValue="1D"
            options={[
                {value: '0U', label: t("no expired date")},
                {value: '1D', label: '1 ' + t("days")},
                {value: '7D', label: '7 ' + t("days")},
                {value: '1M', label: '1 ' + t("months")},
                {value: '1Q', label: '1 ' + t("quarters")},
                {value: '1Y', label: '1 ' + t("years")}
            ]}
            onChange={value => {
              const effectMomentEndDate = _.cloneDeep(effectMomentDate);
              switch (value) {
                case "0U":
                  setEstEndDate(moment("31/12/2099", "DD/MM/YYYY"));
                  break;
                case "1Y":
                  setEstEndDate(effectMomentEndDate.add(1, 'years'));
                  break;
                case "1Q":
                  setEstEndDate(effectMomentEndDate.add(3, 'months'));
                  break;
                case "1M":
                  setEstEndDate(effectMomentEndDate.add(1, 'months'));
                  break;
                case "7D":
                  setEstEndDate(effectMomentEndDate.add(7, 'days'));
                  break;
                case "1D":
                default:
                  setEstEndDate(effectMomentEndDate.add(1, 'days'));
              }
            }}>
          </Select>
        </div>
        <div className="flex items-center gap-3">
          <span className="flex-1 text-sm text-color-400 italic">
            {`${t('This pricing information will expire at')} : `}
          </span>
          <span className="font-bold text-color-000">
            {estEndDate.format("DD/MM/YYYY")}
          </span>
        </div>
      </div>
      <div className="text-center my-3">
        <Button
            type="primary"
            htmlType="submit"
            className="button-rounded-md no-border"
            onClick={() => {
              handleOnCopySubmitLocation({
                product_price_set_id,
                product_price_group_id,
                diff,
                diff2,
                eff_at: effectMomentDate.format("DD/MM/YYYY"),
                end_at: estEndDate.format("DD/MM/YYYY") === "31/12/2099" ? null : estEndDate.format("DD/MM/YYYY")
              })
            }}>
          {[
              t('create'),
              t('at date'),
              effectMomentDate.format("DD/MM/YYYY")
          ].join(" ")}
        </Button>
      </div>

    </div>
  )
}


export const CopyPriceGroupInputComponent = ({
  item, product_price_set_id, product_price_group_id, effectMomentDate, diff = 0, diff2 = 0,
  children
}) => {
  const t = useTranslate();

  return (
    <Toggle>
      {( isToggle, toggle ) => (
        <React.Fragment>
          {
            !!children ? (
                <div onClick={toggle}>
                  {children}
                </div>
              )
              : (
                <MiniButton
                    className="w-fit border border-primary"
                    type="primary"
                    label={
                      [
                        t('create'),
                        t('at date'),
                        effectMomentDate.format("DD/MM/YYYY")
                      ].join(" ")
                    }
                    onClick={toggle}
                />
              )
          }
          <Modal
            width={600}
            className="custom-modal"
            title={(
                <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                  {t('create')}
                </div>
            )}
            maskClosable={false}
            destroyOnClose
            onCancel={toggle}
            visible={isToggle}
            footer={null}>
            <CopyPriceGroupInputForm
              {...{item, product_price_set_id, product_price_group_id, effectMomentDate, diff, diff2}}
              {...{isToggle, toggle}}
            />
          </Modal>
        </React.Fragment>
      )}
    </Toggle>
  )
}


