import {API_ROOT_URL} from 'envs/_current/config'
import {createAsyncAction} from 'modules/asyncCache'
import {schema} from 'normalizr'
import {baseFieldTypes} from './base/baseFieldTypes'
import {channelSchema, userSchema} from './schema'
import {widgetSchema} from './schema/widget'
import {createListTransform} from './utils/createListTransform'
import {createTransform} from './utils/createTransform'

const user_getUserWithChannels_userSchema = new schema.Entity(
  'users',
  {
    channels: [channelSchema]
  },
  {
    idAttribute: 'username'
  }
)
const fields = [
  'avatar',
  'name',
  'cover_photo'
]

const fieldTypes = {
  avatar: baseFieldTypes.image,
  cover_photo: baseFieldTypes.image,
  name: baseFieldTypes.string
}
// Route::get('users/user/{username}/{props}/get-hosted', 'UserController@getPropsByHost');
export const user_getPropsByHost_Api = {
  backend: `// Route::get('users/user/{username}/{props}/get-hosted', 'UserController@getPropsByHost');`,
  root: API_ROOT_URL,
  path:
    '/users/user/:username/:props/get-hosted',
  method: 'GET',
  fields,
  fieldTypes,
  transform: createListTransform(
    userSchema
  )
}
export const create_user_getPropsByHost_Api_action = () => ({
  prefixStr = 'user_getPropsByHost_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@user_getPropsByHost_Api',
    query,
    values,
    apiInfo: user_getPropsByHost_Api,
    maxAge
  })

// Route::get('users/user/{username}/{props}/get-joined', 'UserController@getPropsByJoin');
// Route::get('users/user/{username}/{props}/get-following', 'UserController@getPropsByFollow');
export const user_getPropsByFollow_Api = {
  backend: `// Route::get('users/user/{username}/{props}/get-following', 'UserController@getPropsByFollow');`,
  root: API_ROOT_URL,
  path:
    '/users/user/:username/:props/get-following',
  method: 'GET',
  fields,
  fieldTypes,
  transform: createListTransform(
    userSchema
  )
}
export const create_user_getPropsByFollow_Api_action = () => ({
  prefixStr = 'user_getPropsByFollow_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@user_getPropsByFollow_Api',
    query,
    values,
    apiInfo: user_getPropsByFollow_Api,
    maxAge
  })

// Route::get('apps/get-suggested-data', 'AppController@fetchSuggestedInfo');
export const app_getSuggestedData_Api = {
  backend: `// Route::get('apps/get-suggested-data', 'AppController@fetchSuggestedInfo');`,
  root: API_ROOT_URL,
  path: '/apps/get-suggested-data',
  method: 'GET',
  schema: widgetSchema,
  transform: createListTransform(
    widgetSchema
  )
}
export const create_app_getSuggestedData_Api_action = () => ({
  prefixStr = 'app_getSuggestedData_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@app_getSuggestedData_Api',
    query,
    values,
    apiInfo: app_getSuggestedData_Api,
    maxAge
  })

// Route::get('users/user/{username}/get', 'UserController@getById');
export const user_getById_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/users/user/:username/get',
  method: 'GET',
  schema: user_getUserWithChannels_userSchema,
  transform: createTransform(
    user_getUserWithChannels_userSchema,
    'data'
  )
}
export const create_user_getById_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@user_getById_Api',
    query,
    values,
    apiInfo: user_getById_Api,
    maxAge
  })
// Route::get('users/user/{username}/user/get-info', 'UserController@getUsersByUser');
export const user_getFollowersByUser_Api = {
  name: 'user_getPropsByUser',
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/users/user/:username/user/get-info?filter=follower',
  method: 'GET',
  schema: userSchema,
  transform: createListTransform(
    userSchema
  )
}


export const user_lookupEmailGetInfo_Api = {
  name: 'user_lookupEmailGetInfo_Api',
  backend: `Route::get('auth/email/get-user-by-email', 'UserController@validateEmailAsUser');`,
  root: API_ROOT_URL,
  path: '/auth/email/get-user-by-email',
  method: 'GET',
  paramsFields: ['email'],
  schema: userSchema,
  transform: createTransform(userSchema, 'data'),
}