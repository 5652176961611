import {Avatar} from 'antd'
import {getType} from 'apis/model/base'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import WorkspaceContext from "../WorkspaceContext";
import {FaWarehouse} from "react-icons/fa6";

const WorkspaceBanner = ({Wrapper = 'div'}) => {
  const {organization} = useContext(WorkspaceContext)

  if (_.isEmpty(organization)) {
    return null
  }

  const avatar = _.get(organization, 'avatar')

  return (
    <Wrapper className="flex items-center container mx-auto mt-3 gap-3 px-4">
      <Avatar
        shape="square"
        size={40}
        src={avatar}
        className="object-cover w-full h-full rounded-lg background-200"
        icon={<FaWarehouse size={20} className="text-color-300"/>}
      />
      <Translate>
        {(t) => (
          <div className="flex flex-col">
            <Link
              to={getLinkToDetail(organization)}
              className="font-semibold text-color-org max-lines-1">
              {getTitle(organization)}
            </Link>
            <span className="italic font-medium text-color-400 text-xs leading-tight">
              {t(getType(organization))}
            </span>
          </div>
        )}
      </Translate>
    </Wrapper>
  )
}

export default WorkspaceBanner
