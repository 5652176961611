export const uSIdNames = {
  "US-AL": "Alabama", "US-AR": "Arkansas", "US-AZ": "Arizona", "US-CA": "California", "US-CO": "Colorado",
  "US-CT": "Connecticut", "US-DC": "District of Columbia", "US-DE": "Delaware", "US-FL": "Florida",
  "US-GA": "Georgia", "US-HI": "Hawaii", "US-IA": "Iowa", "US-ID": "Idaho", "US-IL": "Illinois", "US-IN": "Indiana",
  "US-KS": "Kansas", "US-KY": "Kentucky", "US-LA": "Louisiana", "US-MA": "Massachusetts", "US-MD": "Maryland",
  "US-ME": "Maine", "US-MI": "Michigan", "US-MN": "Minnesota", "US-MO": "Missouri", "US-MS": "Mississippi",
  "US-MT": "Montana", "US-NC": "North Carolina", "US-ND": "North Dakota", "US-NE": "Nebraska",
  "US-NH": "New Hampshire", "US-NJ": "New Jersey", "US-NM": "New Mexico", "US-NV": "Nevada", "US-NY": "New York",
  "US-OH": "Ohio", "US-OK": "Oklahoma", "US-OR": "Oregon", "US-PA": "Pennsylvania", "US-RI": "Rhode Island",
  "US-SC": "South Carolina", "US-SD": "South Dakota", "US-TN": "Tennessee", "US-TX": "Texas", "US-UT": "Utah",
  "US-VA": "Virginia", "US-VT": "Vermont", "US-WA": "Washington", "US-WI": "Wisconsin", "US-WV": "West Virginia",
  "US-WY": "Wyoming", "US-AK": "Alaska"
}

export const uSNameIds = {
  "Alabama": "US-AL", "Arkansas": "US-AR", "Arizona": "US-AZ", "California": "US-CA", "Colorado": "US-CO",
  "Connecticut": "US-CT", "District of Columbia": "US-DC", "Delaware": "US-DE", "Florida": "US-FL",
  "Georgia": "US-GA", "Hawaii": "US-HI", "Iowa": "US-IA", "Idaho": "US-ID", "Illinois": "US-IL", "Indiana": "US-IN",
  "Kansas": "US-KS", "Kentucky": "US-KY", "Louisiana": "US-LA", "Massachusetts": "US-MA", "Maryland": "US-MD",
  "Maine": "US-ME", "Michigan": "US-MI", "Minnesota": "US-MN", "Missouri": "US-MO", "Mississippi": "US-MS",
  "Montana": "US-MT", "North Carolina": "US-NC", "North Dakota": "US-ND", "Nebraska": "US-NE",
  "New Hampshire": "US-NH", "New Jersey": "US-NJ", "New Mexico": "US-NM", "Nevada": "US-NV", "New York": "US-NY",
  "Ohio": "US-OH", "Oklahoma": "US-OK", "Oregon": "US-OR", "Pennsylvania": "US-PA", "Rhode Island": "US-RI",
  "South Carolina": "US-SC", "South Dakota": "US-SD", "Tennessee": "US-TN", "Texas": "US-TX", "Utah": "US-UT",
  "Virginia": "US-VA", "Vermont": "US-VT", "Washington": "US-WA", "Wisconsin": "US-WI", "West Virginia": "US-WV",
  "Wyoming": "US-WY", "Alaska": "US-AK"
}

export const mapUSIDNames = (name) => {
  if (uSNameIds[name]) return {id: uSNameIds[name], name: name};

  return {id: name, name: uSIdNames[name] || name};
}