import {Button} from 'antd'
import classNames from 'classnames'
import {Formik} from 'formik'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useState} from 'react'
import {LayoutContext} from '../layouts/Default/LayoutContext'
import {renderIf, renderIfElse} from "../../views/Shared";

export const StepsForm = ({
                            children,
                            routes = [],
                            initialValues = {},
                            renderBottom,
                            onSubmit,
                            onCancel,
                            isLoading,
                            faded = true,
                            ...propsRest
                          }) => {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleOnBack = () => setCurrentIndex(Math.max(0, currentIndex - 1))
  const C = routes[currentIndex].component

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      {...propsRest}>
      {({handleChange, handleSubmit, values, ...rest}) => {
        const handleOnOk = () => {
          setCurrentIndex(Math.min(routes.length - 1, currentIndex + 1))
          if (currentIndex === routes.length - 1) {
            handleSubmit()
          }
        }
        return (
          <div className="flex flex-col gap-4">
            {children && children(currentIndex)}
            {routes[currentIndex].name && (
              <div className="text-center font-bold text-color-000 uppercase mb-3">
                {t(routes[currentIndex].name)}
              </div>
            )}
            <div
              key={currentIndex}
              className={classNames(faded && 'animated fadeIn faster')}>
              <C
                {...{
                  currentIndex,
                  values: values,
                  onChange: handleChange,
                  onNext: handleOnOk,
                  onBack: handleOnBack,
                  ...propsRest,
                  ...rest,
                }}
              />
            </div>
            {/*{renderIf(!isSm, <div className="flex-1" />)}*/}
            {renderBottom ? (
              renderBottom({
                currentIndex,
                values: values,
                onChange: handleChange,
                onNext: handleOnOk,
                onBack: handleOnBack,
                ...rest,
              })
            ) : (
              <div className="z-10 bottom-0 mt-3 md:mt-8 p-3 sticky flex gap-3 items-center justify-end background">
                {renderIfElse(
                  routes.length > 1,
                  <Button
                    className="button-rounded-lg no-border"
                    disabled={currentIndex === 0}
                    onClick={handleOnBack}>
                    {t('back')}
                  </Button>,
                  renderIf(
                    onCancel,
                    <Button
                      onClick={onCancel}
                      // size="small"
                      className="button-rounded-md no-border text-sm">
                      {t('close')}
                    </Button>
                  )
                )}
                <Button
                  type="primary"
                  loading={isLoading}
                  onClick={handleOnOk}
                  className="button-rounded-lg no-border"
                  disabled={!rest.isValid || !rest.dirty}>
                  {currentIndex === routes.length - 1 ? t('submit') : t('next')}
                </Button>
              </div>
            )}
          </div>
        )
      }}
    </Formik>
  )
}
