import React from "react";

export const Stock = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<g>
				<path
					fill="#f35244"
					d="M432 112V24h-88v32h32l-96 96-80-80L80 192l24 24 96-96 80 80L400 80v32z"
				></path>
				<path
					fill="#fca713"
					d="M40 424h80v32H40zM104 456h80v32h-80zM104 392h80v32h-80zM200 424h80v32h-80z"
				></path>
				<g fill="#f4c067">
					<path d="M184 456h80v32h-80zM184 392h80v32h-80zM120 424h80v32h-80zM120 360h80v32h-80zM24 456h80v32H24zM24 392h80v32H24z"></path>
				</g>
				<path fill="#80c326" d="M264 424h184v64H264z"></path>
				<path
					fill="#69a709"
					d="M426.565 424a136.155 136.155 0 01-110.037 56H264v8h184v-64z"
				></path>
				<path fill="#fca713" d="M336 424h32v64h-32z"></path>
				<path fill="#80c326" d="M296 360h184v64H296z"></path>
				<path
					fill="#69a709"
					d="M458.565 360a136.155 136.155 0 01-110.037 56H296v8h184v-64z"
				></path>
				<path fill="#fca713" d="M368 360h32v64h-32z"></path>
				<path fill="#80c326" d="M264 296h184v64H264z"></path>
				<path
					fill="#69a709"
					d="M426.565 296a136.155 136.155 0 01-110.037 56H264v8h184v-64z"
				></path>
				<path fill="#fca713" d="M336 296h32v64h-32z"></path>
				<path fill="#f5b142" d="M240 392h24v32h-24z"></path>
				<path fill="#ed8515" d="M240 424h24v32h-24z"></path>
				<path fill="#f5b142" d="M240 456h24v32h-24z"></path>
				<path fill="#ed8515" d="M160 456h24v32h-24z"></path>
				<path fill="#fca713" d="M176 424h24v32h-24z"></path>
				<path fill="#ed8515" d="M160 392h24v32h-24z"></path>
				<path fill="#f5b142" d="M80 456h24v32H80z"></path>
				<path fill="#ed8515" d="M96 424h24v32H96z"></path>
				<path fill="#f5b142" d="M80 392h24v32H80zM184 360h24v32h-24z"></path>
				<path
					fill="#4db7e5"
					d="M160 208h32v128h-32zM240 232h32v40h-32zM320 208h32v64h-32zM400 144h32v128h-32zM112 248H80v120l32-32z"
				></path>
			</g>
		</svg>
	);
}

export default Stock;
