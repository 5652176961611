import {Alert, Button, DatePicker} from "antd"
import {getId} from "apis/model/base"
import ApiInfoForm from "components/form/ApiInfoForm"
import Null from "components/NullComponent"
import {useFormikContext} from "formik"
import _ from "lodash"
import useTranslate from "modules/local/useTranslate"
import React, {useContext, useEffect, useRef, useState} from "react"
import ServerContext from "views/Server/ServerContext"
import {IoMdInformationCircleOutline} from "react-icons/io";
import {IoClose} from "react-icons/io5";

const RangePickerTypes = Object.freeze({
  DATE: 'date',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
})

const DownloadFormContent = ({
  downloadURL,
  delay = 3000,
  auto = false,
                               values = [],
  rangeType = RangePickerTypes.DATE,
  setValues = Null,
  onSuccess = Null,
  ...props
}) => {
  const dateFormat = 'yyyy-MM-DD'

  const t = useTranslate()

  const {
    isValid,
    isSubmitting,
    handleSubmit,
    setSubmitting,
  } = useFormikContext()

  const timer = useRef()

  useEffect(() => {
    if (!!downloadURL && auto) {
      setSubmitting(false)
      timer.current = setTimeout(() => {
        onSuccess({ downloadURL })
      }, delay)
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [downloadURL, auto])

  return (
    <div>
      <DatePicker.RangePicker
        picker={rangeType}
        onChange={([from, to]) => {
          const [
            eff_at,
            end_at
          ] = [
              from.format(dateFormat),
              to.format(dateFormat)
            ]
          setValues({
            eff_at,
            end_at
          })
        }}
        showTime={false} />

      {!!downloadURL && (
          <div className="my-6">
            <Alert
                type="info"
                // closable
                showIcon={false}
                banner
                message={
                  <div className="bg-blue-100 border border-blue-400 text-blue-700 p-3 rounded-md relative" role="alert">
                    <div className="flex flex-row items-center py-1">
                      <IoMdInformationCircleOutline className="text-blue-500 text-2xl mr-3"/>
                      <div>
                        <strong className="font-semibold text-lg">{t('data has been generate')} {(' !')}</strong>
                        <span className="block sm:inline">{t('please click the {download} button below to download to your device',  { download: <strong> {t('download')} </strong>})}</span>
                      </div>
                    </div>
                    <span className="absolute top-0 bottom-0 right-0 p-2">
                      <IoClose className="text-blue-500"/>
                    </span>
                  </div>
                } />
          </div>
      )}

      <div className="flex gap-4">
        <div className="flex-1" />
        {!!downloadURL ? (
          <Button
            className="font-bold w-24 no-underline"
            type="primary"
            size="large">
            <a
              target="_blank"
              href={downloadURL}
              rel="noopener noreferrer">
              {t('download')}
            </a>
          </Button>
        ) : (
          <Button
            className="font-bold w-24 no-underline"
            type="primary"
            size="large"
            disabled={!isValid}
            loading={!!isSubmitting}
            onClick={() => {
              handleSubmit()
            }}>
            {t('OK')}
          </Button>
        )}
      </div>
    </div>
  )
}

const ExportPriceForm = ({
                           asyncConfigs = {},
  ...props
}) => {

  const { item } = useContext(ServerContext)

  const id = getId(item)

  const { apiInfo, query } = asyncConfigs({ id })

  const [URL, setURL] = useState(null)

  return (
    <div className="flex flex-col space-y-3 p-3">
      <div className="max-w-5xl w-full mx-auto">
        <ApiInfoForm
          apiInfo={apiInfo}
          query={query}
          onSuccess={([, { response },]) => {
            const downloadURL = _.get(response, 'data.path')
            setURL(downloadURL)
          }}>
          {({ ...formProps }) => {
            return (
              <DownloadFormContent
                {...formProps}
                {...props}
                auto={false}
                delay={3000}
                downloadURL={URL} />
            )
          }}
        </ApiInfoForm>
      </div>
    </div>
  )
}

export default ExportPriceForm