import {Button} from 'antd'
import classNames from 'classnames'
import {useFormikContext} from 'formik'
import useTranslate from 'modules/local/useTranslate'
import React, {useEffect} from 'react'
import {renderElse} from 'views/Shared'

function FormActionBar({
                         cancelSubmit,
                         alwaysVisible = true,
                         submitColor,
                         submitText = 'save',
                         resetText = 'reset',
                         actions,
                         className,
                         children,
                       }) {
  const t = useTranslate()
  const {
    touched,
    isValid,
    isSubmitting,
    handleSubmit,
    dirty,
    resetForm,
    initialValues,
    setSubmitting,
    setFieldValue
  } = useFormikContext()
  const show = dirty && touched
  const submit = () => {
    handleSubmit()
    setSubmitting(false)
  }
  useEffect(() => {
    if (isSubmitting && cancelSubmit) {
      setSubmitting(false)
    }
  }, [cancelSubmit, isSubmitting])
  if (children)
    return children(
      <div
        style={{
          borderRadius: '0.3em',
        }}
        className={classNames(
          'flex   pointer-events-auto background-100 justify-end p-2  ',
          className,
          !alwaysVisible && {
            'fadeInUp faster opacity-0 pointer-events-auto animated': show,
            fadeOutDown: !show,
          }
        )}>
        {actions ? (
          actions
        ) : (
          <>
            <span
              className="mr-3 font-bold hover:underline cursor-pointer"
              onClick={
                isSubmitting
                  ? undefined
                  : () => {
                    resetForm({
                      values: initialValues,
                    })
                  }
              }>
              {t(resetText)}
            </span>
            <Button
              color={submitColor}
              disabled={!isValid}
              loading={isSubmitting}
              onClick={submit}
              type="primary"
              className="font-semibold button-rounded-lg">
              {t(submitText)}
            </Button>
          </>
        )}
      </div>
    )
  return (
    <div
      style={{zIndex: 11}}
      className={classNames(
        'py-2 sticky background bottom-0 pointer-events-none',
        className
      )}>
      <div
        style={{
          borderRadius: '0.3em',
        }}
        className={classNames(
          'flex items-center pointer-events-auto justify-end p-2 background',
          !alwaysVisible && {
            'opacity-0 faster fadeInUp  animated': alwaysVisible || show,
            fadeOutDown: !(alwaysVisible || show),
          }
        )}>
        {actions ? (
          actions
        ) : (
          <>
            <span
              className="mr-3 font-bold hover:underline cursor-pointer"
              onClick={
                isSubmitting
                  ? undefined
                  : () => {
                    resetForm({
                      values: initialValues,
                    })
                    setTimeout(() => {
                      setFieldValue('resetKey', Date.now())
                    }, 300)
                  }
              }>
              {t(resetText)}
            </span>
            <Button
              color={submitColor}
              disabled={!isValid || !dirty}
              loading={isSubmitting}
              onClick={submit}
              type="primary"
              className="font-semibold button-rounded-lg">
              {t(submitText)}
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export const ResetButton = ({
                              style,
                              className,
                              label = 'reset',
                              disabled = false,
                              ...props
                            }) => {
  const t = useTranslate()
  const {resetForm, isSubmitting, setFieldValue} = useFormikContext()
  return (
    <span
      style={style}
      className={classNames(
        'mr-3 font-bold hover:underline',
        !disabled && 'cursor-pointer',
        className
      )}
      onClick={
        disabled || isSubmitting
          ? undefined
          : () => {
            resetForm()
            setTimeout(() => {
              setFieldValue('resetKey', Date.now())
            }, 300)
          }
      }
      {...props}>
      {t(label)}
    </span>
  )
}

export const SubmitButton = ({
                               label = 'submit',
                               dirtyRequired = true,
                               ...props
                             }) => {
  const t = useTranslate()
  const {dirty, isValid, isSubmitting, handleSubmit, setSubmitting} =
    useFormikContext()
  const submit = () => {
    if (props.loading) {
      return
    }
    handleSubmit()
    setSubmitting(false)
  }
  const is_modified = dirtyRequired ? dirty : true
  return (
    <Button
      type="primary"
      className="button-rounded-lg"
      onClick={submit}
      loading={isSubmitting}
      disabled={!isValid || !is_modified}
      {...props}>
      {t(label)}
    </Button>
  )
}

export const FormActionGroups = ({
                                   style,
                                   className,
                                   isSubmitting,
                                   resetProps = () => ({}),
                                   submitProps = () => ({}),
                                 }) => {
  const {hidden: hide_reset = false, ...reset_props} = resetProps ?? {}
  const {hidden: hide_submit = false, ...submit_props} = submitProps ?? {}
  return (
    <div
      style={style}
      className={classNames(
        'flex items-center justify-end p-2 background',
        className
      )}>
      {renderElse(
        hide_reset,
        <ResetButton
          disabled={isSubmitting}
          {...reset_props}
        />
      )}
      {renderElse(
        hide_submit,
        <SubmitButton
          loading={isSubmitting}
          {...submit_props}
        />
      )}
    </div>
  )
}

export default FormActionBar
