import { Avatar, Layout } from 'antd'
import { userModel } from 'apis/model'
import Count from 'components/Feed/Count'
import { LoginContext } from 'components/LoginContext'
import ShadowScrollbar from 'components/ShadowScrollbar'
import Toggle from 'components/Toggle'
import { UseHook } from 'components/UseReducer'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import useOnLocationChange from 'modules/navigation/useOnLocationChange'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useContext, useRef } from 'react'
import { MdClose } from 'react-icons/md'
import { useClickAway } from 'react-use'
import { staticPaths } from 'routes/staticPaths'
import UserCtrl from 'views/NewFeed/UserCtrl.lazy'
import { LayoutContext } from './LayoutContext'
import {BsPerson} from "react-icons/bs";
import {RiUser2Fill, RiUser3Line} from "react-icons/ri";

const User = () => {
  const t = useTranslate()
  const login = useContext(LoginContext)
  const history = useHistory()
  if (!login)
    return (
        <Avatar
            onClick={() => history.push(staticPaths.login)}
            size={50}
            icon={<RiUser2Fill className="text-color-400" />}
            className="background-200 hover:border-primary border border-color-50"
        />
    )

  return (
      <Toggle>
          {(istoggle, toggle) => {
              return (
                  <>
                      <Avatar
                          onClick={() => toggle(true)}
                          size={50}
                          className={
                              !istoggle
                                  ? 'background-200 hover:border-red-500 border border-color-50'
                                  : 'background-200 hover:border-red-500 border border-color-50 pointer-events-none'
                          }
                          src={userModel.getFullAvatarUrl(login)}>
                          <div className="font-bold text-xl text-color-300 uppercase">
                              {_.first(userModel.getTitle(login))}
                          </div>
                      </Avatar>
                      {istoggle && (
                          <UseHook hook={useRef}>
                              {(ref) => (
                                  <>
                                      <UseHook
                                          hook={useOnLocationChange}
                                          deps={[toggle]} />
                                      <div
                                          style={{
                                              left: 60,
                                          }}
                                          className="fixed top-0 left-0 w-full h-full bg-gray-600 opacity-75" />
                                      <div
                                          ref={ref}
                                          style={{
                                              width: 300,
                                              position: 'absolute',
                                              height: '100vh',
                                              bottom: 0,
                                              left: `60px`,
                                          }}
                                          className="verticalList__small background border-r border-gray-300">
                                          <ShadowScrollbar>
                                              <UserCtrl
                                                  useClickAway={
                                                      <UseHook
                                                          hook={useClickAway}
                                                          deps={[ref, toggle]} />
                                                  }
                                              />
                                          </ShadowScrollbar>
                                          <Count
                                              onClick={() => toggle()}
                                              className="absolute top-0 right-0 mt-3 z-10"
                                              component={MdClose} />
                                      </div>
                                  </>
                              )}
                          </UseHook>
                      )}
                  </>
              )
          }}
      </Toggle>
  )
}
const { Sider } = Layout
export const LeftSider = () => {
  return (
    <LayoutContext.Consumer>
      {({ leftSideBarWidth, collapsedMenuWidth, leftInner }) => {
        return (
          <Sider
            className="z-50 background"
            theme="light"
            collapsed={true}
            collapsible
            trigger={null}
            width={leftSideBarWidth}
            collapsedWidth={collapsedMenuWidth}>
            <div
              className="flex flex-col justify-between border-r border-color-50"
              style={{
                width: 60,
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100vh',
              }}>
              <div className="flex-1 flex">{leftInner}</div>
              <div
                className="border-color-50 border-t mt-1 flex items-center p-1 flex justify-center cursor-pointer"
                style={{
                  width: 60,
                  height: 60,
                }}>
                <User />
              </div>
            </div>
          </Sider>
        )
      }}
    </LayoutContext.Consumer>
  )
}
