import queryString from 'query-string'
import {useMemo} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {emptyObject} from "../helpers/emptyObjects";

const useQueryString = ({
  pathname,
  method
} = {}) => {
  const history = useHistory()
  const location = useLocation()
  return [
    useMemo(
      () =>
        queryString.parse(
          location.search,
          { arrayFormat: 'comma' }
        ) || emptyObject,
      [location.search]
    ),
    (values = {}) => {
      method
        ? method({
            pathname,
            search: queryString.stringify(
              {
                ...values,
                eventId: Date.now()
              },
              { arrayFormat: 'comma' }
            )
          })
        : history.push({
            pathname,
            search: queryString.stringify(
              {
                ...values,
                eventId: Date.now()
              },
              { arrayFormat: 'comma' }
            )
          })
    }
  ]
}

export default useQueryString
