import { Radio } from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React, { useCallback } from 'react'
import { Null } from 'views/Shared'
import { participant_status_options } from '..'

const EventParticipantFilter = ({
  owner_id,
  owner_type,
  value: filter = {
    status: undefined,
  },
  onChange: setValues = Null,
}) => {
  const t = useTranslate()

  const handleChange = useCallback(
    (name, value) => {
      setValues({
        ...filter,
        [name]: value,
      })
    },
    [filter]
  )

  const options = [
    participant_status_options.pending,
    participant_status_options.approved,
    participant_status_options.rejected,
  ]

  return (
    <div className="flex flex-col md:flex-row items-start md:items-center gap-2 mt-2">
        <div className="flex-1 font-medium text-sm text-color-300 italic">
            {`${t('status')} : `}
        </div>
        <Radio.Group
            name="status"
            buttonStyle="solid"
            className="flex items-center custom-radio-button"
            onChange={(event) => {
                const { name, value } = event.target
                handleChange(name, value)
            }}
            defaultValue={filter.status || undefined}>
            <Radio.Button value={undefined}>{t('all')}</Radio.Button>
            {options.map(({ value, label }) => (
                <Radio.Button
                    key={value}
                    value={value}>
                    {t(label)}
                </Radio.Button>
            ))}
        </Radio.Group>
    </div>
  )
}

export default EventParticipantFilter
