import {Button, DatePicker, Form} from 'antd'
import moment from 'moment'
import React from 'react'
import useTranslate from "../../../../../../modules/local/useTranslate";
import {BsCalendar2Week} from "react-icons/bs";

export const PriceSetGroupEffectDateUpdateForm = ({
    productPriceGroup,
    toggle,
    isToggle,
    handleOnEditSubmitPriceSetGroup
}) => {

  const t = useTranslate();

  return (
    <div className="w-full p-3">
      <Form
          initialValues={{
              eff_at: moment(productPriceGroup.eff_at)
          }}
          onFinish={(values) => {
              handleOnEditSubmitPriceSetGroup({
                  product_price_group_id: productPriceGroup.product_price_group_id,
                  eff_at: values.eff_at
              })
              toggle()
          }}>
          <div className="flex flex-col items-center gap-3">
              <div className="font-medium text-color-100 italic">
                  {t('The effective date of the current price set will be adjusted to the date below')}
              </div>
              <Form.Item name="eff_at">
                  <DatePicker
                      style={{ minWidth: 180 }}
                      className="rounded-md"
                      format={['DD/MM/YYYY']}
                      suffixIcon={ <BsCalendar2Week className="text-color-400" /> }
                  />
              </Form.Item>
              <Button
                  className="button-rounded-md no-border"
                  type="primary"
                  htmlType="submit">
                  {t('save')}
              </Button>
          </div>
      </Form>
    </div>
  )
}
