import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../../LoadingPage";

const OverviewLazy = Loadable({
  loader: () => import('./Overview'),
  loading: LoadingPage
})

const Overview = (props) => (
  <OverviewLazy {...props} />
)

export default Overview
