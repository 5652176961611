import {Button, DatePicker, Form, InputNumber, Select} from 'antd'
import React from 'react'
import useTranslate from "../../../../../../modules/local/useTranslate";
import _ from "lodash";
import {ProductPriceSetInfo} from "../ProductPriceSetInfo";
import {formatterNumber} from "../../../../../../helpers/formatterNumber";
import {useAppConfig} from "../../../../../../modules/local";
import {parseNumber} from "../../../../../../helpers/parseNumber";
import {BsCalendar2Week} from "react-icons/bs";

export const PriceSetCopyToNewLocationForm = ({
    effectMomentDate,
    productPriceSet,
    productPriceGroup,
    toggle,
    isToggle,
    handleOnCopySubmitLocation
}) => {

  const t = useTranslate();
  const {
    countries = [],
    provinces = [],
  } = useAppConfig();

  return (
    <div className="w-full flex flex-col gap-3 p-3">
        <div className="text-center font-medium text-color-100 italic">
            {t('Copy the current priceset for another location with the same priceset')}
        </div>
        <ProductPriceSetInfo productPriceSet={productPriceSet}/>
        <Form className="space-y-3"
            initialValues={{
                country_id: _.get(productPriceGroup, 'location._type', "country") === "country"
                    ? _.get(productPriceGroup, 'location.id', 237) : 237,
                province_id: _.get(productPriceGroup, 'location._type', "country") !== "country"
                    ? _.get(productPriceGroup, 'location.id') : null,
                diff: 0,
                diff2: 0,
                eff_at: effectMomentDate
            }}
            onFinish={(values) => {
                handleOnCopySubmitLocation(
                    {
                        product_price_set_id: productPriceGroup.product_price_set_id,
                        product_price_group_id: productPriceGroup.product_price_group_id,
                        diff: values.diff,
                        diff2: values.diff2,
                        country_id: values.country_id,
                        province_id: values.province_id,
                        eff_at: values.eff_at
                    }
                );

                toggle();
            }}
            {...{
                labelCol: {span: 6},
                wrapperCol: {span: 18},
            }} >
            {
                (values) => {
                    return (
                        <React.Fragment>
                            <Form.Item
                                label={
                                    <span className="text-sm text-color-400 italic">{t('date')}</span>
                                }
                                className="flex-1"
                                name="eff_at">
                                <DatePicker
                                    className="rounded-md"
                                    format={['DD/MM/YYYY']}
                                    suffixIcon={<BsCalendar2Week className="text-color-400"/>}
                                />

                            </Form.Item>

                            <Form.Item
                                label={
                                    <span className="text-sm text-color-400 italic">{t('country')}</span>
                                }
                                className="flex-1"
                                name="country_id">
                                <Select
                                    showSearch
                                    allowClear={true}
                                    virtual={false}
                                    optionFilterProp="label"
                                    placeholder={t(
                                        'please select'
                                    )}
                                    style={{
                                        width: '100%'
                                    }}>
                                    {!_.isEmpty(countries) &&
                                    countries.map(
                                        item => {
                                            const {
                                                id,
                                                location_name: name
                                            } = item
                                            return (
                                                <Select.Option
                                                    key={id}
                                                    value={id}
                                                    label={name}>
                                                    {name}
                                                </Select.Option>
                                            )
                                        }
                                    )}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                key={"province_id_by_" + values.country_id}
                                id={"province_id_by_" + values.country_id}
                                label={
                                    <span className="text-sm text-color-400 italic">{t('province')}</span>
                                }
                                name="province_id">
                                <Select
                                    showSearch
                                    allowClear={true}
                                    virtual={false}
                                    optionFilterProp="label"
                                    placeholder={t(
                                        'please select'
                                    )}
                                    style={{
                                        width: '100%'
                                    }}>
                                    {!_.isEmpty(provinces) &&
                                    provinces.filter(e => e.country_id === values.country_id).map(
                                        item => {
                                            const {
                                                id,
                                                location_name: name
                                            } = item
                                            return (
                                                <Select.Option
                                                    key={id}
                                                    value={id}
                                                    label={name}>
                                                    {name}
                                                </Select.Option>
                                            )
                                        }
                                    )}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={
                                    <div className="flex flex-col items-end italic">
                                        <span className="text-sm text-color-400 leading-tight">{t('added value')}</span>
                                        <span className="text-2xs text-color-500 leading-tight">
                                            ({_.get(productPriceSet, 'price_unit.name')}/{_.get(productPriceSet, 'unit.name')})
                                        </span>
                                    </div>
                                }
                                className="flex-1"
                                name={"diff"}>
                                <InputNumber
                                    name={"diff"}
                                    style={{width: '100%'}}
                                    formatter={formatterNumber}
                                    parser={parseNumber}
                                />
                            </Form.Item>

                            {productPriceSet.contract_type_cd !== 'spot' && !!_.get(productPriceSet, 'unit2') && (
                                <Form.Item
                                    label={
                                        <div className="flex flex-col items-end italic">
                                            <span className="font-medium text-sm text-color-400 leading-tight">{t('added value')} (2)</span>
                                            <span className="text-2xs text-color-500 leading-tight">
                                                ({_.get(productPriceSet, 'price_unit2.name')}/{_.get(productPriceSet, 'unit2.name')})
                                            </span>
                                        </div>
                                    }
                                    className="flex-1"
                                    name={"diff2"}>
                                    <InputNumber
                                        name={"diff2"}
                                        style={{width: '100%'}}
                                        formatter={formatterNumber}
                                        parser={parseNumber}
                                    />
                                </Form.Item>
                            )}

                            <div className="text-center my-3">
                                <Button
                                    className="button-rounded-md no-border"
                                    type="primary"
                                    htmlType="submit">
                                    {t('submit')}
                                </Button>
                            </div>
                        </React.Fragment>
                    )
                }
            }
        </Form>
    </div>
  )
}
