import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../../../LoadingPage";

const ContactBooksLazy = Loadable({
  loader: () => import('./ContactBooks'),
  loading: LoadingPage
})

const ContactBooks = (props) => (
  <ContactBooksLazy {...props} />
)

export default ContactBooks
