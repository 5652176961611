import { compose } from 'recompose'
import createModuleLayout from '../factory/createModuleLayout'

const OrganizationLayout = ({
  children,
  ...props
}) =>
  createModuleLayout(props)(children)

export default compose()(
  // notFoundOnError(
  //   OrganizationContainerContext.Consumer,
  // )
  OrganizationLayout
)
