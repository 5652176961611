import {Editor, Element as SlateElement, Path, Range, Transforms} from 'slate'
import {ReactEditor} from 'slate-react'
import {ElementTypes} from '../types'
import {createParagraphNode} from './paragraph'

export function removeLink(editor) {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      n.type === ElementTypes.LINK,
  })
}

export function insertLink(editor, url) {
  if (!url) return

  const {selection} = editor ?? {}
  const node = {
    href: url,
    type: ElementTypes.LINK,
    children: [{text: url}],
  }

  ReactEditor.focus(editor)

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path
    )

    // Remove the Link node if we're inserting a new link node inside of another
    // link.
    if (parentNode.type === ElementTypes.LINK) {
      removeLink(editor)
    }

    if (editor.isVoid(parentNode)) {
      // Insert the new link after the void node
      Transforms.insertNodes(editor, createParagraphNode([node]), {
        at: Path.next(parentPath),
        select: true,
      })
    } else if (Range.isCollapsed(selection)) {
      // Insert the new link in our last known location
      Transforms.insertNodes(editor, node, {select: true})
    } else {
      // Wrap the currently selected range of text into a Link
      Transforms.wrapNodes(editor, node, {split: true})
      // Remove the highlight and move the cursor to the end of the highlight
      Transforms.collapse(editor, {edge: 'end'})
    }
  } else {
    // // Insert the new node at the bottom of the Editor when selection
    // // is falsey
    // Transforms.insertNodes(editor, createParagraphNode([node]))
  }
}
