import React from 'react'
import {Avatar, Modal} from "antd";
import Iframe from "react-iframe";
import Toggle from "../../../components/Toggle";
import {WiCloudyWindy, WiDayCloudy, WiDayRain} from "react-icons/wi";
import Translate from "../../../modules/local/Translate";

const Icon = ({type}) => {
  switch (type) {
    case "temperature":
      return <WiDayCloudy style={{width: '3rem', fill: '#aa3c00'}}/>
    case "rain":
      return <WiDayRain style={{width: '3rem', fill: '#0147aa'}}/>
    case "wind":
      return <WiCloudyWindy style={{width: '3rem', fill: '#aa9500'}}/>
    default:
      return <WiDayCloudy style={{width: '3rem', fill: 'var(--text-color-300)'}}/>
  }
}

export const renderWeatherItem = e => {

  if (!e) return null;

  return (
    <div
      key={e.id}
      className="cursor-pointer p-1 flex">
      <Toggle>
        {(isToggle, toggle) => {
            return (
                <Translate>
                  {(t) => (
                      <React.Fragment>
                        <div className="flex flex-col flex-center gap-3">
                          <Avatar
                              size={40}
                              className="flex flex-center background-100 hover:background-200 shadow-lg"
                              icon={ <Icon type={e.category_type}/> }
                              onClick={() => toggle()}
                          />
                          <span className="flex justify-center text-2xs text-color-200 truncate">
                    {e.title}
                  </span>
                        </div>
                        <Modal
                            title={
                              <div className="text-center font-bold text-sm text-color-100 uppercase tracking-wide">
                                {e.title}
                              </div>
                            }
                            maskClosable
                            className="custom-modal space-y-10"
                            destroyOnClose
                            width={720}
                            visible={isToggle}
                            onCancel={toggle}
                            onOk={toggle}
                            cancelText={t('close')}
                            cancelButtonProps={{
                              style: { background: "transparent" },
                              className: 'button-rounded-lg no-shadow no-border',
                            }}
                            okButtonProps={{hidden: true}}>
                          <Iframe
                              url={e.frame_url}
                              width={e.frame_width}
                              height={e.frame_height}
                              id={e.id}
                              className="myClassname"
                              display="initial"
                              position="relative"
                              frameborder="0"
                              sandbox=''/>
                        </Modal>
                      </React.Fragment>
                  )}
                </Translate>
            )
        }}
      </Toggle>
    </div>
  )
}
