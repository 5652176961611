export const ElementTypes = Object.freeze({
  LINK: 'link',
  VIDEO: 'video',
  IMAGE: 'image',
  TABLE: 'table',
  MENTION: 'mention',
  MENTION_ITEM: 'mention-item',
  SENTENCE: 'sentence',
  PARAGRAPH: 'paragraph',
  LIST_ITEM: 'list-item',
  TABLE_ROW: 'table-row',
  TABLE_CELL: 'table-cell',
  BLOCK_QUOTE: 'block-quote',
  HEADING_ONE: 'heading-one',
  HEADING_TWO: 'heading-two',
  TABLE_HEADER: 'table-header',
  HEADING_THREE: 'heading-three',
  ORDERED_LIST: 'ordered-list',
  IMAGE_WRAPPER: 'image-wrapper',
  UNORDERED_LIST: 'unordered-list',
})

export const NodeInlineTypes = Object.freeze({
  CODE: 'code',
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINED: 'underlined',
})

export const NodeBlockTypes = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify',
  BLOCK_QUOTE: 'block-quote',
  HEADING_ONE: 'heading-one',
  HEADING_TWO: 'heading-two',
  HEADING_THREE: 'heading-three',
  ORDERED_LIST: 'ordered-list',
  UNORDERED_LIST: 'unordered-list',
})

export const TextAlignTypes = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
  JUSTIFY: 'justify',
})

export const ImageAlignTypes = Object.freeze({
  LEFT: 'left',
  RIGHT: 'right',
  NONE: 'none',
})
