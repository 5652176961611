import {Col, Divider, Skeleton, Steps, Tag} from 'antd'
import {auth_fetchWorkingProfiles_Api} from 'apis'
import EmptyHolder from 'components/EmptyHolder'
import _ from 'lodash'
import useAsyncWithCache from 'modules/asyncCache/useAsyncWithCache'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, {useCallback, useContext, useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useToggle} from 'react-use'
import {compose, mapProps, withProps} from 'recompose'
import {withCountries, withProvinces,} from 'views/Account/components/ProfileOptionSelect'
import {fromObject, Null, renderOwnChild} from 'views/Shared'
import {LoginContext} from '../../components/LoginContext'
import UserContext from '../AppLocals/User/UserContext'
import ProfileQuickView from './components/ProfileQuickView'
import {ItemInformation} from "../AppLocals/Organization/OrganizationEntity";
import {renderIf} from "../Shared";
import getLabelOfGender from "../../helpers/getLabelOfGender";
import {ListHeaderWithLine} from "../AppLocals/Templates/items/ListHeader";

const DefaultComponent = ({value}) => (
  <p className="text-sm font-medium text-color">{value}</p>
)

const DefaultComponentWrapper = ({children, title}) => {
  const t = useTranslate()
  return (
    <Col
      span={12}
      className="w-full">
      <div className="flex items-center">
        <div className="text-xs uppercase text-color-400 mb-1">{t(title)}</div>
      </div>
      {children}
    </Col>
  )
}

export const profileFields = [
  'privacy',
  'company',
  'end_at',
  'start_at',
  'position',
  'on_working',
  'description',
  'working_address',
  'working_country_id',
  'working_province_id',
]

const formSchema = [
  {
    title: 'position',
    name: 'position',
    component: compose(
      withProps(({value}) => ({
        value,
      }))
    )(DefaultComponent),
  },
  {
    title: 'on working',
    name: 'on_working',
    component: compose(
      withTranslate,
      mapProps(({value, translate}) => ({
        color: value === 1 ? 'processing' : 'default',
        children: translate(!!value ? 'yes' : 'no'),
      }))
    )(Tag),
  },
  {
    title: 'address',
    name: 'working_address',
    component: compose(
      withProps(({value}) => ({
        value,
      }))
    )(DefaultComponent),
  },
  {
    title: 'province',
    name: 'working_province_id',
    component: compose(
      withProvinces,
      mapProps(({value, getProvince = Null}) => {
        const [, currentProvince] = getProvince(value)
        return {
          value: currentProvince,
        }
      })
    )(DefaultComponent),
  },
  // {
  //   title: 'privacy',
  //   name: 'privacy',
  //   component: compose(
  //     withTranslate,
  //     mapProps(({ value, translate }) => {
  //       const current = baseFieldTypes.privacy.find((e) => e.value == value)
  //       return {
  //         value: translate(!!current ? current.label : 'undefined'),
  //       }
  //     })
  //   )(DefaultComponent),
  // },
  // {
  //   title: 'start at',
  //   name: 'start_at',
  //   component: compose(
  //     withTranslate,
  //     mapProps(({ value, translate }) => ({
  //       value: !!value ? moment(value).format('YYYY-MM-DD') : translate('undefined'),
  //     }))
  //   )(DefaultComponent),
  // },
  // {
  //   title: 'end at',
  //   name: 'end_at',
  //   component: compose(
  //     withTranslate,
  //     mapProps(({ value, translate }) => ({
  //       value: !!value ? moment(value).format('YYYY-MM-DD') : translate('undefined'),
  //     }))
  //   )(DefaultComponent),
  // },
  {
    title: 'country',
    name: 'working_country_id',
    component: compose(
      withCountries,
      mapProps(({value, getCountry = Null}) => {
        const [, currentCountry] = getCountry(value)
        return {
          value: currentCountry,
        }
      })
    )(DefaultComponent),
  },
  {
    title: 'description',
    name: 'description',
    component: compose(
      withProps(({value}) => ({
        value,
      }))
    )(DefaultComponent),
  },
]

const UserProfileItem = ({Wrapper = renderOwnChild, item}) => {
  const t = useTranslate()
  const [isToggle, toggle] = useToggle()

  const renderItem = useCallback(
    (
      {name, title, defaultValue, component: Component = DefaultComponent},
      index
    ) => {
      const values = fromObject(
        item,
        profileFields.map((e) => ({name: e}))
      )
      return (
        <DefaultComponentWrapper
          key={index}
          title={title}>
          <Component
            {...{
              name,
              title,
              values,
            }}
            value={_.get(values, name, defaultValue)}
          />
        </DefaultComponentWrapper>
      )
    },
    [item, profileFields]
  )

  const data = useMemo(() => {
    const end = isToggle ? formSchema.length : 4
    return formSchema.slice(0, end).map(renderItem)
  }, [isToggle, formSchema])

  if (_.isEmpty(item)) return null
  return (
    <Wrapper>
      {data}
      <div className="w-full mt-2">
        <Divider style={{margin: 0}}>
          <span
            onClick={toggle}
            className="text-xs text-color-300 font-light italic cursor-pointer">
            {t(isToggle ? 'see less' : 'see more')}
          </span>
        </Divider>
      </div>
    </Wrapper>
  )
}

const statusOptions = [
  {wait: 'wait'},
  {process: 'process'},
  {finish: 'finish'},
  {error: 'error'},
]

const UserProfile = () => {
  const t = useTranslate()

  const history = useHistory()

  const login = useContext(LoginContext)

  const {user} = useContext(UserContext)

  const isOwner = !!_.get(login, 'edit')

  const [currentStep, setStep] = useState(0)

  const apiInfo = auth_fetchWorkingProfiles_Api

  const cacheId = [apiInfo.path].join('/')

  const {response, isLoading = false} = useAsyncWithCache({
    cacheId,
    apiInfo,
  })

  const profiles = _.get(response, 'data.data', [])

  const onChange = (value) => {
    setStep(value)
  }

  const steps = useMemo(() => {
    return profiles.map((item, index) => {
      return (
        <Steps.Step
          key={index}
          description={
            <ProfileQuickView
              item={item}
              compact={true}
            />
          }
        />
      )
    })
  }, [profiles])

  const currentContent = useMemo(() => {
    if (_.isEmpty(profiles)) return <EmptyHolder/>
    return (
      <Steps
        progressDot={true}
        onChange={onChange}
        current={currentStep}
        direction="vertical">
        {steps}
      </Steps>
    )
  }, [profiles, currentStep])

  const isAdmin = _.get(user, 'edit')
  const isSuperAdmin = _.get(user, 'edit_system')

  const {
    prefix_title,
    name,
    username,
    gender,
    phone_number,
    phone2_number,
    email,
    date_of_birth,
    hometown,
    company,
    school,
    quote,
    total_channels,
    total_followers,
    total_following,
  } = user ?? {}

  const ItemStatistic = ({label, value}) => (
    <div className="p-2 flex flex-col items-center border border-color-50 shadow-items-md rounded-lg mx-6 md:mx-0">
        <span className="font-medium text-xs text-color-500 uppercase tracking-wide">
          {t(label)}
        </span>
      <span className="font-bold text-lg text-primary">
          {value}
        </span>
    </div>
  )

  return (
    <Skeleton loading={!!isLoading}>
      <div className="flex flex-col gap-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-6">
          <ItemStatistic label="channel" value={total_channels}/>
          <ItemStatistic label="followers" value={total_followers}/>
          <ItemStatistic label="following" value={total_following}/>
        </div>
        <div className="flex flex-col gap-2 mb-3">
          <ListHeaderWithLine title="personal information" className="text-sm"/>
          <div className="flex flex-col gap-2">
            <ItemInformation label="full name" value={`${t(prefix_title)}. ${name}`} className="text-xs"/>
            <ItemInformation label="username" value={username} className="text-xs"/>
            <div className="grid grid-cols-2 gap-2">
              {renderIf(
                date_of_birth,
                <ItemInformation label="date of birth" value={moment(date_of_birth).format('DD/MM/YYYY')}
                                 className="text-xs"/>
              )}
              {renderIf(
                gender !== 0,
                <ItemInformation label="gender" value={t(getLabelOfGender(gender))} className="text-xs"/>
              )}
              {renderIf(
                phone_number,
                <ItemInformation label="phone" value={phone_number} className="text-xs"/>
              )}
              {renderIf(
                phone2_number,
                <ItemInformation label="alternative phone" value={phone2_number} className="text-xs"/>
              )}
              {renderIf(
                email,
                <ItemInformation label="email" value={email} className="text-xs"/>
              )}
            </div>
            <div className="grid grid-cols-2 gap-2">
              {renderIf(
                isAdmin || isSuperAdmin,
                <ItemInformation label="access rights" value={isSuperAdmin ? 'Super Admin' : isAdmin && 'Admin'}
                                 className="text-xs"/>
              )}
              {renderIf(
                hometown,
                <ItemInformation label="hometown" value={hometown} className="text-xs"/>
              )}
              {renderIf(
                company,
                <ItemInformation label="company" value={company} className="text-xs"/>
              )}
              {renderIf(
                school,
                <ItemInformation label="school" value={school} className="text-xs"/>
              )}
            </div>


            {renderIf(
              quote,
              <div className="flex flex-col gap-1 text-xs italic">
                <span className="text-color-500">{`${t('quote')} : `}</span>
                <span className="ml-2 font-bold text-color-100">{quote}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Skeleton>
  )
}

export default UserProfile
