import {Alert, Badge, Menu} from 'antd'
import {auth_getJoinedChannels_Api, memberStatusTypes} from 'apis'
import {channelModel} from 'apis/model'
import DrawMenu from 'components/drawer/DrawMenu'
import {ChannelIcon} from 'components/icons/ChannelIcon'
import Toggle from 'components/Toggle'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {AiOutlineUnorderedList} from 'react-icons/ai'
import {FaAngleDown, FaAngleUp} from 'react-icons/fa'
import {FiMoreHorizontal} from 'react-icons/fi'
import {GoSearch} from 'react-icons/go'
import {Link} from 'react-router-dom'
import {staticPaths} from 'routes/staticPaths'
import {HeaderButton} from 'views/Organization/components/PageHeader'

export const AllChannels = () => {
  const t = useTranslate()

  return (
    <div className="verticalList__small">
      <Toggle defaultValue={true}>
        {(isToggle, toggle) => (
          <>
            <div className="flex items-center h-6 mt-6 mb-3">
              <div className="px-2 uppercase font-bold text-sm text-color-300">
                {t('other channels')}
              </div>
              <div className="flex-1" />
              <DrawMenu
                component={
                  <HeaderButton>
                    <FiMoreHorizontal />
                  </HeaderButton>
                }>
                {(isToggle, toggle) => (
                  <Menu
                    style={{
                      minWidth: '280px'
                    }}>
                    <Menu.Item
                      key="1"
                      className="flex mt-2">
                      <div
                        style={{
                          width: '24px',
                          height: '24px'
                        }}
                        className="mx-2 items-center justify-center">
                        <AiOutlineUnorderedList
                          style={{
                            fontSize:
                              '1.6rem'
                          }}
                        />
                      </div>
                      <Link
                        className="px-2 items-center justify-center truncate"
                        to={
                          staticPaths.accountChannelAdmin
                        }>
                        {t(
                          'managed channels'
                        )}
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      key="3"
                      className="flex mt-2">
                      <div
                        style={{
                          width: '24px',
                          height: '24px'
                        }}
                        className="mx-2 items-center justify-center">
                        <AiOutlineUnorderedList
                          style={{
                            fontSize:
                              '1.6rem'
                          }}
                        />
                      </div>
                      <Link
                        className="px-2 items-center justify-center truncate"
                        to={
                          staticPaths.accountChannelFollow
                        }>
                        {t(
                          'following channels'
                        )}
                      </Link>
                    </Menu.Item>
                  </Menu>
                )}
              </DrawMenu>

              <HeaderButton
                onClick={() =>
                  toggle()
                }>
                {isToggle ? (
                  <FaAngleUp />
                ) : (
                  <FaAngleDown />
                )}
              </HeaderButton>
            </div>
            {isToggle && (
              <div className="verticalList__small background-100">
                <LazyPagination
                  cacheId="my following channels"
                  {...{
                    apiInfo: auth_getJoinedChannels_Api,
                    query: {}
                  }}
                  renderItem={o => (
                    <Link
                      to={channelModel.getLinkToDetail(
                        o
                      )}
                      className="flex hover:background-100 items-center justify-center py-1 px-3">
                      <ChannelIcon
                        className="mr-2 text-color-100 text-3xl w-8 h-8"
                        premium={
                          o.premium
                        }
                      />
                      <div className="flex-col items-center justify-center truncate flex-1">
                        <div className="flex">
                          <div className="font-semibold flex-1">
                            {channelModel.getTitle(
                              o
                            )}
                          </div>
                          {o.has_unread && (
                            <Badge
                              dot
                              color="green"
                            />
                          )}
                        </div>
                        <div className="text-xs m-0 flex-1 truncate">
                          {getTitle(
                            o.owner
                          )}
                        </div>
                        <div className="font-light italic text-xs m-0">
                          {Number(
                            o.join_status
                          ) !== 1 &&
                          _.has(
                            o,
                            'join_status'
                          )
                            ? t(
                                memberStatusTypes[
                                  String(
                                    Number(
                                      o.join_status
                                    )
                                  )
                                ].label
                              )
                            : null}
                        </div>
                      </div>
                    </Link>
                  )}
                  renderEmpty={() => (
                    <Alert
                      type="info"
                      closable
                      showIcon={false}
                      banner
                      className="text-center background-100"
                      message={
                        <div className="py-4">
                          <div className="text-sm m-auto flex flex-1">
                            <span className="whitespace-no-wrap pt-3">
                              {t('Tap')}
                            </span>
                            <Link
                              className="items-center justify-center"
                              to={`${staticPaths.discovery.pathname}?type=channel`}>
                              <HeaderButton>
                                <GoSearch />
                              </HeaderButton>
                            </Link>
                            <span className="flex pt-3">
                              {t(
                                'to find content that interests you'
                              )}
                            </span>
                          </div>
                        </div>
                      }></Alert>
                  )}></LazyPagination>
              </div>
            )}
          </>
        )}
      </Toggle>
    </div>
  )
}
