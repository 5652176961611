import React from 'react'

const FeedContext = React.createContext({
  avatar: '',
  cover: '',
  title: '',
  description: '',
  verified: false,
  id: '',
  to: {},
})

export default FeedContext
