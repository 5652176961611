import classNames from 'classnames'
import ScrollToViewByHash from 'components/ScrollToViewByHash'
import _ from 'lodash'
import React, {useMemo} from 'react'
import {BsCircleFill, BsDiamond, BsFillDiamondFill} from 'react-icons/bs'
import {Link} from 'react-router-dom'
import {headings} from 'views/SlateEditor/components/Heading'
import {findDeepNodes, toBase64} from 'views/SlateEditor/functions'
import useTranslate from '../../../modules/local/useTranslate'
import Toggle from '../../../components/Toggle'

const headerTypes = headings
const headerIcons = [BsCircleFill, BsFillDiamondFill, BsDiamond]
const headerMargin = [0, 15, 30]

const TableOfContents = ({className, editorState, renderEmptyContent}) => {
  const t = useTranslate()

  const blocks = useMemo(
    () =>
      Array.from(
        findDeepNodes(editorState, (node) =>
          headerTypes.includes(node?.type)
        ) ?? []
      ),
    [editorState]
  )

  const result = blocks.map(({type, children}) => ({
    type,
    text: _.get(children, '[0].text'),
  }))

  const itemsToShow = 3

  const blockSize = result.length

  const stopBy =
    blockSize < 2 * itemsToShow
      ? blockSize
      : Math.min(blocks.length, itemsToShow)

  const reachEnd = stopBy >= blockSize

  if (_.isEmpty(result) && !renderEmptyContent) {
    return null
  }

  return (
    <div className={classNames(className, 'flex flex-col gap-2')}>
      <ScrollToViewByHash/>
      <div className="py-1 border-b border-color-50 text-color-300 text-sm font-medium uppercase">
        {t('table of contents')}
      </div>
      {_.isEmpty(result) && _.isFunction(renderEmptyContent) ? (
        renderEmptyContent()
      ) : (
        <Toggle>
          {(isToggle, toggle) => (
            <React.Fragment>
              {result
                .slice(0, isToggle ? result.length : stopBy)
                .map(({type, text}, index) => {
                  const spaces = headerTypes.indexOf(type)
                  const BulletIcon = headerIcons[spaces]
                  const margin = headerMargin[spaces]
                  const hash = toBase64(text)
                  return (
                    <div
                      key={index}
                      style={{marginLeft: margin}}
                      className="flex items-center p-2 border border-color-50 rounded-md hover:shadow-items hover:background-100 cursor-pointer">
                      <Link
                        title={text}
                        replace
                        to={(location) => ({
                          ...location,
                          hash,
                        })}
                        key={index}
                        className="flex items-start gap-1 no-underline">
                        <BulletIcon className="px-1 text-color-400"/>
                        <span
                          className="flex-1 text-xs text-color-300 hover:text-color-000 italic leading-tight max-lines-3">
                          {text}
                        </span>
                      </Link>
                    </div>
                  )
                })}
              {!reachEnd && (
                <React.Fragment>
                  {!isToggle && (
                    <span className="pl-2 text-color-500">{'...'}</span>
                  )}
                  <div
                    onClick={toggle}
                    className="text-center justify-end text-xs font-light italic px-3 cursor-pointer text-color-500 hover:text-color-100">
                    {t(isToggle ? 'see less' : 'see more')}
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Toggle>
      )}
    </div>
  )
}

export default TableOfContents
