import {StarOutlined} from '@ant-design/icons'
import {Menu} from 'antd'
import DrawMenu from 'components/drawer/DrawMenu'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo} from 'react'
import {AiOutlineMenu} from 'react-icons/ai'
import {deepTranslate, Null, PaddingWrapper} from 'views/Shared'

const defaultActions = [
  {
    key: 'action-1',
    label: 'action 1',
    icon: <StarOutlined/>,
  },
  {
    key: 'action-2',
    label: 'action 2',
    icon: <StarOutlined/>,
  },
  {
    key: 'action-3',
    label: 'action 3',
    icon: <StarOutlined/>,
  },
]

const ActionMenu = ({onClick = Null, actions = defaultActions}) => {
  const t = useTranslate()

  const handleClick =
    (callback = Null) =>
      ({key}) => {
        onClick(key)
        callback()
      }

  const items = useMemo(
    () => (actions || []).map(deepTranslate(t)),
    [actions, t]
  )

  return (
    <DrawMenu
      component={
        <PaddingWrapper
          padding="0.25rem"
          className="flex items-center text-primary hover:text-secondary">
          <AiOutlineMenu
            style={{
              fontSize: '1.5em',
            }}
          />
        </PaddingWrapper>
      }>
      {(__, toggle) => (
        <Menu
          items={items}
          onClick={handleClick(toggle)}
        />
      )}
    </DrawMenu>
  )
}

export default ActionMenu
