import React from 'react';
import AppLogoIcon from './icons/AppLogoIcon';

const Loading = () => {
  return (
    <div>
      <AppLogoIcon loading={true}/>
    </div>
  );
};
export default Loading;
