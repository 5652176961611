import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../../LoadingPage";

const DatasetSettingsLazy = Loadable({
  loader: () => import('./DatasetSettings'),
  loading: LoadingPage
})

const DatasetSettings = (props) => (
  <DatasetSettingsLazy {...props} />
)

export default DatasetSettings
