import { notification } from 'antd'
import _ from 'lodash'

export * from './share'
export const getFormDataFromJson = obj => {
  const formData = new FormData()
  for (let key in obj) {
    formData.append(key, obj[key])
  }
  return formData
}
const normalizeString = str => {
  const newStr = _.lowerCase(str)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
  return newStr
}
const memoNormalizeString = _.memoize(
  normalizeString
)
export const matchString = (
  parent,
  child
) => {
  return memoNormalizeString(
    parent
  ).includes(memoNormalizeString(child))
}

export const createOnError = () => {
  return (...args) => {
    const [
      error,
      errorObj = {
        error_code: 'System error!',
        error: '-1'
      }
    ] = args
    switch (true) {
      case Boolean(errorObj.message):
        notification.error({
          message: errorObj.message,
          duration: 3,
          placement: 'topRight'
        })
        break;

      default:
        notification.error({
          message: errorObj.error_code,
          description:
            typeof errorObj.error ===
              'string'
              ? errorObj.error
              : Object.values(
                errorObj.error
              ).toString(),
          duration: 3,
          placement: 'topRight'
        })
        break;
    }


    return args
  }
}
