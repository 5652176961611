import { schema } from 'normalizr'
import { channelSubscriptionSchema } from './channelSubscriptionSchema'

export const channelSchema = new schema.Entity(
  'channels',
  {
    channel_subscription: channelSubscriptionSchema
  },
  {
    idAttribute: 'idname'
  }
)
