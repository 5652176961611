import { Button, Collapse } from 'antd'
import classNames from 'classnames'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useContext,
  useState,
} from 'react'
import { IoCaretDownOutline } from 'react-icons/io5'

const SearchIntroduction = ({
  to,
  label,
  header,
  title,
  description,
  children,
  extra = {},
  ...props
}) => {
  const t = useTranslate()
  const { isSm } = useContext(
    LayoutContext
  )
  const [collapse, setCollapse] =
    useState(true)

  const history = useHistory()

  const { Panel } = Collapse

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <Collapse
        onChange={(key) => {
          setCollapse(_.isEmpty(key))
        }}
        className={classNames(
          'w-full bg-primary-50 custom-collapse',
          collapse
            ? 'rounded-lg'
            : 'rounded-lg'
        )}>
        <Panel
          showArrow={false}
          header={
            <span className="text-xs md:text-base font-bold text-primary leading-tight px-3">
              {t(header)}
            </span>
          }
          extra={
            isSm ? (
              <IoCaretDownOutline className="text-color-300 mx-1" />
            ) : (
              <span
                  style={{
                    marginTop: '0.1rem'
                  }}
                  className="flex items-center italic font-light text-xs text-color-300 hover:text-primary space-x-1 mx-1">
                <span>
                  {t('learn more')}
                </span>
                <IoCaretDownOutline />
              </span>
            )
          }>
          <div className="flex flex-col items-center px-3 py-6 space-y-6">
            <div className="w-full">
              {description}
            </div>
            <Button
              onClick={() => {
                history.push({
                  pathname: to,
                  state: {
                    isModal: true,
                  },
                })
              }}
              className="rounded-lg border-none no-shadow no-text-shadow w-fit"
              type="primary">
              <div className="px-6">
                {t(label)}
              </div>
            </Button>
          </div>
        </Panel>
      </Collapse>
    </div>
  )
}

export default SearchIntroduction
