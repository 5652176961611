import {ElementTypes} from '../types'

const withLinks = (editor) => {
  const {isInline} = editor

  editor.isInline = (element) =>
    element.type === ElementTypes.LINK ? true : isInline(element)

  return editor
}

export default withLinks
