import _ from 'lodash'
import {
  useEffect,
  useState
} from 'react'
export const DelayRender = ({
  active = true,
  lazy,
  time = 50,
  children = null
}) => {
  const [ready, setReady] = useState(
    lazy ? null : children || null
  )
  useEffect(
    () => {
      let timeout
      if (active) {
        timeout = setTimeout(() => {
          requestAnimationFrame(() => {
            let ready
            if (
              _.isFunction(children)
            ) {
              ready = children()
              if (
                ready &&
                ready != null
              )
                setReady(children())
            } else {
              if (
                children &&
                children !== null
              )
                setReady(
                  children || null
                )
            }
          })
        }, time)
      }

      return () => {
        timeout && clearTimeout(timeout)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [children, active]
  )
  if (!active)
    return _.isFunction(children)
      ? children()
      : children
  return ready
}
