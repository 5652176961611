import _ from 'lodash'
import {permissionCodes} from 'modules/permissions/contants'
import React from 'react'
import {BsEnvelopePaper, BsPersonCircle} from 'react-icons/bs'
import {
  IoDiamondOutline,
  IoGridOutline,
  IoPeopleOutline,
  IoPersonOutline,
  IoSettingsOutline,
  IoTimerOutline,
  IoTrashOutline,
} from 'react-icons/io5'
import {withRouter} from 'react-router-dom'
import {compose, fromRenderProps, withProps} from 'recompose'
import {notFoundOnError, withAccessPermissions} from 'views/AppLocals/composers'
import {CustomKeys} from 'views/AppLocals/CustomKeys'
import {AppEntities} from 'views/AppLocals/enums'
import {ContextActionTypes} from 'views/AppLocals/factory/createContextMenu'
import createDeleteEntity from 'views/AppLocals/factory/createDeleteEntity'
import {CoHosts, CoOwners} from 'views/AppLocals/factory/createHostSetting'
import {createSettingModule} from 'views/AppLocals/factory/createModule'
import Members from 'views/MemberSetting/Members.lazy'
import WaitingAcceptMembers from 'views/MemberSetting/WaitingAcceptMembers.lazy'
import EventContext from '../EventContext'
import EventSettingCategories from './EventSettingCategories'
import EventSettingOverview from './EventSettingOverview'
import EventSettingOwner from './EventSettingOwner'
import EventSettingParticpants from './EventSettingParticpants'
import EventSettingSpeakers from './EventSettingSpeakers'
import EventSettingTimetable from './EventSettingTimetable'

const menuItems = [
  {
    key: 'overview',
    label: 'general settings',
    icon: <IoSettingsOutline/>,
  },
  {
    key: 'categories',
    label: 'categories',
    icon: <IoGridOutline/>,
  },
  {
    key: 'owner',
    label: 'owner',
    icon: <IoDiamondOutline/>,
  },
  {
    key: 'timeline',
    label: 'timeline',
    icon: <IoTimerOutline/>,
  },
  {
    key: 'speakers',
    label: 'speakers',
    icon: <BsPersonCircle/>,
  },
  {
    key: 'participants',
    label: 'participants',
    icon: <BsEnvelopePaper/>,
  },
  {
    key: 'member-management',
    label: 'member management',
    icon: <IoPeopleOutline/>,
    children: [
      {
        label: 'members',
        key: 'members',
      },
      {
        label: 'waiting members',
        key: 'waiting-members',
      },
    ],
  },
  {
    key: 'hosts',
    label: 'hosts',
    icon: <IoPersonOutline/>,
    children: [
      {
        label: 'list of co-hosting',
        key: CustomKeys.co_hosts.name,
      },
      {
        label: 'list of co-owners',
        key: 'host-request',
      },
    ],
  },
  {
    key: 'delete',
    label: 'delete',
    icon: <IoTrashOutline/>,
  },
]

const routeEntities = [
  {
    path: 'overview',
    label: 'overview',
    component: EventSettingOverview,
  },
  {
    path: 'categories',
    label: 'categories',
    component: EventSettingCategories,
  },
  {
    path: CustomKeys.co_hosts.name,
    label: CustomKeys.co_hosts.label,
    component: fromRenderProps(EventContext.Consumer, ({event}) => ({
      item: event,
    }))(CoHosts),
  },
  {
    path: 'owner',
    label: 'owner',
    component: EventSettingOwner,
  },
  {
    path: 'host-request',
    label: 'host request',
    component: fromRenderProps(EventContext.Consumer, ({event}) => ({
      item: event,
    }))(CoOwners),
  },
  {
    path: 'timeline',
    label: 'timeline',
    component: EventSettingTimetable,
  },
  {
    path: 'speakers',
    label: 'speakers',
    component: EventSettingSpeakers,
  },
  {
    path: 'participants',
    label: 'participants',
    component: EventSettingParticpants,
  },
  {
    path: 'members',
    label: 'members',
    component: fromRenderProps(EventContext.Consumer, ({event}) => ({
      item: event,
    }))(Members),
  },
  {
    path: 'waiting-members',
    label: 'waiting members',
    component: fromRenderProps(EventContext.Consumer, ({event}) => ({
      item: event,
    }))(WaitingAcceptMembers),
  },
  {
    path: 'delete',
    label: 'delete',
    component: () => (
      <EventContext.Consumer>
        {({event}) => createDeleteEntity(event)}
      </EventContext.Consumer>
    ),
  },
].map(({path, component: Component, ...rest}) => ({
  ...rest,
  path,
  key: path,
  component: (props) => <Component {...props} />,
}))

export const eventSettings = {
  actions: [
    {
      key: ContextActionTypes.SETTINGS,
      label: 'setting',
      icon: <IoSettingsOutline/>,
    },
  ],
  menuItems,
  routeEntities,
}

const EventSettings = ({id, ...props}) =>
  createSettingModule([AppEntities.EVENT], {
    item: id,
    ...props,
  })

export default compose(
  notFoundOnError(EventContext.Consumer),
  withAccessPermissions([permissionCodes.canEdit]),
  withRouter,
  withProps(({match}) => ({
    id: _.get(match, 'params.id'),
  }))
)(EventSettings)
