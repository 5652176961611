import { Radio } from 'antd'
import { datasets_getSuggestion_Api } from 'apis'
import { search_loadFilterData_Api } from 'apis/search'
import EmptyHolder from 'components/EmptyHolder'
import _ from 'lodash'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import queryString from 'query-string'
import React, {
  useMemo,
  useState
} from 'react'
import { renderLoading } from 'views/MainPage/functions/renderPagingLoading'
import { deepTranslate } from 'views/Shared'
import { DelayRender } from '../DelayRender'
import renderSearchResultItem from '../functions/renderSearchResultItem'

const DataSetResult = ({
  type,
  filter,
  keyword,
  filterRow,
  categories
}) => {
  const t = useTranslate()

  const isNotTypeAll =
    type && !['all', ''].includes(type)
  const isEmptyKeyword = _.isEmpty(
    keyword
  )
  const isReadySearching = !(
    isEmptyKeyword &&
    _.isEmpty(
      Object.values(filter).filter(
        item => item
      )
    )
  )

  const isEmptyCategories = _.isEmpty(
    categories
  )

  const showRecommendation =
    isNotTypeAll &&
    isEmptyCategories &&
    isEmptyKeyword &&
    _.isEmpty(filter)

  const createApiInfo = () => {
    const searchString = queryString.stringify(
      {
        keyword: keyword,
        ...filterRow.reduce(
          (result, value, i) => {
            if (!filter[value.id])
              return result
            if (
              value.type === 'checkbox'
            ) {
              if (
                !result[value.groupId]
              ) {
                result[
                  value.groupId
                ] = []
              }
              result[
                value.groupId
              ].push(value.id)
            }
            return result
          },
          {}
        )
      },
      { arrayFormat: 'comma' }
    )
    let path = search_loadFilterData_Api.path.replace(
      ':type',
      'dataset'
    )
    if (!_.isEmpty(searchString)) {
      path = path + `?${searchString}`
    }
    return {
      ...search_loadFilterData_Api,
      path
    }
  }
  const apiInfo = createApiInfo()

  const [select, setSelect] = useState(
    'suggested'
  )

  const defaultList = [
      {
          value: 'suggested',
          label: 'suggested dataset',
          refreshKey: datasets_getSuggestion_Api.path,
          apiInfo: datasets_getSuggestion_Api
      }
  ]

  const refreshKey = [
    apiInfo.path
  ].join('/')

  const options = useMemo(
    () =>
      Array.from(defaultList).map(
        deepTranslate(t)
      ),
    [t, defaultList]
  )

  const validValue = useMemo(() => {
    const item =
      _.find(options, {
        value: select
      }) || _.first(options)
    return _.get(item, 'value', item)
  }, [select, options])

  const current = useMemo(
    () =>
      _.find(defaultList, {
        value: select
      }),
    [select, defaultList]
  )

  return (
    <div className="background w-full justify-center">
      <div className="w-full flex items-center mb-3 RoundedBorderRadioGroup">
        <Radio.Group
          name="filter"
          options={options}
          value={validValue}
          optionType="button"
          buttonStyle="solid"
          onChange={event => {
            setSelect(
              _.get(
                event,
                'target.value'
              )
            )
          }}
        />
      </div>

      <div className="space-y-3">
        {isReadySearching && (
          <LazyPagination
            key={select}
            refreshKey={refreshKey}
            renderLoading={renderLoading}
            renderEmpty={() => (
              <EmptyHolder />
            )}
            apiInfo={apiInfo}
            renderItem={renderSearchResultItem}
          />
        )}
      </div>

      <div className="space-y-3">
        {showRecommendation &&
          !isReadySearching && (
            <DelayRender>
              {current && (
                <LazyPagination
                  key={select}
                  renderLoading={
                    renderLoading
                  }
                  renderEmpty={() => (
                    <EmptyHolder />
                  )}
                  renderItem={(item,i) => renderSearchResultItem({...item, owner: item}, i)}
                  {...current}
                />
              )}
            </DelayRender>
          )}
      </div>
    </div>
  )
}

export default DataSetResult
