import classNames from "classnames";
import React, {useState} from 'react';
import {MdViewAgenda} from "react-icons/md";
import {TbMenu2} from "react-icons/tb";

export const ViewTypes = Object.freeze({
  HEADLINE: 'headline',
  LISTVIEW: 'list',
})

export const ViewContext = React.createContext({})

const ToggleView = ({height = 20, children, defaultView = ViewTypes.HEADLINE, renderHeadlineView, renderListView}) => {
  const [view, setView] = useState(defaultView)
  const isHeadLine = view === ViewTypes.HEADLINE
  const renderItem = isHeadLine ? renderHeadlineView : renderListView;
  const switchComponent = (
    <div
      style={{height: height}}
      className="flex items-center gap-1">
      <MdViewAgenda
        className={classNames("cursor-pointer", isHeadLine ? "text-color-500 hover:text-color-300" : "text-primary hover:text-primary-700")}
        onClick={() => {
          setView(ViewTypes.LISTVIEW)
        }}
      />
      <TbMenu2
        className={classNames("cursor-pointer", isHeadLine ? "text-primary hover:text-primary-700" : "text-color-500 hover:text-color-300")}
        onClick={() => {
          setView(ViewTypes.HEADLINE)
        }}
      />
    </div>
  )

  return (
    children({view, setView, switchComponent, renderItem})
  )
}

export const ViewProvider = ({children, ...props}) => {
  return (
    <ToggleView {...props}>
      {({...rest}) => (
        <ViewContext.Provider
          value={{
            ...rest,
          }}>
          {children}
        </ViewContext.Provider>
      )}
    </ToggleView>
  )
}

export default ToggleView
