import {Avatar} from 'antd'
import {
  comment_addComment_Api,
  comment_addReply_Api,
  comment_getComments_Api,
  comment_getReples_Api,
  commentContainerSchema,
} from 'apis'
import {getId, getType} from 'apis/model/base'
import {socketUnionSchema} from 'apis/schema'
import {commentSchema} from 'apis/schema/commentSchema'
import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import Count from 'components/Feed/Count'
import Description from 'components/Feed/Description'
import {Like} from 'components/Feed/Like'
import {get_mention_data, getText} from 'components/Feed/MentionDataContext'
import Timestamp from 'components/Feed/Timestamp'
import InputBar from 'components/InputBar'
import {LoginContext} from 'components/LoginContext'
import Null from 'components/NullComponent'
import {SelectEntityItem} from 'components/SelectEntityItem'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import logFunc from 'helpers/logFunc'
import _ from 'lodash'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import {SocketContext} from 'modules/socket/SocketContext'
import {JOIN_ROOM} from 'modules/socket/types'
import {normalize} from 'normalizr'
import React, {useCallback, useContext, useEffect, useMemo, useState,} from 'react'
import {BsPersonFill, BsReply} from 'react-icons/bs'
import {IoChatboxEllipsesOutline} from 'react-icons/io5'
import {RiMessage3Line, RiUser3Fill} from 'react-icons/ri'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import useToggle from 'react-use/lib/useToggle'
import {nest} from 'recompose'
import {useSelectEntities} from 'redux/entities/useSelectEntities'
import {NotificationActionTypes, successNotify,} from 'views/AppLocals/factory/createNotification'
import {renderLoading} from 'views/MainPage/functions/renderPagingLoading'
import {renderElse, renderOwnChild} from 'views/Shared'
import {GoogleAdsRecommendedUnitHorizontal} from '../../components/GoogleAds/GoogleAds'
import {LayoutContext} from '../../components/layouts/Default/LayoutContext'
import {useVote} from '../Server/ServerContainer'
import {renderIf} from '../Shared'
import ResponsesContextMenu from './components/ResponsesContextMenu'
import ResponsesEditAction from './components/ResponsesEditAction'

const Comment = ({item, className, canReply = true}) => {
  const login = useContext(LoginContext)
  const {creator, vote_status, total_votes, total_replies, id} = item ?? {}
  const t = useTranslate()
  const [openEdit, toggleOpenEdit] = useToggle()
  const handleVote = useVote({
    vote_status,
    total_votes,
    owner_id: id,
    owner_type: getType(item),
  })
  const [openReplies, toggleOpenReplies] = useToggle()
  const showReplies = !item?.isDeleted && !!canReply

  const {avatar, thumbnail} = creator ?? {}

  const displayName =
    getId(creator) === getId(login) ? (
      <span className="text-color-000 italic">{t('is_me')}</span>
    ) : (
      getTitle(creator)
    )

  const get_decrypted_values = useCallback((values) => {
    const {message, mentions} = values ?? {}
    const description_data = get_mention_data(mentions) ?? undefined
    return {
      ...(values ?? {}),
      description_data,
      message: getText(message, get_mention_data(mentions, true)),
    }
  }, [])

  if (item?.isDeleted) {
    return null
  }

  return (
    <div
      className={classNames(
        'background rounded-md space-y-1 ml-2 mb-4',
        className
      )}>
      <div className="flex items-center gap-2">
        <Avatar
          size={30}
          className="flex flex-center background-100"
          icon={
            <BsPersonFill
              size={18}
              className="text-color-400"
            />
          }
          src={avatar || thumbnail}
        />
        <div className="leading-tight flex flex-col">
          <Link
            rel="nofollow"
            to={getLinkToDetail(creator)}
            className="font-bold text-color-000 no-underline text-xs">
            {displayName}
          </Link>
          <Timestamp
            className="text-color-400 italic text-2xs"
            timestamp={item?.created}
          />
        </div>
        <div className="flex-1"/>
        <ResponsesContextMenu
          item={item}
          onEdit={toggleOpenEdit}
        />
      </div>
      <div className="px-1">
        {openEdit ? (
          <ResponsesEditAction
            onExit={toggleOpenEdit}
            // item={get_decrypted_values(item)}
            item={item}
          />
        ) : (
          <Description
            className="text-sm mt-2 leading-tight"
            type="dataset"
            data={item}
            mentions={_.get(item, 'mentions')}>
            {_.get(item, 'message')}
          </Description>
        )}
      </div>
      <div
        className="flex justify-start items-center"
        style={{
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
        }}>
        <Like
          vote_status={item?.vote_status}
          total_votes={item?.total_votes}
          handleVote={() => handleVote()}
        />
        {canReply && (
          <React.Fragment>
            <Count
              defaultNumber={_.get(item, 'total_replies')}
              onClick={() => {
                toggleOpenReplies()
              }}
              size={14}
              color="text-primary"
              component={RiMessage3Line}
            />
          </React.Fragment>
        )}
      </div>
      {showReplies && total_replies > 0 && (
        <div
          onClick={toggleOpenReplies}
          className="cursor-pointer flex items-center gap-1 mt-2 ml-2 text-xs italic">
          <BsReply
            size={18}
            className="text-color-400"
            style={{transform: 'rotate(180deg)'}}
          />
          <span className="font-medium text-color-400 hover:text-primary background-100 px-2 py-1 rounded-md">
            {renderIf(
              total_replies > 0,
              <span className="font-bold text-color-100 mr-1">
                {total_replies}
              </span>
            )}
            {t('reply')}
          </span>
        </div>
      )}
      {openReplies && (
        <div className="ml-2 md:ml-3 pl-2 md:pl-3 border-l border-color-50">
          <Replies
            comment_id={id}
            id={item?.container_id}
            type={item?.container_type}
          />
        </div>
      )}
    </div>
  )
}

export const VoteComment = ({item, ref_id, className, canReply = true}) => {
  const t = useTranslate()

  const {isSm} = useContext(LayoutContext)

  const [openEdit, toggleOpenEdit] = useToggle()

  const [openReplies, toggleOpenReplies] = useToggle()

  const {
    creator,
    created,
    message,
    container_id,
    container_type,
    vote_status,
    total_votes,
    total_downvotes,
    total_replies,
  } = item ?? {}

  const {name = '', avatar, thumbnail} = creator ?? {}

  const isDeleted = !!_.get(item, 'isDeleted', false)

  const mentions = Array.from(item?.mentions ?? [])

  const messageContent = useMemo(() => {
    return renderElse(
      isDeleted,
      <Description
        data={item}
        mentions={mentions}
        type={container_type}>
        {message}
      </Description>
    )
  }, [isDeleted, message, mentions])

  const showReplies = !isDeleted && !!canReply

  const Wrapper = renderOwnChild

  return (
    <Wrapper
      className={classNames('border-b pb-3 mb-3', !isSm && 'FeedCardContent')}
      item={item}
      ref_id={ref_id}>
      <div className={classNames('pt-2 relative', className)}>
        <div className="flex items-center gap-2">
          <Avatar
            className="flex flex-center background-200"
            size="small"
            icon={
              <RiUser3Fill
                size={12}
                className="text-color-400"
              />
            }
            src={avatar || thumbnail}
          />
          <div className="leading-tight flex flex-col flex-1">
            <Link
              rel="nofollow"
              to={getLinkToDetail(creator)}
              className="font-semibold text-sm no-underline">
              {`${name} `}
            </Link>
            <Timestamp
              className="text-color-500 italic text-2xs"
              timestamp={created}
            />
          </div>
          {!isDeleted && (
            <ResponsesContextMenu
              item={item}
              onEdit={toggleOpenEdit}
            />
          )}
        </div>
        <div className="p-2">
          {openEdit ? (
            <ResponsesEditAction
              onExit={toggleOpenEdit}
              // item={get_decrypted_values(item)}
              item={item}
            />
          ) : (
            messageContent
          )}
        </div>
        {!isDeleted && (
          <div className="flex flex-col">
            <div className="flex gap-3 mt-1">
              {showReplies && (
                <div
                  onClick={isDeleted ? null : toggleOpenReplies}
                  className="cursor-pointer flex items-center gap-1 font-medium text-sm text-color-400 hover:text-primary italic">
                  <IoChatboxEllipsesOutline size={16}/>
                  {renderIf(
                    total_replies > 0,
                    <span className="text-xs">{total_replies}</span>
                  )}
                </div>
              )}
            </div>
            {showReplies && total_replies > 0 && (
              <div
                onClick={isDeleted ? null : toggleOpenReplies}
                className="cursor-pointer flex items-center gap-1 mt-2 text-sm italic">
                <BsReply
                  size={18}
                  className="text-color-400"
                  style={{transform: 'rotate(180deg)'}}
                />
                <span className="font-medium text-color-400 hover:text-primary background-100 px-2 py-1 rounded-md">
                  {renderIf(
                    total_replies > 0,
                    <span className="font-bold text-color-100 mr-1">
                      {total_replies}
                    </span>
                  )}
                  {t('reply')}
                </span>
              </div>
            )}
            {openReplies && (
              <div className="ml-2">
                <Replies
                  id={container_id}
                  type={container_type}
                  comment_id={getId(item)}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  )
}

const renderComment =
  ({id, type, canReply = true}) =>
    (item) => {
      const Component = Comment
      return (
        <Component
          item={item}
          key={item.id}
          ref_id={id}
          canReply={canReply}
        />
      )
    }

export const Responses = ({type, id, Feature = Null, renderEmpty = Null}) => {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)

  const dispatch = useDispatch()

  const {socket, authenticated} = useContext(SocketContext)

  const [refreshKey, setRefreshKey] = useState(Date.now())

  const {sortByDate: comments = []} = useSelectEntities(
    `${type}_${id}`,
    commentContainerSchema,
    {}
  )

  const login = useContext(LoginContext)

  useEffect(() => {
    if (socket && type && id) {
      socket.emit(JOIN_ROOM, {
        roomId: id,
        roomType: type,
      })
    }
  }, [id, type, socket])

  useEffect(() => {
    if (!socket) return
    let timeout
    if (authenticated) {
      socket.off('server-push-content')
      socket.on('server-push-content', (data) => {
        dispatch({
          type: 'entities',
          ...normalize(data, socketUnionSchema),
        })
      })
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [authenticated, dispatch, socket])

  const comment_ids = Object.keys(comments)
    .sort((a, b) => Number(b) - Number(a))
    .map((key) => comments[key])

  return (
    <React.Fragment key={refreshKey}>
      {!login ? (
        <div className="flex flex-col space-y-3 mb-16 mt-3">
          <div className="flex flex-col flex-center gap-3 border border-dashed rounded-lg px-2 py-8">
            <div className="text-center text-sm text-color-400 italic">
              {t(
                'Please log in to FeedIn to leave your comment in the post'
              )}
            </div>
            <Link
              to={'/login'}
              className="rounded-md px-3 py-1 bg-primary text-white text-sm no-text-shadow hover:text-white no-underline shadow-lg">
              {t('Login now')}
            </Link>
          </div>
          <GoogleAdsRecommendedUnitHorizontal/>
        </div>
      ) : (
        <div className="z-10">
          <AsyncAction
            onSuccess={() => {
              successNotify(NotificationActionTypes.CREATE, t)
              setRefreshKey(Date.now())
            }}
            apiInfo={comment_addComment_Api}
            query={{
              ':prop': type,
              ':id': id,
            }}>
            {({isLoading, handleAsyncAction}) => (
              <InputBar
                key={refreshKey}
                textOnly={true}
                isLoading={!!isLoading}
                defaultHeight={90}
                placeholder={t('type comment')}
                handleSubmit={({description}) => {
                  handleAsyncAction({
                    message: description,
                  })
                }}
              />
            )}
          </AsyncAction>
        </div>
      )}
      <div
        className="mt-3 flex-1 background">
        <div className="flex items-center gap-3 mb-2">
          <span className="font-medium text-sm text-color-300 italic uppercase">
            {t('comments')}
          </span>
          <div className="flex-1 border-b"/>
        </div>
        {comment_ids.map((key) => (
          <SelectEntityItem
            key={key}
            item={key}
            schema={commentSchema}>
            {renderComment({id, type})}
          </SelectEntityItem>
        ))}
        <LazyPagination
          refreshKey={refreshKey}
          query={{
            ':prop': type,
            ':id': id,
          }}
          renderLoading={renderLoading}
          apiInfo={comment_getComments_Api}
          key={comment_getComments_Api.path
            .replace(':prop', type)
            .replace(':id', id)}
          renderEmpty={_.isEmpty(comment_ids) ? () => <EmptyHolder/> : Null}
          renderItem={(item) => (
            <SelectEntityItem
              item={item?.id}
              defaultItem={item}
              schema={commentSchema}>
              {(item) => {
                const ids = Object.values(comments)
                const hideRecentComment = _.find(ids, (value) =>
                  [item?.id, item?.idname].includes(value)
                )
                return hideRecentComment
                  ? null
                  : renderComment({id, type})(item)
              }}
            </SelectEntityItem>
          )}
        />
      </div>
    </React.Fragment>
  )
}

export const Replies = nest(({type, id, comment_id}) => {
  const t = useTranslate()
  const login = useContext(LoginContext)
  const [refreshKey, setRefreshKey] = useState(Date.now())
  const {sortByDate: comments = []} = useSelectEntities(
    `${type}_${id}_${comment_id}`,
    commentContainerSchema,
    {}
  )

  return (
    <React.Fragment>
      {!login ? (
        <div className="flex flex-col flex-center gap-3 border border-dashed rounded-lg px-2 py-8">
          <div className="text-center text-sm text-color-400 italic">
            {t(
              'Please log in to FeedIn to leave your comment in the post'
            )}
          </div>
          <Link
            to={'/login'}
            className="rounded-md px-3 py-1 bg-primary text-white text-sm no-text-shadow hover:text-white no-underline shadow-lg">
            {t('Login now')}
          </Link>
        </div>
      ) : (
        <div className="z-10">
          <AsyncAction
            onSuccess={() => {
              successNotify(NotificationActionTypes.CREATE, t)
              setRefreshKey(Date.now())
            }}
            apiInfo={comment_addReply_Api}
            query={{
              ':type': type,
              ':id': id,
              ':comment_id': comment_id,
            }}>
            {({isLoading, handleAsyncAction}) => {
              return (
                <InputBar
                  defaultHeight={60}
                  key={refreshKey}
                  textOnly={true}
                  message={true}
                  isLoading={!!isLoading}
                  placeholder={t('reply')}
                  rows={1}
                  className="bg-transparent"
                  handleSubmit={logFunc(({description}) => {
                    handleAsyncAction({
                      message: description,
                    })
                  })}
                />
              )
            }}
          </AsyncAction>
        </div>
      )}
      <div className="flex-1 verticalList">
        {Object.keys(comments)
          .sort((a, b) => Number(b) - Number(a))
          .map((key) => comments[key])
          .map((key) => (
            <SelectEntityItem
              key={key}
              item={key}
              schema={commentSchema}>
              {renderComment({id, type, canReply: false})}
            </SelectEntityItem>
          ))}
        <LazyPagination
          renderEmpty={Null}
          query={{
            ':type': type,
            ':id': id,
            ':comment_id': comment_id,
          }}
          apiInfo={comment_getReples_Api}
          renderItem={(item) => (
            <SelectEntityItem
              item={item?.id}
              defaultItem={item}
              schema={commentSchema}>
              {(item) => {
                const ids = Object.values(comments)
                const hideRecentComment = _.find(ids, (value) =>
                  [_.get(item, 'id'), _.get(item, 'idname')].includes(value)
                )
                return hideRecentComment
                  ? null
                  : renderComment({id, type, canReply: false})(item)
              }}
            </SelectEntityItem>
          )}
        />
      </div>
    </React.Fragment>
  )
})
