import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../../../LoadingPage";

const ContactsLazy = Loadable({
  loader: () => import('./Contacts'),
  loading: LoadingPage
})

const Contacts = (props) => (
  <ContactsLazy {...props} />
)

export default Contacts
