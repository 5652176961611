import React from 'react'
import useTranslate from "../../../modules/local/useTranslate";
import BarChartSvg from "../../../components/Svgs/BarChartSvg";

const LoadingChart = ({title = 'No data', ...props}) => {
  const t = useTranslate();
  return (
    <div
      style={{
        height:
          'calc(100vh - 7 * var(--header-height))'
      }} className="overflow-hidden opacity-25">
      <div className="flex flex-col justify-center items-center relative">
        <h2 className="title">{t(title)}</h2>
        <BarChartSvg className="p-10 m-10 h-64"/>
      </div>
    </div>
  )
};

export default LoadingChart;