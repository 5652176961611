import {search_getSuggestedKeywords_Api} from 'apis'
import {KeywordInput} from 'components/KeywordInput'
import preventParentEvent from 'helpers/preventParentEvent'
import useQueryString from 'hooks/useQueryString'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useEffect, useState} from 'react'
import {PageContext} from 'views/Market/PageContext'
import productImageHolder from 'views/Market/productImageHolder'
import {AppTextLogoIcon} from "../../../../components/icons/AppTextLogoIcon"

const Banner = () => {
  const { routeEntities } = useContext(PageContext)
  const t = useTranslate()
  const [
    { keyword = '', ...rest } = {},
    setQuery
  ] = useQueryString({
    pathname: routeEntities.search.path
  })
  const [value, setValue] = useState(
    keyword
  )
  useEffect(() => setValue(keyword), [
    keyword
  ])
  return (
    <div
      style={{
        backgroundColor: 'var(--background-200)',
        backgroundImage: `url("${productImageHolder}")`
      }}
      className="w-full pb-6 lg:pb-12 relative">
      <div
        className="truncate flex items-center order-first items-end">
        <AppTextLogoIcon to={"/mart"}/>
        <h1 className="font-normal uppercase font-bold text-orange-600 pt-3">
          Mart
        </h1>
      </div>
      <form
        onSubmit={e => {
          preventParentEvent(e)
          setQuery({
            ...rest,
            keyword: value
          })
        }}
        className="px-3 max-w-xl mx-auto text-center space-y-4">
        <span className="font-bold text-2xl md:text-3xl text-color-100 tracking-widest">
          {t('what are you looking for')}
        </span>
        <KeywordInput
          {...{
              value,
              onClear: () => {
                  setQuery({
                      ...rest,
                      keyword: ''
                  })
              },
              remote: true,
              autoCompleteConfigs: {
                  fieldNames: {
                      label: 'keyword',
                      value: 'keyword',
                  },
                  actionParams: {
                      values: {
                          type: 'product'
                      },
                      apiInfo: search_getSuggestedKeywords_Api
                  },
              },
              onSearchClick: () => {
                  setQuery({
                      ...rest,
                      keyword: value
                  })
              },
              onChange: e => {
                  const value = e.target.value
                  setValue(value)
              },
              placeholder: t('Enter products / services search'),
              style: {
                  width: '100%',
              }
          }}
        />

      </form>
    </div>
  )
}
export default Banner
