import Async from 'modules/asyncCache/components/Async'
import React from 'react'
import {useSelectEntities} from 'redux/entities/useSelectEntities'
import EventContext from './EventContext'
import _ from "lodash"
import {Button, Modal} from "antd";
import useTranslate from "../../modules/local/useTranslate";
import {AppTextLogoSvg} from "appConfigs/svgs/AppTextLogoSvg";
import {ReactReduxContext} from "react-redux";
import {event_getById_Api} from "../../apis/event";
import {eventSchema} from "../../apis/schema/event";

export const EventProvider = ({
                                id,
                                children
                              }) => {
  const t = useTranslate();
  const item = useSelectEntities(
    id,
    eventSchema
  )
  return (
    <EventContext.Provider
      value={{
        item
      }}>
      <React.Fragment key={id}>
        <Async
          {...{
            apiInfo: event_getById_Api,
            query: {':id': id}
          }}>
          {
            (data) => {
              if (data && _.get(data, 'response.status') === 500) {
                Modal.info({
                  bodyStyle: {minHeight: '60vh'},
                  // maskClosable: true,
                  footer: null,
                  okButtonProps: {hidden: true},
                  cancelButtonProps: {hidden: true},
                  title: t(
                    '404'
                  ),
                  content: (
                    <ReactReduxContext.Consumer>
                      {({store}) => (
                        <div
                          className=" px-3 py-16 flex flex-col absolute inset-0 w-full h-full justify-center items-center">
                          <div
                            style={{
                              fontSize: '9rem'
                            }}>
                            <AppTextLogoSvg size={16}/>
                          </div>
                          <div className=" px-6 flex justify-center items-center font-semibold text-2xl">
                            {t('page not found')}
                          </div>
                          <div className=" px-3 pb-8 pt-3 flex justify-center items-center text-color-500 text-center">
                            {t(
                              'The page you are opening does not exist, please check the link or return to the homepage'
                            )}
                          </div>
                          <Button onClick={() => {
                            window.location.href = "/"
                          }} type="primary" className="rounded-full no-shadow">
                            {t('home')}
                          </Button>
                        </div>
                      )}
                    </ReactReduxContext.Consumer>
                  )
                });
              }
              return null;
            }
          }
        </Async>
      </React.Fragment>
      {
        children
      }
    </EventContext.Provider>
  )
}
