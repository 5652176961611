export const CONNECT = 'connect';
export const DISCONNECT = 'disconnect';
export const AUTHENTICATE =
	'authenticate';
export const AUTHENTICATED =
	'authenticated';
export const RECEIVE_COMMENT =
	'receive-comment';
export const JOIN_ROOM = 'join-room';

export const GET_COMMENTS =
	'get-comments';
export const SEND_COMMENT =
	'send-comment';

export const JOINED_ROOM =
	'joined-room';
export const COMMENT_RES =
	'comment-res';

export const VOTE_COMMENT =
	'vote-comment';
export const VOTE_TOTAL_UPDATE =
	'vote-total-update';

export const SPC =
	'server-push-content';