import {Spin} from 'antd'
import _ from 'lodash'
import {normalize, schema} from 'normalizr'
import React, {useContext, useState} from 'react'
import {ReactReduxContext} from 'react-redux'
import {Link} from 'react-router-dom'
import {ChartProvider, PremiumChartProvider, TableChartProvider} from '../../Chart/ChartContext'
import AmChartStateless from '../../Chart/Charts/AmChart/components/AmChartStateless'
import AmGeoChartStateless from '../../Chart/Charts/AmGeoChart/components/AmGeoChartStateless'
import AmVNGeoChartStateless from '../../Chart/Charts/AmVNGeoChart/components/AmVNGeoChartStateless'
import AntTableStateless from '../../Chart/Charts/AntTable/components/AntTableStateless'
import GoogleChartStateless from '../../Chart/Charts/GoogleChart/components/GoogleChartStateless'
import PremiumChartHolderStateless from '../../Chart/Charts/PremiumChartHolder/PremiumChartHolderStateless'
import TradingView from '../../Chart/Charts/TradingView'
import CustomCard from '../../MainPage/CustomCard'
import {ThemeContext} from "../../../modules/theme/ThemeContext";
import useTranslate from "../../../modules/local/useTranslate";
import Tabbar, {TabbarItem} from "../../../components/Tabbar";
import UseState from "../../../components/UseState";
import {DidOnMount} from "../../../modules/asyncCache/components/DidOnMount";
import {API_ROOT_URL} from "../../../envs/_current/config";
import {callAPI} from "../../../apis/middleware/request";
import LazyComponent from "../../../components/LazyComponent";
import chartSize from "../../../conts/chartSize";
import Null from 'components/NullComponent';

const Chart = props => {
  const {theme} = useContext(
    ThemeContext
  )
  switch (true) {
    case _.get(
      props,
      'item.subscribe_required',
      false
    ):
      return (
        <PremiumChartProvider
          item={props.item}>
          <PremiumChartHolderStateless
            {...props}
          />
        </PremiumChartProvider>
      )
    case _.get(
      props,
      'item.chart_source',
      'GoogleChart'
    ) === 'TradingView':
      return (
        <ChartProvider
          item={props.item}>
          <TradingView {...props} />
        </ChartProvider>
      )
    case _.get(
      props,
      'item.chart_source',
      'GoogleChart'
    ) === 'GoogleChart' &&
    _.get(
      props,
      'item.chart_type'
    ) === 'Table':
      return (
        <TableChartProvider
          item={props.item}>
          <GoogleChartStateless
            {...props}
          />
        </TableChartProvider>
      )
    case _.get(
      props,
      'item.chart_source',
      'GoogleChart'
    ) === 'AntDesign' &&
    _.get(
      props,
      'item.chart_type'
    ) === 'Table':
      return (
        <TableChartProvider
          item={props.item}>
          <AntTableStateless
            {...props}
          />
        </TableChartProvider>
      )
    case _.get(
      props,
      'item.chart_source',
      'GoogleChart'
    ) === 'GoogleChart':
      return (
        <ChartProvider
          item={props.item}>
          <GoogleChartStateless
            {...props}
          />
        </ChartProvider>
      )
    case _.get(
      props,
      'item.chart_source',
      'GoogleChart'
    ) === 'AmChart' &&
    _.get(
      props,
      'item.chart_type'
    ) === 'VNGeoChart':
      return (
        <ChartProvider
          item={props.item}>
          <AmVNGeoChartStateless
            {...props}
          />
        </ChartProvider>
      )
    case _.get(
      props,
      'item.chart_source',
      'GoogleChart'
    ) === 'AmChart' &&
    _.get(
      props,
      'item.chart_type'
    ) === 'GeoChart':
      return (
        <ChartProvider
          item={props.item}>
          <AmGeoChartStateless
            {...props}
          />
        </ChartProvider>
      )
    case _.get(
      props,
      'item.chart_source',
      'GoogleChart'
    ) === 'AmChart':
      return (
        <ChartProvider
          item={props.item}>
          <AmChartStateless
            {...props}
          />
        </ChartProvider>
      )
    // case _.get(
    //   props,
    //   'item.chart_source'
    // ) === 'LightWeightChart':
    //   return (
    //     <ChartProvider
    //       item={props.item}>
    //       <div className="w-full">
    //         {createRenderChart(theme)(
    //           props.item,
    //           props.item.id
    //         )}
    //       </div>
    //     </ChartProvider>
    //   )
    default:
      return (
        <pre>
          {JSON.stringify(
            _.get(props, 'item'),
            null,
            2
          )}
        </pre>
      )
  }
}
export const TransformToCardProps = ({
                                       data,
                                       loading
                                     }) => {
  const t = useTranslate()
  const {store} = useContext(
    ReactReduxContext
  )
  const [
    current,
    setCurrent
  ] = useState('0')
  const title = data.title

  const tabList = data.layout_config.tabs.map(
    (tab, i) => ({
      ...tab,
      tab: tab.title,
      key: '' + i
    })
  )

  const rows = _.get(
    tabList[current],
    `content.config_layout.rows`,
    []
  )

  return (
    <CustomCard
      loading={loading}
      tabProps={{
        size: 'small'
      }}
      title={
          <span className="font-bold text-sm text-color-000">{title}</span>
      }
      onTabChange={setCurrent}>

      <Tabbar
          style={{
              marginBottom: '1em'
          }}
          type='button'
          activeKey={tabList.findIndex(el => el.key === current)}>
        {tabList.map(el => (
          <TabbarItem
            onClick={() => {
              setCurrent(el.key)
            }}
            key={el.key}>
            {el.title}
          </TabbarItem>
        ))}
      </Tabbar>

      {rows.map((group, i) =>
        group.columns.map(
          (groupItem, i) => (
            <UseState key={current}>
              {([data, setData]) => (
                <>
                  <div className="space-y-3">
                    <DidOnMount
                      path={
                        groupItem.api_get
                      }
                      onMount={() => {
                        setTimeout(
                          () =>
                            callAPI(
                              'GET',
                              `${API_ROOT_URL}${groupItem.api_get}`
                            ).then(
                              res => {
                                const _result = _.get(
                                  res,
                                  'data'
                                )
                                if (
                                  !data ||
                                  _result?.version !==
                                  data?.version
                                ) {
                                  setData(
                                    _result
                                  )
                                  setTimeout(
                                    () =>
                                      store.dispatch(
                                        {
                                          type:
                                            'entities',
                                          ...normalize(
                                            _result,
                                            new schema.Entity(
                                              'charts',
                                              {},
                                              {
                                                idAttribute:
                                                  'idname',
                                                processStrategy: e => {
                                                  e.update = Date.now()
                                                  e.idname =
                                                    groupItem.api_get
                                                  return e
                                                }
                                              }
                                            )
                                          )
                                        }
                                      )
                                  )
                                }
                              }
                            ),
                          100
                        )
                      }}
                    />
                    {groupItem.description && (
                      <div>
                        {
                          groupItem.description
                        }
                      </div>
                    )}
                    <div
                      className="flex-1 relative flex flex-col"
                      style={{
                        minHeight: _.get(groupItem, 'content.chart_type') === 'Table' ? "auto" : 280,
                        ...((groupItem && groupItem.height)
                          ? {
                            height:
                            groupItem.height
                          }
                          : {})
                      }}>
                      {
                        !!!data ? (
                            <div className="w-full  flex flex-col items-center justify-center h-40 ">
                              <Spin spinning/>
                            </div>
                          )
                          : (
                            <LazyComponent
                              placeholder={
                                <div className="w-full h-full flex flex-col items-center justify-center h-40">
                                  <Spin spinning/>
                                </div>
                              }>
                              <ThemeContext.Consumer>
                                {({ theme }) => (
                                  <Chart
                                    key={
                                      groupItem.id +
                                      theme
                                    }
                                    size={
                                      chartSize.SMALL
                                    }
                                    setChartWrap={
                                      Null
                                    }
                                    item={
                                      data
                                    }
                                  />
                                )}
                              </ThemeContext.Consumer>
                            </LazyComponent>
                          )}
                    </div>

                  </div>
                  <section
                    id={groupItem.id}>
                    {groupItem.redirect_url && (
                      <Link
                        to={
                          groupItem.redirect_url
                        }
                        className="flex text-base md:text-sm mt-3 pt-3 border-t w-full">
                        {groupItem.redirect_text || t('see more')}
                      </Link>
                    )}
                    {groupItem.footer_html && (
                      <div
                        className="flex text-base md:text-sm pt-3 border-t w-full"
                        dangerouslySetInnerHTML={{
                          __html: _.get(
                            groupItem,
                            'footer_html',
                            '<div></div>'
                          )
                        }}/>
                    )}
                  </section>
                </>
              )}
            </UseState>
          )
        )
      )}
    </CustomCard>
  )
}

export default TransformToCardProps
