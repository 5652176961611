import React from 'react'

export const FlagFRSvg = ({size = 20, ...props}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={size}
      height={size}
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}
    >
      <circle cx="256" cy="256" r="256" fill="#F0F0F0"></circle>
      <path
        fill="#D80027"
        d="M512 256c0-110.071-69.472-203.906-166.957-240.077v480.155C442.528 459.906 512 366.071 512 256z"
      ></path>
      <path
        fill="#0052B4"
        d="M0 256c0 110.071 69.473 203.906 166.957 240.077V15.923C69.473 52.094 0 145.929 0 256z"
      ></path>
    </svg>
  );
}
