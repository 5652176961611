import React from 'react'
import {Link} from 'react-router-dom'
import {paths} from "../../views/MainPage/contains"

export const CategoriesXS = ({categories}) => {
  return (
    <div className="flex-1 space-x-1">
      {categories.map(e => {
        return (
          <Link
            to={paths.categoryPath.replace(
              ':category',
              e.id
            ).replace(
              ':refcategory',
              e.alt_idname
            )
            + "?lang=" + e.language}
            key={e.id}
            className="text-xs text-color-200 background-200 rounded-full m-0 h-5 px-3 py-1 no-underline">
            <span className="px-1 py-0 text-color-100 hover:text-primary ">{e.name}</span>
          </Link>
        )
      })}
    </div>
  )
}
