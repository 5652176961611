import { Avatar, Tooltip } from 'antd'
import { AppLogoSvg, AppLogoSvgGray } from 'appConfigs/svgs/AppLogoSvg'
import classNames from 'classnames'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import VerifiedSvg from 'components/Svgs/VerifiedSvg'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import { useAppConfig } from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React, { useContext, useEffect, useState } from 'react'
import OrganizationContainerContext from '../OrganizationContainerContext'
import {polygon} from "leaflet";

const OrganizationBanner = React.memo(({ Wrapper = 'div' }) => {
    const t = useTranslate()
    const { organization } = useContext(OrganizationContainerContext)
    const { isSm } = useContext(LayoutContext)

    const { org_types = {} } = useAppConfig()

    const cover_photo = _.get(organization, 'cover_photo')

    const photos = _.get(organization, 'photos', [])

    const all_photos = [cover_photo, ...photos].filter((e) => !!e)

    const org_type = _.get(org_types, _.get(organization, 'org_type') || '900')

    const verified = _.get(organization, 'certified')

    const [imageIndex, setImageIndex] = useState(0)

    const heightStyle = isSm ? '8rem' : '14rem'

    useEffect(() => {
        const interval = setInterval(() => {
            const newImageIndex =
                imageIndex === all_photos.length - 1 ? 0 : imageIndex + 1
            // console.log('This will be called every 2 seconds ', imageIndex, all_photos.length, newImageIndex);
            setImageIndex(newImageIndex)
        }, 8000)

        return () => clearInterval(interval)
    }, [imageIndex, all_photos])

    const RoundedSvg = React.memo(() => (
        <svg
            style={{visibility: 'hidden', position: 'absolute'}}
            width="0"
            height="0"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1">
            <defs>
                <filter id="round">
                    <feGaussianBlur
                        in="SourceGraphic"
                        stdDeviation={isSm ? '3' : '6'}
                        result="blur"
                    />
                    <feColorMatrix
                        in="blur"
                        mode="matrix"
                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
                        result="goo"
                    />
                    <feComposite
                        in="SourceGraphic"
                        in2="goo"
                        operator="atop"
                    />
                </filter>
            </defs>
        </svg>
    ))

    let shape = isSm ? 'polygon(130px 0px, 100% 0px, 100% 100%, 153px 100%, 167px 75px)' : 'polygon(210px 0px, 100% 0px, 100% 100%, 240px 100%, 275px 125px)'

    return !!all_photos.length ? (
        <div
            style={{ height: isSm ? 150 : 250 }}
            className="container mx-auto my-6 relative">
            <div
                className="absolute top-0"
                style={{
                    filter: 'url(#round) drop-shadow(1px 2px 2px var(--color-primary-800)',
                    width: isSm ? 150 : 250,
                    height: isSm ? 150 : 250,
                }}>
                <Avatar
                    shape="square"
                    className="flex flex-center background-200"
                    style={{ clipPath: 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)' }}
                    size={isSm ? 150 : 250}
                    icon={<AppLogoSvg size={isSm ? 50 : 60} />}
                    src={getAvatar(organization)}
                    alt={getTitle(organization)}
                />
                <RoundedSvg />
            </div>
            <div style={{ filter: 'url(#round) drop-shadow(1px 2px 2px var(--color-primary-800))' }}>
                <div
                    key={imageIndex}
                    style={{
                        width: '100%',
                        height: isSm ? 100 : 190,
                        objectFit: 'cover',
                        background: 'var(--background-100)',
                        backgroundImage: `url(${all_photos[imageIndex]})`,
                        backgroundSize: 'cover',
                        backgroundPosition: '50% 50%',
                        clipPath: shape,
                    }}
                    className="w-full fadeInRight animated object-cover"
                />
                <RoundedSvg />
            </div>
            {all_photos.length > 1 && (
                <div
                    style={{
                        background: 'rgba(0,0,0,0.1)',
                        borderTopRightRadius: '0.5rem',
                        borderBottomLeftRadius: '0.5rem',
                        right: isSm ? '1rem' : 0,
                    }}
                    className="absolute p-1 top-0 right-0">
                    {all_photos.map((e, index) => (
                        <span
                            key={index}
                            onClick={() => {setImageIndex(index)}}
                            className={classNames(
                                'mx-2 text-sm text-white cursor-pointer',
                                imageIndex === index ? 'font-bold' : 'opacity-50 hover:opacity-100 italic'
                            )}>
                            {index + 1}
                        </span>
                    ))}
                </div>
            )}
            <div
                style={{ left: isSm ? 150 : 230 }}
                className="absolute bottom-0 flex flex-col flex-1">
                <div className="uppercase font-medium text-2xs text-primary-600 tracking-wide opacity-75">
                    {t(org_type)}
                </div>
                <div style={{ marginLeft: isSm ? -13 : -15 }} className="font-bold md:font-extrabold text-base md:text-xl text-primary-800 max-lines-1 italic">
                    {getTitle(organization)}
                    {verified === 1 && (
                        <Tooltip
                            title={t('verified')}
                            placement="right"
                            color="#87d068">
                            <VerifiedSvg className="zoom-animation ml-3" />
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>
    ) : (
        <Wrapper className="flex flex-col w-full my-3 md:my-6">
            <div className="container mx-auto p-3 flex justify-start gap-4">
                <Avatar
                    shape="square"
                    style={{ boxShadow: '0px 0px 6px var(--color-primary-700)' }}
                    className="rounded-lg background flex flex-center p-3"
                    size={isSm ? 80 : 100}
                    icon={<AppLogoSvg size={isSm ? 40 : 50} />}
                    src={getAvatar(organization)}
                    alt={getTitle(organization)}
                />
                <div className="flex flex-col flex-1 gap-1 my-2">
                    <div className="uppercase text-xs text-primary-600 opacity-75 font-medium tracking-wide">
                        {t(org_type)}
                    </div>
                    <h1 id="title" className="font-extrabold text-normal md:text-3xl text-primary-700">
                        {getTitle(organization)}
                        {verified === 1 && (
                            <Tooltip
                                title={t('verified')}
                                placement="right"
                                color="#87d068">
                                <VerifiedSvg className="zoom-animation ml-3" />
                            </Tooltip>
                        )}
                    </h1>
                </div>
            </div>
        </Wrapper>
    )
})

export default OrganizationBanner
