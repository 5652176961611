import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../LoadingPage";

const WaitingAcceptMembersLazy = Loadable({
  loader: () => import('./WaitingAcceptMembers'),
  loading: LoadingPage
})

const WaitingAcceptMembers = (props) => (
  <WaitingAcceptMembersLazy {...props} />
)

export default WaitingAcceptMembers
