import SeparatedDot from 'components/SeparatedDot'
import getItemAltId from 'helpers/getItemAltId'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import { nest } from 'recompose'
import {SeeMore} from "../MainPage/MySpace/Home";
import {useHistory} from "../../modules/navigation/useRouter";

const SmallFeedView = ({ item }) => {
  return (
    <Link
      key={item.id}
      to={{
        pathname: `/news/${item._type}/${item.id}`
      }}
      className="p-2 no-underline flex items-center gap-1 border-b border-color-50 overflow-hidden">
        <SeparatedDot className="px-2 text-green-600" />
        <span className="flex-1 font-medium text-xs text-color-100 hover:text-primary truncate leading-tight">
            {_.get(item, 'overview.title')}
        </span>
    </Link>
  )
}

export const FeedMoreRecommends = nest(
  ({ list, item }) => {
      const t = useTranslate()
      const history = useHistory()

      return (
          <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                  {list.map((e, i) => (
                      <SmallFeedView
                          key={i}
                          item={e}
                      />
                  ))}
              </div>
              {!!item && (
                  <SeeMore
                      onClick={() => history.push(`/${item._type}/${getItemAltId(item)}`)}
                  />
              )}
          </div>
      )
  }
)
