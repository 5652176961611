import {message} from 'antd'
import {getId, getType} from 'apis/model/base'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import {permissionCodes} from 'modules/permissions/contants'
import React, {useCallback, useContext, useEffect, useMemo, useState,} from 'react'
import {IoSettingsOutline} from 'react-icons/io5'
import {useSearchParam} from 'react-use'
import {compose} from 'recompose'
import {ModalLayout} from 'routes/createRequiredLogin'
import {staticPaths} from 'routes/staticPaths'
import {Null, renderElse, renderIf} from 'views/Shared'
import {Queries} from 'views/Shared/enums/Queries'
import {withAccessPermissions} from './composers'
import {modalWidth, ModuleContext} from './configs'
import ActionMenu from './custom/ActionMenu'
import {fromAppEntity} from './enums'
import {ContextActionTypes} from './factory/createContextMenu'
import {ExporterLoader} from './factory/createDataExporter'
import gModel from './gModel'
import {ImporterLoader} from "./factory/createDataImporter";

export const useData = ({item}) => {
  const history = useHistory()

  const [action, setAction] = useState({
    type: undefined,
    value: undefined,
  })

  const [id, type] = [getId(item), getType(item)]

  const searchParams = new URLSearchParams(_.get(history, 'location.search'))

  const action_query = useSearchParam(Queries.ACTION)

  const type_query = useSearchParam(Queries.TYPE)

  useEffect(() => {
    setAction({
      type: type_query,
      value: getId(item),
    })
  }, [history.location])

  const onCancel = () => {
    setAction({})
    searchParams.delete(Queries.TYPE)
    searchParams.delete(Queries.ACTION)
    history.replace({
      search: '?' + searchParams.toString(),
      hash: _.get(history, 'location.hash'),
    })
  }

  const onImport = (query_type = type) => {
    searchParams.set(Queries.ACTION, 'import')
    searchParams.set(Queries.TYPE, query_type)
    history.replace({
      search: '?' + searchParams.toString(),
      hash: _.get(history, 'location.hash'),
      state: {
        id,
        type: query_type,
        isModal: true,
      },
    })
  }

  const onExport = (query_type = type) => {
    searchParams.set(Queries.ACTION, 'export')
    searchParams.set(Queries.TYPE, query_type)
    history.replace({
      search: '?' + searchParams.toString(),
      hash: _.get(history, 'location.hash'),
      state: {
        id,
        type: query_type,
        isModal: true,
      },
    })
  }

  const modal_content = useMemo(() => {
    const {value, type} = action;
    let body = null
    if (action_query === 'import') {
      body = (
        <ImporterLoader
          id={value}
          type={type}
          onCancel={onCancel}
        />
      )
    } else if (action_query === 'export') {
      body = (
        <ExporterLoader
          id={value}
          type={type}
        />
      )
    }
    return (
      [
        "COURSE_STUDENT_EXPORT", "COURSE_ATTENDANCE_EXPORT", "COURSE_GRADE_EXPORT",
        "COURSE_STUDENT_IMPORT", "COURSE_ATTENDANCE_IMPORT", "COURSE_GRADE_IMPORT"
      ].includes(action.type) && (
        <ModalLayout
          footer={null}
          onCancel={onCancel}
          maskClosable={false}
          visible={action.type && action_query}
          width={modalWidth.small}
          title={renderElse(
            _.isEmpty(action_query),
            <Translate>
              {(t) => (
                <div className="flex gap-2 flex-center font-bold uppercase">
                  {t(action_query)}
                </div>
              )}
            </Translate>
          )}>
          {body}
        </ModalLayout>
      )
    )
  }, [searchParams, action_query, action])

  return [
    modal_content,
    {
      onImport,
      onExport,
      searchParams,
      action_query,
      current_action: action,
    },
  ]
}

const AdminPanel = ({
                      Wrapper = 'div',
                      goToSetting = ({path, push = Null}) => {
                        push(path)
                      },
                    }) => {
  const t = useTranslate()

  const history = useHistory()

  const {value} = useContext(ModuleContext)

  const {entity, Context} = value

  const currentContext = useContext(Context)

  const {actions} = _.get(value, 'settings') || {}

  const {contextName} = fromAppEntity(entity)

  const item = _.get(currentContext, contextName)

  const withOwnerQueries = (owner, params = {}) => {
    if (_.isEmpty(owner)) {
      return undefined
    }
    let searchParams = new URLSearchParams({
      owner_id: getId(owner),
      owner_type: getType(owner),
      ...params,
    })
    return `?${searchParams.toString()}`
  }

  const [modalIO, {onExport = Null, onImport = Null}] = useData({item})

  const handleClick = useCallback(
    (action) => {
      const _searchQueries = withOwnerQueries(item)
      const [id, type] = [getId(item), getType(item)]
      switch (action) {
        case ContextActionTypes.EXPORT:
          if ([].includes(type)) {
            onExport()
          } else {
            message.warn('coming soon')
          }
          break
        case "COURSE_ATTENDANCE_EXPORT":
        case "COURSE_GRADE_EXPORT":
        case "COURSE_STUDENT_EXPORT":
          onExport(action)
          break
        case "COURSE_ATTENDANCE_IMPORT":
        case "COURSE_GRADE_IMPORT":
        case "COURSE_STUDENT_IMPORT":
          onImport(action)
          break
        case ContextActionTypes.SETTINGS:
          goToSetting({
            push: history.push,
            path: gModel.getLinkToSetting(item),
          })
          break
        case ContextActionTypes.CREATE_CHANNEL:
          history.push({
            pathname: staticPaths.createChannel.pathname,
            search: _searchQueries,
          })
          break
        case ContextActionTypes.CREATE_EVENT:
          history.push({
            pathname: staticPaths.createEvent.pathname,
            search: _searchQueries,
          })
          break
        case ContextActionTypes.CREATE_ORGANIZATION:
          history.push(staticPaths.createOrganization)
          break
        default:
          message.warning(
            <span>
              <span className="font-bold">{'Admin panel'}</span>
              <span>{' missing action handler'}</span>
            </span>
          )
          break
      }
    },
    [item, history]
  )

  if (_.isEmpty(value)) return null

  return (
    <Wrapper className="flex items-center px-3 py-1 my-2 border border-primary bg-primary-50 rounded-md">
      <span className="text-primary capitalize font-bold flex-1">
        {t('administration panel')}
      </span>
      {!_.isEmpty(actions) && (
        <div className="cursor-pointer hover:text-primary">
          <ActionMenu
            actions={actions}
            onClick={handleClick}
          />
        </div>
      )}
      {modalIO ? modalIO : null}
    </Wrapper>
  )
}

export const ActionPanel = ({
                              item,
                              title = 'administration panel',
                              actions = [
                                {
                                  key: ContextActionTypes.SETTINGS,
                                  label: 'setting',
                                  icon: <IoSettingsOutline/>,
                                },
                              ],
                            }) => {
  const t = useTranslate()

  const history = useHistory()

  const handleClick = (action) => {
    const [id, type] = [getId(item), getType(item)]
    switch (action) {
      case ContextActionTypes.SETTINGS:
        history.push(gModel.getLinkToSetting(item))
        break
      case ContextActionTypes.EXPORT:
        if ([].includes(type)) {
          onExport()
        } else {
          message.warn('coming soon')
        }
        break
      case "COURSE_ATTENDANCE_EXPORT":
      case "COURSE_GRADE_EXPORT":
      case "COURSE_STUDENT_EXPORT":
        onExport(action)
        break
      case "COURSE_ATTENDANCE_IMPORT":
      case "COURSE_GRADE_IMPORT":
      case "COURSE_STUDENT_IMPORT":
        onImport(action)
        break
      default:
        break
    }
  }

  const [modalIO, {onExport = Null, onImport = Null}] = useData({item})

  return renderIf(
    item?.edit,
    <div className="flex items-center px-3 py-1 my-2 border border-primary bg-primary-50 rounded-md">
      <span className="text-primary capitalize font-bold flex-1">
        {t(title)}
      </span>
      {!_.isEmpty(actions) && (
        <div className="cursor-pointer hover:text-primary">
          <ActionMenu
            actions={actions}
            onClick={handleClick}
          />
        </div>
      )}
      {modalIO ? modalIO : null}
    </div>
  )
}

export default compose(withAccessPermissions([permissionCodes.canEdit], Null))(
  AdminPanel
)
