import {SelectEntityItem} from 'components/SelectEntityItem'
import UseState from 'components/UseState'
import _ from 'lodash'
import React from 'react'
import {createBaseItemSchema} from '../../../apis/schema'
import {createTransform} from '../../../apis/utils/createTransform'
import {API_ROOT_URL} from '../../../envs/_current/config'
import Async from '../../../modules/asyncCache/components/Async'
import TransformToCardProps from './TransformToCardProps'
import {HighLightTitle} from "../../AppLocals/Organization/OrganizationEntity";

export const WidgetListByCategory = ({
  categoryId,
  title
}) => {
  const path = `/widgets/container/category/${categoryId}/gets`
  return (
    <>
      <Async
        key={path}
        apiInfo={{
          root: API_ROOT_URL,
          path: path,
          transform: createTransform(
            createBaseItemSchema({
              idAttribute: () => path
            }),
            'data'
          )
        }}
      />
      <SelectEntityItem item={path}>
        {item =>
          (item || null) &&
          !_.isEmpty(_.get(item, 'data')) &&
          !!_.get(item, 'data.length') && (
            <div className="space-y-3">
                <HighLightTitle title={title}/>
              {_.get(item, 'data', []).map(
                (data, i) => (
                  <UseState
                    key={i}
                    initialValue={'0'}>
                    {([
                      activeKey,
                      setActiveKey
                    ]) => {
                      return (
                        <TransformToCardProps
                          key={i}
                          data={data}
                        />
                      )
                    }}
                  </UseState>
                )
              )}
            </div>
          )
        }
      </SelectEntityItem>
    </>
  )
}
