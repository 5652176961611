import React, {useState} from 'react'

const SlateEditorContext = React.createContext({})

export const SlateEditorProvider = ({value, children}) => {
  const [values, setValues] = useState({})
  const set = (name, value) => {
    setValues({
      ...(values ?? {}),
      [name]: value,
    })
  }
  return (
    <SlateEditorContext.Provider
      value={{
        ...value,
        set,
        values,
        setValues,
      }}>
      {children}
    </SlateEditorContext.Provider>
  )
}

export default SlateEditorContext
