import {Button, Col, Input, Popconfirm, Row} from 'antd'
import FieldDecorator from 'components/form/FieldDecorator'
import {FormikContext} from 'formik'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'

function Delete() {
    const t = useTranslate()
    return (
        <FormikContext.Consumer>
            {({
                  handleChange,
                  handleSubmit,
                  values
              }) => (
                <div className="flex flex-col gap-3">
                    <FieldDecorator
                        name="title"
                        isRequired
                        label={t('please enter name of product')}>
                        <Input
                            name="title"
                            onChange={handleChange}
                        />
                    </FieldDecorator>

                    <div className="flex justify-end">
                        <Popconfirm
                            title={
                                <div className="font-medium text-sm text-color-100 italic">
                                    {t('do you want delete this product')}
                                </div>
                            }
                            placement="left"
                            onConfirm={() => handleSubmit()}
                            okText={t('sure')}
                            cancelText={t('no')}
                            okButtonProps={{
                                type: "primary",
                                danger: true,
                                className: 'button-rounded-md no-border text-xs font-medium',
                            }}
                            cancelButtonProps={{
                                className: 'button-rounded-md no-border text-xs font-medium',
                            }}>
                            <Button
                                type="primary"
                                danger
                                className="button-rounded-md no-border">
                                {t('delete')}
                            </Button>
                        </Popconfirm>

                    </div>
                </div>
            )}
        </FormikContext.Consumer>
    )
}

export default Delete
