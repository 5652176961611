import React, {
  lazy,
  Suspense
} from 'react'

const GlobalAmchartConfigLazy = lazy(() =>
  import('./GlobalAmchartConfig')
)

const GlobalAmchartConfig = props => (
  <Suspense fallback={null}>
    <GlobalAmchartConfigLazy {...props} />
  </Suspense>
)

export default GlobalAmchartConfig
