import React from 'react'
import {useSelectEntities} from 'redux/entities/useSelectEntities'
import {useRouteMatch} from 'react-router-dom'
import {SubDomainContext} from "./SubDomainContext";
import {organization_validateSubDomain_Api} from "../../apis";
import _ from "lodash"
import {originDomains, subDomains} from "./constSubDomain";
import {ROOT_URL} from "../../envs/_current/config";
import {organizationSchema} from "../../apis/schema";
import useAsyncWithCache from "../../modules/asyncCache/useAsyncWithCache";
import {bindQueryParam} from "../AppLocals/functions/routerHelper";
import {getResponseItem} from "../Shared";

const SubDomainProvider = ({
                             children
                           }) => {
  const parts = window.location.hostname.split('.');
  let last_index = -2;
  const last = parts[parts.length - 1];
  const is_localhost = last === 'localhost';
  if (is_localhost) {
    last_index = -1;
  }
  const subDomainString = parts
    .slice(0, last_index)
    .join('.')
  const subdomain = !!originDomains[
    subDomainString
    ]
    ? ''
    : subDomainString


  const {response, isLoading} =
    useAsyncWithCache({
      cacheId:
        'sub_domain_' + subdomain,
      apiInfo:
      organization_validateSubDomain_Api,
      query: bindQueryParam({
        id: subdomain,
      }),
    })

  const cache =
    getResponseItem(response)

  const organization_idname =
    _.get(cache, 'idname') || subdomain
  const item = useSelectEntities(
    organization_idname,
    organizationSchema
  ) || {
    sub_domain:
      !!subDomains[subdomain || '0'],
  }

  const {url} = useRouteMatch();

  if (
    !originDomains[subdomain] &&
    !!subdomain &&
    !!item &&
    !!_.get(item, 'sub_domain') &&
    window.location.origin !== ROOT_URL
  ) {
    return (
      <SubDomainContext.Provider
        value={{
          isSubDomain: true,
          id: subdomain,
          subDomainItem: item,
          url: url,
          url_domain: window.location.origin,
          url_pathname: window.location.pathname,
          diff_root: window.location.origin !== ROOT_URL,
          url_og_domain: ROOT_URL + window.location.pathname,
        }}>
        {children}
      </SubDomainContext.Provider>
    )
  }
  // DOMAIN
  return (
    <SubDomainContext.Provider
      value={{
        isSubDomain: false,
        id: subdomain,
        subDomainItem: null,
        url: url,
        url_domain: window.location.origin,
        url_pathname: window.location.pathname,
        diff_root: !!subdomain && window.location.origin !== ROOT_URL,
        url_og_domain: ROOT_URL + window.location.pathname,
      }}>
      {children}
    </SubDomainContext.Provider>
  )
}

export default SubDomainProvider
