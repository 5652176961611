import React from 'react'
import {withProps} from 'recompose'
import {Node} from 'slate'
import {toBase64} from '../functions'
import {ElementTypes} from '../types'

const HeadingElement = ({
                          style,
                          element,
                          children,
                          attributes,
                          Component = 'h1',
                        }) => {
  const text = Node.string(element)
  return (
    <Component
      {...attributes}
      style={style}
      id={toBase64(text)}>
      {children}
    </Component>
  )
}

export const HeadingOneElement = withProps({Component: 'h2'})(HeadingElement)

export const HeadingTwoElement = withProps({Component: 'h3'})(HeadingElement)

export const HeadingThreeElement = withProps({Component: 'h4'})(
  HeadingElement
)

export const headings = [
  ElementTypes.HEADING_ONE,
  ElementTypes.HEADING_TWO,
  ElementTypes.HEADING_THREE,
]

export default HeadingElement
