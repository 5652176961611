import { Avatar, List } from 'antd'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import { IoPerson } from 'react-icons/io5'
import { Link } from 'react-router-dom'

export const renderHomeComment = (item, index) => {
  const { creator, message, total_votes, total_replies } = item || {}

  return (
    <Translate key={index}>
      {(t) => (
          <div className="flex items-center gap-2 md:gap-3">
            <Avatar
                src={_.get(item, 'creator.avatar')}
                size={40}
                icon={<IoPerson className="text-color-500" />}
                className="flex items-center justify-center background-200"
            />
            <div className="flex flex-col flex-1 truncate">
              <div className="flex items-baseline italic gap-1">
                <Link
                    to={getLinkToDetail(creator)}
                    className="font-medium text-xs text-primary-600 max-lines-1 leading-tight">
                  {_.get(item, 'creator.name')}
                </Link>
                <span className="text-2xs text-color-400 whitespace-no-wrap">
                  {t('commented')}
                </span>
                <span className="font-medium text-xs text-color-100 max-lines-1 leading-tight">
                  {`"${message}"`}
                </span>
              </div>
              <div className="flex items-baseline gap-1">
                <span className="text-2xs italic text-color-400 whitespace-no-wrap">
                    {t('in post')}
                </span>
                <Link
                    to={getLinkToDetail(_.get(item, 'container'))}
                    className="font-bold text-sm text-color-000 no-underline leading-tight truncate">
                  {_.get(item, 'container.title')}
                </Link>
              </div>
            </div>
          </div>
      )}
    </Translate>
  )
}
