import _ from 'lodash'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {BsCircleFill, BsDot, BsFillDiamondFill} from "react-icons/bs";


export const ProductPriceSetInfo = ({product, productPriceSet, location}) => {
  const t = useTranslate();

  const {
    contract_types = [],
    delivery_types = [],
    incoterm_types = [],
    transport_types = [],
  } = useAppConfig();


  return (
    <React.Fragment>

      {!!product && (
          <div className="flex items-center gap-1 text-sm">
            <BsCircleFill size={7} className="text-color-400 mr-2"/>
            <span className="text-color-400 italic">
                {`${t('product')} : `}
              </span>
            <span className="font-bold text-color-100">
                {_.get(product, 'title')}
              </span>
          </div>
      )}

      {
        !!productPriceSet && (
          <React.Fragment>
            {
              productPriceSet.contract_type_cd !== 'spot' ? (
                  <div className="flex items-center gap-1 text-sm">
                    <BsCircleFill size={7} className="text-color-400 mr-2"/>
                    <span className="text-color-400 italic">
                      {`${t('delivery type')} : `}
                    </span>
                    <div className="font-bold text-color-100">
                      {delivery_types[_.get(productPriceSet, 'delivery_type_cd')]}
                    </div>
                  </div>
              ) : (
                  <div className="flex items-center gap-1 text-sm">
                    <BsCircleFill size={7} className="text-color-400 mr-2"/>
                    <span className="text-color-400 italic">
                      {`${t('price unit')} : `}
                    </span>
                    <div className="font-bold text-color-100">
                      {_.get(productPriceSet, 'price_unit.name', t('price unit'))}/{_.get(productPriceSet, 'unit.name', t('unit'))}
                    </div>
                  </div>
              )
            }

            {productPriceSet.contract_type_cd !== 'spot' && (
                <div className="flex justify-between gap-3">
                  <div className="flex items-center gap-1 text-sm">
                    <BsCircleFill size={7} className="text-color-400 mr-2"/>
                    <span className="text-color-400 italic">
                      {`${t('incoterm')} : `}
                    </span>
                    {!_.isEmpty(productPriceSet.incoterm_type_cd) && (
                        <div className="font-bold text-color-100">
                          {_.has(productPriceSet, 'incoterm_type_cd') ? incoterm_types[_.get(productPriceSet, 'incoterm_type_cd')] : t('incoterm')}
                        </div>
                    )}
                  </div>
                  <div className="flex items-center gap-1 text-sm">
                    <BsCircleFill size={7} className="text-color-400 mr-2"/>
                    <span className="text-color-400 italic">
                      {`${t('transport type')} : `}
                    </span>
                    {!_.isEmpty(productPriceSet.transport_type_cd) && (
                        <div className="font-bold text-color-100">
                          {_.has(productPriceSet, 'transport_type_cd') ? transport_types[_.get(productPriceSet, 'transport_type_cd')] : t('transport type')}
                        </div>
                    )}
                  </div>
                </div>
            )}
          </React.Fragment>
        )
      }

      {
        !!location && (
            <div className="flex items-center gap-1 text-sm">
              <BsCircleFill size={7} className="text-color-400 mr-2"/>
              <span className="text-color-400 italic">
                {`${t('location')} : `}
              </span>
              <div className="font-bold text-color-100">
                {_.get(location, 'location_name')}
              </div>
            </div>
        )
      }

    </React.Fragment>
  )
}
