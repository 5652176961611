import classNames from 'classnames'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useState} from 'react'
import {SelectWidget} from 'views/AppLocals/custom/ReactSelectComponents'
import {createValue} from '../utils'

const DefaultWrapper = ({style, children, className}) => (
  <div
    style={style}
    className={classNames(
      'flex flex-col px-2 py-1 border rounded-lg background',
      className
    )}>
    {children}
  </div>
)

export const OriginalObjectSelector = ({
                                         label,
                                         style,
                                         onSearch,
                                         Wrapper = DefaultWrapper,
                                         ...props
                                       }) => {
  const t = useTranslate()

  const [search, setSearch] = useState()

  return (
    <Wrapper
      {...{style, selected: _.some([props.value, props.defaultValue])}}>
      {label && (
        <div className="text-xs italic text-color-400 px-2 mb-1">
          {t(label)}
        </div>
      )}
      <SelectWidget
        placeholder={t('click to select')}
        {...props}
        onSearch={
          _.isFunction(onSearch)
            ? (text) => {
              setSearch(text)
              onSearch(text)
            }
            : undefined
        }
        style={{
          caretColor: _.isEmpty(search) ? 'transparent' : 'auto',
          ...(style ?? {}),
        }}
      />
    </Wrapper>
  )
}

export const ObjectSelectField = ({
                                    label,
                                    onChange,
                                    invisible,
                                    onOptionChange,
                                    Wrapper = DefaultWrapper,
                                    ...props
                                  }) => (
  <OriginalObjectSelector
    label={label}
    Wrapper={Wrapper}
    onChange={(value, data) => {
      if (_.isFunction(onOptionChange)) {
        onOptionChange(data?.option ?? data)
        return
      }
      const {id, type} = data ?? {}
      onChange(createValue(props.name + '_type', type))
      onChange(createValue(props.name + '_id', id))
      onChange(createValue(props.name, value))
    }}
    {...props}
  />
)
