import classnames from 'classnames'
import Count from 'components/Feed/Count'
import Null from 'components/NullComponent'
import useRouter from 'modules/navigation/useRouter'
import React from 'react'
import './PageHeader.css'
import {VscArrowLeft} from "react-icons/vsc";


export const HeaderButton = ({
    component,
    className,
    round = 'full',
    innerClass,
    style = {},
    width = 50,
    height = 50,
    iconStyle = {},
    icon,
    onClick,
    children,
    ...props
}) => {
  return React.createElement(
    component ? component : 'div',
    {
      onClick,
      className: classnames(
        'flex justify-center items-center flex-shrink-0',
        className
      ),
      style: {
        width,
        height: height,
        ...style
      },
      children: (
        <div
          data-effect="ripple"
          className={classnames(
            'flex flex-center bg-primary-50 hover:bg-primary-100 text-primary hover:text-primary-700 cursor-pointer',
            `rounded-${round}`,
            innerClass
          )}
          style={{
              width: width,
              height: height,
          }}>
          {icon && icon}
          {children}
        </div>
      ),
      ...props
    }
  )
}
export const PageHeader = ({
                             title,
                             onClose = Null,
                             extra
                           }) => {
  const {
    canBack,
    history,
    handleGoBack
  } = useRouter()
  const handleBackClick = () => {
    return handleGoBack()
  }
  return (
    <div className="PageHeader flex background stickyTop items-center border-b border-gray-300">
      <Count
        className="p-5 px-5 text-lg"
        onClick={handleBackClick}>
        <VscArrowLeft/>
      </Count>
      <span className="capitalize font-semibold ml-2 truncate flex items-center flex-1">
        {title}
      </span>
      {extra}
    </div>
  )
}
