import {Avatar} from 'antd'
import {getType} from 'apis/model/base'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {AppEntities, fromAppEntity} from 'views/AppLocals/enums'
import EventContext from 'views/AppLocals/Event/EventContext'
import OrganizationContainerContext from 'views/AppLocals/Organization/OrganizationContainerContext'
import {LoadingWrapper, renderElse, renderOwnChild} from 'views/Shared'
import {OrgType} from '../../Organization/components/OrgType'
import {MdGroups} from "react-icons/md";

const renderHostByItem = ({host, owner, host_name, ...rest}, index) => {
  const title = host_name || getTitle(host)
  const type = getType(host)
  return (
    <Translate key={index}>
      {(t) => (
        <div className="flex items-center gap-2 mb-2">
          <Avatar
            className="flex flex-center background-200 border border-color-50 rounded-md"
            icon={<MdGroups className="text-color-300"/>}
            src={_.get(host, 'avatar')}
          />
          <div className="flex flex-col flex-1">
            <Link
              className="font-semibold text-sm text-primary-500 max-lines-1 no-underline"
              to={getLinkToDetail(host)}>
              {title}
            </Link>
            <span className="text-xs text-color-500 italic leading-tight">
              {type === 'organization' ? <OrgType item={host}/> : t(type)}
            </span>
          </div>
        </div>
      )}
    </Translate>
  )
}

const createHostList =
  (type, Context) =>
    ({id_field = 'id', host_field = 'hosts', Wrapper = renderOwnChild}) => {
      const t = useTranslate()

      const context = useContext(Context)

      const {contextName} = fromAppEntity(type)

      const entity = _.get(context, contextName)

      const items = Array.from(_.get(entity, host_field) ?? [])

      return renderElse(
        _.isEmpty(items),
        <Wrapper>
          <LoadingWrapper isLoading={_.isEmpty(items)}>
            <div className="flex flex-col">
              <span className="uppercase text-sm font-medium text-color-400 border-b mb-2">
                  {t('co-hosts')}
              </span>
              {items.map(renderHostByItem)}
            </div>
          </LoadingWrapper>
        </Wrapper>
      )
    }

export const OrganizationHosts = createHostList(
  AppEntities.ORGANIZATION,
  OrganizationContainerContext
)

export const EventHosts = createHostList(AppEntities.EVENT, EventContext)
