import NullComponent from './NullComponent'

export function UseHook({
  hook,
                          initialValue,
  deps,
                          children = NullComponent
}) {
  const hooked = hook(initialValue, deps)
  return children(hooked)
}
