import {Avatar, Badge, Button, Input, message, Tag} from 'antd'
import {referral_getReferralCode_Api} from 'apis'
import {organizationModel} from 'apis/model'
import ImageLoader from 'components/ImageLoader'
import {UpdateReferalCode} from 'components/UpdateReferalCode'
import copyToClipboard from 'copy-to-clipboard'
import {ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useLayoutEffect} from 'react'
import {Link} from 'react-router-dom'
import useToggle from 'react-use/lib/useToggle'
import LoadingPage from 'views/LoadingPage'
import getLinkToDetail from "../../../helpers/getLinkToDetail";
import {getFullAvatarUrl} from "../../../apis/model/base";
import {renderIf} from "../../Shared";
import {useAppConfig} from "../../../modules/local";
import {BsDiamondFill} from "react-icons/bs";
import {FaWarehouse} from "react-icons/fa6";

const Invite = ({ item }) => {
  const t = useTranslate()

    const { company_types } = useAppConfig()

  const [isToggle, toggle] = useToggle(
    false
  )
  const [
    isToggleCopy2,
    toggleCopy2
  ] = useToggle(false)
  const { response } = useAsync({
    apiInfo: referral_getReferralCode_Api,
    values: {
      target_id: item.id,
      target_type: item._type
    }
  })
  useLayoutEffect(() => {
    let timeout
    if (isToggle) {
      timeout = setTimeout(
        () => toggle(false),
        800
      )
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isToggle, toggle])

  useLayoutEffect(() => {
    let timeout
    if (isToggleCopy2) {
      timeout = setTimeout(
        () => toggleCopy2(false),
        800
      )
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [isToggleCopy2, toggleCopy2])

  const data = _.get(response, 'data')
  if (!data) return <LoadingPage />
  const inviteLink =
    ROOT_URL +
    '/invite/organization/' +
    _.get(data, 'referral_code')
  const timeout = moment(
    _.get(data, 'created')
  )
    .add(
      _.get(data, 'timeout'),
      'second'
    )
    .fromNow()

    const company_type = _.get(company_types, _.get(item, 'company_type'))

  return (
    <div className="flex flex-col gap-3 pb-5">
      <UpdateReferalCode referral={data} />
      <div className="p-2 w-full flex items-center gap-3 rounded-lg border-2 border-primary-600">
          <Avatar
              size={80}
              icon={<FaWarehouse className="text-color-400" />}
              src={getFullAvatarUrl(item)}
              className="flex flex-center background-200 rounded-md border border-color-50"
          />
          <div className="flex flex-col flex-1 gap-1">
              <Link
                  to={getLinkToDetail(item)}
                  className="font-bold text-lg text-color-000 leading-tight no-underline">
                  {item.title}
              </Link>
              {renderIf(
                  company_type,
                  <span className="font-medium text-xs text-primary italic">{t(company_type)}</span>
              )}
              <div className="flex items-center gap-2">
                  <BsDiamondFill size={7} className="text-primary"/>
                  <span className="font-medium text-xs text-color-300 italic">
                      <span className="font-bold text-color-000 mr-1">
                          {item.total_members}
                      </span>
                      {t('members')}
                  </span>
              </div>
          </div>
      </div>
      <div className="text-xs text-color-400 italic mb-6">
        {item.description}
      </div>
      <div className="space-y-4">
          <div className="flex flex-col gap-2">
              <span className="font-medium text-sm text-color-100 italic">
                  {t('Send invitation to your friends, by sending a copy of the page link below')}
              </span>
              <Input
                  className="rounded-lg"
                  value={inviteLink}
                  suffix={
                      <Button
                          size="small"
                          type="primary"
                          className="button-rounded-md no-border font-medium text-xs"
                          onClick={() => {
                              copyToClipboard(inviteLink)
                              message.success(t('copied'))
                              toggle(true)
                          }}>
                          {t(isToggle ? 'copied' : 'copy')}
                      </Button>
                  }
              />
              <div className="text-xs text-color-400 italic">
                  {_.get(data, 'timeout', 0) ===
                  0 ||
                  _.get(
                      data,
                      'no_expired',
                      0
                  ) === 1
                      ? t('Permanent link')
                      : `${t(
                          'your invite link expires'
                      )} ${timeout}`}
              </div>
          </div>
          <div className="flex flex-col gap-2">
              <span className="font-medium text-sm text-color-100 italic">
                  {t('Copy and share the page link below to your friends.')}
              </span>
              <Input
                  value={ROOT_URL + organizationModel.getLinkToDetail(item)}
                  className="rounded-lg"
                  suffix={
                      <Button
                          size="small"
                          type="primary"
                          className="button-rounded-md no-border font-medium text-xs"
                          onClick={() => {
                              copyToClipboard(ROOT_URL + organizationModel.getLinkToDetail(item))
                              message.success(t('copied'))
                              toggleCopy2(true)
                          }}>
                          {t(isToggleCopy2 ? 'copied' : 'copy')}
                      </Button>
                  }
              />
          </div>
      </div>
    </div>
  )
}
export default Invite
