import { API_ROOT_URL } from 'envs/_current/config'
import {
  normalize,
  schema
} from 'normalizr'
export const rolesByItemSchema = new schema.Entity(
  'rolesByItems',
  {},
  {
    idAttribute: 'id'
  }
)
// Route::post('roles/role/{id}/edit', 'RoleController@update');
export const role_update_Api = {
  name: 'role_update',
  backend:
    "Route::post('roles/role/{id}/edit', 'RoleController@update')",
  root: API_ROOT_URL,
  path: '/roles/role/:id/edit',
  method: 'POST',
  transform: (response, { query }) => {
    const type =
      response.data.owner._type
    const id = response.data.owner.id
    const rolesByItem = {
      id: `${type}_${id}`,
      roles: {
        [response.data.id]: {
          ...response.data,
          _permissions: response.data.permissions.reduce(
            (result, permission) => {
              result[
                permission.idname
              ] =
                permission.permission_value
              return result
            },
            {}
          )
        }
      }
    }
    const {
      entities,
      result
    } = normalize(
      rolesByItem,
      rolesByItemSchema
    )
    return {
      response,
      entities,
      result
    }
  }
}

// Route::post('roles/role/{id}/delete', 'RoleController@delete');
export const role_delete_Api = {
  name: 'role_delete',
  backend:
    "Route::post('roles/role/{id}/delete', 'RoleController@delete')",
  root: API_ROOT_URL,
  path: '/roles/role/:id/delete',
  method: 'POST',
  transform: (response, { query }) => {
    const rolesByItem = {
      id: `${query[':containerType']}_${query[':containerId']}`,
      roles: {
        [query[':id']]: {
          isDeleted: true
        }
      }
    }
    const {
      entities,
      result
    } = normalize(
      rolesByItem,
      rolesByItemSchema
    )
    return {
      response,
      entities,
      result: response.data.id
    }
  }
}
// Route::get('roles/role/{id}/get', 'RoleController@getById');
export const role_getByid_Api = {
  name: 'role_getByid',
  backend:
    "Route::get('roles/role/{id}/get', 'RoleController@getById')",
  root: API_ROOT_URL,
  path: '/roles/role/:id/get',
  method: 'GET'
}
// Route::post('roles/role/{prop}/{id}/create', 'RoleController@create');
export const role_create_Api = {
  name: 'role_create',
  backend:
    "Route::post('roles/role/{prop}/{id}/create', 'RoleController@create')",
  root: API_ROOT_URL,
  path: '/roles/role/:prop/:id/create',
  method: 'POST',
  transform: (response, { query }) => {
    const rolesByItem = {
      id: `${query[':prop']}_${query[':id']}`,
      roles: {
        [response.data.id]: {
          ...response.data,
          _permissions: response.data.permissions.reduce(
            (result, permission) => {
              result[
                permission.idname
              ] =
                permission.permission_value
              return result
            },
            {}
          )
        }
      }
    }
    const {
      entities,
      result
    } = normalize(
      rolesByItem,
      rolesByItemSchema
    )
    return {
      response,
      entities,
      result: response.data.id
    }
  }
}

// Route::get('roles/role/{prop}/{id}/gets', 'RoleController@getRoles');
export const role_getRoles_Api = {
  name: 'role_getRoles',
  backend:
    "Route::get('roles/role/{prop}/{id}/gets', 'RoleController@getRoles');",
  root: API_ROOT_URL,
  path: '/roles/role/:prop/:id/gets',
  method: 'GET',
  schema: rolesByItemSchema,
  transform: (response, { query }) => {
    const rolesByItem = {
      id: `${query[':prop']}_${query[':id']}`,
      roles: response.data.reduce(
        (result, role) => {
          result[role.id] = {
            ...role,
            _permissions: role.permissions.reduce(
              (result, permission) => {
                result[
                  permission.idname
                ] =
                  permission.permission_value
                return result
              },
              {}
            )
          }
          return result
        },
        {}
      )
    }
    const {
      entities,
      result
    } = normalize(
      rolesByItem,
      rolesByItemSchema
    )
    return {
      response,
      entities,
      result
    }
  }
}

// Route::post('roles/assign-role/{role_id}/{member_id}/assign', 'RoleController@assignRoleToUser');
// Route::post('roles/assign-role/{role_id}/{member_id}/remove', 'RoleController@removeRoleFromUser');
