import {Avatar, Button, message} from 'antd'
import {auth_updateFollowedCategories_Api} from 'apis'
import Null from 'components/NullComponent'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo, useState} from 'react'
import {useHistory} from 'react-router-dom'
import 'views/SelectCategory'
import Loading from '../../components/Loading'
import {useAppConfig} from '../../modules/local'
import {useRequiredLogin} from '../Server/useRequiredLogin'
import categoryIcons from "../../conts/categoryIcons";
import {renderIf, renderIfElse} from "../Shared";
import SuggestedChannels from "../Channel/components/SuggestedChannels";
import classNames from "classnames";

const normalizeString = (str) => {
  const newStr = _.lowerCase(str)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
  return newStr
}
const memoNormalizeString = _.memoize(normalizeString)

function filter(parent, child) {
  return memoNormalizeString(parent).includes(memoNormalizeString(child))
}

export const FirstSelectCategory = ({onSuccess = Null}) => {
  const t = useTranslate()
  const history = useHistory()
  const handleRequiredLogin = useRequiredLogin()
  const [step, setStep] = useState(0)
  const [categoryIDs, setCategoryIDs] = useState([])

  const {categories = []} = useAppConfig()

  const {success, isLoading, handleAsyncAction} = useAsyncAction({
    apiInfo: auth_updateFollowedCategories_Api,
    onSuccess: () => {
      onSuccess()
    },
    onError: (...args) => message.error(_.get(args, '1.error')),
  })

  const handleSelectItem = (id) => {
    handleAsyncAction({cate_ids: id})
  }

  const cates = useMemo(
    () => categories.filter((el) => !!!el.is_sub_category),
    [categories]
  )


  return (
    <div className="flex flex-col space-y-6">
      {
        renderIfElse(step === 0,
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 gap-2 md:gap-4 my-6">
            {cates.map((item, i) => {

              const Icon = _.get(categoryIcons, item.idname, categoryIcons["default"])

              return (
                <div
                  key={i}
                  onClick={() => {
                    if (categoryIDs.includes(item.id)) {
                      _.pull(categoryIDs, item.id);
                      setCategoryIDs([...categoryIDs]);
                    } else {
                      setCategoryIDs([...categoryIDs, item.id]);
                    }
                    // handleRequiredLogin(() => handleSelectItem(item.id))
                    // setTimeout(() => {
                    //   history.push(`/category/${item.id}`)
                    // }, 100)
                  }}
                  className={classNames(
                    "p-3 border border-color-50 rounded-md hover:border-color-base text-color-000 hover:text-primary hover:shadow-items",
                    categoryIDs.includes(item.id) ? "bg-primary-50" : ""
                  )}>
                  <div className="flex items-center gap-3 cursor-pointer">
                    <Avatar
                      size={25}
                      shape="square"
                      className="bg-transparent"
                      icon={<Icon/>}
                      alt={_.get(item, 'keywords')}
                    />
                    <span className="font-bold">{_.get(item, 'name')}</span>
                  </div>
                </div>
              )
            })}
            {!cates.length && <Loading/>}
          </div>,
          <SuggestedChannels
            values={{no_following_only: 1}}
            sample={30}
            renderWrap={(child, __, count) =>
              renderIf(
                count,
                <div className="flex flex-col gap-3">
                  <div className="text-sm text-color-300 border-b py-3 border-color-50 tracking-wide">
                    {t("Select to follow the channels so you can access the latest articles and content from the news channels")}
                  </div>
                  {child}
                </div>
              )
            }
          />
        )
      }
      <div className="flex flex-row space-x-3">
        <div className="flex-1"/>
        {
          renderIf(
            step === 0,
            <Button
              type="link"
              className="button-rounded-md no-border"
              onClick={() => {
                setStep(step + 1)
              }}>
              {t('skip')}
            </Button>
          )
        }
        {
          renderIfElse(
            step === 0,
            <Button
              type="primary"
              className="button-rounded-md no-border"
              onClick={() => {
                setStep(step + 1)
              }}>
              {t('next')}
            </Button>,
            <Button
              type="ghost"
              className="button-rounded-md no-border"
              onClick={() => {
                setStep(step - 1)
              }}>
              {t('back')}
            </Button>
          )
        }
        {
          renderIfElse(
            step === 1,
            <Button
              type="primary"
              className="button-rounded-md no-border"
              onClick={() => {
                handleRequiredLogin(() => handleSelectItem(categoryIDs.join(",")));
              }}>
              {t('start')}
            </Button>
          )
        }
      </div>
    </div>
  )
}

export default FirstSelectCategory
