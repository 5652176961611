import _ from "lodash"
import Translate from "modules/local/Translate"
import React from "react"

export const renderRequestSummary = (
  items = []
) => {
  if (_.isEmpty(items)) {
    return null
  }
  return (
    <Translate>
      {t => (
        <React.Fragment>
          {items.map(({ label, value }, index) => {
            return (
              <div key={index} className="text-xs text-color-500 italic">
                {`${t(label)} :`}
                <span className="ml-2 font-bold text-color-100">
                  {value}
                </span>
              </div>
            )
          })}
        </React.Fragment>
      )}
    </Translate>
  )
}