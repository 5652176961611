import classNames from 'classnames'
import _ from 'lodash'
import React, {useCallback} from 'react'
import {renderOwnChild} from 'views/Shared'
import {env} from './_current/config'

export const ForDevelop = ({children}) => {
  // return null; // for test
  if (env !== 'PROD') {
    return children
  }

  return null
}

export const isDevEnviroment = () => {
  // return false; // for test
  if (env === 'DEV') {
    return true
  }

  return false
}

export const isDevelop = () => {
  // return false; // for test
  if (env !== 'PROD') {
    return true
  }

  return false
}

export const ForDevelopZone = ({
                                 children,
                                 className,
                                 isPretty = false,
                                 title = 'dev only',
                               }) => {
  const renderJson = useCallback(
    (values) => {
      if (!isPretty) return JSON.stringify(values)
      return (
        <div className="flex flex-wrap">
          <pre>{JSON.stringify(values, null, 2)}</pre>
        </div>
      )
    },
    [isPretty]
  )
  return (
    <ForDevelop>
      <div className="relative">
        <div
          className={classNames(
            'absolute bg-primary text-white px-2',
            className
          )}
          style={{
            top: 0,
            right: 0,
            zIndex: 22,
          }}>
          {title}
        </div>
        {_.isFunction(children) ? children(renderJson) : children}
      </div>
    </ForDevelop>
  )
}

export const JSONViewer = ({value, Wrapper = renderOwnChild, ...props}) => (
  <ForDevelopZone
    isPretty={true}
    {...props}>
    {(render) => (
      <Wrapper>
        {render(_.isArray(value) ? value : _.omit(value, 'debug'))}
      </Wrapper>
    )}
  </ForDevelopZone>
)

export default ForDevelop
