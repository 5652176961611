import {Button, Input, Steps} from 'antd'
import {getId, getType} from 'apis/model/base'
import {organizationSchema} from 'apis/schema'
import classNames from 'classnames'
import AppIcon from 'components/AppIcon'
import DecoratedInputField from 'components/form/fields/DecoratedInputField'
import {ObjectSelectField} from 'components/form/fields/ObjectSelectField'
import {CustomToggleField} from 'components/form/fields/ToggleField'
import FieldsFactory from 'components/form/FieldsFactory'
import {createValue} from 'components/form/utils'
import Pure from 'components/Pure'
import {FormikContext} from 'formik'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React, {useState} from 'react'
import {IoIosLaptop, IoIosPeople, IoMdAlarm, IoMdCloudOutline,} from 'react-icons/io'
import {useSelector} from 'react-redux'
import {compose, mapProps, withProps} from 'recompose'
import {useSelectEntities} from 'redux/entities/useSelectEntities'
import {getLogin} from 'redux/selectors'
import {CustomDateAndTimeRangeField} from './components/EventOverviewSetting'
import {CustomInputTextAreaField} from './EventSetting'

const {Step} = Steps

const ForWhatQuestionField = ({onChange, onNext, ...props}) => {
  const t = useTranslate()

  return (
    <div className="max-w-xl mx-auto flex flex-col space-y-3">
      {[
        {
          id: 'meeting',
          icon: <IoIosPeople size={20}/>,
          title: 'meeting',
        },
        {
          id: 'webinar',
          icon: <IoIosLaptop size={20}/>,
          title: 'online',
        },
        {
          id: 'hybrid',
          icon: <IoMdCloudOutline size={20}/>,
          title: 'hybrid event',
        },
        {
          id: 'reminder',
          icon: <IoMdAlarm size={20}/>,
          title: 'reminder',
        },
      ].map((e, i) => (
        <div
          key={i}
          onClick={() => {
            onChange(createValue('event_type', e.id))
            onNext()
          }}
          className={classNames(
            'flex flex-col py-3 items-center rounded-lg cursor-pointer',
            _.get(props, 'form.values.event_type', 'meeting') === e.id
              ? 'border-primary border bg-primary-50 hover:bg-primary-100 text-primary'
              : 'border background-100 hover:background-200 text-color-200'
          )}>
          <div className="uppercase font-bold rounded-full">
            {e.icon}
          </div>
          <div className="uppercase font-bold text-center px-3">
            {t(e.title)}
          </div>
        </div>
      ))}
    </div>
  )
}

const ForWhereQuestionField = ({onChange, onNext, ...props}) => {
  const t = useTranslate()

  const event_type = _.get(props, 'form.values.event_type', 'meeting')

  return (
    <div className="flex flex-col space-y-3">
      {event_type === 'webinar' ? (
        <div className="flex flex-col px-3 py-1 border background rounded-lg">
          <div className="text-xs text-color-300">{t('link')}</div>
          <Input
            bordered={false}
            name="external_link"
            placeholder="https://../"
            type="url"
            onChange={(value) => {
              onChange(createValue('external_link', value.target.value))
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col px-3 py-1 border background rounded-lg">
          <div className="text-xs text-color-300">{t('location')}</div>
          <Input
            bordered={false}
            name={'location_name'}
            type={'text'}
            onChange={(value) => {
              onChange(createValue('location_name', value.target.value))
            }}
          />
        </div>
      )}
    </div>
  )
}

const PreScreen = ({onNext}) => {
  return (
    <React.Fragment>
      <FieldsFactory
        formSchema={[
          {
            children: {
              component: ForWhatQuestionField,
              withProps: {
                onNext: onNext,
              },
            },
          },
        ]}
      />
    </React.Fragment>
  )
}

const renderOwnerItem = ({id, type, label, avatar, actions}) => (
  <div className="w-full flex items-center gap-2 leading-tight">
    <AppIcon
      size={20}
      item={{
        id,
        avatar,
        title: label,
        _type: type,
      }}
    />
    <div className="flex flex-col gap-1 flex-1">
            <span className="font-medium text-xs text-color-000 leading-tight">
                {label}
            </span>
      {!!type && (
        <Translate>
          {(t) => (
            <span className="text-2xs text-primary-300 leading-tight italic">
                            {t(type)}
                        </span>
          )}
        </Translate>
      )}
    </div>
    <div className="flex">
      {actions}
    </div>
  </div>
)

const FirstScreen = ({}) => {
  const t = useTranslate()
  const login = useSelector(getLogin)

  const organization_ids = _.get(login, 'organizations', [])

  const organizations = useSelectEntities(organization_ids, [
    organizationSchema,
  ])

  const owner_options = [
    ...organizations.map((e) => ({
      id: getId(e),
      type: getType(e),
      value: getId(e),
      label: e.title,
      title: getTitle(e),
      avatar: getAvatar(e),
    })),
  ].map(({label, ...rest}) => ({
    label: renderOwnerItem({
      label,
      ...rest,
      actions: [
        <span className="px-2 py-1 bg-primary text-xs text-white rounded-md">
          {t('choose')}
        </span>,
      ],
    }),
    render_as: renderOwnerItem({
      label,
      ...rest,
    }),
    ...rest,
  }))

  return (
    <React.Fragment>
      <FieldsFactory
        formSchema={[
          {
            children: {
              name: 'title',
              component: compose(
                withTranslate,
                mapProps(({name, value, onChange, translate}) => ({
                  name,
                  value,
                  onChange,
                  size: 'large',
                  bordered: false,
                  label: translate('event name'),
                  autoFocus: true,
                }))
              )(DecoratedInputField),
            },
          },
          {
            children: {
              name: 'owner',
              component: mapProps(({name, value, form, onChange}) => {
                const default_value = _.get(form, 'values.default_owner_id')
                return {
                  name,
                  value,
                  onChange,
                  bordered: false,
                  size: 'large',
                  label: t('owner'),
                  defaultValue: _.find(owner_options, {
                    value: default_value,
                  }),
                  showSearch: true,
                  options: owner_options,
                  optionLabelProp: 'render_as',
                  optionFilterProp: 'title',
                }
              })(ObjectSelectField),
            },
          },
          {
            children: {
              name: 'time_range',
              label: t('time'),
              component: withProps(({values, ...props}) => ({
                values,
                ...props,
                bordered: false,
                name: 'time_range',
              }))(CustomDateAndTimeRangeField),
            },
          },
          {
            children: {
              title: 'private event',
              name: 'privacy',
              component: compose(
                withTranslate,
                mapProps(({name, value, onChange, translate}) => ({
                  name,
                  value: Number(value) === 2,
                  onChange: (value) => {
                    onChange(createValue(name, Boolean(value) ? 2 : 1))
                  },
                  title: translate('private event'),
                  description: translate('private event description'),
                }))
              )(CustomToggleField),
            },
          },
        ]}
      />
    </React.Fragment>
  )
}

const SecondScreen = () => (
  <Pure>
    <FieldsFactory
      formSchema={[
        {
          children: {
            component: ForWhereQuestionField,
            withProps: {
              bordered: false,
              name: 'location',
            },
          },
        },
      ]}
    />
  </Pure>
)

const ThirdScreen = () => {
  const t = useTranslate()
  return (
    <Pure>
      <FieldsFactory
        formSchema={[
          {
            children: {
              type: 'text',
              name: 'description',
              label: t('description'),
              component: CustomInputTextAreaField,
              withProps: {
                bordered: false,
                style: {
                  borderRadius: '0.5em'
                },
                type: 'text',
                label: 'description',
                name: 'description',
                size: 'large',
                rows: 3,
              },
            },
          },
        ]}
      />
    </Pure>
  )
}

const createEventFormRoutes = [
  {
    path: '/pre-screen',
    title: 'event',
    description: 'Select event type (online / meeting)',
    component: PreScreen,
    exact: true,
  },
  {
    path: '/first-screen',
    title: 'Event, what?, when?',
    description: 'Event name, start time',
    component: FirstScreen,
    exact: true,
  },
  {
    path: '/second-screen',
    title: 'Event, where?',
    description:
      'Add a physical location for people to join your event. Or how to meet together in online event',
    component: SecondScreen,
    exact: true,
  },
  {
    path: '/third-screen',
    title: 'Event, how?',
    description:
      'Provide more information about your event so guests know what to expect.',
    component: ThirdScreen,
    exact: true,
  },
]
export default function CreateEventForm({
                                          asyncData: {isLoading, success, errorMessages, result, error},
                                          isValid,
                                          handleSubmit,
                                          routes = createEventFormRoutes,
                                          ...props
                                        }) {
  const t = useTranslate()
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleOnOk = () => {
    const nextCurrentIndex = Math.min(routes.length - 1, currentIndex + 1)
    props.handleChange(createValue('currentIndex', nextCurrentIndex))
    setCurrentIndex(nextCurrentIndex)
    if (isValid && currentIndex === routes.length - 1) {
      handleSubmit()
    }
  }

  const handleOnCancel = () => setCurrentIndex(Math.max(0, currentIndex - 1))

  const C = createEventFormRoutes[currentIndex].component

  return (
    <div className="flex items-center flex-col verticalList">
      <div className="w-full flex flex-col space-y-1 max-w-3xl">
        <Steps
          size="small"
          className="mt-12"
          style={{flexDirection: 'row'}}
          current={currentIndex}>
          {createEventFormRoutes.map((e, i) => (
            <Step
              key={i}
              status={
                i === currentIndex
                  ? 'process'
                  : i < currentIndex
                  ? 'finish'
                  : 'wait'
              }
            />
          ))}
        </Steps>
        <div className="text-center uppercase font-bold text-3xl px-3 pt-3">
          {t(createEventFormRoutes[currentIndex].title)}
        </div>
        <span className="uppercase text-sm font-semibold text-color-300 tracking-wide mb-1 text-center">
          {t(createEventFormRoutes[currentIndex].description)}
        </span>
      </div>
      <C
        isLoading={isLoading}
        {...{
          onNext: handleOnOk,
          onBack: handleOnCancel,
        }}
      />
      <div className="h-8"/>

      <div
        className="sticky flex justify-center capitalize space-x-3"
        style={{bottom: '1em'}}>
        {!!currentIndex && (
          <Button
            loading={isLoading}
            className="rounded-lg no-shadow no-text-shadow "
            type="ghost"
            onClick={handleOnCancel}>
            {t('back')}
          </Button>
        )}

        <FormikContext.Consumer>
          {({errors}) => (
            <Button
              loading={isLoading}
              disabled={!_.isEmpty(errors)}
              className="rounded-lg no-shadow no-text-shadow no-border"
              type="primary"
              onClick={handleOnOk}>
              {currentIndex === createEventFormRoutes.length - 1
                ? t('save')
                : t('next')}
            </Button>
          )}
        </FormikContext.Consumer>
      </div>
    </div>
  )
}
