import {Alert, Button, Spin} from 'antd'
import {getType} from 'apis/model/base'
import ApiInfoForm from 'components/form/ApiInfoForm'
import FieldsFactory from 'components/form/FieldsFactory'
import FormActionBar from 'components/form/FormActionBar'
import Pure from 'components/Pure'
import Toggle from 'components/Toggle'
import ForDevelop, {ForDevelopZone} from 'envs/ForDevelop'
import {FormikConsumer} from 'formik'
import _ from 'lodash'
import React, {useMemo} from 'react'
import {compose} from 'recompose'
import {fromObject, Null, renderElse, renderOwnChild, renderSelf, shallowDiff,} from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import ContentAlert from '../ContentAlert'
import {MaterialTypes} from '../enums'
import createInitialValues from './createInitialValues'

const EditableForm = ({
    item,
    fields,
    children,
    formSchema,
    Wrapper = 'div',
    staticValues,
    Header = Null,
    onPreSubmit = renderSelf,
    initialized = renderSelf,
    ActionBar = FormActionBar,
    useDefaultActionBar = true,
    ...props
}) => {
  const initialValues = useMemo(() => {
    const values = initialized(fromObject(item, fields)) || {}
    return {
      ...createInitialValues(getType(item), CRUD.UPDATE),
      ...values,
    }
  }, [item, fields, initialized])

  return (
    <ApiInfoForm
      onPreSubmit={(values) => ({
        ...onPreSubmit(shallowDiff(values, initialValues), values),
        ...(staticValues || {}),
      })}
      {...props}
      {...{initialValues}}>
      {({isSubmitting = false}) => (
        <Spin spinning={!!isSubmitting}>
          <Wrapper className="space-y-3 flex flex-col">
            <ForDevelop>
              <Toggle>
                {(isToggle, toggle) => (
                  <React.Fragment>
                    <Button
                      type="primary"
                      onClick={toggle}>
                      {`Debug`}
                    </Button>
                    {isToggle && (
                      <ForDevelopZone
                        isPretty={true}
                        className="rounded">
                        {(print) => (
                          <FormikConsumer>
                            {({values}) => (
                              <ContentAlert
                                visible={true}
                                messageType={MaterialTypes.INFO}
                                message={print({
                                  ...values,
                                  changed: shallowDiff(values, initialValues),
                                })}
                              />
                            )}
                          </FormikConsumer>
                        )}
                      </ForDevelopZone>
                    )}
                  </React.Fragment>
                )}
              </Toggle>
            </ForDevelop>
            <Header/>
            {children}
            <FieldsFactory
              formSchema={formSchema}
              Wrapper={renderOwnChild}
            />
            {useDefaultActionBar && <ActionBar className="background"/>}
          </Wrapper>
        </Spin>
      )}
    </ApiInfoForm>
  )
}

export const ErrorSummaries = () => (
  <Pure>
    <FormikConsumer>
      {({errors}) =>
        renderElse(
          _.isEmpty(errors),
          <Alert
            type="warning"
            message={Object.keys(errors || {}).map((name, index) => {
              const value = _.get(errors, name)
              const {title, description} = _.isString(value)
                ? {
                  title: name,
                  description: value,
                }
                : value || {}
              return (
                <p key={index}>
                  <span className="font-semibold">{title}</span>
                  <span>{`: `}</span>
                  <span>{description}</span>
                </p>
              )
            })}
          />
        )
      }
    </FormikConsumer>
  </Pure>
)

const createEditableForm = (fields, formSchema) =>
  compose((props) => (
    <EditableForm
      {...props}
      {...{fields, formSchema}}
    />
  ))

export default createEditableForm
