import {Button} from 'antd'
import logParams from 'helpers/logParams'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {UseFollow} from 'views/Server/ServerContainer'

export const FollowButton = ({
  item,
  size = "small",
  children
}) => {
  const t = useTranslate()
  return (
    <UseFollow
      follow_status={item.follow_status}
      owner_id={
        item.id || item.username
      }
      owner_type={item._type}>
      {handleFollow => {
        if (children) {
          return logParams(children)(
            item.follow_status,
            handleFollow,
            item.follow_status
              ? t('following')
              : t('follow')
          )
        }
        return item.follow_status ? (
          <Button
              ghost
              type="primary"
              size={size}
              onClick={() => handleFollow() }
              style={{ height: 20 }}
              className="button-rounded font-medium text-2xs italic">
            {t('following')}
          </Button>
        ) : (
          <Button
              size={size}
              type="primary"
              onClick={() => handleFollow() }
              style={{ height: 20 }}
              className="button-rounded no-border font-medium text-2xs italic">
            {t('follow')}
          </Button>
        )
      }}
    </UseFollow>
  )
}
