import React from 'react'
import {AppHeader} from 'views/MainPage/MainHeader'
import {Null, renderOwnChild} from 'views/Shared'
import {ObserverProvider} from 'views/Shared/components/ObservedList'
import {ModuleLayoutWrapper} from './createModule'

const createModuleLayout =
  ({
     top,
     bottom,
     Nav = Null,
     Sider = Null,
     Banner = Null,
     visibleLeftDrawer = false,
     Template = renderOwnChild,
     ...props
   }) =>
    (content) =>
      (
        <ObserverProvider>
          <ModuleLayoutWrapper
            Header={AppHeader}
            visibleLeftDrawer={false}>
            {top}
            <Template
              Sider={Sider}
              Banner={Banner}
              {...props}>
              {content}
            </Template>
            {bottom}
          </ModuleLayoutWrapper>
        </ObserverProvider>
      )

export default createModuleLayout
