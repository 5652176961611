import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../../../LoadingPage";

const LocationsLazy = Loadable({
  loader: () => import('./Locations'),
  loading: LoadingPage
})

const Locations = (props) => (
  <LocationsLazy {...props} />
)

export default Locations
