import {Button} from 'antd'
import classNames from 'classnames'
import {createValue} from 'components/form/utils'
import ForDevelop from 'envs/ForDevelop'
import _ from 'lodash'
import pipe from 'lodash/fp/pipe'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState,} from 'react'
import {nest, withProps} from 'recompose'
import {createEditor, Transforms} from 'slate'
import {withHistory} from 'slate-history'
import {Editable, ReactEditor, Slate, withReact} from 'slate-react'
import {Null, renderElse, renderIf, renderSelf} from 'views/Shared'
import {renderIfElse} from '../Shared'
import {HeadingOneElement, HeadingThreeElement, HeadingTwoElement,} from './components/Heading'
import {ImageElement} from './components/Image'
import {LinkElement} from './components/Link'
import {ListItemElement, OrderListElement, UnOrderListElement,} from './components/ListElement'
import {MentionElement} from './components/Mention'
import {Portal} from './components/Portal'
import {SentenceNode} from './components/SentenceNode'
import {TableCellElement, TableElement, TableRowElement,} from './components/Table'
import {TextNode} from './components/TextNode'
import Toolbar from './components/Toolbar'
import {VideoElement} from './components/Video'
import {
  convertFromString,
  convertToString,
  createParagraphNode,
  ensureNotEmpty,
  getNode,
  isAstChange,
  setClipboard,
  toggleMark,
  withSuggestion,
} from './functions'
import withCodeQuote from './plugins/withCodeQuote'
import withEmbeds from './plugins/withEmbeds'
import withImages from './plugins/withImages'
import withLinks from './plugins/withLinks'
import withMentions from './plugins/withMentions'
import withTable from './plugins/withTable'
import './SlateEditor.css'
import SlateEditorContext, {SlateEditorProvider} from './SlateEditorContext'
import {ElementTypes, NodeInlineTypes, TextAlignTypes} from './types'
import {QuoteElement} from "./components/Quote";

const Element = (props) => {
  const {attributes, children, element} = props
  const style = {
    textAlign:
      element?.align ??
      ([ElementTypes.IMAGE].includes(element?.type)
        ? TextAlignTypes.CENTER
        : undefined),
  }
  switch (element?.type) {
    case ElementTypes.MENTION:
      return <MentionElement {...props} />
    case ElementTypes.TABLE:
      return <TableElement {...props} />
    case ElementTypes.TABLE_ROW:
      return <TableRowElement {...props} />
    case ElementTypes.TABLE_CELL:
      return <TableCellElement {...props} />
    case ElementTypes.LINK:
      return <LinkElement {...props} />
    case ElementTypes.VIDEO:
      return <VideoElement {...props} />
    case ElementTypes.SENTENCE:
      return <SentenceNode {...props} />
    case ElementTypes.IMAGE:
      return (
        <ImageElement
          float="left"
          style={style}
          display="inline"
          {...props}
        />
      )
    case ElementTypes.BLOCK_QUOTE:
      return (
        <QuoteElement
          style={style}
          {...props}>
          {children}
        </QuoteElement>
      )
    case ElementTypes.HEADING_ONE:
      return (
        <HeadingOneElement
          className="my-5"
          style={style}
          {...props}
        />
      )
    case ElementTypes.HEADING_TWO:
      return (
        <HeadingTwoElement
          className="my-3"
          style={style}
          {...props}
        />
      )
    case ElementTypes.HEADING_THREE:
      return (
        <HeadingThreeElement
          className="my-3"
          style={style}
          {...props}
        />
      )
    case ElementTypes.LIST_ITEM:
      return (
        <ListItemElement
          style={style}
          {...props}
        />
      )
    case ElementTypes.ORDERED_LIST:
      return (
        <OrderListElement
          style={style}
          {...props}
        />
      )
    case ElementTypes.UNORDERED_LIST:
      return (
        <UnOrderListElement
          style={style}
          {...props}
        />
      )
    default:
      return (
        <div
          className="my-3"
          style={style}
          {...(attributes ?? {})}>
          {children}
        </div>
      )
  }
}

export const renderLeaf = (props) => <TextNode {...props} />

export const renderElement = (props) => <Element {...props} />

export const withOutline = ({selected, focused}) =>
  selected && focused ? 'solid 1px var(--primary-color)' : undefined

const createEditorWithPlugins = pipe(
  withReact,
  withHistory,
  withEmbeds,
  withImages,
  withLinks,
  withTable,
  withMentions,
  withCodeQuote,
  // ,withParagraphs
)

const hotKeys = {
  CBO: {
    validate: (event) => event?.ctrlKey && event?.key === 'b',
    value: NodeInlineTypes.BOLD,
  },
  CIT: {
    validate: (event) => event?.ctrlKey && event?.key === 'i',
    value: NodeInlineTypes.ITALIC,
  },
  CUN: {
    validate: (event) => event?.ctrlKey && event?.key === 'u',
    value: NodeInlineTypes.UNDERLINED,
  },
  CCO: {
    validate: (event) => event?.ctrlKey && event?.key === '`',
    value: NodeInlineTypes.CODE,
  },
  SDE: {
    validate: (event) => event?.shiftKey && event?.key === 'Delete',
    callback: (editor) => {
      const [__, path] = getNode(editor, ElementTypes.PARAGRAPH) ?? [null, null]
      Transforms.removeNodes(editor, {at: path})
      ensureNotEmpty(editor)
    },
  },
}

const defaultSuggestions = {}

const useSuggest = (editor, params) => {
  const t = useTranslate()

  const ref = useRef()

  const [index, setIndex] = useState(0)

  const [target, setTarget] = useState()

  const [search, setSearch] = useState('')

  const {onSubmit = Null, suggestions = defaultSuggestions} = params ?? {}

  const chars = useMemo(
    () =>
      Object.values(suggestions)
        .map((c) => ({...c, title: t(c.title)}))
        .filter(({title, fixed = false}) => {
          if (fixed) {
            return true
          }
          return title.toLowerCase().startsWith(search.toLowerCase())
        })
        .slice(0, 10),
    [search, suggestions]
  )

  const onKeyDown = useCallback(
    (event) => {
      if (target && chars.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault()
            const prevIndex = index >= chars.length - 1 ? 0 : index + 1
            setIndex(prevIndex)
            break
          case 'ArrowUp':
            event.preventDefault()
            const nextIndex = index <= 0 ? chars.length - 1 : index - 1
            setIndex(nextIndex)
            break
          case 'Tab':
          case 'Enter':
            event.preventDefault()
            Transforms.select(editor, target)
            onSubmit(editor, chars[index])
            setTarget(null)
            break
          case 'Escape':
            event.preventDefault()
            setTarget(null)
            break
        }
      }
    },
    [chars, editor, index, target]
  )

  useEffect(() => {
    if (target && chars.length > 0) {
      const el = ref.current
      const topOffset = 24
      const leftOffset = 0
      const domRange = ReactEditor.toDOMRange(editor, target)
      const rect = domRange.getBoundingClientRect()
      el.style.top = `${rect.top + window.pageYOffset + topOffset}px`
      el.style.left = `${rect.left + window.pageXOffset + leftOffset}px`
    }
  }, [chars.length, editor, index, search, target, ref.current])

  const handleSuggest = useCallback((editor) => {
    withSuggestion(editor, {
      onMatched: ({target, search}) => {
        setIndex(0)
        setTarget(target)
        setSearch(search)
      },
      onCancel: () => {
        setTarget(null)
      },
    })
  })

  const contextMenu = useMemo(() => {
    return renderIf(
      target && chars.length,
      <Portal>
        <div
          ref={ref}
          className="MentionMenu flex flex-col gap-1">
          {chars.map((option, i) => {
            const {icon, title, fixed = false} = option ?? {}
            return (
              <div
                key={i}
                onClick={() => {
                  Transforms.select(editor, target)
                  onSubmit(editor, option)
                  setTarget(null)
                }}
                className="MentionOption text-color-100 hover:text-primary">
                <div className="flex items-center gap-2">
                  {icon}
                  <span className="font-medium text-sm cursor-pointer">
                    {title}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </Portal>
    )
  }, [ref, index, target, chars.length])

  return {
    index,
    target,
    search,
    onKeyDown,
    contextMenu,
    handleSuggest,
  }
}

export const defaultState = [createParagraphNode()]

const SlateEditor = ({
                       name,
                       style,
                       resetKey,
                       className,
                       defaultValue,
                       onChange = Null,
                       readOnly = false,
                       ToolbarComponent = DefaultEditorToolbar,
                       placeholder = 'Tap here to write content. \nType @ to mention',
                     }) => {
  const t = useTranslate()

  const [value, setValue] = useState()

  const [initialized, setInitialized] = useState()

  const {set = Null} = useContext(SlateEditorContext)

  // const editor = useMemo(() => createEditorWithPlugins(createEditor()), [])
  // const editorRef = useRef()
  // if (!editorRef.current) editorRef.current = createEditorWithPlugins(createEditor())
  // const editor = editorRef.current
  const [editor] = useState(() => createEditorWithPlugins(createEditor()))
  const initialValue = defaultValue ?? defaultState

  const isEmpty = useMemo(
    () => !value || JSON.stringify(value) === JSON.stringify(defaultState),
    [value]
  )

  const initialize = () => {
    if (initialValue) {
      editor.children = initialValue;
      if (editor.selection) editor.selection = {"anchor":{"path":[0,0],"offset":0},"focus":{"path":[0,0],"offset":0}}
      setValue(initialValue)
      setInitialized(true)
    }
  }

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    if (resetKey) {
      initialize()
    }
  }, [resetKey])

  const {onKeyDown, contextMenu, handleSuggest} = useSuggest(editor, {
    onSubmit: (editor, option) => {
      const {scope, onCreate = Null} = option ?? {}
      if (scope) {
        set(scope, {
          pending: true,
        })
        onCreate(editor, {uid: Date.now()})
      }
    },
  })

  const handleKeyDown = (event) => {
    _.forEach(
      [hotKeys.CBO, hotKeys.CCO, hotKeys.CIT, hotKeys.CUN],
      (hotkey) => {
        if (hotkey.validate(event)) {
          const mark = hotkey.value
          event.preventDefault()
          toggleMark(editor, mark)
        }
      }
    )

    _.forEach([hotKeys.SDE], (hotkey) => {
      if (hotkey.validate(event)) {
        event.preventDefault()
        hotkey.callback(editor)
      }
    })

    onKeyDown(event)
  }

  const renderPlaceholder = useCallback(
    (transform = renderSelf) =>
      !readOnly && _.isString(placeholder) ? transform(placeholder) : undefined,
    [readOnly, placeholder]
  )

  const debounced = _.debounce((value) => {
    setValue(value)
    onChange(createValue(name, value))
  }, 300)

  return (
    <div
      style={style}
      className={classNames(
        'w-full flex flex-col space-y-2 max-w-6xl mx-auto',
        className
      )}>
      <Slate
        value={value}
        editor={editor}
        initialValue={initialValue}
        onChange={(value) => {
          if (isAstChange(editor)) {
            handleSuggest(editor)
            debounced(value)
          }
        }}>
        <div
          className={classNames(
            'SlateEditor',
            renderIfElse(
              readOnly,
              'read-only text-sm text-color-300 border-transparent',
              'border border-color-50 rounded-lg'
            )
          )}>
          {renderElse(readOnly, <ToolbarComponent className="stickyTop"/>)}
          <div className={classNames('EditorWrapper p-2')}>
            <Editable
              readOnly={readOnly}
              renderPlaceholder={({children, attributes}) =>
                renderIf(
                  initialized && isEmpty && !readOnly,
                  <span {...attributes}>{children}</span>
                )
              }
              onKeyDown={handleKeyDown}
              placeholder={renderPlaceholder(t)}
              renderLeaf={(props) =>
                renderLeaf({
                  ...props,
                  readOnly,
                })
              }
              renderElement={(props) =>
                renderElement({
                  ...props,
                  readOnly,
                })
              }
              onPaste={(event) => {
                const text = event.clipboardData.getData('text')
                setClipboard(text)
              }}
            />
            {contextMenu}
          </div>
        </div>
      </Slate>
      {renderIf(
        false,
        <ForDevelop>
          <div className="flex items-center gap-2 justify-end">
            {JSON.stringify({value})}
            <Button
              type="primary"
              onClick={() => {
                const editorState = convertFromString(
                  localStorage.getItem('temp')
                )
                initialize(editorState)
              }}>
              {t('sync')}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                localStorage.setItem('temp', convertToString(value))
              }}>
              commit
            </Button>
          </div>
        </ForDevelop>
      )}
    </div>
  )
}

export const DefaultEditorToolbar = withProps({className: 'stickyTop'})(
  Toolbar
)

export const ArticleEditorToolbar = withProps({className: 'stickyTop2'})(
  Toolbar
)

export const ModalEditorToolbar = withProps({className: 'stickyTop'})(Toolbar)

export default nest(SlateEditorProvider, SlateEditor)
