import {
  cacheKey,
  reducerKey
} from './const'
const INITIAL_STATE = {}

const asyncReducer = function (
  state = INITIAL_STATE,
  action
) {
  if (action[reducerKey]) {
    return {
      ...state,
      ...action[reducerKey]
    }
  }
  return state
}
const cacheReducer = function (
  state = INITIAL_STATE,
  action
) {
  if (action[cacheKey]) {
    return {
      ...state,
      ...action[cacheKey]
    }
  }
  return state
}

export default {
  [reducerKey]: asyncReducer,
  [cacheKey]: cacheReducer
}
