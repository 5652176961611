import { Col, Input } from "antd"
import Null from "components/NullComponent"
import useTranslate from "modules/local/useTranslate"
import React from "react"

const OrderRequestSearch = ({
  Wrapper = Col,
  onSearch = Null,
}) => {

  const t = useTranslate()

  return (
    <div className="flex flex-col gap-1">
      <span className="font-semibold text-sm text-color-300">
          {`${t('search')} : `}
      </span>
      <Input.Search
        allowClear
        className="w-full"
        onSearch={onSearch}
        placeholder={t('enter your keyword')} />
    </div>
  )
}

export default OrderRequestSearch