import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {Link} from 'react-router-dom'
import useRouter from "../../../../../modules/navigation/useRouter";
import productModel from "../../../../../apis/model/product";
import DrawMenu from "../../../../../components/drawer/DrawMenu";
import { Avatar, Menu } from "antd";
import {ProductSettingContainer} from "../../Product";
import Toggle from "../../../../../components/Toggle";
import {create_product_publish_Api, create_product_update_Api} from "../../../../../apis";
import {createAsyncAction} from "../../../../../modules/asyncCache";
import useDispatchAsyncActionWithNotify from "../../../../../modules/asyncCache/useDispatchAsyncActionWithNotify";
import {UseHook} from "../../../../../components/UseHook";
import { IoEllipsisHorizontal } from "react-icons/io5";
import {FullSideModalLayout} from "../../../../../components/Modal/SideModalLayout";
import {BsBox2Fill, BsCircleFill, BsGear, BsInfoCircleFill} from "react-icons/bs";
import Count from "../../../../../components/Feed/Count";
import {BiSolidToggleRight, BiToggleLeft} from "react-icons/bi";
import {renderIf} from "../../../../Shared";
import {CustomAlert, PopupConfirm} from "../../../../AppLocals/Organization/OrganizationEntity";

const ContextMenu = ({item}) => {
  const t = useTranslate()
  const {
    handleGoBack,
    history
  } = useRouter();
  return (
    <DrawMenu
      container={"workspace_product_list"}
      component={
          <Count>
              <IoEllipsisHorizontal size={14} className="text-color-400"/>
          </Count>
      }>
      {(isToggle, toggle) => (
        <Menu style={{ minWidth: 280 }}>
          <Menu.Item key="1" className="flex mt-2">
            <Toggle>
              {( isToggleProductEdit, toggleProductEdit ) => (
                  <>
                    <div onClick={toggleProductEdit} className="w-full flex items-center gap-3">
                        <BsGear />
                        <span>{t('product configuration')}</span>
                    </div>
                    {isToggleProductEdit && (
                        <FullSideModalLayout
                          right
                          onCancel={toggleProductEdit}
                          maskClosable={false}
                          width={900}
                          title={
                              <div className="font-bold text-color-000">
                                  {_.get(item, 'title')}
                              </div>
                          }
                          destroyOnClose
                          visible={isToggleProductEdit}
                          footer={null}>
                          <ProductSettingContainer id={_.get(item, 'idname')}/>
                        </FullSideModalLayout>
                    )}
                  </>
              )}
            </Toggle>
          </Menu.Item>
          {!!_.get(item, 'active') && (
              <Menu.Item key="deactivate" className="flex mt-2">
                <UseHook hook={useDispatchAsyncActionWithNotify}>
                  {([{ success, isLoading }, dispatch ]) => (
                      <PopupConfirm
                          icon={ <BsInfoCircleFill className="anticon text-blue-500"/> }
                          title={t('deactivate')}
                          onConfirm={() => {
                              dispatch(
                                  createAsyncAction(
                                      {
                                          apiInfo: create_product_update_Api(),
                                          query: {
                                              ':id':
                                              item.id
                                          },
                                          values: {
                                              active: 0
                                          }
                                      }
                                  )
                              )
                          }}>
                          <div className="w-full flex items-center gap-3">
                              <BiToggleLeft />
                              <span>{t('deactivate')}</span>
                          </div>
                      </PopupConfirm>
                  )}
                </UseHook>
              </Menu.Item>
          )}
          {!_.get(item, 'active') && (
              <Menu.Item key="activate" className="flex mt-2">
                <UseHook hook={useDispatchAsyncActionWithNotify}>
                  {([{ success, isLoading }, dispatch ]) => (
                      <PopupConfirm
                          icon={ <BsInfoCircleFill className="anticon text-blue-500"/> }
                          title={t('activate')}
                          onConfirm={() => {
                              dispatch(
                                  createAsyncAction(
                                      {
                                          apiInfo: create_product_update_Api(),
                                          query: {
                                              ':id':
                                              item.id
                                          },
                                          values: {
                                              active: 1
                                          }
                                      }
                                  )
                              )
                          }}>
                          <div className="w-full flex items-center gap-3">
                              <BiSolidToggleRight />
                              <span>{t('activate')}</span>
                          </div>
                      </PopupConfirm>
                  )}
                </UseHook>
              </Menu.Item>
          )}
        </Menu>
      )}
    </DrawMenu>
  )
}

export const ProductSettingHeadlineView = ({item, rootPath, setUpdate}) => {
  const t = useTranslate();
  const isAdmin = !!_.get(item, 'owner.edit');

  return (
      item && (
          <div id={item.idname} className="flex items-center gap-3 border border-color-50 hover:shadow-items rounded-lg relative">
            <Avatar
                size={100}
                icon={ <BsBox2Fill size={40} className="text-color-500"/> }
                src={_.get(item, 'avatar')}
                className="flex flex-center background-200 rounded-l-lg rounded-r-0"
            />

            <div className="flex flex-col gap-1 flex-1 pr-3">
                <Link
                    className="font-bold text-color-000 no-underline"
                    to={productModel.getLinkToDetail(item)}>
                    {item.title}
                </Link>
                {renderIf(
                    _.some([item.product_line, item.status !== 1, item.active !== 1]),
                    <div className="flex items-center gap-3 text-2xs italic">
                        {!!item.product_line && (
                            <div style={{ padding: '2px 8px' }} className="bg-blue-200 font-medium text-blue-600 rounded-md">
                                {item.product_line}
                            </div>
                        )}
                        {item.status !== 1 && (
                            <div style={{ padding: '2px 8px' }} className="bg-orange-200 font-medium text-orange-600 rounded-md">
                                {t('internal use')}
                            </div>
                        )}
                        {item.active !== 1 && (
                            <div style={{ padding: '2px 8px' }} className="background-200 font-medium text-color-300 rounded-md">
                                {t('inactive')}
                            </div>
                        )}
                    </div>
                )}
                {!!item.is_ready && (Number(item.status) !== 1 || Number(item.active) !== 1) && (
                    <CustomAlert
                        type="success"
                        title={t("product had basic information and ready to launch")}
                        description={
                            <div className="flex flex-col">
                                {Number(item.status) !== 1 && (
                                    <span className="flex items-center gap-1">
                                        <BsCircleFill size={5} className="text-color-500"/>
                                        <span>
                                            {t("launch the product to display on the store")}
                                        </span>
                                    </span>
                                )}
                                {Number(item.active) !== 1 && (
                                    <span className="flex items-center gap-1">
                                        <BsCircleFill size={5} className="text-color-500"/>
                                        <span>
                                            {t("or activate to input the price and manage it internally")}
                                        </span>
                                    </span>
                                )}
                            </div>
                        }
                        extra={
                            <div className="flex items-center gap-x-3">
                                {Number(item.status) !== 1 && (
                                    <UseHook hook={useDispatchAsyncActionWithNotify}>
                                        {([{ success, isLoading }, dispatch]) => (
                                            <PopupConfirm
                                                icon={ <BsInfoCircleFill className="anticon text-blue-500"/> }
                                                title="launch"
                                                onConfirm={() => {
                                                    dispatch(createAsyncAction({
                                                        apiInfo: create_product_publish_Api(),
                                                        query: {':id': item.id}
                                                    }))
                                                }}>
                                                <span
                                                    style={{ padding: '2px 6px' }}
                                                    className="font-bold text-2xs bg-primary-50 hover:bg-primary-100 text-primary-600 rounded-md leading-snug cursor-pointer">
                                                    {t('launch')}
                                                </span>
                                            </PopupConfirm>
                                        )}
                                    </UseHook>
                                )}
                                {Number(item.active) !== 1 && (
                                    <UseHook hook={useDispatchAsyncActionWithNotify}>
                                        {([{ success, isLoading }, dispatch ]) => (
                                            <PopupConfirm
                                                icon={ <BsInfoCircleFill className="anticon text-blue-500"/> }
                                                title="activate only"
                                                onConfirm={() => {
                                                    dispatch(createAsyncAction({
                                                        apiInfo: create_product_update_Api(),
                                                        query: {':id': item.id},
                                                        values: {active: 1}
                                                    }))
                                                }}>
                                                <span
                                                    style={{ padding: '2px 6px' }}
                                                    className="font-bold text-2xs bg-primary-50 hover:bg-primary-100 text-primary-600 rounded-md leading-snug cursor-pointer">
                                                    {t('activate only')}
                                                </span>
                                            </PopupConfirm>
                                        )}
                                    </UseHook>
                                )}
                            </div>
                        }
                    />
                )}
                {!_.isEmpty(item.requireStrings) && (
                    <div className="flex flex-col">
                        <span className="font-bold text-xs text-red-600 italic">
                            {`* ${t('missing')} : `}
                        </span>
                        <span className="ml-3 flex flex-col leading-tight">
                            {Object.values(item.requireStrings).map(text => (
                                <span key={text} className="text-xs text-red-500 italic">
                                    {`- ${text}.`}
                                </span>
                            ))}
                        </span>
                    </div>
                )}
            </div>

            {isAdmin && (
                <div style={{ top: 10 }} className="absolute right-5">
                  <ContextMenu item={item}/>
                </div>
            )}
          </div>
      )
  )
}

