import {Avatar, Col, Image, Row, Tooltip} from 'antd'
import {getType} from 'apis/model/base'
import {AppLogoSvgGray} from 'appConfigs/svgs/AppLogoSvg'
import classNames from 'classnames'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useContext} from 'react'
import {IoCheckmark} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {fullGrid, Null, oneQuater, renderOwnChild} from 'views/Shared'
import {AppEntities} from '../enums'
import {createDefaultImage} from '../factory/createDefaultImage'

export const FlexCol = ({id, children, className, flex = true, style}) => (
  <div
    id={id}
    style={style}
    className={classNames('flex flex-col', !!flex ? 'flex-1' : '', className)}>
    {children}
  </div>
)

const DefaultHyperLink = ({title, pathname, className, onClick = Null}) => {
  const t = useTranslate()
  return (
    <Link
      to={pathname}
      onClick={onClick}
      className={classNames(
        'font-bold no-underline max-lines-1 leading-tight text-color-000',
        className
      )}>
      {t(title || 'no title')}
    </Link>
  )
}

const DefaultImage = ({src, type}) => (
  <Image
    preview={false}
    fallback={createDefaultImage({
      type,
    })}
    className="border-img-radius-custom object-cover w-full image-item-template"
    src={src || 'no-image'}
  />
)

export const OverlayItemBanner = ({
                                    title,
                                    children,
                                    className,
                                    type = AppEntities.USER,
                                  }) => {
  const t = useTranslate()
  let background = 'bg-gray-600'
  switch (type) {
    case AppEntities.EVENT:
      background = 'bg-indigo-600'
      break
    case AppEntities.USER:
      background = 'bg-gray-600'
      break
    case AppEntities.ORGANIZATION:
      background = 'bg-color-org'
      break
    default:
      break
  }

  const label = title || type

  return (
    <div
      className={classNames(
        'absolute top-0 text-xs text-white font-medium pl-2 pr-5 py-1 border-radius-custom',
        background,
        className
      )}>
      {t(label)}
      {children}
    </div>
  )
}

const ItemAvatar = ({
                      avatar,
                      className,
                      size,
                      Wrapper = renderOwnChild,
                      ...props
                    }) => {
  return (
    <Wrapper>
      <Avatar
        icon={avatar ? undefined : <AppLogoSvgGray size={20}/>}
        size={size}
        src={avatar}
        className={classNames(
          'flex flex-center border-3 border-white background-200',
          className
        )}
        {...props}
      />
    </Wrapper>
  )
}

export const BulletConnector = ({header, children}) => (
  <React.Fragment>
    {header}
    <span>&nbsp;&#8226;&nbsp;</span>
    {children}
  </React.Fragment>
)

const ItemTemplate = ({
                        item,
                        image,
                        children,
                        renderTitle,
                        renderContent,
                        overlay = null,
                        Header = Null,
                        Footer = Null,
                        Wrapper = Row,
                        renderExtra = Null,
                      }) => {
  const title = getTitle(item)

  const avatar = getAvatar(item)

  const {isSm} = useContext(LayoutContext)

  const typeItem = _.get(item, '_type')
  const verified = _.get(item, 'certified')

  const Title = useCallback(
    () =>
      renderTitle ? (
        renderTitle(title)
      ) : (
        <Translate>
          {(t) => (
            <div className="flex items-center">
              <DefaultHyperLink
                pathname={getLinkToDetail(item)}
                title={title}
              />
              {typeItem === 'organization' && verified === 1 && (
                <Tooltip
                  title={t('verified')}
                  placement="right"
                  color="#87d068">
                  <IoCheckmark
                    width={15}
                    height={15}
                    className="ml-1 zoom-animation"
                  />
                </Tooltip>
              )}
            </div>
          )}
        </Translate>
      ),
    [title, renderTitle, item]
  )

  const Content = useCallback(
    () => (
      <div className="my-2">
        {renderContent
          ? renderContent()
          : renderOwnChild({
            children,
          })}
      </div>
    ),
    [children, renderContent]
  )

  const Extra = useCallback(
    () => <div className="italic text-2xs">{renderExtra(renderOwnChild)}</div>,
    [renderExtra]
  )

  const ItemType = useCallback(
    () => (
      <div className="absolute bottom-0 right-0">
        <ItemAvatar
          avatar={avatar}
          size={isSm ? 40 : 45}
        />
      </div>
    ),
    [isSm, avatar]
  )

  const first = oneQuater

  const remain = Math.max(Math.min(fullGrid, fullGrid - first), 0)

  return (
    <Wrapper className="m-row-0 rounded-md background hover:shadow-out shadow-items border border-color-50">
      <Col
        {...{
          xs: fullGrid,
          sm: fullGrid,
          md: first,
          lg: first,
        }}
        className="p-col-0 relative custom-image-layout">
        <DefaultImage
          src={image}
          type={getType(item)}
        />
        <ItemType/>
        {overlay}
      </Col>
      <Col
        {...{
          xs: fullGrid,
          sm: fullGrid,
          md: remain,
          lg: remain,
        }}
        className="px-3 py-2 flex flex-col">
        <Header/>
        <FlexCol flex={false}>
          <Title/>
          <Extra/>
          <Content/>
        </FlexCol>
        <div className="flex-1"/>
        <Footer/>
      </Col>
    </Wrapper>
  )
}

export default ItemTemplate
