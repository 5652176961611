import { Button } from 'antd'
import { participant_getParticipantsByOwner_Api } from 'apis'
import { getId, getType } from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useCallback, useContext, useMemo } from 'react'
import { BsTrash } from 'react-icons/bs'
import { RiCheckboxCircleLine, RiCloseCircleLine } from 'react-icons/ri'
import { useLocation } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import {
  EventActionTypes,
  participant_status_options,
} from 'views/AppLocals/Event'
import { renderEventParticipant } from 'views/AppLocals/Event/functions/renderEventParticipant'
import { useEventParticipantManager } from 'views/AppLocals/Event/useEventParticipant'
import createContextMenu from 'views/AppLocals/factory/createContextMenu'
import { DateNameSortBy } from 'views/AppLocals/factory/createEntitySortBy'
import { bindQueryParams } from 'views/AppLocals/functions/routerHelper'
import usePaginationHelper, {
  createPagerKey,
} from 'views/AppLocals/hooks/usePaginationHelper'
import ListHeader from 'views/AppLocals/Templates/items/ListHeader'
import { Null } from 'views/Shared'
import EventParticipantFilter from '../components/EventParticipantFilter'
import EventContext from '../EventContext'
import {renderRegistrationParticipant} from "../functions/renderEventParticipant";
import {PiCheckCircle, PiTrash, PiXCircle} from "react-icons/pi";

const EventSettingParticpants = ({
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
    status: 'status',
  },
}) => {
  const t = useTranslate()

  const location = useLocation()

  const { event } = useContext(EventContext)

  const [owner_id, owner_type] = [getId(event), getType(event)]

  const sortBy = useSearchParam(filter_actions.sortBy)

  const status = useSearchParam(filter_actions.status)

  const {
    refreshKey,
    modal = null,
    handleAction = Null,
  } = useEventParticipantManager({
    owner: event,
  })

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      refreshKey,
      `refreshToken=${_.get(location.state, 'refreshToken')}`,
    ],
    [owner_id, owner_type, refreshKey, location.state]
  )

  const renderContextMenu = useCallback((item) => {
    let included_actions = []
    switch (item?.status) {
      case participant_status_options.pending.value:
        included_actions = [
          EventActionTypes.PARTICIPANT_REJECT,
          EventActionTypes.PARTICIPANT_APPROVE,
          EventActionTypes.PARTICIPANT_DELETE,
        ]
        break
      case participant_status_options.rejected.value:
        included_actions = [
          EventActionTypes.PARTICIPANT_APPROVE,
          EventActionTypes.PARTICIPANT_DELETE,
        ]
        break
      case participant_status_options.approved.value:
        included_actions = [
          EventActionTypes.PARTICIPANT_REJECT,
          EventActionTypes.PARTICIPANT_DELETE,
        ]
        break
      default:
        break
    }
    return createContextMenu(
      [
        {
          label: 'approve',
          icon: <PiCheckCircle size={16} className="text-green-500" />,
          key: EventActionTypes.PARTICIPANT_APPROVE,
        },
        {
          label: 'reject',
          icon: <PiXCircle size={16} className="text-orange-500" />,
          key: EventActionTypes.PARTICIPANT_REJECT,
        },
        {
          label: 'delete',
          key: EventActionTypes.PARTICIPANT_DELETE,
          icon: <PiTrash size={16} className="text-red-500"/>,
        },
      ].filter(({ key }) => included_actions.includes(key)),
      (key) => {
        handleAction(key, item)
      }
    )
  }, [])

  const [pager_widget] = usePaginationHelper({
    onInit: ({ onSortChange = Null, onFilterChange = Null }) => {
      onFilterChange({
        status,
      })
      onSortChange(sortBy)
    },
    pagerInfo: {
      apiInfo: participant_getParticipantsByOwner_Api,
      query: bindQueryParams([{ id: owner_id }, { type: owner_type }]),
      RenderEntity: React.Fragment,
      renderItem: (item, index) =>
          renderRegistrationParticipant({
          renderContextMenu,
        })(
          {
            owner: event,
            ...item,
          },
          index
        ),
    },
    sortHeader: t('list of register to participate'),
    SortByWidget: DateNameSortBy,
    query_params: filter_actions,
    dependencies: [owner_id, owner_type],
    withKey: createPagerKey(refresh_deps),
    AdvancedFilter: EventParticipantFilter,
  })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <ListHeader
        title="participants"
        extra={
          <Button
            className="button-rounded-lg no-border"
            type="primary"
            onClick={() => {
              handleAction(EventActionTypes.PARTICIPANT_CREATE)
            }}>
            {t('create')}
          </Button>
        }
      />
      {pager_widget}
      {modal}
      <div className="h-10" />
    </Wrapper>
  )
}

export default EventSettingParticpants
