import classnames from 'classnames'
import Null from 'components/NullComponent'
import React from 'react'
import './Count.css'

const Count = ({
  defaultNumber,
  type,
  component: Icon = Null,
  render,
  active,
  color,
  onClick,
  className,
  children,
  size = 16,
  ...props
}) => {
  return (
    <div
      className={classnames(
        'FeedCount cursor-pointer flex items-center px-2',
        className,
        color,
        { active }
      )}
      {...props}>
      <button
        onClick={onClick}
        className="FeedCountButton">
        {Icon && (
          <Icon
              size={size}
              role="img"
          />
        )}
        {render && render()}
        {children}
      </button>
      {!!defaultNumber && (
        <span
          onClick={onClick}
          style={{ lineHeight: '14px' }}
          className={classnames(
            'relative overflow-hidden ml-1 font-bold text-xs text-color-100 ml-1',
            className
          )}>
          {`${defaultNumber === null ? 0 : Number(defaultNumber).toLocaleString()}`}
        </span>
      )}
    </div>
  )
}
export default Count
