import {Button, Image} from 'antd'
import {LoginContext} from 'components/LoginContext'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import React, {useContext} from 'react'
import {nest, withProps} from 'recompose'
import {staticPaths} from 'routes/staticPaths'
import {ModuleLayoutWrapper} from 'views/AppLocals/factory/createModule'
import {AppHeader} from 'views/MainPage/MainHeader'
import {LoadingWrapper} from 'views/Shared'

const WorkspaceLandingPage = () => {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const InformationBloc = ({title, children}) => (
    <div className="flex flex-col gap-2 mt-4">
      <span className="font-semibold text-color-100 italic mt-2 md:mt-3">
        {title}
      </span>
      <div className="flex flex-col gap-2 ml-3">
        {children}
      </div>
    </div>
  )

  return (
    <LoadingWrapper
      timeout={500}
      isLoading={!login}>
      <div className="w-full h-full flex flex-col flex-center p-3">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 items-center mx-6 mb-16">
          <div className="flex flex-col">
            <div className="flex flex-col ml-0 md:ml-6">
              <span className="font-bold text-color-000 text-2xl leading-snug mb-3">
                {t('FeedIn is a platform that connects buyers with providers, creating a leading information sharing network about agribusiness.')}
              </span>
              <span className="text-color-400 italic">
                {t('Share your knowledgeable or your information to enhance your presence, build a brand, make friends with potential partners or even provide information for a fee.')}
              </span>

              <InformationBloc
                title={t('Our mission is to:')}>
                <span className="text-sm text-color-400 italic">
                  {`- ${t('Help people update the latest market information, multi-dimensional, confidential and customized according to need.')}`}
                </span>
                <span className="text-sm text-color-400 italic">
                  {`- ${t('Promote people to share knowledge and experience, so that people can continuously learn and prosper together.')}`}
                </span>
                <span className="text-sm text-color-400 italic">
                  {`- ${t('Supporting brand promotion and market information to enhance business and networking opportunities.')}`}
                </span>
                <span className="text-sm text-color-400 italic">
                  {`- ${t('Encouraging business initiatives creating social impact. Geared towards building a sustainable living environment for generations today and tomorrow.')}`}
                </span>
              </InformationBloc>

            </div>
            {
              <NavigationContext.Consumer>
                {({history, location}) =>
                  login ? (
                    <div className="flex gap-4 mt-8 justify-end">
                      <Button
                        ghost={true}
                        type="primary"
                        onClick={() => {
                          const _searchParams = new URLSearchParams();
                          _searchParams.set('type', 'organization')
                          history.push(
                            {
                              ...location,
                              pathname: staticPaths.discovery.pathname,
                              search: '?' + _searchParams.toString(),
                            }
                          )
                        }}
                        className="button-rounded-md italic">
                        {t('explore the community directory')}
                      </Button>
                    </div>
                  ) : (
                    <div className="flex gap-4 mt-8 justify-end">
                      <Button
                        ghost={true}
                        type="primary"
                        onClick={() => {
                          history.push(staticPaths.register)
                        }}
                        className="button-rounded-md">
                        {t('create account')}
                      </Button>
                      <Button
                        type="primary"
                        onClick={() => {
                          history.push(staticPaths.login)
                        }}
                        className="button-rounded-md no-border">
                        {t('login')}
                      </Button>
                    </div>
                  )
                }
              </NavigationContext.Consumer>
            }
          </div>
          <div className="custom-image-landingpage">
            <Image
              preview={false}
              src={`/images/banner-promo-mart.png`}
              className="fadeIn animated object-cover"
            />
          </div>
        </div>
      </div>
    </LoadingWrapper>
  )
}

export default nest(
  withProps({
    Header: AppHeader,
  })(ModuleLayoutWrapper),
  WorkspaceLandingPage
)
