import { API_ROOT_URL } from 'envs/_current/config'

// Route::post('test-lonnnnnnng-19210231/set-reset-email/{username}', 'TestController@setResetEmail');
export const test_setResetEmail_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path:
    '/test-lonnnnnnng-19210231/set-reset-email/:username',
  method: 'POST'
}
