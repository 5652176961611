import { API_ROOT_URL } from 'envs/_current/config'
import { schema } from 'normalizr'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

export const keywordSchema = new schema.Entity(
  'keywords',
  {},
  {
    idAttribute: 'idname',
  }
)

export const keywordRefsSchema = new schema.Entity(
  'keyword_refs',
  {},
  {
    idAttribute: 'id',
  }
)

export const keyword_getKeywordsByFollowingCategories_Api = {
  backend: `Route::get('keywords/get-by-following-categories', 'KeywordController@getKeywordsByFollowingCategories');`,
  root: API_ROOT_URL,
  path: '/keywords/get-by-following-categories',
  method: 'GET',
  fields: [],
  fieldTypes: {},
  schema: keywordSchema,
  paramsFields: ['categories', 'languages'],
  transform: createListTransform(keywordSchema),
}

// Route::get('keywords/document/{document_id}/gets', 'KeywordController@getKeywordsByDocumentId');
export const keyword_getKeywordsByDocumentId_Api = {
  backend: `Route::get('keywords/document/{document_id}/gets', 'KeywordController@getKeywordsByDocumentId');`,
  root: API_ROOT_URL,
  path: '/keywords/document/:document_id/gets',
  method: 'GET',
  fields: [],
  fieldTypes: {},
  schema: keywordSchema,
  paramsFields: [],
  transform: createListTransform(keywordSchema),
}

export const keyword_getKeywordsByType_Api = {
  backend: `Route::get('keywords/{type}/{id}/gets', 'KeywordController@keyword_getKeywordsByType_Api');`,
  root: API_ROOT_URL,
  path: '/keywords/:type/:id/gets',
  method: 'GET',
  schema: keywordSchema,
  transform: createListTransform(keywordSchema),
}

export const keyword_getRecommendKeywords_Api = {
  backend: `Route::get('keywords/get-recommend-keywords', 'KeywordController@fetchTopKeywords');`,
  root: API_ROOT_URL,
  path: '/keywords/get-recommend-keywords',
  method: 'GET',
  fields: [],
  fieldTypes: {},
  schema: keywordSchema,
  transform: createListTransform(keywordSchema),
}

export const keyword_getKeywordsAsGlossary_Api = {
  backend: `Route::get('keywords/gets-as-glossary', 'KeywordController@getKeywordsAsGlossary');`,
  root: API_ROOT_URL,
  path: '/keywords/gets-as-glossary',
  method: 'GET',
}

export const keyword_getKeywordsByStartWith_Api = {
  backend: `Route::get('keywords/start-with/{char}/gets', 'KeywordController@getKeywordsByStartWith');`,
  root: API_ROOT_URL,
  path: '/keywords/start-with/:char/gets',
  method: 'GET',
}

export const keyword_getAllKeywords_Api = {
  backend: `Route::get('keywords/keyword/gets', 'KeywordController@getAllKeywords');`,
  root: API_ROOT_URL,
  path: '/keywords/keyword/gets',
  method: 'GET',
  paramsFields: ['page', 'sort_by', 'keyword'],
  schema: keywordSchema,
  transform: createListTransform(keywordSchema),
}

export const keyword_addKeyword_Api = {
  backend: `Route::post('keywords/keyword/add','KeywordController@addKeyword');`,
  root: API_ROOT_URL,
  path: '/keywords/keyword/add',
  method: 'POST',
  schema: keywordSchema,
  transform: createTransform(keywordSchema, 'data'),
}

export const keyword_editKeyword_Api = {
  backend: `Route::post('keywords/keyword/{id}/edit','KeywordController@editKeyword');`,
  root: API_ROOT_URL,
  path: '/keywords/keyword/:id/edit',
  method: 'POST',
  schema: keywordSchema,
  transform: createTransform(keywordSchema, 'data'),
}

export const keyword_deleteKeyword_Api = {
  backend: `Route::post('keywords/keyword/{id}/delete','KeywordController@deleteKeyword');`,
  root: API_ROOT_URL,
  path: '/keywords/keyword/:id/delete',
  method: 'POST',
  schema: keywordSchema,
  transform: createTransform(keywordSchema, 'data'),
}

export const keyword_getById_Api = {
  backend: `Route::get('keywords/keyword/{id}/get', 'KeywordController@getById');`,
  root: API_ROOT_URL,
  path: '/keywords/keyword/:id/get',
  method: 'GET',
  schema: keywordSchema,
  transform: createTransform(keywordSchema, 'data'),
}

export const keyword_addKeywordRef_Api = {
  backend: `Route::post('keyword-refs/keyword/{id}/add', 'KeywordController@addKeywordRef');`,
  root: API_ROOT_URL,
  path: '/keyword-refs/keyword/:id/add',
  method: 'POST',
}

export const keyword_deleteKeywordRef_Api = {
  backend: `Route::post('keyword-refs/keyword-ref/{id}/delete', 'KeywordController@deleteKeywordRef');`,
  root: API_ROOT_URL,
  path: '/keyword-refs/keyword-ref/:id/delete',
  method: 'POST',
}

export const keyword_getKeywordRef_Api = {
  backend: `Route::get('keyword-refs/keyword/{id}/gets', 'KeywordController@getKeywordRef');`,
  root: API_ROOT_URL,
  path: '/keyword-refs/keyword/:id/gets',
  method: 'GET',
  paramsFields: ['page', 'sort_by', 'keyword'],
  // schema: keywordRefsSchema,
  // transform: createListTransform(keywordRefsSchema),
}
