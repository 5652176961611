import React from "react";

export const Cereal = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#f6e080"
				d="M430.23 493.076l-166.45 9-164.934-9V340.802l27-100.022-27-73.945V5.161A5.16 5.16 0 01104.007 0H430.23l27 251.038z"
			></path>
			<path
				fill="#eccd61"
				d="M109.445 512H430.23l9-9.29-9-9.29H98.847v7.983c0 5.852 4.745 10.597 10.598 10.597z"
			></path>
			<path
				fill="#e2ae84"
				d="M430.23 340.802l-161.192 9-170.191-9V166.835H430.23l27 84.203z"
			></path>
			<path fill="#d7a379" d="M98.847 339.597H430.23v18.581H98.847z"></path>
			<path
				fill="#dc4955"
				d="M112.402 292.823c.03-.507.046-1.018.046-1.533 0-13.948-11.307-25.255-25.255-25.255-11.323 0-20.905 7.452-24.111 17.719s-2.445 6.453-2.445 6.453l7.875 2.616 19.253 9z"
			></path>
			<path
				fill="#d82f3c"
				d="M68.512 292.823l-7.655 8.137 6.738 10.444 16.816 9 18.051-9c5.686-4.323 9.486-11 9.94-18.581z"
			></path>
			<path
				fill="#f6e080"
				d="M68.51 292.823c-3.387-9.954-12.807-17.118-23.907-17.118s-20.52 7.164-23.907 17.118l23.907 9zM160.533 276.306l22.883-20.957c-4.025-8.605-12.756-14.569-22.883-14.569-10.897 0-20.182 6.902-23.723 16.572z"
			></path>
			<path
				fill="#eccd61"
				d="M185.189 271.496c.388-1.759.599-3.585.599-5.461 0-3.82-.853-7.44-2.371-10.686-16.67 2.986-22.883 11.957-22.883 11.957-6.12-5.725-15.562-8.555-23.723-9.953a25.21 25.21 0 00-1.532 8.683c0 3.431.687 6.7 1.926 9.682h.002c.162-.003 24.526 21.822 25.597 22.781.017.015.04-.039.041-.016.023.35 22.344-26.987 22.344-26.987z"
			></path>
			<path
				fill="#b3e59f"
				d="M161.61 292.823c-3.387-9.954-12.807-17.118-23.908-17.118-11.1 0-20.52 7.164-23.907 17.118l23.907 9z"
			></path>
			<path
				fill="#dc4955"
				d="M212.99 292.823c-1.819-12.161-12.306-21.488-24.974-21.488s-23.155 9.327-24.974 21.488l24.974 9z"
			></path>
			<path
				fill="#e7ecf1"
				d="M390.488 159.66c5.861-5.552 3.723-20.516 1.516-27.718-7.653-3.268-16.869-1.678-22.952 4.743L237.346 275.734l4.396 7.505 10.671-3.361 12.142 2.137z"
			></path>
			<path
				fill="#c6d0da"
				d="M398.451 136.287a20.433 20.433 0 00-6.447-4.345c2.207 7.203.37 15.367-5.491 20.919l-134.1 127.017v13.96h10.342l135.297-128.152c8.384-7.941 8.564-21.234.399-29.399z"
			></path>
			<path
				fill="#b3e59f"
				d="M262.433 292.823c-3.387-9.954-12.808-17.118-23.907-17.118-11.1 0-20.521 7.164-23.908 17.118l23.907 9z"
			></path>
			<path
				fill="#f6e080"
				d="M311.807 292.823c-4.075-8.474-12.741-14.323-22.772-14.323-10.032 0-18.697 5.849-22.772 14.323l22.772 9z"
			></path>
			<path
				fill="#dc4955"
				d="M362.317 292.823c-4.075-8.474-12.74-14.323-22.772-14.323s-18.697 5.849-22.772 14.323l22.772 9z"
			></path>
			<path
				fill="#d82f3c"
				d="M364.8 303.755c0-3.916-.892-7.624-2.483-10.932h-45.544a25.154 25.154 0 00-2.483 10.932c0 2.667.416 5.235 1.182 7.648l24.072 9 24.073-9a25.23 25.23 0 001.183-7.648z"
			></path>
			<path
				fill="#95d6a4"
				d="M213.271 300.959c0 3.726.814 7.26 2.262 10.444l22.993 9 22.993-9a25.145 25.145 0 002.262-10.444c0-2.848-.478-5.583-1.347-8.137h-47.815a25.189 25.189 0 00-1.348 8.137z"
			></path>
			<path
				fill="#eccd61"
				d="M263.78 303.755c0 2.667.416 5.235 1.182 7.648l24.073 9 24.073-9a25.235 25.235 0 001.182-7.648c0-3.916-.892-7.624-2.483-10.932h-45.544a25.154 25.154 0 00-2.483 10.932zM19.348 300.959c0 3.726.813 7.26 2.262 10.444l22.993 9 22.993-9a25.145 25.145 0 002.262-10.444c0-2.848-.478-5.583-1.347-8.137H20.695a25.216 25.216 0 00-1.347 8.137z"
			></path>
			<path
				fill="#d82f3c"
				d="M162.761 296.59a25.136 25.136 0 004.806 14.814l20.449 9 20.449-9a25.136 25.136 0 004.806-14.814c0-1.28-.097-2.538-.281-3.767h-49.948a25.42 25.42 0 00-.281 3.767z"
			></path>
			<path
				fill="#95d6a4"
				d="M112.448 300.959c0 3.726.813 7.26 2.262 10.444l22.993 9 22.993-9a25.145 25.145 0 002.262-10.444c0-2.848-.478-5.583-1.347-8.137h-47.815a25.189 25.189 0 00-1.348 8.137z"
			></path>
			<path
				fill="#808b95"
				d="M98.847 472.275l94.86-9 94.861 9v20.127c0 5.853-4.745 10.598-10.598 10.598H109.445c-5.853 0-10.598-4.745-10.598-10.598v-9.581z"
			></path>
			<path
				fill="#5e6b75"
				d="M277.969 493.419H109.445c-5.853 0-10.598-4.745-10.598-10.598v18.581c0 5.853 4.745 10.598 10.598 10.598H277.97c5.853 0 10.598-4.745 10.598-10.598v-18.581c0 5.853-4.745 10.598-10.599 10.598z"
			></path>
			<path
				fill="#9ec8fc"
				d="M382.144 311.403H5.27c-2.979 0-5.342 2.508-5.152 5.481 1.293 20.162 10.007 91.389 68.585 136.81l125.003 9 125.003-9c58.579-45.421 67.293-116.648 68.586-136.81.192-2.972-2.172-5.481-5.151-5.481z"
			></path>
			<path
				fill="#80b6fc"
				d="M68.706 453.695c8.861 6.871 18.85 13.157 30.14 18.581h189.721c11.29-5.423 21.279-11.71 30.14-18.581z"
			></path>
			<path
				fill="#ecb85e"
				d="M430.23 493.419l40.831 9 40.831-9V5.161A5.16 5.16 0 00506.731 0H430.23z"
			></path>
			<path
				fill="#eaa949"
				d="M430.23 512h76.501a5.161 5.161 0 005.161-5.161V493.42H430.23z"
			></path>
			<g>
				<g fill="#fcf5f5">
					<path d="M482.686 43.868h-22.408a7.757 7.757 0 000 15.514h22.408a7.757 7.757 0 000-15.514zM482.686 74.256h-22.408a7.757 7.757 0 000 15.514h22.408a7.757 7.757 0 000-15.514zM482.686 104.862h-22.408a7.757 7.757 0 000 15.514h22.408a7.757 7.757 0 000-15.514zM482.686 425.028h-22.408a7.757 7.757 0 000 15.514h22.408a7.757 7.757 0 000-15.514zM482.686 455.633h-22.408a7.757 7.757 0 000 15.514h22.408a7.757 7.757 0 000-15.514z"></path>
				</g>
				<path
					fill="#ecb85e"
					d="M352.186 68.822a7.757 7.757 0 00-7.757-7.757H184.681a7.757 7.757 0 000 15.514h159.748a7.757 7.757 0 007.757-7.757zM322.741 105.06a7.757 7.757 0 00-7.757-7.757H214.126a7.757 7.757 0 000 15.514h100.858a7.757 7.757 0 007.757-7.757z"
				></path>
			</g>
		</svg>
	);
}

export default Cereal;
