import {Input} from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo, useState,} from 'react'
import {useDeBounceValue} from 'views/Search/useDeBounceValue'
import {Null, renderOwnChild,} from 'views/Shared'

export const useDebounceSearch = ({
                                    className,
                                    timeout = 500,
                                    callback = Null,
                                    autoFocus = true,
                                  }) => {
  const t = useTranslate()
  const [search, onSearch] = useState()
  const [isTyping, setTyping] =
    useState()
  const [current, setCurrent] =
    useState()

  const debounced = (keyword) => {
    setTyping(true)
    onSearch(keyword)
    callback(keyword)
    setCurrent(keyword)
  }

  const debounced_value =
    useDeBounceValue(
      search,
      Math.max(0, Number(timeout)),
      () => {
        setTyping(false)
      }
    )

  const input = useMemo(
    () => (
      <Input
        type="text"
        allowClear={true}
        autoComplete="off"
        autoFocus={autoFocus}
        className={className}
        placeholder={t('search')}
        onChange={(event) => {
          const value =
            event.target.value
          debounced(value)
        }}
      />
    ),
    [t, className, debounced, autoFocus]
  )
  return [
    debounced_value,
    debounced,
    input,
    current,
    isTyping,
  ]
}

const DebounceSearch = ({
                          timeout,
                          children,
                          renderSearch,
                          Wrapper = renderOwnChild,
                        }) => {
  const [
    search,
    onSearch,
    searchInput = null,
  ] = useDebounceSearch({timeout})
  return (
    <Wrapper>
      {renderSearch
        ? renderSearch()
        : searchInput}
      {children(search, onSearch)}
    </Wrapper>
  )
}

export default DebounceSearch
