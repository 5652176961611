import {Avatar, Input} from 'antd'
import {auth_fetchOrganizationsWithAdminAuth_Api, auth_updateOrg_Api,} from 'apis'
import {getId} from 'apis/model/base'
import classNames from 'classnames'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {LoginContext} from 'components/LoginContext'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useCallback, useContext, useEffect, useMemo, useState,} from 'react'
import {IoCaretDownCircleOutline, IoCheckmarkOutline} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {staticPaths} from 'routes/staticPaths'
import EntityList from 'views/AppLocals/EntityList'
import createContextMenu from 'views/AppLocals/factory/createContextMenu'
import {notifyOnError} from 'views/AppLocals/factory/createErrorEvent'
import {NotificationActionTypes, successNotify,} from 'views/AppLocals/factory/createNotification'
import MainContext from 'views/MainPage/MainContext'
import {useDeBounceValue} from 'views/Search/useDeBounceValue'
import {Null, renderFalse} from 'views/Shared'
import {BsThreeDotsVertical} from "react-icons/bs";
import {FaWarehouse} from "react-icons/fa6";

const WorkspaceAvatar = ({src, style, onClick, ...props}) => (
  <Avatar
    src={src}
    style={style}
    shape="square"
    className="custom-avatar-workspace"
    onClick={onClick}
    {...props}
  />
)

const excludedKeys = {
  all: '__workspace_picker_more__',
}

const renderOrganizationRow =
  ({onItemSelected = Null, isSelected = renderFalse}) =>
    (item) => {
      const id = getId(item)
      const selected = !!isSelected(id)
      return (
        <div
          key={id}
          onClick={selected ? undefined : () => onItemSelected(item)}
          className={classNames(
            'p-2 flex items-center gap-3 rounded-lg border border-color-50',
            !selected ? 'cursor-pointer background hover:shadow-items' : 'background'
          )}>
          <WorkspaceAvatar
            size={30}
            className="rounded-lg background-200"
            src={getAvatar(item)}
            icon={<FaWarehouse className="text-color-400"/>}
          />
          <div className="flex-1 font-semibold text-sm leading-tight">
            {getTitle(item)}
          </div>
          {selected ? (
            <IoCheckmarkOutline
              size={20}
              className="text-green-600"
            />
          ) : (
            <Translate>
              {(t) => (
                <span className="px-2 py-1 bg-primary text-xs text-white rounded-md">
                {t('select')}
              </span>
              )}
            </Translate>
          )}
        </div>
      )
    }

export const AuthOrganiationsModal = () => {
  const t = useTranslate()

  const {setCurrent, handleGoBack = Null} = useContext(NavigationContext)

  const login = useContext(LoginContext)

  const {primary_org} = login || {}

  const primary_id = getId(primary_org) || primary_org

  const onItemSelected = (item) => {
    const result = getId(item)
    setCurrent({
      key: excludedKeys.all,
      value: result,
    })
    handleGoBack()
  }

  const [keyword, setKeyword] = useState()

  const debouncedValue = useDeBounceValue(keyword, 500)

  return (
    <div className="space-y-3 mt-6">
      <div className="font-bold text-center text-lg text-color-000 uppercase">
        {t('choose organization')}
      </div>
      <div className="flex flex-col gap-3">
        <Input.Search
          allowClear={true}
          placeholder={t('keyword')}
          onChange={(event) => {
            setKeyword(event.target.value)
          }}
        />
        <div className="space-y-3">
          <EntityList
            key={debouncedValue}
            values={{
              keyword: debouncedValue,
            }}
            apiInfo={auth_fetchOrganizationsWithAdminAuth_Api}
            RenderEntity={React.Fragment}
            renderItem={renderOrganizationRow({
              onItemSelected,
              isSelected: (id) => id === primary_id,
            })}
          />
        </div>
      </div>
    </div>
  )
}

const WorkspacePicker = ({
                           primary_organization,
                           IconComponent = ({item, isSm}) => (
                             <WorkspaceAvatar
                               src={getAvatar(item)}
                               style={{
                                 cursor: 'pointer',
                                 width: isSm ? '1.4rem' : '1.6rem',
                                 height: isSm ? '1.4rem' : '1.6rem',
                                 borderRadius: '0.5rem',
                                 zIndex: 3,
                                 background: 'var(--background-100)',
                               }}
                             />
                           ),
                         }) => {
  const t = useTranslate()

  const history = useHistory()

  const primary_id = getId(primary_organization)

  const login = useContext(LoginContext)

  const admin_of_organizations = useMemo(
    () =>
      Array.from(_.get(login, 'admin_of_organizations', [])).filter(
        (e) => getId(e) !== primary_id
      ),
    [login, primary_id]
  )

  const isActive = (path) => {
    const pathname = _.get(history, 'location.pathname')
    return pathname ? String(pathname).includes(path) : false
  }

  const {isSm} = useContext(LayoutContext)

  const {setLoading = Null} = useContext(MainContext)

  const {current, setCurrent = Null} = useContext(NavigationContext)

  const {key: nav_type, value: selected_organization_id} = current || {}

  const {isLoading, handleAsyncAction} = useAsyncAction({
    apiInfo: auth_updateOrg_Api,
    onSuccess: (result) => {
      successNotify(NotificationActionTypes.UPDATE, t)
      setLoading(false)
      setCurrent(null)
    },
    onError: notifyOnError(t),
  })

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (
      nav_type === excludedKeys.all &&
      selected_organization_id &&
      selected_organization_id !== primary_id
    ) {
      handleAsyncAction({
        organization_id: selected_organization_id,
      })
    }
  }, [nav_type, primary_id, selected_organization_id])

  const onSelect = useCallback(
    (params = {}) =>
      (key) => {
        if (key === excludedKeys.all) {
          history.push(staticPaths.selectPrimaryOrganization)
        } else if (key !== primary_id) {
          setCurrent(null)
          handleAsyncAction({
            organization_id: key,
          })
        } else {
          setCurrent(null)
        }
      },
    [primary_id]
  )

  const renderLabel = (params) => (label) => {
    const {isSelected = false, className} = params || {}
    return (
      <div
        className={classNames(
          'ml-3 no-underline truncate',
          isSelected ? 'text-primary font-medium' : 'text-color-300',
          className
        )}>
        {label}
      </div>
    )
  }

  const transformMenuItems = (array = []) => [
    ...array,
    {
      key: excludedKeys.all,
      label: (
        <span className="italic text-xs text-color-400 font-light">
          {t('show more')}
        </span>
      ),
      renderLabel: renderLabel({
        className: 'm-0 flex flex-center flex-1',
      }),
    },
  ]

  const renderContextMenu = useCallback(
    (params) =>
      createContextMenu(
        transformMenuItems(
          [primary_organization, ...admin_of_organizations]
            .filter((e) => !_.isEmpty(e))
            .map((item) => {
              const current_id = getId(item)
              const isSelected = primary_id === current_id
              return {
                key: current_id,
                label: getTitle(item),
                icon: (
                  <WorkspaceAvatar
                    className="flex flex-center rounded-lg background-200"
                    size={24}
                    src={getAvatar(item)}
                    icon={<FaWarehouse className="text-color-400"/>}
                  />
                ),
                // disabled: isSelected,
                renderLabel: renderLabel({
                  isSelected,
                }),
              }
            })
        ),
        (key) => onSelect(params)(key),
        {
          withTranslate: (t, item) => item,
          icon: (
            <div className="flex gap-3 items-center">
              <IconComponent
                item={primary_organization}
                isSm={isSm}
              />
              {!isSm && isActive(staticPaths.workspace.pathname) && (
                <React.Fragment>
                  <div
                    onClick={() => {
                      if (primary_organization) {
                        history.push({
                          pathname: getLinkToDetail(primary_organization),
                        })
                      }
                    }}
                    style={{
                      maxWidth: isSm
                        ? 'calc(100vw - (3 * var(--header-height)))'
                        : '100%',
                    }}
                    className="text-primary font-semibold truncate">
                    {getTitle(primary_organization)}
                  </div>
                  <IoCaretDownCircleOutline className="text-color-400 cursor-pointer hover:text-primary"/>
                </React.Fragment>
              )}
            </div>
          ),
        }
      ),
    [isSm, onSelect, primary_id, admin_of_organizations]
  )

  if (_.isEmpty(primary_organization)) {
    return null
  }

  return (
    <React.Fragment>
      {isActive(staticPaths.workspace.pathname) ? (
        renderContextMenu()
      ) : (
        <Link to={staticPaths.workspace.pathname}>
          <Avatar.Group>
            <IconComponent
              isSm={isSm}
              item={primary_organization}
            />
            <Avatar
              size={30} style={{zIndex: 0}}
              shape="square"
              className="flex flex-center rounded-md border border-color-100 background"
              icon={
                <BsThreeDotsVertical
                  size={18}
                  className="text-color-400 hover:text-color-100"
                />
              }
            />
          </Avatar.Group>
        </Link>
      )}
    </React.Fragment>
  )
}

export default WorkspacePicker
