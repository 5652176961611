import classNames from 'classnames'
import React from 'react'

export const SentenceNode = ({
                               style,
                               element,
                               children,
                               className,
                               attributes,
                               ...rest
                             }) => {
  const _style = {
    ...(style ?? {}),
    ...(element?.style ?? {}),
  }
  return (
    <p
      {...rest}
      style={_style}
      className={classNames(className, element?.className)}
      {...(attributes ?? {})}>
      {children}
    </p>
  )
}
