import { ROOT_URL } from 'envs/_current/config'
import { datasetSchema } from '../dataset'
import base, {
  get,
  getLinkToDetailGenerator,
  getLinkToSettingGenerator,
  getLinkToShareGenerator
} from './base'

const getId = get('idname')
const getType = () => 'data'
export const getLinkToDetail = getLinkToDetailGenerator(
  { getId, getType }
)
export const getLinkToSetting = getLinkToSettingGenerator(
  { getId, getType }
)
export const getLinkToShare = getLinkToShareGenerator(
  { rootUrl: ROOT_URL, getType, getId }
)
export default {
  ...base,
  getLinkToDetail,
  getLinkToSetting,
  getLinkToShare,
  schema: datasetSchema,
  getId
}
