import {Button, Spin} from 'antd'
import {getId} from 'apis/model/base'
import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import {ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {SelectWidget} from 'views/AppLocals/custom/ReactSelectComponents'
import {notifyOnError} from 'views/AppLocals/factory/createErrorEvent'
import {Null} from 'views/Shared'
import {search_searchObjects_Api} from '../../../apis'

export const ObjectSelect = ({timeout = 300, type, ...props}) => {
  const t = useTranslate()

  const [options, setOptions] = useState([])

  const [fetching, setFetching] = useState(false)

  const {isLoading = false, handleAsyncAction: fetchData = Null} =
    useAsyncAction({
      apiInfo: search_searchObjects_Api,
      onError: notifyOnError(t),
      onSuccess: (__, data) => {
        const newOptions = _.get(data, 'response.data.data', [])
        setOptions(transformOptions(newOptions))
        setFetching(false)
      },
    })

  const transformOptions = (options = []) => {
    return Array.from(options).map(({title, description, ...item}) => ({
      ...item,
      title,
      label: (
        <div className="flex flex-col">
          <div className="flex items-center gap-1 font-bold text-xs italic">
            <span className="text-primary truncate">{title}</span>
          </div>
        </div>
      ),
      value: getId(item),
    }))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (keyword) => {
      setOptions([])
      setFetching(true)
      const attachParams = type ? {type} : {}
      fetchData({keyword, ...attachParams})
    }
    return _.debounce(loadOptions, timeout)
  }, [timeout])
  return (
    <SelectWidget
      allowClear={true}
      showSearch={true}
      labelInValue={true}
      filterOption={false}
      loading={!!isLoading}
      optionLabelProp="title"
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small"/> : <EmptyHolder/>}
      {...props}
      options={isLoading ? [] : options}
    />
  )
}

function withPath(path) {
  return path ? `${ROOT_URL}${path}` : ROOT_URL
}

function withContent(content, title) {
  return content + (String(title).length ? ' - ' : '')
}

export const useInputUtils = ({
                                store,
                                focus = Null,
                                handleSubmit = Null,
                                hasSelection = false,
                              }) => {
  const t = useTranslate()

  const action = useRef()

  const actions = useMemo(
    () => [],
    [hasSelection]
  )

  const renderActions = useCallback(
    ({style, className, showLabel = true}) => (
      <div
        style={style}
        className={classNames('flex gap-2 items-center', className)}>
        {actions.map(
          ({value, label, icon, onItemClick = Null, ...rest}, index) => (
            <Button
              key={index}
              icon={icon}
              onClick={() => {
                onItemClick(value)
              }}
              style={{background: 'transparent', padding: 0}}
              className="button-rounded-full no-border flex flex-center"
              {...rest}>
              {showLabel && t(label)}
            </Button>
          )
        )}
      </div>
    ),
    [actions]
  )

  return {
    action,
    actions,
    renderActions,
  }
}
