import { schema } from 'normalizr'
import { articleSchema } from './article'
import { pollSchema } from './poll'
import { postSchema } from './post'

export const pinSchema = new schema.Entity(
  'pins',
  {
    owner: new schema.Union(
      {
        post: postSchema,
        article: articleSchema,
        poll: pollSchema
      },
      '_type'
    )
  },
  {
    idAttribute: 'id'
  }
)
