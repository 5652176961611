export const uSIdNames = {
  "VN-01": "Lai Châu",
  "VN-02": "Lào Cai",
  "VN-03": "Hà Giang",
  "VN-04": "Cao Bằng",
  "VN-05": "Sơn La",
  "VN-06": "Yên Bái",
  "VN-07": "Tuyên Quang",
  "VN-09": "Lạng Sơn",
  "VN-13": "Quảng Ninh",
  "VN-14": "Hòa Bình",
  "VN-18": "Ninh Bình",
  "VN-20": "Thái Bình",
  "VN-21": "Thanh Hoá",
  "VN-22": "Nghệ An",
  "VN-23": "Hà Tĩnh",
  "VN-24": "Quảng Bình",
  "VN-25": "Quảng Trị",
  "VN-26": "Thừa Thiên-Huế",
  "VN-27": "Quảng Nam",
  "VN-28": "Kon Tum",
  "VN-29": "Quảng Ngãi",
  "VN-30": "Gia Lai",
  "VN-31": "Bình Định",
  "VN-32": "Phú Yên",
  "VN-33": "Đắk Lắk",
  "VN-34": "Khánh Hoà",
  "VN-35": "Lâm Đồng",
  "VN-36": "Ninh Thuận",
  "VN-37": "Tây Ninh",
  "VN-39": "Đồng Nai",
  "VN-40": "Bình Thuận",
  "VN-41": "Long An",
  "VN-43": "Bà Rịa - Vũng Tàu",
  "VN-44": "An Giang",
  "VN-45": "Đồng Tháp",
  "VN-46": "Tiền Giang",
  "VN-47": "Kiên Giang",
  "VN-49": "Vĩnh Long",
  "VN-50": "Bến Tre",
  "VN-51": "Trà Vinh",
  "VN-52": "Sóc Trăng",
  "VN-53": "Bắc Kạn",
  "VN-54": "Bắc Giang",
  "VN-55": "Bạc Liêu",
  "VN-56": "Bắc Ninh",
  "VN-57": "Bình Dương",
  "VN-58": "Bình Phước",
  "VN-59": "Cà Mau",
  "VN-61": "Hải Dương",
  "VN-63": "Hà Nam",
  "VN-66": "Hưng Yên",
  "VN-67": "Nam Định",
  "VN-68": "Phú Thọ",
  "VN-69": "Thái Nguyên",
  "VN-70": "Vĩnh Phúc",
  "VN-71": "Điện Biên",
  "VN-72": "Đắk Nông",
  "VN-73": "Hậu Giang",
  "VN-CT": "Cần Thơ",
  "VN-DN": "Đà Nẵng",
  "VN-HN": "Hà Nội",
  "VN-HP": "Hải Phòng",
  "VN-SG": "Hồ Chí Minh",
}

// SELECT concat("\"", province, "\":\"", province_mapcode, "\",") FROM `vietnam_swine_local_prices` group by province_mapcode, province
export const uSNameIds = {
  "Lai Châu": "VN-01",
  "Lào Cai": "VN-02",
  "Hà Giang": "VN-03",
  "Cao Bằng": "VN-04",
  "Sơn La": "VN-05",
  "Yên Bái": "VN-06",
  "Tuyên Quang": "VN-07",
  "Lạng Sơn": "VN-09",
  "Quảng Ninh": "VN-13",
  "Hòa Bình": "VN-14",
  "Ninh Bình": "VN-18",
  "Thái Bình": "VN-20",
  "Thanh Hoá": "VN-21",
  "Nghệ An": "VN-22",
  "Hà Tĩnh": "VN-23",
  "Quảng Bình": "VN-24",
  "Quảng Trị": "VN-25",
  "Thừa Thiên Huế": "VN-26",
  "Thừa Thiên-Huế": "VN-26",
  "Quảng Nam": "VN-27",
  "Kon Tum": "VN-28",
  "Quảng Ngãi": "VN-29",
  "Gia Lai": "VN-30",
  "Bình Định": "VN-31",
  "Phú Yên": "VN-32",
  "Đắc Lắc": "VN-33",
  "Đắk Lắk": "VN-33",
  "Khánh Hoà": "VN-34",
  "Lâm Đồng": "VN-35",
  "Ninh Thuận": "VN-36",
  "Tây Ninh": "VN-37",
  "Đồng Nai": "VN-39",
  "Bình Thuận": "VN-40",
  "Long An": "VN-41",
  "Bà Rịa - Vũng Tàu": "VN-43",
  "Vũng Tàu": "VN-43",
  "An Giang": "VN-44",
  "Đồng Tháp": "VN-45",
  "Tiền Giang": "VN-46",
  "Kiên Giang": "VN-47",
  "Vĩnh Long": "VN-49",
  "Bến Tre": "VN-50",
  "Trà Vinh": "VN-51",
  "Sóc Trăng": "VN-52",
  "Bắc Kạn": "VN-53",
  "Bắc Giang": "VN-54",
  "Bạc Liêu": "VN-55",
  "Bắc Ninh": "VN-56",
  "Bình Dương": "VN-57",
  "Bình Phước": "VN-58",
  "Cà Mau": "VN-59",
  "Hải Dương": "VN-61",
  "Hà Nam": "VN-63",
  "Hưng Yên": "VN-66",
  "Nam Định": "VN-67",
  "Phú Thọ": "VN-68",
  "Thái Nguyên": "VN-69",
  "Vĩnh Phúc": "VN-70",
  "Điện Biên": "VN-71",
  "Đắk Nông": "VN-72",
  "Hậu Giang": "VN-73",
  "Cần Thơ": "VN-CT",
  "Đà Nẵng": "VN-DN",
  "Hà Nội": "VN-HN",
  "Hải Phòng": "VN-HP",
  "Hồ Chí Minh": "VN-SG",
  "TP HCM": "VN-SG",
}

export const mapUSIDNames = (name) => {
  if (uSNameIds[name]) return {id: uSNameIds[name], name: name};

  return {id: name, name: uSIdNames[name] || name};
}