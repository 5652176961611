import React from "react";

export default {
  "{n} categories": '{n} chuyên mục',
  "{n} languages": '{n} ngôn ngữ',
  "{n} provider(s)": '{n} tổ chức',
  "1 year": '1 năm',
  "1D": 'Hàng ngày',
  "1M": 'Hàng tháng',
  "1Y": 'Hàng năm',
  "2 years": '2 năm',
  "2Y": '2 năm',
  "3 months": '3 tháng',
  "3M": '3 tháng',
  "6 months": '6 tháng',
  "6M": '6 tháng',
  "about FeedIn": 'Về FeedIn',
  "about us": 'Về Chúng tôi',
  "about-us": 'Về chúng tôi',
  "accept": 'Chấp nhận',
  "accept invitation": 'Chấp nhận lời mời',
  "accepted as member": 'được chấp nhận làm thành viên',
  "access denied": 'Truy cập bị từ chối',
  "account": 'Tài khoản',
  "account settings": 'Cài đặt tài khoản',
  "active": 'Hoạt động',
  "active subscription": 'Gói đăng ký hoạt động',
  "activity": 'Hoạt động',
  "add": 'Thêm',
  "add chart": 'Thêm biểu đồ',
  "add image": 'Thêm hình ảnh',
  "add link": 'Thêm liên kết',
  "add map": 'Thêm bản đồ',
  "add member": 'Thêm thành viên',
  "add money to wallet": 'Nạp tiền vào tài khoản',
  "add more": 'Bổ sung thêm',
  "Add new and manage members here {here}": 'Thêm mới và quản lý thành viên tại đây {here}',
  "add new answer": 'Thêm mới trả lời',
  "add new contact": 'Thêm mới liên hệ',
  "add new location": 'Thêm mới địa điểm',
  "add payment to keep using {subscription} or change back to free": 'Thanh toán khoản phí để tiếp tục sử dụng {subscription} hoặc đổi về lại miễn phí',
  "add payment to keep using or change back to free": 'Thanh toán khoản phí để tiếp tục sử dụng hoặc đổi về lại miễn phí',
  "add photo": 'Thêm hình ảnh',
  "add price": 'Nạp giá',
  "Add product photos": 'Thêm ảnh sản phẩm',
  "add subscriber": 'Thêm người đăng ký',
  "Add Tax Number": 'Thêm Mã số thuế',
  "Add to Home Screen": 'Thêm vào màn hình',
  "add video": 'Gắn video',
  "adding content to the channel is limited due to its policies": 'Thêm nội dung vào kênh bị hạn chế do chính sách của kênh',
  "additional details": 'Chi tiết bổ sung',
  "address": 'Địa chỉ',
  "admin": 'Quản trị',
  "admin description": 'Vai trò quản trị',
  "advanced filter": 'Bộ lọc nâng cao',
  "After select, click Save to finish": 'Sau khi chọn, nhấn Lưu để hoàn tất',
  "after your trial ends": 'Sau khi đợt dùng thử của bạn kết thúc',
  "all": 'Tất cả',
  "all categories": 'Tất cả danh mục',
  "all dataset": 'Tất cả bộ dữ liệu',
  "all products": 'Tất cả sản phẩm',
  "all vietnam": 'Tất cả Việt Nam',
  "allow add new answers": 'Cho phép thêm mới lựa chọn',
  "Allow channel content to be found through search or suggestion": 'Nội dung kênh cho phép được tìm thấy thông qua tìm kiếm hoặc đề xuất',
  "Allow users to find your channel via search function.": 'Cho phép người dùng có thể tìm thấy kênh của bạn thông qua chức năng tìm kiếm.',
  "allowed be found": 'Được phép tìm thấy',
  "Alternate Email": 'Địa chỉ Email (phụ)',
  "Alternate Mobile No.": 'Số điện thoại (phụ)',
  "Alternate Phone Number": 'Số điện thoại (phụ)',
  "alternative phone": 'Số điện thoại (phụ)',
  "amount": 'Số tiền',
  "and": 'và',
  "Annual Turnover": 'Doanh thu hàng năm',
  "another": 'Khác',
  "answers": 'Các câu trả lời',
  "Anyone can view the content of the data. Only members can post articles and comment freely on this": 'Mọi người đều có thể xem nội dung dữ liệu. Chỉ thành viên mới có thể đăng bài viết và bình luận trên này',
  "apply": 'Áp dụng',
  "approved": 'Đã duyệt',
  "Are you sure delete this article?": 'Bạn muốn xóa bài viết này chứ?',
  "Are you sure delete this chart?": 'Bạn chắc chắn muốn xóa biểu đồ này?',
  "Are you sure delete this poll?": 'Bạn có chắc là muốn xóa bình chọn này?',
  "Are you sure delete this task?": 'Bạn có chắc chắn xóa nhiệm vụ này?',
  "are you sure delete this template": 'Bạn có chắc chắn xóa mẫu này không ?',
  "article": 'Bài viết',
  "articles": 'Bài viết',
  "ask for the best deal": 'Cho thỏa thuận tốt nhất',
  "automatically register with Google, Facebook... account": 'Tự động đăng ký với tài khoản Google, Facebook...',
  "available balance": 'Số dư khả dụng',
  "avatar": 'Ảnh đại diện',
  "back": 'Quay về',
  "Back Home": 'Trở về trang chủ',
  "back to account": 'Quay về tài khoản',
  "ban": 'Cấm hoạt động thành viên',
  "banned": 'Bị cấm hoạt động',
  "banned. can not post any": 'Cấm. Hạn chế đăng bài',
  "bans": 'Cấm',
  "Basic": 'Cơ bản',
  "basic": 'Cơ bản',
  "basic info": 'Thông tin cơ bản',
  "because you are looking for {keyword}": 'Bạn đang tìm kiếm {keyword}?',
  "Become a provider?": 'Trở thành tổ chức?',
  "Become a verified seller and get {check1} Higher Listing on FeedIn {check2} More Buyer Enquiries {check3} Preferred Number Service": 'Xác minh thông tin bán hàng để {check1} Có thứ hạng cao hơn trên Feedin {check2} Tiếp cận khách hàng nhiều hơn {check3} Các dịch vụ đặc biệt khác',
  "bill estimate": 'Hóa đơn kỳ tới',
  "billing": 'Thanh toán',
  "billing address": 'Địa chỉ thanh toán',
  "billing contact": 'Liên hệ thanh toán',
  "billing details": 'Chi tiết thông tin thanh toán',
  "billing history": 'Lịch sử thanh toán',
  "billing information": 'Thông tin thanh toán',
  "billing overview": 'Tổng quan thanh toán',
  "billing period": 'Kỳ thanh toán',
  "birthday": 'Ngày sinh',
  "bookmark": 'Đánh dấu',
  "bookmark all": 'Đánh dấu tất cả',
  "bookmarked": 'Đã đánh dấu',
  "brief introduction to yourself": 'Giới thiệu ngắn về bản thân',
  "bulk price update": 'Cập nhật giá hàng theo danh sách',
  "bulletin": 'Bản tin',
  "by categories": 'Theo chuyên mục',
  "by clicking Submit, you agree to the policy of FeedIn": 'Bằng cách nhấp vào Gửi, bạn đồng ý với chính sách của FeedIn',
  "By clicking Submit, you agree to the policy of FeedIn {term1} and {term2}.": 'Bằng cách nhấp vào \'Gửi đi\', bạn đồng ý với chính sách của FeedIn {term1} và {term2}.',
  "By clicking Subscribe, you agree to {term1} and {term2}.": 'Bằng cách nhấp vào Đăng ký, bạn đồng ý với {term1} và {term2}.',
  "by making a channel private, only members will have access to this channel": 'Bằng cách đặt kênh riêng tư, chỉ thành viên mới có quyền truy cập vào kênh này',
  "by using FeedIn, you agree to our": 'Để sử dụng Feedin, bạn đồng ý với',
  "Call": 'Gọi',
  "Call verification": 'Xác minh bằng cuộc gọi',
  "cancel": 'Hủy bỏ',
  "catalog": 'Danh mục',
  "categories": 'Chuyên mục',
  "categories affect how your story appears in public": 'Thay đổi lựa chọn chuyên mục ảnh hưởng đến cách xuất hiện bài viết của bạn',
  "category": 'Chuyên mục',
  "category groups": 'Nhóm chuyên mục',
  "change address": 'Thay đổi địa chỉ',
  "change display title / subtitle / display image / categories": 'Thay đổi tiêu đề, chuyên mục hiển thị',
  "change following categories": 'Thay đổi chuyên mục theo dõi',
  "change password": 'Thay đổi mật khẩu',
  "change preview photo": 'Thay đổi ảnh xem trước',
  "change role": 'Chuyển đổi vai trò',
  "change subscription": 'Thay đổi gói đăng ký',
  "changes here will affect how your story appears in public places like Article's homepage - not the story itself.": 'Các thay đổi ở đây sẽ ảnh hưởng đến cách câu chuyện của bạn xuất hiện ở những nơi công cộng như trang tin - không phải nội dung bài viết.',
  "Changes to the watchlist are allowed within {m} hours. And the next change is made in {d} days.": 'Việc thay đổi chuyên mục theo dõi được phép thực hiện liên tục trong vòng {m} giờ. Và lần thay đổi tiếp theo được thực hiện sau {d} ngày.',
  "Changing the language on this screen will change the labels displayed on the application to suit the language you have selected. To set up content filtering by language, please access the account's configuration to edit": 'Thay đổi ngôn ngữ trên màn hình này sẽ thay đổi các nhãn hiển thị trên ứng dụng phù  hợp với ngôn ngữ bạn đã chọn. Để thiết lập lọc nội dung theo ngôn ngữ, vui lòng truy cập vào cấu hình của tài khoản để hiệu chỉnh',
  "channel": 'Kênh tin',
  "channel name": 'Tên kênh tin',
  "channel subscriptions": 'Đăng ký kênh tin',
  "channels": 'Kênh tin',
  "channels in category": 'Kênh trong chuyên mục',
  "Channels that you may be interested in": 'Các kênh mà bạn có thể quan tâm',
  "channels to follow": 'Kênh giới thiệu',
  "charged fee data": 'Dữ liệu có tính phí',
  "charges apply": 'Có áp dụng phí',
  "chart management": 'Quản lý biểu đồ',
  "charts": 'Biểu đồ',
  "chat with provider": 'Trao đổi cùng tổ chức',
  "check all": 'Chọn tất cả',
  "check your email and click on the activation link to complete the registration": 'Kiểm tra email của bạn và nhấp vào liên kết kích hoạt để hoàn tất đăng ký',
  "check your email and click on the link": 'Kiểm tra email của bạn và nhấp vào liên kết',
  "choose": 'Chọn',
  "choose another FeedIn subscription": 'Chọn gói FeedIn khác',
  "choose file": 'Chọn file',
  "Choose language": 'Chọn ngôn ngữ',
  "Choose one from your existing conversations, or start a new one.": 'Chọn một cuộc trò chuyện từ các cuộc trò chuyện hiện có của bạn hoặc bắt đầu cuộc trò chuyện mới.',
  "Choose the channel for posting": 'Chọn kênh để đăng bài viết',
  "Choose to follow the information catalog to get more news on your wall": 'Chọn theo dõi danh mục thông tin để nhận thêm tin tức trên tường của bạn',
  "choose your default skin tone": 'Chọn tông màu mặc định của bạn',
  "city": 'Thành phố',
  "clear": 'Xóa',
  "clear all": 'Xóa bỏ tất cả',
  "clear role permissions": 'Xóa bỏ phân quyền trên vai trò',
  "click here": 'Nhấp vào đây',
  "Click the {icon} button on the chart page you want to keep track of, the chart will be updated regularly here.": 'Nhấp vào nút {icon} trên trang biểu đồ mà bạn muốn theo dõi, biểu đồ sẽ được cập nhật thường xuyên tại đây.',
  "click to upload": 'Nhấp để tải lên',
  "close": 'Đóng',
  "code of conduct": 'Quy tắc ứng xử',
  "color theme": 'Màu giao diện',
  "comment": 'Bình luận',
  "commented your post": 'đã bình luận bài viết của bạn',
  "Commodity market information": 'Thông tin thị trường hàng hóa',
  "company": 'Công ty',
  "company profile": 'Hồ sơ công ty',
  "confidentiality of member information": 'Bảo mật thông tin các thành viên',
  "configure content by language": 'Cấu hình nội dung theo ngôn ngữ',
  "confirm password": 'Xác nhận mật khẩu',
  "Confirmation change email address has been sent to": 'Xác nhận thay đổi địa chỉ email đã được gửi tới',
  "Confirmation letter will be sent to your email address": 'Thư xác nhận sẽ được gửi đến địa chỉ email của bạn',
  "contact": 'Liên hệ',
  "contact details": 'Thông tin liên hệ chi tiết',
  "contact email": 'Địa chỉ mail liên hệ',
  "contact info": 'Thông tin liên hệ',
  "contact messages": 'Tin nhắn liên hệ',
  "contact name": 'Tên người liên hệ',
  "contact now": 'Liên hệ ngay',
  "contact phone": 'Số điện thoại liên hệ',
  "contact provider": 'Liên hệ tổ chức',
  "contact to member": 'Liên hệ đến thành viên',
  "contact us": 'Liên hệ với chúng tôi',
  "Contact us about your bill": 'Liên hệ với chúng tôi về hóa đơn của bạn',
  "contact with provider": 'Liên hệ đến tổ chức',
  "contact-info": 'Thông tin liên hệ',
  "contacts": 'Liên hệ',
  "content": 'Nội dung',
  "content allowed to be found": 'Nội dung cho phép được tìm thấy',
  "content displayed by language": 'Nội dung hiển thị theo ngôn ngữ',
  "content is for members only": 'Nội dung chỉ dành cho thành viên',
  "Content language": 'Ngôn ngữ nội dung',
  "Continue to {provider}": 'Tiếp tục với {provider}',
  "Continue to PayPal": 'Tiếp tục với PayPal',
  "conversations": 'Hội thoại',
  "cookies policy": 'Chính sách Cookies',
  "cookies-policy": 'Chính sách Cookies',
  "copied": 'Đã sao chép',
  "copy": 'Sao chép',
  "Copy and share the page link below to your friends. For everyone you need to share easily access and follow this channel": 'Sao chép, chia sẻ liên kết trang bên dưới đến bạn bè. Để mọi người bạn cần chia sẻ dễ dàng truy cập và theo dõi kênh này',
  "copy link": 'Sao chép liên kết',
  "copyright": 'Bản quyền',
  "country": 'Quốc gia',
  "cover": 'Phông nền',
  "create": 'Tạo mới',
  "create a free channel": 'Tạo miễn phí kênh thông tin',
  "Create a new chart for dataset": 'Tạo mới biểu đồ cho bộ dữ liệu',
  "Create a new provider so you can post products or manage your information channels.": 'Tạo một tổ chức mới để bạn có thể đăng sản phẩm hoặc quản lý các kênh thông tin của mình.',
  "create a poll": 'Tạo bình chọn',
  "create category": 'Tạo chuyên mục',
  "create channel": 'Tạo kênh',
  "create chart": 'Tạo biểu đồ',
  "create dataset": 'Tạo Bộ dữ liệu',
  "create new": 'Tạo mới',
  "Create new channel": 'Tạo kênh mới',
  "create new group": 'Tạo nhóm mới',
  "create one": 'Tạo một cái mới',
  "create post": 'Tạo bài viết',
  "create product": 'Tạo sản phẩm',
  "create role": 'Tạo vai trò',
  "Create provider profile": 'Tạo hồ sơ tổ chức',
  "credit/debit card": 'Credit/Debit Card',
  "custom url": 'Tùy chỉnh tên miền',
  "daily": 'Hàng ngày',
  "Daily update, the latest commodity, agriculture and finance market prices every day at FeedIn.": 'Cập nhật thông tin, giá thị trường Hàng hóa, Nông nghiệp, Tài chính mới nhất hàng ngày tại FeedIn.',
  "dark mode": 'Chế độ tối',
  "dark skin tone": 'Tông màu tối',
  "data": 'Dữ liệu',
  "data & charts": 'Dữ liệu & Biểu đồ',
  "Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this": 'Mọi người đều có thể xem nội dung dữ liệu, mọi người có thể tự do tham gia và có thể đăng bài viết và bình luận trên này',
  "data explorer": 'Khám phá dữ liệu',
  "data frequency": 'Tần xuất dữ liệu',
  "data in category": 'Dữ liệu trong chuyên mục',
  "data security": 'Bảo vệ dữ liệu',
  "Data set you might be interested in": 'Tập dữ liệu bạn có thể quan tâm',
  "dataset": 'Dữ liệu',
  "dataset list": 'DS dữ liệu',
  "dataset management": 'Quản lý bộ dữ liệu',
  "dataset subscriptions": 'Đăng ký dữ liệu',
  "date": 'Ngày',
  "deactive": 'Ngừng hoạt động',
  "Declare the location for your provider": 'Khai báo địa điểm cho tổ chức',
  "default": 'Mặc định',
  "default skin tone": 'Tông màu mặc định',
  "delete": 'Xóa',
  "delete channel": 'Xóa bỏ kênh',
  "delete data": 'Xóa dữ liệu',
  "delete dataset": 'Xóa dữ liệu',
  "delete provider": 'Xóa tổ chức',
  "Deleted article are gone forever. Are you sure?": 'Bài viết đã bỏ sẽ mất vĩnh viễn. Bạn chắc chắn đi tiếp?',
  "Deleted channel are gone forever. Are you sure?": 'Kênh được xóa sẽ mất dữ liệu mãi mãi. Bạn có chắc chắn muốn xóa?',
  "Deleted dataset are gone forever. Are you sure?": 'Bộ dữ liệu muốn xóa sẽ biến mất vĩnh viễn. Bạn có chắc chắn?',
  "Deleted provider are gone forever. Are you sure?": 'Đơn vị tổ chức bị xóa sẽ biến mất mãi mãi. Bạn có chắc không?',
  "delivery frequency": 'Tần xuất cấp dữ liệu',
  "delivery_time": 'Thời gian giao hàng',
  "description": 'Mô tả',
  "designed and provided by": 'Được thiết kế và cung cấp bởi',
  "desktop app": 'Desktop App',
  "detail": 'Chi tiết',
  "discovery": 'Khám phá',
  "Discovery": 'Khám phá',
  "discovery more providers": 'Tìm kiếm các tổ chức',
  "do not show members information for each other members": 'Không hiển thị thông tin về các thành viên cho từng thành viên khác',
  "do you want cancel the subscription?": 'Bạn có muốn hủy đăng ký?',
  "do you want to cancel?": 'Bạn muốn tiếp tục hủy?',
  "Do you want to deactivate this product?": 'Bạn có muốn hủy kích hoạt sản phẩm này không?',
  "do you want to logout": 'Bạn muốn tiếp tục đăng xuất ?',
  "document (PDF)": 'tài liệu (PDF)',
  "documentation": 'Tài liệu',
  "Doesn't have any bookmarked item": 'Không có bất kỳ mục nào được đánh dấu',
  "done": 'Đã xong',
  "downgrade": 'Hạ cấp',
  "downgrade subscription": 'Hạ cấp gói đăng ký',
  "downgrade your subscription to": 'Hạ cấp đăng ký của bạn xuống',
  "download": 'Tải về',
  "draft": 'Bản nháp',
  "draft products": 'Sản phẩm đang khai báo',
  "edit": 'Chỉnh sửa',
  "edit message": 'Chỉnh sửa tin nhắn',
  "edit name": 'Sửa tên',
  "edit your post": 'Chỉnh sửa bài viết',
  "editor choices": 'Chọn hiển thị bởi biên tập',
  "email": 'Thư điện tử',
  "email (example@company.com)": 'Thư điện tử (example@company.com)',
  "email account": 'Email đăng nhập',
  "emailplaceholder": 'Thư điện tử (example@company.com)',
  "Emoji categories": 'Emoji categories',
  "en": 'English',
  "Encouraging business initiatives creating social impact. Geared towards building a sustainable living environment for generations today and tomorrow.": 'Khuyến khích các sáng kiến kinh doanh tạo tác động xã hội. Hướng đến xây dựng một môi trường sống bền vững cho các thế hệ hôm nay và mai sau.',
  "english": 'English',
  "enter an url": 'Nhập vào đường dẫn liên kết',
  "enter channel name": 'Nhập vào tên của kênh',
  "Enter Dataset Name": 'Nhập tên dữ liệu',
  "Enter message text with minimum requirement of 20, maximum of 4000 characters": 'Nhập nội dung tin nhắn yêu cầu tối thiểu 20, lớn nhất 4000 ký tự',
  "Enter products / services search": 'Nhập sản phẩm / dịch vụ tìm kiếm',
  "Enter some information so the provider knows your requirements": 'Nhập một số thông tin để tổ chức biết yêu cầu của bạn',
  "enter provider name": 'Nhập tên tổ chức',
  "enter your email": 'Nhập vào thư điện tử',
  "enter your keyword": 'Nhập từ khóa tìm kiếm',
  "expires": 'Hết hạn',
  "Explore related products from verified Companies": 'Sản phẩm liên quan từ các tổ chức',
  "Explore similar products": 'Các sản phẩm tương tự',
  "explore similar products": 'Sản phẩm tương tự',
  "extra request": 'Yêu cầu bổ sung',
  "failure purchase!": 'Mua hàng thất bại!',
  "FAQ": 'Các câu hỏi thường gặp',
  "favorites categories": 'Danh mục ưa thích',
  "Featured Categories": 'Mục nổi bật',
  "featured charts": 'Biểu đồ tiêu biểu',
  "featured topics": 'Chủ đề nổi bật',
  "features": 'Tính năng',
  "fee": 'Phí',
  "fee management": 'Quản lý phí',
  "fee settings": 'Cấu hình sử dụng phí',
  "feed": 'Feed',
  "FeedIn gives you the latest information on market data.": 'FeedIn cung cấp cho bạn thông tin mới nhất về dữ liệu thị trường.',
  "FeedIn is a platform that connects buyers with providers, creating a leading information sharing network about agribusiness.": 'FeedIn là nền tảng kết nối người mua với tổ chức, tạo mạng lưới chia sẻ thông tin hàng đầu về nông nghiệp và thị trường hàng hóa. ',
  "FeedIn Member Since": 'Thành viên của FeedIn từ',
  "FeedIn will update the latest and fastest information and data about the goods market to you": 'FeedIn sẽ cập nhật thông tin và dữ liệu mới nhất và nhanh nhất về thị trường hàng hóa cho bạn',
  "feeds": 'Feeds',
  "female": 'Nữ',
  "file upload failed": 'Tập tin tải lên bị lổi',
  "file uploaded successfully": 'Tập tin đã tải lên thành công',
  "filter": 'Lọc tìm kiếm',
  "Filter results": 'Lọc kết quả',
  "first subscription on": 'Đăng ký lần đầu ngày',
  "Flags": 'Quốc kỳ',
  "folders": 'Thư mục',
  "follow": 'Theo dõi',
  "follow more categories": 'Theo dõi thêm các chuyên mục',
  "Follow to get notifications from this channel!": 'Theo dõi để nhận thông báo từ kênh này!',
  "followers": 'Người theo dõi',
  "following": 'Đang theo dõi',
  "following categories": 'Chuyên mục đang theo dõi',
  "following channels": 'Kênh theo dõi',
  "For buyers, investors and those interested. Just select the area of interest, you are suggested to connect with the professionals and the latest news of the market.": 'Đối với người mua, nhà đầu tư và những người quan tâm. Chỉ cần chọn lĩnh vực quan tâm, bạn được đề nghị kết nối với các chuyên gia và tin tức mới nhất của thị trường.',
  "For inquiring about product quotes, sending product information, or contacting cooperation, please contact us": 'Cho yêu cầu gửi báo giá sản phẩm, gửi thông tin về sản phẩm, hay liên hệ hợp tác, vui lòng liên hệ với chúng tôi',
  "for sales": 'Dành cho bán hàng',
  "for you": 'Dành cho bạn',
  "forgotpass": 'Quên mật khẩu',
  "free": 'Miễn phí',
  "frequently questions": 'Câu hỏi thường gặp',
  "Frequently Used": 'Thường xuyên sử dụng',
  "frequently-questions": 'Câu hỏi thường gặp',
  "from": 'Từ',
  "From - To": 'Từ - Đến',
  "from the sender": 'Bài viết cùng người gửi',
  "fullname": 'Tên đầy đủ',
  "gender": 'Giới tính',
  "general permission": 'Phân quyền chung',
  "general settings": 'Cài đặt chung',
  "Get a quote": 'Nhận báo giá',
  "Get Best Price": 'Nhận giá tốt nhất',
  "Get in touch with us": 'Liên hệ với chúng tôi',
  "Get latest price": 'Nhận giá mới nhất',
  "Get Quotes": 'Nhận báo giá',
  "Get quotes from seller": 'Nhận báo giá từ người bán',
  "get started": 'Bắt đầu',
  "get started with {name}": 'Bắt đầu với {name}',
  "Get your business details verified with us for higher rankings in FeedIn!": 'Xác minh chi tiết doanh nghiệp của bạn với chúng tôi để có thứ hạng cao hơn trong FeedIn!',
  "go": 'Bắt đầu',
  "go modal": 'Mở khung hình',
  "go to": 'Chuyển đến',
  "group": 'Nhóm',
  "haven't comments yet": 'Chưa có bình luận nào',
  "head office": 'Văn phòng chính',
  "hello": 'Xin chào',
  "Hello you": 'Chào bạn',
  "help": 'Giúp đỡ',
  "Help people update the latest market information, multi-dimensional, confidential and customized according to need.": 'Giúp mọi người cập nhật thông tin thị trường hàng hóa, nông nghiệp mới nhất, đa chiều, bảo mật và hỗ trợ gói dịch vụ theo nhu cầu.',
  "helps": 'Giúp đỡ',
  "Here is a list of all invite links. You can revoke any one.": 'Dưới đây là danh sách tất cả các liên kết mời. Bạn có thể thu hồi bất kỳ.',
  "hide interaction between members": 'Ẩn hiển thị tương tác giữa các thành viên',
  "Hide interactions between members. But, retaining interaction between members and administrators": 'Ẩn các tương tác giữa các thành viên. Nhưng, duy trì sự tương tác giữa các thành viên và quản trị viên',
  "history": 'Lịch sử',
  "home": 'Trang chủ',
  "hometown": 'Quê quán',
  "If you need assistance, please feel free to contact us via": 'Nếu bạn cần hỗ trợ, xin vui lòng liên hệ với chúng tôi qua',
  "If you want to switch to annual payment, {contactus}": 'Nếu bạn muốn chuyển sang thanh toán hàng năm, {contactus}',
  "ignore": 'Bỏ qua',
  "image": 'Hình ảnh',
  "image must smaller than": 'Kích thước ảnh phải nhỏ hơn',
  "inactive": 'Không hoạt động',
  "incoming requests": 'Yêu cầu gửi đến',
  "index type": 'Loại chỉ số',
  "index types": 'Loại dữ liệu',
  "info chart editing": 'Chỉnh sửa thông tin biểu đồ',
  "interested": 'Quan tâm',
  "Interface language": 'Ngôn ngữ giao diện',
  "introduce": 'Giới thiệu',
  "invalid email address": 'Địa chỉ email không đúng',
  "invalid template": 'biểu mẫu không hợp lệ',
  "invite": 'Thư mời',
  "invite and share": 'Mời và chia sẻ',
  "invite code": 'Mã mời người dùng',
  "invite members": 'Mời thành viên',
  "invite people": 'Mời mọi người',
  "invite people to join this channel": 'Mời mọi người tham gia kênh',
  "invite you to join": 'gửi mời bạn tham gia',
  "Invite your friends to this page": 'Mời bạn tham gia trang này',
  "invited": 'Đã mời',
  "invited to become member": 'Được mời làm thành viên',
  "inviter": 'Người mời',
  "invites": 'Mời tham gia',
  "is served by clicking on a": 'được phục vụ bằng cách nhấp vào',
  "item not found": 'Không tìm thấy nội dung',
  "join": 'Tham gia',
  "join channel": 'Tham gia vào kênh',
  "Join for free now": 'Tham gia miễn phí ngay',
  "Join the channel to share the article, comment or click to follow so that the system will automatically update the channel's latest news to you": 'Tham gia kênh để chia sẻ bài viết, bình luận hoặc nhấp để theo dõi để hệ thống sẽ tự động cập nhật tin tức mới nhất của kênh cho bạn',
  "Join this channel to start chatting": 'Tham gia kênh để được phép đăng bài',
  "joined": 'Đã tham gia',
  "keep subscription": 'Giữ lại đăng ký',
  "keyword": 'Từ khóa',
  "keywords": 'Từ khóa',
  "kick": 'Loại bỏ thành viên',
  "kicked": 'Đã bỏ khỏi thành viên',
  "language": 'Ngôn ngữ',
  "languages": 'Ngôn ngữ',
  "last updated": 'Lần cập nhật cuối',
  "latest": 'Mới nhất',
  "latest updates": 'Cập nhật mới',
  "Latest updates on the commodity market": 'Cập nhật mới nhất về thị trường hàng hóa',
  "leave": 'Rời đi',
  "leave a message": 'Để lại lời nhắn',
  "Leave a Message, we will contact you back!": 'Để lại tin nhắn, chúng tôi sẽ liên hệ lại với bạn!',
  "leave channel": 'Rời khỏi kênh',
  "Legal Status of Firm": 'Loại hình công ty',
  "less": 'Thu lại',
  "license": 'Cấp phép',
  "licenses": 'Cấp phép',
  "light skin tone": 'Tong màu sáng',
  "link": 'Liên kết',
  "Link original post": 'Link bài viết gốc',
  "list of data": 'Danh sách bộ dữ liệu',
  "listed products": 'Sản phẩm đang hoạt động',
  "unlisted products": 'Sản phẩm chưa hoạt động',
  "location": 'Vị trí',
  "Location (Province/district/ward)": 'Địa điểm (Tỉnh (Thành) / Quận (Huyện)/ Phường (Xã))',
  "location & contact": 'Địa điểm & Liên hệ',
  "location name": 'Tên địa danh',
  "login": 'Đăng nhập',
  "Login now": 'Đăng nhập ngay',
  "Login now to select and follow the content that you interested in": 'Đăng nhập ngay để lựa chọn và theo dõi mục bạn quan tâm',
  "login with account": 'Đăng nhập với tài khoản',
  "login with Facebook": 'Đăng nhập với Facebook',
  "login with Google": 'Đăng nhập với Google',
  "logout": 'Đăng xuất',
  "Make your channel get more exciting activities by inviting other members": 'Hãy làm cho trang kênh của bạn có thêm nhiều hoạt động hơn bằng cách mời thêm các thành viên khác',
  "Make your provider get more exciting activities by inviting other members": 'Hãy làm cho trang tổ chức của bạn có thêm nhiều hoạt động hơn bằng cách mời thêm các thành viên khác',
  "male": 'Nam',
  "manage": 'Quản lý',
  "manage dataset": 'Quản lý Bộ dữ liệu',
  "manage products": 'Quản lý sản phẩm',
  "manage services": 'Quản lý dịch vụ',
  "ManageChannelSubscriptions": 'Quản lý đăng ký kênh',
  "managed channels": 'Kênh quản trị',
  "management": 'Quản lý',
  "mark all as read": 'Đánh dấu đã xem tất cả',
  "mark as read": 'Đánh dấu đã xem',
  "market": 'Thị trường',
  "market summary": 'Tóm tắt thị trường',
  "mart": 'Cửa hàng',
  "me to the channel": 'Đến với kênh thông tin',
  "medium skin tone": 'Tông màu trung bình',
  "medium-dark skin tone": 'Tông màu trung bình - tối',
  "medium-light skin tone": 'Tông màu trung bình - sáng',
  "member": 'Thành viên',
  "member description": 'Mô tả thành viên',
  "member management": 'Quản lý thành viên',
  "members": 'Thành viên',
  "members list": 'DS thành viên',
  "mention": 'nhắc đến',
  "menu": 'Danh mục',
  "Message": 'Tin nhắn',
  "messages": 'Tin nhắn',
  "min_order_qtty": 'Đặt hàng tối thiểu',
  "Minimum payment required": 'Yêu cầu thanh toán tối thiểu',
  "miss": 'Chị.',
  "modal": 'Khung hình',
  "monthly": 'Hàng tháng',
  "more": 'Thêm',
  "More data from": 'Xem thêm dữ liệu từ',
  "More like This": 'Xem thêm',
  "mr": 'Ông.',
  "mrs": 'Bà.',
  "ms": 'Cô.',
  "my channels": 'Kênh của tôi',
  "my page": 'Trang cá nhân',
  "my post": 'Bài của tôi',
  "name": 'Tên',
  "name of chart": 'Tên của biểu đồ',
  "name of your company": 'Tên công ty của bạn',
  "Nature of Business": 'Lĩnh vực kinh doanh',
  "new answer": 'Trả lời mới',
  "New contact": 'Thông tin liên hệ mới',
  "new contact": 'Thông tin liên hệ mới',
  "new folder": 'Thư mục mới',
  "new group": 'Tạo nhóm mới',
  "New location": 'Địa điểm mới',
  "new password": 'Mật khẩu mới',
  "New template": 'Biểu mẫu mới',
  "newest data": 'Dữ liệu mới nhất',
  "news": 'Tin tức',
  "news page": 'Trang tin tức',
  "next": 'Tiếp theo',
  "next change": 'Thay đổi lần tiếp theo',
  "No categories have been selected": 'Không có chuyên mục được chọn',
  "no categorized": 'Chưa phân mục',
  "No channel being followed": '0 kênh đang được theo dõi',
  "no channels": 'Chưa có kênh thông tin',
  "No Data": 'Chưa có dữ liệu',
  "No data available at this time.": 'Không có dữ liệu vào lúc này.',
  "no data found": 'Không tìm thấy dữ liệu',
  "No data set are being followed": '0 tập dữ liệu đang được theo dõi',
  "no dataset": 'Chưa có bộ dữ liệu',
  "no description": 'Không có mô tả',
  "No Emoji Found": 'Không tìm thấy Emoji',
  "No filter available": 'Không có lọc dữ liệu',
  "no invites yet": 'Hiện chưa có lời mời nào',
  "no item found": 'Nội dung không được tìm thấy',
  "No news": 'Chưa có tin tức',
  "no office found": 'Không tìm thấy địa điểm',
  "No posts were found": 'Không bài viết nào được tìm thấy',
  "no results found": 'Không tìm thấy kết quả',
  "not a member yet?": 'Bạn chưa phải là thành viên?',
  "note": 'Ghi chú',
  "notes about chart!": 'Ghi chú cho biểu đồ',
  "notification": 'Thông báo',
  "Notifications": 'Thông báo',
  "Number of Employees": 'Số lượng nhân viên',
  "Number of your channels:": 'Số kênh hiện tại của bạn:',
  "Objects": 'Đối tượng',
  "offices": 'Văn phòng',
  "OK": 'OK',
  "old password": 'Mật khẩu cũ',
  "oldest data": 'Dữ liệu xa nhất',
  "on": 'Trên',
  "One more step to become a Verified Seller": 'Hoàn tất khai báo thông tin bán hàng',
  "Only members can view data content, post articles or comment on this": 'Chỉ thành viên mới có thể xem nội dung dữ liệu, đăng bài viết hoặc bình luận trên này',
  "oops...": 'Rất tiếc...',
  "opps, no channels available": 'Rất tiếc, chưa có kênh tin tức',
  "or": 'Hoặc',
  "or continue with": 'hoặc tiếp tục với',
  "organization": 'Tổ chức',
  "other": 'Khác',
  "other categories": 'Chuyên mục khác',
  "other channels": 'Kênh khác',
  "other charts": 'Biểu đồ khác',
  "others": 'Khác',
  "otherSettings": 'Cài đặt khác',
  "Our Channels": 'Kênh thông tin',
  "Our data": 'Dữ liệu',
  "Our mission is to:": 'Sứ mệnh của chúng tôi hướng đến:',
  "Our Videos": 'Video về chúng tôi',
  "overview": 'Tổng quan',
  "owner": 'Chủ quản',
  "owner description": 'Vai trò chủ quản',
  "package in use": 'Gói đang dùng',
  "packaging_details": 'Chi tiết đóng gói',
  "paid channel": 'Kênh có thu phí',
  "partner": 'Đối tác',
  "password": 'Mật khẩu',
  "Password and confirm password does not match": 'Mật khẩu xác nhận lại không trùng khớp',
  "Password must have min 6 characters": 'Mật khẩu phải tồn tại ít nhất 6 ký tự',
  "password required": 'Mật khẩu được yêu cầu',
  "passwordplaceholder": 'Mật khẩu',
  "Passwords must match": 'Mật khẩu nhập phải trùng khớp',
  "paste your link here": 'Dán liên két của bạn vào đây',
  "pay": 'Thanh toán',
  "Pay and subscribe": 'Trả và đăng ký',
  "payment detail": 'Chi tiết thanh toán',
  "payment subscription": 'Thanh toán gói đăng ký',
  "Payment via {provider}": 'Thanh toán qua {provider}',
  "Payment via Paypal": 'Thanh toán qua Paypal',
  "Payment will be sent to continue on {provider}. Please confirm the amount to pay": 'Thanh toán sẽ được tiếp tục qua {provider}, Vui lòng xác nhận lại số tiền để tiếp tục',
  "Payment will be sent to continue on Paypal. Please confirm the amount to pay": 'Thanh toán sẽ được chuyến đến tiếp tục trên Paypal. Vui lòng xác nhận số tiền cần thanh toán',
  "paypal": 'PayPal',
  "pending": 'Đang chờ',
  "pending approval": 'Đang chờ duyệt',
  "people to follow": 'Bạn bè giới thiệu',
  "Permanent link": 'Địa chỉ vĩnh viễn',
  "permission denied": 'Quyền truy cập bị từ chối',
  "permissions": 'Phân quyền',
  "personal": 'Cá nhân',
  "personal channels": 'Kênh cá nhân',
  "personal profile": 'Hồ sơ cá nhân',
  "phone": 'Điện thoại',
  "Phone Number": 'Số điện thoại',
  "Phone number how we can contact you": 'Số điện thoại cách chúng tôi có thể liên hệ với bạn',
  "photos": 'Hình ảnh',
  "Pictures on the home page": 'Hình ảnh đặt trên trang chủ',
  "pin": 'Ghim',
  "Pin code": 'Mã PIN',
  "pinned": 'Đã ghim',
  "pinned posts": 'Bài viết đã ghim',
  "pinnedPosts": 'Bài viết đã ghim',
  "Please check the information and follow the steps to subscribe.": 'Vui lòng kiểm tra thông tin và làm theo các bước để đăng ký.',
  "Please choose to follow related channels for the system to filter the updates to you": 'Vui lòng chọn theo dõi các kênh liên quan để hệ thống lọc các thông tin cập nhật đến bạn',
  "Please choose to save the interest charts so you can view them easily each day": 'Vui lòng chọn lưu các biểu đồ quan tâm để bạn có thể xem các biểu đồ này một cách dễ dàng mỗi ngày',
  "please enter name of product": 'Vui lòng nhập tên sản phẩm',
  "please input your email": 'Vui lòng nhập địa chỉ thư điện tử của bạn',
  "please input your phone number": 'Vui lòng nhập số điện thoại của bạn',
  "Please log in to FeedIn to leave your comment in the post": 'Vui lòng đăng nhập vào FeedIn để để lại bình luận của bạn trong bài viết',
  "Please login to use the message function in the system.": 'Vui lòng đăng nhập để sử dụng chức năng nhắn tin trong hệ thống.',
  "Please register to view this content": 'Vui lòng đăng ký để xem nội dung này',
  "please select": 'Vui lòng chọn',
  "Please select at least 1 topic to complete. FeedIn will help you find relevant information": 'Vui lòng chọn ít nhất 1 chủ đề để hoàn tất. FeedIn sẽ giúp bạn tìm thấy thông tin phù hợp',
  "Please select the unit you want to choose as the default": 'Vui lòng nhấp chọn một công ty mà bạn muốn chọn làm mặc định',
  "policy": 'Chính sách',
  "poll": 'Thăm dò ý kiến',
  "popular": 'Phổ biến',
  "post": 'Bài viết',
  "Post something": 'Viết điều gì đó',
  "post your images": 'Đăng ảnh của bạn',
  "postal code": 'Mã bưu điện',
  "posted": 'Đã đăng',
  "posted by": 'Được đăng bởi',
  "posts": 'Bài viết',
  "Premium": 'Cao cấp',
  "premium": 'Cao cấp',
  "Premium channel, when enabled, allow users to join the channel for a fee. The owner of the channel will receive revenue from the members.": 'Kênh cao cấp, khi được bật, cho phép người dùng tham gia kênh có tính phí. Chủ sở hữu của kênh sẽ nhận được doanh thu từ các thành viên.',
  "premium providers": 'Các tổ chức',
  "press enter to search": 'Nhấn ENTER để tìm kiếm',
  "preview description": 'Mô tả hiển thị',
  "preview title": 'Tựa đề hiển thị',
  "price": 'Giá',
  "pricing": 'Định giá',
  "Primary E-mail": 'Email (chính)',
  "Primary Mobile No.": 'Số ĐT (chính)',
  "privacy": 'Bảo mật thông tin',
  "privacy policy": 'Chính sách riêng tư',
  "privacy settings": 'Cấu hình chính sách riêng tư',
  "privacy-policy": 'Chính sách riêng tư',
  "private": 'Riêng tư',
  "private channel": 'Kênh riêng tư',
  "pro": 'Gói nâng cao',
  "Pro": 'Chuyên nghiệp',
  "product": 'Sản phẩm',
  "product catalogs": 'Nhóm hiển thị sản phẩm',
  "product certificates": 'Chứng nhận sản phẩm',
  "product description": 'Mô tả về sản phẩm',
  "product details": 'Chi tiết sản phẩm',
  "product filter": 'Lọc tìm sản phẩm',
  "product images": 'Hình ảnh sản phẩm',
  "product must have at least five character": 'tên sản phẩm phải có ít nhất 5 ký tự ',
  "product must have at least one image": 'sản phẩm phải có ít nhất một hình ảnh',
  "product name": 'Tên sản phẩm',
  "product settings": 'Quản lý sản phẩm',
  "product specifications": 'Đặc tính sản phẩm',
  "Product/Service Name": 'Sản phẩm / Tên dịch vụ',
  "production_capacity": 'Khả năng sản xuất',
  "products": 'Sản phẩm',
  "products list": 'DS sản phẩm',
  "products page": 'Trang sản phẩm',
  "Professional network in the industry": 'Mạng lưới chuyên môn trong ngành nghề',
  "profile": 'Hồ sơ',
  "profile settings": 'Quản lý tài khoản',
  "Promote people to share knowledge and experience, so that people can continuously learn and prosper together.": 'Thúc đẩy mọi người chia sẻ kiến thức, kinh nghiệm, cùng nhau học hỏi, phát triển thịnh vượng.',
  "provided services": 'Các dịch vụ cung cấp',
  "Province/City": 'Tỉnh/Thành',
  "Province/district/ward": 'Tỉnh (Thành) / Quận (Huyện) / Phường (Xã)',
  "provinces": 'Tỉnh / Thành',
  "public": 'Công khai',
  "public timeline": 'Dòng thời gian công khai',
  "publish": 'Xuất bản',
  "published by": 'Xuất bản bởi',
  "publisher": 'Xuất bản',
  "quick link": 'Liên kết nhanh',
  "quote": 'Trích dẫn',
  "read": 'đọc bài',
  "read more": 'Đọc thêm',
  "read news": 'Xem tin',
  "ready to publish?": 'Sẵn sàng xuất bản',
  "rebookmark": 'Đánh dấu lại',
  "recent news": 'Tin gần đây',
  "recently news": 'Tin mới',
  "Recommendations": 'Khuyến nghị',
  "refreshed": 'Cập nhật lần cuối',
  "region": 'Vùng / Miền',
  "regions": 'Vùng / Miền',
  "register": 'Đăng ký',
  "registered": 'Đã đăng ký',
  "Registering to use advanced data, you will have to pay": 'Đăng ký sử dụng dữ liệu nâng cao, bạn sẽ phải trả',
  "rejected": 'Đã bị từ chối',
  "related articles": 'Bài viết cùng thể loại',
  "related categories": 'Chuyên mục liên quan',
  "related channels": 'Kênh liên quan',
  "related charts": 'Biểu đồ liên quan',
  "related keywords": 'Từ khóa liên quan',
  "Related to items you've viewed": 'Liên quan đến sản phẩm trước bạn đã xem',
  "relevant": 'Có liên quan',
  "remove": 'Xóa bỏ',
  "remove all": 'Xóa tất cả',
  "removed": 'Đã xóa',
  "renew / expires on": 'Gia hạn / Hết hạn ngày',
  "replied": 'Đã phản hồi',
  "reply": 'Trả lời',
  "request": 'Yêu cầu',
  "Request a call": 'Yêu cầu cuộc gọi',
  "Request a quote": 'Yêu cầu báo giá',
  "request have been sent successfully!": 'Yêu cầu đã được gửi đi thành công!',
  "request to join": 'Gửi yêu cầu tham gia',
  "request-to-join": 'Gửi yêu cầu tham gia',
  "requested to join": 'Gửi yêu cầu tham gia',
  "required field": 'Phần bắt buộc nhập',
  "Requirement Details": 'Chi tiết về yêu cầu',
  "resend": 'Gửi lại',
  "reset": 'Nhập lại',
  "reset your password": 'Nhập lại mật khẩu',
  "response rate": 'Tỷ lệ phản hồi',
  "responses": 'Phản hồi',
  "restricted": 'Hạn chế',
  "results": 'Kết quả',
  "return home": 'Quay về trang chủ',
  "return owner page": 'Trờ về trang chủ quản',
  "return user page": 'Trở về trang người dùng',
  "retype password": 'Nhập lại mật khẩu',
  "Review": 'Xem lại',
  "role": 'Vai trò',
  "role name": 'Tên vai trò',
  "role title": 'Chức danh / Vai trò',
  "roles": 'Vai trò',
  "sales information": 'Thông tin bán hàng',
  "save": 'Lưu',
  "save & complete": 'Lưu & Hoàn tất',
  "Save and continue": 'Lưu và tiếp tục',
  "schedule to cancel": 'Đang trong lịch hủy bỏ',
  "search": 'Tìm kiếm',
  "search anythings in FeedIn": 'Tìm kiếm trong Feedin',
  "Search by Name": 'Tìm kiếm theo tên',
  "Search Results": 'Kết quả tìm kiếm',
  "secret channel": 'Kênh riêng tư',
  "section": 'Phân khúc',
  "See all": 'Xem tất cả',
  "See all charts in the data": 'Xem tất cả biểu đồ trong dữ liệu',
  "see less": 'Thu gọn',
  "see more": 'Xem thêm...',
  "select / create a group": 'Chọn / Tạo 1 nhóm',
  "Select a content category or follow the authors to get relevant information": 'Chọn chuyên mục nội dung hoặc theo dõi các tác giả để có được thông tin liên quan',
  "select category": 'Chọn chuyên mục',
  "select preview photo": 'Chọn hình xem đại diện',
  "Select the display order in the category": 'Chọn thứ tự hiển thị trong danh mục',
  "Select the type of content you want to see. And FeedIn will collect and display news that suits your interests.": 'Chọn loại nội dung bạn muốn xem. Và FeedIn sẽ thu thập và hiển thị tin tức phù hợp với sở thích của bạn.',
  "select your country": 'Chọn quốc gia của bạn',
  "selected": 'Đã chọn',
  "seller": 'Người bán',
  "Seller Contact Details": 'Thông tin liên hệ người bán',
  "seller name": 'Tên người bán',
  "send": 'Gửi',
  "Send Email": 'Gửi thư',
  "Send invitation to join the channel to your friends, by sending a copy of the page link below": 'Gửi lời mời tham gia kênh đến bạn bè, bằng cách gửi sao chép liên kết trang bên dưới',
  "Send invitation to join the provider to your friends, by sending a copy of the page link below": 'Gửi lời mời tham gia là thành viên tổ chức đến bạn bè, bằng cách gửi sao chép liên kết trang bên dưới ',
  "send request": 'Gửi yêu cầu',
  "Send your message to this provider": 'Gửi tin nhắn của bạn đến tổ chức này',
  "sending": 'Đang gửi',
  "sent a request to become member": 'Đã gửi một yêu cầu để làm thành viên',
  "services": 'Dịch vụ',
  "set main": 'Thiết lập làm chính',
  "set up content selection by language": 'Thiết lập chọn nội dung theo ngôn ngữ',
  "setting": 'Cài đặt',
  "settings": 'Cài đặt',
  "share": 'Chia sẻ',
  "share a post": 'Chia sẻ bài viết',
  "share button": 'Nút Chia sẻ',
  "share your ideas": 'Chia sẻ ý tưởng của bạn',
  "Share your knowledgeable or your information to enhance your presence, build a brand, make friends with potential partners or even provide information for a fee.": 'Chia sẻ thông tin và kiến ​​thức của bạn để tăng cường sự hiện diện của bạn, xây dựng thương hiệu, kết bạn với các đối tác tiềm năng hoặc cung cấp thông tin có tính phí.',
  "Sharing the information": 'Chia sẻ thông tin',
  "short description": 'Mô tả ngắn',
  "Short description about chart!": 'Mô tả ngắn về biểu đồ',
  "Short description about your company!": 'Mô tả ngắn về công ty của bạn!',
  "Short description about your dataset!": 'Mô tả ngắn về bộ dữ liệu',
  "show in category": 'Hiển thị trong nhóm mục',
  "show more": 'Hiển thị thêm',
  "show this post": 'Xem bài viết',
  "sign in": 'Đăng nhập',
  "sign in with": 'Đăng nhập với',
  "sign up": 'Đăng ký',
  "signin / signup": 'Đăng nhập / Đăng ký',
  "site": 'Địa chỉ trang web',
  "sku": 'Mã SP (SKU)',
  "Smileys & People": 'Smileys & People',
  "sold by": 'bán bởi',
  "some messages...some messages...": 'some messages...some messages...',
  "Sorry, the page you visited does not exist.": 'Rất tiếc, trang bạn truy cập không tồn tại.',
  "Sorry, the page you visited has expired or does not exist.": 'Xin lỗi, trang bạn truy cập đã hết hạn sử dụng hoặc không tồn tại.',
  "source": 'Nguồn',
  "Specification Details": 'Thông số',
  "Additional Details": 'Thuộc tính bổ sung',
  "Specifications": 'Thông số',
  "start free trial": 'Bắt đầu dùng thử miễn phí',
  "start trial": 'Bắt đầu dùng thử',
  "start your no-cost {subscription} trial today": 'Bắt đầu dùng thử {subscription} miễn phí ngay hôm nay',
  "start your no-cost trial today": 'Bắt đầu dùng thử miễn phí ngay hôm nay',
  "state": 'Bang',
  "status": 'Trạng thái',
  "stay connected": 'Giữ kết nối',
  "submit": 'Gửi đi',
  "Submit requirement": 'Gửi yêu cầu',
  "subscribe": 'Đăng ký',
  "subscribe channel": 'Đăng ký kênh',
  "Subscribe to the categories to display the charts you're interested in": 'Đăng ký theo dõi các danh mục để hiển thị các biểu đồ mà bạn quan tâm đến',
  "Subscribe to the channels for information displayed on this page": 'Đăng ký các kênh để biết thông tin hiển thị trên trang này',
  "subscribed data": 'Dữ liệu đã đăng ký',
  "subscribers": 'Người đăng ký',
  "subscribing this channel will cost you": 'Đăng ký kênh này sẽ khiến bạn mất phí',
  "subscriptions": 'Đăng ký',
  "success": 'Thành công',
  "successful": 'Thành công',
  "successfully purchase!": 'Mua thành công!',
  "suggested products": 'Sản phẩm đề xuất',
  "summary": 'Tóm lược',
  "Summary of the latest news today": 'Tóm tắt những tin tức mới nhất hôm nay',
  "supplied by": 'Được cung cấp bởi',
  "provider": 'Tổ chức',
  "provider address": 'Địa chỉ tổ chức',
  "provider details": 'Chi tiết về tổ chức',
  "provider homepage": 'Trang chủ tổ chức',
  "provider name": 'Tên tổ chức',
  "provider profile": 'Hồ sơ tổ chức',
  "provider profile was created successful": 'Hồ sơ tổ chức đã được tạo thành công',
  "provider profiles": 'Hồ sơ tổ chức',
  "provider's channels": 'Kênh của tổ chức',
  "providers": 'Tổ chức',
  "Supporting brand promotion and market information to enhance business and networking opportunities.": 'Hỗ trợ quảng bá thương hiệu, thông tin thị trường nhằm tăng cường cơ hội kết nối, giao thương.',
  "Symbols": 'Symbols',
  "table of contents": 'Mục lục',
  "Tap": 'Nhấp',
  "tax": 'Thuế',
  "tax code": 'Mã số thuế',
  "Tax ID": 'Mã số thuế',
  "tax inclusive": 'Đã bao gồm thuế',
  "tax number": 'Mã Số Thuế',
  "Tell us what you need": 'Nói với chúng tôi điều bạn cần tìm kiếm',
  "template": 'biểu mẫu',
  "terms of service": 'Điều khoản dịch vụ',
  "terms-of-service": 'Điều khoản dịch vụ',
  "the {package} subscription will be continue effect until {date}": 'Đăng ký {package} sẽ tiếp tục có hiệu lực cho đến {date}',
  "the activation email has been sent to": 'Email kích hoạt đã được gửi tới',
  "The banner image will be placed on the company's homepage. Best view with 256 x 1280 pixels resolution. Image type: PNG, JPG.": 'Hình ảnh banner sẽ được đặt trên trang chủ của công ty. Xem tốt nhất với độ phân giải 256 x 1280 pixels. Loại ảnh: PNG, JPG.',
  "the channel has been deleted": 'Kênh đã bị xóa',
  "the channel so that the system will automatically help you update with the latest news": 'kênh để hệ thống sẽ tự động giúp bạn cập nhật những tin tức mới nhất',
  "The chart is enabled and ready to be displayed to the user": 'Biểu đồ được kích hoạt và sẵn sàng để hiển thị cho người dùng',
  "The chart marked as typical and used to represent the data set": 'Biểu đồ được đánh dấu là điển hình và được sử dụng để làm đại diện cho tập dữ liệu',
  "The content (images, posts ...) is shared in the channel, only members of the channel see, discuss. Other users cannot view or respond to this content": 'Các nội dung (hình ảnh, bài viết...) được chia sẻ trong kênh, chỉ thành viên trong kênh nhìn thấy, cùng thảo luận. Người dùng khác không thể xem, phản hồi nội dung này',
  "the information that interests you": 'Thông tin mà bạn quan tâm',
  "The interaction between that member and the channel administrator of each member will be strictly confidential. Only the administrator or the individual in the channel can see each other's information.": 'Các tương tác giữa chính thành viên đó với quản trị viên kênh của từng thành viên một sẽ bảo mật tuyệt đối. Chỉ quản trị viên hoặc riêng cá nhân trong kênh mới nhìn thấy thông tin của nhau.',
  "the latest news": 'Tin tức mới nhất',
  "The members shown here include: Owner, administrator, member as contact person.": 'Các thành viên hiển thị ở đây bao gồm: Chủ sở hữu, quản trị viên, thành viên là người liên hệ.',
  "The page content is not yet available or being written": 'Nội dung trang chưa có sẵn hoặc đang được viết',
  "The page you are looking for does not exist. Tap the button below to return to the homepage.": 'Trang bạn đang tìm kiếm không tồn tại. Nhấp vào nút bên dưới để quay lại trang chủ.',
  "the restore email has been sent to": 'Email khôi phục đã được gửi đến',
  "The state of the data set. If \"On\", the dataset is ready to use": 'Trạng thái của bộ dữ liệu. Nếu \"Bật\", bộ dữ liệu sẵn sàng sử dụng',
  "the provider has been deleted": 'Đơn vị tổ chức đã được xóa',
  "the providers i am involved in": 'Đơn vị tổ chức mà tôi tham gia',
  "The tax code value has been saved successfully": 'Mã số thuế đã được lưu thành công',
  "The verification call is pending": 'Cuộc gọi xác minh đang chờ xử lý',
  "This account has not registered an email address for authentication, please register an email address to verify the ownership of the account": 'Tài khoản này chưa đăng ký địa chỉ mail để xác thực, vui lòng đăng ký địa chỉ email để xác thực tính sở hữu của tài khoản',
  "This channel content is limited to members only, join now to view this channel content.": 'Nội dung kênh này chỉ giới hạn cho thành viên, hãy tham gia ngay để xem nội dung kênh này.',
  "This channel doesn't have any pinned post": 'Kênh này không có bài đăng nào được ghim',
  "this channel supports communication in the form of hidden interactions between members": 'Kênh này hỗ trợ giao tiếp dưới dạng tương tác ẩn giữa các thành viên',
  "this channel to your friends": 'kênh này cho bạn bè của bạn',
  "This channel will charge you": 'Kênh sẽ thu phí từ bạn',
  "This is a description.": 'Đây là đoạn mô tả',
  "this is a regular channel": 'Đây là kênh thông thường',
  "This is an error message": 'Có 1 một thông báo lỗi',
  "This product will be removed from your Catalog and won't be visible to buyers. You will also stop receiving BuyLeads for this product gradually. Are you sure you want to continue?": 'Sản phẩm này sẽ bị xóa khỏi Danh mục của bạn và sẽ không hiển thị với người mua. Bạn cũng sẽ dần dần ngừng nhận BuyLeads cho sản phẩm này. Bạn có chắc chắn muốn tiếp tục không?',
  "Time for updating the tracking list:": 'Thời gian cho phép cập nhật chuyên mục theo dõi:',
  "timeline": 'Dòng thời gian',
  "timeline is only viewable with accounts that are members of this channel": 'Dòng thời gian chỉ có thể xem được với các tài khoản là thành viên của kênh này',
  "timeline is viewed internally only": 'Dòng thời gian chỉ được xem nội bộ',
  "title": 'Tựa đề',
  "title or question": 'Câu hỏi',
  "to channel": 'Đến kênh',
  "to create new channel on your company": 'để tạo kênh mới về công ty của bạn',
  "to create your new channel": 'để tạo mới kênh của bạn',
  "To edit your details, please visit {profile}": 'Để chỉnh sửa chi tiết, vui lòng truy cập {profile}',
  "to find content that interests you": 'để tìm nội dung mà bạn quan tâm',
  "To join the channel and follow the content regularly, you must Login / Register (free) account to become a member": 'Để tham gia vào kênh và theo dõi nội dung thường xuyên, bạn phải Đăng nhập / Đăng ký mới (miễn phí) tài khoản để trở thành thành viên',
  "To make changes to your subscriptions , go to": 'Để thay đổi đăng ký của bạn, hãy đi đến',
  "To manage products visit at {manage_products} or continue with {call_verification}": 'Để quản lý sản phẩm, hãy truy cập tại {management_products} hoặc tiếp tục với {call_verification}',
  "To stay on your current plan after your trial ends, add payment now.": 'Để giữ đăng ký của bạn sau khi đợt dùng thử của bạn kết thúc, hãy nạp thanh toán ngay bây giờ.',
  "to upgrade. This subscription cost you": 'để nâng cấp. Gói đăng ký này lấy phí từ bạn',
  "topic": 'Chủ đề',
  "total": 'Tổng cộng',
  "Total due": 'Tổng số đến ngày',
  "Total due {date}": 'Tổng đến ngày {date}',
  "Travel & Places": 'Travel & Places',
  "trial": 'Dùng thử',
  "trial days": 'Số ngày dùng thử',
  "trust and safe": 'Tin cậy và An toàn',
  "trust-safe": 'Tin tưởng và An toàn',
  "tutorials": 'Hướng dẫn',
  "type product name": 'Gõ tên sản phẩm',
  "type your message": 'Gõ tin nhắn của bạn',
  "typify chart": 'Biểu đồ tiêu biểu',
  "unban": 'Bỏ cấm thành viên',
  "unbookmark": 'Bỏ đánh dấu',
  "unbookmarked": 'Đã bỏ đánh dấu',
  "unclassified products": 'Sản phẩm chưa được phân loại',
  "unfollow": 'Bỏ theo dõi',
  "unit": 'Đơn vị',
  "unlimited": 'Không giới hạn',
  "unpin": 'Bỏ ghim',
  "unpin this?": 'Bỏ ghim mục này',
  "unsave": 'Bản chưa lưu',
  "unsubscribe": 'Hủy đăng ký',
  "update": 'Cập nhật',
  "update about success": 'Giới thiệu cập nhật thành công',
  "update dataset": 'Cập nhật bộ dữ liệu',
  "update fail": 'Cập nhật bị lổi',
  "update success": 'Cập nhật thành công',
  "Updates to you the information you have chosen to follow": 'Cập nhật đến bạn thông tin bạn đã theo dõi',
  "upgrade": 'Nâng cấp',
  "upgrade now. This subscription cost you": 'Nâng cấp ngay bây giờ. Đăng ký này lấy phí của bạn',
  "upgrade subscription": 'Nâng cấp gói đăng ký',
  "upgrade this channel": 'Nâng cấp kênh',
  "upgrade to": 'Nâng cấp lên',
  "upgrade to {name}": 'Nâng cấp lên {name}',
  "upload": 'Tải lên',
  "url": 'Đường dẫn',
  "us to share the news in this channel": 'với chúng tôi để chia sẻ tin tức trong kênh này',
  "Usage: for direct communication purposes through messaging.": 'Sử dụng: cho các mục đích liên lạc trực tiếp thông qua tin nhắn',
  "use email": 'Đăng nhập Email',
  "use the selected system language": 'Dùng theo ngôn ngữ hệ thống đã chọn',
  "used group": 'Các nhóm đã dùng',
  "user": 'Người dùng',
  "user choices": 'Chọn hiển thị bởi người dùng',
  "user management": 'Quản lý tài khoản',
  "uses": 'Đã dùng',
  "value": 'Giá trị',
  "Verification request has been sent, please wait for a response": 'Yêu cầu xác minh đã được gửi đi, vui lòng chờ phản hồi',
  "verified provider": 'Tổ chức đã được xác nhận',
  "Verify Account": 'Xác nhận tài khoản',
  "Verify on Call": 'Xác minh qua cuộc gọi',
  "Verify sales information": 'Xác thực thông tin bán hàng',
  "vi": 'Tiếng Việt',
  "video url": 'Video URL (Liên kết trang Youtube)',
  "vietnamese": 'Tiếng Việt',
  "view": 'Xem',
  "view all": 'Xem tất cả',
  "View all categories": 'Xem tất cả danh mục',
  "View all products in {name}": 'Xem tất cả các sản phẩm trong {name}',
  "View complete detail": 'Xem chi tiết',
  "view full estimate": 'Xem đầy đủ kỳ thanh toán tiếp theo',
  "view more charts": 'Xem thêm biểu đồ',
  "view product page": 'Xem trang sản phẩm',
  "views": 'Lượt truy cập',
  "visit page": 'Ghé thăm trang',
  "Visit the page to preview": 'Truy cập trang để xem trước',
  "vnpay": 'VNPay',
  "volume": 'Số lượng',
  "vote": 'Ủng hộ',
  "voted": 'Đã ủng hộ',
  "waiting": 'Đang chờ',
  "waiting admin accept your request": 'Chờ quản trị viên chấp nhận yêu cầu của bạn',
  "waiting members list": 'DS đăng ký tham gia',
  "wanna more features?": 'Bạn muốn thêm tính năng?',
  "we will upgrade you immediately, you won't have to pay anything until your trial ends": 'Chúng tôi sẽ nâng cấp bạn ngay lập tức, bạn sẽ không phải trả bất cứ điều gì cho đến khi đợt dùng thử của bạn kết thúc',
  "we will upgrade you to {subscription} immediately, you won't have to pay anything until your trial ends": 'Chúng tôi sẽ nâng cấp bạn lên {subscription} ngay lập tức, bạn sẽ không phải trả bất cứ điều gì cho đến khi đợt dùng thử của bạn kết thúc',
  "website": 'Trang web',
  "welcome": 'Chào bạn',
  "what are you looking for": 'Bạn đang cần tìm kiếm gì ?',
  "what is your chart name?": 'Tên của biểu đồ của bạn là gì ?',
  "what is your company name?": 'Công ty của bạn tên là ?',
  "what is your name?": 'Tên của bạn là gì ?',
  "what is your question?": 'Câu hỏi của bạn là gì ?',
  "what your channel name?": 'Kênh của bạn tên là gì ?',
  "what your product name?": 'Sản phẩm của bạn tên là gì ?',
  "when you're ready": 'Khi bạn thấy sẵn sàng',
  "which categories are your interests": 'Bạn quan tâm đến chuyên mục nào ?',
  "with account": 'Bằng tài khoản',
  "write a response": 'Viết bình luận',
  "write article": 'Viết bài',
  "write description for this page": 'Viết mô tả cho trang này',
  "write something about this page": 'Viết điều gì đó về trang này',
  "Write your sharing": 'Viết chia sẻ của bạn',
  "written by": 'Được viết bởi',
  "Year of Establishment": 'Năm thành lập',
  "You are not choosing to follow this chart category. Please choose follow up in advance": 'Bạn không chọn theo dõi chuyên mục thuộc biểu đồ này. Vui lòng chọn theo dõi trước',
  "you can not change your following categories before {time}": 'Bạn không thể thay đổi chuyên mục theo dõi trước thời điểm {time}',
  "you can not follow more {n} categories": 'Bạn không thể theo dõi quá {n} chuyên mục',
  "You can only upload JPG/PNG file": 'Bạn chỉ có thể tải hình định dạng JPG/PNG',
  "you commented on {owner}'s post": 'Bạn đã bình luận trên bài viết của {owner}',
  "you currently have a maximum limit of {n} channels allowed to own. You can change it by upgrading your account": 'Bạn hiện có giới hạn tối đa là {n} kênh được phép sở hữu. Bạn có thể thay đổi nó bằng cách nâng cấp tài khoản của bạn',
  "you currently have a maximum limit of {n} providers allowed to own. You can change it by upgrading your account": 'Bạn hiện có giới hạn tối đa là {n} tổ chức được phép sở hữu. Bạn có thể thay đổi giới hạn bằng cách nâng cấp tài khoản của bạn',
  "you didn't enter the channel name correctly": 'Bạn đã không nhập tên kênh đúng chính xác',
  "you didn't enter the provider name correctly": 'Bạn đã nhập tên công ty chưa đúng',
  "You do not have a conversation selected": 'Bạn không có cuộc trò chuyện nào được chọn',
  "you don't have permission to post": 'Không được phép đăng bài',
  "you have not any notification!": 'Bạn chưa có thông báo nào!',
  "You have the maximum of {n} items to follow. The news will be filtered according to the category you choose.": 'Bạn được chọn tối đa {n} mục để theo dõi. Các tin tức sẽ được lọc theo danh mục bạn chọn.',
  "You have the maximum of {n} items to follow. The news will be filtered according to the language you choose.": 'Bạn được chọn tối đa {n} mục để theo dõi. Các tin tức sẽ được lọc theo ngôn ngữ bạn chọn.',
  "You need {amount} to be able subscribe this channel.": 'Bạn cần {amount} để có thể đăng ký kênh này.',
  "you need login first": 'Bạn cần đăng nhập trước khi thực hiện',
  "Your account has been activated": 'Tài khoản của bạn đã được kích hoạt',
  "your answer": 'Câu trả lời của bạn',
  "Your business information has been fully updated, thank you": 'Thông tin doanh nghiệp của bạn đã được cập nhật đầy đủ, xin cảm ơn',
  "your chart": 'Biểu đồ của bạn',
  "Your current account only allows to follow in one language, if you want to remove this limit, please upgrade to a higher package. Thank you": 'Tài khoản hiện tại của bạn chỉ cho phép theo một ngôn ngữ, nếu bạn muốn xóa giới hạn này, vui lòng nâng cấp lên gói cao hơn. Cảm ơn bạn',
  "Your current active subscription package will continue to hold until it expires": 'Gói đăng ký của bạn đang hoạt động hiện tại của bạn sẽ tiếp tục giữ cho đến khi hết hạn',
  "your device don't support this!": 'Thiết bị không hỗ trợ chức năng này',
  "your invite link expires": 'Liên kết của bạn sẽ hết hạn',
  "your message": 'Tin nhắn của bạn',
  "Your phone number": 'Số điện thoại của bạn',
  "your post": 'bài viết của bạn',
  "your request is pending approval": 'Yêu cầu của bạn đang chờ phê duyệt',
  "your subscription will be renew on {date}": 'Đăng ký của bạn sẽ được gia hạn vào {date}',
  "new template": 'Mẫu tin mới',
  "Your message have been sent": 'Tin nhắn của bạn đã được gửi đi',
  "verified": 'Đã xác minh',
  "price history": 'Lịch sử giá',
  "price type": 'Loại giá',
  "product groups": 'Nhóm sản phẩm',
  "representative": 'Đại diện',
  "representative chart": 'Biểu đồ đại diện',
  "the content will be delivered to you in the language you choose": 'Nội dung sẽ được chuyển đến bạn bằng ngôn ngữ bạn chọn',
  "favorites": 'Mục yêu thích',
  "chart name": 'Tên biểu đồ',
  "select categories": 'Chọn chuyên mục',
  "the dataset has been deleted": 'Tập dữ liệu đã được xóa',
  "create article": 'Tạo bài viết',
  "have sent you invitation": 'đã gửi đến bạn lời mời',
  "related dataset": 'Các bộ dữ liệu liên quan',
  "Send invitation to your friends, by sending a copy of the page link below": 'Gửi lời mời đến bạn bè của bạn, bằng cách gửi đi liên kết trang bên dưới',
  "Copy and share the page link below to your friends.": 'Sao chép và chia sẻ liên kết trang dưới đây cho bạn bè của bạn.',
  "Response from provider will be sent via {email}": 'Phản hồi từ tổ chức sẽ được gửi qua {email}',
  "options": 'Tùy chỉnh',
  "action": 'Hành động',
  "timeout": 'Thời gian có hiệu lực',
  "limit": 'Giới hạn số người mời',
  "change": 'Thay đổi',
  "none": 'Không có',
  "5 minutes": '5 phút',
  "30 minutes": '30 phút',
  "1h": '1 giờ',
  "1day": '1 ngày',
  "Join As Member": 'Tham gia với tư cách thành viên',
  "Join As Admin": 'Tham gia với tư cách quản trị viên',
  "others data": 'Dữ liệu khác',
  "channel management": 'Quản lý kênh bài viết',
  "channel list": 'DS kênh bài viết',
  "all channels": 'Tất cả kênh bài viết',
  "technical specs": 'Thông số kỹ thuật',
  "functional specs": 'Thông số chức năng',
  "other specs": 'Thông số khác',
  "custom specs": 'Thông số tùy chỉnh',
  "delivery": 'Delivery',
  "shipment": 'Shipment',
  "period": 'Kỳ hạn',
  "spot": 'Spot',
  "forward": 'Forward',
  "basis": 'Basis',
  "select period": 'Chọn kỳ hạn',
  "from date": 'Từ ngày',
  "to date": 'Đến ngày',
  "Select the category in which the system will provide you with the most current market data": 'Chọn danh mục mà hệ thống sẽ cung cấp cho bạn dữ liệu thị trường mới nhất',
  "Thanks sponsors bring freely these charts to you": 'Cảm ơn những nhà tài trợ đã mang lại những biểu đồ này miễn phí cho bạn',
  "Thanks sponsors for updating this news to you": 'Cảm ơn các nhà tài trợ đã cập nhật tin tức này đến bạn',
  "let's start for free": 'Khởi đầu miễn phí',
  "created": 'Ngày tạo',
  "quotes of provider": 'Báo giá của tổ chức',
  "announce": 'Ngày gửi báo giá',
  "Name": 'Tên',
  "product price announcement": 'Thông báo giá sản phẩm',
  "create report": 'Tạo báo cáo',
  "reports": 'Báo cáo',
  "the recipient email": 'Thư điện tử của người nhận',
  "new email": 'Thư điện tử mới',
  "price_type": 'Loại giá',
  "province": 'Tỉnh / Thành',
  "Pricing Reports": 'Báo cáo giá sản phẩm',
  "Create price alerts to send them to buyers, buying partners, or make product prices announce": 'Tạo thông báo giá để gửi cho người mua, đối tác mua hoặc thông báo giá sản phẩm',
  "products quotation": 'Báo giá sản phẩm',
  "effected date": 'Ngày hiệu lực',
  "price update": 'Cập nhật giá',
  "no price tag": 'Không để giá',
  "updated at": 'Cập nhật lúc',
  "reference price": 'Giá tham chiếu',
  "month": 'Tháng',
  "basis price": 'Giá cơ sở',
  "forward price": 'Giá kỳ hạn',
  "delivery type": 'Loại giao hàng',
  "general basic prices": 'Giá cơ bản chung',
  "leave blank to select all": 'Để trống để chọn tất cả',
  "This information is confidential and is only for the member to be submitted": 'Thông tin này là bảo mật và chỉ dành riêng cho thành viên được gửi',
  "sure to remove": 'Chắc chắn rằng bạn muốn xóa ?',
  "Enter a price for the period": 'Nhập giá cho khoảng thời gian',
  "advanced entry": 'Nhập nâng cao',
  "flat price": 'Giá chốt',
  "quoted prices": 'Giá công bố',
  "start (/end) date in term months": 'Ngày bắt đầu ( / kết thúc) trong các tháng kỳ hạn',
  "select provinces": 'Chọn Tỉnh / Thành',
  "spot price": 'Giá giao ngay',
  "confirm": 'Xác nhận',
  "delivery type option (delivery is default): this option affects the product price": 'Tùy chọn Giá theo hình thức Phân phối (Vận chuyển là mặc định): Tùy chọn này ảnh hưởng đến giá sản phẩm',
  "delivery price": 'Giá Delivery',
  "shipment price": 'Giá Shipment',
  "add spot price": 'Thêm mới giá giao ngay',
  "add forward price": 'Thêm mới giá kỳ hạn',
  "period range": 'Khoảng thời gian',
  "This information is adjusted within the scope of the report, the content of the original information has not been changed": 'Thông tin này được điều chỉnh trong phạm vi báo cáo, nội dung thông tin ban đầu không thay đổi',
  "original price": 'Giá gốc',
  "feedin users": 'Người dùng trên FeedIn',
  "choose user": 'Chọn người dùng',
  "Select the term for the price input": 'Chọn tháng kỳ hạn cho phần nhập giá',
  "price unit": 'Đ/v giá',
  "enter new price": 'Nhập giá mới',
  "Enter the price / update price in this screen, the price will be updated to the latest at the current date for each price type (spot, forward)": 'Nhập / cập nhật ở màn hình này, giá sẽ được cập nhật mới nhất vào thời điểm hiện tại cho từng loại giá (giá giao ngay, giá kỳ hạn)',
  "incoterm": 'Incoterm',
  "transport type": 'Phương tiện chuyên chở',
  "unit of the basis price": 'Đơn vị cho giá cơ bản',
  "new price record by location": 'Bản ghi giá mới theo vị trí',
  "This location has been declared, cannot be added": 'Địa điểm này đã khai báo, không thể thêm mới',
  "choose another location": 'Chọn địa điểm khác',
  "new price set": 'Tạo dữ liệu giá mới',
  "choose another option for new price set": 'Chọn một tùy chọn khác cho bộ giá mới',
  "These options are already declared, cannot be added": 'Các tùy chọn này đã được khai báo, không thể thêm vào',
  "add new forward price": 'Thêm mới giá kỳ hạn',
  "send to": 'Gửi đến',
  "edit quotes": 'Chỉnh sửa báo giá',
  "other unit": 'đơn vị khác',
  "if basis price is required": 'Nếu giá cơ sở là bắt buộc',
  "range": 'Khoảng',
  "editor's choice": 'Biên tập chọn',
  "remove the 2nd unit": 'Xóa đi đơn vị thứ 2',
  "hidden price": 'Không hiển thị giá bán',
  "wholesale": 'Bán sỉ',
  "contact provider for wholesale price": 'Liên hệ tổ chức để có giá bán sỉ',
  "provided by": 'Cung cấp bởi',
  "about product": 'Về sản phẩm',
  "brief description": 'Mô tả ngắn',
  "manufactor": 'Nhà sản xuất',
  "add new specification": 'Thêm mới thông số',
  "selecting...": 'Đang chọn ....',
  "select": 'Chọn',
  "method type": 'Loại phương pháp',
  "operation": 'Operation',
  "visible": 'Hiển thị',
  "attribute": 'Thuộc tính',
  "attribute value": 'Giá trị thuộc tính',
  "trademark": 'Nhãn hiệu',
  "factory": 'Nhà máy',
  "Specification/Additional Details": 'Thông số / Thuộc tính bổ sung',
  "tap on here to start your articles": 'Nhấp vào đây để bắt đầu bài viết',
  "attribute group": 'Nhóm thuộc tính',
  "displayed on product information": 'Hiển thị trên thông tin sản phẩm',
  "page not found": 'Trang không tồn tại',
  "The page you are opening does not exist, please check the link or return to the homepage": 'Trang bạn đang mở không tồn tại, vui lòng kiểm tra liên kết hoặc quay lại trang chủ',
  "catalog group": 'Nhóm mục lục',
  "manage page": 'Trang quản lý',
  "main page": 'Trang chính',
  "use for": 'Sử dụng cho',
  "set_channel": 'Cập nhật kênh đăng bài',
  "added value": 'Giá cộng thêm',
  "product line": 'Nhóm dòng sản phẩm',
  "2nd unit": 'Đơn vị 2',
  "2nd price unit": 'Đơn vị giá 2',
  "The effective date of the current price set will be adjusted to the date below": 'Ngày hiệu lực của bộ giá hiện tại sẽ điều chỉnh về ngày dưới đây',
  "Generate new updated price data effective as of the date below. Data will be temporarily recorded according to the price of the most recent day.": 'Tạo dữ liệu giá mới cập nhật có hiệu lực theo ngày dưới đây. Dữ liệu sẽ tạm thời ghi nhận theo giá của ngày gần nhất.',
  "Copy the current priceset for another location with the same priceset": 'Sao chép tập giá hiện tại cho một vị trí khác có cùng tập giá',
  "Please contact the administrator of FeedIn to put the data on the site": 'Vui lòng liên hệ quản trị viên của FeedIn để đưa dữ liệu lên trang web',
  "Paste a link to embed content from another site (e.g. Twitter) and click Ok":'Dán đường dẫn ở đây để nhúng nội dung từ một trang web khác (ví dụ: Twitter) và nhấp Ok',
  "Paste a video link to embed content from another site (e.g. Youtube) and click Ok":'Dán  đường dẫn ở đây để nhúng video từ một trang web khác (ví dụ: Youtube) và nhấp vào Ok',
  "Announcement of information":'Thông báo thông tin',
  "Sell products, supply items to the market":'Bán sản phẩm, cung cấp mặt hàng ra thị trường',
  "Provide published information and data for the industry":'Cung cấp thông tin và dữ liệu đã xuất bản cho ngành',
  "event": 'Sự kiện',
  "events":'Sự kiện',
  "create event":'Tạo sự kiện',
  "event name":'Tên sự kiện',
  "what is your event name?":'Tên sự kiện là gì?',
  "end date and time":'Thêm thời gian kết thúc',
  "time":'Thời gian',
  "back to home":'Quay về trang chính',
  "meeting":'Họp mặt',
  "online":'Trực tuyến',
  "online event?":'Sự kiện trực tuyến?',
  "online event":'Sự kiện trực tuyến',
  "co-hosts":'Đồng chủ quản',
  "created by":'Tạo bởi',
  "milestone":'Mốc sự kiện',
  "start date":'Ngày bắt đầu',
  "start time":'Giờ bắt đầu',
  "end date":'Ngày kết thúc',
  "end time":'Giờ kết thúc',
  "Select event type (online / meeting)":'Chọn hình thức sự kiện (trực tuyến / hội họp)',
  "Event, what?, when?":'Sự kiện, Về chủ đề gì?, Thời gian?',
  "Event name, start time ":'Tên sự kiện, thời gian diễn ra',
  "Add a physical location for people to join your event. Or how to meet together in online event":'Thêm vị trí thực tế để mọi người tham gia sự kiện của bạn. Hoặc làm thế nào để gặp nhau trong sự kiện trực tuyến',
  "Event, where?":'Sự kiện, Ở đâu?',
  "Provide more information about your event so guests know what to expect.":'Cung cấp thêm thông tin về sự kiện của bạn để khách mời biết điều gì sẽ xảy ra.',
  "Event, how?":'Sự kiện, Như thế nào?',
  "Everyone meeting on the internet.":'Mọi người họp trên internet.',
  "Everyone meet together at a specific location.":'Mọi người cùng gặp nhau tại một địa điểm cụ thể.',
  "required":'Thông tin yêu cầu nhập',
  "If you have completely forgotten your old password. Please log out and use the \"{1}\" function to reset the password if necessary":'Nếu bạn đã hoàn toàn quên mật khẩu cũ của mình. Vui lòng đăng xuất và sử dụng chức năng \"{1}\" để đặt lại mật khẩu nếu cần',
  "Please select the language you want as the display language on the application":'Vui lòng chọn ngôn ngữ bạn muốn làm ngôn ngữ hiển thị trên ứng dụng',
  "farm":'Nông trại',
  "recover login password":'Khôi phục mật mã đăng nhập',
  "individual":'Cá nhân',
  "you are":'Bạn là',
  "organization name":'Tên tổ chức',
  "attention: please check your activation email in the spam area":'Chú ý: vui lòng kiểm tra email kích hoạt của bạn trong Khu vực thư rác',
  "with your email":'Bằng địa chỉ email của bạn',
  "no products, please add products to your page":'Không có sản phẩm, bạn hãy thêm sản phẩm cho trang của bạn',
  "price set history management": 'Quản lý lịch sử tập giá',
  "product configuration": 'Cấu hình sản phẩm',
  "events in category":'Sự kiện trong chuyên mục',
  "no events":'Không có sự kiện',
  "select / create":'Chọn / Tạo mới',
  "co-admins":'Đồng quản trị',
  "weekly":'Hàng tuần',
  "quarterly":'Hàng quý',
  "yearly":'Hàng năm',
  "the event will repeat in": 'Sự kiện sẽ lặp lại',
  "finish time":'Thời gian kết thúc',
  "updating date": 'Ngày cập nhật',
  "price is valid from the date": 'Giá có hiệu lực tại ngày',
  "contract type": 'Loại hợp đồng',
  "reminder": 'Nhắc nhở',
  "type of event": 'Loại sự kiện',
  "invisible": 'Không hiển thị',
  "speakers":'Diễn giả',
  "select user": 'Chọn người dùng',
  "event_types": 'Loại sự kiện',
  "something is wrong, want to delete it?": 'Có gì đó không đúng, muốn xóa nó?',
  "successfully deleted": 'Đã xóa thành công!',
  "are you sure to delete this?": 'Bạn có chắc chắn xóa cái này không?',
  "deletion is ignored": 'Hành động Xóa bị bỏ qua',
  "insert here": 'Chèn ở đây',
  "not milestone": 'Diễn biến',
  "The next event is scheduled in": 'Sự kiện tiếp theo được lên lịch sau',
  "The next event is scheduled to take place at {1}": 'Sự kiện tiếp theo dự kiến ​​diễn ra lúc {1}',
  "start": 'Bắt đầu',
  "finish": 'Kết thúc',
  "products can be searched": 'Có thể tìm kiếm sản phẩm',
  "your product has been declared complete, you can put it on the store": 'Sản phẩm của bạn đã được khai báo hoàn chỉnh, bạn có thể đưa lên gian hàng',
  "Do you want to activate this product?": 'Bạn có muốn kích hoạt sản phẩm này không?',
  "This product will add to your Activated Catalog and will be visible to buyers. You will also start receiving BuyLeads for this product. Thank you": 'Sản phẩm này sẽ thêm vào Danh mục đã kích hoạt của bạn và sẽ hiển thị với người mua. Bạn cũng sẽ bắt đầu nhận BuyLeads cho sản phẩm này. Cám ơn bạn',
  "published": 'Đã xuất bản',
  "profiles": 'Hồ sơ',
  "incoming requests management": 'Quản lý yêu cầu gửi đến',
  "waiting members": 'Thành viên đang đăng ký',
  "sales": 'Bán hàng',
  "purchases": 'Mua hàng',
  "quote prices": 'Quản lý báo giá',
  "market prices": 'Giá thị trường',
  "Contact information has not been declared, it is good to declare contact information so that customers can contact you directly": 'Thông tin liên hệ chưa được khai báo, thật tốt nếu khai báo thông tin liên hệ để khách hàng có thể liên hệ trực tiếp đến bạn',
  "members management": 'Quản lý thành viên',
  "partners": 'Đối tác',
  "vendor": 'Nhà cung cấp',
  "vendors": 'Nhà cung cấp',
  "customer": 'Khách hàng',
  "customers": 'Khách hàng',
  "contact books": 'Sổ danh bạ',
  "add new contact book": 'Thêm mới vào danh bạ',
  "connect to user": 'Liên kết đến Người dùng',
  "connect to organization": 'Liên kết đến Tổ chức',
  "connect to partner": 'Liên kết đến Đối tác',
  "new contact book": 'Thêm mới danh bạ',
  "new partner": 'Thêm đối tác mới',
  "partner type": 'Loại đối tác',
  "partner name": 'Tên đối tác',
  "edit contact book": 'Chỉnh sửa danh bạ',
  "edit partner": 'Chỉnh sửa đối tác',
  "all partners": 'Tất cả đối tác',
  "add new partner": 'Thêm đối tác mới',
  "for sales notes": 'Ghi chú bán hàng',
  "for purchase notes": 'Ghi chú mua hàng',
  "notes": 'Ghi chú',
  "Note: The effective date of the price being updated is different from the price entry date": 'Lưu ý: Ngày cập nhật giá có hiệu lực khác với ngày nhập giá',
  "product catalog": 'Nhóm hiển thị sản phẩm',
  "group by": 'Nhóm theo',
  "type of product use for sales / purchases": 'Loại sản phẩm sử dụng để bán hàng / mua hàng',
  "sales / purchases": 'Bán hàng / Mua hàng',
  "added": 'Đã thêm',
  "feedin organizations": 'Tổ chức trên FeedIn',
  "refresh chart": 'Cập nhật biểu đồ',
  "incoming quotes": 'Báo giá gửi đến',
  "purchasing planning": 'Lập kế hoạch mua hàng',
  "manage outgoing quotes": 'Quản lý báo giá gửi đi',
  "product group title": 'Tên nhóm sản phẩm',
  "Successfully Sign Up": 'Đăng ký thành công',
  "redirect in {1} seconds": 'chuyển hướng sau {1} giây',
  "Thank you for registering, Website will redirect to login page after 3 seconds": 'Cảm ơn bạn đã đăng ký, Website sẽ chuyển hướng đến trang đăng nhập sau 3 giây',
  "add products":'Thêm sản phẩm',
  "price offer partners":'Đối tác cung cấp giá',
  "Select the products for which you want to send price information to your partner":'Chọn sản phẩm mà bạn muốn gửi thông tin giá cho đối tác',
  "products for partner":'Sản phẩm cho đối tác',
  "the list below shows only partners linked to the organizations on FeedIn": 'Danh sách dưới đây chỉ hiển thị các đối tác được liên kết với các tổ chức trên FeedIn',
  "bookmarks": 'Sổ lưu đánh dấu',
  "my space":'Góc riêng',
  "all bookmarks": 'Tất cả mục đánh dấu',
  "quotations for partners":'Báo giá cho đối tác',
  "favourites": 'Mục yêu thích',
  "vietnam market": 'Thị trường Việt Nam',
  "workspace": 'Góc làm việc',
  "updates": 'Cập nhật',
  "for search optimization": 'để tối ưu công việc tìm kiếm',
  "to optimize product display": 'nhằm tối ưu hiển thị sản phẩm',
  "to group products with the same properties, compare prices": 'nhằm gom nhóm các sản phẩm có cùng tính chất, so sánh giá',
  "be sent from partners": 'Được gửi đến từ đối tác',
  "owned": 'Sở hữu',
  "Prices below are average prices with selected location or selected period is All": 'Giá dưới đây là giá trung bình với vị trí đã chọn hoặc khoảng thời gian đã chọn là Tất cả',
  "Can follow the latest news and events in the industry": 'Có thể theo dõi kênh tin tức và sự kiện mới nhất trong ngành hàng',
  "Explore dataset and charts": 'Khám phá tập dữ liệu và biểu đồ',
  "to help you manage": 'Để giúp bạn quản trị',
  "or create an organization as": 'HOẶC tạo Tổ chức ứng với',
  "create organization": 'Tạo tổ chức',
  "farmer": 'Nông trại',
  "buyer": 'Mua hàng',
  "Create events to reach the commodity market":'Tạo sự kiện để tiếp cận thị trường hàng hóa',
  "Create news channels to announce and share information to the commodity market":'Tạo kênh tin để công bố, chia sẻ thông tin đến thị trường ngành hàng hóa ',
  "Cooperate with FeedIn to share and provide commodity data to customers":'Hợp tác với FeedIn để chia sẻ và cung cấp dữ liệu hàng hóa cho khách hàng',
  "Please contact the administrator of FeedIn":'Vui lòng liên hệ với quản trị viên của FeedIn',
  "name required more than {1} characters":'Tên nội dung yêu cầu nhiều hơn {1} ký tự',
  "set subscription": 'Đặt đăng ký',
  "subscribed": 'Đã đăng ký',
  "edit contact": 'Chỉnh sửa thông tin liên hệ',
  "Information gathered from the declaration in the contact information": 'Thông tin thu thập từ khai báo trong thông tin liên hệ',
  "The information will be linked to the information of partner": 'Thông tin sẽ được liên kết với thông tin của đối tác',
  "not ready": 'Chưa sẵn sàng',
  "product had basic information and ready to launch": 'Sản phẩm đã có thông tin cơ bản và sẵn sàng ra mắt',
  "internal use": 'Lưu hành nội bộ',
  "{launch} the product to display on the store": '{launch} sản phẩm để trưng bày trên cửa hàng',
  "OR {activate} to input the price and manage it internally": 'HOẶC {activate} để nhập giá và quản lý giá nội bộ',
  "launch": 'Khởi chạy',
  "activate only": 'Mở hoạt động',
  "{deactivate} product": '{deactivate} sản phẩm',
  "deactivate": 'Hủy trạng thái hoạt động',
  "set effect date": 'Điều chỉnh ngày hiệu lực',
  "insert at": 'Thêm giá tại ngày',
  "expire at": 'Dừng hiệu lực ngày',
  "the page does not exist or the page is for internal use, you must be a member to view the content of this page.": 'Trang không tồn tại HOẶC trang sử dụng nội bộ, bạn phải là thành viên mới xem nội dung của trang này.',
  "hybrid event": 'Kết hợp Hội họp / Trực tuyến',
  "no data yet": 'Chưa có dữ liệu',
  "Please login to view this content": 'Bạn vui lòng đăng nhập để xem nội dung thông tin này',
  "no data is available now": 'Hiện tại chưa có dữ liệu để hiển thị',
  "latest tracking news":'Tin tức theo dõi mới nhất',
  "activate": 'Kích hoạt',
  "Buy goods, collect quotes from sellers": 'Mua hàng, tổng hợp nhận báo giá từ người bán',
  "following charts": 'Các biểu đồ đang theo dõi',
  "Leave your information so we can contact you again": 'Để lại thông tin để chúng tôi có thể liên hệ lại',
  "contact information": 'Thông tin liên hệ',
  "purchase": 'Mua hàng',
  "enter spot price": 'Nhập giá giao ngay',
  "present": 'Hiện nay',
  "weather": 'Thời tiết',
  "Temperature - weather, tap to see more": 'Nhiệt độ - thời tiết, nhấn để xem thêm',
  "Rain (thunder) - weather, tap to see more": 'Mưa (sấm sét) - thời tiết, nhấn để xem thêm',
  "Wind - weather, tap to see more": 'Gió - thời tiết, nhấn để xem thêm',
  "temperature": 'Nhiệt độ',
  "rain": 'Mưa',
  "wind": 'Gió',
  "edit & save": 'Sửa & Lưu',
  "create from": 'Tạo mới từ',
  "remove expired": 'Bỏ hết hiệu lực',
  "delete prices at": 'Xóa giá tại',
  "copy prices at": 'Sao chép giá tại',
  "enter forward price": 'Nhập giá kỳ hạn',
  "affiliate products": 'Sản phẩm liên kết',
  "at date": 'tại ngày',
  "days": 'ngày',
  "months": 'tháng',
  "This pricing information will expire in": 'Thông tin giá này sẽ hết hạn sau',
  "This pricing information will expire at": 'Thông tin giá này sẽ hết hạn vào',
  "no expired date": 'Không có ngày hết hạn',
  "quarters": 'quý',
  "years": 'năm',
  "you have an article in progress": 'Bạn có một bài viết đang thực hiện',
  "Create an information channel to promote your information and products to everyone": 'Tạo kênh thông tin để quảng bá thông tin và sản phẩm của bạn đến với mọi người',
  "get {free} quotes from multiple sellers": 'Nhận báo giá {free} từ nhiều người bán',
  "Tell us your Requirement": 'Cho chúng tôi biết yêu cầu của bạn',
  "Tell us what You Need": 'Nói cho chúng tôi bạn cần gì',
  "Receive free quotes from sellers": 'Nhận báo giá miễn phí từ người bán',
  "Seal the Deal": 'Tiến hành giao dịch',
  "Enter product/ Service name": 'Nhập tên sản phẩm / dịch vụ',
  "Your email address": 'Địa chỉ email của bạn',
  "Enter your name": 'Nhập tên của bạn',
  "Your live in": 'Bạn sống ở',
  "Submit Requirement": 'Gửi yêu cầu',
  "We connect": 'Chúng tôi kết nối',
  "Buyers Sellers": 'Người mua & Người bán',
  "Trusted Platform": 'Nền tảng đáng tin cậy',
  "Safe Secure": 'An toàn & bảo mật',
  "Quick Assistance": 'Hỗ trợ nhanh',
  "Create events so people can participate and learn more about your organization": 'Tạo sự kiện để mọi người có thể tham gia và hiểu thêm về tổ chức của bạn',
  "my articles": 'Bài viết của tôi',
  "Use keyboard shortcuts": 'Sử dụng phím tắt',
  "edited by": 'Được soạn thảo bởi',
  "Publishing to:": 'Xuất bản bởi:',
  "no data": 'Chưa có dữ liệu',
  "write caption for image": 'Viết chú thích cho hình ảnh',
  "caption of image": 'Chú thích của hình ảnh',
  "write caption for video": 'Viết chú thích cho video',
  "caption of video": 'Chú thích của video',
  "video url is invalid!": 'Video không tồn tại!',
  "enter video url": 'Nhập đường dẫn video',
  "add caption": 'Thêm chú thích',
  "related products": 'Sản phẩm liên quan',
  "products from the same organization":"Sản phẩm cùng tổ chức",
  "get direction":'Hướng dẫn đường đi',
  "export purchasing data":'Xuất dữ liệu mua hàng',
  "export sales data":'Xuất dữ liệu bán hàng',
  "select page":'Chọn trang',
  "edit location":'Chỉnh sửa vị trí',
  "send requirement":'Gửi yêu cầu',
  "detail requirement":'Chi tiết yêu cầu',
  "attach file":'Đính kèm',
  "how it work":'Cách hoạt động',
  "tell us what you need by filling the form":'Điền vào biểu mẫu yêu cầu của bạn',
  "receive verified supplier details":'Nhận báo giá từ các nhà cung cấp',
  "compare quotations and seal the deal":'So sánh các báo giá và giao dịch',
  "your information":'Thông tin của bạn',
  "choosing a category will help the article stay on topic and more accessible to users":'Chọn chuyên mục sẽ giúp bài viết nằm đúng chủ đề của nó và dễ tiếp cận hơn với người dùng',
  "please add images to make the article more vivid and realistic to attract more viewers":'Vui lòng thêm hình ảnh để bài viết trở nên sinh động và chân thực hơn để thu hút nhiều người xem hơn',
  "data has been generate":'Dữ liệu đã được tạo',
  "please click the {download} button below to download to your device":'Bạn vui lòng bấm nút {download} bên dưới để tải về thiết bị',
  "article preview":'xem trước bài viết',
  "back to edit":'Quay lại để chỉnh sửa',
  "please login so the supplier can contact you":'Vui lòng đăng nhập để nhà cung cấp có thể liên hệ với bạn',
  "product information":'Thông tin sản phẩm',
  "please provide a few details to get the most accurate response from the supplier":'Vui lòng cung cấp một vài thông tin chi tiết để nhận được phản hồi chính xác nhất từ nhà cung cấp',
  "article has no images":'Bài viết không có hình ảnh',
  "this channel belongs to":'Kênh này thuộc về',
  "responded":'Đã phản hồi',
  "unit price":'Đơn giá',
  "posted at":'Gửi vào lúc',
  "respond at":'Phản hồi lúc',
  "view website":'Xem trang chính',
  "send message":'Gửi tin nhắn',
  "reject":'Đã đóng',
  "resend request":'Gửi lại yêu cầu',
  "close request":'Đóng yêu cầu',
  "view detail":'Xem chi tiết',
  "hide detail":'Ẩn chi tiết',
  "quantity" : "Số lượng",
  "not sure":'Không chắc chắn',
  "sure":'Chắc chắn',
  "you have found a supplier for this product and you want to close this request":'Bạn đã tìm được nhà cung cấp cho sản phẩm này và bạn muốn đóng yêu cầu này',
  "are you sure":'Bạn có chắc chắn',
  "company name (if you buy for the company)":'Tên công ty: (nếu có, nếu bạn mua hàng cho công ty)',
  "Request a quote / Order":'Yêu cầu báo giá / Đặt hàng',
  "avatar is selected from the post, go back and add an image to make the post more lively and attractive":'Ảnh đại diện được chọn từ bài viết, hãy quay lại và thêm hình ảnh để bài viết sinh động và hấp dẫn hơn',
  "get the latest and most accurate quotes from suppliers for the product you are interested in":'Nhận báo giá mới và chính xác nhất từ nhà cung cấp cho sản phẩm bạn đang quan tâm',
  "filtered by":'Lọc kết quả bởi',
  "closed":'Đã đóng',
  "my order request":'Yêu cầu đặt hàng',
  "keyword: request id, organization id (name)":'Từ khóa: ID yêu cầu, ID tổ chức (tên)',
  "order requests management":'Quản lý yêu cầu đặt hàng',
  "press enter to create new page":'Nhấn enter để tạo trang mới',
  "add new page":'Thêm trang mới',
  "product info":'Thông tin sản phẩm',
  "order request type":'Loại yêu cầu',
  "request type":'Yêu cầu về',
  "received at":'Nhận lúc',
  "product price":'Giá sản phẩm',
  "min quantity":'Số lượng thấp nhất',
  "feedback":'Phản hồi',
  "productinfo":'Thông tin sản phẩm',
  "deleted":'Đã xoá',
  "alert drafting article description":'Bài viết này đang có thay đổi và chưa được xuất bản. Bạn có thể nhấp vào đây để tiếp tục soạn thảo và xuất bản nội dụng.',
  "delete article":'Xoá bài viết',
  "language linking":'Liên kết ngôn ngữ',
  "articles in other languages":'Bài viết ngôn ngữ khác',
  "Are you sure remove this related article?":'Bạn có chắc chắn xóa bài viết liên quan này không?',
  "Type the title of the article in other languages to search and add the article you want to link to.": 'Gõ tiêu đề của bài viết bằng các ngôn ngữ khác để tìm kiếm và thêm bài viết mà bạn muốn liên kết đến.',
  "linked articles": 'Bài viết được liên kết',
  "edit article setting": 'Chỉnh sửa thông tin bài viết',
  "features group":'Nhóm tính năng',
  "lasted articles":'Bài viết mới nhất',
  "learn more":'Tìm hiểu thêm',
  "Information channel is a function to support information sharing and disclosure of organizations and groups":'Kênh thông tin là chức năng hỗ trợ chia sẻ và công bố thông tin của các tổ chức, nhóm',
  "Information channels are maintained throughout":'Các kênh thông tin được duy trì xuyên suốt',
  "Groups, Organizations... can set up 1 or more channels":'Các Nhóm, Tổ chức ... có thể thiết lập 1 hoặc nhiều kênh',
  "Event is an activity for the purpose of sharing information, recording progress for participants and interested communities":'Sự kiện là hoạt động nhằm mục đích chia sẻ thông tin, ghi lại tiến độ cho người tham gia và cộng đồng quan tâm',
  "The event took place in a finite time":'Sự kiện diễn ra trong thời gian hữu hạn',
  "Events are created from Groups, Organizations, ...":'Sự kiện được tạo từ các Nhóm, Tổ chức, ...',
  "Organization is a Group, Association, Community..., established to share commodity price information to farmers":'Tổ chức là một Nhóm, Hiệp hội, Cộng đồng ..., được thành lập để chia sẻ thông tin giá cả hàng hoá cho những người nông dân',
  "Organize activities throughout, be recognized for the group activities":'Tổ chức hoạt động xuyên suốt, được ghi nhận quá trình hoạt động của nhóm',
  "Organizations are created by individuals, and can be made up of 1 to many individuals or organizations, can be used as a branch of another organization":'Các tổ chức được tạo ra bởi các cá nhân, và có thể được tạo thành từ 1 đến nhiều cá nhân hoặc tổ chức, có thể được sử dụng như một chi nhánh của một tổ chức khác',
  "Organizations use tools: Events, Information Channels, Articles to operate and share information with others":'Tổ chức sử dụng các công cụ: Sự kiện, Kênh thông tin, Bài báo để vận hành và chia sẻ thông tin với những người khác',
  "organized by":'tổ chức bởi',
  "suggested dataset":'Dữ liệu được đề xuất',
  "by":'bởi',
  "insights":'Thống kê',
  "reposts":'Bài chia sẻ',
  "q-and-a":'Hỏi đáp',
  "poll list":'Bình chọn',
  "followed":'đã theo dõi',
  "reposts by":'chia sẻ bởi',
  "total views":'Tổng lượt xem',
  "total votes":'Tổng lượt thích',
  "total comments":'Tổng lượt bình luận',
  "total follows":'Tổng lượt theo dõi',
  "commented":'đã bình luận',
  "in post":'trong bài',
  "yesterday":'Hôm qua',
  "today":'Hôm nay',
  "last 7 days":'7 ngày qua',
  "this month":'Tháng này',
  "last month":'Tháng rồi',
  "new articles":'Bài viết mới',
  "interactive":'Tương tác',
  "recent products":'Sản phẩm gần đây',
  "messaged":'đã nhắn tin',
  "recent channels":'Kênh gần đây',
  "keyword: name, tax number, phone number, email":'Từ khoá: Tên, Mã số thuế, Số điện thoại, Thư điện tử,...',
  "exit conversation":'Thoát trò chuyện',
  "are you sure delete this message":'Bạn chắc chắn xoá tin nhắn này ?',
  "are you sure delete this":'Bạn có chắc chắn xóa cái này không ?',
  "alternative email":'Thư điện tử (phụ)',
  "recent dataset":'Dữ liệu gần đây',
  "short name":'Tên ngắn',
  "first name":'Họ',
  "last name":'Tên',
  "---Choose One---":'---Chọn một---',
  "not update":'Chưa cập nhật',
  "create account" : "Tạo tài khoản",
  "explore the community directory" : "Khám phá thông tin cộng đồng",
  "by using Feedin, you agree to our":"Để sử dụng Feedin, bạn đồng ý với",
  "do you want to register as" : "Bạn muốn đăng ký là",
  "register as a member of {app_name}" : "Đăng ký làm thành viên của {app_name}",
  "or register to create an organization/group/association" : "Hoặc đăng ký thành lập tổ chức/nhóm/hiệp hội",
  "register note description" : "Bạn đang tạo một tài khoản dưới dạng tổ chức, điều này có nghĩa là bạn sẽ tạo ra một tổ chức mới và tài khoản này sẽ là chủ sở hữu của tổ chức đó. Nếu bạn chỉ muốn tạo một tài khoản cá nhân, vui lòng quay lại và chọn tùy chọn \"Cá nhân\".",
  "type to search" : "Gõ để tìm kiếm...",
  "notifications" : "Thông báo",
  "mine" : "Của tôi",
  "recently watched" : "Đã xem gần đây",
  "suggested channels" : "Gợi ý kênh tin",
  "lasted-articles" : "Bài viết mới nhất",
  "create articles" : "Tạo bài viết",
  "opening" : "Đang diễn ra",
  "my following events" : "Sự kiện theo dõi",
  "participants" : "Đăng ký tham gia",
  "you have registered to attend the event" : "Bạn đăng ký tham gia sự kiện",
  "no data available to display" : "Chưa có dữ liệu để hiển thị",
  "list of categories" : "Danh sách chuyên mục",
  "choose categories description" : "FeedIn đã nhóm thông tin theo danh mục hàng hóa để giúp bạn truy cập thông tin tốt hơn và tránh bỏ sót thông tin. Vui lòng chọn các danh mục bạn thích dưới đây.",
  "unfollow all categories" : "Bỏ theo dõi tất cả chuyên mục",
  "understood" : "Đã hiểu",
  "create new folder" : "Tạo thư mục mới",
  "order information" : "Thông tin đơn hàng",
  "list of channel" : "Danh sách kênh tin",
  "change folder name" : "Đổi tên thư mục",
  "do you want delete this folder" : "Bạn muốn xóa thư mục này ?",
  "this will permanently delete it but its items will still be in your saved list" : "Hành động này sẽ xóa vĩnh viễn mục này, nhưng các mục của nó sẽ vẫn nằm trong danh sách đã lưu của bạn.",
  "admin of" : "QTV của",
  "poll_question" : "Thăm dò ý kiến",
  "choose organization" : "Chọn tổ chức",
  "activities" : "Hoạt động",
  "organizational structure" : "Cơ cấu tổ chức",
  "operating" : "Vận hành",
  "latest followed user" : "Người theo dõi mới nhất",
  "showing" : "Hiển thị",
  "of" : "của",
  "record(s)" : "dòng",
  "list of event" : "Danh sách sự kiện",
  "list of dataset" : "Danh sách dữ liệu",
  "add new" : "Thêm mới",
  "list of co-hosting":"Danh sách chủ quản",
  "list of co-owners":"Danh sách thành viên",
  "activities of member":"Hoạt động của thành viên",
  "related events" : "Sự kiện gần đây",
  "peer organization":"Đơn vị ngang hàng",
  "add host" : "Thêm chủ quản",
  "type account" : "Loại tài khoản",
  "date desc" : "Ngày giảm dần",
  "date asc" : "Ngày tăng dần",
  "co-owners" : "Thành viên",
  "user of feedin" : "Tài khoản FeedIn",
  "ex: email":"VD: example_email@gmail.com",
  "this email address is associated with the account" : "Địa chỉ email này được liên kết với tài khoản",
  "enter email address" : "Nhập địa chỉ mail",
  "not found email description" : "Địa chỉ mail này không được tìm thấy trên hệ thống, bạn hãy kiểm tra đã nhập địa chỉ mail chính xác chưa.",
  "full name" : "Tên đầy đủ",
  "date of birth" : "Ngày sinh",
  "member type" : "Loại thành viên",
  "ex: phone":"VD: 0987654321",
  "type name and enter" : "Gõ tên và nhấn Enter",
  "update information" : "Cập nhật thông tin",
  "no limit" : "Không giới hạn",
  "click to expanded" : "Nhấp để mở rộng",
  "click to edit" : "Nhấp để chỉnh sửa",
  "products catalog" : "Danh mục sản phẩm",
  "edit products catalog title" : "Chỉnh sửa tên danh mục sản phẩm",
  "product catalog title" : "Tên danh mục sản phẩm",
  "order request management" : "Quản lý yêu cầu đặt hàng",
  "outgoing quotes management" : "Quản lý báo giá",
  "list of reports" : "Danh sách bảng báo giá",
  "report at" : "Ngày gửi",
  "view report" : "Xem báo giá",
  "delete report" : "Xóa báo giá",
  "edit report" : "Chỉnh sửa báo giá",
  "are you sure delete report this" : "Bạn chắc chắn muốn xóa báo giá này ?",
  "yes" : "Có",
  "no" : "Không",
  "are you sure delete partner this" : "Bạn chắc chắn muốn xóa đối tác này ?",
  "click to select" : "Nhấp để chọn",
  "partner can view" : "Đối tác có thể xem",
  "products can view" : "Sản phẩm có thể xem",
  "view chart" : "Xem biểu đồ",
  "are you sure to remove the expired" : "Bạn chắc chắn muốn xóa thời hạn này ?",
  "confirm to expire at" : "Xác nhận dừng hiệu lực ngày : ",
  "copy prices" : "Sao chép giá",
  "add new unit" : "Thêm đơn vị mới",
  "type content" : "Gõ nội dung ...",
  "input search loading default" : "Nhập tìm kiếm mặc định",
  "do you want delete this product" : "Bạn muốn xóa sản phẩm này ?",
  "create option" : "Tạo mới lựa chọn : ",
  "price supplying partners" : "Đối tác cung cấp giá",
  "add new partner description" : "Đối tác là những tổ chức, công ty, cá nhân,... có liên quan, hợp tác đến công việc của tổ chức, công ty của bạn.",
  "update products catalog" : "Cập nhật danh mục sản phẩm",
  "view chart of catalog" : "Xem biểu đồ của danh mục",
  "Tap here to write content. \nType @ to mention" : "Nhấp tại đây để viết nội dung. \nGõ @ để nhắc đến thông tin khác ...",
  "organization type" : "Loại tổ chức",
  "linked pages" : "Liên kết trang",
  "location list" : "Danh sách vị trí",
  "contacts list" : "Danh sách liên hệ",
  "are you sure delete this location" : "Bạn chắc chắn muốn xóa vị trí này ?",
  "are you sure delete this contact" : "Bạn chắc chắn muốn xóa liên hệ này ?",
  "hosts" : "Chủ quản",
  "launch the product to display on the store" : "Khởi chạy để trưng bày sản phẩm trên cửa hàng",
  "missing" : "Thiếu",
  "link account" : "Liên kết tài khoản",
  "are you sure delete this contact info" : "Bạn chắc chắn muốn xóa thông tin liên hệ này ?",
  "are you sure delete this partners" : "Bạn chắc chắn muốn xóa đối tác này ?",
  "invite information" : "Thông tin mời",
  "confirmation" : "Xác nhận",
  "{type} will delete will be gone forever. are you sure" : "{type} sẽ bị xóa vĩnh viễn. Bạn có chắc chắn ?",
  "are you sure you want to delete it permanently" : "Bạn có chắc chắn muốn xóa nó vĩnh viễn không ?",
  "what your channel name" : "Tên kênh tin của bạn ?",
  "create new post" : "Tạo bài viết",
  "administration panel" : "Thanh quản trị dành cho QTV",
  "do you want to leave" : "Bạn muốn rời khỏi tổ chức ?",
  "you will no longer be a member of this community" : "Bạn sẽ không còn là thành viên của tổ chức này",
  "news update" : "Cập nhật tin tức",
  "You can click see more to see this full information" : "Bạn cần nhấn \"Xem thêm\" để xem đầy đủ thông tin",
  "related units" : "Đơn vị liên quan",
  "total org members":"Đơn vị thành viên",
  "total peer organization" : "Đơn vị ngang hàng",
  "peer organization list" : "Danh sách đơn vị ngang hàng",
  "create short news or share links from other sources" : "Tạo bản tin ngắn hoặc chia sẻ liên kết từ nguồn khác",
  "poll question" : "Thăm dò ý kiến",
  "publish a post" : "Đăng bài viết",
  "posted on the channel" : "đã đăng trên kênh",
  "create post setting" : "Cài đặt bài viết",
  "Only members can view data content, post articles or comments on this" : "Chỉ thành viên mới xem được nội dung, bài viết hoặc bình luận trên này",
  "note create poll description" : "Tạo bình chọn là đưa ra vấn để với nhiều giải pháp khác nhau và nhờ sự lựa chọn của số đông người khác để đưa ra đáp án cho vấn đề của bạn.",
  "questions/voting content" : "Câu hỏi / nội dung bình chọn",
  "questions/ content" : "Câu hỏi / nội dung",
  "yes/no questions description" : "CÓ : Đáp án có 2 lựa chọn : Đúng - Sai ; KHÔNG : Đáp án theo tùy chọn",
  "answers can be voted on" : "Các đáp án có thể bình chọn",
  "you" : "bạn",
  "is yes/no question" : "Loại câu hỏi Yes / No ?",
  "number of possible answers in a poll" : "Số đáp án có thể trong lần trả lời bình chọn",
  "input answer" : "Nhập câu trả lời",
  "one answer":"Một trả lời",
  "multiple answer":"Nhiều trả lời",
  "number of choices":"Cho phép chọn tối đa",
  "copy path":"Sao chép liên kết",
  "position" : "Vị trí",
  "Short description about your organization!":"Mô tả ngắn gọn về tổ chức của bạn!",
  "organization info" : "Thông tin tổ chức",
  "managed by" : "Quản lý bởi",
  "add new location description" : "Thêm vị trí văn phòng, cơ sở, chi nhánh,... giúp người dùng khác tiếp cận với tổ chức của bạn tốt hơn.",
  "add new contact description" : "Thêm thông tin liên hệ để người dùng khác có thể liên hệ đến các phòng ban, bộ phận,... cụ thể được tạo ra trong tổ chức của bạn.",
  "default select all" : "Mặc định là Chọn tất cả",
  "This product removed from your Catalog and won't be visible to buyers" : "Sản phẩm này đã bị xóa khỏi Danh mục sản phẩm và người mua sẽ không nhìn thấy sản phẩm này",
  "edit product" : "Chỉnh sửa sản phẩm",
  "quick view" : "Xem nhanh",
  "set as no primary" : "Dừng đặt làm chính",
  "set as primary" : "Cài đặt làm chính",
  "go down" : "Di chuyển xuống",
  "go up" : "Di chuyển lên",
  "invite your friends to this channel" : "Mời bạn tham gia kênh tin này",
  "waiting-members" : "DS đăng ký tham gia",
  "secure" : "Bảo mật",
  "permission" : "Phân quyền",
  "price (VND)" : "Giá (VND)",
  "change-password" : "Đổi mật khẩu",
  "configure-content-by-language" : "Cấu hình ngôn ngữ",
  "do you want to join this event" : "Bạn muốn tham gia sự kiện này ?",
  "webinar" : "Trực tuyến",
  "list of members" : "Danh sách thành viên",
  "about event" : "Giới thiệu sự kiện",
  "registration enabled" : "Cho phép đăng ký",
  "are you sure delete this speaker" : "Bạn chắc chắn muốn xóa diễn giả này ?",
  "list of register to participate" : "Danh sách đăng ký tham gia",
  "ex: address" : "VD: Số 01 đường 01, phường 01, quận 01,...",
  "create participant" : "Tạo mới đăng ký tham gia",
  "sign up for this event" : "đăng ký tham gia sự kiện này",
  "approve participant" : "Xác nhận đăng ký tham gia",
  "approve" : "Xác nhận",
  "reject registration" : "Từ chối đơn đăng ký",
  "are you sure you want to reject this registration" : "Bạn chắc chắc muốn từ chối đơn đăng ký này ?",
  "delete registration" : "Xóa đơn đăng ký",
  "are you sure you want to delete this registration" : "Bạn chắc chắn muốn xóa đơn đăng ký này ?",
  "Invite your friends to this event" : "Mời bạn tham gia sự kiện này",
  "skip" : "Bỏ qua",
  "Select date" : "Chọn ngày...",
  "edit owner" : "Chỉnh sửa chủ sở hữu",
  "remove this item timetable" : "Xóa điểm thời gian này ?",
  "pending list" : "Danh sách chờ",
  "invite link description event":"Gửi lời mời tham gia sự kiện đến bạn bè, bằng cách gửi sao chép liên kết trang bên dưới.",
  "direct link description event":"Sao chép, chia sẻ liên kết trang bên dưới đến bạn bè. Để mọi người bạn cần chia sẻ dễ dàng truy cập và theo dõi kênh này.",
  "sign up to participate" : "Đăng ký tham dự",
  "title article" : "Tiêu đề bài viết",
  "enter title" : "Nhập tiêu đề",
  "This article is currently a draft, and will be saved in {n1}. When you {n2}, the new content or changes become the official version.": "Bài viết này hiện chỉ là bản nháp, sẽ được lưu trong {n1}. Khi bạn {n2}, thì những nội dung hoặc những thay đổi mới trở thành bản chính thức.",
  "local saved" : "Bài viết của tôi / Bản nháp",
  "saved" : "Đã lưu",
  "typing" : "Đang gõ...",
  "information" : "Thông tin",
  "select preview photo description" : "Danh sách hình dưới đây được lấy từ nội dung bài viết, nếu muốn có hình hiển thị cho bài viết, vui lòng đưa vào nội dung bài viết trước.",
  "classifying content" : "Phân nhóm loại nội dung",
  "published status" : "Trạng thái xuất bản",
  "hidden": "Ẩn",
  "display": "Hiển thị",
  "choosing a category will help the articles stay on topic and more accessible to users":"Chọn chuyên mục sẽ giúp bài viết nằm đúng chủ đề của nó và dễ tiếp cận hơn với người dùng",
  "please add images to make the articles more vivid and realistic to attract more viewers":"Vui lòng thêm hình ảnh để bài viết trở nên sinh động và chân thực hơn để thu hút nhiều người xem hơn",
  "type cd":"Loại bài viết",
  "memo" : "Ghi nhớ",
  "form" : "Biểu mẫu",
  "research" : "Nghiên cứu",
  "edit your article" : "Chỉnh sửa bài viết của bạn",
  "unfinished draft":"Bản nháp chưa hoàn tất",
  "comments" : "Bình luận",
  "is_me" : "Tôi",
  "alert drafting articles description":"Bài viết này đang có thay đổi và chưa được xuất bản. Bạn có thể nhấp vào đây để tiếp tục soạn thảo và xuất bản nội dụng.",
  "do not repeat" : "Không lặp lại",
  "time takes place" : "Thời gian diễn ra",
  "end" : "Kết thúc",
  "Use Shift+Enter to break line" : "Ấn Shift+Enter để xuống dòng",
  "file" : "Tập tin",
  "attached image" : "Hình ảnh đính kèm",
  "attached file" : "Tập tin đính kèm",
  "responding" : "Đang trả lời",
  "are you sure delete this conversation" : "Bạn chắc chắn muốn xóa cuộc trò chuyện này ?",
  "group of members" : "Nhóm thành viên",
  "description roles" : "Tham gia với vị trí là thành viên hoặc quản trị viên.",
  "description timeout" : "Thời gian còn lại để bạn đăng ký tham gia.",
  "description limit" : "Nếu không muốn giới hạn số lượng thì nhập vào số 0.",
  "description group of members" : "Nhấp để chọn hoặc gõ loại mới và nhấp Enter để thêm mới.",
  "description public" : "Khi chọn công khai thì những thông tin sẽ được hiển thị lên trang chủ của tổ chức.",
  "description title" : "Tiêu đề để hiển thị lên trang chủ. VD: Mời bạn tham gia làm thành viên...",
  "send by" : "đăng bởi",
  "sponsors" : "Nhà tài trợ",
  "choose shortcut chart" : "Chọn lối tắt cho biểu đồ",
  "what your chart name" : "Tên biểu đồ của bản ?",
  "select the category you interested in and then click follow" : "Chọn chuyên mục mà bạn quan tâm và sau đó bấm 'Theo dõi'",
  "search as a channel" : "Tìm kiếm kênh tin",
  "search as a user" : "Tìm kiếm người dùng",
  "search as a organization" : "Tìm kiếm tổ chức",
  "type comment" : "Gõ bình luận",




}
