import { getTokenKey } from 'helpers/localStorage'
import _ from 'lodash'
import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
export const useRequiredLogin = callback => {
  const history = useHistory()
  const handler = useCallback(
    cb => {
      if (!getTokenKey()) {
        history.push({
          pathname: '/login',
          state: {
            isModal: true
          }
        })
      } else {
        _.isFunction(callback) &&
          callback()
        _.isFunction(cb) && cb()
      }
    },
    [callback, history]
  )
  return handler
}
