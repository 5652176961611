import _ from 'lodash'
import { organizationPermissionCodes } from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, { useCallback } from 'react'
import { BsUiChecksGrid } from 'react-icons/bs'
import { IoBarChartOutline, IoListOutline } from 'react-icons/io5'
import { PiCertificate, PiClockClockwise } from 'react-icons/pi'
import {
  createCheckPermissions,
  invalidPermissions,
} from 'views/AppLocals/factory/createCheckPermissions'
import { SettingsMenu } from 'views/AppLocals/factory/createSettingLayout'
import DefaultTemplate from 'views/AppLocals/Templates/DefaultTemplate'
import { renderFalse, renderOwnChild, Visible } from 'views/Shared'
import CustomSiderMenu from 'views/Shared/components/CustomSiderMenu'
import { Queries } from 'views/Shared/enums/Queries'
import { useMenuHandler } from 'views/Shared/handlers/useMenuHandler'
import WorkspaceOperatingStatistic from './WorkspaceOperatingStatistic'

const ItemKeys = Object.freeze({
  STATISTIC: 'statistic',
  CATEGORY_GROUP: 'category-group',
  MEDAL_LIST: 'medal-list',
  MEDAL_GROUP: 'medal-group',
  MEDAL_REWARDS_HISTORY: 'medal-rewards-history',
  ORGANIZATION_CATEGORIES: 'organization-categories',
})

const with_menu_items = ({ isDisabled = renderFalse }) =>
  [
    {
      key: ItemKeys.STATISTIC,
      label: 'insights',
      icon: <IoBarChartOutline size={16} />,
      disabled: isDisabled({
        required_permissions: [
          organizationPermissionCodes.organizationManageInfo,
        ],
      }),
    },
  ]
    .filter(Visible)
    .map((item) => _.omit(item, ['visible', 'invisible']))

const routeEntities = [
  {
    key: ItemKeys.STATISTIC,
    component: WorkspaceOperatingStatistic,
  },
]
  .filter(Visible)
  .map(({ key, invisible = false, ...rest }) => ({
    key,
    path: key,
    ...rest,
  }))

const WorkspaceOperatingContent = () => {
  const { activeKey, Component, onMenuSelect } = useMenuHandler({
    routes: routeEntities,
    actionName: Queries.REL,
  })

  const renderMenu = useCallback(
    () => (
      <CustomSiderMenu
        deps={[activeKey]}
        Wrapper={renderOwnChild}>
        <PermissionContext.Consumer>
          {(permissions) => (
            <SettingsMenu
              items={with_menu_items({
                isDisabled: invalidPermissions(permissions),
              })}
              onSelect={onMenuSelect}
              defaultSelectedKeys={[activeKey]}
              defaultOpenKeys={[ItemKeys.MEDAL_GROUP, ItemKeys.CATEGORY_GROUP]}
            />
          )}
        </PermissionContext.Consumer>
      </CustomSiderMenu>
    ),
    [with_menu_items, activeKey]
  )

  return (
    <DefaultTemplate renderMenu={renderMenu}>
      <div className="px-3 lg:pr-3">
        <Component />
      </div>
    </DefaultTemplate>
  )
}

export default WorkspaceOperatingContent
