import React from "react";

function EmptyInboxStateSvg({...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1rem"
      height="1rem"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="#9e9797"
        d="M499.923 374.628v60.029c0 7.135-5.775 12.909-12.909 12.909H24.986c-7.134 0-12.909-5.775-12.909-12.909v-60.029c0-1.37.213-2.73.65-4.029L78.094 172.04a12.885 12.885 0 0112.26-8.87h331.293a12.886 12.886 0 0112.26 8.87l65.367 198.558c.436 1.3.649 2.66.649 4.03z"
      ></path>
      <path
        fill="#b9acac"
        d="M499.923 374.628v60.029c0 7.135-5.775 12.909-12.909 12.909h-77.617c-.264-91.51 1.147-242.543 1.563-284.396h10.687a12.886 12.886 0 0112.26 8.87l65.367 198.558c.436 1.3.649 2.66.649 4.03zM12.074 374.628v60.029c0 7.135 5.775 12.909 12.909 12.909H102.6c.264-91.51-1.147-242.543-1.563-284.396H90.351a12.886 12.886 0 00-12.26 8.87L12.724 370.599a12.65 12.65 0 00-.65 4.029z"
      ></path>
      <path
        fill="#756f6f"
        d="M450.878 370.594l-39.805-120.907a12.907 12.907 0 00-12.26-8.871H113.186a12.907 12.907 0 00-12.26 8.871L61.122 370.594a12.903 12.903 0 00-.647 4.036v60.03c0 7.128 5.779 12.907 12.907 12.907h365.237c7.128 0 12.907-5.779 12.907-12.907v-60.03a12.929 12.929 0 00-.648-4.036z"
      ></path>
      <ellipse
        cx="256"
        cy="321.285"
        fill="#5b5555"
        rx="94.356"
        ry="26.528"
      ></ellipse>
      <path
        fill="#5b5555"
        d="M451.525 374.633v60.03c0 7.125-5.782 12.907-12.907 12.907h-44.903c7.125 0 12.907-5.782 12.907-12.907v-60.03c0-1.368-.219-2.736-.645-4.04l-39.805-120.911a12.905 12.905 0 00-12.261-8.867h44.903a12.904 12.904 0 0112.261 8.867l39.805 120.911c.426 1.304.645 2.672.645 4.04z"
      ></path>
      <path
        fill="#31a7fb"
        d="M499.926 387.088v112.005c0 7.128-5.779 12.907-12.907 12.907H24.981c-7.128 0-12.907-5.779-12.907-12.907V387.088c0-7.128 5.779-12.907 12.907-12.907h95.297c4.932 0 9.433 2.811 11.598 7.243l18.726 38.342a12.906 12.906 0 0011.598 7.243h187.6c4.932 0 9.433-2.811 11.598-7.243l18.726-38.342a12.906 12.906 0 0111.598-7.243h95.297c7.128 0 12.907 5.779 12.907 12.907z"
      ></path>
      <path
        fill="#1c96f9"
        d="M499.926 387.088v112.005c0 7.125-5.782 12.907-12.907 12.907h-49.147c7.125 0 12.907-5.782 12.907-12.907V387.088c0-7.124-5.782-12.907-12.907-12.907h49.147c7.124 0 12.907 5.783 12.907 12.907z"
      ></path>
      <g fill="#f8f3f1">
        <path
          d="M78.761 484.49H50.907a7.61 7.61 0 110-15.222h27.854a7.61 7.61 0 110 15.222zM78.761 460.713H50.907c-4.204 0-7.611-3.408-7.611-7.612s3.408-7.611 7.611-7.611h27.854a7.61 7.61 0 017.611 7.611 7.61 7.61 0 01-7.611 7.612z"></path>
      </g>
      <g>
        <circle cx="256" cy="236.198" r="88.317" fill="#a3e440"></circle>
        <path
          fill="#99d53b"
          d="M344.316 236.196c0 48.779-39.543 88.322-88.311 88.322a88.278 88.278 0 01-17.328-1.704c40.474-8.059 70.972-43.78 70.972-86.618s-30.498-78.548-70.972-86.607a88.278 88.278 0 0117.328-1.704c48.768 0 88.311 39.532 88.311 88.311z"
        ></path>
        <path
          fill="#82b732"
          d="M273.552 270.1a7.585 7.585 0 01-4.938-1.822c-3.484-2.974-8.452-4.688-13.632-4.704h-.071c-5.153 0-10.101 1.683-13.587 4.622a7.61 7.61 0 01-10.725-.912 7.61 7.61 0 01.912-10.725c6.307-5.32 14.674-8.227 23.518-8.208 8.859.027 17.193 2.992 23.469 8.348a7.612 7.612 0 01-4.946 13.401zM214.034 235.006h-.043c-4.204-.023-7.638-3.449-7.615-7.653s3.403-7.544 7.608-7.57h.091a7.611 7.611 0 01-.041 15.223z"
        ></path>
      </g>
      <g>
        <path
          fill="#bcea73"
          d="M105.915 80.697c0-14.991 12.152-27.143 27.143-27.143S160.2 65.706 160.2 80.697s-12.152 27.143-27.143 27.143-27.142-12.152-27.142-27.143"
        ></path>
        <path
          fill="#99d53b"
          d="M119.291 104.087c10.655-2.456 18.593-11.994 18.593-23.393 0-11.387-7.926-20.938-18.581-23.394a26.946 26.946 0 0113.756-3.746c14.984 0 27.139 12.156 27.139 27.14 0 14.996-12.156 27.152-27.139 27.152a26.883 26.883 0 01-13.768-3.759"
        ></path>
      </g>
      <g fill="#64b9fc">
        <path
          d="M221.122 2.23a7.61 7.61 0 00-10.764 0l-6.153 6.153-6.153-6.153a7.612 7.612 0 00-10.764 10.764l6.153 6.153-6.153 6.153a7.61 7.61 0 000 10.764 7.583 7.583 0 005.382 2.229 7.59 7.59 0 005.382-2.229l6.153-6.153 6.153 6.153c1.486 1.486 3.434 2.229 5.382 2.229s3.896-.743 5.382-2.229a7.61 7.61 0 000-10.764l-6.153-6.153 6.153-6.153a7.61 7.61 0 000-10.764zM387.121 93.838l6.153-6.153a7.612 7.612 0 00-10.764-10.764l-6.153 6.153-6.153-6.153a7.612 7.612 0 00-10.764 10.764l6.153 6.153-6.153 6.153a7.61 7.61 0 005.382 12.993 7.587 7.587 0 005.382-2.229l6.153-6.153 6.153 6.153c1.486 1.486 3.434 2.229 5.382 2.229s3.896-.743 5.382-2.229a7.61 7.61 0 000-10.764z"></path>
      </g>
      <g fill="#bcea73">
        <path
          d="M255.605 109.604h-.023a7.612 7.612 0 01-7.589-7.634l.067-22.666a7.611 7.611 0 017.611-7.589h.023a7.612 7.612 0 017.589 7.634l-.067 22.666a7.611 7.611 0 01-7.611 7.589zM221.81 119.265a7.607 7.607 0 01-6.537-3.701l-11.777-19.662a7.612 7.612 0 0113.059-7.823l11.777 19.662a7.612 7.612 0 01-6.522 11.524zM289.34 119.575a7.612 7.612 0 01-6.499-11.562l11.894-19.597a7.612 7.612 0 0113.013 7.899l-11.894 19.597a7.608 7.608 0 01-6.514 3.663z"></path>
      </g>
      <g>
        <circle cx="325.478" cy="38.815" r="21.56" fill="#a8e7ef"></circle>
        <path
          fill="#70d9e6"
          d="M347.036 38.813c0 11.912-9.655 21.567-21.557 21.567a21.36 21.36 0 01-10.936-2.985c8.463-1.951 14.769-9.527 14.769-18.582 0-9.045-6.296-16.631-14.759-18.582a21.404 21.404 0 0110.926-2.975c11.902-.001 21.557 9.655 21.557 21.557z"
        ></path>
      </g>
      <path
        fill="#82b732"
        d="M298.048 235.006h-.043c-4.204-.023-7.638-3.45-7.615-7.654.023-4.189 3.382-7.57 7.565-7.57h.133a7.613 7.613 0 01-.04 15.224z"
      ></path>
    </svg>
  );
}

export default EmptyInboxStateSvg;
