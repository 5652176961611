import { API_ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import { createAsyncAction } from 'modules/asyncCache'
import { normalize, schema } from 'normalizr'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

export const notificationSchema = new schema.Entity(
  'notifications',
  {},
  {
    idAttribute: 'id'
  }
)
// Route::get('notifications/gets', 'NotificationController@index');
export const notification_get_Api = {
  backend: `// Route::get('notifications/gets', 'NotificationController@index');`,
  root: API_ROOT_URL,
  path: '/notifications/gets',
  method: 'GET',
  schema: notificationSchema,
  transform: createListTransform(
    notificationSchema
  )
}
export const create_notification_get_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@notification_get_Api',
    query,
    values,
    apiInfo: notification_get_Api,
    maxAge
  })
// Route::get('notifications/system/gets', 'NotificationController@getSpecNotifications');
// Route::get('notifications/notification/{no_id}/edit', 'NotificationController@editNotify');
// Route::get('notifications/notification/{no_id}/read', 'NotificationController@markAsRead');
export const notification_markAsRead_Api = {
  backend: `// Route::get('notifications/notification/{no_id}/read', 'NotificationController@markAsRead');`,
  root: API_ROOT_URL,
  path:
    '/notifications/notification/:no_id/read',
  method: 'POST',
  transform: createTransform(
    notificationSchema,
    'data'
  )
}
export const create_notification_markAsRead_Api_action = () => ({
  prefixStr = '',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@notification_markAsRead_Api',
    query,
    values,
    apiInfo: notification_markAsRead_Api,
    maxAge
  })
// Route::get('notifications/mark-as-read', 'NotificationController@markAllAsRead');
export const notification_markAllAsRead_Api = {
  backend: `Route::get('notifications/mark-all-as-read', 'NotificationController@markAllAsRead');`,
  root: API_ROOT_URL,
  path:
    '/notifications/mark-all-as-read',
  method: 'POST',
  transform: response => {
    const list = _.get(
      response,
      'data.data',
      []
    )
    const {
      result,
      entities
    } = normalize(list, [
      notificationSchema
    ])
    const count_notifications = _.get(
      response,
      'data.owner.count_notifications',
      0
    )
    const username = _.get(
      response,
      'data.owner.username'
    )
    return {
      response,
      result,
      entities: {
        ...entities,
        users: {
          [username]: {
            count_notifications: count_notifications
          }
        }
      }
    }
  }
}

// Route::get('notifications/notification/{no_id}/unfollow', 'NotificationController@unFollow');
