import classnames from 'classnames'
import React from 'react'
import Banner from './Banner'
import { footer } from './footer'
export const MarketLayout = ({
  className,
  children
}) => (
  <div className="flex flex-col w-full">
    <div
      className={classnames(
        'w-full min-h-screen flex flex-col',
        className
      )}>
      <Banner />
      {children}
      <div className="flex-1" />
    </div>
    {footer}
  </div>
)

