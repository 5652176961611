import {Button, DatePicker, Form, Input, InputNumber, Modal, Select} from 'antd'
import moment from 'moment'
import React from 'react'
import useTranslate from "../../../../../../modules/local/useTranslate";
import _ from "lodash";
import {ProductPriceSetInfo} from "../ProductPriceSetInfo";
import {formatterNumber} from "../../../../../../helpers/formatterNumber";
import {useAppConfig} from "../../../../../../modules/local";
import {useSelector} from "react-redux";
import {getAsynCacheSelector} from "../../../../../../modules/asyncCache/selectors";
import {parseNumber} from "../../../../../../helpers/parseNumber";
import {emptyArray} from "../../../../../../helpers/emptyObjects";
import {BsCalendar2Week} from "react-icons/bs";

export const PriceDetailNewPriceForLocationForm = ({
    effectMomentDate,
    productPriceSet,
    toggle,
    isToggle,
    add,
    handleOnNewSubmitLocation
}) => {

  const t = useTranslate();

  const {
    product_units = [],
    product_price_units = [],
    contract_types = [],
    delivery_types = [],
    incoterm_types = [],
    transport_types = [],
    countries = [],
    provinces = [],
  } = useAppConfig();
  const [addForm] = Form.useForm();

  const {
    result: price_periods = []
  } = useSelector(
    state => {
      return getAsynCacheSelector(
        state,
        "/products/get-price-periods?day=1"
      )
    }
  );

  return (
    <div className="w-full space-y-6 p-3">
      <ProductPriceSetInfo productPriceSet={productPriceSet}/>
      <Form className="space-y-3"

          form={addForm}
          initialValues={{
              country_id: 237,
              province_id: null,
              price: 0,
              eff_at: effectMomentDate
          }}
          onFinish={(values) => {

              let location_type = 'country';
              let location_id = 237;
              let location = _.head(countries.filter(e => e.id === location_id));
              if (!_.isEmpty(values.province_id)) {
                  location_type = 'province';
                  location_id = values.province_id;
                  location = _.head(provinces.filter(e => e.id === location_id));
              } else {
                  location_type = 'country';
                  location_id = values.country_id;
                  location = _.head(countries.filter(e => e.id === location_id));
              }

              const locationFilter = _.filter(productPriceSet.data || emptyArray, (e => {
                  if (!!values.province_id) {
                      if (e.location._type === 'province' && e.location.id === values.province_id) {
                          return true;
                      }
                  } else {
                      if (e.location._type === 'country' && e.location.id === values.country_id) {
                          return true;
                      }
                  }

                  return false;
              }));

              if (locationFilter.length > 0) {
                  Modal.warning({
                      title: t('choose another location'),
                      content: t('This location has been declared, cannot be added'),
                  });
              } else {
                  const period_code = (productPriceSet.contract_type_cd === 'spot') ? "T0" : price_periods[0].period_code;
                  const from_date = (productPriceSet.contract_type_cd === 'spot') ? moment() : moment(price_periods[0].from_date);
                  const to_date = (productPriceSet.contract_type_cd === 'spot') ? moment() : moment(price_periods[0].to_date);
                  const month = (productPriceSet.contract_type_cd === 'spot') ? moment().month() : price_periods[0].month_nbr;
                  const year = (productPriceSet.contract_type_cd === 'spot') ? moment().year() : price_periods[0].year;

                  handleOnNewSubmitLocation(
                      {
                          eff_at: moment().format("YYYY-MM-DD"),
                          end_at: null,
                          rangePicker: [from_date, to_date],
                          period_code: period_code,
                          price: 0,
                          price2: 0,
                          from_date: from_date,
                          to_date: to_date,
                          year: year,
                          month: month,
                          ...values, location,
                          location_type, location_id,
                      }
                  );
                  add({
                      eff_at: moment().format("YYYY-MM-DD"),
                      end_at: null,
                      rangePicker: [from_date, to_date],
                      period_code: period_code,
                      price: 0,
                      price2: 0,
                      from_date: from_date,
                      to_date: to_date,
                      year: year,
                      month: month,
                      ...values, location,
                      location_type, location_id,
                  });
                  toggle();
              }

          }}
          {...{
              labelCol: {span: 6},
              wrapperCol: {span: 18},
          }}>
        {(values) => (
            <div className="space-y-3">
              <Form.Item
                label={
                    <span className="text-sm text-color-400 italic">
                        {t('date')}
                    </span>
                }
                className="flex-1"
                name="eff_at">
                <DatePicker
                    className="rounded-md"
                    format={['DD/MM/YYYY']}
                    suffixIcon={<BsCalendar2Week className="text-color-400"/>}
                />
              </Form.Item>

              <Form.Item
                label={
                    <span className="text-sm text-color-400 italic">
                        {t('country')}
                    </span>
                }
                className="flex-1"
                name="country_id">
                <Select
                  showSearch
                  allowClear={true}
                  virtual={false}
                  optionFilterProp="label"
                  placeholder={t(
                    'please select'
                  )}
                  style={{
                    width: '100%'
                  }}>
                  {!_.isEmpty(countries) && countries.map(
                    item => {
                      const {
                        id,
                        location_name: name
                      } = item
                      return (
                        <Select.Option
                          key={id}
                          value={id}
                          label={name}>
                          {name}
                        </Select.Option>
                      )
                    }
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                key={"province_id_by_" + values.country_id}
                id={"province_id_by_" + values.country_id}
                label={
                    <span className="text-sm text-color-400 italic">
                        {t('province')}
                    </span>
                }
                className="flex-1"
                name="province_id">
                <Select
                  showSearch
                  allowClear={true}
                  virtual={false}
                  optionFilterProp="label"
                  placeholder={t(
                    'please select'
                  )}
                  style={{
                    width: '100%'
                  }}>
                  {!_.isEmpty(provinces) &&
                  provinces.filter(e => e.country_id === values.country_id).map(
                    item => {
                      const {
                        id,
                        location_name: name
                      } = item
                      return (
                        <Select.Option
                          key={id}
                          value={id}
                          label={name}>
                          {name}
                        </Select.Option>
                      )
                    }
                  )}
                </Select>
              </Form.Item>

              <Form.Item
                label={
                  <div className="flex flex-col italic">
                      <span className="font-bold text-color-100 leading-tight">{t('price')}</span>
                      <span className="font-normal text-2xs text-color-400 leading-tight">
                          ({_.get(productPriceSet, 'price_unit.name')}/{_.get(productPriceSet, 'unit.name')})
                      </span>
                  </div>
                }
                className="flex-1"
                name={"price"}>
                <InputNumber
                  name={"price"}
                  style={{width: '100%'}}
                  formatter={formatterNumber}
                  parser={parseNumber}
                />
              </Form.Item>

              {
                (!_.isEmpty(_.get(productPriceSet, 'unit2')) && !_.isEmpty(_.get(productPriceSet, 'price_unit2'))) && (
                  <React.Fragment>
                    <Form.Item
                      label={
                          <div className="flex flex-col italic">
                              <span className="font-bold text-color-100 leading-tight">{t('basis price')}</span>
                              <span className="font-normal text-2xs text-color-400 leading-tight">
                                  ({_.get(productPriceSet, 'price_unit2.name')}/{_.get(productPriceSet, 'unit2.name')})
                              </span>
                          </div>
                      }
                      className="flex-1"
                      name={"price2"}>
                      <Input.Group style={{display: 'flex'}} compact>
                        <InputNumber
                          name={"price2"}
                          style={{width: '100%'}}
                          formatter={formatterNumber}
                          parser={parseNumber}
                          onChange={(value) => {
                            const _fields = addForm.getFieldsValue()
                            addForm.setFieldsValue({..._fields, price2: value})
                          }}
                        />
                        <Select className="flex-1"
                                showSearch
                                style={{width: 'auto'}}
                                placeholder={t(
                                  'period'
                                )}
                                options={price_periods.map(e => {
                                  return {
                                    value: e.period_code,
                                    label: e.period_code
                                  }
                                })}
                                onChange={(value) => {
                                  const _fields = addForm.getFieldsValue()
                                  addForm.setFieldsValue({
                                    ..._fields,
                                    period_code: value
                                  })
                                }}
                        />
                      </Input.Group>
                    </Form.Item>
                    <Form.Item
                      hidden={true}
                      className="flex-1"
                      name={"period_code"}>
                      <Select
                          style={{width: 100}}
                          className="flex-1"
                          placeholder={t('period code')}
                          options={price_periods.map(e => {
                              return {
                                  value: e.period_code,
                                  label: e.period_code
                              }
                          })}
                      />
                    </Form.Item>
                  </React.Fragment>
                )
              }

              <div className="text-center mt-4">
                  <Button className="button-rounded-md no-border" type="primary" htmlType="submit">
                      {t('confirm')}
                  </Button>
              </div>
            </div>
        )}
      </Form>
    </div>
  )
}
