import { useContext } from 'react'
import { gPaths } from 'views/AppLocals/configs'
import createSettingContent from 'views/AppLocals/factory/createSettingContent'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import OrganizationContainerContext from '../OrganizationContainerContext'

const OrganizationSettingsContent = ({ Wrapper, routes }) => {
  const { isSubDomain } = useContext(SubDomainContext)

  const rootPath = isSubDomain
    ? gPaths.organizationSettings_domain
    : gPaths.organizationSettings
  return createSettingContent(OrganizationContainerContext, {
    routes,
    Wrapper,
    rootPath,
  })
}

export default OrganizationSettingsContent
