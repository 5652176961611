import {SelectEntityItem} from 'components/SelectEntityItem'
import _ from 'lodash'
import React from 'react'
import {notEmpty} from 'views/Shared'
import {datasetSchema} from "../../../apis";

const DatasetEntity = ({item, children, defaultItem}) => (
  <SelectEntityItem
    item={item}
    defaultItem={defaultItem}
    schema={datasetSchema}>
    {(data) => children(data)}
  </SelectEntityItem>
)

export const DatasetEntities = ({items, children, defaultItem}) => {
  return (
    <SelectEntityItem
      item={items}
      defaultItem={defaultItem}
      schema={[datasetSchema]}>
      {(data = []) => children(_.filter(data, notEmpty))}
    </SelectEntityItem>
  )
}

export default DatasetEntity
