import classNames from 'classnames'
import AppIcon from 'components/AppIcon'
import {CategoriesXS} from 'components/Categories/CategoriesXS'
import Description, {mapKeywords} from 'components/Feed/Description'
import Timestamp from 'components/Feed/Timestamp'
import {GoogleAdsInFeedUnit} from 'components/GoogleAds/GoogleAds'
import ImageLoader from 'components/ImageLoader'
import LazyComponent from 'components/LazyComponent'
import Pure from 'components/Pure'
import {APP_NAME} from 'envs/_current/config'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {useHistory} from 'react-router'
import {Link} from 'react-router-dom'
import {renderIf} from 'views/Shared'
import ArticleWithActions from '../../Articles/ArticleWithActions'
import {FeedWidget} from '../../NewFeed/FeedWidget'
import {paths} from '../contains'
import {GiIronCross} from "react-icons/gi";
import {BsDiamondFill} from "react-icons/bs";

export const renderNewsItem = (item) => {
  if (!item) return null
  switch (item.action) {
    case 'advertise':
      return <GoogleAdsInFeedUnit/>
    case 'sticky':
      return (
        <LazyComponent>
          <Pure>
            <FeedWidget item={item} />
          </Pure>
        </LazyComponent>
      )
    default:
      return (
        (item || null) && (
          <ArticleWithActions
            key={item.id}
            {...item}
            hideActions={true}
          />
        )
      )
  }
}

export const renderHeadlineFeedItem = (item) => {
  if (!item) return null
  switch (item.action) {
    case 'advertise':
      return <GoogleAdsInFeedUnit/>
    case 'sticky':
      return (
        <Translate>
          {(t) => (
            <div className="p-2 flex items-center gap-2 border border-color-50 hover:shadow-items-md rounded-md">
              <AppIcon item={_.get(item, 'content.owner')}/>
              <div className="flex flex-col flex-1">
                <Link
                  to={getLinkToDetail(_.get(item, 'content.owner'))}
                  className="font-bold text-sm text-color-000 no-underline leading-snug max-lines-1">
                  {_.get(
                    item,
                    'content.owner.title',
                    _.get(item, 'content.title')
                  )}
                </Link>
                {_.has(item, 'content.owner.static_data.{last_updated}') && (
                    <span className="text-xs text-color-500 italic">
                      {[
                        t('update'),
                        _.get(item, 'content.owner.static_data.{last_updated}'),
                      ].join(' ')}
                    </span>
                )}
              </div>
            </div>
          )}
        </Translate>
      )
    default:
      return renderIf(
        item ?? false,
        <div className="flex flex-col py-2 block border-b">
          <Link
            to={getLinkToDetail(_.get(item, 'content'))}
            className="font-bold text-sm text-color-100 max-lines-2 leading-tight no-underline">
            {_.get(
              item,
              'content.title',
              _.get(
                item,
                'content.embed_data.title',
                _.get(item, 'content.description')
              )
            )}
          </Link>

          <div className="flex flex-col gap-1">
            <Timestamp
              className="text-2xs text-color-400 italic"
              timestamp={_.get(item, 'content.created')}
            />
            {_.get(item, 'content.categories', []).length > 0 && (
              <div className="flex flex-wrap gap-x-2">
                {_.get(item, 'content.categories', []).map((e, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-1 text-primary">
                    <BsDiamondFill size={6} />
                    <Link
                      to={
                        paths.categoryPath
                          .replace(':category', e.id)
                          .replace(':refcategory', e.alt_idname) +
                        '?lang=' +
                        e.language
                      }
                      key={e.id}
                      className="italic font-medium text-xs">
                      {e.name}
                    </Link>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )
  }
}

const SpotlightItem = ({
  title,
                         subTitle = `${APP_NAME}`,
  categories = [],
  keywords = [],
  image,
  created,
  description,
  url,
  auth_avatar,
  auth_name,
                         total_comments,
}) => {
  const history = useHistory()
  const t = useTranslate()

  return (
    <div className="md:p-2 block md:border verticalList__small rounded ">
      {image && (
        <div
          style={{
            minHeight: '25Vh',
            maxHeight: '30Vh',
          }}
          className="w-full rounded background-100 overflow-hidden h-32 flex-shrink-0 mt-2">
          <ImageLoader
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            alt={title}
            src={image}
          />
        </div>
      )}

      <div className="flex">
        <div className="flex-1 verticalList__small break-words pr-1 overflow-hidden">
          <div>
            <h3 className="font-medium md:font-semibold text-lg leading-snug md:leading-normal">
              <Link
                to={{
                  pathname: url,
                }}
                style={{
                  // maxHeight: '3em',
                  overflow: 'hidden',
                }}>
                {_.truncate(title, {length: 160})}
              </Link>
            </h3>
          </div>
          <Description
            className={classNames(
              'font-normal leading-snug',
              !!!image ? '' : 'hidden md:block'
            )}
            style={{
              maxHeight: '2.67em',
              overflow: 'hidden',
            }}
            ghostbav>
            {mapKeywords(description, keywords)}
            {/*{description}*/}
          </Description>
        </div>
      </div>
    </div>
  )
}

const SpotlightItemRow = ({
  title,
                            subTitle = `${APP_NAME}`,
  categories = [],
  image,
  created,
  description,
  url,
  auth_avatar,
  auth_name,
                            total_comments,
}) => {
  const history = useHistory()
  const t = useTranslate()

  return (
    <div className="block border-b">
      <div className="flex">
        <div className="flex-1 verticalList__small break-words pr-1 overflow-hidden">
          <h3 className="font-medium md:font-semibold text-lg leading-snug md:leading-normal">
            <Link
              to={{
                pathname: url,
              }}
              style={{
                maxHeight: '3em',
                overflow: 'hidden',
              }}>
              {_.truncate(title, {length: 160})}
            </Link>
          </h3>
        </div>
      </div>

      <div className="text-color-300 text-sm pb-3">
        <Timestamp>{created}</Timestamp>
      </div>
    </div>
  )
}

const SpotlightContainer = (feed) => {
  const history = useHistory()
  const t = useTranslate()
  const item = feed.content
  let e = null

  switch (_.get(item, '_type')) {
    case 'article':
      e = (
        <div
          {...{ key: item.id }}
          className="verticalList__small pb-3">
          <SpotlightItem
            {...{
              title: item['title'],
              categories: _.get(item, 'categories'),
              keywords: _.get(item, 'keywords', []),
              image:
                item.cover_photo &&
                item.cover_photo !== null &&
                `${item.cover_photo}`,
              description: item['description'],
              url: getLinkToDetail(item),
              created: _.get(item, 'created'),
              total_comments: _.get(item, 'total_comments', 0),
            }}
          />
        </div>
      )
      break
    case 'post':
      e = (
        <div
          {...{ key: item.id }}
          className="verticalList__small">
          <SpotlightItem
            {...{
              title: _.get(item, 'embed_data.title'),
              categories: _.get(item, 'categories'),
              keywords: _.get(item, 'keywords', []),
              image: _.get(item, 'embed_data.photo'),
              description: _.get(item, 'embed_data.description'),
              url: `/news/post/${item.id}`,
              created: _.get(item, 'created'),
              total_comments: _.get(item, 'total_comments', 0),
            }}
          />
        </div>
      )
      break
    default:
      break
  }
  return e
}

const SpotlightRowContainer = (feed) => {
  const history = useHistory()
  const t = useTranslate()
  const item = feed.content
  let e = null

  switch (_.get(item, '_type')) {
    case 'article':
      e = (
        <div
          {...{ key: item.id }}
          className="verticalList__small">
          <SpotlightItemRow
            {...{
              title: item['title'],
              categories: _.get(item, 'categories'),
              keywords: _.get(item, 'keywords', []),
              image:
                item.cover_photo &&
                item.cover_photo !== null &&
                `${item.cover_photo}`,
              description: item['description'],
              url: getLinkToDetail(item),
              created: _.get(item, 'created'),
              total_comments: _.get(item, 'total_comments', 0),
            }}
          />
        </div>
      )
      break
    case 'post':
      e = (
        <div
          {...{ key: item.id }}
          className="verticalList__small">
          <SpotlightItemRow
            {...{
              title: _.get(item, 'embed_data.title'),
              categories: _.get(item, 'categories'),
              keywords: _.get(item, 'keywords', []),
              image: _.get(item, 'embed_data.photo'),
              description: _.get(item, 'embed_data.description'),
              url: `/news/post/${item.id}`,
              created: _.get(item, 'created'),
              total_comments: _.get(item, 'total_comments', 0),
            }}
          />
        </div>
      )
      break
    default:
      break
  }
  return e
}

export const renderSpotlightItem = (item) => {
  if (!item) return null
  switch (item.action) {
    default:
      return (
        (item || null) && (
          <SpotlightContainer
            key={item.id}
            {...item}
          />
        )
      )
  }
}

export const renderSpotlightItemRow = (item) => {
  if (!item) return null
  switch (item.action) {
    default:
      return (
        (item || null) && (
          <SpotlightRowContainer
            key={item.id}
            {...item}
          />
        )
      )
  }
}
