import _ from 'lodash'
import {mapProps} from 'recompose'

const mapParams = () =>
  mapProps(props =>
    _.get(
      props,
      'match.params',
      {}
    )
  )
export default mapParams
