import { schema } from 'normalizr'
export const collectionSchema = new schema.Entity(
  'baseItems',
  {},
  {
    idAttribute: 'id',
    processStrategy: e => {
      return e
    }
  }
)
