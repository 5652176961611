import base, {getId, getType} from 'apis/model/base'
import {ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import {fromAppEntity} from './enums'

export const getLinkToSetting = (item, segment) => {
  if (_.isEmpty(item)) return '/'

  const [id, type] = [getId(item), getType(item)]

  const {pathname} = fromAppEntity(type)
  const setting_url = ['', pathname, id, 'settings'].join('/')

  return segment ? setting_url + segment : setting_url
}

export const getLinkToDetail = (item) => {
  if (_.isEmpty(item)) return '/'

  const [id, type] = [getId(item), getType(item)]

  const {pathname} = fromAppEntity(type)
  return ['', pathname, id].join('/')
}

export const getLinkToShare = (item) => {
  if (_.isEmpty(item)) return ''
  const [id, type] = [getId(item), getType(item)]

  const {pathname} = fromAppEntity(type)

  return [ROOT_URL, pathname, id].join('/')
}

export default {
  ...base,
  getLinkToSetting,
  getLinkToShare,
  getLinkToDetail,
}
