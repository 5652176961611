import classnames from "classnames"
import Null from "components/NullComponent"
import useTranslate from "modules/local/useTranslate"
import moment from "moment"
import React from "react"
import { MdEmail, MdOutlineWatchLater, MdPhone } from "react-icons/md"
import { renderSelf } from 'views/Shared'

const SenderInfo = ({
  sender,
  className,
  Header = SenderHeader
}) => {

  const t = useTranslate()

  if (!!!sender) {
    return null
  }

  const {
    created,
    sender_name,
    sender_email,
    sender_phone,
  } = sender

  const items = [
    {
      title: 'sender_email',
      className: "font-light text-sm text-color-100",
      value: sender_email,
      Icon: MdEmail,
      transform: value => {
        return (
          <span className="px-2">
            <a href={`mailto:${value}`}>
              {value}
            </a>
          </span>
        )
      }
    },
    {
      title: 'sender_phone',
      className: "font-light text-sm text-color-100",
      value: sender_phone,
      Icon: MdPhone,
      transform: value => {
        return (
          <span className="px-2">
            <a href={`tel:${value}`}>
              {value}
            </a>
          </span>
        )
      }
    },
    {
      title: 'received at',
      value: created,
      Icon: MdOutlineWatchLater,
      transform: value => {
        return (
          <span className="px-2 flex text-xs text-color-300 italic">
            {`${t('received at')} : `}
            {moment(new Date(value)).format("DD-MMM-YYYY")}
          </span>
        )
      }
    }
  ]

  return (
    <div className={className}>
      <Header className="font-semibold">
        {sender_name}
      </Header>
      {items.map(({
        Icon = Null,
        value,
        className,
        transform = renderSelf,
      }, index) => (
        <div
          key={index}
          className={
            classnames(
              "flex items-center",
              className
            )
          }>
          <Icon className="text-color-300" />
          {transform(value)}
        </div>
      ))}
    </div>
  )
}

const SenderHeader = ({
  children,
  className
}) => {
  return (
    <span className={className}>
      {children}
    </span>
  )
}

export default SenderInfo