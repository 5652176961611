import Icon, {LoadingOutlined} from '@ant-design/icons'
import {referral_deleteReferral_Api, referral_getReferrals_Api} from 'apis'
import classnames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import Timestamp from 'components/Feed/Timestamp'
import Modal from 'components/Modal/Modal'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React from 'react'
import {MdClose} from 'react-icons/md'
import useToggle from 'react-use/lib/useToggle'
import {HeaderButton} from 'views/Organization/components/PageHeader'
import Invite from './Invite'
import copyToClipboard from "copy-to-clipboard";
import {ROOT_URL} from "../../../envs/_current/config";
import _ from "lodash";
import {message} from "antd";
import {BsCopy, BsX} from "react-icons/bs";
import {renderIf, renderIfElse} from "../../Shared";

const InviteRow = ({
  className,
  inviter,
  invite_code,
  uses,
  actions,
  expires
}) => (
  <div
    className={classnames(
      'flex items-center px-2',
      className
    )}>
    <div
      style={{ flex: '3 1 0px' }}
      className="hidden md:block">
      {inviter}
    </div>
    <div style={{ flex: '3 1 0px' }}>
      {invite_code}
    </div>
    <div style={{ flex: '1 1 0px' }} className="flex justify-center">
      {uses}
    </div>
    <div style={{ flex: '3 1 0px' }}>
      {expires}
    </div>
    {
      <div style={{ flex: '1 1 0px' }} className="flex justify-end">
        {actions}
      </div>
    }
  </div>
)
const InviteRowHeader = withTranslate(
  ({ translate }) => (
    <InviteRow
      {...{
        inviter: (
          <div className="text-xs uppercase text-color-300 font-semibold truncate hidden md:block">
            {translate('inviter')}
          </div>
        ),
        invite_code: (
          <div className="text-xs uppercase text-color-300 font-semibold truncate">
            {translate('invite code')}
          </div>
        ),
        uses: (
          <div className="text-xs text-right uppercase text-color-300 font-semibold truncate">
            {translate('uses')}
          </div>
        ),
        expires: (
          <div className="text-xs text-right uppercase text-color-300 font-semibold truncate">
            {translate('expires')}
          </div>
        )
      }}
    />
  )
)

function InviteManager({ item }) {
  const t = useTranslate()
  const containerType = item._type
  const containerId = item.id
  const [isToggle, toggle] = useToggle()
  const [
    listKey,
    setListKey
  ] = useToggle()
  return (
    <div className="container max-w-6xl mx-auto p-3 verticalList">
      <div className="text-sm text-color-400 italic border-b pb-1 mb-3">
          {`${t('Here is a list of all invite links. You can revoke any one.')} `}
          <span
              onClick={() => toggle()}
              className="font-bold text-primary hover:text-primary-700 cursor-pointer">
              {t('create one')}
          </span>
          <Modal
              width={520}
              className="custom-modal"
              destroyOnClose
              footer={null}
              maskClosable
              visible={isToggle}
              title={
                  <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                      {t('Invite your friends to this page')}
                  </div>
              }
              onCancel={() => {
                  setListKey(new Date().getTime())
                  toggle()
              }}>
              <Invite item={item} />
          </Modal>
      </div>
      <div className="flex flex-col gap-3">
        <InviteRowHeader />
        <div className="flex flex-col gap-1">
          <LazyPagination
            key={listKey}
            query={{
              ':prop': containerType,
              ':id': containerId
            }}
            renderEmpty={() => (
              <EmptyHolder
                subTitle={t(
                  'no invites yet'
                )}
              />
            )}
            apiInfo={referral_getReferrals_Api}
            renderItem={item => item && (
                <InviteRow
                  className="border border-transparent hover:border-color-base hover:shadow-items-md rounded-md p-2"
                  {...{
                    inviter: (
                        <span className="font-bold text-sm text-color-000">
                            {item.creator.name || 'NaN'}
                        </span>
                    ),
                    invite_code: (
                      <div className="font-bold text-sm text-orange-500">
                          {item.referral_code}
                          <BsCopy
                              size={14}
                              onClick={() => {
                                  copyToClipboard(ROOT_URL + '/invite/organization/' + item.referral_code)
                                  message.success(t('copied'))
                              }}
                              className="ml-2 text-color-400 hover:text-color-100 cursor-pointer"
                          />
                      </div>
                    ),
                    uses: (
                      <div className="text-center">
                        {item.total_accepts || 0}
                      </div>
                    ),
                    expires: (
                      <div className="text-xs text-color-500 italic text-right">
                        {renderIfElse(
                            _.isEmpty(item.expired),
                            <Timestamp timestamp={item.expired} />,
                            <span className="font-medium text-blue-600">{t('no limit')}</span>
                        )}
                      </div>
                    ),
                    actions: (
                      <AsyncAction
                        apiInfo={referral_deleteReferral_Api}
                        query={{':referral_id': item.id}}>
                        {({
                          handleAsyncAction,
                          isLoading,
                          success
                        }) =>
                          success ? (
                            <div className="font-medium text-xs text-red-500 italic text-right">
                                {t('deleted')}
                            </div>
                          ) : (
                            <HeaderButton
                                width={25}
                                height={25}
                                onClick={() => handleAsyncAction()}>
                                {isLoading ? <LoadingOutlined /> : <BsX />}
                            </HeaderButton>
                          )
                        }
                      </AsyncAction>
                    )
                  }}
                />
              )
            }
          />
        </div>
      </div>
    </div>
  )
}

export default InviteManager
