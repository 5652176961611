import {Button, Modal} from 'antd'
import AppTextLogoSvg from 'appConfigs/svgs/AppTextLogoSvg'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import {Link} from 'react-router-dom'
import {staticPaths} from 'routes/staticPaths'
import {DelayRender} from 'views/Discovery/DelayRender'

const DefaultContent = ({title, description}) => (
  <Translate>
    {(t) => (
      <React.Fragment>
        {_.isString(title) ? (
          <div className="p-6 flex justify-center text-center items-center font-bold text-2xl">
            {t(title)}
          </div>
        ) : (
          title
        )}
        {_.isString(description) ? (
          <div className=" p-3 flex justify-center items-center text-color-400 text-center italic font-light">
            {t(description)}
          </div>
        ) : (
          description
        )}
      </React.Fragment>
    )}
  </Translate>
)

export const ContentNotFound = ({
                                  status = {
                                    title: 404,
                                    description: 'page not found',
                                  },
                                  Content = DefaultContent,
                                  redirectUrl = staticPaths.home,
                                  description = 'The page you are opening does not exist, please check the link or return to the homepage',
                                }) => (
  <DelayRender lazy={true}>
    <Modal
      width={720}
      footer={null}
      visible={true}
      closable={false}
      title={_.isEmpty(status) ? undefined : status?.title}>
      <Translate>
        {(t) => (
          <div className="px-3 py-16 flex flex-col w-full h-full justify-center items-center">
            <div
              style={{
                fontSize: '9rem',
              }}>
              <AppTextLogoSvg size={16}/>
            </div>
            <Content
              {...{
                title: _.get(status, 'description'),
                description,
              }}
            />
            <Link to={redirectUrl}>
              <Button className="rounded-lg no-shadow no-text-shadow">
                {t('return home')}
              </Button>
            </Link>
          </div>
        )}
      </Translate>
    </Modal>
  </DelayRender>
)
