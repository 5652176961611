import { getId } from 'apis/model/base'
import classNames from 'classnames'
import { Share } from 'components/Feed/Share'
import copyToClipboard from 'copy-to-clipboard'
import { ROOT_URL } from 'envs/_current/config'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import React, { useContext } from 'react'
import createStickySider from 'views/AppLocals/factory/createStickySider'
import { Null, renderIf } from 'views/Shared'
import { FollowButton } from '../../../../components/Feed/FollowButton'
import categoryIcons from '../../../../conts/categoryIcons'
import useTranslate from '../../../../modules/local/useTranslate'
import { ListHeaderWithLine } from '../../Templates/items/ListHeader'
import UserContext from '../UserContext'
import UserChannels from './UserChannels'
import UserOrganizations from './UserOrganizations'
import {message, Tooltip} from "antd";
import Translate from "../../../../modules/local/Translate";
import {OrganizationShareWidget, UserShareWidget} from "../../factory/createShareWidget";
import {HeaderButton} from "../../../Organization/components/PageHeader";
import {PiCopy, PiLinkSimpleBold} from "react-icons/pi";

const ListWrapper = ({ style, title, className, children }) => {
  const t = useTranslate()
  return (
    <div
      style={style}
      className={classNames('flex flex-col gap-3', className)}>
      <div className="uppercase text-color-300 font-medium pb-1 border-b border-color-50">
        {t(title)}
      </div>
      <div className="flex flex-col gap-3">{children}</div>
    </div>
  )
}

const FavouriteCategories = () => {
    const t =  useTranslate()
    const { user } = useContext(UserContext)
    const categories = Array.from(user?.categories ?? [])

    const shareUrl = `${ROOT_URL}${getLinkToDetail(user)}`

    return renderIf(
        categories.length,
        <div className="flex flex-col gap-4">
            <div className="w-full flex flex-col">
                <ListHeaderWithLine
                    title="follow"
                    className="text-sm"
                />
                <div className="flex item-center justify-end gap-3">
                    <FollowButton item={user} />
                </div>
            </div>
            <div className="flex flex-col gap-3">
                <ListHeaderWithLine
                    title="favourites"
                    className="text-sm"
                />
                <div className="flex flex-wrap gap-3">
                    {categories.map((e, i) => {
                        const Icon = _.get(categoryIcons, getId(e), categoryIcons['default']) ?? Null
                        return (
                            <div
                                key={i}
                                style={{ padding: '5px 12px' }}
                                className="w-fit flex flex-center gap-2 bg-primary-50 rounded-md">
                                <Icon size={14} />
                                <span className="leading-tight font-semibold text-sm text-primary-500 italic">
                                  {e.name}
                                </span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const Channels = () => {
  const { user } = useContext(UserContext)
  return (
    <UserChannels
      user_id={getId(user)}
      renderWrap={(child, __, count) =>
        renderIf(
          count,
          <div className="flex flex-col gap-3">
            <ListHeaderWithLine
              title="channels"
              className="text-sm"
            />
            {child}
          </div>
        )
      }
    />
  )
}

const Organizations = () => {
  const { user } = useContext(UserContext)
  return (
    <UserOrganizations
      user_id={getId(user)}
      renderWrap={(child, __, count) =>
        renderIf(
          count,
          <div className="flex flex-col gap-3">
            <ListHeaderWithLine
              title="admin of organizations"
              className="text-sm"
            />
            {child}
          </div>
        )
      }
    />
  )
}

const Content = () => {
    const t =  useTranslate()
    const { user } = useContext(UserContext)
    const shareUrl = `${ROOT_URL}${getLinkToDetail(user)}`

    return (
        <div className="flex flex-col gap-6 mt-6">
            <FavouriteCategories />
            <Channels />
            <Organizations />
            <div className="h-3"/>
            <UserShareWidget
                className="gap-2"
                header={
                    <Translate>
                        {(t) => (
                            <span className="text-sm text-color-400 italic">
                          {`${t('share')} : `}
                      </span>
                        )}
                    </Translate>
                }
                footer={
                    <HeaderButton
                        onClick={() => {
                            copyToClipboard(shareUrl)
                            message.success(t('copied'))
                        }}
                        width={24}
                        height={24}
                        icon={
                            <Tooltip title={t('copy link')} placement="right">
                                <PiLinkSimpleBold size={12}/>
                            </Tooltip>
                        }
                    />
                }
            />
            <div className="h-3"/>
        </div>
    )
}

const UserSider = ({ Wrapper = 'div', stickySider = true }) =>
  createStickySider({
    Wrapper,
    stickySider,
  })(Content)

export default UserSider
