import { Col } from 'antd'
import { auth_fetchSuggestionChannelsByAuthCategories_Api } from 'apis'
import EmptyHolder from 'components/EmptyHolder'
import CustomLazyPaginationWrapper from 'modules/asyncCache/components/CustomLazyPaginationWrapper'
import React, { useState } from 'react'
import { renderChannelItem } from 'views/MainPage/functions/renderChannelItem'
import { renderShowMore } from 'views/MainPage/functions/renderLimitLoadMore'
import renderPagingLoading from 'views/MainPage/functions/renderPagingLoading'
import renderPagingWrapper from 'views/MainPage/functions/renderPagingWrapper'
import { renderSelf, withPagerWrapper } from 'views/Shared'

const ChannelsByAuth = ({
  Wrapper = Col,
  title = 'channels',
  alwaysVisible = false,
  renderItem = renderChannelItem,
  ...props
}) => {
  const [limit, setLimit] = useState(5)

  const apiInfo = auth_fetchSuggestionChannelsByAuthCategories_Api

  const lookupKey = apiInfo.path
  console.log(4444444444, lookupKey)

  const renderWrap = (alwaysVisible ? renderSelf : withPagerWrapper)(
    renderPagingWrapper(title)
  )

  return (
    <Wrapper>
      <CustomLazyPaginationWrapper
        auto={false}
        sample={limit}
        id={lookupKey}
        key={lookupKey}
        apiInfo={apiInfo}
        renderItem={renderItem}
        renderWrap={renderWrap}
        alwaysVisible={alwaysVisible}
        renderEmpty={() => <EmptyHolder />}
        renderLoading={renderPagingLoading}
        renderLoadMoreButton={renderShowMore([limit, setLimit])}
      />
    </Wrapper>
  )
}

export default ChannelsByAuth
