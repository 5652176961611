import {Drawer} from 'antd'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {RegAsModal} from 'components/Modal/Modal'
import Pure from 'components/Pure'
import ShadowScrollbar from 'components/ShadowScrollbar'
import React, {useContext} from 'react'
import {Null} from 'views/Shared'

const canShowDrawer = (breakpoint) => ['xs', 'sm', 'md'].includes(breakpoint)

export const useDrawerDetector = () => {
  const {breakpoint, openMenu, setOpenMenu} = useContext(LayoutContext)
  return {
    isToggle: openMenu,
    toggle: setOpenMenu,
    hasDrawer: canShowDrawer(breakpoint),
  }
}

const MenuController = ({children}) => {
  return (
    <LayoutContext.Consumer>
      {({openMenu, breakpoint, setOpenMenu}) =>
        children({
          breakpoint,
          isToggle: openMenu,
          toggle: setOpenMenu,
          hasDrawer: canShowDrawer(breakpoint),
        })
      }
    </LayoutContext.Consumer>
  )
}

const DefaultWrapper = ({children, ...props}) => (
  <ShadowScrollbar {...props}>{children}</ShadowScrollbar>
)

const CustomSiderMenu = ({
                           children,
                           width = 320,
                           deps = [],
                           Wrapper = DefaultWrapper,
                         }) => {
  return (
    <Pure input={[width, ...deps]}>
      <MenuController>
        {({toggle = Null, isToggle = false, hasDrawer = false}) => {
          if (hasDrawer) {
            return (
              <Drawer
                bodyStyle={{
                  padding: 0,
                  overflow: 'hidden',
                }}
                width={width}
                placement="left"
                closable={false}
                onClose={() => toggle(false)}
                visible={isToggle}>
                {isToggle && <RegAsModal/>}
                <DefaultWrapper className="absolute top-0 left-0">
                  {children}
                </DefaultWrapper>
              </Drawer>
            )
          }
          return (
            <div
              style={{
                width: width,
              }}
              className="RightSider">
              <Wrapper>{children}</Wrapper>
            </div>
          )
        }}
      </MenuController>
    </Pure>
  )
}

export default CustomSiderMenu
