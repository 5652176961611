window.testcase = {}

var STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm
var ARGUMENT_NAMES = /([^\s,]+)/g

function getParamNames(func) {
  var fnStr = func
    .toString()
    .replace(STRIP_COMMENTS, '')
  var result = fnStr
    .slice(
      fnStr.indexOf('(') + 1,
      fnStr.indexOf(')')
    )
    .match(ARGUMENT_NAMES)
  if (result === null) result = []
  return result
}

export default function logFunc(
  fn,
  prefix
) {
  return (...args) => {
    const argsName = getParamNames(fn)
    const fnName = prefix
      ? `${prefix}.${fn.name}`
      : fn.name
    console.group([fnName])
    console.log(`argsName`, argsName)
    console.log('args', args)
    let start = Date.now()
    const result = fn(...args)
    let time = Date.now() - start
    console.log('result', result)
    console.groupEnd()
    if (!window.testcase[fnName]) {
      window.testcase[fnName] = []
    }
    window.testcase[fnName].push({
      fn,
      time,
      argsName,
      args,
      result
    })
    return result
  }
}
