import { Button, Input, InputNumber, Modal } from "antd"
import { orderRequest_feedbackOrderRequest_Api } from "apis/orderRequest"
import ApiInfoForm from "components/form/ApiInfoForm"
import FieldsFactory from "components/form/FieldsFactory"
import { createValue } from "components/form/utils"
import { FullSideModalLayout } from "components/Modal/SideModalLayout"
import Null from "components/NullComponent"
import { FormikConsumer } from "formik"
import { formatterNumber } from "helpers/formatterNumber"
import { parseNumber } from "helpers/parseNumber"
import _ from "lodash"
import useTranslate from "modules/local/useTranslate"
import withTranslate from "modules/local/withTranslate"
import React, { useContext } from "react"
import { compose, mapProps } from "recompose"
import { SpotRequestTypes } from "views/Market/components/SpotBid/SpotRequestForm"
import SpotSelect, { RenderUnits } from "views/Market/components/SpotBid/SpotSelect"
import OrderRequestContext from "views/OrderRequest/OrderRequestContext"
import * as Yup from 'yup'
import QuoteContent from "./QuoteContent"

const withConfirmModal = ({
  title,
  width = 600,
  toggle = Null,
  sideModal = false,
  ...props
}) => Component => {

  if (sideModal) {
    return (
      <FullSideModalLayout
        right={true}
        title={(
          <div className="font-bold text-color-000">
            {title}
          </div>
        )}
        onCancel={toggle}>
        <div className="p-3">
          <Component
            toggle={toggle}
            {...props} />
        </div>
      </FullSideModalLayout>
    )
  }
  return (
    <Modal
      width={width}
      onCancel={toggle}
      className="custom-modal"
      header={null}
      footer={null}
      visible={true}>
      <Component
        toggle={toggle}
        title={title}
        {...props} />
    </Modal>
  )
}

const ResponseRequestContent = ({
  id,
  type,
  message,
  onOK = Null,
  toggle = Null,
  Avatar = Null,
  onSuccess = Null,
  Description = Null,
  title = '',
}) => {
  const t = useTranslate()

  const { dataSource = {} } = useContext(OrderRequestContext)

  const {
    product_units = [],
    product_price_units = [],
  } = dataSource

  const requiredFieldError = t('required field')

  const invisible = type !== SpotRequestTypes.PRICE

  const validationSchema = Yup.object().shape(
    invisible ? {
      'feedback_message': Yup.string()
        .required(requiredFieldError),
    } : {
      'min_qtty': Yup.number()
        .required(requiredFieldError),
      'price_unit_id': Yup.string()
        .required(requiredFieldError),
      'unit_id': Yup.string()
        .required(requiredFieldError),
      'price': Yup.number()
        .required(requiredFieldError),
      'feedback_message': Yup.string()
        .required(requiredFieldError),
    }
  )

  const formSchema = [
    {
      invisible,
      title: 'product price',
      inline: true,
      className: "grid grid-cols-3 gap-3",
      itemClassNames: [
        "flex flex-col col-span-2",
        "flex flex-col col-span-1",
      ],
      ItemWrapper: ({ children }) => {
        return (
          <div className="space-y-3">
            {children}
          </div>
        )
      },
      children: [
        {
          name: 'price',
          component: compose(
            withTranslate,
            mapProps(
              ({
                name,
                onChange,
                translate,
              }) => ({
                name,
                onChange: value => {
                  onChange(
                    createValue(
                      name,
                      value
                    )
                  )
                },
                inputMode: 'numeric',
                parser: parseNumber,
                className: "rounded",
                addonAfter: (
                  <SpotSelect
                    style={{
                      width: '100px'
                    }}
                    defaultFirstOption
                    items={product_units}
                    onChange={({ value }) => {
                      onChange(
                        createValue(
                          'unit_id',
                          value
                        )
                      )
                    }}
                    renderItem={RenderUnits}
                    placeholder={translate('unit')} />
                ),
                formatter: formatterNumber,
                placeholder: translate('price'),
              })
            )
          )(InputNumber)
        },
        {
          name: 'price_unit_id',
          component: compose(
            withTranslate,
            mapProps(
              ({
                name,
                onChange,
                translate,
              }) => ({
                className: "w-full",
                onChange: ({ value }) => {
                  onChange(
                    createValue(
                      name,
                      value
                    )
                  )
                },
                renderItem: RenderUnits,
                placeholder: translate('price unit'),
                items: product_price_units,
              }))
          )(SpotSelect)
        },
      ]
    },
    {
      invisible,
      title: 'min quantity',
      children: [
        {
          name: 'min_qtty',
          component: compose(
            withTranslate,
            mapProps(({
              name,
              form,
              value,
              onChange,
              translate,
            }) => {
              const product_unit_id = _.get(
                form,
                'values.unit_id',
                ''
              )
              const selected_unit = product_units.find(
                e => e.id === product_unit_id
              )
              return {
                name,
                value,
                onChange,
                type: "number",
                inputMode: "numeric",
                addonAfter: (
                  <div style={{ width: '100px' }}>
                    {translate(
                      _.get(selected_unit, 'name', 'unit')
                    )}
                  </div>
                ),
                placeholder: translate('min quantity')
              }
            })
          )(Input)
        }
      ]
    },
    {
      title: 'reply',
      children: [{
        name: 'feedback_message',
        component: compose(
          withTranslate,
          mapProps(
            ({ onChange, name, value, translate }) => ({
              name,
              value,
              rows: 5,
              onChange,
              placeholder: translate('reply')
            })
          )
        )(Input.TextArea)
      }]
    }
  ]

  return (
    <ApiInfoForm
      onSuccess={onSuccess}
      validateOnChange={false}
      validateOnBlur={false}
      apiInfo={orderRequest_feedbackOrderRequest_Api}
      query={{
        ':id': id
      }}
      initialValues={{
        unit_id: _.get(
          _.first(product_units),
          'id'
        )
      }}
      validationSchema={validationSchema}>
      <React.Fragment>
        <div className="text-center font-bold text-2xl mb-3">
          {t(title)}
        </div>
        <Description />
        <div className="flex flex-col">
          <QuoteContent
            content={message}
            Wrapper={({ children }) => (
              <div className="flex">
                {children}
              </div>
            )}
            className="px-2 font-bold text-2xl" />
          <FieldsFactory
            renderTitle={title => (
              <p className="text-sm font-medium mb-1">
                {title}
              </p>
            )}
            formSchema={formSchema} />
        </div>
        <div className="space-y-3">
          <div className="flex justify-end space-x-3">
            <Button
              onClick={toggle}
              className="rounded-full border-none no-shadow">
              <span>{t('cancel')}</span>
            </Button>
            <FormikConsumer>
              {({ handleSubmit }) => (
                <Button
                  type="primary"
                  onClick={handleSubmit}
                  className="rounded-full border-none no-shadow">
                  <span>{t('reply')}</span>
                </Button>
              )}
            </FormikConsumer>
          </div>
        </div>
      </React.Fragment>
    </ApiInfoForm>
  )
}

const ClosingRequestContent = ({
  onOK = Null,
  toggle = Null,
  Avatar = Null,
  Description = Null,
  title = '',
}) => {

  const t = useTranslate()

  return (
    <div className="p-2">
      <div className="text-center font-bold text-color-000 uppercase tracking-wide mb-6">
          {t('close request')}
      </div>
      <div className="w-full p-2 flex items-center border border-color-50 rounded-md shadow-items-md mb-6">
        {/*<Avatar />*/}
        <div className="flex flex-col gap-1">
          <span className="font-bold text-lg text-color-000">
            {title}
          </span>
          <Description />
        </div>
      </div>
      <div className="flex flex-col items-center italic mb-8">
        <span className="text-color-200">
            {`${t('you have found a supplier for this product and you want to close this request')}.`}
        </span>
        <span className="font-medium text-sm ">
          {`${t('are you sure')} ?`}
        </span>
      </div>
      <div className="flex items-center justify-end gap-6">
        <Button
          onClick={toggle}
          className="button-rounded-md no-border font-medium text-color-300">
            {t('close')}
        </Button>
        <Button
          type="primary"
          onClick={() => {
            onOK()
          }}
          className="button-rounded-md no-border font-medium">
          <span>{t('sure')}</span>
        </Button>
      </div>
    </div>
  )
}

export {
  withConfirmModal,
  ClosingRequestContent,
  ResponseRequestContent
}
