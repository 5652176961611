import Icon, {LinkOutlined, ShareAltOutlined} from '@ant-design/icons'
import {Menu} from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {FaFacebookF, FaShareAlt, FaTwitter} from 'react-icons/fa'
import DrawMenu from '../drawer/DrawMenu'
import Count from './Count'
import FeedContext from "./FeedContext";
import {PiShareFat, PiShareFatLight} from "react-icons/pi";

const createFacebookUrl = url =>
  `https://www.facebook.com/sharer/sharer.php?u=${url}`
const createTwitterUrl = url =>
  `https://twitter.com/intent/tweet?url=${url}`
export const Share = ({
  url,
  context = FeedContext
}) => {
  const {
    handleClick,
    item
  } = useContext(context)
  const handleMenuClick = e => {
    handleClick(e.key)
  }
  const translate = useTranslate()

  return (
    <DrawMenu
      component={
        <Count
          color="color-primary"
          component={PiShareFatLight}
          size={12}
        />
      }>
      {(isToggle, toggle) => (
        <Menu
          onClick={e => {
            handleMenuClick(e)
            toggle()
          }}>
          <Menu.Item key="copy_link">
            <div className="flex inline-flex text-center">
              <div className="h-full text-center">
                <LinkOutlined
                  style={{
                    marginRight: '8px'
                  }}
                />
              </div>
              <div className="font-semibold">
                {translate('copy link')}
              </div>
            </div>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item>
            <a
              className="flex items-center"
              href={createFacebookUrl(
                url
              )}
              target="_blank">
              <Icon
                style={{
                  marginRight: '8px'
                }}
                component={FaFacebookF}
              />
              <span className="text-center font-semibold">
                Facebook
              </span>
            </a>
          </Menu.Item>
          <Menu.Item>
            <a
              className="flex items-center"
              href={createTwitterUrl(
                url
              )}
              target="_blank">
              <Icon
                component={FaTwitter}
                style={{
                  marginRight: '8px'
                }}
              />
              <span className="text-center font-semibold">
                Twitter
              </span>
            </a>
          </Menu.Item>
          <Menu.Item>
            <div
              className="flex items-center"
              onClick={() => {
                if (navigator.share) {
                  navigator
                    .share({
                      title:
                        item.title ||
                        'feed',
                      url: url
                    })
                    .then(() =>
                      console.log(
                        'Successful share'
                      )
                    )
                    .catch(error =>
                      console.log(
                        'Error sharing',
                        error
                      )
                    )
                } else {
                  alert(
                    `your device don't support this!`
                  )
                }
              }}
              target="_blank">
              <Icon
                component={FaShareAlt}
                style={{
                  marginRight: '8px'
                }}
              />
              <span className="text-center font-semibold">
                {translate('share')}
              </span>
            </div>
          </Menu.Item>
        </Menu>
      )}
    </DrawMenu>
  )
}
