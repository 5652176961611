import {Avatar} from 'antd'
import classNames from 'classnames'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {IoCheckmarkCircleOutline, IoCloseCircleOutline, IoRefreshCircle, IoTimeOutline,} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {SearchGroupIcon} from "../../../../components/AppIcon";
import {getType} from "../../../../apis/model/base";
import {OrgType} from "../../Organization/components/OrgType";

export const fromHostStatus = (value) => {
  switch (value) {
    case -1:
      return {
        label: 'pending',
        className: 'border border-blue-500 text-blue-500',
        icon: <IoTimeOutline size={12}/>,
      }
    case 1:
      return {
        label: 'approved',
        className: 'border border-green-500 text-green-500',
        icon: <IoCheckmarkCircleOutline size={12}/>,
      }
    case -8:
      return {
        label: 'has left',
        className: 'bg-orange-600 text-white',
        icon: <IoRefreshCircle size={12}/>,
      }
    case -9:
      return {
        label: 'rejected',
        className: 'border border-red-500 text-red-500',
        icon: <IoCloseCircleOutline size={12}/>,
      }
    default:
      return {}
  }
}

const HostContent = ({item, status, subItem, actions = null}) => {
  const t = useTranslate()
  const {label, className, icon} = fromHostStatus(status)
  const verified = _.get(item, 'certified')

  return (
    <div className="p-3 flex flex-col relative">
      <div className="flex items-center gap-2">
        <Avatar
          size={30}
          className="flex flex-center background-200"
          src={getAvatar(item)}
          icon={
            <SearchGroupIcon size={14} type={getType(item)} className="text-color-500"/>
          }
        />
        <div className="flex flex-col gap-1">
          <Link
              to={getLinkToDetail(item)}
              className="font-bold text-color-000 max-lines-1 no-underline leading-snug">
            {getTitle(item)}
          </Link>
          <OrgType item={item} className="font-medium text-xs text-primary-400 italic leading-snug"/>
        </div>
      </div>
      {!!label && <div className="h-5"/>}
      <div
        className={classNames(
          'rounded-md px-3 py-1 flex flex-center gap-1 absolute truncate bottom-0 right-0 m-2 italic',
          className
        )}>
        {icon}
        {label && (<span className="text-xs leading-tight">{t(label)}</span>)}
      </div>
    </div>
  )
}

export default HostContent
