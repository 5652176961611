import {Button, Input, message, Modal} from 'antd'
import {url_getMetaTags_Api} from 'apis'
import classNames from 'classnames'
import {LinkfyEmbed} from 'components/Feed/BaseEmbed'
import {VideoEmbed} from 'components/Feed/VideoEmbed'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo, useRef} from 'react'
import {RxVideo} from 'react-icons/rx'
import ReactPlayer from 'react-player'
import {Transforms} from 'slate'
import {ReactEditor, useSelected, useSlate, useSlateStatic} from 'slate-react'
import {renderIf} from 'views/Shared'
import {insertVideo} from 'views/SlateEditor/functions/video'
import {withOutline} from 'views/SlateEditor/SlateEditor'
import {string} from 'yup'
import Caption from '../Caption'
import {ToolbarButton} from '../Toolbar'
import {RiCloseFill} from "react-icons/ri";

export const VideoButton = () => {
  const t = useTranslate()

  const editor = useSlate()

  const valuesRef = useRef({})

  const disabled = !!!editor?.selection

  const {isLoading, handleAsyncAction: getMetaFromUrl} = useAsyncAction({
    apiInfo: url_getMetaTags_Api,
    onSuccess: (result, {response}) => {
      const linkMeta = response?.data
      if (linkMeta) {
        valuesRef.current['meta'] = linkMeta
      }
    },
  })

  const onPaste = (event) => {
    const clipboardData = event.clipboardData
    if (clipboardData?.getData) {
      const text = clipboardData.getData('Text')
      const LINK_DETECTION_REGEX =
        /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))?/gi
      if (!!LINK_DETECTION_REGEX.exec(text)) {
        getMetaFromUrl({
          content: text,
        })
      }
    }
  }

  const handleSubmit = async () => {
    const {meta} = valuesRef.current ?? {}
    const url = String(valuesRef.current?.url).trim()
    const schema = string().url()
    const valid = await schema.isValid(url)
    if (valid) {
      insertVideo(editor, url, meta)
      Modal.destroyAll()
    } else {
      message.error('video url is invalid!')
    }
    return
  }

  const formContent = useMemo(
    () => (
      <form
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmit()
        }}>
        <div className="flex items-start gap-4">
          <RxVideo
            size={40}
            style={{color: '#faad14'}}
          />
          <div className="flex flex-col mt-2">
            <div className="font-medium text-color-200 mb-1">
              {t(
                'Paste a video link to embed content from another site (e.g. Youtube) and click Ok'
              )}
            </div>
            <div className="flex items-baseline gap-2 text-xs italic mb-1">
              <p className="text-color-400 font-light">{t('example')}</p>
              <p className="font-semibold text-primary">
                {'https://youtube.com'}
              </p>
            </div>
            <Input.TextArea
              onPaste={onPaste}
              onChange={(event) => {
                valuesRef.current['url'] = event.target.value
              }}
              placeholder={t('enter video url')}
            />
            <input
              hidden
              type="submit"
            />
          </div>
        </div>
      </form>
    ),
    [valuesRef]
  )

  const handleClick = (event) => {
    event.preventDefault()
    Modal.confirm({
      width: 600,
      icon: null,
      okText: t('save'),
      content: formContent,
      cancelText: t('cancel'),
      className: 'custom-modal',
      okButtonProps: {
        type: 'primary',
        onClick: handleSubmit,
        className: 'no-shadow no-text-shadow rounded-lg no-border',
      },
      cancelButtonProps: {
        className: 'no-shadow no-text-shadow rounded-lg no-border',
      },
      title: (
        <div className="text-center text-color-000 font-bold uppercase">
          {t('embed')}
        </div>
      ),
    })
  }

  return (
    <ToolbarButton
      Icon={RxVideo}
      disabled={disabled}
      onClick={handleClick}
    />
  )
}

export const VideoElement = ({
                               children,
                               element,
                               attributes,
                               readOnly = false,
                             }) => {
  const editor = useSlateStatic()
  const selected = useSelected()

  const {url, meta, caption} = element ?? {}

  const path = ReactEditor.findPath(editor, element)

  const embed_content = useMemo(() => {
    const embed_data = meta
      ? {
        url,
        title: meta?.title,
        image: meta?.photo,
        description: meta?.description,
        subTitle: meta?.host_url || meta?.url,
      }
      : {
        title: url,
        subTitle: url,
      }
    if (ReactPlayer.canPlay(url)) {
      return (
        <VideoEmbed
          {...embed_data}
          isShowPlayer={true}
        />
      )
    } else {
      return (
        <LinkfyEmbed
          {...embed_data}
          selectable={!readOnly}
          className={classNames('md:p-3 background-100 rounded-md')}
        />
      )
    }
  }, [url, meta])

  return (
    <div {...(attributes ?? {})}>
      <div
        style={{
          padding: '0.5rem',
          position: 'relative',
          borderRadius: '0.375rem',
          outline: withOutline({selected, focused: selected}),
        }}
        contentEditable={false}>
        {renderIf(
          !readOnly && selected,
          <Button
            icon={<RiCloseFill size={13}/>}
            style={{position: 'absolute', top: 3, right: 3, zIndex: 3, width: 25, height: 25}}
            className="flex flex-center button-rounded-full no-border bg-primary-50 hover:bg-primary-300 text-primary-500 hover:text-primary"
            onClick={(event) => {
              event.preventDefault()
              Transforms.removeNodes(editor, {at: path})
            }}
          />
        )}
        {embed_content}
        <Translate>
          {(t) => (
            <Caption
              value={caption}
              readOnly={readOnly}
              withInput={() => ({
                placeholder: t('caption of video'),
              })}
              withModal={() => ({
                title: t('write caption for video'),
              })}
              onChange={(caption) => {
                const path = ReactEditor.findPath(editor, element)
                const newProperties = {
                  caption,
                }
                Transforms.setNodes(editor, newProperties, {
                  at: path,
                })
              }}
            />
          )}
        </Translate>
      </div>
      {children}
    </div>
  )
}
