import { Avatar, Image, Tooltip } from 'antd'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useContext } from 'react'
import { IoCheckmark } from 'react-icons/io5'
import UserContext from '../UserContext'

const UserBanner = React.memo(({ Wrapper = 'div' }) => {
  const { user } = useContext(UserContext)

  const t = useTranslate()
  const { isSm } = useContext(LayoutContext)

  const title = getTitle(user)

  const verified = _.get(user, 'certified')

  return (
    <Wrapper className="flex flex-col">
      <Image
        preview={false}
        src={_.get(user, 'cover_photo')}
        className="fadeIn animated banner-activity object-cover bg-color-org"
        style={{
            height: isSm ? '13rem' : '16rem',
            backgroundColor: '#794793',
        }}
      />

      <div className="absolute flex items-center w-full mx-auto">
        <div
          style={{
              height: isSm ? '13rem' : '16rem',
              background: 'radial-gradient(rgba(0, 0, 0, 0.09), rgba(0, 0, 0, 0))',
          }}
          className="flex flex-col items-center justify-center w-full px-3 banner-activity">
          <Avatar
            size={60}
            src={getAvatar(user)}
            className="flex flex-center background-200 mb-2"
            alt={title}>
            <span className="text-color-200 font-medium">{_.first(title)}</span>
          </Avatar>
          <div className="px-3 text-xl md:text-2xl font-semibold max-w-4xl text-center text-white my-0 md:my-3 leading-tight">
            {title}
            {verified === 1 && (
              <Tooltip
                title={t('verified')}
                placement="right"
                color="#87d068">
                <IoCheckmark
                  width={isSm ? '1rem' : '1.3rem'}
                  height={isSm ? '1rem' : '1.3rem'}
                  className="ml-1 mb-1 lg:mb-2 zoom-animation"
                />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
})

export default UserBanner
