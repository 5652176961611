import _ from 'lodash'
import {normalize} from 'normalizr'

export const createListTransform =
  (schema, str = 'data.data') =>
    (response) => {
      const {status} = response
      const data = _.get(response, str)
      if (!schema)
        return {
          ...response,
          ...(status !== 200
            ? {
              error: true,
              errorMessages: response.data,
            }
            : {
              success: true,
            }),
          status,
        }
      if (data) {
        const {entities, result} = normalize(data, [schema])
        response.entities = entities
        response.result = result
      }
      return {
        ...response,
        ...(status !== 200
          ? {
            error: true,
            errorMessages: response.data,
          }
          : {
            success: true,
          }),
        status,
        entities: response.entities,
        result: response.result,
      }
    }
