import {Form, Select} from 'antd'
import {API_ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import {AsyncWithCache} from 'modules/asyncCache/components/Async'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'

export const UnitSelect = ({
  name,
  form,
  disabled,
  rules
}) => {
  const t = useTranslate()
  return (
    <AsyncWithCache
      once
      apiInfo={{
        backend: ``,
        root: API_ROOT_URL,
        path:
          '/products/get-product-units',
        method: 'GET'
      }}
      cacheId="_productUnitList">
      {({
        response,
        isLoading,
        success
      }) => {
        const countryData = _.get(
          response,
          'data.data',
          []
        )
        return (
          <div className="flex">
            <Form.Item
              className="flex-1"
              name={name}
              hasFeedback
              noStyle
              rules={rules}>
              <Select
                disabled={disabled}
                loading={isLoading}
                optionFilterProp="children"
                showSearch
                style={{
                  width: '140px'
                }}
                placeholder={t('unit')}>
                {countryData.map(
                  ({
                    code,
                    id,
                    name
                  }) => (
                    <Select.Option
                      data-unit_code={
                        code
                      }
                      key={id}
                      label={name}
                      value={id}>
                      {name}
                    </Select.Option>
                  )
                )}
              </Select>
            </Form.Item>
          </div>
        )
      }}
    </AsyncWithCache>
  )
}
