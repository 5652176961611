import { useContext } from 'react'
import { LocalContext } from './LocalContext'

const defaultTranform = value => value
const useTranslate = () => {
  const {
    translate = defaultTranform
  } = useContext(LocalContext)
  // const translate = useCallback(
  // 	(value, tranforms = []) => {
  // 		return compose(
  // 			tranform,
  // 			...tranforms,
  // 			t
  // 		)(value)
  // 	},
  // 	[t, tranform]
  // )
  // const translate = useCallback(value=>value,[])
  return translate
}
export default useTranslate
