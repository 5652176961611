import React from "react";

export const Vitamin = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#F3E2A9"
				d="M329.802 73.5c0 40.593-33.042 73.5-73.802 73.5s-73.802-32.907-73.802-73.5S215.24 0 256 0s73.802 32.907 73.802 73.5z"
			></path>
			<path
				fill="#EFD898"
				d="M329.802 73.5c0 40.593-33.042 73.5-73.802 73.5-4 0-7.923-.328-11.751-.938 35.173-5.606 62.051-35.953 62.051-72.562S279.422 6.544 244.249.938A74.614 74.614 0 01256 0c40.76 0 73.802 32.907 73.802 73.5z"
			></path>
			<path
				fill="#FF7F54"
				d="M488.5 164.75c0 40.593-33.042 73.5-73.802 73.5s-73.802-32.907-73.802-73.5 33.042-73.5 73.802-73.5 73.802 32.907 73.802 73.5z"
			></path>
			<path
				fill="#F06C4A"
				d="M488.5 164.75c0 40.593-33.042 73.5-73.802 73.5-4 0-7.923-.328-11.751-.938 35.173-5.606 62.051-35.953 62.051-72.562s-26.878-66.956-62.051-72.562c3.828-.61 7.75-.938 11.751-.938 40.76 0 73.802 32.907 73.802 73.5z"
			></path>
			<path
				fill="#FFCF56"
				d="M488.5 347.25c0 40.593-33.042 73.5-73.802 73.5s-73.802-32.907-73.802-73.5 33.042-73.5 73.802-73.5 73.802 32.907 73.802 73.5z"
			></path>
			<path
				fill="#FFB154"
				d="M488.5 347.25c0 40.593-33.042 73.5-73.802 73.5-4 0-7.923-.328-11.751-.938 35.173-5.606 62.051-35.953 62.051-72.562s-26.878-66.956-62.051-72.562c3.828-.61 7.75-.938 11.751-.938 40.76 0 73.802 32.907 73.802 73.5z"
			></path>
			<path
				fill="#B8C76F"
				d="M329.802 438.5c0 40.593-33.042 73.5-73.802 73.5s-73.802-32.907-73.802-73.5S215.24 365 256 365s73.802 32.907 73.802 73.5z"
			></path>
			<path
				fill="#A2B16F"
				d="M329.802 438.5c0 40.593-33.042 73.5-73.802 73.5-4 0-7.923-.328-11.751-.938 35.173-5.606 62.051-35.953 62.051-72.562s-26.878-66.956-62.051-72.562A74.614 74.614 0 01256 365c40.76 0 73.802 32.907 73.802 73.5z"
			></path>
			<path
				fill="#A0C1B7"
				d="M171.103 347.25c0 40.593-33.042 73.5-73.802 73.5S23.5 387.843 23.5 347.25s33.042-73.5 73.802-73.5 73.801 32.907 73.801 73.5z"
			></path>
			<path
				fill="#84AB9E"
				d="M171.103 347.25c0 40.593-33.042 73.5-73.802 73.5-4 0-7.923-.328-11.751-.938 35.173-5.606 62.051-35.953 62.051-72.562s-26.878-66.956-62.051-72.562c3.828-.61 7.75-.938 11.751-.938 40.76 0 73.802 32.907 73.802 73.5z"
			></path>
			<path
				fill="#AA8753"
				d="M171.103 164.75c0 40.593-33.042 73.5-73.802 73.5S23.5 205.343 23.5 164.75s33.042-73.5 73.802-73.5 73.801 32.907 73.801 73.5z"
			></path>
			<path
				fill="#925F43"
				d="M171.103 164.75c0 40.593-33.042 73.5-73.802 73.5-4 0-7.923-.328-11.751-.938 35.173-5.606 62.051-35.953 62.051-72.562S120.723 97.794 85.55 92.188c3.828-.61 7.75-.938 11.751-.938 40.76 0 73.802 32.907 73.802 73.5z"
			></path>
			<path
				fill="#AA8753"
				d="M285.76 101.532l-13.173-52.475a17.07 17.07 0 00-16.588-12.916 17.07 17.07 0 00-16.588 12.916l-13.173 52.475a7.501 7.501 0 0014.55 3.653l2.979-11.868h24.463l2.979 11.868a7.504 7.504 0 007.269 5.676 7.51 7.51 0 001.832-.228 7.5 7.5 0 005.45-9.101zm-38.226-23.216l6.428-25.607A2.088 2.088 0 01256 51.141c.968 0 1.807.645 2.038 1.568l6.428 25.607h-16.932z"
			></path>
			<path
				fill="#F3E2A9"
				d="M123.54 194.608a7.5 7.5 0 01-7.5 7.5H78.563a7.5 7.5 0 01-7.5-7.5v-59.717a7.5 7.5 0 017.5-7.5h37.477a7.5 7.5 0 010 15H86.064v12.246h21.169a7.5 7.5 0 010 15H86.064v17.471h29.976a7.5 7.5 0 017.5 7.5z"
			></path>
			<path
				fill="#FFF"
				d="M89.927 309.892H74.816a7.5 7.5 0 00-7.5 7.5v59.717a7.5 7.5 0 007.5 7.5h15.111c20.601 0 37.36-16.759 37.36-37.358s-16.759-37.359-37.36-37.359zm0 59.716h-7.611v-44.717h7.611c12.329 0 22.36 10.03 22.36 22.358s-10.03 22.359-22.36 22.359zm151.088 53.963v29.858c0 4.097 3.36 7.429 7.49 7.429h14.991c4.13 0 7.49-3.333 7.49-7.429a7.5 7.5 0 0115 0c0 12.368-10.089 22.429-22.49 22.429h-14.991c-12.402 0-22.491-10.062-22.491-22.429v-29.858c0-12.368 10.089-22.429 22.491-22.429h14.991c12.401 0 22.49 10.062 22.49 22.429a7.5 7.5 0 01-15 0c0-4.097-3.36-7.429-7.49-7.429h-14.991c-4.13 0-7.49 3.332-7.49 7.429zm183.936-267.126a20.98 20.98 0 002.734-10.357c0-11.647-9.476-21.123-21.124-21.123h-23.853a7.5 7.5 0 00-7.5 7.5v59.717a7.5 7.5 0 007.5 7.5h28.735c13.088 0 23.737-10.648 23.737-23.736 0-8.071-4.054-15.211-10.229-19.501zm-34.743-16.48h16.352c3.376 0 6.123 2.747 6.123 6.123s-2.747 6.123-6.123 6.123h-16.352v-12.246zm21.235 44.718h-21.234v-17.471h21.234c4.817 0 8.736 3.918 8.736 8.735s-3.919 8.736-8.736 8.736zm42.746 4.887v7.465a7.5 7.5 0 01-15 0v-7.465a7.5 7.5 0 0115 0zm-38.242 149.375a20.98 20.98 0 002.734-10.357c0-11.647-9.476-21.123-21.125-21.123h-23.853a7.5 7.5 0 00-7.5 7.5v59.717a7.5 7.5 0 007.5 7.5h28.735c13.088 0 23.737-10.648 23.737-23.736.001-8.071-4.053-15.211-10.228-19.501zm-34.743-16.48h16.352c3.377 0 6.124 2.747 6.124 6.123s-2.747 6.123-6.124 6.123h-16.352v-12.246zm21.235 44.718h-21.235v-17.471h21.235c4.817 0 8.736 3.918 8.736 8.735s-3.919 8.736-8.736 8.736zm41.741 4.887v7.465a7.5 7.5 0 01-15 0v-7.465a7.5 7.5 0 0115 0zm19.013 0v7.465a7.5 7.5 0 01-15 0v-7.465a7.5 7.5 0 0115 0z"
			></path>
			<path
				fill="#84AB9E"
				d="M329.548 296.54c-14.851 26.687-43.261 43.163-73.588 43.163-2.058 0-4.121-.076-6.192-.229-22.383-1.659-42.779-11.902-57.431-28.841-10.652-12.315-17.319-27.102-19.541-42.824l-1.205 1.347a7.484 7.484 0 01-5.593 2.5 7.5 7.5 0 01-5.589-12.5l12.992-14.525a7.502 7.502 0 0110.578-.602l15.225 13.552a7.5 7.5 0 01-9.974 11.204l-1.296-1.154c2.082 12.171 7.447 23.592 15.748 33.188 12.037 13.916 28.797 22.331 47.194 23.694 26.801 1.981 52.535-11.858 65.563-35.269a7.502 7.502 0 0113.109 7.296z"
			></path>
			<path
				fill="#FF7F54"
				d="M351.34 257.979l-13.945 13.62a7.476 7.476 0 01-5.241 2.135 7.473 7.473 0 01-5.353-2.247l-14.274-14.542a7.5 7.5 0 0110.705-10.507l1.341 1.366c-1.591-13.475-7.158-26.173-16.249-36.683-12.037-13.916-28.797-22.331-47.194-23.694-28.37-2.1-55.554 13.7-67.645 39.323a7.501 7.501 0 01-13.566-6.401c14.722-31.197 47.796-50.438 82.321-47.881 22.383 1.659 42.779 11.901 57.431 28.841 11.606 13.418 18.487 29.769 20.031 47.069l1.156-1.13a7.5 7.5 0 0110.482 10.731z"
			></path>
		</svg>
	);
}

export default Vitamin;
