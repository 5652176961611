import { host_getsByHost_api } from 'apis'
import { getId, getType } from 'apis/model/base'
import classNames from 'classnames'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import React, { useCallback, useContext, useMemo } from 'react'
import { IoCaretDown, IoCaretForward } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { useSearchParam, useToggle } from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  nest,
  pure,
  renderNothing,
} from 'recompose'
import { ExpandablePanel } from 'views/AppLocals/custom/AdvancedFormGroup'
import EntityList from 'views/AppLocals/EntityList'
import { HostsSortBy } from 'views/AppLocals/factory/createEntitySortBy'
import { bindQueryParams } from 'views/AppLocals/functions/routerHelper'
import usePaginationHelper from 'views/AppLocals/hooks/usePaginationHelper'
import { renderHostOwner } from 'views/AppLocals/Host/functions/renderHost'
import HostContext, { HostProvider } from 'views/AppLocals/Host/HostContext'
import ListHeader from 'views/AppLocals/Templates/items/ListHeader'
import { Null, renderIf } from 'views/Shared'
import WorkspaceContext from '../WorkspaceContext'
import HostsOwnerAdvancedFilter from './HostsOwnerAdvancedFilter'

const ExpandContentWrapper = ({ children }) => (
  <div className="p-3 space-y-3">{children}</div>
)

const ExpandWrapper = ({ children }) => (
  <div className="space-y-2">{children}</div>
)

const renderExpandHeader =
  ({ count } = {}) =>
  ({ title, onClick, expanded }) => {
    const IconComponent = !!expanded ? IoCaretDown : IoCaretForward
    return renderIf(
      count,
      <Translate>
        {(t) => (
          <div
            onClick={onClick}
            className={classNames(
              'flex items-center gap-2 p-2 text-primary bg-primary-50 cursor-pointer',
              !!expanded ? 'rounded-t-lg' : 'rounded-lg'
            )}>
            <IconComponent size={14} />
            <span className="text-sm font-medium">{t(title)}</span>
            <span className="text-sm font-semibold">{`(${count})`}</span>
          </div>
        )}
      </Translate>
    )
  }

const renderCollapsedContent = (child) => (
  <div
    style={{
      visibility: 'hidden',
      maxHeight: 0,
    }}>
    {child}
  </div>
)

const HostPendingList = () => {
  const location = useLocation()

  const [isToggle, toggle] = useToggle()

  const { container } = useContext(HostContext)

  const [id, prop] = [getId(container), getType(container)]

  const refreshToken = _.get(location.state, 'refreshToken')

  const renderWrap = useCallback(
    (children, __, count) => (
      <ExpandablePanel
        onClick={toggle}
        expanded={isToggle}
        title="pending list"
        ContentWrapper={ExpandContentWrapper}
        Header={renderExpandHeader({ count })}
        Wrapper={count ? ExpandWrapper : undefined}
        renderCollapsedContent={renderCollapsedContent}>
        {children}
      </ExpandablePanel>
    ),
    [isToggle]
  )

  return (
    <EntityList
      key={refreshToken}
      renderWrap={renderWrap}
      values={{
        request_status: -1,
      }}
      apiInfo={host_getsByHost_api}
      RenderEntity={React.Fragment}
      query={bindQueryParams([{ id }, { prop }])}
      renderItem={renderHostOwner({
        request_status: -1,
      })}
    />
  )
}

export const CoOwnerList = ({
  Header = ListHeader,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
    status: 'status',
  },
}) => {
  const t = useTranslate()

  const { container } = useContext(HostContext)

  const location = useLocation()

  const { current } = useContext(NavigationContext)

  const refreshToken = useMemo(
    () =>
      JSON.stringify({
        token: _.get(location.state, 'refreshToken'),
        dependencies: [current],
      }),
    [current, location.state]
  )

  const [id, prop] = [getId(container), getType(container)]

  const status = useSearchParam(filter_actions.status)

  const sortBy = useSearchParam(filter_actions.sortBy)

  const [pager_widget] = usePaginationHelper({
    onInit: ({ onSortChange = Null, onFilterChange = Null }) => {
      onFilterChange({
        status,
      })
      onSortChange(sortBy)
    },
    pagerInfo: {
      apiInfo: host_getsByHost_api,
      query: bindQueryParams([
        { id },
        {
          prop,
        },
      ]),
      renderItem: renderHostOwner({
        request_status: 1,
      }),
      RenderEntity: React.Fragment,
    },
    withValues: (values) => ({
      ...values,
      owner_type: prop,
      request_status: 1,
    }),
    withKey: ({ sortBy, keyword, filterBy }) =>
      [id, sortBy, keyword, JSON.stringify(filterBy), refreshToken].join('/'),
    filter_params: {
      owner_id: id,
      owner_type: prop,
    },
    query_params: filter_actions,
    dependencies: [id, prop],
    sortHeader: t('list of co-owners'),
    SortByWidget: HostsSortBy,
    AdvancedFilter: HostsOwnerAdvancedFilter,
  })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header title="co-owners" />
      <HostPendingList />
      {pager_widget}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(WorkspaceContext.Consumer, ({ organization }) => ({
    container: organization,
  })),
  branch(({ container }) => !!!getId(container), renderNothing),
  pure
)(
  nest(
    ({ container, children }) => (
      <HostProvider
        container={container}
        autoRefreshKey="refreshToken">
        {children}
      </HostProvider>
    ),
    CoOwnerList
  )
)
