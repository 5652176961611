import React, {lazy, Suspense} from 'react'

const MainLazy = lazy(() =>
  import('./Main')
)

const Main = props => (
  <Suspense fallback={null}>
    <MainLazy {...props} />
  </Suspense>
)

export default Main
