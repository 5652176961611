import { Skeleton } from 'antd'
import React from 'react'

const renderPagingLoading = () =>
  true ? (
    renderLoading()
  ) : (
    <div className="w-full h-screen flex justify-center">
      <Skeleton
        className="p-3"
        active
      />
    </div>
  )

export const renderLoading = () => (
  <div className="w-full flex justify-center">
    <Skeleton
      className="p-3"
      active
    />
  </div>
)

export default renderPagingLoading
