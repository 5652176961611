import {Input, Modal} from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React, {useMemo, useState} from 'react'
import useToggle from 'react-use/lib/useToggle'
import {notifyOnError} from 'views/AppLocals/factory/createErrorEvent'
import {NotificationActionTypes, successNotify,} from 'views/AppLocals/factory/createNotification'
import AsyncAction from '../../modules/asyncCache/components/AsyncAction'
import {renderIf} from "../../views/Shared";

export const PopupConfirm = ({
    children,
    title = '',
    inputType = 'text',
    labelField,
    inputFieldName,
    inputDefaultValue = null,
    PlaceHolderComponent = () => <div>PLACEHOLDER</div>,
    apiInfo,
    query,
    initialValues = {},
    handleOnChange,
}) => {
  const t = useTranslate()
  const [isToggle, toggle] = useToggle()
  const [value, setValue] = useState(inputDefaultValue || null)

  const inputField = useMemo(() => {
    switch (inputType) {
      case 'text':
      default:
        return (
          <Input
            type={inputType}
            defaultValue={inputDefaultValue}
            onChange={(e) => {
              setValue(e.target.value)
              if (handleOnChange) {
                handleOnChange(e)
              }
            }}
          />
        )
    }
  }, [])

  return (
    <React.Fragment>
      <div
        className="flex items-center justify-center cursor-pointer"
        onClick={toggle}>
        <PlaceHolderComponent/>
      </div>

      <AsyncAction
        onSuccess={() => {
          successNotify(NotificationActionTypes.INFORMATION, t)
        }}
        onError={notifyOnError(t)}
        apiInfo={apiInfo}
        query={query}
        values={{
          ...initialValues,
          [inputFieldName]: value,
        }}>
        {({handleAsyncAction}) => (
          <Modal
            title={
              <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                  {t(title)}
              </div>
            }
            className="custom-modal"
            destroyOnClose
            visible={isToggle}
            onCancel={toggle}
            onOk={() => {
              handleAsyncAction()
              toggle()
            }}
            okText={t('save')}
            cancelText={t('close')}
            okButtonProps={{
                type: 'primary',
                className: 'button-rounded-md no-border',
            }}
            cancelButtonProps={{
                className: 'button-rounded-md no-border',
            }}>
            <div className="w-full flex flex-col gap-1 mb-6">
                {renderIf(
                    labelField,
                    <span className="font-medium text-sm text-color-400 uppercase">{t(labelField)}</span>
                )}
                {inputField}
            </div>
          </Modal>
        )}
      </AsyncAction>
    </React.Fragment>
  )
}
