import LazyComponent from 'components/LazyComponent'
import React, {useCallback, useState} from 'react'

export const LazyList = ({
  children,
  placeholder,
                           reversed,
                           withItem = (item, params) => ({}),
}) => {
  const [current, setCurrent] = useState(0)

  const onVisible = useCallback(
    (index) => () => {
      setCurrent(index + 1)
    },
    []
  )

  return children.map
    ? children.map((item, index) => {
      if (index > current) return null
      if (!!!item) return null
        return (
          <LazyComponent
            key={index}
            reversed={reversed}
            placeholder={placeholder}
            onVisible={onVisible(index)}
            bottom={index === 0 ? 0 : 200}
            {...(withItem(item, {
              index,
              current,
              setCurrent,
              notifyChanges: onVisible(index),
            }) ?? {})}>
            {item}
          </LazyComponent>
        )
      })
    : children
}
