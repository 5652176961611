import {Avatar} from 'antd'
import {organization_getOrganizationsByUser_Api} from 'apis'
import {FollowButton} from 'components/Feed/FollowButton'
import getAvatar from 'helpers/getAvatar'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import Translate from 'modules/local/Translate'
import React, {useMemo} from 'react'
import {renderLoading} from 'views/AppLocals/functions/renderers/renderLoading'
import {bindQueryParam} from 'views/AppLocals/functions/routerHelper'
import getTitle from "../../../../helpers/getTitle";
import {Link} from "react-router-dom";
import getLinkToDetail from "../../../../helpers/getLinkToDetail";
import {OrgType} from "../../Organization/components/OrgType";
import {FaWarehouse} from "react-icons/fa6";


const renderItem = (item, index) => {
  return (
    <Translate key={index}>
      {(t) => (
        <div
          className="w-full p-2 flex flex-col gap-1 border border-color-50 rounded-lg hover:shadow-items cursor-pointer">
          <div className="flex items-center gap-2">
            <Avatar
              size={30}
              icon={
                <FaWarehouse size={14} className="text-primary-600"/>
              }
              src={getAvatar(item)}
              className="flex flex-center bg-primary-50 rounded-md"
            />
            <div className="flex flex-col flex-1 truncate">
              <Link to={getLinkToDetail(item)}
                    className="flex-1 font-bold text-sm text-color-000 no-underline truncate">
                {getTitle(item)}
              </Link>
              <OrgType item={item} className="text-xs text-color-400 italic leading-tight"/>
            </div>
          </div>
          <div className="text-right">
            <FollowButton
              size="small"
              item={item}
            />
          </div>
        </div>
      )}
    </Translate>
  )
}
const UserOrganizations = ({user_id, ...props}) => {
  const apiInfo = organization_getOrganizationsByUser_Api
  const refreshKey = useMemo(
    () => [apiInfo.path.replace(':id', user_id)].join('/'),
    [user_id]
  )

  return (
    <LazyPagination
      sample={10}
      key={refreshKey}
      apiInfo={apiInfo}
      refreshKey={refreshKey}
      renderLoading={renderLoading}
      renderItem={renderItem}
      query={bindQueryParam({id: user_id})}
      {...props}
    />
  )
}

export default UserOrganizations
