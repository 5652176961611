import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import {
  article_getArticleGroups_Api,
  auth_fetchSuggestionChannelsByAuthCategories_Api,
  auth_fetchSuggestionDatasetByAuthCategories_Api,
  auth_fetchSuggestionEventsByAuthCategories_Api,
  auth_fetchSuggestionOrganizationsByAuthCategories_Api,
  auth_fetchSuggestionProductByAuthCategories_Api,
  auth_fetchSuggestionUsersByAuthCategories_Api,
  auth_getMyFollowingChannels_Api,
  auth_getMyFollowingDataset_Api,
  auth_getMyFollowingEvents_Api,
  auth_getMyFollowingOUser_Api,
  auth_getMyJoinedOrganization_Api
} from 'apis'
import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import {LoginContext} from 'components/LoginContext'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import ArticleResult from 'views/Discovery/components/ArticleResult'
import MyEvents from 'views/AppLocals/Event/MyEvents'
import {bindQueryParam} from 'views/AppLocals/functions/routerHelper'
import LoadingPage from 'views/LoadingPage'
import {renderLoading} from 'views/MainPage/functions/renderPagingLoading'
import SearchContext from 'views/Search/SearchContext'
import {SearchTypes} from 'views/Search/SearchProvider'
import {deepTranslate, getResponseItem, Null, renderTrue} from 'views/Shared'
import MyArticles from '../MainPage/MySpace/MyArticles'
import MyChannels from '../MainPage/MySpace/MyChannels'
import {DiscoveryRadioGroup, getDiscoveryRadioOption,} from './components/DiscoveryRadioGroup'
import {createFetchRecentByTypeApi} from './DiscoveryProvider'
import createSearchResultComponent from './functions/createSearchResultComponent'

export const RecommendationFilterTypes = Object.freeze({
  JOINED: 'joined',
  FOLLOWING: 'following',
  SUGGESTION: 'suggestion',
  LASTED: 'lasted',
  MINE: 'mine',
  RECENT: 'recent',
})

const renderItem = (item, index) => {
  if (!item) return null

  return (
    <div
      key={index}
      className="w-full">
      {createSearchResultComponent(item, index)}
    </div>
  )
}

const RecommendationFilter = ({filter, setFilter}) => {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const {searchParams} = useContext(SearchContext)

  const {type} = searchParams || {}

  const filter_options = useMemo(
    () =>
      [
        {
          label: 'popular',
          value: RecommendationFilterTypes.SUGGESTION,
          validated: (search_type) =>
            ![SearchTypes.ARTICLE].includes(search_type),
        },
        {
          label: 'following',
          value: RecommendationFilterTypes.FOLLOWING,
          validated: (search_type) =>
            Boolean(
              login &&
              [SearchTypes.DATASET, SearchTypes.EVENT, SearchTypes.CHANNEL].includes(search_type)
            ),
        },
        {
          label: 'joined',
          value: RecommendationFilterTypes.JOINED,
          validated: (search_type) =>
            Boolean(login && search_type === SearchTypes.ORGANIZATION),
        },
        {
          label: 'lasted-articles',
          value: RecommendationFilterTypes.LASTED,
          validated: (search_type) => search_type === SearchTypes.ARTICLE,
        },
        {
          label: 'mine',
          value: RecommendationFilterTypes.MINE,
          validated: (search_type) =>
            !!login &&
            [
              SearchTypes.ARTICLE,
              SearchTypes.CHANNEL,
              SearchTypes.EVENT,
            ].includes(search_type),
        },
        {
          label: 'recently watched',
          value: RecommendationFilterTypes.RECENT,
          validated: (search_type) =>
            ![
              SearchTypes.KEYWORD,
            ].includes(search_type),
        },
      ].map(getDiscoveryRadioOption),
    [login]
  )

  const options = useMemo(
    () =>
      Array.from(filter_options)
        .filter(({validated = renderTrue}) => validated(type))
        .map(deepTranslate(t)),
    [t, type, filter_options]
  )

  const validValue = useMemo(() => {
    const item =
      _.find(options, {
        value: filter,
      }) || _.first(options)
    return _.get(item, 'value', item)
  }, [filter, options])

  return (
    <DiscoveryRadioGroup
      name="filter"
      options={options}
      value={validValue}
      onChange={(event) => {
        setFilter(_.get(event, 'target.value'))
      }}
    />
  )
}

export const Selector = ({selector, children, equalityFn}) => {
  const result = useSelector(selector, equalityFn)
  return children(result)
}

const apis = {
  suggestion: {
    channel: auth_fetchSuggestionChannelsByAuthCategories_Api,
    event: auth_fetchSuggestionEventsByAuthCategories_Api,
    organization: auth_fetchSuggestionOrganizationsByAuthCategories_Api,
    user: auth_fetchSuggestionUsersByAuthCategories_Api,
    product: auth_fetchSuggestionProductByAuthCategories_Api,
    dataset: auth_fetchSuggestionDatasetByAuthCategories_Api,
  },
  following: {
    channel: auth_getMyFollowingChannels_Api,
    event: auth_getMyFollowingEvents_Api,
    dataset: auth_getMyFollowingDataset_Api,
    organization: auth_getMyJoinedOrganization_Api,
    user: auth_getMyFollowingOUser_Api,
  },
  lasted: {
    article: article_getArticleGroups_Api,
  },
}

const mine_tabs = {
  [SearchTypes.CHANNEL]: {
    Component: MyChannels,
  },
  [SearchTypes.ARTICLE]: {
    Component: MyArticles,
  },
  [SearchTypes.EVENT]: {
    Component: MyEvents,
  },
}

export const Recommendation = ({
                                 keyword,
                                 type = '',
                                 categories,
                                 default_filter = RecommendationFilterTypes.SUGGESTION,
                               }) => {
  const [currentFilter, setFilter] = useState(default_filter)

  const refreshKey = [type, currentFilter]

  const content = useMemo(() => {
    let params = {}

    switch (currentFilter) {
      case RecommendationFilterTypes.SUGGESTION:
        params = {
          query: bindQueryParam({
            type,
          }),
          apiInfo: _.get(apis.suggestion, type),
        }
        break
      case RecommendationFilterTypes.RECENT:
        params = createFetchRecentByTypeApi(type)
        break
      case RecommendationFilterTypes.FOLLOWING:
        params = {
          apiInfo: _.get(apis.following, type),
          values: {
            filter: 'follow',
          },
        }
        break
      case RecommendationFilterTypes.LASTED:
        return (
          <Async
            {...{
              apiInfo: _.get(apis.lasted, type),
            }}>
            {({response, isLoading}) => {
              if (!!isLoading) {
                return <LoadingPage/>
              }
              const data = getResponseItem(response, 'data.data')
              return <ArticleResult {...data} />
            }}
          </Async>
        )
      case RecommendationFilterTypes.MINE:
        const {Component = Null} = _.get(mine_tabs, type) ?? {}
        return (
          <div className="???????????">
            <Component/>
          </div>
        )
      case RecommendationFilterTypes.JOINED:
        params = {
          apiInfo: _.get(apis.following, type),
          values: {
            filter: 'member',
          },
        }
        break
      default:
        break
    }
    return (
      <LazyPagination
        {...{renderItem, refreshKey, renderLoading}}
        {...params}
      />
    )
  }, [type, refreshKey, currentFilter])

  if (!_.includes(Object.values(SearchTypes), type)) {
    return null
  }

  if (
    _.every(
      [apis.suggestion, apis.following, apis.lasted],
      (data) => !_.has(data, type)
    )
  ) {
    return <EmptyHolder/>
  }

  return (
    <div className="background w-full justify-center md:p-0">
      <div
        className={classNames(
          'flex items-center w-full mb-3',
          'RoundedBorderRadioGroup'
        )}>
        <RecommendationFilter
          setFilter={setFilter}
          filter={currentFilter}
        />
      </div>
      <ErrorBoundary>
        {content}
      </ErrorBoundary>
    </div>
  )
}
