import React from "react";

export const Pig = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 498.83 498.83"
		>
			<g fill="#fe99a0">
				<path d="M223.506 328.494l-71.228 19.11 13.852 33.735 21.44 44a4.226 4.226 0 003.8 2.37h31.52c2.25 0 4.1-1.76 4.22-4.01l2.96-58.24zM394.34 356.368l-6.26-21.41c.021-.58-8.656-19.724-8.656-19.724l-55.641 17.629 6.227 27.425.82 26.08-15.26 36.23c-1.03 2.43.75 5.11 3.39 5.11h29.67a7.33 7.33 0 005.94-3.04l36.74-50.79a20.199 20.199 0 003.03-17.51zM113.24 146.125v-8.924c0-6.766-5.485-12.25-12.25-12.25H59.818a4.619 4.619 0 00-4.619 4.619v14.143c0 16.565 13.429 29.995 29.994 29.995l18.942 5.755 12.096-14.914zM467.604 173.174a7.47 7.47 0 01-5.077-1.982l-22.982-21.156c-4.5-4.041-7.213-9.913-7.377-16.05a22.565 22.565 0 016.572-16.489c8.761-8.759 23.015-8.76 31.775-.001l.899.899c1.373 1.374 3.2 2.131 5.143 2.131s3.769-.757 5.142-2.13c1.374-1.374 2.131-3.201 2.131-5.144s-.757-3.769-2.13-5.142l-11.878-11.877c-6.315-6.318-6.315-16.596 0-22.913a7.496 7.496 0 0110.606-.001 7.5 7.5 0 01.001 10.606 1.204 1.204 0 000 1.701l11.877 11.876c4.206 4.206 6.523 9.8 6.523 15.749s-2.316 11.543-6.523 15.75c-4.206 4.206-9.8 6.523-15.749 6.523s-11.543-2.316-15.75-6.523l-.899-.899c-2.91-2.911-7.649-2.913-10.562 0a7.407 7.407 0 00-2.185 5.481 7.4 7.4 0 002.475 5.354l23.049 21.218a7.498 7.498 0 01.438 10.597 7.476 7.476 0 01-5.519 2.422z"></path>
			</g>
			<path
				fill="#ffc4c8"
				d="M491.97 358.728a18.863 18.863 0 01-1.87 17.52l-33.61 49.97c-.63.93-1.68 1.49-2.8 1.49h-40.37c-2.35 0-3.98-2.36-3.15-4.56l23.104-61.296a3.747 3.747 0 00-1.5-4.487l-36.795-23.337a4.703 4.703 0 00-4.662-.215l-2.238 1.145-19.65 10.06a217.744 217.744 0 01-38.42 15.27c-19.87 5.79-40.4 8.69-60.95 8.69-13.04 0-26.09-1.17-38.99-3.52a217.364 217.364 0 01-38.65-10.773c-3.716-1.416-7.918-.373-10.506 2.647L168.6 371.698c-.68.79-1.14 1.76-1.31 2.79l-1.16 6.85-7.21 42.56a4.573 4.573 0 01-4.51 3.81h-36c-2.63 0-4.73-2.22-4.57-4.86l4.651-76.333a5.709 5.709 0 00-4.862-5.994L38.47 329.398H9.28a9.28 9.28 0 01-9.28-9.28v-36a9.28 9.28 0 019.28-9.28h21.26l28.27-59.48a167.908 167.908 0 0146.08-58.48 167.834 167.834 0 0149.55-27.71c17.85-6.31 36.76-9.6 55.96-9.6h197.59c12.89 0 25.31 4.76 34.9 13.37l40.5 36.39a36.982 36.982 0 0112.27 27.52v72.04c0 17.753-6.43 34.89-18.046 48.251-.912 1.049-1.19 2.505-.676 3.796z"
			></path>
			<path
				fill="#fdb4ba"
				d="M38.016 298.65a38.588 38.588 0 0034.852-22.024L90.67 239.17a167.908 167.908 0 0146.08-58.48 167.834 167.834 0 0149.55-27.71c17.85-6.31 36.76-9.6 55.96-9.6h197.59a52.208 52.208 0 0118.388 3.349l-15.348-13.791a52.223 52.223 0 00-34.9-13.37H210.4c-19.2 0-38.11 3.29-55.96 9.6a167.834 167.834 0 00-49.55 27.71 167.908 167.908 0 00-46.08 58.48l-28.27 59.48H9.28a9.28 9.28 0 00-9.28 9.28v14.532z"
			></path>
			<path
				fill="#fe99a0"
				d="M23.039 274.838v25.235c0 4.143 3.358 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-25.238a7.494 7.494 0 00-4.281-6.767l-3.218 6.77z"
			></path>
			<path
				fill="#918291"
				d="M475.5 397.958l-19.01 28.26c-.63.93-1.68 1.49-2.8 1.49h-40.37c-2.35 0-3.98-2.36-3.15-4.56l9.49-25.19zM373.64 398.298l-19.07 26.37a7.33 7.33 0 01-5.94 3.04h-29.67c-2.64 0-4.42-2.68-3.39-5.11l10.38-24.64zM228.42 397.968l-1.31 25.73a4.227 4.227 0 01-4.22 4.01h-31.52c-1.62 0-3.09-.92-3.8-2.37l-12.97-26.61 53.61-.77zM163.54 396.638l-4.62 27.26a4.573 4.573 0 01-4.51 3.81h-36c-2.63 0-4.73-2.22-4.57-4.86l1.6-26.26h47.83z"
			></path>
			<path
				fill="#fe99a0"
				d="M213 173.918v24.235c0 6.774-5.491 12.265-12.265 12.265H184.96c-16.569 0-30-13.431-30-30v-14.13c0-2.56 2.07-4.62 4.62-4.62h41.17c6.765 0 12.25 5.485 12.25 12.25z"
			></path>
			<path
				fill="#918291"
				d="M96.206 274.52a7.5 7.5 0 01-7.5-7.5v-15.528c0-4.143 3.358-7.5 7.5-7.5s7.5 3.357 7.5 7.5v15.528a7.5 7.5 0 01-7.5 7.5z"
			></path>
		</svg>
	);
}

export default Pig;
