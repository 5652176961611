import React from "react";

export const Shrimp = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#f1562e"
				d="M351.844 331.365a7.65 7.65 0 01-7.407-5.836c-.337-1.381-7.872-34.216 25.302-67.39a7.61 7.61 0 1110.762 10.762c-26.839 26.839-21.504 52.021-21.264 53.08.996 4.083-1.514 8.171-5.597 9.167a7.55 7.55 0 01-1.796.217z"
			></path>
			<path
				fill="#f1562e"
				d="M366.321 337.073a7.609 7.609 0 01-7.534-8.742c.211-1.406 5.674-34.648 48.942-52.786a7.61 7.61 0 115.884 14.036c-35.462 14.864-39.737 40.752-39.776 41.011a7.612 7.612 0 01-7.516 6.481zM323.682 332.548a7.656 7.656 0 01-7.061-4.72c-.545-1.314-13.022-32.605 14.677-70.471a7.607 7.607 0 0110.634-1.649 7.608 7.608 0 011.649 10.634c-22.408 30.635-13.279 54.702-12.879 55.712 1.61 3.882-.243 8.306-4.125 9.917a7.577 7.577 0 01-2.895.577z"
			></path>
			<path
				fill="#fd7e42"
				d="M295.64 173.048h93.847c67.662 0 122.513 54.851 122.513 122.513 0 59.018-42.077 109.648-100.099 120.445l-47.732 8.883-20.224-52.508 56.692-34.638a28.615 28.615 0 0013.696-24.418c0-15.803-12.811-28.615-28.615-28.615h-91.23zM77.401 282.279c-33.573 0-58.584-10.409-70.269-30.288-13.674-23.263-7.555-55.709 17.23-91.359 23.606-33.954 61.678-67.39 107.202-94.148 72.035-42.341 147.398-59.079 191.994-42.643a7.608 7.608 0 014.509 9.771 7.607 7.607 0 01-9.771 4.509c-39.868-14.693-111.809 1.979-179.02 41.484C52.331 130.71-2.175 206.121 20.253 244.279c13.209 22.472 53.096 28.869 104.099 16.69a7.61 7.61 0 113.534 14.804c-18.286 4.366-35.268 6.506-50.485 6.506z"
			></path>
			<path
				fill="#fd7e42"
				d="M118.916 275.417a7.58 7.58 0 01-1.108-.081c-59.648-8.699-98.293-33.668-103.373-66.792-4.091-26.673 13.604-54.549 49.824-78.491 34.498-22.804 82.242-39.772 134.438-47.777 82.589-12.67 158.796-.321 194.141 31.455a7.61 7.61 0 01.571 10.747 7.608 7.608 0 01-10.746.571c-31.597-28.406-104.598-39.55-181.658-27.73-50.068 7.68-95.651 23.813-128.353 45.431-30.53 20.181-46.266 43.322-43.173 63.487 3.952 25.765 38.64 46.471 90.526 54.039a7.61 7.61 0 01-1.089 15.141z"
			></path>
			<path
				fill="#fc5327"
				d="M446.909 187.321c25.406 22.448 41.432 55.267 41.432 91.835 0 59.018-42.077 109.647-100.098 120.445l-31.552 5.872 7.478 19.417 47.732-8.883C469.923 405.209 512 354.579 512 295.562c0-46.911-26.37-87.657-65.091-108.241zM400.595 229.395c10.801 3.732 27.686-16.381 38.954-45.68-13.119-5.881-27.462-9.516-42.54-10.43-7.986 28.362-6.748 52.54 3.586 56.11z"
			></path>
			<path
				fill="#fc5327"
				d="M488.288 223.12c-26.044 17.939-41.79 39.273-35.576 48.935 5.869 9.127 29.345 4.742 54.792-9.472a122.01 122.01 0 00-19.216-39.463zM506.823 330.758c-26.956-7.845-49.722-6.951-53.434 2.951-3.504 9.345 11.058 23.8 34.116 35.325a122.43 122.43 0 0019.318-38.276z"
			></path>
			<path
				fill="#ed4322"
				d="M488.109 271.689c6.14-2.312 12.697-5.365 19.396-9.107a122.02 122.02 0 00-19.216-39.463 164.828 164.828 0 00-9.974 7.435 121.884 121.884 0 019.794 41.135zM479.181 325.642a122.622 122.622 0 01-15.817 27.648c6.003 5.365 14.293 10.821 24.141 15.743a122.443 122.443 0 0019.319-38.276c-10.056-2.925-19.526-4.634-27.643-5.115z"
			></path>
			<path
				fill="#fd7e42"
				d="M329.825 357.705l-104.786 21.592c-6.329 1.304-10.013 7.936-7.777 13.999a10.562 10.562 0 0010.238 6.901l51.248-1.598c2.288-.071 3.165 2.953 1.194 4.116l-47.192 27.858c-9.601 5.667-4.159 20.421 6.823 18.497l53.978-9.46c2.254-.395 3.551 2.474 1.765 3.905l-40.01 32.064a10.56 10.56 0 00-3.304 11.896c2.236 6.063 9.345 8.714 15.005 5.595l93.708-51.625c15.792-8.7 22.854-27.637 16.614-44.553l-5.938-16.097c-6.24-16.914-23.907-26.729-41.566-23.09z"
			></path>
			<path
				fill="#fc5327"
				d="M377.328 396.895l-5.938-16.097c-5.183-14.052-18.253-23.195-32.653-23.806l.767 2.079c6.24 16.916-.822 35.852-16.614 44.553l-82.11 45.235 52.769-9.248c2.254-.395 3.551 2.474 1.765 3.905l-40.01 32.064A10.56 10.56 0 00252 487.476c2.236 6.063 9.345 8.714 15.005 5.595l93.708-51.625c15.793-8.699 22.855-27.635 16.615-44.551z"
			></path>
			<path
				fill="#fd7e42"
				d="M329.068 229.946c0 45.704-4.695 82.755-50.4 82.755S70.536 275.65 70.536 229.946s162.428-82.755 208.132-82.755 50.4 37.051 50.4 82.755z"
			></path>
			<path
				fill="#fc5327"
				d="M279.204 147.205c4.066 13.862 4.858 31.117 4.858 49.828 0 45.704-4.695 82.755-50.4 82.755-29.658 0-108.454-15.605-160.135-39.048 22.277 40.604 163.096 71.961 205.142 71.961 45.704 0 50.4-37.051 50.4-82.755-.001-45.521-4.672-82.445-49.865-82.741z"
			></path>
			<circle cx="159.125" cy="222.039" r="20.144" fill="#554e57"></circle>
			<path
				fill="#453e43"
				d="M164.853 202.733c.538 1.817.838 3.737.838 5.728 0 11.125-9.019 20.144-20.144 20.144-1.992 0-3.911-.3-5.728-.838 2.469 8.333 10.172 14.416 19.306 14.416 11.125 0 20.144-9.019 20.144-20.144 0-9.134-6.083-16.837-14.416-19.306z"
			></path>
		</svg>
	);
}

export default Shrimp;
