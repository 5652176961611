import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../../../LoadingPage";

const PremiumChartHolderLazy = Loadable({
  loader: () => import('./PremiumChartHolder'),
  loading: LoadingPage
})

const PremiumChartHolder = (props) => (
  <PremiumChartHolderLazy {...props} />
)

export default PremiumChartHolder
