import {getId, getType} from 'apis/model/base'
import _ from 'lodash'
import React, {useEffect, useRef} from 'react'
import {replaceAll} from 'views/Shared'
import {decryptMentionTitle, mark_as_mention} from './Mention'

export function getText(code, map) {
  let text = code
  Object.keys(map ?? {}).forEach((name) => {
    const value = _.get(map, name)
    text = replaceAll(text, name, value)
  })
  return text
}

export function get_mention_data(data, reverse = false) {
  let result = {}
  const mentions = Array.from(data ?? [])
  _.forEach(mentions, (mention, index) => {
    const {owner, owner_key} = mention ?? {}
    const owner_id = getId(owner)
    const owner_type = getType(owner)
    const [__, link] = decryptMentionTitle(owner_key) ?? [,]
    const key = `@${owner_type}:${owner_id}:${owner_key}`
    const placeholder = mark_as_mention(link, {index, owner_id, owner_type})
    if (reverse) {
      result[key] = placeholder
    } else {
      result[placeholder] = key
    }
  })
  return result
}

const MentionDataContext = React.createContext({})

export const MentionDataProvider = ({initialValue, children, ...props}) => {
  const dataRef = useRef()
  const push = (values) => {
    dataRef.current = {
      ...(dataRef.current ?? {}),
      ...(values ?? {}),
    }
  }
  useEffect(() => {
    dataRef.current = initialValue
  }, [initialValue])
  return (
    <MentionDataContext.Provider
      value={{
        ...props,
        push,
        toString: (description) => {
          return getText(description, dataRef.current)
        },
      }}>
      {children}
    </MentionDataContext.Provider>
  )
}

export default MentionDataContext
