import { Input, Tooltip } from 'antd'
import { organization_Edit_Api } from 'apis'
import { getId } from 'apis/model/base'
import { MegaRadioGroup } from 'components/form/fields/MegaRadioGroup'
import { ROOT_URL } from 'envs/_current/config'
import _ from 'lodash'
import { useAppConfig } from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React, { useCallback, useContext, useMemo } from 'react'
import { compose, mapProps, nest, pure } from 'recompose'
import { PhotoUploadComposite } from 'views/AppLocals/custom/PhotoField'
import SelectDataSource from 'views/AppLocals/custom/SelectDataSource'
import { AppEntities } from 'views/AppLocals/enums'
import createEditableForm from 'views/AppLocals/factory/createEditableForm'
import { createControlledFormField } from 'views/AppLocals/factory/createFormField'
import {
  requiredOneOf,
  requiredString,
  yupShape,
} from 'views/AppLocals/factory/createValidationSchema'
import { bindQueryParam } from 'views/AppLocals/functions/routerHelper'
import OrganizationContainerContext from 'views/AppLocals/Organization/OrganizationContainerContext'
import * as Yup from 'yup'
import Translate from "../../../../modules/local/Translate";
import {deepTranslate} from "../../../Shared";
import {service_type_options} from "../../../../conts/service_type_options";
import AppLogoIcon from "../../../../components/icons/AppLogoIcon";
import {ChannelIcon} from "../../../../components/icons/ChannelIcon";
import {createValue} from "../../../../components/form/utils";
import {MultipleSelect} from "../../../../components/form/fields/MultipleSelect";
import {FiDatabase} from "react-icons/fi";
import {AiOutlineShop} from "react-icons/ai";
import {BsBox2Fill, BsDatabaseFill, BsMegaphoneFill} from "react-icons/bs";

const formFields = [
  { name: 'title' },
  { name: 'service_type' },
  { name: 'idname' },
  { name: 'avatar' },
  { name: 'org_type' },
  { name: 'org_types' },
  { name: 'description' },
  { name: 'cover_photo' },
  { name: 'avatar_preview' },
  { name: 'cover_photo_preview' },
  { name: 'privacy', defaultValue: 1 },
]

const formSchema = [
  {
    title: 'organization type',
    children: [
      {
        name: 'org_type',
        component: createControlledFormField({
          Component: SelectDataSource,
          placeholder: 'Select...',
          withValues: (values) => ({
            dataSource: _.get(values, 'org_types'),
            defaultValue: _.get(values, 'org_type'),
          }),
        }),
      },
    ],
  },
  {
    children: [
      {
        name: 'avatar',
        component: mapProps(({ form, onChange }) => {
          const values = _.get(form, 'values')
          return {
            values,
            onChange,
            title: _.get(values, 'title'),
            fallback_cover: undefined,
          }
        })(PhotoUploadComposite),
      },
    ],
  },
  {
    title: 'title',
    children: [
      {
        name: 'title',
        component: compose(
          withTranslate,
          mapProps(({ name, onChange, value, translate }) => ({
            name,
            value,
            onChange,
            maxLength: 160,
            showCount: true,
            style: { borderRadius: '0.375rem' },
            placeholder: translate('name of your company'),
          }))
        )(Input),
      },
    ],
  },
  {
    title: 'provided services',
    children: {
      name: 'service_type',
      component: compose(
        mapProps(
          ({
             onChange,
             value = '0000',
             form
           }) => {
            let _valueArray = [];
            if ((value).substring(0, 1) === '1') _valueArray.push("channel");
            if ((value).substring(1, 2) === '1') _valueArray.push("dataset");
            if ((value).substring(2, 3) === '1') _valueArray.push("product");
            if ((value).substring(3, 4) === '1') _valueArray.push("purchase");

            return ({
              options: service_type_options,
              value: _valueArray,
              size: "large",
              renderItem: (props) => {
                const { value, label } = props;
                let _icon = <AppLogoIcon/>;
                if (value === "channel") _icon = <BsMegaphoneFill className="text-color-500" />;
                if (value === "dataset") _icon = <BsDatabaseFill className="text-color-500" />;
                if (value === "product") _icon = <BsBox2Fill className="text-color-500" />;

                return (
                  <Translate>
                    {t => (
                        <div className="flex items-center gap-2 px-2">
                            {_icon}
                            <span className="text-sm text-color-200">
                                {t(label)}
                            </span>
                        </div>
                    )}
                  </Translate>
                );
              },
              onChange: value => {
                let _value = '';
                _value += value.includes("channel") ? '1' : '0';
                _value += value.includes("dataset") ? '1' : '0';
                _value += value.includes("product") ? '1' : '0';
                _value += value.includes("purchase") ? '1' : '0';
                return onChange(
                  createValue(
                    'service_type',
                    _value
                  )
                )
              }
            })
          }
        ),
        pure
      )(MultipleSelect)
    }
  },
    {
        title: 'description',
        children: {
            name: 'description',
            component: compose(
                withTranslate,
                mapProps(
                    ({ onChange, value, translate }) => ({
                        onChange,
                        value,
                        rows: 5,
                        name: 'description',
                        placeholder: translate('Short description about your organization!'),
                    })
                ),
                pure
            )(Input.TextArea)
        }
    },
  {
    title: 'url',
    children: {
      name: 'idname',
      component: compose(
        mapProps(
          ({ onChange, value }) => ({
            onChange,
            value,
            name: 'idname',
            addonBefore:
              'https://.../organization/',
            placeholder:
              'please input name!',
            type: 'text',
            trigger: ['focus'],
            title:
              ROOT_URL +
              '/organization/' +
              value,
            placement: 'topLeft',

            disabled: true
          })
        ),
        pure
      )(nest(Tooltip, Input))
    }
  },
  {
    title: 'privacy',
    children: [
      {
        name: 'privacy',
        component: ({ name, value, onChange, defaultValue }) => (
            <Translate>
              {(t) => (
                  <MegaRadioGroup
                      {...{
                        name,
                        value,
                        onChange,
                        defaultValue,
                      }}
                      options={[
                        {
                          value: 1,
                          label: 'public',
                          description: 'Data content is viewable by everyone, anyone is free to join and can post articles and comment freely on this',
                        },
                        {
                          value: 2,
                          label: 'private',
                          description: 'Only members can view data content, post articles or comments on this',
                        },
                      ].map(deepTranslate(t))}
                  />
              )}
            </Translate>
        ),
      },
    ],
  },
]

const OrganizationSettingOverview = () => {
  const t = useTranslate()

  const { org_types: org_type_options } = useAppConfig()

  const { organization } = useContext(OrganizationContainerContext)

  const id = getId(organization)

  const org_types = Object.keys(org_type_options).map((name) => ({
    value: Number(name),
    label: org_type_options[name],
  }))

  const validationSchema = useMemo(
    () =>
      yupShape({
        title: requiredString(t),
        org_type: requiredOneOf(
          org_types.map(({ value }) => value),
          t,
          Yup.number()
        ),
      }),
    [t]
  )

  const initialized = useCallback(
    ({ avatar, org_type, cover_photo, service_type, ...values }) => ({
      ...values,
      service_type,
      org_type: org_type ? Number(org_type) : undefined,
      avatar_preview: avatar,
      cover_photo_preview: cover_photo,
    }),
    []
  )

  const onPreSubmit = ({
    org_types,
    avatar_preview,
    cover_photo_preview,
    ...values
  }) => ({
    ...values,
  })

  if (_.isEmpty(organization)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    validationSchema,
    item: {
      ...organization,
      org_types,
    },
    query: bindQueryParam({
      id,
    }),
    onPreSubmit,
    initialized,
    apiInfo: organization_Edit_Api,
  })
}

export default OrganizationSettingOverview
