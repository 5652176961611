import React from "react";

export const Buffalo = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#5D5360"
				d="M503.876 335.542a8.052 8.052 0 01-5.715-2.369c-1.198-1.195-29.058-29.851-12.152-82.399 14.009-43.551 3.095-73.358-11.794-86.732-10.437-9.374-23.684-11.858-35.415-6.643-4.096 1.849-8.897-.024-10.731-4.123-1.818-4.103.024-8.905 4.127-10.727 17.771-7.897 37.526-4.389 52.885 9.401 18.676 16.779 32.796 52.857 16.406 103.801-13.739 42.689 7.31 65.04 8.207 65.973 3.111 3.203 3.072 8.338-.103 11.469a8.11 8.11 0 01-5.715 2.349z"
			></path>
			<path
				fill="#6F6571"
				d="M473.43 200.941c0-20.488-25.399-62.481-62.988-74.672-55.75-18.081-152.392 28.954-247.552-3.556l-91.435 12.191c0 20.713-51.13 52.214-67.53 62.008-3.078 1.838-4.547 5.445-3.677 8.923l3.872 15.488c.775 3.101 3.266 5.467 6.414 6.024 11.691 2.069 42.401 6.049 69.117-1.242 35.629 33.173 91.028 59.667 91.028 59.667v133.089l-23.853 5.963a16.253 16.253 0 00-11.478 10.629l-2.296 6.889c-1.481 4.442 1.825 9.029 6.508 9.029h31.222c5.014 0 9.696-2.506 12.477-6.678l8.772-13.159a24.375 24.375 0 003.831-9.951l18.109-122.234c49.832 27.4 134.768 26.962 192.996.507l37.001 70.238v47.072l-19.72 7.251a16.253 16.253 0 00-10.28 10.28l-2.548 7.643c-1.481 4.442 1.826 9.029 6.508 9.029h29.395c5.981 0 11.39-3.554 13.764-9.044l9.674-22.37a32.51 32.51 0 002.671-12.905v-58.015c0-2.128-.396-4.188-1.212-6.154-3.771-9.084-15.043-38.506-15.043-65.578-.003-29.12 16.253-37.872 16.253-76.362z"
			></path>
			<path
				fill="#5D5360"
				d="M119.723 147.199l-2.079 15.524c-.804 6.006 3.08 11.701 9.012 12.939 9.625 2.008 24.772 2.44 40.08-8.094 2.935-2.02 3.738-5.989 1.688-8.904-5.081-7.224-16.491-19.651-35.778-21.542-6.325-.62-12.08 3.778-12.923 10.077z"
			></path>
			<path
				fill="#AEA8AF"
				d="M177.282 85.298c-2.44-12.418-17.049-32.578-47.24-21.419-2.16.799-3.684 3.846-.003 3.589 9.494-.663 19.42-.737 22.353 9.528 7.45 26.076-37.59 34.542-57.57 36.235-13.229 1.121-23.028 9.143-23.367 21.674-.167 6.187 6.149 13.454 17.947 14.673 17.78 1.836 36.841-3.078 43.236-5.487l.113-.042c15.238-5.758 50.627-27.728 44.531-58.751z"
			></path>
			<g fill="#4B3F4E">
				<path d="M139.558 451.371h31.222c5.014 0 9.696-2.506 12.477-6.678l8.772-13.159-21.352-12.674-23.853 5.963a16.253 16.253 0 00-11.478 10.629l-2.296 6.889c-1.48 4.443 1.826 9.03 6.508 9.03zM443.967 417.168l-19.72 7.251a16.253 16.253 0 00-10.28 10.28l-2.548 7.643c-1.481 4.442 1.826 9.029 6.508 9.029h29.394c5.981 0 11.39-3.554 13.764-9.044l5.469-12.647-22.587-12.512z"></path>
			</g>
			<g fill="#5D5360">
				<path d="M79.65 226.105c35.629 33.173 91.028 59.667 91.028 59.667v-18.996c0-6.022-3.33-11.551-8.653-14.368l-49.338-26.106c-7.282-3.853-15.716-4.82-23.665-2.652a522.667 522.667 0 01-9.372 2.455zM216.119 286.884l-2.148 12.467c49.832 27.4 134.768 26.962 192.996.507l-5.283-14.341c-2.748-7.461-10.462-11.88-18.264-10.351-23.444 4.596-76.516 11.358-148.414-1.491-8.866-1.584-17.358 4.334-18.887 13.209z"></path>
			</g>
			<circle cx="81.28" cy="175.03" r="8.128" fill="#4B3F4E"></circle>
			<path
				fill="#C9C6CA"
				d="M130.042 63.879c-1.302.482-2.351 1.774-2.19 2.673 15.396-4.034 29.595-2.197 34.699 9.258 3.81 8.551 4.826 25.06-14.223 37.505-17.27 11.283-54.099 10.159-58.264 36.3 17.59 1.65 36.258-3.146 42.573-5.526l.113-.042c15.239-5.757 50.628-27.727 44.532-58.75-2.44-12.417-17.049-32.577-47.24-21.418z"
			></path>
			<path
				fill="#5D5360"
				d="M3.925 196.913c-3.078 1.838-4.547 5.445-3.677 8.923l3.872 15.488c.775 3.101 3.266 5.467 6.414 6.024 7.31 1.293 22.056 3.334 38.61 2.9-4.438-19.004-12.883-33.741-24.752-46.24-8.305 5.545-15.805 10.12-20.467 12.905z"
			></path>
			<path
				fill="#4B3F4E"
				d="M11.175 200.433c0-2.329-1.204-4.431-3.134-6.005a562.615 562.615 0 01-4.116 2.485c-3.078 1.838-4.547 5.445-3.677 8.923l.823 3.294c5.686-.537 10.104-4.203 10.104-8.697z"
			></path>
		</svg>
	);
}

export default Buffalo;
