import {getId, getType} from 'apis/model/base'
import {ChannelIcon} from 'components/icons/ChannelIcon'
import {organizationPermissionCodes} from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React from 'react'
import {compose, fromRenderProps, nest, withProps} from 'recompose'
import {DelayRender} from 'views/Discovery/DelayRender'
import {createCheckPermissions, invalidPermissions,} from 'views/AppLocals/factory/createCheckPermissions'
import {SettingsMenu} from 'views/AppLocals/factory/createSettingLayout'
import DefaultTemplate from 'views/AppLocals/Templates/DefaultTemplate'
import {renderFalse, renderOwnChild} from 'views/Shared'
import CustomSiderMenu from 'views/Shared/components/CustomSiderMenu'
import {Queries} from 'views/Shared/enums/Queries'
import {useMenuHandler} from 'views/Shared/handlers/useMenuHandler'
import WorkspaceDatasetList from '../Container/WorkspaceDatasetList'
import WorkspaceContext from '../WorkspaceContext'
import {BsDatabase} from "react-icons/bs";

const ItemKeys = Object.freeze({
  DATASET: 'dataset',
})

const routeEntities = [
  {
    key: ItemKeys.DATASET,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageDataset,
    ])(WorkspaceDatasetList),
  },
].map(({key, ...rest}) => ({
  key,
  path: key,
  ...rest,
}))

const with_menu_items = ({isDisabled = renderFalse}) => [
  {
    key: ItemKeys.DATASET,
    label: 'dataset',
    icon: <BsDatabase size={16}/>,
    disabled: false,
  },
]

const WorkspaceDataset = ({owner_id, owner_type}) => {
  const {activeKey, Component, onMenuSelect} = useMenuHandler({
    routes: routeEntities,
    actionName: Queries.REL,
  })

  return (
    <DefaultTemplate
      renderMenu={() => (
        <CustomSiderMenu
          deps={[activeKey]}
          Wrapper={renderOwnChild}>
          <PermissionContext.Consumer>
            {(permissions) => (
              <SettingsMenu
                items={with_menu_items({
                  isDisabled: invalidPermissions(permissions),
                })}
                onSelect={onMenuSelect}
                defaultSelectedKeys={[activeKey]}
              />
            )}
          </PermissionContext.Consumer>
        </CustomSiderMenu>
      )}>
      <div className="space-y-3 px-3 md:px-0 md:pr-3">
        <Component/>
      </div>
    </DefaultTemplate>
  )
}

export default nest(
  withProps({lazy: true})(DelayRender),
  compose(
    fromRenderProps(WorkspaceContext.Consumer, ({organization}) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    }))
  )(WorkspaceDataset)
)
