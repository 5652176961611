import _ from 'lodash'
import { renderSelf } from 'views/Shared'

const prefixParam = ':'

export const queryBy = (param) => [prefixParam, param].join('')

export const mapPathParam = (target, param) => {
  if (_.isEmpty(target) || _.isEmpty(param)) {
    return target
  }
  const from = _.first(Object.keys(param))
  const to = _.first(Object.values(param))
  return String(target).replace(`${prefixParam}${from}`, to)
}

export const bindQueryParam = (query) => {
  const name = Object.keys(query)[0]
  const value = Object.values(query)[0]
  return {
    [queryBy(String(name))]: value,
  }
}

export const bindQueryParams = (queries = []) => {
  return queries.map(bindQueryParam).reduce(
    (prev, value) => ({
      ...prev,
      ...value,
    }),
    {}
  )
}

export const actionBinding =
  (getValue = renderSelf) =>
  (root, action) => {
    const alias = [root, action].join('/')
    return mapPathParam(getValue(alias), { action })
  }

export const getLastSegment = (pathname, anchor = '') => {
  if (_.isEmpty(pathname)) return null
  if (_.isEmpty(anchor)) {
    const arrs = String(pathname).split('/')
    return _.last(arrs)
  }
  const current = String(pathname).split(anchor)
  if (_.isEmpty(current)) return current
  return String(_.last(current)).replace('/', '')
}

export const withSearchParams =
  (values = {}) =>
  (history) => {
    let _searchParams = new URLSearchParams(
      _.get(history, 'location.search', {})
    )

    _.reduce(
      Object.keys(values),
      (prev, name) => {
        const _value = values[name]
        if (_value) {
          _searchParams.set(name, _value)
        } else {
          _searchParams.delete(name)
        }
        return prev
      },
      {}
    )
    return ['?', _searchParams.toString()].join('')
  }
