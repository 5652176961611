import { Tabs } from 'antd'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { Switch, useParams } from 'react-router-dom'
import { gConfigs, gPaths } from 'views/AppLocals/configs'
import { mapPathParam } from 'views/AppLocals/functions/routerHelper'
import useNavRouteAction from 'views/AppLocals/hooks/useNavRouteAction'
import { NavRoutes } from 'views/AppLocals/hooks/useNavRoutes'
import { notEmpty, Null } from 'views/Shared'
import { Queries } from 'views/Shared/enums/Queries'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import OrganizationContainerContext from '../OrganizationContainerContext'

const OrganizationNav = ({ Wrapper = 'div' }) => {
  const t = useTranslate()

  const { isSubDomain, url_domain, url_pathname, ...rest } =
    useContext(SubDomainContext)

  const params = useParams()

  const history = useHistory()

  const id = _.get(params, 'id')

  const withSubDomain = Boolean(isSubDomain && !id)

  const rootPath = withSubDomain
    ? gPaths.organization_domain
    : gPaths.organization

  const {
    isValidRoute = Null,
    routeEntities = [],
    getDefaultAction = Null,
    organization = {},
    set_route_data = Null,
  } = useContext(OrganizationContainerContext)

  const defaultAction = getDefaultAction(rootPath)

  const { currentAction } = useNavRouteAction({
    anchor: id,
    defaultAction,
    isAction: isValidRoute,
    getActionParam: withSubDomain
      ? (pathname) => {
          const pattern =
            rootPath == '' ? pathname : _.last(String(pathname).split(rootPath))
          const _action = pattern.substring(pattern.startsWith('/') ? 1 : 0)
          return _.isEmpty(_action.trim()) ? defaultAction : _action
        }
      : undefined,
  })

  useEffect(() => {
    set_route_data({
      [Queries.REL]: currentAction,
    })
  }, [currentAction])

  const pathTransform = useCallback(
    (path, segment) =>
      [
        _.isEmpty(id)
          ? path
          : mapPathParam(path, {
              id,
            }),
        segment,
      ]
        .filter(notEmpty)
        .join('/'),
    [id]
  )

  const tabPanes = useMemo(
    () =>
      routeEntities
        .filter(
          ({ isInvisible = Null }) =>
            !isInvisible({
              item: organization,
            })
        )
        .map(({ path, breadcrumbName = Null }) => (
          <Tabs.TabPane
            tab={breadcrumbName(t)}
            key={path}>
            <NavRoutes
              rootPath={rootPath}
              routes={routeEntities}
              transform={pathTransform}
              defaultAction={defaultAction}
              renderRoutes={
                withSubDomain
                  ? (routes, redirect) => (
                      <Switch>
                        {routes}
                        {redirect}
                      </Switch>
                    )
                  : undefined
              }
            />
          </Tabs.TabPane>
        )),
    [
      t,
      rootPath,
      organization,
      defaultAction,
      routeEntities,
      pathTransform,
      withSubDomain,
    ]
  )

  return (
    <Wrapper
      id="content"
      className="space-y-3 background">
      <Tabs
        tabBarStyle={gConfigs.styles.tabBar}
        destroyInactiveTabPane={true}
        //key={currentAction}
        onChange={(activeKey) => {
          history.push({
            pathname: pathTransform(rootPath, activeKey),
            hash: '#content',
          })
        }}
        activeKey={currentAction}>
        {tabPanes}
      </Tabs>
    </Wrapper>
  )
}

export default OrganizationNav
