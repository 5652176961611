import { Divider, message } from 'antd'
import { auth_login_Api } from 'apis'
import Null from 'components/NullComponent'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { useDispatch } from 'react-redux'
import { loginSuccess } from 'redux/actions'
import { AppLogoSvg } from '../../appConfigs/svgs/AppLogoSvg'
import Toggle from '../../components/Toggle'
import { renderIfElse } from '../Shared'
import GoogleLoginBtn from './GoogleLoginBtn/index'
import './Login.css'
import LoginForm from './LoginForm'

const LoginOrSkip = ({ onSuccess = Null }) => {
  const t = useTranslate()
  const dispatch = useDispatch()
  const { isLoading, handleAsyncAction } = useAsyncAction({
    apiInfo: auth_login_Api,
    onSuccess: (...args) => {
      dispatch(loginSuccess(_.get(args, '1.response.data')))
      onSuccess()
    },
    onError: (...args) => message.error(_.get(args, '1.message')),
  })

  return (
    <div className="flex flex-col">
      <div className="text-center text-lg font-bold text-color-100 uppercase">
        {t('login')}
      </div>
      <div className="px-3 py-10 space-y-6">
        <Toggle>
          {(isToggle, toggle) =>
            renderIfElse(
              isToggle,
              <React.Fragment>
                <div className="text-center text-sm text-color-000 font-medium mb-2 italic">
                  {t('with account')} FeedIn
                </div>
                <LoginForm />
              </React.Fragment>,
              <React.Fragment>
                <section className="flex flex-col justify-center space-y-3">
                  <GoogleLoginBtn
                    style={{ width: '20em' }}
                    onSuccess={handleAsyncAction}
                  />
                </section>
                <section className="flex items-center justify-center space-y-3">
                  <div
                    onClick={toggle}
                    style={{ minWidth: '20em' }}
                    className="flex hover:shadow-items-primary bg-primary-50 hover:bg-primary-100 rounded-lg cursor-pointer relative">
                    <div className="flex flex-center p-2 border-r border-white">
                      <AppLogoSvg size={18} />
                    </div>
                    <div className="flex flex-1 flex-center text-primary font-semibold tracking-wide">
                      {t('login with account')}
                    </div>
                  </div>
                </section>

                <Divider
                  style={{
                    marginBottom: 0,
                    color: '#d9d9d9',
                    fontSize: 13,
                    fontStyle: 'italic',
                    textTransform: 'lowercase',
                  }}>
                  {t('or')}
                </Divider>

                <section className="flex items-center justify-center space-y-3">
                  <div
                    onClick={() => {
                      onSuccess()
                    }}
                    style={{ padding: '3px 12px', cursor: 'pointer' }}
                    className="font-medium text-sm text-primary hover:text-primary-500 italic hover:background-200 tracking-wide">
                    {t('skip login')}
                  </div>
                </section>
              </React.Fragment>
            )
          }
        </Toggle>
      </div>
    </div>
  )
}
export default LoginOrSkip
