import _ from "lodash"
import React from "react"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"
import { renderOwnChild } from 'views/Shared'

const QuoteContent = ({
  content,
  className,
  Wrapper = renderOwnChild
}) => {
  if (_.isEmpty(content)) return null
  return (
    <Wrapper>
      <FaQuoteLeft />
      <span className={className}>
        {content}
      </span>
      <FaQuoteRight />
    </Wrapper>
  )
}

export default QuoteContent