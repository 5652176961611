import {getId} from 'apis/model/base'
import {LoginContext} from 'components/LoginContext'
import {useQueryString} from 'hooks'
import _ from 'lodash'
import {useHistory} from 'modules/navigation/useRouter'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import OrganizationContainerContext, {OrganizationContainerProvider,} from 'views/AppLocals/Organization/OrganizationContainerContext'

export const WorkspaceFilterParams = Object.freeze({
  OVERVIEW: 'overview',
})

export const SummaryFilterOptions = Object.freeze({
  TODAY: {
    label: 'today',
    value: 'TODAY',
  },
  YESTERDAY: {
    label: 'yesterday',
    value: 'YESTERDAY',
  },
  LAST7DAYS: {
    label: 'last 7 days',
    value: 'LAST7DAYS',
  },
  THISMONTH: {
    label: 'this month',
    value: 'THISMONTH',
  },
  LASTMONTH: {
    label: 'last month',
    value: 'LASTMONTH',
  },
  THISYEAR: {
    label: 'this year',
    value: 'THISYEAR',
  },
  LASTYEAR: {
    label: 'last year',
    value: 'LASTYEAR',
  },
  LAST3YEAR: {
    label: 'last 3 years',
    value: 'LAST3YEAR',
  },
})

const default_filter_params = {
  [WorkspaceFilterParams.OVERVIEW]: SummaryFilterOptions.THISMONTH.value,
}

const WorkspaceContext = React.createContext({})

export const WorkspaceProvider = ({children, ...props}) => {
  const history = useHistory()

  const [queriesObject] = useQueryString(_.get(history, 'location.pathname'))

  const [filter_params, set_filter_params] = useState()

  const {primary_org} = useContext(LoginContext)

  const primary_id = getId(primary_org) || primary_org

  useEffect(() => {
    const params = Object.values(WorkspaceFilterParams).reduce((prev, name) => {
      const _value = _.get(queriesObject, name)
      if (_value === default_filter_params[name]) {
        return prev
      } else {
        return {
          ...prev,
          [name]: _value,
        }
      }
    }, {})
    set_filter_params(params)
  }, [queriesObject])

  const getPeriod = useCallback(
    (name) => {
      if (_.has(default_filter_params, name)) {
        return _.get(filter_params, name, default_filter_params[name])
      } else {
        return null
      }
    },
    [filter_params]
  )

  const setPeriod = useCallback(
    (name, value) => {
      if (_.has(default_filter_params, name)) {
        const {search, pathname} = _.get(history, 'location', {})
        let _params = new URLSearchParams(search)
        _params.set(name, value)
        history.push({
          pathname,
          search: ['?', _params.toString()].join(''),
        })
      }
    },
    [filter_params]
  )

  return (
    <OrganizationContainerProvider item={primary_id}>
      <OrganizationContainerContext.Consumer>
        {({organization}) => {
          const permissions = _.get(organization, 'available_actions', [])
          return (
            <PermissionProvider permissions={permissions}>
              <WorkspaceContext.Provider
                key={primary_id}
                value={{
                  ...props,
                  getPeriod,
                  setPeriod,
                  organization,
                  organization_id: primary_id,
                }}>
                {children}
              </WorkspaceContext.Provider>
            </PermissionProvider>
          )
        }}
      </OrganizationContainerContext.Consumer>
    </OrganizationContainerProvider>
  )
}

export default WorkspaceContext
