import {GoogleOAuthProvider, useGoogleOneTapLogin} from '@react-oauth/google'
import {Divider, message, Radio} from 'antd'
import {auth_login_Api} from 'apis'
import classNames from 'classnames'
import {LoginContext} from 'components/LoginContext'
import Null from 'components/NullComponent'
import {isDevelop} from 'envs/ForDevelop'
import {setTokenKey} from 'helpers/localStorage'
import jwt_decode from 'jwt-decode'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {useToggle} from 'react-use'
import {loginSuccess} from 'redux/actions'
import UseState from '../../components/UseState'
import {APP_NAME, GG_ID, ROOT_URL} from '../../envs/_current/config'
import {useAppConfig} from '../../modules/local'
import GoogleLoginBtn from './GoogleLoginBtn/index'
import './Login.css'
import LoginForm from './LoginForm'
import Register from './RegisterForm'

function toBase64(text) {
  return Buffer.from(text).toString('base64')
}

function fromBase64(text, encoding = 'utf8') {
  return Buffer.from(text, 'base64').toString(encoding)
}

export const useGoogleSignIn = ({client_id, redirect_uri}) => {
  const history = useHistory()

  const location = useLocation()

  const dispatch = useDispatch()

  var fragmentString = location.hash.substring(1)

  const {handleAsyncAction} = useAsyncAction({
    apiInfo: auth_login_Api,
    onSuccess: (__, res) => {
      const action = loginSuccess(res?.response?.data)
      dispatch(action)
    },
  })

  useEffect(() => {
    let params = {}
    var regex = /([^&=]+)=([^&]*)/g,
      m
    while ((m = regex.exec(fragmentString))) {
      params[decodeURIComponent(m[1])] = decodeURIComponent(m[2])
    }
    let state = params['state'] ? fromBase64(params['state']) : undefined
    if (state) {
      state = JSON.parse(state ?? '{}')
    }
    const {type, callback_url} = state ?? {}
    if (type === 'request_token') {
      const token = params['access_token']
      setTokenKey(token)
      signIn(token)
      if (callback_url) {
        history.push(callback_url)
      }
    }
  }, [fragmentString])

  function signIn(token) {
    if (token) {
      handleAsyncAction({
        token,
        provider: 'google',
      })
    }
  }

  /*
   * Create form to request access token from Google's OAuth 2.0 server.
   */
  function oauth2SignIn(options) {
    // Google's OAuth 2.0 endpoint for requesting an access token
    var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'

    // Create element to open OAuth 2.0 endpoint in new window.
    var form = document.createElement('form')
    form.setAttribute('method', 'GET') // Send as a GET request.
    form.setAttribute('action', oauth2Endpoint)

    const searchParams = new URLSearchParams(location.search)

    // Parameters to pass to OAuth 2.0 endpoint.
    var params = {
      client_id,
      redirect_uri,
      state: toBase64(
        JSON.stringify({
          type: 'request_token',
          callback_url: {
            ...(location ?? {}),
            search: searchParams.keys.length
              ? '?' + searchParams.toString()
              : location.search,
          },
        })
      ),
      response_type: 'token',
      include_granted_scopes: 'true',
      scope: 'https://www.googleapis.com/auth/userinfo.email',
      ...(options ?? {}),
    }

    // Add form parameters as hidden input values.
    for (var p in params) {
      var input = document.createElement('input')
      input.setAttribute('type', 'hidden')
      input.setAttribute('name', p)
      input.setAttribute('value', params[p])
      form.appendChild(input)
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form)
    form.submit()
  }

  return {
    signIn,
    oauth2SignIn,
  }
}

const GoogleOneTapHandler = ({children, client_id, redirect_uri}) => {
  const login = useContext(LoginContext)
  const {oauth2SignIn} = useGoogleSignIn({
    client_id,
    redirect_uri,
  })
  useGoogleOneTapLogin({
    disabled: login,
    onSuccess: (credentialResponse) => {
      if (credentialResponse) {
        const info = jwt_decode(credentialResponse?.credential)
        oauth2SignIn({
          login_hint: info?.email,
        })
      }
    },
    onError: () => {
      console.log('Login Failed')
    },
    promptMomentNotification: isDevelop()
      ? (notification) => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          document.cookie = `g_state=;path=/;expires=Mon, 01 Jan 1900 00:00:00 GMT`
        }
      }
      : undefined,
  })
  return children
}

export const GoogleOneTap = ({children}) => (
  <GoogleOAuthProvider clientId={GG_ID}>
    <GoogleOneTapHandler
      redirect_uri={ROOT_URL}
      client_id={GG_ID}>
      {children}
    </GoogleOneTapHandler>
  </GoogleOAuthProvider>
)

const Footer = () => {
  const t = useTranslate()

  return (
    <div className="mt-3 font-normal text-color-300 italic">
      {t('by using Feedin, you agree to our')}{' '}
      <Link
        to={'/document/privacy-policy'}
        className="color-primary font-medium">
        {t('privacy policy')}
      </Link>{' '}
      {t('and')}{' '}
      <Link
        to={'/document/terms-of-service'}
        className="color-primary font-medium">
        {t('terms of service')}
      </Link>
      .
    </div>
  )
}

const Login = ({
                 onSuccess = Null,
                 isRequired = true,
                 isSignUpDefault = false,
                 initOrgType = undefined,
               }) => {
  const [isSignUp, toggle] = useToggle(isSignUpDefault)
  const t = useTranslate()
  const dispatch = useDispatch()
  const {isLoading, handleAsyncAction} = useAsyncAction({
    apiInfo: auth_login_Api,
    onSuccess: (...args) => {
      dispatch(loginSuccess(_.get(args, '1.response.data')))
      onSuccess()
    },
    onError: (...args) => message.error(_.get(args, '1.message')),
  })

  const {org_types = {}} = useAppConfig()
  let user_types = []
  let organization_types = []
  user_types.push({
    id: 0,
    label: t('individual'),
    className: 'border border-color-50 text-color-400 background hover:background-100 hover:shadow-items-md',
  })
  Object.keys(org_types)
    .sort()
    .map((e) => {
      organization_types.push({
        id: e,
        label: org_types[e],
        className: 'text-primary-600 bg-primary-50 hover:bg-primary-100',
      })
    })

  return (
    <div className="flex flex-col space-y-3 pb-6">
      {!isSignUp && (
        <React.Fragment>
          <div className="w-full flex text-center items-center justify-center">
            <span
              style={{padding: '0.6rem 2rem'}}
              className="flex flex-center border background rounded-lg z-3">
              <div className="text-lg font-bold text-color-100 uppercase">
                {t('login')}
              </div>
            </span>
          </div>
          <div
            style={{marginTop: '-1.5rem'}}
            className="px-3 py-10 space-y-3 rounded-lg border">
            <section className="flex flex-col justify-center space-y-3 pt-6">
              <GoogleLoginBtn onSuccess={handleAsyncAction}/>
            </section>
            <Divider style={{marginBottom: 0, color: '#d9d9d9'}}>
              {t('or')}
            </Divider>
            <div className="text-center text-sm text-color-100 font-medium mb-2 italic">
              {t('with account')} FeedIn
            </div>

            <LoginForm/>

            <Footer/>
          </div>
        </React.Fragment>
      )}

      {isSignUp && (
        <React.Fragment>
          <div className="w-full flex text-center items-center justify-center">
            <span
              style={{padding: '0.6rem 2rem'}}
              className="flex flex-center border background rounded-lg z-3">
              <div className="text-lg font-bold text-color-100 uppercase">
                {t('sign up')}
              </div>
            </span>
          </div>
          <div
            style={{marginTop: '-1.5rem'}}
            className="px-3 py-10 space-y-3 rounded-lg border">
            <UseState
              initialValue={{
                step: !!!initOrgType ? 0 : 1,
                org_type: Number(initOrgType),
              }}>
              {([values, setValues]) => {
                const {step} = values
                switch (step) {
                  case 0:
                    return (
                      <section className="flex flex-col justify-center gap-3">
                        <div className="text-center text-sm text-color-300 uppercase font-bold mb-3 tracking-wider">
                          {t('do you want to register as')}
                        </div>
                        <div className="grid grid-cols-1 gap-3">
                          <div className="text-sm text-color-300 font-semibold italic pt-3 space-x-1">
                            {t('register as a member of {app_name}', {
                              app_name: (
                                <span className="text-primary">
                                    {APP_NAME}
                                </span>
                              ),
                            })}
                          </div>
                          {user_types.map((e, i) => (
                            <div
                              key={i}
                              onClick={() => {
                                if (e.id === 0) {
                                  setValues({
                                    ...values,
                                    step: 1,
                                  })
                                } else {
                                  setValues({
                                    ...values,
                                    org_type: e.id,
                                    step: 1,
                                  })
                                }
                              }}
                              style={{padding: '0.5rem', marginBottom: '1.5rem'}}
                              className={classNames(
                                'w-full flex flex-center text-lg font-bold rounded-lg cursor-pointer uppercase',
                                e.className
                              )}>
                              {t(e.label)}
                            </div>
                          ))}
                          <div className="text-sm text-color-300 font-semibold italic pt-3">
                            {t('or register to create an organization/group/association')}
                          </div>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                            {organization_types.map((e, i) => (
                              <div
                                key={i}
                                onClick={() => {
                                  if (e.id === 0) {
                                    setValues({
                                      ...values,
                                      step: 1,
                                    })
                                  } else {
                                    setValues({
                                      ...values,
                                      org_type: e.id,
                                      step: 1,
                                    })
                                  }
                                }}
                                style={{padding: '0.5rem'}}
                                className={classNames(
                                  'w-full flex flex-center font-bold rounded-lg cursor-pointer capitalize',
                                  e.className
                                )}>
                                {t(e.label)}
                              </div>
                            ))}
                          </div>
                        </div>
                      </section>
                    )
                  case 1:
                    return !!values.org_type ? (
                      <section className="flex flex-col justify-center space-y-3 py-6">
                        <Register
                          setBack={() => {
                            setValues({
                              ...values,
                              org_type: values.org_type,
                              step: 0,
                            })
                          }}
                          {...values}
                        />
                      </section>
                    ) : (
                      <div className="flex flex-col space-y-1 pt-6">
                        <section className="flex flex-col justify-center verticalList__small">
                          <GoogleLoginBtn onSuccess={handleAsyncAction}/>
                        </section>
                        <Divider style={{marginBottom: 0, color: '#d9d9d9'}}>
                          {t('or')}
                        </Divider>
                        <div className="text-center text-sm text-color-100 font-medium italic mb-2">
                          {t('with your email')}
                        </div>

                        <Register
                          setBack={() => {
                            setValues({
                              ...values,
                              org_type: undefined,
                              step: 0,
                            })
                          }}
                          {...values}
                        />
                      </div>
                    )
                  default:
                    return null
                }
              }}
            </UseState>
          </div>
        </React.Fragment>
      )}

      <div className="w-full text-center mt-3">
        <Radio.Group
          className="font-medium"
          size={'medium'}
          onChange={(e) => {
            if (isSignUp !== e.target.value) toggle()
          }}
          defaultValue={isSignUp}>
          <Radio.Button
            value={false}
            style={{borderRadius: '0.5rem 0 0 0.5rem'}}>
            {t('login')}
          </Radio.Button>
          <Radio.Button
            value={true}
            style={{borderRadius: '0 0.5rem 0.5rem 0'}}>
            {t('sign up')}
          </Radio.Button>
        </Radio.Group>
      </div>
      {!isRequired && (
        <div
          className="flex flex-center cursor-pointer text-color-400 hover:text-color-000"
          onClick={() => {
            onSuccess()
          }}>
          {t('skip')}
        </div>
      )}
    </div>
  )
}
export default Login
