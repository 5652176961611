import {Input, InputNumber} from 'antd'
import {createValue} from 'components/form/utils'
import {formatterNumber} from 'helpers/formatterNumber'
import {parseNumber} from 'helpers/parseNumber'
import withTranslate from 'modules/local/withTranslate'
import {compose, mapProps} from 'recompose'
import {Null} from 'views/Shared'
import '../../Shared/styles/FormList.css'

const createFormField = ({
                           placeholder,
                           Component = Input,
                           noTranslate = false,
                           ...props
                         }) =>
  compose(
    withTranslate,
    mapProps(({name, value, onChange, translate}) => ({
      name,
      onChange,
      defaultValue: value,
      placeholder: noTranslate ? placeholder : translate(placeholder),
      ...props,
    }))
  )(Component)

export const createControlledFormField = ({
                                            placeholder,
                                            Component = Input,
                                            noTranslate = false,
                                            withProps = () => ({}),
                                            withValues = () => ({}),
                                            withForm = () => ({}),
                                            ...props
                                          } = {}) =>
  compose(
    withTranslate,
    mapProps(({name, value, form, onChange, translate}) => ({
      name,
      value,
      onChange,
      placeholder: noTranslate
        ? placeholder
        : placeholder
          ? translate(placeholder)
          : placeholder,
      ...(withProps({
        name,
        value,
        onChange,
        translate,
        ...props,
      }) ?? {}),
      ...(withValues(form?.values ?? {}) ?? {}),
      ...(withForm(form) ?? {}),
      ...props,
    }))
  )(Component)

export const createNumberControlledInput = (props) =>
  createControlledFormField({
    min: 0,
    parser: parseNumber,
    Component: InputNumber,
    formatter: formatterNumber,
    className: 'w-full rounded overflow-hidden',
    style: {
      borderColor: 'inherit!important',
    },
    withProps: ({
                  name,
                  value,
                  onChange = Null
                }) => ({
      value: Number(value),
      onChange: (value) => {
        onChange(createValue(name, value))
      },
    }),
    ...props,
  })

export default createFormField
