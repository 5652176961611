import { schema } from 'normalizr'
import { widgetSchema } from './widget'

export const postSchema = new schema.Entity(
  'posts',
  {
    widgets: [widgetSchema]
  },
  {
    idAttribute: 'id'
  }
)
