import _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";

export const addGlobalAmChartConfig = (item, thisChart, isSm = false) => {
  // last_updated
  try {
    if (thisChart) {
      if (
        _.has(
          item,
          'static_data.{last_updated}'
        )
      ) {
        const last_updated = _.get(
          item,
          'static_data.{last_updated}'
        )
        // Add last updated field:
        let labelLastUpdate = thisChart.plotContainer.createChild(
          am4core.Label
        )

        labelLastUpdate.align = 'right';
        labelLastUpdate.valign = 'bottom';
        labelLastUpdate.dy = -13;
        labelLastUpdate.dx = -3;
        labelLastUpdate.opacity = 0.3
        labelLastUpdate.zIndex = 99
        labelLastUpdate.fontSize = "0.68rem"
        labelLastUpdate.text =
          'Last updated: ' +
          last_updated
        labelLastUpdate.zIndex = 99;
        labelLastUpdate.clickable = false;
        labelLastUpdate.focusable = false;
      }

      // no zoom on mobile.
      if (isSm) {
        thisChart.maxZoomLevel = 1
        thisChart.dragGrip.disabled = false
        thisChart.seriesContainer.resizable = false
        thisChart.cursor.behavior =
          'panX'
      }
    }

  } catch (e) {
  }
}