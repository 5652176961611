export const getInitials = (string) => {
  var names = string.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export default function getUpperFirstChar(
  s
) {
  // if (typeof s === 'string') return s.charAt(0).toUpperCase();
  try {
    if (typeof s === 'string') {
      return s.split(" ")
        .slice(0, 2)
        .map((e, key) => e.charAt(0).toUpperCase())
        .join("")
    }
  } catch (e) {
    return s
  }
  return null;
}
