import * as CryptoJS from 'crypto-js'
import _ from 'lodash'

export const decrypt = (
  value = null,
  password = null,
  transform = JSON.parse
) => {
  try {
    let passphrase = password
    let sl = passphrase.slice(-1)
    sl = _.isNumber(sl) ? Number(sl) : 3
    passphrase = CryptoJS.MD5(passphrase).toString()
    for (let step = 0; step < sl; step++) {
      passphrase = CryptoJS.MD5(passphrase.slice(0, -5)).toString()
    }
    const decrypted = CryptoJS.AES.decrypt(value, passphrase).toString(
      CryptoJS.enc.Utf8
    )
    return transform(decrypted)
  } catch (e) {
    return []
  }
}

export const decryptContent = (value, item, transform) => {
  const {data_encrypted, data_passphrase} = item ?? {}
  if (data_encrypted && data_passphrase) {
    if (_.isArray(value)) {
      let array = []
      for (let index = 0; index < value.length; index++) {
        const element = value[index]
        array.push(decrypt(element, data_passphrase, transform))
      }
      return array
    } else {
      return decrypt(value, data_passphrase, transform)
    }
  } else {
    return value
  }
}
