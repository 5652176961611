import {Breadcrumb, Button, Form, Input, Modal, Select, Skeleton} from 'antd'
import _ from 'lodash'
import {useAppConfig} from 'modules/local'
import React, {useContext, useState} from 'react'
import {Link} from 'react-router-dom'
import {datasets_getDatasetsByOwner_Api} from '../../../apis'
import {organizationModel} from '../../../apis/model'
import EmptyHolder from '../../../components/EmptyHolder'
import StickyFrame from "../../../components/StickyFrame"
import logFunc from '../../../helpers/logFunc'
import {LazyPagination} from '../../../modules/asyncCache/components/LazyPagination'
import useTranslate from '../../../modules/local/useTranslate'
import {OrganizationContext} from '../OrganizationContext'
import {DatasetItem} from './DatasetItem'
import {datasets_getTagsFromDatasetByProps_Api} from "../../../apis/dataset";
import useAsync from "../../../modules/asyncCache/useAsync";
import EmptyFolderStateSvg from "../../../components/Svgs/EmptyFolderStateSvg";

const SelectCategory = ({
  onSelect,
                          hierarchy_categories
}) => {
  return (
    <div className="w-full mt-6 space-y-3">
      {hierarchy_categories.map(
        (e, i) => {
          return (
            <div
              key={i}
              className="border-l-2 border-transparent hover:border-primary">
              <div
                onClick={() =>
                  onSelect(e.idname, e)
                }
                key={e.id}
                className="cursor-pointer hover:background-200 py-2 pl-3 hover:text-primary font-bold flex items-center text-sm uppercase">
                {e.name}
              </div>
              <div className="block w-full ">
                <div className="w-full flex flex-col overflow-hidden ">
                  {_.get(
                    e,
                    'children',
                    []
                  ).map(ii => {
                    return (
                      <div
                        key={ii.idname}
                        className="border-l-4 border-transparent hover:border-primary">
                        <div
                          onClick={() =>
                            onSelect(
                              ii.idname,
                              ii
                            )
                          }
                          className="pl-3 py-1 hover:background-200 block hover:background-200 font-medium title-font cursor-pointer">
                          {ii.name}
                        </div>
                        {_.get(
                          ii,
                          'children',
                          []
                        ).map(iii => {
                          return (
                            <div
                              onClick={() =>
                                onSelect(
                                  iii.idname,
                                  iii
                                )
                              }
                              key={
                                iii.idname
                              }
                              className="pl-3 py-1 border-l-8 border-transparent hover:border-primary capitalize hover:background-200 cursor-pointer">
                              {iii.name}
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        }
      )}
    </div>
  )
}

export function Data() {
  const [
    { keyword, categories },
    setFilter
  ] = useState({
    categories: [],
    keyword: ''
  })
  const { organization } = useContext(
    OrganizationContext
  )
  const t = useTranslate()

  const appConfig = useAppConfig()
  const {
    hierarchy_categories = [],
  } = appConfig ||
    {
      hierarchy_categories: []
    }

  const [form] = Form.useForm()
  const [
    options,
    setOptions
  ] = useState([]);


  const {
    response
  } = useAsync({
    key: datasets_getTagsFromDatasetByProps_Api.path
      .replace(":type", 'organization')
      .replace(":id", organization.idname),
    id: datasets_getTagsFromDatasetByProps_Api.path
      .replace(":type", 'organization')
      .replace(":id", organization.idname),
    apiInfo: datasets_getTagsFromDatasetByProps_Api,
    doOnMount: true,
    query: {
      ':type': 'organization',
      ':id': organization.idname
    }
  });

  return (
    <div className="p-3 space-y-6">
      <div className="flex flex-shrink-0">
        <Breadcrumb className="font-light text-sm">
          <Breadcrumb.Item>
            <Link
              to={organizationModel.getLinkToDetail(
                organization
              )}>
              {organizationModel.getTitle(
                organization
              )}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {t('data')}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-3 gap-y-3">
        <section
          style={{
            overflow: 'initial'
          }}
          className="h-full background-100 rounded-lg">
          <StickyFrame>
            <div className="space-y-6 p-3">
              <div className="py-1 border-b-2 border-primary flex-1 text-xl font-medium uppercase mb-5">
                {t('search')}
              </div>
              <div className="flex">
                <Form
                  className="w-full space-y-3"
                  form={form}
                  layout="vertical"
                  onFinish={logFunc(
                    setFilter
                  )}>
                  <div
                    style={{
                      minWidth: 120
                    }}
                    className="flex-1">
                    <Form.Item
                      className="flex-1"
                      name="keyword"
                      label={t('keyword')}>
                      <Input
                        allowClear
                        placeholder={t(
                          'keyword'
                        )}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    label={t('categories')}
                    name="categories">
                    <Select
                      allowClear
                      open={false}
                      options={options}
                      onFocus={() => {
                        const modal = Modal.info(
                          {
                            icon: null,
                            maskClosable: true,
                            title: t(
                              'categories'
                            ),
                            content: (
                              <SelectCategory
                                hierarchy_categories={
                                  hierarchy_categories
                                }
                                onSelect={(
                                  id,
                                  item
                                ) => {
                                  setOptions(
                                    [
                                      {
                                        value: id,
                                        label:
                                          item.name
                                      }
                                    ]
                                  )
                                  form.setFieldsValue(
                                    {
                                      categories: id
                                    }
                                  )
                                  modal.destroy()
                                }}
                              />
                            )
                          }
                        )
                      }}
                      placeholder={t(
                        'categories'
                      )}
                    />
                  </Form.Item>
                  <div className="flex justify-end space-x-3">
                    <Button
                      htmlType="button"
                      className="rounded-full font-light"
                      onClick={() => {
                        form.resetFields()
                      }}>
                      {t('reset')}
                    </Button>
                    <Button
                      type="primary"
                      className="rounded-full font-light"
                      htmlType="submit">
                      {t('search')}
                    </Button>
                  </div>
                </Form>
              </div>

              <div className="py-1 border-b-2 border-primary flex-1 text-xl font-medium uppercase mb-5">
                {t('tags')}
              </div>
              <div className="flex flex-wrap">
                {
                  _.get(response, 'data.data', []).map((e, i) => (
                    <Link
                      key={
                        i
                      }
                      to={`/t/${e.idname}`}
                      className="flex items-center font-medium px-2 text-primary">
                      #{
                      e.tags
                    }
                    </Link>
                  ))
                }
              </div>

            </div>
          </StickyFrame>
        </section>
        <section className="space-y-3 py-3 md:py-0 col-span-2 w-full mx-auto">
          <div className="py-1 border-b-2 border-primary flex-1 text-xl font-medium uppercase mb-5">
            {t('dataset')}
          </div>
          <LazyPagination
            key={JSON.stringify({
              api: datasets_getDatasetsByOwner_Api.path,
              keyword,
              categories
            })}
            renderEmpty={() => (
              <div className="items-center justify-center h-64">
                <div className="p-6 rounded-md background-100">
                  <Skeleton/>
                </div>

                <EmptyHolder
                  icon={() => (
                    <EmptyFolderStateSvg
                      style={{opacity: 0.5}}
                      width="3rem"
                      height="3rem"
                    />
                  )}
                  subTitle={!!_.get(organization, 'edit')
                    ? t('Please contact the administrator of FeedIn to put the data on the site')
                    : t('no data is available now')}
                  title={null}
                />
              </div>
            )}
            apiInfo={{
              ...datasets_getDatasetsByOwner_Api,
              paramsFields: [
                'keyword',
                'categories'
              ]
            }}
            query={{
              ':type': 'organization',
              ':id': organization.idname
            }}
            values={{
              pinned: 0,
              keyword,
              categories
            }}
            renderLoading={() => (
              <>
                <div className="h-40 background-100 rounded p-3" />
                <div className="h-40 background-100 rounded p-3" />
              </>
            )}
            renderItem={item => (
              <div
                className="p-3 block cursor-pointer border border-gray-300 hover:border-gray-500 background-100  hover:background-200  rounded-lg">
                <DatasetItem
                  item={item}
                />
              </div>
            )}
          />
        </section>
      </div>
    </div>
  )
}
