import { ROOT_URL } from 'envs/_current/config'
import { userSchema } from '../schema'
import base, {
  get,
  getDescription,
  getFullAvatarUrl,
  getLinkToDetailGenerator,
  getLinkToSettingGenerator,
  getLinkToShareGenerator,
  getMetaGenerator,
  getType
} from './base'

export const getTitle = get('name')
const getId = get('username')
export const getLinkToDetail = getLinkToDetailGenerator(
  { getId, getType }
)
export const getLinkToSetting = getLinkToSettingGenerator(
  { getId, getType }
)
export const getLinkToShare = getLinkToShareGenerator(
  { rootUrl: ROOT_URL, getType, getId }
)
export default {
  ...base,
  schema: userSchema,
  getLinkToDetail,
  getLinkToSetting,
  getTitle,
  getLinkToShare,
  getMeta: getMetaGenerator({
    getTitle,
    getDescription: getDescription,
    getImage: getFullAvatarUrl,
    getUrl: getLinkToDetail
  })
}
