import {UseHook} from 'components/UseReducer'
import React from 'react'
import {useSearchParam} from 'react-use'
import {withProps} from 'recompose'
import EnhancedPost, {PostTypes} from 'views/AppLocals/factory/createPost'
import ServerContainer from 'views/Server/ServerContainer'

const postRoutes = [
  {
    path: 'createPost',
    name: 'Create Post',
    component: EnhancedPost(PostTypes.DEFAULT),
    exact: true,
  },
  {
    path: 'createPoll',
    name: 'Create Poll',
    component: EnhancedPost(PostTypes.POLL),
    exact: true,
  },
]

const PostContainer = ({id, type, routes, children}) => (
  <UseHook
    hook={useSearchParam}
    deps={['initialCurrent']}>
    {(initialCurrent) => (
      <ServerContainer
        {...{
          id,
          type,
          routes,
          initialCurrent,
        }}>
        {children}
      </ServerContainer>
    )}
  </UseHook>
)

export default withProps({
  routes: postRoutes,
})(PostContainer)
