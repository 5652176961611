import {getId, getType} from 'apis/model/base'
import {organizationPermissionCodes} from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React from 'react'
import {compose, fromRenderProps, nest, withProps} from 'recompose'
import {DelayRender} from 'views/Discovery/DelayRender'
import {invalidPermissions,} from 'views/AppLocals/factory/createCheckPermissions'
import {SettingsMenu} from 'views/AppLocals/factory/createSettingLayout'
import DefaultTemplate from 'views/AppLocals/Templates/DefaultTemplate'
import {renderFalse, renderOwnChild} from 'views/Shared'
import CustomSiderMenu from 'views/Shared/components/CustomSiderMenu'
import {Queries} from 'views/Shared/enums/Queries'
import {useMenuHandler} from 'views/Shared/handlers/useMenuHandler'
import WorkspaceContext from '../WorkspaceContext'
import WorkspaceProductList from "../Container/WorkspaceProductList";
import WorkspaceProductPriceOfferPartner from "../Container/WorkspaceProductPriceOfferPartner";
import WorkspaceProductVendors from "../Container/WorkspaceProductVendors";
import {BsQuestion} from "react-icons/bs";
import WorkspaceProductPurchasePlanning from "../Container/WorkspaceProductPurchasePlanning";
import {GoLog} from "react-icons/go";
import {PiWarehouse} from "react-icons/pi";
import {RxBackpack} from "react-icons/rx";

const ItemKeys = Object.freeze({
  PRODUCTS: 'products',
  PRICE_OFFER_PARTNERS: 'price-offer-partners',
  VENDORS: 'vendors',
})

const routeEntities = [
  {
    key: ItemKeys.PRODUCTS,
    component: WorkspaceProductList,
  },
  {
    key: ItemKeys.PRICE_OFFER_PARTNERS,
    component: WorkspaceProductPriceOfferPartner,
  },
  {
    key: ItemKeys.VENDORS,
    component: WorkspaceProductVendors,
  },
].map(({key, ...rest}) => ({
  key,
  path: key,
  ...rest,
}))

const with_menu_items = ({isDisabled = renderFalse}) => [
  {
    key: ItemKeys.PRODUCTS,
    label: 'products catalog',
    icon: <GoLog size={16}/>,
    disabled: false,
  }, {
    key: ItemKeys.PRICE_OFFER_PARTNERS,
    label: 'price supplying partners',
    icon: <RxBackpack size={16}/>,
    disabled: false,
  }, {
    key: ItemKeys.VENDORS,
    label: 'vendors',
    icon: <PiWarehouse size={16}/>,
    disabled: false,
  },
]

const WorkspacePurchases = ({owner_id, owner_type}) => {
  const {activeKey, Component, onMenuSelect} = useMenuHandler({
    routes: routeEntities,
    actionName: Queries.REL,
  })

  return (
    <DefaultTemplate
      renderMenu={() => (
        <CustomSiderMenu
          deps={[activeKey]}
          Wrapper={renderOwnChild}>
          <PermissionContext.Consumer>
            {(permissions) => (
              <SettingsMenu
                items={with_menu_items({
                  isDisabled: invalidPermissions(permissions),
                })}
                onSelect={onMenuSelect}
                defaultSelectedKeys={[activeKey]}
              />
            )}
          </PermissionContext.Consumer>
        </CustomSiderMenu>
      )}>
      <div className="space-y-3 px-3 md:px-0 md:pr-3">
        <Component/>
      </div>
    </DefaultTemplate>
  )
}

export default nest(
  withProps({lazy: true})(DelayRender),
  compose(
    fromRenderProps(WorkspaceContext.Consumer, ({organization}) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    }))
  )(WorkspacePurchases)
)
