import { Radio } from 'antd'
import classNames from 'classnames'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { Null, renderIf } from 'views/Shared'
import { createValue } from '../utils'

export const MegaRadioGroup = ({ className, name, value, onChange = Null, ...props }) => {
  const t = useTranslate()

  const options = Array.from(props.options ?? [])

  return renderIf(
    options.length,
    <div className={classNames('flex flex-col gap-3', className)}>
      <Radio.Group
        name={name}
        value={value}
        optionType="button"
        buttonStyle="solid"
        onChange={onChange}
        className="custom-radio-button"
        {...props}>
        {options.map(({ label, value }) => (
          <React.Fragment key={value}>
            <Radio.Button value={value}>{t(label)}</Radio.Button>
          </React.Fragment>
        ))}
      </Radio.Group>
      <div className="rounded-lg border border-primary-300">
        {options.map((option, index) => {
          const { label, description, ...rest } = option ?? {}
          return (
            <div
              key={index}
              onClick={() => {
                onChange(createValue(name, rest.value))
              }}
              className={classNames(
                'px-2 py-1 cursor-pointer',
                  rest.value === value ? 'bg-primary-50' : 'background',
                  rest.value === 1 ? ' rounded-t-lg' : 'rounded-b-lg'
              )}>
              <div
                className={classNames(
                  'font-bold',
                  rest.value === value ? 'text-primary-600' : 'text-color-100'
                )}>
                {t(label)}
              </div>
              <div
                className={classNames(
                  'ml-1 text-sm italic leading-tight',
                  rest.value === value ? 'text-primary' : 'text-color-400'
                )}>
                {t(description)}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
