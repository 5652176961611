import React from 'react';

export const PageNotFoundSvg = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 600">
			<defs>
				<clipPath id="GlassClip">
					<path
						d="M380.857 346.164c-1.247 4.651-4.668 8.421-9.196 10.06-9.332 3.377-26.2 7.817-42.301 3.5s-28.485-16.599-34.877-24.192c-3.101-3.684-4.177-8.66-2.93-13.311l7.453-27.798a6.948 6.948 0 016.088-5.13c6.755-.61 20.546-.608 41.785 5.087s33.181 12.591 38.725 16.498a6.948 6.948 0 012.705 7.488l-7.452 27.798z"></path>
				</clipPath>
				<clipPath>
					<path d="M0 0H800V600H0z"></path>
				</clipPath>
			</defs>
			<g fill="none" stroke="#0E0620" strokeMiterlimit="10" strokeWidth="3">
				<circle cx="572.859" cy="108.803" r="90.788"></circle>
				<circle cx="548.891" cy="62.319" r="13.074"></circle>
				<circle cx="591.743" cy="158.918" r="7.989"></circle>
				<path
					strokeLinecap="round"
					d="M476.562 101.461c-30.404 2.164-49.691 4.221-49.691 8.007 0 6.853 63.166 12.408 141.085 12.408s141.085-5.555 141.085-12.408c0-3.378-15.347-4.988-40.243-7.225"
				></path>
				<path
					strokeLinecap="round"
					d="M483.985 127.43c23.462 1.531 52.515 2.436 83.972 2.436 36.069 0 68.978-1.19 93.922-3.149"
					opacity="0.5"
				></path>
			</g>
			<g>
				<g
					fill="none"
					stroke="#0E0620"
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="3"
				>
					<path d="M518.07 245.375L518.07 266.581"></path>
					<path d="M508.129 255.978L528.01 255.978"></path>
					<path d="M154.55 231.391L154.55 252.598"></path>
					<path d="M144.609 241.995L164.49 241.995"></path>
					<g>
						<path d="M320.135 132.746L320.135 153.952"></path>
						<path d="M310.194 143.349L330.075 143.349"></path>
					</g>
					<g>
						<path d="M200.67 483.11L200.67 504.316"></path>
						<path d="M210.611 493.713L190.73 493.713"></path>
					</g>
				</g>
				<g
					fill="none"
					stroke="#0E0620"
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="3"
				>
					<path d="M432.173 380.52L432.173 391.83"></path>
					<path d="M426.871 386.175L437.474 386.175"></path>
					<path d="M489.555 299.765L489.555 308.124"></path>
					<path d="M485.636 303.945L493.473 303.945"></path>
					<path d="M231.468 291.009L231.468 299.369"></path>
					<path d="M227.55 295.189L235.387 295.189"></path>
					<g>
						<path d="M244.032 547.539L244.032 555.898"></path>
						<path d="M247.95 551.719L240.113 551.719"></path>
					</g>
					<g>
						<path d="M186.359 406.967L186.359 415.326"></path>
						<path d="M190.277 411.146L182.44 411.146"></path>
					</g>
					<g>
						<path d="M480.296 406.967L480.296 415.326"></path>
						<path d="M484.215 411.146L476.378 411.146"></path>
					</g>
				</g>
				<g
					fill="none"
					stroke="#0E0620"
					strokeLinecap="round"
					strokeMiterlimit="10"
					strokeWidth="3"
				>
					<circle cx="588.977" cy="255.978" r="7.952"></circle>
					<circle cx="450.066" cy="320.259" r="7.952"></circle>
					<circle cx="168.303" cy="353.753" r="7.952"></circle>
					<circle cx="429.522" cy="201.185" r="7.952"></circle>
					<circle cx="200.67" cy="176.313" r="7.952"></circle>
					<circle cx="133.343" cy="477.014" r="7.952"></circle>
					<circle cx="283.521" cy="568.033" r="7.952"></circle>
					<circle cx="413.618" cy="482.387" r="7.952"></circle>
				</g>
				<g fill="#0E0620">
					<circle cx="549.879" cy="296.402" r="2.651"></circle>
					<circle cx="253.29" cy="229.24" r="2.651"></circle>
					<circle cx="434.824" cy="263.931" r="2.651"></circle>
					<circle cx="183.708" cy="544.176" r="2.651"></circle>
					<circle cx="382.515" cy="530.923" r="2.651"></circle>
					<circle cx="130.693" cy="305.608" r="2.651"></circle>
					<circle cx="480.296" cy="477.014" r="2.651"></circle>
				</g>
			</g>
      <g clipPath="url(cordClip)">
				<path
					fill="none"
					stroke="#0E0620"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="3"
					d="M273.813 410.969s-54.527 39.501-115.34 38.218c-2.28-.048-4.926-.241-7.841-.548-68.038-7.178-134.288-43.963-167.33-103.87-.908-1.646-1.793-3.3-2.654-4.964-18.395-35.511-37.259-83.385-32.075-118.817"
				></path>
				<path
					fill="#FFF"
					stroke="#0E0620"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="3"
					d="M338.164 454.689l-64.726-17.353c-11.086-2.972-17.664-14.369-14.692-25.455l15.694-58.537c3.889-14.504 18.799-23.11 33.303-19.221l52.349 14.035c14.504 3.889 23.11 18.799 19.221 33.303l-15.694 58.537c-2.972 11.085-14.368 17.663-25.455 14.691z"
				></path>
				<g
					fill="#FFF"
					stroke="#0E0620"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="3"
				>
					<path d="M323.396 236.625L295.285 353.753"></path>
					<circle cx="323.666" cy="235.617" r="6.375"></circle>
				</g>
				<g
					fill="#FFF"
					stroke="#0E0620"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="3"
				>
					<path
						d="M360.633 363.039c1.352 1.061 4.91 5.056 5.824 6.634l27.874 47.634c3.855 6.649 1.59 15.164-5.059 19.02h0c-6.649 3.855-15.164 1.59-19.02-5.059l-5.603-9.663"></path>
					<path
						d="M388.762 434.677c5.234-3.039 7.731-8.966 6.678-14.594a15.804 15.804 0 015.837 5.793c4.411 7.596 1.829 17.33-5.767 21.741-7.596 4.411-17.33 1.829-21.741-5.767-1.754-3.021-2.817-5.818-2.484-9.046 4.34 4.551 11.802 5.169 17.477 1.873z"></path>
				</g>
				<g
					fill="#FFF"
					stroke="#0E0620"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="3"
				>
					<path
						d="M301.301 347.66c-1.702.242-5.91 1.627-7.492 2.536l-47.965 27.301c-6.664 3.829-8.963 12.335-5.134 18.999h0c3.829 6.664 12.335 8.963 18.999 5.134l9.685-5.564"></path>
					<path
						d="M241.978 395.324c-3.012-5.25-2.209-11.631 1.518-15.977a15.821 15.821 0 00-7.952 2.096c-7.619 4.371-10.253 14.09-5.883 21.71 4.371 7.619 14.09 10.253 21.709 5.883 3.03-1.738 5.35-3.628 6.676-6.59-6.033 1.768-12.803-1.429-16.068-7.122z"></path>
				</g>
				<g
					stroke="#0E0620"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="3"
				>
					<path
						fill="#FFF"
						d="M353.351 365.387a58.685 58.685 0 01-24.48-1.278 58.678 58.678 0 01-21.836-11.14c-17.004 4.207-31.269 17.289-36.128 35.411l-1.374 5.123c-7.112 26.525 8.617 53.791 35.13 60.899h0c26.513 7.108 53.771-8.632 60.883-35.158l1.374-5.123c4.858-18.122-.949-36.585-13.569-48.734z"
					></path>
					<path
						fill="none"
						d="M269.678 394.912h0c26.3 20.643 59.654 29.585 93.106 25.724l2.419-.114"
					></path>
				</g>
				<g
					stroke="#0E0620"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					strokeWidth="3"
				>
					<g>
						<path
							fill="#FFF"
							d="M312.957 456.734l-14.315 53.395c-1.896 7.07 2.299 14.338 9.37 16.234h0c7.07 1.896 14.338-2.299 16.234-9.37l17.838-66.534c-8.633 5.427-18.558 6.928-29.127 6.275z"
						></path>
						<path fill="none" d="M304.883 486.849L330.487 493.713"></path>
					</g>
					<g>
						<path
							fill="#FFF"
							d="M296.315 452.273L282 505.667c-1.896 7.07-9.164 11.265-16.234 9.37h0c-7.07-1.896-11.265-9.164-9.37-16.234l17.838-66.534c4.759 9.017 12.602 15.281 22.081 20.004z"
						></path>
						<path fill="none" d="M262.638 475.522L288.241 482.387"></path>
					</g>
				</g>
				<g>
					<ellipse
						cx="341.295"
						cy="315.211"
						fill="#FFF"
						stroke="#0E0620"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						strokeWidth="3"
						rx="61.961"
						ry="60.305"
						transform="rotate(-74.989 341.31 315.204)"
					></ellipse>
					<path
						fill="none"
						stroke="#0E0620"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						strokeWidth="3"
						d="M330.868 261.338c-7.929 1.72-15.381 5.246-21.799 10.246"
					></path>
					<path
						fill="#FFF"
						stroke="#0E0620"
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						strokeWidth="3"
						d="M380.857 346.164c-1.247 4.651-4.668 8.421-9.196 10.06-9.332 3.377-26.2 7.817-42.301 3.5s-28.485-16.599-34.877-24.192c-3.101-3.684-4.177-8.66-2.93-13.311l7.453-27.798a6.948 6.948 0 016.088-5.13c6.755-.61 20.546-.608 41.785 5.087s33.181 12.591 38.725 16.498a6.948 6.948 0 012.705 7.488l-7.452 27.798z"
					></path>
          <g clipPath="url(#GlassClip)">
						<path
							fill="none"
							stroke="#0E0620"
							strokeMiterlimit="10"
							strokeWidth="3"
							d="M278.436 375.599L383.003 264.076 364.393 251.618 264.807 364.928z"
						></path>
					</g>
				</g>
			</g>
		</svg>
	);
};
