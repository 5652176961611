import {LOGIN_FAILURE, LOGIN_SUCCESS, UPDATE_REFRESH_TOKEN} from './types'
import * as types from "../types";

const INITIAL_STATE = {}
export default function (
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return action.payload
    case LOGIN_FAILURE:
      return {
        ...state,
        failure: true,
      }
    case UPDATE_REFRESH_TOKEN:
      return {
        ...state,
        token: action.token,
        success: true,
        failure: false,
      }
    case types.CHECK_AUTHENTICATE_FAIL:
      const {type, ...rest} = action;
      return {
        ...state,
        ...rest,
      }
    default:
      return state
  }
}
