import { SearchGroupIcon } from 'components/AppIcon'
import Count from 'components/Feed/Count'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  useCallback,
  useContext,
  useMemo
} from 'react'
import { IoAppsOutline } from 'react-icons/io5'
import SearchContext from 'views/Search/SearchContext'
import {
  SearchParams,
  SearchTypes
} from 'views/Search/SearchProvider'
import {
  Null,
  renderFalse,
  renderOwnChild
} from 'views/Shared'
import createContextMenu from 'views/Shared/factory/createContextMenu'
import { discoveryExplorerRouteEntities } from '../discoveryExplorerRouteEntities'

export const DiscoveryContextMenu = ({
  isSelected = renderFalse,
  Wrapper = renderOwnChild
}) => {
  const history = useHistory()

  const onSelect = useCallback(
    key => {
      const {
        pathname,
        getSearch = Null
      } =
        _.find(
          discoveryExplorerRouteEntities,
          { type: key }
        ) || {}

      history.push({
        pathname,
        search:
          getSearch(history) ||
          undefined
      })
    },
    [history]
  )

  const contextMenu = useMemo(
    () =>
      createContextMenu(
        [
          SearchTypes.ALL,
          SearchTypes.CHANNEL,
          SearchTypes.ARTICLE,
          SearchTypes.EVENT,
          SearchTypes.DATASET,
          SearchTypes.ORGANIZATION
        ].map(key => {
          const selected = isSelected(
            key
          )
          return {
            key,
            label: key,
            icon: (
              <SearchGroupIcon
                type={key}
                size={15}
              />
            ),
            renderLabel: label =>
              selected ? (
                <span className="text-color-000 font-bold ml-2 mr-16">
                  {label}
                </span>
              ) : (
                <span className="text-color-300 ml-2 mr-16">
                  {label}
                </span>
              )
          }
        }),
        onSelect,
        <Count
          deta-effect="ripple"
          className="extraIcon mt-0">
          <IoAppsOutline
            style={{
              fontSize: '1.6em'
            }}
          />
        </Count>
      ),
    [onSelect, isSelected]
  )

  return (
    <Wrapper>{contextMenu}</Wrapper>
  )
}

const DiscoveryExplorerHeader = ({
  Wrapper = 'div'
}) => {
  const t = useTranslate()

  const { searchParams } = useContext(
    SearchContext
  )

  const type = _.get(
    searchParams,
    SearchParams.TYPE,
    SearchTypes.ALL
  )

  return (
    <Wrapper className="p-3 background stickyTop top-0 border-b-2 border-color-50 md:mx-0">
      <div className="flex gap-2 items-center">
        <p className="flex flex-1 font-semibold uppercase">
          {t(type)}
        </p>
        <DiscoveryContextMenu
          isSelected={key =>
            key === type
          }
        />
      </div>
    </Wrapper>
  )
}

export default DiscoveryExplorerHeader
