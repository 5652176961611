import { host_getPeerMembers_Api } from 'apis'
import { getId, getType } from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { NavigationContext } from 'modules/navigation/NavigationContext'
import React, { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  nest,
  pure,
  renderNothing,
} from 'recompose'
import { HostsSortBy } from 'views/AppLocals/factory/createEntitySortBy'
import { bindQueryParams } from 'views/AppLocals/functions/routerHelper'
import usePaginationHelper from 'views/AppLocals/hooks/usePaginationHelper'
import { renderHostOwner } from 'views/AppLocals/Host/functions/renderHost'
import HostContext, { HostProvider } from 'views/AppLocals/Host/HostContext'
import ListHeader from 'views/AppLocals/Templates/items/ListHeader'
import { Null } from 'views/Shared'
import WorkspaceContext from '../WorkspaceContext'
import PeerMembersAdvancedFilter from './PeerMembersAdvancedFilter'

export const WorkspaceHostPeerMembers = ({
  Header = ListHeader,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
    status: 'status',
  },
}) => {
  const t = useTranslate()

  const { container } = useContext(HostContext)

  const location = useLocation()

  const { current } = useContext(NavigationContext)

  const refreshToken = useMemo(
    () =>
      JSON.stringify({
        token: _.get(location.state, 'refreshToken'),
        dependencies: [current],
      }),
    [current, location.state]
  )

  const [id, prop] = [getId(container), getType(container)]

  const status = useSearchParam(filter_actions.status)

  const sortBy = useSearchParam(filter_actions.sortBy)

  const [pager_widget] = usePaginationHelper({
    onInit: ({ onSortChange = Null, onFilterChange = Null }) => {
      onFilterChange({
        status,
      })
      onSortChange(sortBy)
    },
    pagerInfo: {
      apiInfo: host_getPeerMembers_Api,
      query: bindQueryParams([
        { id },
        {
          prop,
        },
      ]),
      renderItem: renderHostOwner({
        request_status: 1,
        withoutContextMenu: true,
      }),
      RenderEntity: React.Fragment,
    },
    withValues: (values) => ({
      ...values,
      owner_type: prop,
      request_status: 1,
    }),
    withKey: ({ sortBy, keyword, filterBy }) =>
      [id, sortBy, keyword, JSON.stringify(filterBy), refreshToken].join('/'),
    filter_params: {
      owner_id: id,
      owner_type: prop,
    },
    query_params: filter_actions,
    dependencies: [id, prop],
    sortHeader: t('peer organization list'),
    SortByWidget: HostsSortBy,
    AdvancedFilter: PeerMembersAdvancedFilter,
  })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <Header title="peer organization" />
      {pager_widget}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(WorkspaceContext.Consumer, ({ organization }) => ({
    container: organization,
  })),
  branch(({ container }) => !!!getId(container), renderNothing),
  pure
)(
  nest(
    ({ container, children }) => (
      <HostProvider
        container={container}
        autoRefreshKey="refreshToken">
        {children}
      </HostProvider>
    ),
    WorkspaceHostPeerMembers
  )
)
