import numeral from "numeral"
import React from 'react'

export const CellNumberFormat = ({number}) => {
  if (String(number).length > 5) {
    return (
      <div className="flex justify-end text-xs">
        {
          numeral(
            number
          ).format("0,0.00")
        }
      </div>
    )
  }

  return (
    <div className="flex justify-end">
      {
        numeral(
          number
        ).format("0,0.00")
      }
    </div>
  )
}
