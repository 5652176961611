import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../LoadingPage";

// const HomeLazy = lazy(() =>
//   import('./Home')
// )
const HomeLazy = Loadable({
  loader: () => import('./Home'),
  loading: LoadingPage
})

const Home = (props) => (
  <HomeLazy {...props} />
)

export default Home
