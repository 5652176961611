import {message_createConversation_Api} from 'apis'
import {getId} from 'apis/model/base'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {LoginContext} from 'components/LoginContext'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useContext, useMemo} from 'react'
import {staticPaths} from 'routes/staticPaths'
import {useRequiredLogin} from 'views/Server/useRequiredLogin'
import {getResponseItem, renderOwnChild} from 'views/Shared'
import {notifyOnError} from './createErrorEvent'
import {RiMessage3Line} from "react-icons/ri";
import {IoChatbubbles, IoChatbubblesOutline} from "react-icons/io5";

const createChatButton = ({onClick, ...props}) => (
  <LayoutContext.Consumer>
    {({isSm}) => (
      <Translate>
        {(t) => (
          <div
            style={{padding: '4px 15px', height: '32px', fontSize: 14}}
            className="flex flex-center gap-3 bg-primary-50 hover:bg-primary font-semibold text-primary-600 hover:text-white rounded-lg cursor-pointer"
            onClick={onClick}
            {...props}>
            <IoChatbubbles/>
            {t('send message')}
          </div>
        )}
      </Translate>
    )}
  </LayoutContext.Consumer>
)

export const ConversationWith = ({
                                   params,
                                   Wrapper = renderOwnChild,
                                   render = (child, params) => child,
                                 }) => {
  const t = useTranslate()

  const login = useContext(LoginContext)

  const history = useHistory()

  const {receiver_id, organization_id} = params ?? {}

  const onSuccess = (__, {response}) => {
    const conversationId = getId(getResponseItem(response))
    history.push({
      ...staticPaths.messages,
      search: `?conversationId=${conversationId}`,
    })
  }

  const values = useMemo(() => {
    const data = {
      receiver_id,
      organization_id,
    }
    return _.reduce(
      Object.keys(data),
      function (prev, key) {
        const value = data[key]
        if (value) {
          return {
            ...prev,
            [key]: value,
          }
        }
        return prev
      },
      {}
    )
  }, [receiver_id, organization_id])

  const {handleAsyncAction: sendMessage} = useAsyncAction({
    values,
    apiInfo: message_createConversation_Api,
    onSuccess,
    onError: notifyOnError(t),
  })

  const handleClick = useRequiredLogin(() => sendMessage())

  if (_.isEqual(receiver_id, getId(login))) {
    return null
  }

  const Component = (props) =>
    createChatButton({
      onClick: () => handleClick(),
      ...props,
    })

  return (
    <Wrapper>{render(<Component/>, {onClick: () => handleClick()})}</Wrapper>
  )
}

export default createChatButton
