import {FullSideModalLayout} from 'components/Modal/SideModalLayout'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {nest, withProps} from 'recompose'
import ServerContext from 'views/Server/ServerContext'
import {Login} from '../Auth'
import Invite from './Invite'

export const ModalLayout = ({name, children}) => {
  const t = useTranslate()
  return (
    <ServerContext.Consumer>
      {({setCurrent, item}) => (
        <FullSideModalLayout
          right
          title={
            name ? (
              t(name)
            ) : (
              <span className="font-semibold flex-1">
                <div
                  style={{
                    fontSize: '0.8em',
                  }}
                  className="text-color-300 capitalize">
                  {item.title}
                </div>
              </span>
            )
          }
          onCancel={() => setCurrent(null)}>
          {children}
        </FullSideModalLayout>
      )}
    </ServerContext.Consumer>
  )
}
export const ModalLogin = () => {
  const translate = useTranslate()
  return (
    <ModalLayout name={translate('you need login first')}>
      <Login />
    </ModalLayout>
  )
}
export const routes = [
  {
    path: 'share',
    name: 'share',
    component: nest(withProps({name: 'invite'})(ModalLayout), Invite),
    exact: true,
  },
]
