import {Input, Upload} from 'antd'
import {image_upload_Api} from 'apis'
import {getBase64} from 'components/form/fields/ImageUpload'
import FieldsFactory from 'components/form/FieldsFactory'
import {StepsForm} from 'components/form/StepsForm'
import {createValue} from 'components/form/utils'
import ImagesLightBox from 'components/ImagesLightBox/ImagesLightBox'
import {API_ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import {callApi} from 'modules/asyncCache/callApi'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import React from 'react'
import Pure from "../../components/Pure";
import {ListHeaderWithLine} from "../AppLocals/Templates/items/ListHeader";
import {PiPlusBold} from "react-icons/pi";
import Null from 'components/NullComponent'


export function getUploadedPhotos(photos = []) {
  return photos.map(({id, path, ...photo}) => {
    return {
      id,
      uid: id,
      name: path,
      status: 'done',
      url: path,
    }
  })
}

export const PicturesWall = withTranslate(
  class extends React.PureComponent {
    state = {
      previewImage: '',
      previewVisible: false,
      fileList: this.props.values?.fileList || [],
    }

    handleCancel = () =>
      this.setState({
        previewVisible: false,
      })

    handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }

      this.setState({
        previewImage: file.url || file.preview,
        previewVisible: true,
      })
    }

    handleChange = ({file, fileList}) => {
      this.setState({fileList})
      if (file.status !== 'uploading') {
        const {onChange = Null} = this.props
        onChange(createValue('fileList', fileList))
        onChange(
          createValue(
            'photos',
            fileList.map((info) => _.get(info, 'response.data.ids.0', info.id))
          )
        )
      }
    }

    render() {
      const {translate: t} = this.props
      const {previewVisible, previewImage, fileList} = this.state
      const uploadButton = (
        <div className="flex flex-col gap-1 items-center">
          <PiPlusBold size={16} className="text-color-400"/>
          <div className="ant-upload-text text-sm font-medium text-color-300">
            {t('upload')}
          </div>
        </div>
      )

      return (
        <div className="w-full">
          <div className="flex flex-col gap-1">
            <ListHeaderWithLine title={t('add image')} className="text-sm"/>
            <Upload
              multiple
              customRequest={({onSuccess, ...args}) => {
                callApi({
                  apiInfo: image_upload_Api,
                  values: {
                    'photos[]': args.file,
                  },
                }).then(onSuccess)
              }}
              action={`${API_ROOT_URL}/images/image/upload`}
              listType="picture-card"
              fileList={fileList}
              onChange={this.handleChange}
              onPreview={this.handlePreview}>
              {fileList.length >= 16 ? null : uploadButton}
            </Upload>
          </div>
          {previewVisible && (
            <ImagesLightBox
              images={[previewImage]}
              onCloseRequest={this.handleCancel}
            />
          )}
        </div>
      )
    }
  }
)
const MessageForm = () => {
  const translate = useTranslate()
  return (
    <Pure>
      <FieldsFactory
        formSchema={[
          {
            children: {
              type: 'message',
              name: 'description',
              label: translate('your message'),
              component: Input.TextArea,
              withProps: {
                autosize: {
                  minRows: 4,
                  maxRows: 12,
                },
                type: 'text',
                size: 'large',
                placeholder: translate('Write your sharing'),
              },
            },
          },
        ]}
      />
    </Pure>
  )
}
export const CreateImagePostRoutes = [
  {
    path: '/questtionAndAnswers',
    name: 'post your images',
    component: PicturesWall,
    exact: true,
  },
  {
    path: '/message',
    name: 'post your images',
    component: MessageForm,
    exact: true,
  },
]
export default function CreateImagePost({handleAsyncPost, isLoading}) {
  return (
    <StepsForm
      isLoading={isLoading}
      routes={CreateImagePostRoutes}
      initialValues={{
        description: '',
        photos: [],
      }}
      onSubmit={(values) => {
        const {fileList, ...rest} = values ?? {}
        handleAsyncPost(rest)
      }}
    />
  )
}
