import {Avatar, Button, Divider} from 'antd'
import {channel_getChannelsByAdmin_Api} from 'apis'
import {articleModel} from 'apis/model'
import {LoginContext} from 'components/LoginContext'
import getAvatar from 'helpers/getAvatar'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import {permissionCodes} from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {useContext, useMemo} from 'react'
import {IoNewspaperOutline} from 'react-icons/io5'
import {RiBarChart2Fill, RiUser3Fill} from 'react-icons/ri'
import {branch, compose, fromRenderProps, nest, renderNothing,} from 'recompose'
import ChannelCreatePost from 'views/Channel/components/ChannelCreatePost'
import {FeedContentTypes} from 'views/Feed/Feed'
import AntdConfigWrapper from 'views/AppLocals/custom/AntdConfigWrapper'
import ServerContext from 'views/Server/ServerContext'
import {Null} from 'views/Shared'
import {renderIf} from '../../../views/Shared'
import {InputBoardProvider} from './InputBoardContext'

const InputHeader = ({title, onClick}) => (
  <LoginContext.Consumer>
    {(login) => (
      <div className="flex items-center gap-3">
        <Avatar
          size={32}
          src={getAvatar(login)}
          icon={<RiUser3Fill className="text-color-400"/>}
          className="flex flex-center background-200"
        />
        <div
          onClick={onClick}
          style={{height: 32}}
          className="flex items-center flex-1 background-200 hover:background-100 rounded-full cursor-pointer">
          <span className="ml-4 text-xs text-color-400 italic leading-tight">
            {title}
          </span>
        </div>
      </div>
    )}
  </LoginContext.Consumer>
)

const InputActions = ({
                        onItemClick = (action, next = Null) => {
                          next()
                        },
                      }) => {
  const t = useTranslate()

  const history = useHistory()

  const {setCurrent = Null} = useContext(ServerContext)

  const actions = useMemo(
    () => [
      {
        type: FeedContentTypes.POLL,
        label: 'poll question',
        path: 'createPoll',
        icon: (
          <RiBarChart2Fill
            size={15}
            className="text-primary"
          />
        ),
      },
      {
        type: FeedContentTypes.ARTICLE,
        label: 'article',
        icon: (
          <IoNewspaperOutline
            size={15}
            className="text-primary"
          />
        ),
      },
    ],
    []
  )

  return (
    <div className="flex items-center gap-3">
      <ChannelCreatePost
        apiInfo={channel_getChannelsByAdmin_Api}
        linkTo={(item) => {
          const url = articleModel.getLinkToCreate(item)
          history.push(url)
        }}>
        {(toggle) =>
          actions.map((action, index) => {
            const {label, path, ...rest} = action ?? {}
            return (
              <Button
                {...rest}
                key={index}
                onClick={() => {
                  onItemClick(action, () => {
                    if (path) {
                      setCurrent(path)
                    } else {
                      toggle(true)
                    }
                  })
                }}
                className="flex items-center gap-2 button-rounded-md no-border font-medium text-xs md:text-sm text-color-100 leading-tight hover:background-200">
                {t(label)}
              </Button>
            )
          })
        }
      </ChannelCreatePost>
    </div>
  )
}

const InputBoardForm = ({container}) => {
  const login = useContext(LoginContext)
  return renderIf(
    login,
    <InputBoardProvider container={container}>
      <ServerContext.Consumer>
        {({setCurrent = Null}) => {
          const toggle = () => {
            setCurrent('createPost')
          }
          return (
            <React.Fragment>
              <Translate>
                {(t) => (
                  <div className="flex flex-col p-3 border shadow-items-md rounded-lg mb-4">
                    <InputHeader
                      onClick={() => toggle(true)}
                      title={t(
                        'create short news or share links from other sources'
                      )}
                    />
                    <Divider className="my-3"/>
                    <InputActions/>
                  </div>
                )}
              </Translate>
            </React.Fragment>
          )
        }}
      </ServerContext.Consumer>
    </InputBoardProvider>
  )
}

export default nest(
  AntdConfigWrapper,
  compose(
    fromRenderProps(PermissionContext.Consumer, (props) => props),
    branch(
      (props) => !Boolean(_.get(props, permissionCodes.canPost)),
      renderNothing
    )
  )(InputBoardForm)
)
