import {API_ROOT_URL} from 'envs/_current/config'


// Route::get('products/price-report/{price_report_id}/search-users', 'PriceReportController@searchUsersForMember');
export const priceReport_searchUsers_Api = {
  name: 'priceReport_searchUsers_Api',
  backend: `Route::get('products/price-report/:id/search-users', 'PriceReportController@searchUsersForMember');`,
  root: API_ROOT_URL,
  path:
    '/products/price-report/:id/search-users',
  method: 'GET',
  paramsFields: ['keyword']
}

// Route::get('products/price-report/{price_report_id}/search-organizations', 'PriceReportController@searchOrganizationsForMember');
export const priceReport_searchOrganizations_Api = {
  name: 'priceReport_searchOrganizations_Api',
  backend: `Route::get('products/price-report/:id/search-organizations', 'PriceReportController@searchOrganizationsForMember');`,
  root: API_ROOT_URL,
  path:
    '/products/price-report/:id/search-organizations',
  method: 'GET',
  paramsFields: ['keyword']
}


// Route::post('products/price-report/{price_report_id}/update-set-emails', 'PriceReportController@updateSetEmails'); // id cua price report, input: emails: list email cách nhau dấu ,
export const priceReport_manageMemberAdd_Api = {
  name: 'priceReport_manageMemberAdd_Api',
  backend: `Route::post('products/price-report/{price_report_id}/update-set-emails', 'PriceReportController@updateSetEmails');`,
  root: API_ROOT_URL,
  path:
    '/products/price-report/:id/update-set-emails',
  method: 'POST'
}


// Route::get('products/price-report/organization/{org_id}/gets', 'PriceReportController@gets');
export const priceReport_getByOwner_Api = {
  name: 'priceReport_getByOwner_Api',
  backend: `Route::get('products/price-report/organization/{org_id}/gets', 'PriceReportController@gets');`,
  root: API_ROOT_URL,
  path:
    '/products/price-report/organization/:id/gets',
  method: 'GET',
  paramsFields: ['keyword']
}


// Route::get('products/price-report/user/{id}/get-by-receivers', 'PriceReportController@getByReceivers');
export const priceReport_getByReceivers_Api = {
  name: 'priceReport_getByReceivers_Api',
  backend: `Route::get('products/price-report/user/{id}/get-by-receivers', 'PriceReportController@getByReceivers');`,
  root: API_ROOT_URL,
  path:
    '/products/price-report/user/:id/get-by-receivers',
  method: 'GET',
  paramsFields: ['keyword']
}