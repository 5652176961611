import {Input} from "antd"
import {orderRequest_createOrderRequest_Api} from "apis/orderRequest"
import ApiInfoForm from "components/form/ApiInfoForm"
import FieldsFactory from "components/form/FieldsFactory"
import Null from "components/NullComponent"
import useTranslate from "modules/local/useTranslate"
import withTranslate from "modules/local/withTranslate"
import React from "react"
import {compose, mapProps} from "recompose"
import * as Yup from 'yup'
import SpotFormExpanded from "./SpotFormExpanded"
import {RequestFieldTypes} from "./SpotRequestForm"
import {OrderRequestFormFooter} from "./SpotWidgets"

const SpotContactForm = ({
    id,
    type,
    hideModal = Null,
    onSuccess = Null,
    initialValues = {},
    Wrapper = ({ children }) => (children)
}) => {

  const t = useTranslate()

  const requiredFieldError = t('required field')

  const validationSchema = Yup.object().shape(
    {
      [RequestFieldTypes.Sender_email]: Yup.string()
        .email(t('invalid email address'))
        .required(requiredFieldError),
      [RequestFieldTypes.Sender_name]: Yup.string()
        .required(requiredFieldError),
      [RequestFieldTypes.Sender_phone]: Yup.string()
        .required(requiredFieldError),
      [RequestFieldTypes.Message]: Yup.string()
        .required(requiredFieldError),
      [RequestFieldTypes.Sender_country_id]: Yup.number()
        .required(requiredFieldError),
    }
  )

  const formSchema = [
    {
      title: 'detail requirement',
      children: [
        {
          name: RequestFieldTypes.Message,
          component: compose(
            withTranslate,
            mapProps(
              ({ translate, onChange, value, name }) => ({
                name,
                rows: 4,
                value: value,
                onChange,
                className: "rounded w-full",
                placeholder: translate('content')
              })
            )
          )(Input.TextArea)
        }
      ]
    },
    {
      title: '',
      renderTitle: Null,
      ItemWrapper: ({ t, children }) => {
        return (
          <SpotFormExpanded
            title={t('company name (if you buy for the company)')}
            iconClassName="text-color-400 hover:text-color-100 cursor-pointer"
            fields={[ RequestFieldTypes.Sender_organization_name ]}
            className="flex items-center justify-between gap-2 cursor-pointer mb-1">
            {children}
          </SpotFormExpanded>
        )
      },
      children: [
        {
          name: RequestFieldTypes.Sender_organization_name,
          component: compose(
            withTranslate,
            mapProps(
              ({ onChange, translate, value, name }) => ({
                name,
                value,
                onChange,
                className: "rounded-md",
                placeholder: translate('company name (if you buy for the company)'),
              })
            )
          )(Input)
        }
      ]
    },
  ]

  return (
    <ApiInfoForm
      query={{
        ':id': id,
        ':prop': type,
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSuccess={() => {
        onSuccess()
        hideModal()
      }}
      onPreSubmit={values => {
        return {
          ...values
        }
      }}
      initialValues={initialValues}
      validationSchema={validationSchema}
      apiInfo={orderRequest_createOrderRequest_Api}>
      <Wrapper>
        <FieldsFactory
          formSchema={formSchema}
          Wrapper={({ children }) => (
              <div className="flex flex-col gap-1">
                  {children}
              </div>
          )}
          renderTitle={title => (
            <span className="font-medium text-sm text-color-300">
              {title}
            </span>
          )}
        />
        <div className="h-6" />
        <OrderRequestFormFooter onCancel={hideModal} />
      </Wrapper>
    </ApiInfoForm>
  )
}

export default SpotContactForm