import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

function useDispatchAsyncActionOnly() {
  const dispatch = useDispatch()
  const handleDispatchAsyncAction = useCallback(
    action => {
      dispatch(action)
      return action
    },
    [dispatch]
  )
  return handleDispatchAsyncAction
}
export default useDispatchAsyncActionOnly
