import React from "react";

export const Rubber = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 340 340"
		>
			<path
				fill="#e7ab94"
				d="M73.258 142.21c10.334-27.7 9.351-58.413 0-91.35 0 0-12.621-8.666-12.621-20.452V14.964a4.578 4.578 0 014.578-4.578h52.1a5.083 5.083 0 006.983 0h36.362a6.518 6.518 0 006.983 0h15.984a4.578 4.578 0 014.578 4.578V310.5l28.727 19.112h-90.2a6.45 6.45 0 00-6.983 0H70.161a9.524 9.524 0 01-9.524-9.524v-158.3s4.79-16.278 12.621-19.578z"
			></path>
			<path
				fill="#bc735f"
				d="M91.6 278.135a3.491 3.491 0 01-3.492-3.491v-92.486a3.492 3.492 0 016.983 0v92.486a3.491 3.491 0 01-3.491 3.491zM181.1 238.952L136.927 210.7l-38.06-9.408 30.007 22.508a33 33 0 0113.188 25.659l.679 30.323a58.518 58.518 0 0029.3 49.487l.6.341h44.29l-28.726-19.11v-64.78z"
			></path>
			<path
				fill="#473f43"
				d="M151.463 252.265s-2.175 27.027 10.7 49.888c5.935 10.539 17.788 18.725 27.387 22.467a75.378 75.378 0 0027.381 4.994h5.47a56.8 56.8 0 0051.571-32.367c5.3-11.334 8.771-26.281 7.651-45.783z"
			></path>
			<path
				fill="#9b9799"
				d="M276.535 238.293c-23.932-21.455-61.065-19.727-61.065-19.727s-35.98-1.906-60.372 23.246a13.263 13.263 0 00.595 18.953c9.162 8.158 27.21 18.527 59.777 18.527s50.466-7.125 60.051-14.741c9.39-7.462 6.492-21.346 1.014-26.258z"
			></path>
			<path
				fill="#473f43"
				d="M184.705 239.92s8.8-6.491 30.868-6.491c17.215 0 32.149 2.809 44.047 9.248a6.586 6.586 0 01-.147 11.718 72.772 72.772 0 01-8.182 3.424s-53.316 2.439-66.586-17.899z"
			></path>
			<path
				fill="#af705f"
				d="M158.377 216.24c.309-27.419-2.71-63.1-5.227-87.677a59.246 59.246 0 00-27.009-43.85L73.258 50.86v41.931q1.565 6.009 0 10.228v20.574c.686 4.013.818 7.387 0 9.664v8.953l-3.916 12.584c7.211 27.274 89.035 61.446 89.035 61.446z"
			></path>
			<path
				fill="#7f473e"
				d="M131.64 88.7c5.434 8.086 12.115 21.953 13.461 42.474 1.522 23.217 2.92 58.816 3.711 80.843 5.873 2.685 9.565 4.227 9.565 4.227.309-27.419-2.71-63.1-5.228-87.677A59.222 59.222 0 00131.64 88.7z"
			></path>
			<path
				fill="#e9eff4"
				d="M73.258 142.21s-1.475 17.26 10.074 26.838c12.6 10.447 46.247 28.917 77.6 46.136a253.077 253.077 0 0139.5 27.425 17.182 17.182 0 0011.492 3.959c10.115-.317 29.353.073 39.366 11.251 0 0-23.068 8.048-48.454 4.745 0 0-22.01-1.6-27.512-12.973a25.909 25.909 0 00-9.209-10.708c-8.821-5.649-23.8-14.906-38.922-22.733-21.746-11.255-67.131-32.441-68.9-53.48s14.965-20.46 14.965-20.46z"
			></path>
			<g fill="#bc735f">
				<path d="M120.807 58.91a3.492 3.492 0 003.491-3.491V10.386h-6.983v45.033a3.492 3.492 0 003.492 3.491zM164.151 79.9a3.492 3.492 0 003.492-3.491V10.386h-6.983v66.025a3.491 3.491 0 003.491 3.489zM126.731 263.943a3.492 3.492 0 10-6.983 0v65.671h6.983z"></path>
			</g>
			<path
				fill="#7f473e"
				d="M148.785 130.1a3.484 3.484 0 01-2.439-.992 70.94 70.94 0 00-19.518-13.256c-21.47-10.086-39.006-26.986-39.742-27.7a3.491 3.491 0 014.863-5.011c.173.166 17.462 16.813 37.849 26.391a77.928 77.928 0 0121.426 14.578 3.491 3.491 0 01-2.439 5.99zM128.753 143.454a3.493 3.493 0 001.6-6.6c-29.191-15.021-49.748-35.941-57.1-44.065v10.228a215.188 215.188 0 0053.9 40.047 3.48 3.48 0 001.6.39zM119.727 156.269c-20.821-9.133-38.231-24.6-46.469-32.676v9.664c9.619 8.815 25.2 21.308 43.664 29.406a114.476 114.476 0 0132.03 20.5 3.492 3.492 0 104.747-5.122 121.416 121.416 0 00-33.972-21.772z"
			></path>
			<circle cx="164.151" cy="96.068" r="3.553" fill="#bc735f"></circle>
			<circle cx="91.602" cy="292.64" r="3.553" fill="#bc735f"></circle>
		</svg>
	);
}

export default Rubber;
