import { DatePicker, Input, Radio } from 'antd'
import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import { getId } from 'apis/model/base'
import EmailLookupField from 'components/EmailLookupField'
import FieldsFactory from 'components/form/FieldsFactory'
import { FormActionGroups } from 'components/form/FormActionBar'
import { createValue } from 'components/form/utils'
import { Formik } from 'formik'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import { getDateString, parseMoment } from 'helpers/momentDatetime'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, { useMemo } from 'react'
import { IoCalendarOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { compose, mapProps } from 'recompose'
import { GenderTypes } from 'views/AppLocals/appFieldTypes'
import { appClassNames } from 'views/AppLocals/custom/appClassNames'
import { createControlledFormField } from 'views/AppLocals/factory/createFormField'
import { deepTranslate, Null, renderIf, shallowDiff } from 'views/Shared'
import {CalendarAvatar} from "../components/EventBanner";

export const formSchema = {
  contact_email: {
    title: 'email',
    children: [
      {
        name: 'contact_email',
        component: compose(
          withTranslate,
          mapProps(({ name, value, form, translate, onChange }) => ({
            name,
            onChange,
            type: 'email',
            defaultValue: value,
            placeholder: translate('ex: email'),
            handleApply: (user) => {
              const { values, setValues } = form ?? {}
              const { name, email, gender, phone_number, phone2_number } =
                user ?? {}
              const user_info = _.omitBy(
                {
                  email,
                  contact_email: email ?? undefined,
                  gender: _.find(GenderTypes, { value: gender })?.value,
                  name: String(name ?? '').length ? name : undefined,
                  contact_phone: phone_number ?? phone2_number ?? undefined,
                },
                _.isUndefined
              )
              setValues({
                ...(values ?? {}),
                user,
                ...(user_info ?? {}),
              })
            },
          }))
        )(EmailLookupField),
      },
    ],
  },
  name: {
    title: 'name',
    children: [
      {
        name: 'name',
        component: createControlledFormField({
          placeholder: 'name',
        }),
      },
    ],
  },
  contact_phone: {
    title: 'phone',
    children: [
      {
        name: 'contact_phone',
        component: createControlledFormField({
          type: 'tel',
          placeholder: 'ex: phone',
          Component: Input,
        }),
      },
    ],
  },
  invisible: {
    title: 'invisible',
    children: [
      {
        name: 'invisible',
        component: ({ name, value, onChange }) => (
          <Translate>
            {(t) => (
              <Radio.Group
                name={name}
                value={value}
                onChange={onChange}
                buttonStyle="solid"
                optionType="button"
                className="custom-radio-button"
                options={baseFieldTypes.yesno.map(deepTranslate(t))}
              />
            )}
          </Translate>
        ),
      },
    ],
  },
  contact_address: {
    title: 'address',
    children: [
      {
        name: 'contact_address',
        component: createControlledFormField({
          placeholder: 'ex: address',
          Component: Input,
        }),
      },
    ],
  },
  date_of_birth__gender: {
    inline: true,
    className: appClassNames.inline_2,
    children: [
      {
        label: 'date of birth',
        name: 'date_of_birth',
        component: compose(
          withTranslate,
          mapProps(({ name, value, onChange, translate }) => ({
            name,
            value,
            className: 'w-full rounded-md',
            format: 'DD/MM/YYYY',
            suffixIcon: <IoCalendarOutline className="text-color-400"/>,
            placeholder: `${translate('date of birth')} "DD/MM/YYYY"`,
            onChange: (date, dateString) => {
              onChange(createValue(name, date ? moment(new Date(date)) : null))
            },
          }))
        )(DatePicker),
      },
      {
        label: 'gender',
        name: 'gender',
        component: compose(
          withTranslate,
          mapProps(({ name, value, onChange, translate }) => ({
            name,
            value,
            onChange,
            buttonStyle: 'solid',
            className: 'custom-radio-rounded-md',
            children: Object.values(GenderTypes).map(
              ({ name, value, label }) => (
                <Radio.Button
                  key={name}
                  value={value}>
                  {translate(label)}
                </Radio.Button>
              )
            ),
          }))
        )(Radio.Group),
      },
    ],
  },
}

const EventInfo = ({ item }) => {
  const t = useTranslate()
  const { time_open, time_close } = item ?? {}
  return (
    <div className="flex py-3 w-full container mx-auto flex-col">
      <div className="flex items-center gap-3">
        <CalendarAvatar time={time_open} />
        <div className="flex flex-col gap-0 md:gap-1 flex-1">
          <Link
            to={getLinkToDetail(item)}
            className="text-base md:text-xl font-bold text-color-000 hover:text-primary no-underline flex-1">
            {getTitle(item)}
          </Link>
          {renderIf(
            time_open || time_close,
            <div className="flex flex-col md:flex-row gap-x-2 uppercase text-xs md:text-sm text-color-100 opacity-75 font-semibold tracking-wide">
              {time_open && (
                <span>
                  <span className="font-normal capitalize italic opacity-50 mr-2">
                    {t('from')}
                  </span>
                  {moment(time_open).format('hh:mm a - Do MMMM, YYYY')}
                </span>
              )}
              {time_close && (
                <span>
                  <span className="font-normal lowercase italic opacity-50 mr-2">
                    {t('to')}
                  </span>
                  {moment(time_close).format('hh:mm a - Do MMMM, YYYY')}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export const CreateParticipant = ({
  owner,
  onCancel,
  owner_id,
  owner_type,
  isSubmitting,
  onSubmit = Null,
}) => {
  const t = useTranslate()

  const validate = (values) => {
    let errors = {}

    const { name, contact_email } = values ?? {}

    if (_.isEmpty(name ?? '')) {
      errors.name = t('required field')
    }

    if (!contact_email) {
      errors.contact_email = t('required field')
    }

    return errors
  }

  const initialValues = useMemo(
    () => ({
      owner_id,
      owner_type,
      invisible: 0,
    }),
    [owner_id, owner_type]
  )

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values) => {
        const { user, email, owner_id, owner_type, date_of_birth, ...rest } =
          values ?? {}
        onSubmit(
          _.omitBy(
            {
              ...rest,
              user_id: getId(user),
              date_of_birth: getDateString(date_of_birth),
            },
            _.isUndefined
          )
        )
      }}>
      <div className="flex flex-col space-y-3">
        {/*<EventInfo item={owner} />*/}
        <FieldsFactory
          formSchema={[
            formSchema.contact_email,
            formSchema.name,
            formSchema.date_of_birth__gender,
            formSchema.contact_phone,
            formSchema.contact_address,
            formSchema.invisible,
          ]}
        />
        <FormActionGroups
          isSubmitting={isSubmitting}
          submitProps={{
            label: 'create',
          }}
        />
      </div>
    </Formik>
  )
}

export const ApproveParticipant = ({
  item,
  owner,
  onCancel,
  owner_id,
  owner_type,
  isSubmitting,
  onSubmit = Null,
}) => {
  const t = useTranslate()

  const validate = (values) => {
    let errors = {}

    const { name, contact_email } = values ?? {}

    if (_.isEmpty(name ?? '')) {
      errors.name = t('required field')
    }

    if (!contact_email) {
      errors.contact_email = t('required field')
    }

    return errors
  }

  const initialValues = useMemo(() => {
    const { date_of_birth, ...rest } = item ?? {}
    return {
      ...(item ?? {}),
      owner_id,
      owner_type,
      invisible: 0,
      date_of_birth: parseMoment(date_of_birth),
    }
  }, [item, owner_id, owner_type])

  return (
    <Formik
      validate={validate}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values) => {
        const changes = shallowDiff(values, initialValues)
        const { user, email, owner_id, owner_type, date_of_birth, ...rest } =
          changes ?? {}
        onSubmit(
          _.omitBy(
            {
              ...rest,
              user_id: getId(values?.user),
              date_of_birth: getDateString(date_of_birth),
            },
            _.isUndefined
          )
        )
      }}>
      <div className="flex flex-col space-y-3">
        {/*<EventInfo item={owner} />*/}
        <FieldsFactory
          formSchema={[
            formSchema.contact_email,
            formSchema.name,
            formSchema.date_of_birth__gender,
            formSchema.contact_phone,
            formSchema.contact_address,
            formSchema.invisible,
          ]}
        />
        <FormActionGroups
          isSubmitting={isSubmitting}
          submitProps={{
            label: 'approve',
          }}
        />
      </div>
    </Formik>
  )
}
