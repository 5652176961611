import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../../../LoadingPage";

const DeleteLazy = Loadable({
  loader: () => import('./Delete'),
  loading: LoadingPage
})

const Delete = (props) => (
  <DeleteLazy {...props} />
)

export default Delete
