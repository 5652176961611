import { FullscreenOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, {useContext} from 'react'
import useToggle from 'react-use/lib/useToggle'
import ImageLoader from '../ImageLoader'
import ImagesLightBox from '../ImagesLightBox/ImagesLightBox'
import {LayoutContext} from "../layouts/Default/LayoutContext";

export function ImageEmbed({ image }) {
  const [open, toggle] = useToggle()
    const {isSm} = useContext(
        LayoutContext
    )
  return (
    <>
      <div
        onClick={toggle}
        style={{
          maxHeight: 300,
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex'
        }}
        className="relative w-full background-200 rounded-lg overflow-hidden">
        <ImageLoader
          style={{
              minHeight: isSm ? '7em' : '15em',
              height: 'auto',
              maxHeight: 300,
              width: '100%',
              objectFit: 'cover'
          }}
          className="w-full rounded-lg"
          src={image}
        />
        <Button.Group
          size="small"
          className="z-10 absolute bottom-0 right-0 p-4">
          <Button
            style={{
              border: 'none',
              background:
                'rgba(0, 0, 0, 0.53)'
            }}
            type="primary"
            icon={
              <FullscreenOutlined />
            }
          />
        </Button.Group>
      </div>
      {open && (
        <ImagesLightBox
          images={[image]}
          onCloseRequest={toggle}
        />
      )}
    </>
  )
}
