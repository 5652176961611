export const EditAbleBlock = ({
	isToggle,
	placeholder,
	children
}) => {
	if (isToggle) {
		return children;
	}
	return placeholder;
};
