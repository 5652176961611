import {API_ROOT_URL} from 'envs/_current/config'
import {createAsyncAction} from 'modules/asyncCache'
import {normalize, schema} from 'normalizr'
import {baseFieldTypes} from './base/baseFieldTypes'
import {channelSchema, feedSchema, organizationSchema, userSchema,} from './schema'
import {commentSchema} from './schema/commentSchema'
import {eventSchema} from './schema/event'
import {createListTransform} from './utils/createListTransform'
import {createTransform} from './utils/createTransform'
import messageSchema from "./schema/messageSchema";

const auth_organization_schema = new schema.Entity(
  'organizations',
  {
    auth_user: userSchema,
  },
  {
    idAttribute: 'idname',
  }
)

const auth_organization_transform = (response, {query}) => {
  const {entities, result} = normalize(
    response.data,
    auth_organization_schema
  )
  if (Number(response?.status) !== 200) {
    return {
      error: true,
      errorMessages: response?.data,
    }
  } else {
    return {
      response,
      entities,
      result,
    }
  }
}

const fields = [
  'idname', // unique ~ username
  'user_id', // auto fill by login user
  'owner_id', // auto fill by login user
  'owner_type', // auto fill by login user
  'org_type', // 100: company
  'title', // Ten cong ty
  'description', // mo ta cong ty
  'avatar', // avatar
  'thumbnail', // tu gen ra boi avatar (kich thuoc nho hon)
  'cover_photo', // banner
  'status', // auto fill = 1
  'certified', // auto fill = 0
  'privacy', // 1: public, 2: private
  'public', // 1: search allow, 0: no searchable -> Kristo control
  'active', // 1: active (default), 0: inactive -> user control,
  'categories',
  'about', // rich text
]

const organization_FieldTypes = {
  user_id: baseFieldTypes.auto,
  owner_id: baseFieldTypes.auto,
  avatar: baseFieldTypes.image,
  cover_photo: baseFieldTypes.image,
  thumbnail: baseFieldTypes.auto,
  about: baseFieldTypes.string,
  // status: baseFieldTypes.status,
  // active: baseFieldTypes.active,
  // certified: baseFieldTypes.certified,
  // privacy: baseFieldTypes.privacy,
  // public: baseFieldTypes.public
}

const followSchema = new schema.Union(
  {
    organization: organizationSchema,
    channel: channelSchema,
    user: userSchema,
  },
  '_type'
)

// Route::post('organizations/add', 'OrganizationController@store');
export const organization_Create_Api = {
  backend: `Route::post('organizations/add', 'OrganizationController@store')`,
  root: API_ROOT_URL,
  path: '/organizations/add',
  method: 'POST',
  fields,
  schema: organizationSchema,
  fieldTypes: organization_FieldTypes,
  // schema: auth_organization_schema,
  transform: auth_organization_transform,
}

// Route::post('organizations/{id}/edit', 'OrganizationController@update');
export const organization_Edit_Api = {
  backend: `Route::post(''organizations/{id}/edit'', 'OrganizationController@update')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/edit',
  method: 'POST',
  fields,
  schema: organizationSchema,
  fieldTypes: organization_FieldTypes,
  // schema: auth_organization_schema,
  transform: auth_organization_transform,
}
// Route::post('organizations/{id}/delete', 'OrganizationController@deletePollQuestion');
export const organization_Delete_Api = {
  backend: `Route::post(''organizations/{id}/delete'', 'OrganizationController@deletePollQuestion')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/delete',
  method: 'POST',
  fields,
  schema: organizationSchema,
  fieldTypes: organization_FieldTypes,
  // schema: auth_organization_schema,
  transform: auth_organization_transform,
}

// Route::get('organizations/get-typical-organizations', 'OrganizationController@getTypicalOrganizations');
export const organization_GetTypicalOrganizations_Api = {
  backend: `Route::get('organizations/get-typical-organizations', 'OrganizationController@getTypicalOrganizations')`,
  root: API_ROOT_URL,
  path: '/organizations/get-typical-organizations',
  method: 'GET',
  fieldTypes: organization_FieldTypes,
  transform: createListTransform(organizationSchema),
}

// Route::get('organizations/{id}/get-following-users', 'OrganizationController@getFollowingUsers');
export const organization_GetFollowingUsers_Api = {
  backend: `Route::get('organizations/{id}/get-following-users', 'OrganizationController@getFollowingUsers')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/get-following-users',
  method: 'GET',
  fieldTypes: organization_FieldTypes,
  transform: createListTransform(organizationSchema),
}

// Route::get('organizations/{id}/validate-sub-domain', 'OrganizationController@validateSubDomain');
export const organization_validateSubDomain_Api = {
  backend: `Route::get('organizations/{id}/validate-sub-domain', 'OrganizationController@validateSubDomain')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/validate-sub-domain',
  method: 'GET',
  transform: createTransform(organizationSchema, 'data'),
}

// Route::get('organizations/{id}/get', 'OrganizationController@getById');
export const organization_getById_Api = {
  backend: `Route::get('organizations/:id/get', 'OrganizationController@getById')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/get',
  method: 'GET',
  schema: organizationSchema,
  fieldTypes: organization_FieldTypes,
  transform: createTransform(organizationSchema, 'data'),
}
export const create_organization_getById_Api_action =
  () =>
    ({prefixStr = 'organization_getById_Api', query, values, maxAge = -1}) =>
      createAsyncAction({
        prefixStr: prefixStr + '@organization_getById_Api',
        query,
        values,
        apiInfo: organization_getById_Api,
        maxAge,
      })
// Route::get('organizations/{id}/show', 'OrganizationController@show');
export const organization_show_Api = {
  backend: `Route::get('organizations/{id}/show', 'OrganizationController@show')`,
  root: API_ROOT_URL,
  path: '/organizations/:id/show',
  method: 'GET',
  fieldTypes: organization_FieldTypes,
  transform: createTransform(organizationSchema, 'data'),
}

// Route::post('organizations/{id}/request-verify', 'OrganizationController@requestVerifyTaxCode');
export const organization_RequestVerify_Api = {
  backend: `Route::post('organizations/{id}/request-verify', 'OrganizationController@requestVerifyTaxCode');`,
  root: API_ROOT_URL,
  path: '/organizations/:id/request-verify',
  method: 'POST',
  fields,
  fieldTypes: organization_FieldTypes,
  transform: createTransform(organizationSchema, 'data'),
}

export const organization_ExportPurchasePriceData_Api = {
  backend: `Route::post('exports/organization/{id}/export-purchasing-price-data', 'ProductPriceController@exportPurchasePriceData')`,
  root: API_ROOT_URL,
  path: '/exports/organization/:id/export-purchasing-price-data',
  method: 'POST',
  fields,
  fieldTypes: organization_FieldTypes,
}

export const organization_ExportPriceData_Api = {
  backend: `Route::post('exports/organization/{id}/export-price-data', 'ProductPriceController@exportPriceData')`,
  root: API_ROOT_URL,
  path: '/exports/organization/:id/export-price-data',
  method: 'POST',
  fields,
  fieldTypes: organization_FieldTypes,
}

export const organization_fetchLatestFeeds_Api = {
  backend: `Route::get('organizations/{id}/insights/get-latest-feeds', 'OrganizationInsightsController@fetchLatestFeeds'); // content_type: article,ask,post,repost,poll_question`,
  root: API_ROOT_URL,
  path: '/organizations/:id/insights/get-latest-feeds',
  method: 'GET',
  paramsFields: ['content_type'],
  schema: feedSchema,
  transform: createListTransform(feedSchema),
}

export const organization_getOrganizationsByUser_Api = {
  backend: `Route::get('organizations/user/{id}/gets', 'OrganizationController@getOrganizationsByUser');`,
  root: API_ROOT_URL,
  path: '/organizations/user/:id/gets',
  method: 'GET',
  paramsFields: ['keyword', 'role'],
  schema: organizationSchema,
  transform: createListTransform(organizationSchema),
}

export const organization_fetchLatestFollowers_Api = {
  backend: `Route::get('organizations/{id}/insights/get-latest-followers', 'OrganizationInsightsController@fetchLatestFollowers');`,
  root: API_ROOT_URL,
  path: '/organizations/:id/insights/get-latest-followers',
  method: 'GET',
  schema: followSchema,
  transform: createListTransform(followSchema),
}

export const organization_fetchLatestComments_Api = {
  backend: `Route::get('organizations/{id}/insights/get-latest-comments', 'OrganizationInsightsController@fetchLatestComments');`,
  root: API_ROOT_URL,
  path: '/organizations/:id/insights/get-latest-comments',
  method: 'GET',
  schema: commentSchema,
  transform: createListTransform(
    commentSchema
  )
}

export const organization_fetchLatestMessage_Api = {
  backend: `Route::get('organizations/{id}/insights/get-latest-messages', 'OrganizationInsightsController@fetchLatestMessages');`,
  root: API_ROOT_URL,
  path:
    '/organizations/:id/insights/get-latest-messages',
  method: 'GET',
  schema: messageSchema,
  transform: createListTransform(
    messageSchema
  )
}

export const organization_fetchStatistics_Api = {
  backend: `Route::get('organizations/{id}/insights/get-statistics', 'OrganizationInsightsController@fetchStatistics');`,
  root: API_ROOT_URL,
  path: '/organizations/:id/insights/get-statistics',
  method: 'GET',
  paramsFields: ['period'],
}

export const organization_getEventsByHost_Api = {
  backend: `Route::get('organization/{organization_id}/hosts/get-event-by-host', 'OrganizationHostsController@getEventsByHost');`,
  root: API_ROOT_URL,
  path: '/organization/:organization_id/hosts/get-event-by-host',
  method: 'GET',
  paramsFields: ['status', 'sort_by', 'keyword', 'organization_id'],
  schema: eventSchema,
  transform: createListTransform(eventSchema),
}

export const organization_getOrgMembersSiteMaps_Api = {
  backend: `API: Route::get('organization/{organization_id}/hosts/get-site-map', 'OrganizationHostsController@getOrgMembersSiteMaps');`,
  root: API_ROOT_URL,
  path: '/organization/:organization_id/hosts/get-site-map',
  method: 'GET',
}

export const organization_fetchRecentlyChannels_Api = {
  backend: `Route::get('organizations/{id}/insights/get-recently-channels', 'OrganizationInsightsController@fetchRecentlyChannels');`,
  root: API_ROOT_URL,
  path:
    '/organizations/:id/insights/get-recently-channels',
  method: 'GET'
}

export const organization_fetchRecentlyProducts_Api = {
  backend: `Route::get('organizations/{id}/insights/get-recently-products', 'OrganizationInsightsController@fetchRecentlyProducts');`,
  root: API_ROOT_URL,
  path:
    '/organizations/:id/insights/get-recently-products',
  method: 'GET'
}

export const organization_fetchRecentlyDatasets_Api = {
  backend: `Route::get('organizations/{id}/insights/get-recently-datasets', 'OrganizationInsightsController@fetchRecentlyDatasets');`,
  root: API_ROOT_URL,
  path:
    '/organizations/:id/insights/get-recently-datasets',
  method: 'GET'
}
