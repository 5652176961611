import EyeInvisibleOutlined from '@ant-design/icons/lib/icons/EyeInvisibleOutlined'
import EyeTwoTone from '@ant-design/icons/lib/icons/EyeTwoTone'
import {Button, Input, message} from 'antd'
import {auth_login_Api} from 'apis'
import FieldDecorator from 'components/form/FieldDecorator'
import CryptoJS from 'crypto-js'
import {API_ROOT_URL} from 'envs/_current/config'
import {Form, Formik} from 'formik'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {loginSuccess} from 'redux/actions'
import * as Yup from 'yup'

export const useCryptoJS = (customkey) => {
  const key = customkey ?? `__${API_ROOT_URL}_sercetkey__`

  let secSpec = CryptoJS.enc.Utf8.parse(key)
  let ivSpec = CryptoJS.enc.Utf8.parse(key)

  secSpec = CryptoJS.lib.WordArray.create(secSpec.words.slice(0, 16 / 4))
  ivSpec = CryptoJS.lib.WordArray.create(ivSpec.words.slice(0, 16 / 4))

  const encrypt = (text) => {
    if (_.isEmpty(text)) {
      return ''
    }

    const plaintext = CryptoJS.enc.Utf8.parse(text)

    return CryptoJS.AES.encrypt(plaintext, secSpec, {iv: ivSpec}).toString()
  }

  const decrypt = (encrypted) =>
    _.isEmpty(encrypted)
      ? ''
      : CryptoJS.AES.decrypt(encrypted, secSpec, {iv: ivSpec}).toString(
      CryptoJS.enc.Utf8
      )

  return {
    encrypt,
    decrypt,
  }
}

const LoginForm = () => {
  const t = useTranslate()
  const dispatch = useDispatch()
  const {isLoading, handleAsyncAction} = useAsyncAction({
    apiInfo: auth_login_Api,
    onSuccess: (...args) => {
      dispatch(loginSuccess(_.get(args, '1.response.data')))
    },
    onError: (...args) => message.error(_.get(args, '1.error')),
  })

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={handleAsyncAction}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('invalid email address'))
          .required(t('Required')),
        password: Yup.string()
          .min(8, t('Password must have min 8 characters'))
          .required(t('password required')),
      })}>
      {(props) => {
        const {
          isValid,
          values,
          isSubmitting,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props
        return (
          <Form
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                event.preventDefault()
                handleSubmit()
              }
            }}
            onSubmit={handleSubmit}
            className="verticalList">
            <FieldDecorator
              isRequired={true}
              error={[errors.email]}
              errors={errors.email}>
              <Input
                className="rounded-md"
                size="large"
                inputMode="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                type="text"
                placeholder={t('email account')}
              />
            </FieldDecorator>
            <FieldDecorator
              isRequired={true}
              errors={[errors.password]}>
              <Input.Password
                className="rounded-md"
                size="large"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                placeholder={t('password')}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                }
              />
            </FieldDecorator>
            <div className="w-full text-center">
              <Button
                loading={isLoading}
                onClick={handleSubmit}
                size="large"
                type="primary"
                className="button-rounded-lg px-10">
                {t('login')}
              </Button>
              <div className="text-right text-xs italic text-color-500 mt-3">
                <Link
                  to={{
                    pathname: '/forgotpassword',
                    state: {isModal: true},
                  }}>
                  {t('forgotpass')}
                </Link>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default LoginForm
