import { BackTop, Layout } from 'antd'
import React from 'react'

export const NormalLayout = ({ children, left, bottom }) => {
  return (
    <Layout className="NormalLayout w-full min-h-screen background">
      <Layout
        className="background"
        style={{
          flexDirection: 'row',
        }}>
        {left}
        {children}
      </Layout>
      {bottom}
      <BackTop
        style={{
          bottom: 'calc(2 * var(--header-height) + 18px)',
          right: 18,
          zIndex: -1,
        }}
      />
    </Layout>
  )
}
