import { feed_fetchFeedByContainer_Api } from 'apis'
import { getId, getType } from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import _ from 'lodash'
import React, { useContext, useMemo } from 'react'
import EntityList from 'views/AppLocals/EntityList'
import { bindQueryParams } from 'views/AppLocals/functions/routerHelper'
import { renderFeedItem } from 'views/MainPage/functions/renderFeedItem'
import UserContext from '../UserContext'

const UserNews = ({ Wrapper = 'div' }) => {
  const { user: item } = useContext(UserContext)

  const [id, prop] = [getId(item), getType(item)]

  const apiInfo = feed_fetchFeedByContainer_Api

  const lookupKey = useMemo(
    () => [apiInfo.path.replace(':id', id).replace(':prop', prop)].join('/'),
    [id, prop, apiInfo]
  )

  if (_.isEmpty(item)) return null

  return (
    <Wrapper className="space-y-3">
      <EntityList
        apiInfo={apiInfo}
        lookupKey={lookupKey}
        query={bindQueryParams([{ id }, { prop }])}
        renderItem={renderFeedItem}
        RenderEntity={React.Fragment}
        renderEmpty={() => (
          <div className="w-full justify-center items-center">
            <EmptyHolder />
          </div>
        )}
      />
    </Wrapper>
  )
}

export default UserNews
