import {
  useEffect,
  useRef,
  useState
} from 'react'

export const Autosizer = ({
  initialDom = {
    clientWidth: 0,
    clientHeight: 0
  },
  children,
  auto = true
}) => {
  const ref = useRef(initialDom)
  const [update, setUpdate] = useState()
  useEffect(() => {
    if (auto) {
      const onUpdate = () => {
        setUpdate(Date.now())
      }
      window.addEventListener(
        'resize',
        onUpdate
      )
      return () =>
        window.removeEventListener(
          'resize',
          onUpdate
        )
    }
  }, [auto])
  return children([
    ref,
    {
      width: ref.current.clientWidth,
      height: ref.current.clientHeight
    }
  ])
}
