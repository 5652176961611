import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Menu, Modal } from 'antd'
import { comment_removeComment_Api } from 'apis'
import DrawMenu from 'components/drawer/DrawMenu'
import Count from 'components/Feed/Count'
import { createAsyncAction } from 'modules/asyncCache'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { BsPencilSquare, BsThreeDotsVertical, BsTrash } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/AppLocals/factory/createNotification'
import { Null } from 'views/Shared'
import {RiMoreFill} from "react-icons/ri";
import {PiTrash} from "react-icons/pi";

const menuOptions = {
  edit: {
    label: 'edit',
    value: 'edit',
    icon: <BsPencilSquare />,
  },
  delete: {
    label: 'delete',
    value: 'delete',
    icon: <BsTrash />,
  },
}

const ResponsesContextMenu = ({ item = {}, onEdit = Null }) => {
  const t = useTranslate()

  const dispatch = useDispatch()

  const handleSelect =
    ({ onDismiss = Null }) =>
    ({ key }) => {
      switch (key) {
        case menuOptions.edit.value:
          onEdit()
          break
        case menuOptions.delete.value:
          Modal.confirm({
            icon: <PiTrash className="anticon"/>,
            title: t('are you sure delete this comment'),
            className: 'custom-modal',
            okText: t('sure'),
            cancelText: t('ignore'),
            okButtonProps: {
              className: 'no-shadow no-text-shadow no-border rounded-lg',
              type: 'primary',
              danger: true,
            },
            cancelButtonProps: {
              className: 'no-shadow no-text-shadow rounded-lg no-border',
            },
            onOk: () => {
              dispatch(
                createAsyncAction({
                  apiInfo: comment_removeComment_Api,
                  query: {
                    ':prop': item.container_type,
                    ':id': item.container_id,
                    ':comment_id': item.id,
                  },
                  onSuccess: () => {
                    successNotify(NotificationActionTypes.DELETE, t)
                  },
                })
              )
            },
          })
          break
        default:
          break
      }
      onDismiss()
    }

  if (!!!item.edit) return null

  return (
    <React.Fragment>
      <DrawMenu component={<Count style={{ right: '-0.2em' }} className="extraIcon mt-0" component={RiMoreFill} />}>
        {(isToggle, toggle) => (
          <Menu
            className="custom-menu-mobile"
            onClick={handleSelect({ onDismiss: toggle })}>
            {[menuOptions.edit, menuOptions.delete].map(
              ({ icon, label, value }) => (
                <Menu.Item
                  key={value}
                  icon={icon}>
                  <span className="text-center font-medium">{t(label)}</span>
                </Menu.Item>
              )
            )}
          </Menu>
        )}
      </DrawMenu>
    </React.Fragment>
  )
}

export default ResponsesContextMenu
