import {BellOutlined, LogoutOutlined, SettingOutlined, UserAddOutlined,} from '@ant-design/icons'
import HomeOutlined from '@ant-design/icons/lib/icons/HomeOutlined'
import {
  Alert,
  Avatar,
  Button,
  DatePicker,
  Divider,
  Input,
  Menu,
  Modal,
  notification,
  Popconfirm,
  Steps,
  Switch,
} from 'antd'
import TextArea from 'antd/es/input/TextArea'
import {eventModel} from 'apis/model'
import Count from 'components/Feed/Count'
import ContentLayout from 'components/layouts/Default/ContentLayout'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {UseHook} from 'components/UseReducer'
import mapParams from 'helpers/hocs/mapParams'
import preventParentEvent from 'helpers/preventParentEvent'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import * as moment from 'moment'
import React, {useContext, useMemo, useState} from 'react'
import {AiFillCaretLeft, AiOutlineArrowRight, AiOutlineMinusCircle, AiOutlinePlusCircle,} from 'react-icons/ai'
import {BsCalendar3, BsPersonFill, BsTrash} from 'react-icons/bs'
import {FaCircle, FaPlayCircle, FaPlusCircle, FaRegDotCircle, FaStopCircle,} from 'react-icons/fa'
import {FiEdit2} from 'react-icons/fi'
import {VscArrowLeft} from 'react-icons/vsc'
import {Link} from 'react-router-dom'
import {useSearchParam} from 'react-use'
import {nest, withProps} from 'recompose'
import {notifyOnError} from 'views/AppLocals/factory/createErrorEvent'
import ServerContext from 'views/Server/ServerContext'
import SlateEditor, {SlateRenderer} from 'views/SlateEditor'
import {convertFromString, convertToString, findNodes,} from 'views/SlateEditor/functions'
import {ElementTypes} from 'views/SlateEditor/types'
import {event_delete_Api, event_edit_Api} from '../../apis/event'
import {eventSchema} from '../../apis/schema/event'
import ApiInfoForm from '../../components/form/ApiInfoForm'
import FieldsFactory from '../../components/form/FieldsFactory'
import FormActionBar from '../../components/form/FormActionBar'
import {createValue} from '../../components/form/utils'
import {LoginContext} from '../../components/LoginContext'
import LookupField from '../../components/LookupField'
import Toggle from '../../components/Toggle'
import UseState from '../../components/UseState'
import AsyncAction from '../../modules/asyncCache/components/AsyncAction'
import {useHistory} from '../../modules/navigation/useRouter'
import {MainPageHeader} from '../Organization/components/MainPageHeader'
import ServerContainer from '../Server/ServerContainer'
import {useRequiredLogin} from '../Server/useRequiredLogin'
import EventOverviewSetting, {EventOverviewFieldSet,} from './components/EventOverviewSetting'
import EventContext from './EventContext'
import {EventProvider} from './EventProvider'
import {routes} from './routes'

const {Step} = Steps

export const CustomInputTextAreaField = ({label, ...props}) => (
  <div className="flex flex-col border border-dashed background-100 rounded-md">
    <Input.TextArea {...props} />
  </div>
)

export const TimelineSpeakersField = ({label, onChange, ...props}) => {
  const t = useTranslate()

  const initSpeakers = _.get(props, 'defaultValues', [])

  return (
    <div className="flex flex-col">
      <UseState
        initialValue={initSpeakers.map((e, remapIndex) => ({
          ...e,
          key: remapIndex,
        }))}>
        {([speakers, setSpeakers]) => {
          return (
            <div className="flex flex-col space-y-3">
              {speakers.map((e, index) => (
                <div
                  key={index}
                  className="flex items-center gap-1 background rounded-md p-2">
                  <Avatar
                    size={25}
                    className="flex flex-center background-100"
                    icon={
                      <BsPersonFill
                        size={15}
                        className="text-color-400"
                      />
                    }
                    src={_.get(e, 'avatar', null)}
                  />
                  <div className="font-medium text-xs text-color-000 flex-1">
                    {_.get(e, 'speaker.name') ||
                    _.get(e, 'speaker_name') ||
                    _.get(e, 'value')}
                  </div>

                  <BsTrash
                    onClick={() => {
                      const _speakers = speakers.filter(
                        (el) => el.key !== index
                      )
                      onChange(_speakers)
                      setSpeakers(_speakers)
                    }}
                    className="cursor-pointer text-red-300 hover:text-red-600 text-xs"
                  />
                </div>
              ))}
              <div className="flex flex-col py-1">
                {label && (
                  <div className="text-xs text-color-300">{t(label)}</div>
                )}
                <LookupField
                  apiPath={`/lookup/user/get-by-types`}
                  {...props}
                  handleChange={(e) => {
                    onChange([
                      ...speakers,
                      {...e.target, speaker_name: e.target.value},
                    ])
                    setSpeakers([
                      ...speakers,
                      {...e.target, speaker_name: e.target.value},
                    ])
                  }}
                />
              </div>
            </div>
          )
        }}
      </UseState>
    </div>
  )
}

export const listHolder = (
  <div className="flex flex-col">
    {new Array(2).fill(1).map((item, i) => (
      <div
        key={'listHolder' + i}
        className="mb-3">
        <div className="flex text-nowrap items-center py-1">
          <Avatar
            size={16}
            style={{
              marginRight: '0.2em',
              background: '#f1f1f1',
              color: '#545454',
              fontWeight: 'bold',
            }}
            src={''}
            shape="circle"
          />
          <span
            style={{
              textOverflow: 'ellipsis',
            }}
            className="font-bold whitespace-no-wrap text-xs text-color-500">
            ####
          </span>
        </div>
        <div
          className="background-200 border-gray-300"
          style={{
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            height: '284px',

            borderRadius: '0.3em',
          }}
        />
      </div>
    ))}
  </div>
)

const TimetableEditor = ({
                           name,
                           item,
                           itemIndex,
                           onChange,
                           isInsert,
                           ...props
                         }) => {
  const t = useTranslate()
  const values = _.get(props, 'form.values', {})

  const [editorState, setEditorState] = useState(() =>
    convertFromString(item?.description)
  )

  const imageSrcList = useMemo(
    () => findNodes(editorState, ElementTypes.IMAGE),
    [editorState]
  )

  return (
    <UseState
      initialValue={{
        isUpdating: false,
        ...item,
        start_time: moment(item.start_time),
        finish_time: !!item.finish_time ? moment(item.finish_time) : null,
      }}>
      {([updateItem, setUpdateItem]) => (
        <div className="flex flex-col w-full">
          <Input
            hidden={true}
            name="timetables"
          />
          <div className="flex flex-col mb-2">
            <div className="text-xs italic font-light text-color-300 leading-tight mb-1">
              {t('start time')}:
            </div>
            <div className="flex items-center gap-3">
              <DatePicker
                showTime
                className="flex-1 rounded-md"
                name="start_time"
                placeholder={t('Select time')}
                defaultValue={updateItem.start_time}
                suffixIcon={<BsCalendar3/>}
                onChange={(value) => {
                  setUpdateItem({
                    ...updateItem,
                    start_time: !!value ? value.utc().format() : null,
                  })
                }}
              />
              <Switch
                checkedChildren={t('milestone')}
                onChange={(checked) => {
                  setUpdateItem({
                    ...updateItem,
                    milestone: checked ? 1 : 0,
                  })
                }}
                unCheckedChildren={t('update')}
                defaultChecked={updateItem.milestone === 1}
              />
            </div>
          </div>

          <Toggle defaultValue={!!updateItem.finish_time}>
            {(isToggle, toggle) => (
              <React.Fragment>
                {!isToggle ? (
                  <div
                    className="flex items-center text-primary space-x-3 cursor-pointer mb-3"
                    onClick={toggle}>
                    <AiOutlinePlusCircle size={12}/>
                    <div className="text-sm leading-tight">
                      {t('finish time')}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col mb-3 mt-1">
                    <div
                      className="flex items-center text-primary space-x-3 cursor-pointer mb-1"
                      onClick={toggle}>
                      <AiOutlineMinusCircle size={12}/>
                      <div className="text-sm leading-tight">
                        {t('finish time')}
                      </div>
                    </div>
                    <DatePicker
                      showTime
                      className="flex-1"
                      placeholder={t('Select time')}
                      defaultValue={moment(values.finish_time)}
                      suffixIcon={<BsCalendar3/>}
                      onChange={(value) => {
                        setUpdateItem({
                          ...updateItem,
                          finish_time: value,
                        })
                      }}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </Toggle>

          <div className="flex flex-col mb-3">
            <div className="text-xs italic font-light text-color-300 leading-tight mb-1">
              {t('title')}:
            </div>
            <Input
              name="title"
              defaultValue={updateItem.title}
              onChange={(e) => {
                setUpdateItem({
                  ...updateItem,
                  title: e.target.value,
                })
              }}
            />
          </div>

          <Toggle defaultValue={!!_.get(updateItem, 'speakers.length')}>
            {(isToggle, toggle) => (
              <React.Fragment>
                {!isToggle ? (
                  <div
                    className="flex items-center text-primary space-x-3 cursor-pointer mb-3"
                    onClick={toggle}>
                    <AiOutlinePlusCircle size={12}/>
                    <div className="text-sm leading-tight">{t('speakers')}</div>
                  </div>
                ) : (
                  <div className="flex flex-col mb-3">
                    <div
                      className="flex items-center text-primary space-x-3 cursor-pointer mb-1"
                      onClick={toggle}>
                      <AiOutlineMinusCircle size={12}/>
                      <div className="text-sm leadinevent_descriptiong-tight">
                        {t('speakers')}
                      </div>
                    </div>
                    <FieldsFactory
                      formSchema={[
                        {
                          children: {
                            type: 'text',
                            name: 'speakers',
                            // label: t('speakers'),
                            component: TimelineSpeakersField,
                            withProps: {
                              bordered: false,
                              type: 'text',
                              // name: 'speakers',
                              size: 'large',
                              placeholder: t('select user'),
                              defaultValues: updateItem.speakers,
                              onChange: (values) => {
                                setUpdateItem({
                                  ...updateItem,
                                  speakers: values.map((e) => ({
                                    speaker_name: e.value,
                                    ...e,
                                  })),
                                })
                              },
                            },
                          },
                        },
                      ]}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </Toggle>

          <Toggle defaultValue={!!_.get(updateItem, 'description')}>
            {(isToggle0, toggle0) => (
              <React.Fragment>
                {!isToggle0 ? (
                  <div
                    className="flex items-center text-primary space-x-3 cursor-pointer"
                    onClick={toggle0}>
                    <AiOutlinePlusCircle size={12}/>
                    <div className="text-sm leading-tight">
                      {t('description')}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col mb-3">
                    <div
                      className="flex items-center text-primary space-x-3 cursor-pointer mb-1"
                      onClick={toggle0}>
                      <AiOutlineMinusCircle size={12}/>
                      <div className="text-sm leading-tight">
                        {t('description')}
                      </div>
                    </div>
                    <Toggle>
                      {(isToggle, toggle) => {
                        return (
                          <div className="flex flex-col space-y-1">
                            <div className="flex items-center mt-1 mb-1">
                              <div className="flex-1 text-sm text-color-300 font-semibold uppercase">
                                {t('description')}
                              </div>
                              <div
                                className="text-primary cursor-pointer px-3 py-1 border border-primary border-dashed rounded-full"
                                onClick={toggle}>
                                <div className="font-medium text-xs leading-tight">
                                  {t('edit')}
                                </div>
                              </div>
                            </div>
                            <div className="border background px-3 rounded-lg">
                              <SlateRenderer
                                key={isToggle}
                                value={editorState}
                              />
                            </div>
                            <Modal
                              onCancel={toggle}
                              maskClosable={false}
                              className="custom-modal space-y-10"
                              title={
                                <div className="text-center font-semibold text-color-000 text-sm">
                                  {t('edit')}
                                </div>
                              }
                              destroyOnClose
                              visible={isToggle}
                              okText={t('save')}
                              cancelText={t('cancel')}
                              okButtonProps={{
                                type: 'primary',
                                className:
                                  'rounded-lg no-shadow no-text-shadow no-border',
                              }}
                              cancelButtonProps={{
                                className:
                                  'rounded-lg no-shadow no-text-shadow no-border',
                              }}
                              onOk={() => {
                                toggle()
                              }}>
                              <SlateEditor
                                name="description"
                                defaultValue={editorState}
                                onChange={(event) => {
                                  setEditorState(event?.target?.value)
                                }}
                              />
                            </Modal>
                          </div>
                        )
                      }}
                    </Toggle>
                  </div>
                )}
              </React.Fragment>
            )}
          </Toggle>

          <div className="flex space-x-3">
            <div className="flex-1"/>
            {!!!isInsert && (
              <Popconfirm
                placement="bottomLeft"
                title={t('remove this?')}
                onConfirm={() => {
                  let _timetableTemp = _.get(values, 'timetables', []).filter(
                    (e) => e.id !== updateItem.id
                  )
                  onChange(createValue('timetables', _timetableTemp))
                }}
                okText={t('sure')}
                cancelText={t('no')}
                okButtonProps={{
                  className:
                    'button-rounded-full no-border text-xs font-medium',
                }}
                cancelButtonProps={{
                  className:
                    'button-rounded-full no-border text-xs font-medium',
                }}>
                <Button
                  danger
                  type={'primary'}
                  size="small"
                  className="button-rounded-full no-border text-xs font-medium">
                  {t('remove')}
                </Button>
              </Popconfirm>
            )}
            <Button
              type={'primary'}
              size="small"
              className="button-rounded-full no-border text-xs font-medium"
              onClick={() => {
                let _updateItem = updateItem
                if (
                  !!_updateItem.start_time &&
                  moment.isMoment(_updateItem.start_time)
                )
                  _updateItem.start_time = _updateItem.start_time.utc().format()
                if (
                  !!_updateItem.finish_time &&
                  moment.isMoment(_updateItem.finish_time)
                )
                  _updateItem.finish_time = _updateItem.finish_time
                    .utc()
                    .format()

                _updateItem.description = convertToString(editorState)

                _updateItem.photo_ids_string = _.join(
                  _.map(imageSrcList, 'imageId'),
                  ','
                )

                if (!!_updateItem.isInsert) delete _updateItem.isInsert
                if (!!_updateItem.active) delete _updateItem.active
                if (!!_updateItem.isUpdating) delete _updateItem.isUpdating

                let _timetableTemp = values.timetables
                _timetableTemp[itemIndex] = {
                  ..._updateItem,
                }

                // insert 1 default new
                if (!!isInsert) {
                  _timetableTemp.splice(itemIndex + 1, 0, {
                    isInsert: true,
                    active: true,
                  })
                }

                onChange(createValue('timetables', _timetableTemp))
                onChange(createValue('update', Date.now()))
              }}>
              {t('update')}
            </Button>
          </div>
        </div>
      )}
    </UseState>
  )
}

const EventSetting = ({id}) => {
  const t = useTranslate()
  const login = useContext(LoginContext)
  const history = useHistory()
  const {item, newPosts, handleSetViewedPosts, handleActionClick} =
    useContext(ServerContext)
  const handleRequiredLogin = useRequiredLogin()
  const {categories = [], description} = item
  const shareUrl = eventModel.getLinkToShare(item)
  const permissions = useMemo(
    () => ({
      canPostArticle: true,
    }),
    []
  )

  const initialValues = {
    title: item.title,
    video_url: item.video_url,
    pdf_path: item.pdf_path,
    idname: item.idname,
    privacy: item.privacy,
    event_type: item.event_type,
    time_open: item.time_open,
    start_date: moment(item.time_open),
    start_time: moment(item.time_open),
    time_close: item.time_close,
    end_date: !!item.time_close ? moment(item.time_close) : null,
    end_time: !!item.time_close ? moment(item.time_close) : null,
    language: item.language,
    interval_type: item.interval_type || 'N',
    interval_daily: item.interval_daily || '0000000',
    interval_monthly: item.interval_monthly || 1,
    registration_enabled: item.registration_enabled || 0,
    interval_yearly: moment(item.interval_monthly) || moment(),
    location_name: item.location_name,
    external_link: item.external_link,
    categories: (item.categories || []).map(({id}) => id),
    categoryOptions: item.categories || [],
    description: item.description,
    schedules: _.get(item, 'schedules', []).map((el) => {
      return moment(el.start_time)
    }),
    co_hosts: _.get(item, 'hosts', []).map((el) => {
      return {
        _type: _.get(el, 'host._type'),
        id: _.get(el, 'host.id'),
        value:
          _.get(el, 'host_name') ||
          _.get(el, 'host.name', _.get(el, 'host.title')),
        avatar: _.get(el, 'host.avatar'),
      }
    }),
    co_admins: _.get(item, 'admins', []).map((el) => ({
      _type: _.get(el, 'user._type'),
      id: _.get(el, 'user.id'),
      value: _.get(el, 'user.name'),
      avatar: _.get(el, 'user.avatar'),
      editable: !(
        _.get(el, 'user.id') === (login && login.id) ||
        _.get(el, 'role_id') == 1
      ),
    })),
  }

  const [editorState, setEditorState] = useState(() =>
    convertFromString(item?.about)
  )

  return (
    <PermissionProvider permissions={permissions}>
      {_.get(item, 'warnings', []).map((e, i) => (
        <Alert
          key={i}
          type="warning"
          closable={true}
          banner
          message={
            <div className="flex flex-col space-y-3">
              <div className="flex items-center text-color-300">
                <div className="mr-3 flex-1">
                  <div className="font-bold">{e.title}</div>
                  <div>{e.description}</div>
                </div>
              </div>
              <div className="flex items-center justify-center">
                {_.get(e, '_type') === 'login_required' && (
                  <Link
                    to={'/login'}
                    className="border rounded-full px-6 py-2 bg-primary text-white">
                    {t('Login now')}
                  </Link>
                )}
              </div>
            </div>
          }
        />
      ))}

      <ApiInfoForm
        initialValues={initialValues}
        query={{
          ':id': item.id,
        }}
        onPreSubmit={(values) => {
          let _values = values
          if (!!values.co_hosts) {
            _values = {
              ..._values,
              co_hosts: JSON.stringify(
                values.co_hosts.map((e) => {
                  return {type: e._type, id: e.id, host_name: e.value}
                })
              ),
            }
          }
          if (!!values.co_admins) {
            _values = {
              ..._values,
              co_admins: JSON.stringify(
                values.co_admins.map((e) => {
                  return e.id
                })
              ),
            }
          }
          if (!!values.speakers) {
            _values = {
              ..._values,
              speakers: JSON.stringify(
                values.speakers.map((e) => {
                  return {type: e._type, id: e.id, speaker_name: e.value}
                })
              ),
            }
          }

          if (!!values.schedules) {
            _values = {
              ..._values,
              schedules: JSON.stringify(
                values.schedules.map((e) => {
                  return e.format('YYYY-MM-DD')
                })
              ),
            }
          }

          if (
            !!values.start_date &&
            moment.isMoment(values.start_date) &&
            !!values.start_time &&
            moment.isMoment(values.start_time)
          ) {
            _values = {
              ..._values,
              time_open: moment(
                values.start_date.format('YYYY-MM-DD') +
                ' ' +
                values.start_time.format('HH:mm')
              )
                .utc()
                .format(),
            }
          }
          if (
            !!values.end_date &&
            moment.isMoment(values.end_date) &&
            !!values.end_time &&
            moment.isMoment(values.end_time)
          ) {
            _values = {
              ..._values,
              time_close: moment(
                values.end_date.format('YYYY-MM-DD') +
                ' ' +
                values.end_time.format('HH:mm')
              )
                .utc()
                .format(),
            }
          }

          if (!!values.timetables) {
            _values = {
              ..._values,
              timetables: JSON.stringify(
                values.timetables.filter((el) => !!!el.isInsert)
              ),
            }
          }

          _values.about = convertToString(editorState)

          _values.description = values.description

          const imageSrcList = findNodes(editorState, ElementTypes.IMAGE)

          _values.photo_ids_string = _.join(_.map(imageSrcList, 'imageId'), ',')

          return {
            ..._values,
          }
        }}
        apiInfo={event_edit_Api}>
        {({
            isSubmitting,
            dirty,
            isValid,
            values = {},
            validate,

            ...props
          }) => {
          return (
            <React.Fragment>
              <LayoutContext.Consumer>
                {({isSm}) => isSm && <EventOverviewFieldSet/>}
              </LayoutContext.Consumer>
              <div className="px-3 flex flex-col gap-1">
                <div className="flex-1 font-semibold text-sm uppercase text-color-400">
                  {t('short description')}
                </div>
                <TextArea
                  rows={5}
                  name={'description'}
                  defaultValue={description}
                  onChange={(e) => {
                    props.handleChange(
                      createValue('description', e.target.value)
                    )
                  }}
                />
              </div>
              <div className="px-3 w-full py-5 space-y-3">
                <Toggle>
                  {(isToggle, toggle) => {
                    return (
                      <div className="flex flex-col space-y-1">
                        <div className="flex items-center">
                          <div className="flex-1 font-semibold text-sm uppercase text-color-400">
                            {t('about event')}
                          </div>
                          <Button
                            ghost={true}
                            type="primary"
                            className="button-rounded-full text-xs font-medium"
                            size={'small'}
                            onClick={toggle}>
                            {t('edit')}
                          </Button>
                        </div>
                        <SlateRenderer
                          key={isToggle}
                          value={editorState}
                        />

                        <Modal
                          onCancel={toggle}
                          maskClosable={false}
                          className="space-y-10"
                          title={
                            <div className="text-center font-semibold uppercase text-color-000">
                              {t('about event')}
                            </div>
                          }
                          destroyOnClose
                          visible={isToggle}
                          okText={t('save')}
                          cancelText={t('cancel')}
                          okButtonProps={{
                            type: 'primary',
                            className: 'button-rounded-lg no-border',
                          }}
                          cancelButtonProps={{
                            className: 'button-rounded-lg no-border',
                          }}
                          onOk={() => {
                            props.handleChange(
                              createValue('about', convertToString(editorState))
                            )
                            toggle()
                          }}>
                          <SlateEditor
                            name="about"
                            defaultValue={editorState}
                            onChange={(event) => {
                              setEditorState(event?.target?.value)
                            }}
                          />
                        </Modal>
                      </div>
                    )
                  }}
                </Toggle>
                <FormActionBar/>
              </div>
            </React.Fragment>
          )
        }}
      </ApiInfoForm>
    </PermissionProvider>
  )
}

const EventTimetableSetting = ({id}) => {
  const t = useTranslate()
  const login = useContext(LoginContext)
  const history = useHistory()
  const {item, newPosts, handleSetViewedPosts, handleActionClick} =
    useContext(ServerContext)
  const handleRequiredLogin = useRequiredLogin()
  const {categories = [], description} = item
  const shareUrl = eventModel.getLinkToShare(item)
  const permissions = useMemo(
    () => ({
      canPostArticle: true,
    }),
    []
  )

  // let _timetables = [];
  const _timetables = useMemo(() => {
    let _timetables = []
    _.get(item, 'timetables', []).forEach(function (arrayItem, index) {
      _timetables.push(arrayItem)
      if (_.get(item, 'timetables', []).length - 2 === index) {
        _timetables.push({isInsert: true, active: true})
      } else if (_.get(item, 'timetables', []).length - 2 > index) {
        _timetables.push({isInsert: true})
      }
    })

    return _timetables
  }, [item])

  const initialValues = {
    invisible_timeline: item.invisible_timeline || 0,
    registration_enabled: item.registration_enabled || 0,
    update: Date.now(),
    timetables: _timetables.map((el) => {
      return {
        ...el,
        speakers: _.get(el, 'speakers', []).map((ell) => ({
          _type: _.get(ell, 'speaker._type'),
          id: _.get(ell, 'speaker.id'),
          value:
            _.get(ell, 'speaker_name') ||
            _.get(ell, 'speaker.name', _.get(ell, 'speaker.title')),
          avatar: _.get(ell, 'speaker.avatar'),
        })),
      }
    }),
  }

  return (
    <PermissionProvider permissions={permissions}>
      <ApiInfoForm
        initialValues={initialValues}
        query={{
          ':id': item.id,
        }}
        onPreSubmit={(values) => {
          let _values = values
          if (!!values.timetables) {
            _values = {
              ..._values,
              timetables: JSON.stringify(
                values.timetables.filter((el) => !!!el.isInsert)
              ),
            }
          }

          return {
            ..._values,
          }
        }}
        apiInfo={event_edit_Api}>
        {({
            isSubmitting,
            dirty,
            isValid,
            values = {},
            validate,
            ...props
          }) => {
          const pastTimetables = values.timetables.filter((e) => {
            return (
              (e.mandatory === 1 && e.title === 'START') ||
              moment(e.start_time).isBefore(moment.now())
            )
          })

          return (
            <div className="px-3 w-full py-5 space-y-3">
              <div className="flex items-center">
                <div className="flex-1 font-semibold text-sm uppercase text-color-400">
                  {t('timeline')}
                </div>

                <Switch
                  name={'invisible_timeline'}
                  checkedChildren={t('visible')}
                  unCheckedChildren={
                    <div className="text-color">{t('invisible')}</div>
                  }
                  defaultChecked={!!!values.invisible_timeline}
                  onChange={(checked, event) => {
                    props.handleChange(
                      createValue('invisible_timeline', !checked ? 1 : 0)
                    )
                  }}
                />
              </div>

              <div className="flex flex-col px-0 md:px-3">
                <Steps
                  direction="vertical"
                  size="small"
                  current={pastTimetables.length - 1}>
                  {values.timetables.map((e, i) => {
                    const editable = !e.mandatory

                    if (e.mandatory === 1 && e.title === 'START')
                      return (
                        <Step
                          key={i}
                          title={
                            <div className="flex flex-row space-x-2 font-bold uppercase">
                              <span>{t('start')}</span>
                            </div>
                          }
                          icon={<FaPlayCircle/>}
                        />
                      )
                    if (e.mandatory === 1 && e.title === 'FINISH')
                      return (
                        <Step
                          key={i}
                          title={
                            <div className="flex flex-row space-x-2 font-bold uppercase">
                              <span>{t('finish')}</span>
                            </div>
                          }
                          icon={<FaStopCircle className="text-red-500"/>}
                        />
                      )
                    if (e.isInsert && e.active) {
                      return (
                        <Step
                          key={i}
                          title={
                            <div className="w-full border border-color-50 rounded-lg background-100 p-3 my-3">
                              <FieldsFactory
                                formSchema={[
                                  {
                                    type: 'text',
                                    name: 'timetables',
                                    label: t('timetables'),
                                    children: {
                                      component: TimetableEditor,
                                      withProps: {
                                        key: JSON.stringify(e),
                                        type: 'text',
                                        name: 'timetables',
                                        item: {
                                          ...e,
                                          milestone: 1,
                                        },
                                        isInsert: true,
                                        itemIndex: i,
                                      },
                                    },
                                  },
                                ]}
                              />
                            </div>
                          }
                          icon={
                            <FaPlusCircle
                              onClick={() => {
                                let _timetableTemp = values.timetables
                                _timetableTemp[i] = {isInsert: true}
                                props.handleChange(
                                  createValue('timetables', _timetableTemp)
                                )
                              }}
                            />
                          }
                        />
                      )
                    }
                    if (e.isInsert)
                      return (
                        <Step
                          key={i}
                          title={
                            <div
                              onClick={() => {
                                let _timetableTemp = values.timetables
                                _timetableTemp[i] = {
                                  isInsert: true,
                                  active: true,
                                }
                                props.handleChange(
                                  createValue('timetables', _timetableTemp)
                                )
                              }}
                              className="flex gap-1 font-bold items-center text-color-100 hover:text-primary cursor-pointer">
                              <AiFillCaretLeft/>
                              <div>{t('insert here')}</div>
                            </div>
                          }
                          icon={
                            <FaPlusCircle
                              style={{padding: '0.3rem'}}
                              className="cursor-pointer"
                              onClick={() => {
                                let _timetableTemp = values.timetables
                                _timetableTemp[i] = {
                                  isInsert: true,
                                  active: true,
                                }
                                props.handleChange(
                                  createValue('timetables', _timetableTemp)
                                )
                              }}
                            />
                          }
                        />
                      )
                    if (e.isUpdating)
                      return (
                        <Step
                          key={i}
                          title={
                            <div className="w-full border border-color-50 rounded-lg background-100 p-3 my-3">
                              <FieldsFactory
                                formSchema={[
                                  {
                                    type: 'text',
                                    name: 'timetables',
                                    label: t('timetables'),
                                    children: {
                                      component: TimetableEditor,
                                      withProps: {
                                        key: JSON.stringify(e),
                                        type: 'text',
                                        name: 'timetables',
                                        item: e,
                                        itemIndex: i,
                                      },
                                    },
                                  },
                                ]}
                              />
                            </div>
                          }
                          icon={
                            e.milestone === 1 ? (
                              <FaRegDotCircle
                                onClick={() => {
                                  let _timetableTemp = values.timetables
                                  _timetableTemp[i] = {
                                    ..._timetableTemp[i],
                                    isUpdating: false,
                                  }
                                  props.handleChange(
                                    createValue('timetables', _timetableTemp)
                                  )
                                }}
                              />
                            ) : (
                              <FaCircle
                                onClick={() => {
                                  let _timetableTemp = values.timetables
                                  _timetableTemp[i] = {
                                    ..._timetableTemp[i],
                                    isUpdating: false,
                                  }
                                  props.handleChange(
                                    createValue('timetables', _timetableTemp)
                                  )
                                }}
                              />
                            )
                          }
                        />
                      )

                    return (
                      <Step
                        key={i}
                        title={
                          <div className="flex flex-col space-y-3">
                            <div className="flex flex-col">
                              <div className="flex flex-row gap-2">
                                <span className="font-bold">{e.title}</span>
                                {editable && (
                                  <div
                                    onClick={() => {
                                      let _timetableTemp = values.timetables
                                      _timetableTemp[i] = {
                                        ..._timetableTemp[i],
                                        isUpdating: true,
                                      }
                                      props.handleChange(
                                        createValue(
                                          'timetables',
                                          _timetableTemp
                                        )
                                      )
                                    }}
                                    className="flex items-center cursor-pointer">
                                    <Avatar
                                      size={25}
                                      shape="circle"
                                      className="flex flex-center background-200"
                                      icon={
                                        <FiEdit2
                                          size={13}
                                          className="text-color-300"
                                        />
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="flex flex-wrap gap-3 italic font-light text-color-400">
                                {!!e.start_time && (
                                  <div className="text-xs">
                                    {moment(e.start_time).format(
                                      'DD/MM/YYYY HH:mm Z'
                                    )}
                                  </div>
                                )}
                                {!!e.finish_time && (
                                  <div className="text-xs">
                                    <AiOutlineArrowRight/>
                                    {moment(e.finish_time).format(
                                      'DD/MM/YYYY HH:mm Z'
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>

                            {_.get(e, 'speakers', []).map((eee, index) => (
                              <div
                                key={index}
                                className="flex items-center gap-2">
                                <Avatar
                                  size={25}
                                  className="flex flex-center background-200"
                                  icon={
                                    <BsPersonFill
                                      size={15}
                                      className="text-color-400"
                                    />
                                  }
                                  src={_.get(eee, 'avatar', null)}
                                />
                                <div className="font-medium text-xs text-color-000 flex-1">
                                  {_.get(eee, 'speaker.name') ||
                                  _.get(eee, 'speaker_name') ||
                                  _.get(eee, 'value')}
                                </div>
                              </div>
                            ))}
                          </div>
                        }
                        description={
                          <SlateRenderer
                            key={'timetable_' + i}
                            value={convertFromString(e?.description)}
                          />
                        }
                        icon={
                          e.milestone === 1 ? <FaRegDotCircle/> : <FaCircle/>
                        }
                      />
                    )
                  })}
                </Steps>
              </div>

              <div className="flex-1"/>
              <FormActionBar
                // actions={
                //   <>
                //     <Button
                //       loading={isSubmitting}
                //       disabled={!!values.timetables.filter(e => e.isUpdating).length}
                //       onClick={() => {
                //         props.handleSubmit()
                //       }}
                //       type="primary"
                //       className="font-bold">
                //       {t('save')}
                //     </Button>
                //   </>
                // }
              />
            </div>
          )
        }}
      </ApiInfoForm>
    </PermissionProvider>
  )
}

const EventDelSetting = ({id}) => {
  const t = useTranslate()
  const login = useContext(LoginContext)
  const history = useHistory()
  const {item} = useContext(ServerContext)

  const permissions = useMemo(
    () => ({
      canPostArticle: true,
    }),
    []
  )

  return (
    <PermissionProvider permissions={permissions}>
      <div className="flex flex-col px-3 w-full py-5 space-y-3 pb-16">
        <div className="flex-1">
          {t('something is wrong, want to delete it')}
        </div>

        <div className="flex items-center justify-center p-3 rounded-full">
          <AsyncAction
            onSuccess={() => {
              Modal.success({
                content: t('successfully deleted'),
              })
              history.replace('/')
            }}
            onError={notifyOnError(t)}
            apiInfo={event_delete_Api}
            query={{
              ':id': item.id,
            }}>
            {({handleAsyncAction, isLoading}) => {
              return (
                <Popconfirm
                  title={t('are you sure you want to delete it permanently?')}
                  onConfirm={() => {
                    handleAsyncAction()
                  }}
                  onCancel={(e) => {
                    notification.open({
                      message: 'deletion is ignored',
                    })
                  }}
                  okText={t('sure')}
                  cancelText={t('ignore')}
                  okButtonProps={{
                    type: 'primary',
                    danger: true,
                    className: 'rounded-md no-shadow no-text-shadow text-sm',
                  }}
                  cancelButtonProps={{
                    className:
                      'rounded-md no-shadow no-text-shadow text-sm no-border',
                  }}>
                  <Button
                    danger
                    ghost
                    type="primary"
                    className="px-8 rounded-full no-shadow">
                    {t('delete')}
                  </Button>
                </Popconfirm>
              )
            }}
          </AsyncAction>
        </div>
      </div>
    </PermissionProvider>
  )
}

export default mapParams()(
  nest(
    EventProvider,
    withProps(({item}) => ({
      renderHeader: () => (
        <EventContext.Consumer>
          {({item}) =>
            !!item &&
            !!_.get(item, 'edit') && (
              <Translate>
                {(t) => (
                  <div className="p-2 px-3 background-100 font-bold flex space-x-2 items-center">
                    <div>{t('admin')}</div>
                    <div className="flex-1"/>
                    <Link
                      to={{
                        pathname: `/event/${item.idname}`,
                      }}>
                      <Button
                        className="button-rounded-full text-sm font-medium"
                        icon={<HomeOutlined size={14}/>}>
                        {t('back to home')}
                      </Button>
                    </Link>
                  </div>
                )}
              </Translate>
            )
          }
        </EventContext.Consumer>
      ),
      menu: (
        <div className="relative">
          <EventOverviewSetting/>
        </div>
      ),
    }))(ContentLayout),
    () => (
      <UseHook
        hook={useSearchParam}
        deps={['initialCurrent']}>
        {(initialCurrent) => (
          <EventContext.Consumer>
            {({item}) =>
              item && (
                <NavigationContext.Consumer>
                  {({history}) => (
                    <Translate>
                      {(t) => (
                        <ServerContainer
                          key={item.id}
                          initialCurrent={initialCurrent}
                          {...{
                            item,
                            schema: eventSchema,
                            id: item.id,
                            type: item._type,
                            routes: routes,
                            createMenuContext: ({
                                                  setCurrent,
                                                  handleActionClick,
                                                }) => (
                              <Menu
                                onClick={({key}) => {
                                  switch (key) {
                                    case 'setting':
                                      history.push(
                                        eventModel.getLinkToSetting(item)
                                      )
                                      break
                                    default:
                                      handleActionClick(key)
                                      break
                                  }
                                }}>
                                <Menu.Item key={'share'}>
                                  <UserAddOutlined/>
                                  <span className="font-semibold">
                                    {t('invite people')}
                                  </span>
                                </Menu.Item>
                                {!item.follow_status &&
                                !item.display_follow_off && (
                                  <Menu.Item key={'follow'}>
                                    <BellOutlined/>
                                    <span className="font-semibold">
                                        {t('follow')}
                                      </span>
                                  </Menu.Item>
                                )}
                                {!!item.follow_status &&
                                !item.display_follow_off && (
                                  <Menu.Item key={'unfollow'}>
                                    <BellOutlined/>
                                    <span className="font-semibold">
                                        {t('unfollow')}
                                      </span>
                                  </Menu.Item>
                                )}
                                {!!item.join_status && (
                                  <Menu.Item key={'leave'}>
                                    <LogoutOutlined/>
                                    <span className="font-semibold">
                                      {t('leave event')}
                                    </span>
                                  </Menu.Item>
                                )}

                                <Menu.Divider/>
                                {!!item.edit && (
                                  <Menu.Item key={'setting'}>
                                    <SettingOutlined/>
                                    <span className="font-semibold">
                                      {t('setting')}
                                    </span>
                                  </Menu.Item>
                                )}
                              </Menu>
                            ),
                          }}>
                          <LayoutContext.Consumer>
                            {({isLg}) => {
                              return (
                                <NavigationContext.Consumer>
                                  {({handleGoBack}) => {
                                    return (
                                      <MainPageHeader
                                        title={
                                          <>
                                            {!isLg && (
                                              <div className="pl-3">
                                                <Count
                                                  onClick={() =>
                                                    handleGoBack()
                                                  }>
                                                  <VscArrowLeft/>
                                                </Count>
                                              </div>
                                            )}
                                            <div
                                              className="flex items-center truncate flex-1 pl-3"
                                              onClick={preventParentEvent}>
                                              <div
                                                className="capitalize text-lg font-medium leading-tight flex items-center gap-2">
                                                {item.icon || <BsCalendar3/>}
                                                {t(item.title)}
                                              </div>
                                            </div>
                                          </>
                                        }
                                      />
                                    )
                                  }}
                                </NavigationContext.Consumer>
                              )
                            }}
                          </LayoutContext.Consumer>

                          <div className="flex flex-col space-y-3">
                            <EventSetting id={item.id}/>
                            <Divider/>
                            <EventTimetableSetting id={item.id}/>
                            <Divider/>
                            <EventDelSetting id={item.id}/>
                          </div>
                        </ServerContainer>
                      )}
                    </Translate>
                  )}
                </NavigationContext.Consumer>
              )
            }
          </EventContext.Consumer>
        )}
      </UseHook>
    )
  )
)
