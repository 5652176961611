import React, {useState} from 'react'
import {NavigationContext} from './NavigationContext'

export const HistoryContext = React.createContext(null)
function NavigationProvider({
  match,
  history,
  location,
  isModal,
  mainLocation,
  saveLocation,
  routesConfig,
  modalRoutesConfig,
                              children,
}) {
  const [current, setCurrent] = useState()
  return (
    <NavigationContext.Provider
      value={{
        match,
        history,
        handleGoBack: history.goBack,
        location,
        isModal,
        mainLocation,
        saveLocation,
        routesConfig,
        modalRoutesConfig,
        current,
        setCurrent,
      }}>
      <HistoryContext.Provider value={history}>
        {children}
      </HistoryContext.Provider>
    </NavigationContext.Provider>
  )
}

export default NavigationProvider
