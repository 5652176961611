import { Avatar } from 'antd'
import { getFullAvatarUrl } from 'apis/model/base'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import React from 'react'
import { IoHome, IoPersonCircleOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { FlexCol } from 'views/AppLocals/Templates/ItemTemplate'

const ActionButtons = ({ item }) => (
  <div className="inline-flex flex-row space-x-1">
    <Link
      to={getLinkToDetail(item)}
      className="border border-color-50 rounded-full w-10 h-10 flex items-center justify-center hover:bg-primary hover:text-white">
      <IoHome className="Icon" />
    </Link>
  </div>
)

const UserSettingHeader = ({ user }) => {
  const item = user || {}

  if (_.isEmpty(item)) return null

  const avatar = getFullAvatarUrl(item)

  return (
    <div className="flex px-3 w-full flex-col">
      <div className="flex items-center">
        {avatar ? (
          <Avatar
            shape="square"
            size={100}
            src={avatar}
            className="object-contain w-full h-full rounded-lg"
          />
        ) : (
          <IoPersonCircleOutline className="text-6xl p-2 border border-color-50 rounded-lg text-colo-300" />
        )}
        <div className="flex flex-col px-3">
          <Link
            title={item.title}
            to={getLinkToDetail(item)}>
            <h2 className="title-font text-base md:text-xl font-semibold hover:text-primary">
              {getTitle(item)}
            </h2>
          </Link>
        </div>
        <FlexCol />
        <ActionButtons {...{ item }} />
      </div>
    </div>
  )
}

export default UserSettingHeader
