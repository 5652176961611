import {Popover, Typography} from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, {useContext, useEffect, useMemo} from 'react'
import {Link} from 'react-router-dom'
import {useToggle} from 'react-use'
import {useFocused, useSelected, useSlateStatic} from 'slate-react'
import {Null, renderIf} from 'views/Shared'
import {editMentionContent, removeMention, updateMention,} from 'views/SlateEditor/functions'
import SlateEditorContext from 'views/SlateEditor/SlateEditorContext'
import {ElementTypes} from 'views/SlateEditor/types'
import {validateUrl} from '../../../Shared'
import './Mention.css'
import {useMentions} from './useMentions'

const scope = ElementTypes.MENTION

export const ObjMention = ({url, title, content}) => {
  const editor = useSlateStatic()

  const prefix = content + (String(title ?? '').length ? ' - ' : '')

  const handleEdit = _.debounce((text) => {
    editMentionContent(editor, String(text ?? '').length ? text : undefined)
  }, 100)

  return (
    <span>
      {renderIf(
        content,
        <Popover
          title={null}
          trigger="click"
          content={
            <div className="flex flex-col gap-2">
              <Typography.Text
                editable={{
                  text: content,
                  editing: true,
                  autoSize: true,
                  onChange: handleEdit,
                }}>
                {prefix}
              </Typography.Text>
            </div>
          }>
          <span className={classNames('font-medium text-color-100 italic')}>
            {prefix}
          </span>
        </Popover>
      )}
      {url ? (
        validateUrl(url) ? (
          <a
            href={url}
            target="_blank"
            className="font-semibold text-primary">
            {title}
          </a>
        ) : (
          <Link
            to={url}
            className="font-semibold text-primary">
            {title}
          </Link>
        )
      ) : (
        <span className="text-color-100">{title}</span>
      )}
    </span>
  )
}

export const MentionRenderer = ({pending, mention_type, ...props}) => {
  if (!pending) {
    switch (mention_type) {
      default:
        break
    }
  }
  return null
}

export const MentionElement = ({attributes, children, element}) => {
  const focused = useFocused()
  const selected = useSelected()
  const editor = useSlateStatic()

  const [isToggle, toggle] = useToggle()

  const {values, set = Null} = useContext(SlateEditorContext)

  const {pending = false} = _.get(values, scope) ?? {}

  const {uid, mention_type} = element ?? {}

  const reset = () => {
    set(scope, undefined)
    toggle(false)
  }

  useEffect(() => {
    if (pending) {
      toggle(true)
    }
  }, [pending])

  const useMentionsFunc = useMentions

  const {modal} = useMentionsFunc({
    type: mention_type,
    visible: isToggle,
    onCancel: () => {
      if (pending) {
        removeMention(editor, {match: (n) => n?.uid === uid})
      }
      toggle(false)
      reset()
    },
    onSubmit: (values) => {
      set(scope, {pending: false})
      updateMention(editor, {
        ...(values ?? {}),
        uid: undefined,
      })
      reset()
    },
    title: (
      <Translate>
        {(t) => (
          <div className="text-center font-bold text-color-000 uppercase">
            {t('Refer to a content')}
          </div>
        )}
      </Translate>
    ),
  })

  const style = useMemo(() => {
    const initialValue = {
      // padding: '0.5rem',
      // margin: '0 1px',
      verticalAlign: 'baseline',
      // display: 'inline-block',
      // borderRadius: '0.375rem',
      // border: '1px solid var(--border-color-50)',
      // backgroundColor: 'var(--background-100)',
      fontSize: '1rem',
      // outline: withOutline({ selected, focused }),
    }
    if (element.children[0]?.bold) {
      initialValue.fontWeight = 'bold'
    }
    if (element.children[0]?.italic) {
      initialValue.fontStyle = 'italic'
    }
    if (element.children[0]?.underlined) {
      initialValue.textDecoration = 'underline'
    }
    return initialValue
  }, [element.children, selected, focused])

  if (!mention_type || pending) {
    return (
      <span>
        {children}
        {modal}
      </span>
    )
  }

  return (
    <span
      {...attributes}
      style={style}>
      <MentionRenderer {...(element ?? {})} />
      {children}
    </span>
  )
}
