import {UpOutlined} from "@ant-design/icons"
import React from "react"
import {BsCaretDownFill, BsCaretUpFill, BsPlusLg} from "react-icons/bs"
import {useToggle} from "react-use"

const SpotFormExpanded = ({
  title,
  children,
  className,
  renderTitle,
  icons = [ BsCaretUpFill, BsCaretDownFill ],
  iconClassName,
  fields = [],
  ...props
}) => {

  const [Left, Right] = icons

  const [isToggle, toggle] = useToggle(false);

  const IconButton = isToggle ? Left : Right

  return (
    <>
      <div
        onClick={toggle}
        className={className}>
        {renderTitle
          ? renderTitle(title)
          : (
            <span className="font-medium text-sm text-color-300">
              {title}
            </span>
          )}
        <IconButton
          data-effect="ripple"
          size={14}
          className={iconClassName} />
      </div>
      {isToggle ? children : null}
    </>
  )
}

export default SpotFormExpanded