import {notification} from 'antd'
import _ from 'lodash'
import {renderSelf} from 'views/Shared'
import {AppEntities, LAWMessageTypes, MaterialTypes} from './enums'

// Todo: implement translation

export const organizationMessages = {
  [LAWMessageTypes.ERROR]: 'error',
  [LAWMessageTypes.EMPTY]: 'organization not found',
}

export const userMessages = {
  [LAWMessageTypes.ERROR]: 'error',
  [LAWMessageTypes.EMPTY]: 'user not found',
}

export const getMessage = (
  entity,
  type = LAWMessageTypes.ERROR,
  transform = renderSelf
) => {
  const defaultMessage = 'error'

  let message = defaultMessage

  const getMessage = (from) => _.get(from, type, defaultMessage)

  switch (entity) {
    case AppEntities.ORGANIZATION:
      message = getMessage(organizationMessages)
      break
    case AppEntities.USER:
      message = getMessage(userMessages)
      break
    default:
      break
  }
  return transform(message)
}

export const showNotification = ({
                                   message,
                                   description,
                                   type = MaterialTypes.INFO,
                                 }) =>
  notification[type]({
    message,
    description,
  })

export const getValidationMessage = ({
                                       type,
                                       notValid = true,
                                       translate = renderSelf,
                                     }) => {
  let message = 'required field'
  switch (type) {
    case 'email':
      message = notValid ? 'email is not valid' : 'email is required'
      break
    default:
      break
  }
  return translate(message)
}
