import { search_getSuggestedKeywords_Api } from 'apis'
import { KeywordInput } from 'components/KeywordInput'
import { LayoutContext } from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { IoFilter } from 'react-icons/io5'
import { compose, withProps } from 'recompose'
import SearchContext from 'views/Search/SearchContext'
import { SearchParams } from 'views/Search/SearchProvider'
import { Null } from 'views/Shared'
import DiscoveryExplorerTagsFilter from './DiscoveryExplorerTagsFilter'

const SearchInput = compose(

  withProps(({ onSearch, searchParams }) => ({
      remote: true,
      dependencies: [_.get(searchParams, SearchParams.TYPE)],
      autoCompleteConfigs: {
          fieldNames: {
              label: 'keyword',
              value: 'keyword',
          },
          actionParams: {
              values: {
                  type: _.get(searchParams, SearchParams.TYPE),
              },
              apiInfo: search_getSuggestedKeywords_Api,
          },
      },
      onSearchClick: onSearch,
      title: 'search anythings in FeedIn',
      placeholder: 'enter your keyword',
      prefix: (
          <LayoutContext.Consumer>
              {({ setOpenMenu }) => (
                  <IoFilter
                      onClick={() => setOpenMenu(true)}
                      className="KeywordInputButton text-color-400 hover:text-color-100 cursor-pointer"
                  />
              )}
          </LayoutContext.Consumer>
      ),
  }))
)(KeywordInput)

const DiscoveryExplorerSearchBar = ({ Wrapper = 'div' }) => {
  const {
    searchParams,
    onClear = Null,
    onSearch = Null,
  } = useContext(SearchContext)

  const values = {
    type: _.get(searchParams, SearchParams.TYPE),
    keyword: _.get(searchParams, SearchParams.KEYWORD),
  }

  const [keyword, setKeyword] = useState(values.keyword)

  const handleSearch = (keyword) => {
    onSearch({
      keyword,
      type: values.type,
    })
  }

  const onChange = (event) => {
    setKeyword(event.target.value)
  }

  return (
    <Wrapper className="flex flex-col items-center space-y-3 py-3 mx-2 md:mx-0">
      <SearchInput
        name="keyword"
        value={keyword}
        onClear={onClear}
        onChange={onChange}
        onSearch={handleSearch}
      />
      <DiscoveryExplorerTagsFilter />
    </Wrapper>
  )
}

export default DiscoveryExplorerSearchBar
