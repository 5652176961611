import { API_ROOT_URL } from 'envs/_current/config'
import { orderRequestSchema } from './schema'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

export const orderRequest_createOrderRequest_Api = {
  backend: `Route::post('order-requests/{prop}/{id}/create-order-request', 'OrderRequestController@createOrderRequest');`,
  root: API_ROOT_URL,
  path: '/order-requests/:prop/:id/create-order-request',
  method: 'POST',
  schema: orderRequestSchema,
  transform: createTransform(
    orderRequestSchema,
    'data'
  )
}

export const orderRequest_getMyOrderRequests_Api = {
  backend: `Route::get('order-requests/order-request/get-order-requests', 'OrderRequestController@getOrderRequests');`,
  root: API_ROOT_URL,
  path: '/order-requests/order-request/get-order-requests',
  method: 'GET',
  paramsFields: [
    'order_request_type',
    'status',
    'keyword',
  ],
  schema: orderRequestSchema,
  transform: createListTransform(
    orderRequestSchema
  )
}

export const orderRequest_closeOrderRequest_Api = {
  backend: `Route::post('order-requests/order-request/{id}/close-order-request', 'OrderRequestController@closeOrderRequest');`,
  root: API_ROOT_URL,
  path: '/order-requests/order-request/:id/close-order-request',
  method: 'POST',
  schema: orderRequestSchema,
  transform: createTransform(
    orderRequestSchema,
    'data'
  )
}

export const orderRequest_getOrderRequests_Api = {
  backend: `Route::get('order-requests/{prop}/{id}/get-order-requests', 'OrderRequestController@getOrderRequestsByProvider');`,
  root: API_ROOT_URL,
  path: '/order-requests/:prop/:id/get-order-requests',
  method: 'GET',
  paramsFields: [
    'order_request_type',
    'status',
    'keyword',
  ],
  schema: orderRequestSchema,
  transform: createListTransform(
    orderRequestSchema
  )
}

export const orderRequest_feedbackOrderRequest_Api = {
  backend: `Route::post('order-requests/order-request-detail/{id}/feedback-order-request', 'OrderRequestController@feedbackOrderRequest');`,
  root: API_ROOT_URL,
  path: '/order-requests/order-request-detail/:id/feedback-order-request',
  method: 'POST',
  schema: orderRequestSchema,
  transform: createTransform(
    orderRequestSchema,
    'data'
  )
}
