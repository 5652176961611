import React, {
  lazy,
  Suspense
} from 'react'

const MessagesLazy = lazy(() =>
  import('./Messages')
)

const Messages = props => (
  <Suspense fallback={null}>
    <MessagesLazy {...props} />
  </Suspense>
)

export default Messages
