import React from "react";

export const Fish = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#ffc250"
				d="M153.573 362.431c-36.363 13.123-40.553 52.592-40.553 52.592l5.022 6.809c48.171 26.834 122.356 36.576 192.947-7.63-65.085-2.054-119.66-12.752-157.416-51.771z"
			></path>
			<path
				fill="#75cef9"
				d="M154.603 363.473c-15.315-15.497-27.921-35.541-37.396-61.436a27.537 27.537 0 00-1.372-3.117c-23.481 9.698-59.778 28.497-49.199 67.67 5.251 19.44 24.173 40.072 51.406 55.242-3.178-34.998 23.086-52.004 36.561-58.359z"
			></path>
			<path
				fill="#ffc250"
				d="M509.879 339.123c3.66-4.705 2.447-11.54-2.601-14.708-11.058-6.94-19.054-15.141-25.993-24.865-13.858-19.421-20.766-42.867-20.794-66.725-.009-8.052-.382-16.1-1.199-23.987-23.386-24.779-85.169-75.78-194.957-71.868-52.857 1.883-92.694 13.906-121.43 27.59a96.599 96.599 0 005.165 8.238c27.631 39.473-10.419 109.403-10.45 171.309 17.886 26.056 41.15 42.771 68.736 53.384l5.647-2.078 49.962 10.645 6.327 5.622.001.001c17.393 2.003 35.788 2.921 55.036 3.222l.002-.002 6.282-3.221 40.434-3.047 9.921 3.936c65.272-7.962 107.986-33.317 124.626-44.951 4.483-3.135 5.63-9.276 2.596-13.828a10.066 10.066 0 01.43-11.765z"
			></path>
			<path
				fill="#f2ab08"
				d="M507.647 341.993c-210.884 49.724-302.228-32.158-355.972-63.135a78.507 78.507 0 00-4.228-2.249c-4.974 22.358-9.814 45.438-9.825 67.499 17.886 26.056 41.15 42.771 68.736 53.384l5.647-2.078 49.962 10.645 6.327 5.622.001.001c17.393 2.003 35.788 2.921 55.036 3.222l.002-.002 6.282-3.221 40.434-3.047 9.921 3.936c65.272-7.962 107.986-33.317 124.626-44.951 4.483-3.135 5.63-9.276 2.596-13.828a10.066 10.066 0 01.43-11.765z"
			></path>
			<path
				fill="#75cef9"
				d="M152.192 172.799a95.905 95.905 0 01-6.014-9.77c-26.205 12.006-43.63 25.563-53.606 34.782 3.062 5.056 7.001 10.169 11.915 15.324 6.256 6.563 3.413 17.451-5.21 20.252a26.77 26.77 0 01-14.743.521c-18.904-4.683-33.999-14.992-44.925-24.984-11.537-10.55-30.121-5.234-34.15 9.872-8.232 30.872-5.93 59.159-1.099 80.606 3.445 15.299 22.08 21.384 33.901 11.077 13.975-12.186 32.106-19.128 46.699-23.02 13.412-3.578 27.422 3.51 32.249 16.525 6.701 18.062 14.968 33.238 24.649 45.986-2.565-63.304 38.841-136.447 10.334-177.171z"
			></path>
			<path
				fill="#6bbef6"
				d="M141.856 349.97c-.938-23.176 4.017-47.67 9.277-71.409-43.92-24.844-117.421-9.215-150.226-.261a179.796 179.796 0 003.452 21.103c3.445 15.299 22.08 21.384 33.901 11.077 13.975-12.186 32.106-19.128 46.699-23.02 13.412-3.578 27.422 3.51 32.249 16.525 6.7 18.062 14.967 33.237 24.648 45.985z"
			></path>
			<path
				fill="#75cef9"
				d="M276.864 178.94c-6.914-15.648-10.74-29.605-12.384-41.974l-.145.004c-20.753.739-39.492 3.046-56.349 6.399 2.002 10.731 5.604 22.564 11.352 35.571 34.22 77.444-33.688 136.545-12.99 218.555 18.757 7.216 39.519 11.603 61.947 14.186-33.143-89.549 44.73-150.904 8.569-232.741z"
			></path>
			<path
				fill="#6bbef6"
				d="M209.986 314.238c-6.872 26.022-11.252 53.089-3.638 83.258 18.757 7.216 39.519 11.603 61.947 14.186-9.88-26.694-9.892-50.882-6.003-73.855-19.857-7.035-37.122-15.276-52.306-23.589z"
			></path>
			<path
				fill="#75cef9"
				d="M388.202 178.94c-3.608-8.166-6.367-15.866-8.423-23.127-17.579-7.054-37.886-12.855-61.084-16.135 1.823 11.69 5.565 24.744 11.981 39.262 36.593 82.815-43.6 144.654-7.342 235.962 5.262.082 10.583.121 15.969.121 14.401 0 27.955-.903 40.668-2.453-33.99-90.031 44.511-151.523 8.231-233.63z"
			></path>
			<path
				fill="#6bbef6"
				d="M371.417 357.485c-20.714-.58-39.695-2.667-57.126-5.844-1.774 19.788.051 40.618 9.042 63.261 5.262.082 10.583.121 15.969.121 14.401 0 27.955-.903 40.668-2.453-7.376-19.539-9.45-37.732-8.553-55.085z"
			></path>
			<circle cx="428.739" cy="313.795" r="21.271" fill="#477b9e"></circle>
			<path
				fill="#365e7d"
				d="M428.739 313.795c0-7.869 4.284-14.723 10.636-18.402a21.144 21.144 0 00-10.636-2.869c-11.747 0-21.271 9.523-21.271 21.271s9.523 21.271 21.271 21.271c3.879 0 7.504-1.055 10.636-2.869-6.352-3.68-10.636-10.534-10.636-18.402z"
			></path>
			<path
				fill="#ffe07d"
				d="M449.43 167.733c-11.543-27.16-34.364-56.041-69.346-75.823l-12.524 3.208-30.734-11.675-4.388-10.153c-14.947-3.606-31.291-5.715-49.057-5.935l-22.273 9.563-29.36 6.181-10.934-9.403c-26.969 5.53-53.442 14.433-75.802 25.871l-32.249 30.405v56.561c27.237-19.599 75.995-43.978 152.127-46.729 112.722-4.073 174.137 51.384 194.92 74.793-1.263-16.68-4.408-32.811-10.38-46.864z"
			></path>
			<g fill="#90d8f9">
				<path d="M145.036 99.544c-48.542 24.821-77.665 61.59-50.221 101.75 9.657-9.168 26.887-22.972 53.076-35.208-16.163-27.573-10.823-51.081-2.855-66.542zM220.81 73.692c-9.865 13.869-19.648 37.497-12.239 72.666 16.818-3.436 35.544-5.803 56.32-6.554-5.878-37.414 8.083-60.752 18.492-72.448a264.86 264.86 0 00-27.378 1.099c-11.625 1.059-23.457 2.83-35.195 5.237zM332.438 73.29c-9.608 13.32-19.293 35.883-13.257 69.33 23.541 3.436 44.053 9.537 61.706 16.92-9.078-29.244-6.746-51.519-.803-67.629-13.934-7.88-29.796-14.314-47.646-18.621z"></path>
			</g>
		</svg>
	);
}

export default Fish;
