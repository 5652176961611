import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import numeral from 'numeral'
import React from 'react'
import {
  IoArrowDown,
  IoArrowUp,
  IoEyeOutline,
  IoRemoveOutline,
} from 'react-icons/io5'
import { branch, compose, fromRenderProps } from 'recompose'
import { ContentNotFound } from 'views/AppLocals/ContentNotFound'
import { renderOwnChild, renderSelf } from 'views/Shared'
import WorkspaceContext from '../WorkspaceContext'
import { InsightsBlockHeader } from './WorkspaceInsights'

const MetaItem = ({ icon, title, value, children, render = renderSelf }) => (
  <div
    style={{
      boxShadow: '0 0 4px 2px rgb(0 0 0 / 5%)',
    }}
    className="flex flex-col items-center justify-center px-3 py-5 rounded-lg">
    <div className="text-center text-sm text-color-400">{title}</div>
    <div className="flex flex-col flex-center leading-tight mt-2">
      {render(
        <React.Fragment>
          <div className="flex items-center gap-2">
            {icon}
            <span className="text-2xl font-medium text-color-100 uppercase">
              {value.toString().length > 9
                ? numeral(value).format('0 a').toLocaleString()
                : value.toLocaleString()}
            </span>
          </div>
          {children}
        </React.Fragment>,
        { value }
      )}
    </div>
  </div>
)

const renderDiff = ([current_value = 0, old_value = 0]) => {
  const diff = Number(current_value) - Number(old_value)

  const _value = diff / (Number(old_value) === 0 ? diff : Number(old_value))

  const percent = numeral(_value).format(
    (_value * 100) % 1 === 0 ? '0%' : '0.00%'
  )

  return (
    <div className="flex items-center justify-center gap-2">
      {diff > 0 ? (
        <IoArrowUp className="text-green-500 text-xs" />
      ) : diff < 0 ? (
        <IoArrowDown className="text-red-500 text-xs" />
      ) : (
        <IoRemoveOutline className="text-color-400 text-xs" />
      )}

      {current_value !== old_value && Number(current_value) !== 0 && (
        <span
          className={classNames(
            diff > 0
              ? 'text-green-500 text-xs'
              : diff < 0
              ? 'text-red-500 text-xs'
              : 'text-color-400 text-xs'
          )}>
          {percent}
        </span>
      )}
    </div>
  )
}

const defaultFieldParams = [
  {
    name: 'total',
    label: 'total',
    extra_name: 'total_prev',
    icon: <IoEyeOutline />,
    render: (child, params) => child,
  },
]

const WorkspaceInsightsMetaSummary = ({
  style,
  summary,
  className,
  isLoading = false,
  title = 'overview',
  Wrapper = renderOwnChild,
  Header = InsightsBlockHeader,
  field_params = defaultFieldParams,
}) => (
  <Wrapper>
    <Header title={title} />
    {_.isEmpty(field_params) ? (
      <EmptyHolder />
    ) : (
      <div
        style={style}
        className={className}>
        <Translate>
          {(t) =>
            Array.from(field_params).map(
              (
                {
                  name,
                  icon,
                  label,
                  extra_name,
                  render = (child, params) => child,
                },
                index
              ) => {
                const value = _.get(summary, name, 0)
                return (
                  <MetaItem
                    key={index}
                    icon={icon}
                    value={value}
                    title={t(label)}
                    render={render}>
                    <span className="flex items-center italic font-light">
                      {renderDiff([value, _.get(summary, extra_name)])}
                    </span>
                  </MetaItem>
                )
              }
            )
          }
        </Translate>
      </div>
    )}
  </Wrapper>
)

export default ({ summary, ...props }) =>
  compose(
    fromRenderProps(WorkspaceContext.Consumer, ({ organization }) => ({
      item: organization,
    })),
    branch(
      ({ item }) => _.isEmpty(item),
      () => () => <ContentNotFound />
    )
  )(WorkspaceInsightsMetaSummary)({
    summary,
    ...props,
  })
