import { Avatar, Checkbox, Input, Radio, Tooltip } from 'antd'
import {
  create_poll_unVoteOnPoll_Api_action,
  create_poll_vote_Api_action,
  poll_addAnswer_Api,
  poll_getVoters_Api,
} from 'apis'
import { userModel } from 'apis/model'
import classnames from 'classnames'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { renderIf } from 'views/Shared'
import { useRequiredLogin } from '../../views/Server/useRequiredLogin'
import Modal from '../Modal/Modal'
import Toggle from '../Toggle'
import { FollowButton } from './FollowButton'
import {AiFillPlusCircle} from "react-icons/ai";
import {RiAddCircleLine, RiUserFill} from "react-icons/ri";

const renderItem = (item, i) => {
  if (!item) return null
  return (
    <Translate>
      {(t) => (
        <div className="py-2 px-4 flex w-full border-b border-gray-300">
          <div
            style={{
              flexShrink: 0,
            }}
            className="flex">
            <Avatar
              size="large"
              style={{
                background: '#f1f1f1',
                marginRight: '1em',
              }}
              src={item.avatar}
              shape={item._type === 'user' ? 'circle' : 'square'}>
              {getTitle(item)}
            </Avatar>
          </div>
          <div className="flex-1 overflow-hidden">
            <div className="flex items-start">
              <div className="flex-1 overflow-hidden">
                <Link
                  to={getLinkToDetail(item)}
                  className="cursor-pointer  font-bold leading-tight truncate">
                  {item.title || item.name}
                </Link>
                <div className="text-xs text-color-300 leading-tight truncate">
                  <span>{item.idname || item.username}</span>
                </div>
              </div>
            </div>
            <div className="text-xs">{item.description || item.about}</div>
          </div>
          <div>
            <FollowButton />
          </div>
        </div>
      )}
    </Translate>
  )
}

export function PollEmbed({ poll }) {
  const {
    id,
    title: question = 'What framework do you prefer?',
    total_votes,
    is_yesno_question = 1,
    poll_answers = [],
    poll_yesno_answers = [],
    vote_on_maximum,
  } = poll
  const getPercent = (voteNumber) =>
    total_votes ? Math.floor((voteNumber * 100) / total_votes) : 0
  const [newAnswer, setNewAnswer] = useState('')
  const {
    success,
    isLoading,
    handleAsyncAction: addNewAnswer,
    loading,
  } = useAsyncAction({
    onSuccess: () => setNewAnswer(''),
    apiInfo: poll_addAnswer_Api,
    query: {
      ':poll_question_id': id,
    },
  })
  const requireLogin = useRequiredLogin()

  const t = useTranslate()
  const [asynVoteData, asyncdispath] = useDispatchAsyncActionWithNotify()
  const handleVote = (poll_answer_id, vote_status) => {
    if (asynVoteData.isLoading) return
    const vote = (poll_question_id, poll_answer_id) => {
      const action = create_poll_vote_Api_action()({
        query: {
          ':poll_question_id': poll_question_id,
          ':poll_answer_id': poll_answer_id,
        },
      })
      requireLogin(() => asyncdispath(action))
    }
    const unVote = (poll_question_id, poll_answer_id) => {
      const action = create_poll_unVoteOnPoll_Api_action()({
        query: {
          ':poll_question_id': poll_question_id,
          ':poll_answer_id': poll_answer_id,
        },
      })

      requireLogin(() => asyncdispath(action))
    }
    if (!vote_status) {
      vote(id, poll_answer_id)
    } else {
      unVote(id, poll_answer_id)
    }
  }

  const input = (
    <Input.Search
      onChange={(e) => setNewAnswer(e.target.value)}
      loading={isLoading}
      className="w-full"
      placeholder={t('new answer')}
      enterButton={<span className="text-sm font-medium">{t('add')}</span>}
      value={newAnswer}
      onSearch={(value) =>
        addNewAnswer({
          title: value,
        })
      }
    />
  )
  return (
    <div>
      <div className="whitespace-pre-wrap pl-3">
        <Link to={getLinkToDetail(poll)} className="text-sm no-underline">{question}</Link>
      </div>

      {renderIf(
          vote_on_maximum > 1,
          <div className="text-right text-2xs text-color-400 italic tracking-wide leading-tight">
            * {t(
              'you are allowed to select up to {n} answers for this question',
              { n: ( <span className="font-bold text-color-100">{vote_on_maximum}</span> ) }
          )}
          </div>
      )}

      <div className="space-y-2 p-1">
        {!!is_yesno_question &&
          poll_yesno_answers.map(
            (
              {
                title,
                total_votes: voteNumber,
                vote_status,
                voters = [],
                id: poll_answer_id,
              },
              i
            ) => {
              const percent = getPercent(voteNumber)
              const restNumber = voters.length - 5
              return (
                <div
                  key={i}
                  className="w-full">
                  <div
                    onClick={() => {
                      handleVote(poll_answer_id, vote_status)
                    }}
                    data-effect="ripple"
                    className="relative font-bold overflow-hidden pl-3 py-1 flex items-center cursor-pointer">
                    <div className="flex-1">
                      <Radio checked={vote_status}>{t(title)}</Radio>
                    </div>
                    {renderIf(
                      percent,
                      <React.Fragment>
                        <div
                          style={{
                            transition: 'width 0.25s linear',
                            height: '2px',
                            background: 'var(--color-primary)',
                            width: `${percent}%`,
                            left: '2.5rem',
                          }}
                          className=" h-full left-0 bottom-0 absolute "
                        />
                        <div className="flex items-center font-semibold text-xs text-primary italic gap-1">
                          {renderIf(
                            voters.length,
                            <div className="color-primary-0 flex font-bold">
                              <div className="m-1 pl-6 flex items-center gap-1">
                                {voters.map(
                                  (item, i) =>
                                    i < 3 && (
                                      <Tooltip
                                        key={i}
                                        title={userModel.getTitle(item)}>
                                        <Avatar
                                          size={20}
                                          className="background-100 border border-color-50 flex flex-center"
                                          icon={<RiUserFill size={10} className="text-color-500"/>}
                                          src={userModel.getThumbnailUrl(item)}
                                        />
                                      </Tooltip>
                                    )
                                )}
                                {restNumber > 0 && (
                                  <Toggle>
                                    {(isToggle, toggle) => {
                                      return (
                                        <>
                                          <Avatar
                                            size="small"
                                            className="background-100 border border-color-50 flex flex-center">
                                            +{restNumber}
                                          </Avatar>
                                          <Modal
                                            maskClosable
                                            onCancel={toggle}
                                            visible={isToggle}
                                            title={Translate('users')}>
                                            <LazyPagination
                                              apiInfo={poll_getVoters_Api}
                                              query={{
                                                ':poll_question_id': id,
                                                ':poll_answer_id': poll_answer_id,
                                              }}
                                              renderItem={renderItem}
                                            />
                                          </Modal>
                                        </>
                                      )
                                    }}
                                  </Toggle>
                                )}
                              </div>
                            </div>
                          )}
                          <span>{percent}%</span>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )
            }
          )}

        {!!!is_yesno_question &&
          poll_answers.map(
            (
              {
                title,
                total_votes: voteNumber,
                vote_status,
                voters = [],
                id: poll_answer_id,
              },
              i
            ) => {
              const percent = getPercent(voteNumber)
              const restNumber = voters.length - 5
              return (
                <div
                  key={i}
                  className="mt-2 w-full ">
                  <div
                    onClick={() => {
                      handleVote(poll_answer_id, vote_status)
                    }}
                    data-effect="ripple"
                    className={classnames(
                      vote_status ? '' : '',
                      'relative font-medium overflow-hidden pl-3 py-1 flex items-center cursor-pointer'
                    )}>
                    <div className="flex items-center flex-1">
                      {_.get(poll, 'vote_on_maximum', 1) === 1 ? (
                        <Radio checked={vote_status}>
                          <div className="font-normal text-sm leading-tight">
                            {title}
                          </div>
                        </Radio>
                      ) : (
                        <Checkbox checked={vote_status}>
                          <div className="font-normal text-sm leading-tight">
                            {title}
                          </div>
                        </Checkbox>
                      )}
                    </div>
                    {renderIf(
                      percent,
                      <React.Fragment>
                        <div
                          style={{
                            transition: 'width 0.25s linear',
                            height: '2px',
                            background: 'var(--color-primary)',
                            width: `${percent}%`,
                            left: '2.5rem',
                          }}
                          className="h-full left-0 bottom-0 absolute"
                        />
                        <div className="flex items-center font-semibold text-xs text-primary italic gap-1">
                          {renderIf(
                            voters.length,
                            <span className="color-primary-0 flex font-bold">
                              <span className="m-1 pl-6 flex-1">
                                <span className="flex gap-1">
                                  {voters.map(
                                    (item, i) =>
                                      i < 3 && (
                                        <Tooltip
                                          key={i}
                                          title={userModel.getTitle(item)}>
                                          <Avatar
                                            size={20}
                                            className="background-100 border border-color-50 flex flex-center"
                                            src={userModel.getThumbnailUrl(item)}
                                            icon={<RiUserFill size={10} className="text-color-500"/>}
                                          />
                                        </Tooltip>
                                      )
                                  )}
                                </span>
                                {restNumber > 0 && (
                                  <Toggle>
                                    {(isToggle, toggle) => {
                                      return (
                                        <>
                                          <Avatar
                                            size="small"
                                            className="background-100 border border-color-50 flex flex-center">
                                            +{restNumber}
                                          </Avatar>
                                          <Modal
                                            maskClosable
                                            onCancel={toggle}
                                            visible={isToggle}
                                            title={Translate('users')}>
                                            <LazyPagination
                                              apiInfo={poll_getVoters_Api}
                                              query={{
                                                ':poll_question_id': id,
                                                ':poll_answer_id': poll_answer_id,
                                              }}
                                              renderItem={renderItem}
                                            />
                                          </Modal>
                                        </>
                                      )
                                    }}
                                  </Toggle>
                                )}
                              </span>
                            </span>
                          )}
                          <span>{percent}%</span>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              )
            }
          )}
      </div>



      {!!!is_yesno_question && (
        <PermissionContext.Consumer>
          {({ add_new_answer }) =>
            add_new_answer && (
              <Toggle>
                {(isToggle, toggle) => (
                  <div className="w-full">
                    <div className="flex items-center justify-end gap-1 mt-2 mb-1">

                      <div className="flex items-center gap-2 text-color-500 hover:text-color-100">
                        <RiAddCircleLine size={12} />
                        <span
                            onClick={toggle}
                            className="font-medium text-xs italic cursor-pointer">
                          {`${t('add answer')} ...`}
                        </span>
                      </div>
                    </div>
                    {renderIf(
                      isToggle,
                      <div className="relative font-bold overflow-hidden pl-4 py-1 flex items-center cursor-pointer">
                        <div className="flex flex-1 items-center">{input}</div>
                      </div>
                    )}
                  </div>
                )}
              </Toggle>
            )
          }
        </PermissionContext.Consumer>
      )}
    </div>
  )
}
