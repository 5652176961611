import {
  useLayoutEffect,
  useRef
} from 'react'
import useRouter from './useRouter'

const useOnLocationChange = callback => {
  const countRef = useRef(0)
  const { location } = useRouter()
  useLayoutEffect(() => {
    if (countRef.current) {
      callback()
    }
    countRef.current =
      countRef.current + 1
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])
}
export default useOnLocationChange
