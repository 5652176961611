import {Result} from 'antd'
import _ from 'lodash'
import {useAppConfig} from 'modules/local'
import Translate from 'modules/local/Translate'
import PermissionContext from 'modules/permissions/PermissionContext'
import React from 'react'
import {branch, compose, fromRenderProps, renderComponent, withProps,} from 'recompose'
import LoadingPage from 'views/LoadingPage'
import {getResponseItem, LoadingWrapper} from 'views/Shared'
import {ContentNotFound} from './ContentNotFound'

export const withAppConfig = (Component) => {
  return function Wrapper(props) {
    const appConfig = useAppConfig()
    return (
      <Component
        {...props}
        {...{appConfig}}
      />
    )
  }
}

export const notFoundOnError = (
  FromConsumer,
  NotFoundPage = ContentNotFound,
  LoadingComponent = LoadingPage
) =>
  compose(
    fromRenderProps(
      FromConsumer,
      ({status, isSucceed, error_code, isLoading = true}) => ({
        status,
        isLoading,
        error_code,
        failed: !isSucceed,
      })
    ),
    branch(
      ({isLoading}) => !!isLoading,
      () => () =>
        (
          <div className="flex items-center justify-center w-full h-full">
            <LoadingComponent/>
          </div>
        )
    ),
    branch(
      ({failed}) => !!failed,
      renderComponent(
        withProps(({status, error_code}) => ({
          status,
          description: error_code,
        }))(NotFoundPage)
      )
    )
  )

export function getContextResponse(response) {
  const isSucceed = Number(response?.status) === 200
  const error = getResponseItem(response, 'error')
  const errorMessage = response?.errorMessages?.error
  const error_code = response?.errorMessages?.error_code
  const status = {
    title: getResponseItem(response, 'status'),
    description: errorMessage,
  }
  return {
    error,
    status,
    isSucceed,
    error_code,
    errorMessage,
  }
}

export const withAccessPermissions = (
  requiredPermissions = [],
  AccessDenied = () => (
    <Translate>
      {(t) => (
        <LoadingWrapper
          isLoading={true}
          timeout={1000}>
          <div className="flex w-full p-3 justify-center">
            <Result
              status="403"
              title={t('permission denied')}
            />
          </div>
        </LoadingWrapper>
      )}
    </Translate>
  )
) =>
  compose(
    fromRenderProps(PermissionContext.Consumer, (permissions) => ({
      denied: !!_.find(
        Array.from(requiredPermissions ?? []),
        (name) => _.isEmpty(permissions ?? {}) || !_.get(permissions, name)
      ),
    })),
    branch(({denied}) => denied, renderComponent(AccessDenied))
  )
