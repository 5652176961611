import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../LoadingPage";

const MembersLazy = Loadable({
  loader: () => import('./Members'),
  loading: LoadingPage
})

const Members = (props) => (
  <MembersLazy {...props} />
)

export default Members
