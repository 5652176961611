import React, {
  lazy,
  Suspense
} from 'react'

const ProductsSearchLazy = lazy(() =>
  import('./ProductsSearch')
)

const ProductsSearch = props => (
  <Suspense fallback={null}>
    <ProductsSearchLazy {...props} />
  </Suspense>
)

export default ProductsSearch
