import _ from 'lodash'
import {GenderTypes} from "../views/AppLocals/appFieldTypes";
import React from "react";

export default function getLabelOfGender(value) {

  let label_gender = _.find(GenderTypes, {value: value})?.label

  return _.isString(label_gender) ? label_gender : undefined
}
