import {Space, Tag} from 'antd'
import classNames from 'classnames'
import Description from 'components/Feed/Description'
import {DatasetIcon} from 'components/icons/DatasetIcon'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {Link} from 'react-router-dom'
import {paths} from "../MainPage/contains";
import {BsDiamondFill} from "react-icons/bs";

export const DatasetItem = ({
  className,
  item
}) => {
  const t = useTranslate()

  console.log(3211111111, item)

  return (
    <div className={classNames('flex flex-col gap-3', className)}>
        <div className="flex items-center gap-2">
            <DatasetIcon premium={_.get(item, 'premium', 0)} />
            <Link
                to={`/data/${item.idname}`}
                className="flex-1 font-semibold text-color-000 leading-tight">
                {item.title}
            </Link>
        </div>

        <Description type="dataset" className="text-sm text-color-500 italic">
            {item.description}
        </Description>

        {_.get(item, 'categories', []).length > 0 && (
            <div className="flex flex-wrap items-center gap-4">
                {_.get(item, 'categories', []).map((e, i) => (
                    <Link
                        key={i}
                        to={paths.categoryPath.replace(':category', e.id).replace(':refcategory', e.alt_idname) + "?lang=" + e.language}
                        className="flex items-center gap-1 text-primary no-underline">
                        <BsDiamondFill size={6}/>
                        <span className="font-semibold text-sm italic">
                            {t(e.name)}
                        </span>
                    </Link>
                ))}
            </div>
        )}
    </div>
  )
}
