import { Avatar } from 'antd';
import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import useTranslate from "../../modules/local/useTranslate";
import getUpperFirstChar from "../../helpers/getUpperFirstChar";

export default React.memo(
	function BaseItemLayout({
		className,
		title = 'White rice',
		description = `Rice is a food product harvested from rice. Ordinary rice grain White, brown or red dark, contains many nutrients`,
		to,
		avatar
	}) {
		const translate = useTranslate();
		return (
			<div
				className={classnames(
					'flex mb-4',
					className
				)}>
				{avatar && (
					<Avatar
						style={{
							marginRight: '1em'
						}}
						className="border border-gray-300 "
						shape="square"
						size={100}
						src={avatar}
					>{getUpperFirstChar(title)}</Avatar>
				)}
				<div className=" mb-2 flex flex-col flex-1">
					<strong>{title}</strong>
					<div
						style={{
							maxHeight: '84px',
							overflow: 'hidden',
							textOverflow: 'ellipsis'
						}}>
						{description}
					</div>
					{to && (
						<Link to={to}>
							<small className="float-right hover:underline uppercase color-primary self-end py-2 font-semibold">
								{translate('detail')}
							</small>
						</Link>
					)}
				</div>
			</div>
		);
	}
);
