import {API_ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import {normalize, schema} from 'normalizr'
import {baseFieldTypes} from './base/baseFieldTypes'
import {articleSchema, channelSchema, feedSchema} from './schema'
import {createListTransform} from './utils/createListTransform'
import {createTransform} from './utils/createTransform'

const article_container_schema = new schema.Union(
  {
    channel: channelSchema,
  },
  '_type'
)

const fields = [
  'description',
  'title',
  'content',
  'embed_data',
  'categories',
  'cover_photo',
  'privacy',
  'user_id',
  'action',
  'photos',
  'container_type',
  'container_id',
  'owner_type',
  'owner_id',
]

const fieldTypes = {
  photos: baseFieldTypes.image,
  description: '',
  title: '',
  cover_photo: baseFieldTypes.string,
  embed_data: baseFieldTypes.object,
  categories: [],
  privacy: '',
  content: baseFieldTypes.string,
  user_id: baseFieldTypes.auto,
  action: baseFieldTypes.auto,
  container_type: '',
  container_id: '',
  owner_type: '',
  owner_id: '',
}
// // articles
// Route::post('articles/article/{prop}/{id}/create', 'ArticleController@createArticle');
export const function_article_createArticle_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:prop/:id/create',
  method: 'POST',
  fields,
  schema: articleSchema,
  fieldTypes,
  transform: (response) => {
    const {entities, ...rest} =
    createTransform(feedSchema, 'data')(response) ?? {}
    return {
      ...rest,
      entities: {
        ...(entities ?? {}),
        ...(normalize(
          response?.data?.content?.container,
          article_container_schema
        )?.entities ?? {}),
      },
    }
  },
}
export const create_function_article_createArticle_Api_action =
  () =>
    ({
       prefixStr = 'function_article_createArticle_Api',
       query,
       values,
       maxAge = -1,
     }) =>
      createAsyncAction({
        prefixStr: prefixStr + '@function_article_createArticle_Api',
        query,
        values,
        apiInfo: function_article_createArticle_Api,
        maxAge,
      })
// Route::post('articles/article/{prop}/{id}/{article_id}/delete', 'PostController@deletePost');
export const article_delete_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:article_id/delete',
  method: 'POST',
  fields,
  fieldTypes,
  transform: (response, { query }) => {
    return {
      response,
      entities: {
        ...(normalize(response?.data?.container, article_container_schema)
          ?.entities ?? {}),
        articles: {
          [query[':article_id']]: {
            isDeleted: true,
            update: Date.now(),
          },
        },
      },
    }
  },
}
export const create_article_delete_Api_action =
  () =>
    ({prefixStr = 'article_delete_Api', query, values, maxAge = -1}) =>
      createAsyncAction({
        prefixStr: prefixStr + '@article_delete_Api',
        query,
        values,
        apiInfo: article_delete_Api,
        maxAge,
      })

// Route::post('articles/article/{prop}/{id}/{article_id}/update', 'PostController@updatePost');
export const article_update_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:prop/:id/:article_id/update',
  method: 'POST',
  fields,
  fieldTypes,
  transform: createTransform(articleSchema, 'data'),
}
export const create_article_update_Api_action =
  () =>
    ({prefixStr = 'article_update_Api', query, values, maxAge = -1}) =>
      createAsyncAction({
        prefixStr: prefixStr + '@article_update_Api',
        query,
        values,
        apiInfo: article_update_Api,
        maxAge,
      })

// Route::post('articles/article/{article_id}/change-tags', 'PostController@updatePostTags');

// Route::get('articles/gets', 'ArticleController@getArticles')
export const article_getAticles_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/gets',
  method: 'GET',
  fields,
  fieldTypes,
  schema: articleSchema,
  transform: createListTransform(articleSchema),
}
export const create_article_getAticles_Api_action =
  () =>
    ({prefixStr = 'article_getAticles_Api', query, values, maxAge = -1}) =>
      createAsyncAction({
        prefixStr: prefixStr + '@article_getAticles_Api',
        query,
        values,
        apiInfo: article_getAticles_Api,
        maxAge,
      })

//Route::get('articles/article/{article_id}/get', 'ArticleController@getById');
export const article_getById_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:article_id/get',
  method: 'GET',
  fields,
  schema: articleSchema,
  fieldTypes,
  transform: createTransform(articleSchema, 'data'),
}
export const create_article_getById_Api_action =
  () =>
    ({prefixStr = 'article_getById_Api', query, values, maxAge = -1}) =>
      createAsyncAction({
        prefixStr: prefixStr + '@article_getById_Api',
        query,
        values,
        apiInfo: article_getById_Api,
        maxAge,
      })

// Route::get('articles/latest-articles', 'ArticleController@getArticleGroups');
export const articleGroups_CategoriesSchema = new schema.Entity(
  'articleGroups_Categories',
  {
    articles: [articleSchema],
  },
  {
    idAttribute: 'id',
  }
)
export const articleGroupSchema = new schema.Entity(
  'articleGroups',
  {
    categories: [articleGroups_CategoriesSchema],
    latest_articles: [articleSchema],
  },
  {
    idAttribute: 'id',
    processStrategy: (entity) => {
      entity.id = _.uniqueId('articleGroup_')
      return entity
    },
  }
)
export const articleLanguageGroupSchema = new schema.Entity(
  'article_language_group',
  {},
  {
    idAttribute: 'id',
  }
)
export const article_getArticleGroups_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/latest-articles',
  method: 'GET',
  fields,
  fieldTypes,
  paramsFields: ['category', 'keyword'],
  schema: articleGroupSchema,
  transform: createTransform(articleGroupSchema),
}
export const create_article_getArticleGroups_Api_action =
  () =>
    ({
       prefixStr = 'article_getArticleGroups_Api',
       query,
       values,
       maxAge = -1,
     }) =>
      createAsyncAction({
        prefixStr: prefixStr + '@article_getArticleGroups_Api',
        query,
        values,
        apiInfo: article_getArticleGroups_Api,
        maxAge,
      })

// Route::get('articles/article/{prop}/{id}/get-articles', 'ArticleController@getDraftArticles');
export const article_getDraftArticles_Api = {
  name: 'article_getDraftArticles',
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:prop/:id/get-articles',
  method: 'GET',
}
// Route::post('articles/article/{prop}/{id}/create-draft', 'ArticleController@createDraftArticle');
export const article_createDraftArticle_Api = {
  name: 'article_createDraftArticle',
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:prop/:id/create-draft',
  method: 'POST',
  schema: articleSchema,
  transform: createTransform(articleSchema, 'data'),
}
// Route::post('articles/article/{prop}/{id}/{article_id}/delete-draft', 'ArticleController@deleteDraftArticle');
export const article_deleteDraftArticle_Api = {
  name: 'article_deleteDraftArticle',
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:prop/:id/:article_id/delete-draft',
  schema: articleSchema,
  method: 'POST',
  transform: createTransform(articleSchema, 'data'),
}
// Route::post('articles/article/{prop}/{id}/{article_id}/update-draft', 'ArticleController@updateDraftArticle');
export const article_updateDraftArticle_Api = {
  name: 'article_updateDraftArticle',
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:prop/:id/:article_id/update-draft',
  schema: articleSchema,
  method: 'POST',
  transform: createTransform(articleSchema, 'data'),
}
// Route::post('articles/article/{prop}/{id}/{article_id}/publish', 'ArticleController@publishArticle');
export const article_publishArticle_Api = {
  name: 'article_publishArticle',
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:prop/:id/:article_id/publish',
  method: 'POST',
  transform: createTransform(feedSchema, 'data'),
}

// enhanced articles

// Route::post('articles/article/{article_id}/edit-article', 'ArticleController@updateEnhancedArticle');
export const article_updateEnhancedArticle_Api = {
  name: 'article_updateEnhancedArticle',
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:article_id/edit-article',
  method: 'POST',
  fields,
  schema: articleSchema,
  fieldTypes,
  transform: createTransform(articleSchema, 'data'),
}

// Route::post('articles/article/{article_id}/delete-article', 'ArticleController@deleteEnhancedArticle');
export const article_deleteEnhancedArticle_Api = {
  name: 'article_deleteEnhancedArticle',
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:article_id/delete-article',
  method: 'POST',
  fields,
  schema: articleSchema,
  fieldTypes,
  transform: createTransform(articleSchema, 'data'),
}

// Route::post('articles/article/{article_id}/publish-article', 'ArticleController@publishEnhancedArticle');
export const article_publishEnhancedArticle_Api = {
  name: 'article_publishEnhancedArticle',
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:article_id/publish-article',
  method: 'POST',
  fields,
  schema: articleSchema,
  fieldTypes,
  transform: createTransform(articleSchema, 'data'),
}

// Route::post('articles/article/{article_id}/rollback-article', 'ArticleController@rollbackEnhancedArticle');
export const article_rollbackEnhancedArticle_Api = {
  name: 'rollbackEnhancedArticle',
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:article_id/rollback-article',
  method: 'POST',
  fields,
  schema: articleSchema,
  fieldTypes,
  transform: createTransform(articleSchema, 'data'),
}

// Route::post('articles/article/{prop}/{id}/create-new-article', 'ArticleController@createEnhancedArticle');
export const article_createEnhancedArticle_Api = {
  name: 'article_createEnhancedArticle',
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/:prop/:id/create-new-article',
  method: 'POST',
  fields,
  schema: articleSchema,
  fieldTypes,
  transform: createTransform(articleSchema, 'data'),
}

// Route::get('articles/article/get-draft-articles-by-user', 'ArticleController@getDraftArticlesByUser');
export const article_getDraftArticlesByUser_Api = {
  name: 'getDraftArticlesByUser',
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/get-draft-articles-by-user',
  method: 'GET',
  fields,
  schema: articleSchema,
  fieldTypes,
  transform: createListTransform(articleSchema),
}

// Route::get('articles/article/get-published-articles-by-user', 'ArticleController@getPublishedArticlesByUser');
export const article_getPublishedArticlesByUser_Api = {
  name: 'getPublishedArticlesByUser',
  backend: ``,
  root: API_ROOT_URL,
  path: '/articles/article/get-published-articles-by-user',
  method: 'GET',
  fields,
  schema: articleSchema,
  fieldTypes,
  transform: createListTransform(articleSchema),
}

export const article_addRelatedArticleByLang_Api = {
  backend: `Route::post('articles/article/{article_id}/article-language-group/add', 'ArticleController@addRelatedArticleByLang');`,
  root: API_ROOT_URL,
  path: '/articles/article/:article_id/article-language-group/add',
  method: 'POST',
  fields,
  fieldTypes,
  schema: articleLanguageGroupSchema,
  transform: createTransform(articleLanguageGroupSchema, 'data'),
}

export const article_removeRelatedArticleByLang_Api = {
  backend: `Route::post('articles/article/{article_id}/article-language-group/{id}/remove', 'ArticleController@removeRelatedArticleByLang');`,
  root: API_ROOT_URL,
  path: '/articles/article/:article_id/article-language-group/:id/remove',
  method: 'POST',
  fields,
  fieldTypes,
  schema: articleLanguageGroupSchema,
  transform: createTransform(articleLanguageGroupSchema, 'data'),
}

export const article_getRelatedArticlesByLang_Api = {
  backend: `Route::get('articles/article/{article_id}/article-language-group/gets', 'ArticleController@getRelatedArticlesByLang');`,
  root: API_ROOT_URL,
  path: '/articles/article/:article_id/article-language-group/gets',
  method: 'GET',
  fields,
  fieldTypes,
  schema: articleLanguageGroupSchema,
  transform: createListTransform(articleLanguageGroupSchema),
}

export const article_fetchNonLinkedArticlesByLang_Api = {
  backend: `Route::get('articles/article/{article_id}/article-language-group/search', 'ArticleController@fetchNonLinkedArticlesByLang');`,
  root: API_ROOT_URL,
  path: '/articles/article/:article_id/article-language-group/search',
  method: 'GET',
  fields,
  fieldTypes,
  paramsFields: ['keyword'],
  schema: articleSchema,
  transform: createListTransform(articleSchema),
}

export const article_getMentionedArticlesByRef_Api = {
  backend: `Route::get('articles/{type}/{id}/get-mentioned-articles', 'ArticleController@getMentionedArticlesByRef');`,
  root: API_ROOT_URL,
  path: '/articles/:type/:id/get-mentioned-articles',
  method: 'GET',
  fields,
  fieldTypes,
  schema: articleSchema,
  transform: createListTransform(articleSchema),
}

export const product_getProductsByArticle_Api = {
  backend: `Route::get('products/suggest/article/{id}/get-products', 'ProductController@getProductsByArticle');`,
  root: API_ROOT_URL,
  path:
    '/products/suggest/article/:id/get-products',
  method: 'GET',
  fields,
  schema: articleSchema,
  fieldTypes,
  transform: createListTransform(
    articleSchema
  )
}
