import {datasetSchema} from 'apis'
import {chartSchema} from 'apis/chart'
import {baseModel, channelModel, userModel} from 'apis/model'
import {articleSchema, channelSchema, feedSchema, organizationSchema, postSchema, userSchema} from 'apis/schema'
import {keywordSchema} from 'apis/schema/keyword'
import {SelectEntityItem} from 'components/SelectEntityItem'
import {SelectEntityItemByIdname} from 'components/SelectEntityItemByIdname'
import {ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import {LocalContext} from 'modules/local/LocalContext'
import useRouter from 'modules/navigation/useRouter'
import React from 'react'
import {Helmet} from 'react-helmet'
import {Route, Switch} from 'react-router-dom'
import Translate from "../modules/local/Translate"

const aliasTitle = {
  home: 'Commodity market information',
  news: 'Commodity market information',
  notification:
    'Notifications',
  category: 'category',
  discovery: 'Discovery',
  start:
    'Commodity market information',
  welcome:
    'Commodity market information'
}

const aliasDescription = {
  home:
    'Daily update, the latest commodity, agriculture and finance market prices every day at FeedIn.'
}

const aliasKeywords = {
  home:
    'Thị trường hàng hóa, tài chính, nông nghiệp, xuất nhập khẩu hàng nông nghiệp'
}

function setLanguageToUrl(url, lang) {
  let _url = "";
  try {
    _url = new URL(url);
  } catch (e) {
    try {
      _url = new URL(window.location.origin + url);
    } catch (e) {
    }
  }
  if (!!!_url) return _url;

  let _search_params = _url.searchParams

  // new value of "id" is set to "101"
  _search_params.set('lang', lang)

  // change the search property of the main url
  _url.search = _search_params.toString()

  return _url.toString()
}

const renderMeta = ({
  _type = '',
  title = '',
  description = '',
  keywords = '',
  url = '',
  urls = [],
  image = '',
  language = 'vi',
  type = 'article',
  see_also = [],
  structuredJSON
}) => {

  return (
    <Translate>
      {
        t => (
          <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <meta charSet="utf-8" />
            <title>
              {(title ? t(title) + ' | ' : '') + 'FeedIn'}
            </title>
            <meta
              httpEquiv="content-language"
              content={language}
            />
            <link
              ref="canonical"
              href={url}
            />
            <meta
              name="description"
              content={t(description || '')}
            />
            {
              Array.isArray(keywords)
                ? (
                  <meta
                    name="keywords"
                    content={
                      keywords.map((e, key) => e.keyword).join(", ") || ' feedin.me'
                    }
                  />
                )
                : (
                  <meta
                    name="keywords"
                    content={
                      keywords || ' feedin.me'
                    }
                  />
                )
            }
            {
              Array.isArray(keywords)
                ? (
                  <meta
                    name="news_keywords"
                    content={
                      keywords.map((e, key) => e.keyword).join(", ") || ' feedin.me'
                    }
                  />
                )
                : (
                  <meta
                    name="news_keywords"
                    content={
                      keywords || ' feedin.me'
                    }
                  />
                )
            }

            <link
              id="canonical"
              rel="canonical"
              href={url}
            />
            <meta
              property="og:url"
              content={url}
            />
            <meta
              property="og:type"
              content={type}
            />
            <meta
              property="og:title"
              content={
                (title ? t(title) + ' | ' : '') +
                'FeedIn'
              }
            />
            <meta
              property="og:description"
              content={t(description || '')}
            />
            {_.map(see_also, function (
              e,
              key
            ) {
              return (
                <meta
                  key={key}
                  property="og:see_also"
                  content={
                    ROOT_URL + '/t/' + e
                  }
                />
              )
            })}
            {
              !!image && (
                <meta
                  property="og:image"
                  content={image}
                />
              )
            }
            {
              !!image && (
                <meta
                  property="og:image:width"
                  content={600}
                />
              )
            }
            {
              !!image && (
                <meta
                  property="og:image:height"
                  content={314}
                />
              )
            }

            <meta
              property="og:locale"
              content="vi_VN"
            />

            <link
              href={url}
              hrefLang="x-default"
              rel="alternate"
            />

            {
              _type !== 'category'
              && (
                ['en', 'vi'].map(e => (
                  <link key={e}
                    href={setLanguageToUrl(
                      url,
                      e
                    )}
                    hrefLang={e}
                    rel="alternate"
                  />
                ))
              )
            }
            {
              urls.map(e => (
                <link key={e.id}
                  href={e.url}
                  hrefLang={e.id}
                  rel="alternate"
                />
              ))
            }

            <meta
              property="twitter:card"
              content="summary_large_image"
            />
            <meta
              property="twitter:url"
              content={url}
            />
            <meta
              property="twitter:title"
              content={
                (title ? t(title) + ' | ' : '') +
                'FeedIn'
              }
            />
            <meta
              property="twitter:description"
              content={t(description || '')}
            />
            <meta
              property="twitter:image"
              content={image}
            />
            {structuredJSON && (
              <script
                className="structured-data-list"
                type="application/ld+json">
                {structuredJSON}
              </script>
            )}
          </Helmet>
        )
      }
    </Translate>
  )
}
const MetaData = renderMeta
const routes = [
  <Route
    key="/t/:id"
    path="/t/:id"
    render={props => {
      const id = _.get(
        props,
        'match.params.id'
      )
      return (
        <SelectEntityItem
          item={id}
          schema={keywordSchema}>
          {item => {
            const schemaOrg = _.get(
              item,
              'schema'
            )
            const structuredJSON = schemaOrg
              ? JSON.stringify(
                schemaOrg
              )
              : undefined

            return (
              (item &&
                renderMeta({
                  url:
                    ROOT_URL +
                    '/t/' +
                    item.idname,
                  title: item.title,
                  description:
                    item.description,
                  keywords:
                    item.keyword_alt,
                  structuredJSON
                })) ||
              null
            )
          }}
        </SelectEntityItem>
      )
    }}
  />,
  <Route
    key="/c/:cateId"
    path="/c/:cateId"
    render={props => {
      const id = _.get(
        props,
        'match.params.cateId'
      )
      return (
        <LocalContext.Consumer
        >
          {({ appConfig: item }) => {
            if (
              !(
                item &&
                item.categoryEntities &&
                item.categoryEntities[
                id
                ]
              )
            )
              return null

            const schemaOrg = _.get(
              item.categoryEntities[id],
              'schema'
            )
            const structuredJSON = schemaOrg
              ? JSON.stringify(
                schemaOrg
              )
              : undefined
            return renderMeta({
              _type: 'category',
              url:
                ROOT_URL +
                '/c/' +
                item.categoryEntities[
                  id
                ].idname,
              title:
                item.categoryEntities[
                  id
                ].title,
              description:
                item.categoryEntities[
                  id
                ].description,
              keywords:
                item.categoryEntities[
                  id
                ].keywords,
              structuredJSON
            })
          }}
        </LocalContext.Consumer>
      )
    }}
  />,
  <Route
    key="/category/:cateId/:refCateId"
    path="/category/:cateId/:refCateId"
    render={props => {
      const id = _.get(
        props,
        'match.params.cateId'
      )
      const ref_id = _.get(
        props,
        'match.params.refCateId'
      )

      return (
        <LocalContext.Consumer
        >
          {({ appConfig: item }) => {
            if (
              !(
                item &&
                item.categoryEntities &&
                item.categoryEntities[
                id
                ]
              )
            )
              return null

            const schemaOrg = _.get(
              item.categoryEntities[id],
              'schema'
            )
            const structuredJSON = schemaOrg
              ? JSON.stringify(
                schemaOrg
              )
              : undefined
            return renderMeta({
              _type: 'category',
              url:
                ROOT_URL +
                '/category/' +
                _.get(
                  item.categoryEntities[id],
                  'idname'
                ) + '/' +
                _.get(
                  item.categoryEntities[id],
                  'alt_idname', '#'
                )
              ,
              urls: _.get(
                item.categoryEntities[id],
                'urls', []
              ),
              title:
                item.categoryEntities[
                  id
                ].title,
              description:
                item.categoryEntities[
                  id
                ].description,
              keywords:
                Array.isArray(item.categoryEntities[id].keywords)
                  ? _.get(
                    item.categoryEntities[id],
                    'keywords',
                    []
                  ).reduce(
                    (result, kw) => {
                      result +=
                        kw.keyword +
                        ', '
                      return result
                    },
                    ''
                  )
                  : item.categoryEntities[id].keywords,
              structuredJSON
            })
          }}
        </LocalContext.Consumer>
      )
    }}
  />,

  <Route
    key="/news/article/:id"
    path="/news/article/:id"
    render={props => {
      const id = _.get(
        props,
        'match.params.id'
      )

      return (
        <SelectEntityItem
          item={id}
          schema={articleSchema}>
          {item => {

            const schemaOrg = _.get(
              item,
              'schema'
            )
            const structuredJSON = schemaOrg
              ? JSON.stringify(
                schemaOrg
              )
              : undefined

            return (
              (item &&
                renderMeta({
                  ...item,
                  url:
                    ROOT_URL +
                    '/news/article/' +
                    item.idname,
                  image: _.get(
                    item,
                    'overview.photo'
                  ),
                  title: _.get(
                    item,
                    'overview.title'
                  ),
                  description: _.get(
                    item,
                    'overview.description'
                  ),
                  keywords: _.get(
                    item,
                    'keywords',
                    []
                  ).reduce(
                    (result, kw) => {
                      result +=
                        kw.keyword +
                        ', '
                      return result
                    },
                    ''
                  ),
                  see_also: _.get(
                    item,
                    'keywords',
                    []
                  ).reduce(
                    (result, kw) => {
                      result.push(
                        kw.idname
                      )
                      return result
                    },
                    []
                  ),
                  structuredJSON
                })) ||
              null
            )
          }}
        </SelectEntityItem>
      )
    }}
  />,

  <Route
    key="/news/post/:id"
    path="/news/post/:id"
    render={props => {
      const id = _.get(
        props,
        'match.params.id'
      )
      return (
        <SelectEntityItem
          item={id}
          schema={postSchema}>
          {item => {
            const schemaOrg = _.get(
              item,
              'schema'
            )
            const structuredJSON = schemaOrg
              ? JSON.stringify(
                schemaOrg
              )
              : undefined

            return (
              (item &&
                renderMeta({
                  ...item,
                  structuredJSON,
                  url:
                    ROOT_URL +
                    '/news/post/' +
                    item.id,
                  image: _.get(
                    item,
                    'overview.photo'
                  ),
                  title: _.get(
                    item,
                    'overview.title'
                  ),
                  description: _.get(
                    item,
                    'overview.description'
                  ),
                  keywords: _.get(
                    item,
                    'keywords',
                    []
                  ).reduce(
                    (result, kw) => {
                      result +=
                        kw.keyword +
                        ', '
                      return result
                    },
                    ''
                  ),
                  see_also: _.get(
                    item,
                    'keywords',
                    []
                  ).reduce(
                    (result, kw) => {
                      result.push(
                        kw.idname
                      )
                      return result
                    },
                    []
                  )
                })) ||
              null
            )
          }}
        </SelectEntityItem>
      )
    }}
  />,

  <Route
    key="/news/:type/:id"
    path="/news/:type/:id"
    render={props => {
      const id = _.get(
        props,
        'match.params.id'
      )
      return (
        <SelectEntityItem
          item={id}
          schema={feedSchema}>
          {item => {
            return null
          }}
        </SelectEntityItem>
      )
    }}
  />,

  <Route
    key="/channel/:id"
    path="/channel/:id"
    render={props => {
      const id = _.get(
        props,
        'match.params.id'
      )
      return (
        <SelectEntityItem
          item={id}
          schema={channelSchema}>
          {item => {
            const schemaOrg = _.get(
              item,
              'schema'
            )
            const structuredJSON = schemaOrg
              ? JSON.stringify(
                schemaOrg
              )
              : undefined

            return (
              (item || null) && (
                <MetaData
                  language={
                    item.language
                  }
                  url={
                    ROOT_URL +
                    '/channel/' +
                    item.idname
                  }
                  {...channelModel.getMeta(
                    item
                  )}
                  keywords={
                    item.keywords
                  }
                  structuredJSON={
                    structuredJSON
                  }
                />
              )
            )
          }}
        </SelectEntityItem>
      )
    }}
  />,
  <Route
    key="/user/:id"
    path="/user/:id"
    render={props => {
      const id = _.get(
        props,
        'match.params.id'
      )
      return (
        <SelectEntityItemByIdname
          item={id}
          schema={userSchema}>
          {item => {
            return (
              (item && (
                <MetaData
                  url={
                    ROOT_URL +
                    '/user/' +
                    item.username
                  }
                  {...userModel.getMeta(
                    item
                  )}
                />
              )) ||
              null
            )
          }}
        </SelectEntityItemByIdname>
      )
    }}
  />,
  <Route
    key="/organization/:id"
    path="/organization/:id"
    render={props => {
      const id = _.get(
        props,
        'match.params.id'
      )
      return (
        <SelectEntityItem
          item={id}
          schema={organizationSchema}>
          {item => {
            return (
              (item || null) && (
                <MetaData
                  url={
                    ROOT_URL +
                    '/organization/' +
                    item.idname
                  }
                  {...baseModel.getMeta(
                    item
                  )}
                />
              )
            )
          }}
        </SelectEntityItem>
      )
    }}
  />,
  <Route
    key="/data/:id"
    path="/data/:id"
    render={props => {
      const id = _.get(
        props,
        'match.params.id'
      )
      return (
        <SelectEntityItem
          item={id}
          schema={datasetSchema}>
          {item => {
            return (
              (item || null) && (
                <MetaData
                  image={
                    ROOT_URL +
                    '/images/dataset_default.jpg'
                  }
                  url={
                    ROOT_URL +
                    '/data/' +
                    item.idname
                  }
                  {...baseModel.getMeta(
                    item
                  )}
                  keywords={
                    item.keywords
                  }
                />
              )
            )
          }}
        </SelectEntityItem>
      )
    }}
  />,
  <Route
    key="/chart/:id"
    path="/chart/:id"
    render={props => {
      const id = _.get(
        props,
        'match.params.id'
      )
      return (
        <SelectEntityItem
          item={id}
          schema={chartSchema}>
          {item => {
            const schemaOrg = _.get(
              item,
              'schema'
            )
            const structuredJSON = schemaOrg
              ? JSON.stringify(
                schemaOrg
              )
              : undefined

            return (
              (item || null) && (
                <MetaData
                  image={_.get(
                    item,
                    'avatar',
                    ROOT_URL +
                    '/images/chart_default.jpg'
                  )}
                  url={
                    ROOT_URL +
                    '/chart/' +
                    item.idname
                  }
                  {...baseModel.getMeta(
                    item
                  )}
                  keywords={
                    _.get(item, 'keyword_objects', []).map((e, key) => e.keyword).join(", ")
                  }
                  structuredJSON={
                    structuredJSON
                  }
                />
              )
            )
          }}
        </SelectEntityItem>
      )
    }}
  />,

  <Route
    key="/news/daily"
    path="/news/daily"
    exact={true}
    render={() =>
      renderMeta({
        title: aliasTitle['news'],
        type: 'website',
        description:
          aliasDescription['home'],
        keywords: aliasKeywords['home'],
        url:
          ROOT_URL +
          window.location.pathname
      })
    }
  />,

  <Route
    key="/news"
    path="/news"
    exact={true}
    render={() =>
      renderMeta({
        title: aliasTitle['news'],
        type: 'website',
        description:
          aliasDescription['home'],
        keywords: aliasKeywords['home'],
        url:
          ROOT_URL +
          window.location.pathname
      })
    }
  />,

  <Route
    key="/"
    path="/"
    exact={true}
    render={() => {
      const host = window.location.host;
      const hostArray = host.split('.');
      if (!host.includes("localhost")) {
        hostArray.pop();
      }
      hostArray.pop();

      if (!!_.first(hostArray)) {
        return (
          <SelectEntityItem
            item={_.first(hostArray)}
            schema={organizationSchema}>
            {item => {
              return (
                (item || null) && (
                  <MetaData
                    url={
                      window.location.host
                    }
                    {...baseModel.getMeta(
                      item
                    )}
                  />
                )
              )
            }}
          </SelectEntityItem>
        )
      }

      return renderMeta({
        title: aliasTitle['home'],
        type: 'website',
        description:
          aliasDescription['home'],
        keywords: aliasKeywords['home'],
        url:
          ROOT_URL +
          window.location.pathname
      })
    }}
  />,
  <Route
    key="home"
    path=""
    exact={true}
    render={() => {
      const host = window.location.host;
      const hostArray = host.split('.');
      if (!host.includes("localhost")) {
        hostArray.pop();
      }
      hostArray.pop();

      if (!!_.first(hostArray)) {
        return (
          <SelectEntityItem
            item={_.first(hostArray)}
            schema={organizationSchema}>
            {item => {
              return (
                (item || null) && (
                  <MetaData
                    url={
                      window.location.host
                    }
                    {...baseModel.getMeta(
                      item
                    )}
                  />
                )
              )
            }}
          </SelectEntityItem>
        )
      }

      return renderMeta({
        title: aliasTitle['home'],
        type: 'website',
        description:
          aliasDescription['home'],
        keywords: aliasKeywords['home'],
        url:
          ROOT_URL +
          window.location.pathname
      })
    }}
  />,
  <Route
    key="/:type/:id"
    path="/:type/:id"
    exact={true}
    render={props => {
      const type = _.upperFirst(
        _.get(
          props,
          'match.params.type'
        )
      )
      return renderMeta({
        title: type,
        url:
          ROOT_URL +
          window.location.pathname
      })
    }}
  />,

  <Route
    key="/:type"
    path="/:type"
    exact={true}
    render={props => {
      const type = _.upperFirst(
        aliasTitle[
        _.get(
          props,
          'match.params.type'
        )
        ] ??
        _.get(
          props,
          'match.params.type'
        )
      )
      return renderMeta({
        title: type,
        url:
          ROOT_URL +
          window.location.pathname
      })
    }}
  />,
  <Route
    key="*"
    path="*"
    render={() =>
      renderMeta({
        title: _.upperFirst(
          _.get(
            window.location.pathname.split(
              '/'
            ),
            '1',
            'FeedIn'
          )
        ),
        url:
          ROOT_URL +
          window.location.pathname
      })
    }
  />
]
const LoadMeta = ({ config }) => {
  const { location = {} } = useRouter()

  return (
    <Switch location={location}>
      {routes}
    </Switch>
  )
}
export default LoadMeta
