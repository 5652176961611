import {Button, Result, Space, Typography} from 'antd'
import {auth_subscribeTrialDataset_Api} from 'apis'
import classNames from 'classnames'
import ContentContainer from 'components/ContentContainer'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import Modal from 'components/Modal/Modal'
import Null from 'components/NullComponent'
import Toggle from 'components/Toggle'
import chartSize from 'conts/chartSize'
import viewModes from 'conts/viewModes'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import {AsyncByActionWithNotify} from 'modules/asyncCache/components/Async'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import formatCurrency from 'views/Billing/components/formatCurrency'
import {SubscribeModal} from 'views/Data/SubscribeModal'
import {useRequiredLogin} from 'views/Server/useRequiredLogin'
import LoadingChart from '../LoadingChart'

const PremiumChartHolderStateless = ({
  viewmode = viewModes.BLOCK,
  ...props
}) => {
  const t = useTranslate()
  const handleRequiredLogin = useRequiredLogin()
  const { isSm } = useContext(
    LayoutContext
  )
  const { item } = props

  const _chartSize = _.get(
    props,
    'size',
    isSm
      ? chartSize.SMALL
      : chartSize.NORMAL
  )

  const chartC = (
    <div className="verticalList">
      <div className="relative ">
        <LoadingChart title="" />
        {/*<img*/}
        {/*  style={{opacity: 0.1}}*/}
        {/*  className="w-full object-cover"*/}
        {/*  src={chartholder}*/}
        {/*  alt={item.title}*/}
        {/*/>*/}
        <Space
          direction="vertical"
          className="flex absolute top-0 left-0 w-full h-full flex-col justify-center items-center z-10">
          <div
            style={{
              textShadow:
                '1px 1px 1px #fff;'
            }}
            className="text-lg font-bold">
            {t(
              'Please register to view this content'
            )}
          </div>
          <Toggle>
            {(isToggle, toggle) => {
              return (
                <>
                  <Button
                    onClick={toggle}
                    type="primary">
                    {t('subscribe')}
                  </Button>
                  {isToggle && (
                    <Modal
                      onCancel={toggle}
                      visible
                      footer={null}>
                      <ContentContainer className="w-full p-3">
                        <Result
                          icon={Null}
                          status="info"
                          title={t(
                            'charged fee data'
                          )}>
                          <div>
                            <Typography.Paragraph>
                              <Typography.Text
                                style={{
                                  fontSize: 14
                                }}>
                                {t(
                                  'Registering to use advanced data, you will have to pay'
                                )}{' '}
                                {formatCurrency(
                                  item.price
                                )}
                                {' / '}
                                <span className="lowercase">
                                  {t(
                                    item.billing_period
                                  )}
                                </span>
                              </Typography.Text>
                            </Typography.Paragraph>
                            <div className="verticalList">
                              <AsyncByActionWithNotify>
                                {(
                                  data,
                                  dispath
                                ) => (
                                  <Button
                                    onClick={() => {
                                      handleRequiredLogin(
                                        () =>
                                          dispath(
                                            createAsyncAction(
                                              {
                                                apiInfo: auth_subscribeTrialDataset_Api,
                                                query: {
                                                  ':id':
                                                    item
                                                      .dataset
                                                      .idname
                                                }
                                              }
                                            )
                                          )
                                      )
                                    }}
                                    loading={
                                      data.isLoading
                                    }
                                    block
                                    // className="my-3"
                                    type="primary"
                                    size="large"
                                    className="rounded">
                                    {t(
                                      'start trial'
                                    )}
                                  </Button>
                                )}
                              </AsyncByActionWithNotify>

                              <Toggle>
                                {(
                                  isToggle,
                                  toggle
                                ) => {
                                  return (
                                    <>
                                      <Button
                                        onClick={() => {
                                          handleRequiredLogin(
                                            () =>
                                              toggle()
                                          )
                                        }}
                                        loading={
                                          isToggle
                                        }
                                        block
                                        size="large"
                                        type="ghost"
                                        className="rounded border-dashed">
                                        {t(
                                          'subscribe'
                                        )}
                                      </Button>
                                      {isToggle && (
                                        <SubscribeModal
                                          item={
                                            item
                                          }
                                          visible={
                                            isToggle
                                          }
                                          onClose={() =>
                                            toggle()
                                          }
                                        />
                                      )}
                                    </>
                                  )
                                }}
                              </Toggle>
                            </div>
                          </div>
                        </Result>
                      </ContentContainer>
                    </Modal>
                  )}
                </>
              )
            }}
          </Toggle>
        </Space>
      </div>
    </div>
  )

  return (
    <div
      style={{
        minHeight:
          _chartSize === chartSize.SMALL
            ? '55Vh'
            : 'calc(100Vh - 240px)'
      }}
      className={classNames(
        props.className,
        'h-full w-full'
      )}>
      {chartC}
    </div>
  )
}

export default PremiumChartHolderStateless
