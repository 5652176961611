import { createLazyComponent } from 'helpers/createLazyComponent'
import React from 'react'

const OrganizationLazy =
  createLazyComponent(() =>
    import('./Organization')
  )

const Organization = (props) => (
  <OrganizationLazy {...props} />
)

export default Organization
