import _ from 'lodash'
import moment from 'moment'
import React from 'react'

export const Datetime = ({
  children: date,
  ...props
}) => {
  const momentDate = moment(date)
  const diffDays = moment().diff(
    momentDate,
    'days'
  )
  if (diffDays === 0) {
    if (_.isFunction(date))
      return date(
        momentDate.format('HH:mm')
      )
    return (
      <time
        className="text-xs block text-color-300"
        {...props}>
        {momentDate.format('HH:mm')}
      </time>
    )
  }
  if (_.isFunction(date))
    return date(
      momentDate.format('DD/MM HH:mm')
    )
  return (
    <time
      className="text-xs block text-color-300"
      {...props}>
      {momentDate.format('DD/MM HH:mm')}
    </time>
  )
}
