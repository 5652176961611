import React from 'react'
import {Link} from 'react-router-dom'
import Translate from "../../../modules/local/Translate";
import {FcStatistics} from "react-icons/fc";
import {IoEye} from "react-icons/io5";
import numeral from "numeral";

export const renderChartItemRow = e => {

  return (
    <Translate>
      {t => (
        <div
          key={e.id}
          className="cursor-pointer px-3 py-1 flex space-x-3">

          <FcStatistics
            className="mr-2 text-color-100 text-3xl w-8 h-8 justify-start"/>

          <div className="flex flex-col w-full pb-1 border-b">
            <Link
              to={`/chart/${e.idname}`}
              className="font-medium">
              {e.title}
            </Link>

            <div className="flex space-x-3">

              <div className="flex flex-row space-x-1 items-center truncate">
                <div className="text-xs flex items-center justify-center">
                  {
                    (e.total_views && e.total_views > 0) && (
                      <div className="flex items-center justify-center">
                        <IoEye
                          style={{
                            color: '#bfbfbf'
                          }}
                        />
                        <span className="px-1">{numeral(e.total_views).format()}</span>
                      </div>
                    )
                  }
                </div>
              </div>
              <div className="flex-1"/>

            </div>

          </div>
        </div>
      )}
    </Translate>
  )

}
