import {Button, Menu, Modal, Tooltip} from 'antd'
import {member_joinAsMember_Api, member_leaveAsMember_Api} from 'apis'
import {getId, getType} from 'apis/model/base'
import DrawMenu from 'components/drawer/DrawMenu'
import Null from 'components/NullComponent'
import {UseHook} from 'components/UseHook'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useContext} from 'react'
import {IoCallSharp, IoExitOutline} from 'react-icons/io5'
import {RxDotsHorizontal, RxEnter} from 'react-icons/rx'
import LoadingPage from 'views/LoadingPage'
import {useJoin} from 'views/Server/ServerContainer'
import {renderIf, renderOwnChild, renderPhone} from 'views/Shared'
import {FollowButton, VoteButton} from '../custom/ToggleActionButton'
import {fromAppEntity} from '../enums'
import {bindQueryParams} from '../functions/routerHelper'
import {ConversationWith} from './createChatButton'
import {notifyOnError} from './createErrorEvent'
import {NotificationActionTypes, successNotify} from './createNotification'
import {RiPhoneFill} from "react-icons/ri";

const UseJoinAction = ({id, type, children, join_status}) => {
  const handleJoin = useJoin({
    id,
    type,
    status: join_status,
  })

  return _.isFunction(children)
    ? children(handleJoin, {id, type})
    : children || null
}

export const useJoinAction = ({id, type, joined_status}) => {
  const t = useTranslate()
  const onError = notifyOnError(t)
  const onSuccess = () => {
    successNotify(NotificationActionTypes.INFORMATION, t)
  }
  const {handleAsyncAction: join} = useAsyncAction({
    onError,
    onSuccess,
    apiInfo: member_joinAsMember_Api,
    query: bindQueryParams([{id}, {prop: type}]),
  })
  const {handleAsyncAction: leave} = useAsyncAction({
    onError,
    onSuccess,
    apiInfo: member_leaveAsMember_Api,
    query: bindQueryParams([{id}, {prop: type}]),
  })
  return {
    id,
    type,
    join,
    leave,
    joined_status,
    toggle: () => {
      if (joined_status) {
        leave()
      } else {
        join()
      }
    },
  }
}

const ActionMemberJoin = (props) => (
  <Translate>
    {(t) => (
      <UseJoinAction
        join_status={false}
        {...props}>
        {(handleJoin) => (
          <Button
            type="primary"
            onClick={handleJoin}
            icon={<IoExitOutline/>}
            className="flex gap-2 items-center">
            {t('join')}
          </Button>
        )}
      </UseJoinAction>
    )}
  </Translate>
)

const ActionMemberLeave = (props) => {
  const t = useTranslate()
  const showConfirmDialog = useCallback(
    (onOk) =>
      Modal.confirm({
        title: t('do you want to leave'),
        content: (
          <span className="text-sm text-color-400 italic">
            {t('you will no longer be a member of this community')}
          </span>
        ),
        className: 'custom-modal',
        okText: t('sure'),
        cancelText: t('ignore'),
        okButtonProps: {
          type: 'primary',
          danger: true,
          className: 'rounded-lg no-shadow no-text-shadow no-border',
        },
        cancelButtonProps: {
          className: 'rounded-lg no-shadow no-text-shadow no-border',
        },
        onOk,
      }),
    [t]
  )
  return (
    <UseJoinAction
      {...props}
      join_status={true}>
      {(handleLeave) => (
        <div
          onClick={() => {
            showConfirmDialog(handleLeave)
          }}
          className="flex items-center px-3 py-1 hover:text-primary">
          <IoExitOutline/>
          <span className="text-center ml-2">{t('leave')}</span>
        </div>
      )}
    </UseJoinAction>
  )
}

const withToggleButton =
  ({renderLabel = (isToggle) => String(!!isToggle), ...props}) =>
    ({
       onClick,
       isToggle,
       active: active_button_params,
       inactive: inactive_button_params,
     }) =>
      (
        <Button
          ghost={isToggle}
          onClick={onClick}
          type="primary"
          className="font-medium no-shadow no-text-shadow rounded-lg"
          {...props}>
          <span className="text-sm">{renderLabel(isToggle)}</span>
        </Button>
      )

const createStatusBar =
  (type, Context) =>
    ({canJoin = false, Wrapper = renderOwnChild}) => {
      const t = useTranslate()

      const context = useContext(Context)

      const {contextName, ...rest} = fromAppEntity(type)

      const item = _.get(context, contextName)

      const {isLoading = false} = context || {}

      const contacts = _.get(item, 'contacts', [])

      let member = _.get(_.first(contacts), 'contact_member')
      let phone = _.get(_.first(contacts), 'phone')
      const join_status = Boolean(_.get(item, 'join_status'))

      const action_params = {
        id: getId(item),
        type: getType(item),
      }

      if (_.isEmpty(item)) return null;

      console.log(1111111, item)

      return (
        <Wrapper>
          <div className="flex items-center flex-wrap gap-3 mt-2 mb-3">
            <div className="flex gap-3 flex-1">
              <FollowButton item={item}/>
              <VoteButton item={item}/>
            </div>
            {join_status ? (
              <DrawMenu
                component={
                  <div
                    style={{
                      width: 32,
                      height: 32,
                    }}
                    className="cursor-pointer rounded-md background-100 hover:background-200 text-color-400 hover:text-color-100 flex flex-center my-auto border border-color-50">
                    <RxDotsHorizontal size={16} className="mx-auto"/>
                  </div>
                }>
                {(__, toggle) => (
                  <Menu onClick={toggle}>
                    <Menu.Item>
                      <ActionMemberLeave {...action_params} />
                    </Menu.Item>
                  </Menu>
                )}
              </DrawMenu>
            ) : (
              renderIf(
                canJoin,
                <UseHook
                  hook={useJoinAction}
                  initialValue={action_params}>
                  {({join = Null}) => (
                    <Tooltip title={t('join')}>
                      <div className="flex flex-center">
                        <Button
                          type="primary"
                          block={true}
                          icon={<RxEnter/>}
                          onClick={() => {
                            join()
                          }}
                          className="rounded-lg no-shadow no-text-shadow px-2 flex flex-center capitalize text-sm md:text-base"
                        />
                      </div>
                    </Tooltip>
                  )}
                </UseHook>
              )
            )}
          </div>

          {type === 'organization' && (
            <React.Fragment>
              {_.get(member, 'user') ? (
                <div className="flex flex-col">
                  <div className="uppercase text-sm font-medium text-color-400 border-b mb-2">
                    {t('contact')}
                  </div>
                  <div className="grid grid-cols-2 gap-3 flex-1">
                    {!!_.get(member, 'user') ? (
                      <ConversationWith
                        params={{
                          receiver_id: getId(_.get(member, 'user')),
                        }}
                      />
                    ) : null}
                    {!!phone &&
                    renderPhone(
                      phone,
                      (value) => (
                        <div
                          style={{padding: '4px 15px', height: '32px', fontSize: 14}}
                          className="w-full bg-primary-50 hover:bg-primary font-semibold text-primary-600 hover:text-white rounded-lg flex flex-center gap-3 cursor-pointer">
                          <IoCallSharp/>
                          {value}
                        </div>
                      ),
                      ({href, children}) => (
                        <a
                          href={href}
                          className="no-underline">
                          {children}
                        </a>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <ConversationWith
                  Wrapper={({children}) =>
                    !!isLoading ? (
                      <LoadingPage/>
                    ) : (
                      <div className="flex flex-col">
                        <div className="uppercase text-sm font-medium text-color-400 border-b mb-2">
                          {t('contact')}
                        </div>
                        <div className="grid grid-cols-2 gap-3 flex-1">
                          {children}
                        </div>
                      </div>
                    )
                  }
                  params={{
                    receiver_id: getId(_.get(item, 'owner')),
                  }}
                  EmptyHolder={Null}
                />
              )}
            </React.Fragment>
          )}
        </Wrapper>
      )
    }

export default createStatusBar
