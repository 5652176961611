import {API_ROOT_URL} from 'envs/_current/config'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import Request from './middleware/request'

export * from './apps'
export * from './article'
export * from './auth'
export * from './billing'
export * from './bookmarks'
export * from './category'
export * from './channel'
export * from './chart'
export * from './comment'
export * from './contact'
export * from './dataset'
export * from './host'
export * from './image'
export * from './keyword'
export * from './location'
export * from './member'
export * from './message'
export * from './notification'
export * from './organization'
export * from './organizationPartner'
export * from './participant'
export * from './payments'
export * from './permission'
export * from './pin'
export * from './poll'
export * from './post'
export * from './product'
export * from './referral'
export * from './role'
export * from './search'
export * from './subscription'
export * from './systems'
export * from './test'
export * from './url-link'
export * from './user'
export * from './vote'

export function postRequest({
                              url,
                              data
                            }) {
  return Request.post(
    `${API_ROOT_URL}/${url}/info`,
    data
  )
  // return new Promise(resolve => {
  // 	setTimeout(() => resolve({ status: 400 }), 1000)
  // })
}

export const create_loadMore_Api_action = ({
                                             prefixStr,
                                             path,
                                             apiInfo,
                                             ...rest
                                           }) =>
  createAsyncAction({
    asyncId: `${prefixStr}/loadmore/${path}`,
    apiInfo: {
      ..._.omit(apiInfo, ['root']),
      path
    },
    ...rest
  })
