import classnames from 'classnames'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import moment from 'moment'
import React, {useContext} from 'react'
import {BsFacebook, BsLinkedin, BsShieldLockFill, BsTwitter, BsYoutube,} from 'react-icons/bs'
import {IoEarth} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {withProps} from 'recompose'
import {CustomEditorRenderer} from 'views/AppLocals/custom/CustomRichEditor'
import {FlexCol} from 'views/AppLocals/Templates/ItemTemplate'
import {LayoutContext} from '../../../../components/layouts/Default/LayoutContext'
import getTitle from '../../../../helpers/getTitle'
import {useAppConfig} from '../../../../modules/local'
import useTranslate from '../../../../modules/local/useTranslate'
import {EventTypeIcon, getEventTypeTitle,} from '../../../Event/components/EventOverviewSetting'
import {renderColon, renderFromToTime, renderIf} from '../../../Shared'
import {isRawContentEmpty} from '../../../SlateEditor/functions/parseRawContent'
import createPagerFooter from '../../factory/createPagerFooter'
import OrganizationContainerContext from '../OrganizationContainerContext'
import OrganizationNews from './OrganizationNews'
import Translate from "../../../../modules/local/Translate";
import {PiArrowRightBold} from "react-icons/pi";
import {ChartContainer, ChartContext} from "../../../Chart/ChartContext";
import {ChartStateless} from "../../../Chart/Charts";
import {Slider} from "../../../Organization/Slider";
import ImageLoader from "../../../../components/ImageLoader";
import ProductLink from "../../../Market/components/ProductLink";
import OrderRequestProvider from "../../../OrderRequest/OrderRequestProvider";
import SpotRequestForm, {SpotRequestTypes} from "../../../Market/components/SpotBid/SpotRequestForm";
import productModel from "../../../../apis/model/product";
import {productSchema} from "../../../../apis/schema";
import {Button} from "antd";
import ChartWrapper from "../../../Chart/Charts/ChartWrapper/ChartWrapper.lazy";
import {OrganizationContext} from "../../../Organization/OrganizationContext";
import {getId, getType} from "../../../../apis/model/base";
import productImageHolder from "../../../Market/productImageHolder";

function renderIntervalWeekly(value) {
  let label = ''
  switch (value) {
    case '1000000':
      label = 'monday'
      break
    case '0100000':
      label = 'tuesday'
      break
    case '0010000':
      label = 'wednesday'
      break
    case '0001000':
      label = 'thursday'
      break
    case '0000100':
      label = 'friday'
      break
    case '0000010':
      label = 'saturday'
      break
    case '0000001':
      label = 'saturday'
      break
    default :
      label = null
      break
  }
  return label ? label : null
}

function renderInterval(value) {
  let label
  switch (value) {
    case 'D':
      label = 'daily'
      break
    case 'W':
      label = 'weekly'
      break
    case 'M':
      label = 'monthly'
      break
    default :
      label = 'do not repeat'
      break
  }
  return (
    <Translate>
      {(t) => t(label)}
    </Translate>
  )
}

const OrganizationProfile = ({organization, children}) => {
  const t = useTranslate()
  const {
    business_types,
    company_types,
    number_of_employees,
    annual_turnover = {},
  } = useAppConfig()

  const business_type = _.get(
    business_types,
    _.get(organization, 'business_type')
  )
  const company_type = _.get(company_types, _.get(organization, 'company_type'))
  const annual = _.get(annual_turnover, _.get(organization, 'annual_turnover'))
  const number_of_employee = _.get(
    number_of_employees,
    _.get(organization, 'number_of_employees')
  )

  const ItemInformation = ({label, value, key = null, className = ''}) => (
    <div
      key={key}
      className="flex items-baseline gap-1">
      <span
        className={classnames(
          'text-color-500 font-light  text-xs italic',
          className
        )}>
        {t(label)} {':'}
      </span>
      <span className="text-sm text-color-000 font-medium flex-1">{value}</span>
    </div>
  )

  return _.isFunction(children)
    ? children(
      <div className="flex flex-col gap-2">
        {_.get(organization, 'tax_code') && (
          <ItemInformation
            label="tax code"
            value={_.get(organization, 'tax_code')}
          />
        )}
        {_.get(organization, 'established_year') && (
          <ItemInformation
            label="Year of Establishment"
            value={_.get(organization, 'established_year')}
          />
        )}
        {_.get(organization, 'business_type') && (
          <ItemInformation
            label="Nature of Business"
            value={business_type}
          />
        )}
        {_.get(organization, 'company_type') && (
          <ItemInformation
            label="Legal Status of Firm"
            value={company_type}
          />
        )}
        {_.get(organization, 'annual_turnover') && (
          <ItemInformation
            label="Annual Turnover"
            value={annual}
          />
        )}
        {_.get(organization, 'number_of_employees') && (
          <ItemInformation
            label="Number of Employees"
            value={number_of_employee}
          />
        )}
      </div>,
      {
        data: organization,
      }
    )
    : children || null
}


const breakpointProductLayoutConfig = {
  xs: {
    productSize: 2
  },
  sm: {
    productSize: 2
  },
  md: {
    productSize: 4
  },
  lg: {
    productSize: 6
  }
}

const OrganizationInfo = ({Wrapper = FlexCol}) => {
  // const {organization} = useContext(OrganizationContainerContext)
  const {organization, ...restOrg} = useContext(OrganizationContext);
  const t = useTranslate()
  const {isSm, breakpoint} = useContext(LayoutContext)

  const website = _.get(organization, 'website')
  const examinations = _.get(organization, 'examinations', [])
  const charts = _.get(organization, 'charts', [])
  const products = _.get(organization, 'products', [])
  const events = _.get(organization, 'events', [])

  const linkPage = _.get(organization, 'linked_pages', [])

  if (_.isEmpty(organization)) return null

  const {tags, about, description, categories = []} = organization;
  const chartIndex = 0;

  const IconPage = ({page, size, className}) => {
    switch (page) {
      case 'facebook':
        return (
          <BsFacebook
            size={size}
            className={className}
          />
        )
      case 'linkedin':
        return (
          <BsLinkedin
            size={size}
            className={className}
          />
        )
      case 'twitter':
        return (
          <BsTwitter
            size={size}
            className={className}
          />
        )
      default:
        return (
          <IoEarth
            size={22}
            className={className}
          />
        )
    }
  }

  return (
    <Wrapper className="items-start space-y-6">

      {renderIf(
          charts.length,
          <section className="w-full body-font relative p-3">
            <div className="flex justify-center items-center font-semibold text-2xl uppercase mb-6 mt-8">
              {
                t('data')
              }
            </div>
            <div className="lg:px-6 items-center justify-center flex flex-col">
              <div className="p-3 rounded hover:shadow w-full h-full border min-h">
                <ChartContainer
                  key={chartIndex}
                  WrapperHodler={ChartWrapper}
                  id={_.get(organization, 'charts.' + chartIndex + '.idname')}>
                  <ChartContext.Consumer>
                    {({
                        item,
                        ...props
                      }) => {
                      return (
                        <ChartStateless
                          hideshowmore={true}
                          key={item.idname}
                          item={item}
                          CWrapper={ChartWrapper}
                        />
                      )
                    }}
                  </ChartContext.Consumer>
                </ChartContainer>
              </div>

              <div className="flex items-center justify-center p-3">
                <Link to={`${getLinkToDetail(organization)}/data`}
                      className="px-8 py-1 border rounded-full hover:border-primary no-underline font-light">
                  <div className="font-light">{t('show more')}</div>
                </Link>
              </div>
            </div>
          </section>
      )}
      {renderIf(
          _.get(restOrg, 'groups', []).length,
          <section className="w-full body-font relative p-3">
            <div className="flex justify-center items-center font-bold text-2xl text-color-200 uppercase tracking-widest mb-6 mt-8">
              {t('products')}
            </div>
            <div className="mb-6">
              <Slider/>
            </div>
            {_.get(restOrg, 'groups', []).map(e => (
                <div key={e.id} className="flex flex-col gap-1 mb-8">
                  <div className="font-semibold mb-2 border-b border-primary text-primary-600 uppercase tracking-wider">
                    {e.title}
                  </div>
                  <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-3">
                    {_.get(e, 'products', [])
                        .slice(0, _.get(breakpointProductLayoutConfig, breakpoint + '.productSize', 6))
                        .map(p => (
                            <div
                                key={p.id}
                                className="flex flex-col rounded-md hover:shadow-items border border-color-50 background">
                              <ImageLoader
                                  style={{ height: 150 }}
                                  className="object-cover rounded-tl-md rounded-tr-md border-0"
                                  src={p.avatar || productImageHolder}
                              />
                              <div className="p-2 flex flex-col flex-1 gap-2">
                                <ProductLink
                                    className="font-semibold text-sm md:text-base flex-1 leading-tight no-underline"
                                    item={p}>
                                  {p.title}
                                </ProductLink>
                                <div className="mt-3 text-center">
                                  <OrderRequestProvider organization={organization}>
                                    <SpotRequestForm
                                        target={{
                                          id: productModel.getId(p),
                                          schema: productSchema
                                        }}
                                        childOnly={!!!organization}
                                        id={getId(organization)}
                                        type={getType(organization)}
                                        requestType={SpotRequestTypes.PRICE}>
                                      {(toggle) => {
                                        return (
                                            <Button
                                                type="button"
                                                className="text-sm border border-primary button-rounded-lg leading-tight"
                                                style={{
                                                  padding: '3px 12px',
                                                  background: 'var(--background)',
                                                  color: 'var(--color-primary-600)',
                                                  fontWeight: 500,
                                                  height: 30,
                                                }}
                                                onClick={toggle}>
                                              {t('Get Best Price')}
                                            </Button>
                                        )
                                      }}
                                    </SpotRequestForm>
                                  </OrderRequestProvider>
                                </div>
                              </div>
                            </div>
                        ))
                    }
                  </div>

                </div>
            ))}
            <div className="flex items-center justify-center mb-3 mt-10">
              <Link
                  to={`${getLinkToDetail(organization)}/products`}
                  style={{ padding: '2px 12px' }}
                  className="border hover:border-primary text-color-400 rounded-full no-underline leading-tight">
                <span className="font-medium text-xs italic">{t('show more')}</span>
              </Link>
            </div>
          </section>
      )}

      {examinations.length > 0 && (
        <HeaderItemInfo title="list of examination">
          <div className="flex flex-col gap-2">
            {examinations.map((e, i) => (
              <div
                key={i}
                className="flex flex-col p-2 rounded-lg border border-color-50 hover:shadow-items relative">
                {_.get(e, 'privacy') === 2 && (
                  <BsShieldLockFill
                    size={20}
                    className="absolute text-blue-700 opacity-80"
                    style={{top: '0.5rem', right: '0.5rem'}}
                  />
                )}
                <Link
                  to={getLinkToDetail(e)}
                  className="font-bold text-color-000 no-underline max-lines-1 mb-1">
                  {getTitle(e)}
                </Link>
                <div className="flex flex-col md:flex-row items-baseline">
                  <span className="text-xs text-color-500 italic">
                      {`${t('time takes place')} : `}
                  </span>
                  <div className="w-full flex flex-1 items-center">
                      <span className="font-bold text-xs text-color-100 italic">
                          {renderFromToTime(_.get(e, 'time_open'), _.get(e, 'time_close'))}
                      </span>
                    <div className="flex-1"/>
                    <span className="text-xs md:text-xs italic font-semibold">
                          {_.get(e, 'is_expired') === true ? (
                            <span className="text-red-600">{t('close')}</span>
                          ) : moment(_.get(e, 'time_open')).isAfter(moment()) ? (
                            <span className="text-green-700">{t('upcoming')}</span>
                          ) : (
                            <span className="text-blue-700">{t('opening')}</span>
                          )}
                      </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </HeaderItemInfo>
      )}
      {events.length > 0 && (
        <HeaderItemInfo title="list of event">
          <div className="flex flex-col gap-2">
            {events.map((e, i) => {

              const {time_open, time_close, event_type, interval_type, interval_daily, is_expired, location_name} = e ?? {}

              const renderLabelTime = (value) => {
                let label
                if (interval_type === 'D') {
                  label = moment(value).format('HH:mm')
                } else if (interval_type === 'W') {
                  label = [
                    moment(value).format('HH:mm'),
                    t(renderIntervalWeekly(interval_daily))
                  ].join(' - ')
                } else if (interval_type === 'M') {
                  label = [
                    moment(value).format('HH:mm'),
                    '-',
                    t('date'),
                    moment(value).format('DD')
                  ].join(' ')
                } else {
                  label = moment(value).format('HH:mm - DD/MM/YYYY')
                }
                return (
                  <span className="flex items-center gap-1">
                            {label}
                    {renderIf(
                      time_close,
                      <span className="flex items-center font-bold">
                                    <PiArrowRightBold className="text-color-500 mx-1"/>
                        {moment(time_close).format('HH:mm - DD/MM/YYYY')}
                                </span>
                    )}
                    <span className="ml-2 text-green-600 italic">
                                ( {renderInterval(interval_type)} )
                            </span>
                        </span>
                )
              }

              return (
                <div
                  key={i}
                  className="flex flex-col gap-1 p-2 rounded-lg border border-color-50 hover:shadow-items relative">
                  <Link
                    to={getLinkToDetail(e)}
                    className="font-bold text-color-000 no-underline max-lines-1 mb-1">
                    {getTitle(e)}
                  </Link>
                  <span className="flex items-center gap-1">
                            <EventTypeIcon type={event_type}/>
                            <span className="font-medium text-xs text-color-100 leading-tight italic">
                                {t(getEventTypeTitle(event_type))}
                            </span>
                        </span>

                  <div className="flex items-center  italic">
                            <span className="text-xs text-color-500">
                                {t('time takes place')}{renderColon()}
                            </span>
                    <span className="font-bold text-xs ml-2">
                                {renderLabelTime(time_open)}
                            </span>
                  </div>

                  {renderIf(
                    location_name,
                    <div className="flex items-baseline gap-1">
                                <span className="text-xs text-color-500 italic">
                                    {t('locations')}{renderColon()}
                                </span>
                      <span className="font-bold text-xs text-color-100">
                                    {location_name}
                                </span>
                    </div>
                  )}

                  <div className="m-2 font-semibold text-xs italic text-right absolute bottom-0 right-0">
                    {is_expired === true ? (
                      <span className="text-red-600">{t('close')}</span>
                    ) : moment(_.get(e, 'time_open')).isAfter(moment()) ? (
                      <span className="text-green-700">{t('upcoming')}</span>
                    ) : (
                      <span className="text-blue-700">{t('opening')}</span>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </HeaderItemInfo>
      )}
      <HeaderItemInfo
        title="linked pages"
        className="w-full"
        hidden={_.every(
          [website, _.get(organization, 'video_url'), linkPage],
          (value) => _.isEmpty(value)
        )}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          {website && (
            <div className="flex items-center gap-2">
              <IoEarth
                size={22}
                className="text-primary"
              />
              <a
                href={website}
                target="_blank"
                className="truncate font-medium italic">
                {website}
              </a>
            </div>
          )}
          {_.get(organization, 'video_url') && (
            <div className="flex items-center gap-2">
              <BsYoutube
                size={20}
                className="text-primary"
              />
              <a
                href={_.get(organization, 'video_url')}
                target="_blank"
                className="truncate font-medium italic">
                {_.get(organization, 'video_url')}
              </a>
            </div>
          )}
          {linkPage.length > 0 &&
          linkPage.map((e, i) => (
            <div
              key={i}
              className="flex items-center gap-2">
              <IconPage
                page={e.page}
                size={20}
                className="text-primary"
              />
              <a
                href={e.url}
                target="_blank"
                className="truncate font-medium italic">
                {e.url}
              </a>
            </div>
          ))}
        </div>
      </HeaderItemInfo>

      <OrganizationProfile organization={organization}>
        {(child, {data}) => (
          <HeaderItemInfo
            title="provider profiles"
            hidden={_.every(
              [
                'annual',
                'tax_code',
                'company_type',
                'business_type',
                'established_year',
                'number_of_employee',
              ].map((name) => ({value: _.get(data, name)})),
              ({value}) => _.isEmpty(value)
            )}>
            {child}
          </HeaderItemInfo>
        )}
      </OrganizationProfile>

      <HeaderItemInfo
        title="description"
        hidden={_.isEmpty(description)}>
        <div className="p-2 text-sm text-color-400 border border-color-50 rounded-lg italic">
          {description}
        </div>
      </HeaderItemInfo>

      {!isRawContentEmpty(about) && (
        <CustomEditorRenderer
          rawValue={about}
          Wrapper={withProps({
            className: 'border-transparent py-2',
            title: 'about us',
          })(HeaderItemInfo)}
        />
      )}

      <OrganizationNews
        sample={2}
        Footer={withProps({
          pathname: getLinkToDetail(organization) + '/news',
        })(({pathname}) =>
          createPagerFooter({
            to: pathname,
            description:
              'You can click see more to see this full information',
          })
        )}
        Wrapper={withProps({
          title: 'news update',
        })(HeaderItemInfo)}
      />
    </Wrapper>
  )
}

export default OrganizationInfo
