import classNames from 'classnames'
import {callNativeShare, createFacebookShareLink, createTwitterShareLink,} from 'helpers'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React, {useContext} from 'react'
import {getLinkToShare} from 'views/AppLocals/gModel'
import {HeaderButton} from 'views/Organization/components/PageHeader'
import {AppEntities, fromAppEntity} from '../enums'
import EventContext from '../Event/EventContext'
import {RiFacebookFill, RiShareForwardFill, RiTwitterXLine} from "react-icons/ri";
import {OrganizationContext} from "../../Organization/OrganizationContext";
import UserContext from "../User/UserContext";

const createShareWidget =
  (type, Context) =>
    ({style, className, header, footer}) => {
      const context = useContext(Context)

      const {contextName} = fromAppEntity(type)

      const entity = _.get(context, contextName)

      const shareUrl = getLinkToShare(entity)

      if (_.isEmpty(shareUrl)) return null

      return (
        <div
          style={style}
          className={classNames('relative flex items-center', className)}>
          {header}
          <div className="flex gap-2">
            <HeaderButton
              component="a"
              href={createFacebookShareLink(shareUrl)}
              target="_blank"
              width={24}
              height={24}
              icon={
                <RiFacebookFill size={12}/>
              }
            />
            <HeaderButton
              component="a"
              href={createTwitterShareLink(shareUrl)}
              target="_blank"
              width={24}
              height={24}
              icon={
                <RiTwitterXLine size={10}/>
              }
            />
            <HeaderButton
              onClick={() => {
                callNativeShare({
                  title: getTitle(entity),
                  url: shareUrl,
                })
              }}
              width={24}
              height={24}
              icon={
                <RiShareForwardFill size={12}/>
              }
            />
            {footer}
          </div>
        </div>
      )
    }

const renderTitle = (title = 'share') => (
  <Translate>
    {(t) => (
      <div className="uppercase text-sm font-semibold text-color-300 tracking-wide flex-1">
        {t(title)}
      </div>
    )}
  </Translate>
)

export const EventShareWidget = createShareWidget(
  AppEntities.EVENT,
  EventContext
)

export const ChannelShareWidget = createShareWidget(
  AppEntities.CHANNEL,
  EventContext
)

export const OrganizationShareWidget = createShareWidget(
  AppEntities.ORGANIZATION,
  OrganizationContext
)


export const UserShareWidget = createShareWidget(
  AppEntities.USER,
  UserContext
)

export default createShareWidget
