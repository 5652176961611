import {ROOT_URL} from 'envs/_current/config'
import {organizationSchema} from '../schema'
import base, {
  get,
  getDescription,
  getFullAvatarUrl,
  getLinkToDetailGenerator,
  getLinkToSettingGenerator,
  getLinkToShareGenerator,
  getMetaGenerator,
  getTitle,
  getType
} from './base'

const getId = get('idname')
export const getLinkToDetail = getLinkToDetailGenerator(
  { getId, getType }
)
export const getLinkToSetting = getLinkToSettingGenerator(
  { getId, getType }
)
export const getLinkToShare = getLinkToShareGenerator(
  { rootUrl: ROOT_URL, getType, getId }
)
export default {
  ...base,
  getLinkToShare,
  getLinkToDetail,
  getLinkToSetting,
  schema: organizationSchema,
  getId,
  getMeta: getMetaGenerator({
    getTitle,
    getDescription,
    getImage: getFullAvatarUrl,
    getUrl: getLinkToShare
  })
}
