import { Avatar, Button, Popconfirm } from 'antd'
import { event_edit_Api } from 'apis/event'
import { getId, getType } from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import { createValue } from 'components/form/utils'
import { useLookupField } from 'components/LookupField'
import getAvatar from 'helpers/getAvatar'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useCallback, useContext, useMemo } from 'react'
import { BsPersonFill, BsTrash } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import createEditableForm from 'views/AppLocals/factory/createEditableForm'
import { DefaultSettingHeader } from 'views/AppLocals/factory/createSettingContent'
import { bindQueryParam } from 'views/AppLocals/functions/routerHelper'
import { Null, renderIf } from 'views/Shared'
import EventContext from '../EventContext'
import {FaUserTie} from "react-icons/fa6";

const formFields = [{ name: 'speakers', defaultValue: [] }]

const SpeakerItem = ({ item, actions }) => {
  const t = useTranslate()
  const { value, speaker } = item ?? {}
  const title = value ?? t('undefined')

    console.log(32111111, item)

  const description = useMemo(() => {

    let content
    if (speaker) {
      content = (
        <Link
          target="_blank"
          to={getLinkToDetail(speaker)}
          className="font-bold text-sm text-color-000 leading-snug no-underline">
          {title}
        </Link>
      )
    } else {
      content = (
        <div className="font-bold text-sm text-color-000 leading-snug">{title}</div>
      )
    }

    return (
      <div className="flex flex-col flex-1">
        {content}
        <span className="font-medium text-2xs text-primary-400 leading-tight italic">
          {t(speaker ? getType(speaker) : getType(item))}
        </span>
      </div>
    )
  }, [speaker])

  return (
    <div className="space-y-3 px-3 py-2 rounded-lg shadow-xs hover:shadow-out background">
      <div className="w-full flex items-center gap-2">
        <Avatar
          icon={<FaUserTie className="text-color-500" />}
          className="flex flex-center background-200"
          src={getAvatar(speaker) || getAvatar(item)}
        />
        {description}
        {actions}
      </div>
    </div>
  )
}

export const SpeakersPicker = ({
  name,
  label,
  value,
  onChange = Null,
  renderEmpty = () => <EmptyHolder />,
  renderButton = (child, params) => child,
  ...props
}) => {
  const t = useTranslate()

  const speakers = Array.from(value ?? [])

  const list = useMemo(() => {
    if (_.isEmpty(speakers)) {
      return renderEmpty()
    }
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {speakers
                .map((e, remapIndex) => ({ ...e, key: remapIndex }))
                .map((item, index) => (
                    <SpeakerItem
                        key={index}
                        item={item}
                        actions={
                            <Popconfirm
                                placement="left"
                                title={
                                    <div className="font-medium text-sm text-color-100 italic">
                                        {t('are you sure delete this speaker')}
                                    </div>
                                }
                                okText={t('sure')}
                                cancelText={t('ignore')}
                                okButtonProps={{
                                    type: 'primary',
                                    className:
                                        'button-rounded-md no-border text-xs font-medium px-3',
                                }}
                                cancelButtonProps={{
                                    className:
                                        'button-rounded-md no-border text-xs font-medium px-3',
                                }}
                                onConfirm={() => {
                                    const _speakers = speakers
                                        .map((e, remapIndex) => ({ ...e, key: remapIndex }))
                                        .filter((el) => el.key !== item.key)
                                    onChange(createValue(name, [..._speakers]))
                                }}>
                                <BsTrash
                                    size={15}
                                    className="text-red-500 hover:text-red-600 cursor-pointer"
                                />
                            </Popconfirm>
                        }
                    />
                ))}
        </div>
    )
  }, [value, speakers])

  const { toggle, isToggle, modal } = useLookupField({
    apiPath: '/lookup/user/get-by-types',
    handleChange: (e) => {
      onChange(
        createValue(name, [
          ...speakers,
          {
            ...e.target,
            speaker_name: e.target.value,
          },
        ])
      )
    },
    ...props,
  })

  return (
    <div className="flex flex-col gap-4">
      {renderButton(
        <DefaultSettingHeader
          title="speakers"
          extra={
            <Button
              type="primary"
              className="no-border button-rounded-lg"
              onClick={() => {
                toggle(true)
              }}>
              {t('add new')}
            </Button>
          }
        />,
        {
          toggle,
        }
      )}
      <div className="flex flex-col">
        {list}
      </div>
      {renderIf(isToggle, modal)}
    </div>
  )
}

const formSchema = [
  {
    children: [
      {
        name: 'speakers',
        component: ({ name, value, onChange }) => (
          <SpeakersPicker
            name={name}
            value={value}
            title="speakers"
            onChange={onChange}
            placeholder="select user"
          />
        ),
      },
    ],
  },
]

const EventSettingSpeakers = () => {
  const { event } = useContext(EventContext)

  const onPreSubmit = ({ speakers, ...values }) =>
    _.omitBy(
      {
        ...values,
        speakers: speakers
          ? JSON.stringify(
              Array.from(speakers ?? [])
                .filter((e) => e.value)
                .map((speaker) => ({
                  type: getType(speaker),
                  speaker_id: speaker?.id,
                  speaker_name: speaker?.value,
                }))
            )
          : undefined,
      },
      _.isUndefined
    )

  const initialized = useCallback(
    ({ speakers, ...values }) => ({
      ...values,
      speakers: Array.from(speakers ?? []).map((item) => ({
        speaker: item?.speaker,
        id: item?.speaker?.id,
        _type: getType(item?.speaker),
        avatar: getAvatar(item?.speaker),
        value: item?.speaker_name ?? getTitle(item?.speaker),
      })),
    }),
    [event.speakers]
  )

  if (_.isEmpty(event)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: event,
    query: bindQueryParam({
      id: getId(event),
    }),
    onPreSubmit,
    initialized,
    apiInfo: event_edit_Api,
    validationSchema: undefined,
  })
}

export default EventSettingSpeakers
