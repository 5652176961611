import {Table, Tooltip} from 'antd'
import {eventModel} from 'apis/model'
import {getType} from 'apis/model/base'
import {baseItemSchema} from 'apis/schema'
import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import {SelectEntityItem} from 'components/SelectEntityItem'
import {ROOT_URL} from 'envs/_current/config'
import {copyLink} from 'helpers'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import {getAntdLocale} from 'locales'
import _ from 'lodash'
import {callApi} from 'modules/asyncCache/callApi'
import {LazyPagination} from 'modules/asyncCache/components/LazyPagination'
import {LocalContext} from 'modules/local/LocalContext'
import Translate from 'modules/local/Translate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useCallback, useContext, useEffect, useState} from 'react'
import {withProps} from 'recompose'
import {Null, renderIf} from 'views/Shared'
import AntdConfigWrapper from './custom/AntdConfigWrapper'
import {AppEntities} from './enums'
import {ContextActionTypes} from './factory/createContextMenu'
import {renderLoading} from './functions/renderers/renderLoading'
import gModel from './gModel'

export const EntityContext = React.createContext({})

export const EntityProvider = ({item, children}) => {
  const history = useHistory()

  const handleAction = useCallback(
    (action, payload) => {
      const params = payload || {}
      switch (action) {
        case ContextActionTypes.COPY:
          copyLink(`${ROOT_URL}${getLinkToDetail(item)}`)
          break
        case ContextActionTypes.SETTINGS:
          if (getType(item) === AppEntities.EVENT) {
            history.push({
              pathname: eventModel.getLinkToSetting(item),
            })
          } else {
            history.push({
              pathname: gModel.getLinkToSetting(item),
            })
          }
          break
        default:
          break
      }
    },
    [item, history]
  )
  return (
    <EntityContext.Provider value={{item, handleAction}}>
      {children}
    </EntityContext.Provider>
  )
}

const RenderDefaultItem = ({item}) => (
  <div className="flex w-full">{getTitle(item)}</div>
)

const RenderDefaultEntity = ({children, ...props}) => (
  <SelectEntityItem
    schema={baseItemSchema}
    {...props}>
    {children}
  </SelectEntityItem>
)

const renderDefaultMeta = (
  {count, links, total, per_page, current_page, total_pages},
  params = {}
) => {
  const loaded_pages = Math.max(0, current_page - 1)
  const loaded_count = loaded_pages * per_page

  const {style, className} = params ?? {}
  return (
    <Translate>
      {(t) => (
        <div
          className={classNames('PaginationMeta flex stickyTop1', className)}
          style={{top: '55px', ...(style ?? {})}}>
          <div className="flex gap-2 text-sm rounded-full background-200 opacity-90 px-3 py-1 truncate italic">
            <span>{t('showing')}</span>
            <span className="font-semibold text-color-000">
              {Number(loaded_count + count).toString()}
            </span>
            <span>{t('of')}</span>
            <span className="font-semibold text-color-000">{total}</span>
            <span className="lowercase">{t('record(s)')}</span>
          </div>
        </div>
      )}
    </Translate>
  )
}

export const EntityPaginationMeta = ({
                                       data,
                                       renderMeta = renderDefaultMeta,
                                       ...params
                                     }) => {
  const pagination = _.get(data, 'response.data.meta.pagination') || {}
  return renderMeta(pagination, params)
}

export const createEntity = ({id, type} = {}) =>
  renderIf(id && type, {
    id,
    idname: id,
    _type: type,
  })

export const NoneStickyMeta = withProps({
  style: {position: 'inherit'},
})(EntityPaginationMeta)

const EntityList = ({
                      query,
                      values,
                      apiInfo,
                      Header,
                      refreshKey,
                      renderItem,
                      renderWrap,
                      PaginationMeta,
                      alwaysVisible = true,
                      RenderItem = RenderDefaultItem,
                      RenderEntity = RenderDefaultEntity,
                      renderItemWrapper,
                      ...props
                    }) => (
  <LazyPagination
    query={query}
    values={values}
    apiInfo={apiInfo}
    Header={Header}
    refreshKey={refreshKey}
    alwaysVisible={alwaysVisible}
    renderLoading={renderLoading}
    PaginationMeta={PaginationMeta}
    renderItem={
      _.isFunction(renderItem)
        ? renderItem
        : (item, index) => (
          <RenderEntity
            key={index}
            item={item}
            defaultItem={item}>
            {withProps((item) => ({
              item,
            }))(RenderItem)}
          </RenderEntity>
        )
    }
    renderEmpty={() => (
      <div className="w-full justify-center items-center">
        <EmptyHolder/>
      </div>
    )}
    {...props}
    renderWrap={renderWrap}
    Wrapper={renderItemWrapper}
  />
)

export const TruncatedCell = ({
                                style,
                                width,
                                tooltip,
                                maxWidth,
                                children,
                                className = 'max-lines-3',
                              }) => (
  <Tooltip title={tooltip}>
    <span
      style={{
        width,
        maxWidth,
        ...style,
      }}
      className={classNames('truncated', className)}>
      {children}
    </span>
  </Tooltip>
)

export const ATable = ({
                         columns,
                         apiInfo,
                         className,
                         newRows,
                         dependencies = [],
                         createQuery = Null,
                         createValues = Null,
                         withData = (source) => ({}),
                         withPagination = (params) => ({}),
                         ...props
                       }) => {
  const locale = useContext(LocalContext)

  const {isSm} = useContext(LayoutContext)

  const [data, setData] = useState([])

  const [loading, setLoading] = useState(false)

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  })

  const query = createQuery(tableParams) || undefined

  const values = createValues(tableParams) || undefined

  const fetchData = () => {
    setLoading(true)
    callApi(
      _.omitBy(
        {
          query,
          values,
          apiInfo,
        },
        _.isUndefined
      )
    ).then((response) => {
      const data = response?.data
      const {current_page, per_page, total, ...rest} =
      data?.meta?.pagination ?? {}
      setData(data?.data)
      setLoading(false)
      setTableParams({
        ...tableParams,
        pagination: {
          ...(tableParams.pagination ?? {}),
          total,
          simple: !isSm,
          hideOnSinglePage: true,
          showSizeChanger: false,
          showQuickJumper: !isSm,
          pageSize: per_page,
          current: current_page > rest.total_pages ? 1 : current_page,
          ...(withPagination({
            isSm,
            total,
            per_page,
            current_page,
            ...rest,
          }) || {}),
        },
      })
    })
  }

  useEffect(() => {
    fetchData()
  }, [JSON.stringify({query, values, dependencies})])

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    })
  }

  return (
    <AntdConfigWrapper locale={getAntdLocale(locale?.lang)}>
      <Table
        key={loading}
        scroll={{
          x: true,
        }}
        bordered={true}
        columns={columns}
        dataSource={[...(newRows || []), ...(data || [])]}
        loading={loading}
        onChange={handleTableChange}
        pagination={tableParams.pagination}
        className={classNames('BorderedTable', className)}
        {...(withData(data) || {})}
        {...props}
      />
    </AntdConfigWrapper>
  )
}

export default EntityList
