import { organizationModel } from "apis/model"
import { organizationSchema } from "apis/schema"
import { LoginContext } from "components/LoginContext"
import _ from "lodash"
import useTranslate from "modules/local/useTranslate"
import React, { useContext } from "react"
import { useSelectEntities } from "redux/entities/useSelectEntities"
import OrderRequestContext from "views/OrderRequest/OrderRequestContext"

export const UserInfo = () => {

  const t = useTranslate()

  const login = useContext(LoginContext)

  if (!!!login) {
    return null
  }
  return (
    <div className="flex flex-col gap-1">
      <span className="font-medium text-sm text-color-400 uppercase tracking-wide">
          {t('your information')}
      </span>
      <div className="ml-2 flex flex-col">
          <span className="font-bold text-sm text-primary-700">
              {login.name}
          </span>
          <span className="text-xs text-color-300 italic">
              {login.email}
          </span>
          <span className="text-xs text-color-300 italic">
              {login.phone}
          </span>
      </div>
    </div>
  )
}

const SpotFormHeader = ({
  id,
  children,
  type = 'organization',
}) => {
  return (
    <div className="flex flex-col gap-3 mb-3">
      <div className="flex flex-col gap-3">
          {children}
          <SpotOrganizaionInfo id={id} />
      </div>
      <UserInfo />
    </div>
  )
}

export const SpotOrganizaionInfo = ({
  id,
}) => {

  const t = useTranslate()

  const {
    organization: fromContext
  } = useContext(OrderRequestContext)

  const entity = useSelectEntities(
    id,
    organizationSchema,
    {}
  )

  const organization = _.isEmpty(fromContext)
    ? entity
    : fromContext

  return (
    <div className="flex flex-col gap-1">
        <span className="font-medium text-sm text-color-400 uppercase tracking-wide">
            {t('vendors')}
        </span>
        <div className="ml-2 flex flex-col">
            <span className="font-bold text-sm text-primary-700">
                {organizationModel.getTitle(organization)}
            </span>
            <span className="text-xs text-color-300 italic">
                {_.get(organization, 'locations.0.address')}{' '}
                {
                    !_.isEmpty(
                        _.get(organization, 'locations.0.ward', null)
                    ) &&
                    _.get(organization, 'locations.0.ward.type') + ' ' +
                    _.get(organization, 'locations.0.ward.name')}
                {!_.isEmpty(
                    _.get(organization, 'locations.0.district', null)
                ) && ', ' +
                _.get(organization, 'locations.0.district.type') + ' ' +
                _.get(organization, 'locations.0.district.name')}
                {!_.isEmpty(
                    _.get(organization, 'locations.0.province', null)
                ) && ', ' +
                _.get(organization, 'locations.0.province.type') + ' ' +
                _.get(organization, 'locations.0.province.name')
                }
            </span>
            <span className="text-xs text-color-300 italic">
                {organizationModel.get(organization, 'phone')}
            </span>
        </div>
    </div>
  )
}

export default SpotFormHeader