import React, {
  lazy,
  Suspense
} from 'react'

export const createLazyComponent = (
  promise,
  name
) => {
  const LazyComponent = lazy(promise)
  const Component = props => (
    <Suspense fallback={null}>
      <LazyComponent {...props} />
    </Suspense>
  )
  Component.displayName = `nest(Suspense,lazy(${name ||
    'Component'}))`
  return Component
}
