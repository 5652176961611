import _ from 'lodash'
import {useMemo, useState} from 'react'
import {FilterMenuTypes} from './components/FilterMenu'

const getFilterOptions = (value) => {
  const options = _.get(value, 'options', [])
  return options.map(({ id, label, ...rest }) => ({
    ...rest,
    id,
    label,
    groupId: value.id,
    name: `${value.title}.${id}`,
    type: FilterMenuTypes.CHECKBOX,
  }))
}

const filterNodeReducing = (result, value) => {
  const options = getFilterOptions(value)
  return [...result, ...options]
}

const filterGroupReducing = (result, value) => {
  const options = getFilterOptions(value)
  return [
    ...result,
    {
      group_label: value.title,
      type: FilterMenuTypes.GROUP_TITLE,
      options,
    },
  ]
}

const useFilterHandler = ({item}) => {
  const filterRows = useMemo(() => {
    if (_.isEmpty(item)) {
      return []
    }
    return Object.values(item).reduce(filterGroupReducing, [])
  }, [item])

  const filterRow = useMemo(
    () => Object.values(item).reduce(filterNodeReducing, []),
    [item]
  )

  const [filter, setFilter] = useState({})

  const removeFilter = (value) => {
    if (value) {
      setFilter(
        _.reduce(
          filter,
          (prev, current) => {
            if (current.id === value) {
              return {
                ...prev,
                [current.id]: {
                  ...current,
                  value: false,
                },
              }
            } else {
              return {
                ...prev,
                [current.id]: current,
              }
            }
          },
          {}
        )
      )
    }
  }

  return {
    filter,
    setFilter,
    filterRow,
    filterRows,
    removeFilter,
  }
}

export { useFilterHandler, filterNodeReducing, filterGroupReducing }
