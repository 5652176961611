import Icon, {CheckOutlined} from '@ant-design/icons'
import Null from 'components/NullComponent'
import React, {useEffect, useMemo, useRef} from 'react'
import {MdClose} from 'react-icons/md'
import './PermissionRatio.css'

export const PermissionRatio = ({
  disabled,
  value = '2',
  defaultValue = '2',
  onChange = Null
}) => {
  const onChangeRef = useRef(onChange)
  onChangeRef.current = onChange
  const selectRef = useRef(
    value || defaultValue
  )
  const wrapRef = useRef()
  useEffect(() => {
    if (disabled) {
      wrapRef.current.setAttribute(
        'disabled',
        disabled
      )
    } else {
      wrapRef.current.removeAttribute(
        'disabled'
      )
    }
  }, [disabled])
  useEffect(() => {
    wrapRef.current.getAttribute(
      'data-current'
    ) !== value &&
      wrapRef.current.setAttribute(
        'data-current',
        value
      )
  }, [value])
  const e = useMemo(
    () => (
      <div
        ref={wrapRef}
        data-current={selectRef.current}
        className="PermissionRatio flex overflow-hidden">
        <div
          onClick={() => {
            onChangeRef.current('1')
            wrapRef.current.setAttribute(
              'data-current',
              '1'
            )
          }}
          className="flex-1 text-red-500 flex  items-center justify-center">
          <Icon
            className="PermissionRatioIcon"
            component={MdClose}
          />
        </div>
        <div
          onClick={() => {
            onChangeRef.current('2')
            wrapRef.current.setAttribute(
              'data-current',
              '2'
            )
          }}
          className="flex-1 text-color-300 flex items-center justify-center">
          <div className="PermissionRatioIcon">
            -
          </div>
        </div>
        <div
          onClick={() => {
            onChangeRef.current('3')
            wrapRef.current.setAttribute(
              'data-current',
              '3'
            )
          }}
          className="flex-1 text-blue-500 flex items-center justify-center">
          <CheckOutlined className="PermissionRatioIcon" />
        </div>
      </div>
    ),
    []
  )
  return e
}
