import React from "react";

export const DucksAndOtherPoultry = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#ff6840"
				d="M190.48 496.43l2.27 3.88H95.23l6.25-5.86a87.094 87.094 0 0127.97-14.24l9.41-2.81a21.383 21.383 0 0013.57-12.12l20.85-48.7s12.72.39 16.05 1.75c9.64 3.94 13.46 8.73 13.46 8.73l-28.82 42.99a9.584 9.584 0 002.17 13.18l3.85 2.47a33.49 33.49 0 0110.49 10.73zM327.16 500.31h-83.51l9.01-8.04a87.527 87.527 0 0116.56-11.63l2.82-1.52a23.12 23.12 0 0012.09-21.55l-1.01-21.59c3.63-2.73 10.47-4.86 14.26-5.1 5.16-.34 6.62.74 12.45 2.79l-3.01 28.44a23.147 23.147 0 008.68 20.7l1.76 1.38a26.514 26.514 0 018.92 12.97z"
			></path>
			<path
				fill="#dd4b2f"
				d="M157.47 490.68a45.638 45.638 0 00-6.39 5.4c-1.72 1.78-2.9 3.36-3.01 4.23h-9c0-7.26 10.16-14.87 13.27-17.02a4.5 4.5 0 115.13 7.39zM294.03 490.58a49.968 49.968 0 00-6.82 5.45c-1.84 1.78-3.12 3.38-3.29 4.28h-9c0-7.15 9.9-14.44 14.16-17.25a4.5 4.5 0 014.95 7.52z"
			></path>
			<path
				fill="#f2eee9"
				d="M458.05 298.36c5.12-5.87 8.19-12.2 8.95-17.69 2.8-20.31-13-20.17-20.67-16.67-6.482 2.96-18.931 8.214-28.373 10.215a12.519 12.519 0 01-4.554.108h-.023c-13.13-2-28.81-5.68-45.32-10.24-47.05-12.98-100.86-33.14-121.83-42.11-18.42-7.87-41.11-32.56-41.39-52.59-.03-1.95.19-6.76.23-7.17 1.43-19.76 9.3-42.56 15.3-66.66 7.9-31.7.77-84.83-51.18-87.51-39.26-2.02-60.57 22.91-62.67 48.05L40.98 89.21c-5.51 2.85-11.81 13.01-.3 21.58 19.33 14.41 56.8 9.5 82.53 7.42 7.73 16.1 22.94 65.8 1.92 92.05-42.11 52.6-67.74 149.22 41.83 203.32 1.32.66 2.67 1.31 4.03 1.95a210.985 210.985 0 0049.77 15.68 291.561 291.561 0 0073.6 4.24c40.56-2.63 81.51-21.78 109.8-46.13 17.9-15.4 33.04-34.75 45.62-47.32 33.22-27 21.47-46 8.27-43.64z"
			></path>
			<path
				fill="#ff6840"
				d="M133.38 113.28a26.134 26.134 0 01-4.83 4.53c-25.6 1.68-67.15 8.42-87.87-7.02-11.51-8.57-5.21-18.73.3-21.58l65.54-33.12c.105 12.785 8.092 36.558 24.22 44.76a8.221 8.221 0 012.64 12.43z"
			></path>
			<rect
				width="12.971"
				height="16.757"
				x="152.244"
				y="47.456"
				fill="#513232"
				rx="6.485"
				transform="rotate(-5.13 159.256 56.056)"
			></rect>
			<path
				fill="#dd4b2f"
				d="M89.993 88.46a4.5 4.5 0 01-.527-8.969 52.824 52.824 0 019.81-.01 4.5 4.5 0 11-.879 8.957 44.266 44.266 0 00-7.866-.009 4.672 4.672 0 01-.538.031z"
			></path>
			<g fill="#bfb8af">
				<path d="M443 314.831a4.5 4.5 0 01-2.156-8.453 50.488 50.488 0 0013.813-10.972 4.5 4.5 0 016.785 5.912 59.552 59.552 0 01-16.294 12.964 4.484 4.484 0 01-2.148.549zM348.851 326.874c-3.665 0-7.69-.044-12.119-.133a4.5 4.5 0 11.181-9c26.238.529 40.683-.608 42.933-3.372a3.424 3.424 0 00-.1-2.04c-1.056-4.625-11.973-13.13-33.446-20.872-22.911-8.261-50.591-13.272-77.94-14.112a4.5 4.5 0 01.276-9C325.736 270.1 384 290.5 388.525 310.327c.337 1.476 1.362 5.965-1.7 9.726-3.598 4.422-10.91 6.821-37.974 6.821z"></path>
				<path d="M305.018 372.239q-6.54 0-13.764-.475a4.5 4.5 0 01.6-8.981c52.5 3.517 71.4-13.6 74.3-24.267 1.455-5.362-.489-10.114-5.072-12.4a4.5 4.5 0 014.022-8.05c8.389 4.19 12.3 13.358 9.735 22.811-4.088 15.064-24.882 31.361-69.821 31.362z"></path>
				<path d="M287.012 400.428a80.62 80.62 0 01-9.817-.561 4.5 4.5 0 111.11-8.931c17.735 2.207 58.353-2.359 63.53-30.264a4.5 4.5 0 118.849 1.642c-5.53 29.807-40.572 38.114-63.672 38.114zM231.881 386.139c-.126 0-.254 0-.382-.016-.382-.032-38.283-3.639-46.23-40.252a4.5 4.5 0 018.795-1.908c6.552 30.185 37.874 33.165 38.19 33.191a4.5 4.5 0 01-.373 8.985z"></path>
			</g>
		</svg>
	);
}

export default DucksAndOtherPoultry;
