import Icon, {DownloadOutlined} from '@ant-design/icons'
import React, {useState} from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import {RegAsModal} from '../Modal/Modal'

export default function ImagesLightBox({
  defaultIndex,
                                         images = [],
  onCloseRequest
}) {
  const [
    currentIndex = 0,
    setCurrentIndex
  ] = useState(defaultIndex)
  return (
    <RegAsModal>
      <Lightbox
        toolbarButtons={[
          <a
            href={images[currentIndex]}
            download={'output.png'}
            style={{
              width: 40,
              height: 35,
              opacity: 0.7,
              display: 'inline-flex',
              verticalAlign: 'middle',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Icon
              style={{
                fontSize: '24px'
              }}
              component={
                DownloadOutlined
              }
            />
          </a>
        ]}
        mainSrc={images[currentIndex]}
        nextSrc={
          images.length > 1
            ?
            images[
            (currentIndex + 1) %
            images.length
            ]
            : null
        }
        prevSrc={
          images.length > 1
            ?
            images[
            (currentIndex +
              images.length -
              1) %
            images.length
            ]
            : null
        }
        onCloseRequest={onCloseRequest}
        onMovePrevRequest={() =>
          setCurrentIndex(
            (currentIndex +
              images.length -
              1) %
            images.length
          )
        }
        onMoveNextRequest={() =>
          setCurrentIndex(
            (currentIndex + 1) %
            images.length
          )
        }
      />
    </RegAsModal>
  )
}
