import {getId, getType} from 'apis/model/base'
import PermissionContext from 'modules/permissions/PermissionContext'
import React from 'react'
import {compose, fromRenderProps, nest, withProps} from 'recompose'
import {DelayRender} from 'views/Discovery/DelayRender'
import {invalidPermissions,} from 'views/AppLocals/factory/createCheckPermissions'
import {SettingsMenu} from 'views/AppLocals/factory/createSettingLayout'
import DefaultTemplate from 'views/AppLocals/Templates/DefaultTemplate'
import {renderFalse, renderOwnChild} from 'views/Shared'
import CustomSiderMenu from 'views/Shared/components/CustomSiderMenu'
import {Queries} from 'views/Shared/enums/Queries'
import {useMenuHandler} from 'views/Shared/handlers/useMenuHandler'
import WorkspaceContext from '../WorkspaceContext'
import {BsBox2, BsEnvelopePaper, BsQuestion} from "react-icons/bs";
import WorkspaceProductSalesOrderRequestManagement from "../Container/WorkspaceProductSalesOrderRequestManagement";
import WorkspaceProductSales from "../Container/WorkspaceProductSales";
import WorkspaceProductSalesOutgoingQuoteManagement from "../Container/WorkspaceProductSalesOutgoingQuoteManagement";
import WorkspaceProductSalesPartners from "../Container/WorkspaceProductSalesPartners";
import {ImFileText2} from "react-icons/im";
import {RxBackpack} from "react-icons/rx";

const ItemKeys = Object.freeze({
  PRODUCT_SALES: 'product sales',
  ORDER_REQUEST_MANAGEMENT: 'order-requests-management',
  OUTGOING_QUOTES_MANAGEMENT: 'outgoing-quotes-management',
  PARTNERS: 'partners',
})

const routeEntities = [
  {
    key: ItemKeys.PRODUCT_SALES,
    component: WorkspaceProductSales,
  },
  {
    key: ItemKeys.ORDER_REQUEST_MANAGEMENT,
    component: WorkspaceProductSalesOrderRequestManagement,
  },
  {
    key: ItemKeys.OUTGOING_QUOTES_MANAGEMENT,
    component: WorkspaceProductSalesOutgoingQuoteManagement,
  },
  {
    key: ItemKeys.PARTNERS,
    component: WorkspaceProductSalesPartners,
  },
].map(({key, ...rest}) => ({
  key,
  path: key,
  ...rest,
}))

const with_menu_items = ({isDisabled = renderFalse}) => [
  {
    key: ItemKeys.PRODUCT_SALES,
    label: 'products',
    icon: <BsBox2 size={16}/>,
    disabled: false,
  }, {
    key: ItemKeys.ORDER_REQUEST_MANAGEMENT,
    label: 'order request management',
    icon: <ImFileText2 size={16}/>,
    disabled: false,
  }, {
    key: ItemKeys.OUTGOING_QUOTES_MANAGEMENT,
    label: 'outgoing quotes management',
    icon: <BsEnvelopePaper size={16}/>,
    disabled: false,
  }, {
    key: ItemKeys.PARTNERS,
    label: 'partners',
    icon: <RxBackpack size={16}/>,
    disabled: false,
  },
]

const WorkspaceSales = ({owner_id, owner_type}) => {
  const {activeKey, Component, onMenuSelect} = useMenuHandler({
    routes: routeEntities,
    actionName: Queries.REL,
  })

  return (
    <DefaultTemplate
      renderMenu={() => (
        <CustomSiderMenu
          deps={[activeKey]}
          Wrapper={renderOwnChild}>
          <PermissionContext.Consumer>
            {(permissions) => (
              <SettingsMenu
                items={with_menu_items({
                  isDisabled: invalidPermissions(permissions),
                })}
                onSelect={onMenuSelect}
                defaultSelectedKeys={[activeKey]}
              />
            )}
          </PermissionContext.Consumer>
        </CustomSiderMenu>
      )}>
      <div className="space-y-3 px-3 md:px-0 md:pr-3">
        <Component/>
      </div>
    </DefaultTemplate>
  )
}

export default nest(
  withProps({lazy: true})(DelayRender),
  compose(
    fromRenderProps(WorkspaceContext.Consumer, ({organization}) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    }))
  )(WorkspaceSales)
)
