import React from "react";

function EmptyFolderStateSvg({...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1rem"
      height="1rem"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill="#756f6f"
        d="M512 190.587V480.13H122.664c-6.521 0-11.796-5.285-11.796-11.796V150.699a11.788 11.788 0 0111.796-11.796h143.051c3.121 0 6.119 1.236 8.334 3.451l32.986 32.986a11.788 11.788 0 008.344 3.451h184.825c6.511.001 11.796 5.286 11.796 11.796z"
      ></path>
      <g fill="#5b5555">
        <circle cx="324.065" cy="207.547" r="28.755"></circle>
        <path d="M218.512 275.423c5.192 5.182 1.525 14.062-5.82 14.062h-90.8c-3.719 0-7.397-.186-11.023-.536v-138.25a11.788 11.788 0 0111.796-11.796h102.513c4.78 12.372 7.407 25.827 7.407 39.889 0 30.566-12.393 58.236-32.42 78.273zM512 190.587V480.13h-39.168V190.587c0-6.511-5.275-11.796-11.796-11.796h39.167c6.512.001 11.797 5.286 11.797 11.796z"></path>
      </g>
      <path
        fill="#64b9fc"
        d="M391.699 332.217L512 480.133H116.473c-3.55 0-6.91-1.599-9.15-4.353L2.666 347.1c-6.268-7.707-.784-19.236 9.15-19.236h370.732c3.551 0 6.912 1.599 9.151 4.353z"
      ></path>
      <path
        fill="#31a7fb"
        d="M512 480.13h-50.726L340.98 332.217a11.822 11.822 0 00-9.148-4.358h50.716c3.554 0 6.912 1.607 9.148 4.358z"
      ></path>
      <path
        fill="#f8f3f1"
        d="M422.426 445.438H318.359a10.304 10.304 0 01-8.198-4.063l-26.67-35.043c-2.581-3.391-.162-8.27 4.099-8.27h104.067c3.217 0 6.249 1.503 8.198 4.063l26.67 35.043c2.581 3.39.163 8.27-4.099 8.27z"
      ></path>
      <path
        fill="#efe2dd"
        d="M422.426 445.435h-33.481c4.265 0 6.686-4.873 4.1-8.272l-26.671-35.036a10.28 10.28 0 00-8.19-4.069h33.471c3.224 0 6.253 1.504 8.2 4.069l26.671 35.036c2.575 3.4.165 8.272-4.1 8.272z"
      ></path>
      <path
        fill="#f8f3f1"
        d="M256 453.162h-47.272a7.726 7.726 0 110-15.452H256a7.726 7.726 0 110 15.452zM238.749 427.535h-20.065a7.726 7.726 0 110-15.452h20.065a7.726 7.726 0 017.726 7.726 7.725 7.725 0 01-7.726 7.726z"
      ></path>
      <path
        fill="#31a7fb"
        d="M232.583 142.561c0-61.134-49.559-110.693-110.692-110.693-61.077 0-110.694 49.617-110.694 110.694.001 61.134 49.559 110.692 110.693 110.692h90.8c7.341 0 11.018-8.876 5.827-14.067l-18.355-18.355c20.032-20.03 32.421-47.704 32.421-78.271z"
      ></path>
      <path
        fill="#1c96f9"
        d="M218.512 239.191c5.192 5.192 1.525 14.062-5.82 14.062h-37.334c7.335 0 11.013-8.87 5.82-14.062l-11.382-11.388c-3.786-3.788-4.026-9.834-.574-13.929 16.238-19.26 26.028-44.148 26.028-71.314 0-54.764-39.775-100.247-92.016-109.117a109.989 109.989 0 0115.486-1.532c61.905-1.744 112.818 46.842 113.848 108.763.521 31.311-11.967 59.72-32.404 80.158z"
      ></path>
      <path
        fill="#f8f3f1"
        d="M143.743 142.562l20.591-20.591c6.035-6.035 6.035-15.819 0-21.853-6.034-6.035-15.819-6.035-21.853 0l-20.591 20.591-20.59-20.592c-6.034-6.035-15.819-6.035-21.853 0-6.035 6.034-6.035 15.818 0 21.853l20.591 20.591-20.591 20.591c-6.035 6.035-6.035 15.819 0 21.853 3.017 3.018 6.972 4.526 10.927 4.526s7.909-1.509 10.927-4.526l20.591-20.591 20.591 20.591a15.401 15.401 0 0010.927 4.526c3.954 0 7.909-1.509 10.927-4.526 6.035-6.034 6.035-15.818 0-21.853z"
      ></path>
      <circle cx="324.065" cy="183.206" r="28.755" fill="#bcea73"></circle>
      <path
        fill="#99d53b"
        d="M352.818 183.203c0 15.887-12.878 28.765-28.752 28.765a28.485 28.485 0 01-14.586-3.982c11.288-2.602 19.698-12.707 19.698-24.783 0-12.063-8.397-22.181-19.685-24.783a28.554 28.554 0 0114.573-3.968c15.875-.001 28.752 12.877 28.752 28.751z"
      ></path>
      <circle cx="424.77" cy="126.036" r="22.692" fill="#bcea73"></circle>
      <path
        fill="#99d53b"
        d="M447.46 126.034c0 12.537-10.162 22.699-22.689 22.699-4.2 0-8.14-1.141-11.511-3.142 8.908-2.053 15.544-10.028 15.544-19.557 0-9.52-6.626-17.504-15.534-19.558a22.531 22.531 0 0111.5-3.132c12.528 0 22.69 10.163 22.69 22.69z"
      ></path>
      <path
        fill="#64b9fc"
        d="M372.92 84.56l4.18-4.18a7.727 7.727 0 00-10.927-10.927l-4.18 4.18-4.18-4.18a7.725 7.725 0 00-10.927 0 7.725 7.725 0 000 10.927l4.18 4.18-4.18 4.18a7.725 7.725 0 000 10.927c1.509 1.509 3.486 2.263 5.463 2.263s3.955-.755 5.463-2.263l4.18-4.18 4.18 4.18c1.509 1.509 3.486 2.263 5.463 2.263s3.955-.755 5.463-2.263a7.725 7.725 0 000-10.927z"
      ></path>
      <path
        fill="#a8e7ef"
        d="M57.85 259.965a7.727 7.727 0 00-10.927 0l-7.278 7.278-7.278-7.278a7.727 7.727 0 00-10.927 10.927l7.278 7.278-7.278 7.278a7.725 7.725 0 000 10.927c1.509 1.509 3.486 2.263 5.463 2.263s3.955-.755 5.463-2.263l7.278-7.278 7.278 7.278c1.509 1.509 3.486 2.263 5.463 2.263s3.955-.755 5.463-2.263a7.725 7.725 0 000-10.927l-7.278-7.278 7.278-7.278a7.725 7.725 0 00.002-10.927z"
      ></path>
    </svg>
  );
}

export default EmptyFolderStateSvg;
