import _ from 'lodash'
import * as moment from 'moment'
import React from 'react'
import {IoLinkSharp, IoLocationOutline, IoTimeOutline} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {eventModel} from '../../../apis/model'
import AppIcon, {CalendarIcon} from '../../../components/AppIcon'
import Timestamp from '../../../components/Feed/Timestamp'
import Translate from '../../../modules/local/Translate'
import {Datetime} from '../../Messages/Datetime'
import {CalendarAvatar} from "../../AppLocals/Event/MyEvents";
import getTitle from "../../../helpers/getTitle";
import getLinkToDetail from "../../../helpers/getLinkToDetail";

export const renderEventItem = (e) => {
  if (!e) return null

  const startDate = moment(_.get(e, 'time_open'))
  const endDate = !!_.get(e, 'time_close')
    ? moment(_.get(e, 'time_close'))
    : null
  const eventType = _.get(e, 'event_type', 'meeting')
  const owner = _.get(e, 'owner')

  if (eventType === 'reminder') {
    return (
      <Translate>
        {(t) => (
          <div
            key={e.id}
            className="flex gap-3 border border-color-50 hover:shadow-items-md rounded-md">
            <CalendarAvatar time={e.time_open} />
            <div className="flex flex-col justify-between flex-1 py-2">
              <Link
                  to={eventModel.getLinkToDetail(e)}
                  className="font-bold text-color-000 no-underline leading-tight max-lines-2">
                {e.title}
              </Link>
              <div className="flex flex-col gap-1">
                <div className="space-x-1 text-xs italic">
                  <span className="text-color-400">
                    {t('organized by')}
                  </span>
                  <Link to={getLinkToDetail(owner)} className="font-bold text-primary">
                    {getTitle(owner)}
                  </Link>
                </div>
                <div className="flex gap-6">
                  <div className="flex gap-1 text-xs italic">
                    <span className="text-color-500">
                      {`${t('start')} : `}
                    </span>
                    <span className="flex-1 font-bold text-color-100">
                      {startDate?.format('HH:mm - DD/MM/YYYY')}
                    </span>
                  </div>
                  <div className="flex gap-1 text-xs italic">
                    <span className="text-color-500">
                      {`${t('type of event')} : `}
                    </span>
                    <span className="flex-1 font-bold text-color-100">
                      {t(eventType)}
                    </span>
                  </div>
                </div>
                {!!e.external_link && (
                    <div className="flex items-center gap-1 text-xs">
                      <div className="flex items-center justify-center">
                        <IoLinkSharp/>
                      </div>
                      <div className="text-xs text-color-400">
                        {e.external_link}
                      </div>
                    </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Translate>
    )
  }

  return (
    <Translate>
      {(t) => (
        <div
          key={e.id}
          className="flex gap-3 border border-color-50 hover:shadow-items-md rounded-md">
          <CalendarAvatar time={e.time_open} />
          <div className="flex flex-col justify-between flex-1 py-2">
            <Link
              to={eventModel.getLinkToDetail(e)}
              className="font-bold text-color-000 no-underline leading-tight max-lines-2">
              {!!startDate && !!endDate &&
              (moment().isBetween(
                startDate,
                !!endDate ? endDate : moment().add(1, 'days'),
                'minutes',
                '[]'
              ) ? (
                <span className="uppercase font-bold text-sm text-red-500">
                    <span className="blobs-container">
                      <span className="blob red"/>
                    </span>
                    LIVE
                  </span>
              ) : (
                moment().isBetween(
                  startDate.add(-1, 'days'),
                  startDate,
                  'minutes',
                  '[]'
                ) && (
                  <span className="uppercase font-bold text-sm text-yellow-500">
                      <span className="blobs-container">
                        <span className="blob yellow"/>
                      </span>
                      INCOMING
                    </span>
                )
              ))}
              <span>{e.title}</span>
            </Link>
            <div className="space-y-1">
              {!!e.location_name && (
                <div className="flex flex-row items-center space-x-1 text-xs">
                  <div className="flex items-center justify-center">
                    <IoLocationOutline/>
                  </div>
                  <div className="text-xs text-color-400">
                    {e.location_name}
                  </div>
                </div>
              )}
              <div className="flex flex-row items-center space-x-1 text-xs">
                <div className="inline-flex justify-center items-center">
                  <IoTimeOutline/>
                </div>
                {[
                  <Timestamp
                    className="font-normal text-color-400 italic"
                    timestamp={_.get(e, 'time_open')}
                  />,
                  !!_.get(e, 'time_close') && ' - ',
                  !!_.get(e, 'time_close') && (
                    <Datetime className="font-normal text-color-300">
                      {_.get(e, 'time_close')}
                    </Datetime>
                  ),
                ].map((e, i) => (
                  <span key={i}>{e}</span>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </Translate>
  )
}
