import React from "react";

export const AminoAcids = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#9facba"
				d="M147.321 308.118a7.723 7.723 0 01-7.723-7.723v-96.978a7.723 7.723 0 1115.446 0v96.978a7.723 7.723 0 01-7.723 7.723zM385.165 299.629a7.723 7.723 0 01-7.723-7.723v-79.321a7.723 7.723 0 1115.446 0v79.321a7.723 7.723 0 01-7.723 7.723zM148.163 183.229a7.723 7.723 0 01-3.809-14.447l34.643-19.592a7.722 7.722 0 0110.525 2.921 7.723 7.723 0 01-2.92 10.524l-34.643 19.592a7.7 7.7 0 01-3.796 1.002zM213.861 146.074a7.718 7.718 0 01-6.729-3.923 7.723 7.723 0 012.92-10.524l36.976-20.911a7.723 7.723 0 0110.525 2.921 7.723 7.723 0 01-2.92 10.524l-36.976 20.911a7.69 7.69 0 01-3.796 1.002zM381.209 182.102a7.694 7.694 0 01-3.795-1.002l-100.681-56.939a7.724 7.724 0 017.605-13.445l100.681 56.939a7.722 7.722 0 012.92 10.524 7.722 7.722 0 01-6.73 3.923zM250.822 392.747a7.694 7.694 0 01-3.795-1.002L152.54 338.31a7.724 7.724 0 017.605-13.445l94.487 53.435a7.722 7.722 0 012.92 10.524 7.723 7.723 0 01-6.73 3.923zM280.542 392.747a7.723 7.723 0 01-3.809-14.447l100.681-56.939a7.722 7.722 0 0110.525 2.921 7.723 7.723 0 01-2.92 10.524l-100.681 56.939a7.7 7.7 0 01-3.796 1.002z"
			></path>
			<path
				fill="#9facba"
				d="M394.725 184.97a7.723 7.723 0 01-4.773-13.799l57.985-45.499a7.722 7.722 0 119.535 12.151l-57.985 45.499a7.687 7.687 0 01-4.762 1.648zM463.119 116.122a7.723 7.723 0 01-7.723-7.723V38.85a7.723 7.723 0 1115.446 0v69.549a7.722 7.722 0 01-7.723 7.723zM265.682 478.563a7.723 7.723 0 01-7.723-7.723v-64.652a7.723 7.723 0 1115.446 0v64.652a7.723 7.723 0 01-7.723 7.723zM440.394 382.95a7.7 7.7 0 01-5.461-2.262l-45.674-45.674a7.723 7.723 0 1110.923-10.922l45.674 45.674a7.723 7.723 0 01-5.462 13.184zM394.72 453.246a7.723 7.723 0 01-5.461-13.184l45.674-45.674a7.724 7.724 0 0110.923 10.922l-45.674 45.674a7.704 7.704 0 01-5.462 2.262zM136.664 324.025H65.155a7.723 7.723 0 110-15.446h71.509a7.723 7.723 0 110 15.446z"
			></path>
			<circle cx="463.119" cy="24.396" r="24.396" fill="#8ac9fe"></circle>
			<circle cx="381.215" cy="457.572" r="24.396" fill="#8ac9fe"></circle>
			<circle cx="463.119" cy="122.836" r="24.396" fill="#fdb441"></circle>
			<circle cx="265.682" cy="487.602" r="24.396" fill="#fdb441"></circle>
			<circle cx="452.704" cy="387.538" r="24.396" fill="#fdb441"></circle>
			<circle cx="48.881" cy="316.302" r="24.396" fill="#8ac9fe"></circle>
			<g fill="#ee6161">
				<circle cx="147.321" cy="188.189" r="24.396"></circle>
				<circle cx="147.321" cy="316.302" r="24.396"></circle>
				<circle cx="385.165" cy="188.189" r="24.396"></circle>
				<circle cx="385.165" cy="316.302" r="24.396"></circle>
				<circle cx="265.682" cy="113.299" r="24.396"></circle>
				<circle cx="265.682" cy="389.162" r="24.396"></circle>
			</g>
			<path
				fill="#60b7ff"
				d="M487.514 24.392c0 13.479-10.925 24.394-24.394 24.394-10.339 0-19.184-6.436-22.726-15.528 1.277.206 2.585.309 3.913.309 13.479 0 24.405-10.925 24.405-24.394 0-3.12-.587-6.117-1.668-8.856 11.604 1.874 20.47 11.945 20.47 24.075zM398.463 474.827c-9.525 9.525-24.971 9.525-34.496 0a24.255 24.255 0 01-5.488-8.403c7.476 1.215 15.415-1.061 21.182-6.827 7.054-7.054 8.887-17.361 5.488-26.104a24.301 24.301 0 0113.314 6.827c9.525 9.526 9.525 24.972 0 34.507z"
			></path>
			<path
				fill="#fea613"
				d="M487.514 122.834c0 13.479-10.925 24.394-24.394 24.394-10.339 0-19.184-6.436-22.726-15.528 1.277.206 2.585.309 3.913.309 13.479 0 24.405-10.926 24.405-24.405 0-3.12-.587-6.106-1.668-8.845 11.604 1.875 20.47 11.945 20.47 24.075zM290.074 487.606c0 13.469-10.915 24.394-24.394 24.394-10.349 0-19.194-6.446-22.726-15.549 1.277.216 2.585.319 3.923.319 13.469 0 24.394-10.926 24.394-24.394 0-3.12-.587-6.106-1.668-8.845 11.615 1.874 20.471 11.934 20.471 24.075zM469.957 404.785c-9.525 9.535-24.981 9.535-34.506 0a24.194 24.194 0 01-5.478-8.392c7.476 1.215 15.405-1.061 21.171-6.827 7.054-7.054 8.887-17.361 5.488-26.104a24.301 24.301 0 0113.325 6.827c9.525 9.525 9.525 24.971 0 34.496z"
			></path>
			<path
				fill="#60b7ff"
				d="M73.275 316.3c0 13.479-10.925 24.394-24.394 24.394-10.338 0-19.184-6.436-22.726-15.528 1.277.206 2.585.309 3.913.309 13.479 0 24.405-10.925 24.405-24.394 0-3.12-.587-6.117-1.668-8.856 11.604 1.874 20.47 11.945 20.47 24.075z"
			></path>
			<path
				fill="#e94444"
				d="M171.717 188.191c0 13.469-10.925 24.394-24.394 24.394-10.349 0-19.194-6.446-22.726-15.539 1.277.206 2.585.309 3.913.309 13.479 0 24.394-10.915 24.394-24.394 0-3.13-.587-6.117-1.668-8.856 11.604 1.875 20.481 11.936 20.481 24.086zM171.717 316.3c0 13.479-10.925 24.394-24.394 24.394-10.349 0-19.184-6.436-22.726-15.528 1.277.206 2.585.309 3.913.309 13.479 0 24.394-10.925 24.394-24.394 0-3.13-.587-6.117-1.668-8.866 11.604 1.874 20.481 11.945 20.481 24.085zM409.564 188.191c0 13.469-10.925 24.394-24.394 24.394-10.359 0-19.204-6.446-22.736-15.549 1.277.216 2.585.319 3.923.319 13.469 0 24.394-10.915 24.394-24.394 0-3.13-.587-6.117-1.668-8.856 11.604 1.875 20.481 11.936 20.481 24.086zM409.564 316.3c0 13.479-10.925 24.394-24.394 24.394-10.349 0-19.204-6.436-22.736-15.539 1.277.216 2.585.319 3.923.319 13.469 0 24.394-10.925 24.394-24.394 0-3.13-.587-6.117-1.668-8.866 11.604 1.875 20.481 11.946 20.481 24.086zM290.074 113.299c0 13.469-10.915 24.394-24.394 24.394-10.349 0-19.194-6.446-22.726-15.549 1.277.216 2.585.319 3.923.319 13.469 0 24.394-10.915 24.394-24.394 0-3.12-.587-6.106-1.668-8.845 11.615 1.874 20.471 11.945 20.471 24.075zM290.074 389.164c0 13.469-10.915 24.394-24.394 24.394-10.349 0-19.194-6.446-22.726-15.549 1.277.216 2.585.319 3.923.319 13.469 0 24.394-10.915 24.394-24.394 0-3.12-.587-6.106-1.668-8.845 11.615 1.874 20.471 11.934 20.471 24.075z"
			></path>
		</svg>
	);
}

export default AminoAcids;
