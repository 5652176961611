import {Form, Input, InputNumber, Modal, Select} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import {API_ROOT_URL} from 'envs/_current/config';
import logFunc from 'helpers/logFunc';
import _ from 'lodash';
import {createAsyncAction} from 'modules/asyncCache';
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify';
import useTranslate from 'modules/local/useTranslate';
import React, {useContext, useEffect, useState} from 'react';
import useToggle from 'react-use/lib/useToggle';
import {emptyObject} from "../helpers/emptyObjects";
import {renderColon, renderIf} from "../views/Shared";
import {LayoutContext} from "./layouts/Default/LayoutContext";
import OrganizationContainerContext from "../views/AppLocals/Organization/OrganizationContainerContext";
import {LoginContext} from "./LoginContext";

// Route::post('referrals/{referral_id}/edit-referral', 'ReferralController@updateReferral');
export const UpdateReferalCode = ({referral: initReferral}) => {

  const {isSm} = useContext(LayoutContext)
  const login = useContext(LoginContext);
  const {organization} = useContext(OrganizationContainerContext)
  const layout = {
    labelCol: {span: isSm ? 24 : 24, },
    wrapperCol: {span: isSm ? 24 : 24},
  };
  const t = useTranslate()
  const timoutOptions = [
    {
      value: 0,
      label: t('none')
    },
    {
      value: 120,
      label: t('5 minutes')
    },
    {
      value: 1800,
      label: t('30 minutes')
    },
    {
      value: 3600,
      label: t('1h')
    },
    {
      value: 86400,
      label: t('1day')
    }
  ];
  const actionOptions = [
    {
      label: t('Join As Member'),
      value: 'joinAsMember'
    },
    {
      label: t('Join As Admin'),
      value: 'joinAsAdmin'
    }
  ];
  const groupOptions = _.get(organization, 'member_groups', [])
    .map(e => {
      return {label: e.title, value: e.title}
    });
  const [referral, setReferal] = useState(initReferral);
  const [isToggle1, toggle1] = useToggle();
  const [isPublic, setPublic] = useToggle(false);
  const referral_id = _.get(referral, 'referral_code');
  const [{isloading}, dispath] = useDispatchAsyncActionWithNotify({
    onSuccess: ([__, data]) => {
      const referral = logFunc(_.get)(data, 'response.data');
      setReferal(referral);
      toggle1();
    }
  });
  const onFinish = (values) => {
    dispath(createAsyncAction({
      apiInfo: {
        root: API_ROOT_URL,
        path: `/referrals/${referral_id}/edit-referral`,
        method: 'POST',
      }, values: {...values, public: isPublic ? 1 : 0}
    }));
  };
  const [form] = useForm();
  const initialValues = {
    timeout: _.get(referral, 'timeout'),
    action: _.get(referral, 'action') || 'joinAsMember',
    member_group_type: referral?.member_group_type !== null ? String(referral?.member_group_type).split(",") : [],
    limit: _.get(referral, 'limit'),
    public: _.get(referral, 'public'),
    title: _.get(referral, 'title')
  }
  useEffect(() => {
    form.resetFields()
  }, [isToggle1])
  return (
    <Form className="border border-color-50 rounded-lg shadow-items-md space-y-3" key={JSON.stringify({initialValues, isToggle1})} form={form}
          {...layout}
          name="basic"
          initialValues={initialValues}
          onFinish={onFinish}>
      <div className="space-y-2 rounded-lg p-3">
        <div className="flex items-center mb-1">
          <span className="flex-1 font-semibold text-sm text-color-200 italic">
            {t('invite information')}
          </span>
          <div
            style={{padding: '2px 10px'}}
            className="border rounded-md background-100 hover:background-200 font-bold text-xs text-color-100 cursor-pointer"
            onClick={toggle1}>
            {t('change')}
          </div>
        </div>
        {renderIf(
            !!_.get(referral, 'public'),
            <div className="flex items-baseline gap-1">
              <div className="italic text-xs text-color-500">{t('title')}{renderColon()}</div>
              <div className="font-semibold text-sm text-color-100">
                {_.get(referral, 'title')}
              </div>
            </div>
        )}
        <div className="flex items-baseline gap-1">
          <div className="italic text-xs text-color-500">{t('admin description')}{renderColon()}</div>
          <div className="font-semibold text-sm text-color-100">
            {(actionOptions.find(item => _.get(referral, 'action') === item.value) || emptyObject).label}
          </div>
        </div>
        <div className="flex items-baseline gap-1">
          <div className="italic text-xs text-color-500">{t('timeout')}{renderColon()}</div>
          <div className="font-semibold text-sm text-color-100">
            {(timoutOptions.find(item => _.get(referral, 'timeout') === item.value) || emptyObject).label}
          </div>
        </div>
        <div className="flex items-baseline gap-1">
          <div className="italic text-xs text-color-500">{t('limit')}{renderColon()}</div>
          <div className="font-semibold text-sm text-color-100">
            {_.get(referral, 'limit')}
          </div>
        </div>
        {renderIf(
            !_.isEmpty(_.get(referral, 'member_group_type')),
            <div className="flex items-baseline gap-1">
              <div className="italic text-xs text-color-500">{t('group of members')}{renderColon()}</div>
              {
                String(_.get(referral, 'member_group_type')).split(",").map((e, i) => (
                  <span
                      key={i}
                      style={{ padding: '4px 10px'}}
                      className="font-semibold text-2xs text-primary italic mr-2 bg-primary-50 rounded-xs">
                    {e}
                  </span>
                ))
              }
            </div>
        )}
      </div>
      <Modal
        className="custom-modal"
        destroyOnClose
        visible={isToggle1}
        title={
          <div className="text-center font-bold text-color-000 uppercase">{t('options')}</div>
        }
        onCancel={toggle1}
        onOk={form.submit}
        okText={t('save')}
        cancelText={t('cancel')}
        okButtonProps={{
          loading: isloading,
          type: 'primary',
          className: 'button-rounded-lg no-border',
        }}
        cancelButtonProps={{
          className: 'button-rounded-lg no-border',
        }}>

        <div className="mb-3">
          <Form.Item
              label={t("roles")}
              name="action">
            <Select options={actionOptions}/>
          </Form.Item>
          <label className="text-xs text-color-500 italic">
            {`* ${t('description roles')}`}
          </label>
        </div>

        <div className="mb-3">
          <Form.Item label={t("timeout")} name="timeout">
            <Select placeholder={t('timeout')} options={timoutOptions}/>
          </Form.Item>
          <label className="text-xs text-color-500 italic">
            {`* ${t('description timeout')}`}
          </label>
        </div>

        <div className="mb-3">
          <Form.Item label={t('limit')} name="limit">
            <InputNumber min={0} className="w-full"/>
          </Form.Item>
          <label className="text-xs text-color-500 italic">
            {`* ${t('description limit')}`}
          </label>
        </div>

        <div className="mb-3">
          <Form.Item label={t("group of members")} name="member_group_type">
            <Select
                showSearch={true}
                className="w-full"
                allowClear={true}
                mode="tags"
                options={groupOptions}
            />
          </Form.Item>
          <label className="text-xs text-color-500 italic">
            {`* ${t('description group of members')}`}
          </label>
        </div>

        {renderIf(
            !!_.get(login, 'edit'),
            <div className="mb-3">
              <Form.Item label={t("public")} name="public">
                <Select
                    onChange={value => {
                      setPublic(!!value)
                    }}
                    options={[
                      {label: t('yes'), value: 1},
                      {label: t('no'), value: 0}
                    ]}
                />
              </Form.Item>
              <label className="text-xs text-color-500 italic">
                {`* ${t('description public')}`}
              </label>
            </div>
        )}
        {renderIf(
            !!isPublic,
            <div className="mb-3">
              <Form.Item label={t('title')} name="title">
                <Input placeholder={t('enter title')}/>
              </Form.Item>
              <label className="text-xs text-color-500 italic">
                {`* ${t('description title')}`}
              </label>
            </div>
        )}
      </Modal>
    </Form>
  );
};
