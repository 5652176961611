import { CloseOutlined } from '@ant-design/icons'
import { Button, Checkbox, Input, InputNumber, Segmented, Tooltip } from 'antd'
import { getId } from 'apis/model/base'
import { PostSettingPanel } from 'components/Feed/InputBoard/CreatePostForm'
import { InputBoardContext } from 'components/Feed/InputBoard/InputBoardContext'
import FieldDecorator from 'components/form/FieldDecorator'
import FieldsFactory from 'components/form/FieldsFactory'
import { StepsForm } from 'components/form/StepsForm'
import { createValue } from 'components/form/utils'
import Pure from 'components/Pure'
import { Field, useFormikContext } from 'formik'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useContext, useEffect, useState } from 'react'
import { notifyOnError } from 'views/AppLocals/factory/createErrorEvent'
import {
  NotificationActionTypes,
  successNotify,
} from 'views/AppLocals/factory/createNotification'
import { poll_edit_Api } from '../../apis'
import { FullSideModalLayout } from '../../components/Modal/SideModalLayout'
import { formatterNumber } from '../../helpers/formatterNumber'
import { parseNumber } from '../../helpers/parseNumber'
import useAsyncAction from '../../modules/asyncCache/useAsyncAction'
import ServerContext from '../Server/ServerContext'

const Answers = ({ onChange, defaultValue = [] }) => {
  const t = useTranslate()
  const [answers, setAnswers] = useState(defaultValue)
  const [newAnswer, setNewAnswer] = useState('')
  useEffect(() => {
    onChange(answers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers])
  const handleAddNewAnswer = (e) => {
    e.preventDefault()
    setAnswers([...answers, { id: null, title: newAnswer }])
    setNewAnswer('')
  }
  const handleRemoveAnswer = (value) => {
    setAnswers([...answers.filter((e, index) => value !== e.title)])
  }

  return (
    <>
      {answers.map((answer, index) => (
        <Input
          key={index}
          className="mb-3 animated fadeInUp faster"
          onChange={(e) => {
            const newAnswers = [...answers]
            const current = newAnswers[index]
            newAnswers[index] = { ...(current ?? {}), title: e.target.value }
            setAnswers(newAnswers)
          }}
          addonAfter={
            <Tooltip title={t('remove')}>
              <CloseOutlined
                onClick={() => {
                  handleRemoveAnswer(answer.title)
                }}
              />
            </Tooltip>
          }
          defaultValue={answer.title}
        />
      ))}
      <form onSubmit={handleAddNewAnswer}>
        <div className="flex gap-3">
          <Input.Group
            compact
            style={{ display: 'inline-flex', alignItems: 'center' }}
            className="flex items-center">
            <Input
              key="create new answer"
              onChange={(e) => {
                setNewAnswer(e.target.value)
              }}
              placeholder={t('input answer')}
              value={newAnswer}
            />
            <Tooltip
              placement="topLeft"
              title={t('add answers to poll questions')}>
              <Button
                type="primary"
                onClick={handleAddNewAnswer}>
                {t('add')}
              </Button>
            </Tooltip>
          </Input.Group>
        </div>
      </form>
    </>
  )
}

const PollForm = ({ onBack, onChange }) => {
  const t = useTranslate()
  const { values } = useFormikContext()
  return (
    <div className="flex flex-col gap-6">
      <PostSettingPanel />
      <Pure>
        <FieldsFactory
          formSchema={[
            {
              children: {
                type: 'title',
                name: 'title',
                label: t('questions/voting content'),
                component: Input,
                withProps: {
                  type: 'text',
                  placeholder: t('questions/ content'),
                },
              },
            },
          ]}
        />
      </Pure>
      <Field
        name="is_yesno_question"
        render={({ field }) => (
          <FieldDecorator
            className="w-full flex flex-col"
            name="is_yesno_question"
            labelProps={{ className: undefined }}
            hasError={false}
            label={t('is yes/no question')}>
            <span className="text-xs text-color-400 italic mb-2">
              {`* ${t('yes/no questions description')}`}
            </span>
            <Segmented
              className="w-fit"
              defaultValue={_.get(values, 'is_yesno_question', 0)}
              options={[
                { label: t('yes'), value: 1 },
                { label: t('no'), value: 0 },
              ]}
              onChange={(value) =>
                field.onChange(createValue('is_yesno_question', value))
              }
            />
          </FieldDecorator>
        )}
      />
      {!!!_.get(values, 'is_yesno_question', 0) && (
        <div className="flex flex-col gap-2">
          <Field
            name="answers"
            render={({
              field,
              form: { values = {}, touched = {}, errors = {} } = {
                touched: {},
                errors: {},
              },
            }) => (
              <FieldDecorator
                errors={errors.answers}
                labelProps={{ className: undefined }}
                label={t('answers can be voted on')}>
                <div className="flex flex-col">
                  <Answers
                    onChange={(value) => {
                      field.onChange(createValue('answers', value))
                    }}
                    defaultValue={_.get(values, 'answers', [])}
                  />
                </div>
              </FieldDecorator>
            )}
          />
          <Field
            name="customizable_answers"
            render={({ field }) => (
              <Checkbox
                className="text-sm text-color-300 italic"
                disabled={!!_.get(values, 'is_yesno_question', 0)}
                checked={Number(field.value)}
                onChange={(e) =>
                  field.onChange(
                    createValue(
                      'customizable_answers',
                      e.target.checked ? '1' : '0'
                    )
                  )
                }>
                {t('allow add new answers')}
              </Checkbox>
            )}
          />
        </div>
      )}

      <div className="border-b border-color-50" />

      <div className="flex flex-col gap-2">
        <Field
          name="is_single_selection"
          render={({ field }) => (
            <FieldDecorator
              className="w-full col-span-2"
              name="is_single_selection"
              labelProps={{ className: undefined }}
              hasError={false}
              label={t('number of possible answers in a poll')}>
              <Segmented
                disabled={!!_.get(values, 'is_yesno_question', 0)}
                defaultValue={_.get(values, 'is_single_selection', 0)}
                value={
                  !!_.get(values, 'is_yesno_question', 0)
                    ? 1
                    : _.get(values, 'is_single_selection', 0)
                }
                options={[
                  { label: t('one answer'), value: 1 },
                  { label: t('multiple answer'), value: 0 },
                ]}
                onChange={(value) =>
                  field.onChange(createValue('is_single_selection', value))
                }
              />
            </FieldDecorator>
          )}
        />
        {!!!_.get(values, 'is_yesno_question', 0) &&
          !!!_.get(values, 'is_single_selection') && (
            <Field
              name="vote_on_maximum"
              render={({ field, form }) => (
                <FieldDecorator
                  className="w-full col-span-2"
                  name="vote_on_maximum"
                  labelProps={{ className: undefined }}
                  label={t('number of choices')}>
                  <InputNumber
                    min={2}
                    max={_.get(form, 'values.answers', 1)}
                    name="vote_on_maximum"
                    inputMode={'numeric'}
                    className="w-full"
                    formatter={formatterNumber}
                    parser={parseNumber}
                    defaultValue={1}
                    value={
                      !!_.get(values, 'is_yesno_question', 0)
                        ? 1
                        : _.get(values, 'vote_on_maximum', 1)
                    }
                    onChange={(value) => {
                      field.onChange(createValue('vote_on_maximum', value))
                    }}
                  />
                </FieldDecorator>
              )}
            />
          )}
      </div>
    </div>
  )
}

const CreatePollRoutes = [
  {
    path: '/questtionAndAnswers',
    name: 'edit a poll',
    component: PollForm,
    exact: true,
  },
]

const tranformBeforeSubmit = (values = {}) => {
  return {
    ...values,
    vote_on_maximum:
      !!values.is_single_selection || !!values.is_yesno_question
        ? 1
        : values.vote_on_maximum,
    answers: JSON.stringify(
      _.get(values, 'answers', []).map((obj) => ({
        id: obj.id,
        title: obj.title,
      }))
    ),
  }
}

export const EditPoll = ({
  initialValues = {
    title: '',
    vote_on_maximum: 1,
    customizable_answers: 0,
    is_single_selection: 1,
    is_yesno_question: 0,
    answers: [],
  },
  onSubmit,
  onCancel,
  isLoading,
}) => {
  const t = useTranslate()
  const handleSubmit = (values) => {
    const { owner, container, ...rest } = values ?? {}
    onSubmit(tranformBeforeSubmit(rest))
  }
  const validate = (values) => {
    const { title, is_yesno_question } = values ?? {}
    const answers = Array.from(values?.answers ?? [])
    let errors = {}
    if (_.isEmpty(String(title ?? '').trim())) {
      errors.title = t('required field')
    }
    if (!is_yesno_question && !answers.length) {
      errors.answers = t('required field')
    }
    return errors
  }
  return (
    <StepsForm
      validate={validate}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      routes={CreatePollRoutes}
      initialValues={initialValues}
      onCancel={onCancel}
    />
  )
}

export const EnhancedEditPoll = ({ pollItem, onCancel }) => {
  const t = useTranslate()
  const { item, setCurrent } = useContext(ServerContext)
  const { options } = useContext(InputBoardContext)
  const {
    time,
    isLoading,
    handleAsyncAction: handleAsyncPost,
  } = useAsyncAction({
    apiInfo: poll_edit_Api,
    query: {
      ':id': pollItem.id,
    },
    onSuccess: (result) => {
      successNotify(NotificationActionTypes.UPDATE, t)
      setCurrent(null)
      onCancel()
    },
    onError: notifyOnError(t),
  })

  return (
    <FullSideModalLayout
      right
      renderHeader={() => null}
      // title={
      //   <span className="font-bold flex-1">
      //     <div
      //       style={{
      //         fontSize: '0.8em',
      //       }}
      //       className="text-color-300 capitalize">
      //       {getTitle(item)}
      //     </div>
      //   </span>
      // }
      onCancel={() => {
        setCurrent(null)
        onCancel()
      }}>
      <div className="p-3 md:p-6">
        <EditPoll
          initialValues={{
            containers: options,
            owner_disabled: true,
            owner: pollItem?.owner,
            container_disabled: true,
            privacy: pollItem?.privacy,
            container: pollItem?.container,
            default_owner_id: getId(pollItem?.owner),
            default_container_id: getId(pollItem?.container),
            title: _.get(pollItem, 'title'),
            description: _.get(pollItem, 'description'),
            vote_on_maximum: _.get(pollItem, 'vote_on_maximum', 1),
            answers: _.get(pollItem, 'poll_answers', []),
            customizable_answers: _.get(pollItem, 'customizable_answers'),
            is_single_selection:
              _.get(pollItem, 'vote_on_maximum', 1) === 1 ||
              _.get(pollItem, 'is_yesno_question') === 1
                ? 1
                : 0,
            is_yesno_question: _.get(pollItem, 'is_yesno_question'),
          }}
          title={t('edit')}
          isLoading={isLoading}
          onSubmit={(values) => {
            const {
              owner,
              container,
              containers,
              owner_disabled,
              default_owner_id,
              container_disabled,
              default_container_id,
              ...rest
            } = values ?? {}
            handleAsyncPost(rest)
          }}
          onCancel={() => {
              setCurrent(null)
              onCancel()
          }}
        />
      </div>
    </FullSideModalLayout>
  )
}
