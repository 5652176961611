import {
    Avatar,
    Button,
    DatePicker,
    Input,
    Modal,
    Popconfirm,
    Steps,
    Switch, Tooltip,
} from 'antd'
import { event_edit_Api } from 'apis/event'
import { getId, getType } from 'apis/model/base'
import FieldsFactory from 'components/form/FieldsFactory'
import { createValue } from 'components/form/utils'
import LookupField from 'components/LookupField'
import Toggle from 'components/Toggle'
import UseState from 'components/UseState'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import * as moment from 'moment'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import {
  AiOutlineArrowRight,
  AiOutlineMinusCircle,
  AiOutlinePlusCircle,
} from 'react-icons/ai'
import {
    BsCalendar2Week,
    BsCalendar3,
    BsFillCaretLeftFill,
    BsPersonFill,
    BsTrash,
} from 'react-icons/bs'
import {
    FaCircle, FaMinusCircle,
    FaPlayCircle,
    FaPlusCircle,
    FaRegDotCircle,
    FaStopCircle,
} from 'react-icons/fa'
import {FiEdit2, FiEdit3} from 'react-icons/fi'
import createEditableForm from 'views/AppLocals/factory/createEditableForm'
import { bindQueryParam } from 'views/AppLocals/functions/routerHelper'
import { Null } from 'views/Shared'
import SlateEditor, { SlateRenderer } from 'views/SlateEditor'
import {
  convertFromString,
  convertToString,
  findNodes,
} from 'views/SlateEditor/functions'
import { ElementTypes } from 'views/SlateEditor/types'
import { isRawContentEmpty } from '../../../SlateEditor/functions/parseRawContent'
import EventContext from '../EventContext'
import { timeline_steps } from '../holders/EventTimeline'
import { SpeakersPicker } from './EventSettingSpeakers'

const formFields = [
  { name: 'timetables', defaultValue: [] },
  { name: 'invisible_timeline', defaultValue: false },
]

const TimelineSpeakersField = ({ label, onChange, ...props }) => {
  const t = useTranslate()

  const initSpeakers = _.get(props, 'defaultValues', [])

  return (
    <div className="flex flex-col">
      <UseState
        initialValue={initSpeakers.map((e, remapIndex) => ({
          ...e,
          key: remapIndex,
        }))}>
        {([speakers, setSpeakers]) => (
          <div className="flex flex-col space-y-3">
            {speakers.map((e, index) => (
              <div
                key={index}
                className="flex items-center gap-1 background rounded-md p-2">
                <Avatar
                  size={25}
                  className="flex flex-center background-100"
                  icon={
                    <BsPersonFill
                      size={15}
                      className="text-color-400"
                    />
                  }
                  src={_.get(e, 'avatar', null)}
                />

                <div className="font-medium text-xs text-color-000 flex-1">
                  {_.get(e, 'speaker.name') ||
                    _.get(e, 'speaker_name') ||
                    _.get(e, 'value')}
                </div>

                <BsTrash
                  onClick={() => {
                    const _speakers = speakers.filter((el) => el.key !== index)
                    onChange(_speakers)
                    setSpeakers(_speakers)
                  }}
                  className="cursor-pointer text-red-300 hover:text-red-600 text-xs"
                />
              </div>
            ))}
            <div className="flex flex-col py-1">
              {label && (
                <div className="text-xs text-color-300">{t(label)}</div>
              )}
              <LookupField
                apiPath="/lookup/user/get-by-types"
                {...props}
                handleChange={(e) => {
                  onChange([
                    ...speakers,
                    { ...e.target, speaker_name: e.target.value },
                  ])
                  setSpeakers([
                    ...speakers,
                    { ...e.target, speaker_name: e.target.value },
                  ])
                }}
              />
            </div>
          </div>
        )}
      </UseState>
    </div>
  )
}

const TimetableEditor = ({
  name,
  item,
  itemIndex,
  onChange,
  isInsert,
  ...props
}) => {
  const t = useTranslate()
  const values = _.get(props, 'form.values', {})

  const { handleSubmit = Null } = props.form ?? {}

  const [editorState, setEditorState] = useState(() =>
    convertFromString(item?.description)
  )

  const imageSrcList = useMemo(
    () => findNodes(editorState, ElementTypes.IMAGE),
    [editorState]
  )

  const submitOnChange = (delay = 300) => {
    setTimeout(() => {
      handleSubmit()
    }, delay)
  }

  return (
    <UseState
      initialValue={{
        isUpdating: false,
        ...item,
        start_time: moment(item.start_time),
        finish_time: !!item.finish_time ? moment(item.finish_time) : null,
      }}>
      {([updateItem, setUpdateItem]) => (
        <div className="flex flex-col w-full">
          <Input
            hidden={true}
            name="timetables"
          />
          <div className="flex flex-col mb-1">
            <div className="text-xs italic text-color-400 leading-tight mb-1">
                {`${t('start time')} : `}
            </div>
            <div className="flex items-center gap-3">
              <DatePicker
                showTime
                className="flex-1 rounded-md"
                name="start_time"
                placeholder={t('Select date')}
                defaultValue={updateItem.start_time}
                suffixIcon={<BsCalendar2Week className="text-color-400"/>}
                onChange={(value) => {
                  setUpdateItem({
                    ...updateItem,
                    start_time: !!value ? value.utc().format() : null,
                  })
                }}
              />
              <Switch
                checkedChildren={t('milestone')}
                onChange={(checked) => {
                  setUpdateItem({
                    ...updateItem,
                    milestone: checked ? 1 : 0,
                  })
                }}
                unCheckedChildren={t('update')}
                defaultChecked={updateItem.milestone === 1}
              />
            </div>
          </div>

          <Toggle defaultValue={!!updateItem.finish_time}>
            {(isToggle, toggle) => (
              <React.Fragment>
                {!isToggle ? (
                  <div
                    className="flex items-center text-primary hover:text-primary-700 space-x-3 cursor-pointer mb-3 mt-1"
                    onClick={toggle}>
                    <AiOutlinePlusCircle size={12} />
                    <div className="font-medium text-sm leading-tight">
                      {t('finish time')}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col mb-3 mt-1">
                    <div
                      className="flex items-center text-primary hover:text-primary-700 space-x-3 cursor-pointer mb-1"
                      onClick={toggle}>
                      <AiOutlineMinusCircle size={12} />
                      <div className="font-medium text-sm leading-tight">
                        {t('finish time')}
                      </div>
                    </div>
                    <DatePicker
                      showTime
                      className="flex-1 rounded-md"
                      placeholder={t('Select date')}
                      defaultValue={moment(values.finish_time)}
                      suffixIcon={<BsCalendar2Week className="text-color-400"/>}
                      onChange={(value) => {
                        setUpdateItem({
                          ...updateItem,
                          finish_time: value,
                        })
                      }}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </Toggle>

          <div className="flex flex-col mb-3">
            <div className="text-xs italic text-color-400 leading-tight mb-1">
                {`${t('title')} : `}
            </div>
            <Input
              name="title"
              defaultValue={updateItem.title}
              onChange={(e) => {
                setUpdateItem({
                  ...updateItem,
                  title: e.target.value,
                })
              }}
            />
          </div>

          <Toggle defaultValue={!!_.get(updateItem, 'speakers.length')}>
            {(isToggle, toggle) => (
              <div className="flex flex-col mb-3">
                <div
                  className="flex items-center text-primary space-x-3 cursor-pointer mb-1"
                  onClick={toggle}>
                  {isToggle ? (
                    <AiOutlineMinusCircle size={12} />
                  ) : (
                    <AiOutlinePlusCircle size={12} />
                  )}
                  <div className="font-medium text-sm leading-tight">{t('speakers')}</div>
                </div>
                {isToggle && (
                    <FieldsFactory
                        formSchema={[
                            {
                                children: {
                                    name: 'speakers',
                                    hideError: true,
                                    component: SpeakersPicker,
                                    withProps: {
                                        renderEmpty: Null,
                                        renderButton: (_, { toggle }) => (
                                            <Input.Search
                                                onSearch={() => {
                                                    toggle(true)
                                                }}
                                                onClick={() => {
                                                    toggle(true)
                                                }}
                                            />
                                        ),
                                        value: updateItem.speakers,
                                        placeholder: 'select user',
                                        onChange: (event) => {
                                            const values = event?.target?.value
                                            setUpdateItem({
                                                ...updateItem,
                                                speakers: values.map((e) => ({
                                                    speaker_name: e.value,
                                                    ...e,
                                                })),
                                            })
                                        },
                                    },
                                },
                            },
                        ]}
                    />
                )}
              </div>
            )}
          </Toggle>

          <Toggle defaultValue={!!_.get(updateItem, 'description')}>
            {(isToggle0, toggle0) => (
              <React.Fragment>
                {!isToggle0 ? (
                  <div
                    className="flex items-center text-primary space-x-3 cursor-pointer"
                    onClick={toggle0}>
                    <AiOutlinePlusCircle size={12} />
                    <div className="font-medium text-sm leading-tight">
                      {t('description')}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col mb-3">
                    <div
                      className="flex items-center text-primary space-x-3 cursor-pointer mb-1"
                      onClick={toggle0}>
                      <AiOutlineMinusCircle size={12} />
                      <div className="text-sm leading-tight">
                        {t('description')}
                      </div>
                    </div>
                    <Toggle>
                      {(isToggle, toggle) => {
                        return (
                          <div className="flex flex-col space-y-1">
                            <div className="flex items-center mt-1 mb-1">
                              <div className="flex-1 text-sm text-color-300 font-semibold uppercase">
                                {t('description')}
                              </div>
                              <div
                                className="flex items-center gap-2 text-color-400 hover:text-color-100 cursor-pointer"
                                onClick={toggle}>
                                  <FiEdit3 size={12}/>
                                  <span className="font-medium text-xs italic leading-tight">
                                      {t('edit')}
                                  </span>
                              </div>
                            </div>
                            <div
                              onClick={toggle}
                              className="px-1 border border-color-50 hover:shadow-items-md background rounded-lg cursor-pointer">
                              <SlateRenderer
                                key={isToggle}
                                value={editorState}
                              />
                            </div>
                            <Modal
                              width={540}
                              className="custom-modal"
                              title={
                                  <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                                      {t('edit')}
                                  </div>
                              }
                              destroyOnClose
                              onCancel={toggle}
                              maskClosable={false}
                              visible={isToggle}
                              okText={t('save')}
                              cancelText={t('close')}
                              okButtonProps={{
                                type: 'primary',
                                className: 'button-rounded-md no-border',
                              }}
                              cancelButtonProps={{
                                className: 'button-rounded-md no-border',
                              }}
                              onOk={() => {
                                toggle()
                              }}>
                              <SlateEditor
                                name="description"
                                defaultValue={editorState}
                                resetKey={values?.resetKey}
                                onChange={(event) => {
                                  setEditorState(event?.target?.value)
                                }}
                              />
                            </Modal>
                          </div>
                        )
                      }}
                    </Toggle>
                  </div>
                )}
              </React.Fragment>
            )}
          </Toggle>

          <div className="flex justify-end gap-3">
            {!!!isInsert && (
              <Popconfirm
                placement="bottomLeft"
                title={
                    <div className="font-medium text-sm text-color-100 italic">
                        {t('remove this item timetable')}
                    </div>
                }
                onConfirm={() => {
                  let _timetableTemp = _.get(values, 'timetables', []).filter(
                    (e) => e.id !== updateItem.id
                  )
                  onChange(createValue('timetables', _timetableTemp))

                  submitOnChange()
                }}
                okText={t('sure')}
                cancelText={t('no')}
                okButtonProps={{
                  className: 'button-rounded-md no-border text-xs font-medium',
                }}
                cancelButtonProps={{
                  className: 'button-rounded-md no-border text-xs font-medium',
                }}>
                <Button
                    ghost danger
                    type="primary"
                    size="small"
                    className="button-rounded-md font-medium text-xs">
                  {t('remove')}
                </Button>
              </Popconfirm>
            )}
            <Button
              type="primary"
              size="small"
              className="button-rounded-md no-border text-xs font-medium"
              onClick={() => {
                let _updateItem = updateItem
                if (
                  !!_updateItem.start_time &&
                  moment.isMoment(_updateItem.start_time)
                )
                  _updateItem.start_time = _updateItem.start_time.utc().format()
                if (
                  !!_updateItem.finish_time &&
                  moment.isMoment(_updateItem.finish_time)
                )
                  _updateItem.finish_time = _updateItem.finish_time
                    .utc()
                    .format()

                _updateItem.description = convertToString(editorState)

                _updateItem.photo_ids_string = _.join(
                  _.map(imageSrcList, 'imageId'),
                  ','
                )

                if (!!_updateItem.isInsert) delete _updateItem.isInsert
                if (!!_updateItem.active) delete _updateItem.active
                if (!!_updateItem.isUpdating) delete _updateItem.isUpdating

                let _timetableTemp = values.timetables
                _timetableTemp[itemIndex] = {
                  ..._updateItem,
                }

                // insert 1 default new
                if (!!isInsert) {
                  _timetableTemp.splice(itemIndex + 1, 0, {
                    isInsert: true,
                    active: true,
                  })
                }

                onChange(createValue('timetables', _timetableTemp))
                onChange(createValue('update', Date.now()))

                submitOnChange()
              }}>
              {t('update')}
            </Button>
          </div>
        </div>
      )}
    </UseState>
  )
}

const EventTimetable = ({ name, value, onChange = Null }) => {
  const t = useTranslate()

  const timetables = Array.from(value ?? [])

  return (
    <Steps size="small" direction="vertical" className="custom-step-timetable">
      {timetables.map((item, index) => {
        const {
          active,
          title,
          isInsert,
          milestone,
          start_time,
          finish_time,
          isUpdating,
          mandatory,
          description,
        } = item ?? {}

        const speakers = Array.from(item?.speakers ?? [])
        const editable = !mandatory
        if (mandatory === 1 && title === timeline_steps.START)
          return (
            <Steps.Step
              key={index}
              status="process"
              title={
                <div className="flex flex-row space-x-2 font-bold uppercase">
                  <span>{t('start')}</span>
                </div>
              }
              icon={<FaPlayCircle />}
            />
          )
        if (mandatory === 1 && title === timeline_steps.FINISH)
          return (
            <Steps.Step
              key={index}
              status="process"
              title={
                <div className="flex flex-row space-x-2 font-bold uppercase">
                  <span>{t('finish')}</span>
                </div>
              }
              icon={<FaStopCircle className="text-red-500" />}
            />
          )
        if (isInsert && active) {
          return (
            <Steps.Step
              key={index}
              status="process"
              title={
                <div className="w-full border border-color-50 shadow-items-md rounded-lg background p-3 my-3">
                  <FieldsFactory
                    formSchema={[
                      {
                        type: 'text',
                        name: 'timetables',
                        label: t('timetables'),
                        children: {
                          component: TimetableEditor,
                          withProps: {
                            key: JSON.stringify(item),
                            type: 'text',
                            name: 'timetables',
                            item: {
                              ...item,
                              milestone: 1,
                            },
                            isInsert: true,
                            itemIndex: index,
                          },
                        },
                      },
                    ]}
                  />
                </div>
              }
              icon={
                <FaMinusCircle
                    className="text-color-500 hover:text-color-300 cursor-pointer"
                    onClick={() => {
                        let _timetableTemp = timetables
                        _timetableTemp[index] = { isInsert: true }
                        onChange(createValue(name, _timetableTemp))
                    }}
                />
              }
            />
          )
        }
        if (isInsert) {
          return (
            <Steps.Step
              key={index}
              status="process"
              title={
                <div
                  onClick={() => {
                    let _timetableTemp = timetables
                    _timetableTemp[index] = {
                      isInsert: true,
                      active: true,
                    }
                    onChange(createValue(name, _timetableTemp))
                  }}
                  className="flex items-center gap-1 font-semibold text-sm text-color-200 hover:text-primary italic cursor-pointer">
                  <BsFillCaretLeftFill size={12} />
                  <span>{t('insert here')}</span>
                </div>
              }
              icon={
                <FaPlusCircle
                  style={{ padding: '0.25rem' }}
                  className="text-primary hover:text-primary-700 cursor-pointer"
                  onClick={() => {
                    let _timetableTemp = timetables
                    _timetableTemp[index] = {
                      isInsert: true,
                      active: true,
                    }
                    onChange(createValue(name, _timetableTemp))
                  }}
                />
              }
            />
          )
        }
        if (isUpdating) {
          return (
            <Steps.Step
              key={index}
              status="process"
              title={
                <div className="w-full border border-color-50 rounded-lg background-100 p-3 my-3">
                  <FieldsFactory
                    formSchema={[
                      {
                        type: 'text',
                        name: 'timetables',
                        label: t('timetables'),
                        children: {
                          component: TimetableEditor,
                          withProps: {
                            key: JSON.stringify(item),
                            type: 'text',
                            name: 'timetables',
                            item: item,
                            itemIndex: index,
                          },
                        },
                      },
                    ]}
                  />
                </div>
              }
              icon={
                milestone === 1 ? (
                  <FaRegDotCircle
                    onClick={() => {
                      let _timetableTemp = timetables
                      _timetableTemp[index] = {
                        ..._timetableTemp[index],
                        isUpdating: false,
                      }
                      onChange(createValue(name, _timetableTemp))
                    }}
                  />
                ) : (
                  <FaCircle
                    onClick={() => {
                      let _timetableTemp = timetables
                      _timetableTemp[index] = {
                        ..._timetableTemp[index],
                        isUpdating: false,
                      }
                      onChange(createValue(name, _timetableTemp))
                    }}
                  />
                )
              }
            />
          )
        }
        return (
          <Steps.Step
            key={index}
            status="process"
            title={
              <div className="flex flex-col gap-2">
                <div className="flex flex-col">
                  <div className="flex items-center gap-4">
                    <span className="font-bold">{title}</span>
                    {editable && (
                        <Tooltip title={t('edit')} overlayClassName="font-medium text-xs italic">
                            <FiEdit3
                                onClick={() => {
                                    let _timetableTemp = timetables
                                    _timetableTemp[index] = {..._timetableTemp[index], isUpdating: true,}
                                    onChange(createValue(name, _timetableTemp))
                                }}
                                className="text-color-400 hover:text-color-100 cursor-pointer"
                            />
                        </Tooltip>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-3 text-color-400 italic leading-tight">
                    {!!start_time && (
                      <span className="text-xs">
                        {moment(start_time).format('HH:mm - DD/MM/YYYY')}
                      </span>
                    )}
                    {!!finish_time && (
                        <div className="flex items-center gap-3">
                            <AiOutlineArrowRight size={12}/>
                            <span className="text-xs">
                                {moment(finish_time).format('HH:mm - DD/MM/YYYY')}
                            </span>
                        </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                    <span className="font-bold text-xs text-color-400 uppercase tracking-wider leading-snug">
                        {`${t('speakers')} : `}
                    </span>
                    <div className="ml-3 grid grid-cols-1 md:grid-cols-2 gap-2">
                        {speakers.map((eee, index) => (
                            <div
                                key={index}
                                className="flex items-center gap-2">
                                <Avatar
                                    size={20}
                                    className="flex flex-center background-200"
                                    icon={ <BsPersonFill size={12} className="text-color-400" /> }
                                    src={getAvatar(eee)}
                                />
                                <div className="font-bold text-xs text-color-000 flex-1">
                                    {_.get(eee, 'speaker.name') || _.get(eee, 'speaker_name') || _.get(eee, 'value')}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
              </div>
            }
            description={
              description && isRawContentEmpty(description) ? null : (
                <SlateRenderer value={convertFromString(description)} />
              )
            }
            icon={milestone === 1 ? <FaRegDotCircle className="text-primary-700" /> : <FaCircle className="text-primary-700" />}
          />
        )
      })}
    </Steps>
  )
}

const formSchema = [
  {
    children: [
      {
        name: 'invisible_timeline',
        component: ({ name, value, onChange }) => (
          <Translate>
            {(t) => (
              <Switch
                name={name}
                defaultChecked={!!!value}
                checkedChildren={t('visible')}
                unCheckedChildren={
                  <div className="text-color">{t('invisible')}</div>
                }
                onChange={(checked, event) => {
                  onChange(createValue(name, !checked ? 1 : 0))
                }}
              />
            )}
          </Translate>
        ),
      },
    ],
  },
  {
    title: 'timeline',
    children: [
      {
        name: 'timetables',
        component: ({ name, value, onChange }) => (
          <EventTimetable
            name={name}
            value={value}
            onChange={onChange}
          />
        ),
      },
    ],
  },
]

export const EventSettingTimetable = () => {
  const { event } = useContext(EventContext)

  const onPreSubmit = (__, values) => {
    const { timetables, description, ...restValues } = values ?? {}
    return _.omitBy(
      {
        ...restValues,
        timetables: timetables
          ? JSON.stringify(
              Array.from(timetables ?? [])
                .filter((item) => !!!item?.isInsert)
                .map((item) => ({
                  ...(item ?? {}),
                  speakers: Array.from(item?.speakers ?? []).map(
                    ({ id, ...rest }) => ({
                      speaker_id: id,
                      speaker_name: rest?.value,
                      ...rest,
                    })
                  ),
                }))
            )
          : undefined,
      },
      _.isUndefined
    )
  }

  const initialized = useCallback(
    ({ description, invisible_timeline, ...values }) => {
      let _timetables = []
      const timetables = Array.from(values.timetables ?? [])
      timetables.forEach(function (arrayItem, index) {
        _timetables.push(arrayItem)
        if (timetables.length - 2 === index) {
          _timetables.push({ isInsert: true, active: true })
        } else if (timetables.length - 2 > index) {
          _timetables.push({ isInsert: true })
        }
      })
      return {
        ...values,
        update: Date.now(),
        description: convertFromString(description),
        invisible_timeline: invisible_timeline ?? 0,
        timetables: _timetables.map((item) => ({
          ...item,
          speakers: Array.from(item?.speakers ?? []).map(
            ({ speaker, speaker_name }) => ({
              id: speaker?.id,
              _type: getType(speaker),
              value: speaker_name ?? getTitle(speaker),
              avatar: getAvatar(speaker),
            })
          ),
        })),
      }
    },
    [event.timetables, event.invisible_timeline]
  )

  return React.useMemo(
    () =>
      createEditableForm(
        formFields,
        formSchema
      )({
        item: event,
        query: bindQueryParam({
          id: getId(event),
        }),
        onPreSubmit,
        initialized,
        apiInfo: event_edit_Api,
        validationSchema: undefined,
      }),
    [event, initialized]
  )
}

export default EventSettingTimetable
