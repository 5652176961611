export const AntdConfigs = Object.freeze({
  GRID_BASED: 24,
})

const fullGrid = AntdConfigs.GRID_BASED

const getSpanSize = (value = 1) => {
  return fullGrid * value
}

const oneThirdGrid = Math.floor(getSpanSize(1 / 3))
const twoThirdGrid = Math.floor(getSpanSize(2 / 3))
const oneHalfGrid = Math.floor(getSpanSize(1 / 2))
const oneQuater = Math.floor(getSpanSize(1 / 4))
const twoQuater = Math.floor(getSpanSize(2 / 4))
const threeQuater = Math.floor(getSpanSize(3 / 4))

export {
  fullGrid,
  oneQuater,
  twoQuater,
  threeQuater,
  getSpanSize,
  oneHalfGrid,
  oneThirdGrid,
  twoThirdGrid,
}
