import {Avatar} from 'antd'
import classNames from 'classnames'
import {FollowButton} from 'components/Feed/FollowButton'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useContext} from 'react'
import {BiLinkAlt} from 'react-icons/bi'
import {IoSettingsOutline} from 'react-icons/io5'
import {Link} from 'react-router-dom'
import {nest} from 'recompose'
import {EntityContext, EntityProvider} from 'views/AppLocals/EntityList'
import {AppEntities} from 'views/AppLocals/enums'
import {ContextActionTypes, ItemContextMenu,} from 'views/AppLocals/factory/createContextMenu'
import {Null, renderIf} from 'views/Shared'
import {renderColon} from '../../../Shared'
import {OverlayItemBanner} from '../../Templates/ItemTemplate'
import Translate from "../../../../modules/local/Translate";
import {PiArrowRightBold} from "react-icons/pi";

export const EventCompactItem = ({item}) => {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)

  return (
    <div className="relative w-full border border-color-50 rounded-lg background">
      <OverlayItemBanner type={AppEntities.EVENT}/>
      <div className="h-7"/>
      <div className="flex items-center px-3 py-2">
        {isSm ? null : (
          <Avatar src={_.get(item, 'owner.avatar')}>
            <span className="capitalize text-color-100">
              {_.first(_.get(item, 'owner.title') || _.get(item, 'owner.name'))}
            </span>
          </Avatar>
        )}
        <div className="flex-col flex-1 mx-3">
          <Link
            to={getLinkToDetail(item)}
            className="cursor-pointer font-bold leading-tight max-lines-1">
            {getTitle(item)}
          </Link>
          <span className="text-xs text-secondary italic leading-tight max-lines-1 mt-1">
            {_.get(item, 'owner.name') || _.get(item, 'owner.title')}
          </span>
          <div className="flex items-center mt-2">
            <div className="flex flex-col flex-1 mr-3">
              <div className="flex space-x-1 text-xs italic">
                <span className="text-color-400">{`${t('type of event')} :`}</span>
                <span className="text-color-000 font-semibold">
                  {t(_.get(item, 'event_type'))}
                </span>
              </div>
              <div className="flex space-x-1 text-xs italic">
                <span className="text-color-400">{`${t('expires')} :`}</span>
                <span className="text-color-000 font-semibold">
                  {moment(_.get(item, 'time_open')).format(
                    'HH:MM - DD/MM/YYYY'
                  )}
                </span>
              </div>
            </div>
            <FollowButton
              size={isSm ? 'small' : 'default'}
              item={item}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const ItemHeader = ({
                      impactCategories,
                      withMenuOptions = (item) =>
                        [
                          {
                            key: ContextActionTypes.COPY,
                            label: 'copy path',
                            icon: <BiLinkAlt/>,
                          },
                          !!_.get(item, 'edit', false)
                            ? {
                              key: ContextActionTypes.SETTINGS,
                              label: 'setting',
                              icon: <IoSettingsOutline/>,
                            }
                            : undefined,
                        ].filter((e) => !_.isUndefined(e)),
                    }) => (
  <div className="absolute top-0 right-0 p-1">
    <EntityContext.Consumer>
      {({item, handleAction}) => (
        <ItemContextMenu
          item={item}
          onMenuSelect={handleAction}
          items={withMenuOptions(item)}
        />
      )}
    </EntityContext.Consumer>
  </div>
)

const DefaultHyperLink = ({title, pathname, className, onClick = Null}) => {
  const t = useTranslate()
  return (
    <Link
      to={pathname}
      onClick={onClick}
      className={classNames(
        'font-bold no-underline max-lines-1 leading-tight text-color-000 ',
        className
      )}>
      {t(title || 'no title')}
    </Link>
  )
}

function renderIntervalWeekly(value) {
  let label = ''
  switch (value) {
    case '1000000':
      label = 'monday'
      break
    case '0100000':
      label = 'tuesday'
      break
    case '0010000':
      label = 'wednesday'
      break
    case '0001000':
      label = 'thursday'
      break
    case '0000100':
      label = 'friday'
      break
    case '0000010':
      label = 'saturday'
      break
    case '0000001':
      label = 'saturday'
      break
    default :
      label = null
      break
  }
  return label ? label : null
}

function renderInterval(value) {
  let label
  switch (value) {
    case 'D':
      label = 'daily'
      break
    case 'W':
      label = 'weekly'
      break
    case 'M':
      label = 'monthly'
      break
    default :
      label = 'do not repeat'
      break
  }
  return (
    <Translate>
      {(t) => t(label)}
    </Translate>
  )
}

const EventItem = ({item}) => {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)

  const {time_open, time_close, description, event_type, interval_type, interval_daily, is_expired} = item ?? {}
  const now = moment()

  const renderLabelTime = (value) => {
    let label
    if (interval_type === 'D') {
      label = moment(value).format('HH:mm')
    } else if (interval_type === 'W') {
      label = [
        moment(value).format('HH:mm'),
        t(renderIntervalWeekly(interval_daily))
      ].join(' - ')
    } else if (interval_type === 'M') {
      label = [
        moment(value).format('HH:mm'),
        '-',
        t('date'),
        moment(value).format('DD')
      ].join(' ')
    } else {
      label = moment(value).format('HH:mm - DD/MM/YYYY')
    }
    return (
      <span className="flex items-center gap-1">
                {label}
        {renderIf(
          _.get(item, 'time_close'),
          <span className="flex items-center font-bold text-color-100">
                        <PiArrowRightBold className="text-color-500 mx-1"/>
            {moment(time_close).format('HH:mm - DD/MM/YYYY')}
                    </span>
        )}
        <span className="ml-2 text-green-600 italic">
                    ( {renderInterval(interval_type)} )
                </span>
            </span>
    )
  }

  return (
    <div className="flex items-center gap-3 p-2 border border-color-50 rounded-lg hover:shadow-items relative">
      <ItemHeader/>
      <div className="flex flex-col flex-1 gap-1">
        <DefaultHyperLink
          pathname={getLinkToDetail(item)}
          title={getTitle(item)}
        />

        <div className="w-full flex gap-1">
          <div className="flex flex-col flex-1 gap-1">
            <div className="flex items-center gap-1 text-xs italic">
                            <span className="text-color-500">
                                {t('type of event')}{renderColon()}
                            </span>
              <span className="font-bold text-color-100">
                                {t(event_type)}
                            </span>
            </div>
            <div className="flex flex-col md:flex-row text-xs italic">
                            <span className="text-color-500">
                                {t('time takes place')}{renderColon()}
                            </span>
              <span className="font-bold text-xs ml-2">
                                {renderLabelTime(time_open)}
                            </span>
            </div>
          </div>
          <div className="m-2 font-semibold text-xs italic text-right absolute bottom-0 right-0">
            {is_expired === true ? (
              <span className="text-red-600">{t('close')}</span>
            ) : moment(time_open).isAfter(moment()) ? (
              <span className="text-green-700">{t('upcoming')}</span>
            ) : (
              <span className="text-blue-700">{t('opening')}</span>
            )}
          </div>
        </div>

        <div className="text-xs text-color-300 italic">
          {description}
        </div>
      </div>
    </div>
  )
}

export default nest(EntityProvider, EventItem)
