import {DownloadOutlined, PlusOutlined} from '@ant-design/icons'
import {Button, Col, Input, Row, Select} from 'antd'
import FieldDecorator from 'components/form/FieldDecorator'
import FormActionBar from 'components/form/FormActionBar'
import {createValue} from 'components/form/utils'
import Toggle from 'components/Toggle'
import {useFormikContext} from 'formik'
import _ from 'lodash'
import {useAppConfig} from 'modules/local'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {AiFillDelete} from "react-icons/ai";
import {SelectCategories} from "./SelectCategories";
import logFunc from "../../../helpers/logFunc";
import {AsyncWithCache} from "../../../modules/asyncCache/components/Async";
import {DelayRender} from "../../Discovery/DelayRender";
import {ProductContext} from "./ProductContainer";
import {API_ROOT_URL} from "../../../envs/_current/config";
import useAsyncWithCache from "../../../modules/asyncCache/useAsyncWithCache";
import LookupField from "../../../components/LookupField";
import UseState from "../../../components/UseState";
import {BsTrash3} from "react-icons/bs";

const {Option, OptGroup} = Select

export const ProductDetailForm = ({
                                      submitText
                                  }) => {
    const {id, owner = {}} = useContext(
        ProductContext
    )
    const t = useTranslate()
    const {
        values,
        errors,
        handleChange,
        ...rest
    } = useFormikContext()
    const appConfig = useAppConfig()
    const {
        product_units = [],
        product_price_units = [],
        product_categories = []
    } = appConfig ||
    {
        product_units: [],
        product_price_units: [],
        product_categories: []
    }

    const {
        success,
        result,
        action,
        isLoading,
        response,
        handleDispatchAsyncAction
    } = useAsyncWithCache({
        cacheId: `/lookup/factory/gets?keyword=${values.factory_keyword}`,
        apiInfo: {
            root: API_ROOT_URL,
            path: `/lookup/factory/gets?keyword=${values.factory_keyword}`,
            method: 'GET',
        },
        doOnMount: true,
    });
    const factory_options = _.uniq(
        _.filter(
            _.get(
                response,
                'data.data',
                []
            )
            , function (o) {
                return o.id !== '';
            }).map(
            ({name}) =>
                name
        )
    );


    return (
        <div className="flex flex-col gap-3">
            <div className="w-full space-y-3">
                <FieldDecorator
                    name="categories"
                    label={
                        <div className="flex items-baseline gap-1 mb-1">
                <span className="capitalize text-sm font-semibold text-color-300">
                  {t('categories')}
                </span>
                            <span className="font-normal text-2xs text-color-500 italic">
                  ({t('for search optimization')})
                </span>
                        </div>
                    }>
                    <SelectCategories
                        type="product"
                        categoryOptions={[...values.categoryOptions, ...product_categories]}
                        selected={values.categories}
                        onChange={logFunc(value => handleChange(createValue('categories', value)))}
                    />
                </FieldDecorator>

                <FieldDecorator
                    name="product_catalog_title"
                    label={
                        <div className="flex items-baseline gap-1 mb-1">
                    <span className="capitalize text-sm font-semibold text-color-300">
                        {t('product catalog')}
                    </span>
                            <span className="font-normal text-2xs text-color-500 italic">
                        ({t('to optimize product display')})
                    </span>
                        </div>
                    }>
                    <AsyncWithCache
                        cacheId={`/products/${owner._type}/${owner.idname}/get-product-catalogs`}
                        apiInfo={{
                            root: API_ROOT_URL,
                            path: `/products/${owner._type}/${owner.idname}/get-product-catalogs`,
                            method: 'GET'
                        }}>
                        {({
                              response,
                              isLoading,
                              handleDispatchAsyncAction
                          }) => {
                            const options = _.uniq(
                                _.filter(
                                    _.get(
                                        response,
                                        'data.data',
                                        []
                                    )
                                    , function (o) {
                                        return o.id !== '';
                                    }).map(
                                    ({title}) =>
                                        title
                                )
                            )
                            return (
                                <Select
                                    allowClear
                                    loading={isLoading}
                                    value={values.product_catalog_title || ''}
                                    onChange={value => {
                                        handleChange(
                                            createValue(
                                                'product_catalog_title',
                                                value || ''
                                            )
                                        )
                                        handleChange(
                                            createValue(
                                                'group_keyword',
                                                ''
                                            )
                                        )
                                    }}
                                    onFocus={() => handleDispatchAsyncAction()}
                                    showSearch
                                    className="w-full"
                                    placeholder={t('select / create a group')}
                                    onSearch={value => {
                                        handleChange(
                                            createValue(
                                                'group_keyword',
                                                value
                                            )
                                        )
                                    }}
                                    dropdownRender={menu => (
                                        <DelayRender>
                                            {menu}
                                        </DelayRender>
                                    )}>
                                    {!!(values.group_keyword && values.group_keyword.length) && (
                                        <Option
                                            key="@new group"
                                            value={
                                                values.group_keyword
                                            }>
                                            <PlusOutlined/>{' '}
                                            {t(
                                                'new group'
                                            )}{' '}
                                            <b>
                                                {values.group_keyword
                                                }
                                            </b>
                                        </Option>
                                    )}

                                    <Option
                                        key="default"
                                        className="font-semibold text-gray-500 italic"
                                        title={t('unclassified products')}
                                        value={""}>
                                        {t('unclassified products')}
                                    </Option>

                                    <OptGroup label={t('used group')}>
                                        {options.map(
                                            o => (
                                                <Option
                                                    key={o}
                                                    value={o}>
                                                    {o}
                                                </Option>
                                            )
                                        )}
                                    </OptGroup>
                                </Select>
                            )
                        }}
                    </AsyncWithCache>
                </FieldDecorator>

                <FieldDecorator
                    name="product_line"
                    label={
                        <div className="flex items-baseline gap-1 mb-1">
                            <span className="capitalize text-sm font-semibold text-color-300">
                              {t('product line')}
                            </span>
                            <span className="font-normal text-2xs text-color-500 italic">
                              ({t('to group products with the same properties, compare prices')})
                            </span>
                        </div>
                    }>
                    <LookupField
                        defaultValue={
                            values.product_line ||
                            ''
                        }
                        name={"product_line"}
                        handleChange={handleChange}
                    />
                </FieldDecorator>

                <FieldDecorator
                    name="factory"
                    labelProps={{className: 'capitalize'}}
                    label={t('factory')}>
                    <LookupField
                        defaultValue={
                            values.factory ||
                            ''
                        }
                        name={"factory"}
                        handleChange={handleChange}
                    />
                </FieldDecorator>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                    <FieldDecorator
                        name="sku"
                        errors={errors.sku}
                        labelProps={{className: 'capitalize'}}
                        label={t('sku')}>
                        <Input
                            name="sku"
                            defaultValue={values.sku}
                            onChange={handleChange}
                        />
                    </FieldDecorator>

                    <FieldDecorator
                        name="trademark"
                        errors={errors.trademark}
                        labelProps={{className: 'capitalize'}}
                        label={t('trademark')}>
                        <Input
                            name="trademark"
                            defaultValue={values.trademark}
                            onChange={handleChange}
                        />
                    </FieldDecorator>
                </div>

                <FieldDecorator
                    name="use_for"
                    labelProps={{className: 'capitalize'}}
                    label={t('use for')}>
                    <UseState initialValue={{value: (_.get(values, 'use_for', '') || '').split(",").filter(e => !!e)}}>
                        {([
                              localState,
                              setState
                          ]) => {
                            return (
                                <Select
                                    showSearch
                                    optionFilterProp={"label"}
                                    value={localState.value}
                                    mode={"multiple"}
                                    onSearch={value => {
                                        setState({
                                            ...localState,
                                            customOption: value && value.length ? {
                                                value: value,
                                                label: `${t('create option') + ': ' + value}`
                                            } : null
                                        })
                                    }}
                                    placeholder={t('select / create')}
                                    className="block"
                                    onChange={(__, option) => {

                                        setState({
                                            ...localState,
                                            value: option.map(e => e.value)
                                        });

                                        handleChange(
                                            createValue(
                                                'use_for',
                                                option.map(e => e.value).join(",")
                                            )
                                        )
                                    }}
                                    options={
                                        _.uniqBy(
                                            [
                                                {...localState.customOption},
                                                ...[...(_.get(values, 'use_for', '') || '').split(",").filter(e => !!e)
                                                ].map(e => {
                                                    return {
                                                        label: e, value: e
                                                    }
                                                })
                                            ], 'value'
                                        )
                                    }

                                />
                            )
                        }}
                    </UseState>
                </FieldDecorator>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                    <FieldDecorator
                        name="min_order_qtty"
                        errors={errors.min_order_qtty}
                        labelProps={{className: 'capitalize'}}
                        label={t('min_order_qtty')}>
                        <Input
                            name="min_order_qtty"
                            defaultValue={
                                values.min_order_qtty
                            }
                            type="number"
                            min="0"
                            onChange={handleChange}
                        />
                    </FieldDecorator>
                    <FieldDecorator
                        name="production_capacity"
                        errors={errors.production_capacity}
                        labelProps={{className: 'capitalize'}}
                        label={t('production_capacity')}>
                        <Input
                            name="production_capacity"
                            defaultValue={
                                values.production_capacity
                            }
                            onChange={handleChange}
                        />
                    </FieldDecorator>
                </div>

                <FieldDecorator
                    name="packaging_details"
                    errors={errors.packaging_details}
                    labelProps={{className: 'capitalize'}}
                    label={t('packaging_details')}>
                    <Input.TextArea
                        rows={4}
                        name="packaging_details"
                        defaultValue={
                            values.packaging_details
                        }
                        maxLength={5000}
                        onChange={handleChange}
                        allowClear
                    />
                </FieldDecorator>

                <FieldDecorator
                    name="document (PDF)"
                    labelProps={{className: 'capitalize'}}
                    label={t('document (PDF)')}>
                    <Toggle>
                        {(isToggle, toggle) => (
                            <div className="flex items-center gap-4">
                                {!isToggle && Boolean(values.pdf_path) && (
                                    <div className="flex items-center gap-2">
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={values.pdf_path}
                                            download>
                                            <Button className="button-rounded-md">
                                                {t('download')}
                                            </Button>
                                        </a>
                                        <BsTrash3
                                            size={20}
                                            onClick={() => {handleChange(createValue('pdf_path', ""))}}
                                            className="text-red-500 hover:text-red-600 cursor-pointer"
                                        />
                                    </div>
                                )}
                                {Boolean(_.get(values, 'upload_file.name')) && (
                                    <label className="font-semibold text-sm text-primary italic">
                                        {_.get(values, 'upload_file.name')}
                                    </label>
                                )}
                                <label
                                    hidden={isToggle}
                                    className="block"
                                    htmlFor="upload_file">
                                    <Button
                                        onClick={toggle}
                                        className="w-40 button-rounded-md no-border"
                                        type="primary">
                                        {t('choose file')}
                                    </Button>
                                </label>
                                <input
                                    id="upload_file"
                                    className="ml-0"
                                    hidden={!isToggle}
                                    name="upload_file"
                                    onChange={e => {
                                        handleChange(
                                            createValue(
                                                'upload_file',
                                                e.target
                                                    .files[0]
                                            )
                                        )
                                        toggle()
                                    }}
                                    type="file"
                                    accept=".pdf"
                                />
                            </div>
                        )}
                    </Toggle>
                </FieldDecorator>
            </div>
            <FormActionBar
                className="w-full"
                submitText={submitText}
            />
        </div>
    )
}


function ProductDetailInfo() {
    return (
        <ProductDetailForm/>
    )
}

export default ProductDetailInfo
