import {Modal} from 'antd'
import {ChannelIcon} from 'components/icons/ChannelIcon'
import {WithDetailPopup} from 'components/WithDetailPopup'
import {createAliasTransform} from 'helpers/createAliasTransform'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {Null} from 'views/Shared'
import {
  DetailFeedContent,
  FeedContent,
  FeedContentLayout,
  FeedContentMeta,
  FeedFooter,
  FeedWrapper,
  withFeedContext,
} from './FeedCard'
import FeedContainerStateless from './FeedContainerStateless'
import FeedContext from './FeedContext'
import {FeedProvider} from './FeedProvider'
import {PiTrash} from "react-icons/pi";
import {HiOutlineTrash} from "react-icons/hi2";

const alias = {
  creatorId: ['creator.username', 'creator.idname', 'creator.id'],
  photos: 'photos',
  embed_data: 'embed_data',
  content_data: 'content_data',
  created: 'created',
  creatorType: 'creator._type',
  creatorName: 'creator.name',
  title: ['title', 'name'],
  description: 'description',
  cover: 'cover_photo',
  id: 'id',
  type: '_type',
}

export const transformAlias = createAliasTransform(alias)
export const allActions = {
  to: true,
  pin: true,
  unpin: true,
  bookmark: true,
  vote: true,
  voteUp: false,
  voteDown: false,
  message: true,
  view: true,
  share: true,
  copy_link: true,
  edit: true,
  delete: true,
}
const Container = withFeedContext(FeedContainerStateless)

export const defaultComponents = {
  Container,
  Wrapper: FeedWrapper,
  ContentLayout: FeedContentLayout,
  ContentMeta: FeedContentMeta,
  Content: FeedContent,
  Footer: FeedFooter,
}

export const defaultDetailComponents = {
  Container,
  Wrapper: FeedWrapper,
  ContentLayout: FeedContentLayout,
  ContentMeta: FeedContentMeta,
  Content: DetailFeedContent,
  Footer: FeedFooter,
}


const renderDeleted = (
  item,
  {Wrapper, ContentLayout, ContentMeta, Content, Footer}
) => {
  return null
}

export const showConfirm = ({
    width = 540,
    icon = <HiOutlineTrash className="anticon"/>,
    title = 'Are you sure delete this task?',
    content,
    okText = 'yes',
    cancelText = 'no',
    onOk = () => {
    console.log('OK')
    },
    onCancel = () => {
    console.log('Cancel')
    },
    ...props
} = {}) =>
  Modal.confirm({
      width,
    icon,
    title,
    content,
    okText,
    cancelText,
    onOk,
    onCancel,
    ...props,
  })
export const createTimeLineFeed = (
  customComponents = {},
  defaultActions = allActions
) => {
  const components = {
    ...defaultComponents,
    ...customComponents,
  }
  const {
    Footer = Null,
    Wrapper = Null,
    Content = Null,
    ContentMeta = Null,
    ContentLayout = Null,
  } = components

  return function TimelineFeed({item, detail = false, children}) {
    if (!item) return null

    return (
      <FeedProvider
        item={item}
        detail={detail}
        defaultActions={defaultActions}>
        {children ? (
          children
        ) : item.isDeleted || item.deleted ? (
          renderDeleted(item, components)
        ) : (
          <Wrapper>
            <ContentLayout>
              <ContentMeta/>
              <Content/>
              <Footer/>
            </ContentLayout>
          </Wrapper>
        )}
      </FeedProvider>
    )
  }
}


export const DetailFeed = createTimeLineFeed(defaultDetailComponents, {
  ...allActions,
  to: false,
  message: false,
})

export const FeedCard = createTimeLineFeed(
  {
    Container: () => {
      const {item} = useContext(FeedContext)
      return (
        <div className="mb-1 text-color-300 flex items-center justify-start">
          <ChannelIcon
            premium={_.get(item, 'container.premium', 0)}
            className="mr-2 text-color-100 text-3xl w-8 h-8"
          />
          <Link
            to={getLinkToDetail(item.container)}
            className="cursor-pointer hover:underline">
            {_.get(item, 'container.title') || _.get(item, 'container.name')}
          </Link>
        </div>
      )
    },
    Content: () => <FeedContent/>,
    ContentLayout: ({style, children}) => {
      return (
        <div
          style={style}
          className="background-100 border border-color-50 hover:border-color-base rounded-lg FeedCardContent verticalList__small">
          {children}
        </div>
      )
    },
  },
  {
    ...allActions,
    bookmark: true,
    // pin: false,
    // unpin: false,
  }
)

export const EventFeed = createTimeLineFeed({
  Container: Null,
})

export const ChannelFeed = createTimeLineFeed({
  Container: Null,
})

export const ChannelNewsFeed = createTimeLineFeed({
  Container: () => (
    <FeedContext.Consumer>
      {({item}) => (
        <WithDetailPopup
          item={item.container}
          id={item.container}
          style={{
            textOverflow: 'ellipsis',
          }}>
          <span className=" font-bold flex items-center whitespace-no-wrap mr-2 cursor-pointer hover:underline">
            <span className="mr-2">
              <ChannelIcon
                className="mr-2  text-3xl w-8 h-8"
                premium={_.get(item, 'container.premium', 0)}
              />
            </span>
            <span className="truncate flex-1">{item.container.title}</span>
          </span>
        </WithDetailPopup>
      )}
    </FeedContext.Consumer>
  ),
  ContentLayout: (props) => <FeedContentLayout {...props} />,
})

export const CategoryNewsFeed = createTimeLineFeed({
  Container: () => (
    <FeedContext.Consumer>
      {({item}) => (
        <WithDetailPopup
          item={item.container}
          id={item.container}
          style={{
            textOverflow: 'ellipsis',
          }}>
          <span className=" font-bold flex items-center whitespace-no-wrap mr-2 cursor-pointer hover:underline">
            <span className="mr-2">
              <ChannelIcon
                className="mr-2 text-color-100 text-3xl w-8 h-8"
                premium={_.get(item, 'container.premium', 0)}
              />
            </span>
            <span className="truncate flex-1">{item.container.title}</span>
          </span>
        </WithDetailPopup>
      )}
    </FeedContext.Consumer>
  ),
  ContentLayout: (props) => <FeedContentLayout {...props} />,
})
const TimelineFeed = createTimeLineFeed()

export default TimelineFeed
