import classNames from 'classnames'
import Description from 'components/Feed/Description'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {
  useContext
} from 'react'
import { FcStatistics } from 'react-icons/fc'
import { Link } from 'react-router-dom'
import ChartContext from 'views/Chart/ChartContext'
import { ChartActions } from 'views/Chart/components/ChartActions'
import { Copyright } from 'views/Chart/components/Copyright'

const ChartWrapper = ({
  children,
  header,
  className,
  hideTitle,
  hidenote,
  hideIcon,
  hideactionbar,
  hidedescription,
  hideshowmore,
  ...props
}) => {
  const { item } = useContext(
    ChartContext
  )
  const t = useTranslate()

  if (!!!item) {
    return <>{children}</>
  }

  return (
    <div
      className={classNames(
        'verticalList flex flex-col w-full',
        className
      )}
      {...props}>
      <div className="relative">
        {!!!hideTitle && (
          <h2 className="flex p-1">
            <FcStatistics className="mr-3 text-2xl w-7 h-7" />
            <Link
              to={`/chart/${item.idname}`}
              className="font-bold text-sm md:text-lg text-primary leading-snug">
              {item.title}
            </Link>
          </h2>
        )}
        {!!!Boolean(
          hidedescription
        ) && (
          <Description type="dataset">
            {item.description}
          </Description>
        )}
      </div>
      <div className="flex-1 flex flex-col">
        {header && header}
        <div
          style={{
            minHeight:
              _.get(
                item,
                'chart_type'
              ) === 'Table'
                ? 'auto'
                : 360,
            height:
              _.get(
                item,
                'chart_type'
              ) === 'Table'
                ? 'auto'
                : 0
          }}
          className="flex flex-col">
          {children}
        </div>
      </div>
      <Copyright />
      {!!!hideshowmore &&
        item &&
        item.dataset &&
        item.dataset.idname && (
          <Link
            to={`/data/${_.get(
              item,
              'dataset.idname'
            )}`}
            className="p-3 uppercase text-center font-bold border rounded-md items-center justify-center text-blue-600 ">
            {t(
              'See all charts in the data'
            )}
          </Link>
        )}

      {!!!hideactionbar && (
        <>
          <div className="border-b border-gray-300">
            <div />
          </div>
          <ChartActions />
        </>
      )}
    </div>
  )
}

export default ChartWrapper
