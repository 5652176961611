import _ from 'lodash'
import {fromObject} from 'views/Shared'
import CRUD from 'views/Shared/enums/CRUD'
import {AppEntities} from '../enums'

const baseFieldValues = {
  privacy: 1,
  title: undefined,
  owner: undefined,
}

const entities = {
  [AppEntities.ORGANIZATION]: {
    [CRUD.CREATE]: {
      ...baseFieldValues,
    },
  },
}

const byAction = (source, action) => _.get(source, action, {})

const createInitialValues = (type, action = CRUD.CREATE, params) => {
  switch (type) {
    case AppEntities.ORGANIZATION:
    default:
      return {
        ...byAction(_.get(entities, type), action),
        ...params,
      }
  }
}

export const asArray = (input) => (input ? [...input] : [])

export const asObject = (input) => (input ? {...input} : {})

export const fromInitialValues = (
  [source, fields = []],
  transform = asArray
) => {
  const initialValues = _.get(source, 'initialValues')

  if (_.isEmpty(initialValues)) {
    transform()
  }

  const _fields = fields.map((item) =>
    _.isString(item)
      ? {name: item}
      : {
        ...item,
        name: _.get(item, 'name', String(item)),
      }
  )

  const result = fromObject(initialValues, _fields)

  const array = _fields.map(({name}) => ({
    name,
    value: result[name],
  }))

  return transform(array)
}

export default createInitialValues
