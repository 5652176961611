import {FollowButton} from 'components/Feed/FollowButton'
import _ from 'lodash'
import React from 'react'
import {Link} from 'react-router-dom'
import Translate from "../../../modules/local/Translate";
import {LoginContext} from 'components/LoginContext'
import {datasetModel} from "../../../apis/model";
import {FiRss} from "react-icons/fi";
import numeral from "numeral";
import AppIcon from "../../../components/AppIcon";

export const renderDatasetItem = e => {

  return (
    <Translate>
      {t => (
        <div
          key={e.id}
          className="p-2 flex items-center gap-2 border border-color-50 hover:shadow-items-md rounded-md">
          <AppIcon item={e} />
          <div className="flex flex-col gap-1 flex-1">
            <Link
              to={datasetModel.getLinkToDetail(
                e
              )}
              className="font-bold text-sm text-color-000 no-underline leading-snug max-lines-1">
              {e.title}
            </Link>
            <div className="flex space-x-3">
              <div className="flex flex-1 flex-row space-x-1 items-center truncate">
                {!!_.get(e, 'selected_by_editor') && (
                    <span className="text-xs text-color-200 background-200 rounded-full px-2 py-0 m-0 h-5">
                      {t("editor's choice")}
                    </span>
                )}
                <div className="text-xs flex items-center justify-center">
                  {e.follow_status ? (
                    <FiRss className="text-primary-600" />
                  ) : (
                    <FiRss className="text-color-500" />
                  )}
                  {(e.total_followers && e.total_followers > 0) && (
                      <span className="px-1 font-bold text-color-100">{numeral(e.total_followers).format()}</span>
                  )}
                </div>
              </div>
              <LoginContext.Consumer>
                {login => {
                  return (
                    login &&
                    !!!_.get(e, 'follow_status') && (
                      <FollowButton  item={e} className="text-xs"/>
                    )
                  )
                }}
              </LoginContext.Consumer>
            </div>
          </div>
        </div>
      )}
    </Translate>
  )
}
