import _ from 'lodash'
import React from 'react'
import {renderSelf} from 'views/Shared'
import {errorNotify} from './createNotification'

const onError = (
  error,
  errorObj = {
    error_code: 'System error!',
    error: '-1',
    message: 'System error!',
  },
  translate = renderSelf
) => {
  const getErrors = (errors) => {
    if (typeof errors === 'object') {
      const array = Object.keys(errors || [])
      return (
        <ul>
          {array.map((name, index) => (
            <li key={index}>{_.get(errors, name)}</li>
          ))}
        </ul>
      )
    } else {
      return JSON.stringify(errors)
    }
  }
  errorNotify(translate, {
    message:
      typeof errorObj.error_code === 'string'
        ? errorObj.error_code
        : JSON.stringify(errorObj.error_code || errorObj.message),
    description:
      typeof errorObj.error === 'string'
        ? errorObj.error
        : getErrors(errorObj.error),
  })
}

export const notifyOnError = (translate) => (error, errorObj) =>
  onError(error, errorObj, translate)
