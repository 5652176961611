import {schema} from 'normalizr'
import {commentSchema} from './commentSchema'
import messageSchema from './messageSchema'

export const socketUnionSchema = new schema.Union(
  {
    comment: commentSchema,
    message: messageSchema,
  },
  '_type'
)
