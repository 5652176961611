import {branch, compose, fromRenderProps, nest, pure, renderNothing, withProps,} from 'recompose'
import {DelayRender} from 'views/Discovery/DelayRender'
import Icon from '@ant-design/icons/es'
import {organizationPermissionCodes} from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {useCallback} from 'react'
import {createCheckPermissions, invalidPermissions,} from 'views/AppLocals/factory/createCheckPermissions'
import {SettingsMenu} from 'views/AppLocals/factory/createSettingLayout'
import DefaultTemplate from 'views/AppLocals/Templates/DefaultTemplate'
import {renderFalse, renderOwnChild} from 'views/Shared'
import CustomSiderMenu from 'views/Shared/components/CustomSiderMenu'
import {Queries} from 'views/Shared/enums/Queries'
import {useMenuHandler} from 'views/Shared/handlers/useMenuHandler'
import {CoHostList} from './WorkspaceCoHostList'
import WorkspaceContext from "../WorkspaceContext";
import Members from "../../MemberSetting/Members.lazy";
import WaitingAcceptMembers from "../../MemberSetting/WaitingAcceptMembers.lazy";
import InviteManager from "../../Organization/Settings/InviteManager";
import {getId} from 'apis/model/base'
import TeamOutlined from "@ant-design/icons/lib/icons/TeamOutlined";
import {RiMailSendLine, RiUserAddLine, RiUserLine} from "react-icons/ri";
import {LuMailPlus} from "react-icons/lu";
import {BsEnvelopeAt, BsPerson, BsPersonCheck, BsPersonPlus} from "react-icons/bs";

const ItemKeys = Object.freeze({
  MEMBERS: 'members',
  WAITING_MEMBERS: 'waiting-members',
  INVITES: 'invites',
});

const WorkspaceMembersList = compose(
  fromRenderProps(WorkspaceContext.Consumer, ({organization}) => ({
    container: organization,
  })),
  branch(({container}) => !!!getId(container), renderNothing),
  pure
)(
  nest(
    ({container, children}) => (
      <Members
        item={container}>
      </Members>
    ),
    CoHostList
  )
)

const WorkspaceMembersWaitingList = compose(
  fromRenderProps(WorkspaceContext.Consumer, ({organization}) => ({
    container: organization,
  })),
  branch(({container}) => !!!getId(container), renderNothing),
  pure
)(
  nest(
    ({container, children}) => (
      <WaitingAcceptMembers
        item={container}>
      </WaitingAcceptMembers>
    ),
    CoHostList
  )
)

const WorkspaceMembersInvites = compose(
  fromRenderProps(WorkspaceContext.Consumer, ({organization}) => ({
    container: organization,
  })),
  branch(({container}) => !!!getId(container), renderNothing),
  pure
)(
  nest(
    ({container, children}) => (
      <InviteManager
        item={container}>
      </InviteManager>
    ),
    CoHostList
  )
)

const with_menu_items = ({isDisabled = renderFalse}) => [
  {
    key: ItemKeys.MEMBERS,
    label: 'members',
    icon: (
      <BsPerson size={16} />
    ),
  },
  {
    key: ItemKeys.WAITING_MEMBERS,
    label: 'waiting members',
    icon: (
      <BsPersonPlus size={16} />
    ),
  },
  {
    key: ItemKeys.INVITES,
    label: 'invites',
    icon: (
      <BsEnvelopeAt size={16} />
    ),
  },
]

const routeEntities = [
  {
    key: ItemKeys.MEMBERS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(WorkspaceMembersList),
  },
  {
    key: ItemKeys.WAITING_MEMBERS,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(WorkspaceMembersWaitingList),
  },
  {
    key: ItemKeys.INVITES,
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(WorkspaceMembersInvites),
  },
].map(({key, ...rest}) => ({
  key,
  path: key,
  ...rest,
}))

const WorkspaceMemberContent = () => {
  const {activeKey, Component, onMenuSelect} = useMenuHandler({
    routes: routeEntities,
    actionName: Queries.REL,
  })

  const renderMenu = useCallback(
    () => (
      <CustomSiderMenu
        deps={[activeKey]}
        Wrapper={renderOwnChild}>
        <PermissionContext.Consumer>
          {(permissions) => (
            <SettingsMenu
              items={with_menu_items({
                isDisabled: invalidPermissions(permissions),
              })}
              onSelect={onMenuSelect}
              defaultSelectedKeys={[activeKey]}
              defaultOpenKeys={[
                ItemKeys.MEMBERS
              ]}
            />
          )}
        </PermissionContext.Consumer>
      </CustomSiderMenu>
    ),
    [with_menu_items, activeKey]
  )

  return (
    <DefaultTemplate renderMenu={renderMenu}>
      <div className="px-3 lg:pr-3">
        <Component/>
      </div>
    </DefaultTemplate>
  )
}

export default nest(
  withProps({lazy: true})(
    DelayRender
  ),
  WorkspaceMemberContent
)
