import {API_ROOT_URL} from 'envs/_current/config'
import {createAsyncAction} from 'modules/asyncCache'
import {schema} from 'normalizr'
import {baseFieldTypes} from './base/baseFieldTypes'
import {createListTransform} from './utils/createListTransform'
import {createTransform} from './utils/createTransform'

export const locationSchema = new schema.Entity(
  'locations',
  {},
  {
    idAttribute: 'id',
  }
)
export const locationFields = [
  'address',
  'country_id',
  'province_id',
  'district_id',
  'ward_id',
  'static_map_url', //maps
  'longitude', //maps
  'latitude', //maps
  'orders',
]
const fieldTypes = {
  address: baseFieldTypes.string,
  country_id: baseFieldTypes.string,
  province_id: baseFieldTypes.string,
  district_id: baseFieldTypes.string,
  ward_id: baseFieldTypes.string,
  static_map_url: baseFieldTypes.string, //maps
  longitude: baseFieldTypes.string, //maps
  latitude: baseFieldTypes.string, //maps
  orders: baseFieldTypes.string,
}
// Route::post('locations/location/{prop}/{id}/add', 'LocationController@addLocation');
export const location_addLocation_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/locations/location/:type/:id/add',
  method: 'POST',
  fields: locationFields,
  fieldTypes,
  schema: locationSchema,
  transform: createTransform(locationSchema, 'data'),
  // transform: (res, { query }) => {
  //   if (res.status === 200) {
  //     const _type = query[':type']
  //     const idname = query[':id']
  //     const newOrganization = {
  //       idname,
  //       locations: [res.data],
  //       _type
  //     }
  //     const {
  //       entities,
  //       result
  //     } = normalize(
  //       newOrganization,
  //       organizationSchema
  //     )
  //     return {
  //       status: 200,
  //       success: true,
  //       entities,
  //       result
  //     }
  //   }

  //   return {
  //     error: true,
  //     errorMessages: res.data
  //   }
  // }
}

// Route::post('locations/location/{prop}/{id}/{location_id}/edit', 'LocationController@updateLocation');
export const location_updateLocation_Api = {
  backend:
    "Route::post('locations/location/{prop}/{id}/{location_id}/edit', 'LocationController@updateLocation')",
  root: API_ROOT_URL,
  path: '/locations/location/:type/:id/:location_id/edit',
  method: 'POST',
  fields: locationFields,
  fieldTypes,
  schema: locationSchema,
  transform: createTransform(locationSchema, 'data'),
  // transform: (res, { query }) => {
  //   if (res.status === 200) {
  //     const _type = query[':type']
  //     const idname = query[':id']
  //     const newOrganization = {
  //       idname,
  //       locations: [res.data],
  //       _type
  //     }
  //     const {
  //       entities,
  //       result
  //     } = normalize(
  //       newOrganization,
  //       organizationSchema
  //     )
  //     return {
  //       status: 200,
  //       success: true,
  //       entities,
  //       result
  //     }
  //   }
  //   return {
  //     error: true,
  //     errorMessages: res.data
  //   }
  // }
}

// Route::post('locations/location/{prop}/{id}/{location_id}/delete', 'LocationController@deleteLocation');
export const location_deleteLocation_Api = {
  backend:
    "Route::post('locations/location/{type}/{id}/{location_id}/delete', 'LocationController@deleteLocation')",
  root: API_ROOT_URL,
  path: '/locations/location/:type/:id/:location_id/delete',
  method: 'POST',
  fields: locationFields,
  fieldTypes,
  schema: locationSchema,
  transform: createTransform(locationSchema, 'data'),
  // transform: (response, { query }) => {
  //   return {
  //     response,
  //     entities: {
  //       locations: {
  //         [query[':location_id']]: {
  //           isDeleted: true,
  //           update: Date.now()
  //         }
  //       }
  //     }
  //   }
  // }
}

// Route::post('locations/location/{prop}/{id}/{location_id}/set-default', 'LocationController@setDefaultLocation');

export const location_getStaticCountries_Api = {
  backend: `// Route::get('locations/get-static/countries', 'LocationController@getStaticCountries');`,
  root: API_ROOT_URL,
  path: '/locations/get-static/countries',
  method: 'GET',
}
export const create_location_getStaticCountries_Api_action =
  () =>
    ({
       prefixStr = 'location_getStaticCountries_Api',
       query,
       values,
       maxAge = -1,
     }) =>
      createAsyncAction({
        prefixStr: prefixStr + '@_getStaticCountries_Api',
        query,
        values,
        apiInfo: location_getStaticCountries_Api,
        maxAge,
      })
// Route::get('locations/get-static/countries/{id}', 'LocationController@getStaticCountryById');
export const location_getStaticCountryById_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/locations/get-static/countries/:id',
  method: 'GET',
}
export const create_location_getStaticCountryById_Api_action =
  () =>
    ({
       prefixStr = 'location_getStaticCountryById_Api',
       query,
       values,
       maxAge = -1,
     }) =>
      createAsyncAction({
        prefixStr: prefixStr + '@location_getStaticCountryById_Api',
        query,
        values,
        apiInfo: location_getStaticCountryById_Api,
        maxAge,
      })

// Route::get('locations/location/{type}/{id}/gets', 'LocationController@getLocationsByOwner');
export const location_getLocationsByOwner_Api = {
  backend: `Route::get('locations/location/{type}/{id}/gets', 'LocationController@getLocationsByOwner');`,
  root: API_ROOT_URL,
  path: '/locations/location/:type/:id/gets',
  method: 'GET',
  fieldTypes,
  schema: locationSchema,
  transform: createListTransform(locationSchema),
  // transform: (res, {query}) => {
  //   if (res.status === 200 && res.data.data) {
  //     const _type = query[':type']
  //     const idname = query[':id'];
  //     const newOrganization = {
  //       idname,
  //       locations: [...res.data.data],
  //       _type
  //     }
  //     const {
  //       entities,
  //       result
  //     } = normalize(
  //       newOrganization,
  //       organizationSchema
  //     );
  //     return {
  //       status: 200,
  //       success: true,
  //       entities,
  //       result
  //     }
  //   }

  //   return {
  //     error: true,
  //     errorMessages: res.data
  //   }
  // }
}

// Route::post('locations/location/{type}/{id}/set-order-position', 'LocationController@sortOrder'); // location_id, position_number
export const location_sortOrder_Api = {
  backend: ``,
  root: API_ROOT_URL,
  path: '/locations/location/:type/:id/set-order-position',
  method: 'POST',
  fields: locationFields,
  fieldTypes,
  schema: locationSchema,
  transform: createListTransform(locationSchema),
}
