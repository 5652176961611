import { baseItemSchema } from 'apis/schema'
import { SelectEntityItem } from 'components/SelectEntityItem'
import _ from 'lodash'
import React, { useMemo, useState } from 'react'
import { renderSelf } from '../typedefs'

const IsDeleted = (e) => !!_.get(e, 'deleted')

const useObservedList = ({
  isDeleted = IsDeleted,
  renderItem = renderSelf,
  schema = baseItemSchema,
}) => {
  const [newItems, setNewItems] = useState([])

  const visibleItems = useMemo(() => {
    if (_.isEmpty(newItems)) return []
    return newItems.filter((e) => !!!isDeleted(e))
  }, [newItems])

  const add = (result) => {
    if (!_.isEmpty(result)) {
      setNewItems([...newItems, result])
    }
  }

  const component = useMemo(
    () => (
      <SelectEntityItem
        key={visibleItems}
        item={visibleItems}
        schema={[schema]}>
        {(list = []) => list.map(renderItem)}
      </SelectEntityItem>
    ),
    [newItems, visibleItems, schema, renderItem]
  )

  return [add, component, visibleItems]
}

export default useObservedList
