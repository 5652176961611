import {Popover} from 'antd'
import {baseItemSchema} from 'apis/schema'
import getLinkToDetail from 'helpers/getLinkToDetail'
import useTranslate from 'modules/local/useTranslate'
import React, {useState} from 'react'
import {BaseItem} from './Item/BaseItem'
import './WithDetailPopup.scss'

const PopupContent = ({ item }) => {
  const t = useTranslate()
  return (
    <BaseItem
      totals={{}}
      {...{
        type: item._type,
        avatar:
          item.thumbnail &&
          item.thumbnail,
        cover: item.cover_photo,
        title: item.title || item.name,
        description: t(item._type),
        premium: item.premium,
        id: item.idname,
        to: getLinkToDetail(item)
      }}
    />
  )
}
export const WithDetailPopup = ({
  style,
  className,
  children,
  id,
  item,
  schema
}) => {
  const [toggle, setToggle] = useState()
  const content = (
    <PopupContent
      {...{
        item,
        id,
        schema: baseItemSchema
      }}
    />
  )
  if (!item) return children
  return (
    <Popover
      overlayClassName="WithDetailPopup"
      placement="bottomLeft"
      mouseEnterDelay={0.3}
      destroyTooltipOnHide
      visible={toggle}
      content={content}
      onVisibleChange={setToggle}
      trigger="hover">
      {children}
    </Popover>
  )
}
