import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import _ from 'lodash'
import React from 'react'
import {Null} from 'views/Shared'
import {SlateRenderer} from 'views/SlateEditor'
import {convertFromString} from 'views/SlateEditor/functions'
import SlateEditor from 'views/SlateEditor/SlateEditor'
import {isRawContentEmpty} from '../../SlateEditor/functions/parseRawContent'
import './CustomRichEditor.css'

const EmptyEditor = () => <EmptyHolder style={{border: 'none'}}/>

const CustomRichEditor = ({
                            name,
                            value,
                            className,
                            placeholder,
                            Wrapper = 'div',
                            onChange = Null,
                            readOnly = false,
                            Empty = EmptyEditor,
                            ...props
                          }) => {
  const showEmptyHolder = Boolean(readOnly && isRawContentEmpty(value))

  if (readOnly && _.isEmpty(value)) {
    return null
  }

  return (
    <Wrapper className={classNames('w-full border rounded-md', className)}>
      {showEmptyHolder ? (
        <Empty/>
      ) : (
        <SlateEditor
          {...props}
          name={name}
          readOnly={readOnly}
          onChange={onChange}
          defaultValue={value}
          placeholder={placeholder}
        />
      )}
    </Wrapper>
  )
}

export const CustomEditorRenderer = React.memo(
  ({value, rawValue, className, Wrapper = 'div'}) => (
    <Wrapper className={classNames('w-full border rounded-md', className)}>
      <SlateRenderer value={rawValue ? convertFromString(rawValue) : value}/>
    </Wrapper>
  )
)

export default CustomRichEditor
