import React from 'react'

export default function PhotoSvg(
  props
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#415E72"
        d="M0 421.544L148.072 249.736 296.136 421.544z"
      ></path>
      <path
        fill="#25B6D2"
        d="M111.08 421.544L311.544 188.968 512 421.544z"
      ></path>
      <circle cx="204.824" cy="139.712" r="49.256" fill="#FFD07D"></circle>
    </svg>
  )
}
