import classNames from 'classnames'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import Null from 'components/NullComponent'
import useRouter from 'modules/navigation/useRouter'
import React, {useContext} from 'react'
import './PageHeader.css'

export const MainPageHeader = ({
  title,
  onClose = Null,
  className,
  children,
  extra
}) => {
  const {
    canBack,
    history
  } = useRouter()
  const {
    showMainPageHeader
  } = useContext(LayoutContext)

  return (
    <div
      className={classNames(
        'PageHeader stickyTop ',
        className
      )}>
      <div
        style={{
          height: 'var(--header-height)'
        }}
        className=" flex background  items-center">
        <span className="flex items-center flex-1 overflow-hidden">
          {title}
        </span>
        {extra}
      </div>
      {children}
    </div>
  )
}
