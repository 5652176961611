import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import chartSize from 'conts/chartSize'
import viewModes from 'conts/viewModes'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useContext, useEffect, useState} from 'react'
import FeedInLogo from "../../../../../assets/svgs/applogo.svg"
import {decrypt} from '../../../mapParams/dataHelpers'
import {mapUSIDNames} from "../../AmGeoChart/mapIdnames";
import {emptyArray} from "../../../../../helpers/emptyObjects";

const addWatermarkLogoConfig = (item, thisChart, align = 'left', valign = 'bottom') => {
  try {
    if (thisChart) {
      let labelWatermark = thisChart.chartContainer.createChild(
        am4core.Image
      );
      labelWatermark.href = FeedInLogo;
      labelWatermark.scale = 2;
      labelWatermark.align = align;
      labelWatermark.valign = valign;
      labelWatermark.opacity = 0.3;
      labelWatermark.dx = 10;
      labelWatermark.zIndex = 99;
      labelWatermark.clickable = false;
      labelWatermark.focusable = false;


      if (
        _.has(
          item,
          'dataset.copyright'
        )
        && !_.isEmpty(
          item.dataset.copyright
        )
      ) {

        let labelCopyright = thisChart.chartContainer.createChild(
          am4core.Label
        );
        labelCopyright.align = 'right';
        labelCopyright.valign = 'bottom';
        labelCopyright.dx = -3;
        labelCopyright.opacity = 0.3;
        labelCopyright.zIndex = 999;
        labelCopyright.fontSize = "0.68rem";
        labelCopyright.text =
          'Source: ' +
          item.dataset.copyright;
        labelCopyright.zIndex = 99;
        labelCopyright.clickable = false;
        labelCopyright.focusable = false;
      }
    }
  } catch (e) {
  }
}


const addGlobalAmChartConfig = (item, thisChart, isSm = false) => {
  // last_updated
  try {
    if (thisChart) {
      if (
        _.has(
          item,
          'static_data.{last_updated}'
        )
      ) {
        const last_updated = _.get(
          item,
          'static_data.{last_updated}'
        )
        // Add last updated field:
        let labelLastUpdate = thisChart.chartContainer.createChild(
          am4core.Label
        )

        labelLastUpdate.align = 'right';
        labelLastUpdate.valign = 'bottom';
        labelLastUpdate.dy = -13;
        labelLastUpdate.dx = -3;
        labelLastUpdate.opacity = 0.3
        labelLastUpdate.zIndex = 99
        labelLastUpdate.fontSize = "0.68rem"
        labelLastUpdate.text =
          'Last updated: ' +
          last_updated
        labelLastUpdate.zIndex = 99;
        labelLastUpdate.clickable = false;
        labelLastUpdate.focusable = false;
      }

      // no zoom on mobile.
      if (isSm) {
        thisChart.maxZoomLevel = 1
        thisChart.dragGrip.disabled = false
        thisChart.seriesContainer.resizable = false
        thisChart.cursor.behavior =
          'panX'
      }
    }

  } catch (e) {
  }
}

export const AmGeoChartStateless = ({
  item,
  setChartWrap,
  viewmode = viewModes.BLOCK,
  ...props
}) => {
  const [chart, setChart] = useState()
  const [id] = useState(
    _.uniqueId('chart_' + item.id)
  )
  const t = useTranslate()

  const { isSm } = useContext(
    LayoutContext
  )
  const _chartSize = _.get(
    props,
    'size',
    isSm || viewmode === viewModes.BLOCK
      ? chartSize.SMALL
      : chartSize.NORMAL
  )

  useEffect(() => {
    if (chart) return () => {
    }

    let thisChart
    let chartParams = item.params
    chartParams = JSON.parse(
      JSON.stringify(chartParams)
    )

    // am4core.options.queue = true
    // am4core.options.onlyShowOnViewport = true

    // Create map instance
    thisChart = am4core.createFromConfig(
      {
        width: '100%',
        height: '100%',
        responsive: {
          enabled: true
        },
        geodata: am4geodata_worldLow,
        projection: 'Miller',
        tapToActivate: true,
        tapTimeout: 3000,
        ...chartParams,
        chartContainer: {
          wheelable: false
        }
      },
      id,
      am4maps.MapChart
    )

    if (thisChart.series.values[0]) {
      thisChart.series.values[0].data = []
    }

    addGlobalAmChartConfig(item, thisChart);
    addWatermarkLogoConfig(item, thisChart);

    let polygonSeries =
      thisChart.series.values[0]

    // Set up heat legend
    let heatLegend = thisChart.createChild(
      am4maps.HeatLegend
    )
    heatLegend.series = polygonSeries
    heatLegend.align = 'right'
    heatLegend.width = am4core.percent(
      25
    )
    heatLegend.marginRight = am4core.percent(
      4
    )
    heatLegend.valign = 'bottom'

    // Create hover state and set alternative fill color
    let hs = polygonSeries.mapPolygons.template.states.create(
      'hover'
    )
    hs.properties.fill = thisChart.colors
      .getIndex(1)
      .brighten(-0.5)
    thisChart.exporting.useWebFonts = false;
    // thisChart.svgContainer.autoResize = false
    setChartWrap({
      getChart: () => thisChart,
      type: 'amchart'
    })
    setChart(thisChart)
  }, [
    _chartSize,
    chart,
    id,
    isSm,
    item,
    setChartWrap
  ])

  useEffect(() => {
    if (chart) {
      const data_values = (() => {
        let data_values =
          Number(
            item.data_encrypted
          ) !== 1
            ? item.data_values
            : decrypt(
              item.data_values,
              item.data_passphrase
            )
        const data_types = _.get(item, 'data_columns', []).map(
          c => c.type || 'string'
        )
        const transpose = (r, a) =>
          a.map((v, i) => {
            if (r[i]) {
              if (
                data_types[i] ===
                'datetime'
              ) {
                return [
                  ...r[i],
                  moment
                    .utc(v)
                    .local()
                    .valueOf()
                ]
              } else {
                return [...r[i], v]
              }
            } else {
              return [...[], v]
            }
          })
        data_values = (data_values || emptyArray).map(
          e =>
            Object.fromEntries(
              [
                _.get(item, 'data_columns', []).map(
                  c => c.id
                ),
                e
              ].reduce(transpose, [])
            )
        )

        // tính cộng dồn lại với data_columns có thuộc tính 'cumulated': true
        let _found_cols
        if (!_.isEmpty(data_values)) {
          _found_cols = _.filter(
            item.data_columns,
            { cumulated: true }
          )
          _.forEach(
            _found_cols,
            function (col) {
              let _prevValue = 0
              data_values = (data_values || emptyArray).map(
                e => {
                  _prevValue =
                    _prevValue +
                    e[col.id]
                  return {
                    ...e,
                    [col.id]: _prevValue
                  }
                }
              )
            }
          )
        }


        // Chuyển đổi tên State thành ID state
        if (!_.isEmpty(data_values)) {
          data_values = (data_values || emptyArray).map(
            e => {
              return {
                ...e,
                ...mapUSIDNames(e.id)
              }
            }
          )
        }

        return data_values
      })()

      // Create map polygon series
      let polygonSeries =
        chart.series.values[0]
      if (polygonSeries)
        polygonSeries.data = data_values
    }
  }, [
    item.data_columns,
    item.data_passphrase,
    item.data_values,
    chart,
    item.data_encrypted
  ])

  if (
    !item.data_columns ||
    !item.data_columns.length
  ) {
    return (
      <div
        className={classNames(
          props.className,
          'w-full'
        )}>
        <EmptyHolder
          title={null}
          subTitle={t('no data found')}
        />
      </div>
    )
  }

  return (
    <div
      className="flex-1"
      style={{
        width: '100%',
        height: '100%',
        overflow: 'hidden'
      }}
      id={id}></div>
  )
}

export default AmGeoChartStateless
