import {create_product_delete_Api} from 'apis'
import {handleconfirm} from 'components/Confirm'
import ApiInfoForm from 'components/form/ApiInfoForm'
import LazyComponent from 'components/LazyComponent'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React from 'react'
import Delete from './Delete'
import {ProductConsumer} from './ProductContainer'
import {workspaceManageProductRoutes} from "./Product/ProductSettings/WorkspaceManageProducts";

const ProductDelete = () => {
  const history = useHistory()
  const t = useTranslate()
  return (
    <LazyComponent>
      <ProductConsumer>
        {item => (
          <ApiInfoForm
            onSuccess={() =>
              handleconfirm({
                native: true,
                title: t('do you want delete this product'),
                okText: t('delete'),
                onOk: () =>
                  history.replace(
                    workspaceManageProductRoutes["listed-products"].path
                  )
              })
            }
            onPreSubmit={({
                            photos = [],
                            ...rest
                          }) => ({
              ...rest,
              ...(photos
                ? {
                  photos: photos.map(
                    file => {
                      let photo
                      file.read(
                        file => {
                          photo = _.get(
                            file,
                            'id'
                          )
                        }
                      )
                      return photo
                    }
                  )
                }
                : {})
            })}
            apiInfo={create_product_delete_Api()}
            query={{
              ':id': item.id
            }}
            initialValues={{}}>
            <Delete/>
          </ApiInfoForm>
        )}
      </ProductConsumer>
    </LazyComponent>
  )
}

export default ProductDelete
