import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined'
import {Image, message} from 'antd'
import classNames from 'classnames'
import copyToClipboard from 'copy-to-clipboard'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useLayoutEffect, useMemo, useState} from 'react'
import {BsDiamondFill} from 'react-icons/bs'
import {GiIronCross} from 'react-icons/gi'
import {Link} from 'react-router-dom'
import {paths} from 'views/MainPage/contains'
import {Photo} from '../../conts/imageBase64'
import getLinkToDetail from '../../helpers/getLinkToDetail'
import {renderIf, validateUrl} from '../../views/Shared'
import ImageLoader from '../ImageLoader'
import {LayoutContext} from '../layouts/Default/LayoutContext'
import Description, {mapKeywords} from './Description'
import FeedContext from './FeedContext'
import Mark from "mark.js";
import {RiCircleFill} from "react-icons/ri";

export function LinkfyEmbed({
                              title,
                              url,
                              subTitle,
                              description,
                              categories = [],
                              image,
                              style = {},
                              className = '',
                              selectable = false,
                            }) {
  const Wrapper = useMemo(
    () =>
      ({children}) => {
        if (selectable) {
          return children
        } else {
          return (
            <a
              href={url}
              target="_blank"
              style={{boxShadow: 'none'}}
              onClick={(event) => {
                event.stopPropagation()
              }}>
              {children}
            </a>
          )
        }
      },
    [selectable]
  )

  return (
    <Wrapper>
      <div
        style={{
          borderRadius: '0.3em',
          ...style,
        }}
        className={classNames('cursor-pointer', className)}>
        <div className="w-full flex gap-3 overflow-hidden">
          <div className="flex flex-1 flex-col gap-2">
            {subTitle && (
              <div className="flex items-center gap-2">
                <img
                  style={{
                    verticalAlign: 'sub',
                    margin: 0,
                  }}
                  alt={title}
                  src={`https://www.google.com/s2/favicons?domain=${subTitle}`}
                />
                <span className="truncate flex-1 text-sm text-color-300 hover:underline leading-tight italic">
                  {subTitle}
                </span>
              </div>
            )}
            <div className="font-bold text-lg text-primary leading-tight">
              {title}
            </div>
            <div className="text-sm text-color-400 italic leading-snug">
              {description}
            </div>
            {categories.length > 0 && (
              <div className="text-xs text-color-200 leading-none">
                {categories.map((e) => (
                  <Link
                    key={e.id}
                    className="leading-none"
                    to={
                      paths.categoryPath
                        .replace(':category', e.id)
                        .replace(':refcategory', e.alt_idname) +
                      '?lang=' +
                      e.language
                    }>
                    {' '}
                    • {e.name}
                  </Link>
                ))}
              </div>
            )}
          </div>
          {!!image && (
            <ImageLoader
              style={{
                width: '15%',
                height: '15%',
                objectFit: 'cover',
                marginTop: 0,
                marginBottom: 0,
              }}
              src={image}
            />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

/**
 * @return {null}
 */
export function BaseEmbed({
                            url,
                            title,
                            description,
                            keywords = [],
                            categories = [],
                            image,
                          }) {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)

  const {url: feed_url} = useContext(FeedContext)

  const linkToUrl = feed_url || url;

  if (title) {
    return (
      <div className="flex flex-col gap-1 overflow-hidden">
        <Link
          to={linkToUrl}
          className="font-bold text-color-000 no-underline leading-tight">
          {title}
        </Link>
        <div className="w-full flex gap-2">
          <Description
            style={{lineHeight: 1.8}}
            className="flex-1 max-lines-3 text-sm text-color-300">
            {mapKeywords(description, keywords)}
            {/*{description}*/}
          </Description>
          {!!image && (
            <Link to={linkToUrl}>
              <Image
                className="w-20 md:w-28 h-20 object-contain rounded-md"
                preview={false}
                src={image}
                fallback={Photo}
              />
            </Link>
          )}
        </div>
        {categories.length > 0 && (
          <div className="flex flex-wrap gap-2 items-center italic font-light text-color-300 text-xs">
            {categories.slice(0, isSm ? 3 : 5).map((e) => (
              <div
                key={e.id}
                className="flex items-center gap-1 text-primary">
                <BsDiamondFill size={6} />
                <Link
                  className="italic font-medium text-xs"
                  to={
                    paths.categoryPath
                      .replace(':category', e.id)
                      .replace(':refcategory', e.alt_idname) +
                    '?lang=' +
                    e.language
                  }>
                  {e.name}
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    )
  } else {
    return null
  }
}

export function DetailEmbed({
                              id,
                              title,
                              url,
                              subTitle,
                              description,
                              categories = [],
                              image,
                              content,
                              keywords = [],
                              others = [],
                            }) {
  const t = useTranslate()
  const {item, ...rest} = useContext(FeedContext)
  const [toggleLinkView, setToggleLinkView] = useState(false)
  const _renderContentItem = (it, i) => {
    switch (_.get(it, 'type', 'p')) {
      case 'img':
        return !_.has(it, 'url') ? null : (
          <div
            className="py-4 w-full"
            key={i}>
            <img
              className="w-full object-cover bg-no-repeat rounded-md"
              src={_.get(it, 'url')}
              alt={_.get(it, 'alt', '')}
            />
            {_.has(it, 'value') && (
              <div className="flex text-color-300 text-base italic">
                {_.get(it, 'value')}
              </div>
            )}
          </div>
        )
      case 'html':
        return !_.has(it, 'value') ? null : (
          <div
            className="py-2 w-full flex"
            key={i}>
            <div
              className="flex-1 fix-table w-0 overflow-auto"
              dangerouslySetInnerHTML={{
                __html: _.get(it, 'value'),
              }}
              key={i}
            />
          </div>
        )
      case 'h':
        return !_.has(it, 'value') ? null : (
          <Description
            className="py-2 font-semibold"
            ghostbav
            key={i}>
            {/*{mapKeywords(_.get(it, 'value', ''), keywords)}*/}
            {_.get(it, 'value', '')}
          </Description>
        )
      default:
        return !_.has(it, 'value') ? null : (
          <Description
            className="py-2"
            ghostbav
            key={i}>
            {_.get(it, 'value', '')}
            {/*{mapKeywords(_.get(it, 'value', ''), keywords)}*/}
          </Description>
        )
    }
  }
  const contentId = id + '-content';

  useLayoutEffect(() => {
    const mark_content = document.getElementById(contentId)
    if (mark_content) mark_content.innerHTML = mark_content.innerHTML + '';
    const markInstance = new Mark(mark_content)
    markInstance.mark(
      keywords.map((e) => e?.keyword),
      {
        element: 'a',
        diacritics: false,
        accuracy: 'exactly',
        className: 'text-content-mark',
        separateWordSearch: false,
        each: (element) => {
          const current = _.find(
            keywords,
            (e) =>
              element.textContent &&
              String(e?.keyword ?? '').toLowerCase() ===
              element.textContent.toLowerCase()
          )
          element.setAttribute('href', current ? getLinkToDetail(current) : '#')
        },
      }
    )

    return () => {
      if (markInstance) markInstance.unmark() // Clean up on component unmount
    }
  }, [])

  return (
    <div>
      <div className="border border-color-50 rounded-lg p-2 md:p-3">
        <div className="flex overflow-hidden">
          <div className="break-words flex-1 flex-col verticalList__small">
            <Link
              to={getLinkToDetail(item)}
              className="font-bold color-primary text-lg md:text-2xl leading-snug">
              {title}
            </Link>
            <div className="leading-snug my-3">{description}</div>
            <div className="flex gap-3 font-light text-xs text-color-300 italic">
              {categories.map((e) => (
                <div
                  key={e.id}
                  className="flex items-center text-color-300 hover:text-primary">
                  <BsDiamondFill
                    size={5}
                    className="mr-1"
                  />
                  <Link
                    className="italic font-medium text-xs"
                    to={
                      paths.categoryPath
                        .replace(':category', e.id)
                        .replace(':refcategory', e.alt_idname) +
                      '?lang=' +
                      e.language
                    }>
                    {e.name}
                  </Link>
                </div>
              ))}
            </div>
            {image && (
              <ImageLoader
                alt={
                  (keywords || []).reduce(function (r, e) {
                    r += e.keyword + ', '
                    return r
                  }, '') + 'by FeedIn'
                }
                className="w-full object-cover"
                style={{
                  verticalAlign: 'middle',
                  borderRadius: '0.5rem',
                }}
                src={image}
              />
            )}
          </div>
        </div>
        {others.length > 0 && (
          <div className="mt-3 border-t border-color-50">
            {others.slice(0, 3).map((e, i) => (
              <Link
                key={i}
                to={{
                  pathname: `/news/${e._type}/${e.id}`,
                }}
                className="p-2 flex items-center gap-2 text-color-300 leading-5 text-sm italic no-underline">
                <RiCircleFill size={8} className="text-color-500"/>
                <span className="text-sm flex-1">
                      {_.truncate(_.get(e, 'overview.title'), {length: 160})}
                  </span>
              </Link>
            ))}
          </div>
        )}
        {!_.isEmpty(content) && (
          <div id={contentId} className="whitespace-pre-wrap p-3 background-100 rounded-md leading-7 w-full">
            {content.map((it, i) => {
              return _renderContentItem(it, i)
            })}
          </div>
        )}
      </div>

      <div className="flex my-3">
        <div className="flex-1"/>
        <div className="flex flex-center mx-2 px-2 py-1 background-200 rounded-full">
          <div className="italic text-sm text-color-300 px-2">
            {`${t('source')}: `}
          </div>
          <div className="text-sm font-semibold pr-2">{subTitle}</div>
          {!toggleLinkView && (
            <div
              onClick={() => {
                copyToClipboard(url)
                message.success(t('copied'))
                setToggleLinkView(true)
              }}
              className="px-2 cursor-pointer italic text-sm">
              <QuestionCircleOutlined/>
            </div>
          )}
        </div>
      </div>

      {toggleLinkView && (
        <div className="whitespace-normal my-3 text-sm italic">
          <span className="text-xs text-color-400">{`${t('source')}: `}</span>
          {validateUrl(url) ? (
            <a
              href={url}
              className="text-primary font-medium">
              {url}
            </a>
          ) : (
            <span className="text-primary font-medium">{url}</span>
          )}
        </div>
      )}
    </div>
  )
}

export const Keywords = (props) => {
  const t = useTranslate()
  const keywords = Array.from(props.keywords ?? [])
  return renderIf(
    keywords.length,
    <div id="keywords">
      <span className="text-sm text-color-500 italic">
        {`${t('keywords')}: `}
      </span>
      {keywords.map(({keyword, idname}, index) => (
        <React.Fragment key={index}>
          <Link
            className="font-bold text-sm text-color-000 hover:text-primary ml-2 no-underline"
            to={`/t/${idname}/home`}>
            {keyword}
          </Link>
          {index < keywords.length - 1 && ','}
        </React.Fragment>
      ))}
    </div>
  )
}
