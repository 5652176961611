import DownCircleOutlined from "@ant-design/icons/lib/icons/DownCircleOutlined"
import TagFilled from "@ant-design/icons/lib/icons/TagFilled"
import UpCircleOutlined from "@ant-design/icons/lib/icons/UpCircleOutlined"
import {Avatar, Button, Modal, Select, Spin, Tooltip} from 'antd'
import {LoginContext} from "components/LoginContext"
import _ from "lodash"
import React, {useContext, useState} from 'react'
import {AiOutlineAreaChart, AiOutlineLineChart} from "react-icons/ai"
import {ImStatsBars} from "react-icons/im"
import {Link} from "react-router-dom"
import {useToggle} from "react-use"
import {
  product_clusters_updateProductGroupTitle_Api,
  product_getClusteredProducts_Api,
  product_getUnGroupClusteredProducts_Api,
  product_getUnGroupPurchaseProducts_Api,
  product_getUnGroupPurchaseProductsGroupByLabel_Api
} from '../../../apis'
import {organizationModel, productModel} from '../../../apis/model'
import {product_getClusteredProductGroups_Api} from "../../../apis/product"
import {productSchema} from "../../../apis/schema"
import EmptyHolder from "../../../components/EmptyHolder"
import FieldDecorator from "../../../components/form/FieldDecorator"
import LookupField from "../../../components/LookupField"
import {SelectEntityItem} from "../../../components/SelectEntityItem"
import Toggle from "../../../components/Toggle"
import UseState from "../../../components/UseState"
import getLinkToDetail from "../../../helpers/getLinkToDetail"
import getTitle from "../../../helpers/getTitle"
import {createAsyncAction} from "../../../modules/asyncCache"
import CustomLazyPaginationWrapper from "../../../modules/asyncCache/components/CustomLazyPaginationWrapper"
import {LazyPagination} from "../../../modules/asyncCache/components/LazyPagination"
import useAsyncWithCache from '../../../modules/asyncCache/useAsyncWithCache'
import useDispatchAsyncActionWithNotify from "../../../modules/asyncCache/useDispatchAsyncActionWithNotify"
import useTranslate from '../../../modules/local/useTranslate'
import productImageHolder from "../../Market/productImageHolder"
import {ProductPriceSetChart} from "../../Market/Seller/Product/components/ProductPriceSetChart"
import {SharedProductPriceSetGroupChart} from "../../Market/Seller/Product/components/SharedProductPriceSetGroupChart"
import {ContextMenu} from "../../Market/Seller/Product/ProductSettings/ProductSaleSettingHeadlineView"
import ServerContext from "../../Server/ServerContext"
import {OrganizationContext} from '../OrganizationContext'
import CustomLazyPagination from "../../../modules/asyncCache/components/CustomLazyPagination";
import {ToggleCollapseView} from "../../../components/Toggle/ToggleCollapseView";
import {BsBox2Fill, BsCaretDownFill, BsCaretRightFill} from "react-icons/bs";
import classNames from "classnames";
import {renderIf, renderIfElse} from "../../Shared";

const ProductHeadlineView = ({ product, product_group_title, setUpdate }) => {
  const t = useTranslate()
  const { organization } = useContext(OrganizationContext)
  const isAdmin = !!_.get(product, 'owner.edit') && _.get(product, 'owner.id') === _.get(organization, 'id')
  const [isToggle, toggle] = useToggle()
  const [eventId, setEventId] = useState(Date.now())
  const id = productModel.getId(product)

  const [
    data,
    dispatchAsync
  ] = useDispatchAsyncActionWithNotify({
    onSuccess: () => {
      setUpdate(Date.now())
    }
  })

  return (
    <SelectEntityItem
      key={id}
      item={id}
      schema={productSchema}>
      {( item = product ) => {
        return !!item && (
          <div
              id={item.idname}
              className="border border-color-50 hover:shadow-items-md rounded-md">
            <div className="flex flex-col flex-1 gap-3">
              <div className="flex items-center gap-3 relative">
                <Avatar
                    size={50}
                    className="flex flex-center background-200 rounded-l-md rounded-r-0"
                    src={_.get(item, 'avatar')}
                    icon={ <BsBox2Fill className="text-color-500" /> }
                />
                <div className="flex flex-col gap-1 flex-1">
                  <Link
                      className="font-bold text-sm text-color-000 leading-tight no-underline"
                      to={productModel.getLinkToDetail(item)}>
                    {item.title}
                  </Link>
                  <div className="flex items-center gap-x-9">
                    <Link
                        className="font-medium text-xs text-primary-400 italic leading-tight no-underline"
                        to={getLinkToDetail(_.get(item, 'owner'))}>
                      {getTitle(_.get(item, 'owner'))}
                    </Link>
                    {renderIf(
                        _.some([item.product_line, item.status !== 1, item.active !== 1]),
                        <div className="flex items-center gap-3 text-2xs italic">
                          {!!item.product_line && (
                              <div style={{ padding: '2px 8px' }} className="bg-blue-200 font-medium text-blue-600 rounded-md">
                                {item.product_line}
                              </div>
                          )}
                          {item.status !== 1 && (
                              <div style={{ padding: '2px 8px' }} className="bg-orange-200 font-medium text-orange-600 rounded-md">
                                {t('internal use')}
                              </div>
                          )}
                          {item.active !== 1 && (
                              <div style={{ padding: '2px 8px' }} className="background-200 font-medium text-color-300 rounded-md">
                                {t('inactive')}
                              </div>
                          )}
                        </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-3 mr-2">
                  <Toggle>
                    {(isToggle, toggle) => (
                        <React.Fragment>
                          <Tooltip title={t('update products catalog')} placement="left" overlayClassName="font-medium text-xs italic">
                            <Avatar
                                size={24}
                                onClick={toggle}
                                className="flex flex-center background-200 hover:bg-primary-100 text-color-500 hover:text-primary-600 cursor-pointer"
                                icon={ <TagFilled size={12} /> }
                            />
                          </Tooltip>
                          <UseState initialValue={product_group_title}>
                            {([productGroupTitle, setProductGroupTitle]) => (
                                <Modal
                                    width={500}
                                    className="custom-modal"
                                    title={
                                      <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                                        {_.get(product, 'title')}
                                      </div>
                                    }
                                    maskClosable
                                    destroyOnClose
                                    visible={isToggle}
                                    onCancel={toggle}
                                    onOk={() => {
                                      dispatchAsync(
                                          createAsyncAction({
                                            apiInfo: product_clusters_updateProductGroupTitle_Api,
                                            query: {
                                              ':organization_id': _.get(organization, 'id'),
                                              ':id': _.get(product, 'id')
                                            },
                                            values: {
                                              product_group_title: productGroupTitle
                                            }
                                          })
                                      )
                                    }}
                                    okText={t('save')}
                                    cancelText={t('close')}
                                    okButtonProps={{
                                      type: 'primary',
                                      className: 'button-rounded-md no-border font-medium px-3',
                                    }}
                                    cancelButtonProps={{
                                      className: 'button-rounded-md no-border font-medium px-3',
                                    }}>
                                  <div className="mb-4">
                                    <FieldDecorator
                                        name="product_group_title"
                                        labelProps={{ className: undefined }}
                                        label={t('product group title')}>
                                      <LookupField
                                          defaultValue={productGroupTitle}
                                          name={_.get(organization, 'id') + "_product_group_title"}
                                          handleChange={(e) => {setProductGroupTitle(e.target.value)}}
                                      />
                                    </FieldDecorator>
                                  </div>
                                </Modal>
                            )}
                          </UseState>
                        </React.Fragment>
                    )}
                  </Toggle>
                  {isAdmin && (
                      <React.Fragment>
                        <Tooltip title={t('view chart')} placement="left" overlayClassName="font-medium text-xs italic">
                          <Avatar
                              size={24}
                              onClick={toggle}
                              className="flex flex-center background-200 hover:bg-primary-100 text-color-500 hover:text-primary-600 cursor-pointer"
                              icon={ <AiOutlineAreaChart size={14} /> }
                          />
                        </Tooltip>

                        <ContextMenu item={item} />
                      </React.Fragment>
                  )}
                </div>
              </div>
              {isToggle && (
                  <div className="flex flex-col gap-3 m-3">
                    <div className="flex items-center gap-3">
                      <span className="font-bold text-xs text-color-400 uppercase">
                        {t('charts')}
                      </span>
                      <div className="flex-1 border-t"/>
                    </div>
                    <ProductPriceSetChart
                      product={item}
                      product_id={item.id}
                      optionByProduct={true}
                      eventId={eventId}
                      setEventId={setEventId}
                    />
                  </div>
              )}
            </div>
          </div>
        )
      }}
    </SelectEntityItem>
  )
}

export function ClusteredProducts({ }) {
  const t = useTranslate()
  const {
    organization
  } = useContext(OrganizationContext)
  const oId = organizationModel.getId(
    organization
  )
  const {
    item,
    handleActionClick,
    contextMenu
  } = useContext(ServerContext)
  const [update, setUpdate] = useState(Date.now())
  const cachedGroupId = `${product_getClusteredProductGroups_Api.path}_${oId}_${update}`
  const cachedId = `${product_getClusteredProducts_Api.path}_${oId}_${update}`
  const otherListCachedId = `${product_getUnGroupClusteredProducts_Api.path}_${oId}_${update}`
  // const ownedListCachedId = `${product_getUnGroupPurchaseProducts_Api.path}_${oId}_${update}`
  const {
    response, success, isLoading
  } = useAsyncWithCache({
    cacheId: cachedGroupId,
    apiInfo: product_getClusteredProductGroups_Api,
    query: {
      ':id': oId
    }
  })

  const login = useContext(LoginContext)

  const [filter, setFilter] = useState("")
  const [groupByDisplay, setGroupByDisplay] = useState("product_catalog")

  const groupByProductArray = _.get(response, 'data.data', []);

  const {
    response: responseUnClassifyGroup
  } = useAsyncWithCache({
    // key: product_getUnGroupPurchaseProductsGroupByLabel_Api.path.replace(":id", oId) + groupByDisplay,
    cacheId: product_getUnGroupPurchaseProductsGroupByLabel_Api.path.replace(":id", oId) + groupByDisplay,
    apiInfo: product_getUnGroupPurchaseProductsGroupByLabel_Api,
    query: {
      ':id': oId
    },
    values: {
      group_by_type: groupByDisplay
    }
  });

  return (
    <div id="workspace_product_list" className="space-y-5">
      {/*<div className="flex py-1 font-medium text-xl border-b-2 border-green-500">*/}
      {/*  <div className="flex-1">*/}
      {/*    {t('products')}*/}
      {/*  </div>*/}
      {/*  <div className="flex space-x-2 px-2 text-2xl flex-col md:flex-row space-y-3 md:space-y-0 items-end">*/}
      {/*    {*/}
      {/*      !!_.get(organization, 'edit', false) && (*/}
      {/*        <>*/}
      {/*          {(_.get(organization, 'privileges.price_export', 0) > 0) && (*/}
      {/*            <Button*/}
      {/*              onClick={() =>*/}
      {/*                handleActionClick(*/}
      {/*                  'exportPurchasingPriceData'*/}
      {/*                )*/}
      {/*              }*/}
      {/*              icon={<ExportOutlined />}>*/}
      {/*              {*/}
      {/*                t('export purchasing data')*/}
      {/*              }*/}
      {/*            </Button>*/}
      {/*          )}*/}
      {/*          <Button*/}
      {/*            type="primary"*/}
      {/*            onClick={() =>*/}
      {/*              handleActionClick(*/}
      {/*                'createPurchaseProduct'*/}
      {/*              )*/}
      {/*            }*/}
      {/*            icon={<PlusOutlined />}>*/}
      {/*            {t('create product')}*/}
      {/*          </Button>*/}
      {/*        </>*/}
      {/*      )*/}
      {/*    }*/}
      {/*  </div>*/}
      {/*</div>*/}

      {groupByProductArray.map((o, i) => (
          <Toggle key={i}>
            {(isToggleCollapse, toggleCollapse) => (
                <div key={i} className="flex flex-col gap-3 relative">
                  <div
                      onClick={toggleCollapse}
                      style={{ padding: '6px 8px' }}
                      className="flex items-center gap-2 bg-primary-50 border border-transparent hover:border-primary rounded-md cursor-pointer">
                    {renderIfElse(
                        isToggleCollapse,
                        <BsCaretDownFill size={12} className="text-primary-600" />,
                        <BsCaretRightFill size={12} className="text-primary-600" />
                    )}
                    <div
                        id={o.product_group_title}
                        className="font-bold text-primary-600 uppercase tracking-wide leading-tight">
                      {o.product_group_title}
                    </div>
                    <div className="flex-1 border-b border-primary-600 opacity-50"/>
                    <Toggle>
                      {( isToggle0, toggle0 ) => (
                          <div className="flex flex-col">
                            <Tooltip title={t('view chart')} placement="left" overlayClassName="font-medium text-xs italic">
                              <AiOutlineAreaChart
                                  onClick={toggle0}
                                  size={20}
                                  className="text-primary hover:text-primary-700 cursor-pointer"
                              />
                            </Tooltip>
                            <Modal
                                width={720}
                                className="custom-modal"
                                title={
                                  <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                                    {t('view chart')}
                                  </div>
                                }
                                onCancel={toggle0}
                                maskClosable={false}
                                destroyOnClose
                                visible={isToggle0}
                                footer={null}>
                              <div className="flex flex-col gap-3">
                                {!!o.product_group_title && (
                                    <div className="flex items-baseline gap-1 italic">
                                    <span className="font-medium text-xs text-color-500">
                                      {`${t('view chart of catalog')} : `}
                                    </span>
                                      <div className="font-bold text-color-000">
                                        {o.product_group_title}
                                      </div>
                                    </div>
                                )}
                                <div className="flex flex-col space-y-3 p-3 border rounded-lg">
                                  <div className="flex items-center gap-3">
                                  <span className="font-bold text-xs text-color-400 uppercase">
                                    {t('charts')}
                                  </span>
                                    <div className="flex-1 border-t"/>
                                  </div>
                                  <SharedProductPriceSetGroupChart
                                      product_group_title={o.product_group_title}
                                      product_receiver_type="organization"
                                      product_receiver_id={oId}
                                  />
                                </div>
                              </div>
                            </Modal>
                          </div>
                      )}
                    </Toggle>
                  </div>
                  {isToggleCollapse && (
                      <LazyPagination
                          key={cachedId + o.product_group_title}
                          id={cachedId + o.product_group_title}
                          renderItem={(item, index) => (
                              <ProductHeadlineView
                                  {...{ product: item, product_group_title: o.product_group_title }}
                                  setUpdate={setUpdate}
                              />
                          )}
                          renderLoading={() => (
                              <div className="w-full flex justify-center items-center h-screen-offset-4header"
                                   style={{ height: '30vh' }}>
                                <Spin className="m-auto" />
                              </div>
                          )}
                          renderEmpty={() => (<EmptyHolder />)}
                          apiInfo={product_getClusteredProducts_Api}
                          query={{ ":id": oId }}
                          values={{ product_group_title: o.product_group_title }}
                      />
                  )}
                </div>
            )}
          </Toggle>
      ))}

      <div className="flex flex-col gap-3 pt-3">
        <div className="flex flex-col lg:flex-row items-end gap-3 pb-1 border-b-2 border-primary-600">
          <div className="items-center gap-1 flex-1 space-x-1">
            <span className="font-bold text-color-000 uppercase tracking-wide leading-tight">
              {t('unclassified products')}
            </span>
            <span className="text-xs text-color-400 italic">
              ({t('be sent from partners')})
            </span>
          </div>

          <div className="flex items-center gap-2">
            <span className="text-xs text-color-400 italic">
              {t('group by')}
            </span>
            <Select
                size="small"
                className="font-medium text-xs"
                suffixIcon={ <BsCaretDownFill size={11} className="text-color-500" /> }
                defaultValue={groupByDisplay}
                onChange={setGroupByDisplay}>
              <Select.Option value="product_line">
                {t('product line')}
              </Select.Option>
              <Select.Option value="product_catalog">
                {t('product catalog')}
              </Select.Option>
            </Select>
          </div>
        </div>

        <div key={groupByDisplay + update} className="flex flex-col gap-2">
          {_.get(responseUnClassifyGroup, 'data.data', []).map(eGroup => (
              <ToggleCollapseView defaultValue={false} key={eGroup.id} title={eGroup.name}>
                <CustomLazyPagination
                    key={otherListCachedId + eGroup.id}
                    id={otherListCachedId + eGroup.id}
                    renderItem={(item, index) => (
                        <ProductHeadlineView
                            {...{product: item, product_group_title: null}}
                            setUpdate={setUpdate}
                        />
                    )}
                    renderEmpty={() => ( <EmptyHolder/> )}
                    apiInfo={ product_getUnGroupClusteredProducts_Api }
                    query={{":id": oId}}
                    values={{
                        group_by_type: groupByDisplay,
                        group_by_type_code: eGroup.id
                    }}
                />
              </ToggleCollapseView>
          ))}
        </div>
      </div>

      {/*<CustomLazyPaginationWrapper*/}
      {/*  id={ownedListCachedId}*/}
      {/*  key={ownedListCachedId}*/}
      {/*  renderWrap={children => (*/}
      {/*    <div className="flex flex-col space-y-1 pt-3">*/}
      {/*      <div className="flex hover:underline font-bold border-l-4 border-b border-blue-400 py-1 italic">*/}
      {/*        <div*/}
      {/*          className="absolute"*/}
      {/*          style={{*/}
      {/*            top: '-50px'*/}
      {/*          }}*/}
      {/*          id={"unclassified products 1"}*/}
      {/*        />*/}
      {/*        <div className="px-3 flex flex-1 space-x-2 items-end">*/}
      {/*          <div className="">*/}
      {/*            {t("unclassified products")}*/}
      {/*          </div>*/}
      {/*          <div className="italic font-normal">*/}
      {/*            ({t("owned")})*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      {children}*/}
      {/*    </div>*/}
      {/*  )*/}
      {/*  }*/}
      {/*  renderItem={(item, index) => (*/}
      {/*    <ProductHeadlineView*/}
      {/*      {...{ product: item, product_group_title: null }}*/}
      {/*      setUpdate={setUpdate}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*  renderEmpty={() => (*/}
      {/*    <EmptyHolder />*/}
      {/*  )}*/}
      {/*  apiInfo={*/}
      {/*    product_getUnGroupPurchaseProducts_Api*/}
      {/*  }*/}
      {/*  query={*/}
      {/*    {*/}
      {/*      ":id": oId*/}
      {/*    }*/}
      {/*  }*/}
      {/*/>*/}

    </div>


  )
}
