import { Calendar, DatePicker, Input, Select } from 'antd'
import { baseFieldTypes } from 'apis/base/baseFieldTypes'
import { event_edit_Api } from 'apis/event'
import { getId } from 'apis/model/base'
import classNames from 'classnames'
import { FormFieldWrapper } from 'components/form/FieldDecorator'
import { createValue } from 'components/form/utils'
import Pure from 'components/Pure'
import { FormikConsumer } from 'formik'
import { getDateString, parseMoment } from 'helpers/momentDatetime'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import * as moment from 'moment'
import React, { useCallback, useContext, useMemo } from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import {BsCalendar2Week, BsCalendar3} from 'react-icons/bs'
import { mapProps } from 'recompose'
import { withAdvancedOptions } from 'views/AppLocals/custom/AdvancedFormGroup'
import { appClassNames } from 'views/AppLocals/custom/appClassNames'
import CustomRichEditor from 'views/AppLocals/custom/CustomRichEditor'
import { PhotoUploadComposite } from 'views/AppLocals/custom/PhotoField'
import RadioOptions from 'views/AppLocals/custom/RadioOptions'
import { SelectWidget } from 'views/AppLocals/custom/ReactSelectComponents'
import createEditableForm from 'views/AppLocals/factory/createEditableForm'
import { createControlledFormField } from 'views/AppLocals/factory/createFormField'
import {
  requiredString,
  yupShape,
} from 'views/AppLocals/factory/createValidationSchema'
import { bindQueryParam } from 'views/AppLocals/functions/routerHelper'
import { deepTranslate, Null, renderElse, renderIf } from 'views/Shared'
import { convertFromString, convertToString } from 'views/SlateEditor/functions'
import EventContext, { EventIntervalTypes, EventTypes } from '../EventContext'

const formFields = [
  { name: 'title' },
  { name: 'about' },
  // { name: 'avatar' },
  { name: 'language' },
  { name: 'schedules' },
  { name: 'time_open' },
  { name: 'time_close' },
  { name: 'event_type' },
  { name: 'description' },
  { name: 'cover_photo' },
  { name: 'location_name' },
  { name: 'external_link' },
  { name: 'interval_type' },
  { name: 'interval_daily' },
  { name: 'interval_yearly' },
  { name: 'interval_monthly' },
  // { name: 'avatar_preview' },
  { name: 'cover_photo_preview' },
  { name: 'active', defaultValue: 1 },
  { name: 'status', defaultValue: 1 },
  { name: 'privacy', defaultValue: 1 },
  { name: 'registration_enabled', defaultValue: 0 },
]

const EventTypeExtra = ({ values, onChange = Null }) => {
  const t = useTranslate()
  const { event_type, external_link, location_name } = values ?? {}
  switch (event_type) {
    case EventTypes.MEETING.value:
    case EventTypes.HYBRID.value:
      return (
        <Pure input={[location_name]}>
          <FormFieldWrapper label="location">
            <Input
              className="w-full"
              name="location_name"
              onChange={onChange}
              placeholder={t('location')}
              value={location_name}
            />
          </FormFieldWrapper>
        </Pure>
      )
    case EventTypes.WEBINAR.value:
    case EventTypes.REMINDER.value:
      return (
        <Pure input={[external_link]}>
          <FormFieldWrapper label="website">
            <Input
              inputMode="url"
              className="w-full"
              onChange={onChange}
              name="external_link"
              value={values.external_link}
              placeholder={t('www.yourwebsite.com...')}
            />
          </FormFieldWrapper>
        </Pure>
      )
    default:
      break
  }
  return null
}

const EventTypeSelect = ({ name, value, onChange = Null }) => {
  const t = useTranslate()
  return (
    <SelectWidget
      name={name}
      value={value}
      allowClear={true}
      className="w-full"
      placeholder={t('type of event')}
      onChange={(value) => {
        onChange(createValue(name, value ?? null))
      }}>
      {[
        EventTypes.MEETING,
        EventTypes.WEBINAR,
        EventTypes.HYBRID,
        EventTypes.REMINDER,
      ].map(({ icon, label, value }) => (
        <Select.Option
          key={value}
          value={value}
          label={t(label)}>
          <div className="flex items-center gap-2">
            {icon}
            {t(label)}
          </div>
        </Select.Option>
      ))}
    </SelectWidget>
  )
}

const EventSchedules = ({ name, value, onChange = Null }) => {
  const schedules = Array.from(value ?? [])
  return (
    <Calendar
      fullscreen={false}
      className="border rounded-md"
      dateCellRender={(date) =>
        renderIf(
          schedules.some((e) => date.isSame(e, 'day')),
          <AiOutlineCheck
            className="text-lg absolute text-primary"
            style={{ bottom: '1rem', left: '1.2rem' }}
          />
        )
      }
      disabledDate={(current) => current && current < moment().endOf('day')}
      headerRender={({ value, onChange }) => {
        const start = 0
        const end = 12
        const monthOptions = []

        const current = value.clone()
        const localeData = value.localeData()
        const months = []
        for (let i = 0; i < 12; i++) {
          current.month(i)
          months.push(localeData.monthsShort(current))
        }

        for (let index = start; index < end; index++) {
          monthOptions.push(
            <Select.Option
              key={`${index}`}
              className="month-item">
              {months[index]}
            </Select.Option>
          )
        }
        const month = value.month()

        const year = value.year()
        const options = []
        for (let i = year - 10; i < year + 10; i += 1) {
          options.push(
            <Select.Option
              key={i}
              value={i}
              className="year-item">
              {i}
            </Select.Option>
          )
        }
        return (
          <div className="flex justify-end gap-3 p-2">
            <SelectWidget
              value={String(year)}
              dropdownMatchSelectWidth={false}
              onChange={(newYear) => {
                const now = value.clone().year(newYear)
                onChange(now)
              }}>
              {options}
            </SelectWidget>
            <SelectWidget
              value={String(month)}
              dropdownMatchSelectWidth={false}
              onChange={(selectedMonth) => {
                const newValue = value.clone()
                newValue.month(parseInt(selectedMonth, 10))
                onChange(newValue)
              }}>
              {monthOptions}
            </SelectWidget>
          </div>
        )
      }}
      onSelect={(date) => {
        if (schedules.some((e) => date.isSame(e, 'day'))) {
          const _value = schedules.filter((e) => !date.isSame(e, 'day'))
          onChange(createValue(name, _value))
        } else {
          const _value = [...schedules, date]
          onChange(createValue(name, _value))
        }
      }}
    />
  )
}

const IntervalTypeSelect = ({ name, value, onChange = Null }) => {
  const t = useTranslate()
  const renderExtra = useCallback(
    (values) => {
      switch (value) {
        case EventIntervalTypes.DAYLY.value:
          const schedules = values?.schedules
          return (
            <React.Fragment>
              <EventSchedules
                name="schedules"
                onChange={onChange}
                value={values?.schedules}
              />
              {renderElse(
                _.isEmpty(schedules),
                <div className="flex flex-col gap-1">
                  <div className="font-light text-xs text-color-400 italic">
                    {t('The next event is scheduled in')}:
                  </div>
                  <div className="font-medium text-sm text-color-000">
                    {Array.from(schedules ?? [])
                      .sort((a, b) => a.valueOf() - b.valueOf())
                      .map((e) => e.format('DD/MM/YYYY'))
                      .join(' → ')}
                  </div>
                </div>
              )}
            </React.Fragment>
          )
        case EventIntervalTypes.WEEKLY.value:
          const interval_daily = Object.assign(
            [],
            values?.interval_daily ?? '0000000'
          )
          return (
            <div className="grid grid-cols-7 gap-1 text-xs uppercase font-bold">
              {[
                { value: 'M', label: 'MON' },
                { value: 'T', label: 'TUE' },
                { value: 'W', label: 'WED' },
                { value: 'T', label: 'THU' },
                { value: 'F', label: 'FRI' },
                { value: 'S', label: 'SAT' },
                { value: 'S', label: 'SUN' },
              ].map(({ label }, index) => (
                <div
                  key={index}
                  onClick={() => {
                    var _interval_daily = Object.assign([], '0000000')
                    _interval_daily[index] = 1
                    onChange(
                      createValue('interval_daily', _interval_daily.join(''))
                    )
                  }}
                  style={{ padding: '0.65rem 0' }}
                  className={classNames(
                    'flex flex-center rounded-md cursor-pointer text-2xs cursor-pointer',
                    !!Number(interval_daily[index])
                      ? 'bg-primary text-white'
                      : 'background-200 hover:bg-blue-200 hover:text-primary'
                  )}>
                  {t(label)}
                </div>
              ))}
            </div>
          )
        case EventIntervalTypes.MONTHLY.value:
          return renderIf(
            values?.time_open,
            <div className="font-medium text-sm text-color-400 italic">
              {t('The next event is scheduled to take place at {1}', {
                1: <span className="font-bold text-color-100">
                    {moment(values?.time_open) .add(1, 'M') .format('HH:mm A - DD/MM/YYYY')}
                </span>,
              })}
            </div>
          )
        case EventIntervalTypes.NONE.value:
        default:
          break
      }
      return null
    },
    [value]
  )
  return (
    <div className="space-y-3">
      <SelectWidget
        name={name}
        value={value}
        allowClear={true}
        className="w-full"
        placeholder={t('the event will repeat in')}
        onChange={(value) => {
          onChange(createValue(name, value ?? null))
        }}>
        {[
          EventIntervalTypes.NONE,
          EventIntervalTypes.DAYLY,
          EventIntervalTypes.WEEKLY,
          EventIntervalTypes.MONTHLY,
        ].map(({ icon, label, value }) => (
          <Select.Option
            key={value}
            value={value}
            label={t(label)}>
            <div className="flex items-center gap-2">
              {icon}
              {t(label)}
            </div>
          </Select.Option>
        ))}
      </SelectWidget>
      <FormikConsumer>{({ values }) => renderExtra(values)}</FormikConsumer>
    </div>
  )
}

const formSchema = [
  {
    children: [
      {
        // name: 'avatar',
        name: 'cover_photo',
        component: mapProps(({ form, onChange }) => ({
          onChange,
          renderAvatar: Null,
          values: form?.values,
          fallback_cover: '/images/default/event-bg.jpg',
          renderAvatarUploader: Null,
          // title: form?.values?.title,
        }))(PhotoUploadComposite),
      },
    ],
  },
  {
    title: 'event name',
    children: [
      {
        name: 'title',
        component: createControlledFormField({
          maxLength: 160,
          showCount: true,
          placeholder: 'event name',
          style: { borderRadius: '0.375rem' },
        }),
      },
    ],
  },
  {
    title: 'short description',
    children: [
      {
        name: 'description',
        component: createControlledFormField({
          maxLength: 500,
          rows: 5,
          showCount: true,
          placeholder: 'short description',
          Component: Input.TextArea,
        }),
      },
    ],
  },
  {
    title: 'about event',
    children: [
      {
        name: 'about',
        component: ({ name, value, form, onChange }) => {
          const { resetKey } = form?.values ?? {}
          return (
            <CustomRichEditor
              {...{
                name,
                value,
                resetKey,
                onChange,
              }}
              placeholder="about event"
            />
          )
        },
      },
    ],
  },
  {
    inline: true,
    className: appClassNames.inline_2,
    children: [
      {
        label: 'type of event',
        name: 'event_type',
        component: createControlledFormField({
          Component: EventTypeSelect,
        }),
      },
      {
        label: 'language',
        name: 'language',
        component: ({ name, value, onChange }) => (
          <Translate>
            {(t) => (
              <SelectWidget
                name={name}
                value={value}
                allowClear={true}
                className="w-full"
                placeholder={t('language')}
                onChange={(value) => {
                  onChange(createValue(name, value ?? null))
                }}
                options={[
                  { label: 'vietnamese', value: 'vi' },
                  { label: 'english', value: 'en' },
                ].map(deepTranslate(t))}
              />
            )}
          </Translate>
        ),
      },
    ],
  },
  {
    children: {
      isInvisble: (values) => !values?.event_type,
      component: ({ form, onChange }) => (
        <EventTypeExtra
          onChange={onChange}
          values={form?.values}
        />
      ),
    },
  },
  {
    inline: true,
    className: appClassNames.inline_2,
    children: [
      {
        label: 'start date',
        name: 'time_open',
        component: ({ name, value, onChange }) => (
          <Translate>
            {(t) => (
              <DatePicker
                showTime={true}
                showSecond={false}
                className="w-full rounded-md"
                placeholder={t('Select date')}
                allowClear={false}
                format="DD/MM/YYYY - HH:mm"
                suffixIcon={<BsCalendar2Week className="text-color-400"/>}
                defaultValue={value ?? undefined}
                onChange={(value) => {
                  onChange(createValue(name, value))
                }}
              />
            )}
          </Translate>
        ),
      },
      {
        label: 'end date',
        name: 'time_close',
        component: ({ name, value, onChange }) => (
          <Translate>
            {(t) => (
              <DatePicker
                showTime={true}
                showSecond={false}
                className="w-full rounded-md"
                placeholder={t('Select date')}
                allowClear={true}
                format="DD/MM/YYYY - HH:mm"
                suffixIcon={<BsCalendar2Week className="text-color-400"/>}
                defaultValue={value ?? undefined}
                onChange={(value) => {
                  onChange(createValue(name, value))
                }}
              />
            )}
          </Translate>
        ),
      },
    ],
  },
  {
    title: 'the event will repeat in',
    children: [
      {
        name: 'interval_type',
        component: createControlledFormField({
          Component: IntervalTypeSelect,
        }),
      },
    ],
  },

  {
    inline: true,
    className: appClassNames.inline_3,
    children: [
      {
        label: 'registration enabled',
        name: 'registration_enabled',
        component: withAdvancedOptions(baseFieldTypes.yesno)(RadioOptions),
      },
      {
        label: 'privacy',
        name: 'privacy',
        component: withAdvancedOptions(baseFieldTypes.privacy)(RadioOptions),
      },
      {
        label: 'active',
        name: 'active',
        component: withAdvancedOptions(baseFieldTypes.active)(RadioOptions),
      },
    ],
  },
]

const EventSettingOverview = () => {
  const t = useTranslate()
  const { event } = useContext(EventContext)

  const validationSchema = useMemo(() => {
    return yupShape({
      title: requiredString(t),
    })
  }, [t])

  const initialized = useCallback(
    ({
      // avatar,
      about,
      schedules,
      time_open,
      time_close,
      cover_photo,
      interval_type,
      interval_daily,
      interval_yearly,
      interval_monthly,
      ...values
    }) => ({
      ...values,
      // avatar_preview: avatar,
      about: convertFromString(about),
      cover_photo_preview: cover_photo,
      interval_type: interval_type ?? 'N',
      interval_monthly: interval_monthly ?? 1,
      interval_daily: interval_daily ?? '0000000',
      time_open: parseMoment(time_open),
      time_close: parseMoment(time_close),
      interval_yearly: interval_monthly ? moment(interval_monthly) : moment(),
      schedules: Array.from(event?.schedules ?? []).map((el) =>
        moment(el.start_time)
      ),
    }),
    [event]
  )

  const onPreSubmit = ({
    about,
    resetKey,
    time_open,
    time_close,
    schedules,
    avatar_preview,
    cover_photo_preview,
    ...values
  }) =>
    _.omitBy(
      {
        ...values,
        time_open: getDateString(time_open),
        time_close: getDateString(time_close),
        about: about ? convertToString(about) : undefined,
        schedules: schedules ? JSON.stringify(schedules) : undefined,
      },
      _.isUndefined
    )

  const content = useMemo(() => {
    if (_.isEmpty(event)) return null
    return createEditableForm(formFields, formSchema)({
      item: event,
      validationSchema,
      query: bindQueryParam({
        id: getId(event),
      }),
      onPreSubmit,
      initialized,
      apiInfo: event_edit_Api,
    })
  }, [event])

  return content
}

export default EventSettingOverview
