import { Radio } from 'antd'
import classNames from 'classnames'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useCallback, useMemo } from 'react'
import AntdConfigWrapper, {
  RenderEmptyMeta,
} from 'views/AppLocals/custom/AntdConfigWrapper'
import {
  reactSelectComponents,
  SelectWidget,
} from 'views/AppLocals/custom/ReactSelectComponents'
import { AppEntities } from 'views/AppLocals/enums'
import { deepTranslate, Null } from 'views/Shared'

const FilterSelectField = ({
  name,
  value,
  onChange,
  options,
  isLoading,
  className,
  ...props
}) => (
  <AntdConfigWrapper renderEmpty={RenderEmptyMeta}>
    <SelectWidget
      value={_.find(options, { value }) ? value : undefined}
      showSearch={true}
      allowClear={true}
      onChange={(value) => {
        onChange(name, value)
      }}
      loading={!!isLoading}
      optionFilterProp="label"
      className={classNames('CenterSelector w-full flex flex-1', className)}
      style={{ minWidth: 200 }}
      options={!!isLoading ? [] : options}
      suffixIcon={
        !!isLoading ? undefined : <reactSelectComponents.DropdownIndicator />
      }
      {...props}
    />
  </AntdConfigWrapper>
)

const HostsAdvancedFilter = ({
  owner_id,
  owner_type,
  value: filter = {
    status: undefined,
    host_type: undefined,
  },
  onChange: setValues = Null,
}) => {
  const t = useTranslate()

  const handleChange = useCallback(
    (name, value) => {
      setValues({
        ...filter,
        [name]: value,
      })
    },
    [filter]
  )

  const options = useMemo(
    () =>
      [
        {
          label: 'organization',
          value: AppEntities.ORGANIZATION,
        },
        {
          label: 'user',
          value: AppEntities.USER,
        },
      ].map(deepTranslate(t)),
    [t]
  )

  return (
    <div className="flex flex-col md:flex-row items-center gap-2 mt-2">
      <FilterSelectField
        options={options}
        name="host_type"
        value={filter.host_type}
        placeholder={t('type account')}
        onChange={(name, value) => {
          handleChange(name, value)
        }}
      />
      <Radio.Group
        name="status"
        buttonStyle="solid"
        className="flex items-center custom-radio-button justify-end w-full lg:w-auto"
        onChange={(event) => {
          const { name, value } = event.target
          handleChange(name, value)
        }}
        defaultValue={filter.status || undefined}>
        <Radio.Button value={undefined}>{t('all')}</Radio.Button>
        <Radio.Button value={'1'}>{t('active')}</Radio.Button>
        <Radio.Button value={'9'}>{t('closed')}</Radio.Button>
      </Radio.Group>
    </div>
  )
}

export default HostsAdvancedFilter
