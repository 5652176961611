import Icon, {
  TwitterOutlined
} from '@ant-design/icons'
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined'
import {
  Avatar,
  Breadcrumb,
  Skeleton,
  Tabs
} from 'antd'
import {
  channel_getChannelsByCategory_Api,
  channel_getPromoteChannelsForEveryone_Api,
  dataset_getPromoteDatasetForEveryone_Api,
  feed_fetchNewsData_Api,
  get_editorChoiceWidgetFeeds_Api,
  get_typicalChartsFeeds_Api
} from 'apis'
import { feed_fetchSpotlightNewsData_Api } from 'apis/post'
import { createBaseItemSchema } from 'apis/schema'
import { createTransform } from 'apis/utils/createTransform'
import classNames from 'classnames'
import {
  API_ROOT_URL,
  ROOT_URL
} from 'envs/_current/config'
import {
  callNativeShare,
  createFacebookShareLink,
  createTwitterShareLink
} from 'helpers'
import { useQueryString } from 'hooks'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import CustomLazyPaginationWrapper from 'modules/asyncCache/components/CustomLazyPaginationWrapper'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import { useAppConfig } from 'modules/local'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {
  Suspense,
  useContext,
  useEffect,
  useState
} from 'react'
import {
  AiFillDownCircle,
  AiFillUpCircle
} from 'react-icons/ai'
import {
  FaFacebookF,
  FaShareAlt
} from 'react-icons/fa'
import {
  connect,
  useSelector
} from 'react-redux'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-use'
import {
  branch,
  compose,
  mapProps
} from 'recompose'
import { DelayRender } from 'views/Discovery/DelayRender'
import { dataset_getChartsByFollowingDataset_Api } from '../../apis'
import { event_getPromoteEventsForEveryone_Api } from '../../apis/auth'
import { event_getEventsByCategory_Api } from '../../apis/event'
import { iframes_getIframesByCategories_Api } from '../../apis/iframe'
import { product_getPromotedProductsByCategory_Api } from '../../apis/product'
import AppIcon from '../../components/AppIcon'
import EmptyHolder from '../../components/EmptyHolder'
import {
  GoogleAdsRecommendedUnitHorizontal,
  GoogleAdsRecommendedUnitHorizontalMob
} from '../../components/GoogleAds/GoogleAds'
import { ChannelIcon } from '../../components/icons/ChannelIcon'
import ImageLoader from '../../components/ImageLoader'
import { LayoutContext } from '../../components/layouts/Default/LayoutContext'
import { ContentLanguageSwitch } from '../../components/layouts/Default/SiderMenu/ContentLanguageSwitch'
import LazyComponent from '../../components/LazyComponent'
import { LoginContext } from '../../components/LoginContext'
import { SelectEntityItem } from '../../components/SelectEntityItem'
import StickyFrame from '../../components/StickyFrame'
import Tabbar, {
  TabbarItem
} from '../../components/Tabbar'
import Toggle from '../../components/Toggle'
import UseState from '../../components/UseState'
import { emptyObject } from '../../helpers/emptyObjects'
import { LocalContext } from '../../modules/local/LocalContext'
import { getLogin } from '../../redux/auth/selectors'
import TransformToCardProps from '../Feed/components/TransformToCardProps'
import { GetQuoteButton } from '../Market/components/GetQuoteButton'
import PageLink from '../Market/components/PageLink'
import { HeaderButton } from '../Organization/components/PageHeader'
import { validateServiceType } from '../Organization/functions/validateServiceType'
import { paths } from './contains'
import { renderChannelItem } from './functions/renderChannelItem'
import { renderChartItemRow } from './functions/renderChartItemRow'
import { renderDatasetItem } from './functions/renderDatasetItem'
import { renderEventItem } from './functions/renderEventItem'
import { renderFeedItem } from './functions/renderFeedItem'
import {
  renderHeadlineFeedItem,
  renderNewsItem,
  renderSpotlightItem
} from './functions/renderNewsItem'
import { renderWeatherItem } from './functions/renderWeatherItem'
import IndividualEmptyPlaceholder from './IndividualEmptyPlaceholder'
import ToggleView from './ToggleView'
import {AppHeader} from "./MainHeader";
import {AppTextLogoIcon} from "../../components/icons/AppTextLogoIcon";
import {HeaderBlock, SeeMore} from "./MySpace/Home";
import {RiCelsiusFill, RiCloudWindyLine, RiHailLine} from "react-icons/ri";
import {ModuleLayoutWrapper} from "../AppLocals/factory/createModule";
import categoryIcons from "../../conts/categoryIcons";
import {getId} from 'apis/model/base'
import {FaShare, FaXTwitter} from "react-icons/fa6";
import {BsCaretDownFill, BsCaretUpFill} from "react-icons/bs";
import {CalendarAvatar} from "../AppLocals/Event/MyEvents";
import {eventModel} from "../../apis/model";
import getLinkToDetail from "../../helpers/getLinkToDetail";
import getTitle from "../../helpers/getTitle";

const { TabPane } = Tabs

const Home = ({
  categoryId,
  refCategoryId = null
}) => {
  const t = useTranslate()
  const appConfig = useAppConfig()
  const {
    categoryEntities = {}
  } = appConfig || {
    hierarchy_categories: [],
    categoryEntities: {}
  }
  const location = useLocation()
  const history = useHistory()

  const { contentLang } = useContext(
    LocalContext
  )
  const login = useContext(LoginContext)
  const {
    following_languages_maximum = 1,
    ...loginProps
  } = login || emptyObject
  const [
    contentLanguages,
    setContentLanguages
  ] = useState(
    !!loginProps &&
      !_.isEmpty(
        loginProps.following_languages
      )
      ? loginProps.following_languages
      : [contentLang]
  )
  useEffect(() => {
    setContentLanguages(
      !!loginProps &&
        !_.isEmpty(
          loginProps.following_languages
        )
        ? loginProps.following_languages
        : [contentLang]
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login])

  let _tab = _.get(
    location,
    'hash',
    'timeline'
  ).replace('#', '')
  if (
    !_.includes(
      ['timeline', 'news', 'data'],
      _tab
    )
  ) {
    _tab = 'timeline'
  }
  const [
    { type = 'timeline' },
    go
  ] = useQueryString(location.pathname)
  const category =
    categoryEntities[categoryId] ||
    emptyObject

  if (!category) return null

  return (
    <div className="block grid grid-cols-1 md:grid-cols-12 w-full h-full md:gap-3 gap-y-3">
      <div className="h-full col-span-1 md:col-span-3">
        <StickyFrame>
          <div className="space-y-3">

              <CustomLazyPaginationWrapper
                  sample={2}
                  id={
                      event_getPromoteEventsForEveryone_Api.path +
                      '_category_' +
                      categoryId +
                      '_ref_category_' +
                      refCategoryId
                  }
                  key={
                      event_getPromoteEventsForEveryone_Api.path +
                      '_category_' +
                      categoryId +
                      '_ref_category_' +
                      refCategoryId
                  }
                  apiInfo={
                      event_getPromoteEventsForEveryone_Api
                  }
                  values={{
                      category_id: categoryId,
                      ref_category_id: refCategoryId
                  }}
                  renderLoading={() => (
                      <div className="w-full flex justify-center">
                          <Skeleton
                              className="p-3"
                              active
                          />
                      </div>
                  )}
                  renderItem={(item) => (
                      <div className="flex items-center gap-2 border border-color-50 hover:shadow-items-md rounded-md">
                          <CalendarAvatar time={item.time_open} />
                          <div className="flex flex-col gap-2 flex-1 mr-2">
                              <Link
                                  to={eventModel.getLinkToDetail(item)}
                                  className="font-bold text-sm text-color-000 max-lines-2 no-underline leading-snug">
                                  {item.title}
                              </Link>
                              <div className="space-x-1 text-xs italic">
                                  <span className="text-color-400">
                                    {t('organized by')}
                                  </span>
                                  <Link to={getLinkToDetail(item.owner)} className="font-bold text-primary">
                                      {getTitle(item.owner)}
                                  </Link>
                              </div>
                          </div>
                      </div>
                  )}
                  renderEmpty={() => (
                      <EmptyHolder
                          title={null}
                          subTitle={t(
                              'No event'
                          )}
                      />
                  )}
                  renderWrap={children => (
                      <div className="space-y-3">
                          <HeaderBlock title="events in category" />
                          {children}
                          <div className="bottom-0 flex w-full">
                              <SeeMore
                                  onClick={() => {
                                      go({
                                          type: 'news'
                                      })
                                  }}
                              />
                          </div>
                      </div>
                  )}
              />

              <CustomLazyPaginationWrapper
                  sample={2}
                  id={
                      channel_getPromoteChannelsForEveryone_Api.path +
                      '_category_' +
                      categoryId +
                      '_ref_category_' +
                      refCategoryId +
                      '_languages_' +
                      contentLanguages.join(
                          ','
                      )
                  }
                  key={
                      channel_getPromoteChannelsForEveryone_Api.path +
                      '_category_' +
                      categoryId +
                      '_ref_category_' +
                      refCategoryId +
                      '_languages_' +
                      contentLanguages.join(
                          ','
                      )
                  }
                  apiInfo={
                      channel_getPromoteChannelsForEveryone_Api
                  }
                  values={{
                      category_id: categoryId,
                      ref_category_id: refCategoryId,
                      languages: contentLanguages.join(
                          ','
                      )
                  }}
                  renderLoading={() => (
                      <div className="w-full flex justify-center">
                          <Skeleton
                              className="p-3"
                              active
                          />
                      </div>
                  )}
                  renderItem={
                      renderChannelItem
                  }
                  renderEmpty={() => (
                      <EmptyHolder
                          title={null}
                          subTitle={t(
                              'No channel being followed'
                          )}
                      />
                  )}
                  renderWrap={children => (
                      <div className="space-y-3">
                          <HeaderBlock
                              title="channels in category"
                              extra={
                                  <ContentLanguageSwitch
                                      following_languages_maximum={
                                          following_languages_maximum
                                      }
                                      languages={
                                          contentLanguages
                                      }
                                      setLanguages={
                                          setContentLanguages
                                      }
                                  />
                              }
                          />
                          {children}
                          <div className="bottom-0 flex w-full">
                              <SeeMore
                                  onClick={() => {
                                      go({
                                          type: 'news'
                                      })
                                  }}
                              />
                          </div>
                      </div>
                  )}
              />

              <CustomLazyPaginationWrapper
                  sample={2}
                  key={
                      dataset_getPromoteDatasetForEveryone_Api.path +
                      '_category_' +
                      categoryId +
                      '_ref_category_' +
                      refCategoryId
                  }
                  id={
                      dataset_getPromoteDatasetForEveryone_Api.path +
                      '_category_' +
                      categoryId +
                      '_ref_category_' +
                      refCategoryId
                  }
                  apiInfo={
                      dataset_getPromoteDatasetForEveryone_Api
                  }
                  values={{
                      category_id: categoryId,
                      ref_category_id: refCategoryId
                  }}
                  renderLoading={() => (
                      <div className="w-full flex justify-center">
                          <Skeleton
                              className="p-3"
                              active
                          />
                      </div>
                  )}
                  renderItem={
                      renderDatasetItem
                  }
                  renderEmpty={() => (
                      <EmptyHolder
                          title={null}
                          subTitle={t(
                              'No data set are being followed'
                          )}
                      />
                  )}
                  renderWrap={children => (
                      <div className="space-y-3">
                          <HeaderBlock title="data in category" />
                          {children}
                          <div className="bottom-0 flex w-full">
                              <SeeMore
                                  onClick={() => {
                                      go({
                                          type: 'data'
                                      })
                                  }}
                              />
                          </div>
                      </div>
                  )}
              />

              <Toggle>
                  {(isToggle, toggle) => (
                      <div className="space-y-3 block md:hidden">
                          <div className="flex uppercase font-bold text-color-300 flex-1 truncate">
                      <span className="flex-1 font-bold text-primary truncate uppercase tracking-wide">
                          {t('related keywords')}
                      </span>
                              <Avatar
                                  size={24}
                                  className="flex items-center justify-center bg-primary-50 rounded"
                                  onClick={() => {toggle()}}
                                  icon={isToggle ? <BsCaretUpFill className="text-primary-600"/> : <BsCaretDownFill className="text-primary-600"/>}
                              />
                          </div>
                          <nav
                              className={classNames(
                                  'flex-1 flex-row bg-transparent border-gray-300 font-semibold',
                                  isToggle
                                      ? 'w-full overflow-hidden'
                                      : 'flex overflow-auto pb-3'
                              )}>
                              <DelayRender key={categoryId}>
                                  {() => _.get(category, 'keywords', []).map((kw, i) => (
                                      <a
                                          href={`/t/${kw.idname}`}
                                          key={kw.idname}
                                          id={kw.idname + i}
                                          style={{ padding: '4px 10px' }}
                                          className="inline-block truncate flex-shrink-0 rounded-md border w-full">
                                          #{kw.keyword}
                                      </a>
                                  ))}
                              </DelayRender>
                          </nav>
                      </div>
                  )}
              </Toggle>

              <GoogleAdsRecommendedUnitHorizontalMob />

              <LazyComponent>
                  <Suspense fallback={null}>
                      <WidgetListByCategory
                          categoryId={
                              categoryId
                          }
                      />
                  </Suspense>
              </LazyComponent>

          </div>
        </StickyFrame>
      </div>
      <div className="h-full col-span-1 md:col-span-9">
        <div className="block grid grid-cols-1 md:grid-cols-2 w-full md:gap-3 gap-y-3">
          <div className="h-full">
            <StickyFrame className="space-y-5">
              <React.Fragment>
                <CustomLazyPaginationWrapper
                  auto={false}
                  alwaysVisible={false}
                  sample={1}
                  id={
                    get_editorChoiceWidgetFeeds_Api.path +
                    '_category_' +
                    categoryId +
                    '_ref_category_' +
                    refCategoryId +
                    '_following_0'
                  }
                  key={
                    get_editorChoiceWidgetFeeds_Api.path +
                    '_category_' +
                    categoryId +
                    '_ref_category_' +
                    refCategoryId
                  }
                  apiInfo={
                    get_editorChoiceWidgetFeeds_Api
                  }
                  values={{
                    following: 0,
                    category_id: categoryId,
                    ref_category_id: refCategoryId
                  }}
                  renderLoading={() => (
                    <div className="w-full flex justify-center">
                      <Skeleton
                        className="p-3"
                        active
                      />
                    </div>
                  )}
                  renderItem={
                    renderNewsItem
                  }
                  renderEmpty={() => (
                    <EmptyHolder />
                  )}
                  renderWrap={children => (
                    <div className="space-y-3">
                        <HeaderBlock title="editor choices" />
                        {children}
                        <div className="bottom-0 flex w-full">
                            <SeeMore
                                onClick={() => {
                                    go({type: 'data'})
                                }}
                            />
                        </div>
                    </div>
                  )}
                />
              </React.Fragment>

              <React.Fragment>
                <ToggleView
                  renderHeadlineView={
                    renderHeadlineFeedItem
                  }
                  renderListView={
                    renderFeedItem
                  }>
                  {({
                    switchComponent,
                    renderItem,
                    setView,
                    view
                  }) => (
                    <CustomLazyPaginationWrapper
                      auto={false}
                      alwaysVisible={
                        false
                      }
                      sample={5}
                      id={
                        get_typicalChartsFeeds_Api.path +
                        '_category_' +
                        categoryId +
                        '_view_' +
                        view +
                        '_ref_category_' +
                        refCategoryId +
                        '_following_0'
                      }
                      apiInfo={
                        get_typicalChartsFeeds_Api
                      }
                      values={
                        categoryId
                          ? {
                              following: 0,
                              view: view,
                              category_id: categoryId,
                              ref_category_id: refCategoryId
                            }
                          : {
                              view: view,
                              following: 0
                            }
                      }
                      renderLoading={() => (
                        <div className="w-full flex justify-center">
                          <Skeleton
                            className="p-3"
                            active
                          />
                        </div>
                      )}
                      renderItem={
                        renderItem
                      }
                      renderEmpty={() => (
                        <EmptyHolder />
                      )}
                      renderWrap={children => (
                        <div className="space-y-3">
                            <HeaderBlock title="featured charts" extra={switchComponent && switchComponent}/>
                          {children}
                          <div className="bottom-0 flex w-full">
                              <SeeMore
                                  onClick={() => {
                                      go({
                                          type:
                                              'data'
                                      })
                                  }}
                              />
                          </div>
                        </div>
                      )}
                    />
                  )}
                </ToggleView>
              </React.Fragment>
            </StickyFrame>
          </div>
          <div className="h-full">
            <StickyFrame className="space-y-3">
              <WeatherList
                categoryId={null}
                refCategoryId={null}
              />

              <PromotedProductList
                categoryId={categoryId}
                refCategoryId={
                  refCategoryId
                }
              />

              <GoogleAdsRecommendedUnitHorizontal />

              <ToggleView
                renderHeadlineView={
                  renderHeadlineFeedItem
                }
                renderListView={
                  renderNewsItem
                }>
                {({
                  switchComponent,
                  renderItem
                }) => (
                  <CustomLazyPaginationWrapper
                    auto={false}
                    sample={10}
                    id={
                      feed_fetchNewsData_Api.path +
                      '_category_' +
                      categoryId +
                      '_ref_category_' +
                      refCategoryId +
                      '_languages_' +
                      contentLanguages.join(
                        ','
                      )
                    }
                    key={
                      feed_fetchNewsData_Api.path +
                      '_category_' +
                      categoryId +
                      '_ref_category_' +
                      refCategoryId +
                      '_languages_' +
                      contentLanguages.join(
                        ','
                      )
                    }
                    apiInfo={
                      feed_fetchNewsData_Api
                    }
                    values={{
                      category_id: categoryId,
                      ref_category_id: refCategoryId,
                      languages: contentLanguages.join(
                        ','
                      )
                    }}
                    renderLoading={() => (
                      <div className="w-full flex justify-center">
                        <Skeleton
                          className="p-3"
                          active
                        />
                      </div>
                    )}
                    renderItem={
                      renderItem
                    }
                    renderEmpty={() => (
                      <div className="w-full justify-center items-center border rounded">
                        <EmptyHolder
                          title={null}
                        />
                      </div>
                    )}
                    renderWrap={children => (
                      <div className="space-y-3">
                          <HeaderBlock
                              title="the latest news"
                              extra={
                                  <div className="flex items-center gap-2">
                                      <ContentLanguageSwitch
                                          following_languages_maximum={following_languages_maximum}
                                          languages={contentLanguages}
                                          setLanguages={setContentLanguages}
                                      />
                                      {switchComponent && switchComponent}
                                  </div>
                              }
                          />
                          {children}
                          <div className="bottom-0 flex w-full">
                              <SeeMore
                                  onClick={() => {go({type: 'news'})}}
                              />
                          </div>
                      </div>
                    )}
                  />
                )}
              </ToggleView>
            </StickyFrame>
          </div>
        </div>
      </div>
    </div>
  )
}

export const WeatherList = ({
  categoryId,
  refCategoryId = null
}) => {
  const t = useTranslate()

  return (
    <div className="flex flex-col">
        <HeaderBlock title="weather"/>
        <Tabs defaultActiveKey={1}>
            {[
                {
                    id: 'temperature',
                    name: 'temperature',
                    icon: <RiCelsiusFill size={17} />,
                    title: 'Temperature - weather, tap to see more'
                },
                {
                    id: 'rain',
                    name: 'rain',
                    icon: <RiHailLine size={17} />,
                    title: 'Rain (thunder) - weather, tap to see more'
                },
                {
                    id: 'wind',
                    name: 'wind',
                    icon: <RiCloudWindyLine size={17} />,
                    title: 'Wind - weather, tap to see more'
                }
            ].map((e, i) => (
                <TabPane
                    key={i}
                    tab={
                        <div className="flex items-baseline gap-2">
                            {e.icon}
                            <span className="font-medium text-sm">{t(e.name)}</span>
                        </div>
                    }>
                    <div key={i} className="flex flex-col gap-1">
                        <div className="flex text-sm text-color-300 italic">
                            {t(e.title)}
                        </div>
                        <div className="p-2 flex items-center justify-between border border-color-50 background hover:shadow-items rounded-lg">
                            <LazyPagination
                                id={
                                    iframes_getIframesByCategories_Api.path +
                                    '_category_' +
                                    categoryId +
                                    '_ref_category_' +
                                    refCategoryId +
                                    '_type_' +
                                    e.id
                                }
                                key={
                                    iframes_getIframesByCategories_Api.path +
                                    '_category_' +
                                    categoryId +
                                    '_ref_category_' +
                                    refCategoryId +
                                    '_type_' +
                                    e.id
                                }
                                apiInfo={
                                    iframes_getIframesByCategories_Api
                                }
                                query={{
                                    ':category_id': categoryId,
                                    ':ref_category_id': refCategoryId,
                                    ':category_type':
                                    e.id
                                }}
                                values={{
                                    category_id: categoryId,
                                    ref_category_id: refCategoryId,
                                    category_type: e.id
                                }}
                                renderLoading={() => (
                                    <div className="w-full flex justify-center">
                                        <Skeleton
                                            className="p-3"
                                            active
                                        />
                                    </div>
                                )}
                                renderItem={renderWeatherItem}
                                renderEmpty={null}
                            />
                        </div>
                    </div>
                </TabPane>
            ))}
        </Tabs>
    </div>
  )
}

const PromotedProductList = ({
  categoryId,
  refCategoryId = null
}) => {
  const t = useTranslate()

  return (
    <CustomLazyPaginationWrapper
      sample={2}
      id={
        product_getPromotedProductsByCategory_Api.path +
        '_category_' +
        categoryId +
        '_ref_category_' +
        refCategoryId
      }
      key={
        product_getPromotedProductsByCategory_Api.path +
        '_category_' +
        categoryId +
        '_ref_category_' +
        refCategoryId
      }
      apiInfo={
        product_getPromotedProductsByCategory_Api
      }
      query={{
        ':id': categoryId
      }}
      renderLoading={() => (
          <div className="w-full flex justify-center">
              <Skeleton
                  className="p-3"
                  active
              />
          </div>
      )}
      renderItem={(e, i) => (
          <div
              key={i}
              className="background-100 w-full col-span-1 border h-full border-gray-200 flex flex-col">
              <div
                  style={{
                      paddingTop: '100%'
                  }}
                  className="w-full  overflow-hidden relative">
                  <ImageLoader
                      className="w-full h-full object-cover absolute left-0 top-0"
                      src={e.avatar}
                  />
              </div>
              <div className="space-y-3 py-3 flex-1 flex flex-col md:text-lg text-sm">
                  <h2 className="px-3 font-medium">
                      <PageLink
                          to={
                              '/product/' +
                              e.idname
                          }>
                          {e.title}
                      </PageLink>
                  </h2>

                  <div className="px-3 flex-1"></div>
                  <div className="px-3">
                      <GetQuoteButton
                          {...{
                              t,
                              item: e
                          }}>
                          {t('Get Best Price')}
                      </GetQuoteButton>
                  </div>
              </div>
          </div>
      )}
      renderWrap={children => (
          <div className="space-y-3">
              <HeaderBlock title="related products" />
              <div className="grid grid-cols-2 gap-3">
                  {children}
              </div>
          </div>
      )}
    />
  )
}

const NewsChannelsList = ({
  categoryId,
  refCategoryId = null
}) => {
  const t = useTranslate()
  const { isSm } = useContext(
    LayoutContext
  )
  const [limit, setLimit] = useState(3)

  return (
    <div className="space-y-3">
        <HeaderBlock title="channels in category" />
        <div className="space-y-3">
            <LazyPagination
                sample={limit}
                key={
                    channel_getChannelsByCategory_Api.path +
                    '_category_' +
                    categoryId +
                    '_ref_category_' +
                    refCategoryId
                }
                apiInfo={
                    channel_getChannelsByCategory_Api
                }
                query={{
                    ':category_id': categoryId,
                    ':ref_category_id': refCategoryId
                }}
                renderLoading={() => (
                    <div className="w-full flex justify-center">
                        <Skeleton
                            className="p-3"
                            active
                        />
                    </div>
                )}
                renderItem={renderChannelItem}
                renderEmpty={() => (
                    <EmptyHolder
                        icon={() => (
                            <div
                                style={{
                                    fontSize: '2rem',
                                    color:
                                        'rgba(226, 232, 240, 1)'
                                }}>
                                <ChannelIcon />
                            </div>
                        )}
                        title={null}
                        subTitle={t(
                            'no channels'
                        )}
                    />
                )}
            />
        </div>
        {limit > 0 && (
            <SeeMore
                onClick={() => {
                    setLimit(0)
                }}
            />
        )}
    </div>
  )
}

const NewsEventsList = ({
  categoryId,
  refCategoryId = null
}) => {
  const t = useTranslate()
  const { isSm } = useContext(
    LayoutContext
  )
  const [limit, setLimit] = useState(3)

  return (
    <div className="space-y-3">
        <HeaderBlock title="events in category" />
        <div className="space-y-3">
            <LazyPagination
                sample={limit}
                key={
                    event_getEventsByCategory_Api.path +
                    '_category_' +
                    categoryId +
                    '_ref_category_' +
                    refCategoryId
                }
                apiInfo={
                    event_getEventsByCategory_Api
                }
                query={{
                    ':category_id': categoryId,
                    ':ref_category_id': refCategoryId
                }}
                renderLoading={() => (
                    <div className="w-full flex justify-center">
                        <Skeleton
                            className="p-3"
                            active
                        />
                    </div>
                )}
                renderItem={(item) => (
                    <div className="flex items-center gap-2 border border-color-50 hover:shadow-items-md rounded-md">
                        <CalendarAvatar time={item.time_open} />
                        <div className="flex flex-col gap-2 flex-1 mr-2">
                            <Link
                                to={eventModel.getLinkToDetail(item)}
                                className="font-bold text-sm text-color-000 max-lines-2 no-underline leading-snug">
                                {item.title}
                            </Link>
                            <div className="space-x-1 text-xs italic">
                                  <span className="text-color-400">
                                    {t('organized by')}
                                  </span>
                                <Link to={getLinkToDetail(item.owner)} className="font-bold text-primary">
                                    {getTitle(item.owner)}
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
                renderEmpty={() => (
                    <EmptyHolder
                        title={null}
                        subTitle={t('No event')}
                    />
                )}
            />
        </div>
        {limit > 0 && (
            <SeeMore
                onClick={() => {
                    setLimit(0)
                }}
            />
        )}
    </div>
  )
}

const NewsLatestByCategory = ({
  categoryId,
  refCategoryId = null
}) => {
  const t = useTranslate()
  const { contentLang } = useContext(
    LocalContext
  )
  const {
    following_languages_maximum = 1,
    ...loginProps
  } = useSelector(getLogin) || emptyObject

  const [
    contentLanguages,
    setContentLanguages
  ] = useState(
    !!loginProps &&
      !_.isEmpty(
        loginProps.following_languages
      )
      ? loginProps.following_languages
      : [contentLang]
  )

  return (
    <div className="space-y-3">
      <ToggleView
        renderHeadlineView={renderHeadlineFeedItem}
        renderListView={renderNewsItem}>
        {({ switchComponent, renderItem }) => (
          <React.Fragment>
              <HeaderBlock
                  title="the latest news"
                  extra={
                      <div className="flex items-center gap-2">
                          <ContentLanguageSwitch
                              following_languages_maximum={
                                  following_languages_maximum
                              }
                              languages={
                                  contentLanguages
                              }
                              setLanguages={
                                  setContentLanguages
                              }
                          />
                          {switchComponent && switchComponent}
                      </div>
                  }
              />
              <LazyPagination
                  id={
                      feed_fetchNewsData_Api.path +
                      '_category_' +
                      categoryId +
                      '_ref_category_' +
                      refCategoryId +
                      '_LANGUAGE_' +
                      contentLanguages.join(
                          ','
                      )
                  }
                  key={
                      feed_fetchNewsData_Api.path +
                      '_category_' +
                      categoryId +
                      '_ref_category_' +
                      refCategoryId +
                      '_LANGUAGE_' +
                      contentLanguages.join(
                          ','
                      )
                  }
                  apiInfo={
                      feed_fetchNewsData_Api
                  }
                  values={{
                      category_id: categoryId,
                      ref_category_id: refCategoryId,
                      languages: contentLanguages.join(',')
                  }}
                  renderLoading={() => (
                      <div className="w-full flex justify-center">
                          <Skeleton
                              className="p-3"
                              active
                          />
                      </div>
                  )}
                  renderItem={renderItem}
                  renderEmpty={() => <EmptyHolder />}
              />
          </React.Fragment>
        )}
      </ToggleView>
    </div>
  )
}

const NewsSpotlightByCategory = ({
  categoryId,
  refCategoryId = null
}) => {
  return (
    <div className="space-y-3">
      <LazyPagination
        key={
          feed_fetchSpotlightNewsData_Api.path +
          '_category_' +
          categoryId +
          '_ref_category_' +
          refCategoryId
        }
        apiInfo={
          feed_fetchSpotlightNewsData_Api
        }
        values={{
          category_id: categoryId,
          ref_category_id: refCategoryId
        }}
        renderLoading={() => (
          <div className="w-full flex justify-center">
            <Skeleton
              className="p-3"
              active
            />
          </div>
        )}
        renderItem={renderSpotlightItem}
        renderEmpty={() => <EmptyHolder />}
      />
    </div>
  )
}

const News = ({
  categoryId,
  refCategoryId = null
}) => {
  return (
    <div className="block grid grid-cols-1 md:grid-cols-12 w-full h-full md:gap-3 gap-y-3">
      <div className="col-span-1 md:col-span-3 space-y-3">
        <StickyFrame className="space-y-3">
          <NewsEventsList
            categoryId={categoryId}
            refCategoryId={
              refCategoryId
            }
          />
          <NewsChannelsList
            categoryId={categoryId}
            refCategoryId={
              refCategoryId
            }
          />
        </StickyFrame>
      </div>

      <div className="col-span-1 md:col-span-9">
        <div className="block grid grid-cols-1 md:grid-cols-12 w-full h-full md:gap-3 gap-y-3">
          <div className="col-span-1 md:col-span-6 space-y-3">
            <StickyFrame className="space-y-3">
              <NewsSpotlightByCategory
                categoryId={categoryId}
                refCategoryId={
                  refCategoryId
                }
              />
            </StickyFrame>
          </div>

          <div className="col-span-1 md:col-span-6 space-y-3">
            <StickyFrame>
              <NewsLatestByCategory
                categoryId={categoryId}
                refCategoryId={refCategoryId}
              />
            </StickyFrame>
          </div>
        </div>
      </div>
    </div>
  )
}

const DataPromotedDatasetList = ({
  categoryId,
  refCategoryId = null
}) => {
  const t = useTranslate()
  const { isSm } = useContext(
    LayoutContext
  )
  const [limit, setLimit] = useState(3)
  return (
    <CustomLazyPaginationWrapper
      sample={limit}
      id={
        dataset_getPromoteDatasetForEveryone_Api.path +
        '_category_' +
        categoryId +
        '_ref_category_' +
        refCategoryId
      }
      key={
        dataset_getPromoteDatasetForEveryone_Api.path +
        '_category_' +
        categoryId +
        '_ref_category_' +
        refCategoryId
      }
      apiInfo={
        dataset_getPromoteDatasetForEveryone_Api
      }
      values={{
        category_id: categoryId,
        ref_category_id: refCategoryId
      }}
      renderLoading={() => (
        <div className="w-full flex justify-center">
          <Skeleton
            className="p-3"
            active
          />
        </div>
      )}
      renderItem={renderDatasetItem}
      renderEmpty={() => <EmptyHolder title={null} />}
      renderWrap={children => (
        <div className="space-y-3">
            <HeaderBlock
                title="data in category"
            />
            {children}
            {limit > 0 && (
                <SeeMore
                    onClick={() => {
                        setLimit(0)
                    }}
                />
            )}
        </div>
      )}
    />
  )
}

const DataEditorsChoiceFeeds = ({
  categoryId,
  refCategoryId = null
}) => {
  const t = useTranslate()

  return (
    <UseState initialValue={1}>
      {([limit, setLimit]) => (
        <CustomLazyPaginationWrapper
          sample={limit}
          id={
            get_editorChoiceWidgetFeeds_Api.path +
            '_category_' +
            categoryId +
            '_ref_category_' +
            refCategoryId
          }
          key={
            get_editorChoiceWidgetFeeds_Api.path +
            '_category_' +
            categoryId +
            '_ref_category_' +
            refCategoryId +
            '_following_0'
          }
          apiInfo={
            get_editorChoiceWidgetFeeds_Api
          }
          values={{
            following: 0,
            category_id: categoryId,
            ref_category_id: refCategoryId
          }}
          renderLoading={() => (
            <div className="w-full flex justify-center">
              <Skeleton
                className="p-3"
                active
              />
            </div>
          )}
          renderItem={renderNewsItem}
          renderEmpty={() => <EmptyHolder title={null} />}
          renderWrap={children => {
            return (
              <div className="space-y-3">
                  <HeaderBlock
                      title="editor choices"
                  />
                  {children}
                  {limit > 0 && (
                      <SeeMore
                          onClick={() => {
                              setLimit(0)
                          }}
                      />
                  )}
              </div>
            )
          }}
        />
      )}
    </UseState>
  )
}
const DataTypicalChartFeeds = ({
  categoryId,
  refCategoryId = null
}) => {
  const t = useTranslate()
  return (
    <ToggleView
      renderHeadlineView={renderHeadlineFeedItem}
      renderListView={renderFeedItem}>
      {({
        switchComponent,
        renderItem,
        setView,
        view
      }) => (
        <div className="space-y-3">
            <HeaderBlock title="featured charts" extra={switchComponent && switchComponent}/>
            <LazyPagination
                key={
                    get_typicalChartsFeeds_Api.path +
                    '_category_' +
                    categoryId +
                    '_view_' +
                    view +
                    '_ref_category_' +
                    refCategoryId +
                    '_following_0'
                }
                apiInfo={
                    get_typicalChartsFeeds_Api
                }
                values={
                    categoryId
                        ? {
                            following: 0,
                            view: view,
                            category_id: categoryId,
                            ref_category_id: refCategoryId
                        }
                        : {
                            view: view,
                            following: 0
                        }
                }
                renderLoading={() => (
                    <div className="w-full flex justify-center">
                        <Skeleton
                            className="p-3"
                            active
                        />
                    </div>
                )}
                renderItem={renderItem}
                renderEmpty={() => <EmptyHolder title={null} />}
            />
        </div>
      )}
    </ToggleView>
  )
}
const WidgetListByCategory = ({
  categoryId,
  refCategoryId = null
}) => {
  const t = useTranslate()
  const path = `/widgets/container/category/${categoryId}/gets`
  return (
    <div className="">
      <Async
        key={path}
        apiInfo={{
          root: API_ROOT_URL,
          path: path,
          transform: createTransform(
            createBaseItemSchema({
              idAttribute: () => path
            }),
            'data'
          )
        }}
      />
      <StickyFrame className="space-y-6">
        <SelectEntityItem item={path}>
          {item =>
            (item || null) &&
            !!_.get(
              item,
              'data.length'
            ) && (
              <div className="space-y-3">
                  <HeaderBlock title="overview" />
                  {item.data.map(
                      (data, i) => (
                          <UseState
                              key={i}
                              initialValue={
                                  '0'
                              }>
                              {([
                                    activeKey,
                                    setActiveKey
                                ]) => {
                                  return (
                                      <TransformToCardProps
                                          key={i}
                                          data={data}
                                      />
                                  )
                              }}
                          </UseState>
                      )
                  )}
              </div>
            )
          }
        </SelectEntityItem>
      </StickyFrame>
    </div>
  )
}
const DataOverviewWidget = ({
  categoryId,
  refCategoryId = null
}) => {
  const t = useTranslate()
  return (
    <div className="space-y-3">
      <DelayRender
        lazy={true}
        time={50}>
        {() => (
          <LazyComponent>
            <Suspense fallback={null}>
              <WidgetListByCategory
                categoryId={categoryId}
                refCategoryId={
                  refCategoryId
                }
              />
            </Suspense>
          </LazyComponent>
        )}
      </DelayRender>
    </div>
  )
}

const DataMyFollowingChartList = ({
  categoryId,
  refCategoryId = null
}) => {
  const t = useTranslate()
  const key = categoryId

  return (
    <UseState initialValue={3}>
      {([limit, setLimit]) => (
        <CustomLazyPaginationWrapper
          auto={false}
          sample={limit}
          id={
            key +
            dataset_getChartsByFollowingDataset_Api.path +
            '_category_' +
            categoryId +
            '_ref_category_' +
            refCategoryId
          }
          key={
            key +
            dataset_getChartsByFollowingDataset_Api.path +
            '_category_' +
            categoryId +
            '_ref_category_' +
            refCategoryId
          }
          apiInfo={
            dataset_getChartsByFollowingDataset_Api
          }
          values={
            categoryId
              ? {
                  category_id: categoryId,
                  ref_category_id: refCategoryId
                }
              : {}
          }
          renderLoading={() => (
            <div className="w-full h-screen flex justify-center">
              <Skeleton
                className="p-3"
                active
              />
            </div>
          )}
          renderItem={
            renderChartItemRow
          }
          renderEmpty={() => <EmptyHolder />}
          renderWrap={children => (
            <div className="space-y-3">
                <HeaderBlock title="following charts" />
                {children}
                {limit > 0 && (
                    <SeeMore
                        onClick={() => {
                            setLimit(0)
                        }}
                    />
                )}
            </div>
          )}
        />
      )}
    </UseState>
  )
}

const Data = ({
  categoryId,
  refCategoryId = null
}) => {
  return (
    <div className="block grid grid-cols-1 md:grid-cols-12 w-full h-full md:gap-3 gap-y-3">
      <div className="col-span-1 md:col-span-3 space-y-3">
        <StickyFrame className="space-y-3">
          <DataPromotedDatasetList
            categoryId={categoryId}
            refCategoryId={
              refCategoryId
            }
          />

          <DataOverviewWidget
            categoryId={categoryId}
            refCategoryId={
              refCategoryId
            }
          />
        </StickyFrame>
      </div>

      <div className="col-span-1 md:col-span-9">
        <div className="block grid grid-cols-1 md:grid-cols-12 w-full h-full md:gap-3 gap-y-3">
          <div className="col-span-1 md:col-span-6 space-y-3">
            <StickyFrame>
              <DataEditorsChoiceFeeds
                categoryId={categoryId}
                refCategoryId={
                  refCategoryId
                }
              />
            </StickyFrame>
          </div>

          <div className="col-span-1 md:col-span-6 space-y-3">
            <StickyFrame>
              <DataMyFollowingChartList
                categoryId={categoryId}
                refCategoryId={
                  refCategoryId
                }
              />

              <DataTypicalChartFeeds
                categoryId={categoryId}
                refCategoryId={
                  refCategoryId
                }
              />
            </StickyFrame>
          </div>
        </div>
      </div>
    </div>
  )
}

const NewsWrapper = compose(
  connect(state => ({
    login: !!getLogin(state)
  })),
  branch(
    props => !props.login,
    () => () => (
      <IndividualEmptyPlaceholder />
    )
  )
)(({ categoryId, refCategoryId }) => {
  return (
    <News
      key={categoryId + refCategoryId}
      {...{ categoryId, refCategoryId }}
    />
  )
})

const DataWrapper = compose(
  connect(state => ({
    login: !!getLogin(state)
  })),
  branch(
    props => !props.login,
    () => () => (
      <IndividualEmptyPlaceholder />
    )
  )
)(({ categoryId, refCategoryId }) => {
  return (
    <Data
      key={categoryId + refCategoryId}
      {...{ categoryId, refCategoryId }}
    />
  )
})

const headerConfigs = [
  {
    id: 'timeline',
    tabComponent: (
      <Translate>
        {t => (
          <React.Fragment>
            {t('timeline')}
          </React.Fragment>
        )}
      </Translate>
    ),
    renderContent: ({
      categoryId,
      refCategoryId
    }) => (
      <Home
        key={categoryId + refCategoryId}
        {...{
          categoryId,
          refCategoryId
        }}
      />
    )
  },
  {
    id: 'news',
    tabComponent: (
      <Translate>
        {t => (
          <React.Fragment>
            {t('news')}
          </React.Fragment>
        )}
      </Translate>
    ),
    renderContent: ({
      categoryId,
      refCategoryId
    }) => (
      <NewsWrapper
        key={categoryId + refCategoryId}
        {...{
          categoryId,
          refCategoryId
        }}
      />
    ),
    valid: ['login']
  },
  {
    id: 'data',
    tabComponent: (
      <Translate>
        {t => (
          <React.Fragment>
            {t('data')}
          </React.Fragment>
        )}
      </Translate>
    ),
    renderContent: ({
      categoryId,
      refCategoryId
    }) => (
      <DataWrapper
        key={categoryId + refCategoryId}
        {...{
          categoryId,
          refCategoryId
        }}
      />
    )
  }
]
const headerMobConfigs = [
  {
    id: 'timeline',
    tabComponent: (
      <Translate>
        {t => (
          <React.Fragment>
            {t('timeline')}
          </React.Fragment>
        )}
      </Translate>
    ),
    renderContent: ({
      categoryId,
      refCategoryId
    }) => (
      <Home
        key={categoryId + refCategoryId}
        {...{
          categoryId,
          refCategoryId
        }}
      />
    )
  },
  {
    id: 'news',
    tabComponent: (
      <Translate>
        {t => (
          <React.Fragment>
            {t('news')}
          </React.Fragment>
        )}
      </Translate>
    ),
    renderContent: ({
      categoryId,
      refCategoryId
    }) => (
      <NewsWrapper
        key={categoryId + refCategoryId}
        {...{
          categoryId,
          refCategoryId
        }}
      />
    ),
    valid: ['login']
  },
  {
    id: 'data',
    tabComponent: (
      <Translate>
        {t => (
          <React.Fragment>
            {t('data')}
          </React.Fragment>
        )}
      </Translate>
    ),
    renderContent: ({
      categoryId,
      refCategoryId
    }) => (
      <DataWrapper
        key={categoryId + refCategoryId}
        {...{
          categoryId,
          refCategoryId
        }}
      />
    )
  }
]

let _tabs = ['timeline', 'news', 'data']

const Category = ({
  categoryId,
  refCategoryId
}) => {
  const t = useTranslate()
  const { isSm } = useContext(
    LayoutContext
  )
  const appConfig = useAppConfig()
  const {
    categoryEntities = {}
  } = appConfig || {
    categoryEntities: {}
  }
  const location = useLocation()
  const [
    { type = 'timeline' },
    go
  ] = useQueryString(location.pathname)
  const category =
    categoryEntities[categoryId] ||
    emptyObject
  const { breadcrumbs = [] } = category

  if (category) {
    if (
      !validateServiceType(
        _.get(category, 'service_type'),
        'dataset'
      )
    ) {
      _.pull(_tabs, 'timeline')
      _.pull(_tabs, 'data')
    }
  }
  const [
    currentTab,
    setCurrentTab
  ] = useState(type)
  useEffect(() => {
    if (type !== currentTab) {
      setCurrentTab(type)
    }
  }, [type, currentTab])

  if (!category) return null

  const tabitems = (isSm
    ? headerMobConfigs
    : headerConfigs
  )
    .filter(e => _tabs.includes(e.id))
    .filter(item => !!item)

  let activeKey = tabitems.findIndex(
    item => item.id === currentTab
  )

    const Icon = _.get(categoryIcons, getId(category), categoryIcons['default'])

  if (activeKey < 0) activeKey = 0

  return (
    <ModuleLayoutWrapper
      Header={AppHeader}
      visibleLeftDrawer={true}>
      <div className="p-3 flex flex-col gap-3">
          <header className="space-y-2">
              <div className="flex items-center gap-2 md:gap-6">
                  <Icon size={60}/>
                  <div className="flex flex-col gap-1 flex-1">
                      <div className="font-bold text-base md:text-lg text-color-000 leading-tight">
                          {category.title}
                      </div>
                      <div className="overflow-auto">
                          <Breadcrumb className="flex flex-1 font-semibold text-xs md:text-sm italic whitespace-no-wrap">
                              {breadcrumbs.map(id => {
                                  const {
                                      idname,
                                      alt_idname,
                                      language,
                                      name
                                  } = categoryEntities[id]
                                  return (
                                      <Breadcrumb.Item key={idname}>
                                          <Link
                                              className="text-color-300 no-underline"
                                              to={
                                                  paths.categoryPath
                                                      .replace(':category', idname)
                                                      .replace(':refcategory', alt_idname) + '?lang=' + language
                                              }>
                                              {name}
                                          </Link>
                                      </Breadcrumb.Item>
                                  )
                              })}
                              {category && (
                                  <Breadcrumb.Item className="text-color-500 italic">
                                      {category.name}
                                  </Breadcrumb.Item>
                              )}
                          </Breadcrumb>
                      </div>
                  </div>
              </div>
              <div className="text-xs md:text-sm text-color-500 italic">
                  {category.description}
              </div>
          </header>
          <div className="mt-0 md:mt-2 flex items-center justify-end gap-2">
              <span className="text-xs text-color-400 italic">
                  {`${t('share')} : `}
              </span>
              <div className="flex items-center gap-2">
                  <HeaderButton
                      component="a"
                      href={createFacebookShareLink(
                          ROOT_URL +
                          paths.categoryPath
                              .replace(
                                  ':category',
                                  category.id
                              )
                              .replace(
                                  ':refcategory',
                                  category.alt_idname
                              )
                              .substring(1) +
                          '?lang=' +
                          category.language
                      )}
                      target="_blank"
                      width={22}
                      height={22}
                      icon={<FaFacebookF size={12}/>}
                  />
                  <HeaderButton
                      component="a"
                      href={createTwitterShareLink(
                          ROOT_URL +
                          paths.categoryPath
                              .replace(
                                  ':category',
                                  category.id
                              )
                              .replace(
                                  'ref:category',
                                  category.alt_idname
                              )
                              .substring(1) +
                          '?lang=' +
                          category.language
                      )}
                      target="_blank"
                      width={22}
                      height={22}
                      icon={<FaXTwitter size={10}/>}
                  />
                  <HeaderButton
                      onClick={() => {
                          callNativeShare({
                              title:
                              category.name,
                              url:
                                  ROOT_URL +
                                  paths.categoryPath
                                      .replace(
                                          ':category',
                                          category.id
                                      )
                                      .replace(
                                          ':refcategory',
                                          category.alt_idname
                                      )
                                      .substring(1) +
                                  '?lang=' +
                                  category.language
                          })
                      }}
                      width={22}
                      height={22}
                      icon={<FaShare size={12}/>}
                  />
              </div>
          </div>
          <div className="space-y-6">
              <Tabbar
                  style={{
                      overflowY: 'initial',
                      overflowX: 'auto'
                  }}
                  activeKey={activeKey}>
                  {tabitems.map((el, i) => (
                      <TabbarItem
                          key={i}
                          onClick={() => {
                              setCurrentTab(el.id)
                              go({
                                  type: el.id
                              })
                          }}>
                          <div className="bold">
                              {el.tabComponent}
                          </div>
                      </TabbarItem>
                  ))}
              </Tabbar>
              {tabitems[activeKey] && tabitems[activeKey].renderContent({categoryId, refCategoryId})}
          </div>
      </div>
    </ModuleLayoutWrapper>
  )
}
export default compose(
  mapProps(({ match }) => ({
    categoryId: match.params.category,
    refCategoryId:
      match.params.refcategory
  }))
)(Category)
