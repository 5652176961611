import {Button, DatePicker, Form, InputNumber, Popconfirm, Radio, Select, Table, Typography} from 'antd'
import {create_product_updatePrice_Api} from 'apis'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import React, {useState} from 'react'
import {formatterNumber} from "../../../../../helpers/formatterNumber";
import {ProductPriceSetInfo} from "../components/ProductPriceSetInfo";
import {parseNumber} from "../../../../../helpers/parseNumber";
import Async from "../../../../../modules/asyncCache/components/Async";
import {
  create_product_expirePriceSetGroup_Api,
  create_product_price_getById_Api,
  create_product_removeExpirePriceSetGroup_Api
} from "../../../../../apis";
import {useSelectEntities} from "../../../../../redux/entities/useSelectEntities";
import {productSchema} from "../../../../../apis/schema";
import UseState from "../../../../../components/UseState";
import {PlusOutlined} from "@ant-design/icons";
import Toggle from "../../../../../components/Toggle";
import {useSelector} from "react-redux";
import {getAsynCacheSelector} from "../../../../../modules/asyncCache/selectors";
import MinusCircleFilled from "@ant-design/icons/lib/icons/MinusCircleFilled";
import MessageFilled from "@ant-design/icons/lib/icons/MessageFilled";
import {ProductPriceGroupCommentComponent} from "../components/forms/ProductPriceGroupCommentForm";
import MessageOutlined from "@ant-design/icons/lib/icons/MessageOutlined";
import CloseCircleFilled from "@ant-design/icons/lib/icons/CloseCircleFilled";
import StopFilled from "@ant-design/icons/lib/icons/StopFilled";
import {CopyPriceGroupInputComponent} from "./CopyPriceGroupInputForm";
import {BsCalendar2Week, BsCaretDownFill, BsCaretLeftFill, BsStopCircle, BsTrash3, BsXCircle} from "react-icons/bs";
import {FaMapMarkerAlt} from "react-icons/fa";
import {MiniButton} from "../../../../AppLocals/Organization/OrganizationEntity";
import {IoChatboxEllipsesOutline} from "react-icons/io5";

const ForwardPriceInputByProduct = ({item, effectDate}) => {
  const t = useTranslate();

  const [__, dispatch] = useDispatchAsyncActionWithNotify();

  const product = useSelectEntities(
    item.idname,
    productSchema
  ) || item;

  const [update, setUpdate] = useState(Date.now());

  const productPriceSetsByContractType = _.reduce(_.get(product, 'prices', []), (res, {data: eData, ...e}) => {
    if (e.contract_type_cd === "forward") {

      const _locGroup = _.reduce(eData, (res1, ee) => {
        const {data: locData, id: product_price_set_id, ...loc} = ee;
        const {data: groupData, id: product_price_group_id, ...group} = _.get(locData, '0', {});

        if (!!loc.max_eff_at) {
          res1.push({
            product_price_set_id, ...loc,
            product_price_group_id, ...group,
            data: groupData
          });
        }

        return res1;

      }, []);

      res.push(
        {
          ...e,
          data: _locGroup
        }
      );

      return res;

    }

    return res;
  }, []);

  const {
    result: price_periods = []
  } = useSelector(
    state => {
      return getAsynCacheSelector(
        state,
        "/products/get-price-periods?day=1"
      )
    }
  );

  const handleOnExpirePriceSetGroup = ({product_price_group_id, eff_at}) => {
    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_expirePriceSetGroup_Api(),
          query: {
            ':product_price_group_id': product_price_group_id
          },
          values: {
            end_at: eff_at.format("YYYY-MM-DD")
          }
        }
      )
    )
  }
  const handleOnRemoveExpirePriceSetGroup = ({product_price_group_id, eff_at}) => {
    dispatch(
      createAsyncAction(
        {
          apiInfo: create_product_removeExpirePriceSetGroup_Api(),
          query: {
            ':product_price_group_id': product_price_group_id
          },
          values: {
            end_at: eff_at.format("YYYY-MM-DD")
          }
        }
      )
    )
  }

  return (
      <Toggle>
          {(isToggle, toggle) => (
              <>
                  <div
                      onClick={() => toggle()}
                      className="px-3 py-2 flex items-center border-l-4 border-primary background-100 hover:bg-primary-50 rounded-r-md cursor-pointer">
                      <span className="flex-1 font-bold text-primary-600 leading-tight">
                          {_.get(product, 'title')}
                      </span>
                      {productPriceSetsByContractType.length > 0 && (
                          isToggle ? <BsCaretDownFill size={12} className="text-primary-600"/> : <BsCaretLeftFill size={12} className="text-primary-600"/>
                      )}
                  </div>
                  <div key={product.id + effectDate.format("DD/MM/YYYY")}>
                      {isToggle && (
                          productPriceSetsByContractType.map((productPriceSet, iProductPriceSet) => (
                              <div className="space-y-3 p-2"
                                   key={product.id + iProductPriceSet}>
                                  <ProductPriceSetInfo productPriceSet={productPriceSet}/>
                                  {_.get(productPriceSet, 'data', []).map((productPriceGroup, iProductPriceGroup) => (
                                      <UseState key={iProductPriceGroup} initialValue="view">
                                          {([action, setAction]) => (
                                              <div className="flex flex-col gap-2">
                                                  <div className="flex items-center justify-between gap-2">
                                                      <div className="flex flex-col flex-1">
                                                          <div className="flex items-center gap-1">
                                                              <FaMapMarkerAlt size={12} className="text-primary-600"/>
                                                              <span className="font-bold text-color-000 leading-snug">
                                                                  {_.get(productPriceGroup, 'location.location_name')}
                                                              </span>
                                                          </div>
                                                          <span className="ml-5 text-2xs text-color-400 italic leading-tight">
                                                              {[
                                                                  moment(_.get(productPriceGroup, 'eff_at')).format("DD/MM/YYYY"),
                                                                  !!_.get(productPriceGroup, 'end_at')
                                                                      ? moment(_.get(productPriceGroup, 'end_at')).format("DD/MM/YYYY")
                                                                      : moment("31/12/2099", "DD/MM/YYYY").format("DD/MM/YYYY")
                                                              ].join(" → ")}
                                                          </span>
                                                      </div>
                                                      <div className="flex flex-col">
                                                          {!!productPriceGroup.end_at && (
                                                              <Popconfirm
                                                                  title={
                                                                      <div className="font-medium text-sm text-color-100 italic">
                                                                          {t('Sure to remove the expired?')}
                                                                      </div>
                                                                  }
                                                                  placement="left"
                                                                  onConfirm={() => {
                                                                      handleOnRemoveExpirePriceSetGroup(
                                                                          {
                                                                              product_price_group_id: productPriceGroup.product_price_group_id,
                                                                              eff_at: effectDate
                                                                          }
                                                                      );
                                                                  }}
                                                                  okText={t('sure')}
                                                                  cancelText={t('no')}
                                                                  okButtonProps={{
                                                                      type: 'primary',
                                                                      danger: true,
                                                                      className: 'button-rounded-md no-border text-xs font-medium px-3',
                                                                  }}
                                                                  cancelButtonProps={{
                                                                      className: 'button-rounded-md no-border text-xs font-medium px-3',
                                                                  }}>
                                                                  <MiniButton
                                                                      type="danger"
                                                                      icon={<BsXCircle size={12}/>}
                                                                      label={t('remove expired')}
                                                                  />
                                                              </Popconfirm>
                                                          )}
                                                          {!!productPriceGroup.eff_at && !!!productPriceGroup.end_at && (
                                                              <Popconfirm
                                                                  title={
                                                                      <div className="font-medium text-sm text-color-100 italic">
                                                                          {t('confirm to expire at') + effectDate.format("DD/MM/YYYY")}
                                                                      </div>
                                                                  }
                                                                  placement="left"
                                                                  onConfirm={() => {
                                                                      handleOnExpirePriceSetGroup(
                                                                          {
                                                                              product_price_group_id: productPriceGroup.product_price_group_id,
                                                                              eff_at: effectDate
                                                                          }
                                                                      );
                                                                  }}
                                                                  okText={t('sure')}
                                                                  cancelText={t('no')}
                                                                  okButtonProps={{
                                                                      type: 'primary',
                                                                      danger: true,
                                                                      className: 'button-rounded-md no-border text-xs font-medium px-3',
                                                                  }}
                                                                  cancelButtonProps={{
                                                                      className: 'button-rounded-md no-border text-xs font-medium px-3',
                                                                  }}>
                                                                  <MiniButton
                                                                      type="danger"
                                                                      icon={<BsStopCircle size={12}/>}
                                                                      label={`${t('expire at')} ${effectDate.format("DD/MM/YYYY")}`}
                                                                  />
                                                              </Popconfirm>
                                                          )}
                                                      </div>
                                                  </div>
                                                  <div className="flex items-center gap-2">
                                                      <div className="flex-1"/>
                                                      <ProductPriceGroupCommentComponent
                                                          productPriceGroup={productPriceGroup}
                                                          effectMomentDate={moment(_.get(productPriceGroup, 'eff_at'))}>
                                                          <MiniButton
                                                              icon={<IoChatboxEllipsesOutline size={12}/>}
                                                              label={t('notes')}
                                                          />
                                                      </ProductPriceGroupCommentComponent>
                                                      <Radio.Group
                                                          defaultValue={action}
                                                          value={action}
                                                          onChange={e => {setAction(e.target.value)}}
                                                          size="small"
                                                          buttonStyle="solid">
                                                          <Radio.Button value="view" className="font-medium text-xs">
                                                              {t('view')}
                                                          </Radio.Button>
                                                          <Radio.Button value="edit" className="font-medium text-xs">
                                                              {[
                                                                  t('edit'),
                                                                  t('at date'),
                                                                  moment(_.get(productPriceGroup, 'eff_at')).format("DD/MM/YYYY")
                                                              ].join(" ")}
                                                          </Radio.Button>
                                                      </Radio.Group>
                                                  </div>
                                                  {!!_.get(productPriceGroup, 'notes') && (
                                                      <span className="text-xs text-color-400 italic">
                                                          * {_.get(productPriceGroup, 'notes')}
                                                      </span>
                                                  )}
                                                  <Form
                                                      key={product.id + iProductPriceSet + iProductPriceGroup + update}
                                                      className="flex flex-col"
                                                      initialValues={{ ...productPriceGroup }}
                                                      name={"dynamic_form_item" + product.id + iProductPriceSet + iProductPriceGroup}>
                                                      {(values, form) => (
                                                          <Toggle>
                                                              {(isToggleAddNew, toggleAddNew, setToggleAddNew) => (
                                                                  <React.Fragment>
                                                                      <Form.List name="data">
                                                                          {(fields, {add, remove}) => (
                                                                              <Table
                                                                                  key={fields.length}
                                                                                  responsive
                                                                                  pagination={{
                                                                                      defaultPageSize: 50,
                                                                                      hideOnSinglePage: true
                                                                                  }}
                                                                                  columns={[
                                                                                      {
                                                                                          title: t('month'),
                                                                                          dataIndex: 'month',
                                                                                          key: 'month',
                                                                                          visible: true,
                                                                                          render: (_, record) => {
                                                                                              const _rec = form.getFieldValue('data')[record.name];
                                                                                              return (
                                                                                                  <div className="flex flex-col leading-tight truncate">
                                                                                                      <div className="font-semibold text-sm text-color-000">
                                                                                                          {moment(_rec.month, 'M').format('MM')}{'-'}{_rec.year}
                                                                                                      </div>
                                                                                                      <div className="text-2xs text-color-400 italic inline-block">
                                                                                                          {`${moment(_rec.from_date).format('DD/MM')}-${moment(_rec.to_date).format('DD/MM')}`}
                                                                                                      </div>
                                                                                                  </div>
                                                                                              )
                                                                                          },
                                                                                      },
                                                                                      {
                                                                                          title: (
                                                                                              <div className="flex flex-col leading-tight">
                                                                                                  <span className="font-semibold text-sm text-color">
                                                                                                      {t('flat price')}
                                                                                                  </span>
                                                                                                  <span className="text-2xs text-color-400 italic">
                                                                                                      {
                                                                                                          _.get(productPriceSet, 'price_unit.name', t('price unit'))
                                                                                                      }/
                                                                                                      {
                                                                                                          _.get(productPriceSet, 'unit.name', t('unit'))
                                                                                                      }
                                                                                                  </span>
                                                                                              </div>
                                                                                          ),
                                                                                          key: 'price',
                                                                                          visible: true,
                                                                                          render: (__, record) => {
                                                                                              const _rec = form.getFieldValue('data')[record.name];
                                                                                              return (
                                                                                                  <InputNumber
                                                                                                      step="0.01"
                                                                                                      defaultValue={_rec.price}
                                                                                                      disabled={!!_.get(productPriceGroup, 'unit') || action === "view"}
                                                                                                      name="price"
                                                                                                      style={{width: '100%'}}
                                                                                                      formatter={formatterNumber}
                                                                                                      parser={parseNumber}
                                                                                                      onChange={(value) => {
                                                                                                          let data = _.cloneDeep(form.getFieldValue('data'));

                                                                                                          Object.assign(data[record.name], {
                                                                                                              price: value
                                                                                                          })

                                                                                                          form.setFieldsValue({
                                                                                                              data
                                                                                                          })
                                                                                                      }}
                                                                                                  />
                                                                                              )
                                                                                          }
                                                                                      },
                                                                                      {
                                                                                          title: (
                                                                                              <div className="flex flex-col leading-tight">
                                                                                                  <span className="font-semibold text-sm text-color">
                                                                                                      {t('basis price')}
                                                                                                  </span>
                                                                                                  <span className="text-2xs text-color-400 italic">
                                                                                                      {
                                                                                                          _.get(productPriceSet, 'price_unit2.name', t('price unit'))
                                                                                                      }/
                                                                                                      {
                                                                                                          _.get(productPriceSet, 'unit2.name', t('unit'))
                                                                                                      }
                                                                                                  </span>
                                                                                              </div>
                                                                                          ),
                                                                                          key: 'price2',
                                                                                          visible: (!_.isEmpty(productPriceSet.unit2) && !_.isEmpty(productPriceSet.price_unit2)),
                                                                                          render: (__, record) => {
                                                                                              const _rec = form.getFieldValue('data')[record.name];
                                                                                              return (
                                                                                                  <InputNumber
                                                                                                      step="0.01"
                                                                                                      defaultValue={_rec.price2}
                                                                                                      disabled={!!_.get(productPriceGroup, 'unit2') || action === "view"}
                                                                                                      name="price2"
                                                                                                      style={{width: '100%'}}
                                                                                                      formatter={formatterNumber}
                                                                                                      parser={parseNumber}
                                                                                                      onChange={(value) => {
                                                                                                          let data = _.cloneDeep(form.getFieldValue('data'));

                                                                                                          Object.assign(data[record.name], {
                                                                                                              price2: value
                                                                                                          })

                                                                                                          form.setFieldsValue({
                                                                                                              data
                                                                                                          })
                                                                                                      }}
                                                                                                  />
                                                                                              )
                                                                                          }
                                                                                      },
                                                                                      {
                                                                                          title: t('period'),
                                                                                          dataIndex: 'period_code',
                                                                                          key: 'period_code',
                                                                                          visible: true,
                                                                                          render: (_, record) => {
                                                                                              const _rec = form.getFieldValue('data')[record.name];
                                                                                              return (
                                                                                                  <div className="text-sm">
                                                                                                      {_rec.period_code}
                                                                                                  </div>
                                                                                              )
                                                                                          },
                                                                                      },
                                                                                      {
                                                                                          visible: action === "edit",
                                                                                          render: (__, record) => {
                                                                                              return (
                                                                                                  <BsTrash3
                                                                                                      size={14}
                                                                                                      className="text-color-300 hover:text-red-600 cursor-pointer"
                                                                                                      onClick={() => {
                                                                                                          let data = _.cloneDeep(form.getFieldValue('data'));
                                                                                                          data.splice(record.name, 1);
                                                                                                          form.setFieldsValue({data});
                                                                                                      }}
                                                                                                  />
                                                                                              )
                                                                                          },
                                                                                      },
                                                                                  ].filter(e => e.visible)}
                                                                                  dataSource={fields}
                                                                                  size="small"
                                                                                  footer={() => action !== "view" && (
                                                                                      !isToggleAddNew ? (
                                                                                          <Button
                                                                                              size="small"
                                                                                              className="button-rounded-md no-border font-medium text-xs"
                                                                                              onClick={toggleAddNew}>
                                                                                              {t('add')}
                                                                                          </Button>
                                                                                      ) : (
                                                                                          <UseState
                                                                                              initialValue={{
                                                                                                  price: null,
                                                                                                  price2: null,
                                                                                                  rangePicker: [moment(), moment()],
                                                                                              }}>
                                                                                              {([detailPrices, setDetailPrices]) => (
                                                                                                  <div className="grid grid-cols-2 gap-2 p-2 rounded-md border border-dashed">
                                                                                                      <Select
                                                                                                          showSearch
                                                                                                          virtual={false}
                                                                                                          optionFilterProp="label"
                                                                                                          placeholder={t('month')}
                                                                                                          onChange={(value) => {
                                                                                                              const {period_code, from_date, to_date, year, month, month_nbr, ...rest} =
                                                                                                                  _.head(price_periods.filter(e => e.period_code === value));

                                                                                                              setDetailPrices(
                                                                                                                  {
                                                                                                                      ...detailPrices,
                                                                                                                      year: year,
                                                                                                                      month: month_nbr,
                                                                                                                      rangePicker: [moment(from_date), moment(to_date)],
                                                                                                                      period_code: period_code
                                                                                                                  }
                                                                                                              )
                                                                                                          }}>
                                                                                                          {price_periods.map((e) => (
                                                                                                              <Select.Option
                                                                                                                  key={e.period_code}
                                                                                                                  value={e.period_code}
                                                                                                                  label={e.month + ' - ' + e.year}>
                                                                                                                  <div className="flex flex-col">
                                                                                                                      {e.month + ' - ' + e.year}
                                                                                                                  </div>
                                                                                                              </Select.Option>
                                                                                                          ))}
                                                                                                      </Select>
                                                                                                      <DatePicker.RangePicker
                                                                                                          className="rounded-md"
                                                                                                          suffixIcon={<BsCalendar2Week className="text-color-400"/>}
                                                                                                          style={{width: '100%'}}
                                                                                                          onChange={(value) => {
                                                                                                              setDetailPrices({
                                                                                                                  ...detailPrices,
                                                                                                                  rangePicker: value
                                                                                                              })
                                                                                                          }}
                                                                                                      />
                                                                                                      <InputNumber
                                                                                                          step="0.01"
                                                                                                          // defaultValue={productPriceDetail.price}
                                                                                                          name="price"
                                                                                                          placeholder={t(
                                                                                                              'flat price'
                                                                                                          )}
                                                                                                          style={{width: '100%'}}
                                                                                                          formatter={formatterNumber}
                                                                                                          parser={parseNumber}
                                                                                                          onChange={value => {
                                                                                                              setDetailPrices({
                                                                                                                  ...detailPrices,
                                                                                                                  price: value
                                                                                                              })
                                                                                                          }}
                                                                                                      />
                                                                                                      <InputNumber
                                                                                                          step="0.01"
                                                                                                          // defaultValue={productPriceDetail.price}
                                                                                                          name="price2"
                                                                                                          placeholder={t(
                                                                                                              'basis price'
                                                                                                          )}
                                                                                                          style={{width: '100%'}}
                                                                                                          formatter={formatterNumber}
                                                                                                          parser={parseNumber}
                                                                                                          onChange={value => {
                                                                                                              setDetailPrices({
                                                                                                                  ...detailPrices,
                                                                                                                  price2: value
                                                                                                              })
                                                                                                          }}
                                                                                                      />
                                                                                                      <Select
                                                                                                          className="flex-1"
                                                                                                          showSearch
                                                                                                          style={{width: 'auto'}}
                                                                                                          placeholder={t('period')}
                                                                                                          options={price_periods.map(e => {
                                                                                                              return {
                                                                                                                  value: e.period_code,
                                                                                                                  label: e.period_code
                                                                                                              }
                                                                                                          })}
                                                                                                          onChange={(value) => {
                                                                                                              setDetailPrices({
                                                                                                                  ...detailPrices,
                                                                                                                  period_code: value
                                                                                                              })
                                                                                                          }}
                                                                                                      />
                                                                                                      <Button
                                                                                                          block
                                                                                                          type="primary"
                                                                                                          className="button-rounded-md no-border"
                                                                                                          onClick={() => {
                                                                                                              let data = _.cloneDeep(form.getFieldValue('data'));
                                                                                                              data = [
                                                                                                                  ...data,
                                                                                                                  {
                                                                                                                      keyid: Date.now(),
                                                                                                                      ...detailPrices,
                                                                                                                      from_date: !!_.get(detailPrices, 'rangePicker.0')
                                                                                                                          ? _.get(detailPrices, 'rangePicker.0').utc().format()
                                                                                                                          : null,
                                                                                                                      to_date: !!_.get(detailPrices, 'rangePicker.1')
                                                                                                                          ? _.get(detailPrices, 'rangePicker.1').utc().format()
                                                                                                                          : null,
                                                                                                                  },
                                                                                                              ]
                                                                                                              form.setFieldsValue({
                                                                                                                  data
                                                                                                              });
                                                                                                              toggleAddNew();
                                                                                                          }}>
                                                                                                          {t('add')}
                                                                                                      </Button>
                                                                                                  </div>
                                                                                              )}
                                                                                          </UseState>
                                                                                      )
                                                                                  )}
                                                                              />
                                                                          )}
                                                                      </Form.List>
                                                                      <div className="w-full flex items-center gap-2">
                                                                          <div className="flex-1"/>
                                                                          {action === "view" && (
                                                                              <CopyPriceGroupInputComponent
                                                                                  item={product}
                                                                                  product_price_set_id={_.get(productPriceGroup, 'product_price_set_id')}
                                                                                  product_price_group_id={_.get(productPriceGroup, 'product_price_group_id')}
                                                                                  diff={0}
                                                                                  diff2={0}
                                                                                  country_id={_.get(productPriceGroup, 'location._type') === "country" ? _.get(productPriceGroup, 'location.id') : null}
                                                                                  province_id={_.get(productPriceGroup, 'location._type') !== "country" ? _.get(productPriceGroup, 'location.id') : null}
                                                                                  effectMomentDate={effectDate}>
                                                                                  <Button
                                                                                      className="button-rounded-md no-border"
                                                                                      type="primary">
                                                                                      {[
                                                                                          t('create'),
                                                                                          t('at date'),
                                                                                          effectDate.format("DD/MM/YYYY")
                                                                                      ].join(" ")}
                                                                                  </Button>
                                                                              </CopyPriceGroupInputComponent>
                                                                          )}
                                                                          {action === "edit" && (
                                                                              <Button
                                                                                  className="button-rounded-md no-border"
                                                                                  onClick={() => {
                                                                                      const priceSetValue = {
                                                                                          ...productPriceSet,
                                                                                          unit_id: _.get(productPriceSet, 'unit.id'),
                                                                                          unit2_id: _.get(productPriceSet, 'unit2.id'),
                                                                                          price_unit_id: _.get(productPriceSet, 'price_unit.id'),
                                                                                          price_unit2_id: _.get(productPriceSet, 'price_unit2.id'),
                                                                                          country_id: _.get(productPriceGroup, 'location._type') === "country" ? _.get(productPriceGroup, 'location.id') : null,
                                                                                          province_id: _.get(productPriceGroup, 'location._type') !== "country" ? _.get(productPriceGroup, 'location.id') : null,
                                                                                      }
                                                                                      dispatch(createAsyncAction({
                                                                                          apiInfo: create_product_updatePrice_Api(),
                                                                                          query: {':id': product.id},
                                                                                          values: {
                                                                                              ...priceSetValue,
                                                                                              eff_at: moment(_.get(productPriceGroup, 'eff_at')).format("YYYY-MM-DD"),
                                                                                              data: JSON.stringify(form.getFieldValue('data'))
                                                                                          }
                                                                                      }))
                                                                                      setAction("view")
                                                                                      setToggleAddNew(false)
                                                                                  }}
                                                                                  type="primary"
                                                                                  htmlType="submit">
                                                                                  {t('edit & save')}
                                                                              </Button>
                                                                          )}
                                                                      </div>
                                                                  </React.Fragment>
                                                              )}
                                                          </Toggle>
                                                      )}
                                                  </Form>
                                              </div>
                                          )}
                                      </UseState>
                                  ))}
                              </div>
                          ))
                      )}
                  </div>
              </>
          )}
      </Toggle>
  )
}

export const ForwardPriceInputFormMulti = ({organization, products}) => {
  const t = useTranslate();
  const [effectDate, setEffectDate] = useState(moment());

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  return (
    <div key={effectDate.format("YYYY-MM-DD")} className="p-3 space-y-3">

        <div className="px-3 py-2 flex items-center background border-2 border-primary-600 rounded-lg hover:shadow-items-md stickyTop z-3">
            <div className="flex-1 font-semibold text-color-100 italic">
                {t('effected date')}
            </div>
            <DatePicker
                style={{ minWidth: 200 }}
                className="rounded-md"
                suffixIcon={<BsCalendar2Week className="text-color-400"/>}
                allowClear={false}
                onChange={(value) =>  setEffectDate(value) }
                defaultValue={effectDate}
                format={dateFormatList}
            />
        </div>

      <div className="space-y-3">
        {products.map((product, i) => (
            <div key={i} className="flex flex-col">
                <Async
                    apiInfo={create_product_price_getById_Api()}
                    query={{':id': product.id, ':effect_date': effectDate.format("YYYY-MM-DD")}}
                />
                <ForwardPriceInputByProduct
                    item={product}
                    effectDate={effectDate}
                />
            </div>
        ))}
      </div>

    </div>
  )
}


