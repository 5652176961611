import _ from 'lodash'
import {normalize} from 'normalizr'
import {emptyObject} from "../../helpers/emptyObjects";

export const createTransform = (
  schema,
  str = 'data.data'
) => (response) => {
  let error,
    errorMessages,
    entities,
    result,
    success
  const {status} = response || emptyObject
  const data = _.get(response, str)
  switch (status) {
    case 200:
      success = true
      if (schema && data) {
        const {
          entities: e,
          result: r,
        } = normalize(data, schema)
        entities = e
        result = r
      } else {
        result = data
      }

      break

    default:
      error = true
      errorMessages = response.data
      break
  }

  return {
    ...response,
    response,
    error,
    errorMessages,
    success,
    status,
    entities,
    result,
  }
}
export const defaultTranform = createTransform()
