import { getId } from 'apis/model/base'
import _ from 'lodash'
import useAsyncWithCache from 'modules/asyncCache/useAsyncWithCache'
import React, { useCallback } from 'react'
import { getResponseItem, LoadingWrapper } from 'views/Shared'
import { userApi } from '../apis'
import { gPaths } from '../configs'
import { bindQueryParam, mapPathParam } from '../functions/routerHelper'
import { PermissionEntity } from '../Permissions/PermissionEntity'
import UserEntity from './UserEntity'

const UserContext = React.createContext({
  item: {},
})

export const UserProvider = ({
  item,
  children,
  routeEntities = [],
  routePathField = 'path',
  ...props
}) => {
  const username = getId(item) || item

  const apiInfo = userApi.user_getById_Api

  const cacheId = mapPathParam(apiInfo.path, { username })

  const { response, isLoading } = useAsyncWithCache({
    cacheId,
    apiInfo,
    query: bindQueryParam({
      username,
    }),
  })

  const isSucceed = Number(_.get(response, 'status')) === 200

  const cache = getResponseItem(response)

  const error = getResponseItem(response, 'error')

  const errorMessage = _.get(response, 'errorMessages.error')

  const error_code = _.get(response, 'errorMessages.error_code')

  const status = {
    title: getResponseItem(response, 'status'),
    description: errorMessage,
  }

  const defaultRoute = _.first(routeEntities)

  const getDefaultAction = (current) => {
    switch (current) {
      case gPaths.user:
        return _.get(defaultRoute, routePathField)
      default:
        return null
    }
  }

  const isValidRoute = useCallback(
    (route) => {
      return routeEntities
        .map((item) => _.get(item, routePathField))
        .includes(route)
    },
    [routeEntities, routePathField]
  )

  const renderHeader = useCallback((action, isModal) => null, [])

  return (
    <LoadingWrapper isLoading={!item}>
      <UserEntity
        key={cacheId}
        item={username}>
        {(user) => (
          <PermissionEntity item={user}>
            <UserContext.Provider
              value={{
                error,
                cache,
                user,
                status,
                isSucceed,
                isLoading,
                error_code,
                renderHeader,
                errorMessage,
                defaultRoute,
                routeEntities,
                routePathField,
                isValidRoute,
                getDefaultAction,
                ...props,
              }}>
              {children}
            </UserContext.Provider>
          </PermissionEntity>
        )}
      </UserEntity>
    </LoadingWrapper>
  )
}

export default UserContext
