import Translate from "modules/local/Translate"
import React from "react"
import classNames from 'classnames'

const renderRequestStatus = status => {

  if (status) {

    const { Icon, name, color } = status

    return (
      <Translate>
        {t => (
          <div className={classNames("flex items-center gap-2 opacity-75", color)}>
            <Icon size={12} />
            <span className="font-bold text-xs italic">
              {t(name)}
            </span>
          </div>
        )}
      </Translate>
    )
  }
}

export default renderRequestStatus