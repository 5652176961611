import _ from 'lodash'
import {organizationPermissionCodes, permissionCodes,} from 'modules/permissions/contants'
import PermissionProvider from 'modules/permissions/PermissionProvider'
import React from 'react'
import {
  BsBox2, BsFolder2Open,
  BsGear,
  BsGrid,
  BsPeople,
  BsPerson,
  BsPersonBadge, BsPersonVcard,
  BsPlusCircle,
  BsQuestion,
  BsTrash
} from 'react-icons/bs'
import {
  IoBriefcaseOutline,
  IoFolderOpenOutline,
  IoGridOutline,
  IoIdCardOutline,
  IoPeopleOutline,
  IoPersonOutline,
  IoSettingsOutline,
} from 'react-icons/io5'
import {withRouter} from 'react-router-dom'
import {compose, fromRenderProps, nest, withProps} from 'recompose'
import {notFoundOnError, withAccessPermissions} from 'views/AppLocals/composers'
import {CustomKeys} from 'views/AppLocals/CustomKeys'
import {AppEntities} from 'views/AppLocals/enums'
import {createCheckPermissions} from 'views/AppLocals/factory/createCheckPermissions'
import {ContextActionTypes} from 'views/AppLocals/factory/createContextMenu'
import createDeleteEntity from 'views/AppLocals/factory/createDeleteEntity'
import {CoHosts, CoOwners} from 'views/AppLocals/factory/createHostSetting'
import {createSettingModule} from 'views/AppLocals/factory/createModule'
import Members from 'views/MemberSetting/Members.lazy'
import WaitingAcceptMembers from 'views/MemberSetting/WaitingAcceptMembers.lazy'
import Contacts from 'views/Organization/Settings/Contacts/Contacts.lazy'
import InviteManager from 'views/Organization/Settings/InviteManager'
import Locations from 'views/Organization/Settings/Locations/Locations.lazy'
import {SubDomainContext} from 'views/SubDomain/SubDomainContext'
import OrganizationContainerContext from '../OrganizationContainerContext'
import OrganizationSettingAbout from './OrganizationSettingAbout'
import OrganizationSettingCategories from './OrganizationSettingCategories'
import OrganizationSettingOverview from './OrganizationSettingOverview'
import OrganizationSettingPermission from './OrganizationSettingPermission'
import OrganizationSettingPhoto from './OrganizationSettingPhoto'
import OrganizationSettingProfile from './OrganizationSettingProfile'
import OrganizationSettingRole from './OrganizationSettingRole'
import OrderRequests from "../../../OrderRequest/Settings/OrderRequests";
import WorkspaceManageProducts from "../../../Market/Seller/Product/ProductSettings/WorkspaceManageProducts";
import Translate from "../../../../modules/local/Translate";
import ServerContext from "../../../Server/ServerContext";
import ContactBooks from "../../../Organization/Settings/ContactBook/ContactBooks.lazy";
import Partners from "../../../Organization/Settings/Partners/Partners";
import {FaRegAddressBook} from "react-icons/fa6";

const menuItems = [
  {
    key: 'overview',
    label: 'general settings',
    icon: <BsGear/>,
  },
  {
    key: 'profiles',
    label: 'provider profiles',
    icon: <BsFolder2Open/>,
    children: [
      {
        label: 'about us',
        key: 'about-us',
      },
      {
        label: 'photos',
        key: 'photos',
      },
      {
        label: 'profile',
        key: 'profile',
      },
    ],
  },
  {
    key: 'category',
    label: 'categories',
    icon: <BsGrid/>,
    children: [
      {
        label: 'categories',
        key: 'categories',
      },
    ],
  },
  {
    key: 'location-contact',
    label: 'location & contact',
    icon: <BsPersonVcard/>,
    children: [
      {
        label: 'location',
        key: 'location',
      },
      {
        label: 'contact',
        key: 'contact',
      },
    ],
  },
  {
    key: 'member-management',
    label: 'member management',
    icon: <BsPeople/>,
    children: [
      {
        label: 'members',
        key: 'members',
      },
      {
        label: 'waiting members',
        key: 'waiting-members',
      },
      {
        label: 'invites',
        key: 'invites',
      },
    ],
  },
  {
    key: 'hosts',
    label: 'hosts',
    icon: <BsPerson/>,
    children: [
      {
        label: 'list of co-hosting',
        key: CustomKeys.co_hosts.name,
      },
      {
        label: 'list of co-owners',
        key: 'host-request',
      },
    ],
  },
  {
    key: 'products',
    label: 'products',
    icon: <BsBox2/>,
    children: [
      {
        label: 'products',
        key: 'product-list',
      },
      {
        label: 'order requests management',
        key: 'order-requests-management',
      },
    ],
  },
  {
    key: 'contact-books',
    label: 'contact books',
    icon: <FaRegAddressBook/>,
  },
  // {
  //   key: 'access rights',
  //   label: 'access rights',
  //   icon: <IoShieldCheckmarkOutline />,
  //   children: [
  //     {
  //       label: 'roles',
  //       key: 'roles',
  //     },
  //     {
  //       label: 'permissions',
  //       key: 'permissions',
  //     },
  //   ],
  // },
  {
    key: 'partners',
    label: 'partners',
    icon: <IoBriefcaseOutline/>,
    children: [
      {
        label: 'partners',
        key: 'partners',
      },
      {
        label: 'vendors',
        key: 'vendors',
      },
      {
        label: 'customers',
        key: 'customers',
      },
    ],
  },
  {
    key: 'delete',
    label: 'delete',
    icon: <BsTrash/>,
  },
]

const routeEntities = [
  {
    path: 'overview',
    label: 'overview',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(OrganizationSettingOverview),
  },
  {
    path: 'about-us',
    label: 'about us',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(OrganizationSettingAbout),
  },
  {
    path: 'photos',
    label: 'photos',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(OrganizationSettingPhoto),
  },
  {
    path: 'categories',
    label: 'categories',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(OrganizationSettingCategories),
  },
  {
    path: 'profile',
    label: 'profile',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(OrganizationSettingProfile),
  },
  {
    path: 'location',
    label: 'location',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(Locations),
  },
  {
    path: 'contact',
    label: 'contact',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(Contacts),
  },
  {
    path: 'partners',
    label: 'partners',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(props => (
        <ServerContext.Consumer>
          {({item}) => (
              <Partners type={null} title="partners" item={item}/>
          )}
        </ServerContext.Consumer>
    )),
  },
  {
    path: 'vendors',
    label: 'vendors',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(props => (
        <ServerContext.Consumer>
          {({item}) => (
                <Partners type="vendor" title="vendors" item={item}/>
          )}
        </ServerContext.Consumer>
    )),
  },
  {
    path: 'customers',
    label: 'customers',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(props => (
        <ServerContext.Consumer>
          {({item}) => (
              <Partners type="customer" title="customers" item={item}/>
          )}
        </ServerContext.Consumer>
    )),
  },
  {
    path: 'members',
    label: 'members',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(Members),
  },
  {
    path: 'waiting-members',
    label: 'waiting members',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(WaitingAcceptMembers),
  },
  {
    path: 'invites',
    label: 'invites',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageMember,
    ])(InviteManager),
  },
  {
    path: 'roles',
    label: 'roles',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageRole,
    ])(OrganizationSettingRole),
  },
  {
    path: CustomKeys.co_hosts.name,
    label: CustomKeys.co_hosts.label,
    component: createCheckPermissions([])(CoHosts),
  },
  {
    path: 'host-request',
    label: 'host request',
    component: createCheckPermissions([])(CoOwners),
  },
  {
    path: 'permissions',
    label: 'permissions',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManagePermission,
    ])(OrganizationSettingPermission),
  },
  {
    path: 'order-requests-management',
    label: 'order requests management',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageProduct,
    ])(OrderRequests),
  },
  {
    path: 'product-list',
    label: 'products',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageProduct,
    ])(WorkspaceManageProducts),
  },
  {
    path: 'contact-books',
    label: 'contact books',
    component: props => (
        <ServerContext.Consumer>
          {({item}) => (
              <ContactBooks item={item}/>
          )}
        </ServerContext.Consumer>
    ),
  },
  {
    path: 'delete',
    label: 'delete',
    component: createCheckPermissions([
      organizationPermissionCodes.organizationManageInfo,
    ])(({item}) => createDeleteEntity(item)),
  },
].map(
  ({
     path,
     component: Component,
     withItem = (item) => ({item}),
     ...rest
   }) => ({
    ...rest,
    path,
    key: path,
    component: (props) => (
      <OrganizationContainerContext.Consumer>
        {({organization: item}) => (
          <Component
            {...(withItem(item) ?? {})}
            {...props}
          />
        )}
      </OrganizationContainerContext.Consumer>
    ),
  })
)

export const organizationSettings = {
  actions: [
    {
      key: ContextActionTypes.CREATE_CHANNEL,
      label: 'create channel',
      icon: <BsPlusCircle/>,
    },
    {
      key: ContextActionTypes.CREATE_EVENT,
      label: 'create event',
      icon: <BsPlusCircle/>,
    },
    {
      key: ContextActionTypes.SETTINGS,
      label: 'setting',
      icon: <BsGear/>,
    },
  ],
  menuItems,
  routeEntities,
}

const OrganizationSettings = nest(
  ({children, ...props}) => (
    <OrganizationContainerContext.Consumer>
      {({organization: item}) => (
        <PermissionProvider
          permissions={{
            edit: item.edit,
            ...item.available_actions,
          }}>
          {children}
        </PermissionProvider>
      )}
    </OrganizationContainerContext.Consumer>
  ),
  ({id, ...props}) =>
    createSettingModule([AppEntities.ORGANIZATION], {
      item: id,
      ...props,
    })
)

export default compose(
  notFoundOnError(OrganizationContainerContext.Consumer),
  withAccessPermissions([permissionCodes.canEdit]),
  withRouter,
  fromRenderProps(SubDomainContext.Consumer, ({id}) => ({
    id,
  })),
  withProps(({id, match}) => ({
    id: id || _.get(match, 'params.id'),
  }))
)(OrganizationSettings)
