import React from "react";

export const PinnedIcon = ({size = 20, ...props}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 520 520"
      width={size}
      height={size}
      {...props}
    >
      <defs>
        <filter x="-50%" y="-50%" width="200%" height="200%" id="f1"/>
      </defs>
      <style>{".c{fill:#d32f2f}.d{fill:#f44336}"}</style>
      <path
        d="M190.7 368l-38-38.4c-1.9-2-4.6-3.1-7.4-2.8-2.8.2-5.3 1.6-6.9 3.8L33.9 474.7c-3.1 4.2-2.3 10.2 1.9 13.4 3.4 2.6 8 2.6 11.4 0l142.5-105.6c4.2-3.2 5.2-9.2 2.1-13.5q-.5-.5-1-1.1zm123.5-67.2l-95-96.1c-16-12.5-35.9-18.9-56.1-17.9-26.5-.9-52.2 9-71.3 27.6-15.5 15.6-13.9 39.7 4.7 71.6 17.6 27.8 38.3 53.5 61.8 76.4 37.8 38.2 85.5 77.9 120.2 77.9 9.8.2 19.3-3.6 26.2-10.7 39.6-39.9 29.7-108.4 9.5-128.8zm76-76.8l-95-96.1c-3.7-3.7-9.7-3.7-13.4 0l-76 76.8c-12.4 12.6-11.4 40.4 28.5 80.9 26 26.3 46.8 35.9 61.5 35.9 6.9.2 13.5-2.3 18.4-7.1l76-76.9c3.7-3.7 3.7-9.8 0-13.5zm97.1-25.7c-1.5-3.6-4.9-6-8.8-6-20.6 0-65.8-22.5-97.8-54.8-31.9-32.3-54.2-78-54.2-98.8 0-5.3-4.2-9.6-9.5-9.7-2.5 0-4.9 1.1-6.7 2.9-38.8 39.2-51 106.1 9.5 167.2 23 25.8 55.3 41.2 89.7 42.5 28.5-.4 55.8-12.2 75.7-32.9 2.7-2.7 3.5-6.8 2.1-10.4zM428.2 89.5C388 48.8 351.6 29.1 317 29.1c-5.2 0-9.5 4.3-9.5 9.6 0 28.6 26.8 79.1 59.8 112.4 32.9 33.3 82.9 60.4 111.2 60.4 5.3 0 9.5-4.3 9.5-9.6 0-35-19.5-71.7-59.8-112.4z"
        filter="url(#f1)"
        fill="#cfd8dc"
      />
      <path
        d="M190.7 368l-38-38.4c-1.9-2-4.6-3.1-7.4-2.8-2.8.2-5.3 1.6-6.9 3.8L33.9 474.7c-3.1 4.2-2.3 10.2 1.9 13.4 3.4 2.6 8 2.6 11.4 0l142.5-105.6c4.2-3.2 5.2-9.2 2.1-13.5q-.5-.5-1-1.1z"
        fill="#cfd8dc"
      />
      <path
        className="c"
        d="M314.2 300.8l-95-96.1c-15.9-12.6-35.9-18.9-56.1-18-26.5-.9-52.2 9.1-71.3 27.6-15.4 15.6-13.9 39.7 4.7 71.7 17.6 27.8 38.3 53.4 61.8 76.4 37.8 38.1 85.5 77.8 120.3 77.8 9.8.3 19.2-3.6 26.1-10.6 39.6-40 29.7-108.4 9.5-128.8z"
      />
      <path
        className="d"
        d="M390.3 223.9l-95.1-96a9.3 9.3 0 00-13.4 0l-76 76.8c-12.4 12.6-11.4 40.3 28.5 80.8 26.1 26.4 46.8 35.9 61.6 35.9 6.8.3 13.4-2.3 18.3-7.1l76.1-76.8c3.7-3.8 3.7-9.8 0-13.6z"
      />
      <path
        className="c"
        d="M487.3 198.2c-1.5-3.6-4.9-5.9-8.8-5.9-20.6 0-65.8-22.5-97.7-54.8-32-32.3-54.3-78-54.3-98.9 0-5.3-4.2-9.6-9.5-9.6-2.5 0-4.9 1-6.7 2.8-38.8 39.3-50.9 106.2 9.5 167.3 23 25.8 55.3 41.1 89.7 42.4 28.5-.3 55.8-12.1 75.7-32.8 2.8-2.8 3.6-6.9 2.1-10.5z"
      />
      <path
        className="d"
        d="M428.3 89.5C388 48.8 351.6 29 317 29c-5.2 0-9.5 4.3-9.5 9.6 0 28.7 26.9 79.1 59.8 112.5 32.9 33.3 82.9 60.4 111.2 60.4 5.3 0 9.5-4.3 9.5-9.6 0-35-19.5-71.8-59.7-112.4z"
      />
    </svg>
  );
}