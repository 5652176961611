import {Avatar, Tooltip} from 'antd'
import React, {useContext, useState} from 'react'
import {organizationModel, productModel} from '../../../../apis/model'
import {productSchema} from '../../../../apis/schema'
import {SelectEntityItem} from '../../../../components/SelectEntityItem'
import useAsyncWithCache from '../../../../modules/asyncCache/useAsyncWithCache'
import useTranslate from '../../../../modules/local/useTranslate'
import {OrganizationContext} from '../../OrganizationContext'
import {API_ROOT_URL} from "../../../../envs/_current/config";
import _ from "lodash"
import {useHistory} from "../../../../modules/navigation/useRouter";
import ServerContext from "../../../Server/ServerContext";
import {ProductSaleSettingHeadlineView} from "../../../Market/Seller/Product/ProductSettings/ProductSaleSettingHeadlineView";
import Toggle from "../../../../components/Toggle";
import {LoginContext} from "../../../../components/LoginContext";
import UpCircleOutlined from "@ant-design/icons/lib/icons/UpCircleOutlined";
import DownCircleOutlined from "@ant-design/icons/lib/icons/DownCircleOutlined";
import ToggleView from "../../../MainPage/ToggleView";
import {ProductView} from "../../../Market/Impcat/Impcat";
import {HeaderBlock} from "../../../MainPage/MySpace/Home";
import {HighLightTitle} from "../../../AppLocals/Organization/OrganizationEntity";
import {BsCaretDownFill, BsCaretUpFill} from "react-icons/bs";

export function ProductPublicView ({view = "block"}) {

  const {
    groups = [],
    organization
  } = useContext(OrganizationContext)
  const {
    item,
    handleActionClick,
    contextMenu
  } = useContext(ServerContext)
  const oId = organizationModel.getId(
    organization
  )
  const editable = !!_.get(organization, 'edit');
  useAsyncWithCache({
    cacheId: `/products/get-price-periods?day=1`,
    apiInfo: {
      root: API_ROOT_URL,
      path: `/products/get-price-periods?day=1`,
      method: 'GET'
    }
  })
  const t = useTranslate()
  const [filter, setFilter] = useState();
  const [sortBy, setSortBy] = useState("product_catalog");

  const flattenProducts = _.flatMap(groups, (e) => e.products).filter(e => !!e && !!e.active && e.status === 1);
  const groupByProducts = _.groupBy(flattenProducts, function (item) {
    return item[sortBy];
  });

  return (
    <ToggleView
        height={24}
        renderHeadlineView={props => <ProductSaleSettingHeadlineView {...props} />}
        renderListView={props => <ProductView {...props} />}>
      {({switchComponent, renderItem}) => (
          <section id={"workspace_product_list"} className="space-y-5 right col-span-3">
            <HeaderBlock
                title="products"
                extra={switchComponent && switchComponent}
                className="pb-1"
            />
            {Object.keys(groupByProducts).sort().map((o, i) => (
              <Toggle defaultValue={true} key={i}>
                {(isToggleCollapse, toggleCollapse) => (
                    <div
                      key={i}
                      className="flex flex-col gap-3 relative">
                      <HighLightTitle
                          title={!!o && o !== 'null' ? o : t('others')}
                          extra={
                            isToggleCollapse ?
                                <Tooltip title={t('see less')} placement="right" overlayClassName="font-medium text-xs italic">
                                  <BsCaretUpFill onClick={toggleCollapse} className="text-primary hover:text-primary-700 cursor-pointer"/>
                                </Tooltip> :
                                <BsCaretDownFill onClick={toggleCollapse} className="text-primary hover:text-primary-700 cursor-pointer"/>}
                      />
                      {isToggleCollapse && _.orderBy(groupByProducts[o], [sortBy === "product_catalog" ? "product_line" : "product_catalog"], ['asc'])
                          .map(product => {
                            const id = productModel.getId(
                                product
                            )
                            const visible =
                                !filter ||
                                (
                                    product.title +
                                    product.idname
                                ).includes(filter);
                            if (!visible)
                              return null;

                            return (
                                <SelectEntityItem
                                    key={id}
                                    item={id}
                                    schema={
                                      productSchema
                                    }>
                                  {(
                                      item = product
                                  ) => {
                                    return !!item && renderItem({item})
                                  }
                                  }
                                </SelectEntityItem>
                            )
                          })
                      }
                    </div>
                )}
              </Toggle>
            ))}
          </section>
      )}
    </ToggleView>
  )
}
