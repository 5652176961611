import {Select} from 'antd'
import classNames from 'classnames'
import filterOption from 'helpers/filterOption'
import Translate from 'modules/local/Translate'
import React from 'react'
import {FaCaretDown} from 'react-icons/fa'
import {Null} from 'views/Shared'

const IndicatorsContainer = ({children}) => (
  <div
    className="flex items-center"
    style={{
      padding: '0 8px',
    }}>
    {children}
  </div>
)

const DropdownIndicator = () => (
  <FaCaretDown
    style={{
      color: 'var(--text-color-500)',
      marginLeft: '8px',
    }}
  />
)

const DefaultEmptyHolder = React.memo(() => (
  <Translate>{(t) => <div>{t('no data is available now')}</div>}</Translate>
))

export const reactSelectOnTop = {
  styles: {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  },
  menuPortalTarget: document.body,
  noOptionsMessage: () => <DefaultEmptyHolder/>,
}

export const dropdownComponents = {
  DropdownIndicator,
  IndicatorsContainer,
  LoadingIndicator: Null,
}

export const reactSelectComponents = {
  DropdownIndicator,
  IndicatorsContainer,
  IndicatorSeparator: Null,
  LoadingIndicator: Null,
}

export const SelectWidget = React.forwardRef(
  ({className, suffixIcon = <DropdownIndicator/>, ...props}, ref) => (
    <Select
      ref={ref}
      suffixIcon={props.loading ? undefined : suffixIcon}
      filterOption={filterOption}
      className={classNames('SelectWidget custom-select-widget', className)}
      {...props}
    />
  )
)

export const SelectDropdownIndicator = DropdownIndicator
