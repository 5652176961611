import {Avatar, Button, List} from 'antd'
import {host_searchHostsByOwner_api} from 'apis'
import {getId, getType} from 'apis/model/base'
import classNames from 'classnames'
import getAvatar from 'helpers/getAvatar'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import Async from 'modules/asyncCache/components/Async'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import React, {useCallback, useContext, useState} from 'react'
import {Redirect} from 'react-router-dom'
import AntdConfigWrapper from 'views/AppLocals/custom/AntdConfigWrapper'
import {useDebounceSearch} from 'views/AppLocals/custom/DebounceSearch'
import {bindQueryParams} from 'views/AppLocals/functions/routerHelper'
import LoadingPage from 'views/LoadingPage'
import {getResponseItems, Null} from 'views/Shared'
import {useHostActions} from '../HostContext'
import {fromHostStatus} from '../Settings/HostContent'
import {renderIfElse} from "../../../Shared";
import {SearchGroupIcon} from "../../../../components/AppIcon";

const renderRow =
  ({onClick = Null}) =>
    (item, index) => {
      const {certified, owner = {}, excluded = false} = item || {}

      const id = getId(owner)
      const prop = getType(owner)

      const host_status = fromHostStatus(-1)

      return (
        <div
          key={index}
          className="relative rounded-lg hover:shadow-items border border-color-50 mb-3">
          <Translate>
            {(t) => (
              <div className="flex items-center gap-2 p-3">
                <Avatar
                  src={getAvatar(owner)}
                  size={35}
                  className="flex flex-center background-200"
                  icon={
                    <SearchGroupIcon size={20} type={getType(owner)} className="text-color-500"/>
                  }
                />
                <div className="flex-1 font-bold text-color-000">
                  <span>{getTitle(owner)}</span>
                </div>
                {renderIfElse(
                  excluded,
                  <div
                    className={classNames(
                      host_status.className,
                      'rounded-md px-3 italic flex items-center gap-1 text-sm'
                    )}>
                    {host_status.icon}
                    <span>{t(host_status.label)}</span>
                  </div>,
                  <Button
                    type="primary"
                    size="small"
                    className="capitalize text-xs font-medium button-rounded-md"
                    onClick={() => {
                      onClick(id, prop)
                    }}>
                    {t('send request')}
                  </Button>
                )}
              </div>
            )}
          </Translate>
        </div>
      )
    }

export const HostPickerModal = ({
                                  item,
                                  owner_id,
                                  owner_type,
                                  Wrapper = 'div',
                                }) => {
  const t = useTranslate()

  const [excluded_ids, set_excluded_ids] = useState([])

  const apiInfo = host_searchHostsByOwner_api

  const [keyword, __, searchInput] = useDebounceSearch({
    timeout: 500,
    className: 'w-full p-2',
  })

  const {onCreate = Null} = useHostActions({
    container: item,
    onCreated: (result, data) => {
      const host_id = getId(_.get(data, 'response.data.host'))
      set_excluded_ids([...excluded_ids, host_id])
    },
  })

  const renderHost = useCallback(
    (item, index) =>
      renderRow({
        onClick: (host_id, host_type) =>
          onCreate({
            host_id,
            host_type,
          }),
      })(item, index),
    [onCreate]
  )

  return (
    <Wrapper className="space-y-3 py-2">
      <div className="text-center text-xl font-bold uppercase text-color-000">
        {t('add host')}
      </div>
      {searchInput}
      <Async
        key={keyword}
        query={bindQueryParams([{id: owner_id}, {prop: owner_type}])}
        values={{
          keyword,
        }}
        apiInfo={apiInfo}>
        {({response, isLoading}) => {
          if (Boolean(isLoading)) {
            return <LoadingPage/>
          }
          const items = getResponseItems(response) || []
          return (
            <List
              loading={!!isLoading}
              dataSource={items}
              renderItem={(item, index) =>
                renderHost({
                  ...item,
                  excluded: excluded_ids.includes(getId(item)),
                })
              }
              pagination={{
                pageSize: 8,
                hideOnSinglePage: true,
              }}
            />
          )
        }}
      </Async>
    </Wrapper>
  )
}

const AddHostModalContent = ({Wrapper = 'div', ...props}) => {
  const t = useTranslate()

  const [excluded_ids, set_excluded_ids] = useState([])

  const {setCurrent} = useContext(NavigationContext)

  const [keyword, __, searchInput] = useDebounceSearch({
    timeout: 500,
    className: 'w-full rounded-lg p-2',
  })

  const apiInfo = host_searchHostsByOwner_api

  const {id, prop} = _.get(props, 'location.state', {})

  const {onCreate = Null} = useHostActions({
    container: {
      id,
      _type: prop,
    },
    onCreated: (result, data) => {
      const host_id = getId(_.get(data, 'response.data.host'))
      setCurrent(result)
      set_excluded_ids([...excluded_ids, host_id])
    },
  })

  const renderHost = useCallback(
    (item, index) =>
      renderRow({
        onClick: (host_id, host_type) =>
          onCreate({
            host_id,
            host_type,
          }),
      })(item, index),
    [onCreate]
  )

  if (_.every([id, prop], _.isEmpty)) {
    return <Redirect to="/"/>
  }

  return (
    <Wrapper className="space-y-3 py-3">
      <div className="text-center text-xl font-bold uppercase text-color-000">
        {t('add host')}
      </div>
      {searchInput}
      <Async
        key={keyword}
        query={bindQueryParams([{id}, {prop}])}
        values={{
          keyword,
        }}
        apiInfo={apiInfo}>
        {({response, isLoading}) => {
          if (Boolean(isLoading)) {
            return <LoadingPage/>
          }
          const items = getResponseItems(response) || []
          return (
            <List
              loading={!!isLoading}
              dataSource={items}
              renderItem={(item, index) =>
                renderHost({
                  ...item,
                  excluded: excluded_ids.includes(getId(item)),
                })
              }
              pagination={{
                pageSize: 8,
                hideOnSinglePage: true,
              }}
            />
          )
        }}
      </Async>
    </Wrapper>
  )
}

const AddHostModal = (props) => (
  <AntdConfigWrapper>
    <AddHostModalContent {...props} />
  </AntdConfigWrapper>
)

export default AddHostModal
