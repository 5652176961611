import {Button, Input, message} from 'antd'
import {auth_requestResetEmail_Api} from 'apis'
import Modal from 'components/Modal/Modal'
import Toggle from 'components/Toggle'
import {Formik} from 'formik'
import _ from 'lodash'
import AsyncAction from 'modules/asyncCache/components/AsyncAction'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {getLogin} from 'redux/selectors'
import {Selector} from 'views/Discovery/Recommendation'

export const CheckEmail = () => {
  const t = useTranslate()
  return (
    <Selector selector={getLogin}>
      {login => {
        return (
          <Toggle
            key={
              !!login &&
              Number(
                login.reset_email
              ) === 1
            }
            defaultValue={
              !!login &&
              Number(
                login.reset_email
              ) === 1
            }>
            {(isToggle, toggle) => {
              if (!isToggle) return null
              return (
                <Toggle>
                  {(
                    openEmailForm,
                    toggleOpenEmailForm
                  ) => {
                    return (
                      <div className="w-full  flex  items-center justify-center  bg-blue-100 shadow-inner">
                        {login.preset_email ? (
                          <div
                            style={{
                              color:
                                '#ff7875'
                            }}
                            className="px-3 text-xs text-center font-semibold py-2 ">
                            {t(
                              'Confirmation change email address has been sent to'
                            )}{' '}
                            {
                              login.preset_email
                            }
                            <Button
                              onClick={() =>
                                toggleOpenEmailForm()
                              }
                              style={{
                                marginLeft:
                                  '1em',
                                marginRight:
                                  '1em'
                              }}
                              size="small"
                              type="danger">
                              {t(
                                'resend'
                              )}
                            </Button>
                          </div>
                        ) : (
                          <div
                            style={{
                              color:
                                '#ff7875'
                            }}
                            className="px-3 text-xs text-center font-semibold py-2 ">
                            {t(
                              'This account has not registered an email address for authentication, please register an email address to verify the ownership of the account'
                            )}
                            <Button
                              onClick={() =>
                                toggleOpenEmailForm()
                              }
                              style={{
                                marginLeft:
                                  '1em',
                                marginRight:
                                  '1em'
                              }}
                              size="small"
                              type="danger">
                              {t(
                                'Verify Account'
                              )}
                            </Button>
                          </div>
                        )}
                        {openEmailForm && (
                          <AsyncAction
                            apiInfo={
                              auth_requestResetEmail_Api
                            }
                            onSuccess={(
                              ...args
                            ) =>
                              toggleOpenEmailForm()
                            }
                            onError={(
                              ...args
                            ) =>
                              message.error(
                                _.get(
                                  args,
                                  '1.error'
                                )
                              )
                            }>
                            {({
                              handleAsyncAction,
                              isLoading
                            }) => {
                              return (
                                <Formik
                                  onSubmit={
                                    handleAsyncAction
                                  }
                                  initialValues={{
                                    email:
                                      login.preset_email ||
                                      ''
                                  }}>
                                  {({
                                    values,
                                    isSubmitting,
                                    handleSubmit,
                                    handleChange
                                  }) => {
                                    return (
                                      <Modal
                                        confirmLoading={
                                          isLoading
                                        }
                                        title={t(
                                          'Confirmation letter will be sent to your email address'
                                        )}
                                        visible={
                                          openEmailForm
                                        }
                                        onOk={() =>
                                          handleSubmit()
                                        }
                                        onCancel={
                                          toggleOpenEmailForm
                                        }>
                                        <div className="verticalList py-4">
                                          <Input
                                            name="email"
                                            inputMode="email"
                                            value={
                                              values.email
                                            }
                                            onChange={
                                              handleChange
                                            }
                                            placeholder={t(
                                              'email'
                                            )}
                                            size="large"></Input>
                                        </div>
                                      </Modal>
                                    )
                                  }}
                                </Formik>
                              )
                            }}
                          </AsyncAction>
                        )}
                      </div>
                    )
                  }}
                </Toggle>
              )
            }}
          </Toggle>
        )
      }}
    </Selector>
  )
}
