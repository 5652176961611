import useAsyncAction from '../useAsyncAction';

const AsyncAction = ({
	children,
	...props
}) => {
	const result = useAsyncAction(props);

	return children(result);
};
export default AsyncAction;
