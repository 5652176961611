import React from 'react';
import useTranslate from './useTranslate';

// const defaultTranform = value => value
const withTranslate = Component => {
	return function WrapperComponent(
		props
	) {
		const translate = useTranslate();
		return (
			<Component
				{...props}
				translate={translate}
			/>
		);
	};
};
export default withTranslate;
