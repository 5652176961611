import {Input, Tabs} from 'antd'
import {getId} from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useCallback, useContext, useMemo, useState} from 'react'
import {BiDotsHorizontalRounded, BiTrash} from 'react-icons/bi'
import {Null} from 'views/Shared'
import {EventActionTypes} from '.'
import EntityList from '../EntityList'
import {AppEntities} from '../enums'
import {showDeleteConfirmDialog} from '../factory/createConfirmDialog'
import createContextMenu from '../factory/createContextMenu'
import {bindQueryParam} from '../functions/routerHelper'
import {renderEventParticipant} from './functions/renderEventParticipant'
import {useEventParticipant} from './useEventParticipant'
import UseState from "../../../components/UseState";
import {LazyPagination} from "../../../modules/asyncCache/components/LazyPagination";
import {renderLoading} from "../functions/renderers/renderLoading";
import EmptyHolder from "../../../components/EmptyHolder";
import {auth_getMyFollowingEvents_Api, participant_getParticipantsByAuth_Api} from "../../../apis";
import {renderEventItem} from "../../MainPage/functions/renderEventItem";
import {LayoutContext} from "../../../components/layouts/Default/LayoutContext";
import * as moment from "moment";


export const CalendarAvatar = ({ time, color }) => {
    const { isSm } = useContext(LayoutContext)
    const dt = moment(time) || moment()

    const LeftPattern = () => (
        <div
            style={{
                position: 'absolute',
                width: 8,
                height: 8,
                top: 6,
                left: 16,
                borderRadius: 5,
                backgroundColor: color || '#fff',
            }}
        />
    )

    const RightPattern = () => (
        <div
            style={{
                position: 'absolute',
                width: 8,
                height: 8,
                top: 6,
                right: 16,
                borderRadius: 5,
                backgroundColor: color || '#fff',
            }}
        />
    )

    var month = dt.format('MMMM')
    var day = dt.format('DD')

    return (
        <div
            style={{
                zIndex: 1,
                width: 80,
                height: 80,
                borderTopLeftRadius: '0.5rem',
                borderBottomLeftRadius: '0.5rem',
            }}
            className="flex flex-col flex-center gap-1 bg-primary-600 p-2 relative">
            <LeftPattern />
            <RightPattern />
            <div className="flex flex-center font-bold text-xs text-white uppercase leading-snug">
                {month}
            </div>
            <div
                style={{ width: '85%', lineHeight: 1.2 }}
                className="flex flex-center rounded font-bold text-lg md:text-xl text-primary-600 bg-white tracking-wide">
                {day}
            </div>
        </div>
    )
}

const auth_tabs = {
  following_events: {
    label: 'my following events',
    value: 'following_events',
    apiInfo: auth_getMyFollowingEvents_Api,
    renderItem: renderEventItem,
  },
  participants: {
    label: 'participants',
    value: 'participants',
    apiInfo: participant_getParticipantsByAuth_Api,
    query: bindQueryParam({type: AppEntities.EVENT}),
    renderItem: renderEventParticipant({}),
  },
}

const EventList = ({query, values, apiInfo, ...props}) => {
  const t = useTranslate()

  const {onUnsubscribe = Null, ...rest} = useEventParticipant() ?? {}

  const refreshKey = useMemo(
    () =>
      [
        apiInfo?.path,
        JSON.stringify({query, values}),
        `refreshKey=${rest.refreshKey}`,
      ].join('/'),
    [query, values, apiInfo, rest.refreshKey]
  )

  const handleAction = (action, params) => {
    switch (action) {
      case EventActionTypes.UNSUBSCRIBE:
        showDeleteConfirmDialog({
          translate: t,
          onOk: () => {
            onUnsubscribe({}, {id: getId(params)})
          },
        })
        break
      default:
        break
    }
  }

  const renderContextMenu = useCallback(
    (item) =>
      createContextMenu(
        [
          {
            icon: <BiTrash/>,
            label: 'delete',
            key: EventActionTypes.UNSUBSCRIBE,
          },
        ],
        (key) => {
          handleAction(key, item)
        },
        {
          icon: (
            <BiDotsHorizontalRounded
              size={22}
              className="p-1 hover:background-100 rounded-full text-color-500 hover:text-color-100 cursor-pointer"
            />
          ),
        }
      ),
    []
  )

  return (
    <EntityList
      key={refreshKey}
      query={query}
      values={values}
      apiInfo={apiInfo}
      refreshKey={refreshKey}
      RenderEntity={React.Fragment}
      renderItem={renderEventParticipant({renderContextMenu})}
      {...props}
    />
  )
}

// const MyEvents = (props) => (
//   <div className="flex flex-col space-y-3">
//     <EventList
//       query={bindQueryParam({ type: AppEntities.EVENT })}
//       apiInfo={participant_getParticipantsByAuth_Api}
//       {...props}
//     />
//   </div>
// )
const MyEvents = ({...props}) => {
  const t = useTranslate()

  return (
    <div className="w-full space-y-4">
      <UseState
        initialValue={{
          value: undefined,
          type: auth_tabs.following_events.value,
        }}>
        {([action, setAction]) => {
          const {
            label,
            value,
            withProps = Null,
            ...rest
          } = _.get(auth_tabs, action?.type) ?? {}


          return (
            <div className="flex flex-col gap-3">
              <Tabs
                className="custom-tabs-activity"
                activeKey={action?.type}
                onChange={(type) => {
                  setAction({
                    ...(action ?? {}),
                    type,
                  })
                }}>
                {[auth_tabs.following_events, auth_tabs.participants].map(
                  ({label, value}, i) => {
                    return (
                      <Tabs.TabPane
                        key={value}
                        tab={
                          <div
                            key={i}
                            className="custom-tab-label"
                            style={
                              i === 0 ? {
                                borderTopLeftRadius: 5,
                                borderBottomLeftRadius: 5,
                                borderRight: 0,
                              } : {
                                borderTopRightRadius: 5,
                                borderBottomRightRadius: 5,
                              }
                            }>
                            {t(label)}
                          </div>
                        }
                      />
                    )
                  }
                )}
              </Tabs>
              <LazyPagination
                key={JSON.stringify(action)}
                renderLoading={renderLoading}
                renderEmpty={() => <EmptyHolder/>}
                {...rest}
                {...(withProps({
                  onRefresh: () => {
                    setAction({
                      ...(action ?? {}),
                      value: Date.now(),
                    })
                  },
                }) ?? {})}
              />
            </div>
          )
        }}
      </UseState>
    </div>
  )
}

export const EventActivities = (props) => {
  const t = useTranslate()
  const [keyword, setKeyword] = useState()
  const onSearch = _.debounce((keyword) => {
    setKeyword(keyword)
  }, 500)
  return (
    <div className="flex flex-col space-y-3">
      <Input.Search
        name="keyword"
        autoComplete="off"
        allowClear={true}
        onChange={(event) => {
          onSearch(event?.target?.value)
        }}
        onSearch={onSearch}
        placeholder={t('keyword')}
        className="input-search-with-icon"
      />
      <EventList
        values={{
          ...(props?.values ?? {}),
          keyword,
        }}
        apiInfo={participant_getParticipantsByAuth_Api}
        query={bindQueryParam({type: AppEntities.EVENT})}
        {...props}
      />
    </div>
  )
}

export default MyEvents
