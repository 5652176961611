
import createApp from 'clients/createApp'
import { modalRoutesConfig, routesConfig } from 'clients/shop/martRoutes'
import startApp from 'clients/startApp'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from 'routes'
import { createRoutes } from "routes/createRoutes"
import config from './config'
import history from './history'
const App = createApp({
  config,
  history
})
startApp({
  app: <App>
    <Router>
      <Routes
        Routes={
          createRoutes({
            modalRoutesConfig: modalRoutesConfig,
            routesConfig: routesConfig
          })
        }
      ></Routes>
    </Router>
  </App>,
  history: history,
})