import React, {
  lazy,
  Suspense
} from 'react'

const SearchLazy = lazy(() =>
  import('./MarketSearch')
)

const MarketSearch = props => (
  <Suspense fallback={null}>
    <SearchLazy {...props} />
  </Suspense>
)

export default MarketSearch
