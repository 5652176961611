import React from "react";

export const Fruits = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="-4 0 512 512.001"
		>
			<path
				fill="#ffcf5d"
				d="M475.895 83.762c-2.618-8.215-7.29-14.645-13.102-19.254-3.59-2.852-5.625-7.23-5.625-11.817V15.238a7.617 7.617 0 00-7.617-7.617h-15.235a7.616 7.616 0 00-7.613 7.617v31.766c0 5.625-3.082 10.832-8.07 13.434-7.176 3.75-13.387 9.812-17.426 18.265-17.023 35.66-47.238 109.488-109.3 171.43-61.942 62.062-135.774 92.277-171.43 109.304-32.66 15.594-29.778 63.586 5.054 74.684 20.344 6.48 42.946 9.59 66.606 9.16 69.027-1.246 141.633-39.96 197.297-95.62 55.66-55.661 94.375-128.266 95.62-197.298.43-23.66-2.679-46.258-9.16-66.601zm0 0"
			></path>
			<path
				fill="#ffb545"
				d="M443.86 88.375c1.378 1.094 2.359 2.605 3.003 4.633 5.5 17.262 8.102 36.37 7.73 56.808-1.03 57.137-33.445 123.043-86.702 176.305-53.262 53.258-119.168 85.668-176.309 86.703-16.144.293-29.121 13.094-29.871 28.996 9.871 1.16 20.047 1.653 30.426 1.465 69.027-1.25 141.633-39.965 197.297-95.625 55.66-55.66 94.375-128.265 95.62-197.297.43-23.656-2.679-46.258-9.16-66.601-2.617-8.215-7.289-14.645-13.101-19.254-3.59-2.852-5.625-7.23-5.625-11.817V15.238a7.617 7.617 0 00-7.617-7.617h-15.235a7.616 7.616 0 00-7.613 7.617v37.453c0 14.028 6.254 27.036 17.156 35.684zm0 0"
			></path>
			<path
				fill="#28d05b"
				d="M89.473 230.8l-25.618-16.222c-6.44-4.078-14.542 1.992-12.312 9.238l7.52 24.414c5.601 18.204 17.199 34.024 32.94 44.946l25.173 16.562c0-29.66-1.149-62.12-27.703-78.937zm0 0"
			></path>
			<path
				fill="#b66652"
				d="M204.68 227.238l-4.063-4.062a7.62 7.62 0 00-7.793-1.84c-39.664 13.223-68.965 43.36-84.73 87.156l14.332 5.16c13.73-38.148 37.543-63.797 70.816-76.304l.664.664a7.618 7.618 0 0010.774-10.774zm0 0"
			></path>
			<path
				fill="#ff5368"
				d="M159.309 298.68c-14.77-.54-29.082 3.308-42.133 10.59-13.051-7.282-27.364-11.133-42.133-10.59-30.879 1.316-53.09 17.879-64.934 39.203-11.89 21.672-13.41 48.11-3.93 77.281 14.5 44.488 52.977 84.336 89.532 86.426 3.59.242 7.102.172 10.516-.192 7.23-.761 14.668-.761 21.894 0 3.414.364 6.926.434 10.516.192 36.554-2.09 75.035-41.938 89.535-86.426 9.48-29.172 7.957-55.61-3.93-77.281-11.844-21.324-34.054-37.887-64.933-39.203zm0 0"
			></path>
			<path
				fill="#d9435b"
				d="M224.242 337.883c-5.797-10.438-14.082-19.727-24.648-26.653a78.16 78.16 0 013.117 5.122c11.887 21.671 13.41 48.109 3.93 77.285-9.739 29.867-30.286 57.648-53.801 73.308-12.985 8.653-28.313 13.114-43.918 13.114H48.957c14.52 12.523 30.82 20.62 46.758 21.53 3.59.247 7.101.173 10.515-.19 7.282-.77 14.625-.766 21.903.003 3.414.36 6.922.43 10.508.188 36.55-2.086 75.035-41.938 89.53-86.422 9.481-29.176 7.958-55.61-3.929-77.285zm0 0"
			></path>
			<path
				fill="#ff983b"
				d="M432.785 313.293c-3.203-13.398-15.246-23.36-29.625-23.36-14.375 0-26.418 9.962-29.62 23.36-41.638 12.676-71.93 51.375-71.93 97.156C301.61 466.535 347.073 512 403.16 512s101.55-45.465 101.55-101.55c0-45.782-30.292-84.481-71.925-97.157zm0 0"
			></path>
			<path
				fill="#ff7e40"
				d="M463.39 328.684c12.434 16.851 19.79 37.683 19.79 60.234 0 56.086-45.47 101.55-101.551 101.55-22.55 0-43.379-7.355-60.23-19.788C339.89 495.742 369.624 512 403.16 512c56.086 0 101.55-45.465 101.55-101.55 0-33.536-16.257-63.27-41.32-81.766zm0 0"
			></path>
			<path
				fill="#b66652"
				d="M395.543 249.27v70.812c0 4.168 3.215 7.805 7.383 7.93 4.312.133 7.851-3.328 7.851-7.614v-79.343c-6.539-4.297-15.234.39-15.234 8.215zm0 0"
			></path>
			<path
				fill="#28d05b"
				d="M461.176 316.957l-7.516-24.414c-5.605-18.203-17.203-34.027-32.941-44.945l-9.942-6.54c-6.539-4.3-15.234.387-15.234 8.212v10.82c0 20.367 9.742 38.512 27.703 49.883l25.621 16.226c6.438 4.074 14.54-2 12.309-9.242zm0 0"
			></path>
			<path
				fill="#ff7e40"
				d="M363.738 360.004c0 4.207-3.406 7.617-7.613 7.617a7.617 7.617 0 110-15.234 7.614 7.614 0 017.613 7.617zm0 0M384.05 388.441c0 4.207-3.405 7.618-7.613 7.618s-7.617-3.41-7.617-7.618c0-4.207 3.41-7.617 7.618-7.617s7.613 3.41 7.613 7.617zm0 0M343.43 410.785c0 4.207-3.41 7.613-7.618 7.613s-7.617-3.406-7.617-7.613a7.617 7.617 0 1115.235 0zm0 0"
			></path>
			<path
				fill="#28d05b"
				d="M292.148 28.102a7.618 7.618 0 00-9.953-4.122l-4.562 1.887 3.492-3.492a7.61 7.61 0 000-10.77 7.618 7.618 0 00-10.77 0l-3.492 3.493 1.89-4.563A7.62 7.62 0 00264.63.582c-3.883-1.613-8.34.234-9.95 4.125l-4.136 9.984 27.5 27.496 9.984-4.136a7.617 7.617 0 004.121-9.95zm0 0"
			></path>
			<path
				fill="#ff5368"
				d="M212.594 22.688c15.863-15.864 39.43-13.708 55.293 2.156s18.015 39.43 2.152 55.293-58.098 32.379-73.96 16.515c-15.864-15.863.651-58.097 16.515-73.965zm0 0"
			></path>
			<path
				fill="#d9435b"
				d="M267.887 24.844c-3.809-3.809-8.063-6.817-12.547-8.989 6.863 14.192 5.223 30.692-6.832 42.747-12.055 12.058-39.332 24.472-58.7 22.785.536 6.117 2.462 11.457 6.27 15.265 15.863 15.864 58.098-.652 73.961-16.515 15.867-15.864 13.711-39.43-2.152-55.293zm0 0"
			></path>
			<g fill="#ffcf5d">
				<path d="M243.145 57.203a7.616 7.616 0 01-15.23 0 7.616 7.616 0 1115.23 0zm0 0M221.602 78.742a7.616 7.616 0 11-15.23 0 7.616 7.616 0 0115.23 0zm0 0M221.602 57.203a7.615 7.615 0 11-15.231-.004 7.615 7.615 0 0115.23.004zm0 0M243.145 78.742a7.616 7.616 0 11-7.617-7.613 7.616 7.616 0 017.617 7.613zm0 0M264.688 57.203a7.616 7.616 0 01-7.618 7.613 7.616 7.616 0 110-15.23 7.617 7.617 0 017.618 7.617zm0 0M243.145 35.66a7.616 7.616 0 01-15.23 0 7.616 7.616 0 1115.23 0zm0 0"></path>
			</g>
			<path
				fill="#5c87bd"
				d="M187.363 156.145c0 28.042-22.734 50.773-50.777 50.773s-50.774-22.73-50.774-50.773 22.731-50.778 50.774-50.778 50.777 22.735 50.777 50.778zm0 0"
			></path>
			<path
				fill="#456ea2"
				d="M160.07 111.129a50.538 50.538 0 015.762 23.484c0 28.04-22.734 50.774-50.777 50.774a50.535 50.535 0 01-23.485-5.758c8.477 16.215 25.45 27.289 45.016 27.289 28.043 0 50.777-22.73 50.777-50.773 0-19.57-11.074-36.54-27.293-45.016zm0 0"
			></path>
			<path
				fill="#315588"
				d="M145.059 138.371l2.23-2.23a7.618 7.618 0 000-10.77 7.622 7.622 0 00-10.773 0l-2.23 2.23-2.231-2.23a7.618 7.618 0 00-10.77 0 7.61 7.61 0 000 10.77l2.23 2.23-2.23 2.23a7.615 7.615 0 005.387 13.004c1.95 0 3.898-.746 5.387-2.23l2.226-2.23 2.23 2.23a7.608 7.608 0 005.387 2.23 7.615 7.615 0 005.387-13.004zm0 0"
			></path>
			<path
				fill="#28d05b"
				d="M333.621 128.285c-1.71-3.805-6.3-5.383-10.152-3.785l-4.27 1.766 3.27-3.266c2.945-2.95 3.246-7.793.39-10.828a7.62 7.62 0 00-10.937-.168l-3.492 3.488 1.89-4.558a7.618 7.618 0 00-4.12-9.954 7.614 7.614 0 00-9.954 4.122l-4.137 9.984a7.583 7.583 0 00.582 6.953c2.047 3.277 1.692 7.504-1.043 10.238l-.066.063c-3.035 3.035-3.203 8.172-.094 11.133a7.615 7.615 0 0010.637-.13c3.18-3.179 7.32-3.292 10.605-1.292a7.583 7.583 0 006.88.535l9.984-4.137a7.616 7.616 0 004.027-10.164zm0 0"
			></path>
			<path
				fill="#d9435b"
				d="M312.492 132.973c-5.562-1.493-11.347.277-15.152 4.406l-24.988-7.8-18.493 19.41-7.847 29.277c-.09-.024-.18-.055-.27-.082-8.125-2.18-16.476 2.636-18.66 10.761-2.184 8.125 2.637 16.48 10.762 18.664 8.125 2.18 16.48-2.636 18.66-10.761.629-2.34.726-4.746.219-7.106 1.558 4.703 5.41 8.606 10.554 9.985 8.13 2.175 16.48-2.645 18.66-10.77.668-2.492.696-5.113.075-7.617a15.095 15.095 0 003.867 6.543c5.95 5.949 15.594 5.949 21.539 0 5.95-5.95 5.95-15.594 0-21.543a15.268 15.268 0 00-6.633-3.89c8.07 2.066 16.317-2.75 18.477-10.821 2.18-8.125-2.64-16.48-10.77-18.656zm0 0"
			></path>
			<path
				fill="#ff5368"
				d="M290.95 111.441c-11.329-3.03-21.673 7.371-18.653 18.653-1.762-6.602-7.77-11.281-14.715-11.281a15.169 15.169 0 00-10.77 4.46c-5.949 5.95-5.949 15.594 0 21.543a15.117 15.117 0 007.176 4.036c-8.297-2.43-16.816 2.496-19.008 10.687-2.175 8.125 2.653 16.477 10.778 18.652 8.129 2.172 16.48-2.652 18.652-10.78a15.194 15.194 0 000-7.872c1.723 6.313 7.461 11.281 14.715 11.281.023 0 .047-.004.07-.004 13.395-.058 20.301-16.402 10.703-26a15.075 15.075 0 00-6.761-3.93c8.11 2.134 16.422-2.687 18.59-10.792 2.175-8.13-2.653-16.477-10.778-18.653zm0 0"
			></path>
		</svg>
	);
}

export default Fruits;
