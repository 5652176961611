import {Skeleton} from 'antd'
import React from 'react'

export const renderLoading = () => (
  <div className="w-full flex justify-center">
    <Skeleton
      active={true}
      loading={true}
      className="p-3"
    />
  </div>
)
