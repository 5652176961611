import _ from 'lodash'
import {staticPaths} from 'routes/staticPaths'
import {SearchParams, SearchTypes} from 'views/Search/SearchProvider'
import {Null} from 'views/Shared'
import {withSearchParams} from 'views/Shared/routerHelper'
import {
  ArticleExplorer,
  ChannelExplorer,
  DataSetExplorer,
  EventExplorer,
  GeneralExplorer,
  OrganizationExplorer
} from './createDiscoveryExplorer'

const rootPath =
  staticPaths.discovery.pathname

const getSearch = type => location => {
  let _searchParams = new URLSearchParams(
    _.get(location, 'search', {})
  )
  _searchParams.set(
    SearchParams.TYPE,
    type
  )
  return [
    '?',
    _searchParams.toString()
  ].join('')
}

export const discoveryExplorerRouteEntities = [
  {
    type: SearchTypes.ALL,
    path: rootPath,
    invisible: true,
    getSearch: Null,
    component: GeneralExplorer
  },

  {
    type: SearchTypes.CHANNEL,
    component: ChannelExplorer
  },

  {
    type: SearchTypes.ARTICLE,
    component: ArticleExplorer
  },

  {
    type: SearchTypes.EVENT,
    component: EventExplorer
  },
  {
    type: SearchTypes.DATASET,
    component: DataSetExplorer
  },
  {
    type: SearchTypes.ORGANIZATION,
    component: OrganizationExplorer
  }
].map(({ type, ...props }) => {
  const search = `${SearchParams.TYPE}=${type}`
  return {
    type,
    search,
    pathname: rootPath,
    getSearch: withSearchParams({
      [SearchParams.TYPE]: type,
      [SearchParams.KEYWORD]: undefined
    }),
    path: [rootPath, search].join('?'),
    ...props
  }
})
