import React from 'react'

export default function ChartSvg ({size = 20, ...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 489.8 489.8"
      xmlSpace="preserve"
      {...props}>
        <path
          fill="#2C2F33"
          d="M479.9 2.25H9.9c-5.5 0-9.9 4.4-9.9 9.9s4.4 9.9 9.9 9.9h18.3v287c0 14.5 11.8 26.3 26.3 26.3h382.6c14.5 0 26.3-11.8 26.3-26.3v-287h16.5c5.5 0 9.9-4.4 9.9-9.9s-4.4-9.9-9.9-9.9zm-36.3 306.8c0 3.6-2.9 6.5-6.5 6.5H54.5c-3.6 0-6.5-2.9-6.5-6.5v-287h395.6v287zM260.5 400.75v-25.7H295c5.5 0 9.9-4.4 9.9-9.9s-4.4-9.9-9.9-9.9h-90.2c-5.5 0-9.9 4.4-9.9 9.9s4.4 9.9 9.9 9.9h35.9v25.7l-69.9 69.9c-3.9 3.9-3.9 10.1 0 14 1.9 1.9 4.5 2.9 7 2.9s5.1-1 7-2.9l55.9-55.9v48.9c0 5.5 4.4 9.9 9.9 9.9s9.9-4.4 9.9-9.9v-48.9l55.6 55.6c1.9 1.9 4.5 2.9 7 2.9s5.1-1 7-2.9c3.9-3.9 3.9-10.1 0-14l-69.6-69.6z"
        />
        <path
          fill="#3C92CA"
          d="M282.3 147.65h75.6c5.5 0 9.9-4.4 9.9-9.9 0-20.9-7.6-41-21.4-56.6-16.2-18.4-39.6-28.9-64.1-28.9-5.5 0-9.9 4.4-9.9 9.9v75.6c0 5.5 4.4 9.9 9.9 9.9zm9.9-74.8c15.1 2.3 29.1 9.8 39.3 21.4 8.4 9.5 13.8 21.2 15.7 33.6h-55v-55z"
        />
        <path
          fill="#2C2F33"
          d="M239.7 285.25c58.5 0 106.2-47.6 106.2-106.2 0-5.5-4.4-9.9-9.9-9.9h-86.4v-86.3c0-5.5-4.4-9.9-9.9-9.9-58.5 0-106.2 47.6-106.2 106.2 0 58.5 47.6 106.1 106.2 106.1zm-10-192v85.8c0 5.5 4.4 9.9 9.9 9.9h85.8c-4.9 43-41.5 76.5-85.8 76.5-47.6 0-86.4-38.7-86.4-86.4.1-44.2 33.6-80.8 76.5-85.8z"
        />
    </svg>
  )
}
