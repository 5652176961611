import { HomeOutlined } from '@ant-design/icons'
import { Button, Result } from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'

const RequiredLogin = () => {
  const t = useTranslate()
  return (
    <Result
      className="m-auto"
      // status="403"
      // title="403"
      subTitle={t(
        'Sorry, the page you visited require login.'
      )}
      extra={
        <div className="flex items-center justify-center">
          <Link
            className="mr-4"
            to={{
              pathname: '/login',
              state: { isModal: true }
            }}>
            <Button>
              {t('Login now')}
            </Button>
          </Link>
          <Link to={'/'}>
            <Button
              icon={<HomeOutlined />}
              type="primary">
              {t('home')}
            </Button>
          </Link>
        </div>
      }
    />
  )
}

export default RequiredLogin
