import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../../../LoadingPage";

const PartnerViewLazy = Loadable({
  loader: () => import('./PartnerView'),
  loading: LoadingPage
})

const PartnerView = (props) => (
  <PartnerViewLazy {...props} />
)

export default PartnerView
