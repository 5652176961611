import { schema as sche } from 'normalizr'
export const createBaseItemSchema = ({
  type = 'baseItems',
  idAttribute = 'id',
  schema = {},
  processStrategy,
  ...rest
} = {}) =>
  new sche.Entity(type, schema, {
    idAttribute: idAttribute,
    processStrategy,
    ...rest
  })
export const baseItemSchema = createBaseItemSchema()
export const baseItemSchemaArray = [
  baseItemSchema
]
