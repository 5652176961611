import { Button } from "antd"
import Null from "components/NullComponent"
import { useFormikContext } from "formik"
import useTranslate from "modules/local/useTranslate"
import React from "react"
import { MdOutlineAttachFile } from "react-icons/md"

const OrderRequestFormFooter = ({
  onCancel = Null
}) => {

  const {
    isSubmitting = false,
    handleSubmit = Null,
  } = useFormikContext()

  const t = useTranslate()

  return (
    <div className="flex items-center justify-end gap-3">
      <Button
        disabled={isSubmitting}
        onClick={onCancel}
        className="button-rounded-md no-border">
        {t('cancel')}
      </Button>
      <Button
        type="primary"
        loading={isSubmitting}
        onClick={() => {
          handleSubmit()
        }}
        className="button-rounded-md no-border">
        {t('send requirement')}
      </Button>
    </div>
  )
}

const SpotAttachFile = ({
  className,
  children,
  ...props
}) => {

  const t = useTranslate()

  return (
    <div className={className}>
      <button className="flex text-sm items-center border border-primary py-1 px-3 rounded-full font-light text-primary hover:text-white hover:bg-primary">
        <MdOutlineAttachFile className="mr-1" />
        {t('attach file')}
      </button>
    </div>
  )
}

export {
  SpotAttachFile,
  OrderRequestFormFooter
}
