import React from 'react'
import Loadable from "react-loadable";
import LoadingPage from "../../../LoadingPage";

const PhotoLazy = Loadable({
  loader: () => import('./Photo'),
  loading: LoadingPage
})

const Contact = (props) => (
  <PhotoLazy {...props} />
)

export default Contact
