import {ROOT_URL} from 'envs/_current/config'
import base, {get, getLinkToDetailGenerator, getLinkToSettingGenerator, getLinkToShareGenerator, getType} from './base'
import {eventSchema} from "../schema/event";

const getId = get('idname')
export const getLinkToDetail = getLinkToDetailGenerator(
  {getId, getType}
)
export const getLinkToSetting = getLinkToSettingGenerator(
  {getId, getType}
)
export const getLinkToShare = getLinkToShareGenerator(
  {rootUrl: ROOT_URL, getType, getId}
)
export default {
  ...base,
  getLinkToDetail,
  getLinkToSetting,
  getLinkToShare,
  schema: eventSchema,
  getId
}
