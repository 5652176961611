import { getChannelsByProps_Api } from 'apis'
import { getId, getType } from 'apis/model/base'
import EmptyHolder from 'components/EmptyHolder'
import _ from 'lodash'
import CustomLazyPagination from 'modules/asyncCache/components/CustomLazyPagination'
import React, { useContext, useMemo } from 'react'
import { bindQueryParams } from 'views/AppLocals/functions/routerHelper'
import { renderChannelItem } from 'views/MainPage/functions/renderChannelItem'
import OrganizationContainerContext from '../OrganizationContainerContext'
import useTranslate from '../../../../modules/local/useTranslate'

const OrganizationChannelList = ({ Wrapper = 'div' }) => {
  const t = useTranslate()
  const { organization: item } = useContext(OrganizationContainerContext)

  const [id, type] = [getId(item), getType(item)]

  const apiInfo = getChannelsByProps_Api

  const lookupKey = useMemo(
    () => [apiInfo.path.replace(':id', id).replace(':type', type)].join('/'),
    [id, type, apiInfo]
  )

  if (_.isEmpty(item)) return null

  return (
    <Wrapper className="space-y-3">
      <div className="font-medium text-sm text-color-400 border-b uppercase">
        {t('list of channel')}
      </div>
      <CustomLazyPagination
        id={lookupKey}
        key={lookupKey}
        apiInfo={apiInfo}
        query={bindQueryParams([{ id }, { type }])}
        renderEmpty={() => <EmptyHolder />}
        renderItem={renderChannelItem}
      />
      <div className="h-10" />
    </Wrapper>
  )
}

export default OrganizationChannelList
