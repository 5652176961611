import { API_ROOT_URL } from 'envs/_current/config'
import { schema } from 'normalizr'
import { createListTransform } from './utils/createListTransform'
import { createTransform } from './utils/createTransform'

export const participantSchema = new schema.Entity(
  'participants',
  {},
  {
    idAttribute: 'id',
  }
)

export const participant_getVerifiedAttendees_Api = {
  backend: `Route::get('participants/{type}/{id}/get-verified-attendees', 'ParticipantController@getVerifiedAttendees');`,
  root: API_ROOT_URL,
  path: '/participants/:type/:id/get-verified-attendees',
  method: 'GET',
  paramsFields: ['keyword'],
  schema: participantSchema,
  transform: createListTransform(participantSchema),
}

export const participant_createParticipant_Api = {
  backend: `Route::post('participants/{type}/{id}/participant/create', 'ParticipantController@createParticipant');`,
  root: API_ROOT_URL,
  path: '/participants/:type/:id/participant/create',
  method: 'POST',
  schema: participantSchema,
  transform: createTransform(participantSchema, 'data'),
}

export const participant_getById_Api = {
  backend: `Route::get('participants/participant/{id}/get', 'ParticipantController@getById');`,
  root: API_ROOT_URL,
  path: '/participants/participant/:id/get',
  method: 'GET',
  schema: participantSchema,
  transform: createTransform(participantSchema, 'data'),
}

export const participant_getParticipantsByOwner_Api = {
  backend: `Route::get('participants/{type}/{id}/gets', 'ParticipantController@getParticipantsByOwner');`,
  root: API_ROOT_URL,
  path: '/participants/:type/:id/gets',
  method: 'GET',
  paramsFields: ['keyword', 'status', 'sort_by'],
  schema: participantSchema,
  transform: createListTransform(participantSchema),
}

export const participant_getParticipantsByAuth_Api = {
  backend: `Route::get('participants/{type}/get-by-auth', 'ParticipantController@getParticipantsByAuth');`,
  root: API_ROOT_URL,
  path: '/participants/:type/get-by-auth',
  method: 'GET',
  paramsFields: ['keyword', 'status', 'sort_by'],
  schema: participantSchema,
  transform: createListTransform(participantSchema),
}

export const participant_manageParticipantCreate_Api = {
  backend: `Route::post('participants/{type}/{id}/manage/add', 'ParticipantController@manageParticipantCreate');`,
  root: API_ROOT_URL,
  path: '/participants/:type/:id/manage/add',
  method: 'POST',
  schema: participantSchema,
  transform: createTransform(participantSchema, 'data'),
}

export const participant_manageParticipantEdit_Api = {
  backend: `Route::post('participants/participant/{id}/manage/edit', 'ParticipantController@manageParticipantEdit');`,
  root: API_ROOT_URL,
  path: '/participants/:type/:id/manage/edit',
  method: 'POST',
  schema: participantSchema,
  transform: createTransform(participantSchema, 'data'),
}

export const participant_manageParticipantDelete_Api = {
  backend: `Route::post('participants/participant/{id}/manage/delete', 'ParticipantController@manageParticipantDelete');`,
  root: API_ROOT_URL,
  path: '/participants/participant/:id/manage/delete',
  method: 'POST',
  schema: participantSchema,
  transform: createTransform(participantSchema, 'data'),
}

export const participant_manageParticipantAccept_Api = {
  backend: `Route::post('participants/participant/{id}/manage/accept', 'ParticipantController@manageParticipantAccept');`,
  root: API_ROOT_URL,
  path: '/participants/participant/:id/manage/accept',
  method: 'POST',
  schema: participantSchema,
  transform: createTransform(participantSchema, 'data'),
}

export const participant_manageParticipantReject_Api = {
  backend: `Route::post('participants/participant/{id}/manage/reject', 'ParticipantController@manageParticipantReject');`,
  root: API_ROOT_URL,
  path: '/participants/participant/:id/manage/reject',
  method: 'POST',
  schema: participantSchema,
  transform: createTransform(participantSchema, 'data'),
}

export const participant_cancelParticipant_Api = {
  backend: `Route::post('participants/participant/{id}/delete', 'ParticipantController@cancelParticipant');`,
  root: API_ROOT_URL,
  path: '/participants/participant/:id/delete',
  method: 'POST',
  schema: participantSchema,
  transform: createTransform(participantSchema, 'data'),
}
