import React from "react";

export const Wheat = ({size = 16,...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 511.52 511.52"
		>
			<path
				fill="#F3C432"
				d="M285.264 64.864C178.416 186.8 122.848 347.12 133.36 511.52c3.136-.208 6.256-.4 9.392-.608-10.352-161.888 44.368-319.76 149.584-439.84-2.352-2.064-4.72-4.128-7.072-6.208z"
			></path>
			<g fill="#FFD83B">
				<path d="M76.448 248.272c-24.624 74.08 12.288 141.936 65.232 146.56 7.728-63.952-15.024-127.936-65.232-146.56zM259.728 309.184c-17.264 50.624-64.768 90.528-118.048 85.648 8.688-67.712 68.304-104.32 118.048-85.648zM125.792 134.288c-37.12 68.672-12.464 141.392 38.832 155.232 18.624-61.104 7.344-128.144-38.832-155.232zM295.648 226.208c-25.808 46.864-79.424 77.472-131.024 63.328 20.208-64.64 85.264-90.4 131.024-63.328zM195.264 29.328c-49.008 61.488-37.424 137.36 10.624 160.016 29.04-56.848 30.032-125.2-10.624-160.016zM346.224 149.808c-33.92 41.904-92.016 62.544-140.32 39.536 31.232-60.048 100.064-74.304 140.32-39.536zM358.672 0c-13.552 59.104-57.52 107.552-114.448 125.392C237.984 64.992 296.928.144 358.672 0z"></path>
			</g>
			<g fill="#F3C432">
				<path d="M165.296 410.112c-8.256 4.816-15.744 11.456-22.256 18.864a612.012 612.012 0 00-1.504 47.92c4.224-23.36 15.472-42.272 29.776-54.544 21.648-18.816 45.024-21.104 70.048-17.168-25.552-8.512-50.08-10.336-76.064 4.928zM113.648 420.896c7.184 6.752 13.488 14.832 19.088 23.088a620.432 620.432 0 00-.288 48.816c-5.28-24.08-14.848-44.048-26.848-59.648-21.008-27.264-42.64-34.624-65.008-36.176 23.664-3.776 47.296-.528 73.056 23.92zM423.024 219.12c-69.712 79.552-105.968 184.16-99.12 291.44l6.128-.384c-6.752-105.632 28.96-208.656 97.6-286.992-1.52-1.36-3.072-2.704-4.608-4.064z"></path>
			</g>
			<g fill="#FFD83B">
				<path d="M286.784 338.8c-16.064 48.336 8.016 92.608 42.56 95.632 5.04-41.744-9.808-83.488-42.56-95.632z"></path>
				<path d="M406.368 378.528c-11.264 33.024-42.272 59.072-77.024 55.888 5.664-44.176 44.56-68.064 77.024-55.888zM318.96 264.416c-24.224 44.8-8.128 92.256 25.344 101.296 12.16-39.872 4.8-83.616-25.344-101.296zM429.792 324.4c-16.832 30.576-51.824 50.544-85.488 41.312 13.2-42.176 55.648-58.992 85.488-41.312zM364.304 195.936c-31.984 40.128-24.416 89.632 6.928 104.4 18.944-37.088 19.6-81.696-6.928-104.4zM462.8 274.544c-22.128 27.344-60.032 40.816-91.568 25.792 20.384-39.168 65.312-48.464 91.568-25.792zM470.928 176.8c-8.832 38.56-37.536 70.176-74.672 81.808-4.08-39.408 34.368-81.712 74.672-81.808z"></path>
			</g>
			<g fill="#F3C432">
				<path d="M344.752 444.4c-5.392 3.136-10.272 7.472-14.528 12.32a390.922 390.922 0 00-.976 31.264c2.768-15.36 10.096-27.584 19.424-35.6 13.888-12.08 28.992-13.824 45.712-11.2-16.24-5.424-32.336-6.96-49.632 3.216zM311.04 451.424c4.688 4.416 8.8 9.68 12.464 15.072a406.058 406.058 0 00-.192 31.84c-3.456-15.792-9.696-28.736-17.52-38.928-13.568-17.6-27.68-22.576-42.416-23.6 15.504-2.464 30.976-.24 47.664 15.616z"></path>
			</g>
			<g fill="#E8EFF3">
				<path d="M122.672 104.752c0-6.864-11.504-18.368-18.368-18.368 6.864 0 18.368-11.504 18.368-18.368 0 6.864 11.504 18.368 18.368 18.368-6.864 0-18.368 11.504-18.368 18.368zM331.36 76.592c0-10.816-18.096-28.912-28.912-28.912 10.816 0 28.912-18.096 28.912-28.912 0 10.816 18.096 28.912 28.912 28.912-10.816 0-28.912 18.096-28.912 28.912z"></path>
			</g>
		</svg>
	);
}

export default Wheat;
