import {
  CameraFilled,
  CheckOutlined,
  CrownOutlined,
  DeleteOutlined,
  DownOutlined,
  LoadingOutlined,
  MoreOutlined,
  UpOutlined
} from '@ant-design/icons'
import {Alert, Avatar, Button, Col, Dropdown, Menu, Modal, Popconfirm, Row, Space, Tag, Tooltip} from 'antd'
import {create_product_publish_Api} from 'apis'
import {baseModel, productModel} from 'apis/model'
import classNames from 'classnames'
import ImagesLightBox from 'components/ImagesLightBox/ImagesLightBox'
import {UseHook} from 'components/UseReducer'
import logFunc from 'helpers/logFunc'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useEffect, useState} from 'react'
import {useToggle} from 'react-use'
import formatCurrency from 'views/Billing/components/formatCurrency'
import ProductContainer, {ProductContext} from '../Seller/ProductContainer'
import {Link} from "react-router-dom";
import {dataURItoBlob, getBase64} from "../../../components/form/fields/ImageUpload";
import Translate from "../../../modules/local/Translate";
import {emptyArray} from "../../../helpers/emptyObjects";
import {
    BsCameraFill,
    BsCaretDownFill,
    BsCaretUpFill,
    BsCheck2,
    BsCheckCircle, BsDiamondFill, BsThreeDotsVertical,
    BsTrash3,
    BsXCircle
} from "react-icons/bs";
import {GoDotFill, GoTrash} from "react-icons/go";
import Pure from "../../../components/Pure";
import productImageHolder from "../productImageHolder";
import ImageLoader from "../../../components/ImageLoader";
import {renderIf} from "../../Shared";
import Toggle from "../../../components/Toggle";
import {IconMiniButton, ItemInformation, MiniButton} from "../../AppLocals/Organization/OrganizationEntity";
import {FaCrown} from "react-icons/fa6";

export function Product({
                          item: product,
                          handleAction,
                          deactive
                        }) {
  const t = useTranslate()
  const overlay = (
    <Menu onClick={handleAction}>
        <Menu.Item key={'preview'}>
            {t('view product page')}
        </Menu.Item>
        <Menu.Item key={'edit'}>
            {t('edit product')}
        </Menu.Item>
        <Menu.Item key={'report'}>
            {t('create report')}
        </Menu.Item>
        {deactive ? (
            <Menu.Item key="active">
                {t('active')}
            </Menu.Item>
        ) : (
            <Menu.Item key="deactive">
                {t('deactive')}
            </Menu.Item>
        )}
        <Menu.Item key="delete">
            {t('delete')}
        </Menu.Item>
    </Menu>
  )
  const showrequires = !_.isEmpty(product.requireStrings)

  return (
    <ProductContainer item={product}>
        <div className="flex border border-color-50 hover:shadow-items rounded-lg relative">
            <ImageLoader
                style={{ width: 150, height: 150 }}
                className="w-full h-full object-cover rounded-l-lg rounded-r-0"
                src={_.get(product, 'photos.0.path') || productImageHolder}
            />
            <div className="flex flex-col flex-1 p-3">
                <Detail />
                {product.is_ready && (
                    <Alert
                        type="success"
                        description={
                            <div className="flex justify-between space-x-3 2">
                                <div>
                                    •{' '}
                                    {t(
                                        'your product has been declared complete, you can put it on the store'
                                    )}
                                </div>
                                {'' + product.status !==
                                '1' && (
                                    <UseHook
                                        hook={
                                            useDispatchAsyncActionWithNotify
                                        }>
                                        {([
                                              {
                                                  success,
                                                  isLoading
                                              },
                                              dispatch
                                          ]) => (
                                            <Popconfirm
                                                title={`${t('publish')} ?`}
                                                onConfirm={() => {
                                                    dispatch(
                                                        createAsyncAction(
                                                            {
                                                                apiInfo: create_product_publish_Api(),
                                                                query: {
                                                                    ':id':
                                                                    product.id
                                                                }
                                                            }
                                                        )
                                                    )
                                                }}
                                                okText="OK"
                                                cancelText="Cancel">
                                                <Button
                                                    loading={
                                                        isLoading
                                                    }
                                                    type="primary">
                                                    {t('publish')}
                                                </Button>
                                            </Popconfirm>
                                        )}
                                    </UseHook>
                                )}
                            </div>
                        }
                    />
                )}
                {showrequires && (
                    <div className="flex flex-col text-xs">
                        <span className="font-bold text-red-600 italic">
                            {`* ${t('missing')} : `}
                        </span>
                        <div className="ml-2 flex items-center flex-1 gap-3 leading-tight">
                            {Object.values(product.requireStrings).map((text, i) => (
                                <span key={i} className="flex items-center gap-1 text-red-500">
                                    <GoDotFill size={12}/>
                                    <span className="font-medium">{text}</span>
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <Action
                overlay={overlay}
                handleAction={handleAction}
                product={product}
            />
        </div>
    </ProductContainer>
  )
}

function Detail() {
  const t = useTranslate()
  const product =
    useContext(ProductContext) ||
    {};
  return (
      <div className="w-full flex flex-col gap-2">
          <div className="flex flex-col gap-1">
              <Link
                  to={productModel.getLinkToDetail(product)}
                  className="font-bold text-lg text-color-000 no-underline">
                  {productModel.getTitle(product)}
              </Link>
              <div className="flex items-center gap-2 flex-1">
                  <div className="flex flex-col gap-1 flex-1">
                      {!!!product.hidden_price && ( Number(product.price) > 0 ? (
                              <div className="space-x-1 text-sm">
                                  <span className="font-bold text-primary-600">
                                      {formatCurrency(product.price)}
                                  </span>
                                  <span className="text-color-400">
                                      {`/ ${_.get(product, 'unit.name', t('unit'))}`}
                                  </span>
                              </div>
                          ) : (
                              <span className="font-medium text-xs text-blue-500 italic">
                                  {`* ${t('no price tag')}`}
                              </span>
                          )
                      )}
                      {renderIf(
                          !!product.wholesale,
                          <span className="font-medium text-xs text-color-300 italic">
                              {t('contact provider for wholesale price')}
                          </span>
                      )}
                  </div>
                  <Pure input={[product]}>
                      {[
                          (Number(product.status) === 1 && Number(product.active) === 1) && (
                              <Tag
                                  key={product.id + "_1"}
                                  color="success"
                                  icon={<BsCheckCircle size={12} />}
                                  className="flex flex-center gap-2 font-medium text-xs italic">
                                  {t('published')}
                              </Tag>
                          ),
                          (Number(product.active) === 0) && (
                              <Tag
                                  color="error"
                                  icon={<BsXCircle size={12} />}
                                  className="flex flex-center gap-2 font-medium text-xs italic">
                                  {t('inactive')}
                              </Tag>
                          )
                      ].map((children, i) => !!children && (
                          <div key={i}>{children}</div>
                      ))}
                  </Pure>
              </div>
          </div>
          <div className="text-xs text-color-400 italic max-lines-2">
              {productModel.get(product, 'description')}
          </div>
      </div>
  )
}

function Action({ overlay, handleAction, product }) {

    const t = useTranslate()


    if (!overlay) return null

    return (
        <div
            style={{ top: 5, right: 5 }}
            className="flex flex-center gap-1 absolute">
            {
                !!product.is_primary ? (
                    <IconMiniButton
                        title={t('set as no primary')}
                        onClick={() => handleAction({key: 'is_not_primary'})}
                        icon={ <FaCrown size={11} className="text-orange-500"/>}
                    />
                ) : (
                    <IconMiniButton
                        title={t('set as primary')}
                        onClick={() => handleAction({key: 'is_primary'})}
                        icon={ <FaCrown size={11}/>}
                    />
                )
            }
            <IconMiniButton
                title={t('go down')}
                onClick={() => handleAction({key: 'sort_down'})}
                icon={ <BsCaretDownFill size={13}/> }
            />
            <IconMiniButton
                title={t('go up')}
                onClick={() => handleAction({key: 'sort_up'})}
                icon={ <BsCaretUpFill size={13}/> }
            />

            <Toggle>
                {(isToggle, toggle) => (
                    <>
                        <MiniButton
                            onClick={() => toggle()}
                            labelClassName="italic"
                            label={t('quick view')}
                            className="border border-color-50 hover:border-primary-600"
                        />
                        {isToggle && (
                            <Modal
                                width={700}
                                className="custom-modal"
                                visible={isToggle}
                                maskClosable={true}
                                title={
                                    <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                                        {t('product information')}
                                    </div>
                                }
                                okButtonProps={{ hidden: true }}
                                cancelText={t('close')}
                                cancelButtonProps={{ className: "button-rounded-md no-border" }}
                                onCancel={toggle}>
                                <ModalContent item={product} />
                            </Modal>
                        )}
                    </>
                )}
            </Toggle>

            <Dropdown overlay={overlay} placement="bottomRight">
                <div
                    style={{ width: 23, height: 23, cursor: 'pointer', display: 'flex' }}
                    className="rounded border border-color-50 background-100 hover:background-200 text-color-300 hover:text-color-100 flex-center">
                    <BsThreeDotsVertical size={13}/>
                </div>
            </Dropdown>
        </div>
    )
}

const ModalContent = ({item}) => {
    const t = useTranslate()
    const {
        title,
        description,
        sku,
        trademark,
        product_line,
        photos = [],
        categories = [],
        specifications = [],
        attributes = [],
    } = item ?? {}

    const ItemProductInfo = ({ label, value }) => (
        renderIf(
            value,
            <div className="flex flex-col text-sm italic">
                <span className="text-color-400">
                    {t(label)}
                </span>
                <span className="ml-2 font-semibold text-color-100">
                    {value}
                </span>
            </div>
        )
    )

    return (
        <div className="flex flex-col gap-4">
            <ImagesAndFiles
                readyOnly
                className="h-full"
                files={(photos).map(item => {
                    const url = _.get(item, 'path')
                    if (url) return url
                    return item
                })}
            />
            <div className="flex flex-col text-sm italic">
                <span className="text-color-400">
                    {t('product name')}
                </span>
                <span className="ml-2 font-bold text-lg text-color-000 truncate">
                    {title}
                </span>
            </div>
            <div className="flex flex-col text-sm">
                <span className="text-color-400 italic">
                    {t('short description')}
                </span>
                <span className="ml-2 text-color-300">
                    {description}
                </span>
            </div>
            <div className="grid grid-cols-2 gap-3">
                <ItemProductInfo
                    label="sku"
                    value={sku}
                />
                <ItemProductInfo
                    label="trademark"
                    value={trademark}
                />
                <ItemProductInfo
                    label="product line"
                    value={product_line}
                />
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-color-500 italic">
                    {t('category')}
                </span>
                <div className="flex items-center gap-8">
                    {categories.map((e, i) => (
                        <div key={i} className="flex items-center gap-1 text-primary">
                            <BsDiamondFill size={6} />
                            <span className="font-semibold text-sm italic">
                                {_.get(e, 'name')}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="flex flex-col">
                <span className="text-sm text-color-500 italic">
                    {t('Specification/Additional Details')}
                </span>
                <div className="ml-2 mt-2 grid grid-cols-2 gap-2">
                    {renderIf(
                        specifications.length > 0,
                        <div className="flex flex-col gap-1">
                            {specifications.map((item, i) => (
                                <div key={i} className="flex items-center gap-2 text-xs italic">
                                <span className="text-color-400">
                                    {`${_.get(item, 'attribute_name')} : `}
                                </span>
                                    <span className="font-medium text-color-200">
                                    {_.get(item, 'attribute_value')}
                                </span>
                                </div>
                            ))}
                        </div>
                    )}
                    {renderIf(
                        attributes.length > 0,
                        <div className="flex flex-col gap-1">
                            {attributes.map((item, i) => (
                                <div key={i} className="flex items-center gap-2 text-xs italic">
                                <span className="text-color-400">
                                    {`${_.get(item, 'attribute_name')} : `}
                                </span>
                                    <span className="font-medium text-color-200">
                                    {_.get(item, 'attribute_value')}
                                </span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const ImagePreview = ({
    className,
    file,
    holder = (
        <Translate>
            {t => (
                <div className="flex flex-col items-center gap-1">
                    <BsCameraFill
                        size={15}
                        className="text-color-500"
                    />
                    <span className="font-medium text-2xs text-color-300 leading-none">
                        {t('add')}
                    </span>
                </div>
            )}
        </Translate>
    )
}) => {
  const [url, setUrl] = useState(file)
  useEffect(() => {
    if (file && file.read) {
      file.read(
        logFunc(data =>
          setUrl(_.get(data, 'urls[0]'))
        )
      )
    } else {
      if (typeof file === 'string')
        setUrl(file)
    }
  }, [file])

  if (!file) {
    return holder
  }
  if (file && !url) {
    return (
      <LoadingOutlined spin="true" />
    )
  }
  return (
    <img
      src={url}
      className={classNames("w-full h-full object-cover", className)}
    />
  )
}

function ImagesAndFiles({
    files = [],
    className,
    onChange,
    onAdd,
    onItemClick,
    readyOnly
}) {
  const [id, setId] = useState(
    '' + Date.now()
  )
  const [mainId] = useState(
    'main-' + id
  )
  const t = useTranslate()
  const handleAdd = async (files) => {
    if (onAdd) {
      // console.log({files})
      let loadedFiles = [];
      for (let i = 0; i < files.length; i++) {
        console.log({file: files[i]})
        const file = await getBase64(files[i], 1024, 1024);
        const image = dataURItoBlob(file);
        loadedFiles.push(image)
      }
      console.log({loadedFiles})
      onAdd(loadedFiles)
    }
  }
  const [isToggle, toggle] = useToggle()

  return (
    <div style={{ height: 300 }} className={classNames('flex flex-col', className)}>
        <div className="flex items-center gap-1">
            <div className="flex flex-col">
                {new Array(6)
                    .fill(undefined)
                    .map((v, i) => {
                        const file = files[i]
                        return (
                            <div key={i} onClick={readyOnly ? () => {toggle()} : null} className="w-full withHover relative">
                                <label
                                    key={i}
                                    onClick={readyOnly ? () => {toggle()} : null}
                                    htmlFor={!file ? id : undefined}
                                    style={{ width: 50, height: 50 }}
                                    className="w-full flex flex-col flex-center background-100 border border-white hover:border-color-base rounded cursor-pointer">
                                    <ImagePreview file={file} className="rounded"/>
                                </label>
                                {file && !readyOnly && (
                                    <div
                                        style={{ left: 50, top: '50%', transform: 'translate(0, -50%)' , whiteSpace: 'nowrap' }}
                                        className="visible flex text-xs shadow-items-xs border background rounded-md absolute z-10">
                                        <div
                                            onClick={() => onItemClick(i, 'up')}
                                            data-effect="ripple"
                                            className="px-3 flex flex-center hover:background-200 cursor-pointer">
                                            <BsCaretUpFill className="text-color-400" />
                                        </div>
                                        <div className="border-l" />
                                        <div
                                            onClick={() => onItemClick(i, 'down')}
                                            data-effect="ripple"
                                            className="px-3 flex flex-center hover:background-200 cursor-pointer">
                                            <BsCaretDownFill className="text-color-400" />
                                        </div>
                                        <div className="border-l"/>
                                        <div
                                            onClick={() => onItemClick(i, 'main')}
                                            data-effect="ripple"
                                            className="p-2 flex flex-center hover:background-200 cursor-pointer">
                                            <BsCheck2 className="mr-2 text-primary-600" />
                                            <span className="font-medium">{t('set main')}</span>
                                        </div>
                                        <div className="border-l"/>
                                        <div
                                            onClick={() => onItemClick(i, 'delete')}
                                            data-effect="ripple"
                                            className="p-2 flex flex-center hover:background-200 cursor-pointer">
                                            <GoTrash className="mr-2 text-red-500" />
                                            <span className="font-medium">{t('delete')}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )
                    })}
            </div>
            <div style={{ height: 300 }} className="flex-1">
                <label
                    onClick={ readyOnly ? () => {toggle()} : null}
                    htmlFor={mainId}
                    className="h-full flex flex-center flex-col background-100 border border-white hover:border-color-base rounded-md cursor-pointer">
                    <ImagePreview
                        className="rounded-md"
                        holder={
                            <div className="flex flex-col items-center gap-1">
                                <BsCameraFill
                                    size={50}
                                    style={{ color: '#e9e9e9' }}
                                />
                                <span className="text-2xs text-color-400 italic leading-tight">
                                    {'( 512 x 512 )'}
                                </span>
                                <span className="font-bold text-xs text-color-300 leading-none">
                                    {t('add')}
                                </span>
                            </div>
                        }
                        file={files[0]}
                    />
                </label>
            </div>
        </div>
        {readyOnly ? null : (
            <>
                <input
                    hidden
                    multiple
                    onChange={e => {
                        console.log(
                            'images',
                            e.target.files
                        )
                        handleAdd(e.target.files)
                    }}
                    id={id}
                    name={id}
                    type="file"
                    accept="image/*"
                />
                <input
                    hidden
                    id={mainId}
                    name={mainId}
                    onChange={e => {
                        console.log(
                            'images',
                            e.target.files
                        )
                        handleAdd(e.target.files)
                    }}
                    type="file"
                    accept="image/*"
                />
            </>
        )}
        {isToggle && (
            <ImagesLightBox
                images={files}
                onCloseRequest={toggle}
            />
        )}
    </div>
  )
}

export {
  Detail,
  Action,
  ImagesAndFiles
}

