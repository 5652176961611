import { schema } from 'normalizr'
export const commentSchema = new schema.Entity(
  'comments',
  {},
  {
    idAttribute: 'id'
  }
)
export const commentArray = [
  commentSchema
]
