import {Button, Col, Radio, Row} from 'antd'
import {auth_getMyChannels_Api} from 'apis'
import EmptyHolder from 'components/EmptyHolder'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import getLinkToDetail from 'helpers/getLinkToDetail'
import getTitle from 'helpers/getTitle'
import CustomLazyPaginationWrapper from 'modules/asyncCache/components/CustomLazyPaginationWrapper'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useContext, useState} from 'react'
import {GiIronCross} from 'react-icons/gi'
import {RiAddFill} from 'react-icons/ri'
import {Link} from 'react-router-dom'
import {staticPaths} from 'routes/staticPaths'
import {fullGrid, oneThirdGrid, renderIf, twoThirdGrid} from '../../Shared'
import renderPagingLoading from '../functions/renderPagingLoading'
import ChannelsByAuth from './components/ChannelsByAuth'
import './Radio.css'

const renderItem = (item, index) => {
  const {owner, description, total_articles, total_followers} = item ?? {}
  const categories = Array.from(item?.categories ?? [])
  return (
    <Translate>
      {(t) => (
        <div
          key={index}
          className="p-3 space-y-2 cursor-pointer border border-color-50 hover:shadow-items rounded-lg">
          <div className="flex flex-col">
            <Link
              to={getLinkToDetail(item)}
              className="font-bold text-color-000 flex-1 no-underline leading-tight">
              {getTitle(item)}
            </Link>
            {owner && (
              <Link
                rel="nofollow"
                to={getLinkToDetail(owner)}
                className="truncate no-underline text-xs text-color-400 italic">
                {getTitle(owner)}
              </Link>
            )}
          </div>
          {renderIf(
            categories.length,
            <div className="flex flex-wrap gap-x-3 gap-y-1">
              {categories.map((category, index) => (
                <div
                  key={index}
                  className="flex items-center text-primary">
                  <GiIronCross
                    size={8}
                    className="mr-1"
                  />
                  <span className="italic font-medium text-xs">
                    {category?.name}
                  </span>
                </div>
              ))}
            </div>
          )}
          {renderIf(
            description,
            <div className="text-sm text-color-100 italic">{description}</div>
          )}
          <div className="flex gap-6">
            {renderIf(
              total_followers,
              <div className="flex items-baseline text-sm gap-1 leading-tight">
                <span className="font-medium text-color-000">
                  {total_followers}
                </span>
                <span className="font-light text-xs italic text-color-300">
                  {t('members')}
                </span>
              </div>
            )}
            {renderIf(
              total_articles,
              <div className="flex items-baseline text-sm gap-1 leading-tight">
                <span className="font-medium text-color-000">
                  {total_articles}
                </span>
                <span className="font-light text-xs italic text-color-300">
                  {t('articles')}
                </span>
              </div>
            )}
          </div>
        </div>
      )}
    </Translate>
  )
}

const MyChannels = () => {
  const t = useTranslate()
  const history = useHistory()
  const [type, setType] = useState('admin')

  const {isSm} = useContext(LayoutContext)

  const apiInfo = auth_getMyChannels_Api

  const lookupKey = [apiInfo.path, `type=${type}`].join('/')

  return (
    <div className="flex flex-col gap-3 h-full">
      <div className="w-full flex items-center">
        <div className="flex-1"/>
        <div className="flex items-center gap-3">
          <Button
            style={{height: 30, padding: '2px 10px', border: 0}}
            onClick={() => {
              history.push(staticPaths.createChannel)
            }}
            type="primary"
            className="button-rounded-md flex flex-center gap-2 font-semibold text-sm">
            <RiAddFill/>
            {t('create channel')}
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <div className="col-span-2 flex flex-col gap-3">
          <div className="flex items-center w-full RoundedBorderRadioGroup">
            <Radio.Group
                defaultValue={type}
                onChange={(e) => setType(e.target.value)}
                optionType="button"
                buttonStyle="solid"
                className="custom-radio-my-activity">
              <Radio.Button value="owner">
                {t('owner')}
              </Radio.Button>
              <Radio.Button value="admin">
                {t('admin')}
              </Radio.Button>
            </Radio.Group>
          </div>
          <CustomLazyPaginationWrapper
              id={lookupKey}
              key={lookupKey}
              alwaysVisible={true}
              apiInfo={apiInfo}
              values={{filter: type}}
              renderEmpty={() => <EmptyHolder/>}
              renderLoading={renderPagingLoading}
              renderItem={renderItem}
          />
        </div>
        <div className="col-span-1">
          <ChannelsByAuth
              alwaysVisible={true}
              title={'suggested channels'}
          />
        </div>
      </div>
    </div>
  )
}

export default MyChannels
