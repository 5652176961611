import {Col, Divider, Row, Tag} from 'antd'
import {baseFieldTypes} from 'apis/base/baseFieldTypes'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import withTranslate from 'modules/local/withTranslate'
import moment from 'moment'
import React, {useMemo} from 'react'
import {useToggle} from 'react-use'
import {compose, mapProps, withProps} from 'recompose'
import {fromObject, fullGrid, renderOwnChild} from 'views/Shared'
import AddressInline from './AddressInLine'

const renderTitle = (title) =>
  _.isEmpty(title) ? null : (
    <Translate>
      {(t) => (
        <p className="text-xs uppercase text-color-400 mb-1">{t(title)}</p>
      )}
    </Translate>
  )

const SingleWrapper = ({children, ...props}) => (
  <Col
    span={fullGrid}
    className="p-col-0"
    {...props}>
    {children}
  </Col>
)

const InlineWrapper = ({children, title, ...props}) => (
  <Col
    flex={1}
    className="p-col-0"
    {...props}>
    {renderTitle(title)}
    {children}
  </Col>
)

const DefaultComponent = ({
                            title,
                            value,
                            children,
                            Wrapper = SingleWrapper,
                            ...props
                          }) => {
  return (
    <Wrapper {...props}>
      {renderTitle(title)}
      <p className="text-sm font-medium text-color-100">{children || value}</p>
    </Wrapper>
  )
}

const DefaultWrapper = ({children, ...props}) => {
  return (
    <Row
      className="mb-2"
      gutter={[10, 0]}
      style={{marginLeft: 0, marginRight: 0}}
      {...props}>
      {children}
    </Row>
  )
}

const DefaultStepTitle = ({value, prefix}) => {
  const t = useTranslate()
  return (
    <div style={{paddingTop: '0.15rem'}} className="w-full">
      <span className="font-bold text-lg text-color-000 uppercase italic">{value}</span>
    </div>
  )
}

const formSchema = [
  {
    group: 'company',
    children: [
      {
        title: 'company',
        name: 'company',
        component: withProps({prefix: 'company'})(DefaultStepTitle),
      },
    ],
  },
  {
    group: 'position',
    children: [
      {
        title: 'position',
        name: 'position',
        component: DefaultComponent,
      },
      {
        title: 'on working',
        name: 'on_working',
        component: compose(
          withTranslate,
          mapProps(({title, value, translate}) => ({
            title,
            children: (
              <Tag
                className="m-0"
                style={{
                  border: 0,
                  fontSize: 10,
                  fontWeight: '700',
                  color: value === 1 ? 'var(--color-primary)' : 'var(--text-color-300)',
                  backgroundColor: value === 1 ? 'var(--color-primary-100)' : 'var(--background-200)',
                }}>
                {translate(!!value ? 'yes' : 'no')}
              </Tag>
            ),
          }))
        )(DefaultComponent),
      },
    ],
  },
  {
    group: 'working_address',
    children: [
      {
        title: 'address',
        name: 'working_address',
        component: compose(
          withTranslate,
          mapProps(({title, value, values = {}}) => {
            const {} = values
            return {
              title,
              values: {
                address: value,
                provinceId: _.get(values, 'working_province_id'),
                countryId: _.get(values, 'working_country_id'),
              },
              format: '{address}, {province}, {country}',
              Wrapper: withProps({title})(DefaultComponent),
            }
          })
        )(AddressInline),
      },
    ],
    // children: [
    //   {
    //     name: 'working_address',
    //     component: DefaultComponent,
    //   },
    //   {
    //     name: 'working_province_id',
    //     component: compose(
    //       withProvinces,
    //       mapProps(({ value, getProvince = () => [,] }) => {
    //         const [, currentProvince] = getProvince(value)
    //         return {
    //           value: currentProvince,
    //         }
    //       })
    //     )(DefaultComponent),
    //   },
    //   {
    //     name: 'working_country_id',
    //     component: compose(
    //       withCountries,
    //       mapProps(({ value, getCountry = () => [,] }) => {
    //         const [, currentCountry] = getCountry(value)
    //         return {
    //           value: currentCountry,
    //         }
    //       })
    //     )(DefaultComponent),
    //   },
    // ],
  },
  {
    group: 'privacy',
    children: [
      {
        title: 'privacy',
        name: 'privacy',
        component: compose(
          withTranslate,
          mapProps(({title, value, translate}) => {
            const current = baseFieldTypes.privacy.find((e) => e.value == value)
            return {
              title,
              value: translate(!!current ? current.label : 'undefined'),
            }
          })
        )(DefaultComponent),
      },
    ],
  },
  {
    group: 'description',
    children: [
      {
        title: 'description',
        name: 'description',
        component: DefaultComponent,
      },
    ],
  },
]

const DateHeaderItem = ({from, to, Wrapper = renderOwnChild}) => {
  const t = useTranslate()
  const dateFormat = 'DD/MM/YYYY'
  const content = useMemo(() => {
    const renderDate = (value, prefix) => {
      const date = moment(value)
      if (!_.isEmpty(value) && date.isValid) {
        return (
          <span>
            {String(prefix)}
            <span className="font-semibold text-sm text-color-100 mx-1">
              {date.format(dateFormat)}
            </span>
          </span>
        )
      }
      return (
        <span>
          {String(prefix)}
          <span className="font-semibold text-sm text-color-100 mx-1">{t('today')}</span>
        </span>
      )
    }
    return (
      <Wrapper>
        <div className="mb-2 text-xs italic">
          {renderDate(from, t('from date'))}
          {renderDate(to, t('to date'))}
        </div>
      </Wrapper>
    )
  }, [from, to])

  return content
}

const ProfileQuickView = ({
                            item = {},
                            compact = false,
                            includedFields,
                            DateHeader = DateHeaderItem,
                            excludedFields = ['privacy'],
                          }) => {
  const t = useTranslate()
  const [isToggle, toggle] = useToggle()

  const [fromDate, toDate] = [_.get(item, 'start_at'), _.get(item, 'end_at')]

  const content = useMemo(() => {
    const includedItems = formSchema.filter(({group}) => {
      if (includedFields) return includedFields.includes(group)
      return !excludedFields.includes(group)
    })
    const limit = 2
    const size = includedItems.length
    const take = isToggle ? size : limit
    const data = !!compact ? _.take(includedItems, take) : includedItems
    return data.map(
      (
        {children = [], relatives = [], Wrapper = DefaultWrapper, ...dd},
        index
      ) => {
        if (children.length > 1) {
          return (
            <Col
              key={index}
              className="p-col-0">
              <DateHeader
                from={fromDate}
                to={toDate}
              />
              {renderTitle(_.get(dd, 'title'))}
              <div className="flex mb-2">
                {children.map(
                  ({name, title, component: Component}, index) => (
                    <InlineWrapper key={index}>
                      <Component
                        key={name}
                        name={name}
                        title={title}
                        values={item}
                        value={_.get(item, name)}
                      />
                    </InlineWrapper>
                  )
                )}
              </div>
            </Col>
          )
        }
        const {name, title, component: Single} = _.first(children)
        return (
          <Wrapper key={index}>
            <Single
              key={name}
              name={name}
              title={title}
              values={item}
              value={_.get(item, name)}
              relatives={fromObject(
                item,
                relatives.map((e) => ({name: e}))
              )}
            />
          </Wrapper>
        )
      }
    )
  }, [compact, isToggle, formSchema, includedFields, excludedFields])

  if (_.isEmpty(item)) return null

  return (
    <Col className="p-col-0 w-full">
      <div className="mb-2">{content}</div>
      {compact && (
        <div className="w-full">
          <Divider style={{margin: 0}}>
            <span
              onClick={toggle}
              className="text-xs text-color-400 hover:text-primary hover:underline font-light italic cursor-pointer">
              {t(isToggle ? 'see less' : 'see more')}
            </span>
          </Divider>
        </div>
      )}
    </Col>
  )
}

export default ProfileQuickView
