import { event_edit_Api } from 'apis/event'
import { getId, getType } from 'apis/model/base'
import _ from 'lodash'
import { useCallback, useContext } from 'react'
import { withProps } from 'recompose'
import OwnerPicker from 'views/AppLocals/custom/OwnerPicker'
import createEditableForm from 'views/AppLocals/factory/createEditableForm'
import { bindQueryParam } from 'views/AppLocals/functions/routerHelper'
import EventContext from '../EventContext'

const formFields = [{ name: 'owner' }]

const formSchema = [
  {
    title: 'owner',
    children: [
      {
        name: 'owner',
        component: withProps(({ name, form, value, onChange }) => {
          const { values } = form || {}
          const { id, type } = values || {}
          return {
            id,
            type,
            name,
            onChange,
            defaultValue: value,
          }
        })(OwnerPicker),
      },
    ],
  },
]

const EventSettingOwner = () => {
  const { event } = useContext(EventContext)

  const [id, type] = [getId(event), getType(event)]

  const onPreSubmit = ({ id, type, owner, ...values }) => {
    const [owner_id, owner_type] = [getId(owner), getType(owner)]
    return {
      ...values,
      owner_id,
      owner_type,
    }
  }

  const initialized = useCallback(
    ({ owner, ...values }) => ({
      ...values,
      id,
      type,
      owner,
    }),
    [event]
  )

  if (_.isEmpty(event)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: event,
    query: bindQueryParam({
      id,
    }),
    onPreSubmit,
    initialized,
    apiInfo: event_edit_Api,
    validationSchema: undefined,
  })
}

export default EventSettingOwner
