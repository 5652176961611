import React, {
  lazy,
  Suspense
} from 'react'

const ProductPageLazy = lazy(() =>
  import('./ProductPage')
)

const ProductPage = props => (
  <Suspense fallback={null}>
    <ProductPageLazy {...props} />
  </Suspense>
)

export default ProductPage
