import { AutoComplete, Input, Spin, Tooltip } from 'antd'
import { getType } from 'apis/model/base'
import classNames from 'classnames'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import queryString from 'query-string'
import React, { useContext, useMemo, useState } from 'react'
import { RiCloseFill, RiSearch2Line } from 'react-icons/ri'
import { SearchTypes } from 'views/Search/SearchProvider'
import { SearchSuggestion } from 'views/Search/useSearchSuggestion'
import { getResponseItems, Null, renderIf, renderOwnChild } from 'views/Shared'
import preventDefaultEvent from '../helpers/preventDefaultEvent'
import { useHistory } from '../modules/navigation/useRouter'
import { staticPaths } from '../routes/staticPaths'
import EmptyHolder from './EmptyHolder'
import { createValue } from './form/utils'
import './KeywordInput.scss'
import { LayoutContext } from './layouts/Default/LayoutContext'
import {renderIfElse} from "../views/Shared";

const style = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
  // border: 'solid 1px var(--border-color-100)',
  boxShadow: 'none',
  outline: 'none',
}

const renderSuggestionOption = (fieldNames) => (option, index) => {
  const title = _.get(option, fieldNames?.label)
  const value = _.get(option, fieldNames?.value)
  const sub_title = option?.sub_title ?? getType(option)
  return {
    ...(option ?? {}),
    value,
    key: index,
    label: (
      <Translate>
        {(t) => (
          <div className="flex flex-col py-1">
            <div className="font-semibold text-sm text-color-000 leading-snug">{title}</div>
            {sub_title && (
              <div className="text-2xs text-primary-600 italic leading-snug">
                {t(sub_title)}
              </div>
            )}
          </div>
        )}
      </Translate>
    ),
  }
}

const SearchContent = ({
  name,
  value,
  prefix,
  suffix,
  placeholder,
  options = [],
  fieldNames = {},
  tooltip = false,
  onSelect = Null,
  onSuggest = Null,
}) => {
  const t = useTranslate()
  const history = useHistory()
  const { isSm } = useContext(LayoutContext)
  const { type } = queryString.parse(history.location.search) ?? {}
  let searchParams = new URLSearchParams(history.location.search)

  const [isLoading, setLoading] = useState(false)

  const Wrapper = !!tooltip ? Tooltip : renderOwnChild

  const notFoundContent = useMemo(() => {
    if (!!isLoading) {
      return (
        <Spin
          spinning={true}
          className="flex items-center justify-center h-8"
        />
      )
    }
    return renderIf(value, <EmptyHolder icon={null} />)
  }, [isLoading])

  const mixed_options = useMemo(() => {
    const text = String(value ?? '')
    const withDefaultOptions =
      ([SearchTypes.ALL].includes(type) || !type) && text.length
    const typed_result =
      type && text.length
        ? [
            {
              type,
              id: type,
              sub_title: type,
            },
          ]
        : []
    const _result = withDefaultOptions
      ? [
          {
            id: 'as_default',
            sub_title: 'default',
            type: SearchTypes.ALL,
          },
          {
            id: 'as_user',
            sub_title: 'search as a user',
            type: SearchTypes.USER,
          },
          {
            id: 'as_channel',
            sub_title: 'search as a channel',
            type: SearchTypes.CHANNEL,
          },
          {
            id: 'as_organization',
            sub_title: 'search as a organization',
            type: SearchTypes.ORGANIZATION,
          },
        ]
      : typed_result
    return [
      ..._result.map(({ id, ...rest }) => ({
        ...rest,
        search_text: value,
        ...(fieldNames?.value ? { [fieldNames?.value]: id } : {}),
        ...(fieldNames?.label ? { [fieldNames?.label]: value } : {}),
      })),
      ...(options ?? []),
    ].map(renderSuggestionOption(fieldNames))
  }, [type, value, options, fieldNames])

  return (
    <Wrapper
      trigger={['focus']}
      title={t('press enter to search')}
      placement="bottomLeft">
      <AutoComplete
        name={name}
        value={value}
        options={mixed_options}
        // autoFocus={true}
        onInputKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSelect(value)
          }
        }}
        onSelect={(value, option) => {
          const { search_text } = option ?? {}
          onSelect(search_text ?? value, option)
        }}
        onChange={(value, option) => {
          const { search_text } = option ?? {}
          setLoading(true)
          onSuggest(search_text ?? value, () => {
            setLoading(false)
          })
        }}
        notFoundContent={notFoundContent}
        className="w-full KeywordAutoComplete">
        <Input
          onClick={(e) => {
            preventDefaultEvent(e)
            if (isSm) {
              if (type) {
                searchParams.set('type', type)
              }
              history.push({
                ...(history?.location ?? {}),
                pathname: staticPaths.search.pathname,
                search: '?' + searchParams.toString(),
              })
            }
          }}
          prefixCls="KeywordInput"
          autoComplete="off"
          style={{
            height: isSm ? 40 : 50,
            ...style
          }}
          prefix={prefix}
          suffix={suffix}
          placeholder={t(placeholder)}
          className="w-full"
        />
      </AutoComplete>
    </Wrapper>
  )
}

const DefaultSearcContainer = ({ children }) => {
  return children([], Null, false)
}

export const KeywordInput = ({
  name,
  value,
  title,
  onClear,
  tooltip,
  className,
  prefix = null,
  remote = false,
  onChange = Null,
  placeholder = '',
  dependencies = [],
  onSearchClick = Null,
  autoCompleteConfigs = {},
  style,
}) => {
  const { isSm } = useContext(LayoutContext)
  const translate = useTranslate()
  const isEmpty = !(value && value.length > 0)
  const suffix = useMemo(() => {
    return (
        renderIfElse(
            !isEmpty,
            <RiCloseFill
                className="KeywordInputButton text-red-300 hover:text-red-600 cursor-pointer"
                onClick={() => {
                  onClear && onClear()
                  onChange(createValue(name, ''))
                }}
            />,
            <RiSearch2Line
                className="KeywordInputButton text-color-400 hover:text-color-100 cursor-pointer"
                onClick={(event) => {
                  onSearchClick(value)
                }}
            />
        )
    )
  }, [isEmpty, name, value, onClear, onChange, onSearchClick])

  const {
    actionParams = {},
    fieldNames = {
      label: 'label',
      value: 'value',
      options: 'options',
    },
  } = autoCompleteConfigs

  const result = useMemo(() => {
    return (Wrapper) => (
      <Wrapper
        key={dependencies}
        transform={(response) => getResponseItems(response)}
        {...actionParams}>
        {(result, onSearch, isPending = false) => {
          return (
            <SearchContent
              {...{
                name,
                value,
                prefix,
                suffix,
                tooltip,
                isPending,
                fieldNames,
                placeholder,
              }}
              options={result}
              onSuggest={(value, callback) => {
                onChange(createValue(name, value))
                onSearch(value, callback)
              }}
              onSelect={(value, option) => {
                onSearchClick(value, option)
              }}
            />
          )
        }}
      </Wrapper>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, dependencies])

  return (
    <div className={classNames('flex flex-col items-center', className)}>
      {title && (
        <h1 className="font-bold text-2xl pb-3 lg:px-10 text-color-000 text-center">
          {_.isString(title) ? translate(title) : title}
        </h1>
      )}
      <div style={isSm ? { width: '100%' } : { width: '200%' }}>
        {result(remote ? SearchSuggestion : DefaultSearcContainer)}
      </div>
    </div>
  )
}
