export default (obj) => {
  const formData = new FormData()
  for (let key in obj) {
    switch (true) {
      case Array.isArray(obj[key]):
        formData.append(key, obj[key])
        break

      default:
        formData.append(key, obj[key])
        break
    }
  }
  return formData
}
