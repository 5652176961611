import {getId} from 'apis/model/base'
import classNames from 'classnames'
import {FollowButton} from 'components/Feed/FollowButton'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import numeral from 'numeral'
import React from 'react'
import {RiRssLine} from 'react-icons/ri'
import {Link} from 'react-router-dom'
import getTitle from "../../../helpers/getTitle";
import {getType} from "../../../apis/model/base";
import {BsFillPersonFill, BsMegaphoneFill} from "react-icons/bs";
import {FaWarehouse} from "react-icons/fa6";
import {Avatar} from "antd";

export const ChannelItem = ({item, style, className}) => {
  const t = useTranslate()

  let icon
  switch (getType(item?.owner)) {
    case "organization":
      icon = <FaWarehouse size={10} className="text-color-500"/>
      break
    case 'user':
      icon = <BsFillPersonFill size={10} className="text-color-500"/>
      break
    default:
      icon = <BsMegaphoneFill size={10} className="text-color-500"/>
      break
  }

  if (!item) return null

  return (
    <div
      style={style}
      className={classNames('flex items-center gap-2', className)}>
      <div className="flex flex-col flex-1 gap-1 truncate">
        <Link
          to={getLinkToDetail(item)}
          className="font-bold text-sm text-color-000 no-underline leading-tight truncate">
          {item.title}
        </Link>
        <div className="ml-1 flex items-center gap-2 text-xs text-color-300 italic">
          <Avatar
              size={20}
              icon={icon}
              className="flex flex-center background-200"
          />
          <span className="flex-1 truncate">
            {getTitle(item?.owner)}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex flex-1 items-center gap-2">
            <div className="text-xs flex items-center gap-2 justify-center">
              <RiRssLine
                  size={10}
                  style={{color: item.follow_status ? 'var(--text-primary)' : 'var(--text-color-500)'}}
              />
              {item.total_followers && item.total_followers > 0 && (
                <span className="font-bold text-xs text-color-000">
                  {numeral(item.total_followers).format()}
                </span>
              )}
            </div>
            {!!_.get(item, 'selected_by_editor') && (
                <span
                    style={{ padding: '3px 6px' }}
                    className="flex flex-center font-bold text-2xs bg-blue-200 text-blue-700 italic rounded-full">
                  {t("editor's choice")}
                </span>
            )}
          </div>
          <FollowButton item={item} />
        </div>
      </div>
    </div>
  )
}

export const renderChannelItem = (item) => (
  <ChannelItem
    item={item}
    key={getId(item)}
    className="p-2 border border-color-50 rounded-lg cursor-pointer hover:shadow-items"
  />
)
