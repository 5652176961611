import React from 'react'
import Count from './Count'
import {BiLike, BiSolidLike} from "react-icons/bi";

export const Like = React.memo(
  ({
     vote_status,
     total_votes = 0,
     handleVote
   }) => {
    return (
      <Count
        active={!!vote_status}
        defaultNumber={total_votes}
        color="text-primary"
        size={14}
        onClick={handleVote}
        {...(!!vote_status
          ? {
            component: BiSolidLike
          }
          : {
            component: BiLike
          })}
      />
    )
  }
)

