import React, { lazy, Suspense } from 'react'

const CreateNewArticleLazy = lazy(() =>
  import('./CreateNewArticle')
)

const CreateNewArticle = props => (
  <Suspense fallback={null}>
    <CreateNewArticleLazy {...props} />
  </Suspense>
)

export default CreateNewArticle