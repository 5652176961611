import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, { useContext } from 'react'
import ChartContext from '../ChartContext'

export const Copyright = () => {
  const t = useTranslate()
  const { item } = useContext(
    ChartContext
  )

  if (!!!item) {
    return (
      <div className="flex bottom-0 right-0">
        <div className="text-sm text-color-500 italic">
          <span className="font-bold text-color-100 opacity-0">...</span>
        </div>
      </div>
    )
  }

  return (
      <div className="flex flex-col items-end text-xs text-color-500 italic">
          {!_.isEmpty(_.get(item, 'dataset.copyright')) && (
              <div className="space-x-2">
                  {`${t('source')} : `}
                  <span className="font-bold text-color-100">
                      {_.get(item, 'dataset.copyright')}
                  </span>
              </div>
          )}
          {!_.isEmpty(_.get(item, 'dataset.designed_by')) && (
              <div className="space-x-2">
                  {`${t('designed and provided by')} `}
                  <span className="font-bold text-primary-600">
                      {_.get(item, 'dataset.designed_by')}
                  </span>
              </div>
          )}
      </div>
  )
}
