import _ from 'lodash'
import * as moment from 'moment'

export function convertToMoment(x) {
  let m = moment(x)
  if (!m.isValid()) m = moment(x, 'DD/MM/YYYY')
  if (!m.isValid()) m = moment(x, 'DD-MM-YYYY')
  if (!m.isValid()) m = moment(x, 'DD/MM/YY')
  if (!m.isValid()) m = moment(x, 'MM/DD/YY')

  return m
}

export const timeFromSeconds = (seconds = 0) => {
  const number = _.defaultTo(seconds, 0)
  return [Math.floor(number / 3600), Math.floor(number / 60) % 60, number % 60]
}

export const momentFromSeconds = (value = 0) => {
  const [hours = 0, minutes = 0, seconds = 0] = timeFromSeconds(value)
  const time = new Date().setHours(hours, minutes, seconds)
  return moment(time)
}

export const minutesFromMoment = (value) => {
  if (moment.isMoment(value)) {
    const hours = (value?.get('hours') || 0) * 3600
    const minutes = (value?.get('minutes') || 0) * 60
    const seconds = value?.get('seconds') || 0
    const total_seconds = hours + minutes + seconds
    return Math.floor(total_seconds / 60)
  } else {
    return 0
  }
}

export const secondsFromMoment = (value) => {
  if (moment.isMoment(value)) {
    const hours = (value?.get('hours') || 0) * 3600
    const minutes = (value?.get('minutes') || 0) * 60
    const seconds = value?.get('seconds') || 0
    return hours + minutes + seconds
  } else {
    return 0
  }
}

export function parseMoment(date) {
  return (date && moment(date).isValid()) ? moment(date) : undefined
}

export function isValidDate(value, format) {
  try {
    const date = value && moment(new Date(value)).format(format)
    const {isValid} = date ?? {}
    return _.isFunction(isValid) && isValid() ? date : false
  } catch (error) {
    return false
  }
}

export function getDateString(date) {
  if (!!date && moment.isMoment(date)) {
    return moment(date).local().format()
  }
  return date === null ? null : undefined
}

export function hasExpired(date) {
  if (!date) return true
  return moment(date).isBefore(moment.now())
}
