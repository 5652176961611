import moment from 'moment'
import React from 'react'

const momentCalendar = timestamp =>
  moment(timestamp).calendar()
const Timestamp = React.memo(
  ({
    timestamp,
    children,
    className
  }) => {
    if (!!timestamp && moment(timestamp).utc().format("HH:mm") === "00:00") {
      return (
        <time
          className={` ${className}`}
          dateTime={timestamp}>
          {moment(timestamp).format('DD/MM/YYYY')}
        </time>
      )
    }
    return (
      <time
        className={` ${className}`}
        dateTime={timestamp}>
        {momentCalendar(
          timestamp || children
        )}
      </time>
    )
  }
)
export default Timestamp
