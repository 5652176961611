import { Button } from "antd"
import Null from "components/NullComponent"
import Translate from "modules/local/Translate"
import React from "react"
import { renderOwnChild } from 'views/Shared'

const renderAction = (
  title,
  withProps,
  onClick = Null
) => {
  return (
    <Button
      ghost
      onClick={onClick}
      size="small"
      style={{ height: 24 }}
      className="button-rounded-md font-medium text-xs leading-tight"
      {...withProps}>
      {title}
    </Button>
  )
}

const renderRequestActions = (
  actions = [],
  Wrapper = renderOwnChild,
) => {
  return (
    <Translate>
      {t => {
        return (
          <Wrapper>
            {actions.map(({
              title,
              withProps,
              onClick = Null,
            }, index) => {
              return (
                <React.Fragment key={index}>
                  {renderAction(
                    t(title),
                    withProps,
                    onClick
                  )}
                </React.Fragment>
              )
            })}
          </Wrapper>
        )
      }}
    </Translate>
  )
}

export default renderRequestActions