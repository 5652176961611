import _ from 'lodash'
import React, {useMemo} from 'react'
import {useCountries, useProvinces,} from 'views/Account/components/ProfileOptionSelect'
import {renderOwnChild} from 'views/Shared'

const AddressInline = ({
                         Wrapper = renderOwnChild,
                         values = {},
                         fieldNames = {
                           address: 'address',
                           provinceId: 'provinceId',
                           countryId: 'countryId',
                         },
                         format = '{address}, {province}, {country}',
                       }) => {
  const {getCountry = () => []} = useCountries()
  const {getProvince = () => []} = useProvinces()

  const address = _.get(values, fieldNames.address)
  const countryId = _.get(values, fieldNames.countryId)
  const provinceId = _.get(values, fieldNames.provinceId)

  const [, currentCountry] = getCountry(countryId)
  const [, currentProvince] = getProvince(provinceId)

  const child = useMemo(
    () =>
      String(format)
        .replace('{address}', address || '')
        .replace('{province}', currentProvince || '')
        .replace('{country}', currentCountry || '')
        .trim(),
    [format, values, address, currentProvince, currentCountry]
  )

  return <Wrapper>{child}</Wrapper>
}

export default AddressInline
