import { Button, Modal } from 'antd'
import getLinkToDetail from 'helpers/getLinkToDetail'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React from 'react'
import { RiAddFill } from 'react-icons/ri'
import { useToggle } from 'react-use'
import { staticPaths } from 'routes/staticPaths'
import { Null } from 'views/Shared'
import EmptyHolder from '../../../components/EmptyHolder'
import renderChannelModalItem from '../functions/renderChannelModalItem'

const ChannelCreatePost = ({ linkTo, children, onClose = Null, ...props }) => {
  const t = useTranslate()

  const history = useHistory()

  const [isToggle, toggle] = useToggle(false)

  const onCancel = () => {
    toggle(false)
    onClose()
  }

  const goTo = (item) => {
    if (linkTo) {
      toggle(false)
      linkTo(item)
    } else {
      const detailLink = getLinkToDetail(item)
      history.push(detailLink)
    }
  }

  return (
    <React.Fragment>
      {children(toggle)}
      <Modal
        width={620}
        okButtonProps={{
          hidden: true,
        }}
        closable={true}
        closeIcon={null}
        visible={isToggle}
        maskClosable={true}
        onCancel={onCancel}
        destroyOnClose={true}
        className="custom-modal"
        cancelText={t('close')}
        cancelButtonProps={{
          className: 'button-rounded-lg no-border',
        }}
        title={
          <div className="text-center font-bold text-color-000 uppercase">
            {t('Choose the channel for posting')}
          </div>
        }>
        <div className="space-y-3">
          <ChannelPickerContent
            onCancel={onCancel}
            onItemClick={(item) => {
              Modal.destroyAll()
              goTo(item)
            }}
            {...props}
          />
        </div>
      </Modal>
    </React.Fragment>
  )
}

const ChannelPickerContent = ({
  onCancel = Null,
  onItemClick = (item) => null,
  ...props
}) => {
  const t = useTranslate()
  const history = useHistory()
  const { refreshToken } = history?.location?.state ?? {}
  const refreshKey = [refreshToken, props.refreshKey]
  return (
    <LazyPagination
      key={refreshKey}
      refreshKey={refreshKey}
      renderEmpty={() => <EmptyHolder />}
      {...props}
      renderWrap={(child) => (
        <div className="flex flex-col gap-3">
          <div className="flex items-center justify-between gap-2">
            <span className="flex-1 font-medium text-color-300 tracking-wide uppercase">
              {t('list of channel')}
            </span>
            <Button
              type="primary"
              icon={<RiAddFill size={11}/>}
              onClick={() => {
                history.push({
                  pathname: staticPaths.createChannel.pathname,
                  state: {
                    isModal: true,
                    willRedirect: false,
                  },
                })
              }}
              style={{ padding: '4px 12px', height: 'unset', fontSize: '12px' }}
              className="button-rounded-md no-border flex flex-center gap-2">
              {t('create channel')}
            </Button>
          </div>
          {child}
        </div>
      )}
      renderItem={(item, index) =>
        renderChannelModalItem(item, {
          t,
          index,
          onItemClick,
        })
      }
    />
  )
}

export default ChannelCreatePost
