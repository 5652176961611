import _ from 'lodash'
import OrganizationChannelList from './holders/OrganizationChannelList'
import OrganizationContactInfo from './holders/OrganizationContactInfo'
import OrganizationEventList from './holders/OrganizationEventList'
import OrganizationHostList from './holders/OrganizationHostList'
import OrganizationInfo from './holders/OrganizationInfo'
import OrganizationNews from './holders/OrganizationNews'
import OrganizationDataset from "./holders/OrganizationDataset";
import OrganizationProducts from "./holders/OrganizationProducts";
import {validateServiceType} from "../../Organization/functions/validateServiceType";

export const organizationRouteEntities = [
  {
    path: 'overview',
    component: OrganizationInfo,
    breadcrumbName: (t) => t('overview'),
  },
  {
    path: 'hosts',
    component: OrganizationHostList,
    breadcrumbName: (t) => t('related units'),
  },
  {
    path: 'news',
    component: OrganizationNews,
    breadcrumbName: (t) => t('news'),
  },
  {
    path: 'channels',
    component: OrganizationChannelList,
    breadcrumbName: (t) => t('channels'),
    isInvisible: ({item}) => !validateServiceType(item?.service_type, "channel"),
  },
  {
    path: 'events',
    component: OrganizationEventList,
    breadcrumbName: (t) => t('events'),
  },
  {
    path: 'products',
    component: OrganizationProducts,
    breadcrumbName: (t) => t('products'),
    isInvisible: ({item}) => !validateServiceType(item?.service_type, "product"),
  },
  {
    path: 'data',
    component: OrganizationDataset,
    breadcrumbName: (t) => t('data'),
    isInvisible: ({item}) => !validateServiceType(item?.service_type, "dataset"),
  },
  {
    path: 'contacts',
    component: OrganizationContactInfo,
    breadcrumbName: (t) => t('contact'),
    isInvisible: ({item}) => _.isEmpty(item?.contacts),
  },
]
