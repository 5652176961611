import { createLazyComponent } from 'helpers/createLazyComponent'
import React from 'react'
import { BsCheckCircle, BsClock, BsXCircle } from 'react-icons/bs'

const EventLazy = createLazyComponent(() => import('./Event'))

const Event = (props) => <EventLazy {...props} />

export const EventActionTypes = Object.freeze({
  JOIN: 'join',
  LEAVE: 'leave',
  INVITE: 'share',
  SUBSCRIBE: 'subscribe',
  UNSUBSCRIBE: 'unsubscribe',
  PARTICIPANT_CREATE: 'participant-create',
  PARTICIPANT_UPDATE: 'participant-update',
  PARTICIPANT_DELETE: 'participant-delete',
  PARTICIPANT_REJECT: 'participant-reject',
  PARTICIPANT_APPROVE: 'approve-participant',
  SHOW_SUBSCRIBE_MODAL: 'show-subscribe-modal',
  SHOW_PARTICIPANT_CREATE_MODAL: 'show-participant-create-modal',
})

export const participant_status_options = {
  pending: {
    value: -1,
    label: 'pending',
    icon: <BsClock size={10} />,
    className: 'border border-blue-400 text-blue-500',
  },
  approved: {
    value: 1,
    label: 'approved',
    icon: <BsCheckCircle size={10} />,
    className: 'border border-green-500 text-green-500',
  },
  rejected: {
    value: -2,
    label: 'rejected',
    icon: <BsXCircle size={10} />,
    className: 'border border-red-500 text-red-500',
  },
}

export default Event
