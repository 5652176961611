import {
  nest,
  withProps,
} from 'recompose'
import { DelayRender } from 'views/Discovery/DelayRender'
import WorkspaceHostsContent from './WorkspaceHostsContent'

export default nest(
  withProps({ lazy: true })(
    DelayRender
  ),
  WorkspaceHostsContent
)
