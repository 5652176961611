import {SelectEntityItem} from 'components/SelectEntityItem'
import React, {useCallback} from 'react'
import useObservedList from '../handlers/useObservedList'
import {Null, renderOwnChild, renderSelf} from '../typedefs'

export const ObserverContext = React.createContext({post: []})

export const ObserverProvider = ({children, ...props}) => {
  const {add, clear, remove, update, visibleItems} = useObservedList({
    ...props,
  })

  const renderNewPosts = useCallback(
    ({
       item,
       schema,
       renderItem = Null,
       transform = renderSelf,
       defaultItem = visibleItems,
     }) => {
      const value = item || visibleItems
      return (
        <SelectEntityItem
          schema={schema}
          item={transform(value)}
          defaultItem={transform(defaultItem || value)}>
          {renderItem}
        </SelectEntityItem>
      )
    },
    [visibleItems]
  )

  return (
    <ObserverContext.Provider
      value={{
        addPost: add,
        renderNewPosts,
        clearPosts: clear,
        removePost: remove,
        updatePost: update,
        posts: visibleItems,
      }}>
      {children}
    </ObserverContext.Provider>
  )
}

const ObservedList = ({
  schema,
  children,
  isDeleted,
  renderItem,
  Wrapper = renderOwnChild,
}) => {
  const {
    add,
    visibleItems,
    component = null,
  } = useObservedList({
    schema,
    isDeleted,
    renderItem,
  })

  return (
    <Wrapper>
      {component}
      {children(add, component, visibleItems)}
    </Wrapper>
  )
}

export default ObservedList
