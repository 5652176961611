export const roleCodes = {
  admin: 'admin',
  guest: 'guest',
  user: 'user',
}

export const organizationPermissionCodes = {
  organizationManageMedal: 'organization-manage-medal',
  organizationManageEvent: 'organization-manage-event',
  organizationManageChannel: 'organization-manage-channel',
  organizationManageDataset: 'organization-manage-dataset',
  organizationManageProduct: 'organization-manage-product',
  organizationManageArticle: 'organization-manage-article',
  organizationManageMember: 'organization-manage-member',
  organizationManageInfo: 'organization-manage-info',
  organizationManageDelete: 'organization-manage-delete',
  organizationManageRole: 'organization-manage-role',
  organizationManagePermission: 'organization-manage-permission',
  organizationManageStructure: 'organization-manage-structure',
  organizationManagePeerStructure: 'organization-manage-peer-structure',
}

export const permissionCodes = {
  canPost: 'can_post',
  canInvite: 'canInvite',
  canJoin: 'canJoin',
  canSubscribe: 'canSubscribe',
  canBookmark: 'canBookmark',
  canPostArticle: 'canPostArticle',
  canCopy_link: 'canCopy_link',
  canDelete: 'canDelete',
  canEdit: 'canEdit',
  canEessage: 'canEessage',
  canRemove: 'canRemove',
  canShare: 'canShare',
  canTo: 'canTo',
  canView: 'canView',
  canVote: 'canVote',
}
