import { API_ROOT_URL } from 'envs/_current/config'
import { createAsyncAction } from 'modules/asyncCache'
import { baseFieldTypes } from './base/baseFieldTypes'
const fields = ['content']
const fieldTypes = {
  content: baseFieldTypes.string
}
// Route::post('url-link/get-meta-tags', 'TagController@getMetaTags');
export const url_getMetaTags_Api = {
  backend: `Route::post('url-link/get-meta-tags', 'TagController@getMetaTags')`,
  root: API_ROOT_URL,
  path: '/url-link/get-meta-tags',
  method: 'POST',
  fields,
  fieldTypes
}
export const create_url_getMetaTags_Api_action = () => ({
  prefixStr = 'url_getMetaTags_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr +
      '@url_getMetaTags_Api',
    query,
    values,
    apiInfo: url_getMetaTags_Api,
    maxAge
  })
