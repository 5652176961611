import _ from 'lodash'
import React, {useMemo} from 'react'
import {withProps} from 'recompose'
import {Null} from 'views/Shared'
import {NodeInlineTypes} from '../types'

const LeafNode = ({children, Component = Null}) => (
  <Component>{children}</Component>
)

export const CodeNode = withProps({Component: 'code'})(LeafNode)

export const BoldNode = withProps({Component: 'strong'})(LeafNode)

export const ItalicNode = withProps({Component: 'em'})(LeafNode)

export const UnderlineNode = withProps({Component: 'u'})(LeafNode)

export const TextNode = (props) => {
  const {attributes, leaf} = props
  const isEmpty = leaf?.text === ''

  let children = props.children

  const [code, bold, italic, underlined] = useMemo(
    () => [
      _.get(leaf, NodeInlineTypes.CODE),
      _.get(leaf, NodeInlineTypes.BOLD),
      _.get(leaf, NodeInlineTypes.ITALIC),
      _.get(leaf, NodeInlineTypes.UNDERLINED),
    ],
    [leaf]
  )

  if (bold) {
    children = <BoldNode>{children}</BoldNode>
  }
  if (italic) {
    children = <ItalicNode>{children}</ItalicNode>
  }
  if (underlined) {
    children = <UnderlineNode>{children}</UnderlineNode>
  }
  if (code) {
    children = <CodeNode>{children}</CodeNode>
  }
  return (
    <span
      style={{
        ...(attributes?.style ?? {}),
        ...(isEmpty ? {paddingLeft: '0.1px'} : {}),
      }}
      {...(attributes ?? {})}>
      {children}
    </span>
  )
}
