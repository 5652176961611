import React from "react";

export const Forex = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<path
				fill="#eaf6ff"
				d="M115.955 403.772a7.727 7.727 0 01-5.463-13.191L390.583 110.49a7.727 7.727 0 1110.927 10.928L121.418 401.509a7.705 7.705 0 01-5.463 2.263z"
			></path>
			<path
				fill="#eaf6ff"
				d="M396.045 403.772a7.704 7.704 0 01-5.463-2.263l-280.091-280.09a7.727 7.727 0 1110.927-10.928l280.091 280.091a7.727 7.727 0 01-5.464 13.19z"
			></path>
			<ellipse
				cx="256"
				cy="256"
				fill="#ffe179"
				rx="115.023"
				ry="113.614"
			></ellipse>
			<path
				fill="#fbd268"
				d="M256 142.386c-5.241 0-10.398.353-15.453 1.023 56.217 7.458 99.57 55.02 99.57 112.591s-43.353 105.133-99.57 112.591A117.55 117.55 0 00256 369.614c63.526 0 115.023-50.866 115.023-113.614S319.526 142.386 256 142.386z"
			></path>
			<path
				fill="#fff"
				d="M269.368 248.273h-5.642v-43.971h5.643c6.797 0 12.327 6.531 12.327 14.558a7.726 7.726 0 1015.452 0c0-16.548-12.462-30.011-27.78-30.011h-5.643v-9.145a7.726 7.726 0 10-15.452 0v9.145h-5.643c-15.318 0-27.78 13.463-27.78 30.011v14.856c0 16.547 12.462 30.01 27.781 30.01h5.642v43.972h-5.643c-6.797 0-12.327-6.531-12.327-14.558a7.726 7.726 0 10-15.452 0c0 16.548 12.462 30.011 27.78 30.011h5.643v9.145a7.726 7.726 0 1015.452 0v-9.145h5.643c15.318 0 27.78-13.463 27.78-30.011v-14.856c0-16.549-12.462-30.011-27.781-30.011zm-26.737.001c-6.798 0-12.328-6.53-12.328-14.557V218.86c0-8.027 5.53-14.558 12.327-14.558h5.643v43.972zm39.066 44.866c0 8.027-5.53 14.558-12.327 14.558h-5.643v-43.973h5.642c6.798 0 12.328 6.531 12.328 14.558z"
			></path>
			<circle cx="428.04" cy="428.04" r="83.96" fill="#ffe179"></circle>
			<path
				fill="#fbd268"
				d="M428.04 344.08a84.33 84.33 0 00-15.453 1.444c38.985 7.257 68.507 41.428 68.507 82.516s-29.522 75.26-68.507 82.516A84.323 84.323 0 00428.04 512c46.37 0 83.96-37.591 83.96-83.96 0-46.37-37.59-83.96-83.96-83.96z"
			></path>
			<path
				fill="#fff"
				d="M451.719 455.838a30.785 30.785 0 01-13.76 3.207c-8.446 0-16.11-3.398-21.707-8.894h21.707a7.726 7.726 0 100-15.452h-30.277c-.472-2.146-.728-4.372-.728-6.658s.256-4.512.728-6.658h30.277a7.726 7.726 0 100-15.452h-21.707c5.597-5.496 13.261-8.894 21.707-8.894 4.829 0 9.455 1.08 13.748 3.212a7.727 7.727 0 006.871-13.841c-6.448-3.201-13.386-4.824-20.62-4.824-17.618 0-32.976 9.858-40.85 24.347h-14.715a7.726 7.726 0 100 15.452h9.592c-.314 2.176-.484 4.396-.484 6.658s.171 4.482.484 6.658h-9.592a7.726 7.726 0 100 15.452h14.715c7.874 14.489 23.233 24.347 40.85 24.347 7.222 0 14.156-1.618 20.609-4.809a7.726 7.726 0 10-6.848-13.851z"
			></path>
			<g>
				<circle cx="83.96" cy="428.04" r="83.96" fill="#ffe179"></circle>
				<path
					fill="#fbd268"
					d="M83.96 344.08a84.33 84.33 0 00-15.453 1.444c38.985 7.257 68.507 41.428 68.507 82.516s-29.523 75.26-68.507 82.516A84.323 84.323 0 0083.96 512c46.37 0 83.96-37.591 83.96-83.96 0-46.37-37.591-83.96-83.96-83.96z"
				></path>
				<path
					fill="#fff"
					d="M118.261 379.9a7.726 7.726 0 00-10.806 1.616l-23.495 31.76-23.495-31.76a7.726 7.726 0 10-12.422 9.191l21.856 29.543h-9.795a7.726 7.726 0 100 15.452h16.13v11.317h-16.13a7.726 7.726 0 100 15.452h16.13v17.798a7.726 7.726 0 1015.452 0v-17.798h16.13a7.726 7.726 0 100-15.452h-16.13v-11.317h16.13a7.726 7.726 0 100-15.452h-9.795l21.856-29.543a7.726 7.726 0 00-1.616-10.807z"
				></path>
			</g>
			<g>
				<circle cx="428.04" cy="83.96" r="83.96" fill="#ffe179"></circle>
				<path
					fill="#fbd268"
					d="M428.04 0a84.33 84.33 0 00-15.453 1.444c38.985 7.257 68.507 41.428 68.507 82.516s-29.522 75.26-68.507 82.516a84.323 84.323 0 0015.453 1.444c46.37 0 83.96-37.591 83.96-83.96C512 37.59 474.41 0 428.04 0z"
				></path>
				<path
					fill="#fff"
					d="M452.872 120.272h-35.599a66.351 66.351 0 005.917-19.599h17.943a7.726 7.726 0 100-15.452h-17.6a67.501 67.501 0 00-2.413-12.598c-1.74-5.951-.607-12.208 3.111-17.169 3.717-4.961 9.405-7.806 15.605-7.806h9.795a7.726 7.726 0 100-15.452h-9.795c-11.112 0-21.307 5.1-27.971 13.992-6.663 8.892-8.696 20.109-5.576 30.774a52.038 52.038 0 011.699 8.26h-10.961a7.726 7.726 0 100 15.452h10.501a51.361 51.361 0 01-8.565 20.358 9.29 9.29 0 00-.657 9.662 9.29 9.29 0 008.276 5.031h46.29a7.726 7.726 0 007.726-7.726 7.725 7.725 0 00-7.726-7.727z"
				></path>
			</g>
			<g>
				<circle cx="83.96" cy="83.96" r="83.96" fill="#ffe179"></circle>
				<path
					fill="#fbd268"
					d="M83.96 0a84.33 84.33 0 00-15.453 1.444c38.985 7.257 68.507 41.428 68.507 82.516s-29.523 75.26-68.507 82.516a84.323 84.323 0 0015.453 1.444c46.37 0 83.96-37.591 83.96-83.96C167.92 37.59 130.329 0 83.96 0z"
				></path>
				<path
					fill="#fff"
					d="M109.956 56.439h-7.295a35.913 35.913 0 00-5.665-12.907h12.961a7.726 7.726 0 100-15.452H57.964a7.726 7.726 0 100 15.452h9.453c8.645 0 16.053 5.35 19.118 12.907H57.963a7.726 7.726 0 100 15.452h28.572c-3.066 7.557-10.474 12.907-19.118 12.907h-9.453a7.727 7.727 0 00-5.588 13.063l37.813 39.59a7.728 7.728 0 0011.175-10.674L75.216 99.402c13.674-3.023 24.455-13.827 27.451-27.511h7.289a7.726 7.726 0 007.726-7.726 7.725 7.725 0 00-7.726-7.726z"
				></path>
			</g>
		</svg>
	);
}

export default Forex;
