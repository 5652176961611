import { Input, Select } from 'antd'
import countryCodes from 'conts/countryCodes'
import React from 'react'
import { CustomSelect } from './CustomSelect'

const { TextArea } = Input
const { Option } = Select
export const defaultInputProps = {
  email: {
    title: 'email',
    component: Input,
    withProps: {
      type: 'email',
      placeholder:
        'please input your email!'
    }
  },
  phone: {
    title: 'phone',
    component: Input,
    withProps: {
      type: 'phone',
      placeholder:
        'please input your phone number!'
    }
  },
  fullname: {
    title: 'fullname',
    component: Input,
    withProps: {
      type: 'text',
      placeholder: 'what your name?'
    }
  },
  textArea: {
    component: TextArea,
    withProps: {
      rows: 5
    }
  },
  country: {
    component: CustomSelect,
    withProps: {
      showSearch: true,
      placeholder:
        'select your country',
      optionFilterProp: 'children',
      style: { width: '100%' },
      onFocus: (...args) =>
        console.log('onFocus', args),
      onBlur: (...args) =>
        console.log('onBlur', args),
      onSearch: (...args) =>
        console.log('onSearch', args),
      filterOption: (input, option) =>
        option.props.children
          .toLowerCase()
          .indexOf(
            input.toLowerCase()
          ) >= 0,
      children: countryCodes.map(
        ({ name, code }) => {
          return (
            <Option
              key={code}
              value={code}>
              {name}
            </Option>
          )
        }
      )
    }
  },
  prefixPhoneNumber: {
    component: CustomSelect,
    withProps: {
      showSearch: true,
      placeholder: '+123',
      optionFilterProp: 'children',
      style: { width: '100%' },
      onFocus: (...args) =>
        console.log('onFocus', args),
      onBlur: (...args) =>
        console.log('onBlur', args),
      onSearch: (...args) =>
        console.log('onSearch', args),
      filterOption: (input, option) =>
        option.props.children
          .toLowerCase()
          .indexOf(
            input.toLowerCase()
          ) >= 0,
      children: countryCodes.map(
        ({ dial_code, code }) => {
          return (
            <Option
              key={code}
              value={code}>
              {dial_code}
            </Option>
          )
        }
      )
    }
  }
}
