export const uSNameIds = {
  "Afghanistan": "AF", "AFGHAN": "AF",
  "Aland": "AX",
  "Albania": "AL", "ALBANIA": "AL",
  "Algeria": "DZ", "ALGERIA": "DZ",
  "American Samoa": "AS", "AM SAMOA": "AS",
  "Andorra": "AD",
  "Angola": "AO", "ANGOLA": "AO",
  "Anguilla": "AI",
  "Antarctica": "AQ",
  "Antigua and Barbuda": "AG",
  "Argentina": "AR", "ARGENT": "AR",
  "Armenia": "AM", "ARMENIA": "AM",
  "Aruba": "AW",
  "Ascension": "AC",
  "Australia": "AU", "AUSTRAL": "AU",
  "Austria": "AT", "AUSTRIA": "AT",
  "Azerbaijan": "AZ", "AZERBJN": "AZ",
  "Bahamas, The": "BS", "BAHAMAS": "BS",
  "Bahrain": "BH", "BAHRAIN": "BH",
  "Bangladesh": "BD", "BANGLADH": "BD",
  "Barbados": "BB", "BARBADO": "BB",
  "Belarus": "BY", "BELARUS": "BY",
  "Belgium": "BE", "BELGIUM": "BE",
  "Belize": "BZ", "BELIZE": "BZ",
  "Benin": "BJ", "BENIN": "BJ",
  "Bermuda": "BM", "BERMUDA": "BM",
  "Bhutan": "BT",
  "Bolivia": "BO", "BOLIVIA": "BO",
  "Bosnia and Herzegovina": "BA", "BOSNIA": "BA",
  "Botswana": "BW", "BOTSWANA": "BW",
  "Bouvet Island": "BV",
  "Brazil": "BR", "BRAZIL": "BR",
  "British Indian Ocean Territory": "IO",
  "British Virgin Islands": "VG",
  "Brunei": "BN", "BR P IS": "BN",
  "Bulgaria": "BG", "BULGAR": "BG",
  "Burkina Faso": "BF", "BUR FASO": "BF",
  "Burundi": "BI", "BURUNDI": "BI",
  "Cambodia": "KH", "CAMBODIA": "KH",
  "Cameroon": "CM", "CAMROON": "CM",
  "Canada": "CA", "CANADA": "CA",
  "Cape Verde": "CV",
  "Cayman Islands": "KY", "CAYMAN": "KY",
  "Central African Republic": "CF",
  "Chad": "TD",
  "Chile": "CL", "CHILE": "CL",
  "China, People's Republic of": "CN", "CHINA": "CN",
  "China, Republic of (Taiwan)": "TW", "TAIWAN": "TW",
  "Christmas Island": "CX",
  "Cocos (Keeling) Islands": "CC",
  "Colombia": "CO", "COLOMB": "CO",
  "Comoros": "KM",
  "Congo ? Brazzaville": "CG", "CO BRAZ": "CG",
  "Congo ? Kinshasa": "CD", "CONGO DR": "CD",
  "Cook Islands": "CK",
  "Costa Rica": "CR", "C RICA": "CR",
  "Cote d'Ivoire (Ivory Coast)": "CI", "C IVOIRE": "CI",
  "Croatia": "HR", "CROATIA": "HR",
  "Cuba": "CU", "CUBA": "CU",
  "Cyprus": "CY", "CYPRUS": "CY",
  "Czech Republic": "CZ", "CZECH RE": "CZ",
  "Denmark": "DK", "DENMARK": "DK",
  "Djibouti": "DJ", "DJIBOUTI": "DJ",
  "Dominica": "DM",
  "Dominican Republic": "DO", "DOM REP": "DO",
  "Ecuador": "EC", "ECUADOR": "EC",
  "Egypt": "EG", "EGYPT": "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ", "EQ GUIN": "GQ",
  "Eritrea": "ER", "ERITREA": "ER",
  "Estonia": "EE", "ESTONIA": "EE",
  "Ethiopia": "ET", "ETHIOP": "ET",
  "Falkland Islands": "FK",
  "Faroe Islands": "FO",
  "Fiji": "FJ",
  "Finland": "FI", "FINLAND": "FI",
  "France": "FR", "FRANCE": "FR",
  "French Guiana": "GF", "F GUIAN": "GF",
  "French Polynesia": "PF", "FR P IS": "PF",
  "French Southern Territories": "TF",
  "Gabon": "GA", "GABON": "GA",
  "Gambia, The": "GM", "GAMBIA": "GM",
  "Georgia": "GE", "GEORGIA": "GE",
  "Germany": "DE",
  "Ghana": "GH", "GHANA": "GH",
  "Gibraltar": "GI", "GIBRALT": "GI",
  "Greece": "GR", "GREECE": "GR",
  "Greenland": "GL", "GREENLD": "GL",
  "Grenada": "GD",
  "Guam": "GU", "GUAM": "GU",
  "Guatemala": "GT", "GUATMAL": "GT",
  "Guernsey": "GG", "GERMANY": "GG",
  "Guinea": "GN", "GUIN-CON": "GN",
  "Guinea-Bissau": "GW", "GUIN-BIS": "GW",
  "Guyana": "GY", "GUYANA": "GY",
  "Haiti": "HT", "HAITI": "HT",
  "Heard and McDonald Islands": "HM",
  "Honduras": "HN", "HONDURA": "HN",
  "Hong Kong": "HK", "HG KONG": "HK",
  "Hungary": "HU", "HUNGARY": "HU",
  "Iceland": "IS", "ICELAND": "IS",
  "India": "IN", "INDIA": "IN",
  "Indonesia": "ID", "INDNSIA": "ID",
  "Iran": "IR", "IRAN": "IR",
  "Iraq": "IQ", "IRAQ": "IQ",
  "Ireland": "IE", "IRELAND": "IE",
  "Isle of Man": "IM",
  "Israel": "IL", "ISRAEL": "IL",
  "Italy": "IT", "ITALY": "IT",
  "Jamaica": "JM", "JAMAICA": "JM",
  "Japan": "JP", "JAPAN": "JP",
  "Jersey": "JE",
  "Jordan": "JO", "JORDAN": "JO",
  "Kazakhstan": "KZ", "KAZAKHS": "KZ",
  "Kenya": "KE", "KENYA": "KE",
  "Kiribati": "KI",
  "Korea(North Korea)": "KP", "NO KOR": "KP",
  "Korea(South Korea)": "KR", "KOR REP": "KR",
  "Kosovo": "CS",
  "Kuwait": "KW", "KUWAIT": "KW",
  "Kyrgyzstan": "KG", "KYRGYZS": "KG",
  "Laos": "LA", "LAOS": "LA",
  "Latvia": "LV", "LATVIA": "LV",
  "Lebanon": "LB", "LEBANON": "LB",
  "Lesotho": "LS", "LESOTHO": "LS",
  "Liberia": "LR", "LIBERIA": "LR",
  "Libya": "LY", "LIBYA": "LY",
  "Liechtenstein": "LI",
  "Lithuania": "LT", "LITHUAN": "LT",
  "Luxembourg": "LU",
  "Macau": "MO", "MACAU": "MO",
  "Macedonia": "MK", "MACEDON": "MK",
  "Madagascar": "MG", "MADAGASR": "MG",
  "Malawi": "MW", "MALAWI": "MW",
  "Malaysia": "MY", "MALAYSA": "MY",
  "Maldives": "MV",
  "Mali": "ML", "MALI": "ML",
  "Malta": "MT", "MALTA": "MT",
  "Marshall Islands": "MH", "MARSHALL": "MH",
  "Martinique": "MQ", "MAURITN": "MQ",
  "Mauritania": "MR", "MAURIT": "MR",
  "Mauritius": "MU",
  "Mayotte": "YT",
  "Mexico": "MX", "MEXICO": "MX",
  "Micronesia": "FM", "MICRONES": "FM",
  "Moldova": "MD", "MOLDOVA": "MD",
  "Monaco": "MC",
  "Mongolia": "MN", "MONGOLIA": "MN",
  "Montenegro": "ME",
  "Montserrat": "MS",
  "Morocco": "MA", "MOROCCO": "MA",
  "Mozambique": "MZ", "MOZAMBQ": "MZ",
  "Myanmar (Burma)": "MM",
  "Namibia": "NA", "NAMIBIA": "NA",
  "Nauru": "NR",
  "Nepal": "NP", "NEPAL": "NP",
  "Netherlands": "NL", "NETHLDS": "NL",
  "Netherlands Antilles": "AN", "N ANTIL": "AN",
  "New Caledonia": "NC",
  "New Zealand": "NZ", "N ZEAL": "NZ",
  "Nicaragua": "NI", "NICARAG": "NI",
  "Niger": "NE", "NIGER": "NE",
  "Nigeria": "NG", "NIGERIA": "NG",
  "Niue": "NU",
  "Norfolk Island": "NF",
  "Northern Mariana Islands": "MP", "NMARIANA": "MP",
  "Norway": "NO", "NORWAY": "NO",
  "Oman": "OM", "OMAN": "OM",
  "Pakistan": "PK", "PAKISTN": "PK",
  "Palau": "PW", "PALAU": "PW",
  "Palestinian Territories": "PS",
  "Panama": "PA", "PANAMA": "PA",
  "Papua New Guinea": "PG", "PARAGUA": "PG",
  "Paraguay": "PY",
  "Peru": "PE", "PERU": "PE",
  "Philippines": "PH", "PHIL": "PH",
  "Pitcairn Islands": "PN",
  "Poland": "PL", "POLAND": "PL",
  "Portugal": "PT", "PORTUGL": "PT",
  "Puerto Rico": "PR",
  "Qatar": "QA", "QATAR": "QA",
  "Reunion": "RE",
  "Romania": "RO", "ROMANIA": "RO",
  "Russia": "RU", "RUSSIA": "RU",
  "Rwanda": "RW", "RWANDA": "RW",
  "Saint Helena": "SH",
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Pierre and Miquelon": "PM",
  "Samoa": "WS", "W SAMOA": "WS",
  "San Marino": "SM",
  "Sao Tome and Principe": "ST",
  "Saudi Arabia": "SA", "S ARAB": "SA",
  "Senegal": "SN", "SENEGAL": "SN",
  "Serbia": "RS",
  "Seychelles": "SC",
  "Sierra Leone": "SL", "SIER LN": "SL",
  "Singapore": "SG", "SINGAPR": "SG",
  "Slovakia": "SK", "SLOVAKIA": "SK",
  "Slovenia": "SI", "SLOVENIA": "SI",
  "Solomon Islands": "SB",
  "Somalia": "SO", "SOM REP": "SO",
  "South Africa": "ZA",
  "South Georgia Island": "GS",
  "Spain": "ES", "SPAIN": "ES",
  "Sri Lanka": "LK", "S LANKA": "LK",
  "St. Vincent and the Grenadines": "VC",
  "Sudan": "SD", "SUDAN": "SD",
  "Suriname": "SR", "SURINAM": "SR",
  "Svalbard": "SJ", "SALVADR": "SJ",
  "Swaziland": "SZ", "SWAZLND": "SZ",
  "Sweden": "SE", "SWEDEN": "SE",
  "Switzerland": "CH", "SWITZLD": "CH",
  "Syria": "SY", "SYRIA": "SY",
  "Tajikistan": "TJ", "TAJIKIS": "TJ",
  "Tanzania": "TZ", "TNZANIA": "TZ",
  "Thailand": "TH", "THAILND": "TH",
  "Timor-Leste (East Timor)": "TL",
  "Togo": "TG", "TOGO": "TG",
  "Tokelau": "TK",
  "Tonga": "TO",
  "Trinidad and Tobago": "TT", "TRINID": "TT",
  "Tristan da Cunha": "TA",
  "Tunisia": "TN", "TUNISIA": "TN",
  "Turkey": "TR", "TURKEY": "TR",
  "Turkmenistan": "TM", "TURKMEN": "TM",
  "Turks and Caicos Islands": "TC",
  "Tuvalu": "TV",
  "U.S. Virgin Islands": "VI",
  "Uganda": "UG", "UGANDA": "UG",
  "Ukraine": "UA", "UKRAINE": "UA",
  "United Arab Emirates": "AE", "U AR EM": "AE",
  "United Kingdom": "GB", "U KING": "GB",
  "United States": "US", "US O IS": "US",
  "Uruguay": "UY", "URUGUAY": "UY",
  "Uzbekistan": "UZ", "UZBEKIS": "UZ",
  "Vanuatu": "VU",
  "Vatican City": "VA",
  "Venezuela": "VE", "VENEZ": "VE",
  "Việt Nam": "VN", "Viet Nam": "VN", "VIETNAM": "VN",
  "Wallis and Futuna": "WF",
  "Western Sahara": "EH",
  "Yemen": "YE", "YEMEN": "YE",
  "Zambia": "ZM", "ZAMBIA": "ZM",
  "Zimbabwe": "ZW", "ZIMBABWE": "ZW"

}

export const mapUSIDNames = (name) => {
  if (uSNameIds[name]) return {id: uSNameIds[name], name: name};

  return {id: name};
}