import classNames from 'classnames'
import React, {useEffect, useRef} from 'react'
import {RiBold, RiCodeLine, RiItalic, RiUnderline} from 'react-icons/ri'
import {Editor, Range} from 'slate'
import {useFocused, useSlate} from 'slate-react'
import {Null} from 'views/Shared'
import {withAlign} from 'views/SlateEditor/functions'
import {isBlockActive, isMarkActive, toggleBlock, toggleMark,} from 'views/SlateEditor/functions/toolbar'
import Menu from 'views/SlateEditor/Menu'
import {NodeInlineTypes} from 'views/SlateEditor/types'
import {Portal} from '../Portal'

const HoverIcon = ({Component = Null, ...props}) => (
  <Component
    {...{
      size: 26,
      className: 'block',
      style: {padding: '0 4px', margin: '0 1px'},
    }}
    {...props}
  />
)

const FormatButton = ({Icon, style, format, className, ...props}) => {
  const editor = useSlate()
  const active = props?.active ?? isMarkActive(editor, format)
  return (
    <div
      style={{
        ...(style ?? {}),
        // height: 26,
      }}
      className={classNames(
        className,
        'inline-block cursor-pointer',
        active ? 'text-primary' : 'text-white'
      )}
      onClick={() => toggleMark(editor, format)}
      {...props}>
      <HoverIcon Component={Icon}/>
    </div>
  )
}

const BlockButton = ({Icon, style, format, className, ...props}) => {
  const editor = useSlate()
  const active = isBlockActive(editor, format, withAlign(format) ?? 'type')
  return (
    <div
      style={{
        ...(style ?? {}),
        // height: 26,
      }}
      className={classNames(
        className,
        'inline-block align-middle cursor-pointer',
        active ? 'text-primary' : 'text-white'
      )}
      onClick={(event) => {
        toggleBlock(editor, format)
      }}>
      <HoverIcon Component={Icon}/>
    </div>
  )
}

const BoldButton = () => (
  <FormatButton
    Icon={RiBold}
    format={NodeInlineTypes.BOLD}
  />
)

const ItalicButton = () => (
  <FormatButton
    Icon={RiItalic}
    format={NodeInlineTypes.ITALIC}
  />
)

const UnderlinedButton = () => (
  <FormatButton
    Icon={RiUnderline}
    format={NodeInlineTypes.UNDERLINED}
  />
)

const CodeButton = () => (
  <FormatButton
    Icon={RiCodeLine}
    format={NodeInlineTypes.CODE}
  />
)

const HoveringToolbar = () => {
  const ref = useRef()

  const editor = useSlate()

  const inFocus = useFocused()

  useEffect(() => {
    const el = ref.current
    const {selection} = editor

    if (!el) {
      return
    }

    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style')
      return
    }

    const domSelection = window.getSelection()
    const domRange = domSelection.getRangeAt(0)
    const rect = domRange.getBoundingClientRect()
    el.style.opacity = '1'
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`
    el.style.left = `${
      rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2
    }px`
  })

  return (
    <Portal>
      <Menu
        ref={ref}
        className="SlateMenu text-center flex items-center"
        onMouseDown={(e) => {
          e.preventDefault()
        }}>
        <BoldButton/>
        <ItalicButton/>
        <UnderlinedButton/>
        <CodeButton/>
      </Menu>
    </Portal>
  )
}

export default HoveringToolbar
