import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { withProps } from 'recompose'
import { ValidateComponent } from 'routes/routesValidate'
import { gConfigs, gPaths } from 'views/AppLocals/configs'
import { ContentNotFound } from 'views/AppLocals/ContentNotFound'
import { OrganizationContainerProvider } from 'views/AppLocals/Organization/OrganizationContainerContext'
import OrganizationSettingsLazy from 'views/AppLocals/Organization/Settings'
import { SubDomainContext } from 'views/SubDomain/SubDomainContext'
import { OrganizationStateless } from './Organization'

export const SubDomainPaths = Object.freeze({
  NOT_FOUND: '/page-not-found',
})

const DomainOrganization = () => {
  const { id, diff_root, withSubDomain } = useContext(SubDomainContext)

  return (
    <React.Fragment>
      <div className="w-full">
        <OrganizationContainerProvider item={id}>
          <Switch>
            {gConfigs.gRoutes.map(({ component: Component, ...route }) => {
              const _path = route.path
              return (
                <Route
                  key={_path}
                  {...route}
                  component={
                    diff_root &&
                    [
                      gPaths.user,
                      gPaths.topic,
                      gPaths.category,
                      gPaths.userSettings,
                    ].includes(_path)
                      ? undefined
                      : Component
                  }
                  render={(props) => (
                    <ValidateComponent {...route}>
                      <Component {...props} />
                    </ValidateComponent>
                  )}
                />
              )
            })}
            <Route
              path={gPaths.organizationSettings_domain}
              component={OrganizationSettingsLazy}
            />
            <Route
              component={withProps({
                redirectUrl: '/',
              })(ContentNotFound)}
              withSubDomain={true}
              path={SubDomainPaths.NOT_FOUND}
            />
            <Route
              path="*"
              component={() => <OrganizationStateless id={id} />}
            />
          </Switch>
        </OrganizationContainerProvider>
      </div>
    </React.Fragment>
  )
}

export default DomainOrganization
