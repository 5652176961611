import {Select, Spin} from 'antd'
import React from 'react'
import {API_ROOT_URL} from "../../envs/_current/config";
import debounce from 'lodash/debounce';
import _ from "lodash";

function DebounceSelect({name, type, defaultOptions = [], fetchOptions, debounceTimeout = 800, ...props}) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState(defaultOptions);
  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value = "") => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setFetching(true);
      fetchOptions(type, value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      showSearch
      filterOption={false}
      allowClear
      onFocus={() => debounceFetcher("")}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small"/> : null}
      options={options}
      {...props}
    />
  );
} // Usage of DebounceSelect

export async function fetchLookupList(type, keyword) {
  console.log('fetching ', type, keyword);
  return fetch(API_ROOT_URL + `/lookup/${type}/gets?keyword=${keyword}`)
    .then((response) => response.json())
    .then((body) => {

      let options = _.get(body, 'data', []).map((e) => ({
          label: e.name,
          value: e.name,
          id: e.id,
        }));

        const found = options.some(el => el.value === keyword);
        if (!found) options.push({
          label: keyword,
          value: keyword,
          id: null,
        });

        return [
          ...options
        ]
      },
    );
}

export async function fetchLookupByTypeList(type, keyword) {
  console.log('fetching ', type, keyword);
  return fetch(API_ROOT_URL + `/lookup/${type}/get-by-types?keyword=${keyword}`)
    .then((response) => response.json())
    .then((body) => {

      let options = _.get(body, 'data', []).map((e) => ({
          label: e.name,
          value: e.name,
          id: e.id,
        }));

        const found = options.some(el => el.value === keyword);
        if (!found) options.push({
          label: keyword,
          value: keyword,
          id: null,
        });

        return [
          ...options
        ]
      },
    );
}

export default DebounceSelect
