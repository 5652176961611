import { channel_getPromoteChannelsForEveryone_Api } from 'apis'
import { LazyPagination } from 'modules/asyncCache/components/LazyPagination'
import React, { useMemo } from 'react'
import { renderLoading } from 'views/AppLocals/functions/renderers/renderLoading'
import { renderChannelItem } from 'views/MainPage/functions/renderChannelItem'

const SuggestedChannels = (props) => {
  const refreshKey = useMemo(() => [], [])
  return (
    <LazyPagination
      key={refreshKey}
      refreshKey={refreshKey}
      renderLoading={renderLoading}
      renderItem={renderChannelItem}
      apiInfo={channel_getPromoteChannelsForEveryone_Api}
      {...props}
    />
  )
}

export default SuggestedChannels
