import {Skeleton} from 'antd'
import chartSize from 'conts/chartSize'
import viewModes from 'conts/viewModes'
import {GOOGLE_API_KEY} from 'envs/_current/config'
import _ from 'lodash'
import React from 'react'
import {decrypt} from './dataHelpers'

const mapGeoChart = ({
  theme,
  chart,
  size = chartSize.NORMAL,
  viewmode = viewModes.BLOCK
}) => {
  let params = chart.params
  // console.log({ params })
  let chartParams = _.cloneDeep(params)
  if (
    chartParams && chartParams.options &&
    (
      !_.has(chartParams, 'options.height') ||
      chartParams.options.height ===
      'auto'
    )
  ) {
    chartParams.options.height =
      viewmode === viewModes.FULLSCREEN
        ? window.screen.height - 320
        : Math.floor(
            window.screen.height * 0.38
          )
  }

  if (theme === 'dark') {
    chartParams.options.backgroundColor = {
      ..._.get(
        chartParams,
        'options.backgroundColor',
        {}
      ),
      fill: 'transparent',
      fillOpacity: 0.8
    }

    const chartTextStyle = {
      color: '#FFF'
    }
    chartParams.options.vAxis = {
      ..._.get(
        chartParams,
        'options.vAxis',
        {}
      ),
      textStyle: chartTextStyle,
      titleTextStyle: chartTextStyle,
      gridlines: { color: '#787878' }
    }
    chartParams.options.hAxis = {
      ..._.get(
        chartParams,
        'options.hAxis',
        {}
      ),
      textStyle: chartTextStyle,
      titleTextStyle: chartTextStyle
    }
    chartParams.options.legend = {
      ..._.get(
        chartParams,
        'options.legend',
        {}
      ),
      textStyle: chartTextStyle
    }
  }

  let chartConfig = {
    chartType: 'GeoChart',
    loader: <Skeleton />,
    ...chartParams,
    // Note: you will need to get a mapsApiKey for your project.
    // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
    mapsApiKey: GOOGLE_API_KEY,
    rootProps: {
      'data-google-id': chart.id
    }
  }

  const data_values =
    Number(chart.data_encrypted) !== 1
      ? chart.data_values
      : decrypt(
          chart.data_values,
          chart.data_passphrase
        )

  if (
    _.has(chart, 'spreadsheet_url') &&
    _.has(
      chart,
      'spreadsheet_query_params'
    )
  ) {
    chartConfig.spreadSheetUrl = _.get(
      chart,
      'spreadsheet_url'
    )
    chartConfig.spreadSheetQueryParameters = _.get(
      chart,
      'spreadsheet_query_params'
    )
  } else if (
    _.has(chart, 'data_columns') &&
    !_.isEmpty(chart.data_columns) &&
    _.has(chart, 'data_values')
  ) {
    chartConfig.data = [
      chart.data_columns,
      ...data_values
    ]
  } else {
    chartConfig = {
      chartType: 'TreeMap',
      loader: <Skeleton />,
      data: [
        //give a datatable with string 'No Data' to display
        ['', '', ''],
        ['no data', null, 100]
      ],
      options: {
        width: '100%',
        height: '100%'
      },
      rootProps: {
        'data-google-id': chart.id
      }
    }
  }

  return chartConfig
}
export default mapGeoChart
