import React from "react"

export const MessageWrapper = ({
  children
}) => (
  <div className="col-span-12" >
    <div className="border-t border-gray-300 my-3" />
    {children}
  </div>
)

const BorderWrapper = ({
  children
}) => {
  return (
    <div className="flex flex-col items-center border rounded-lg">
      {children}
    </div>
  )
}

const ResponsiveRow = ({
  children,
}) => {
  return (
    <div className="w-full flex flex-col justify-center background border border-color-50 rounded-lg p-3 hover:shadow-items">
      {children}
    </div>
  )
}

export default {
  ResponsiveRow,
  BorderWrapper
}
