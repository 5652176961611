import { gPaths } from 'views/AppLocals/configs'
import createSettingContent from 'views/AppLocals/factory/createSettingContent'
import EventContext from '../EventContext'

const EventSettingsContent = ({ Wrapper, routes }) => {
  const rootPath = gPaths.eventSettings
  return createSettingContent(EventContext, { routes, Wrapper, rootPath })
}

export default EventSettingsContent
