import CaretDownFilled from '@ant-design/icons/CaretDownFilled'
import CaretUpFilled from '@ant-design/icons/CaretUpFilled'
import {Table, Typography} from 'antd'
import classNames from 'classnames'
import EmptyHolder from 'components/EmptyHolder'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import chartSize from 'conts/chartSize'
import viewModes from 'conts/viewModes'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import moment from 'moment'
import numeral from 'numeral'
import React, {useContext, useEffect, useState} from 'react'
import {decrypt} from 'views/Chart/mapParams/dataHelpers'
import {emptyArray} from "../../../../../helpers/emptyObjects";

const { Text } = Typography

export const AntTableStateless = ({
  item,
  setChartWrap,
  viewmode = viewModes.BLOCK,
  ...props
}) => {
  const [id] = useState(
    _.uniqueId('chart_' + item.id)
  )
  const t = useTranslate()
  const [
    loading,
    setLoading
  ] = useState(_.isEmpty(item));
  let title = undefined, footer = undefined;

  const {
    isSm,
    breakpoint,
    breakpoints
  } = useContext(LayoutContext)
  const _chartSize = _.get(
    props,
    'size',
    isSm || viewmode === viewModes.BLOCK
      ? chartSize.SMALL
      : chartSize.NORMAL
  )

  let chartParams = _.get(item, 'params', {});

  chartParams = JSON.parse(
    JSON.stringify(chartParams)
  )

  if (!chartParams)
    chartParams = {};
  chartParams.loading = loading;

  if (chartParams.title) {
    title = () => chartParams.title;
  }
  if (chartParams.footer) {
    footer = () => chartParams.footer;
  }

  let data_columns = _.cloneDeep(
    _.get(item, 'data_columns', [])
  )

  useEffect(() => {
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  let _summaryValues = {}
  const data_values = (() => {
    let data_values =
      Number(item.data_encrypted) !== 1
        ? item.data_values
        : decrypt(
          item.data_values,
          item.data_passphrase
        )

    const data_types = data_columns.map(
      c => c.type || 'string'
    )
    const transpose = (r, a) =>
      a.map((v, i) => {
        if (r[i]) {
          if (
            data_types[i] === 'datetime'
          ) {
            return [
              ...r[i],
              moment
                .utc(v)
                .local()
                .valueOf()
            ]
          } else {
            return [...r[i], v]
          }
        } else {
          return [...[], v]
        }
      })
    data_values = (data_values || emptyArray).map(e =>
      Object.fromEntries(
        [
          data_columns.map(c => c.id),
          e
        ].reduce(transpose, [])
      )
    ).map((e, i) => {
      return {...e, key: i}
    })

    // tính cộng dồn lại với data_columns có thuộc tính 'cumulated': true
    let _found_cols
    if (!_.isEmpty(data_values)) {
      _found_cols = _.filter(
        data_columns,
        { cumulated: true }
      )
      _.forEach(_found_cols, function (
        col
      ) {
        let _prevValue = 0
        data_values = (data_values || emptyArray).map(
          e => {
            _prevValue =
              _prevValue + e[col.id]
            return {
              ...e,
              [col.id]: _prevValue
            }
          }
        )
      })
    }

    if (chartParams.summaryFieldY) {
      (data_values || emptyArray).map(e => {
        e.sumY = data_columns.reduce(
          (sum, rec1) => {
            return (
              sum +
              Number(
                rec1.aggregate
                  ? e[rec1.id] ?? 0
                  : 0
              )
            )
          },
          0
        )
      })

      if (
        _.isEmpty(
          _.filter(data_columns, {
            id: 'sumY'
          })
        )
      ) {
        data_columns.push({
          id: 'sumY',
          dataIndex: 'sumY',
          title: 'Total',
          type: 'number',
          align: 'right',
          fixed: 'right',
          formatNumber: '#,###',
          ...chartParams.summaryFieldY
        })
      }
    }

    if (chartParams.summaryFieldX) {
      const _aggregateFields = _.filter(
        data_columns,
        { aggregate: true }
      )
        .map(e => e.id)
        .reduce(
          (a, b) => ((a[b] = 0), a),
          {}
        )

      _summaryValues = data_values.reduce(
        (sumObj, rec1) => {
          let _tempR = _.transform(
            rec1,
            (r, v, k) => {
              if (_.has(r, k.trim()))
                r[k.trim()] =
                  v + r[k.trim()]
            },
            sumObj
          )
          return _tempR
        },
        _aggregateFields
      )

      chartParams = {
        ...chartParams,
        summaryFixed: true,
        summaryPosition: 'top',
        summary: data => {
          return (
            <Table.Summary.Row>
              {data_columns.map(e => {
                if (e.aggregate) {
                  let _text =
                    _summaryValues[e.id]
                  if (
                    typeof _text ===
                    'number' &&
                    chartParams
                      .summaryFieldX
                      .formatNumber
                  )
                    _text = numeral(
                      _text
                    ).format(
                      chartParams
                        .summaryFieldX
                        .formatNumber
                    )
                  return (
                    <Table.Summary.Cell>
                      <Text
                        strong
                        className="float-right">
                        {_text}
                      </Text>
                    </Table.Summary.Cell>
                  )
                } else {
                  return (
                    <Table.Summary.Cell>
                      -
                    </Table.Summary.Cell>
                  )
                }
              })}
            </Table.Summary.Row>
          )
        }
      }
    }

    return data_values
  })()

  data_columns = data_columns.map(e => {
    e.render = (
      text,
      record,
      index
    ) => {
      let _rs = null
      let _text = text
      if (
        text instanceof Object &&
        text.f
      )
        _text = text.f
      if (e.formatNumber) {
        if (typeof _text === 'number')
          _text = numeral(_text).format(
            e.formatNumber
          )
      }

      let _textVal = text
      if (
        text instanceof Object &&
        text.v
      )
        _textVal = text.v
      if (e.renderBody && !!_textVal) {
        let _html = e.renderBody.replace(
          '{text}',
          _text
        )

        if (
          _html.includes('{') &&
          _html.includes('}')
        ) {
          Object.keys(record).every(
            function (e1, index) {
              if (
                record[e1] !== undefined
              ) {
                let _texte = _.get(
                  record,
                  e1 + '.f',
                  _.get(record, e1)
                )
                if (e.formatNumber) {
                  if (
                    typeof _texte ===
                    'number'
                  )
                    _texte = numeral(
                      _texte
                    ).format(
                      e.formatNumber
                    )
                }
                _html = _html.replace(
                  '{' + e1 + '}',
                  _texte
                )
              }
              return !!(
                _html.includes('{') &&
                _html.includes('}')
              )
            }
          )
        }
        _rs = (
          <div
            dangerouslySetInnerHTML={{
              __html: _html
            }}></div>
        )
      } else {
        _rs = _text
      }

      if (
        e.cellFormat === 'ArrowFormat'
      ) {
        if (
          typeof _textVal === 'number'
        ) {
          if (Number(_textVal) < 0) {
            return (
              <div className="flex">
                <CaretDownFilled className="text-red-500 text-left flex-1" />
                {_rs}
              </div>
            )
          } else if (
            Number(_textVal) > 0
          ) {
            return (
              <div className="flex">
                <CaretUpFilled className="text-green-500 text-left flex-1" />
                {_rs}
              </div>
            )
          } else {
            return _rs
          }
        }
      }

      if (
        e.cellFormat === 'ColorFormat'
      ) {
        if (
          typeof _textVal === 'number'
        ) {
          if (Number(_textVal) < 0) {
            return (
              <div className="text-red-500 text-right">
                {_rs}
              </div>
            )
          } else if (
            Number(_textVal) > 0
          ) {
            return (
              <div className="text-green-500 text-right">
                {_rs}
              </div>
            )
          } else {
            return _rs
          }
        }
      }

      return _rs
    }

    if (_.get(e, 'filterAllow', true)) {
      e.onFilter = (value, record) =>
        record[e.dataIndex].indexOf(
          value
        ) === 0
    }

    if (_.get(e, 'sortAllow', true)) {
      e.sorter = (a, b, sorted) => {
        if (
          typeof (
            (a[e.dataIndex] &&
              a[e.dataIndex].v) ??
            a[e.dataIndex]
          ) == 'number'
        ) {
          return (
            ((a[e.dataIndex] &&
              a[e.dataIndex].v) ??
              a[e.dataIndex]) -
            ((b[e.dataIndex] &&
              b[e.dataIndex].v) ??
              b[e.dataIndex])
          )
        }
        return (
          ((a[e.dataIndex] &&
            a[e.dataIndex].v) ??
            a[e.dataIndex]) >
          ((b[e.dataIndex] &&
            b[e.dataIndex].v) ??
            b[e.dataIndex])
        )
      }
    }

    return e
  })

  if (
    !data_columns ||
    !data_columns.length
  ) {
    return (
      <div
        className={classNames(
          props.className,
          'w-full'
        )}>
        <EmptyHolder
          title={null}
          subTitle={t('no data found')}
        />
      </div>
    )
  }

  _.remove(data_columns, function (el) {
    // remove all numbers
    return el.hidden === true
  })

  data_columns = _.uniq(data_columns)

  return (
    <div
      className="flex-1"
      style={{
        width: '100%',
        height: '100%'
      }}
      id={id}>
      <Table
        pagination={{
          defaultPageSize: 7,
          hideOnSinglePage: true
        }}
        scroll={{ x: 'max-content' }}
        rowClassName="background"
        responsive={[breakpoint]}
        {...chartParams}
        title={title}
        footer={footer}
        columns={data_columns}
        dataSource={data_values}
      />
    </div>
  )
}

export default AntTableStateless
