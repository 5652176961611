import { Button, Input } from 'antd'
import { event_getEventsByProps_Api } from 'apis/event'
import { getId, getType } from 'apis/model/base'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, { useMemo, useState } from 'react'
import { compose, fromRenderProps } from 'recompose'
import { staticPaths } from 'routes/staticPaths'
import EntityList from 'views/AppLocals/EntityList'
import EventItem from 'views/AppLocals/Event/components/EventItem'
import EventEntity from 'views/AppLocals/Event/EventEntity'
import { bindQueryParams } from 'views/AppLocals/functions/routerHelper'
import ListHeader from 'views/AppLocals/Templates/items/ListHeader'
import { Null, renderOwnChild } from 'views/Shared'
import WorkspaceContext from '../WorkspaceContext'

const ListFilter = ({
  sortBy,
  onSearch = Null,
  setSortBy = Null,
  Wrapper = renderOwnChild,
}) => {
  const t = useTranslate()
  return (
    <Wrapper>
      <div className="flex flex-1 space-x-3">
        <Input.Search
          className="input-search"
          placeholder={t('search')}
          onSearch={(value) => onSearch(value)}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex items-center justify-between border-b border-color-50 pb-2">
          <span className="uppercase text-sm font-semibold text-color-200">
            {t('list of event')}
          </span>
          {/* <div className="flex items-end justify-end">
            <EventsSortBy
              value={sortBy}
              onSelect={(value) => setSortBy(value)}
            />
          </div> */}
        </div>
      </div>
    </Wrapper>
  )
}

const WorkspaceEventList = ({ owner_id, owner_type, Wrapper = 'div' }) => {
  const t = useTranslate()

  const history = useHistory()

  const [sortBy, setSortBy] = useState()

  const [keyword, setKeyword] = useState()

  const refreshKey = useMemo(() => {
    return [owner_id, owner_type, sortBy, keyword].join('/')
  }, [owner_id, owner_type, sortBy, keyword])

  return (
    <Wrapper className="flex flex-col space-y-3">
      <ListHeader
        title="event"
        extra={
          <Button
            className="button-rounded-lg no-border"
            type="primary"
            onClick={() => {
              history.push({
                ...staticPaths.createEvent,
                search:
                  '?' +
                  new URLSearchParams({
                    owner_id,
                    owner_type,
                  }).toString(),
              })
            }}>
            {t('create')}
          </Button>
        }
      />
      <ListFilter
        sortBy={sortBy}
        setSortBy={setSortBy}
        onSearch={setKeyword}
      />
      <div className="space-y-4">
        <EntityList
          refreshKey={refreshKey}
          apiInfo={event_getEventsByProps_Api}
          values={{
            keyword,
            sort_by: sortBy,
          }}
          query={bindQueryParams([{ id: owner_id }, { type: owner_type }])}
          RenderItem={EventItem}
          RenderEntity={EventEntity}
        />
      </div>
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(WorkspaceContext.Consumer, ({ organization }) => ({
    owner_id: getId(organization),
    owner_type: getType(organization),
  }))
)(WorkspaceEventList)
