import React from 'react'
import Hotkeys from 'react-hot-keys'
import useRouter from '../navigation/useRouter'

const GlobalShortCuts = () => {
  const { history } = useRouter()
  const onKeyUp = (
    keyName,
    e,
    handle
  ) => {
    if (keyName === 'alt+s') {
      history.push('/search', {
        isModal: true
      })
    }
    if (keyName === 'alt+m') {
      history.push('/navigationMenu', {
        isModal: true
      })
    }
  }
  const onKeyDown = (
    keyName,
    e,
    handle
  ) => {
    console.log(
      'onKeyDown',
      keyName,
      e,
      handle
    )
  }
  return (
    <Hotkeys
      keyName="alt+m,alt+s"
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
    />
  )
}
export default GlobalShortCuts
