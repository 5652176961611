import {Input} from 'antd'
import classNames from 'classnames'
import {createValue} from 'components/form/utils'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useEffect, useMemo, useState} from 'react'
import {BsEnvelope} from 'react-icons/bs'
import {MdMarkEmailRead, MdOutlineMarkEmailUnread} from 'react-icons/md'
import {RiSearch2Line} from 'react-icons/ri'
import {Null, renderSelf} from 'views/Shared'
import {user_lookupEmailGetInfo_Api} from '../../apis'
import useAsyncAction from '../../modules/asyncCache/useAsyncAction'
import {renderIf, renderIfElse} from '../../views/Shared'
import {LayoutContext} from '../layouts/Default/LayoutContext'

const EmailLookupField = ({
                            name,
                            auto = true,
                            handleApply,
                            defaultValue,
                            onChange = Null,
                            disabled = false,
                            getValue = renderSelf,
                            placeholder = 'ex: email',
                            className,
                          }) => {
  const t = useTranslate()
  const [autoExec, setAutoExec] = useState(auto)
  const [email, setEmail] = useState(defaultValue)
  const [userInfo, setUserInfo] = useState()
  const {isSm} = useContext(LayoutContext)

  const {
    response,
    success,
    handleAsyncAction: lookupEmailGetInfo,
  } = useAsyncAction({
    values: {email},
    apiInfo: user_lookupEmailGetInfo_Api,
  })

  useEffect(() => {
    if (success) {
      const userInfo = _.get(response, 'data', {})
      setUserInfo(userInfo)
      if (!!autoExec) {
        handleApply({...userInfo, email})
      }
    } else {
      setUserInfo(null)
      if (!!autoExec) {
        handleApply({email})
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, response])
  useEffect(() => {
    if (!!defaultValue) {
      lookupEmailGetInfo({email: defaultValue})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const suffix = useMemo(
    () =>
      renderIf(
        email,
        renderIfElse(
          userInfo,
          <MdMarkEmailRead
            style={{
              fontSize: 16,
              color: '#0d7c23',
            }}
          />,
          <MdOutlineMarkEmailUnread
            style={{
              fontSize: 16,
              color: '#1677ff',
            }}
          />
        )
      ),
    [email, userInfo]
  )

  return (
    <div>
      <Input.Search
        name={name}
        suffix={suffix}
        allowClear={true}
        disabled={disabled}
        onChange={onChange}
        defaultValue={getValue(defaultValue)}
        placeholder={_.isString(placeholder) ? t(placeholder) : undefined}
        className={classNames('w-full custom-input-search-addon', className)}
        onSearch={(value) => {
          onChange(createValue(name, value))
          if (!!value) {
            lookupEmailGetInfo({email: value})
          }
          setEmail(value)
          if (!autoExec) {
            setAutoExec(true)
          }
        }}
        enterButton={
          isSm ? (
            <RiSearch2Line style={{marginTop: '0.4rem'}}/>
          ) : (
            <span className="text-sm ">{t('search')}</span>
          )
        }
        addonBefore={
          isSm ? (
            <BsEnvelope
              style={{marginTop: '0.4rem'}}
              className="text-color-500"
            />
          ) : (
            <span className="text-sm text-color-500 italic">{t('email')}</span>
          )
        }
      />
      <div className="mt-2 px-1">
        {renderIfElse(
          email,
          renderIfElse(
            userInfo,
            <span className="text-sm italic">
              <span className="text-color-400">
                {`${t('this email address is associated with the account')} : `}
              </span>
              <span className="font-bold text-primary">{userInfo?.name}</span>
            </span>,
            <div className="text-xs text-color-200 italic leading-tight">
              {`* ${t('not found email description')}`}
            </div>
          ),
          <span className="text-xs text-color-500 italic">
            {`* ${t('enter email address')}`}
          </span>
        )}
      </div>
    </div>
  )
}

export default EmailLookupField
