import {Avatar, Button, Modal} from 'antd'
import {post_create_Api} from 'apis'
import {getId, getType} from 'apis/model/base'
import classNames from 'classnames'
import {MegaRadioGroup} from 'components/form/fields/MegaRadioGroup'
import FieldsFactory from 'components/form/FieldsFactory'
import {createValue} from 'components/form/utils'
import Pure from 'components/Pure'
import {Formik, FormikConsumer, useFormikContext} from 'formik'
import getTitle from 'helpers/getTitle'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useContext, useEffect, useMemo} from 'react'
import {useToggle} from 'react-use'
import {mapProps, nest} from 'recompose'
import {createControlledFormField} from 'views/AppLocals/factory/createFormField'
import {NotificationActionTypes, successNotify,} from 'views/AppLocals/factory/createNotification'
import LoadingPage from 'views/LoadingPage'
import {deepTranslate, Null} from 'views/Shared'
import {ObserverContext} from 'views/Shared/components/ObservedList'
import Translate from '../../../modules/local/Translate'
import {notifyOnError} from '../../../views/AppLocals/factory/createErrorEvent'
import {renderElse, renderIf, renderIfElse} from '../../../views/Shared'
import {SearchGroupIcon} from '../../AppIcon'
import {LayoutContext} from '../../layouts/Default/LayoutContext'
import MentionDataContext, {MentionDataProvider} from '../MentionDataContext'
import {AttachedActions} from './AttachedActions'
import {InputBoardContext} from './InputBoardContext'
import MegaTextarea from './MegaTextarea'
import {CreatorSelect, OwnerSelect} from './ObjectSelector'
import {PostPrivacyTypes} from './PostPrivacy'
import {HiPencilSquare} from "react-icons/hi2";

export const formSchema = {
  container: {
    children: [
      {
        name: 'container',
        component: ({name, value, form, onChange}) => {
          const {containers, container_disabled} = form?.values ?? {}
          return (
            <CreatorSelect
              name={name}
              value={value}
              bordered={false}
              options={containers}
              onChange={onChange}
              placeholder="select"
              disabled={container_disabled}
              // renderSelect={multiple_options_only}
              label="Choose the channel for posting"
            />
          )
        },
      },
    ],
  },
  owner: {
    children: [
      {
        name: 'owner',
        component: ({name, value, form, onChange}) => {
          const {container, owner_disabled} = form?.values ?? {}
          return (
            <OwnerSelect
              name={name}
              value={value}
              label="posted by"
              onChange={onChange}
              placeholder="select"
              disabled={owner_disabled}
              parent_id={getId(container)}
            />
          )
        },
      },
    ],
  },
  description: {
    children: [
      {
        name: 'description',
        component: createControlledFormField({
          rows: 6,
          placeholder: 'type content',
          Component: MegaTextarea,
          style: {
            outline: 'none!important',
          },
          fieldNames: {
            embed_data: 'embed_data',
            description: 'description',
          },
          withProps: ({form, onChange = Null}) => {
            const {description_data} = form?.values ?? {}
            return {
              onMentioned: (mention) => {
                onChange(
                  createValue('description_data', {
                    ...(description_data ?? {}),
                    ...(mention ?? {}),
                  })
                )
              },
            }
          },
        }),
      },
    ],
  },
  privacy: {
    title: 'privacy',
    children: [
      {
        name: 'privacy',
        component: ({name, value, onChange}) => (
          <Translate>
            {(t) => (
              <MegaRadioGroup
                {...{
                  name,
                  value,
                  onChange,
                }}
                options={[
                  PostPrivacyTypes.public,
                  PostPrivacyTypes.private,
                ].map(deepTranslate(t))}
              />
            )}
          </Translate>
        ),
      },
    ],
  },
  settings: {
    children: [
      {
        component: mapProps(({onChange, form}) => ({
          onChange,
          values: _.pick(form?.values, ['tags', 'privacy']),
          fieldNames: {
            tags: 'tags',
            privacy: 'privacy',
          },
        }))(AttachedActions),
      },
    ],
  },
}

const PostActionPanel = ({owner, container}) => {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext);
  return (
    <div className="flex flex-1 items-center gap-2 truncate">
      {renderIf(
        owner && container,
        <React.Fragment>
          <Avatar
            size={isSm ? 30 : 25}
            src={_.get(owner, 'owner.avatar')}
            icon={
              <SearchGroupIcon
                size={15}
                type={getType(owner)}
                className="text-color-500"
              />
            }
            className={classNames(
              'flex flex-center background-200',
              getType(owner) === 'organization' ? 'rounded-md' : 'rounded-full'
            )}
          />
          {renderIfElse(
            (getId(owner) === getId(container) || (container?.id === (owner?.id + '@default@' + owner?.idname))),
            <div className="flex flex-wrap flex-1 items-center gap-x-2 text-sm truncate">
              <span className="font-bold text-primary no-underline truncate cursor-pointer leading-tight">
                {getTitle(owner)}
              </span>
              <span className="whitespace-no-wrap text-color-400 italic leading-tight lowercase">
                {t('posted')}
              </span>
            </div>,
            <div className="flex flex-wrap flex-1 items-center gap-x-2 text-sm truncate">
              <span className="font-bold text-primary no-underline truncate cursor-pointer leading-tight">
                {getTitle(owner)}
              </span>
              <React.Fragment>
                <span className="whitespace-no-wrap text-color-400 italic leading-tight lowercase">
                  {t('posted on the channel')}
                </span>
                <span className="font-bold text-color-000 no-underline truncate cursor-pointer leading-tight">
                  {`${getTitle(container)}`}
                </span>
              </React.Fragment>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export const PostSettingPanel = ({}) => {
  const t = useTranslate()

  const [isToggle, toggle] = useToggle()

  const {values, handleChange: onChange = Null} = useFormikContext()

  const {
    owner,
    container,
    containers,
    default_owner_id,
    default_container_id,
    container_disabled,
    owner_disabled
  } = values ?? {}

  const {isLoading, getCreators = Null} = useContext(InputBoardContext)

  const defaultContainer = useMemo(() => {
    const _options = Array.from(containers ?? [])
    if (_options.length === 1) {
      return _.first(_options)
    }
    const defaultOption = _.find(_options, ({is_default, ...option}) => {
      return default_container_id
        ? getId(option) === default_container_id
        : !!is_default
    })

    if (!_.isEmpty(defaultOption)) return defaultOption

    return (
      _.find(
        _options,
        ({data, owner_id}) =>
          owner_id === default_owner_id || data?.is_default
      ) ?? {}
    )
  }, [containers, default_owner_id])
  useEffect(() => {
    if (defaultContainer) {
      onChange(createValue('container', defaultContainer))
    }
  }, [defaultContainer])

  const defaultOwner = useMemo(() => {
    const _options = getCreators(getId(container))
    const array = Array.from(_options ?? [])
    if (array.length === 1) {
      return _.first(array)
    }
    return _.find(array, {
      id: default_owner_id,
    })
  }, [container, default_owner_id])
  useEffect(() => {
    if (defaultOwner) {
      onChange(createValue('owner', defaultOwner))
    }
  }, [defaultOwner])

  if (!!isLoading) {
    return <LoadingPage style={{padding: 0}}/>
  }

  return (
    <React.Fragment>
      <div
        onClick={() => {
          if (!(container_disabled && owner_disabled)) toggle(true)
        }}
        className="flex items-center justify-between gap-2 py-2 px-3 rounded-md background-100 hover:background-200 cursor-pointer">
        <PostActionPanel
          {...{owner, container}}
          {..._.omitBy(
            {
              owner: owner?.data,
              container: container?.data,
            },
            _.isUndefined
          )}
        />
        {
          renderElse(
            container_disabled && owner_disabled,
            <HiPencilSquare
              size={18}
              className="text-color-500 hover:text-color-100 cursor-pointer"
              onClick={() => {
                toggle(true)
              }}
            />
          )
        }
      </div>
      <Modal
        width={620}
        visible={isToggle}
        cancelText={t('close')}
        onCancel={() => {
          toggle(false)
        }}
        title={
          <div className="text-center font-bold text-color-000 uppercase">
            {t('create post setting')}
          </div>
        }
        destroyOnClose={true}
        className="custom-modal"
        cancelButtonProps={{
          className: 'button-rounded-md no-border',
        }}
        okButtonProps={{hidden: true}}>
        <div className="flex flex-col gap-4">
          <Pure>
            <FieldsFactory
              formSchema={[
                formSchema.container,
                formSchema.owner,
                formSchema.privacy,
              ]}
            />
          </Pure>
        </div>
      </Modal>
    </React.Fragment>
  )
}

const CreatePostForm = ({initialValues, onCancel = Null}) => {
  const t = useTranslate()

  const history = useHistory()

  const {isSm} = useContext(LayoutContext)

  const {options} = useContext(InputBoardContext)

  const {addPost = Null} = useContext(ObserverContext)

  const {push = Null, toString = Null} = useContext(MentionDataContext)

  const _initialValues = useMemo(() => {
    return {
      containers: options,
      privacy: PostPrivacyTypes.public.value,
      ...(initialValues ?? {}),
    }
  }, [options, initialValues])

  const onSuccess = (__, {response}) => {
    onCancel()
    successNotify(NotificationActionTypes.CREATE, t)
    history.push({
      state: {
        refreshToken: Date.now(),
      },
    })

    //
    // const item = getResponseItem(response, 'data.content')
    // if (item) {
    //   addPost(item)
    //   willGoTo({
    //     item,
    //     okText: t('go to'),
    //     title: (
    //       <div className="flex items-center gap-2 font-semibold text-green-700">
    //         <IoCheckmarkCircleOutline size={20}/>{' '}
    //         <span>{t('the article was posted')}</span>
    //       </div>
    //     ),
    //     content: (
    //       <div className="text-sm italic text-color-300">
    //         {t('would you like to go to the detailed article page')}
    //       </div>
    //     ),
    //     cancelText: t('close'),
    //     okButtonProps: {
    //       type: 'primary',
    //       className: 'rounded-lg no-shadow no-text-shadow no-border',
    //     },
    //     cancelButtonProps: {
    //       className: 'rounded-lg no-shadow no-text-shadow no-border',
    //     },
    //     onOk: (pathname) =>
    //       history.push({
    //         pathname,
    //       }),
    //   })
    // }

  }

  const {handleAsyncAction: createPost = Null} = useAsyncAction({
    apiInfo: post_create_Api,
    onError: notifyOnError(t),
    onSuccess,
  })

  const onSubmit = ({
                      tags,
                      description,
                      container_id,
                      container_type,
                      ...rest
                    }) => {
    const _tags = _.isArray(tags) ? Array.from(tags) : undefined
    createPost(
      {
        ...rest,
        tags: _tags ? _tags.join(',') : undefined,
        description: toString(description),
      },
      {id: container_id, prop: container_type}
    )
  }

  const initialErrors = {
    description: t('min length 2 characters'),
  }

  const validate = (values) => {
    let errors = {}

    const {description} = values ?? {}

    _.forEach(['owner', 'container'], (name) => {
      const value = _.get(values, name)
      if (_.isEmpty(value ?? undefined)) {
        errors[name] = t('required field')
      }
    })

    if (String(description ?? '').trim().length < 2) {
      errors.description = _.get(initialErrors, 'description')
    }

    return errors
  }

  return (
    <Formik
      onSubmit={(values) => {
        const {
          owner,
          container,
          containers,
          default_owner_id,
          default_container_id,
          isLoading,
          ...rest
        } = values ?? {}
        onSubmit(
          _.omitBy(
            {
              ...rest,
              owner_id: getId(owner?.data),
              owner_type: getType(owner?.data),
              container_id: getId(container?.data),
              container_type: getType(container?.data),
            },
            _.isUndefined
          )
        )
      }}
      validate={validate}
      enableReinitialize={true}
      initialErrors={initialErrors}
      initialValues={_initialValues}>
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between md:justify-center gap-2">
          {renderIf(
            isSm,
            <Button
              onClick={onCancel}
              // size="small"
              className="button-rounded-md no-border text-sm text-color-300 italic">
              {t('close')}
            </Button>
          )}
          <div className="font-bold text-color-000 uppercase leading-tight">
            {t('posts')}
          </div>
          {renderIf(
            isSm,
            <FormikConsumer>
              {({isValid, handleSubmit = Null}) => (
                <Button
                  // size="small"
                  type="primary"
                  onClick={() => handleSubmit()}
                  disabled={!isValid}
                  className="button-rounded-md no-border text-sm px-3">
                  {t('publish a post')}
                </Button>
              )}
            </FormikConsumer>
          )}
        </div>
        <div className="flex flex-col gap-3 md:gap-6 p-0 md:p-3 m-0 md:mt-6">
          <PostSettingPanel/>
          <FormikConsumer>
            {({values, handleChange}) => {
              const {description} = values ?? {}
              return (
                <MegaTextarea
                  rows={4}
                  value={description}
                  name="description"
                  onChange={handleChange}
                  placeholder={t(
                    'Tap here to write content. \nType @ to mention'
                  )}
                  style={{outline: 'none !important', resize: 'none'}}
                  fieldNames={{
                    embed_data: 'embed_data',
                    description: 'description',
                  }}
                  onMentioned={(mention) => {
                    push(mention)
                  }}
                />
              )
            }}
          </FormikConsumer>
        </div>
        {renderElse(
          isSm,
          <div className="flex justify-end gap-4 px-0 md:px-3">
            <Button
              onClick={onCancel}
              className="button-rounded-md no-border">
              {t('close')}
            </Button>
            <FormikConsumer>
              {({isValid, handleSubmit = Null}) => (
                <Button
                  type="primary"
                  onClick={() => handleSubmit()}
                  disabled={!isValid}
                  className="button-rounded-md no-border">
                  {t('publish a post')}
                </Button>
              )}
            </FormikConsumer>
          </div>
        )}
      </div>
    </Formik>
  )
}

export default nest(MentionDataProvider, CreatePostForm)
