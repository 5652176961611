import {getId} from 'apis/model/base'
import _ from 'lodash'
import React, {useCallback, useState} from 'react'
import {OrganizationContext} from 'views/Organization/OrganizationContext'
import OrganizationProvider from 'views/Organization/OrganizationProvider'
import {LoadingWrapper} from 'views/Shared'
import {gPaths} from '../configs'

const OrganizationContainerContext = React.createContext({
  item: {},
})

export const OrganizationContainerProvider = ({
                                                item,
                                                children,
                                                routeEntities = [],
                                                routePathField = 'path',
                                                ...props
                                              }) => {
  const id = getId(item) || item

  const [route_data, set_route_data] = useState({})

  const defaultRoute = _.first(routeEntities)

  const getDefaultAction = (current) => {
    switch (current) {
      case gPaths.organization:
      case gPaths.organization_domain:
        return _.get(defaultRoute, routePathField)
      case gPaths.organizationSettings_domain:
        return 'settings'
      default:
        return null
    }
  }

  const isValidRoute = useCallback(
    (route) => {
      return routeEntities
        .map((item) => _.get(item, routePathField))
        .includes(route)
    },
    [routeEntities, routePathField]
  )

  const renderHeader = useCallback((action, isModal) => null, [])

  return (
    <LoadingWrapper isLoading={!item}>
      <OrganizationProvider
        key={id}
        id={id}>
        <OrganizationContext.Consumer>
          {({isSucceed, isLoading, organization}) => (
            <OrganizationContainerContext.Provider
              key={id}
              value={{
                isSucceed,
                isLoading,
                organization,
                renderHeader,
                defaultRoute,
                routeEntities,
                routePathField,
                isValidRoute,
                getDefaultAction,
                route_data,
                set_route_data: (values) => {
                  set_route_data({
                    ...(route_data ?? {}),
                    ...(values ?? {}),
                  })
                },
                ...props,
              }}>
              {children}
            </OrganizationContainerContext.Provider>
          )}
        </OrganizationContext.Consumer>
      </OrganizationProvider>
    </LoadingWrapper>
  )
}

export default OrganizationContainerContext
