import {Alert, Button, Form, Input, InputNumber, Select} from 'antd'
import moment from 'moment'
import React from 'react'
import useTranslate from "../../../../../../modules/local/useTranslate";
import {ProductPriceSetChart} from "../ProductPriceSetChart";
import _ from "lodash";
import {formatterNumber} from "../../../../../../helpers/formatterNumber";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {getAsynCacheSelector} from "../../../../../../modules/asyncCache/selectors";
import {parseNumber} from "../../../../../../helpers/parseNumber";

export const PriceDetailForwardPriceEditForm = ({
                                                  effectMomentDate,
                                                  product,
                                                  productPriceSet,
                                                  location_type,
                                                  location_id,
                                                  unit_id,
                                                  price_unit_id,
                                                  unit2_id,
                                                  price_unit2_id,
                                                  productPriceGroup,
                                                  productPriceDetail,
                                                  tableRecord,
                                                  chartAddedData, setChartAddedData,
                                                  toggle,
                                                  isToggle,
                                                  update,
                                                  form,
                                                  handleOnEditDetailSubmit
                                                }) => {

  const t = useTranslate();
  // const [form] = Form.useForm();


  const {
    result: price_periods = []
  } = useSelector(
    state => {
      return getAsynCacheSelector(
        state,
        "/products/get-price-periods?day=1"
      )
    }
  );

  return (
    <div className="w-full pt-6">

      <Form className="space-y-3"
            form={form}
            onFinish={(values) => {
              handleOnEditDetailSubmit();
              toggle();
            }}
            {...{
              labelCol: {span: 12},
              wrapperCol: {span: 12},
            }} >
        {
          (values) => {

            return (
              <React.Fragment>

                {
                  moment(productPriceGroup.eff_at).diff(effectMomentDate, 'days')
                    ? (
                      <Alert type={"error"}
                             message={
                               t('Note: The effective date of the price being updated is different from the price entry date')
                             }
                      />
                    )
                    : null
                }
                <div className="flex flex-col space-y-1 py-6">

                  {
                    productPriceSet.contract_type_cd !== "spot" && (
                      <div className="grid grid-cols-3 gap-3 items-center">
                        <div
                          className="col-span-2 text-color text-base text-right">
                          {
                            t('period')
                          }
                        </div>
                        <div className="font-bold">
                          {
                            moment(new Date(productPriceDetail.year, productPriceDetail.month, 1)).format("MM/YYYY")
                          }
                        </div>
                      </div>
                    )
                  }

                  <div className="grid grid-cols-3 gap-3 items-center">
                    <div
                      className="col-span-2 text-color text-base text-right">
                      {
                        t('price is valid from the date')
                      }
                    </div>
                    <div className={classNames("font-bold",
                      moment(productPriceGroup.eff_at).diff(effectMomentDate, 'days')
                        ? "text-green-500" : "text-color"
                    )}>
                      {
                        moment(productPriceGroup.eff_at).format("DD/MM/YYYY")
                      }
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-3 items-center">
                    <div
                      className="col-span-2 text-color text-base text-right">
                      {
                        t('updating date')
                      }
                    </div>
                    <div className={classNames("font-bold",
                      moment(productPriceGroup.eff_at).diff(effectMomentDate, 'days')
                        ? "text-red-500" : "text-color"
                    )}>
                      {
                        effectMomentDate.format("DD/MM/YYYY")
                      }
                    </div>
                  </div>

                </div>

                <Form.Item
                  label={
                    <div className="flex">
                      <div>{t('flat price')}</div>
                      <div className="px-1 text-sm font-light">
                        ({
                        _.get(productPriceSet, 'price_unit.name')
                      }/
                        {
                          _.get(productPriceSet, 'unit.name')
                        })
                      </div>
                    </div>
                  }
                  className="flex-1"
                  name={["data", String(tableRecord.name), "price"]}
                >
                  <InputNumber
                    step="0.01"
                    // defaultValue={productPriceDetail.price}
                    name={["data", String(tableRecord.name), "price"]}
                    style={{width: '100%'}}
                    formatter={formatterNumber}
                    parser={parseNumber}
                  />
                </Form.Item>

                {
                  (productPriceSet.unit2 && productPriceSet.price_unit2) && (
                    <Form.Item
                      label={
                        <div className="flex">
                          <div>{t('basis price')}</div>
                          <div className="px-1 text-sm font-light">
                            ({
                            _.get(productPriceSet, 'price_unit2.name')
                          }/
                            {
                              _.get(productPriceSet, 'unit2.name')
                            })
                          </div>
                        </div>
                      }
                      className="flex-1"
                      name={["data", String(tableRecord.name), "price2"]}
                    >
                      <Input.Group style={{display: 'flex'}} compact>

                        <InputNumber
                          step={0.01}
                          name={["data", String(tableRecord.name), "price2"]}
                          style={{width: '100%'}}
                          formatter={formatterNumber}
                          parser={parseNumber}
                          defaultValue={productPriceDetail.price2}
                          onChange={(value) => {
                            const _fields = form.getFieldsValue();
                            const {data} = _fields;

                            Object.assign(data[tableRecord.name], {
                              price2: value
                            })

                            form.setFieldsValue({
                              data
                            })
                          }}
                        />

                        <Select className="flex-1"
                                showSearch
                                style={{width: 'auto'}}
                                name={["data", String(tableRecord.name), "period_code"]}
                                placeholder={t(
                                  'period'
                                )}
                                defaultValue={productPriceDetail.period_code}
                                options={price_periods.map(e => {
                                  return {
                                    value: e.period_code,
                                    label: e.period_code
                                  }
                                })}
                                onChange={(value) => {
                                  const _fields = form.getFieldsValue();
                                  const {data} = _fields;

                                  Object.assign(data[tableRecord.name], {
                                    period_code: value
                                  })

                                  form.setFieldsValue({
                                    data
                                  })
                                }}
                        />
                      </Input.Group>
                    </Form.Item>
                  )
                }


                <div className="flex w-full py-3 space-x-3 justify-end">
                  <Button onClick={() => {
                    setChartAddedData({
                      additionalData: [moment(productPriceGroup.eff_at).utc().format(),
                        productPriceDetail.price, productPriceDetail.year, productPriceDetail.month],
                      additionalData2: [moment(productPriceGroup.eff_at).utc().format(),
                        productPriceDetail.price2, productPriceDetail.year, productPriceDetail.month]
                    })
                  }} className="" type="dashed">
                    {t('refresh chart')}
                  </Button>
                  <Button className="float-right" type="primary" htmlType="submit">
                    {t('save')}
                  </Button>
                </div>

              </React.Fragment>
            )
          }
        }
      </Form>

      <div key={chartAddedData}
           className="flex flex-col space-y-3 p-3 border rounded-lg">
        <div className="font-medium text-xs">
          {
            t('charts')
          }
        </div>
        {
          !_.isEmpty(_.get(chartAddedData, 'additionalData', [])) && (
            <ProductPriceSetChart
              product={product}
              product_id={product.id}
              {...productPriceSet}
              location_type={location_type}
              location_id={location_id}
              unit_id={unit_id}
              price_unit_id={price_unit_id}
              unit2_id={unit2_id}
              price_unit2_id={price_unit2_id}
              eventId={update}
              additionalData={chartAddedData.additionalData}
              additionalData2={chartAddedData.additionalData2}
            />
          )
        }
      </div>
    </div>
  )
}
