import {
  host_add_api,
  host_approve_api,
  host_delete_api,
  host_leaveHost_api,
  host_reject_api,
  host_resendAddHost_api,
} from 'apis'
import {getId, getType} from 'apis/model/base'
import {showConfirm} from 'components/Feed/TimeLineFeed'
import _ from 'lodash'
import useAsyncAction from 'modules/asyncCache/useAsyncAction'
import useTranslate from 'modules/local/useTranslate'
import {useHistory} from 'modules/navigation/useRouter'
import React, {useMemo} from 'react'
import {useLocation} from 'react-router-dom'
import {useNewPost} from 'views/Server/ServerContainer'
import {Null} from 'views/Shared'
import {notifyOnError} from '../factory/createErrorEvent'
import {errorNotify, NotificationActionTypes, successNotify,} from '../factory/createNotification'
import {bindQueryParams} from '../functions/routerHelper'

export const HostActionTypes = Object.freeze({
  ADD: 'add',
  APPROVE: 'approve',
  DECLINE: 'decline',
  RESEND: 'resend',
  CANCEL: 'cancel',
  LEAVE: 'leave',
})

const HostContext = React.createContext({
  item: {},
})

export const useHostActions = ({
                                 container,
                                 onLeft = Null,
                                 callback = Null,
                                 onResent = Null,
                                 onCreated = Null,
                                 onApproved = Null,
                                 onDelinced = Null,
                                 onCancelled = Null,
                               }) => {
  const t = useTranslate()
  const query = useMemo(() => {
    if (_.isEmpty(container)) return undefined
    const [id, prop] = [getId(container), getType(container)]
    return bindQueryParams([
      {
        id,
      },
      {prop},
    ])
  }, [container])

  const onError = notifyOnError(t)

  const onSuccess = (__, ___) => {
    successNotify(NotificationActionTypes.INFORMATION, t, {
      message: 'action completed',
      description: null,
    })
    callback()
  }

  const {handleAsyncAction: onApprove} = useAsyncAction({
    onError,
    onSuccess: (result, data) => {
      onApproved(result, data)
      onSuccess(result, data)
    },
    apiInfo: host_approve_api,
  })

  const {handleAsyncAction: onDelince} = useAsyncAction({
    onError,
    onSuccess: (result, data) => {
      onDelinced(result, data)
      onSuccess(result, data)
    },
    apiInfo: host_reject_api,
  })

  const {handleAsyncAction: onCreate} = useAsyncAction({
    query,
    onError,
    onSuccess: (result, data) => {
      onCreated(result, data)
      onSuccess(result, data)
    },
    apiInfo: host_add_api,
  })

  const {handleAsyncAction: onDelete} = useAsyncAction({
    onError,
    onSuccess: (result, data) => {
      onCancelled(result, data)
      onSuccess(result, data)
    },
    apiInfo: host_delete_api,
  })

  const {handleAsyncAction: onLeave} = useAsyncAction({
    onError,
    onSuccess: (result, data) => {
      onLeft(result, data)
      onSuccess(result, data)
    },
    apiInfo: host_leaveHost_api,
  })

  const {handleAsyncAction: onResend} = useAsyncAction({
    onError,
    onSuccess: (result, data) => {
      onResent(result, data)
      onSuccess(result, data)
    },
    apiInfo: host_resendAddHost_api,
  })

  return {
    onCreate: (values) => {
      onCreate({...values})
    },
    onLeave: ({id, prop, host_id}) => {
      showConfirm({
        title: t('do you want to leave the position of host of this campaign'),
        okText: t('sure'),
        cancelText: t('no'),
        okButtonProps: {
          danger: true,
          type: 'primary',
          className: 'rounded-lg no-shadow no-text-shadow',
        },
        cancelButtonProps: {
          className: 'rounded-lg no-shadow no-text-shadow',
        },
        onOk: () => {
          onLeave({}, {id, prop, host_id})
        },
      })
    },
    onCancel: ({id, prop, host_id}) => {
      showConfirm({
        title: t('do you want to delete the invitation'),
        okText: t('sure'),
        cancelText: t('no'),
        okButtonProps: {
          danger: true,
          type: 'primary',
          className: 'button-rounded-lg no-border',
        },
        cancelButtonProps: {
          className: 'button-rounded-lg no-border',
        },
        onOk: () => {
          onDelete({}, {id, prop, host_id})
        },
      })
    },
    onResend: ({id, prop, host_id}) => {
      onResend({}, {id, prop, host_id})
    },
    onDelince: ({host_id}) => {
      onDelince({}, {host_id})
    },
    onApprove: ({host_id}) => {
      onApprove({}, {host_id})
    },
  }
}

export const HostProvider = ({item, container, children, autoRefreshKey}) => {
  const t = useTranslate()

  const location = useLocation()

  const history = useHistory()

  const {newPosts, addNewPost = Null} = useNewPost()

  const onCreated = (result, __) => {
    if (newPosts.includes(result)) return
    if (result) {
      addNewPost(result)
    }
  }

  const {
    onCreate = Null,
    onDelince = Null,
    onApprove = Null,
    onResend = Null,
    onCancel = Null,
    onLeave = Null,
  } = useHostActions({
    container,
    onCreated,
    callback: () => {
      if (autoRefreshKey) {
        history.push({
          ...location,
          state: {
            ...(location.state || {}),
            [autoRefreshKey]: Date.now(),
          },
        })
      }
    },
  })

  const accessDenied = (title = 'access denied') =>
    errorNotify(t, {
      message: title,
      description: null,
    })

  const handleAction = (action, payload) => {
    switch (action) {
      case HostActionTypes.ADD:
        onCreate(payload)
        break
      case HostActionTypes.APPROVE:
        onApprove(payload)
        break
      case HostActionTypes.DECLINE:
        onDelince(payload)
        break
      case HostActionTypes.LEAVE:
        onLeave(payload)
        break
      case HostActionTypes.RESEND:
        onResend(payload)
        break
      case HostActionTypes.CANCEL:
        onCancel(payload)
        break
      default:
        break
    }
  }

  return (
    <HostContext.Provider
      value={{
        item,
        container,
        addNewPost,
        accessDenied,
        handleAction,
        newPosts: Array.from(newPosts || []).filter(
          (item) => !_.get(item, 'deleted', false)
        ),
      }}>
      {children}
    </HostContext.Provider>
  )
}

export default HostContext
