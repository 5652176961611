import { organization_getEventsByHost_Api } from 'apis'
import { getId, getType } from 'apis/model/base'
import _ from 'lodash'
import useTranslate from 'modules/local/useTranslate'
import { useHistory } from 'modules/navigation/useRouter'
import React, {useContext, useMemo} from 'react'
import { useLocation, useSearchParam } from 'react-use'
import {
  branch,
  compose,
  fromRenderProps,
  pure,
  renderNothing,
} from 'recompose'
import EventItem from 'views/AppLocals/Event/components/EventItem'
import EventEntity from 'views/AppLocals/Event/EventEntity'
import { EventsSortBy } from 'views/AppLocals/factory/createEntitySortBy'
import { bindQueryParam } from 'views/AppLocals/functions/routerHelper'
import usePaginationHelper from 'views/AppLocals/hooks/usePaginationHelper'
import ListHeader from 'views/AppLocals/Templates/items/ListHeader'
import { Null } from 'views/Shared'
import { RelatedEventsAdvancedFilter } from './RelatedAdvancedFilter'
import WorkspaceContext from "../WorkspaceContext";

const HostRelatedEvents = ({
  owner_id,
  owner_type,
  Wrapper = 'div',
  filter_actions = {
    sortBy: 'sort-by',
    keyword: 'keyword',
    status: 'status',
    organization_id: 'organizaion-id',
  },
}) => {
  const t = useTranslate()

  const location = useLocation()

  const history = useHistory()

  const status = useSearchParam(filter_actions.status)

  const sortBy = useSearchParam(filter_actions.sortBy)

  const filter_organization_id = useSearchParam(filter_actions.organization_id)

  const refresh_deps = useMemo(
    () => [owner_id, `refreshToken=${_.get(location.state, 'refreshToken')}`],
    [owner_id, location.state]
  )

  const [pager_widget] = usePaginationHelper({
    onInit: ({ onSortChange = Null, onFilterChange = Null }) => {
      onFilterChange({
        status,
        organization_id: filter_organization_id,
      })
      onSortChange(sortBy)
    },
    pagerInfo: {
      apiInfo: organization_getEventsByHost_Api,
      query: bindQueryParam({
        organization_id: owner_id,
      }),
      RenderItem: EventItem,
      RenderEntity: EventEntity,
    },
    withKey: ({ sortBy, keyword, filterBy }) =>
      [...refresh_deps, sortBy, keyword, JSON.stringify(filterBy)].join('/'),
    filter_params: {
      owner_id,
      owner_type,
    },
    query_params: filter_actions,
    dependencies: [owner_id],
    sortHeader: t('list of event'),
    SortByWidget: EventsSortBy,
    AdvancedFilter: RelatedEventsAdvancedFilter,
  })

  return (
    <Wrapper className="flex flex-col space-y-3">
      <ListHeader title="related events" />
      {pager_widget}
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(WorkspaceContext.Consumer, ({ organization }) => ({
    owner_id: getId(organization),
    owner_type: getType(organization),
  })),
  branch(({ owner_id }) => _.isEmpty(owner_id), renderNothing),
  pure
)(HostRelatedEvents)
