import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import _ from 'lodash'
import {organizationPermissionCodes, permissionCodes,} from 'modules/permissions/contants'
import PermissionContext from 'modules/permissions/PermissionContext'
import React, {useContext} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {branch, compose, nest} from 'recompose'
import {getLogin} from 'redux/selectors'
import {staticPaths} from 'routes/staticPaths'
import {createCheckPermissions, hasPermissions,} from 'views/AppLocals/factory/createCheckPermissions'
import {ModuleLayoutWrapper} from 'views/AppLocals/factory/createModule'
import DefaultTemplate from 'views/AppLocals/Templates/DefaultTemplate'
import LoadingPage from 'views/LoadingPage'
import {AppHeader} from 'views/MainPage/MainHeader'
import {Null} from 'views/Shared'
import WorkspaceActivities from './Activities/WorkspaceActivities'
import WorkspaceBanner from './components/WorkspaceBanner'
import WorkspaceFeatures from './components/WorkspaceFeatures'
import WorkspaceHome from './HomePage/WorkspaceHome'
import WorkspaceHosts from './Host/WorkspaceHosts'
import WorkspaceOperating from './Operating/WorkspaceOperating'
import WorkspaceSetting from './Settings/WorkspaceSetting'
import WorkspaceMembers from "./Host/WorkspaceMembers";
import WorkspaceContext, {WorkspaceProvider} from "./WorkspaceContext";
import WorkspaceLandingPage from "./WorkspaceLandingPage";
import WorkspaceDataset from "./Dataset/WorkspaceDataset";
import WorkspacePurchases from "./Product/WorkspacePurchases";
import WorkspaceSales from "./Product/WorkspaceSales";

export const WorkSpaceActions = Object.freeze({
  HOME: 'dashboard',
  ACTIVITIES: 'activities',
  EVENT: 'event',
  DATASET: 'dataset',
  SETTING: 'manage',
  SALES: 'sales',
  PURCHASES: 'purchases',
  OPERATING: 'operating',
  STRUCTURES: 'structures',
  MEMBER: 'member',
})

const routes = [
  {
    key: WorkSpaceActions.HOME,
    label: 'overview',
    component: WorkspaceHome,
  },
  {
    key: WorkSpaceActions.ACTIVITIES,
    label: 'activities',
    component: WorkspaceActivities,
  },
  {
    key: WorkSpaceActions.DATASET,
    label: 'dataset',
    component: WorkspaceDataset,
  },
  {
    key: WorkSpaceActions.STRUCTURES,
    label: 'organizational structure',
    component: WorkspaceHosts,
    required_permissions: [
      organizationPermissionCodes.organizationManageStructure,
    ],
  },
  {
    key: WorkSpaceActions.MEMBER,
    label: 'members',
    component: WorkspaceMembers,
    required_permissions: [
      organizationPermissionCodes.organizationManageMember,
    ],
  },
  // {
  //   key: WorkSpaceActions.OPERATING,
  //   label: 'operating',
  //   component: WorkspaceOperating,
  // },
  {
    key: WorkSpaceActions.PURCHASES,
    label: 'purchases',
    component: WorkspacePurchases,
  },
  {
    key: WorkSpaceActions.SALES,
    label: 'sales',
    component: WorkspaceSales,
  },
  {
    key: WorkSpaceActions.SETTING,
    label: 'setting',
    component: WorkspaceSetting,
  },
].map(({key, required_permissions = [], component, ...route}) => ({
  key,
  ...route,
  required_permissions,
  component: required_permissions
    ? createCheckPermissions(required_permissions)(component)
    : component,
}))

const Workspace = () => {
  const {isSm} = useContext(LayoutContext)

  const {organization} = useContext(WorkspaceContext)

  const isReady = _.has(organization, 'available_actions')

  const permissions = useContext(PermissionContext)

  return (
    <ModuleLayoutWrapper
      Header={AppHeader}
      visibleLeftDrawer={true}>
      <DefaultTemplate renderMenu={Null}>
        {isSm && <WorkspaceBanner/>}
        {isReady ? (
          <WorkspaceFeatures
            routes={routes}
            isDisabled={({required_permissions}) =>
              !hasPermissions(permissions, required_permissions)
            }
            defaultAction={WorkSpaceActions.HOME}
          />
        ) : (
          <LoadingPage/>
        )}
      </DefaultTemplate>
    </ModuleLayoutWrapper>
  )
}

export default compose(
  connect((state) => {
    const login = getLogin(state)
    const primary_org = _.get(login, 'primary_org')
    return {
      primary_org,
      [permissionCodes.canEdit]: Boolean(
        !_.isEmpty(_.get(login, 'primary_org'))
      ),
    }
  }),
  branch(
    ({primary_org}) => _.isEmpty(primary_org),
    () => () => <WorkspaceLandingPage/>
  ),
  branch(
    (props) => !_.get(props, permissionCodes.canEdit),
    () => () => <Redirect to={staticPaths.start}/>
  )
)(nest(WorkspaceProvider, Workspace))
