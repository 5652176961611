import * as firebase from 'firebase/app'
import 'firebase/messaging'
const createMessaging = () => {
  const isSupportMessaging = firebase.messaging.isSupported()
  if (!isSupportMessaging) {
    console.error(
      'browser does not support firebase messaging'
    )
    return null
  }
  const initializedFirebaseApp = firebase.initializeApp(
    {
      // Project Settings => Add Firebase to your web app
      apiKey:
        'AIzaSyDMbAdzj6XQemY6KeRow4KoVF8tIEwkKwQ',
      authDomain:
        'feedin-firebase.firebaseapp.com',
      databaseURL:
        'https://feedin-firebase.firebaseio.com',
      projectId: 'feedin-firebase',
      storageBucket:
        'feedin-firebase.appspot.com',
      messagingSenderId: '713485216987',
      appId:
        '1:713485216987:web:438962523ed1e2753db0fe',
      measurementId: 'G-RZCQ4B08JX'
    }
  )

  const messaging = initializedFirebaseApp.messaging()
  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    'BPx7D93S8zFtbfyvMAl8rzvD1FYFT1Ygg8rnJG7QVZa_STAqnH4t6AtWrQJq5GEBCmGP11xxYEK2D8zN3jDwgfM'
  )
  return messaging
}

export { createMessaging }
