import Null from 'components/NullComponent'
import {Formik, FormikConsumer, useFormikContext} from 'formik'
import logParams from 'helpers/logParams'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useDispatchAsyncActionWithNotify from 'modules/asyncCache/useDispatchAsyncActionWithNotify'
import React, {useCallback, useEffect, useState} from 'react'

const OnSuccess = ({data}) => {
  const {success} = data ?? {}
  const {setSubmitting} = useFormikContext()
  useEffect(() => {
    if (success) {
      setSubmitting(false)
    }
  }, [success])
  return null
}

const SetError = ({errors = {}}) => {
  const {
    resetForm,
    values,
    initialValues,
    setSubmitting,
    setErrors,
    initialErrors,
  } = useFormikContext()
  useEffect(() => {
    if (errors) {
      setErrors(errors)
      setSubmitting(false)
    }
  }, [errors, setErrors, setSubmitting])

  return null
}

const ApiInfoForm = ({
  prefixStr,
  onSuccess = Null,
  query,
  apiInfo,
  onPreSubmit,
                       initialValues = {},
  enableReinitialize = true,
  children,
  disableNotify,
  validationSchema,
  validate,
  onReset,
  ...props
}) => {
  const [data = {}, dispatchAsyncAction] = useDispatchAsyncActionWithNotify({
    onSuccess,
    onError: logParams,
    disableNotify,
  })
  const { isLoading, success } = data
  const handleSubmit = useCallback(
    (values) => {
      let _values = values
      if (onPreSubmit) {
        _values = onPreSubmit(values)
      }
      const action = createAsyncAction({
        prefixStr,
        query,
        apiInfo,
        values: _values,
      })
      dispatchAsyncAction(action)
    },
    [apiInfo, dispatchAsyncAction, onPreSubmit, prefixStr, query]
  )
  const [resetNumber, setResetNumber] = useState(Date.now())
  const handleReset = useCallback(
    (...args) => {
      setResetNumber(Date.now())
      if (onReset) {
        onReset(args)
      }
    },
    [onReset]
  )
  return (
    <Formik
      onReset={handleReset}
      validationSchema={validationSchema}
      validate={validate}
      initialErrors={data.errorMessages ? data.errorMessages.error : null}
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true)
        setTimeout(() => {
          handleSubmit && handleSubmit(values)
        })
      }}
      {...props}>
      <React.Fragment>
        <OnSuccess data={data}/>
        <SetError
          errors={data.errorMessages ? data.errorMessages.error : null}
        />
        {_.isFunction(children) ? (
          <FormikConsumer>
            {({isSubmitting, dirty, isValid, ...props}) =>
              children({
                resetNumber,
                isValid: dirty && isValid,
                isSubmitting: isLoading,
                ...props,
                asyncData: data,
                success,
              })
            }
          </FormikConsumer>
        ) : (
          children
        )}
      </React.Fragment>
    </Formik>
  )
}

export default ApiInfoForm
