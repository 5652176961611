import classNames from 'classnames'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'

export const renderDecoratedLabel = (text, params) => {
  const {
    style,
    className,
    isRequired = false,
    withLabel = () => {
    },
  } = params || {}
  return (
    <Translate>
      {(t) => (
        <label
          style={style}
          className={classNames(
            'flex uppercase text-sm font-semibold text-color-300 tracking-wide mb-1',
            className,
            isRequired && 'required-label'
          )}
          {...withLabel({
            className,
            isRequired,
          })}>
          {t(text)}
        </label>
      )}
    </Translate>
  )
}

const FieldDecorator = ({
    className,
    label,
    description,
    errors,
    children,
    isRequired = false,
    hasError = true,
    labelProps,
    renderLabel = renderDecoratedLabel,
}) => {
  const t = useTranslate()
  return (
    <div
      className={classNames(
        'ErrorDescription relative',
        isRequired && 'required-field',
        { errors },
        className
      )}>
      {_.isString(label)
        ? renderLabel(label, {
          isRequired,
          className: 'flex capitalize text-sm font-semibold pb-2 block text-color-100',
          ...(labelProps ?? {}),
        })
        : label}
      {children}
      {_.isString(description) ? (
        <label className="text-xs py-1 block text-color-400 italic">
          {t(description)}
        </label>
      ) : (
        description
      )}
      {hasError && !!_.get(errors, 'message', errors) && (
        <div
          style={{
            minHeight: '1em',
            lineHeight: '1em',
          }}
          className="text-xs text-red-500 mt-1 ml-1">
          {_.get(errors, 'message', errors)}
        </div>
      )}
    </div>
  )
}

export const FormFieldWrapper = (props) => (
  <FieldDecorator
    labelProps={{
      style: {padding: 0},
    }}
    {...props}
  />
)

export default FieldDecorator
