import { Drawer } from 'antd'
import { RegAsModal } from 'components/Modal/Modal'
import ShadowScrollbar from 'components/ShadowScrollbar'
import React from 'react'
import { LayoutContext } from './LayoutContext'
export const RightDrawer = ({
  children
}) => {
  return (
    <LayoutContext.Consumer>
      {({
        setVisibleRightDrawer,
        rightInner,
        visibleRightDrawer
      }) =>
        !!(
          visibleRightDrawer &&
          (children || rightInner)
        ) && (
          <Drawer
            bodyStyle={{
              padding: 0,
              overflow: 'hidden'
            }}
            visible={true}
            width={280}
            placement="left"
            closable={false}
            onClose={() =>
              setVisibleRightDrawer(
                false
              )
            }>
            <RegAsModal />
            <ShadowScrollbar className="absolute top-0 left-0">
              {rightInner}
              {children}
            </ShadowScrollbar>
          </Drawer>
        )
      }
    </LayoutContext.Consumer>
  )
}
