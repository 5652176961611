import {Spin} from 'antd'
import classNames from 'classnames'
import {ObjectSelectField} from 'components/form/fields/ObjectSelectField'
import {createValue} from 'components/form/utils'
import _ from 'lodash'
import React, {useContext, useEffect, useMemo} from 'react'
import {withProps} from 'recompose'
import {Null, renderIf} from 'views/Shared'
import {InputBoardContext} from './InputBoardContext'

const DefaultFieldWrapper = ({className, children}) => (
  <div className={classNames('flex flex-col px-3 py-2 rounded-lg', className)}>
    {children}
  </div>
)

export const OwnerSelect = ({
                              name,
                              value,
                              parent_id,
                              onChange = Null,
                              default_owner_id,
                              renderSelect = (child, params) => child,
                              ...props
                            }) => {
  const {isLoading, getCreators = Null} = useContext(InputBoardContext)

  const options = useMemo(
    () => getCreators(parent_id),
    [parent_id, getCreators]
  )

  const array = Array.from(options ?? [])

  const defaultOwner = useMemo(() => {
    if (array.length === 1) {
      return _.first(array)
    }
    return _.find(array, {
      id: default_owner_id,
    })
  }, [array, default_owner_id])

  useEffect(() => {
    if (defaultOwner) {
      onChange(createValue(name, defaultOwner))
    }
  }, [defaultOwner])

  const disabled = props.disabled || !parent_id || array.length < 2

  const Wrapper = useMemo(
    () =>
      withProps({
        className: classNames(
          disabled
            ? 'background-100 border-color-50'
            : 'background shadow-items-md'
        ),
      })(DefaultFieldWrapper),
    [disabled]
  )

  return renderIf(
    parent_id,
    <Spin spinning={!!isLoading}>
      {renderSelect(
        <ObjectSelectField
          name={name}
          value={value}
          options={array}
          bordered={false}
          Wrapper={Wrapper}
          onChange={onChange}
          onOptionChange={(option) => {
            onChange(createValue(name, option))
          }}
          {...props}
          disabled={disabled}
        />,
        {item: value, options: array}
      )}
    </Spin>
  )
}

export const CreatorSelect = ({
                                name,
                                value,
                                options,
                                isLoading,
                                onChange = Null,
                                renderSelect = (child, params) => child,
                                ...props
                              }) => {
  const array = Array.from(options ?? [])

  const disabled = props.disabled || array.length < 2

  const Wrapper = useMemo(
    () =>
      withProps({
        className: classNames(
          disabled
            ? 'background-100 border-color-50'
            : 'background shadow-items-md'
        ),
      })(DefaultFieldWrapper),
    [disabled]
  )

  return (
    <Spin spinning={!!isLoading}>
      {renderSelect(
        <ObjectSelectField
          name={name}
          value={value}
          options={array}
          bordered={false}
          Wrapper={Wrapper}
          onOptionChange={(option) => {
            onChange(createValue(name, option))
          }}
          {...props}
          disabled={disabled}
        />,
        {item: value, options: array}
      )}
    </Spin>
  )
}
