import {Tabs} from 'antd'
import {host_getPeerMembers_Api, host_getsByHost_api, partners_getPartners_Api,} from 'apis'
import {getId, getType} from 'apis/model/base'
import classNames from 'classnames'
import HeaderItemInfo from 'components/ItemLayout/HeaderItemInfo'
import {LayoutContext} from 'components/layouts/Default/LayoutContext'
import getLinkToDetail from 'helpers/getLinkToDetail'
import _ from 'lodash'
import useAsync from 'modules/asyncCache/useAsync'
import Translate from 'modules/local/Translate'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext, useMemo, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useSearchParam} from 'react-use'
import {compose, fromRenderProps} from 'recompose'
import GMapWidget from 'views/Chart/GMap/GMapWidget'
import {EntityPaginationMeta} from 'views/AppLocals/EntityList'
import {AppEntities} from 'views/AppLocals/enums'
import {HostsSortBy, PartnersSortBy,} from 'views/AppLocals/factory/createEntitySortBy'
import {bindQueryParam, bindQueryParams,} from 'views/AppLocals/functions/routerHelper'
import usePaginationHelper, {createPagerKey,} from 'views/AppLocals/hooks/usePaginationHelper'
import LoadingPage from 'views/LoadingPage'
import {ViewContext, ViewProvider} from 'views/MainPage/ToggleView'
import PartnerFilter from 'views/Organization/Settings/Partners/PartnerFilter'
import {getResponseItems, Null, renderElse, renderOwnChild,} from 'views/Shared'
import {HostRow} from '../../Host/Settings/HostActionRow'
import OrganizationContainerContext from '../OrganizationContainerContext'
import GoogleMapWidget from "../../../Chart/GMap/GoogleMapWidget";

const TabOptions = Object.freeze({
  HOSTS: 'hosts',
  PEER_MEMBERS: 'peer-members',
  PARTNERS: 'partners',
})

const ItemStatistic = ({label, value}) => (
  <Translate>
    {(t) => (
      <div
        className="px-2 py-4 flex flex-col flex-center gap-1 border border-color-50 rounded-lg shadow-items cursor-pointer">
        <span className="text-xl md:text-2xl font-bold text-color-000 hover:text-primary">
          {value.toLocaleString()}
        </span>
        <span className="leading-tight text-sm text-color-400 tracking-wide uppercase">
          {t(label)}
        </span>
      </div>
    )}
  </Translate>
)


const ChartByHost = ({
                       name,
                       query,
                       values,
                       apiInfo,
                       owner_id,
                       owner_type,
                     }) => {
  const t = useTranslate()

  const cacheId = JSON.stringify(
    [
      apiInfo.path,
      owner_id,
      owner_type,
      JSON.stringify({query, values}),
    ].join('/')
  )

  const {response, isLoading = false} = useAsync({
    query,
    values,
    cacheId,
    apiInfo,
  })

  const items = getResponseItems(response)

  if (!!isLoading) {
    return <LoadingPage/>
  }

  return (
    <React.Fragment>
      <GoogleMapWidget items={items}/>
    </React.Fragment>
  )

  return (
    <GMapWidget
      id={name}
      height={400}
      style={{zIndex: 1, borderRadius: '0.5rem'}}
      key={cacheId}
      groupBy={true}
      fieldNames={{
        name: 'title',
      }}
      actions={false}
      fullScreen={true}
      showSearch={false}
      autoPlayDelay={1000}
      data={items.map((item) => ({
        ...item,
        title: item.name,
      }))}
      settings={{
        dragging: true,
        zoomControl: true,
        doubleClickZoom: false,
        scrollWheelZoom: false,
      }}
      renderMarker={(item) => {
        const {
          title,
          country_code,
          country_name,
          province_name,
          district_name,
          ward_name,
          address,
        } = item
        const location = [
          address,
          ward_name,
          district_name,
          province_name,
        ].filter((value) => value && !_.isEmpty(value))
        return (
          <div className="flex flex-col">
            <div className="text-base font-semibold">{title}</div>
            <div>
              {`${t('country')}: `}
              <span className="font-semibold">{country_name}</span>
            </div>
            {renderElse(
              _.isEmpty(location),
              <div>
                {`${t('address')}: `}
                <span className="font-semibold">{location.join(', ')}</span>
              </div>
            )}
          </div>
        )
      }}
    />
  )
}

const renderGridMeta = (action) => (props) =>
  (
    <LayoutContext.Consumer>
      {({isLg}) => (
        <div className={classNames(isLg ? 'col-span-2' : 'col-span-1')}>
          <EntityPaginationMeta
            {...props}
            style={{position: 'inherit'}}
            action={<div className="flex flex-1 justify-end">{action}</div>}
          />
        </div>
      )}
    </LayoutContext.Consumer>
  )

const renderPagerWrap = ({view_type, grid = true}) =>
  grid
    ? (child, __, count) =>
      Number(count) > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-3">
          {child}
        </div>
      ) : (
        child
      )
    : undefined

const OrganizationHostList = ({
    owner_id,
    owner_type,
    Wrapper = 'div',
    values = {
    status: 1,
    owner_type: AppEntities.ORGANIZATION,
    },
    filter_actions = {
        sortBy: 'sort-by',
        keyword: 'keyword',
    },
}) => {
  const t = useTranslate()
  const location = useLocation()

  const {view, renderItem, switchComponent} = useContext(ViewContext)

  const sortBy = useSearchParam(filter_actions.sortBy)


  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(location.state, 'refreshToken')}`,
    ],
    [owner_id, owner_type, location.state]
  )

  const [pager_widget] = usePaginationHelper({
    onInit: ({onSortChange = Null, onFilterChange = Null}) => {
      onSortChange(sortBy)
      onFilterChange({})
    },
    renderHeader: ([searchBar, filterBar]) => (
      <React.Fragment>
        {searchBar}
        {filterBar}
      </React.Fragment>
    ),
    filter_params: {
      owner_id,
      owner_type,
    },
    pagerInfo: {
      apiInfo: host_getsByHost_api,
      query: bindQueryParams([{id: owner_id}, {prop: owner_type}]),
      renderItem,
      RenderEntity: React.Fragment,
      PaginationMeta: renderGridMeta(switchComponent),
      renderWrap: renderPagerWrap({
        view_type: view,
      }),
    },
    withValues: ({...rest}) => ({
      ...rest,
      ...values,
      status: 1,
    }),
    withKey: createPagerKey(refresh_deps),
    sortHeader: t('list of co-owners'),
    query_params: filter_actions,
    SortByWidget: HostsSortBy,
    renderFilter: Null,
    AdvancedFilter: PartnerFilter,
    isToggleDefault: true,
    AdvancedToggle: Null,
  })

  return (
    <Wrapper className="flex flex-col space-y-3">
      {/*<Pure input={[owner_id, owner_type]}>*/}
      {/*  <ChartByHost*/}
      {/*    name="location-by-host"*/}
      {/*    query={bindQueryParams([{id: owner_id}, {prop: owner_type}])}*/}
      {/*    apiInfo={{*/}
      {/*      root: API_ROOT_URL,*/}
      {/*      path: '/hosts/:prop/:id/gets-location-by-host',*/}
      {/*      method: 'GET',*/}
      {/*    }}*/}
      {/*    {...{owner_id, owner_type}}*/}
      {/*  />*/}
      {/*</Pure>*/}
      {pager_widget}
      <div className="h-10"/>
    </Wrapper>
  )
}

const OrganizationGetPartners = ({
                                   owner_id,
                                   owner_type,
                                   Wrapper = 'div',
                                   filter_actions = {
                                     sortBy: 'sort-by',
                                     keyword: 'keyword',
                                   },
                                 }) => {
  const t = useTranslate()

  const location = useLocation()

  const {view, renderItem, switchComponent} = useContext(ViewContext)

  const sortBy = useSearchParam(filter_actions.sortBy)

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(location.state, 'refreshToken')}`,
    ],
    [owner_id, owner_type, location.state]
  )

  const [pager_widget] = usePaginationHelper({
    onInit: ({onSortChange = Null, onFilterChange = Null}) => {
      onSortChange(sortBy)
      onFilterChange({})
    },
    renderHeader: ([searchBar, filterBar]) => (
      <React.Fragment>
        {searchBar}
        {filterBar}
      </React.Fragment>
    ),
    filter_params: {
      owner_id,
      owner_type,
    },
    pagerInfo: {
      apiInfo: partners_getPartners_Api,
      query: bindQueryParam({
        id: owner_id,
      }),
      renderItem,
      RenderEntity: React.Fragment,
      PaginationMeta: renderGridMeta(switchComponent),
      renderWrap: renderPagerWrap({
        view_type: view,
      }),
    },
    withKey: createPagerKey(refresh_deps),
    withValues: ({...rest}) => ({
      ...rest,
      status: 1,
    }),
    query_params: filter_actions,
    sortHeader: t('list of partners'),
    SortByWidget: PartnersSortBy,
    renderFilter: Null,
    AdvancedFilter: PartnerFilter,
    isToggleDefault: true,
    AdvancedToggle: Null,
  })

  return (
    <Wrapper className="flex flex-col space-y-3">
      {/*<Pure input={[owner_id, owner_type]}>*/}
      {/*  <ChartByHost*/}
      {/*    name="location-partner"*/}
      {/*    query={bindQueryParams([{ id: owner_id }, { prop: owner_type }])}*/}
      {/*    apiInfo={{*/}
      {/*      root: API_ROOT_URL,*/}
      {/*      path: '/organizations/:id/partners/gets-location-partner',*/}
      {/*      method: 'GET',*/}
      {/*    }}*/}
      {/*    {...{ owner_id, owner_type }}*/}
      {/*  />*/}
      {/*</Pure>*/}
      {pager_widget}
      <div className="h-10"/>
    </Wrapper>
  )
}

const OrganizationGetPeerMembers = ({
                                      owner_id,
                                      owner_type,
                                      Wrapper = 'div',
                                      filter_actions = {
                                        sortBy: 'sort-by',
                                        keyword: 'keyword',
                                      },
                                    }) => {
  const t = useTranslate()

  const location = useLocation()

  const {view, renderItem, switchComponent} = useContext(ViewContext)

  const sortBy = useSearchParam(filter_actions.sortBy)

  const refresh_deps = useMemo(
    () => [
      owner_id,
      owner_type,
      `refreshToken=${_.get(location.state, 'refreshToken')}`,
    ],
    [owner_id, owner_type, location.state]
  )

  const [pager_widget] = usePaginationHelper({
    onInit: ({onSortChange = Null, onFilterChange = Null}) => {
      onSortChange(sortBy)
      onFilterChange({})
    },
    renderHeader: ([searchBar, filterBar]) => (
      <React.Fragment>
        {searchBar}
        {filterBar}
      </React.Fragment>
    ),
    filter_params: {
      owner_id,
      owner_type,
    },
    pagerInfo: {
      apiInfo: host_getPeerMembers_Api,
      query: bindQueryParams([{id: owner_id}, {prop: owner_type}]),
      renderItem,
      RenderEntity: React.Fragment,
      PaginationMeta: renderGridMeta(switchComponent),
      renderWrap: renderPagerWrap({
        view_type: view,
      }),
    },
    withKey: createPagerKey(refresh_deps),
    withValues: ({...rest}) => ({
      ...rest,
      status: 1,
    }),
    query_params: filter_actions,
    sortHeader: t('peer organization list'),
    SortByWidget: HostsSortBy,
    renderFilter: Null,
    AdvancedFilter: PartnerFilter,
    isToggleDefault: true,
    AdvancedToggle: Null,
  })

  return (
    <Wrapper className="flex flex-col space-y-3">
      {/*<Pure input={[owner_id, owner_type]}>*/}
      {/*  <ChartByHost*/}
      {/*    name="location-peer-members"*/}
      {/*    query={bindQueryParams([{ id: owner_id }, { prop: owner_type }])}*/}
      {/*    apiInfo={{*/}
      {/*      root: API_ROOT_URL,*/}
      {/*      path: '/hosts/:prop/:id/gets-location-peer-members',*/}
      {/*      method: 'GET',*/}
      {/*    }}*/}
      {/*    {...{ owner_id, owner_type }}*/}
      {/*  />*/}
      {/*</Pure>*/}
      {pager_widget}
      <div className="h-10"/>
    </Wrapper>
  )
}

const renderHost =
  ({...props}) =>
    (item) =>
      (
        <HostRow
          {...props}
          item={item}
        />
      )

const Widget = ({owner_id, owner_type, Wrapper = renderOwnChild}) => {
  const t = useTranslate()
  const {isSm} = useContext(LayoutContext)

  const [current, setCurrent] = useState(TabOptions.HOSTS)

  const {organization} = useContext(OrganizationContainerContext)

  const tabs = useMemo(
    () =>
      [
        {
          type: TabOptions.HOSTS,
          title: 'list of co-owners',
          component: OrganizationHostList,
          params: {
            renderHeadlineView: renderHost({
              type: 'owner',
              showLabel: false,
              showInfo: false,
            }),
            renderListView: renderHost({
              type: 'owner',
              showLabel: false,
            }),
          },
        },
        {
          type: TabOptions.PEER_MEMBERS,
          title: 'peer organization',
          component: OrganizationGetPeerMembers,
          params: {
            renderHeadlineView: renderHost({
              type: 'owner',
              showLabel: false,
              showInfo: false,
            }),
            renderListView: renderHost({
              type: 'owner',
              showLabel: false,
            }),
          },
        },
        // {
        //   type: TabOptions.PARTNERS,
        //   title: 'list of partners',
        //   component: OrganizationGetPartners,
        //   params: {
        //     renderHeadlineView: renderPartner({
        //       showInfo: false,
        //     }),
        //     renderListView: renderPartner(),
        //   },
        // },
      ].map(({type, title, params, component: Component}) => (
        <Tabs.TabPane
          key={type}
          tab={<span>{t(title)}</span>}>
          <ViewProvider
            className="px-2 py-1 border rounded-md"
            {...(params || {})}>
            <Component
              {...{
                owner_id,
                owner_type,
              }}
            />
          </ViewProvider>
        </Tabs.TabPane>
      )),
    [current, owner_id, owner_type]
  )

  return (
    <Wrapper>
      {[
        {
          id: 'insights',
          title: 'insights',
          className: 'border-2',
          containerClassName: 'grid grid-cols-2 gap-3',
          linkTo: getLinkToDetail(organization) + '/hosts',
          children: [
            {
              label: 'total org members',
              value: _.get(organization, 'total_org_members', 0),
            },
            {
              label: 'total peer organization',
              value: _.get(organization, 'total_org_peer_members', 0),
            },
            // {
            //   label: 'total org partners',
            //   value: _.get(organization, 'total_org_partners', 0),
            // },
          ],
        },
      ]
        .filter((e) => !!!e.hidden)
        .map((e, i) => {
          return !!isSm ? (
            <HeaderItemInfo
              key={i}
              title={e.title}
              className="flex flex-col gap-2">
              <Link
                to={e.linkTo}
                className={classNames(
                  'px-4 py-3 flex flex-col gap-2 hover:border-primary rounded-lg shadow-items cursor-pointer h-full no-underline',
                  e.className
                )}>
                {_.get(e, 'children', []).map((ee, ii) => (
                  <div
                    key={ii}
                    className="flex items-center text-base md:text-lg gap-3">
                    <span className="font-bold text-color-000 hover:text-primary">
                      {ee.value.toLocaleString()}
                    </span>
                    <span className="text-color-400 tracking-wide uppercase hover:text-primary">
                      {t(ee.label)}
                    </span>
                  </div>
                ))}
              </Link>
            </HeaderItemInfo>
          ) : (
            <HeaderItemInfo
              key={i}
              title={e.title}
              className="flex flex-col gap-2">
              <div className={e.containerClassName}>
                {_.get(e, 'children', []).map((ee, ii) => (
                  <ItemStatistic
                    key={ii}
                    className={e.className}
                    label={ee.label}
                    value={ee.value}
                    to={e.linkTo}
                  />
                ))}
              </div>
            </HeaderItemInfo>
          )
        })}

      <Tabs
        defaultActiveKey={current}
        onChange={(activeKey) => {
          setCurrent(activeKey)
        }}>
        {tabs}
      </Tabs>
    </Wrapper>
  )
}

export default compose(
  fromRenderProps(
    OrganizationContainerContext.Consumer,
    ({organization}) => ({
      owner_id: getId(organization),
      owner_type: getType(organization),
    })
  )
)(Widget)
