import {getId} from 'apis/model/base'
import _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'
import React from 'react'
import Translate from '../../modules/local/Translate'
import {Null, renderSelf} from './typedefs'

const getLine = (input, fields = [], get = _.get) => {
  return fields
    .map((name) => get(input, name, null))
    .filter((el) => !!el)
    .join(' ')
}

const getLocationLines = (item, position = 0) => {
  const location = _.get(item, `locations.${position}`, {})

  if (_.isEmpty(location)) {
    return ''
  }

  return [
    getLine(location, ['address']),
    getLine(location, ['ward.type', 'ward.name']),
    getLine(location, ['district.type', 'district.name']),
    getLine(location, ['province.type', 'province.name']),
    getLine(location, ['country.common_name']),
  ]
    .filter((el) => !!el)
    .join(', ')
}

const getFullAddress = (item, {Wrapper = 'span'} = {}) => {
  if (
    _.isEmpty(_.get(item, 'address')) &&
    _.isEmpty(_.get(item, 'ward.location_name')) &&
    _.isEmpty(_.get(item, 'district.location_name')) &&
    _.isEmpty(_.get(item, 'province.location_name'))
  ) {
    return null
  }

  return (
    <Wrapper className="space-x-1">
      {[
        _.get(item, 'address'),
        _.get(item, 'ward.location_name'),
        _.get(item, 'district.location_name'),
        _.get(item, 'province.location_name'),
        _.get(item, 'country.common_name'),
      ]
        .filter((e) => !!e)
        .join(', ')}
    </Wrapper>
  )
}

const sortById = (collection = [], getFn = getId) => _.sortBy(collection, getFn)

const getItemMoment = (item, name, defaultValue = moment()) => {
  const value = _.get(item, name)
  return !!value ? moment(value) : defaultValue
}

const deepTranslate =
  (
    t = Null,
    [fields, params] = [
      {
        label: 'label',
        children: 'children',
      },
      {},
    ]
  ) =>
    (item) => {
      const translateFn = (item) => {
        const target = _.get(item, fields.label)
        const label = _.isString(target) ? t(target) : target
        const children = _.get(item, fields.children, [])
        if (_.isEmpty(children)) {
          return {...item, ...params, [fields.label]: label}
        } else {
          return {
            ...item,
            ...params,
            [fields.label]: label,
            [fields.children]: children.map(translateFn),
          }
        }
      }
      return translateFn(item)
    }

const searchQueryParam = (search, name, arrayFormat = 'comma') => {
  const query = queryString.parse(search, {
    arrayFormat,
  })
  return _.get(query, name)
}

const equalValues = ([left, right], getValue = renderSelf) => {
  const leftValue = getValue(left)
  const rightValue = getValue(right)
  return _.isEqual(leftValue, rightValue)
}

const notEmpty = (value) =>
  [
    _.isEmpty(value),
    _.isEqual(value, 'undefined'),
    _.isEqual(value, 'null'),
  ].every((e) => !e)

const searchWith = ({type, keyword, pathname = '/'}, onSearch = Null) => {
  const _searchParams = new URLSearchParams()
  if (!_.isEmpty(type)) {
    _searchParams.set('type', type)
  }
  if (!_.isEmpty(keyword)) {
    _searchParams.set('keyword', keyword)
  }
  onSearch({
    pathname,
    search: _searchParams.toString(),
  })
}

const normalizeString = (str) => {
  const newStr = _.lowerCase(str)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
  return newStr
}
const memoNormalizeString = _.memoize(normalizeString)

const compare = (left, right) => {
  return memoNormalizeString(left).includes(memoNormalizeString(right))
}

const toQueryString = (param, options = {arrayFormat: 'comma'}) =>
  queryString.stringify(param, options)

const getURL = (url, queryParams = {}, options = {arrayFormat: 'comma'}) => {
  if (_.isEmpty(queryParams)) return url
  return queryString.stringifyUrl({url, query: queryParams}, options)
}

const fromObject = (
  item = {},
  fieldParams = [],
  configs = {nameIndex: 'name', defaultValueIndex: 'defaultValue'}
) => {
  if (_.isEmpty(item) || _.isEmpty(fieldParams)) return {}
  return fieldParams.reduce((result, current = {}) => {
    const name = _.get(current, configs.nameIndex)
    const defaultValue = _.get(current, configs.defaultValueIndex)
    result[name] = _.get(item, name, defaultValue)
    return result
  }, {})
}

const shallowDiff = (a, b) => {
  return _.omitBy(a, (v, k) => {
    return _.isArray(b[k]) || _.isObject(b[k])
      ? JSON.stringify(b[k]) === JSON.stringify(v)
      : b[k] === v
  })
}

const renderColon = () => <span style={{marginLeft: 1}}>{':'}</span>

export const getResponseItem = (response, which = 'data') =>
  _.get(response, which) || {}

export const getResponseItems = (response, which = 'data.data') =>
  _.get(response, which) || []

export const mapStringParams = (
  string = '',
  values = [],
  createPattern = (name) => `{${name}}`
) => {
  if (_.isEmpty(values)) return string
  return values.reduce(
    (__, {name, value}) => string.replace(createPattern(name), value),
    string
  )
}

export const validateUrl = (inputString) => {
  try {
    new URL(inputString)
    return true
  } catch (error) {
    return false
  }
}

const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const renderFromToTime = (from = null, to = null) => {
  const fromDate = moment(from)
  const toDate = moment(to)

  if (!!!to) {
    return fromDate.hour() === 0 && fromDate.minute() === 0
      ? fromDate.format('DD/MM/YYYY')
      : fromDate.format('HH:mm - DD/MM/YYYY')
  } else if (!!!from) {
    return toDate.hour() === 0 && toDate.minute() === 0
      ? toDate.format('DD/MM/YYYY')
      : toDate.format('HH:mm - DD/MM/YYYY')
  }

  if (fromDate.isSame(toDate, 'day')) {
    return fromDate.hour() === 0 && fromDate.minute() === 0 && toDate.hour() === 0 && toDate.minute() === 0
      ? fromDate.format('DD/MM/YYYY')
      : (
        <Translate>
          {(t) =>
            `${fromDate.format('DD/MM/YYYY')} ${fromDate.format('HH:mm')} ${t('to')} ${toDate.format('HH:mm')}`
          }
        </Translate>
      )
  } else {
    return fromDate.hour() === 0 && fromDate.minute() === 0 && toDate.hour() === 0 && toDate.minute() === 0
      ? (
        <Translate>
          {(t) =>
            `${t('from')} ${fromDate.format('DD/MM/YYYY')} ${t('to')} ${toDate.format('DD/MM/YYYY')}`
          }
        </Translate>
      )
      : (
        <Translate>
          {(t) =>
            `${t('from')} ${fromDate.format('HH:mm - DD/MM/YYYY')} ${t('to')} ${toDate.format('HH:mm - DD/MM/YYYY')}`
          }
        </Translate>
      )
  }
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
}

export {
  getURL,
  compare,
  notEmpty,
  sortById,
  searchWith,
  fromObject,
  shallowDiff,
  equalValues,
  deepTranslate,
  getItemMoment,
  toQueryString,
  getFullAddress,
  searchQueryParam,
  getLocationLines,
  renderColon,
  generateUUID,
  renderFromToTime,
}
