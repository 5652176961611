import {Alert, Avatar, Checkbox, Modal} from 'antd'
import _ from 'lodash'
import React, {useContext} from 'react'
import languageArray from "../../../../conts/languages";
import useTranslate from "../../../../modules/local/useTranslate";
import UseState from "../../../UseState";
import {FlagIcon} from "../../../icons/FlagIcon";
import classNames from "classnames"
import {LocalContext} from "../../../../modules/local/LocalContext";

export const ContentLanguageSwitch = ({
    following_languages_maximum = 1,
    languages = [],
    setLanguages = () => {},
    setUpdate
}) => {
  const t = useTranslate();
  const {
    changeContentLang
  } = useContext(LocalContext);

  return (
    <UseState initialValue={{isToggle: false, errors: [], following_languages_maximum, languages: languages}}>
      {
        ([{isToggle, errors, following_languages_maximum, languages}, setLanguagesState]) => {

          const toggle = () => setLanguagesState({
            isToggle: !isToggle,
            errors: [],
            following_languages_maximum,
            languages
          });
          const setLangs = (value) => {

            if (_.includes(
              languages,
              value
            )) {
              if (languages.length > 1) {
                setLanguagesState({
                  isToggle,
                  errors: [],
                  following_languages_maximum,
                  languages: languages.filter(e => e !== value)
                });
              }
            } else {
              if (following_languages_maximum === 1) {
                setLanguagesState(
                  {
                    isToggle, errors: [],
                    following_languages_maximum,
                    languages: [value]
                  }
                );
              } else {
                if (following_languages_maximum > languages.length) {
                  setLanguagesState(
                    {
                      isToggle, errors: [],
                      following_languages_maximum,
                      languages: [...languages, value]
                    }
                  );
                } else {
                  setLanguagesState(
                    {
                      isToggle,
                      errors: [
                        t('the maximum number of languages allowed to choose is {1}',
                          {
                            1: following_languages_maximum
                          }
                        )
                      ],
                      following_languages_maximum,
                      languages
                    }
                  );
                }
              }

            }
          }

          return (
            <React.Fragment>
              <div className="flex items-center justify-center cursor-pointer" onClick={toggle}>
                <Avatar.Group size={20} maxCount={2} className="items-center">
                    {languages.map((e, i) => (
                        <FlagIcon key={i} type={e} size={i > 0 ? (20 - (i * 4)) : 19} />
                    ))}
                </Avatar.Group>
              </div>
              <Modal
                destroyOnClose
                title={
                    <div className="text-center font-bold text-color-000 uppercase tracking-wide">
                        {t('languages')}
                    </div>
                }
                className="custom-modal"
                maskClosable
                visible={isToggle}
                onCancel={toggle}
                onOk={() => {
                  changeContentLang(languages);
                  setLanguages(languages);
                  setUpdate && setUpdate(Date.now());
                  toggle();
                }}
                okText={t('save')}
                cancelText={t('close')}
                okButtonProps={{
                    type: 'primary',
                    className: 'button-rounded-lg no-border',
                }}
                cancelButtonProps={{
                    className: 'button-rounded-lg no-border bg-transparent',
                }}>
                <div className="flex flex-col space-y-3">
                  {
                    errors.map((ee, eei) => (
                      <Alert
                        key={eei}
                        type="warning"
                        closable={true}
                        banner
                        message={
                          ee
                        }
                      />
                    ))
                  }
                  <div className="space-y-3 flex flex-col">
                    {languageArray.map(
                      (value, key) => (
                        <div key={key} className="flex items-center">
                          <Checkbox
                            key={key}
                            value={
                              value
                            }
                            onChange={e => {
                              setLangs(e.target.value);
                            }}
                            checked={
                              _.includes(
                                languages,
                                value
                              )
                            }
                            className={classNames(
                              'w-full flex items-center gap-3 hover:background-200 rounded-md px-3 custom-checkbox-lang',
                              _.includes(
                                languages,
                                value
                              )
                                ? 'font-bold text-primary'
                                : 'font-medium text-color-100'
                            )}>
                            <div className="flex items-center gap-3 py-1">
                                <Avatar
                                    size={26}
                                    icon={ <FlagIcon type={value} size={25} /> }
                                    className="flex flex-center"
                                />
                                <span className="ml-2">
                                    {t(value)}
                                </span>
                            </div>
                          </Checkbox>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </Modal>
            </React.Fragment>
          )
        }
      }
    </UseState>
  )
}
