import { Button, Result } from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import { links } from '../routes'

const AddPaymentSuccess = () => {
  const t = useTranslate()
  return (
    <div className="px-3 max-w-xl mx-auto pt-6 verticalList__large">
      <Result
        status="success"
        title={t(
          'successfully purchase!'
        )}
        extra={[
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3">
            <Link className="col-span-1" to={links.overview}>
              <Button block
                type="primary"
                key="console">
                {t('billing overview')}
              </Button>
            </Link>

            <Link className="col-span-1" to={links.billHistory}>
              <Button block key="buy">
                {t('billing history')}
              </Button>
            </Link>
          </div>
        ]}
      />
    </div>
  )
}
export default AddPaymentSuccess
