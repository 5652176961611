import {Avatar, Badge} from 'antd'
import React from 'react'
import classNames from "classnames";
import {FaCrown} from "react-icons/fa";
import {BsFillBarChartLineFill} from "react-icons/bs";

export const DatasetIcon = ({
                              premium = 0,
                              className,
                              ...props
                            }) => {
  if (premium !== 1) {
    return (
      <div
        className={classNames("flex flex-center", className)}
        {...props}>
        <Avatar
          src={null}
          size={30}
          icon={<BsFillBarChartLineFill className="text-color-500"/>}
          className="flex flex-center background-200"
        />
      </div>
    );
  } else {
    return (
      <Badge className={classNames("flex flex-center", className)}
             {...props}
             right={3}
             count={
               <FaCrown size={11} className="text-orange-500"/>
             }>
        <Avatar
          src={null}
          size={30}
          icon={<BsFillBarChartLineFill className="text-color-500"/>}
          className="flex flex-center background-200"
        />
      </Badge>
    )
  }
}
