import {create_product_update_Api} from 'apis'
import {showConfirm} from 'components/Feed/TimeLineFeed'
import _ from 'lodash'
import {createAsyncAction} from 'modules/asyncCache'
import useTranslate from 'modules/local/useTranslate'
import React, {useContext} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {PageContext} from '../../../PageContext'
import {create_product_sortOrder_Api} from "../../../../../apis";
import {Product} from "../Product";

export const ProductSettingListView = ({item, rootPath, setUpdate}) => {
  const location = useLocation()
  const t = useTranslate()
  const history = useHistory()
  const dispatch = useDispatch()
  const {pageRoot} = useContext(PageContext)

  return (
    <>
      <Product
        key={item.id + item.updated}
        item={item}
        deactive={
          '' + item.active === '0'
        }
        {...{
          handleAction: ({key}) => {
            switch (key) {
              case 'preview':
                history.push(
                  pageRoot + '/product/' +
                  item.idname
                )
                break
              case 'edit':
                history.push({
                  ...location,
                  pathname:
                    rootPath +
                    `/product/${item.idname}`
                })
                break
              case 'deactive':
                showConfirm({
                  title: t(
                    'Do you want to deactivate this product?'
                  ),
                  content: t(`This product will be removed from your Catalog and won't be visible to buyers. You will also stop receiving BuyLeads for this product gradually. Are you sure you want to continue?`),
                  onOk: () => {
                    dispatch(
                      createAsyncAction({
                        apiInfo: create_product_update_Api(),
                        query: {
                          ':id':
                          item.idname
                        },
                        values: {
                          active: 0
                        }
                      })
                    )
                  }
                })
                break
              case 'active':
                showConfirm({
                  title: t(
                    'Do you want to activate this product?'
                  ),
                  content: t(`This product will add to your Activated Catalog and will be visible to buyers. You will also start receiving BuyLeads for this product. Thank you`),
                  onOk: () => {
                    dispatch(
                      createAsyncAction({
                        apiInfo: create_product_update_Api(),
                        query: {
                          ':id': item.idname
                        },
                        values: {
                          active: 1
                        }
                      })
                    )
                  }
                })

                break;
              case 'is_primary':
                dispatch(
                  createAsyncAction({
                    apiInfo: create_product_update_Api(),
                    query: {
                      ':id': item.idname
                    },
                    values: {
                      is_primary: 1
                    }
                  })
                )
                break;
              case 'is_not_primary':
                dispatch(
                  createAsyncAction({
                    apiInfo: create_product_update_Api(),
                    query: {
                      ':id': item.idname
                    },
                    values: {
                      is_primary: 0
                    }
                  })
                )
                break
              case 'sort_down':
                dispatch(
                  createAsyncAction({
                    apiInfo: create_product_sortOrder_Api(),
                    query: {
                      ':id': _.get(item, 'owner.id'),
                      ':product_id': item.idname,
                      ':direction': 1
                    }
                  })
                );
                setUpdate(Date.now())
                break
              case 'sort_up':
                dispatch(
                  createAsyncAction({
                    apiInfo: create_product_sortOrder_Api(),
                    query: {
                      ':id': _.get(item, 'owner.id'),
                      ':product_id': item.idname,
                      ':direction': -1
                    }
                  })
                )
                setUpdate(Date.now())
                break
              default:
                history.push({
                  ...location,
                  pathname:
                    rootPath +
                    `/product/${item.idname}/${key}`
                })
                break
            }
          }
        }}
      />
    </>
  )
}

