import {Button, Form, Input} from "antd"
import FieldDecorator from "components/form/FieldDecorator"
import Null from "components/NullComponent"
import _ from "lodash"
import Translate from "modules/local/Translate"
import useTranslate from "modules/local/useTranslate"
import React from "react"
import {FaMinus, FaPlus} from "react-icons/fa"
import {compose, mapProps} from "recompose"
import {BsPlus} from "react-icons/bs";
import {HiOutlineMinusSm} from "react-icons/hi";

const formSchema = [
  {
    title: 'phone',
    name: 'sender_phone',
    // rules: [
    //   {
    //     required: true,
    //     message: 'required!'
    //   }
    // ],
    render: compose(
      mapProps(({ t, title }) => ({
        inputMode: "tel",
        allowClear: true,
        type: "tel",
        placeholder: t(title),
      })
      )(Input)
    )
  },
  {
    title: 'email',
    name: 'sender_email',
    render: compose(
      mapProps(({ t, title }) => ({
        inputMode: "email",
        allowClear: true,
        type: "email",
        placeholder: t(title),
      })
      )(Input)
    )
  },
]

const FieldWrapper = ({
  children,
  ...props
}) => {
  return (
    <FieldDecorator {...props}>
      {children}
    </FieldDecorator>
  )
}

const renderTitle = ({label = ''}) => (
  <Translate>
    {t => (
      <span className="font-medium text-sm">
        {t(label)}
      </span >
    )}
  </Translate>
)

const FilterActionButton = ({
  Icon,
  label,
  children,
  ghost = false,
  onClick = Null,
  Title = renderTitle,
  ...props
}) => {
  return (
    <Button
      type="primary"
      icon={(
        <Icon size={18}/>
      )}
      ghost={ghost}
      style={{
        height: 28
      }}
      className="flex items-center gap-1 px-2 button-rounded-md no-shadow"
      onClick={onClick}
      {...props}>
      <Title label={label} />
    </Button>
  )
}

export const FilterTags = ({
    className,
    selectedValue,
    lableName = 'label',
    valueName = 'value',
    tags = [],
    onItemClick = Null,
    onClear = Null,
    compactResult = true,
    renderItem = renderTitle,
    ...props
}) => {

  const actions = [
    {
      name: 'checked',
      state: false,
      onClick: onItemClick,
      Icon: BsPlus,
    },
    {
      name: 'unchecked',
      state: true,
      onClick: onClear,
      Icon: HiOutlineMinusSm,
    }
  ]

  const isSelected = value => _.isEqual(
    value,
    selectedValue
  )

  return (
    <div {...{
      className
    }}>
      {tags.map((tag, index) => {
        const [label, value] = [
          _.get(tag, lableName),
          _.get(tag, valueName),
        ]
        const isToggle = isSelected(value)
        const {
          name,
          state,
          onClick = Null,
          ...params
        } = actions.find(e => {
          return e.state === isToggle
        })

        return (
          <div
            key={index}
            className="px-1">
            <FilterActionButton
              label={label}
              onClick={() => {
                onClick(tag, index)
              }}
              ghost={!isToggle}
              {...params} />
          </div>
        )
      })}
    </div>
  )
}

const FilterWidget = ({
  className,
  onSubmit = Null
}) => {

  const t = useTranslate()

  const [form] = Form.useForm()

  return (
    <div className={className}>
      <Form className="space-y-3"
        initialValues={{
          sender_phone: null,
          sender_email: null,
        }}
        className="w-full"
        form={form}
        layout="vertical"
        onFinish={onSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-3">
          {formSchema.map(({
            name,
            title,
            rules,
            render,
            Wrapper = FieldWrapper,
          }, index) => {
            return (
              <Wrapper
                key={index}
                name={name}
                label={t(title)}>
                <Form.Item
                  rules={rules}
                  name={name}>
                  {render({
                    title,
                    form,
                    t
                  })}
                </Form.Item>
              </Wrapper>
            )
          })}
        </div>

        <div className="flex space-x-3 py-3">
          <div className="flex-1" />
          <Button
            htmlType="button"
            onClick={() => {
              form.resetFields()
            }}>
            {t('reset')}
          </Button>
          <Button
            type="primary"
            htmlType="submit">
            {t('search')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default FilterWidget