import { API_ROOT_URL } from 'envs/_current/config'
import { createAsyncAction } from 'modules/asyncCache'
import { baseFieldTypes } from './base/baseFieldTypes'

// image
const fields = ['photos[]']
const fieldTypes = {
  'photos[]': baseFieldTypes.image
}
// Route::post('images/image/upload', 'ImageController@upload');
export const image_upload_Api = {
  backend: `Route::post('images/image/upload', 'ImageController@upload')`,
  root: API_ROOT_URL,
  path: '/images/image/upload',
  method: 'POST',
  fields,
  fieldTypes
}
export const create_image_upload_Api_action = () => ({
  prefixStr = 'image_upload_Api',
  query,
  values,
  maxAge = -1
}) =>
  createAsyncAction({
    prefixStr:
      prefixStr + '@image_upload_Api',
    query,
    values,
    apiInfo: image_upload_Api,
    maxAge
  })

// Route::post('images/image/upload-to-album', 'ImageController@uploadAlbum');
// Route::post('images/image/delete-images', 'ImageController@deleteImages');

// // album
// Route::post('images/folder/create-folder', 'ImageController@createFolder');
// Route::post('images/album/create', 'ImageController@createAlbum');
// Route::post('images/album/{album_id}/edit', 'ImageController@updateAlbum');
// Route::post('images/album/{album_id}/change-folder', 'ImageController@changeAlbumFolder');
// Route::post('images/album/delete-albums', 'ImageController@deleteAlbums');

// Route::get('images/image/{props}/{id}/{container_type}/{container_id}/get-albums', 'ImageController@getAlbums');
// Route::get('images/image/{props}/{id}/{container_type}/{container_id}/get-images', 'ImageController@getImages');

// Route::resource('image', 'ImageController', ['only' => ['index']]);
