import Icon from '@ant-design/icons'
import DefaultLayout from 'components/layouts/Default'
import Modal from 'components/Modal/Modal'
import {NavigationContext} from 'modules/navigation/NavigationContext'
import React, {useContext, useEffect} from 'react'
import {MdClose} from 'react-icons/md'
import Loadable from 'react-loadable'
import {Redirect} from 'react-router-dom'
import {fromRenderProps, nest, withProps} from 'recompose'
import {compose} from 'redux'
import {createRequiredLogin, ModalLogin} from 'routes/createRequiredLogin'
import {createRequiredNonLogin} from 'routes/createRequiredNonLogin'
import AccountSettings from 'views/Account/AccountSettings'
import {Logout, ResetPassword} from 'views/Auth'
import DocumentEditor from 'views/DocumentEditor'
import GetQuotes from 'views/GetQuotes'
import LoadingPage from 'views/LoadingPage'
import createMarketRoutes from "views/Market/createMarketRoutes"
import ProductsSearch from 'views/Market/ProductsSearch.lazy'
import Messages from 'views/Messages/Messages.lazy'
import Notification from 'views/Notification'
import {HeaderButton} from 'views/Organization/components/PageHeader'
import {SettingsModal} from 'views/Organization/Settings/SettingsModal'
import {staticPaths} from './staticPaths'

export const RequiredLogin = Loadable({
  loader: () => import('views/403'),
  loading: ({ isLoading }) =>
    isLoading && <LoadingPage />
})

const routesObject = createMarketRoutes({ pageRoot: '' })
export const routesConfig = [

  {
    key: 'reset-pwd',
    name: 'reset password',
    path: '/reset-pwd',
    exact: true,
    component: ResetPassword
  },
  {
    key: 'logout',
    name: 'logout',
    path: '/logout',
    withSubDomain: true,
    exact: true,
    component: Logout
  },
  {
    path: '/mart/get-quotes/product/:id',
    name: 'getQuotes',
    component: GetQuotes
  },
  {
    path: '/',
    name: 'home',
    component: DefaultLayout,
    routes: [
      {
        name: 'document',
        path: '/document/:id',
        component: DocumentEditor
      },
      {
        name: 'document',
        path: '/document',
        component: DocumentEditor
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: createRequiredLogin(
          ModalLogin
        )(Notification),
        exact: true
      },
      // {
      //   path: '/bookmark',
      //   name: 'bookmark',
      //   component: createRequiredLogin(
      //     ModalLogin
      //   )(BookmarkLazy),
      //   exact: true,
      // }, 
      {
        path: '/messages',
        name: 'messages',
        component: createRequiredLogin(
          ModalLogin
        )(Messages)
      }, ...routesObject.routes,]
  }
]
const PageModal = ({
  children,
  ...props
}) => (
  <NavigationContext.Consumer>
    {({ handleGoBack }) => (
      <Modal
        visible="true"
        footer={null}
        header={
          <HeaderButton
            style={{
              position: 'absolute',
              top: 0,
              right: 0
            }}
            onClick={handleGoBack}>
            <Icon
              style={{
                fontWeight: 700,
                fontSize: '1.2em'
              }}
              component={MdClose}
            />
          </HeaderButton>
        }
        onCancel={handleGoBack}
        {...props}>
        <div className="mx-auto flex flex-1 flex-col">
          {children}
        </div>
      </Modal>
    )}
  </NavigationContext.Consumer>
)

export const modalRoutesConfig = [
  {
    path: '/login',
    name: 'login',
    component: createRequiredNonLogin(
      () => {
        const {
          handleGoBack
        } = useContext(
          NavigationContext
        )
        useEffect(() => {
          handleGoBack()
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [handleGoBack])
        return null
      }
    )(ModalLogin),
    exact: true
  },
  {
    key: 'logout',
    name: 'logout',
    path: '/logout',
    exact: true,
    withSubDomain: true,
    component: createRequiredLogin(
      withProps({
        to: staticPaths.home
      })(Redirect)
    )(nest(PageModal, Logout))
  },
  {
    path: '/account/settings',
    name: 'account settings',
    component: createRequiredLogin(
      ModalLogin
    )(
      compose(
        fromRenderProps(
          NavigationContext.Consumer,
          ({ handleGoBack }) => ({
            onCancel: handleGoBack
          })
        ),
        withProps(() => ({
          maskClosable: false
        }))
      )(
        nest(
          SettingsModal,
          AccountSettings
        )
      )
    )
  },
  {
    path: '/search',
    name: 'search products',
    component: compose(
      fromRenderProps(
        NavigationContext.Consumer,
        ({ handleGoBack }) => ({
          onCancel: handleGoBack
        })
      )
    )(ProductsSearch),
    exact: true
  }

]
