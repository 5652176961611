import classNames from 'classnames'
import _ from 'lodash'
import Translate from 'modules/local/Translate'
import React from 'react'
import {Null, renderIf} from 'views/Shared'
import SeeMore from '../MySpace/components/SeeMore'

const renderLimitLoadMore = ([limit, setLimit, params = {}]) => {
  const { Component = 'div', ...props } = params
  return renderIf(limit > 0, (__, ___, data, nextUrl) => {
    const items = Array.from(_.get(data, 'response.data.data') ?? [])
    return renderIf(
      nextUrl || items.length,
      <SeeMore
        Component={Component}
        renderItem={(render) =>
          render({
            onClick: () => {
              setLimit(0)
            },
          })
        }
        {...props}
      />
    )
  })
}

export const renderShowMore =
  ([limit = 0, setLimit = Null], {className, title = 'show more'} = {}) =>
    (fetch_data, record) => {
      const {links, count = 0} = record ?? {}
      return renderIf(
        _.some([limit > 0 && count > limit, links?.next], Boolean),
        <Translate>
          {(t) => (
            <div className={classNames('bottom-0 flex w-full', className)}>
              <div className="py-3 px-3 flex-wrap flex w-full justify-center text-color-300 italic text-xs">
                <div className="flex-1"/>
                <div
                  className="flex cursor-pointer"
                  onClick={() => {
                    if (limit > 0) {
                      setLimit(0)
                    }
                    fetch_data()
                  }}>
                  {_.isString(title) ? t(title) : title}
                </div>
              </div>
            </div>
          )}
        </Translate>
      )
    }

export default renderLimitLoadMore
