import React from "react";

export const Petroleum = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 -49 511.997 511"
		>
			<path
				fill="#ccc"
				d="M511.996 58.012l-.18.183-52.62 52.63L443.167 94.8c-26.203-26.203-68.86-26.203-95.063 0l-29.863 29.863-51.254-10.566-1.558-42.246L295.293 42c55.328-55.332 145.36-55.332 200.687-.012zm0 0"
			></path>
			<path
				fill="#e6e6e6"
				d="M511.816 58.195l-52.62 52.63L443.171 94.8c-26.207-26.203-68.86-26.203-95.067 0l-29.863 29.863-30.91-30.91 29.863-29.863c53.348-53.356 138.957-55.258 194.621-5.696zm0 0"
			></path>
			<path
				fill="#ccc"
				d="M288.48 258.152c-34.44 0-62.46-28.02-62.46-62.457 0-5.582 4.527-10.113 10.117-10.113 5.586 0 10.113 4.531 10.113 10.113 0 23.285 18.945 42.23 42.23 42.23 5.586 0 10.114 4.528 10.114 10.114 0 5.586-4.528 10.113-10.114 10.113zm0 0"
			></path>
			<path
				fill="#5c5e70"
				d="M85.074 283.137l44.778 44.777-85.075 85.074L0 368.211zm0 0"
			></path>
			<path
				fill="#3b3d4c"
				d="M85.074 283.133l18.07 18.074-85.074 85.074L0 368.207zm0 0"
			></path>
			<path
				fill="#e6e6e6"
				d="M86.734 250.555l75.7 75.699-36.532 36.531-75.699-75.7zm0 0"
			></path>
			<path
				fill="#ccc"
				d="M86.734 250.555l25.18 25.18-36.531 36.53-25.18-25.18zm0 0"
			></path>
			<path
				fill="#f4514a"
				d="M370.246 176.664L265.43 71.848 105.02 155.37c-14.012 7.293-16.903 26.094-5.735 37.262l22.684 22.687L74.289 263l104.805 104.805c20.625 20.625 54.05 20.625 74.676 0l116.476-116.477c20.613-20.613 20.613-54.05 0-74.664zm-30.183 44.492l-63.848 63.856c-3.945 3.949-10.367 3.949-14.317 0l-52.003-52.004 78.164-78.16 52.003 51.988c3.95 3.95 3.95 10.375 0 14.32zm0 0"
			></path>
			<path
				fill="#d13030"
				d="M276.379 345.195c-20.621 20.621-54.05 20.621-74.672 0L96.902 240.391 74.29 263l104.805 104.805c20.62 20.625 54.05 20.625 74.672 0l116.476-116.477zm0 0M144.582 192.707l-22.684-22.687c-5.566-5.563-7.636-13.024-6.585-20.008l-10.293 5.36c-14.012 7.292-16.903 26.093-5.735 37.26l22.684 22.688zm0 0"
			></path>
			<path
				fill="#fbd25f"
				d="M422.16 221.86c0 .995.012 1.992.043 2.98 1.473 49.39 75.957 49.39 77.426 0a94.61 94.61 0 00.047-2.98c0-31.903-15.211-60.243-38.758-78.204-23.55 17.961-38.758 46.301-38.758 78.203zm0 0"
			></path>
			<path
				fill="#f99041"
				d="M460.914 261.879c-18.992 0-37.98-12.344-38.71-37.04a94.697 94.697 0 01-.044-2.984c0-31.894 15.203-60.234 38.762-78.203-9.324 17.97-15.336 46.309-15.336 78.203 0 1 0 1.993.012 2.985.289 24.695 7.804 37.039 15.316 37.039zm0 0"
			></path>
		</svg>
	);
}

export default Petroleum;
