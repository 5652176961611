import React, {useState} from 'react'
import {UseHook} from './UseHook'

export default function UseState({
                                   initialValue,
                                   ...props
                                 }) {
  return (
    <UseHook
      hook={useState}
      initialValue={initialValue}
      {...props}
    />
  )
}
