import {Editor, Node, Path, Transforms} from 'slate'
import {ReactEditor} from 'slate-react'
import {ElementTypes} from '../types'
import {getNextNode, getNode} from './editor'
import {createParagraphNode} from './paragraph'

export function insertImage(editor, src, params) {
  const {error, imageId, isLoading} = params ?? {}

  const {selection} = editor ?? {}

  const node = {
    src,
    imageId,
    alt: 'Image',
    type: ElementTypes.IMAGE,
    children: [{text: ''}],
  }

  ReactEditor.focus(editor)

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path
    )

    const current = getNode(editor, ElementTypes.IMAGE)

    if (!imageId && parentNode.type === ElementTypes.IMAGE) {
      Transforms.removeNodes(editor, {at: parentPath})
    }

    if (current) {
      const [__, path] = current
      if (error) {
        Transforms.removeNodes(editor, {at: path})
      } else if (!isLoading && imageId) {
        Transforms.removeNodes(editor, {at: path})
        Transforms.insertNodes(editor, node)
        if (!getNextNode(editor)) {
          Transforms.insertNodes(editor, createParagraphNode())
        }
      }
      return
    }

    if (editor.isVoid(parentNode) || Node.string(parentNode).length) {
      Transforms.insertNodes(editor, createParagraphNode([node]), {
        at: Path.next(parentPath),
        select: true,
      })
    } else {
      Transforms.insertNodes(editor, node, {at: parentPath, select: true})
    }
  }
}
