import React from "react";

export const SoyBean = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 256 256"
		>
			<ellipse
				cx="91.653"
				cy="67.029"
				fill="#fcd383"
				rx="28.113"
				ry="35.299"
				transform="rotate(-69.76 91.71 67.052)"
			></ellipse>
			<path
				fill="#af793b"
				d="M97.132 66.406l-9.244-3.404a5.63 5.63 0 113.89-10.565l9.244 3.405a5.63 5.63 0 01-3.89 10.565z"
			></path>
			<ellipse
				cx="95.415"
				cy="159.493"
				fill="#ffecb6"
				rx="35.299"
				ry="28.114"
				transform="rotate(-52.06 95.436 159.47)"
			></ellipse>
			<path
				fill="#af793b"
				d="M104.02 157.607l-6.053 7.771a5.63 5.63 0 11-8.882-6.918l6.053-7.771a5.629 5.629 0 118.882 6.918z"
			></path>
			<ellipse
				cx="142.328"
				cy="183.644"
				fill="#fcdc97"
				rx="35.299"
				ry="28.113"
			></ellipse>
			<path
				fill="#af793b"
				d="M147.253 181.167h-9.85a5.63 5.63 0 010-11.26h9.85a5.63 5.63 0 110 11.26z"
			></path>
			<ellipse
				cx="193.317"
				cy="140.086"
				fill="#fcd383"
				rx="35.299"
				ry="28.114"
				transform="rotate(-30.08 193.323 140.113)"
			></ellipse>
			<path
				fill="#af793b"
				d="M196.338 135.474l-8.525 4.936a5.63 5.63 0 11-5.641-9.743l8.525-4.936a5.63 5.63 0 115.641 9.743z"
			></path>
			<ellipse
				cx="201.942"
				cy="191.749"
				fill="#ffecb6"
				rx="28.113"
				ry="35.299"
				transform="rotate(-48.53 201.911 191.783)"
			></ellipse>
			<path
				fill="#af793b"
				d="M203.288 200.454l-7.38-6.526a5.63 5.63 0 017.46-8.434l7.379 6.527a5.63 5.63 0 01-7.46 8.433z"
			></path>
			<ellipse
				cx="144.681"
				cy="86.336"
				fill="#fcdc97"
				rx="35.299"
				ry="28.114"
				transform="rotate(-42.08 144.664 86.35)"
			></ellipse>
			<path
				fill="#af793b"
				d="M120.303 71.494a5.627 5.627 0 009.255 5.438l7.313-6.601a5.627 5.627 0 00-4.464-9.762 43.988 43.988 0 00-12.104 10.925z"
			></path>
			<ellipse
				cx="53.477"
				cy="91.403"
				fill="#ffecb6"
				rx="35.299"
				ry="28.114"
				transform="rotate(-17.25 53.529 91.567)"
			></ellipse>
			<path
				fill="#af793b"
				d="M37.582 67.699a5.626 5.626 0 006.117 8.82l9.409-2.921a5.627 5.627 0 00.046-10.734 44 44 0 00-15.572 4.834z"
			></path>
		</svg>
	);
}

export default SoyBean;
