import Null from "components/NullComponent"
import _ from "lodash"
import useAsyncAction from "modules/asyncCache/useAsyncAction"
import React, { useMemo, useState } from "react"
import { usePrevious } from "react-use"
import { renderSelf } from 'views/Shared'

const useSearchSuggestion = ({
  query = {},
  values = {},
  apiInfo = {},
  minLength = 2,
  debounce = 500,
  dependencies = [],
  transform = renderSelf
}) => {
  const {
    response,
    isLoading = false,
    handleAsyncAction
  } = useAsyncAction({
    query,
    values,
    apiInfo,
  })

  const [keyword, setkeyword] = useState()

  const result = transform(response)

  const prev = usePrevious(result)

  const str = _.toString(keyword).trim()

  const isPending = str.length >= minLength

  const onSearch = useMemo(() => {
    const handler = (keyword) => {
      setkeyword(keyword)
      // const current = _.toString(keyword).trim()
      // if (current.length < minLength) return
      handleAsyncAction({
        keyword,
      })
    }
    return _.debounce(handler, debounce)
  }, [])

  return {
    result: isLoading ? prev : result,
    keyword,
    onSearch,
    isPending
  }
}

export const renderAutoCompleteLabel = label => (
  <span className="text-primary">{label}</span>
)

export const renderAutoCompleteItem = ({
  label,
  value,
  render = renderAutoCompleteLabel
}) => ({
  value,
  label: render(label)
})

export const SearchSuggestion = ({
  children,
  query = {},
  values = {},
  apiInfo = {},
  transform = renderSelf,
  ...props
}) => {
  const {
    result = [],
    onSearch = Null,
    isPending = false
  } = useSearchSuggestion({
    query,
    values,
    apiInfo,
    transform,
    ...props
  })

  return children(
    result,
    onSearch,
    isPending
  )
}

export default useSearchSuggestion