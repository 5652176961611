import React from "react";

export const MilkProducts = ({size = 16, ...props}) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
		>
			<g data-name="Dairy Product">
				<g>
					<path
						fill="#e2dbd5"
						d="M208 72H48V16A16 16 0 0164 0h128a16 16 0 0116 16z"
					></path>
					<path fill="#d4ccc7" d="M208.167 56H144v456h112.333V144z"></path>
					<path fill="#c6bab3" d="M144 56h64.167v456H144z"></path>
					<path
						fill="#e2dbd5"
						d="M169.6 128l-9.6 16v368H16a16 16 0 01-16-16V144a32.956 32.956 0 0128.259-16z"
					></path>
					<rect width="112" height="80" x="304" fill="#ffead2" rx="8"></rect>
					<path
						fill="#faddc3"
						d="M408 0h-24a88.011 88.011 0 01-51.3 80H408a8 8 0 008-8V8a8 8 0 00-8-8z"
					></path>
					<path fill="#f5d3b8" d="M304 24h112v16H304z"></path>
					<path
						fill="#f09b73"
						d="M440 512H280a8 8 0 01-8-8V200a88.428 88.428 0 0134.54-69.9C322.887 117.594 288 105.284 288 86.833A22.859 22.859 0 01310.833 64h98.334A22.859 22.859 0 01432 86.833c0 10.149-11.067 19.4-11.067 19.4-8.859 7.418-15.391 17.806-7.473 23.863A88.428 88.428 0 01448 200v304a8 8 0 01-8 8z"
					></path>
					<path
						fill="#e58b6c"
						d="M272 311.394V504a8 8 0 008 8h160a8 8 0 008-8V200a87.086 87.086 0 00-1.051-13.424A208.119 208.119 0 01272 311.394z"
					></path>
					<path fill="#efebe8" d="M160 144H0l48-88h160z"></path>
					<path
						fill="#eed60f"
						d="M280 512c54.5 0 124.472-11.3 157.577-43.217a7.884 7.884 0 001.889-8.536l-65.54-168.742a8.019 8.019 0 00-9.552-4.806c-22.076 5.918-25.806-22.324-11.487-29.463a7.9 7.9 0 003.871-9.934l-13.292-34.221A8 8 0 00336 208h-56z"
					></path>
					<path
						fill="#f8e868"
						d="M280 512a145.412 145.412 0 01-101.577-40.926 8 8 0 01-1.889-8.611l9.784-25.41a8 8 0 016.984-5.111c11.908-.718 19.9-17.064 10.327-26.922a8 8 0 01-1.726-8.447l25.85-67.138a8.072 8.072 0 0111.771-3.868c19.987 12.755 33.973-25.583 11.316-29.31a8.085 8.085 0 01-6.167-10.769l27.861-72.363A8 8 0 01280 208a8 8 0 017.466 5.125l96 249.338a8 8 0 01-1.889 8.611A145.412 145.412 0 01280 512z"
					></path>
					<path
						fill="#f6e23d"
						d="M383.466 462.463l-41.412-107.558a200.121 200.121 0 01-163.56 116.236 146.517 146.517 0 00203.083-.067 8 8 0 001.889-8.611z"
					></path>
					<circle cx="312" cy="432" r="24" fill="#f8e868"></circle>
					<circle cx="288" cy="376" r="24" fill="#f6e23d"></circle>
					<circle cx="248" cy="448" r="24" fill="#f8e868"></circle>
					<g fill="#eed60f">
						<path d="M304 448a24 24 0 0130.966-22.966 24 24 0 10-29.932 29.932A23.932 23.932 0 01304 448zM280 392a24 24 0 0130.966-22.966 24 24 0 10-29.932 29.932A23.932 23.932 0 01280 392zM240 464a24 24 0 0130.966-22.966 24 24 0 10-29.932 29.932A23.932 23.932 0 01240 464z"></path>
					</g>
					<circle cx="280" cy="280" r="16" fill="#fcf5be"></circle>
					<circle cx="296" cy="312" r="8" fill="#fcf5be"></circle>
					<path
						fill="#81c784"
						d="M336.033 328l16 176.724A8 8 0 00360 512h112a8 8 0 007.967-7.276l16-176.724z"
					></path>
					<circle cx="356" cy="148" r="20" fill="#f2bba5"></circle>
					<circle cx="388" cy="180" r="12" fill="#f2bba5"></circle>
					<circle cx="64" cy="96" r="16" fill="#fff"></circle>
					<circle cx="88" cy="120" r="8" fill="#fff"></circle>
					<circle cx="376" cy="368" r="16" fill="#c8e6c9"></circle>
					<circle cx="400" cy="392" r="8" fill="#c8e6c9"></circle>
					<path
						fill="#d4ccc7"
						d="M0 496a16 16 0 0016 16h144v-80C132.4 495.156 0 496 0 496z"
					></path>
					<path fill="#ea6049" d="M0 208h160v224H0z"></path>
					<path
						fill="#de513e"
						d="M0 372.288V432h160V241.692A216.326 216.326 0 010 372.288z"
					></path>
					<path
						fill="#fff"
						d="M32 336c0-23.491 40-80 48-80s48 55.383 48 80c0 57.418-96 65.9-96 0z"
					></path>
					<path
						fill="#efebe8"
						d="M36.09 357.189C54.4 399.027 128 386.274 128 336c0-5.617-2.087-12.839-5.4-20.636-13.267 32.691-51.368 52.063-86.51 41.825z"
					></path>
					<path
						fill="#49ad4d"
						d="M504 336H328a8 8 0 010-16h176a8 8 0 010 16z"
					></path>
					<path
						fill="#66bb6a"
						d="M348 460.142l4.036 44.582A8 8 0 00360 512h112a8 8 0 007.967-7.276l12.354-136.453A172 172 0 01348 460.142z"
					></path>
				</g>
			</g>
		</svg>
	);
}

export default MilkProducts;
