import {Button} from 'antd'
import useTranslate from 'modules/local/useTranslate'
import React from 'react'
import {PicturesWall} from 'views/CreateImagePost/CreateImagePost'
import {Null} from 'views/Shared'

const AttachedImages = ({values, Wrapper = 'div', onChange = Null}) => {
  const t = useTranslate()
  return (
    <Wrapper className="flex flex-wrap items-center w-full">
      <PicturesWall
        values={values}
        onChange={onChange}>
        <div
          style={{
            padding: '0.5em',
            width: '100px',
            height: '100px',
          }}>
          <Button className="w-full h-full flex flex-center border-dashed background-100 hover:background-200">
            {t('upload')}
          </Button>
        </div>
      </PicturesWall>
    </Wrapper>
  )
}

export default AttachedImages
