import { baseItemSchema } from 'apis/schema'
import {
  useCallback,
  useMemo
} from 'react'
import { useSelector } from 'react-redux'
import { selectEntitiesByIdName } from 'redux/selectors'

export const SelectEntityItemByIdname = ({
  item,
  schema = baseItemSchema,
  equal,
  children
}) => {
  const mapStateToProps = useCallback(
    state => {
      return selectEntitiesByIdName(
        state,
        item,
        schema
      )
    },
    [item, schema]
  )

  const result = useSelector(
    mapStateToProps,
    equal
  )

  return useMemo(
    () => children(result) || null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result]
  )
}
