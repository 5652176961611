import { event_edit_Api } from 'apis/event'
import { getId } from 'apis/model/base'
import _ from 'lodash'
import { useContext } from 'react'
import { MultiCategoryPicker } from 'views/AppLocals/custom/TagsPicker'
import createEditableForm from 'views/AppLocals/factory/createEditableForm'
import { createControlledFormField } from 'views/AppLocals/factory/createFormField'
import { mapEntityOptions } from 'views/AppLocals/factory/createSelectEntityProps'
import { bindQueryParam } from 'views/AppLocals/functions/routerHelper'
import EventContext from '../EventContext'

const formFields = [
  {
    name: 'categories',
    defaultValue: undefined,
  },
]

const formSchema = [
  {
    title: 'categories',
    children: [
      {
        name: 'categories',
        component: createControlledFormField({
          Component: MultiCategoryPicker,
        }),
      },
    ],
  },
]

const EventSettingCategories = () => {
  const { event } = useContext(EventContext)

  if (_.isEmpty(event)) return null

  return createEditableForm(
    formFields,
    formSchema
  )({
    item: event,
    query: bindQueryParam({
      id: getId(event),
    }),
    validationSchema: undefined,
    onPreSubmit: ({ categories, ...values }) =>
      _.omitBy(
        {
          ...values,
          categories: categories
            ? Array.from(categories || []).join(',')
            : undefined,
        },
        _.isUndefined
      ),
    initialized: ({ categories, ...values }) => ({
      ...values,
      categories: mapEntityOptions(categories),
    }),
    apiInfo: event_edit_Api,
  })
}

export default EventSettingCategories
